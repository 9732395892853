import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useAppContext } from "../../Context";
import { HomeCredential } from "../../types/serviceLocation";
import { useFormik } from "formik";
import { credentialSchema } from "../../pages/NewServiceLocationForm";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { LoadingSpinner } from "./loadingSpinner";
import { addCredentialToLocation, deleteCredentialInLocation, updateCredentialInLocation } from "../../apicalls/serviceLocationService";

export const EditLocationCredentialsCard = props => {
    const [editId, setEditId] = useState<string | null>(null);

    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, updatedCredential: HomeCredential) {
        
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    function handleDelete(){
        // Delete the contact
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    const [showCredentialForm, setShowCredentialForm] = useState(false);

    const openCredentialForm = () => {
        setShowCredentialForm(true);
    }

    const closeCredentialForm = () => {
        setShowCredentialForm(false);
    }

    const credentialFormik = useFormik({
        initialValues: {
            CredentialName: "",
            CredentialType: "",
            UserName: "",
            Password: "",
            AccountID: "",
            TwoFactorAuthContact: "",
        },
        validationSchema: credentialSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Form submitted")
            console.log(values)
            setLoading(true);

            var newCredential: HomeCredential = {
                homeCredentialID: "",
                serviceLocationID: "",
                credentialType: values.CredentialType,
                credentialName: values.CredentialName,
                userName: values.UserName,
                password: values.Password,
                accountID: values.AccountID,
                twoFactorAuth: values.TwoFactorAuthContact,
            };

            var result = await addCredentialToLocation(props?.location?.serviceLocationID, newCredential, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Home Credential added successfully.");
                appContext.setShowSuccessModal(true);
            }
            setLoading(false);
            props?.refreshLocation(props?.location?.serviceLocationID);
            closeCredentialForm();
            //handleAddCredential(values);
        },
    });

    return (<>
        <div className="tw-mt-4">
                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Credentials</span>
                            {!showCredentialForm && <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={openCredentialForm}/> }
                        </div>

                        {showCredentialForm && <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">New Credential</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Credential Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="credential-name"
                                                id="credential-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.CredentialName && credentialFormik.errors.CredentialName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('CredentialName')}
                                                />
                                                {credentialFormik.touched.CredentialName && credentialFormik.errors.CredentialName && <p className="tw-text-red-500">{credentialFormik.errors.CredentialName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Credential Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="credential-type"
                                                id="credential-type"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.CredentialType && credentialFormik.errors.CredentialType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('CredentialType')}
                                                />
                                                {credentialFormik.touched.CredentialType && credentialFormik.errors.CredentialType && <p className="tw-text-red-500">{credentialFormik.errors.CredentialType}</p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                UserName
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="username"
                                                id="username"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.UserName && credentialFormik.errors.UserName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('UserName')}
                                                />
                                                {credentialFormik.touched.UserName && credentialFormik.errors.UserName && <p className="tw-text-red-500">{credentialFormik.errors.UserName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Password
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="password"
                                                id="password"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.Password && credentialFormik.errors.Password ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('Password')}
                                                />
                                                {credentialFormik.touched.Password && credentialFormik.errors.Password && <p className="tw-text-red-500">{credentialFormik.errors.Password}</p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Account ID
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="account-id"
                                                id="account-id"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.AccountID && credentialFormik.errors.AccountID ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('AccountID')}
                                                />
                                                {credentialFormik.touched.AccountID && credentialFormik.errors.AccountID && <p className="tw-text-red-500">{credentialFormik.errors.AccountID}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                2FA Contact
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="2fa-contact"
                                                id="2fa-contact"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.TwoFactorAuthContact && credentialFormik.errors.TwoFactorAuthContact ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('TwoFactorAuthContact')}
                                                />
                                                {credentialFormik.touched.TwoFactorAuthContact && credentialFormik.errors.TwoFactorAuthContact && <p className="tw-text-red-500">{credentialFormik.errors.TwoFactorAuthContact}</p>}
                                            </div>
                                        </div>

                                        
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={closeCredentialForm}>
                                                        Cancel
                                                </ButtonSecondary>
                                                <ButtonPrimary className="tw-mt-md" onClick={credentialFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>

                                        </div></>}
                                </div>}

                        {props?.credentials?.length > 0 &&  props?.credentials?.map((credential, index) => {
                            if(index !== props?.credentials?.length - 1) {
                                return <>
                                    {credential.homeCredentialID === editId ? <EditCredentialCard credential={credential} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} location={props?.location}/> : <ViewCredentialCard credential={credential} handleEdit={handleEdit} />}
                                    
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                </>
                            } else {
                                return <>
                                    {credential.homeCredentialID === editId ? <EditCredentialCard credential={credential} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} location={props?.location}/> : <ViewCredentialCard credential={credential} handleEdit={handleEdit} />}
                                </>
                            }
                            })}


        </div>
    </>);
}

const EditCredentialCard = props => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const confirmDelete = () => {
        setShowConfirmDelete(true);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const deleteCredential = async () => {
        setLoading(true);
        var result = await deleteCredentialInLocation(props?.location?.serviceLocationID, props?.credential?.homeCredentialID, appContext.handleAPICallError);

        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Home credential deleted successfully.");
            appContext.setShowSuccessModal(true);
        }

        props?.handleDelete();

        setLoading(false);
    }
    
    const credentialFormik = useFormik({
        initialValues: {
            CredentialName: props?.credential.credentialName,
            CredentialType: props?.credential.credentialType,
            UserName: props?.credential.userName,
            Password: props?.credential.password,
            AccountID: props?.credential.accountID,
            TwoFactorAuthContact: props?.credential.twoFactorAuth,
        },
        validationSchema: credentialSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Form submitted");
            console.log(values);
            setLoading(true);

            var updatedCredential: HomeCredential = {
                homeCredentialID: props?.credential?.homeCredentialID,
                serviceLocationID: props?.credential?.serviceLocationID,
                credentialType: values.CredentialType,
                credentialName: values.CredentialName,
                userName: values.UserName,
                password: values.Password,
                accountID: values.AccountID,
                twoFactorAuth: values.TwoFactorAuthContact,
            };

            var result = await updateCredentialInLocation(props?.location?.serviceLocationID, updatedCredential, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Home Credential added successfully.");
                appContext.setShowSuccessModal(true);
            }
            setLoading(false);

            props?.handleSave(updatedCredential);
            //handleAddCredential(values);
        },
    });

    
    return (<>
        <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Credential</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Credential Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="credential-name"
                                                id="credential-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.CredentialName && credentialFormik.errors.CredentialName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('CredentialName')}
                                                />
                                                {credentialFormik.touched.CredentialName && credentialFormik.errors.CredentialName && <p className="tw-text-red-500"><>{credentialFormik.errors.CredentialName}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Credential Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="credential-type"
                                                id="credential-type"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.CredentialType && credentialFormik.errors.CredentialType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('CredentialType')}
                                                />
                                                {credentialFormik.touched.CredentialType && credentialFormik.errors.CredentialType && <p className="tw-text-red-500"><>{credentialFormik.errors.CredentialType}</></p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                UserName
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="username"
                                                id="username"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.UserName && credentialFormik.errors.UserName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('UserName')}
                                                />
                                                {credentialFormik.touched.UserName && credentialFormik.errors.UserName && <p className="tw-text-red-500"><>{credentialFormik.errors.UserName}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Password
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="password"
                                                id="password"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.Password && credentialFormik.errors.Password ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('Password')}
                                                />
                                                {credentialFormik.touched.Password && credentialFormik.errors.Password && <p className="tw-text-red-500"><>{credentialFormik.errors.Password}</></p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Account ID
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="account-id"
                                                id="account-id"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.AccountID && credentialFormik.errors.AccountID ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('AccountID')}
                                                />
                                                {credentialFormik.touched.AccountID && credentialFormik.errors.AccountID && <p className="tw-text-red-500"><>{credentialFormik.errors.AccountID}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                2FA Contact
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="2fa-contact"
                                                id="2fa-contact"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.TwoFactorAuthContact && credentialFormik.errors.TwoFactorAuthContact ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('TwoFactorAuthContact')}
                                                />
                                                {credentialFormik.touched.TwoFactorAuthContact && credentialFormik.errors.TwoFactorAuthContact && <p className="tw-text-red-500"><>{credentialFormik.errors.TwoFactorAuthContact}</></p>}
                                            </div>
                                        </div>

                                        
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner />
                                        </div>
                                    </> : <>
                                    {showConfirmDelete ? <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                        <p className="tw-text-primary">Are you sure you want to delete this home credential?</p>
                                        </div>
                                    
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deleteCredential}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={confirmDelete}>
                                                    Delete
                                            </ButtonSecondary>
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.handleCancel}>
                                                        {credentialFormik.dirty ? "Discard" : "Cancel"}
                                                </ButtonSecondary>
                                                {credentialFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={credentialFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>}

                                        </div>
                                    </>}
                                    </>}
                                    
                                </div>
    </>);
}

const ViewCredentialCard = props => {
    return (<>
        <div className="tw-bg-grey-light tw-flex tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
        <div className="tw-w-4/5 tw-flex-grow">
        <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.credential.credentialName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.credential.credentialType}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>UserName</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.credential.userName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Password</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.credential.password}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Account ID</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.credential.accountID}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>2FA Contact</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.credential.twoFactorAuth}</span>
                                            </div>
                                        

        </div>
        <div className="tw-w-1/5 tw-flex tw-justify-end tw-items-center">
                        <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={() => props?.handleEdit(props?.credential.homeCredentialID)} />
            </div>  
            </div>
                                            
    </>);
}