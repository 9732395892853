import ClientReportDashboard from "./ClientReportDashboard";

const ClientReportManagementPage = props => {
    return (<>
        <div className="tw-px-xl tw-py-xl">
				 <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Client Report Management</h2>
                </div>

                <ClientReportDashboard />

        </div>
    </>);
}

export default ClientReportManagementPage;