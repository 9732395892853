import axios, { AxiosRequestConfig } from "axios";
import BlobFile from "../types/blobFile";
import BlobFileStatus from "../types/blobFileStatus";
import { getAPIToken } from "./billingService";

export const uploadFilesToBlob = async (files: FormData, handleAPICallError: (error: any) => void) : Promise<BlobFileStatus[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "files/blobUpload";

    return axios.post<BlobFileStatus[]>(url, files, requestHeader).then((response) => {
        console.log(response.data);

        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });


}

export const getAccountingClaimExcelFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "accountingExcel/"+startDate+"/"+endDate;
    

    return axios.get<BlobFile>(url,requestHeader)
    .then(response => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    })
    .catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getMaskedAccountingClaimExcelFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "accountingExcel/masked/"+startDate+"/"+endDate;
    

    return axios.get<BlobFile>(url,requestHeader)
    .then(response => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    })
    .catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getSalesReportExcelFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "salesReport/"+startDate+"/"+endDate;
    

    return axios.get<BlobFile>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getErrorReportExcelFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "errorReport/"+startDate+"/"+endDate;
    

    return axios.get<BlobFile>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getCombinedReportExcelFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "combinedReport/"+startDate+"/"+endDate;
    

    return axios.get<BlobFile>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getMismatchReportExcelFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "mismatchReport/"+startDate+"/"+endDate;
    

    return axios.get<BlobFile>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getUnBilledReportExcelFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "unbilledReport/"+startDate+"/"+endDate;
    

    return axios.get<BlobFile>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}