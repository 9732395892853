import { useEffect, useRef, useState } from "react";
import Button, { ButtonPrimary, ButtonSecondary } from "../components/buttons/Buttons";
import { Input, Select } from "../components/forms/formFields";
import { Block, Ellipsis } from "../components/utilities/Helpers";
import IntrinsicRatio from "../components/utilities/IntrinsicRatio";
import { formatDate, formatDollars } from "../hooks/useFormats";
import Core from "../templates/Core";
import Patient from "../types/patient";
import { articleHeader, paragraph3 } from "../utilities/typography";
import CircularProgress from '@mui/material/CircularProgress';
import { PageHeader } from "../components/typography/Headers";
import { getAccountingClaimExcelFile, getCombinedReportExcelFile, getErrorReportExcelFile, getMaskedAccountingClaimExcelFile, getMismatchReportExcelFile, getSalesReportExcelFile, getUnBilledReportExcelFile } from "../apicalls/billingReportService";
import BlobFile from "../types/blobFile";
import moment from "moment";
import { useAppContext } from "../Context";

const BillingAnalyticsPage = props => {
    const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
    const reportRef = useRef() || { current: { value: ''}};
    const [selectedReport, setSelectedReport] = useState("");
    const [reportLoading, setReportLoading] = useState(false);
    const [reportAvailable, setReportAvailable] = useState(false);
    const [excelReport, setExcelReport] = useState<BlobFile>();
    const appContext = useAppContext();

    const clearReportInputs = async () => {
		setReportLoading(false);
		setReportAvailable(false);
		setStartDate('');
		setEndDate('');
		reportRef.current.value = "";
		setSelectedReport(reportRef.current.value);
		//setSelectedPatient('');
	}

    const generateExcelReport = async () => {
        switch (selectedReport) {
            case "Accounting":
                await getAccountingExcel();
                break;
            case "SalesReport":
                await getSalesReportExcel();
                break;
            case "ErrorReport":
                await getErrorReportExcel();
                break;
            case "CombinedReport":
                await getCombinedReportExcel();
                break;
            case "MismatchReport":
                await getMismatchReportExcel();
                break;
            case "UnBilledReport":
                await getUnBilledReportExcel();
                break;
            case "MaskedAccounting":
                await getMaskedAccountingExcel();
                break;
            default:
                break;
        }
    }

    const downloadExcelReport = () => {
        switch (selectedReport) {
            case "Accounting":
                downloadAccountingFile();
                break;
            case "SalesReport":
                downloadSalesReportFile();
                break;
            case "ErrorReport":
                downloadErrorReportFile();
                break;
            case "CombinedReport":
                downloadCombinedReportFile();
                break;
            case "MismatchReport":
                downloadMismatchReportFile();
                break;
            case "UnBilledReport":
                downloadUnbilledReportFile();
                break;
            case "MaskedAccounting":
                downloadMaskedAccountingFile();
                break;
            default:
                break;
        }
    }

    const getAccountingExcel = async () => {
		setReportLoading(true);
        setReportAvailable(false);

		const excelBlob = await getAccountingClaimExcelFile(startDate, endDate, appContext.handleAPICallError);

        setExcelReport(excelBlob);

        setReportLoading(false);
        setReportAvailable(true);
	}

    const getMaskedAccountingExcel = async () => {
		setReportLoading(true);
        setReportAvailable(false);

		const excelBlob = await getMaskedAccountingClaimExcelFile(startDate, endDate, appContext.handleAPICallError);

        setExcelReport(excelBlob);

        setReportLoading(false);
        setReportAvailable(true);
	}

    const downloadMaskedAccountingFile = () => {
        var fileName = "OAH_AccountingClaimSummary_" + moment().format("MMDDYY") + ".xlsx";

		const url = excelReport.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const downloadAccountingFile = () => {
        var fileName = "OAH_AccountingClaimSummary_" + moment().format("MMDDYY") + ".xlsx";

		const url = excelReport.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const getSalesReportExcel = async () => {
		setReportLoading(true);
        setReportAvailable(false);

		const excelBlob = await getSalesReportExcelFile(startDate, endDate, appContext.handleAPICallError);

        setExcelReport(excelBlob);

        setReportLoading(false);
        setReportAvailable(true);
	}

    const downloadSalesReportFile = () => {
        var fileName = "OAH_SalesReport_" + moment().format("MMDDYY") + ".xlsx";

		const url = excelReport.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const getErrorReportExcel = async () => {
		setReportLoading(true);
        setReportAvailable(false);

		const excelBlob = await getErrorReportExcelFile(startDate, endDate, appContext.handleAPICallError);

        setExcelReport(excelBlob);

        setReportLoading(false);
        setReportAvailable(true);
	}

    const downloadErrorReportFile = () => {
        var fileName = "OAH_ErrorReport_" + moment().format("MMDDYY") + ".xlsx";

		const url = excelReport.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const getCombinedReportExcel = async () => {
		setReportLoading(true);
        setReportAvailable(false);

		const excelBlob = await getCombinedReportExcelFile(startDate, endDate, appContext.handleAPICallError);

        setExcelReport(excelBlob);

        setReportLoading(false);
        setReportAvailable(true);
	}

    const downloadCombinedReportFile = () => {
        var fileName = "OAH_CombinedReport_" + moment().format("MMDDYY") + ".xlsx";

		const url = excelReport.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const getMismatchReportExcel = async () => {
		setReportLoading(true);
        setReportAvailable(false);

		const excelBlob = await getMismatchReportExcelFile(startDate, endDate, appContext.handleAPICallError);

        setExcelReport(excelBlob);

        setReportLoading(false);
        setReportAvailable(true);
	}

    const downloadMismatchReportFile = () => {
        var fileName = "OAH_ReimbMismatchReport_" + moment().format("MMDDYY") + ".xlsx";

		const url = excelReport.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    const getUnBilledReportExcel = async () => {
		setReportLoading(true);
        setReportAvailable(false);

		const excelBlob = await getUnBilledReportExcelFile(startDate, endDate, appContext.handleAPICallError);

        setExcelReport(excelBlob);

        setReportLoading(false);
        setReportAvailable(true);
	}

    const downloadUnbilledReportFile = () => {
        var fileName = "OAH_UnsubmittedClaimReport_" + moment().format("MMDDYY") + ".xlsx";

		const url = excelReport.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    return(<>
        {/* <Core { ...props }> */}
        <div className="tw-px-xl tw-py-xl">
                {/* <div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
                        <div className="tw-flex-grow tw-flex-shrink-0">
                            <PageHeader>
                                <Ellipsis>
                                    Billing Analytics
                                </Ellipsis>
                            </PageHeader>
                        </div>
                    </div> */}

                <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Billing Analytics</h2>
                </div>
            
            <ul className="tw-space-x-4 tw-flex tw-flex-wrap">
					<li className="tw-flex-grow">
						<Block>Report Type:</Block>
						<Select value="Selected Report" ref={ reportRef } onChange={ e => {
										console.log(e, e.target, e.target.value, selectedReport)
										setSelectedReport(reportRef?.current?.value);
										e.preventDefault();
									}}>
							<option value="">--Select Report--</option>
							<option value="Accounting">Accounting</option>
                            <option value="MaskedAccounting">Masked Accounting</option>
                            <option value="SalesReport">Sales Report</option>
                            <option value="ErrorReport">Error Report</option>
                            <option value="CombinedReport">Combined Report</option>
                            <option value="MismatchReport">Reimbursment Mismatch</option>
                            <option value="UnBilledReport">Unsubmitted Claims</option>
						</Select>
					</li>
					<li className="tw-flex-grow">
									<Block>Start Date:</Block>
                                    <Input
										className="tw-w-10/12"
										 min="2000-01-01" max="2024-12-31"
										type="date"
										value={ startDate } onChange={ e => {
										const temp = e.target.value || '';
										setStartDate( temp )
									} }/>
									{/* <Input type="tel" value={ startDate } onChange={ e => {
										const temp = e.target.value || '';
										setStartDate( formatDate(temp,'-').mmddyyyy )
									} }/> */}
					</li>
					<li className="tw-flex-grow">
									<Block>End Date:</Block>
                                    <Input
										className="tw-w-10/12"
										 min="2000-01-01" max="2024-12-31"
										type="date"
										value={ endDate } onChange={ e => {
										const temp = e.target.value || '';
										setEndDate( temp )
									} }/>
									{/* <Input type="tel" value={ endDate } onChange={ e => {
										const temp = e.target.value || '';
										setEndDate( formatDate(temp,'-').mmddyyyy )
									} }/> */}
					</li>
					<li>
						<ButtonPrimary className="tw-mt-md" onClick={generateExcelReport}>
							Generate
						</ButtonPrimary>
					</li>
					<li>
									<ButtonSecondary className="tw-mt-md" onClick={clearReportInputs}>
										Clear
									</ButtonSecondary>
					</li>
				</ul>

            {reportLoading ? <>
                <div className="tw-flex tw-justify-center tw-m-md">
					<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
			    </div>
            </>:<></>}
           
           {reportAvailable ? <>
                <div className="tw-flex tw-justify-center tw-m-md">
                            <ButtonPrimary className="tw-mt-md" onClick={downloadExcelReport}>
                                Download Excel
                            </ButtonPrimary>
                </div>
           </>:<></>}

            
        </div>
        {/* </Core> */}
    </>)

}

export default BillingAnalyticsPage;