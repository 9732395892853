import { Block } from "@medforall/medforall-component-library";
import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import AvatarNameInline from "../avatars/AvatarNameInline";
import { CircleLabel } from "../calendars/CalendarUtils";
import { LabelOutputs } from "../utilities/Helpers";
import ClientHome from "../../types/clientHome";
import { PatientDirectSupportTeam } from "../../types/patient";


const ClientDSTeamPanel = props => {
	const navContext = useAppContext();
	const [name, setName] = useState<any>(props.name);
	const [team, setCareTeam] = useState<PatientDirectSupportTeam>(props.team);

	useLayoutEffect(() => {
		//TODO: Identify "id" to pass to client  <ClientInfoPanel />
		if (props.name) setName(props.name)
	}, [props.name])

    useLayoutEffect(() => {
        if (props.team) setCareTeam(props.team)
    }, [props.team])

	useLayoutEffect(() => {
		// navContext.setSideNavTitle("")
	}, [])

	return <>
		<div className="tw-p-20px tw-space-y-15px tw-w-full">
			<div>
				<AvatarNameInline className="tw-text-3 tw-font-primary-bold tw-text-dark tw-flex tw-leading-none" space="tw-space-x-15px"  size="40" name={` ${name} `} />
			</div>
			<h3 className="tw-font-primary-bold tw-font-5 tw-pt-30px">
				<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
					<span className="tw-text-dark tw-ml-5px">Coordinator</span>
				</CircleLabel>
			</h3>
            <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                <li className="tw-flex tw-flex-col tw-space-y-5px">
                    <Block className="tw-pl-20px tw-ml-15px">
						<p className="tw-text-black hover:tw-text-primary tw-block">
							{team?.coordinatorName}
						</p>
					</Block>
                </li>
            </ul>

            <h3 className="tw-font-primary-bold tw-font-5 tw-pt-30px">
				<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
					<span className="tw-text-dark tw-ml-5px">Direct Support Providers</span>
				</CircleLabel>
			</h3>
            <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                {team?.providers.map((provider) => {
                    return <li className="tw-flex tw-flex-col tw-space-y-5px">
                    <Block className="tw-pl-20px tw-ml-15px">
						<p className="tw-text-black hover:tw-text-primary tw-block">
							{provider.providerName}
						</p>
					</Block>
                </li>
                }) } 
            </ul>
		</div>
	</>
}

export default ClientDSTeamPanel;