import { Dispatch, createContext, useContext, useEffect, useLayoutEffect, useRef, useState, useMemo } from "react";
import { Div, LabelOutputs, Status } from "../../components/utilities/Helpers";
import AvatarNameInline, { AvatarNameInlineList } from "../../components/avatars/AvatarNameInline";
import { isStarting, isPm, getHoursOrMinutes, isHalfHour, toNumberFromTime } from "../../components/calendars/DailyCalendar";
import { useAppContext } from "../../Context";
import CoverageDetailsPanel, { CoverageDetailsPanelSuccess } from "../../components/panels/CoverageDetailsPanel";
import ClientInfoPanel from "../../components/panels/ClientInfoPanel";
import SessionDetailsPanel from "../../components/panels/SessionDetail";
import SessionListPanel from "../../components/panels/SessionList";
import { useParams } from "react-router-dom";
import { getMonthName, getDayName, getNumOfDayOfWeek, getDaysInMonth, currentYear, currentDay, currentMonth } from "../../components/calendars/CalendarSelector";
import { articleHeader } from "../../utilities/typography";
import { SectionHeader } from "../../components/typography/Headers";
import { ButtonSecondary, DefaultButton } from "@medforall/medforall-component-library";
import Button from "../../components/buttons/Buttons";
import { RSABreadCumbs } from "./RemoteSupportLocations";
import { Label, Select } from "../../components/forms/formFields";
import { startWithZero } from "../../hooks/useFormats";
import { getSessionsForSpan } from "../../apicalls/schedulingService";
import moment from "moment";
import RemoteSupportSession from "../../types/remoteSupportSession";
import { getStationsForColumbus } from "../../apicalls/sessionService";
import RemoteSupportStation from "../../types/remoteSupportStation";
import { approximateToTwoDecimals } from "../Analytics/OperationAnalytics";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { getDayCoveragForMonth, getDayCoverage, getFirstQuadrantCoverage, getFourthQuadrantCoverage, getSecondQuadrantCoverage, getThirdQuadrantCoverage } from "./RSAScheduleUtils";

const CalendarContext = createContext({
	setType: (() => undefined) as Dispatch<any>,
	type: 'monthly',
	setActive: (() => undefined) as Dispatch<any>,
	active: false,
	top: 0,
	setTop: (() => undefined) as Dispatch<any>,
	right: 0,
	setRight: (() => undefined) as Dispatch<any>,
	offset: 0,
	setOffset: (() => undefined) as Dispatch<any>,
	timeframe: null,
	setTimeframe: (() => undefined) as Dispatch<any>
});

const useCalendarContext = () => useContext(CalendarContext);

const MonthTimeblock = props => {
	const calendarContext = useCalendarContext();
	const attributes = {
		...props,
		className: null,
		openness: null,
		children: null
	}

	return <>
		<div { ...attributes } role="button" title={`Saturday, April ${props.date}, 2023`} className={`${ props.className } tw-group o-layered tw-text-4 tw-aspect-square  tw-bg-light tw-min-w-60px tw-min-h-60px tw-relative ${ props.openness ? `before:tw-content-[var(--content)] before:tw-text-[#EF5700] before:tw-text-6 before:tw-leading-none  before:tw-absolute before:tw-right-10px  before:tw-top-10px ${props.openness.toString().replaceAll(' ','').toLowerCase() }` : ''} `} style={{'--content': props.openness ? `'${ props.openness }'` : ''}}>
			<div className="o-layered__layer tw-relative tw-z-1  || tw-text-[.75em] tw-text-dark tw-leading-none tw-aspect-square tw-w-full tw-h-full tw-items-start  tw-pt-25px tw-flex-col tw-space-y-3px tw-flex">
				<span className="tw-flex-grow  tw-w-full tw-space-y-3px tw-flex tw-flex-col tw-pt-5px tw-pb-10px tw-px-10px tw-min-height-50px tw-h-full slider group-hover:tw-overflow-y-clip tw-overflow-y-auto tw-overflow-x-visible tw-flex-shrink">
					{ props.children }
				</span>
			</div>
			<div className="o-layered__layer tw-relative tw-z-0 || tw-text-[.75em]  tw-text-dark tw-leading-none tw-aspect-square tw-text-5 tw-p-10px tw-w-full tw-h-full tw-items-start tw-justify-start tw-flex">
				<span className="date tw-font-primary-bold tw-text-5">
					{ props.date  }
				</span>
			</div>
		</div>
	</>
}

const MonthTimeblockItem = props => {
	const calendarContext = useCalendarContext();
	const [active, setActive] = useState<boolean>(false);
	const attributes = {
		...props,
		className: null,
		openness: null
	}


	return <>
		<div { ...attributes } role="button" title={`Saturday, April ${props.date}, 2023`} className={`${ props.className } tw-cursor-pointer monthtimeblockitem tw-w-full tw-border tw-border-light-dark/70 tw-rounded-6px tw-text-6 tw-relative tw-bg-white ${ props.openness ? `before:tw-content-[var(--content)] before:tw-text-[#EF5700] before:tw-text-6 before:tw-leading-none  before:tw-absolute before:tw-right-10px  before:tw-top-10px ${props.openness.toString().replaceAll(' ','').toLowerCase() }` : ''} `} style={{'--content': props.openness ? `'${ props.openness }'` : ''}}
		onClick={ e => {
			const target = e.target as HTMLElement;
			const bounds = target.getBoundingClientRect();
			setActive(true);
			e.preventDefault();
			calendarContext.setActive(true);
			calendarContext.setTop(bounds.top)
			calendarContext.setRight(bounds.right)
			calendarContext.setOffset(target.offsetWidth)
			console.log(target.offsetTop, target.offsetLeft, bounds.top, bounds.left, target.offsetWidth)
		}}>
			<span className="tw-px-4px tw-py-4px tw-block tw-w-full tw-text-[.9em] tw-pointer-events-none">
				<SectionHeader tag="h4" childClasses="tw-flex tw-items-center  tw-text-6 tw-leading-none">
					<Status className={`tw-text-primary ${ props.open ? 'status' : ''}`}>Active</Status>
					<span className="tw-text-[.8em] tw-text-dark/70 tw-leading-none">
						{ props.children || "8 AM - 2 PM"}
					</span>
				</SectionHeader>
			</span>
		</div>
	</>
}

const MonthlyDetailsModal = props => {
	const calendarContext = useCalendarContext();
	const [active, setActive] = useState<boolean>(false);
	const [right, setRight] = useState<any>(0);
	const [top, setTop] = useState<any>(0);
	const [offset, setOffset] = useState<any>(0);

	useLayoutEffect(() => {
		if (calendarContext.right) setRight(calendarContext.right);
		if (calendarContext.top) setTop(calendarContext.top);
		if (calendarContext.offset) setOffset(calendarContext.offset);
		if (calendarContext.active) setActive(calendarContext.active);
	}, [calendarContext.right, calendarContext.top, calendarContext.offset])


	return <>
		{ active ? <>
			<Div className="tw-inline-flex tw-absolute tw-translate-x-full tw-z-10 tw-left-[calc(var(--right)*1px)] tw-top-[calc((var(--top)*1px)-1.25rem)] tw-ml-[calc(var(--offset)*-1.5px)]" style={{'--right': right, '--top': top, '--offset': offset}}>

				<div className="tw-bg-white tw-border-2px tw-rounded-6px  tw-border-dark/50 tw-min-w-200px tw-relative tw-z-1 tw-flex tw-flex-col">
					<img src="/images/graphic-modal-arrow.svg" className="tw-absolute tw-top-20px tw-left-4px -tw-translate-x-full"/>
					<div className="tw-flex tw-justify-between tw-pb-5px ">
						<h5 className="tw-font-primary tw-font-primary-bold tw-text-dark tw-leading-none tw-text-6 tw-flex-shrink-0 tw-leading-none tw-px-20px tw-pl-30px tw-flex tw-flex-grow tw-items-center">
							<span className="tw-top-3px tw-relative">Session Details</span>
						</h5>
						<Button onClick={ (e) => {
							e.preventDefault();
							setActive(false);
							calendarContext.setActive(false);
						} } className="tw-ml-auto tw-flex tw-items-center tw-justify-center tw-pt-10px tw-flex-shrink-0 tw-w-[3.375em] tw-border-l tw-border-[rgba(0,0,0,.05)] hover:tw-border-white/10 hover:tw-bg-dark/10" childClasses="tw-flex tw-items-center">
							<span className="tw-speak-none tw-w-[0.875em] tw-h-[0.875em] || tw-inline-flex tw-items-center tw-justify-center">

								<img className="tw-inline-flex tw-w-full tw-h-full tw-object-contain tw-border-0" src={`/images/icon-close-black.svg`} />
							</span>
							<span className="tw-hidden">Close</span>
						</Button>
					</div>

					<div className="tw-flex-shrink-0 tw-py-5px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5 tw-px-8px">
						<div className="tw-flex tw-items-center tw-flex-grow tw-justify-between tw-space-x-3px">
							<AvatarNameInline size="16" image="/images/icon-providers.svg">
								<span className="tw-w-full tw-flex tw-flex-col tw-text-6">
									<span className="tw-w-full tw-text-[.9em] tw-text-black">
										Providers
									</span>
								</span>
							</AvatarNameInline>
						</div>
					</div>

					<div className="tw-flex-grow tw-h-full tw-max-h-150px">
						<div className="tw-h-full tw-w-full tw-overflow-y-auto">
							<div className="tw-flex tw-flex-col tw-divide-y-1px tw-divide-black/20 tw-border-b-1px tw-border-b-black/20">
								<div className="tw-flex tw-flex-col tw-relative tw-overflow-y-auto tw-divide-y-1px tw-divide-black/20 tw-snap-y slider tw-px-8px">
									{ [...Array(25)].map( ( day, index ) => {
										return <>
											<div className="tw-snap-center tw-flex-shrink-0 tw-py-5px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5">
												<div className="tw-flex tw-items-center tw-flex-grow tw-justify-between tw-space-x-3px">
													<AvatarNameInline size="16">
														<span className="tw-w-full tw-flex tw-flex-col tw-text-6">
															<span className="tw-w-full tw-text-[.9em] tw-text-dark">
																John Mogan
															</span>
														</span>
													</AvatarNameInline>
													<span className="tw-text-6 tw-flex-shrink-0">
														<span className="tw-w-full tw-text-[.9em] tw-text-black/40">
															1200 - 16:00
														</span>
													</span>
												</div>
											</div>
										</>
									}) }
								</div>
								{/* <div className="tw-w-full tw-flex tw-flex-shrink-0">
									<DefaultButton className="tw-w-full tw-text-6 tw-min-h-30px tw-mt-2px tw-text-dark hover:tw-text-primary">
										View More
									</DefaultButton>
								</div> */}
							</div>
						</div>
					</div>
					<div className="tw-flex tw-justify-end tw-mt-auto tw-flex-shrink-0 tw-items-center">
						<a href="/schedule/rsa/daily" className="tw-text-primary tw-inline-flex tw-items-center hover:tw-text-black tw-min-h-30px tw-inline-flex tw-font-primary-bold  tw-px-20px  tw-text-6 tw-leading-none">
							View details
						</a>
					</div>
				</div>
			</Div>
		</> : <></> }
	</>
}


const MonthlyCalendar = props => {
	const calendarContext = useCalendarContext();
	const [month, setMonth] = useState(props.monthValue);
	const [year, setYear] = useState(props.yearValue);
	const [daysOfMonth, setDaysOfMonth] = useState<any>(props.daysInMonth || '');
	const [firstDayOfMonth, setFirstDayOfMonth] = useState<any>('');

	const [monthValue, setMonthValue] = useState<any>(startWithZero(currentMonth));
	const [monthName, setMonthName] = useState(getMonthName());
	const [dayNameValue, setdayNameValue] = useState<any>('');
	const [daysInMonth, setDaysInMonth] = useState<any>(startWithZero(currentDay));
	const [dayValue, setDayValue] = useState<any>(startWithZero(currentDay));
	const [yearValue, setYearValue] = useState<any>(currentYear);

	// const [type, setType] = useState<string>('monthly');
	// const [active, setActive] = useState<boolean>(false);
	// const [right, setRight] = useState<any>(0);
	// const [top, setTop] = useState<any>(0);
	// const [offset, setOffset] = useState<any>(0);

	useLayoutEffect(() => {
		if (monthValue && yearValue) {
			setFirstDayOfMonth( getNumOfDayOfWeek(getMonthName(Number(monthValue - 1)), "1", yearValue)  );
			setDaysOfMonth( getDaysInMonth(monthValue.toString(), yearValue.toString()) )
		}
	}, [monthValue, yearValue])


	useLayoutEffect(() => {
		if (props.month && props.year) {
			setMonthValue(props.month);
			setYearValue(props.year);
			calendarContext.setTimeframe(getMonthName(props.month - 1));
		}
	}, [props.month, props.year])

	function statusForDay(day: string): any {
		if (props?.days) {
			var dayCoverage = props?.days.find(value => value.date === day);
			return dayCoverage ? dayCoverage.openness : "";
		} else {
			return "Fully Open";
		}
	}

	function sessionsForDay(day: string): any {
		if(props?.days){
			var dayCoverage = props?.days.find(value => value.date === day);
			return dayCoverage ? dayCoverage.sessions : [];
		} else {
			return [];
		}
	}

	return <>
			<div className="tw-relative">
				<div className="tw-sticky tw-top-0 tw-grid tw-grid-cols-7 tw-z-3 tw-bg-white tw-border-b-2px tw-border-b-light-accent tw-text-dark/70 tw-divide-x tw-divide-dark/20">
					<span className="tw-flex tw-items-center tw-justify-center tw-py-5px" title="Sunday">
						Sunday
					</span>
					<span className="tw-flex tw-items-center tw-justify-center tw-py-5px" title="Monday">
						Monday
					</span>
					<span className="tw-flex tw-items-center tw-justify-center tw-py-5px" title="Tuesday">
						Tuesday
					</span>
					<span className="tw-flex tw-items-center tw-justify-center tw-py-5px" title="Wednesday">
						Wednesday
					</span>
					<span className="tw-flex tw-items-center tw-justify-center tw-py-5px" title="Thursday">
						Thursday
					</span>
					<span className="tw-flex tw-items-center tw-justify-center tw-py-5px" title="Friday">
						Friday
					</span>
					<span className="tw-flex tw-items-center tw-justify-center tw-py-5px" title="Saturday">
						Saturday
					</span>
				</div>

				<Div className=" || tw-grid tw-grid-cols-7 tw-min-h-px-rem tw-divide-y-1px tw-divide-x-1px tw-divide-black/20" style={{'--px': 180 }}>
					{ [...Array(firstDayOfMonth)].map((item, index) => <div key={ 'grid_day-block-' + index } className={`${ index === 0 ? 'tw-border-l-1px tw-border-l-black/20 tw-aspect-square' : '' } tw-aspect-square tw-bg-light tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-w-60px tw-min-h-60px`}></div> ) }
					{ [...Array(daysOfMonth)].map((item, index) => {
						return <>
							<MonthTimeblock date={ index + 1 } openness={ props?.days ? statusForDay(`${monthValue}-${startWithZero(index+1)}-${yearValue}`) : "" }>
									{/* <MonthTimeblockItem>
										{monthValue}-{startWithZero(index+1)}-{yearValue}
									</MonthTimeblockItem> */}
									{ props?.days ? sessionsForDay(`${monthValue}-${startWithZero(index+1)}-${yearValue}`).map((session, index) => {
										return <>
											<MonthTimeblockItem>
												{session.displayStartTime}-{session.displayEndTime}
											</MonthTimeblockItem>
										</>
									}) : <></> }
								{/* { (index === 2 || index === 12) && <>

									<MonthTimeblockItem>
										12:00 - 2:00pm
									</MonthTimeblockItem>
									<MonthTimeblockItem open={ index === 12 ? true : null }>
										12:00 - 2:00pm
									</MonthTimeblockItem>

									<MonthTimeblockItem>
										12:00 - 2:00pm
									</MonthTimeblockItem>
									<MonthTimeblockItem open={ index === 12 ? true : null }>
										12:00 - 2:00pm
									</MonthTimeblockItem>

								</> } */}
							</MonthTimeblock>
						</>
					}) }
				</Div>
			</div>
	</>
}

const WeeklyCalendarContainer = props => {
	const [hours, setHours] = useState<Number>(24);
	const attributes = {
		...props,
		className: null
	}

	return <>
		<div { ...attributes } className={`${ props.className }`}>
			<div className={`tw-flex tw-w-full tw-flex-grow`}>
				<div className="tw-flex-shrink-0 tw-h-full tw-flex tw-flex-col tw-flex-shrink tw-w-60px tw-px-10px tw-bg-white tw-border-r-4px tw-border-r-dark/20 tw-text-6">
					<div className="tw-text-center tw-h-50px tw-text-center tw-border-t-5px tw-border-t-transparent tw-flex tw-items-center tw-justify-center  tw-flex-shrink-0">
						<span className="tw-uppercase tw-text-dark/70">
							Time
						</span>
					</div>
					{ [...Array(25)].map((item, index) => {
						return <>
							<div className={`${ props.weekly ? 'tw-h-60px' : 'tw-h-30px' } tw-flex tw-w-full tw-items-start tw-flex-shrink-0 tw-leading-none`}>
								{`${ index < 10 ? '0' : '' }${index}:00`}
							</div>
							{ index < 24 && <>
								<div className={`${ props.weekly ? 'tw-h-60px' : 'tw-h-30px' } tw-flex tw-w-full tw-items-start tw-flex-shrink-0 tw-leading-none`}>
									{`${ index < 10 ? '0' : '' }${index}:30`}
								</div>
							</> }
						</>
					}) }
				</div>
				<Div className="tw-flex-grow tw-grid tw-grid-cols-dynamic tw-divide-x-1px tw-divide-dark/10" style={{'--cols': (props.cols || 5)}}>
					{ props.children }
				</Div>
			</div>
		</div>
	</>
}

function removeWhitespaceAndLowercase(str: string): string {

	// Remove whitespace using regex
	const stripped = str.replace(/\s/g, '');
  
	// Lowercase the string
	const lowercased = stripped.toLowerCase();
  
	return lowercased;
  
}

const WeeklyCalendarDayContainer = props => {
	const [hours, setHours] = useState<Number>(24);
	const attributes = {
		...props,
		className: null
	}
	const [coverageStatus, setCoverageStatus] = useState<string>(null);
	const [color, setColor] = useState<string>("tw-text-primary");

	useLayoutEffect(() => {
		if (props.status) {
			setCoverageStatus(props.status);
		}
		if (props.status) {
			if (props.status === "partial") {
				setColor('tw-text-warning');
			} else if (props.status === "full") {
				setColor('tw-text-primary');
			} else {
				setColor('tw-text-error');
			}
		}
	}, [props.status])

	return <>
		<div { ...attributes } className={`tw-flex-grow tw-flex-col ${ props.className }`}>
			{ props.color ? <>
				<Div className={`tw-sticky tw-top-0 tw-z-3 tw-bg-light-accent tw-text-center tw-h-50px tw-text-center tw-border-t-5px tw-border-t-borderColor tw-flex tw-items-center tw-justify-center`} style={{'--borderColor': props.color, 'border-top-color': props.color }}>
					{ props.title }
				</Div>
			</> : <>
				<Div className="tw-sticky tw-top-0 tw-z-3 tw-bg-white tw-border-b-1px tw-border-b-light-accent tw-text-center tw-h-50px tw-text-center tw-flex tw-items-center tw-justify-center">
					<span className="tw-w-full tw-flex tw-flex-col tw-items-start tw-space-y-3px tw-pt-5px tw-px-10px">
						<span className="tw-uppercase tw-text-6 tw-tracking-tight tw-leading-none tw-text-dark-accent">
							{ props.dayOfWeek }
						</span>
						<span className="tw-text-dark tw-flex tw-space-x-5px tw-items-baseline tw-justify-between tw-w-full">
							<span className="tw-uppercase tw-text-1 tw-font-primary tw-font-primary-bold tw-tracking-tight tw-leading-[.8] tw-flex tw-items-end">
								<span className="tw-text-[.75em]">
									{ props.date }
								</span>
							</span>
							<div className="tw-ml-auto tw-items-center tw-flex tw-space-x-5px">
								<img src={`/images/icon-pie-${coverageStatus}.svg`} alt="partial pie" />
								<span className={`tw-text-6 ${color}`}>
									<span className="tw-text-[.9em]">
										{props?.coverage}%
									</span>
								</span>
							</div>
						</span>
					</span>
				</Div>
			</>}
			<div className="o-layered tw-w-full tw-flex-grow">
				<div className="o-layered__layer tw-h-full tw-overflow-y-visible">
					<Div children={ props.children } className={`${props.weeklyView ? "tw-h-2880px": "tw-h-1440px"} tw-w-full tw-grid tw-grid-cols-1 tw-relative tw-z-2`} style={{ gridTemplateRows: `repeat(${Number(hours) * (props.weeklyView ? 8 : 4)}, minmax(0px, 1fr))` }} />
				</div>
				<div className="o-layered__layer || tw-divide-y tw-divide-dark/10 || tw-w-full">
					{ [...Array(hours)].map((item, index) => {
						return <div key={ 'grid_bg-' + index } className="tw-h-60px">{` `}</div>
					}) }
				</div>
			</div>
		</div>
	</>
}


export const Timeblock = props => {
	const [offsetHours, setOffsetHours] = useState<Number>(0);
	const [start, setStart] = useState<Number>(0);
	const [weeklyView, setWeeklyView] = useState<boolean>(false);
	const [end, setEnd] = useState(1);
	const [color, setColor] = useState<string>(null);
	const TimeBlockRef = useRef(null);
	const Tag = props.tag || "div";

	const handleOnclick = (e) => {
		e.preventDefault();
		if (props.onClick) props.onClick();
	}

	useLayoutEffect(() => {
		if (props.start) {
			const startHours = Number(getHoursOrMinutes(props.start)[0]);
			const startMinutes = isHalfHour(Number(toNumberFromTime(getHoursOrMinutes(props.start)[1])));
			const startTemp = toNumberFromTime(Number(startHours) + startMinutes);
			setStart( Number( (isPm(props.start) && !(isStarting(startHours))) ? (startTemp + 12) : (isStarting(startHours) && !isPm(props.start) ? 0 : startTemp) ) )
		}

		if (props.end) {
			const endHours = Number(getHoursOrMinutes(props.end)[0]);
			const endMinutes = isHalfHour(Number(toNumberFromTime(getHoursOrMinutes(props.end)[1])));
			const endTemp = toNumberFromTime(Number(endHours) + endMinutes);
			setEnd( Number( (props.end === '12:00am') ? 24 : (isPm(props.end) && !(isStarting(endHours))) ? (endTemp + 12) : (isStarting(endHours) && !isPm(props.end) ? 0 : endTemp) ) )
		}

	}, [props.start, props.ends]);

	useLayoutEffect(() => {
		if (props.weekly) {
			setWeeklyView(true);

			if (props.coverage) {
				if (props.coverage === "Partial") {
					setColor('var(--color-warning)');
				} else if (props.coverage === "Full") {
					setColor('#12B4B9');
				} else {
					setColor('var(--color-error)');
				}
			}
		}
	}, [props.weekly, props.coverage])

	return <>
		<Div onClick={ handleOnclick } ref={ TimeBlockRef } title={`${ props.children || props.name }: ${ props.start }-${ props.end }`} className={`${ weeklyView ? '' : ''} tw-bg-white tw-flex tw-flex-col tw-text-5 tw-border-b-dark/20 grid-row-vars tw-relative ${ props.className } ${ props.onClick && ' tw-cursor-pointer' }`} style={{'--start': (((Number(start) - Number(offsetHours)) * ( weeklyView ? 8 : 4 )) + 1), '--end': (((Number(end) - Number(offsetHours)) * ( weeklyView ? 8 : 4 )) + 1)}}>
			<Div className={`${ weeklyView ? 'tw-border-t-5px tw-border-t-[var(--borderColor)] ' : ''} tw-w-full tw-font-primary tw-font-primary-light tw-px-10px tw-pt-10px`}  style={{'--borderColor': color ? color : props.color ? `${ props.color }` : "#000", 'border-top-color': color ? color : props.color ? `${ props.color }` : "#000"}}>
				<span className="tw-text-[.75em] tw-text-dark/70">
					{ props.start } - { props.end }
				</span>
			</Div>
			<div className="tw-w-full tw-px-10px">

				{ weeklyView ? <>
						<b>{ props.count || 0}</b> sessions
				</> : <></> }

				<span className={`tw-text-[.75em] tw-block tw-w-full ${weeklyView ? 'tw-text-dark/50' : ''}`}>
					<b>
						{ props.children }
					</b>
				</span>
			</div>
			<div className="tw-mt-auto tw-flex tw-justify-end tw-w-full tw-px-10px tw-pb-10px tw-border-b-2px tw-border-b-light-accent tw-absolute tw-right-0 tw-bottom-0">
				{ weeklyView ? <>
					<AvatarNameInlineList size="24" />
				</> : <>
					<AvatarNameInline className="tw-text-6 tw-text-light-dark/50" size="24" space="tw-space-x-5px" image={ null } />
				</>}
			</div>
		</Div>
	</>
}

const CalendarWrapper = props => {
	const calendarContext = useCalendarContext();
	const navContext = useAppContext();
	let { calendarType } = useParams();
	const [type, setType] = useState<string>('monthly');
	const [active, setActive] = useState<boolean>(false);
	const [right, setRight] = useState<any>(0);
	const [top, setTop] = useState<any>(0);
	const [offset, setOffset] = useState<any>(0);
	const [timeframe, setTimeframe] = useState<any>(props?.timeHeader)
	const value = useMemo(() => ({
		type, setType,
		active, setActive,
		top, setTop,
		right, setRight,
		offset, setOffset,
		timeframe, setTimeframe
	}), [right, top, offset, active, timeframe]);

	const handleAddNew = (e) => {
		if (e) e.preventDefault();

		// if (type === 'daily' || type === 'weekly') {
			navContext.setSideNav( <SessionDetailsPanel type={ type } editing={ true } /> );
			navContext.setSideNavTitle("Session Details");
			navContext.openSideNav();
			navContext.setSideNavActions({
				"ctas": [{
					"name": 'Discard',
					onClick: () => {
						navContext.closeSideNav();
					}
				}, {
					"name": 'Save',
					onClick: () => {
						alert("Success!")
						navContext.closeSideNav();
					}
				}]
			});
		// }
	}

	useLayoutEffect(() => {
		if (calendarType) {
			setType(calendarType)
			calendarContext.setType(calendarType);
		}
	}, [calendarType])

	useEffect(() => {
		if (props.timeHeader) {
			setTimeframe(props.timeHeader);
			console.log("Props Header: " + props.timeHeader);
		}
	}, [props.timeHeader])

	useEffect(() => {
		console.log("Type: " + type);
		console.log("Timeframe: " + timeframe);
		if (type === "daily") {
			setTimeframe(props.timeHeader || 'April 11');
		} else if (type === "weekly") {
			setTimeframe(props.timeHeader || 'April 11 - April 17');
		} else {
			setTimeframe(props.timeHeader || 'April 2023');
		}
	}, [type, timeframe])


	return <>
		<CalendarContext.Provider value={ value }>
			<MonthlyDetailsModal />
			<div className="tw-w-full tw-flex tw-flex-col tw-overflow-hidden tw-h-vh tw-px-40px">
				<div className="tw-py-20px tw-flex-shrink-0 tw-space-y-15px">
					<RSABreadCumbs />
					<div className="tw-relative">
						<div className="tw-max-w-300px">
							<Label for="locations" icon="/images/icon-location.svg" className="tw-z-0 tw-absolute tw-left-15px tw-top-12px  tw-items-center">
								<span className="tw-hidden">Locations</span>
							</Label>
							<Select id="locations" className="!tw-bg-transparent tw-relative tw-z-2 !tw-border-dark" childClasses="!tw-pl-40px !tw-bg-transparent !tw-border-dark">
								<option>Columbus</option>
							</Select>
						</div>
					</div>
				</div>
				<div className={`tw-bg-black/10 tw-overflow-hidden tw-flex-grow tw-flex tw-flex-col tw-rounded-3px ${ type !== 'monthly' ? '' : '' }`}>
					<div className="tw-space-x-5px tw-w-full tw-flex-shrink-0  tw-py-15px tw-px-20px tw-bg-white/10 tw-flex tw-justify-between tw-space-x-15px tw-text-5">
						<div className="tw-flex tw-space-x-5px tw-items-center tw-w-320px">
							<ButtonSecondary className={`tw-min-h-40px tw-w-100px tw-inline-flex tw-text-center tw-justify-center ${ type == 'monthly' ? `!tw-bg-primary !tw-text-white tw-pointer-events-none` : ''}`} href="/schedule/session-calendar/monthly">
								Month
							</ButtonSecondary>
							<ButtonSecondary className={`tw-min-h-40px tw-w-100px tw-inline-flex tw-text-center tw-justify-center ${ type == 'weekly' ? `!tw-bg-primary !tw-text-white tw-pointer-events-none` : ''}`} href="/schedule/session-calendar/weekly">
								Week
							</ButtonSecondary>
							<ButtonSecondary className={`tw-min-h-40px tw-w-100px tw-inline-flex tw-text-center tw-justify-center ${ type == 'daily' ? `!tw-bg-primary !tw-text-white tw-pointer-events-none` : ''}`} href="/schedule/session-calendar/daily">
								Day
							</ButtonSecondary>
						</div>
						<div className="tw-items-center tw-flex tw-space-x-10px">
							<DefaultButton className="tw-min-h-40px tw-min-w-40px tw-bg-white tw-text-dark hover:tw-bg-primary tw-group tw-rounded-6px" onClick={props?.prevTimePeriod}>
								<LabelOutputs className="group-hover:tw-text-black group-hover:tw-invert tw-max-w-30px" icon="/images/icon-arrow-left.svg" size="6">
									<span className="tw-hidden">Previous</span>
								</LabelOutputs>
							</DefaultButton>
							<DefaultButton className="tw-min-h-40px tw-bg-white tw-text-dark hover:tw-bg-primary tw-group tw-rounded-6px">
								<LabelOutputs className="group-hover:tw-text-black group-hover:tw-invert tw-font-primary-bold  tw-px-10px tw-whitespace-nowrap">
									<span className="tw-leading-none tw-min-w-200px tw-inline-flex tw-items-center tw-justify-center">
										{ timeframe }
									</span>
								</LabelOutputs>
							</DefaultButton>
							<DefaultButton className="tw-min-h-40px tw-min-w-40px tw-bg-white tw-text-dark hover:tw-bg-primary tw-group tw-rounded-6px" onClick={props?.nextTimePeriod}>
								<LabelOutputs className="group-hover:tw-text-black group-hover:tw-invert tw-max-w-30px tw-rotate-180" icon="/images/icon-arrow-left.svg" size="6">
									<span className="tw-hidden">Next</span>
								</LabelOutputs>
							</DefaultButton>
						</div>
						<div className="tw-items-center tw-justify-end tw-flex tw-w-320px">
							<DefaultButton className="tw-min-h-40px tw-bg-white tw-text-dark hover:tw-bg-primary tw-group tw-rounded-6px" onClick={ e => handleAddNew(e) }>
								<LabelOutputs className="group-hover:tw-text-black group-hover:tw-invert" icon="/images/icon-add-new.svg">Add New</LabelOutputs>
							</DefaultButton>
						</div>
					</div>
					<div className="tw-flex tw-items-center tw-space-x-40px tw-px-20px tw-pb-15px">
						<AvatarNameInlineList size="24">
							Active RSA (12)
						</AvatarNameInlineList>
						<AvatarNameInlineList size="24">
							Inactive RSA (2)
						</AvatarNameInlineList>
					</div>
					<div className="tw-flex-grow tw-w-full tw-flex tw-flex-col tw-overflow-y-auto tw-px-20px slider tw-border-t tw-border-t-dark/20">
						<div className="tw-w-full tw-pt-30px">
							{ props.children }
						</div>
					</div>
				</div>
			</div>
		</CalendarContext.Provider>
	</>
}


const RSASchedulingPage = props => {
	const calendarContext = useCalendarContext();
	const navContext = useAppContext();
	const [hours, setHours] = useState<Number>(24);
	let { calendarType } = useParams();
	const [type, setType] = useState<any>(null);
	const [daySessions, setDaySessions] = useState<RemoteSupportSession[]>([]);
	const [weekSessions, setWeekSessions] = useState<RemoteSupportSession[]>([]);
	const [monthSessions, setMonthSessions] = useState<RemoteSupportSession[]>([]);
	const [supportStations, setSupportStations] = useState<RemoteSupportStation[]>([]);
	const [currentDate, setCurrentDate] = useState<any>(moment().format('MM-DD-YYYY'));
	const [currentWeekStart, setCurrentWeekStart] = useState<any>(moment().startOf('week').format('MM-DD-YYYY'));
	const [currentWeekEnd, setCurrentWeekEnd] = useState<any>(moment().endOf('week').format('MM-DD-YYYY'));
	const [currentHeader, setCurrentHeader] = useState<any>(moment().format('MMMM DD'));
	const [weekDays, setWeekDays] = useState<any>([]);
	const [monthDays, setMonthDays] = useState<any>([]);
	const [currentMonth, setCurrentMonth] = useState<any>(moment().format('MM'));
	const [currentYear, setCurrentYear] = useState<any>(moment().format('YYYY'));
	const [scheduleLoading, setScheduleLoading] = useState<boolean>(false);

	const mapWeekSessions = (sessions: RemoteSupportSession[], startOfWeek: moment.Moment) => {
		const days = [];
		console.log("Start of Week: " + startOfWeek.format('MM-DD-YYYY'));
		console.log("Sessions: " + sessions);
		console.log("Sessions Length: " + (!sessions || sessions.length === 0));

		if(!sessions || sessions.length === 0){
			for (var i = 0; i < 7; i++) {
				days.push({
					"date": moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY'),
					"day": moment(startOfWeek).add(i, 'days').format('dddd'),
					"dayNumber": moment(startOfWeek).add(i, 'days').format('DD'),
					"status": "open",
					"coverage": "0",
					"firstQuadrantCoverage": {
						"status": "open",
						"coveragePct": "0",
						"shifts": [],
					},
					"secondQuadrantCoverage": {
						"status": "open",
						"coveragePct": "0",
						"shifts": [],
					},
					"thirdQuadrantCoverage": {
						"status": "open",
						"coveragePct": "0",
						"shifts": [],
					},
					"fourthQuadrantCoverage": {
						"status": "open",
						"coveragePct": "0",
						"shifts": [],
					},
				});

				
			}
			setWeekDays(days);
		} else {
			for (var i = 0; i < 7; i++) {
				//console.log(moment().startOf('week').add(i, 'days').format('MM-DD-YYYY'));
				var sessionsForDate = sessions.filter(session => session.displayStartDate === moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY') || session.displayEndDate === moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY'));
				//console.log(sessionsForDate);
				var coverageStatus = getDayCoverage(sessionsForDate, moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY'));
				//console.log("Day Status: " + coverageStatus.status);
				//console.log("Day Coverage: " + coverageStatus.coveragePct);
				var firstQuadrantCoverage = getFirstQuadrantCoverage(sessionsForDate, moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY'));
				// console.log("First Quadrant Coverage: " + firstQuadrantCoverage.status);
				// console.log("First Quadrant Coverage: " + firstQuadrantCoverage.coveragePct);
				// console.log(firstQuadrantCoverage.shifts);
				var secondQuadrantCoverage = getSecondQuadrantCoverage(sessionsForDate, moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY'));
				var thirdQuadrantCoverage = getThirdQuadrantCoverage(sessionsForDate, moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY'));
				var fourthQuadrantCoverage = getFourthQuadrantCoverage(sessionsForDate, moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY'));
				days.push({
					"date": moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY'),
					"day": moment(startOfWeek).add(i, 'days').format('dddd'),
					"dayNumber": moment(startOfWeek).add(i, 'days').format('DD'),
					"status": coverageStatus.status,
					"coverage": coverageStatus.coveragePct,
					"firstQuadrantCoverage": firstQuadrantCoverage,
					"secondQuadrantCoverage": secondQuadrantCoverage,
					"thirdQuadrantCoverage": thirdQuadrantCoverage,
					"fourthQuadrantCoverage": fourthQuadrantCoverage,
				});
			}

			setWeekDays(days);
		}

		console.log(days);
	}

	const mapMonthSessions = (sessions: RemoteSupportSession[], month: any, year: any) => {
		// Create a moment for the start of the month
		const start = moment().month(month).date(1).year(year);

		// Create a moment for the end of the month  
		const end = moment().month(month).endOf('month').year(year); 

		const days = [];

		console.log("Start of Month: " + start.format('MM-DD-YYYY'));
		console.log("End of Month: " + end.format('MM-DD-YYYY'));

		if(!sessions || sessions.length === 0){
			for(var i=0; i <= end.date(); i++){
				days.push({
					"date": moment(start).add(i, 'days').format('MM-DD-YYYY'),
					"day": moment(start).add(i, 'days').format('dddd'),
					"dayNumber": moment(start).add(i, 'days').format('DD'),
					"status": "open",
					"coverage": "0",
					"openness": "Fully Open",
					"sessions": [],
				});
			}
			setMonthDays(days);
		} else {
			for(var i=0; i < end.date(); i++){
				var sessionsForDate = sessions.filter(session => session.displayStartDate === moment(start).add(i, 'days').format('MM-DD-YYYY') || session.displayEndDate === moment(start).add(i, 'days').format('MM-DD-YYYY'));
				var coverageStatus = getDayCoveragForMonth(sessionsForDate, moment(start).add(i, 'days').format('MM-DD-YYYY'));

				days.push({
					"date": moment(start).add(i, 'days').format('MM-DD-YYYY'),
					"day": moment(start).add(i, 'days').format('dddd'),
					"dayNumber": moment(start).add(i, 'days').format('DD'),
					"status": coverageStatus.status,
					"coverage": coverageStatus.coveragePct,
					"sessions": sessionsForDate,
					"openness": coverageStatus.openDuration,
				});
			}
			setMonthDays(days);
		}

		console.log(days);


	}
	

	useLayoutEffect(() => {
		const fetchLocationStations = async () => {
			const stations = await getStationsForColumbus(navContext.handleAPICallError);
			console.log(stations.sort((a, b) => {
				const aName = a.stationName.split(' ')[1];
				const bName = b.stationName.split(' ')[1];
				return parseInt(aName) - parseInt(bName);
			  }));
			setSupportStations(stations.sort((a, b) => {
				const aName = a.stationName.split(' ')[1];
				const bName = b.stationName.split(' ')[1];
				return parseInt(aName) - parseInt(bName);
			  }));
		}

		fetchLocationStations();
	}, [])

	useLayoutEffect(() => {
		console.log(calendarType);

		if (calendarType) {
			setType(calendarType)
			calendarContext.setType(calendarType);
		}

		const fetchSessionsForDate = async (date) => {
			//setSessionLoading(true);
			setScheduleLoading(true);

			const sessions = await getSessionsForSpan(date, date, navContext.handleAPICallError)

			// sessions.forEach(async (session, index, arr) => {
			// 	var sessionByID = await getRemoteSupportSessionByID(session.remoteSupportSessionID, navContext.handleAPICallError);
			// 	arr[index].assignedShifts = sessionByID.assignedShifts;
			// });

			console.log(sessions);

			if(sessions !== null){
				console.log(sessions);
				setDaySessions(sessions);
				
			}

			setScheduleLoading(false);

			// setSessionLoading(false);
		}

		const fetchSessionsForWeek = async (start, end) => {
			//setSessionLoading(true);
			setScheduleLoading(true);

			const sessions = await getSessionsForSpan(start, end, navContext.handleAPICallError)

			console.log(sessions);

			mapWeekSessions(sessions, moment().startOf('week'));

			if(sessions !== null){
				console.log(sessions);
				setWeekSessions(sessions);
			}

			setScheduleLoading(false);
		}

		const fetchSessionsForMonth = async (month, year) => {
			setScheduleLoading(true);

			// Create a moment for the start of the month
			const start = moment().month(month).date(1).year(year);

			// Create a moment for the end of the month  
			const end = moment().month(month).endOf('month').year(year); 

			// console.log(start.format('MM-DD-YYYY'));
			// console.log(end.format('MM-DD-YYYY'));

			const sessions = await getSessionsForSpan(start.format('MM-DD-YYYY'), end.format('MM-DD-YYYY'), navContext.handleAPICallError)

			console.log(sessions);

			mapMonthSessions(sessions, month, year);

			if(sessions !== null){
				console.log(sessions);
				setMonthSessions(sessions);
			}

			setScheduleLoading(false);
		}


		if(calendarType === 'daily'){
			var today = moment().format('MM-DD-YYYY');
			console.log(today);
			setCurrentHeader(moment().format('MMMM DD'));

			fetchSessionsForDate(today);
		}

		if(calendarType === 'weekly'){
			var weekStart = moment().startOf('week').format('MM-DD-YYYY');
			var weekEnd = moment().endOf('week').format('MM-DD-YYYY');

			setCurrentHeader(moment().startOf('week').format('MMM Do') + ' to ' + moment().endOf('week').format('MMM Do YYYY'));

			console.log(moment().startOf('week').format('MMM Do') + ' to ' + moment().endOf('week').format('MMM Do YYYY'));

			fetchSessionsForWeek(weekStart, weekEnd);
		}

		if(calendarType === 'monthly'){
			setCurrentHeader(moment().format('MMMM YYYY'));

			// Get current month and year 
			const month = moment().month();
			const year = moment().year();

			fetchSessionsForMonth(month, year);

			
		}

		if(!calendarType){
			setCurrentHeader(moment().format('MMMM YYYY'));
			calendarContext.setType('monthly');

			// Get current month and year 
			const month = moment().month();
			const year = moment().year();

			fetchSessionsForMonth(month, year);
		}



	}, [calendarType])

	const handleNextTimePeriod = async () => {
		console.log("Next Time Period");
		setDaySessions([]);
		setScheduleLoading(true);
		if (type === 'daily') {
			var nextDay = moment(currentDate).add(1, 'days').format('MM-DD-YYYY');
			console.log(nextDay);
			setCurrentDate(nextDay);
			setCurrentHeader(moment(nextDay).format('MMMM DD'));
			const sessions = await getSessionsForSpan(nextDay, nextDay, navContext.handleAPICallError)
			if(sessions !== null){
				console.log(sessions);
				setDaySessions(sessions);
			}

		}
		if (type === 'weekly') {
			var nextWeekStart = moment(currentWeekStart).add(1, 'weeks').format('MM-DD-YYYY');
			var nextWeekEnd = moment(currentWeekEnd).add(1, 'weeks').format('MM-DD-YYYY');
			console.log(nextWeekStart);
			console.log(nextWeekEnd);
			setCurrentWeekStart(nextWeekStart);
			setCurrentWeekEnd(nextWeekEnd);
			setCurrentHeader(moment(nextWeekStart).format('MMM Do') + ' to ' + moment(nextWeekEnd).format('MMM Do YYYY'));

			const sessions = await getSessionsForSpan(nextWeekStart, nextWeekEnd, navContext.handleAPICallError)
			mapWeekSessions(sessions, moment(nextWeekStart));
			if(sessions !== null){
				console.log(sessions);
				setWeekSessions(sessions);
			}
		}
		if (type === 'monthly') {
			var nextMonth = moment(currentMonth + " " + currentYear, "MM YYYY").add(1, 'months').format('MM-DD-YYYY');
			console.log(nextMonth);
			const month = moment(nextMonth).month();
			const year = moment(nextMonth).year();
			const startOfMonth = moment(nextMonth).startOf('month').format('MM-DD-YYYY');
			const endOfMonth = moment(nextMonth).endOf('month').format('MM-DD-YYYY');
			// console.log(startOfMonth);
			// console.log(endOfMonth);

			setCurrentMonth(moment(nextMonth).format('MM'));
			setCurrentYear(moment(nextMonth).format('YYYY'));
			setCurrentHeader(moment(nextMonth).format('MMMM YYYY'));
			const sessions = await getSessionsForSpan(startOfMonth, endOfMonth, navContext.handleAPICallError)
			mapMonthSessions(sessions, month, year);
			if(sessions !== null){
				console.log(sessions);
				setMonthSessions(sessions);
			}
		}

		setScheduleLoading(false);
	}

	const handlePreviousTimePeriod = async () => {
		console.log("Previous Time Period");
		setScheduleLoading(true);
		setDaySessions([]);
		if (type === 'daily') {
			var previousDay = moment(currentDate).subtract(1, 'days').format('MM-DD-YYYY');
			console.log(previousDay);
			setCurrentDate(previousDay);
			setCurrentHeader(moment(previousDay).format('MMMM DD'));
			const sessions = await getSessionsForSpan(previousDay, previousDay, navContext.handleAPICallError)
			if(sessions !== null){
				console.log(sessions);
				setDaySessions(sessions);
			}
		}

		if (type === 'weekly') {
			var previousWeekStart = moment(currentWeekStart).subtract(1, 'weeks').format('MM-DD-YYYY');
			var previousWeekEnd = moment(currentWeekEnd).subtract(1, 'weeks').format('MM-DD-YYYY');
			console.log(previousWeekStart);
			console.log(previousWeekEnd);
			setCurrentWeekStart(previousWeekStart);
			setCurrentWeekEnd(previousWeekEnd);
			setCurrentHeader(moment(previousWeekStart).format('MMM Do') + ' to ' + moment(previousWeekEnd).format('MMM Do YYYY'));

			const sessions = await getSessionsForSpan(previousWeekStart, previousWeekEnd, navContext.handleAPICallError)
			mapWeekSessions(sessions, moment(previousWeekStart));
			if(sessions !== null){
				console.log(sessions);
				setWeekSessions(sessions);
			}
		}

		if (type === 'monthly') {
			var previousMonth = moment(currentMonth + " " + currentYear, "MM YYYY").subtract(1, 'months').format('MM-DD-YYYY');
			console.log(previousMonth);
			const month = moment(previousMonth).month();
			const year = moment(previousMonth).year();
			const startOfMonth = moment(previousMonth).startOf('month').format('MM-DD-YYYY');
			const endOfMonth = moment(previousMonth).endOf('month').format('MM-DD-YYYY');
			// console.log(startOfMonth);
			// console.log(endOfMonth);
			
			setCurrentMonth(moment(previousMonth).format('MM'));
			setCurrentYear(moment(previousMonth).format('YYYY'));
			setCurrentHeader(moment(previousMonth).format('MMMM YYYY'));

			const sessions = await getSessionsForSpan(startOfMonth, endOfMonth, navContext.handleAPICallError)
			mapMonthSessions(sessions, month, year);
			if(sessions !== null){
				console.log(sessions);
				setMonthSessions(sessions);
			}
		} 
		setScheduleLoading(false);
	}


	const handleOnClick = e => {
		e.preventDefault();
		if (props.onClick) props.onClick(e);
	}


	const handleTimeblockEvent = (e = null, start = null, end = null, staff = null) => {
		if (e) e.preventDefault();

		if (type === 'daily' || type === 'weekly') {
			navContext.setSideNav( <SessionDetailsPanel  type={ type } /> );
			navContext.setSideNavTitle("Session Details");
			navContext.openSideNav();
			navContext.setSideNavActions(null);
		}

		// if (showDetails === false) {
		// 	setShowDetails(true);

		// 	if (start && end) {
		// 		navContext.setValues({
		// 			startTime: start,
		// 			endTime: end
		// 		})
		// 	}

		// 	if (start && end && staff) {
		// 		navContext.setValues({
		// 			startTime: start,
		// 			endTime: end,
		// 			staff: staff
		// 		})
		// 	}

		// 	navContext.setSideNav(<ClientInfoPanel />);

		// 	navContext.setSideNavActions({
		// 		"ctas": [{
		// 			"name": "Back",
		// 			"onClick": (e) => {
		// 				setShowDetails(false)
		// 				navContext.setSideNavActions(null);
		// 			}
		// 		}, {
		// 			"primary": true,
		// 			"name": "Assign",
		// 			"onClick": (e) => {
		// 				alert("woohoo!")
		// 			}
		// 		}]
		// 	});
		// } else {
		// 	setShowDetails(false);
		// }
	}

	const colsCount = 3;

	return <>
		{/* <button onClick={ e => {
			e.preventDefault();
			navContext.setDisableMenu(true);
		}}>
			Disable Main Menu Demo
		</button> */}

		<CalendarWrapper timeHeader={currentHeader} nextTimePeriod={handleNextTimePeriod} prevTimePeriod={handlePreviousTimePeriod}>
			{scheduleLoading ? <div className="tw-flex tw-justify-center tw-mx-4">
                <LoadingSpinner />
            </div>:
			<>
			{ calendarType === 'daily' ? <>
				{supportStations && <WeeklyCalendarContainer cols={supportStations.length} className="tw-flex tw-flex-col tw-w-full">
					{supportStations.map((station, index) => {
						const colorArray = ['#FBDF9B', '#1241B9', '#EF5700', '#831D55', '#104042']
						return <>
							<WeeklyCalendarDayContainer title={station.stationName} color={colorArray[index%5]}>
								{daySessions.map((session, index) => {
									if(session.stationID === station.remoteSupportStationID){
										if(!session.multiDay){
											return <>
												<Timeblock start={removeWhitespaceAndLowercase(session.displayStartTime)} end={removeWhitespaceAndLowercase(session.displayEndTime)}  onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
													{session.providerName}
												</Timeblock>
											</>
										} 
										if (session.multiDay && session.displayStartDate !== currentDate && session.displayEndTime != "12:00 AM"){
											return <>
												<Timeblock start="12:00am" end={removeWhitespaceAndLowercase(session.displayEndTime)} onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
													{session.providerName}
												</Timeblock>
											</>
										}

										if (session.multiDay && session.displayStartDate === currentDate){
											return <>
												<Timeblock start={removeWhitespaceAndLowercase(session.displayStartTime)} end="12:00am" onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
													{session.providerName}
												</Timeblock>
											</>
										}
										
									}
								})}
							</WeeklyCalendarDayContainer>
						</>
					})}

					{/* <WeeklyCalendarDayContainer title={`Current 1`} color={'#FBDF9B'}>
							{daySessions.map((session, index) => {
								if(!session.multiDay && session.colStart === 1){
								return <>
									<Timeblock start={removeWhitespaceAndLowercase(session.displayStartTime)} end={removeWhitespaceAndLowercase(session.displayEndTime)}  onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
										{session.providerName}
									</Timeblock>
								</>}
								else if(session.multiDay && session.colStart === 1 && session.displayStartDate === currentDate)
								{
									return <>
									<Timeblock start={removeWhitespaceAndLowercase(session.displayStartTime)} end={removeWhitespaceAndLowercase(session.displayEndTime)}  onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
										{session.providerName}
									</Timeblock>
								</>}
								else if(session.multiDay && session.multiDayColStart === 1 && session.displayStartDate !== currentDate){
									return <>
									<Timeblock start={removeWhitespaceAndLowercase(session.displayStartTime)} end="12:00am"  onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
										{session.providerName}
									</Timeblock>
									</>
								}
								
					})}
					</WeeklyCalendarDayContainer>
					<WeeklyCalendarDayContainer title={`Current 2`} color={'#FBDF9B'}>
							{daySessions.map((session, index) => {
								if(!session.multiDay && session.colStart !== 1){
								return <>
									<Timeblock start={removeWhitespaceAndLowercase(session.displayStartTime)} end={removeWhitespaceAndLowercase(session.displayEndTime)}  onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
										{session.providerName}
									</Timeblock>
								</>}
					})}
					</WeeklyCalendarDayContainer> */}
					{/* { [...Array(colsCount-2)].map( ( day, index ) => {
						const colorArray = ['#FBDF9B', '#1241B9', '#EF5700', '#831D55', '#104042']
						return <>
							<WeeklyCalendarDayContainer title={`Station ${ index + 1}`} color={colorArray[index]}>
								<Timeblock start="12:00am" end="8:00am"  onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
									Chris, Green
								</Timeblock>
								<Timeblock start="10:00am" end="12:00pm" onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
									Maxx, Williams
								</Timeblock>
							</WeeklyCalendarDayContainer>
						</>
					})} */}
				</WeeklyCalendarContainer>}

			</> : <>
				{ calendarType === 'weekly' ? <>
					<WeeklyCalendarContainer weekly cols={7} className="tw-flex tw-flex-col tw-w-full">
						{ weekDays.map( ( weekDay, index ) => {
							return <>
								<WeeklyCalendarDayContainer title={`Station ${ index + 1}`} day="blue" dayOfWeek={weekDay.day.substring(0, 3).toUpperCase()} date={weekDay.dayNumber} status={weekDay.status} coverage={weekDay.coverage} weeklyView>
									<Timeblock weekly start="12:00am" end="6:00am" coverage={weekDay.firstQuadrantCoverage.status} count={weekDay.firstQuadrantCoverage.shifts.length} onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
										<div className="tw-flex tw-flex-col">
											{weekDay.firstQuadrantCoverage.shifts.map((shift, index) => {
												return <>
													<p>{shift.providerName}</p>
												</>
											})}
											{/* <p>Chris, Green</p>
											<p>Maxx, Williams</p>
											<p>Taylor, Kaeser</p> */}
										</div>
									</Timeblock>
									<Timeblock weekly start="6:00am" end="12:00pm"  coverage={weekDay.secondQuadrantCoverage.status} count={weekDay.secondQuadrantCoverage.shifts.length} onClick={ e => handleTimeblockEvent(e, '12:00am', '3:00am', 'Kmack') }>
											{weekDay.secondQuadrantCoverage.shifts.map((shift, index) => {
												return <>
													<p>{shift.providerName}</p>
												</>
											})}
									</Timeblock>
									<Timeblock weekly start="12:00pm" end="6:00pm" coverage={weekDay.thirdQuadrantCoverage.status} count={weekDay.thirdQuadrantCoverage.shifts.length} onClick={ e => handleTimeblockEvent(e, '11:00am', '12:00pm', 'Kmack') }>
											{weekDay.thirdQuadrantCoverage.shifts.map((shift, index) => {
												return <>
													<p>{shift.providerName}</p>
												</>
											})}
									</Timeblock>
									<Timeblock weekly start="6:00pm" end="12:00am" coverage={weekDay.fourthQuadrantCoverage.status} count={weekDay.fourthQuadrantCoverage.shifts.length} onClick={ e => handleTimeblockEvent(e, '11:00am', '12:00pm', 'Kmack') }>
											{weekDay.fourthQuadrantCoverage.shifts.map((shift, index) => {
												return <>
													<p>{shift.providerName}</p>
												</>
											})}
									</Timeblock>
								</WeeklyCalendarDayContainer>
							</>
						})}
					</WeeklyCalendarContainer>

				</> : <>
					<div className="tw-pb-20px">
						<MonthlyCalendar month={currentMonth || moment().format("MM")} year={currentYear || moment().format("YYYY")} days={monthDays || []} />
					</div>
				</> }
			</> }</>}
		</CalendarWrapper>

	</>
}

export default RSASchedulingPage;
