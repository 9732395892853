import { DefaultButton } from "@medforall/medforall-component-library";
import { ButtonPrimary, ButtonSecondary } from "../buttons/Buttons";
import { CircleLabel } from "../calendars/CalendarUtils";
import { ChangeType, FrequencyField, FrequencyFieldDays, InputTimes, Label, NoYes, Search, Select, SupportType, Textarea } from "../forms/formFields";
import { LabelOutputs, Span } from "../utilities/Helpers";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppContext } from "../../Context";
import CalendarSelector from "../calendars/CalendarSelector";
import AvatarNameInline from "../avatars/AvatarNameInline";

const Added = props => {
	return <LabelOutputs className="tw-w-200px tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
		<span className="tw-text-5">
			Added Successfully
		</span>
	</LabelOutputs>
}

export const DelegationType = props => {
	const ref = useRef<HTMLInputElement>(null);
	const [value, setValue] = useState( props.value || '');

	const attributes = {
		...props,
		placeholder: null,
		required: null,
		id: null,
		name: null
	}

	useEffect(() => {
		const temp = value;
		let inputEvent = new Event('change', { bubbles: true });
		ref.current.dispatchEvent(inputEvent);
		if (props.onChange) props.onChange(inputEvent)
    }, [value]);


	return <>
		<div { ...attributes }>
			<Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-message.svg">
				Reason for delegation
			</Label>
			<fieldset className="tw-flex tw-flex-col tw-space-y-5px">
				<div className="tw-w-full">
					<label htmlFor={ props.id ? `${props.id}_1` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input  onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_1` :  null} value={ props.value || 'Staff called off' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group-hover:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span className="tw-flex-grow tw-text-dark group-hover:tw-text-primary-dark">
							{ props.value || 'Staff called off' }
						</span>
					</label>
				</div>
				<div>
					<label htmlFor={ props.id ? `${props.id}_2` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_2` :  null} value={ props.value2 || 'No Show' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value2 || 'No show' }
						</span>
					</label>
				</div>
				<div>
					<label htmlFor={ props.id ? `${props.id}_3` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_3` :  null} value={ props.value2 || 'Client requested' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value3 || 'Client requested' }
						</span>
					</label>
				</div>
				<div>
					<label htmlFor={ props.id ? `${props.id}_4` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_4` :  null} value={ props.value2 || 'Staff shortage' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value4 || 'Staff shortage' }
						</span>
					</label>
				</div>
				<input required={ props.required || null } type="hidden" ref={ ref } value={ value } name={ props.name || props.id || null } id={ props.id || null } />
			</fieldset>
		</div>
	</>
}


const SessionListPanelDetailsView = props => {
	const navContext = useAppContext();

	useLayoutEffect(() => {
		navContext.setSideNavTitle("Session Details");
	}, [])

	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex-shrink-0 tw-w-full tw-overflow-x-hidden tw-overflow-y-auto">
				<div className="tw-p-20px tw-space-y-20px tw-w-full">

					<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
						{props.date ? <>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Date
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{props.date}
								</span>
							</li>
						</> : <></>}
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-status.svg" className="tw-opacity-70">
									Status
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								<Span className="tw-flex-shrink-0">
									Partially Opened
								</Span>
								<Span className="tw-ml-auto || tw-w-16p tw-h-16p tw-aspect-square tw-relative">
									<img className="tw-block tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-object-cover tw-object-center" src="/images/icon-partial.svg" alt="Partial Coverage" />
								</Span>
							</span>
						</li>
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
									Support Type
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								Remote Support
							</span>
						</li>
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
									Time Period
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								8:00 AM - 2:00 PM
							</span>
						</li>
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-edit.svg" className="tw-opacity-70">
									Created On
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								03/17/2021
							</span>
						</li>
					</ul>

				</div>
			</div>
		</div>

	</>
}

const SessionListPanel = props => {
	const navContext = useAppContext();
	const [toggleOpen, setToggleOpen] = useState<boolean>(true);
	const [details, setDetails] = useState<boolean>(false);
	const [toggleAssigned, setToggleAssigned] = useState<boolean>(false);
	const [editing, setEditing] = useState<boolean>(false);
	const [sessionList, setSessionList] = useState<boolean>(false);
	const [values, setValues] = useState({
		'reason': 'Staff called off',
		'reasonDetail': ''
	});
	const [repeatFrequency, setRepeatFrequency] = useState<boolean>(false);
	const [added, setAdded] = useState<boolean>(false);


	useEffect(() => {
		let timer = setTimeout(() =>{
			setAdded(false)
		}, 2000)
	}, [added]);


	useEffect(() => {
		navContext.setValues({...values, values});
	}, [values])


	return <>
		{ details ? <>
			<SessionListPanelDetailsView />
		</> : editing ? <>
			<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full tw-relative">
				<form className="tw-p-20px tw-space-y-40px tw-w-full tw-h-full tw-overflow-y-auto">
					<div>
						<Label for="provider" icon="/images/icon-provider.svg">
							Provider
						</Label>
						<Select id="provider">
							<option>
								Kevin Mack
							</option>
						</Select>
					</div>
					<div className="tw-space-y-20px">
						<DelegationType id="reason" onChange={ e => {
								setValues({
									...values,
									"reason": e.target.value
								})
							}} />
							<fieldset className="tw-w-full tw-pt-10px">
								<Textarea id="reasonDetail" className="!tw-w-[calc(100%-2.5rem)]" placeholder="Type your delegation for changing here (Optional)" onChange={ e => {
									setValues({
										...values,
										"reasonDetail": e.target.value
									})
								}}>
								</Textarea>
							</fieldset>
					</div>
				</form>
			</div>
		</> : <>
			<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full tw-relative">
				{ added && <div className="tw-absolute tw-top-0 tw-right-0 tw-left-0 tw-bottom-1/2  tw-pt-80px tw-max-h-full tw-w-full tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-white/20" onClick={() => setAdded(false) }>
					<Added />
				</div> }
				<div className="tw-py-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
					<div className="tw-flex-shrink-0 tw-w-full">
						<div className="tw-px-20px tw-flex tw-w-full tw-items-center">
							<div className="tw-flex-grow tw-flex tw-items-center">
								<Search className="tw-flex tw-w-full tw-flex-grow"/>
							</div>
						</div>
					</div>
					<div className="tw-flex-grow tw-w-full tw-flex tw-flex-col tw-overflow-hidden">
						<div className={`${toggleOpen ? 'tw-basis-1/2 tw-h-1/2 tw-max-h-1/2' : 'tw-min-h-40px' } tw-flex-shrink-0 tw-overflow-y-auto`}>
							<h3 role="button" className="tw-px-20px tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]" onClick={ e => {
								let temp = toggleOpen;
								setToggleOpen( temp = !temp )
							}}>
								<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full">
									<span className="tw-flex-grow">
										<span className="tw-text-black">
											Open - <Span className="tw-text-dark">(20)</Span>
										</span>
									</span>
									<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
										<img className="tw-w-full" src={`/images/icon-arrow-${ toggleOpen ? 'down-2' : 'up'}.svg`} alt="Icon" />
									</span>
								</span>
							</h3>
							{ toggleOpen && <>
								<div className="!tw-mt-5px tw-h-full tw-flex-grow tw-overflow-hidden tw-flex tw-flex-col tw-divide-y-1px tw-divide-black/20 tw-border-b-1px tw-border-b-black/20">
									<div className="tw-flex tw-flex-col tw-px-20px tw-overflow-y-auto tw-divide-y-1px tw-divide-black/20 tw-snap-y u-scrollbar">
										<div className="tw-flex tw-justify-end tw-sticky tw-top-0 tw-bg-white tw-z-2 tw-pb-4px">
											<div className="tw-inline-flex tw-items-center tw-justify-end tw-min-w-60px">
												<span className="tw-text-dark/70 tw-text-[.7em]">Delegate</span>
												{/* claim */}
											</div>
											<div className="tw-inline-flex tw-items-center tw-justify-center tw-w-50px">
												<span className="tw-text-dark/70 tw-text-[.7em]">Details</span>
											</div>
										</div>
										{ [...Array(25)].map( ( day, index ) => {
											return <>
												<div className="tw-snap-center tw-flex-shrink-0 tw-py-10px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5">
													<div className="tw-flex tw-items-center tw-w-200px tw-flex-grow">
														<AvatarNameInline size="30">
															<span className="tw-w-full tw-flex tw-flex-col tw-text-5">
																<span className="tw-w-full">
																	John Mogan
																</span>
																<span className="tw-w-full tw-text-[.75em] tw-text-dark-light">
																	08:00 - 10:00, Apr 12, 2022
																</span>
															</span>
														</AvatarNameInline>
													</div>
													<div className="tw-flex tw-items-center tw-flex-shrink-0 tw-justify-start tw-space-x-5px">
														<DefaultButton className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px tw-mr-5px" onClick={ e => {
															e.preventDefault();

															if (window.confirm("Are you sure you want to add coverage?") === true) {
																setAdded(true);
															}
														}}>
															<img src="/images/icon-action-add.svg" />
														</DefaultButton>
														<DefaultButton className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px tw-mr-5px" onClick={ e => {
															e.preventDefault();
															setDetails(true);

															navContext.setSideNavActions({
																"ctas": [{
																	"name": 'Back',
																	onClick: () => {
																		setDetails(false);
																		navContext.setSideNavActions(null);
																	}
																}]
															});
														}}>
															<img src="/images/icon-action-more.svg" />
														</DefaultButton>
													</div>
												</div>
											</>
										}) }
									</div>
									<div className="tw-w-full tw-flex tw-flex-shrink-0">
										<DefaultButton className="tw-w-full tw-text-6 tw-min-h-30px tw-mt-2px tw-text-dark hover:tw-text-primary">
											View More
										</DefaultButton>
									</div>
								</div>
							</>}
						</div>
						<div className={`${toggleAssigned ? 'tw-basis-1/2 tw-h-1/2 tw-max-h-1/2' : 'tw-min-h-40px' } tw-flex-shrink-0 tw-overflow-y-auto`}>
							<h3 role="button" className="tw-px-20px tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]" onClick={ e => {
								let temp = toggleAssigned;
								setToggleAssigned( temp = !temp )
							}}>
								<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full">
									<span className="tw-flex-grow">
										<span className="tw-text-black">
											Assigned - <Span className="w-text-dark">(20)</Span>
										</span>
									</span>
									<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
										<img className="tw-w-full" src={`/images/icon-arrow-${ toggleAssigned ? 'down-2' : 'up'}.svg`} alt="Icon" />
									</span>
								</span>
							</h3>
							{ toggleAssigned && <>
								<div className="!tw-mt-5px tw-h-full tw-flex-grow tw-overflow-hidden tw-flex tw-flex-col tw-divide-y-1px tw-divide-black/20 tw-border-b-1px tw-border-b-black/20">
									<div className="tw-flex tw-flex-col tw-px-20px tw-overflow-y-auto tw-divide-y-1px tw-divide-black/20 tw-snap-y u-scrollbar">
										<div className="tw-flex tw-justify-end tw-sticky tw-top-0 tw-bg-white tw-z-2 tw-pb-4px">
											<div className="tw-inline-flex tw-items-center tw-justify-end tw-min-w-60px">
												<span className="tw-text-dark/70 tw-text-[.7em]">Delegate</span>
											</div>
											<div className="tw-inline-flex tw-items-center tw-justify-center tw-w-50px">
												<span className="tw-text-dark/70 tw-text-[.7em]">Details</span>
											</div>
										</div>
										{ [...Array(25)].map( ( day, index ) => {
											return <>
												<div className="tw-snap-center tw-flex-shrink-0 tw-py-10px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5">
													<div className="tw-flex tw-items-center tw-w-200px tw-flex-grow">
														<AvatarNameInline size="30">
															<span className="tw-w-full tw-flex tw-flex-col tw-text-5">
																<span className="tw-w-full">
																	John Mogan
																</span>
																<span className="tw-w-full tw-text-[.75em] tw-text-dark-light">
																	08:00 - 10:00, Apr 12, 2022
																</span>
															</span>
														</AvatarNameInline>
													</div>
													<div className="tw-flex tw-items-center tw-flex-shrink-0 tw-justify-start tw-space-x-5px">
														<DefaultButton className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px tw-mr-5px"  onClick={ e => {
															setEditing(true);

															navContext.setSideNavTitle("Delegation");
															navContext.openSideNav();
															navContext.setSideNavActions({
																"ctas": [{
																	"name": "Discard",
																	"onClick": (e) => {
																		setEditing(false);
																		navContext.setSideNavTitle("Sessions List");
																		navContext.setSideNavActions(null);
																	},
																	"title": `Go Back`
																}, {
																	"name": "Save",
																	"onClick": (e) => {
																		alert("Saved!")
																		navContext.closeSideNav();
																		setEditing(false);
																		navContext.setSideNavActions(null);
																	},
																	"title": `Go Back`
																}]
															});
														}}>
															<img src="/images/icon-action-assigned.svg" />
														</DefaultButton>
														<DefaultButton className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px  tw-mr-5px" onClick={ e => {
															e.preventDefault();
															setDetails(true);

															navContext.setSideNavActions({
																"ctas": [{
																	"name": 'Back',
																	onClick: () => {
																		setDetails(false);
																		navContext.setSideNavActions(null);
																	}
																}]
															});
														}}>
															<img src="/images/icon-action-more.svg" />
														</DefaultButton>
													</div>
												</div>
											</>
										}) }
									</div>
									<div className="tw-w-full tw-flex tw-flex-shrink-0">
										<DefaultButton className="tw-w-full tw-text-6 tw-min-h-30px tw-mt-2px tw-text-dark hover:tw-text-primary">
											View More
										</DefaultButton>
									</div>
								</div>
							</>}
						</div>

					</div>
				</div>
			</div>
		</>}
	</>
}

export default SessionListPanel;
