import { useState } from "react";
import { ProviderAttempt, Quiz, QuizQuestionResponse } from "../../types/training";
import { CircleLabel } from "@medforall/medforall-component-library";
import { LabelOutputs } from "../utilities/Helpers";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import Divider from "../utilities/Divider";

const ProviderAttemptPanel = props => {
    const [attempts, setAttempts] = useState<ProviderAttempt[]>(props.attempts);
    const [employeeName, setEmployeeName] = useState<string>(props.employeeName);
    const [trainingQuiz, setTrainingQuiz] = useState<Quiz>(props.trainingQuiz);

    const isCorrectAnswer = (questionID: string, submittedAnswers: QuizQuestionResponse[]) => {
        const question = submittedAnswers.find((answer) => answer.questionID === questionID);
        return question?.correct;
    }

    const containsMCQAnswer = (questionID: string, choice: string, submittedAnswers: QuizQuestionResponse[]) => {
        const question = submittedAnswers.find((answer) => answer.questionID === questionID);
        const submittedChoices = question?.submittedChoice.split("|");
        return submittedChoices?.includes(choice);
    }

    const containsSingleAnswer = (questionID: string, choice: string, submittedAnswers: QuizQuestionResponse[]) => {
        const question = submittedAnswers.find((answer) => answer.questionID === questionID);
        return question?.submittedChoice === choice;
    }
    
    const renderQuizResults = () => {
        if (!attempts) return null;

        if (!attempts[0].submittedAnswers) return null;

        return trainingQuiz && 
            [...trainingQuiz.questions]
            .sort((a, b) => a.questionSequence - b.questionSequence)
            .map((question, index) => (
            <>
            <div key={question.questionID} className="tw-mb-4">
                <div className="tw-flex tw-justify-start">
                    <div className="tw-mr-2">
                        {isCorrectAnswer(question.questionID, attempts[0].submittedAnswers) ? (
                        <CheckCircleIcon className="tw-h-6 tw-w-6 tw-text-green-500" />
                        ) : (
                        <XCircleIcon className="tw-h-6 tw-w-6 tw-text-red-500" />
                        )}
                    </div>
                    <h4 className="tw-text-md tw-font-semibold">Question {index + 1}: {question.questionType === "True-False" ? "True or False: ": ""} {question.question} {question.questionType === "Multiple-Choice" ? " - Select all that apply":""}</h4>
                    
                </div>
                
                {question.questionType === "Image-Based" && (
                    <div className="tw-flex tw-justify-between">
                        <img src={question.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />
                    </div>
                    
                )}
                    
                    <div key={question.choice1}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice1}
                                checked={containsMCQAnswer(question.questionID, "1", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice1}
                                checked={containsSingleAnswer(question.questionID, "1", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice1} <span className="tw-text-green-500">{isCorrectChoice(question.questionID, "1") ? "- Correct": ""}</span></span>
                        </label>
                    </div>
                    <div key={question.choice2}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice2}
                                checked={containsMCQAnswer(question.questionID, "2", attempts[0].submittedAnswers)}
                                disabled
                
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice2}
                                checked={containsSingleAnswer(question.questionID, "2", attempts[0].submittedAnswers)}
                                disabled
                                
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice2} <span className="tw-text-green-500">{isCorrectChoice(question.questionID, "2") ? "- Correct": ""}</span></span>
                        </label>
                    </div>
                    {question.questionType !== "True-False" && <div key={question.choice3}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice3}
                                checked={containsMCQAnswer(question.questionID, "3", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice3}
                                checked={containsSingleAnswer(question.questionID, "3", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice3} <span className="tw-text-green-500">{isCorrectChoice(question.questionID, "3") ? "- Correct": ""}</span></span>
                        </label>
                    </div>}
                    {question.questionType !== "True-False" && <div key={question.choice4}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice4}
                                checked={containsMCQAnswer(question.questionID, "4", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice4}
                                checked={containsSingleAnswer(question.questionID, "4", attempts[0].submittedAnswers)}
                                disabled
                                
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice4} <span className="tw-text-green-500">{isCorrectChoice(question.questionID, "4") ? "- Correct": ""}</span></span>
                        </label>
                    </div>}
            </div>
            <Divider />
            </>
        ));
    };

    const isCorrectChoice = (questionID: string, answer: string) => {
        var question = trainingQuiz?.questions.find((q) => q.questionID === questionID);

        //console.log(question);

        if (!question) return false;

        if (question.questionType === "Multiple-Choice") {
            var submittedAnswers = answer.split("|");
            var correctMCQAnswers = question.correctChoice.split("|");

            var correct = true;

            correctMCQAnswers.forEach((ca) => {
                if (!submittedAnswers.includes(ca)) {
                    correct = false;
                }
            });

            submittedAnswers.forEach((sa) => {
                if (!correctMCQAnswers.includes(sa)) {
                    correct = false;
                }
            });

            //console.log("MCQ Correct: " + correct);

            return correct;
        }

        //console.log("Single Correct: " + (answer === question.correctChoice));

        return answer === question.correctChoice;
    }

    return (<>
        <div className="tw-p-10px tw-space-y-15px tw-w-full">
            <h3 className="tw-font-primary-bold tw-font-5 tw-pt-15px">
				<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
					<span className="tw-text-dark tw-ml-5px">Participant Details</span>
				</CircleLabel>
			</h3>
            <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-pb-10px">
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Employee Name
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{employeeName}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Total Attempts
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{attempts ? attempts.length : 0}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Last Attempt Date
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{attempts ? new Date(attempts[0].submissionTime).toLocaleString() : "Not Started"}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Status
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{attempts ? attempts[0].status : "Not Started"}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Last Attempt Score
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
                                {attempts ? attempts[0].score : "Not Started"}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Passing Score
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
                                {trainingQuiz?.passingScore ? trainingQuiz.passingScore : "Not Set"}
							</span>
						</li>

            </ul>

            <h3 className="tw-font-primary-bold tw-font-5 tw-pt-15px">
				<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
					<span className="tw-text-dark tw-ml-5px">Quiz Responses</span>
				</CircleLabel>
			</h3>

            {!attempts  || !attempts[0].submittedAnswers ? (
                <div className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-pb-10px">
                    <li className="tw-flex tw-space-x-5px tw-items-center">
                        <span className="tw-w-full tw-flex tw-items-center">
                            <LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
                                No Quiz Responses
                                </LabelOutputs>
                                </span>
                                </li>
                                </div>
                                ) : null}

            {renderQuizResults()}



        </div>
    </>);
}

export default ProviderAttemptPanel;