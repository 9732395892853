import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { RemoteSupportEvent } from "../../types/servicePlan";
import { v4 as uuidv4 } from 'uuid';
import { EditRemoteSupportTaskList } from "../portalComponents/editRemoteSupportTaskCard";

const NewTaskSection = () => {
    const [showTaskCard, setShowTaskCard] = useState(false);

    const planContext = useNewPlanContext();

    const RemoteSupportEventSchema = Yup.object().shape({
        eventTitle: Yup.string()
          .max(50, 'Event title must be at most 50 characters')
          .required('Event title is required'),
        eventDetails: Yup.string().required('Event details are required'),
        timeOfDay: Yup.string().required('Time of day is required')
            .matches(
            /^(0?[1-9]|1[0-2]):[0-5][0-9] ?(am|pm)$/i,
            'Time of Day must be in 12-hour format (hh:mm am/pm)'
            ),
        preEventReminder: Yup.number()
          .positive('Pre-event reminder must be at least 1 minute or greater')
          .required('Pre-event reminder is required'),
        postEventReminder: Yup.number()
          .positive('Post-event reminder must be at least 1 minute or greater')
          .required('Post-event reminder is required'),
      });

    const initialValues = {
        eventTitle: '',
        eventDetails: '',
        timeOfDay: '',
        repeatsMonday: false,
        repeatsTuesday: false,
        repeatsWednesday: false,
        repeatsThursday: false,
        repeatsFriday: false,
        repeatsSaturday: false,
        repeatsSunday: false,
        preEventReminder: 0,
        postEventReminder: 0,
      };

    const taskFormik = useFormik({
        initialValues,
        validationSchema: RemoteSupportEventSchema,
        onSubmit: (values) => {
            console.log(values);
            var newTask: RemoteSupportEvent = {
                eventTitle: values.eventTitle,
                eventDetails: values.eventDetails,
                timeOfDay: values.timeOfDay,
                repeatsMonday: values.repeatsMonday,
                repeatsTuesday: values.repeatsTuesday,
                repeatsWednesday: values.repeatsWednesday,
                repeatsThursday: values.repeatsThursday,
                repeatsFriday: values.repeatsFriday,
                repeatsSaturday: values.repeatsSaturday,
                repeatsSunday: values.repeatsSunday,
                preEventReminder: values.preEventReminder,
                postEventReminder: values.postEventReminder,
                remoteSupportEventID: uuidv4(),
                servicePlanID: "",
                responsibleParty: ""
            };

            planContext.setPlanTasks([...planContext.planTasks, newTask]);

            toggleTaskCard();


            
        },
    });

    const toggleTaskCard = () => {
        setShowTaskCard(!showTaskCard);
        taskFormik.resetForm();
    }

    const handleRemoveEvent = (event) => {
        const index = planContext.planTasks.indexOf(event);
        const newItems = [...planContext.planTasks.slice(0, index), ...planContext.planTasks.slice(index + 1)];
        planContext.setPlanTasks(newItems);
    }
    
    return (<>
        {showTaskCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.eventTitle && taskFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('eventTitle')}
                                                    />
                                                    {taskFormik.touched.eventTitle && taskFormik.errors.eventTitle && <p className="tw-text-red-500">{taskFormik.errors.eventTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.eventDetails && taskFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('eventDetails')}
                                                    />
                                                    {taskFormik.touched.eventDetails && taskFormik.errors.eventDetails && <p className="tw-text-red-500">{taskFormik.errors.eventDetails}</p>}
                                                </div>
                                            </div>
                                    </div>
                                    <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Time Of Day
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="time-of-day"
                                                    id="time-of-day"    
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.timeOfDay && taskFormik.errors.timeOfDay ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('timeOfDay')}
                                                    />
                                                    {taskFormik.touched.timeOfDay && taskFormik.errors.timeOfDay && <p className="tw-text-red-500">{taskFormik.errors.timeOfDay}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Pre Reminder
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="number"
                                                    name="pre-reminder"
                                                    id="pre-reminder"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.preEventReminder && taskFormik.errors.preEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('preEventReminder')}
                                                    />
                                                    {taskFormik.touched.preEventReminder && taskFormik.errors.preEventReminder && <p className="tw-text-red-500">{taskFormik.errors.preEventReminder}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Overdue Reminder
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="number"
                                                    name="overdue-reminder"
                                                    id="overdue-reminder"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.postEventReminder && taskFormik.errors.postEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('postEventReminder')}
                                                    />
                                                    {taskFormik.touched.postEventReminder && taskFormik.errors.postEventReminder && <p className="tw-text-red-500">{taskFormik.errors.postEventReminder}</p>}
                                                </div>
                                            </div>
                                    </div>
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900 tw-pt-4">
                                        Repeat
                                    </label>
                                    <div className="tw-flex tw-p-1">
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsMonday', !taskFormik.values.repeatsMonday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsTuesday', !taskFormik.values.repeatsTuesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsWednesday', !taskFormik.values.repeatsWednesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsThursday', !taskFormik.values.repeatsThursday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsFriday', !taskFormik.values.repeatsFriday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsSaturday', !taskFormik.values.repeatsSaturday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsSunday', !taskFormik.values.repeatsSunday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                        </span>
                                    </div>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleTaskCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={taskFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                </div>
                            </>:<>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                                        <div className="tw-flex tw-items-center">
                                                <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleTaskCard}>
                                                <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                                <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Task</span>
                                                </div>  
                                        </div>
                                </div>
                            </>}

                            {/* {planContext.planTasks.length > 0 && planContext.planTasks?.map((task, index) => (
                                <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mt-4">
                                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={task.eventTitle}
                                                disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Description
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="description"
                                                id="description"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={task.eventDetails}
                                                disabled={true}
                                                />
                                            </div>
                                        </div>
                                </div>
                                <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Time Of Day
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="time-of-day"
                                                id="time-of-day"    
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={task.timeOfDay}
                                                disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Pre Reminder
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="number"
                                                name="pre-reminder"
                                                id="pre-reminder"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={task.preEventReminder}
                                                disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Overdue Reminder
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="number"
                                                name="overdue-reminder"
                                                id="overdue-reminder"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={task.postEventReminder}
                                                disabled={true}
                                                />
                                            </div>
                                        </div>
                                </div>
                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900 tw-pt-4">
                                    Repeat
                                </label>
                                <div className="tw-flex tw-p-1">
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${task.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${task.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${task.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${task.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${task.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${task.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${task.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${task.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${task.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${task.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${task.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${task.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${task.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${task.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                    </span>
                                </div>
                                <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mt-md" onClick={e => handleRemoveEvent(task)}>
                                                Remove
                                        </ButtonSecondary>

                                </div>
                            </div>
                            ))} */}

                            <div className="tw-mt-4">
                                {planContext.planTasks.length > 0 && <EditRemoteSupportTaskList tasks={planContext.planTasks} newPlan={true}></EditRemoteSupportTaskList>}
                            </div>
    </>);
}

export default NewTaskSection;