import { Block } from "@medforall/medforall-component-library";
import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import AvatarNameInline from "../avatars/AvatarNameInline";
import { CircleLabel } from "../calendars/CalendarUtils";
import { LabelOutputs } from "../utilities/Helpers";
import ClientHome from "../../types/clientHome";
import { PatientDirectSupportTeam } from "../../types/patient";
import TimeOffRequest from "../../types/timeOffRequest";


const TimeOffRequestPanel = props => {
	const navContext = useAppContext();
	const [request, setRequest] = useState<TimeOffRequest>(props.request);

	useLayoutEffect(() => {
		//TODO: Identify "id" to pass to client  <ClientInfoPanel />
        console.log(props.request);
		if (props.request) setRequest(props.request)
	}, [props.request])

	useLayoutEffect(() => {
		// navContext.setSideNavTitle("")
	}, [])

	return <>
		<div className="tw-p-20px tw-space-y-15px tw-w-full">
			<div>
				<AvatarNameInline className="tw-text-3 tw-font-primary-bold tw-text-dark tw-flex tw-leading-none" space="tw-space-x-15px"  size="40" name={` ${request?.employeeName} `} />
			</div>
			<h3 className="tw-font-primary-bold tw-font-5 tw-pt-30px">
				<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
					<span className="tw-text-dark tw-ml-5px">Details</span>
				</CircleLabel>
			</h3>
            <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-pb-10px">
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Start Date
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{request?.displayStartDate}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									End Date
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{request?.displayEndDate}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Status
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{request?.status}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Submission Date
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{request?.displaySubmittedDate}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Reason
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{request?.reason}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px">
							<span className="tw-w-400px tw-flex">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Detail
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{request?.reasonDetail}
							</span>
						</li>

            </ul>
		</div>
	</>
}

export default TimeOffRequestPanel;