import axios, { AxiosRequestConfig } from "axios";
import Patient from "../types/patient";
import { getAPIToken } from "./billingService";
import ServicePlan, { AuthorizedService, DesiredOutcome, RemoteSupportEvent, RemoteSupportRoutine, SuggestedAction, SupportArea, UnusualEvent } from "../types/servicePlan";
import moment from "moment";

const mapServicePlan = (plan: ServicePlan) : ServicePlan => {
    plan.displayStartDate = moment.utc(plan.startDate).format("MM/DD/YYYY");
    plan.displayEndDate = moment.utc(plan.endDate).format("MM/DD/YYYY");
    plan.startDate = moment.utc(plan.startDate).toDate().toISOString().substring(0,10);
    plan.endDate = moment.utc(plan.endDate).toDate().toISOString().substring(0,10);

    return plan

}

export const addServicePlan = async (plan: ServicePlan, patientID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/patient/' + patientID;
    return axios.post(url,plan,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const addSupportArea = async (area: SupportArea, planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/supportAreas';
    return axios.post(url,area,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateSupportArea = async (area: SupportArea, areaID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'supportAreas/' + areaID;
    return axios.put(url,area,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getSupportArea = async (areaID: string, handleAPICallError: (error: any) => void) : Promise<SupportArea> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'supportAreas/' + areaID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const deleteSupportArea = async (areaID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'supportAreas/' + areaID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getActiveServicePlan = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<ServicePlan> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/patient/' + patientID + '/active';
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getServicePlan = async (planID: string, handleAPICallError: (error: any) => void) : Promise<ServicePlan> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getServicePlansForPatient = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<ServicePlan[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/patient/' + patientID;
    return axios.get<ServicePlan[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data ? response.data.map(mapServicePlan): [];
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const updateServicePlan = async (plan: ServicePlan, planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID;
    return axios.put(url,plan,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const addUnusualEvent = async (event: UnusualEvent, planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/unusualEvents';
    return axios.post(url,event,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateUnusualEvent = async (event: UnusualEvent, planID: string, unusualEventID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/unusualEvents/' + unusualEventID;
    return axios.put(url,event,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const deleteUnusualEvent = async (planID: string, unusualEventID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/unusualEvents/' + unusualEventID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getUnusualEventByID = async (unusualEventID: string, handleAPICallError: (error: any) => void) : Promise<UnusualEvent> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'unusualEvents/' + unusualEventID;
    return axios.get<UnusualEvent>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data ? response.data : null;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
            });
}

export const addSuggestedAction = async (action: SuggestedAction, planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/suggestedActions';
    return axios.post(url,action,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateSuggestedAction = async (action: SuggestedAction, planID: string, suggestedActionID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/suggestedActions/' + suggestedActionID;
    return axios.put(url,action,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const deleteSuggestedAction = async (planID: string, suggestedActionID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/suggestedActions/' + suggestedActionID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const addRemoteSupportEvent = async (event: RemoteSupportEvent, planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/remoteSupportEvents';
    return axios.post(url,event,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateRemoteSupportEvent = async (event: RemoteSupportEvent, planID: string, remoteSupportEventID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/remoteSupportEvents/' + remoteSupportEventID;
    return axios.put(url,event,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const deleteRemoteSupportEvent = async (planID: string, remoteSupportEventID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/remoteSupportEvents/' + remoteSupportEventID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const addRemoteSupportRoutine = async (routine: RemoteSupportRoutine, planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/remoteSupportRoutines';
    return axios.post(url,routine,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateRemoteSupportRoutine = async (routine: RemoteSupportRoutine, planID: string, remoteSupportRoutineID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/remoteSupportRoutines/' + remoteSupportRoutineID;
    return axios.put(url,routine,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const deleteRemoteSupportRoutine = async (planID: string, remoteSupportRoutineID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/remoteSupportRoutines/' + remoteSupportRoutineID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getRemoteSupportRoutineByID = async (remoteSupportRoutineID: string, handleAPICallError: (error: any) => void) : Promise<RemoteSupportRoutine | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'remoteSupportRoutines/' + remoteSupportRoutineID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })    
        .catch(error => {
            handleAPICallError(error);
            return null;
            });
}

export const addDesiredOutcome = async (outcome: DesiredOutcome, planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/desiredOutcomes';
    return axios.post(url,outcome,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateDesiredOutcome = async (outcome: DesiredOutcome, planID: string, desiredOutcomeID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/desiredOutcomes/' + desiredOutcomeID;
    return axios.put(url,outcome,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const deleteDesiredOutcome = async (planID: string, desiredOutcomeID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/desiredOutcomes/' + desiredOutcomeID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getDesiredOutcomeByID = async (desiredOutcomeID: string, handleAPICallError: (error: any) => void) : Promise<DesiredOutcome | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'desiredOutcomes/' + desiredOutcomeID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
            });
}


export const addAuthorizedService = async (service: AuthorizedService, planID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/authorizedServices';
    return axios.post(url,service,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateAuthorizedService = async (service: AuthorizedService, planID: string, authorizedServiceID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID + '/authorizedServices/' + authorizedServiceID;
    return axios.put(url,service,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const deleteAuthorizedService = async (planID: string, authorizedServiceID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'servicePlans/' + planID +  '/authorizedServices/' + authorizedServiceID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}