import { DefaultButton, SectionHeader } from "@medforall/medforall-component-library";
import { ButtonPrimary, ButtonSecondary } from "../buttons/Buttons";
import { CircleLabel } from "../calendars/CalendarUtils";
import { ChangeType, FrequencyField, FrequencyFieldDays, InputTimes, Label, NoYes, Search, Select, SupportType, Textarea } from "../forms/formFields";
import { LabelOutputs, Span, Status } from "../utilities/Helpers";
import { useEffect, useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import CalendarSelector from "../calendars/CalendarSelector";
import AvatarNameInline from "../avatars/AvatarNameInline";
import SessionListPanel from "./SessionList";

const SessionDetailsPanel = props => {
	const navContext = useAppContext();
	const [toggleFilters, setToggleFilters] = useState<boolean>(false);
	const [type, setType] = useState<string>(null);
	const [editing, setEditing] = useState<boolean>(false);
	const [values, setValues] = useState({
		'dedicated': 'No',
		'overnight': 'No'
	});
	const [repeatFrequency, setRepeatFrequency] = useState<boolean>(false);

	useLayoutEffect(() => {
		if (props.editing) setEditing(true)
		if (props.type) setType(props.type)
	}, [props.editing, props.type])


	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">

			{ type === 'weekly' ? <>
				{ editing ? <>
					<form className="tw-p-20px tw-space-y-40px tw-w-full tw-h-full tw-overflow-y-auto">
						<div>
							<Label for="provider" icon="/images/icon-provider.svg">
								Provider
							</Label>
							<Select id="provider">
								<option>
									Kevin Mack
								</option>
							</Select>
						</div>
						<div>
							<CalendarSelector id="startDate" value={ props.startDate || null } />
							<div className="tw-space-y-20px tw-pt-5px">
								<InputTimes id="startTime"  label="Start Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
									onChange={ e => {
										setValues({
											...values,
											[e.target.id]: e.target.value
										})
									}}
								/>
							</div>
						</div>
						<div>
							<CalendarSelector id="endDate" value={ props.endDate || null } />
							<div className="tw-space-y-20px tw-pt-5px">
								<InputTimes id="endTime" label="End Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
									onChange={ e => {
										setValues({
											...values,
											[e.target.id]: e.target.value
										})
									}}
								/>
							</div>
						</div>

						<div>
							<Label htmlFor="" className="tw-w-full" icon="/images/icon-location.svg">
								Location
							</Label>
							<Select>
								<option value="">Locations</option>
							</Select>
						</div>
						<div className="tw-space-y-20px">
							<NoYes id="overnight" title={ 'Overnight' } icon="/images/icon-overnight.svg" value={ values?.overnight || 'No' } onChange={ e => {
								setValues({
									...values,
									"overnight": e.target.value
								})
							}} />
							<NoYes id="dedicated" title={ 'Dedicated' } icon="/images/icon-dedicated.svg" value={ values?.dedicated || 'No' } onChange={ e => {
								setValues({
									...values,
									"dedicated": e.target.value
								})
							}} />
						</div>
						<div>
							<Label htmlFor="repeatFrequency" icon="/images/icon-repeat.svg">
								Repeat
							</Label>
							<Select id="repeatFrequency" onChange={ e => {
								setRepeatFrequency( e.target.value === 'customize' )
							}}>
								<option value="">
									No Repeat
								</option>
								<option value="customize">
									Customize
								</option>
							</Select>
						</div>
						{ repeatFrequency && <>
							<div className="tw-bg-light-accent -tw-mx-20px tw-p-20px">
								<FrequencyField id="frequency" onChange={ e => {
									setValues({
										...values,
										[e.target.id]: JSON.parse(e.target.value)
									})
								}}/>
							</div>
						</> }
					</form>
				</> : <>
					<div className="tw-flex-grow tw-h-full tw-flex tw-flex-col">
						<div className="tw-flex-shrink-0 tw-px-20px tw-pt-20px">
							<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-pt-30px tw-pb-10px">
								<li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-120px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
											Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-text-black">
										03/17/2021
									</span>
								</li>
								<li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-120px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
											Time
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-text-black">
										00:00 - 04:00
									</span>
								</li>
								<li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-120px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
											Status
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-text-black">
										<SectionHeader tag="h4" childClasses="tw-flex tw-items-start  tw-text-6 tw-leading-none">
											<Status className={`tw-mt-4px tw-text-primary ${ props.open ? 'status' : ''}`}>Active</Status>
											<span className=" tw-text-dark tw-leading-none tw-text-5">
												Fully covered by several providers
											</span>
										</SectionHeader>
									</span>
								</li>
							</ul>
						</div>
						<div className="tw-flex-grow tw-red-300 tw-overflow-y-auto">
							<div className="tw-p-20px tw-space-y-20px">
								<div className="tw-space-y-15px tw-w-full">
									<h4 className="tw-font-primary tw-font-primary-bold tw-font-4">
										Covered Times
									</h4>
									<ul className="tw-flex tw-flex-col tw-space-y-8px tw-w-full tw-pr-40px">
										{ [...Array(4)].map( ( item, index ) => {
											return <li>
												<ButtonSecondary className="tw-w-full tw-group" onClick={ e => {
													navContext.setSideNav( <SessionDetailsPanel  type="daily" /> );
													navContext.setSideNavTitle("Session Details");
													navContext.openSideNav();
													navContext.setSideNavActions(null);
													e.preventDefault();
												}}>
													<span className="tw-w-full tw-flex-col tw-space-y-5px tw-text-6">
														<span className="tw-flex tw-justify-between tw-items-center tw-w-full">
															<span className="tw-items-center tw-flex tw-text-dark  group-hover:tw-text-white">
																00:00 - 02:00
															</span>
															<span className="tw-items-center tw-flex">
																<span className="tw-bg-primary  group-hover:!tw-bg-white  group-hover:tw-text-primary tw-text-white tw-inline-flex tw-px-5px tw-py-2px tw-leading-none">Overnight</span>
															</span>
														</span>
														<AvatarNameInline size="12" className="tw-text-dark group-hover:tw-text-white">
															<span>
																Kevin Mack
															</span>
														</AvatarNameInline>
													</span>
												</ButtonSecondary>
											</li>
										}) }
									</ul>
								</div>
								<div className="tw-space-y-15px tw-w-full">
									<h4 className="tw-font-primary tw-font-primary-bold tw-font-4">
										Uncovered Times
									</h4>
									<ul className="tw-flex tw-flex-col tw-space-y-8px tw-w-full tw-pr-40px">
										{ [...Array(2)].map( ( item, index ) => {
											return <li>
												<ButtonSecondary className="tw-w-full tw-group" onClick={ e => {
													navContext.setSideNav( <SessionDetailsPanel type="daily" editing={ true } /> );
													navContext.setSideNavTitle("Session Details");
													navContext.openSideNav();
													navContext.setSideNavActions({
														"ctas": [{
															"name": 'Discard',
															onClick: () => {
																navContext.closeSideNav();
															}
														}, {
															"name": 'Save',
															onClick: () => {
																alert("Success!")
																navContext.closeSideNav();
															}
														}]
													});
												}}>
													<span className="tw-w-full tw-flex tw-flex-col tw-space-y-5px tw-text-6 tw-text-left">
														<span className="tw-items-center tw-flex tw-text-dark  group-hover:tw-text-white">
															00:00 - 02:00
														</span>
														<span className="tw-text-dark group-hover:tw-text-white">
															No Provider Yet
														</span>
													</span>
												</ButtonSecondary>
											</li>
										}) }
									</ul>
								</div>
							</div>
						</div>
						<div className="tw-flex-shrink-0 tw-p-20px tw-flex tw-justify-center">
							<ButtonSecondary href="/schedule/rsa/daily/324324234" onClick={ e => {
								navContext.closeSideNav();
							}}>
								View Details
							</ButtonSecondary>
						</div>
					</div>
				</> }
			</> : <>
				{ editing ? <>
					<form className="tw-p-20px tw-space-y-40px tw-w-full tw-h-full tw-overflow-y-auto">
						<div>
							<CalendarSelector id="startDate" value={ props.startDate || null } />
							<div className="tw-space-y-20px tw-pt-5px">
								<InputTimes id="startTime"  label="Start Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
									onChange={ e => {
										setValues({
											...values,
											[e.target.id]: e.target.value
										})
									}}
								/>
							</div>
						</div>
						<div>
							<CalendarSelector id="endDate" value={ props.endDate || null } />
							<div className="tw-space-y-20px tw-pt-5px">
								<InputTimes id="endTime" label="End Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
									onChange={ e => {
										setValues({
											...values,
											[e.target.id]: e.target.value
										})
									}}
								/>
							</div>
						</div>

						<div>
							<Label htmlFor="" className="tw-w-full" icon="/images/icon-location.svg">
								Location
							</Label>
							<Select>
								<option value="">Locations</option>
							</Select>
						</div>
						<div className="tw-space-y-20px">
							<NoYes id="overnight" title={ 'Overnight' } icon="/images/icon-overnight.svg" value={ values?.overnight || 'No' } onChange={ e => {
								setValues({
									...values,
									"overnight": e.target.value
								})
							}} />
							<NoYes id="dedicated" title={ 'Dedicated' } icon="/images/icon-dedicated.svg" value={ values?.dedicated || 'No' } onChange={ e => {
								setValues({
									...values,
									"dedicated": e.target.value
								})
							}} />
						</div>
						<div>
							<Label htmlFor="repeatFrequency" icon="/images/icon-repeat.svg">
								Repeat
							</Label>
							<Select id="repeatFrequency" onChange={ e => {
								setRepeatFrequency( e.target.value === 'customize' )
							}}>
								<option value="">
									No Repeat
								</option>
								<option value="customize">
									Customize
								</option>
							</Select>
						</div>
						{ repeatFrequency && <>
							<div className="tw-bg-light-accent -tw-mx-20px tw-p-20px">
								<FrequencyField id="frequency" onChange={ e => {
									setValues({
										...values,
										[e.target.id]: JSON.parse(e.target.value)
									})
								}}/>
							</div>
						</> }
					</form>
					</> : <>
						<div className="tw-w-full tw-flex tw-flex-col tw-h-full">

							<div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
								<div className="tw-flex-shrink-0 tw-w-full">
									<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px">
										<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
											<span className="tw-flex-grow">
												<span className="tw-text-dark">
													Session Information
												</span>
											</span>
										</span>
									</h3>
									<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
										<li className="tw-flex tw-space-x-5px tw-items-center">
											<span className="tw-w-150px tw-flex tw-items-center">
												<span className="tw-opacity-70">
													Status
												</span>
											</span>
											<span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
												Chris Green
											</span>
										</li>
										<li className="tw-flex tw-space-x-5px tw-items-center">
											<span className="tw-w-150px tw-flex tw-items-center">
												<span className="tw-opacity-70">
													Start Date
												</span>
											</span>
											<span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
												Thu, 14 April 2022
											</span>
										</li>
										<li className="tw-flex tw-space-x-5px tw-items-center">
											<span className="tw-w-150px tw-flex tw-items-center">
												<span className="tw-opacity-70">
													End Date
												</span>
											</span>
											<span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
												Thu, 14 April 2022
											</span>
										</li>
										<li className="tw-flex tw-space-x-5px tw-items-center">
											<span className="tw-w-150px tw-flex tw-items-center">
												<span className="tw-opacity-70">
													Time Period
												</span>
											</span>
											<span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
												08:00 - 14:00
											</span>
										</li>
										<li className="tw-flex tw-space-x-5px tw-items-center">
											<span className="tw-w-150px tw-flex tw-items-center">
												<span className="tw-opacity-70">
													Overnight
												</span>
											</span>
											<span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
												No
											</span>
										</li>
										<li className="tw-flex tw-space-x-5px tw-items-center">
											<span className="tw-w-150px tw-flex tw-items-center">
												<span className="tw-opacity-70">
													Dedicated
												</span>
											</span>
											<span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
												No
											</span>
										</li>
										<li className="tw-flex tw-space-x-5px tw-items-center">
											<span className="tw-w-150px tw-flex tw-items-center">
												<span className="tw-opacity-70">
													Address
												</span>
											</span>
											<span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
												875 N High St Suite 300, Columbus
											</span>
										</li>
									</ul>
									<div className="tw-flex tw-justify-end tw-pr-20px tw-pb-10px tw-pt-15px">
										<ButtonPrimary className="tw-min-w-80px" onClick={ e => {
											e.preventDefault();
											setEditing(true);
											navContext.setSideNavTitle("Edit Session");
											navContext.setSideNavActions({
												"ctas": [{
													"name": "Discard",
													"onClick": (e) => {
														setEditing(false)
														navContext.setSideNavActions(null);
													},
													"title": `Go Back`
												}, {
													"name": "Save",
													"onClick": (e) => {
														// navContext.setSideNavTitle("Changes Saved");
														// navContext.setSideNav( <CoverageDetailsPanelSuccess canceling  /> );
														alert("Saved!");
														navContext.setSideNavActions(null);
														navContext.closeSideNav();
													},
													"title": `Save changes`
												}]
											});
										}}>
											Edit
										</ButtonPrimary>
									</div>
									<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px tw-pt-20px">
										<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
											<span className="tw-flex-grow">
												<span className="tw-text-dark">
													Assigned Clients - <Span className="tw-text-black">10</Span>
												</span>
											</span>
										</span>
									</h3>
									<div className="tw-flex tw-w-full tw-items-center">
										<div className="tw-flex-grow tw-flex tw-items-center tw-pr-20px">
											<Search className="tw-flex tw-w-full tw-flex-grow"/>
										</div>
										<div className="tw-max-w-40px tw-flex tw-justify-center tw-items-center" role="button" onClick={ () => {
												let temp = toggleFilters;
												setToggleFilters( temp = !temp )
										} }>
											<span className="tw-relative tw-aspect-square tw-w-30px">
												{ toggleFilters && <span className="tw-w-8px tw-h-8px tw-z-2 -tw-mt-1px tw-bg-primary tw-absolute tw-top-0 tw-right-0 tw-block tw-rounded-full"></span> }
												<img className="tw-w-full tw-h-full tw-absolute tw-object-cover tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-max-w-40px " src="/images/icon-filter.svg" />
											</span>
										</div>

										{/* TODO: https://www.freecodecamp.org/news/build-a-search-filter-using-react-and-react-hooks/ */}

									</div>
									{toggleFilters && <>
										<div className="-tw-mx-20px tw-pt-15px">
											<div className="tw-flex tw-w-full tw-overflow-x-auto tw-snap-x tw-space-x-5px tw-px-20px slider">
												{ [...Array(10)].map( ( day, index ) => {
													return <>
														<ButtonSecondary className={`${ index === 0 ? '!tw-bg-primary tw-text-white hover:!tw-bg-white hover:!tw-text-primary': '' } tw-px-8px tw-snap-center tw-text-6 tw-py-0 tw-min-h-20px tw-rounded-20px tw-flex-shrink-0`} childClasses="tw-py-5px">08:00-10:00</ButtonSecondary>
													</>
												}) }
											</div>
										</div>
									</>}
								</div>
								<div className="-tw-mx-20px !tw-mt-5px tw-h-full tw-flex-grow tw-overflow-hidden tw-flex tw-flex-col tw-divide-y-1px tw-divide-black/20 tw-border-b-1px tw-border-b-black/20">
									<div className="tw-py-10px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5 tw-flex-shrink-0 tw-px-20px">
										<div className="tw-flex tw-items-center tw-w-200px">
											<span className="tw-text-dark/70 tw-text-[.75em]">
												Client
											</span>
										</div>
										<div className="tw-flex tw-items-center tw-flex-grow tw-ml-auto tw-justify-start tw-space-x-10px">
											<span className="tw-text-dark/70 tw-text-[.7em]">
												Coverage Period
											</span>
										</div>
									</div>
									<div className="tw-flex tw-flex-col tw-px-20px tw-overflow-y-auto tw-divide-y-1px tw-divide-black/20 tw-snap-y u-scrollbar">
										{ [...Array(25)].map( ( day, index ) => {
											return <>
												<div className="tw-snap-center tw-flex-shrink-0 tw-py-10px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5">
													<div className="tw-flex tw-items-center tw-w-200px">
														<CircleLabel className="tw-text-black/20 tw-space-x-5px tw-w-full" size={14} space="tw-space-x-5px">
															<span className="tw-text-dark">
																John Morgan
															</span>
														</CircleLabel>
													</div>
													<div className="tw-flex tw-items-center tw-flex-grow tw-ml-auto tw-justify-start tw-space-x-10px">
														<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-flex-grow">
															<b>
																10:00 - 11:00
															</b>
														</div>
														<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px">
															{ index == 2 ?
																<img src="/images/icon-status-checked.svg" className="tw-w-20px" />
																:  (index == 3 ) ? <img src="/images/icon-status-repeat.svg" className="tw-w-20px" /> : <img src="/images/icon-status-stop.svg" className="tw-w-20px" />
															}
														</div>
													</div>
												</div>
											</>
										}) }
									</div>
									<div className="tw-w-full tw-flex tw-flex-shrink-0">
										<DefaultButton className="tw-w-full tw-text-6 tw-min-h-30px tw-mt-2px tw-text-dark hover:tw-text-primary">
											View More
										</DefaultButton>
									</div>
								</div>
							</div>
							<div className="tw-flex-shrink-0 tw-p-20px tw-flex tw-justify-center">
								<ButtonSecondary href="/schedule/rsa/daily/324324234" onClick={ e => {
									e.preventDefault();
									navContext.setSideNav( <SessionListPanel  /> );
									// setType("weekly");
									// setEditing(false)

									// console.log(type)
									// navContext.openSideNav();
									// navContext.setSideNavActions(null);
								}}>
									Add Additional Session
								</ButtonSecondary>
							</div>
						</div>
				</> }
			</> }

		</div>
	</>
}

export default SessionDetailsPanel;
