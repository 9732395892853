import { useEffect, useRef, useState } from "react";
import { ButtonPrimary, ButtonSecondary } from "../components/buttons/Buttons";
import { Input, Label, Select } from "../components/forms/formFields";
import { PageHeader, PageSubHeader } from "../components/typography/Headers";
import { Ellipsis } from "../components/utilities/Helpers";
import { useNavigate, useParams } from 'react-router-dom';
import Core from "../templates/Core";
import { rem, uuid } from "../utilities/helpers";
import { pageSubHeader } from "../utilities/typography";
import { getClientByID, getClientHomes, getClientServiceType, saveLegacyEvent } from "../apicalls/schedulingService";
import { SelectChangeEvent } from "@mui/material/Select";
import { Checkbox, CircularProgress, InputLabel, ListItemText, MenuItem, OutlinedInput } from "@mui/material";
import moment from "moment";
import CoverageEvent from "../types/coverageEvent";
import { useAppContext } from "../Context";
//import { Select as MSelect } from "@mui/material/Select";


const CoverageShiftForm = props => {
	const { patientID } = useParams()

	const appContext = useAppContext();

	const [clientServiceTypes, setClientServiceTypes] = useState([]);

	const [clientHomes, setClientHomes] = useState([]);

	const [clientDetails, setClientDetails] = useState(null);

	const [showShiftLoading, setShowShiftLoading] = useState(false);

	useEffect(() => {
		const fetchServiceTypes = async () => {
			const serviceTypes = await getClientServiceType(patientID, appContext.handleAPICallError);

			console.log(serviceTypes);

			setClientServiceTypes(serviceTypes);
		}

		const fetchClientHomes = async () => {
			const clientHomes = await getClientHomes(patientID, appContext.handleAPICallError);

			console.log(clientHomes);

			setClientHomes(clientHomes);
		}

		const fetchClientDetails = async () => {
			const clientDetails = await getClientByID(patientID, appContext.handleAPICallError);

			console.log(clientDetails);

			setClientDetails(clientDetails);
		}

		fetchServiceTypes()
			.catch(console.error);

		fetchClientHomes()
			.catch(console.error);

		fetchClientDetails()
			.catch(console.error);
	}, [patientID])

    const [values, setValues] = useState<any>({
		"date": "",
		"start_time": "",
		"end_time": "",
		"shift_type": "",
		"shift_location": "",
	});

	let navigate = useNavigate();

    const date = useRef();
	const start_time = useRef();
	const end_time = useRef();
	const shift_type = useRef();
	const shift_location = useRef();
	const shift_recurrence = useRef();
	const repeat_end_date = useRef();

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const menuprops = {
	PaperProps: {
		style: {
		maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		width: 250,
		},
	},
	};

	const repeats = [
		'Repeats Sunday',
		'Repeats Monday',
		'Repeats Tuesday',
		'Repeats Wednesday',
		'Repeats Thursday',
		'Repeats Friday',
		'Repeats Saturday',
	];

	const [repeatName, setRepeatName] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof repeatName>) => {
    const {
      target: { value },
    } = event;
    setRepeatName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const saveNewLegacyShift = async () => {
	console.log(values)

	const found = clientServiceTypes.find(element => element.shiftType === values.shift_type);

	const startTime = moment(values.date + " " + values.start_time)
	const endTime = moment(values.date + " " + values.end_time)

	let newCoverage: CoverageEvent = {
		coverageEventID: uuid(),
		patientID: clientDetails.patientID,
		patientName: "",
		providerID: "",
		staffSize: found.staffSize,
		groupSize: found.groupSize,
		billingCode: found.billingCode,
		coverageDate: moment(values.date).format(),
		providerName: "",
		shiftType: found.shiftType,
		shiftTheme: 0,
		startTime: startTime.format(),
		displayStartTime: values.start_time,
		endTime: endTime.format(),
		displayEndTime: values.end_time,
		displayCoverageDate: "",
		status: "Open"
	}

	console.log(newCoverage);

	setShowShiftLoading(true);

	const result = await saveLegacyEvent(newCoverage, appContext.handleAPICallError);

	setShowShiftLoading(false);

	navigate(-1);
  }

  const discardLegacyShift = async () => {
	navigate(-1);
  }

    useEffect(() => {
		console.log(values);
	}, [values])

    return (<>
        {/* <Core> */}
        <div className="tw-px-xl tw-py-xl">
				{/* <div className="tw-flex tw-items-center tw-flex tw-border-b tw-border-light tw-pb">
					<div className="tw-flex-grow tw-flex-shrink-0">
						<PageHeader>
							<Ellipsis>
								Client Calendar
							</Ellipsis>
						</PageHeader>
					</div>
				</div> */}
				<div className="tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">New Legacy Client Shift</h2>
                </div>
                <div className="tw-px-xl tw-pb-xl tw-text-dark">
                    <div>
                        <div>
                        <form className="tw-p-0 tw-m-0 tw-border-0">
							<PageSubHeader className="tw-py-xl" tag="legend">
								New Shift - {clientDetails?.firstName} {clientDetails?.lastName}
							</PageSubHeader>
							<div className="tw-space-y">

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Date
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
                                        <Input
										 min="2000-01-01" max="2024-12-31"
										type="date" ref={ date }
										value={ values.date } onChange={ e => setValues({...values, "date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Start Time
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Input id="start_time" type="time" onChange={ e => setValues({...values, "start_time": e.target.value }) } ref={ start_time } value={ values.start_time } alt className="tw-flex-grow tw-max-w-300" />
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
												End Time
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Input id="end_time" type="time" onChange={ e => setValues({...values, "end_time": e.target.value }) } ref={ end_time } value={ values.end_time } alt className="tw-flex-grow tw-max-w-300" />
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Shift Type
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
											<Select id="shift_type" onChange={ e => setValues({...values, "shift_type": e.target.value }) } ref={ shift_type } value={ values.shift_type } alt className="tw-flex-grow tw-max-w-500">
												<option value="">--Select--</option>
												{
													clientServiceTypes.map(service =>
														<option value={service.shiftType}>{service.shiftType}</option>)
												}
											</Select>
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="shift_location" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Location
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
											<Select id="shift_location" onChange={ e => setValues({...values, "shift_location": e.target.value }) } ref={ shift_type } value={ values.shift_location } alt className="tw-flex-grow tw-max-w-500">
												<option value="">--Select--</option>
												{/* <option value="custom">Custom</option> */}
												{
													clientHomes.map(home =>
														<option value={home.state}>{home.street1}, {home.street2 ? home.street2 + "," : "" } {home.city}, {home.state} - {home.postalCode}</option>)
												}
											</Select>
										</div>
									</div>
								</div>

								{

									values["shift_location"] === "custom" ? <>
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="shift_location" className="tw-flex-grow" childClasses={ pageSubHeader }>
													Street1
												</Label>
											</div>
											<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
												<Input />
											</div>
										</div>
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="shift_location" className="tw-flex-grow" childClasses={ pageSubHeader }>
													Street2
												</Label>
											</div>
											<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
												<Input />
											</div>
										</div>
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="shift_location" className="tw-flex-grow" childClasses={ pageSubHeader }>
													City
												</Label>
											</div>
											<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
												<Input />
											</div>
										</div>
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="shift_location" className="tw-flex-grow" childClasses={ pageSubHeader }>
													State
												</Label>
											</div>
											<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
												<Input />
											</div>
										</div>
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="shift_location" className="tw-flex-grow" childClasses={ pageSubHeader }>
													Zip Code
												</Label>
											</div>
											<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
												<Input />
											</div>
										</div>
									</>:<></>
								}

								{/* <div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
											<Label for="shift_recurrence" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Recurrence
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p">
											<Select id="shift_recurrence" onChange={ e => setValues({...values, "shift_recurrence": e.target.value }) } ref={ shift_type } value={ values.shift_recurrence } alt className="tw-flex-grow tw-max-w-500">
												<option value="">--Select--</option>
												<option value="Never">Never</option>
												<option value="Daily">Daily</option>
												<option value="Weekly">Weekly</option>
												<option value="DaysOfWeek">Days Of Week</option>
											</Select>
										</div>
									</div>
								</div> */}

								{/* {values["shift_recurrence"] !== "" && values["shift_recurrence"] !== "Never" ? <>
									<div className="-tw-m">
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
												<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
													Repeat End Date
												</Label>
											</div>
											<div className="tw-flex-grow tw-flex tw-items-start tw-p">
											<Input
											min="2000-01-01" max="2024-12-31"
											type="date" ref={ repeat_end_date }
											value={ values.repeat_end_date } onChange={ e => setValues({...values, "repeat_end_date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
											</div>
										</div>
									</div>
								</>:<></>} */}

								

								{values["shift_recurrence"] === "DaysOfWeek" ? <>
								<div className="-tw-m">
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
													Repeats
												</Label>
											</div>
											<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
												<Select
												labelId="demo-multiple-checkbox-label"
												id="demo-multiple-checkbox"
												multiple
												value={repeatName}
												onChange={handleChange}
												input={<OutlinedInput label="Repeats" />}
												renderValue={(selected) => selected.join(', ')}
												MenuProps={menuprops}
												>
												{repeats.map((name) => (
													<MenuItem key={name} value={name}>
													<Checkbox checked={repeatName.indexOf(name) > -1} />
													<ListItemText primary={name} />
													</MenuItem>
												))}
											</Select>
											</div>
										</div>
									</div>
								</>:<></>}

							</div>
							<div className="tw-pt-xxl tw-pb-xl">
								<ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
									{showShiftLoading ? 
									<>
									<li>
										<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
									</li>
									
									</>: <>
									<li>
										<ButtonSecondary large className="tw-px tw-min-w-200" onClick={ e => {
											discardLegacyShift();
											e.preventDefault();
											
										}}>
											Discard
										</ButtonSecondary>
									</li>
									<li>
										<ButtonPrimary large className="tw-px tw-min-w-200" onClick={ e => {
											saveNewLegacyShift();
											e.preventDefault();
										}}>
											Save
										</ButtonPrimary>
									</li>
									</>}
									
								</ul>
							</div>
						</form>
                        </div>
                    </div>
                </div>
		</div>
        {/* </Core> */}
    </>);
}

export default CoverageShiftForm