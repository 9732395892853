import axios, { AxiosRequestConfig } from "axios";
import { getAPIToken } from "./billingService";
import TimeOffRequest from "../types/timeOffRequest";
import moment from "moment";
import Employee from "../types/employee";

export const handleIncomingRequests =  (request: TimeOffRequest): TimeOffRequest => {
    request.displayStartDate = moment.utc(request.startDate).format("MM-DD-YYYY");
    request.displayEndDate = moment.utc(request.endDate).format("MM-DD-YYYY");

    request.displaySubmittedDate = moment.utc(request.submittedDate).format("MM-DD-YYYY");

    if(request.directSupportProvider && request.remoteSupportAssociate){
        request.role = "PCA & RSA";
    } else if(request.directSupportProvider){
        request.role = "PCA";
    } else if(request.remoteSupportAssociate){
        request.role = "RSA";
    } else {
        request.role = "N/A";
    }

    return request;
}

export const getAllRequestsForSpan = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<TimeOffRequest[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    var spanStart = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD');
    var spanEnd = moment(endDate).add(1, 'days').format('YYYY-MM-DD');

    const url = baseURL+"timeOff/" + spanStart + "/" + spanEnd;
    return axios.get<TimeOffRequest[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                if(response.data){
                    return response.data.map(handleIncomingRequests);
                } else {
                    return [];
                }
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getAllActiveProviders = async (handleAPICallError: (error: any) => void) : Promise<Employee[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"providers/active";
    return axios.get<Employee[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                if(response.data){
                    return response.data;
                } else {
                    return [];
                }
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}