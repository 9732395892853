import { Dialog as TWDialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useContext, createContext, useState, useMemo, type Dispatch, Fragment, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

export const AppContext = createContext({
	isSideNavOpen: false,
	openSideNav: () => {},
	closeSideNav: () => {},
	sideNavTheme: 1,
	setSideNavTheme: (() => undefined) as Dispatch<any>,
	sideNavTitle: 1,
	setSideNavTitle: (() => undefined) as Dispatch<any>,
	sideNav: 1,
	setSideNav: (() => undefined) as Dispatch<any>,
	panelBackBtn: null,
	setPanelBackBtn: (() => undefined) as Dispatch<any>,
	panelSize: '',
	setPanelSize: (() => undefined) as Dispatch<any>,
	avatar: 'images/placeholder_1x1.png',
	setAvatar: (() => undefined) as Dispatch<any>,
	name: { firstName: '', lastName: '' },
	setName: (() => undefined) as Dispatch<any>,
	showBilling: false,
	setShowBilling: (() => undefined) as Dispatch<any>,
	showScheduling: false,
	setShowScheduling: (() => undefined) as Dispatch<any>,
	showDirectCare: false,
	setShowDirectCare: (() => undefined) as Dispatch<any>,
	showRemoteSupport: false,
	setShowRemoteSupport: (() => undefined) as Dispatch<any>,
	showTesting: false,
	setShowTesting: (() => undefined) as Dispatch<any>,
	showTraining: false,
	setShowTraining: (() => undefined) as Dispatch<any>,
	showHR: false,
	setShowHR: (() => undefined) as Dispatch<any>,
	showProviderAvailability: false,
	setShowProviderAvailability: (() => undefined) as Dispatch<any>,
	showSuccessModal: false,
	setShowSuccessModal: (() => undefined) as Dispatch<any>,
	modalMessageHeader: '',
	setModalMessageHeader: (() => undefined) as Dispatch<any>,
	modalMessageBody: '',
	setModalMessageBody: (() => undefined) as Dispatch<any>,
	showErrorModal: false,
	setShowErrorModal: (() => undefined) as Dispatch<any>,
	navigateToPreviousPage: false,
	setNavigateToPreviousPage: (() => undefined) as Dispatch<any>,
	handleAPICallError: (error: any) => {},
	sideNavActions: null,
	setSideNavActions: (() => undefined) as Dispatch<any>,
	sideNavFormik: null,
	setSideNavFormik: (() => undefined) as Dispatch<any>,
	values: null,
	setValues: (() => undefined) as Dispatch<any>,
	errors: null,
	setErrors: (() => undefined) as Dispatch<any>,
	errorMessages: null,
	setErrorMessages: (() => undefined) as Dispatch<any>,
	fieldStates: null,
	setFieldStates: (() => undefined) as Dispatch<any>,
	sideNavBottom: null,
	setSideNavBottom: (() => undefined) as Dispatch<any>,
	scheduleAdmin: false,
	setScheduleAdmin: (() => undefined) as Dispatch<any>,
	hrAdmin: false,
	setHrAdmin: (() => undefined) as Dispatch<any>,
	clientScheduleAdmin: false,
	setClientScheduleAdmin: (() => undefined) as Dispatch<any>,
	sessionScheduleAdmin: false,
	setSessionScheduleAdmin: (() => undefined) as Dispatch<any>,

});

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
	/* Nav Context */
	const [isSideNavOpen, setisSideNavOpen] = useState<boolean>(false);
	const [sideNavTheme, setSideNavTheme] = useState<any>(3);
	const [sideNav, setSideNav] = useState<any>(1);
	const [sideNavTitle, setSideNavTitle] = useState<any>("navContext.sideNavTitle");
	const [panelSize, setPanelSize] = useState<any>('tw-max-w-400px');
	const [showBilling, setShowBilling] = useState<boolean>(false);
	const [showScheduling, setShowScheduling] = useState<boolean>(false);
	const [showDirectCare, setShowDirectCare] = useState<boolean>(false);
	const [showRemoteSupport, setShowRemoteSupport] = useState<boolean>(false);
	const [showTesting, setShowTesting] = useState<boolean>(false);
	const [showTraining, setShowTraining] = useState<boolean>(false);
	const [showHR, setShowHR] = useState<boolean>(false);
	const [showProviderAvailability, setShowProviderAvailability] = useState<boolean>(false);
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [modalMessageHeader, setModalMessageHeader] = useState<string>('');
	const [modalMessageBody, setModalMessageBody] = useState<string>('');
	const [navigateToPreviousPage, setNavigateToPreviousPage] = useState<boolean>(false);
	const [sideNavActions, setSideNavActions] = useState<any>();
	const [sideNavFormik, setSideNavFormik] = useState<any>();
	const [panelBackBtn, setPanelBackBtn] = useState<any>();
	const [values, setValues] = useState<any>();
	const [errors, setErrors] = useState<any>();
	const [errorMessages, setErrorMessages] = useState<any>();
	const [fieldStates, setFieldStates] = useState<any>();
	const [sideNavBottom, setSideNavBottom] = useState<any>(null);
	const [scheduleAdmin, setScheduleAdmin] = useState<any>(false);
	const [sessionScheduleAdmin, setSessionScheduleAdmin] = useState<any>(false);
	const [hrAdmin, setHrAdmin] = useState<any>(false);
	const [clientScheduleAdmin, setClientScheduleAdmin] = useState<any>(false);

	const navigate = useNavigate();

	// useEffect(() => {
	// 	console.log("Context Values:");
	// 	console.log(values);
	// }, [values]);

	const handleSuccessModalClose = () => {
		setShowSuccessModal(false);
		if(navigateToPreviousPage){
			navigate(-1);
		}
		setNavigateToPreviousPage(false);
	}

	const handleErrorModalClose = () => {
		setShowErrorModal(false);
		setShowSuccessModal(false);
	}

	const openSideNav = () => setisSideNavOpen(true);
	const closeSideNav = () => setisSideNavOpen(false);

	/* User Context */
	const [avatar, setAvatar] = useState<any>(null);
	const [name, setName] = useState<any>(null);

	const handleAPICallError = (error: any) => {

		// console.log("API Call Error:");
		// console.log(error);

		const errorMessage = error.data ? error.data : 'Unknown error';
	
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			setShowSuccessModal(false);
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			setModalMessageHeader("Something went wrong!");
			setModalMessageBody("There was an error processing your request. Received an unexpected reponse code: " + error.response.status);
			setShowErrorModal(true);
		  } else if (error.status) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			setShowSuccessModal(false);
			console.log(error.status);
			setModalMessageHeader("Something went wrong!");
			setModalMessageBody("There was an error processing your request. Received an unexpected reponse code: " + error.status + ". Error message: " + errorMessage);
			setShowErrorModal(true);
		  } else if (error.request) {
			// The request was made but no response was received
			console.log(error.request);
			setShowSuccessModal(false);
			setModalMessageHeader("Something went wrong!");
			setModalMessageBody("There was an error processing your request. No Response was received from server.");
			setShowErrorModal(true);
		  } else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
			setShowSuccessModal(false);
			setModalMessageHeader("Something went wrong!");
			setModalMessageBody("There was an error processing your request. Error message: " + error.message);
			setShowErrorModal(true);
		  }
		  console.log(error.config);
	}

	const value = useMemo(() => ({
		isSideNavOpen: isSideNavOpen,
		openSideNav,
		closeSideNav,
		sideNavTheme, setSideNavTheme,
		sideNavTitle, setSideNavTitle,
		panelSize, setPanelSize,
		sideNav, setSideNav,
		sideNavActions, setSideNavActions,
		avatar, setAvatar,
		name, setName,
		values, setValues,
		errors, setErrors,
		errorMessages, setErrorMessages,
		fieldStates, setFieldStates,
		showBilling, setShowBilling,
		showScheduling, setShowScheduling,
		showDirectCare, setShowDirectCare,
		showRemoteSupport, setShowRemoteSupport,
		showTesting, setShowTesting,
		showTraining, setShowTraining,
		showHR, setShowHR,
		showProviderAvailability, setShowProviderAvailability,
		showSuccessModal, setShowSuccessModal,
		showErrorModal, setShowErrorModal,
		modalMessageHeader, setModalMessageHeader,
		modalMessageBody, setModalMessageBody,
		navigateToPreviousPage, setNavigateToPreviousPage,
		panelBackBtn, setPanelBackBtn,
		sideNavFormik, setSideNavFormik,
		sideNavBottom, setSideNavBottom,
		scheduleAdmin, setScheduleAdmin,
		hrAdmin, setHrAdmin,
		clientScheduleAdmin, setClientScheduleAdmin,
		sessionScheduleAdmin, setSessionScheduleAdmin,
		handleAPICallError
	}), [isSideNavOpen, sideNavBottom, avatar, name, showBilling, showScheduling, showDirectCare, showRemoteSupport, showTesting, showTraining, showHR, showProviderAvailability, showSuccessModal, navigateToPreviousPage, modalMessageHeader, modalMessageBody, showErrorModal, sideNavActions, values, errors, errorMessages, fieldStates, sideNav, panelBackBtn, sideNavTheme, sideNavTitle, panelSize, sideNavFormik, scheduleAdmin, hrAdmin, clientScheduleAdmin, sessionScheduleAdmin]);


	return (
		<AppContext.Provider value={ value }>
			<ErrorBoundary navigate={navigate}>
			<Transition.Root show={showErrorModal} as={Fragment}>
				<TWDialog as="div" className="tw-relative tw-z-10" onClose={handleErrorModalClose}>
					<Transition.Child
					as={Fragment}
					enter="tw-ease-out tw-duration-300"
					enterFrom="tw-opacity-0"
					enterTo="tw-opacity-100"
					leave="tw-ease-in tw-duration-200"
					leaveFrom="tw-opacity-100"
					leaveTo="tw-opacity-0"
					>
					<div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
					</Transition.Child>

					<div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
					<div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
						<Transition.Child
						as={Fragment}
						enter="tw-ease-out tw-duration-300"
						enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
						enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leave="tw-ease-in tw-duration-200"
						leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
						>
						<TWDialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-lg sm:tw-p-6">
							<div className="tw-absolute tw-right-0 tw-top-0 tw-hidden tw-pr-4 tw-pt-4 sm:tw-block">
							<button
								type="button"
								className="tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2"
								onClick={handleErrorModalClose}
							>
								<span className="tw-sr-only">Close</span>
								<XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
							</button>
							</div>
							<div className="sm:tw-flex sm:tw-items-start">
							<div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
								<ExclamationTriangleIcon className="tw-h-6 tw-w-6 tw-text-red-600" aria-hidden="true" />
							</div>
							<div className="tw-mt-3 tw-text-center sm:tw-ml-4 sm:tw-mt-0 sm:tw-text-left">
								<TWDialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
								{modalMessageHeader}
								</TWDialog.Title>
								<div className="tw-mt-2">
								<p className="tw-text-sm tw-text-gray-500">
									{modalMessageBody}
								</p>
								</div>
							</div>
							</div>
							<div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
							<button
								type="button"
								className="tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 sm:tw-mt-0 sm:tw-w-auto"
								onClick={handleErrorModalClose}
							>
								Close
							</button>
							</div>
						</TWDialog.Panel>
						</Transition.Child>
					</div>
					</div>
				</TWDialog>
    		</Transition.Root>
			<Transition.Root show={showSuccessModal} as={Fragment}>
				<TWDialog as="div" className="tw-relative tw-z-10" onClose={handleSuccessModalClose}>
					<Transition.Child
					as={Fragment}
					enter="tw-ease-out tw-duration-300"
					enterFrom="tw-opacity-0"
					enterTo="tw-opacity-100"
					leave="tw-ease-in tw-duration-200"
					leaveFrom="tw-opacity-100"
					leaveTo="tw-opacity-0"
					>
					<div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
					</Transition.Child>

					<div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
						<div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
							<Transition.Child
							as={Fragment}
							enter="tw-ease-out tw-duration-300"
							enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
							enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
							leave="tw-ease-in tw-duration-200"
							leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
							leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
							>
							<TWDialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6">
								<div>
								<div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100">
									<CheckIcon className="tw-h-6 tw-w-6 tw-text-green-600" aria-hidden="true" />
								</div>
								<div className="tw-mt-3 tw-text-center sm:tw-mt-5">
									<TWDialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
									{modalMessageHeader}
									</TWDialog.Title>
									<div className="tw-mt-2">
									<p className="tw-text-sm tw-text-gray-500">
										{modalMessageBody}
									</p>
									</div>
								</div>
								</div>
								<div className="tw-mt-5 sm:tw-mt-6">
								<button
									type="button"
									className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-primary tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-indigo-600"
									onClick={handleSuccessModalClose}
								>
									OK
								</button>
								</div>
							</TWDialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</TWDialog>
			</Transition.Root>
			{ children }
			</ErrorBoundary>
		</AppContext.Provider>
	);
}
