import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
//import { ThemeProvider } from '@mui/core/styles';
import { theme } from "./styles/theme";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Import from @sentry/browser
import * as Sentry from "@sentry/browser";

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { ThemeProvider } from '@mui/material';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    console.log(accounts);
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

Sentry.init({
    dsn: "https://1e10d354db235fa1c9c40ed20c70b607@o4505716267876352.ingest.sentry.io/4505716433616896",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https://admin-dev.thankfulgrass-c82ee804.eastus.azurecontainerapps.io/", "https://my-container-app.thankfulgrass-c82ee804.eastus.azurecontainerapps.io/", "https://scheduling-service.thankfulgrass-c82ee804.eastus.azurecontainerapps.io/"],
      }),
      new Sentry.Replay({
        "maskAllText": true,
        "blockAllMedia": true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<BrowserRouter><App pca={ msalInstance } /></BrowserRouter>);

// ReactDOM.render(
//   <Router>
//       <ThemeProvider theme={theme}>
//           <App pca={msalInstance} />
//       </ThemeProvider>
//   </Router>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

