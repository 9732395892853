import { useEffect, useState } from "react";
import { AppContext, useAppContext } from "../../Context";
import { CircleLabel } from "../calendars/CalendarUtils";
import { ButtonPrimary } from "../buttons/Buttons";
import { LabelOutputs, Span } from "../utilities/Helpers";
import CoverageEvent from "../../types/coverageEvent";
import { getLegacyEventByID } from "../../apicalls/schedulingService";

const DirectSupportShiftPanel = props => {
    const navContext = useAppContext();
    const [toggleRequiredInformation, setToggleRequiredInformation] = useState<boolean>(true);
    const [shift, setShift] = useState<CoverageEvent>(props.shift || null);

    const [icon, setIcon] = useState<string>('/images/icon-pie-_Fully-Open.svg');

    const [providerName, setProviderName] = useState<string>("");

    useEffect(() => {
        if(props.shift.status === "Open"){
            setIcon('/images/icon-pie-_Fully-Open.svg');
        } else if(props.shift.status === "Assigned"){
            setIcon('/images/icon-pie-_Partially-Covered.svg');
        } else {
            setIcon('/images/icon-pie-_Fully-Covered.svg');
        }

        console.log("Shift: ", props.shift);

        const fetchShift = async () => {
            const shiftID = props.shift?.coverageEventID ? props.shift?.coverageEventID : props.shift?.coverageRequirementID;

            const response = await getLegacyEventByID(shiftID, navContext.handleAPICallError);

            console.log("Shift: ", response);

            if(response){
                setProviderName(response.providerName);
            }
        }

        if(props.shift.status !== "Open"){
            fetchShift();
        }
    }, [props.shift])

    
    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
            <div className="tw-flex-shrink-0 tw-w-full tw-overflow-x-hidden tw-overflow-y-auto">
                <div className="tw-p-20px tw-space-y-20px tw-w-full">
                    <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]" onClick={ e => {
                            let temp = toggleRequiredInformation;
                            setToggleRequiredInformation( temp = !temp )
                        }}>
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                <span className="tw-flex-grow">
                                <CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
                                    <span className="tw-text-dark tw-ml-5px">Required Information</span>
                                </CircleLabel>
                                </span>
                                <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/icon-arrow-${ toggleRequiredInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
                                </span>
                            </span>
                    </h3>
                    { toggleRequiredInformation ? <>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">

							{ props.date ? <>
								<li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ props.date }
									</span>
								</li>
							</> : <></>}
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-status.svg" className="tw-opacity-70">
										Status
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									<Span className="tw-flex-grow tw-pl-8">
										{shift?.status}
									</Span>
									<Span className="tw-ml-auto || tw-w-16p tw-h-16p tw-aspect-square tw-relative">
										<img className="tw-block tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-object-cover tw-object-center" src={`${icon}`} alt="Partial Coverage" />
									</Span>
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Support Type
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.shiftType}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Time Period
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.displayStartTime} - {shift?.displayEndTime}
								</span>
							</li>
                            {providerName.length !== 0 && <li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-provider.svg" className="tw-opacity-70">
										Provider
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{providerName}
								</span>
							</li>}
						</ul>

					{(shift.status === "Open") && <div className="tw-flex tw-justify-end tw-pr-20px tw-pb-10px">
						<ButtonPrimary className="tw-min-w-80px" onClick={ e => {
							e.preventDefault();
							//navContext.setSideNav(<EditCoverDetailsPanel changeRequirements shift={shift || null} serviceTypes={props?.serviceTypes || null}  refreshCalendar={props?.refreshCalendar || null}/>); // pass props in this
						}}>
							Generate Recommendations
						</ButtonPrimary>
					</div> }
					</> : <></> }
                </div>
            </div>
        </div>
    </>);
}

export default DirectSupportShiftPanel;