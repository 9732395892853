import { PencilIcon } from "@heroicons/react/24/solid"
import moment from "moment"
import { useEffect, useLayoutEffect, useState } from "react";
import { patientSchema } from "../../pages/NewClientForm";
import Patient from "../../types/patient";
import { useFormik } from "formik";
import { ButtonPrimary, ButtonSecondary, Input } from "@medforall/medforall-component-library";
import { LoadingSpinner } from "./loadingSpinner";
import { updateClientInfo } from "../../apicalls/clientMgmtService";
import { useAppContext } from "../../Context";

export const EditClientPersonalInfo = (props: any) => {
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const appContext = useAppContext();

    useLayoutEffect(() => {
        console.log(props.patient);
    }, [props.patient])

    const toggleEditMode = () => {
        console.log(initialValues);

        setEditMode(!editMode);
    }

    const initialValues = {
        FirstName: props?.patient?.firstName,
          LastName: props?.patient?.lastName,
          DateOfBirth: new Date(moment(props?.patient?.dateOfBirth).format("MM-DD-YYYY")).toISOString().substring(0,10) || '',
          Phone: props?.patient?.phone,
          State: props?.patient?.state,
          County: props?.patient?.county,
          InternalEmail: props?.patient?.email,
          PersonalEmail: props?.patient?.personalEmail,
          ServiceType: props?.patient?.serviceType,
          SocialSecurityNumber: props?.patient?.socialSecurityNumber,
    }

    const patientInfoFormik = useFormik({
        initialValues: initialValues,
        validationSchema: patientSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Form submitted")
            console.log(values);
            const updatedPatient: Patient = {
                firstName: values.FirstName,
                lastName: values.LastName,
                dateOfBirth: new Date(values.DateOfBirth),
                phone: values.Phone,
                state: values.State,
                county: values.County,
                internalEmail: values.InternalEmail,
                personalEmail: values.PersonalEmail,
                serviceType: values.ServiceType,
                socialSecurityNumber: values.SocialSecurityNumber,
                patientID: props.patient.patientID,
                contacts: [],
                evvServices: [],
                email: values.InternalEmail,
                patientDetailID: '',
                active: false,
                patientDetail: undefined,
                testPatient: false,
                insurance: undefined
            };

            console.log(updatedPatient);

            setLoading(true);
            var result = await updateClientInfo(updatedPatient, appContext.handleAPICallError);
            setLoading(false);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Client Info Updated Successfully");
                appContext.setShowSuccessModal(true);
            }

            props?.refreshClient();

            toggleEditMode();
        },
        enableReinitialize: true
      });

    
    return (<>
        <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6">

            {editMode ? <>
                <div className="tw-pb-5 tw-flex tw-px-4">
                    <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Personal Info</span>
                </div>

                <div className="tw-space-y-6 tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        First Name
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.FirstName && patientInfoFormik.errors.FirstName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('FirstName')}
                                        />
                                        {patientInfoFormik.touched.FirstName && patientInfoFormik.errors.FirstName && <p className="tw-text-red-500"><>{patientInfoFormik.errors.FirstName}</></p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Last Name
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.LastName && patientInfoFormik.errors.LastName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('LastName')}
                                        />
                                        {patientInfoFormik.touched.LastName && patientInfoFormik.errors.LastName && <p className="tw-text-red-500"><>{patientInfoFormik.errors.LastName}</></p>}
                                    </div>
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Date of Birth
                                    </label>
                                    <div className="tw-mt-2">
                                        <Input
                                        min="2000-01-01" max="2024-12-31"
                                        type="date"
                                        name="dateOfBirth"
                                        id="dateOfBirth"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.DateOfBirth && patientInfoFormik.errors.DateOfBirth ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('DateOfBirth')}
                                        />
                                        {patientInfoFormik.touched.DateOfBirth && patientInfoFormik.errors.DateOfBirth && <p className="tw-text-red-500"><>{patientInfoFormik.errors.DateOfBirth}</></p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Phone
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.Phone && patientInfoFormik.errors.Phone ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('Phone')}
                                        />
                                        {patientInfoFormik.touched.Phone && patientInfoFormik.errors.Phone && <p className="tw-text-red-500"><>{patientInfoFormik.errors.Phone}</></p>}
                                    </div>
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        State
                                    </label>
                                    <select
                                        id="state"
                                        name="state"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.State && patientInfoFormik.errors.State ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('State')}
                                    >
                                        <option value="">Select State</option>
                                        <option value="Ohio">Ohio</option>
                                    </select>
                                    {patientInfoFormik.touched.State && patientInfoFormik.errors.State && <p className="tw-text-red-500"><>{patientInfoFormik.errors.State}</></p>}

                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        County
                                    </label>
                                    <select
                                        id="county"
                                        name="county"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.County && patientInfoFormik.errors.County ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('County')}
                                    >
                                        <option value="">Select County</option>
                                        <option value="Franklin">Franklin</option>
                                        <option value="Cuyahoga">Cuyahoga</option>
                                        <option value="Licking">Licking</option>
                                    </select>
                                    {patientInfoFormik.touched.County && patientInfoFormik.errors.County && <p className="tw-text-red-500"><>{patientInfoFormik.errors.County}</></p>}
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Internal Email
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="email"
                                        name="internalMail"
                                        id="internalMail"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.InternalEmail && patientInfoFormik.errors.InternalEmail ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('InternalEmail')}
                                        />
                                        {patientInfoFormik.touched.InternalEmail && patientInfoFormik.errors.InternalEmail && <p className="tw-text-red-500"><>{patientInfoFormik.errors.InternalEmail}</></p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Personal Email
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="email"
                                        name="personalMail"
                                        id="personalMail"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.PersonalEmail && patientInfoFormik.errors.PersonalEmail ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('PersonalEmail')}
                                        />
                                        {patientInfoFormik.touched.PersonalEmail && patientInfoFormik.errors.PersonalEmail && <p className="tw-text-red-500"><>{patientInfoFormik.errors.PersonalEmail}</></p>}
                                    </div>
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Service Type
                                    </label>
                                    <select
                                        id="serviceType"
                                        name="serviceType"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.ServiceType && patientInfoFormik.errors.ServiceType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('ServiceType')}
                                    >
                                        <option value="">Select Service Type</option>
                                        <option value="Remote Support">Remote Support</option>
                                        <option value="Direct Support">Direct Support</option>
                                        <option value="Hybrid">Hybrid Support</option>
                                    </select>
                                    {patientInfoFormik.touched.ServiceType && patientInfoFormik.errors.ServiceType && <p className="tw-text-red-500"><>{patientInfoFormik.errors.ServiceType}</></p>}
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Social Security Number
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="ssn"
                                        id="ssn"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.SocialSecurityNumber && patientInfoFormik.errors.SocialSecurityNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('SocialSecurityNumber')}
                                        />
                                        {patientInfoFormik.touched.SocialSecurityNumber && patientInfoFormik.errors.SocialSecurityNumber && <p className="tw-text-red-500"><>{patientInfoFormik.errors.SocialSecurityNumber}</></p>}
                                    </div>
                                </div>


                            </div>
                </div>

                {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>

                <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleEditMode}>
                                                {(patientInfoFormik.dirty) ? 'Discard' : 'Cancel'}
                                            </ButtonSecondary>
                                            {(patientInfoFormik.dirty) && <ButtonPrimary className="tw-mt-md" onClick={patientInfoFormik.handleSubmit}>
                                                Update
                                            </ButtonPrimary>}

                </div>
                </>}
            </>:<>
            
            <div className="tw-pb-5 tw-flex tw-px-4">
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Personal Info</span>

                <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={toggleEditMode} />
            </div>

            <div className="tw-flex tw-px-4 tw-pb-2">
                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>First Name</span>
                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.patient?.firstName}</span>
            </div>
            <div className="tw-flex tw-px-4 tw-pb-2">
                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Last Name</span>
                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.patient?.lastName}</span>
            </div>
            <div className="tw-flex tw-px-4 tw-pb-2">
                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Date Of Birth</span>
                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{moment(props?.patient?.dateOfBirth).format("MM-DD-YYYY")}</span>
            </div>
            <div className="tw-flex tw-px-4 tw-pb-2">
                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Phone</span>
                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.patient?.phone}</span>
            </div>
            <div className="tw-flex tw-px-4 tw-pb-2">
                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>State</span>
                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.patient?.state}</span>
            </div>
            <div className="tw-flex tw-px-4 tw-pb-2">
                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>County</span>
                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.patient?.county}</span>
            </div>
            <div className="tw-flex tw-px-4 tw-pb-2">
                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Internal Email</span>
                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.patient?.email}</span>
            </div>
            <div className="tw-flex tw-px-4 tw-pb-2">
                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Personal Email</span>
                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.patient?.personalEmail}</span>
            </div>
            <div className="tw-flex tw-px-4 tw-pb-2">
                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Service Type</span>
                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.patient?.serviceType}</span>
            </div>
            </>}

            

        </div>
    </>)
}