import { ButtonPrimary, ButtonSecondary, Input } from "@medforall/medforall-component-library";
import { useState } from "react"
import { useEditPlanContext } from "../../pages/EditServicePlanPage";
import { useFormik } from "formik";
import * as Yup from 'yup';
import moment from "moment";
import ServicePlan from "../../types/servicePlan";
import { updateServicePlan } from "../../apicalls/servicePlanService";
import { useAppContext } from "../../Context";
import { LoadingSpinner } from "./loadingSpinner";


export const EditPlanCard = props => {
    const [editMode, setEditMode] = useState(false);
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    }

    const planFormik = useFormik({
        initialValues: {
            fiscalYear: editPlanContext.servicePlan?.fiscalYear || '',
            waiverType: editPlanContext.servicePlan?.waiverType || '',
            spanStartDate: new Date(editPlanContext.servicePlan?.startDate).toISOString().substring(0,10) || '',
            spanEndDate: new Date (editPlanContext.servicePlan?.endDate).toISOString().substring(0,10) || '',
            aboutClient: editPlanContext.servicePlan?.aboutClient || '',
            status: editPlanContext.servicePlan?.status || '',
        },
        validationSchema: Yup.object().shape({
            fiscalYear: Yup.string().required('Fiscal year is required.'),
            waiverType: Yup.string().required('Waiver type is required.'),
            spanStartDate: Yup.date()
                //.min(Yup.ref('spanEndDate'), 'Span start date must be before span end date.')
                .required('Span start date is required.'),
            spanEndDate: Yup.date()
                .min(Yup.ref('spanStartDate'), 'Span end date must be after span start date.')
                .required('Span end date is required.'),
            aboutClient: Yup.string().required('About client is required.'),
            status: Yup.string().required('Status is required.'),
                    }),

        onSubmit: async (values) => {
          // handle form submission
            console.log("Plan Form submitted")
            console.log(values);
            var newServicePlan: ServicePlan = {
                fiscalYear: values.fiscalYear,
                waiverType: values.waiverType,
                startDate: moment(values.spanStartDate).format(),
                endDate: moment(values.spanEndDate).format(),
                aboutClient: values.aboutClient,
                planID: "",
                servicePlanID: editPlanContext.servicePlan?.servicePlanID || "",
                patientID: editPlanContext.clientID || "",
                status: values.status,
                displayStartDate: moment(values.spanStartDate).format("MM/DD/YYYY"),
                displayEndDate: moment(values.spanEndDate).format("MM/DD/YYYY"),
                authorizedServices: [],
                supportAreas: [],
                desiredOutcomes: [],
                remoteSupportEvents: [],
                suggestedActions: [],
                remoteSupportRoutines: [],
                unusualEvents: []
            }

            setLoading(true);

            var result = await updateServicePlan(newServicePlan, editPlanContext.servicePlan?.servicePlanID, appContext.handleAPICallError)
            await editPlanContext.updateLocalPlan();
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Service plan updated successfully.");
                appContext.setShowSuccessModal(true);
            }
            
            setLoading(false);
            toggleEditMode();
        },
    });

    return (<>
        {editMode ? <>
            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Basic Info</span>
                        <div className="tw-space-y-6 tw-bg-white tw-p-6">
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Fiscal Year
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="fiscal-year"
                                        id="fiscal-year"
                                        className={`tw-block tw-w-full tw-rounded-md ${planFormik.touched.fiscalYear && planFormik.errors.fiscalYear ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...planFormik.getFieldProps('fiscalYear')}
                                        />
                                        {planFormik.touched.fiscalYear && planFormik.errors.fiscalYear && <p className="tw-text-red-500">{planFormik.errors.fiscalYear}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Waiver Type
                                    </label>
                                    <select
                                        id="waiver-type"
                                        name="waiver-type"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${planFormik.touched.waiverType && planFormik.errors.waiverType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...planFormik.getFieldProps('waiverType')}
                                    >
                                        <option value="">Select a waiver type</option>
                                        <option value="Individual Option">Individual Option</option>
                                        <option value="Level One">Level One</option>
                                        <option value="SELF">SELF</option>
                                    </select>
                                    {planFormik.touched.waiverType && planFormik.errors.waiverType && <p className="tw-text-red-500">{planFormik.errors.waiverType}</p>}
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Span Start
                                    </label>
                                    <div className="tw-mt-2">
                                        <Input
                                            min="2000-01-01" max="2024-12-31"
                                            type="date"
                                            name="spanStartDate"
                                            id="spanStartDate"
                                            className={`tw-block tw-w-full tw-rounded-md ${planFormik.touched.spanStartDate && planFormik.errors.spanStartDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...planFormik.getFieldProps('spanStartDate')}
                                            />
                                            {planFormik.touched.spanStartDate && planFormik.errors.spanStartDate && <p className="tw-text-red-500">{planFormik.errors.spanStartDate}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Span End
                                    </label>
                                    <div className="tw-mt-2">
                                        <Input
                                            min="2000-01-01" max="2024-12-31"
                                            type="date"
                                            name="spanEndDate"
                                            id="spanEndDate"
                                            className={`tw-block tw-w-full tw-rounded-md ${planFormik.touched.spanEndDate && planFormik.errors.spanEndDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...planFormik.getFieldProps('spanEndDate')}
                                            />
                                            {planFormik.touched.spanEndDate && planFormik.errors.spanEndDate && <p className="tw-text-red-500">{planFormik.errors.spanEndDate}</p>}
                                    </div>
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Status
                                    </label>
                                    <select
                                        id="status"
                                        name="status"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${planFormik.touched.status && planFormik.errors.status ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...planFormik.getFieldProps('status')}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Draft">Draft</option>
                                        <option value="Archived">Archived</option>
                                    </select>
                                    {planFormik.touched.status && planFormik.errors.status && <p className="tw-text-red-500">{planFormik.errors.status}</p>}

                                </div>

                                <div className="tw-col-span-2">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        About {editPlanContext.patient.firstName}
                                    </label>
                                    <textarea
                                        rows={4}
                                        name="aboutClient"
                                        id="aboutClient"
                                        className={`tw-block tw-w-full tw-rounded-md ${planFormik.touched.aboutClient && planFormik.errors.aboutClient ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-text-gray-900 tw-px-2 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-py-1.5 sm:tw-text-sm sm:tw-leading-6`}
                                        {...planFormik.getFieldProps('aboutClient')}
                                        />
                                        {planFormik.touched.aboutClient && planFormik.errors.aboutClient && <p className="tw-text-red-500">{planFormik.errors.aboutClient}</p>}
                                </div>
                            </div>
                        </div>

                            <div className="tw-flex tw-justify-end tw-mx-4">
                                    {loading ? <>
                                        <LoadingSpinner />
                                    </>:<>
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleEditMode}>
                                                Discard
                                        </ButtonSecondary>
                                        {planFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={planFormik.handleSubmit}>
                                            Save
                                        </ButtonPrimary>}
                                    </>}
                                    

                        </div>
                    </div>
        </>:<>
        <div className="tw-bg-white tw-flex tw-border-2 tw-border-primary tw-shadow tw-rounded-lg tw-p-6 tw-mt-4">
                        <div className="tw-w-4/5">
                            <div className="tw-flex tw-px-4 tw-py-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Fiscal Year</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{editPlanContext.servicePlan?.fiscalYear}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Waiver Type</span>
                                    <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{editPlanContext.servicePlan?.waiverType}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Starts</span>
                                    <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{editPlanContext.servicePlan?.displayStartDate}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Ends</span>
                                    <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{editPlanContext.servicePlan?.displayEndDate}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Status</span>
                                    <div className='tw-w-4/5 tw-flex'>
                                        <span className='tw-text-sm tw-text-gray-900 tw-font-semibold tw-leading-4'>{editPlanContext.servicePlan?.status}</span>
                                        {/* {editPlanContext.servicePlan?.status === "Draft" && <div className="tw-py-0 tw-px-2">
                                            <svg className="tw-h-2 tw-w-2 tw-fill-gray-500" viewBox="0 0 6 6" aria-hidden="true">
                                            <circle cx={3} cy={3} r={3} />
                                            </svg>
                                        </div>}
                                        {editPlanContext.servicePlan?.status === "Active" && <div className="tw-py-0 tw-px-2">
                                            <svg className="tw-h-2 tw-w-2 tw-fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                                            <circle cx={3} cy={3} r={3} />
                                            </svg>
                                        </div>}
                                        {editPlanContext.servicePlan?.status === "Archived" && <div className="tw-py-0 tw-px-2">
                                            <svg className="tw-h-2 tw-w-2 tw-fill-black" viewBox="0 0 6 6" aria-hidden="true">
                                            <circle cx={3} cy={3} r={3} />
                                            </svg>
                                        </div>} */}
                                    </div>
                                    
                            </div>

                        </div>
                        <div className="tw-w-1/5">
                            {editPlanContext.servicePlan.status !== "Archived" && <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mx-4" onClick={toggleEditMode}>
                                                    Edit
                                        </ButtonSecondary>
                            </div>}
                        </div>
                            
                            
                </div>
        </>}
    </>);

}