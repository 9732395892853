import { useLayoutEffect, useState } from "react";
import AssignedEvent from "../../types/assignedEvent";
import CoverageEvent from "../../types/coverageEvent";
import { ButtonErrorSecondary, ButtonPrimary, ButtonSecondary } from "../buttons/Buttons";
import { Span } from "../utilities/Span";
import ManageSessionShiftsPanel from "./ManageSessionShiftsPanel";
import { ManageSessionShiftsBottomPanel } from "./PanelCTAs";
import { useAppContext } from "../../Context";
import SessionInfoPanel from "./SessionInfoPanel";
import RemoteSupportSession from "../../types/remoteSupportSession";
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import { LabelOutputs } from "../utilities/Helpers";
import { getRemoteSupportSessionByID, reassignShiftToDedicatedSession } from "../../apicalls/sessionService";
import moment from "moment";

const MoveShiftToSessionPanel = (props) => {
    const navContext = useAppContext();

    const [selectedSession, setSelectedSession] = useState(null);
    const [sessionShifts, setSessionShifts] = useState<AssignedEvent[]>(null);
    const [shiftToMove, setShiftToMove] = useState<CoverageEvent>(null);
    const [movableShifts, setMovableShifts] = useState<CoverageEvent[]>(null);
    const [concurrentSessions, setConcurrentSessions] = useState<RemoteSupportSession[]>(null);

    const [reassigningShift, setReassigningShift] = useState(false);

    const [showReassignSucces, setShowReassignSuccess] = useState(false);

    useLayoutEffect(() => {
        console.log("Selected Session: ", props.selectedSession);

        if(props?.selectedSession) {
            setSelectedSession(props?.selectedSession);
            setSessionShifts(props?.sessionShifts);
        }

        if(props?.movableShifts){
            setMovableShifts(props?.movableShifts);
        }

        if(props?.shiftToMove){
            setShiftToMove(props?.shiftToMove);
        }

        if(props?.concurrentSessions){
            setConcurrentSessions(props?.concurrentSessions);
        }
    }, [props.selectedSession, props.sessionShifts, props.movableShifts, props.shiftToMove, props.concurrentSessions]);

    const discardSessionChanges = () => {

        navContext.setSideNav( <SessionInfoPanel selectedSession={selectedSession} updateSession={props?.updateSession} removeSession={props?.removeSession} updateShiftCount={props?.updateShiftCount}/>);
		navContext.setSideNavTheme(3);
		navContext.setSideNavTitle("Session Details");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
    }

    const cancelMoveShiftToSession = () => {
        navContext.setSideNav(<ManageSessionShiftsPanel selectedSession={selectedSession} sessionShifts={sessionShifts} movableShifts={movableShifts} concurrentSessions={concurrentSessions} updateSession={props?.updateSession} removeSession={props?.removeSession} updateShiftCount={props?.updateShiftCount}/>);
        navContext.setSideNavTheme(3);
        navContext.setSideNavTitle("Manage Session Shifts");
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(<ManageSessionShiftsBottomPanel onCancel={discardSessionChanges} />);
    }

    const handleBackToManageShifts = () => {
        navContext.setSideNav(<ManageSessionShiftsPanel selectedSession={selectedSession} sessionShifts={sessionShifts} updateSession={props?.updateSession} removeSession={props?.removeSession} updateShiftCount={props?.updateShiftCount}/>);
        navContext.setSideNavTheme(3);
        navContext.setSideNavTitle("Manage Session Shifts");
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(<ManageSessionShiftsBottomPanel onCancel={discardSessionChanges} />);
    }

    const handleShiftReassign = async () => {
        setReassigningShift(true);

        const result = await reassignShiftToDedicatedSession(selectedSession?.remoteSupportSessionID, shiftToMove, navContext.handleAPICallError);

		const sessionByID = await getRemoteSupportSessionByID(selectedSession?.remoteSupportSessionID, navContext.handleAPICallError);

        console.log("Updated Session Shifts");
        console.log(sessionByID.assignedShifts);

		setSessionShifts(sessionByID.assignedShifts);

        if(props?.updateShiftCount){
            props.updateShiftCount(sessionByID, sessionByID?.assignedShifts?.length);
        }

        setShowReassignSuccess(true);
        setReassigningShift(false);
    } 
    
    
    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full tw-relative">
            <div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
                <div className="tw-flex-shrink-0 tw-w-full">
                <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-10px">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                    <span className="tw-flex-grow">
                                        <span className="tw-text-dark">
                                            Session Information
                                        </span>
                                    </span>
                                </span>
                            </h3>
                            <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-5px tw-leading-tight">
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Provider
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.providerName}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Start Date
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.displayStartDate}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Time Period
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.displayStartTime} - {selectedSession?.displayEndTime}
                                    </span>
                                    {!moment(selectedSession?.startDate).isSame(selectedSession?.endDate) && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
                                </span>}
                                </li>
                            </ul>
                </div>

                <div className="tw-flex-shrink-0 tw-w-full tw-border-t tw-border-t-neutral-400 tw-pt-20px">
                    <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-10px">
                        <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                            <span className="tw-flex-grow">
                                <span className="tw-text-primary">
                                    Shift To Be Reassigned
                                </span>
                            </span>
                        </span>
                    </h3>
                    <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-5px tw-leading-tight">
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Client
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {shiftToMove?.patientName}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Original Provider
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {shiftToMove?.providerName}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Coverage Date
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {shiftToMove?.displayCoverageDate}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Time Period
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {shiftToMove?.displayStartTime} - {shiftToMove?.displayEndTime}
                                    </span>
                                </li>
                    </ul>
                </div>

                {showReassignSucces ? <>
                    <div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-my-auto tw-py-20px">
						<div className="tw-space-y-15px tw-text-center">
							<LabelOutputs className="tw-w-full tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
								<span className="tw-text-5">
									Success! Shift successfully reassigned!
								</span>
							</LabelOutputs>
							<ButtonSecondary className="tw-w-200px" onClick={ e => {
								e.preventDefault();
                                handleBackToManageShifts();
								//navContext.closeSideNav();
							}}>
								Back to Manage Shifts
							</ButtonSecondary>
						</div>
					</div>
                </>:<>
                 {reassigningShift ? <>
                    <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                        <LoadingSpinner /> 
                        <span className="tw-text-primary tw-text-5 tw-text-center">Reassigning Shift</span>
                    </div>
                 </> : <>
                    <div>
						<h3 className="tw-font-primary-bold tw-font-5 tw-text-dark tw-py-20px">
							Are you sure you want to reassign <Span>this shift?</Span>
						</h3>
						<div className="tw-flex tw-justify-between tw-space-x-60px">
							<ButtonErrorSecondary className="tw-basis-1/2" onClick={ e => {
								e.preventDefault();
                                cancelMoveShiftToSession();
								// navContext.setPanelSize('tw-max-w-400px');
								// navContext.setSideNav( <CoverageDetailsPanel date={moment(shift.displayCoverageDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={ props?.serviceTypes || null}/> );
								// navContext.setSideNavActions(null);
								// navContext.setSideNavBottom(null);
							}}>
								No, Cancel
							</ButtonErrorSecondary>
							<ButtonPrimary className="tw-basis-1/2" onClick={ e => {
                                e.preventDefault();
                                handleShiftReassign();
                                //handleSessionDelete();
                            }}>
								Yes, Reassign
							</ButtonPrimary>
						</div>
					</div>
                 </>}
                </>}

                    
            </div>
        </div>
    </>);
}

export default MoveShiftToSessionPanel;