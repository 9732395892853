import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useAppContext } from "../../Context";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { addSmartHubToLocation, deleteSmartHubInLocation, updateSmartCameraInLocation, updateSmartHubInLocation } from "../../apicalls/serviceLocationService";
import { SmartHub } from "../../types/serviceLocation";
import { LoadingSpinner } from "./loadingSpinner";

const validationSchema = Yup.object().shape({
    HubName: Yup.string().required('Hub Name is required'),
    HubType: Yup.string().required('Hub Type is required'),
    CloudProvider: Yup.string().required('Cloud Provider is required'),
    HostName: Yup.string().required('Host Name is required'),
    SerialNumber: Yup.string().required('Serial Number is required'),
    UserName: Yup.string().required('Username is required'),
    Password: Yup.string().required('Password is required'),
    MessageChannel: Yup.string().required('Message Channel is required'),
  });

export const EditLocationSmartHubsCard = props => {
    const [showHubForm, setShowHubForm] = useState(false);
    const [editId, setEditId] = useState<string | null>(null);

    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const openHubForm = () => {
        hubFormik.resetForm();
        setShowHubForm(true);
    }

    const closeHubForm = () => {
        setShowHubForm(false);
        hubFormik.resetForm();
    }

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, updatedHub: SmartHub) {
        
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    function handleDelete(){
        // Delete the contact
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    const initialValues = {
        HubName: '',
        HubType: '',
        CloudProvider: '',
        HostName: '',
        SerialNumber: '',
        UserName: '',
        Password: '',
        MessageChannel: '',
      };

    const hubFormik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Hub Form submitted");
            console.log(values);
            setLoading(true);

            var newHub: SmartHub = {
                smartHubID: "",
                serviceLocationID: "",
                hubName: values.HubName,
                hubType: values.HubType,
                cloudProvider: values.CloudProvider,
                hostName: values.HostName,
                serialNumber: values.SerialNumber,
                userName: values.UserName,
                password: values.Password,
                messageChannel: values.MessageChannel,
                status: ""
            }

            console.log(props?.location);
            console.log(props?.location?.serviceLocationID);

            var result = await addSmartHubToLocation(props?.location?.serviceLocationID, newHub, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Smart Hub added successfully.");
                appContext.setShowSuccessModal(true);
            }
            setLoading(false);
            props?.refreshLocation(props?.location?.serviceLocationID);
            closeHubForm();
        },
    });

    return (<>
        <div className="tw-mt-4">
                    <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Hubs</span>
                            {!showHubForm && <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={openHubForm}/> }
                    </div>
                    {showHubForm && <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">New Smart Hub</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Hub Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.HubName && hubFormik.errors.HubName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('HubName')}
                                                />
                                                {hubFormik.touched.HubName && hubFormik.errors.HubName && <p className="tw-text-red-500">{hubFormik.errors.HubName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Hub Type
                                            </label>
                                            <select
                                                id="state"
                                                name="state"
                                                className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${hubFormik.touched.HubType && hubFormik.errors.HubType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('HubType')}
                                            >
                                                <option value="">Select Hub Type</option>
                                                <option value="Jetson Nano 4G">Jetson Nano 4G</option>
                                                <option value="Jetson Nano 2G">Jetson Nano 2G</option>
                                                <option value="Jetson Xavier NX">Jetson Xavier NX</option>
                                                <option value="Jetson Xavier AGX">Jetson Xavier AGX</option>
                                                <option value="Stereolabs TX2-NX">Stereolabs TX2-NX</option>
                                                <option value="Stereolabs Xavier NX">Stereolabs Xavier NX</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            {hubFormik.touched.HubType && hubFormik.errors.HubType && <p className="tw-text-red-500">{hubFormik.errors.HubType}</p>}

                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Cloud Provider
                                            </label>
                                            <select
                                                id="state"
                                                name="state"
                                                className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${hubFormik.touched.CloudProvider && hubFormik.errors.CloudProvider ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('CloudProvider')}
                                            >
                                                <option value="">Select Cloud Provider</option>
                                                <option value="AWS">AWS</option>
                                                <option value="Azure">Azure</option>
                                                <option value="Google Cloud">Google Cloud</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            {hubFormik.touched.CloudProvider && hubFormik.errors.CloudProvider && <p className="tw-text-red-500">{hubFormik.errors.CloudProvider}</p>}

                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Host Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.HostName && hubFormik.errors.HostName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('HostName')}
                                                />
                                                {hubFormik.touched.HostName && hubFormik.errors.HostName && <p className="tw-text-red-500">{hubFormik.errors.HostName}</p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                User Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.UserName && hubFormik.errors.UserName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('UserName')}
                                                />
                                                {hubFormik.touched.UserName && hubFormik.errors.UserName && <p className="tw-text-red-500">{hubFormik.errors.UserName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Password
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.Password && hubFormik.errors.Password ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('Password')}
                                                />
                                                {hubFormik.touched.Password && hubFormik.errors.Password && <p className="tw-text-red-500">{hubFormik.errors.Password}</p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Serial Number
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.SerialNumber && hubFormik.errors.SerialNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('SerialNumber')}
                                                />
                                                {hubFormik.touched.SerialNumber && hubFormik.errors.SerialNumber && <p className="tw-text-red-500">{hubFormik.errors.SerialNumber}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Message Channel
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.MessageChannel && hubFormik.errors.MessageChannel ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('MessageChannel')}
                                                />
                                                {hubFormik.touched.MessageChannel && hubFormik.errors.MessageChannel && <p className="tw-text-red-500">{hubFormik.errors.MessageChannel}</p>}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={closeHubForm}>
                                                        Cancel
                                                </ButtonSecondary>
                                                <ButtonPrimary className="tw-mt-md" onClick={hubFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>

                                        </div> 
                                    </>}
                                </div>}

                    {props?.hubs?.length > 0 &&  props?.hubs?.map((hub, index) => {
                            if(index !== props?.hubs?.length - 1) {
                                return <>
                                    {hub.smartHubID === editId ? <EditHubCard hub={hub} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} location={props?.location}/> : <ViewHubCard hub={hub} handleEdit={handleEdit} />}
                                    
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                </>
                            } else {
                                return <>
                                    {hub.smartHubID === editId ? <EditHubCard hub={hub} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} location={props?.location}/> : <ViewHubCard hub={hub} handleEdit={handleEdit} />}
                                </>
                            }
                            })}

                        
        </div>
    </>);
}

export const EditHubCard = props => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const confirmDelete = () => {
        setShowConfirmDelete(true);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const deleteHub = async () => {
        setLoading(true);
        var result = await deleteSmartHubInLocation(props?.location?.serviceLocationID, props?.hub?.smartHubID, appContext.handleAPICallError);

        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Smart hub deleted successfully.");
            appContext.setShowSuccessModal(true);
        }

        props?.handleDelete();

        setLoading(false);
    }

    const initialValues = {
        HubName: props?.hub.hubName,
        HubType: props?.hub.hubType,
        CloudProvider: props?.hub.cloudProvider,
        HostName: props?.hub.hostName,
        SerialNumber: props?.hub.serialNumber,
        UserName: props?.hub.userName,
        Password: props?.hub.password,
        MessageChannel: props?.hub.messageChannel,
    };

    const hubFormik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Hub Form submitted");
            console.log(values);
            setLoading(true);

            var newHub: SmartHub = {
                smartHubID: props?.hub?.smartHubID,
                serviceLocationID: props?.location?.serviceLocationID,
                hubName: values.HubName,
                hubType: values.HubType,
                cloudProvider: values.CloudProvider,
                hostName: values.HostName,
                serialNumber: values.SerialNumber,
                userName: values.UserName,
                password: values.Password,
                messageChannel: values.MessageChannel,
                status: ""
            }

            var result = await updateSmartHubInLocation(props?.location?.serviceLocationID, newHub, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Smart Hub updated successfully.");
                appContext.setShowSuccessModal(true);
            }
            setLoading(false);

            props?.handleSave(newHub);



        },
    });
    
    return (<>
            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Smart Hub</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Hub Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.HubName && hubFormik.errors.HubName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('HubName')}
                                                />
                                                {hubFormik.touched.HubName && hubFormik.errors.HubName && <p className="tw-text-red-500"><>{hubFormik.errors.HubName}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Hub Type
                                            </label>
                                            <select
                                                id="state"
                                                name="state"
                                                className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${hubFormik.touched.HubType && hubFormik.errors.HubType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('HubType')}
                                            >
                                                <option value="">Select Hub Type</option>
                                                <option value="Jetson Nano 4G">Jetson Nano 4G</option>
                                                <option value="Jetson Nano 2G">Jetson Nano 2G</option>
                                                <option value="Jetson Xavier NX">Jetson Xavier NX</option>
                                                <option value="Jetson Xavier AGX">Jetson Xavier AGX</option>
                                                <option value="Stereolabs TX2-NX">Stereolabs TX2-NX</option>
                                                <option value="Stereolabs Xavier NX">Stereolabs Xavier NX</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            {hubFormik.touched.HubType && hubFormik.errors.HubType && <p className="tw-text-red-500"><>{hubFormik.errors.HubType}</></p>}

                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Cloud Provider
                                            </label>
                                            <select
                                                id="state"
                                                name="state"
                                                className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${hubFormik.touched.CloudProvider && hubFormik.errors.CloudProvider ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('CloudProvider')}
                                            >
                                                <option value="">Select Cloud Provider</option>
                                                <option value="AWS">AWS</option>
                                                <option value="Azure">Azure</option>
                                                <option value="Google Cloud">Google Cloud</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            {hubFormik.touched.CloudProvider && hubFormik.errors.CloudProvider && <p className="tw-text-red-500"><>{hubFormik.errors.CloudProvider}</></p>}

                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Host Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.HostName && hubFormik.errors.HostName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('HostName')}
                                                />
                                                {hubFormik.touched.HostName && hubFormik.errors.HostName && <p className="tw-text-red-500"><>{hubFormik.errors.HostName}</></p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                User Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.UserName && hubFormik.errors.UserName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('UserName')}
                                                />
                                                {hubFormik.touched.UserName && hubFormik.errors.UserName && <p className="tw-text-red-500"><>{hubFormik.errors.UserName}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Password
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.Password && hubFormik.errors.Password ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('Password')}
                                                />
                                                {hubFormik.touched.Password && hubFormik.errors.Password && <p className="tw-text-red-500"><>{hubFormik.errors.Password}</></p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Serial Number
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.SerialNumber && hubFormik.errors.SerialNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('SerialNumber')}
                                                />
                                                {hubFormik.touched.SerialNumber && hubFormik.errors.SerialNumber && <p className="tw-text-red-500"><>{hubFormik.errors.SerialNumber}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Message Channel
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${hubFormik.touched.MessageChannel && hubFormik.errors.MessageChannel ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...hubFormik.getFieldProps('MessageChannel')}
                                                />
                                                {hubFormik.touched.MessageChannel && hubFormik.errors.MessageChannel && <p className="tw-text-red-500"><>{hubFormik.errors.MessageChannel}</></p>}
                                            </div>
                                        </div>
                                        
                                    </div>
                                
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner />
                                        </div>
                                    </> : <>
                                    {showConfirmDelete ? <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                        <p className="tw-text-primary">Are you sure you want to delete this smart hub?</p>
                                        </div>
                                    
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deleteHub}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={confirmDelete}>
                                                    Delete
                                            </ButtonSecondary>
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.handleCancel}>
                                                        {hubFormik.dirty ? "Discard": "Cancel" }
                                                </ButtonSecondary>
                                                {hubFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={hubFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>}

                                    </div>
                                    </>}
                                    </>}

            </div>
    </>);
}

export const ViewHubCard = props => {
    return (<>
         <div className="tw-bg-grey-light tw-flex tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
        <div className="tw-w-4/5 tw-flex-grow">
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Hub Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.hub.hubName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Hub Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.hub.hubType}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Cloud Provider</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.hub.cloudProvider}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Host Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.hub.hostName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>User Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.hub.userName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Password</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.hub.password}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Serial Number</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.hub.serialNumber}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Message Channel</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.hub.messageChannel}</span>
                                            </div>
                                        

        </div>
        <div className="tw-w-1/5 tw-flex tw-justify-end tw-items-center">
                        <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={() => props?.handleEdit(props?.hub.smartHubID)} />
            </div>  
            </div>
    </>);
}