import { useFormik } from "formik";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import * as Yup from 'yup';
import { useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { ActionStep, DesiredOutcome } from "../../types/servicePlan";
import { v4 as uuidv4 } from 'uuid';
import { EditDesiredOutcomeList } from "../portalComponents/editDesiredOutcomeCard";

export const ActionStepSchema = Yup.object().shape({
    stepTitle: Yup.string().required('Step title is required.'),
    stepDetails: Yup.string().required('Step details are required.'),
    frequency: Yup.string().required('Frequency is required.'),
    responsibleParty: Yup.string().required('Responsible party is required.'),
});

export const ExpectedOutcomeSchema = Yup.object().shape({
    expectedOutcomeDescription: Yup.string().required('Expected Outcome Description is required.'),
});

export const DesiredOutcomeSchema = Yup.object().shape({
    outcomeTitle: Yup.string().required('Outcome title is required.'),
    outcomeDetails: Yup.string().required('Outcome details is required.'),
    outcomeProgress: Yup.string().required('Outcome progress are required.'),
    actionSteps: Yup.array().of(ActionStepSchema)
                    .min(1, "Please add at least one action step.")
                    .required('At least one action step is required.'),
    expectedOutcomes: Yup.array().of(ExpectedOutcomeSchema)
                    .min(1, "Please add at least one expected outcome.")
                    .required('At least one expected outcome is required.'),
});

const NewDesiredOutcomeSection = () => {
    const planContext = useNewPlanContext();
    const [showGoalCard, setShowGoalCard] = useState(false);

    const toggleGoalCard = () => {
        setShowGoalCard(!showGoalCard);
    }

    const desiredOutcomeFormik = useFormik({
        initialValues: {
            outcomeTitle: '',
            outcomeDetails: '',
            outcomeProgress: '',
            actionSteps: [],
            expectedOutcomes: [],
        },
        validationSchema: DesiredOutcomeSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted")
            console.log(values);

            var newSteps: ActionStep [] = [];
            values.actionSteps.forEach((step) => {
                newSteps.push({
                    actionStepTitle: step.stepTitle,
                    actionStepDetails: step.stepDetails,
                    frequency: step.frequency,
                    responsibleParty: step.responsibleParty,
                    actionStepID: uuidv4(),
                    desiredOutcomeID: ""
                });
            });

            var newOutcomes: any [] = [];
            values.expectedOutcomes.forEach((outcome) => {
                newOutcomes.push({
                    expectedOutcomeDescription: outcome.expectedOutcomeDescription,
                    expectedOutcomeID: uuidv4(),
                    desiredOutcomeID: ""
                });
            });

            var newDesiredOutcome: DesiredOutcome = {
                outcomeTitle: values.outcomeTitle,
                outcomeDetails: values.outcomeDetails,
                outcomeProgress: values.outcomeProgress,
                desiredOutcomeID: uuidv4(),
                actionSteps: newSteps,
                expectedOutcomes: newOutcomes,
                servicePlanID: ""
            }

            planContext.setPlanDesiredOutcomes([...planContext.planDesiredOutcomes, newDesiredOutcome]);
            desiredOutcomeFormik.resetForm();
            actionStepFormik.resetForm();
            expectedOutcomeFormik.resetForm();
            toggleGoalCard();

            desiredOutcomeFormik.setFieldValue('actionSteps', []);
            desiredOutcomeFormik.setFieldValue('expectedOutcomes', []);
        },
    });

    const actionStepFormik = useFormik({
        initialValues: {
            stepTitle: '',
            stepDetails: '',
            frequency: '',
            responsibleParty: '',
        },
        validationSchema: ActionStepSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            desiredOutcomeFormik.values.actionSteps.push(values);
            desiredOutcomeFormik.validateField('actionSteps');
            actionStepFormik.resetForm();
        },
    });

    const expectedOutcomeFormik = useFormik({
        initialValues: {
            expectedOutcomeDescription: '',
        },
        validationSchema: ExpectedOutcomeSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            desiredOutcomeFormik.values.expectedOutcomes.push(values);
            desiredOutcomeFormik.validateField('expectedOutcomes');
            expectedOutcomeFormik.resetForm();
        },
    });

    const handleRemoveExpectedOutcome = (outcome) => {
        const index = desiredOutcomeFormik.values.expectedOutcomes.indexOf(outcome);
        desiredOutcomeFormik.values.expectedOutcomes.splice(index, 1);
        desiredOutcomeFormik.setFieldValue('expectedOutcomes', desiredOutcomeFormik.values.expectedOutcomes);
    }

    const handleRemoveActionStep = (step) => {
        const index = desiredOutcomeFormik.values.actionSteps.indexOf(step);
        desiredOutcomeFormik.values.actionSteps.splice(index, 1);
        desiredOutcomeFormik.setFieldValue('actionSteps', desiredOutcomeFormik.values.actionSteps);
    }

    const handleRemoveDesiredOutcome = (outcome) => {
        const index = planContext.planDesiredOutcomes.indexOf(outcome);
        const newItems = [...planContext.planDesiredOutcomes.slice(0, index), ...planContext.planDesiredOutcomes.slice(index + 1)];
        planContext.setPlanDesiredOutcomes(newItems);
    }

    return (<>
        {showGoalCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-title"
                                                id="outcome-title"
                                                className={`tw-block tw-w-full tw-rounded-md ${desiredOutcomeFormik.touched.outcomeTitle && desiredOutcomeFormik.errors.outcomeTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...desiredOutcomeFormik.getFieldProps('outcomeTitle')}
                                                />
                                                {desiredOutcomeFormik.touched.outcomeTitle && desiredOutcomeFormik.errors.outcomeTitle && <p className="tw-text-red-500">{desiredOutcomeFormik.errors.outcomeTitle}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Details
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-details"
                                                id="outcome-details"
                                                className={`tw-block tw-w-full tw-rounded-md ${desiredOutcomeFormik.touched.outcomeDetails && desiredOutcomeFormik.errors.outcomeDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...desiredOutcomeFormik.getFieldProps('outcomeDetails')}
                                                />
                                                {desiredOutcomeFormik.touched.outcomeDetails && desiredOutcomeFormik.errors.outcomeDetails && <p className="tw-text-red-500">{desiredOutcomeFormik.errors.outcomeDetails}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Progress
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-progress"
                                                id="outcome-progress"
                                                className={`tw-block tw-w-full tw-rounded-md ${desiredOutcomeFormik.touched.outcomeProgress && desiredOutcomeFormik.errors.outcomeProgress ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...desiredOutcomeFormik.getFieldProps('outcomeProgress')}
                                                />
                                                {desiredOutcomeFormik.touched.outcomeProgress && desiredOutcomeFormik.errors.outcomeProgress && <p className="tw-text-red-500">{desiredOutcomeFormik.errors.outcomeProgress}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Action Steps</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-title"
                                                    id="step-title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.stepTitle && actionStepFormik.errors.stepTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('stepTitle')}
                                                    />
                                                    {actionStepFormik.touched.stepTitle && actionStepFormik.errors.stepTitle && <p className="tw-text-red-500">{actionStepFormik.errors.stepTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-details"
                                                    id="step-details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.stepDetails && actionStepFormik.errors.stepDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('stepDetails')}
                                                    />
                                                    {actionStepFormik.touched.stepDetails && actionStepFormik.errors.stepDetails && <p className="tw-text-red-500">{actionStepFormik.errors.stepDetails}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.frequency && actionStepFormik.errors.frequency ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('frequency')}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {actionStepFormik.touched.frequency && actionStepFormik.errors.frequency && <p className="tw-text-red-500">{actionStepFormik.errors.frequency}</p>}
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.responsibleParty && actionStepFormik.errors.responsibleParty ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('responsibleParty')}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {actionStepFormik.touched.responsibleParty && actionStepFormik.errors.responsibleParty && <p className="tw-text-red-500">{actionStepFormik.errors.responsibleParty}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={actionStepFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={actionStepFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>

                                    <div>
                                        {desiredOutcomeFormik.touched.actionSteps && desiredOutcomeFormik.errors.actionSteps && <p className="tw-text-red-500"><>{desiredOutcomeFormik.errors.actionSteps}</></p>}
                                    </div>

                                    {desiredOutcomeFormik.values.actionSteps.length > 0 && desiredOutcomeFormik.values.actionSteps?.map((actionStep, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 tw-mt-4 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-title"
                                                    id="step-title"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.stepTitle}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-details"
                                                    id="step-details"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.stepDetails}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.frequency}
                                                    disabled={true}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.responsibleParty}
                                                    disabled={true}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveActionStep(actionStep);
                                                        }}>
                                                            Remove
                                                    </ButtonSecondary>

                                        </div>
                                    </div>
                                    ))}

                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Expected Outcomes</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md ${expectedOutcomeFormik.touched.expectedOutcomeDescription && expectedOutcomeFormik.errors.expectedOutcomeDescription ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...expectedOutcomeFormik.getFieldProps('expectedOutcomeDescription')}
                                                    />
                                                    {expectedOutcomeFormik.touched.expectedOutcomeDescription && expectedOutcomeFormik.errors.expectedOutcomeDescription && <p className="tw-text-red-500">{expectedOutcomeFormik.errors.expectedOutcomeDescription}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={expectedOutcomeFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={expectedOutcomeFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>

                                    <div>
                                        {desiredOutcomeFormik.touched.expectedOutcomes && desiredOutcomeFormik.errors.expectedOutcomes && <p className="tw-text-red-500"><>{desiredOutcomeFormik.errors.expectedOutcomes}</></p>}
                                    </div>

                                    {desiredOutcomeFormik.values.expectedOutcomes.length > 0 && desiredOutcomeFormik.values.expectedOutcomes?.map((expectedOutcome, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6 tw-mt-4">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={expectedOutcome.expectedOutcomeDescription}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveExpectedOutcome(expectedOutcome);
                                                        }}>
                                                            Remove
                                                    </ButtonSecondary>

                                        </div>
                                    </div>
                                    ))} 

                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleGoalCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={desiredOutcomeFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleGoalCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Desired Outcome</span>
                                            </div>  
                                    </div>
                            </div>
            </>}

            {/* {planContext.planDesiredOutcomes.length > 0 && planContext.planDesiredOutcomes?.map((outcome, index) => (
                <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mt-4">
                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-title"
                                                id="outcome-title"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={outcome.outcomeTitle}
                                                disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Details
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-details"
                                                id="outcome-details"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={outcome.outcomeDetails}
                                                disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Progress
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-progress"
                                                id="outcome-progress"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={outcome.outcomeProgress}
                                                disabled={true}
                                                />
                                            </div>
                                        </div>
                    </div>
                    
                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Action Steps</span>
                    </div>

                    {outcome.actionSteps.length > 0 && outcome.actionSteps?.map((actionStep, index) => (
                                    <div className="tw-space-y-6 tw-bg-grey-light tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 tw-mt-4 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-title"
                                                    id="step-title"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.actionStepTitle}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-details"
                                                    id="step-details"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.actionStepDetails}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.frequency}
                                                    disabled={true}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.responsibleParty}
                                                    disabled={true}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    ))}

                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Expected Outcomes</span>
                    </div>

                    {outcome.expectedOutcomes.length > 0 && outcome.expectedOutcomes?.map((expectedOutcome, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6 tw-mt-4">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={expectedOutcome.expectedOutcomeDescription}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))} 

                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveDesiredOutcome(outcome);
                                                        }}>
                                                    Remove
                                            </ButtonSecondary>
                        </div>
                </div>
            ))} */}

            <div className="tw-mt-4">
                {planContext.planDesiredOutcomes.length > 0 && <EditDesiredOutcomeList outcomes={planContext.planDesiredOutcomes} newPlan={true}></EditDesiredOutcomeList>}
            </div>
    </>);
};

export default NewDesiredOutcomeSection;