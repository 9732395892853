import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CalendarSelector from "../calendars/CalendarSelector";
import { InputTimes, Label, Select } from "../forms/formFields";
import { useAppContext } from "../../Context";
import { getRemoteSupportAssociates } from "../../apicalls/sessionService";
import moment from "moment";
import Employee from "../../types/employee";
import { isMidnight, removeWhitespace } from "./SchedulePanel";

const EditSessionPanel = props => {
    const navContext = useAppContext();

    const [values, setValues] = useState<any>(navContext.values || {});
	const [originalValues, setOriginalValues] = useState<any>();

    const [activeRSAs, setActiveRSAs] = useState<Employee[]>(null);
    const [selectedSession, setSelectedSession] = useState(null);

    const providerRef = useRef();

    const trainingRef = useRef();

    useEffect(() => {
		//console.log(navContext.values);
		navContext.setValues(values);
		console.log("Schedule Day Panel Values", values);
	}, [values])

    useLayoutEffect(() => {
        console.log("Selected Session: ", props.selectedSession);

        if(props?.selectedSession) {
            const fetchRSAs = async () => {
				const data = await getRemoteSupportAssociates(navContext.handleAPICallError);
				console.log(data);
				setActiveRSAs(data);
			}

            fetchRSAs()
				.catch(console.error);

            setSelectedSession(props?.selectedSession);

            // setValues({
			// 	"start_date": moment.utc(props?.selectedSession.displayStartDate).format("M-D-YYYY"),
			// 	"end_date": moment.utc(props?.selectedSession.displayEndDate).format("M-D-YYYY"),
			// 	"start_time": moment.utc(props?.selectedSession.startTime).format("h:mm A"),
			// 	"end_time": moment.utc(props?.selectedSession.endTime).format("h:mm A"),
			// 	"provider": props?.selectedSession.providerID,
			// 	"supervisor": "",
			// });

			setOriginalValues({
				"start_date": moment.utc(props?.selectedSession.displayStartDate.replace(/-/g, "/")).format("M-D-YYYY"),
				"end_date": moment.utc(props?.selectedSession.displayEndDate.replace(/-/g, "/")).format("M-D-YYYY"),
				"start_time": moment.utc(props?.selectedSession.startTime).format("h:mm A"),
				"end_time": moment.utc(props?.selectedSession.endTime).format("h:mm A"),
				"provider": props?.selectedSession.providerID,
                "training": props?.selectedSession.training,
				"supervisor": "",
			});
        }
    }, [props.selectedSession]);

    const validateStartTime = (start) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));
		console.log("Validate Start Time");

		// check if start_time is valid
		if (start.length === 0 || !moment(start, 'h:mm A', true).isValid()) {
			contextErrors['start_time'] = true;
            contextErrorMessages['start_time'] = 'Invalid time';
		} else {
			contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';
			
			if (values.end_time && moment(values.end_time, 'h:mm A', true).isValid() && moment(start, 'h:mm A', true).isAfter(moment(values.end_time, 'h:mm A', true)) && !isMidnight(moment(values.end_time, 'h:mm A', true)) && moment(values.end_date, 'M-D-YYYY').isSame(moment(values.start_date, 'M-D-YYYY'))) {
				contextErrors['end_time'] = true;
				contextErrorMessages['end_time'] = 'End time must be after start time';
				console.log("End time must be greater than start time in Schedule Day Panel Start Time");
			} else {
				contextErrors['end_time'] = false;
				contextErrorMessages['end_time'] = '';
			}
		}

		console.log(contextErrors);
		console.log(contextErrorMessages);
		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);

	}

    const validateStartDate = (start) => {
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

        console.log("Validate Start Date");

        if (start.length === 0 || !moment(start, 'M-D-YYYY', true).isValid()) {
            contextErrors['start_date'] = true;
            contextErrorMessages['start_date'] = 'Invalid date';
        } else {
            contextErrors['start_date'] = false;
            contextErrorMessages['start_date'] = '';

            if (values.end_date && moment(values.end_date, 'M-D-YYYY', true).isValid() && moment(start, 'M-D-YYYY', true).isAfter(moment(values.end_date, 'M-D-YYYY', true))) {
                contextErrors['end_date'] = true;
                contextErrorMessages['end_date'] = 'End date must be after start date';
                console.log("End date must be greater than start date in Schedule Day Panel Start Date");
            } else {
                contextErrors['end_date'] = false;
                contextErrorMessages['end_date'] = '';
            }
        }
    }

    const validateEndTime = (end) => {
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));
        console.log("Validate End Time");

        if (end.length === 0 || !moment(end, 'h:mm A', true).isValid()) {
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'Invalid time';
        } else {
            contextErrors['end_time'] = false;
            contextErrorMessages['end_time'] = '';

            if (values.start_time && moment(values.start_time, 'h:mm A', true).isValid() && moment(end, 'h:mm A', true).isBefore(moment(values.start_time, 'h:mm A', true)) && !isMidnight(moment(values.end_time, 'h:mm A', true)) && moment(values.end_date, 'M-D-YYYY').isSame(moment(values.start_date, 'M-D-YYYY'))) {
                contextErrors['end_time'] = true;
                contextErrorMessages['end_time'] = 'End time must be after start time';
                console.log("End time must be greater than start time in Schedule Day Panel End Time");
            } else {
                contextErrors['end_time'] = false;
                contextErrorMessages['end_time'] = '';
            }
        }

        console.log(contextErrors);
        console.log(contextErrorMessages);
        navContext.setErrors(contextErrors);
        navContext.setErrorMessages(contextErrorMessages);
    }

    const validateEndDate = (end) => {
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

        console.log("Validate End Date");

        if (end.length === 0 || !moment(end, 'M-D-YYYY', true).isValid()) {
            contextErrors['end_date'] = true;
            contextErrorMessages['end_date'] = 'Invalid date';
        } else {
            contextErrors['end_date'] = false;
            contextErrorMessages['end_date'] = '';

            if (values.start_date && moment(values.start_date, 'M-D-YYYY', true).isValid() && moment(end, 'M-D-YYYY', true).isBefore(moment(values.start_date, 'M-D-YYYY', true))) {
                contextErrors['end_date'] = true;
                contextErrorMessages['end_date'] = 'End date must be after start date';
                console.log("End date must be greater than start date in Schedule Day Panel End Date");
            } else {
                contextErrors['end_date'] = false;
                contextErrorMessages['end_date'] = '';
            }
        }
    }

    const validateProvider = (provider) => {
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

        console.log("Validate Provider");

        if (provider.length === 0) {
            contextErrors['provider'] = true;
            contextErrorMessages['provider'] = 'Please select a provider';
        } else {
            contextErrors['provider'] = false;
            contextErrorMessages['provider'] = '';
        }
    }
    
    useEffect(() => {
		console.log(navContext.fieldStates);

		if(navContext.fieldStates){
			if (navContext.fieldStates['start_date']) {
                validateStartDate(values.start_date);
            }
            if (navContext.fieldStates['end_date']) {
                validateEndDate(values.end_date);
            }
			if (navContext.fieldStates['start_time']) {
				validateStartTime(values.start_time);
			}
			if (navContext.fieldStates['end_time']) {
				validateEndTime(values.end_time);
			}
            if (navContext.fieldStates['provider']) {
                validateProvider(values.provider);
            }
		}

		
	}, [navContext.fieldStates])
    
    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
            <form className="tw-p-20px tw-space-y-40px tw-w-full tw-h-full tw-overflow-y-auto">
                        <div>
							<CalendarSelector id="startDate" className={navContext.errors?.start_date ? '_form-error' : ''} 
                             onBlur={() => navContext.setFieldStates({...navContext.fieldStates, start_date: true})}
                             name="Start Date:" value={ values?.start_date || null } 
                             onChange={ e => {
												
                                console.log(e.target.value);
                                setValues({...values, "start_date": e.target.value});
                                validateStartDate(e.target.value);
                            }}/>
                             {navContext.errors?.date && <p className="tw-text-red-500">{navContext.errorMessages.date}</p>}
							
                            <div className="tw-space-y-20px tw-pt-5px">
								<InputTimes value={values?.start_time} className={navContext.errors?.start_time ? '_form-error' : ''} id="startTime"  label="Start Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
									onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "start_time": true})}
                                    onChange={ e => {
                                        if(removeWhitespace(e.target.value) !== removeWhitespace(values.start_time)) {
                                            console.log("Start Time", e.target.value);
                                            setValues({
                                                ...values,
                                                "start_time": e.target.value
                                            });
                                            validateStartTime(e.target.value);
                                        }
										// setValues({
										// 	...values,
										// 	[e.target.id]: e.target.value
										// })
									}}
								/>
                                {navContext.errors?.start_time && <p className="tw-text-red-500">{navContext.errorMessages.start_time}</p>}
							</div>
						</div>
                        <div>
							<CalendarSelector id="endDate" className={navContext.errors?.end_date ? '_form-error' : ''} 
                            onBlur={() => navContext.setFieldStates({...navContext.fieldStates, end_date: true})}
                            onChange={ e => {
                                console.log(e.target.value);
                                setValues({...values, "end_date": e.target.value});
                                validateEndDate(e.target.value);
                            }
                            }
                             name="End Date:" value={ values?.end_date || null } />
                                {navContext.errors?.end_date && <p className="tw-text-red-500">{navContext.errorMessages.end_date}</p>}
							<div className="tw-space-y-20px tw-pt-5px">
								<InputTimes className={navContext.errors?.end_time ? '_form-error' : ''} value={values?.end_time} id="endTime" label="End Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
									onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "end_time": true})}
                                    onChange={ e => {
										if(removeWhitespace(e.target.value) !== removeWhitespace(values.end_time)) {
                                            console.log("End Time", e.target.value);
                                            setValues({
                                                ...values,
                                                "end_time": e.target.value
                                            });
                                            validateEndTime(e.target.value);
                                        }
									}}
								/>
                                {navContext.errors?.end_time && <p className="tw-text-red-500">{navContext.errorMessages.end_time}</p>}
							</div>
						</div>
                        <div className="tw-space-y-10px">
                            <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
                                    { 'Provider'}
                            </Label>
                        <Select id="session-provider" className={navContext.errors?.provider ? '_form-error': ''} 
                        onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "provider": true})}
                            onChange={ e => {
                                console.log(e.target.value);
                                setValues({...values, "provider": e.target.value});

                                validateProvider(e.target.value);
                            }} ref={ providerRef } value={ values?.provider }>
                            <option value="">Select</option>
                            { activeRSAs?.map((provider, index) => {
                                return <option key={ index } value={ provider.employeeID }>{ provider.firstName } {provider.lastName}</option>
                            })
                            }

                            {/* <option value="With Back-up">With Back-up</option>
                            <option value="Without Back-up">Without Back-up</option> */}
                        </Select>
                        {navContext.errors?.provider && <p className="tw-text-red-500">{navContext.errorMessages.provider}</p>}
			            </div>
                        <div className="tw-space-y-10px">
                            <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
                                    { 'Training'}
                            </Label>
                            <Select id="session-training" className={navContext.errors?.training ? '_form-error': ''}
                                onChange={ e => {
                                    console.log(e.target.value);
                                    setValues({...values, "training": e.target.value === "true"});
                                }} ref={ trainingRef } value={ values?.training ? "true" : "false" }>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </Select>
                            {navContext.errors?.training && <p className="tw-text-red-500">{navContext.errorMessages.training}</p>}
                        </div>
            </form>
        </div>
    </>);
}

export default EditSessionPanel;