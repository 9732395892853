import React, { useEffect, useState } from 'react';
import { LabelOutputs } from '../utilities/Helpers';
import { Holiday } from '../../types/availabilityPeriod';
import moment from 'moment';

interface CheckboxProps {
  holiday?: Holiday;
  isAvailable?: boolean;
  isUnAvailable?: boolean;
  editMode?: boolean;
  onNameChange?: (name: string) => void;
  onCheckboxChange?: (name: string, isChecked: boolean) => void;
}

const CheckboxComponent: React.FC<CheckboxProps> = ({
  holiday = undefined,
  isAvailable = false,
  isUnAvailable = false,
  editMode = false,
  onNameChange,
  onCheckboxChange,
}) => {
  const [inputHoliday, setInputHoliday] = useState(holiday);
  const [availableChecked, setAvailableChecked] = useState(false);
  const [unavailableChecked, setUnavailableChecked] = useState(false);

  useEffect(() => {
    if(isAvailable){
      console.log("Holiday Name:" + inputHoliday?.holidayName);
      console.log("is Available Checked", isAvailable);
      setAvailableChecked(isAvailable);
    }
  },[isAvailable])

  useEffect(() => {
    if(isUnAvailable){
      console.log("Holiday Name:" + inputHoliday?.holidayName);
      console.log("is Unavailable Checked", isUnAvailable);
      setUnavailableChecked(isUnAvailable);
    }
  },[isUnAvailable])

  const handleUnAvailableCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!unavailableChecked) {
      setUnavailableChecked(event.target.checked);
      setAvailableChecked(!event.target.checked);
      onCheckboxChange?.(inputHoliday.holidayName, false);
    }
  }


  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!availableChecked){
      setAvailableChecked(event.target.checked);
      setUnavailableChecked(!event.target.checked);
      onCheckboxChange?.(inputHoliday.holidayName, true);
    }
  };

  return (
    <div className="tw-flex tw-items-center tw-space-x-4">
      <span className="tw-w-500px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
										{inputHoliday?.holidayName + " - " + moment.utc(inputHoliday?.date).format('dddd, MMM Do YYYY')}
										</LabelOutputs>
									</span>
        <span className="tw-ml-2 tw-text-gray-700">Available</span>
        <input
          type="checkbox"
          checked={availableChecked}
          onChange={handleCheckboxChange}
          disabled={!editMode}
          className={`tw-form-checkbox tw-h-5 tw-w-5 ${editMode ? "tw-text-primary":"tw-text-gray-500"} tw-rounded`}
        />
        <span className="tw-ml-2 tw-text-gray-700">Not Available</span>
        <input
          type="checkbox"
          checked={unavailableChecked}
          onChange={handleUnAvailableCheckboxChange}
          disabled={!editMode}
          className={`tw-form-checkbox tw-h-5 tw-w-5 ${editMode ? "tw-text-primary":"tw-text-gray-500"} tw-rounded`}
        />
    </div>
  );
};

export default CheckboxComponent;
