import axios, { AxiosRequestConfig } from "axios";
import ServiceLocation, { HomeCredential, Room, SmartCamera, SmartHub, SmartSensor } from "../types/serviceLocation";
import { getAPIToken } from "./billingService";

export const getAddressLatLng = async (address: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCuvwHziczNGMQZ9eFk6VotWnftc1rDiYI`
      ).catch(error => {
        handleAPICallError(error);
        return null;
      });
    const { lat, lng } = response.data.results[0].geometry.location;

    return { lat, lng };
}

export const addServiceLocation = async (patientID: string, serviceLocation: ServiceLocation, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'client/' + patientID + '/serviceLocation';
    return axios.post(url,serviceLocation,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const findDuplicateLocation = async (serviceLocation: ServiceLocation, handleAPICallError: (error: any) => void) : Promise<ServiceLocation[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/duplicate';
    return axios.post(url,serviceLocation,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getServiceLocationsByPatientID = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<ServiceLocation[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/patient/' + patientID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getServiceLocationByID = async (serviceLocationID: string, handleAPICallError: (error: any) => void) : Promise<ServiceLocation> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateServiceLocation = async (serviceLocationID: string, serviceLocation: ServiceLocation, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID;
    return axios.put(url,serviceLocation,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
            });
}


export const addRoomToLocation = async (serviceLocationID: string, room: Room, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID + '/rooms';
    return axios.post(url,room,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateRoomInLocation = async (serviceLocationID: string, room: Room, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/rooms/' + room.roomID;
    return axios.put(url,room,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const deleteRoomInLocation = async (serviceLocationID: string, roomID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/rooms/' + roomID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const addCredentialToLocation = async (serviceLocationID: string, credential: HomeCredential, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID + '/homeCredentials';
    return axios.post(url,credential,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );
}

export const updateCredentialInLocation = async (serviceLocationID: string, credential: HomeCredential, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/homeCredentials/' + credential.homeCredentialID;
    return axios.put(url,credential,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );
}

export const deleteCredentialInLocation = async (serviceLocationID: string, credentialID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/homeCredentials/' + credentialID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );
}

export const getCredentialByIDForLocation = async (serviceLocationID: string, credentialID: string, handleAPICallError: (error: any) => void) : Promise<HomeCredential> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/homeCredentials/' + credentialID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );
}

export const addSmartHubToLocation = async (serviceLocationID: string, smartHub: SmartHub, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID + '/smartHubs';
    return axios.post(url,smartHub,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );
}

export const updateSmartHubInLocation = async (serviceLocationID: string, smartHub: SmartHub, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/smartHubs/' + smartHub.smartHubID;
    return axios.put(url,smartHub,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );
}

export const deleteSmartHubInLocation = async (serviceLocationID: string, smartHubID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/smartHubs/' + smartHubID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );
}

export const getSmartHubByIDForLocation = async (serviceLocationID: string, smartHubID: string, handleAPICallError: (error: any) => void) : Promise<SmartHub> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/smartHubs/' + smartHubID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );
}

export const addSmartSensorToLocation = async (serviceLocationID: string, smartSensor: SmartSensor, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID + '/smartSensors';
    return axios.post(url,smartSensor,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );

}

export const updateSmartSensorInLocation = async (serviceLocationID: string, smartSensor: SmartSensor, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/smartSensors/' + smartSensor.smartSensorID;
    return axios.put(url,smartSensor,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );

}

export const deleteSmartSensorInLocation = async (serviceLocationID: string, smartSensorID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/smartSensors/' + smartSensorID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );

}

export const getSmartSensorByIDForLocation = async (serviceLocationID: string, smartSensorID: string, handleAPICallError: (error: any) => void) : Promise<SmartSensor> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/smartSensors/' + smartSensorID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );

}

export const addSmartCameraToLocation = async (serviceLocationID: string, smartCamera: SmartCamera, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID + '/smartCameras';
    return axios.post(url,smartCamera,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );

}

export const updateSmartCameraInLocation = async (serviceLocationID: string, smartCamera: SmartCamera, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/smartCameras/' + smartCamera.smartCameraID;
    return axios.put(url,smartCamera,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );

}

export const deleteSmartCameraInLocation = async (serviceLocationID: string, smartCameraID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/smartCameras/' + smartCameraID;
    return axios.delete(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );

}

export const getSmartCameraByIDForLocation = async (serviceLocationID: string, smartCameraID: string, handleAPICallError: (error: any) => void) : Promise<SmartCamera> => {
    const token = await getAPIToken();

    const requestHeader: AxiosRequestConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_CLIENTURL;
    const url = baseURL + 'serviceLocation/' + serviceLocationID +'/smartCameras/' + smartCameraID;
    return axios.get(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          }
        );

}
