import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useAppContext } from "../../Context";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { LoadingSpinner } from "./loadingSpinner";
import { SmartCamera } from "../../types/serviceLocation";
import { addSmartCameraToLocation, deleteSmartCameraInLocation, updateSmartCameraInLocation } from "../../apicalls/serviceLocationService";

const cameraSchema = Yup.object().shape({
    roomID: Yup.string().required('Room ID is required'),
    cameraName: Yup.string().required('Camera Name is required'),
    cameraType: Yup.string().required('Camera Type is required'),
    firmwareVersion: Yup.string().required('Firmware Version is required'),
    macAddress: Yup.string().required('MAC Address is required'),
    localIP: Yup.string().required('Local IP is required'),
    streamName: Yup.string().required('Stream Name is required'),
    userName: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    retentionPeriod: Yup.number()
      .required('Retention Period is required')
      .positive('Retention Period must be a positive number')
      .integer('Retention Period must be an integer'),
});

export const EditLocationSmartCamerasCard = props => {
    const [showCameraForm, setShowCameraForm] = useState(false);
    const [editId, setEditId] = useState<string | null>(null);

    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const openCameraForm = () => {
        cameraFormik.resetForm();
        setShowCameraForm(true);
    }

    const closeCameraForm = () => {
        setShowCameraForm(false);
    }

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, updatedCamera: SmartCamera) {
        
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    function handleDelete(){
        // Delete the contact
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    const initialValues = {
        roomID: '',
        cameraName: '',
        cameraType: '',
        firmwareVersion: '',
        macAddress: '',
        localIP: '',
        streamName: '',
        userName: '',
        password: '',
        retentionPeriod: '',
    };

    const cameraFormik = useFormik({
        initialValues: initialValues,
        validationSchema: cameraSchema,
        onSubmit: async (values) => {
          // handle form submission
          console.log("Camera Form submitted")
            console.log(values)
            setLoading(true);

            var room = props?.rooms.filter(r => r.roomID === values.roomID)[0];

            var newCamera : SmartCamera = {
                smartCameraID: "",
                serviceLocationID: "",
                roomID: room?.roomID,
                roomName: room?.roomName,
                cameraName: values.cameraName,
                cameraType: values.cameraType,
                firmwareVersion: values.firmwareVersion,
                macAddress: values.macAddress,
                localIP: values.localIP,
                streamName: values.streamName,
                userName: values.userName,
                password: values.password,
                retentionPeriod: parseInt(values.retentionPeriod),    
            }

            var result = await addSmartCameraToLocation(props?.location?.serviceLocationID, newCamera, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Smart Camera added successfully.");
                appContext.setShowSuccessModal(true);
            }
            setLoading(false);
            props?.refreshLocation(props?.location?.serviceLocationID);
            closeCameraForm();
            //handleAddRoom(values);
        },
    });

    return (<>
        <div className="tw-mt-4">
                    <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Cameras</span>
                            {!showCameraForm && <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={openCameraForm}/> }
                    </div>

                    {showCameraForm && <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">New Smart Camera</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Camera Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.cameraName && cameraFormik.errors.cameraName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('cameraName')}
                                                />
                                                {cameraFormik.touched.cameraName && cameraFormik.errors.cameraName && <p className="tw-text-red-500">{cameraFormik.errors.cameraName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room
                                            </label>
                                            <select
                                                id="state"
                                                name="state"
                                                className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.roomID && cameraFormik.errors.roomID ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('roomID')}
                                            >
                                                <option value="">Select Room</option>
                                                {props?.rooms?.map((room, index) => {
                                                    return <option key={index} value={room.roomID}>{room.roomName}</option>
                                                })}
                                            </select>
                                            {cameraFormik.touched.roomID && cameraFormik.errors.roomID && <p className="tw-text-red-500">{cameraFormik.errors.roomID}</p>}

                                        </div>
                                        
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Camera Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.cameraType && cameraFormik.errors.cameraType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('cameraType')}
                                                />
                                                {cameraFormik.touched.cameraType && cameraFormik.errors.cameraType && <p className="tw-text-red-500">{cameraFormik.errors.cameraType}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Firmware Version
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.firmwareVersion && cameraFormik.errors.firmwareVersion ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('firmwareVersion')}
                                                />
                                                {cameraFormik.touched.firmwareVersion && cameraFormik.errors.firmwareVersion && <p className="tw-text-red-500">{cameraFormik.errors.firmwareVersion}</p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Mac Address
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.macAddress && cameraFormik.errors.macAddress ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('macAddress')}
                                                />
                                                {cameraFormik.touched.macAddress && cameraFormik.errors.macAddress && <p className="tw-text-red-500">{cameraFormik.errors.macAddress}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Local IP
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.localIP && cameraFormik.errors.localIP ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('localIP')}
                                                />
                                                {cameraFormik.touched.localIP && cameraFormik.errors.localIP && <p className="tw-text-red-500">{cameraFormik.errors.localIP}</p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                User Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.userName && cameraFormik.errors.userName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('userName')}
                                                />
                                                {cameraFormik.touched.userName && cameraFormik.errors.userName && <p className="tw-text-red-500">{cameraFormik.errors.userName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Password
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.password && cameraFormik.errors.password ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('password')}
                                                />
                                                {cameraFormik.touched.password && cameraFormik.errors.password && <p className="tw-text-red-500">{cameraFormik.errors.password}</p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Stream Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.streamName && cameraFormik.errors.streamName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('streamName')}
                                                />
                                                {cameraFormik.touched.streamName && cameraFormik.errors.streamName && <p className="tw-text-red-500">{cameraFormik.errors.streamName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Retention Period(in hours)
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="number"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.retentionPeriod && cameraFormik.errors.retentionPeriod ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('retentionPeriod')}
                                                />
                                                {cameraFormik.touched.retentionPeriod && cameraFormik.errors.retentionPeriod && <p className="tw-text-red-500">{cameraFormik.errors.retentionPeriod}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={closeCameraForm}>
                                                        Cancel
                                                </ButtonSecondary>
                                                <ButtonPrimary className="tw-mt-md" onClick={cameraFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>

                                        </div>
                                    </>}
                                </div>}

                    {props?.cameras?.length > 0 &&  props?.cameras?.map((camera, index) => {
                            if(index !== props?.cameras?.length - 1) {
                                return <>
                                    {camera.smartCameraID === editId ? <EditCameraCard camera={camera} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} rooms={props?.rooms} location={props?.location}/> : <ViewCameraCard camera={camera} handleEdit={handleEdit} />}
                                    
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                </>
                            } else {
                                return <>
                                    {camera.smartCameraID === editId ? <EditCameraCard camera={camera} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} rooms={props?.rooms} location={props?.location}/> : <ViewCameraCard camera={camera} handleEdit={handleEdit} />}
                                </>
                            }
                            })}
        </div>
    </>);
}

export const EditCameraCard = props => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const confirmDelete = () => {
        setShowConfirmDelete(true);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const deleteCamera = async () => {
        setLoading(true);
        var result = await deleteSmartCameraInLocation(props?.location?.serviceLocationID, props?.camera?.smartCameraID, appContext.handleAPICallError);

        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Smart camera deleted successfully.");
            appContext.setShowSuccessModal(true);
        }

        props?.handleDelete();

        setLoading(false);
    }
    
    const initialValues = {
        roomID: props?.camera?.roomID,
        cameraName: props?.camera?.cameraName,
        cameraType: props?.camera?.cameraType,
        firmwareVersion: props?.camera?.firmwareVersion,
        macAddress: props?.camera?.macAddress,
        localIP: props?.camera?.localIP,
        streamName: props?.camera?.streamName,
        userName: props?.camera?.userName,
        password: props?.camera?.password,
        retentionPeriod: props?.camera?.retentionPeriod,
    };

    const cameraFormik = useFormik({
        initialValues: initialValues,
        validationSchema: cameraSchema,
        onSubmit: async (values) => {
          // handle form submission
          console.log("Camera Form submitted")
            console.log(values)
            setLoading(true);

            var room = props?.rooms.filter(r => r.roomID === values.roomID)[0];

            var newCamera : SmartCamera = {
                smartCameraID: props?.camera?.smartCameraID,
                serviceLocationID: props?.location?.serviceLocationID,
                roomID: room?.roomID,
                roomName: room?.roomName,
                cameraName: values.cameraName,
                cameraType: values.cameraType,
                firmwareVersion: values.firmwareVersion,
                macAddress: values.macAddress,
                localIP: values.localIP,
                streamName: values.streamName,
                userName: values.userName,
                password: values.password,
                retentionPeriod: parseInt(values.retentionPeriod),    
            };

            var result = await updateSmartCameraInLocation(props?.location?.serviceLocationID, newCamera, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Smart Hub updated successfully.");
                appContext.setShowSuccessModal(true);
            }
            setLoading(false);

            props?.handleSave(newCamera);
        },
    });

    return (<>
    <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Smart Camera</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Camera Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.cameraName && cameraFormik.errors.cameraName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('cameraName')}
                                                />
                                                {cameraFormik.touched.cameraName && cameraFormik.errors.cameraName && <p className="tw-text-red-500"><>{cameraFormik.errors.cameraName}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room
                                            </label>
                                            <select
                                                id="state"
                                                name="state"
                                                className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.roomID && cameraFormik.errors.roomID ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('roomID')}
                                            >
                                                <option value="">Select Room</option>
                                                {props?.rooms?.map((room, index) => {
                                                    return <option key={index} value={room.roomID}>{room.roomName}</option>
                                                })}
                                            </select>
                                            {cameraFormik.touched.roomID && cameraFormik.errors.roomID && <p className="tw-text-red-500"><>{cameraFormik.errors.roomID}</></p>}

                                        </div>
                                        
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Camera Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.cameraType && cameraFormik.errors.cameraType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('cameraType')}
                                                />
                                                {cameraFormik.touched.cameraType && cameraFormik.errors.cameraType && <p className="tw-text-red-500"><>{cameraFormik.errors.cameraType}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Firmware Version
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.firmwareVersion && cameraFormik.errors.firmwareVersion ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('firmwareVersion')}
                                                />
                                                {cameraFormik.touched.firmwareVersion && cameraFormik.errors.firmwareVersion && <p className="tw-text-red-500"><>{cameraFormik.errors.firmwareVersion}</></p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Mac Address
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.macAddress && cameraFormik.errors.macAddress ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('macAddress')}
                                                />
                                                {cameraFormik.touched.macAddress && cameraFormik.errors.macAddress && <p className="tw-text-red-500"><>{cameraFormik.errors.macAddress}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Local IP
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.localIP && cameraFormik.errors.localIP ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('localIP')}
                                                />
                                                {cameraFormik.touched.localIP && cameraFormik.errors.localIP && <p className="tw-text-red-500"><>{cameraFormik.errors.localIP}</></p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                User Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.userName && cameraFormik.errors.userName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('userName')}
                                                />
                                                {cameraFormik.touched.userName && cameraFormik.errors.userName && <p className="tw-text-red-500"><>{cameraFormik.errors.userName}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Password
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.password && cameraFormik.errors.password ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('password')}
                                                />
                                                {cameraFormik.touched.password && cameraFormik.errors.password && <p className="tw-text-red-500"><>{cameraFormik.errors.password}</></p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Stream Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.streamName && cameraFormik.errors.streamName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('streamName')}
                                                />
                                                {cameraFormik.touched.streamName && cameraFormik.errors.streamName && <p className="tw-text-red-500"><>{cameraFormik.errors.streamName}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Retention Period(in hours)
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="number"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${cameraFormik.touched.retentionPeriod && cameraFormik.errors.retentionPeriod ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...cameraFormik.getFieldProps('retentionPeriod')}
                                                />
                                                {cameraFormik.touched.retentionPeriod && cameraFormik.errors.retentionPeriod && <p className="tw-text-red-500"><>{cameraFormik.errors.retentionPeriod}</></p>}
                                            </div>
                                        </div>
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner />
                                        </div>
                                    </> : <>
                                    {showConfirmDelete ? <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                        <p className="tw-text-primary">Are you sure you want to delete this smart camera?</p>
                                        </div>
                                    
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deleteCamera}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={confirmDelete}>
                                                    Delete
                                            </ButtonSecondary>
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.handleCancel}>
                                                        {cameraFormik.dirty ? "Discard": "Cancel" }
                                                </ButtonSecondary>
                                                {cameraFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={cameraFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>}

                                    </div>
                                    </>}
                                    </>}
                                </div>
    </>);
}

export const ViewCameraCard = props => {
    return (<>
         <div className="tw-bg-grey-light tw-flex tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
        <div className="tw-w-4/5 tw-flex-grow">
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Camera Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.cameraName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Room</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.roomName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Camera Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.cameraType}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Firmware Version</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.firmwareVersion}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>MAC Address</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.macAddress}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Local IP</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.localIP}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>User Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.userName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Password</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.password}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Stream Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.streamName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Retention Period</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.camera.retentionPeriod}</span>
                                            </div>
                                        

        </div>
        <div className="tw-w-1/5 tw-flex tw-justify-end tw-items-center">
                        <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={() => props?.handleEdit(props?.camera.smartCameraID)} />
            </div>  
            </div>
    </>);
}