import { useState } from "react";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { PersonalService, SupportArea } from "../../types/servicePlan";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { v4 as uuidv4 } from 'uuid';
import { EditSupportAreaList } from "../portalComponents/editSupportAreaCard";

export const ServiceSchema = Yup.object().shape({
    serviceTitle: Yup.string().required('Service title is required.'),
    //serviceDetails: Yup.string().required('Service details are required.'),
    frequency: Yup.string().required('Frequency is required.'),
    responsibleParty: Yup.string().required('Responsible party is required.'),
});

export const SupportAreaSchema = Yup.object().shape({
    supportTitle: Yup.string().required('Support title is required.'),
    //supportType: Yup.string().required('Support type is required.'),
    supportDetails: Yup.string().required('Support details are required.'),
    services: Yup.array().of(ServiceSchema)
                    .min(1, "Please add at least one service.")
                    .required('At least one service is required.'),
});

const NewSupportAreaSection = props => {
    const planContext = useNewPlanContext();

    const [showSupportAreaCard, setShowSupportAreaCard] = useState(false);

    

    const supportAreaFormik = useFormik({
        initialValues: {
            supportTitle: '',
            //supportType: '',
            supportDetails: '',
            services: [],
        },
        validationSchema: SupportAreaSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted")
            console.log(values);
            var areaServices: PersonalService[] = [];

            values.services.forEach((service) => {
                var newService: PersonalService = {
                    serviceTitle: service.serviceTitle,
                    //serviceDetails: service.serviceDetails,
                    frequency: service.frequency,
                    responsibleParty: service.responsibleParty,
                    personalServiceID: uuidv4(),
                    supportAreaID: "",
                    serviceDetails: "",
                    lastCompletedOn: "",
                    lastCompletedBy: ""
                }
                areaServices.push(newService);
            })

            var newArea: SupportArea = {
                supportTitle: values.supportTitle,
                //supportType: values.supportType,
                supportDetails: values.supportDetails,
                personalServices: areaServices,
                supportAreaID: uuidv4(),
                servicePlanID: "",
                supportType: ""
            }

            planContext.setPlanSupportAreas([...planContext.planSupportAreas, newArea]);

            supportAreaFormik.resetForm();
            personalServiceFormik.resetForm();
            toggleSupportAreaCard();
            supportAreaFormik.setFieldValue('services', []);
        },
    });

    const personalServiceFormik = useFormik({
        initialValues: {
            serviceTitle: '',
            //serviceDetails: '',
            frequency: '',
            responsibleParty: '',
        },
        validationSchema: ServiceSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            supportAreaFormik.values.services.push(values);
            supportAreaFormik.validateField('services');
            personalServiceFormik.resetForm();
        },
    });

    const toggleSupportAreaCard = () => {
        supportAreaFormik.resetForm();
        personalServiceFormik.resetForm();
        supportAreaFormik.setFieldValue('services', []);
        setShowSupportAreaCard(!showSupportAreaCard);
    }

    const handleRemovePersonalService = (service) => {
        const index = supportAreaFormik.values.services.indexOf(service);
        supportAreaFormik.values.services.splice(index, 1);
        supportAreaFormik.setFieldValue('services', supportAreaFormik.values.services);
    }

    const handleRemoveSupportArea = (area) => {
        const index = planContext.planSupportAreas.indexOf(area);
        const newItems = [...planContext.planSupportAreas.slice(0, index), ...planContext.planSupportAreas.slice(index + 1)];
        planContext.setPlanSupportAreas(newItems);
    }

    return (<>
        {showSupportAreaCard ?<>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Support Area Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="support-area-title"
                                                id="support-area-title"
                                                className={`tw-block tw-w-full tw-rounded-md ${supportAreaFormik.touched.supportTitle && supportAreaFormik.errors.supportTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...supportAreaFormik.getFieldProps('supportTitle')}
                                                />
                                                {supportAreaFormik.touched.supportTitle && supportAreaFormik.errors.supportTitle && <p className="tw-text-red-500">{supportAreaFormik.errors.supportTitle}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Details
                                            </label>
                                            <textarea
                                                rows={2}
                                                name="supportDetails"
                                                id="supportDetails"
                                                className={`tw-block tw-w-full tw-rounded-md ${supportAreaFormik.touched.supportDetails && supportAreaFormik.errors.supportDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-text-gray-900 tw-px-2 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-py-1.5 sm:tw-text-sm sm:tw-leading-6`}
                                                {...supportAreaFormik.getFieldProps('supportDetails')}
                                                />
                                                {supportAreaFormik.touched.supportDetails && supportAreaFormik.errors.supportDetails && <p className="tw-text-red-500">{supportAreaFormik.errors.supportDetails}</p>}
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Services</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Name
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-name"
                                                    id="service-name"
                                                    className={`tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.serviceTitle && personalServiceFormik.errors.serviceTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('serviceTitle')}
                                                    />
                                                    {personalServiceFormik.touched.serviceTitle && personalServiceFormik.errors.serviceTitle && <p className="tw-text-red-500">{personalServiceFormik.errors.serviceTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.frequency && personalServiceFormik.errors.frequency ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('frequency')}
                                                >
                                                    <option value="">Select Frequency</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {personalServiceFormik.touched.frequency && personalServiceFormik.errors.frequency && <p className="tw-text-red-500">{personalServiceFormik.errors.frequency}</p>}
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.responsibleParty && personalServiceFormik.errors.responsibleParty ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('responsibleParty')}
                                                >
                                                    <option value="">Select Responsible Party</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {personalServiceFormik.touched.responsibleParty && personalServiceFormik.errors.responsibleParty && <p className="tw-text-red-500">{personalServiceFormik.errors.responsibleParty}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={personalServiceFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={personalServiceFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>
                                
                                    {supportAreaFormik.values.services.length > 0 && supportAreaFormik.values.services?.map((personalService, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Name
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-name"
                                                    id="service-name"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value = {personalService.serviceTitle}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={personalService.frequency}
                                                    disabled={true}
                                                >
                                                    <option value="">Select Frequency</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={personalService.responsibleParty}
                                                    disabled={true}
                                                >
                                                    <option value="">Select Responsible Party</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemovePersonalService(personalService);
                                                        }}>
                                                            Remove
                                                    </ButtonSecondary>
                                                    {/* <ButtonPrimary className="tw-mt-md">
                                                        Edit
                                                    </ButtonPrimary> */}

                                        </div>
                                    </div>))}                            

                                    <div>
                                        {supportAreaFormik.touched.services && supportAreaFormik.errors.services && <p className="tw-text-red-500"><>{supportAreaFormik.errors.services}</></p>}
                                    </div>
                                    
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleSupportAreaCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={supportAreaFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                </div>
                            </>:<>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                                        <div className="tw-flex tw-items-center">
                                                <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleSupportAreaCard}>
                                                <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                                <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Support Area</span>
                                                </div>  
                                        </div>
                                </div>
                            </> }

                            <div className="tw-mt-4">
                                {planContext.planSupportAreas.length > 0 && <EditSupportAreaList areas={planContext.planSupportAreas} newPlan={true}></EditSupportAreaList>}
                            </div>

                            

                            {/* {planContext.planSupportAreas.length > 0 && planContext.planSupportAreas?.map((area, index) => (
                                <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mt-4">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Support Area Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="support-area-title"
                                                id="support-area-title"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={area.supportTitle}
                                                disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Details
                                            </label>
                                            <textarea
                                                rows={2}
                                                name="supportDetails"
                                                id="supportDetails"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-text-gray-900 tw-px-2 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-py-1.5 sm:tw-text-sm sm:tw-leading-6`}
                                                value={area.supportDetails}
                                                disabled={true}
                                                />
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Services</span>
                                    </div>
                                    {area.personalServices.length > 0 && area.personalServices?.map((personalService, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Name
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-name"
                                                    id="service-name"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value = {personalService.serviceTitle}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={personalService.frequency}
                                                    disabled={true}
                                                >
                                                    <option value="">Select Frequency</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={personalService.responsibleParty}
                                                    disabled={true}
                                                >
                                                    <option value="">Select Responsible Party</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>))}   

                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveSupportArea(area);
                                                        }}>
                                                    Remove
                                            </ButtonSecondary>
                                    </div>
                                </div>
                            ))} */}
    </>)
}

export default NewSupportAreaSection