import { useLayoutEffect, useState } from "react";
import AvatarNameInline from "../avatars/AvatarNameInline";

const CommentBlock = props => {
	const [data, setData] = useState<any>(null);
	const attributes = {
		...props,
		className: null
	}

	useLayoutEffect(() => {
		if (props.data) setData(props.data);
	}, [props.data]);

	const [commentColor, setCommentColor] = useState("tw-bg-light-accent");

	useLayoutEffect(() => {
		if (props.color) setCommentColor(props.color);
	}, [props.color]);

	// user: {
	// 	name: "Kevin Mack",
	// 	image: null
	// },
	// comment: {
	// 	createdOn: "March 16, 2022 | 8:00AM"
	// }

	return data ? <>
		<div { ...attributes } className={`tw-space-y-5px ${ props.className }`}>
			<div className="tw-text-6 tw-text-dark-light tw-w-full">
				<span className="tw-text-[.9em]">
					Created { data.comment.createdOn }
				</span>
			</div>
			<div className={`${commentColor} tw-py-8px tw-px-10px tw-text-7 tw-w-full`}>
				{ props.children }
			</div>
			<div className="tw-flex tw-justify-end">
				<AvatarNameInline className="tw-text-6 tw-text-light-dark/50" size="16" space="tw-space-x-5px" image={ data?.user?.image || null }>
					<span className="tw-text-[.9em]">
						{ data?.user?.name }
					</span>
				</AvatarNameInline>
			</div>
		</div>
	</> : <></>
}
export default CommentBlock;
