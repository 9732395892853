import { useEffect, useLayoutEffect, useState } from "react";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { Training } from "../../types/training";
import moment from "moment";
import { ButtonPrimary } from "../../components/buttons/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { getActiveContinuousEducationTrainingsByPageNumber, getArchiveContinuousEducationTrainingsByPageNumber, getDraftContinuousEducationTrainingsByPageNumber } from "../../apicalls/continuousEducationService";
import { useAppContext } from "../../Context";
import { getDraftClientTrainingsByPageNumber } from "../../apicalls/trainingService";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Search } from "../../components/forms/formFields";
import { get } from "lodash";

export function truncateStringToLength(input, maxLength) {
    if (input.length > maxLength) {
        return input.slice(0, maxLength - 3) + '...';
    }
    return input;
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ContinuousEducationLandingPage = props => {
    let navigate = useNavigate();

    const {status} = useParams();

    const [loadingTraining, setLoadingTraining] = useState<boolean>(true);

    const [currentTabs, setCurrentTabs] = useState([
        { name: 'Active', href: '#', current: true },
        { name: 'Draft', href: '#', current: false },
        { name: 'Archived', href: '#', current: false }
    ]);

    const [filteredTraining, setFilteredTraining] = useState<Training[]>([]);

    const [activeTraining, setActiveTraining] = useState<Training[]>([]);
    const [draftTraining, setDraftTraining] = useState<Training[]>([]);
    const [archivedTraining, setArchivedTraining] = useState<Training[]>([]);

    const [currentActiveTab, setCurrentActiveTab] = useState<string>("Active");

    const [searchText, setSearchText] = useState<string>("");
    
    useEffect(() => {
        if(status) {
            switch(status) {
                case "active":
                    setCurrentActiveTab("Active");
                    break;
                case "draft":
                    setCurrentActiveTab("Draft");
                    break;
                case "archived":
                    setCurrentActiveTab("Archived");
                    break;
                default:
                    break;
            }
        }
    }, [status])

    const appContext = useAppContext();

    const [activePageNumber, setActivePageNumber] = useState<number>(1);
        const [draftPageNumber, setDraftPageNumber] = useState<number>(1);
        const [archivedPageNumber, setArchivedPageNumber] = useState<number>(1);

    useLayoutEffect(() => {
        setLoadingTraining(false);

        const fetchTrainings = async () => {
            // console.log("Fetching Trainings");
            // console.log("Selected Status: " + currentActiveTab);
            // console.log("Route Param: " + status);

            setLoadingTraining(true);

            const active = await getActiveContinuousEducationTrainingsByPageNumber(activePageNumber, appContext.handleAPICallError);
            const draft = await getDraftContinuousEducationTrainingsByPageNumber(draftPageNumber, appContext.handleAPICallError);
            const archived = await getArchiveContinuousEducationTrainingsByPageNumber(archivedPageNumber, appContext.handleAPICallError);

            setActiveTraining(active);
            setDraftTraining(draft);
            setArchivedTraining(archived);

            console.log(active);
            console.log(draft);
            console.log(archived);

            //setFilteredTraining(active);

            var activeTab = currentActiveTab;

            if(status) {
                switch(status) {
                    case "active":
                        activeTab = "Active";
                        break;
                    case "draft":
                        activeTab = "Draft";
                        break;
                    case "archived":
                        activeTab = "Archived";
                        break;
                    default:
                        break;
                }
            }

            switch(activeTab) {
                case "Active":
                    setFilteredTraining(active);
                    setCurrentTabs([
                        { name: 'Active', href: '#', current: true },
                        { name: 'Draft', href: '#', current: false },
                        { name: 'Archived', href: '#', current: false }
                    ]);
                    break;
                case "Draft":
                    setFilteredTraining(draft);
                    setCurrentTabs([
                        { name: 'Active', href: '#', current: false },
                        { name: 'Draft', href: '#', current: true },
                        { name: 'Archived', href: '#', current: false }
                    ]);
                    break;
                case "Archived":
                    setFilteredTraining(archived);
                    setCurrentTabs([
                        { name: 'Active', href: '#', current: false },
                        { name: 'Draft', href: '#', current: false },
                        { name: 'Archived', href: '#', current: true }
                    ]);
                    break;
                default:
                    setFilteredTraining(active);
                    setCurrentTabs([
                        { name: 'Active', href: '#', current: true },
                        { name: 'Draft', href: '#', current: false },
                        { name: 'Archived', href: '#', current: false }
                    ]);
                    break;
            }

            setLoadingTraining(false);
        }

        fetchTrainings();

        //setFilteredTraining(ActiveTraining);
    
    }, [])

    const getFilteredTrainings = (text: string, currentTab: string) => {
        
        if(text) {
            const searchText = text.toLowerCase();

            const currentTraining = currentTab === "Active" ? activeTraining : currentTab === "Draft" ? draftTraining : archivedTraining;

            const filteredByName = currentTraining.filter(element => {
                
                // 👇️ using AND (&&) operator
                return element.trainingName.toLowerCase().includes(searchText.toLowerCase());
            });

            const filteredByCategory = currentTraining.filter(element => {
                
                // 👇️ using AND (&&) operator
                return element.trainingCategory.toLowerCase().includes(searchText.toLowerCase());
            });

            const filteredByDescription = currentTraining.filter(element => {
                
                // 👇️ using AND (&&) operator
                return element.trainingDescription.toLowerCase().includes(searchText.toLowerCase());
            });

            // Combine the results and ensure uniqueness
            const combinedResults = [
                ...filteredByName,
                ...filteredByCategory,
                ...filteredByDescription
            ];

            // Use a Set to store unique entries
            const uniqueResults = Array.from(new Set(combinedResults.map(item => item.trainingID)))
                .map(id => combinedResults.find(item => item.trainingID === id));

            setFilteredTraining(uniqueResults);
        } else {
            if (currentTab === "Active") {
                setFilteredTraining(activeTraining);
            }
            if (currentTab === "Draft") {
                setFilteredTraining(draftTraining);
            }
            if (currentTab === "Archived") {
                setFilteredTraining(archivedTraining);
            }

        }
    }

    const NavigateToTab = (tab) => {
        setCurrentTabs(currentTabs.map((item) => {
            if (item.name === tab.name) {
                return {
                    ...item,
                    current: true
                }
            } else {
                return {
                    ...item,
                    current: false
                }
            }
        }));

        switch(tab.name) {
            case "Active":
                getFilteredTrainings(searchText, "Active");
                setCurrentActiveTab("Active");
                setSortedByTitle("");
                setSortedByCategory("");
                setSortedByDuration("");
                setSortedByDate("");
                break;
            case "Draft":
                getFilteredTrainings(searchText, "Draft");
                setCurrentActiveTab("Draft");
                setSortedByTitle("");
                setSortedByCategory("");
                setSortedByDuration("");
                setSortedByDate("");
                break;
            case "Archived":
                getFilteredTrainings(searchText, "Archived");
                setCurrentActiveTab("Archived");
                setSortedByTitle("");
                setSortedByCategory("");
                setSortedByDuration("");
                setSortedByDate("");
                break;
            default:
                break;
        }
    }

    const goToCreateTraining = () => {
        navigate('/training/continuous-education-management/create');
    }

    const manageTraining = (trainingID: string) => {
        navigate(`/training/continuous-education-management/${trainingID}/manage`);
    }

    const [sortedByTitle, setSortedByTitle] = useState<string>("");

    const toggleSortByTitle = () => {
        if(sortedByTitle === "ascending") {
            setSortedByTitle("descending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.trainingName.localeCompare(b.trainingName)).reverse());
        } else {
            setSortedByTitle("ascending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.trainingName.localeCompare(b.trainingName)));
        }

        setSortedByCategory("");
        setSortedByDuration("");
        setSortedByDate("");
    }

    const [sortedByCategory, setSortedByCategory] = useState<string>("");

    const toggleSortByCategory = () => {
        if(sortedByCategory === "ascending") {
            setSortedByCategory("descending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.trainingCategory.localeCompare(b.trainingCategory)).reverse());
        } else {
            setSortedByCategory("ascending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.trainingCategory.localeCompare(b.trainingCategory)));
        }

        setSortedByTitle("");
        setSortedByDuration("");
        setSortedByDate("");
    }

    const [sortedByDuration, setSortedByDuration] = useState<string>("");

    const toggleSortByDuration = () => {
        if(sortedByDuration === "ascending") {
            setSortedByDuration("descending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.duration - b.duration).reverse());
        } else {
            setSortedByDuration("ascending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.duration - b.duration));
        }

        setSortedByTitle("");
        setSortedByCategory("");
        setSortedByDate("");
    }

    const [sortedByDate, setSortedByDate] = useState<string>("");

    const toggleSortByDate = () => {
        if(sortedByDate === "ascending") {
            setSortedByDate("descending");
            setFilteredTraining(filteredTraining.sort((a, b) => moment.utc(b.dateCreated).diff(moment.utc(a.dateCreated))));
        } else {
            setSortedByDate("ascending");
            setFilteredTraining(filteredTraining.sort((a, b) => moment.utc(a.dateCreated).diff(moment.utc(b.dateCreated))));
        }

        setSortedByTitle("");
        setSortedByCategory("");
        setSortedByDuration("");
    }

    return (<>
        <div className="tw-px-xl tw-py-sm">
                <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Continuous Education Management</h2>
                </div>

                {loadingTraining ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>:<>

                <div className="tw-pb-5">
                <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-md">
                    <ButtonPrimary onClick={goToCreateTraining}>
                        Create New Training
                    </ButtonPrimary>
                </div>

                <div className="tw-flex-grow tw-flex tw-items-center tw-mt-md tw-pr-20px">
                                <Search className="tw-flex tw-w-full tw-flex-grow" placeholder="Search trainings" onChange={ e => {

                                    console.log(e?.target.value || "nope");

                                    setSearchText(e?.target.value || "");

                                    getFilteredTrainings(e?.target.value || "", currentActiveTab);

                                    // if(e?.target.value){
                                    //     const searchText = e?.target.value.toString().toLowerCase();
                                    //     const filtered = sessionShifts.filter(element => {
                                            
                                    //         // 👇️ using AND (&&) operator
                                    //         return element.patientName.toLowerCase().includes(searchText);
                                    //     });

                                    //     console.log(filtered)

                                    //     setFilteredShifts(filtered);
                                    // } else {
                                    //     setFilteredShifts(sessionShifts);
                                    // }
                                    }}/>
                            </div>


                <div className="hidden sm:block">
                    <div className="tw-border-b tw-border-gray-200">
                    <nav className="-tw-mb-px tw-flex tw-space-x-8" aria-label="Tabs">
                        {currentTabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={classNames(
                            tab.current
                                ? 'tw-border-primary tw-text-primary'
                                : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-200 hover:tw-text-gray-700 tw-cursor-pointer',
                            'tw-flex tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => NavigateToTab(tab)}
                        >
                            {tab.name}
                            {/* <span
                                className={classNames(
                                tab.current ? 'tw-bg-primary-bg tw-text-primary' : 'tw-bg-gray-100 tw-text-gray-900',
                                'tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block'
                                )}
                            >
                                {tab.count}
                            </span> */}
                        </span>
                        ))}
                    </nav>
                    </div>
                </div>

                <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-10px tw-leading-tight tw-mt-15px">
                        <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-140px tw-flex tw-justify-center tw-cursor-pointer" onClick={toggleSortByTitle}>
                                        <span className="tw-text-dark tw-font-semibold">Training Name</span>
                                        {sortedByTitle === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByTitle === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-justify-center tw-cursor-pointer" onClick={toggleSortByCategory}>
                                        <span className="tw-text-dark tw-font-semibold">Category</span>
                                        {sortedByCategory === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByCategory === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-250px tw-flex tw-justify-center">
                                        <span className="tw-text-dark tw-font-semibold">Description</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-justify-center tw-cursor-pointer" onClick={toggleSortByDuration}>
                                        <span className="tw-text-dark tw-font-semibold">Duration</span>
                                        {sortedByDuration === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByDuration === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-justify-center tw-cursor-pointer" onClick={toggleSortByDate}>
                                        <span className="tw-text-dark tw-font-semibold">Published (Date/By)</span>
                                        {sortedByDate === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByDate === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                            </div>
                        </li>

                {(!filteredTraining || filteredTraining.length === 0) && <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                    <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                            
                            <span className="tw-w-full tw-flex tw-justify-center tw-text-dark tw-pb-sm">
                                No Trainings Found
                            </span>
                    </div>
                </li>}
                
                {filteredTraining?.map((training, index) => {
                    return (
                        <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-py-2">
                        <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1 tw-items-center">
                          <span className="tw-w-140px tw-flex tw-justify-start tw-pl-2">
                            <span className="tw-text-dark tw-text-center">{training.trainingName}</span>
                          </span>
                          <span className="tw-w-140px tw-flex tw-justify-center tw-items-center">
                            <span className="tw-text-dark">{training.trainingCategory}</span>
                          </span>
                          <span className="tw-w-250px tw-flex tw-justify-start tw-pl-2">
                            <span className="tw-text-dark">{truncateStringToLength(training.trainingDescription, 80)}</span>
                          </span>
                          <span className="tw-w-140px tw-flex tw-justify-center tw-items-center">
                            <span className="tw-text-dark">{training.duration ? training.duration : 30} minutes</span>
                          </span>
                          <span className="tw-w-180px tw-flex tw-flex-col tw-items-center tw-justify-center">
                                        <span className="tw-text-dark">{moment.utc(training.dateCreated).tz('America/New_York').format('MMM Do YYYY')}</span>
                                        <span className="tw-text-dark tw-pt-md">{training?.createdBy.split('@')[0]}</span>
                                    </span>
                          <span className="tw-flex tw-justify-center tw-items-center">
                            <ButtonPrimary 
                              onClick={(e) => {
                                e.preventDefault();
                                manageTraining(training.trainingID);
                              }} 
                              className="tw-bg-gray-400 tw-border-gray-400 hover:tw-text-gray-400 tw-h-10"
                            >
                              View Details
                            </ButtonPrimary>
                          </span>
                        </div>
                      </li>
                    )
                })}
                </ul>
                </div>
                
                </>}
        </div>
    </>);
}

export default ContinuousEducationLandingPage;