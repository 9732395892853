import { Configuration, OpenAIApi, ChatCompletionRequestMessage } from "openai";
import { useState } from "react";
import { useAppContext } from "../Context";

const configuration = new Configuration({
    apiKey: "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",
  });
const openai = new OpenAIApi(configuration);


const ChatGPTPage = props => {
    const navContext = useAppContext();

    const [prompt, setPrompt] = useState<string>("");
    

    const [messages, setMessages] = useState<ChatCompletionRequestMessage[]>([
        {
            content: "I am Medforall Copilot, your personal assistant. Ask me anything.",
            role: "assistant"
          },
        //   {
        //     content: "You underestimate my power!",
        //     role: "user"
        //   },
      ]);
    
    const [isTyping, setIsTyping] = useState(false);

    const handlePromptChange = async (e) => {
        setPrompt(e.target.value);

        const embedding = await openai.createEmbedding({
            model: "text-embedding-ada-002",
            input: e.target.value
        });

        console.log("Embedding:");
        console.log(embedding.data);

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const newMessage: ChatCompletionRequestMessage = {
          content: e.target[0].value,
          role: "user"
        }

    
        const newMessages = [...messages, newMessage];
        setMessages(newMessages);
        setIsTyping(true);
        e.target.reset();


    
        const completion = await openai.createChatCompletion({
          model: "gpt-4",
          messages: prompt.length === 0 ? [...newMessages] : [{content: prompt, role: "user" }, ...newMessages ]
        });
    
        setMessages([...newMessages, completion.data.choices[0].message]);
        setIsTyping(false);

        
      }

    return (<>
        <div className="tw-px-xl tw-py-xl">
            <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Medforall Copilot</h2>
            </div>
            <div className="">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Enter Prompt for Knowledge Base
                            </label>
                            <div className="tw-mt-2">
                                <textarea
                                rows={3}
                                name="prompt"
                                id="prompt"
                                className="tw-block tw-px-2 tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                onChange={handlePromptChange}
                                />
                            </div>
                        </div>
            <section className='tw-container tw-mx-auto tw-p-5 tw-inset-0'>
                   
                <div className="tw-mockup-window tw-border tw-bg-base-300 tw-w-full tw-h-full tw-flex tw-flex-col">
                    <div className='tw-p-5 tw-pb-8 tw-flex-grow tw-overflow-auto'>
                    {
                        messages.length && messages.map((msg, i) => {
                        return (
                            <div className={`tw-chat ${msg.role === 'assistant' ? 'tw-chat-start' : 'tw-chat-end'}`} key={'chatKey' + i}>
                            <div className="tw-chat-image tw-avatar">
                                <div className="tw-w-10 tw-rounded-full">
                                <img src={msg.role === 'assistant' ? '/images/ms-icon-310x310.png' : navContext.avatar} />
                                </div>
                            </div>
                            <div className="tw-chat-bubble tw-whitespace-break-spaces">{msg.content}</div>
                            </div>
                        )
                        })
                    }
                    </div>

                    <form className="tw-form-control tw-m-5 tw-items-center" onSubmit={(e) => handleSubmit(e)}>
                    <div className="tw-input-group tw-max-w-full tw-w-[800px] tw-relative">
                        {isTyping && <small className='tw-absolute -tw-top-5 tw-left-0.5 tw-animate-pulse'>Copilot is Typing...</small>}

                        <input type="text" placeholder="Type a question for Medforall Copilot, ask anything!" className="tw-input tw-input-bordered tw-flex-grow" required />
                        <button className="tw-btn tw-btn-square" type="submit">
                        <svg xmlns="http://www.w3.org/2000/svg" className="tw-h-6 tw-w-6" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                        </button>
                    </div>
                    </form>
                </div>
            </section>
            
        </div>
    </>);
}

export default ChatGPTPage;