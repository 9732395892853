import { useFormik } from "formik";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import * as Yup from 'yup';
import { ButtonPrimary, ButtonSecondary, Input } from "@medforall/medforall-component-library";
import ServicePlan from "../../types/servicePlan";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const NewPlanSection = props => {
    const planContext = useNewPlanContext();

    const goToServiceDetails = () => {
        planContext.setActiveSteps([
            { id: '1', name: 'New Plan', href: '#', status: 'complete' },
            { id: '2', name: 'Service Details', href: '#', status: 'current' },
            { id: '3', name: 'Authorization', href: '#', status: 'upcoming' },
            { id: '4', name: 'Review', href: '#', status: 'upcoming' },
            ]);
    }

    const planFormik = useFormik({
        initialValues: {
            fiscalYear: planContext.newPlan?.fiscalYear || '',
            waiverType: planContext.newPlan?.waiverType || '',
            spanStartDate: planContext.newPlan ? new Date(planContext.newPlan?.startDate).toISOString().substring(0,10) : '' || '',
            spanEndDate: planContext.newPlan ? new Date (planContext.newPlan?.endDate).toISOString().substring(0,10) : '' || '',
            aboutClient: planContext.newPlan?.aboutClient || '',
        },
        validationSchema: Yup.object().shape({
            fiscalYear: Yup.string().required('Fiscal year is required.'),
            waiverType: Yup.string().required('Waiver type is required.'),
            spanStartDate: Yup.date()
                //.min(Yup.ref('spanEndDate'), 'Span start date must be before span end date.')
                .required('Span start date is required.'),
            spanEndDate: Yup.date()
                .min(Yup.ref('spanStartDate'), 'Span end date must be after span start date.')
                .required('Span end date is required.'),
            aboutClient: Yup.string().required('About client is required.'),
                    }),

        onSubmit: (values) => {
          // handle form submission
            console.log("Plan Form submitted")
            console.log(values);
            var newServicePlan: ServicePlan = {
                fiscalYear: values.fiscalYear,
                waiverType: values.waiverType,
                startDate: values.spanStartDate,
                endDate: values.spanEndDate,
                aboutClient: values.aboutClient,
                planID: "",
                servicePlanID: "",
                patientID: "",
                status: "",
                displayStartDate: moment(values.spanStartDate).format("MM/DD/YYYY"),
                displayEndDate: moment(values.spanEndDate).format("MM/DD/YYYY"),
                authorizedServices: [],
                supportAreas: [],
                desiredOutcomes: [],
                remoteSupportEvents: [],
                suggestedActions: [],
                remoteSupportRoutines: [],
                unusualEvents: []
            }

            planContext.setNewPlan(newServicePlan);

            goToServiceDetails();
        },
    });

    let navigate = useNavigate();

    const cancelNewPlan = () => {
        navigate(-1);
    }

    return (<>
        <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                        <div className="tw-space-y-6 tw-bg-white tw-p-6">
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Fiscal Year
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="fiscal-year"
                                        id="fiscal-year"
                                        className={`tw-block tw-w-full tw-rounded-md ${planFormik.touched.fiscalYear && planFormik.errors.fiscalYear ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...planFormik.getFieldProps('fiscalYear')}
                                        />
                                        {planFormik.touched.fiscalYear && planFormik.errors.fiscalYear && <p className="tw-text-red-500">{planFormik.errors.fiscalYear}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Waiver Type
                                    </label>
                                    <select
                                        id="waiver-type"
                                        name="waiver-type"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${planFormik.touched.waiverType && planFormik.errors.waiverType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...planFormik.getFieldProps('waiverType')}
                                    >
                                        <option value="">Select a waiver type</option>
                                        <option value="Individual Option">Individual Option</option>
                                        <option value="Level One">Level One</option>
                                        <option value="SELF">SELF</option>
                                    </select>
                                    {planFormik.touched.waiverType && planFormik.errors.waiverType && <p className="tw-text-red-500">{planFormik.errors.waiverType}</p>}
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Span Start
                                    </label>
                                    <div className="tw-mt-2">
                                        <Input
                                            min="2000-01-01" max="2024-12-31"
                                            type="date"
                                            name="spanStartDate"
                                            id="spanStartDate"
                                            className={`tw-block tw-w-full tw-rounded-md ${planFormik.touched.spanStartDate && planFormik.errors.spanStartDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...planFormik.getFieldProps('spanStartDate')}
                                            />
                                            {planFormik.touched.spanStartDate && planFormik.errors.spanStartDate && <p className="tw-text-red-500">{planFormik.errors.spanStartDate}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Span End
                                    </label>
                                    <div className="tw-mt-2">
                                        <Input
                                            min="2000-01-01" max="2024-12-31"
                                            type="date"
                                            name="spanEndDate"
                                            id="spanEndDate"
                                            className={`tw-block tw-w-full tw-rounded-md ${planFormik.touched.spanEndDate && planFormik.errors.spanEndDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...planFormik.getFieldProps('spanEndDate')}
                                            />
                                            {planFormik.touched.spanEndDate && planFormik.errors.spanEndDate && <p className="tw-text-red-500">{planFormik.errors.spanEndDate}</p>}
                                    </div>
                                </div>

                                <div className="tw-col-span-2">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        About {planContext.patient.firstName}
                                    </label>
                                    <textarea
                                        rows={4}
                                        name="aboutClient"
                                        id="aboutClient"
                                        className={`tw-block tw-w-full tw-rounded-md ${planFormik.touched.aboutClient && planFormik.errors.aboutClient ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-text-gray-900 tw-px-2 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-py-1.5 sm:tw-text-sm sm:tw-leading-6`}
                                        {...planFormik.getFieldProps('aboutClient')}
                                        />
                                        {planFormik.touched.aboutClient && planFormik.errors.aboutClient && <p className="tw-text-red-500">{planFormik.errors.aboutClient}</p>}
                                </div>
                            </div>
                        </div>

                            <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelNewPlan}>
                                            Cancel
                                    </ButtonSecondary>
                                    <ButtonPrimary className="tw-mt-md" onClick={planFormik.handleSubmit}>
                                        Next
                                    </ButtonPrimary>

                        </div>
                    </div>
    </>)


}

export const NewPlanBanner = props => {
    const planContext = useNewPlanContext();

    return (<>
        <div className="tw-flex">
                        <div className="tw-w-4/5">
                            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Service Plan</span>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6 tw-rounded-lg tw-bg-white tw-border tw-border-primary tw-mx-4">
                                <div className="tw-col-span-1 tw-p-2">
                                            <label className="tw-block tw-text-sm tw-font-light tw-leading-6 tw-text-grey-dark">
                                                Waiver Type
                                            </label>
                                            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{planContext.newPlan?.waiverType}</span>

                                </div>
                                <div className="tw-col-span-1 tw-p-2">
                                            <label className="tw-block tw-text-sm tw-font-light tw-leading-6 tw-text-grey-dark">
                                                Span Starts
                                            </label>
                                            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{planContext.newPlan?.displayStartDate}</span>

                                </div>
                                <div className="tw-col-span-1 tw-p-2">
                                            <label className="tw-block tw-text-sm tw-font-light tw-leading-6 tw-text-grey-dark">
                                                Span Ends
                                            </label>
                                            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{planContext.newPlan?.displayEndDate}</span>

                                </div>
                            </div>
                        </div>
                        <div className="tw-w-1/5">
                            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Service Type</span>
                            <div className="tw-flex tw-rounded-lg tw-bg-white tw-border tw-border-primary tw-mx-1 tw-items-center tw-py-5">
                                <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-mx-auto">{planContext.patient.serviceType}</span>
                            </div>
                        </div>
                    </div>
    </>);
}

export default NewPlanSection;