import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { RemoteSupportRoutine, RoutineEvent } from "../../types/servicePlan";
import { v4 as uuidv4 } from 'uuid';
import { EditRoutineList } from "../portalComponents/editRemoteSupportRoutine";

export const RoutineEventSchema = Yup.object().shape({
    eventTitle: Yup.string()
      .max(50, 'Event title must be at most 50 characters')
      .required('Event title is required'),
    eventDetails: Yup.string().required('Event details are required'),
    responsibleParty: Yup.string()
      .max(50, 'Responsible party must be at most 50 characters'),
    timeOfDay: Yup.string()
                .required('Time of Day is required')
                .matches(
                /^(0?[1-9]|1[0-2]):[0-5][0-9] ?(am|pm)$/i,
                'Time of Day must be in 12-hour format (hh:mm am/pm)'
                ),
    preEventReminder: Yup.number()
        .positive('Pre-event reminder must be at least 1 minute or greater')
        .required('Pre-event reminder is required'),
    postEventReminder: Yup.number()
    .positive('Post-event reminder must be at least 1 minute or greater')
    .required('Post-event reminder is required'),
  });

export const RemoteSupportRoutineSchema = Yup.object().shape({
    routineTitle: Yup.string()
      .max(50, 'Routine title must be at most 50 characters')
      .required('Routine title is required'),
    routineDetails: Yup.string(),
    routineStart: Yup.string()
                    .required('Routine Start Time is required')
                    .matches(
                    /^(0?[1-9]|1[0-2]):[0-5][0-9] ?(am|pm)$/i,
                    'Routine Start Time must be in 12-hour format (hh:mm am/pm)'
                    ),
    routineEnd: Yup.string()
                    .required('Routine End Time is required')
                    .matches(
                    /^(0?[1-9]|1[0-2]):[0-5][0-9] ?(am|pm)$/i,
                    'Routine End Time must be in 12-hour format (hh:mm am/pm)'
                    ),
    events: Yup.array().of(RoutineEventSchema).min(1, 'At least one routine event is required')
            .required('At least one routine event is required'),
  });

const NewRoutineSection = () => {
    const planContext = useNewPlanContext();

    const [showRoutineCard, setShowRoutineCard] = useState(false);

    
      
    const initialRoutineEventValues = {
        eventTitle: '',
        eventDetails: '',
        responsibleParty: '',
        timeOfDay: '',
        preEventReminder: 0,
        postEventReminder: 0,
    };

      
      
    const initialValues = {
        routineTitle: '',
        routineDetails: '',
        routineStart: '',
        routineEnd: '',
        repeatsMonday: false,
        repeatsTuesday: false,
        repeatsWednesday: false,
        repeatsThursday: false,
        repeatsFriday: false,
        repeatsSaturday: false,
        repeatsSunday: false,
        events: [],
    };

    const routineEventFormik = useFormik({
        initialValues: initialRoutineEventValues,
        validationSchema: RoutineEventSchema,
        onSubmit: (values) => {
            console.log(values);
            routineFormik.values.events.push(values);
            routineFormik.validateField('events');
            routineEventFormik.resetForm();
        },
    });

    const routineFormik = useFormik({
        initialValues: initialValues,
        validationSchema: RemoteSupportRoutineSchema,
        onSubmit: (values) => {
            console.log(values);

            var routineEvents: RoutineEvent[] = [];

            values.events.forEach((routineEvent) => {
                var newEvent: RoutineEvent = {
                    eventTitle: routineEvent.eventTitle,
                    eventDetails: routineEvent.eventDetails,
                    responsibleParty: routineEvent.responsibleParty,
                    timeOfDay: routineEvent.timeOfDay,
                    preEventReminder: routineEvent.preEventReminder,
                    postEventReminder: routineEvent.postEventReminder,
                    routineEventID: uuidv4(),
                    remoteSupportRoutineID: ""
                }
                routineEvents.push(newEvent);
            })

            var newRoutine: RemoteSupportRoutine = {
                remoteSupportRoutineID: uuidv4(),
                servicePlanID: "",
                routineTitle: values.routineTitle,
                routineDetails: values.routineDetails,
                routineStart: values.routineStart,
                routineEnd: values.routineEnd,
                repeatsMonday: values.repeatsMonday,
                repeatsTuesday: values.repeatsTuesday,
                repeatsWednesday: values.repeatsWednesday,
                repeatsThursday:  values.repeatsThursday,
                repeatsFriday:  values.repeatsFriday,
                repeatsSaturday: values.repeatsSaturday,
                repeatsSunday: values.repeatsSunday,
                events: routineEvents
            }

            planContext.setPlanRoutines([...planContext.planRoutines, newRoutine]);
            setShowRoutineCard(false);
            routineEventFormik.resetForm();
            routineFormik.resetForm();
            routineFormik.setFieldValue('events', []);

        },
    });

    const toggleRoutineCard = () => {
        routineEventFormik.resetForm();
        routineFormik.resetForm();
        routineFormik.setFieldValue('events', []);
        setShowRoutineCard(!showRoutineCard);
    }

    const handleRemoveRoutineEvent = (routineEvent) => {
        const index = routineFormik.values.events.indexOf(routineEvent);
        routineFormik.values.events.splice(index, 1);
        routineFormik.setFieldValue('services', routineFormik.values.events);
    }

    const handleRemoveRoutine = (routine) => {
        const index = planContext.planRoutines.indexOf(routine);
        const newItems = [...planContext.planRoutines.slice(0, index), ...planContext.planRoutines.slice(index + 1)];
        planContext.setPlanRoutines(newItems);
    }



    return (<>
        {showRoutineCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${routineFormik.touched.routineTitle && routineFormik.errors.routineTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...routineFormik.getFieldProps('routineTitle')}
                                                    />
                                                    {routineFormik.touched.routineTitle && routineFormik.errors.routineTitle && <p className="tw-text-red-500">{routineFormik.errors.routineTitle}</p>}
                                                </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Repeat
                                            </label>
                                            <div className="tw-flex tw-p-1">
                                            <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsMonday', !routineFormik.values.repeatsMonday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsTuesday', !routineFormik.values.repeatsTuesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsWednesday', !routineFormik.values.repeatsWednesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsThursday', !routineFormik.values.repeatsThursday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsFriday', !routineFormik.values.repeatsFriday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsSaturday', !routineFormik.values.repeatsSaturday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsSunday', !routineFormik.values.repeatsSunday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                        </span>
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Start Time
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${routineFormik.touched.routineStart && routineFormik.errors.routineStart ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...routineFormik.getFieldProps('routineStart')}
                                                    />
                                                    {routineFormik.touched.routineStart && routineFormik.errors.routineStart && <p className="tw-text-red-500">{routineFormik.errors.routineStart}</p>}
                                                </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    End Time
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${routineFormik.touched.routineEnd && routineFormik.errors.routineEnd ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...routineFormik.getFieldProps('routineEnd')}
                                                    />
                                                    {routineFormik.touched.routineEnd && routineFormik.errors.routineEnd && <p className="tw-text-red-500">{routineFormik.errors.routineEnd}</p>}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Routine Tasks</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Title
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.eventTitle && routineEventFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('eventTitle')}
                                                        />
                                                        {routineEventFormik.touched.eventTitle && routineEventFormik.errors.eventTitle && <p className="tw-text-red-500">{routineEventFormik.errors.eventTitle}</p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Description
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="description"
                                                        id="description"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.eventDetails && routineEventFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('eventDetails')}
                                                        />
                                                        {routineEventFormik.touched.eventDetails && routineEventFormik.errors.eventDetails && <p className="tw-text-red-500">{routineEventFormik.errors.eventDetails}</p>}
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Time Of Day
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="time-of-day"
                                                        id="time-of-day"    
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.timeOfDay && routineEventFormik.errors.timeOfDay ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('timeOfDay')}
                                                        />
                                                        {routineEventFormik.touched.timeOfDay && routineEventFormik.errors.timeOfDay && <p className="tw-text-red-500">{routineEventFormik.errors.timeOfDay}</p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Pre Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="number"
                                                        name="routine-pre-reminder"
                                                        id="routine-pre-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.preEventReminder && routineEventFormik.errors.preEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('preEventReminder')}
                                                        />
                                                        {routineEventFormik.touched.preEventReminder && routineEventFormik.errors.preEventReminder && <p className="tw-text-red-500"><>{routineEventFormik.errors.preEventReminder}</></p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Overdue Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="number"
                                                        name="routine-overdue-reminder"
                                                        id="routine-overdue-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.postEventReminder && routineEventFormik.errors.postEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('postEventReminder')}
                                                        />
                                                        {routineEventFormik.touched.postEventReminder && routineEventFormik.errors.postEventReminder && <p className="tw-text-red-500"><>{routineEventFormik.errors.postEventReminder}</></p>}
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={routineEventFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={routineEventFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>

                                    {routineFormik.values.events.length > 0 && routineFormik.values.events?.map((routineEvent, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Title
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.eventTitle}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Description
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="description"
                                                        id="description"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.eventDetails}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Time Of Day
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="time-of-day"
                                                        id="time-of-day"    
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.timeOfDay}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Pre Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="pre-reminder"
                                                        id="pre-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.preEventReminder}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Overdue Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="overdue-reminder"
                                                        id="overdue-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.postEventReminder}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveRoutineEvent(routineEvent);
                                                        }}>
                                                            Remove
                                                    </ButtonSecondary>
                                                    {/* <ButtonPrimary className="tw-mt-md">
                                                        Edit
                                                    </ButtonPrimary> */}

                                        </div>
                                    </div>))}  

                                    <div>
                                        {routineFormik.touched.events && routineFormik.errors.events && <p className="tw-text-red-500"><>{routineFormik.errors.events}</></p>}
                                    </div>

                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleRoutineCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={routineFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>

                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleRoutineCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Routine</span>
                                            </div>  
                                    </div>
                            </div>
                            </>}

                            {/* {planContext.planRoutines.length > 0 && planContext.planRoutines?.map((routine, index) => (
                                <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mt-4">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={routine.routineTitle}
                                                    disabled={true}
                                                    />
                                                </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Repeat
                                            </label>
                                            <div className="tw-flex tw-p-1">
                                                <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routine.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1`}>
                                                    <span className={`tw-font-medium tw-leading-none ${routine.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                                </span>
                                                <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routine.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1`}>
                                                    <span className={`tw-font-medium tw-leading-none ${routine.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                                </span>
                                                <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routine.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1`}>
                                                    <span className={`tw-font-medium tw-leading-none ${routine.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                                </span>
                                                <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routine.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1`}>
                                                    <span className={`tw-font-medium tw-leading-none ${routine.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                                </span>
                                                <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routine.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1`}>
                                                    <span className={`tw-font-medium tw-leading-none ${routine.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                                </span>
                                                <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routine.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1`}>
                                                    <span className={`tw-font-medium tw-leading-none ${routine.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                                </span>
                                                <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routine.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1`}>
                                                    <span className={`tw-font-medium tw-leading-none ${routine.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Start Time
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={routine.routineStart}
                                                    disabled={true}
                                                    />
                                                    
                                                </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    End Time
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={routine.routineEnd}
                                                    disabled={true}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Tasks</span>
                                    </div>
                                    {routine.events.length > 0 && routine.events?.map((routineEvent, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Title
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.eventTitle}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Description
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="description"
                                                        id="description"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.eventDetails}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Time Of Day
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="time-of-day"
                                                        id="time-of-day"    
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.timeOfDay}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Pre Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="pre-reminder"
                                                        id="pre-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.preEventReminder}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Overdue Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="overdue-reminder"
                                                        id="overdue-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.postEventReminder}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                    </div>))}  


                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveRoutine(routine);
                                                        }}>
                                                    Remove
                                            </ButtonSecondary>
                                    </div>
                                </div>
                            ))} */}

                            <div className="tw-mt-4">
                                {planContext.planRoutines.length > 0 && <EditRoutineList routines={planContext.planRoutines} newPlan={true}></EditRoutineList>}
                            </div>
    </>);
}

export default NewRoutineSection;