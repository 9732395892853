import { useEffect, useState } from "react";
import { LabelOutputs } from "../utilities/Helpers";
import { ButtonPrimary } from "../buttons/Buttons";
import { InputTimes } from "../forms/formFields";
import moment from "moment";
import { removeWhitespace } from "../panels/SchedulePanel";
import { TrashIcon } from "@heroicons/react/24/solid";
import { DailyAvailability, TimeBlock } from "../../types/providerAvailability";

const DailyAvailabilityComponent = props => {
    const [availableChecked, setAvailableChecked] = useState(false);
    const [unavailableChecked, setUnavailableChecked] = useState(false);
    const [availabilityStartTime, setAvailabilityStartTime] = useState("");
    const [availabilityEndTime, setAvailabilityEndTime] = useState("");
    const [dailyAvailabilities, setDailyAvailabilities] = useState<TimeBlock[]>([]); // [{startTime: "9:00 AM", endTime: "5:00 PM"}]
    const [showValidationError, setShowValidationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [editMode, setEditMode] = useState(true);

    useEffect(() => {
      if(props.dailyAvailability) {
        if(props.dailyAvailability.isAvailable) {
          setAvailableChecked(true);
          setUnavailableChecked(false);
        } else {
          setAvailableChecked(false);
          setUnavailableChecked(true);
        }
        if(props.dailyAvailability.isAvailable) {
          setDailyAvailabilities(props.dailyAvailability.specificTimeBlocks);
        }
        
      }
    },[props.dailyAvailability])

    useEffect(() => {
      if(props.editMode !== undefined) {
        setEditMode(props.editMode);
      }
    }, [props.editMode])



    const handleAddAvailability = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      // Validate start time and end time
      if(!moment(availabilityStartTime, 'h:mm A', true).isValid() || !moment(availabilityEndTime, 'h:mm A', true).isValid()) {
        setShowValidationError(true);
        setErrorMessage("Please enter a valid start time and end time");
        return;
      } 
      // Check is start time is before end time except when end time is 12:00 AM
      else if(moment(availabilityStartTime, 'h:mm A', true).isAfter(moment(availabilityEndTime, 'h:mm A', true)) && !moment(availabilityEndTime, 'h:mm A', true).isSame(moment('12:00 AM', 'h:mm A', true))) {
        setShowValidationError(true);
        setErrorMessage("Start time cannot be after end time");
        return;
      }
      // Check if start time and end time are the same
      else if(moment(availabilityStartTime, 'h:mm A', true).isSame(moment(availabilityEndTime, 'h:mm A', true))) {
        setShowValidationError(true);
        setErrorMessage("Start time cannot be the same as end time");
        return;
      } 
      // Check if entered time overlaps with existing availability
      else if(dailyAvailabilities.length > 0) {
        const availabilityExists = dailyAvailabilities.filter((availability) => {
          const newStart = moment(availabilityStartTime, 'h:mm A', true);
          const newEnd = moment(availabilityEndTime, 'h:mm A', true);
          const existingStart = moment(availability.displayStart, 'h:mm A', true);
          const existingEnd = moment(availability.displayEnd, 'h:mm A', true);

          return newStart.isBetween(existingStart, existingEnd) || 
                 newEnd.isBetween(existingStart, existingEnd) ||
                 existingStart.isBetween(newStart, newEnd) ||
                 existingEnd.isBetween(newStart, newEnd);
        });
        if(availabilityExists.length > 0) {
          setShowValidationError(true);
          setErrorMessage("Availability overlaps with existing availability");
          return;
        } else {
          console.log("No overlap");
        }
        // Check if entered time already exists
        const duplicateExists = dailyAvailabilities.filter((availability) => {
          return (moment(availabilityStartTime, 'h:mm A', true).isSame(moment(availability.displayStart, 'h:mm A', true)) &&
          moment(availabilityEndTime, 'h:mm A', true).isSame(moment(availability.displayEnd, 'h:mm A', true)));
        });
        if(duplicateExists.length > 0) {
          setShowValidationError(true);
          setErrorMessage("Availability already exists");
          return;
        } else {
          console.log("Not duplicate");
        }
      } 

      // Reset error message
      setShowValidationError(false);
      setErrorMessage("");

      var newTimeBlock: TimeBlock = {
        start: moment().startOf('day').add(moment(availabilityStartTime, 'h:mm A').diff(moment().startOf('day'))).toDate(),
        end: moment().startOf('day').add(moment(availabilityEndTime, 'h:mm A').diff(moment().startOf('day'))).toDate(),
        displayStart: availabilityStartTime,
        displayEnd: availabilityEndTime,
        timeBlockID: "",
        dailyAvailabilityID: ""
      }

      setDailyAvailabilities([...dailyAvailabilities, newTimeBlock]);
    }

    const handleRemoveAvailability = (start_time: string, end_time: string) => {
      setDailyAvailabilities(dailyAvailabilities.filter((availability) => {
        return availability.displayStart !== start_time && availability.displayEnd !== end_time;
      }));
    }
  
    const handleUnAvailableCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(!unavailableChecked){
        setUnavailableChecked(event.target.checked);
        setAvailableChecked(!event.target.checked);
        setDailyAvailabilities([]);
      }
    }
  
  
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(!availableChecked){
        setAvailableChecked(event.target.checked);
        setUnavailableChecked(!event.target.checked);
      }
      
    };

    useEffect(() => {
      if(props?.setDailyAvailablity){
        if(availableChecked || unavailableChecked) {
          var updatedDayAvailability : DailyAvailability = {
            dayOfWeek: props?.dayOfWeek,
            isAvailable: availableChecked,
            dailyAvailabilityID: "",
            providerAvailabilityID: "",
            specificTimeBlocks: dailyAvailabilities
          }

          props?.setDailyAvailablity(updatedDayAvailability);
        }
      }
    },[dailyAvailabilities, availableChecked, unavailableChecked])
    
    return (<>
            <div className="tw-flex tw-flex-col tw-space-y-15px">
              <div className="tw-flex tw-items-center tw-space-x-4">
                  <span className="tw-w-500px tw-flex tw-items-center">
                                                  <LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
                                                  {props?.dayOfWeek}
                                                  </LabelOutputs>
                                              </span>
                  <span className="tw-ml-2 tw-text-gray-700">Available</span>
                  <input
                  type="checkbox"
                  checked={availableChecked}
                  onChange={handleCheckboxChange}
                  disabled={!editMode}
                  className={`tw-form-checkbox tw-h-5 tw-w-5 ${editMode ? "tw-text-primary":"tw-text-gray-500"} tw-rounded`}
                  />
                  <span className="tw-ml-2 tw-text-gray-700">Not Available</span>
                  <input
                  type="checkbox"
                  checked={unavailableChecked}
                  onChange={handleUnAvailableCheckboxChange}
                  disabled={!editMode}
                  className={`tw-form-checkbox tw-h-5 tw-w-5 ${editMode ? "tw-text-primary":"tw-text-gray-500"} tw-rounded`}
                  />
              </div>
              {availableChecked ? <>
                          <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                            {dailyAvailabilities.map((availability, index) => {
                              return (
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                      <span className="tw-w-250px tw-flex tw-items-center">
                                          <LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
                                          {availability.displayStart} - {availability.displayEnd} 
                                          </LabelOutputs>
                                      </span>
                                      {editMode && <TrashIcon className="tw-w-5 tw-h-5 tw-text-red-400 hover:tw-text-red-500 tw-cursor-pointer" onClick={() => handleRemoveAvailability(availability.displayStart, availability.displayEnd)}/>}
                                </li>);

                            })}
                            </ul>
                            {editMode && <div className="tw-flex tw-flex-col tw-bg-white tw-space-x-5px tw-border tw-border-gray-800">
                                <div className="tw-flex tw-bg-white tw-p-4">
                                    <InputTimes id="startTime" value={availabilityStartTime} label="Start Time"  increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
                                        
                                        onChange={ e => {
                                          e.preventDefault();
                                          if(removeWhitespace(e.target.value) !== removeWhitespace(availabilityStartTime)) {
                                            console.log("Start Time", e.target.value);
                                            setAvailabilityStartTime(e.target.value);
                                          }
                                        }}
                                      />
                                      <InputTimes id="endTime" value={availabilityEndTime} label="End Time" className="tw-pl-8"  increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
                                        
                                        onChange={ e => {
                                          e.preventDefault();
                                          if(removeWhitespace(e.target.value) !== removeWhitespace(availabilityEndTime)) {
                                            console.log("End Time", e.target.value);
                                            setAvailabilityEndTime(e.target.value);
                                          }
                                        }}
                                      />

                                    <div className="tw-pl-4 tw-mt-7">
                                        <ButtonPrimary onClick={handleAddAvailability}>
                                            Add
                                        </ButtonPrimary>
                                    </div>
                                </div>
                                {showValidationError && <p className="tw-text-red-500 tw-pb-5 tw-px-5">{errorMessage}</p>}
                               
                            </div>}

                            
                            
              </>:<></>}
            </div>
            
    </>);
}

export default DailyAvailabilityComponent;
