import moment from "moment";
import { ClientAnnouncement } from "../../types/clientAnnouncement";
import { ButtonPrimary } from "../../components/buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import { getActiveClientAnnouncements, getArchivedClientAnnouncements } from "../../apicalls/announcementService";
import { Search } from "../../components/forms/formFields";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";

// const sampleAnnouncements: ClientAnnouncement[] = [
//     {
//         clientAnnouncementID: "123",
//         clientID: "456",
//         clientName: "John Doe",
//         announcementTitle: "New Policy",
//         announcementDescription: "New policy has been implemented",
//         announcementImpactLevel: "High",
//         dateCreated: moment().add(-5, 'days').toDate(),
//         createdBy: "mark.doe@ohioathome.com",
//         dateOfExpiry: moment().add(20, 'days').toDate(),
//         status: "Active",
//     },
//     {
//         clientAnnouncementID: "456",
//         clientID: "789",
//         clientName: "Jane Doe",
//         announcementTitle: "New Procedure",
//         announcementDescription: "New procedure has been implemented",
//         announcementImpactLevel: "Medium",
//         dateCreated: moment().add(-2, 'days').toDate(),
//         createdBy: "mark.doe@ohioathome.com",
//         dateOfExpiry: moment().add(15, 'days').toDate(),
//         status: "Active",
//     }
// ]

const tabs = [
    { name: 'Active/Draft', href: '#', current: true },
    { name: 'Archived', href: '#', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ClientAnnouncementsPage = props => {  
    let navigate = useNavigate();

    const navContext = useAppContext();

    const [loadingAnnouncements, setLoadingAnnouncements] = useState(false);

    const [activeAnnouncements, setActiveAnnouncements] = useState<ClientAnnouncement[]>([]);
    const [filteredAnnouncements, setFilteredAnnouncements] = useState<ClientAnnouncement[]>([]);

    const [archivedAnnouncements, setArchivedAnnouncements] = useState<ClientAnnouncement[]>([]);
    const [filteredArchivedAnnouncements, setFilteredArchivedAnnouncements] = useState<ClientAnnouncement[]>([]);

    const [currentTabs, setCurrentTabs] = useState(tabs);

    const NavigateToTab = (tab) => {
        setCurrentTabs(currentTabs.map((item) => {
            if (item.name === tab.name) {
                return {
                    ...item,
                    current: true
                }
            } else {
                return {
                    ...item,
                    current: false
                }
            }
        }));
    }

    useLayoutEffect(() => {
        setLoadingAnnouncements(true);

        const fetchAnnouncements = async () => {
            const result = await getActiveClientAnnouncements(navContext.handleAPICallError);

            setActiveAnnouncements(result);
            setFilteredAnnouncements(result);

            const archived = await getArchivedClientAnnouncements(navContext.handleAPICallError);

            setArchivedAnnouncements(archived);
            setFilteredArchivedAnnouncements(archived);

            setLoadingAnnouncements(false);
        };

        fetchAnnouncements();
    }, []);

    const handleAddAnnouncement = () => {
        console.log("Add Announcement");

        navigate(`/announcement/clients/add`);
    }

    const handleEditAnnouncement = (announcement: ClientAnnouncement) => {
        console.log("Edit Announcement", announcement.clientAnnouncementID);

        navigate(`/announcement/clients/${announcement.clientAnnouncementID}/edit`);
    }

    return (<>
        <div className="tw-px-xl tw-pt-xl">
            <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Client Announcements</h2>
            </div>

            <div className="tw-flex tw-mx-auto tw-pb-5">
                <label className="tw-block tw-flex-grow tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                    List of active client announcements for remote support associates
                </label>
                <ButtonPrimary onClick={handleAddAnnouncement}>
                    Add Client Announcement
                </ButtonPrimary>
            </div>

            {loadingAnnouncements ? <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                    <LoadingSpinner /> 
					<span className="tw-text-primary tw-text-5 tw-text-center">Loading Client Announcements</span>
                </div> :
            <div className="-tw-mx-4 -tw-my-2 tw-overflow-x-auto sm:-tw-mx-6 lg:-tw-mx-8">
                <div className="tw-flex-shrink-0 tw-w-full tw-px-10 tw-py-5">
						<div className="tw-flex tw-w-full tw-items-center">
							<div className="tw-flex-grow tw-flex tw-items-center">
								<Search className="tw-flex tw-w-full tw-flex-grow" onChange={ e => {

										//console.log(e?.target.value || "nope");

										if(e?.target.value){
											const searchText = e?.target.value.toString().toLowerCase();
											const filtered = activeAnnouncements.filter(element => {
												
												// 👇️ using AND (&&) operator
												return element.clientName.toLowerCase().includes(searchText);
											});

                                            const filteredArchived = archivedAnnouncements.filter(element => {
                                                return element.clientName.toLowerCase().includes(searchText);
                                            });

											//console.log(filtered)

											setFilteredAnnouncements(filtered);
                                            setFilteredArchivedAnnouncements(filteredArchived);
										} else {
											setFilteredAnnouncements(activeAnnouncements);
                                            setFilteredArchivedAnnouncements(archivedAnnouncements);
										}
										}}/>
							</div>
						</div>
				</div>
                <div className="hidden sm:block sm:tw-px-6 lg:tw-px-8">
                    <div className="tw-border-b tw-border-gray-200">
                    <nav className="-tw-mb-px tw-flex tw-space-x-8" aria-label="Tabs">
                        {currentTabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={classNames(
                            tab.current
                                ? 'tw-border-primary tw-text-primary'
                                : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-200 hover:tw-text-gray-700 tw-cursor-pointer',
                            'tw-flex tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => NavigateToTab(tab)}
                        >
                            {tab.name}
                            
                        </span>
                        ))}
                    </nav>
                    </div>
                </div>
                <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle sm:tw-px-6 lg:tw-px-8">
                    <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                    <thead>
                        <tr>
                        <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">
                            Client
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-break-words tw-max-w-250px tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Title
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Status
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Created By
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Created Date
                        </th>
                        <th scope="col" className="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-0">
                            <span className="tw-sr-only">Edit</span>
                        </th>
                        </tr>
                    </thead>
                    {currentTabs[0].current && <tbody className="tw-divide-y tw-divide-gray-200">
                        {filteredAnnouncements?.map((announcement) => (
                        <tr key={announcement.clientAnnouncementID} className="tw-cursor-pointer" onClick={ e => {
                            e.preventDefault();
                            handleEditAnnouncement(announcement);
                        }}>
                            <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">
                            {announcement.clientName}
                            </td>
                            <td className="tw-break-words tw-max-w-250px tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.announcementTitle}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.status}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.createdBy}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{moment(announcement.dateCreated).format("MM-DD-YY h:mm A")}</td>
                            <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-0">
                            <span className="tw-text-primary tw-cursor-pointer hover:tw-text-primary-dark" onClick={ e => {
                                                        e.preventDefault();
                                                        handleEditAnnouncement(announcement);
                                                    }}>
                                                        Edit<span className="tw-sr-only">, {announcement.clientAnnouncementID}</span>
                            </span>
                            </td>
                        </tr>
                        ))}
                    </tbody>}

                    {currentTabs[1].current && <tbody className="tw-divide-y tw-divide-gray-200">
                        {filteredArchivedAnnouncements?.map((announcement) => (
                        <tr key={announcement.clientAnnouncementID} className="tw-cursor-pointer" onClick={ e => {
                            e.preventDefault();
                            handleEditAnnouncement(announcement);
                        }}>
                            <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">
                            {announcement.clientName}
                            </td>
                            <td className="tw-break-words tw-max-w-250px tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.announcementTitle}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.status}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.createdBy}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{moment(announcement.dateCreated).format("MM-DD-YY h:mm A")}</td>
                            <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-0">
                            <span className="tw-text-primary tw-cursor-pointer hover:tw-text-primary-dark" onClick={ e => {
                                                        e.preventDefault();
                                                        handleEditAnnouncement(announcement);
                                                    }}>
                                                        Edit<span className="tw-sr-only">, {announcement.clientAnnouncementID}</span>
                            </span>
                            </td>
                        </tr>
                        ))}
                    </tbody>}
                    </table>
                </div>
            </div>}
        </div>
    </>);
}

export default ClientAnnouncementsPage;