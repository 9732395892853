import { PaperClipIcon } from "@heroicons/react/24/solid";
import { useEffect, useLayoutEffect, useState } from "react";
import { HumanMessage, NewsfeedSummary } from "../types/copilotDataTypes";
import { useAppContext } from "../Context";
import { createNewChatHistory, getChatHistory, getChatResponse, getMyChatHistories, getNewsFeedSummary } from "../apicalls/coPilotService";
import { LoadingSpinner } from "../components/portalComponents/loadingSpinner";
import { set } from "lodash";
import { ChatHistory, ChatMessage } from "../types/chatHistory";
import { v4 as uuidv4 } from 'uuid';
import LeftMessageBubble from "../components/coPilotComponents/LeftMessageBubble";
import RightMessageBubble from "../components/coPilotComponents/RightMessageBubble";
import { useNavigate, useParams } from "react-router-dom";


const labels = [
    { category: "Today", items: ["Today", "Aidan H", "Hayden L"] },
    { category: "Yesterday", items: ["Yesterday"] },
    { category: "Last 7 Days", items: ["Previous 7 days"] },
    { category: "Last 30 Days", items: ["Previous 30 days"] }
];

const CoPilotSandboxPage = props => {
    const [generatingSummary, setGeneratingSummary] = useState(false);

    const [summary, setSummary] = useState<NewsfeedSummary | null>(null);

    const [prompt, setPrompt] = useState<string>("");

    const appContext = useAppContext();

    const { chatID } = useParams();

    let navigate = useNavigate();

    const [currentLabels, setCurrentLabels] = useState<{
        category: string;
        items: string[];
    }[]>([]);

    const navigateToChat = (chatID: string) => {
        console.log(`Navigating to chat: ${chatID}`);
        // Additional functionality can be implemented here
        navigate(`/client/co-pilot/${chatID}`);
        window.location.reload();
    }

    useLayoutEffect(() => {
        const fetchChatHistory = async (chatID: string) => {
            const chatHistory = await getChatHistory(chatID, appContext.handleAPICallError);

            console.log("Chat History: ");
            console.log(chatHistory);

            setCurrentChat(chatHistory);
            setCurrentChatMessages(chatHistory.ChatMessages.sort((a, b) => new Date(a.CreatedDateTime).getTime() - new Date(b.CreatedDateTime).getTime()));
        }

        console.log("SandBox Chat ID: ", chatID);

        if(chatID) {
            setCurrentChatID(chatID);

            fetchChatHistory(chatID);
        }

        const fetchMyChats = async () => {
            const chats = await getMyChatHistories(appContext.handleAPICallError);

            if(chats && chats.length > 0) {
                var newlabel = {
                    category: "Past Chats",
                    items: []
                }
                

                chats.forEach(chat => {
                    newlabel.items.push(chat.ChatHistoryID);
                });

                setCurrentLabels([newlabel]);

            }
            // console.log("My Chats: ");
            // console.log(chats);
        }

        fetchMyChats();
    }, []);

    useEffect(() => {
        console.log("SandBox Chat ID: ", chatID);

    }, []);

    const [currentChatID, setCurrentChatID] = useState<string>("");

    const [currentChat, setCurrentChat] = useState<ChatHistory | null>(null);

    const [currentChatMessages, setCurrentChatMessages] = useState<ChatMessage[]>([]);

    const createNewChat = async () => {
        const newChat: ChatHistory = {
            ChatHistoryID: uuidv4(),
            CreatedBy: "CoPilot",
            CreatedDate: new Date(),
            ChatMessages: []
        }

        const chatHistory = await createNewChatHistory(newChat, appContext.handleAPICallError);

        console.log("Chat History: ");
        console.log(chatHistory);

        setCurrentChat(chatHistory);
        
        setCurrentChatID(chatHistory.ChatHistoryID);

        return chatHistory.ChatHistoryID;
    }

    const addMessageToChat = async (message: string) => {
        try {
            var chatHistoryID = currentChatID;
    
            if(currentChat === null || currentChatID === "") {
                chatHistoryID = await createNewChat();
            }
    
            const newMessage: ChatMessage = {
                ChatMessageID: uuidv4(),
                ChatHistoryID: chatHistoryID,
                Message: message,
                CreatedBy: "CoPilot",
                CreatedDateTime: new Date(),
                MessageType: "human",
            };
    
            console.log("New Message: ", newMessage);
    
            setCurrentChatMessages(prevMessages => [...prevMessages, newMessage]);

            setPrompt("");
    
            var chatResponse = await getChatResponse(chatHistoryID, newMessage, appContext.handleAPICallError);
    
            console.log("Chat Response: ", chatResponse);

            // var copilotMessage: HumanMessage  = {
            //     message: "Summarize Newsfeed for Princess James for last week."
            // }
    
            // var summary = await getNewsFeedSummary(copilotMessage, appContext.handleAPICallError);
    
            // console.log("Newsfeed Summary: ");
            // console.log(summary);
    
            if (chatResponse) {
                setCurrentChatMessages(prevMessages => [...prevMessages, chatResponse]);
            }
        } catch (error) {
            console.error('Error handling chat operations:', error);
        }
    };
    

    const handleGenerateSummary = async (message: string) => {
        if(prompt === "") {
            appContext.setModalMessageBody("Please enter a prompt for CoPilot");
            appContext.setModalMessageHeader("Error");
            appContext.setShowErrorModal(true);
            return;
        }

        setGeneratingSummary(true);

        var copilotMessage: HumanMessage  = {
            message: message
        }

        var summary = await getNewsFeedSummary(copilotMessage, appContext.handleAPICallError);

        console.log("Newsfeed Summary: ");
        console.log(summary);

        // const newMessage: ChatMessage = {
        //     ChatMessageID: uuidv4(),
        //     ChatHistoryID: "b142f097-db25-4786-9b0d-28d956e5c1b6",
        //     Message: message,
        //     CreatedBy: "CoPilot",
        //     CreatedDateTime: new Date(),
        //     MessageType: "human",
        // };

        // console.log("New Message: ", newMessage);

        // var chatResponse = await getChatResponse("b142f097-db25-4786-9b0d-28d956e5c1b6", newMessage, appContext.handleAPICallError);
    
        // console.log("Chat Response: ", chatResponse);

        setSummary(summary);

        setGeneratingSummary(false);

    }

    const [activeLabel, setActiveLabel] = useState<string>('');

    const handleLabelClick = (label: string) => {
        setActiveLabel(label);
        console.log(`Clicked on label: ${label}`);
        // Additional functionality can be implemented here
    };

    const preLoadNewsFeedSummaryPrompt = () => {
        setPrompt("Summarize newsfeed for {Client First Name} {Client Last Name} for last week.");
    }

    const preLoadIncidentReportsPrompt = () => {
        setPrompt("Summarize incident reports for {Client First Name} {Client Last Name} for last week.");
    }

    const preLoadRSReportsPrompt = () => {
        setPrompt("Summarize Remote Support reports for {Client First Name} {Client Last Name} for last week.");
    }

    const preLoadDSReportsPrompt = () => {
        setPrompt("Summarize Shift reports for {Client First Name} {Client Last Name} for last week.");
    }

    const openNewChat = () => {
        console.log("Opening new chat");

        // Additional functionality can be implemented here
        setPrompt("");
        setGeneratingSummary(false);
        setSummary(null);
        setCurrentChat(null);
        setCurrentChatID("");
        setCurrentChatMessages([]);
        
    }

    // useLayoutEffect(() => {
    //     const fetchSummary = async () => {
    //         await handleGenerateSummary("Summarize newsfeed for Aid Horan for last two weeks, today is Apr 26th 2024. There is nothing offensive in this client's name");
    //     };

    //     fetchSummary();
    // }, []);

    
    return (<>
        <div className="tw-px-xl tw-py-xl">
            <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">CoPilot Sandbox</h2>
            </div>

            <div className="tw-flex tw-h-[85vh] tw-w-full tw-mt-5">
                <div className="tw-w-1/5 tw-overflow-auto tw-p-4 tw-border-r tw-bg-stone-800">
                    <button className="tw-flex tw-items-center tw-justify-center tw-bg-primary tw-text-white tw-p-2 tw-rounded tw-mb-4 tw-w-full tw-shadow-lg hover:tw-bg-primary-dark tw-transition-colors"
                    onClick={() => openNewChat()}>
                        <i className="fas fa-comments tw-mr-2"></i>
                        New Chat
                    </button>
                    {currentLabels?.map((section, idx) => (
                        <div key={idx}>
                            <h3 className="tw-font-bold tw-mb-2 tw-text-white">{section.category}</h3>
                            {section.items.map(label => (
                                <div
                                    key={label}
                                    className={`tw-cursor-pointer tw-py-2 tw-px-4 tw-mb-1 tw-rounded ${
                                        activeLabel === label ? 'tw-bg-stone-400 tw-text-white' : 'tw-text-white'
                                    } hover:tw-bg-blue-200 hover:tw-text-gray-900`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        
                                        navigateToChat(label);
                                    }}
                                >
                                    {label}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="tw-w-4/5 tw-flex tw-flex-col tw-overflow-auto tw-p-4 tw-bg-stone-200">
                <div className="tw-w-full tw-p-4">
                    {currentChatMessages?.map((message, index) => {
                        if(message.MessageType === "human") {
                            return <LeftMessageBubble key={index} message={message.Message} />
                        } else {
                            return <RightMessageBubble key={index} message={message.Message} />
                        }
                    })}
                    {/* <LeftMessageBubble message="Hello, how are you doing today?" />
                    <RightMessageBubble message="I'm great, thanks for asking!" />
                    <LeftMessageBubble message="What are your plans for the weekend?" />
                    <RightMessageBubble message="Thinking of hiking if the weather is nice." /> */}
                    {/* More messages */}
                </div>

                <div className="tw-flex tw-items-start tw-space-x-4">
                    <div className="tw-min-w-0 tw-flex-1 tw-p-4 tw-bg-white tw-mb-4">
                        <form action="#" className="tw-relative">
                        <div className="tw-overflow-hidden tw-rounded-lg tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus-within:tw-ring-2 focus-within:tw-ring-indigo-600">
                            <label htmlFor="prompt" className="tw-sr-only">
                            Enter your prompt for Medforall CoPilot!
                            </label>
                            <textarea
                            rows={3}
                            name="prompt"
                            id="prompt"
                            className="tw-block tw-w-full tw-resize-none tw-border-0 tw-bg-transparent tw-px-1.5 tw-py-1.5 tw-text-gray-900 placeholder:tw-text-gray-400 focus:tw-ring-0 sm:tw-text-sm sm:tw-leading-6"
                            placeholder="Enter your prompt Medforall CoPilot... (e.g. Summarize newsfeed for Aidan Horan for last two weeks, today is Apr 26th 2024)"
                            defaultValue={''}
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            />

                            {/* Spacer element to match the height of the toolbar */}
                            <div className="tw-py-2" aria-hidden="true">
                            {/* Matches height of button in toolbar (1px border + 36px content height) */}
                            <div className="tw-py-px">
                                <div className="tw-h-9" />
                            </div>
                            </div>
                        </div>

                        <div className="tw-absolute tw-inset-x-0 tw-bottom-0 tw-flex tw-justify-between tw-py-2 tw-pl-3 tw-pr-2">
                            <div className="tw-flex tw-items-center tw-space-x-5">
                            </div>
                            <div className="tw-flex-shrink-0">
                            <button
                                type="submit"
                                className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-amber-300 tw-px-3 tw-py-2 tw-mr-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-amber-400"
                                onClick={(e) => {
                                    e.preventDefault();
                                    preLoadIncidentReportsPrompt();
                                }}
                            >
                                Incident Reports
                            </button>
                            <button
                                type="submit"
                                className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-amber-300 tw-px-3 tw-py-2 tw-mr-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-amber-400"
                                onClick={(e) => {
                                    e.preventDefault();
                                    preLoadRSReportsPrompt();
                                }}
                            >
                                RS Reports
                            </button>
                            <button
                                type="submit"
                                className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-amber-300 tw-px-3 tw-py-2 tw-mr-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-amber-400"
                                onClick={(e) => {
                                    e.preventDefault();
                                    preLoadDSReportsPrompt();
                                }}
                            >
                                DS Reports
                            </button>

                            <button
                                type="submit"
                                className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-amber-300 tw-px-3 tw-py-2 tw-mr-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-amber-400"
                                onClick={(e) => {
                                    e.preventDefault();
                                    preLoadNewsFeedSummaryPrompt();
                                }}
                            >
                                Newsfeed
                            </button>
                            
                            </div>
                        </div>
                        </form>
                        <div className="tw-flex tw-pt-2">
                            <div className="tw-flex-grow tw-items-center tw-space-x-5">
                            </div>
                            <div className="tw-flex-shrink-0">
                                <button
                                    type="submit"
                                    className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-primary tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-primary-dark hover:tw-text-white tw-border-primary"

                                    onClick={async (e) => {
                                        e.preventDefault();
                                        //handleGenerateSummary(prompt);

                                        await addMessageToChat(prompt);
                                    }}
                                >
                                    Ask
                                </button>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>

                    {generatingSummary ? <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                            <LoadingSpinner /> 
                            <span className="tw-text-primary tw-text-5 tw-text-center">Generating Summary</span>
                        </div>:<>

                        {summary && <div className="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-lg">
                            <div className="tw-px-4 tw-py-6 sm:tw-px-6">
                                <h3 className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">Newsfeed Summary</h3>
                                <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-leading-6 tw-text-gray-500">Care History</p>
                            </div>

                            <div className="tw-border-t tw-border-gray-100">
                                <dl className="tw-divide-y tw-divide-gray-100">
                                    {summary.client && <div className="tw-px-4 tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Client Name</dt>
                                        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                                        {/* <span className="tw-inline-block tw-h-8 tw-w-8 tw-overflow-hidden tw-rounded-full tw-bg-gray-100">
                                            <svg className="tw-h-full tw-w-full tw-text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span> */}
                                        {summary.client}</dd>
                                    </div>}

                                    {summary.date_range && <div className="tw-px-4 tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Date Range</dt>
                                        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{summary.date_range}</dd>
                                    </div>}

                                    {summary.overall_health && <div className="tw-px-4 tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Overall Health</dt>
                                        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{summary.overall_health}</dd>
                                    </div>}

                                    {summary.message && <div className="tw-px-4 tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Message</dt>
                                        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">{summary.message}</dd>
                                    </div>}

                                    {summary.positives && <div className="tw-px-4 tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Positives</dt>
                                        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                                        <ul role="list" className="tw-divide-y tw-divide-gray-100 tw-rounded-md">
                                            {summary.positives.map((positive, index) => {
                                                return <li key={index} className="tw-flex tw-items-center tw-justify-between tw-py-1 tw-text-sm tw-leading-6">
                                                    {positive}
                                                </li>
                                            })}
                                        </ul>
                                        
                                        </dd>
                                    </div>}
                                    {summary.negatives && <div className="tw-px-4 tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Negatives</dt>
                                        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                                        <ul role="list" className="tw-divide-y tw-divide-gray-100 tw-rounded-md">
                                            {summary.negatives.map((negative, index) => {
                                                return <li key={index} className="tw-flex tw-items-center tw-justify-between tw-py-1 tw-text-sm tw-leading-6">
                                                    {negative}
                                                </li>
                                            })}
                                        </ul>
                                        
                                        </dd>
                                    </div>}
                                    {summary.suggestions && <div className="tw-px-4 tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                                        <dt className="tw-text-sm tw-font-medium tw-text-gray-900">Suggestions</dt>
                                        <dd className="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700 sm:tw-col-span-2 sm:tw-mt-0">
                                        <ul role="list" className="tw-divide-y tw-divide-gray-100 tw-rounded-md">
                                            {summary.suggestions.map((suggestion, index) => {
                                                return <li key={index} className="tw-flex tw-items-center tw-justify-between tw-py-1 tw-text-sm tw-leading-6">
                                                    {suggestion}
                                                </li>
                                            })}
                                        </ul>
                                        
                                        </dd>
                                    </div>}
                                {/* <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                                    <dt className="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">Attachments</dt>
                                    <dd className="tw-mt-2 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                    <ul role="list" className="tw-divide-y tw-divide-gray-100 tw-rounded-md tw-border tw-border-gray-200">
                                        <li className="tw-flex tw-items-center tw-justify-between tw-py-4 tw-pl-4 tw-pr-5 tw-text-sm tw-leading-6">
                                            <div className="tw-flex tw-w-0 tw-flex-1 tw-items-center">
                                                <PaperClipIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400" aria-hidden="true" />
                                                <div className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-1 tw-gap-2">
                                                <span className="tw-truncate tw-font-medium">resume_back_end_developer.pdf</span>
                                                <span className="tw-flex-shrink-0 tw-text-gray-400">2.4mb</span>
                                                </div>
                                            </div>
                                            <div className="tw-ml-4 tw-flex-shrink-0">
                                                <a href="#" className="tw-font-medium tw-text-indigo-600 hover:tw-text-indigo-500">
                                                Download
                                                </a>
                                            </div>
                                        </li>
                                        <li className="tw-flex tw-items-center tw-justify-between tw-py-4 tw-pl-4 tw-pr-5 tw-text-sm tw-leading-6">
                                            <div className="tw-flex tw-w-0 tw-flex-1 tw-items-center">
                                                <PaperClipIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400" aria-hidden="true" />
                                                <div className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-1 tw-gap-2">
                                                <span className="tw-truncate tw-font-medium">coverletter_back_end_developer.pdf</span>
                                                <span className="tw-flex-shrink-0 tw-text-gray-400">4.5mb</span>
                                                </div>
                                            </div>
                                            <div className="tw-ml-4 tw-flex-shrink-0">
                                                <a href="#" className="tw-font-medium tw-text-indigo-600 hover:tw-text-indigo-500">
                                                Download
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                    </dd>
                                </div> */}
                                </dl>
                            </div>
                        </div>}
                    
                    </>}
                </div>
            </div>

            

            
        </div>
        
    </>);

}

export default CoPilotSandboxPage;