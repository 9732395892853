import { placeholder, rem } from "../../utilities/helpers";
import { pageSubHeader, paragraph2, paragraph3 } from "../../utilities/typography";
import { ButtonAnchor } from "../buttons/Buttons";
import { Label } from "../forms/formFields";
import { PageSubHeader } from "../typography/Headers";
import { Ellipsis } from "../utilities/Helpers";
import { Avatar } from "./ImageContainer";

export const Comment = props => {
	const Tag = props.tag || 'div';
	const classes = `tw-bg-light-accent tw-text-dark tw-p-sm ${ paragraph2 } ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;

	const attributes = {
		...props,
		className: classes,
		tag: null,
		coreClasses: null
	}

	return <Tag { ...attributes } />
}

export const ContactCard = props => {
	const user = props.user || {
		"avatar": null,
		"firstName": "Kevin",
		"lastName": "Mack",
		"email": "kmack@discreetaf.com",
		"phone": "614-565-8967",
		"address": "123 Street Name St, City, ST 12345"
	}

	return <>
		<div className={`tw-bg-white tw-w-full tw-max-w-600 tw-p-sm ${ props.className ? ' || ' + props.className : '' }`}>
			<div className="tw-min-w-0 tw-flex tw-items-center">
				<div className="tw-flex-shrink-0 tw-w-12 tw-mr-sm tw-self-start">
					<Avatar src={ user?.avatar || placeholder('200/000000/FFFFFF?text=User') } />
				</div>
				<div className="tw-min-w-0 tw-flex-grow tw-pr-x">
					<PageSubHeader title={ user?.firstName + ' ' + user?.lastName } tag="span" className="tw-dark tw-max-w-full tw-flex tw-border-b tw-border-light-light tw-pb-1">
						<span className="tw-block tw-max-w-full">
							<span className="tw-flex tw-items-baseline">
								<Ellipsis className="tw-max-w-[30.625rem]">
									{ user?.firstName }
								</Ellipsis>
								<span className="tw-flex-shrink-0">, { user?.lastName?.substring(0,1)}<span className="tw-hidden">{ user?.lastName?.substring(1)}</span></span>
							</span>
						</span>
					</PageSubHeader>
					<div className="tw-flex  tw-items-center">
						<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-pr-sm">
							<ButtonAnchor href={`mailto:kmack@discreetaf.com`} title={`Email ${ user?.email }` } className="tw-min-w-0 tw-py-sm" svg={`<svg width="${rem("18")}" height="${rem("16")}"  viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z" fill="currentColor"/></svg>`}>
								<Ellipsis className="tw-max-w-320 tw-text-dark tw-text-4">
									{ user?.email }
								</Ellipsis>
							</ButtonAnchor>
						</div>
						<div className="tw-flex-shrink-0 before:tw-pr-sm before:tw-content-['|'] before:tw-text-light-light tw-flex tw-items-center">
							<ButtonAnchor href={`tel:+1${ user?.phone.replaceAll('-','').replaceAll('(', '').replaceAll(')', '') }`} title={`Call ${ user?.phone }`} className="tw-min-w-0 tw-py-sm" svg={`<svg  width="${rem("18")}" height="${rem("18")}" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 12.5C15.75 12.5 14.55 12.3 13.43 11.93C13.08 11.82 12.69 11.9 12.41 12.17L10.21 14.37C7.38 12.93 5.06 10.62 3.62 7.79L5.82 5.58C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.5C18 12.95 17.55 12.5 17 12.5ZM9 0V10L12 7H18V0H9Z" fill="currentColor"/></svg>`}>
								<span className="tw-text-dark tw-text-4">{ user?.phone }</span>
							</ButtonAnchor>
						</div>
					</div>
					<div>
						<ButtonAnchor href={`https://maps.google.com/maps?q=${user?.address.replaceAll(' ','+')}`} title={`View ${ user?.address } on maps`} className="tw-min-w-0 tw-py-sm" svg={`<svg width="${rem("20")}" height="${rem("20")}" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z" fill="currentColor"/></svg>`}>
							<Ellipsis className="tw-max-w-[30.625rem] tw-text-dark tw-text-4">
								{ user?.address }
							</Ellipsis>
						</ButtonAnchor>
					</div>
				</div>
			</div>
		</div>
	</>
}

export const ClientInfoCard = props => {
	const user = props.user || {
		"avatar": null,
		"firstName": "Chance",
		"lastName": "Gardner",
		"email": "gardner.2@medforall.io",
		"phone": "614-565-8967",
		"address": "123 Street Name St, City, ST 12345"
	}

	return <>
		<div className={`tw-bg-white tw-w-full tw-max-w-600px tw-p-sm ${ props.className ? ' || ' + props.className : '' }`}>
			<div className="tw-min-w-0 tw-flex tw-items-center">
				<div className="tw-flex-shrink-0 tw-w-12 tw-mr-sm tw-self-start">
					<Avatar src={ user?.avatar || placeholder('200/000000/FFFFFF?text=User') } />
				</div>
				<div className="tw-min-w-0 tw-flex-grow tw-pr-x">
					<PageSubHeader title={ props.firstName + ' ' + props.lastName } tag="span" className="tw-dark tw-max-w-full tw-flex tw-border-b tw-border-light-light tw-pb-1">
						<span className="tw-block tw-max-w-full">
							<span className="tw-flex tw-items-baseline">
								<Ellipsis className="tw-max-w-[30.625rem]">
									{ props.firstName }
								</Ellipsis>
								<span className="tw-flex-shrink-0">, { props.lastName?.substring(0,1)}<span className="tw-hidden">{ user?.lastName?.substring(1)}</span></span>
							</span>
						</span>
					</PageSubHeader>
					<div className="tw-flex  tw-items-center">
						<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-pr-sm">
							<ButtonAnchor href={`mailto:kmack@discreetaf.com`} title={`Email ${ user?.email }` } className="tw-min-w-0 tw-py-sm" svg={`<svg width="${rem("18")}" height="${rem("16")}"  viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z" fill="currentColor"/></svg>`}>
								<Ellipsis className="tw-max-w-320 tw-text-dark tw-text-4">
									{ props.email }
								</Ellipsis>
							</ButtonAnchor>
						</div>
						<div className="tw-flex-shrink-0 before:tw-pr-sm before:tw-content-['|'] before:tw-text-light-light tw-flex tw-items-center">
							<ButtonAnchor href={`tel:+1${ user?.phone.replaceAll('-','').replaceAll('(', '').replaceAll(')', '') }`} title={`Call ${ user?.phone }`} className="tw-min-w-0 tw-py-sm" svg={`<svg  width="${rem("18")}" height="${rem("18")}" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 12.5C15.75 12.5 14.55 12.3 13.43 11.93C13.08 11.82 12.69 11.9 12.41 12.17L10.21 14.37C7.38 12.93 5.06 10.62 3.62 7.79L5.82 5.58C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.5C18 12.95 17.55 12.5 17 12.5ZM9 0V10L12 7H18V0H9Z" fill="currentColor"/></svg>`}>
								<span className="tw-text-dark tw-text-4">{ user?.phone }</span>
							</ButtonAnchor>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export const CommentBlock = props => {
	const Tag = props.tag || 'div';
	const classes = `c-comment-block${ paragraph2 } ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;

	const attributes = {
		...props,
		className: classes,
		tag: null,
		coreClasses: null,
		childTag: null,
		childClasses: null,
	}

	return <Tag>
		<Label tag="h6">
			{ props.title || 'props.title' }
		</Label>
		<Comment childTag={ props.childTag || null } className={ props.childClasses || null } { ...attributes }>
			{ props.children }
		</Comment>
	</Tag>
}


export const SessionCardHours = props => {

	return <>
		<span className={`tw-flex tw-py-sm tw-px-lg tw-space-x-md  tw-items-center${ props.className ? ' || ' + props.className : '' }`}>
			<span className={`tw-flex tw-flex-shrink-0 tw-items-center tw-w-250 ${ pageSubHeader }`}>
				<span className="tw-w-12 tw-flex-shrink-0 tw-mr-md">
					<Avatar className="tw-w-full" src={ props.image || null } alt={`${ props.firstName } ${ props.lastName } profile image`} />
				</span>
				<Ellipsis>
					{`${props.firstName } ${props.lastName }`}
				</Ellipsis>
			</span>
			<span className="tw-w-150 tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.hours }
			</span>
			<span className="tw-flex-grow tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.amount }
			</span>
			<span className="tw-flex-grow tw-flex-shrink-0 tw-items-center tw-text-left">
				{ props?.status === 'review' ? <>
					<span className={`tw-text-[#EC7274] ${ paragraph3 }`}>Needs review</span>
				</> : <></>}
			</span>
			<span className="tw-ml-auto tw-flex tw-justify-end tw-justify-end tw-items-center">
				<img className="tw-w-3 tw-inline-flex tw-ml-auto" src="/images/icon-arrow-r.svg" alt={`View ${ props.firstName } ${ props.lastName }'s session details`}  />
			</span>
		</span>
	</>
}


export const ClaimDetailsCard = props => {

	return <>
		<span className={`tw-flex tw-py-sm tw-px-lg tw-space-x-md  tw-items-center${ props.className ? ' || ' + props.className : '' }`}>
			<span className={`tw-flex tw-flex-shrink-0 tw-items-center tw-w-300px tw-text-2`}>
				
				<Ellipsis>
					{`${props.serviceDate }`}
				</Ellipsis>
			</span>
			<span className="tw-w-150px tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.serviceType }
			</span>
			<span className="tw-w-100px tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.units }
			</span>
			<span className="tw-w-150px tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.budgetStatus }
			</span>
			<span className="tw-flex-grow tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.totalAmount }
			</span>
			{/* <span className="tw-flex-grow tw-flex-shrink-0 tw-items-center tw-text-left">
				{ props?.status === 'review' ? <>
					<span className={`tw-text-[#EC7274] ${ paragraph3 }`}>Needs review</span>
				</> : <></>}
			</span> */}
			<span className="tw-ml-auto tw-flex tw-justify-end tw-justify-end tw-items-center">
				<img className="tw-w-3 tw-inline-flex tw-ml-auto" src="/images/icon-arrow-r.svg" alt={`View ${ props.firstName } ${ props.lastName }'s session details`}  />
			</span>
		</span>
	</>
}

export const ClientDetailsCard = props => {

	return <>
		<span className={`tw-flex tw-py-sm tw-px-lg tw-space-x-md  tw-items-center${ props.className ? ' || ' + props.className : '' }`}>
			<span className={`tw-flex tw-flex-shrink-0 tw-items-center tw-w-250px tw-text-2`}>
				
				<Ellipsis>
					{`${props.clientFirstName} ${props.clientLastName}`}
				</Ellipsis>
			</span>
			<span className="tw-w-250px tw-flex-shrink-0 tw-items-center tw-text-left tw-text-5">
				{ props.clientEmail }
			</span>
			<span className="tw-flex-grow tw-flex-shrink-0 tw-items-center tw-text-left tw-text-5">
				{ props.clientCounty }
			</span>
			<span className="tw-ml-auto tw-flex tw-justify-end tw-justify-end tw-items-center">
				<img className="tw-w-3 tw-inline-flex tw-ml-auto" src="/images/icon-arrow-r.svg" alt={`View ${ props.firstName } ${ props.lastName }'s session details`}  />
			</span>
		</span>
	</>
}

export const ClientClaimDetailsCard = props => {

	return <>
		<span className={`tw-flex tw-py-sm tw-px-lg tw-space-x-md  tw-items-center${ props.className ? ' || ' + props.className : '' }`}>
			<span className={`tw-flex tw-flex-shrink-0 tw-items-center tw-w-250px tw-text-2`}>
				
				<Ellipsis>
					{`${props.clientName }`}
				</Ellipsis>
			</span>
			<span className="tw-w-150px tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.directSupportUnits }
			</span>
			<span className="tw-w-150px tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.remoteSupportUnits }
			</span>
			<span className="tw-w-150px tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.tranportationUnits }
			</span>
			<span className="tw-flex-grow tw-flex-shrink-0 tw-items-center tw-text-left tw-text-2">
				{ props.totalAmount }
			</span>
			{/* <span className="tw-flex-grow tw-flex-shrink-0 tw-items-center tw-text-left">
				{ props?.status === 'review' ? <>
					<span className={`tw-text-[#EC7274] ${ paragraph3 }`}>Needs review</span>
				</> : <></>}
			</span> */}
			<span className="tw-ml-auto tw-flex tw-justify-end tw-justify-end tw-items-center">
				<img className="tw-w-3 tw-inline-flex tw-ml-auto" src="/images/icon-arrow-r.svg" alt={`View ${ props.firstName } ${ props.lastName }'s session details`}  />
			</span>
		</span>
	</>
}

export const SessionCard = props => {
	return <>
		<span className={`tw-flex tw-py-sm tw-px-lg tw-space-x-md  tw-items-center${ props.className ? ' || ' + props.className : '' }`}>
			<span className={`tw-flex tw-flex-grow tw-flex-shrink-0 tw-items-center tw-w-250 ${ pageSubHeader }`}>
				<span className="tw-w-12 tw-flex-shrink-0 tw-mr-md">
					<Avatar className="tw-w-full" src={ props.image || null } alt={`${ props.firstName } ${ props.lastName } profile image`} />
				</span>
				<Ellipsis>
					{ props.lastName }
				</Ellipsis>
				{`,`}&nbsp;
				<span>
					{ props.firstName.slice(0,1) }
					{ props.firstName.length > 1 && <span className="tw-hidden">{ props.firstName.slice(1) }</span> }
				</span>
			</span>
			<span className="tw-flex-grow tw-flex-shrink-0 tw-items-center">
				{ props.date }
			</span>
			<span className="tw-flex-grow tw-flex-shrink-0 tw-items-center">
				{ props.day }
			</span>
			<span className="tw-flex-grow tw-flex-shrink-0 tw-items-center">
				{ props.time }
			</span>
			<span className="tw-ml-auto tw-flex tw-justify-end tw-justify-end tw-items-center">
				<img className="tw-w-3 tw-inline-flex tw-ml-auto" src="/images/icon-arrow-r.svg" alt={`View ${ props.firstName } ${ props.lastName }'s session details`}  />
			</span>
		</span>
	</>
}
