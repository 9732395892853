import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context";
import { useLayoutEffect, useState } from "react";
import { getAvailabilityPeriodResponseCount, getCurrentAvailabilityPeriods } from "../../apicalls/availabilityService";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import moment from "moment";
import { ButtonPrimary } from "../../components/buttons/Buttons";

interface AvailabilityPeriodResponseCount {
    periodID: string;
    count: number;
}

const AvailabilityResponsesPage = props => {
    let navigate = useNavigate();
    const navContext = useAppContext();
    const [loadingPeriods, setLoadingPeriods] = useState(true);
    const [periods, setPeriods] = useState([]);
    const [responses, setResponses] = useState<AvailabilityPeriodResponseCount[]>([]); // [{periodID: "123", count: 5}, {periodID: "456", count: 10}

    useLayoutEffect(() => {
        const fetchPeriods = async () => {
            setLoadingPeriods(true);

            const periods = await getCurrentAvailabilityPeriods(navContext.handleAPICallError);

            // iterate through periods and get responses for each period
            periods.forEach(async (period) => {
                const responses = await getAvailabilityPeriodResponseCount(period.periodID, navContext.handleAPICallError);
                console.log(responses);
                period.count = responses;
                var newResponse = {periodID: period.periodID, count: responses};
                setResponses(responses => [...responses, newResponse]);
            });

            console.log(periods);

            setPeriods(periods);

            setLoadingPeriods(false);
        }

        fetchPeriods();
    }, [])

    const getPeriodCount = (periodID: string) => {
        var count = 0;
        responses.forEach((response) => {
            if (response.periodID === periodID) {
                count = response.count;
            }
        });
        return count;
    }

    const goToSubmission = (periodID: string) => {
        navigate(`/availability/submissions/${periodID}/submissions`);
    }
    
    return (<>
        <div className="tw-px-xl tw-py-sm">
                <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Availability Submissions</h2>
                </div>

                {loadingPeriods ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>:

                <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                    {periods.map((period, index) => {
                        return (
                            <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                                <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                        <span className="tw-w-1/6">
                                            <span className="tw-text-dark tw-font-semibold">{period.name}</span>
                                        </span>
                                        <span className="tw-w-1/2">
                                            <span className="tw-text-dark">{moment.utc(period.startDate).format('dddd, MMM Do YYYY')} to {moment.utc(period.endDate).format('dddd, MMM Do YYYY')}</span>
                                        </span>
                                        <span className="tw-w-1/6">
                                            <span className="tw-text-dark tw-font-semibold">{getPeriodCount(period.periodID)} responses</span>
                                        </span>
                                        <ButtonPrimary className="tw-mb-md" onClick={() => goToSubmission(period.periodID)}>
                                            See Responses
                                        </ButtonPrimary>
                                </div>
                            </li>
                        )
                    })}
                    {periods.length === 0 && <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-md">
                        <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-1/2">
                                            <span className="tw-text-dark tw-font-semibold">No Current Availability Periods</span>
                                        </span>
                        </div>
                    </li>
                        }
                    {/* <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                        <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                <span className="tw-w-1/3">
                                    <span className="tw-text-dark tw-font-semibold">Spring Semester 2024</span>
                                </span>
                                <span className="tw-w-1/2">
                                    <span className="tw-text-dark">Monday, Jan 8th 2024 to Sunday, April 21st 2024</span>
                                </span>
                                <ButtonPrimary className="tw-mb-md" onClick={() => goToForm("SpringSemester")}>
                                    Go To Form
                                </ButtonPrimary>
                        </div>
                    </li> */}
                </ul>}
        </div>
    </>);
}

export default AvailabilityResponsesPage;