import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

interface SuccessDialogProps {
    open: boolean;
    onClose: () => void;
    closeButtonText?: string;
    title: string;
    message: string;
}

const SuccessDialog: React.FC<SuccessDialogProps> = ({ open, onClose, closeButtonText, title, message }) => {
    return (
        <Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="tw-relative tw-z-10" onClose={onClose}>
				<Transition.Child
				as={Fragment}
				enter="tw-ease-out tw-duration-300"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-ease-in tw-duration-200"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
				>
				<div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
				</Transition.Child>

				<div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
					<div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
						<Transition.Child
						as={Fragment}
						enter="tw-ease-out tw-duration-300"
						enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
						enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leave="tw-ease-in tw-duration-200"
						leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
						>
						<Dialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6">
							<div>
							<div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100">
								<CheckIcon className="tw-h-6 tw-w-6 tw-text-green-600" aria-hidden="true" />
							</div>
							<div className="tw-mt-3 tw-text-center sm:tw-mt-5">
								<Dialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
								 {title}
								</Dialog.Title>
								<div className="tw-mt-2">
								<p className="tw-text-sm tw-text-gray-500">
									{message}
								</p>
								</div>
							</div>
							</div>
							<div className="tw-mt-5 sm:tw-mt-6">
							<button
								type="button"
								className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-primary tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-primary-dark focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-indigo-600"
								onClick={onClose}
							>
								{closeButtonText ? closeButtonText : "Close"}
							</button>
							</div>
						</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
    );
};

export default SuccessDialog;