import { CheckIcon, ChevronRightIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { CircularProgress } from "@mui/material";
import { Fragment, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getClient } from "../apicalls/clientMgmtService";
import {addServiceLocation, findDuplicateLocation, getAddressLatLng} from "../apicalls/serviceLocationService";
import Patient from "../types/patient";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Dialog as TWDialog, Transition } from '@headlessui/react'
import ServiceLocation, { Address, HomeCredential, Room } from "../types/serviceLocation";
import { useAppContext } from "../Context";

const steps = [
    { id: '1', name: 'Address', href: '#', status: 'current' },
    { id: '2', name: 'Details', href: '#', status: 'upcoming' },
    { id: '3', name: 'Preview', href: '#', status: 'upcoming' },
  ]

export const roomSchema = Yup.object().shape({
    RoomName: Yup.string().required("Required"),
    RoomType: Yup.string().required("Required"),
});

export const addressSchema = Yup.object().shape({
    AddressType: Yup.string().required("Required"),
    Street1: Yup.string().required("Required"),
    City: Yup.string().required("Required"),
    State: Yup.string().required("Required"),
    ZipCode: Yup.string().required("Required").matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Invalid Zip Code"),
});

export const credentialSchema = Yup.object().shape({
    CredentialName: Yup.string().required("Required"),
    CredentialType: Yup.string().required("Required"),
    UserName: Yup.string().required("Required"),
    Password: Yup.string().required("Required"),
});

const NewServiceLocationPage = props => {
    const [activeSteps, setActiveSteps] = useState(steps);
    const [showHomeCredentialCard, setShowHomeCredentialCard] = useState(false);
    const [showSmartSensorCard, setShowSmartSensorCard] = useState(false);
    const [showSmartHubCard, setShowSmartHubCard] = useState(false);
    const [showSmartCameraCard, setShowSmartCameraCard] = useState(false);
    const [showRoomCard, setShowRoomCard] = useState(false);
    const { clientID } = useParams();
    const [clientLoading, setClientLoading] = useState(true);
    const [patient, setPatient] = useState<Patient>();
    const [addressLatLng, setAddressLatLng] = useState({lat: 0, lng: 0});
    const [addressRooms, setAddressRooms] = useState([]);
    const [addressHomeCredentials, setAddressHomeCredentials] = useState([]);
    const [newServiceLocation, setNewServiceLocation] = useState<ServiceLocation>();
    const [savingLocation, setSavingLocation] = useState(false);
    const [saveLocationOpen, setSaveLocationOpen] = useState(false);
    const [duplicateLocationOpen, setDuplicateLocationOpen] = useState(false);
    let navigate = useNavigate();

    const appContext = useAppContext();

    useLayoutEffect(() => {
        console.log("Client ID");
        console.log(clientID);

        const fetchPatient = async () => {
            var client = await getClient(clientID, appContext.handleAPICallError);
            console.log(client);
            setPatient(client);
            setClientLoading(false);
        }

        fetchPatient()
            .catch(console.error);
        
    },[])

    const handleLocationSuccessClose = () => {
		setSaveLocationOpen(false);

		navigate(-1);
	}

    const goToAddressForm = () => {
        setActiveSteps([
            { id: '1', name: 'Address', href: '#', status: 'current' },
            { id: '2', name: 'Details', href: '#', status: 'upcoming' },
            { id: '3', name: 'Preview', href: '#', status: 'upcoming' },
            ]);
    }

    const goToDetailsForm = () => {
        setActiveSteps([
            { id: '1', name: 'Address', href: '#', status: 'complete' },
            { id: '2', name: 'Details', href: '#', status: 'current' },
            { id: '3', name: 'Preview', href: '#', status: 'upcoming' },
            ]);
    }


    const toggleHomeCredentialCard = () => {
        credentialFormik.resetForm();
        setShowHomeCredentialCard(!showHomeCredentialCard);
    };

    const toggleSmartSensorCard = () => {
        setShowSmartSensorCard(!showSmartSensorCard);
    };

    const toggleSmartHubCard = () => {
        setShowSmartHubCard(!showSmartHubCard);
    };

    const toggleSmartCameraCard = () => {
        setShowSmartCameraCard(!showSmartCameraCard);
    };

    const toggleRoomCard = () => {
        roomFormik.resetForm();
        setShowRoomCard(!showRoomCard);
    };

    const addressFormik = useFormik({
        initialValues: {
            AddressType: "",
            Street1: "",
            Street2: "",
            City: "",
            State: "",
            ZipCode: "",
        },
        validationSchema: addressSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Form submitted")
            console.log(values)
            var addressString = values.Street1 + " " + values.Street2 + " " + values.City + " " + values.State + " " + values.ZipCode;

            const geoCodes = await getAddressLatLng(addressString, appContext.handleAPICallError);
            console.log(geoCodes.lat);
            console.log(geoCodes.lng);
            setAddressLatLng(geoCodes);

            const newAddress: Address = {
                addressType: values.AddressType,
                street1: values.Street1,
                street2: values.Street2,
                city: values.City,
                state: values.State,
                zip: values.ZipCode,
                latitude: geoCodes.lat,
                longitude: geoCodes.lng,
            }

            const newServiceLocation: ServiceLocation = {
                physicalAddress: newAddress,
                locationCategory: "",
                rooms: [],
                credentials: [],
                serviceLocationID: "",
                smartHubs: [],
                smartSensors: [],
                smartCameras: []
            }

            const duplicateLocations = await findDuplicateLocation(newServiceLocation, appContext.handleAPICallError);
            console.log(duplicateLocations);

            if (duplicateLocations && duplicateLocations.length > 0) {
                setDuplicateLocationOpen(true);
            } else {
                setDuplicateLocationOpen(false);
                goToDetailsForm();
            }

            
        },
    });

    const roomFormik = useFormik({
        initialValues: {
            RoomName: "",
            RoomType: "",
        },
        validationSchema: roomSchema,
        onSubmit: (values) => {
          // handle form submission
          console.log("Form submitted")
            console.log(values)
            handleAddRoom(values);
        },
    });

    const credentialFormik = useFormik({
        initialValues: {
            CredentialName: "",
            CredentialType: "",
            UserName: "",
            Password: "",
            AccountID: "",
            TwoFactorAuthContact: "",
        },
        validationSchema: credentialSchema,
        onSubmit: (values) => {
          // handle form submission
          console.log("Form submitted")
            console.log(values)
            handleAddCredential(values);
        },
    });

    const handleRemoveRoom = (room) => {
        const index = addressRooms.indexOf(room);
        const newItems = [...addressRooms.slice(0, index), ...addressRooms.slice(index + 1)];
        setAddressRooms(newItems);
    };

    const handleRemoveCredential = (credential) => {
        const index = addressHomeCredentials.indexOf(credential);
        const newItems = [...addressHomeCredentials.slice(0, index), ...addressHomeCredentials.slice(index + 1)];
        setAddressHomeCredentials(newItems);
    };

    const handleAddRoom = (values) => {
        console.log("Adding Room");
        console.log(values);
        var room: Room = {
            roomName: values.RoomName,
            roomType: values.RoomType,
            roomID: "",
            serviceLocationID: ""
        }
        setAddressRooms([...addressRooms, room]);
        console.log(addressRooms);
        roomFormik.resetForm();
        toggleRoomCard();
    };

    const handleAddCredential = (values) => {
        console.log("Adding Credential");
        console.log(values);
        var credential: HomeCredential = {
            credentialName: values.CredentialName,
            credentialType: values.CredentialType,
            userName: values.UserName,
            password: values.Password,
            accountID: values.AccountID,
            twoFactorAuth: values.TwoFactorAuthContact,
            homeCredentialID: "",
            serviceLocationID: ""
        }
        setAddressHomeCredentials([...addressHomeCredentials, credential]);
        console.log(addressHomeCredentials);
        credentialFormik.resetForm();
        toggleHomeCredentialCard();
    };

    const goToPreviewForm = () => {
        const physicalAddress: Address = {
            addressType: addressFormik.values.AddressType,
            street1: addressFormik.values.Street1,
            street2: addressFormik.values.Street2,
            city: addressFormik.values.City,
            state: addressFormik.values.State,
            zip: addressFormik.values.ZipCode,
            latitude: addressLatLng.lat,
            longitude: addressLatLng.lng,
        }

        const serviceLocation: ServiceLocation = {
            serviceLocationID: "",
            locationCategory: "",
            physicalAddress: physicalAddress,
            rooms: addressRooms,
            credentials: addressHomeCredentials,
            smartHubs: [],
            smartSensors: [],
            smartCameras: []
        }

        console.log(serviceLocation);
        setNewServiceLocation(serviceLocation);

        setActiveSteps([
            { id: '1', name: 'Address', href: '#', status: 'complete' },
            { id: '2', name: 'Details', href: '#', status: 'complete' },
            { id: '3', name: 'Preview', href: '#', status: 'current' },
            ]);
    }

    const handleSaveLocation = async () => {

        console.log(newServiceLocation);

        setSavingLocation(true);

        const result = await addServiceLocation(clientID, newServiceLocation, appContext.handleAPICallError);

        setSaveLocationOpen(true);
        setSavingLocation(false);
        
    }

    const cancelNewLocation = () => {
        navigate(-1);
    }

    return (<>
     <div className="tw-px-xl tw-py-xl">
     { clientLoading ? <>
                <div className="tw-flex tw-justify-center tw-m-md">
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                </div>
            </> :
        <>
            <Transition.Root show={saveLocationOpen} as={Fragment}>
                <TWDialog as="div" className="tw-relative tw-z-10" onClose={handleLocationSuccessClose}>
                    <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                    >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                    </Transition.Child>

                    <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                        <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="tw-ease-out tw-duration-300"
                            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leave="tw-ease-in tw-duration-200"
                            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            >
                            <TWDialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6">
                                <div>
                                <div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100">
                                    <CheckIcon className="tw-h-6 tw-w-6 tw-text-green-600" aria-hidden="true" />
                                </div>
                                <div className="tw-mt-3 tw-text-center sm:tw-mt-5">
                                    <TWDialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                                    Added Successfully
                                    </TWDialog.Title>
                                    <div className="tw-mt-2">
                                    <p className="tw-text-sm tw-text-gray-500">
                                        New Location Saved Successfully
                                    </p>
                                    </div>
                                </div>
                                </div>
                                <div className="tw-mt-5 sm:tw-mt-6">
                                <button
                                    type="button"
                                    className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-primary tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-indigo-600"
                                    onClick={handleLocationSuccessClose}
                                >
                                    Go back to Edit Client
                                </button>
                                </div>
                            </TWDialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </TWDialog>
            </Transition.Root>

            <Transition.Root show={duplicateLocationOpen} as={Fragment}>
                <TWDialog as="div" className="tw-relative tw-z-10" onClose={setDuplicateLocationOpen}>
                    <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                    >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                    </Transition.Child>

                    <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                    <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                        <Transition.Child
                        as={Fragment}
                        enter="tw-ease-out tw-duration-300"
                        enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                        leave="tw-ease-in tw-duration-200"
                        leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                        leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        >
                        <TWDialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-lg sm:tw-p-6">
                            
                            <div className="sm:tw-flex sm:tw-items-start">
                            <div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                                <ExclamationTriangleIcon className="tw-h-6 tw-w-6 tw-text-red-600" aria-hidden="true" />
                            </div>
                            <div className="tw-mt-3 tw-text-center sm:tw-ml-4 sm:tw-mt-0 sm:tw-text-left">
                                <TWDialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                                Duplicate Location
                                </TWDialog.Title>
                                <div className="tw-mt-2">
                                <p className="tw-text-sm tw-text-gray-500">
                                    The entered location address is duplicate of existing service location. Please make sure the address is unique.
                                </p>
                                </div>
                            </div>
                            </div>
                            <div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
                            <button
                                type="button"
                                className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-red-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-red-500 sm:tw-ml-3 sm:tw-w-auto"
                                onClick={() => setDuplicateLocationOpen(false)}
                            >
                                OK
                            </button>
                            </div>
                        </TWDialog.Panel>
                        </Transition.Child>
                    </div>
                    </div>
                </TWDialog>
            </Transition.Root>

            <div className="tw-pb-5">
                <nav className="tw-flex" aria-label="Breadcrumb">
                    <ol role="list" className="tw-flex tw-items-center tw-space-x-4">
                            <li key='Clients'>
                                <div className="tw-flex tw-items-center">
                                <a href="/client/client-management"
                                    className="tw-ml-4 tw-text-lg tw-font-medium tw-text-gray-500 hover:tw-text-gray-700"
                                >
                                    Clients
                                </a>
                                </div>
                            </li>
                            <li key='ClientID'>
                                <div className="tw-flex tw-items-center">
                                <ChevronRightIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-500" aria-hidden="true" />
                                <a href={`/client/${clientID}`}
                                    className="tw-ml-4 tw-text-lg tw-font-medium tw-text-gray-500 hover:tw-text-gray-700"
                                >
                                    {patient?.firstName}, {patient?.lastName.charAt(0)}
                                </a>
                                </div>
                            </li>
                            <li key='ClientID'>
                                <div className="tw-flex tw-items-center">
                                <ChevronRightIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-900" aria-hidden="true" />
                                <a
                                    className="tw-ml-4 tw-text-lg tw-font-semibold tw-text-gray-900 hover:tw-text-gray-700"
                                >
                                    New Service Location
                                </a>
                                </div>
                            </li>

                    </ol>
                </nav>
            </div>

            <nav aria-label="Progress">
                <ol role="list" className="tw-divide-y tw-divide-gray-300 tw-rounded-md tw-border tw-border-gray-300 md:tw-flex md:tw-divide-y-0">
                    {activeSteps.map((step, stepIdx) => (
                    <li key={step.name} className="tw-relative md:tw-flex md:tw-flex-1">
                        {step.status === 'complete' ? (
                        <div className="tw-group tw-flex tw-w-full tw-items-center">
                            <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                            <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary group-hover:tw-bg-indigo-800">
                                <CheckIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                            </span>
                            <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">{step.name}</span>
                            </span>
                        </div>
                        ) : step.status === 'current' ? (
                        <div className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium" aria-current="step">
                            <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-primary">
                            <span className="tw-text-primary">{step.id}</span>
                            </span>
                            <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-primary">{step.name}</span>
                        </div>
                        ) : (
                        <div className="tw-group tw-flex tw-items-center">
                            <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                            <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 group-hover:tw-border-gray-400">
                                <span className="tw-text-gray-500 group-hover:tw-text-gray-900">{step.id}</span>
                            </span>
                            <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-500 group-hover:tw-text-gray-900">{step.name}</span>
                            </span>
                        </div>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                        <>
                            {/* Arrow separator for lg screens and up */}
                            <div className="tw-absolute tw-top-0 tw-right-0 tw-hidden tw-h-full tw-w-5 md:tw-block" aria-hidden="true">
                            <svg
                                className="tw-h-full tw-w-full tw-text-gray-300"
                                viewBox="0 0 22 80"
                                fill="none"
                                preserveAspectRatio="none"
                            >
                                <path
                                d="M0 -2L20 40L0 82"
                                vectorEffect="non-scaling-stroke"
                                stroke="currentcolor"
                                strokeLinejoin="round"
                                />
                            </svg>
                            </div>
                        </>
                        ) : null}
                    </li>
                    ))}
                </ol>
            </nav>

            {/* <div className="tw-pb-5 tw-flex tw-px-4">
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-text-primary tw-flex-grow">Physical Address</span>
            </div> */}

            {activeSteps[0].status === 'current' && 
                <div className="tw-space-y-6 tw-mt-4">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow sm:tw-rounded-lg sm:tw-p-6">
                        
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    {/* <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Address Tag
                                        </label>
                                        <select
                                            id="address-tag"
                                            name="address-tag"
                                            className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                            defaultValue="Primary"
                                        >
                                            <option>Primary</option>
                                            <option>Secondary</option>
                                        </select>
                                    </div>
                                    <div className="tw-col-span-1">

                                    </div> */}

                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Address Type
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="address-type"
                                            id="address-type"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.AddressType && addressFormik.errors.AddressType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('AddressType')}
                                            />
                                            {addressFormik.touched.AddressType && addressFormik.errors.AddressType && <p className="tw-text-red-500">{addressFormik.errors.AddressType}</p>}
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Street 1
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="street-1"
                                            id="street-1"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.Street1 && addressFormik.errors.Street1 ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('Street1')}
                                            />
                                            {addressFormik.touched.Street1 && addressFormik.errors.Street1 && <p className="tw-text-red-500">{addressFormik.errors.Street1}</p>}
                                        </div>
                                    </div>

                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Street 2
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="street-2"
                                            id="street-2"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.Street2 && addressFormik.errors.Street2 ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('Street2')}
                                            />
                                            {addressFormik.touched.Street2 && addressFormik.errors.Street2 && <p className="tw-text-red-500">{addressFormik.errors.Street2}</p>}
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            City
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="city"
                                            id="city"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.City && addressFormik.errors.City ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('City')}
                                            />
                                            {addressFormik.touched.City && addressFormik.errors.City && <p className="tw-text-red-500">{addressFormik.errors.City}</p>}
                                        </div>
                                    </div>

                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            State
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="state"
                                            id="state"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.State && addressFormik.errors.State ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('State')}
                                            />
                                            {addressFormik.touched.State && addressFormik.errors.State && <p className="tw-text-red-500">{addressFormik.errors.State}</p>}
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Zip Code
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="zip-code"
                                            id="zip-code"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.ZipCode && addressFormik.errors.ZipCode ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('ZipCode')}
                                            />
                                            {addressFormik.touched.ZipCode && addressFormik.errors.ZipCode && <p className="tw-text-red-500">{addressFormik.errors.ZipCode}</p>}
                                        </div>
                                    </div>

                            </div>
                        
                        <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelNewLocation}>
                                            Cancel
                                    </ButtonSecondary>
                                    <ButtonPrimary className="tw-mt-md" onClick={addressFormik.handleSubmit}>
                                            Next
                                    </ButtonPrimary>

                        </div>
                    </div>
                </div>
            }   

            {activeSteps[1].status === 'current' && 
                <div className="tw-space-y-6 tw-mt-4">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow sm:tw-rounded-lg sm:tw-p-6">
                            <div className="tw-py-5 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-text-primary tw-flex-grow">Rooms</span>
                                <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-text-primary tw-underline tw-cursor-pointer">Add</span>
                            </div>

                            {showRoomCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${roomFormik.touched.RoomName && roomFormik.errors.RoomName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...roomFormik.getFieldProps('RoomName')}
                                                />
                                                {roomFormik.touched.RoomName && roomFormik.errors.RoomName && <p className="tw-text-red-500">{roomFormik.errors.RoomName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-type"
                                                id="room-type"
                                                className={`tw-block tw-w-full tw-rounded-md ${roomFormik.touched.RoomType && roomFormik.errors.RoomType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...roomFormik.getFieldProps('RoomType')}
                                                />
                                                {roomFormik.touched.RoomType && roomFormik.errors.RoomType && <p className="tw-text-red-500">{roomFormik.errors.RoomType}</p>}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleRoomCard}>
                                                        Cancel
                                                </ButtonSecondary>
                                                <ButtonPrimary className="tw-mt-md" onClick={roomFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>

                                        </div>
                                </div>
                            </>:<>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                                        <div className="tw-flex tw-items-center">
                                                <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleRoomCard}>
                                                <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                                <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Room</span>
                                                </div>  
                                        </div>
                                </div>
                            </>}

                            {addressRooms.length > 0 && addressRooms?.map((room, index) => (
                            <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Room Name
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {room.roomName}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Room Type
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        value = {room.roomType}
                                        disabled={true}
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                e.preventDefault();
                                                handleRemoveRoom(room);
                                                }}>
                                                    Remove
                                            </ButtonSecondary>
                                            {/* <ButtonPrimary className="tw-mt-md">
                                                Edit
                                            </ButtonPrimary> */}

                                </div>
                            </div>))}

                            <div className="tw-py-5 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-text-primary tw-flex-grow">Home Credentials</span>
                                <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-text-primary tw-underline tw-cursor-pointer">Add</span>
                            </div>

                            {showHomeCredentialCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Credential Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="credential-name"
                                                id="credential-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.CredentialName && credentialFormik.errors.CredentialName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('CredentialName')}
                                                />
                                                {credentialFormik.touched.CredentialName && credentialFormik.errors.CredentialName && <p className="tw-text-red-500">{credentialFormik.errors.CredentialName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Credential Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="credential-type"
                                                id="credential-type"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.CredentialType && credentialFormik.errors.CredentialType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('CredentialType')}
                                                />
                                                {credentialFormik.touched.CredentialType && credentialFormik.errors.CredentialType && <p className="tw-text-red-500">{credentialFormik.errors.CredentialType}</p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                UserName
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="username"
                                                id="username"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.UserName && credentialFormik.errors.UserName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('UserName')}
                                                />
                                                {credentialFormik.touched.UserName && credentialFormik.errors.UserName && <p className="tw-text-red-500">{credentialFormik.errors.UserName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Password
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="password"
                                                id="password"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.Password && credentialFormik.errors.Password ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('Password')}
                                                />
                                                {credentialFormik.touched.Password && credentialFormik.errors.Password && <p className="tw-text-red-500">{credentialFormik.errors.Password}</p>}
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Account ID
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="account-id"
                                                id="account-id"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.AccountID && credentialFormik.errors.AccountID ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('AccountID')}
                                                />
                                                {credentialFormik.touched.AccountID && credentialFormik.errors.AccountID && <p className="tw-text-red-500">{credentialFormik.errors.AccountID}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                2FA Contact
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="2fa-contact"
                                                id="2fa-contact"
                                                className={`tw-block tw-w-full tw-rounded-md ${credentialFormik.touched.TwoFactorAuthContact && credentialFormik.errors.TwoFactorAuthContact ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...credentialFormik.getFieldProps('TwoFactorAuthContact')}
                                                />
                                                {credentialFormik.touched.TwoFactorAuthContact && credentialFormik.errors.TwoFactorAuthContact && <p className="tw-text-red-500">{credentialFormik.errors.TwoFactorAuthContact}</p>}
                                            </div>
                                        </div>

                                        
                                    </div>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleHomeCredentialCard}>
                                                        Cancel
                                                </ButtonSecondary>
                                                <ButtonPrimary className="tw-mt-md" onClick={credentialFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>

                                        </div>
                                </div>
                            </>:<>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                                        <div className="tw-flex tw-items-center">
                                                <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleHomeCredentialCard}>
                                                <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                                <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Home Credential</span>
                                                </div>  
                                        </div>
                                </div>
                            </>}

                            {addressHomeCredentials.length > 0 && addressHomeCredentials?.map((credential, index) => (
                            <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Credential Name
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {credential.credentialName}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Credential Type
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {credential.credentialType}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            UserName
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {credential.userName}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Password
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {credential.password}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Account ID
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {credential.accountID}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            2FA Contact
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {credential.twoFactorAuth}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                e.preventDefault();
                                                handleRemoveCredential(credential);
                                                }}>
                                                    Remove
                                            </ButtonSecondary>
                                            {/* <ButtonPrimary className="tw-mt-md">
                                                Edit
                                            </ButtonPrimary> */}

                                </div>
                            </div>))}

                            
                        <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={goToAddressForm}>
                                            Back
                                    </ButtonSecondary>
                                    <ButtonPrimary className="tw-mt-md" onClick={goToPreviewForm}>
                                            Next
                                    </ButtonPrimary>

                        </div>
                    </div>
                </div>
            }   

            {activeSteps[2].status === 'current' && 
                <div className="tw-space-y-6 tw-mt-4">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6">

                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Physical Address</span>
                        </div>

                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Address Type</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{newServiceLocation?.physicalAddress.addressType}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Street 1</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{newServiceLocation?.physicalAddress.street1}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Street 2</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{newServiceLocation?.physicalAddress.street2}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>City</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{newServiceLocation?.physicalAddress.city}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>State</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{newServiceLocation?.physicalAddress.state}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Zip Code</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{newServiceLocation?.physicalAddress.zip}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Latitude</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{newServiceLocation?.physicalAddress.latitude}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Longitude</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{newServiceLocation?.physicalAddress.longitude}</span>
                        </div>

                        { newServiceLocation?.rooms.length > 0 && 
                            <div className="tw-mt-4">
                            <div className="tw-pb-5 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Rooms</span>
                            </div>
                            {newServiceLocation?.rooms.map((room, index) => {
                                if(index !== newServiceLocation?.rooms.length - 1) {
                                    return <>
                                        <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Room Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{room.roomName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Room Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{room.roomType}</span>
                                            </div>
                                        </div>
                                        <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    </>
                                } else {
                                    return <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Room Name</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{room.roomName}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Room Type</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{room.roomType}</span>
                                        </div>
                                    </div>
                                }
                                })}
                        </div>
                        }

                        { newServiceLocation?.credentials.length > 0 && 
                            <div className="tw-mt-4">
                            <div className="tw-pb-5 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Credentials</span>
                            </div>
                            {newServiceLocation?.credentials.map((credential, index) => {
                                if(index !== newServiceLocation?.credentials.length - 1) {
                                    return <>
                                        <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.credentialName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.credentialType}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>UserName</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.userName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Password</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.password}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Account ID</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.accountID}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>2FA Contact</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.twoFactorAuth}</span>
                                            </div>
                                        </div>
                                        <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    </>
                                } else {
                                    return <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.credentialName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.credentialType}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>UserName</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.userName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Password</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.password}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Account ID</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.accountID}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>2FA Contact</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.twoFactorAuth}</span>
                                            </div>
                                    </div>
                                }
                                })}
                        </div>
                        }

                    </div>
                            <div className="tw-flex tw-justify-end tw-mx-4">
                            {savingLocation ? <>
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </>:<>
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={goToDetailsForm}>
                                                Back
                                        </ButtonSecondary>
                                        <ButtonPrimary className="tw-mt-md" onClick={handleSaveLocation}>
                                                Save
                                        </ButtonPrimary>
                                        </>}

                            </div>
                </div>
            }   

            

           
            

            {/* <div className="tw-py-5 tw-flex tw-px-4">
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-text-primary tw-flex-grow">Smart Sensors</span>
                <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-text-primary tw-underline tw-cursor-pointer">Add</span>
            </div> */}

            {/* {showSmartSensorCard ? <>
                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Sensor Type
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="sensor-type"
                                id="sensor-type"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                
                                />
                            </div>
                        </div>
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Sensor Name
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="sensor-name"
                                id="sensor-name"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Room
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="room"
                                id="room"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                
                                />
                            </div>
                        </div>
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Manufacturer
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="manufacturer"
                                id="manufacturer"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        
                    </div>
                    <div className="tw-flex tw-justify-end tw-mx-4">
                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleSmartSensorCard}>
										Cancel
								</ButtonSecondary>
                                <ButtonPrimary className="tw-mt-md">
                                    Save
                                </ButtonPrimary>

                        </div>
                </div>
            </>:<>
                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                        <div className="tw-flex tw-items-center">
                                <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleSmartSensorCard}>
                                <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Smart Sensor</span>
                                </div>  
                        </div>
                </div>
            </>}

            <div className="tw-py-5 tw-flex tw-px-4">
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-text-primary tw-flex-grow">Smart Hubs</span>
                <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-text-primary tw-underline tw-cursor-pointer">Add</span>
            </div>

            {showSmartHubCard ? <>
                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Hub Name
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="hub-name"
                                id="hub-name"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                
                                />
                            </div>
                        </div>
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Cloud Provider
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="cloud-provider"
                                id="cloud-provider"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Host Name
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="host-name"
                                id="host-name"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                
                                />
                            </div>
                        </div>
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Serial Number
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="serial-number"
                                id="serial-number"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                UserName
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="user-name"
                                id="user-name"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                
                                />
                            </div>
                        </div>
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Password
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="password"
                                id="password"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Message Channel
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="message-channel"
                                id="message-channel"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        
                    </div>
                    <div className="tw-flex tw-justify-end tw-mx-4">
                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleSmartHubCard}>
										Cancel
								</ButtonSecondary>
                                <ButtonPrimary className="tw-mt-md">
                                    Save
                                </ButtonPrimary>

                        </div>
                </div>
            </>:<>
                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                        <div className="tw-flex tw-items-center">
                                <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleSmartHubCard}>
                                <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Smart Hub</span>
                                </div>  
                        </div>
                </div>
            </>}

            <div className="tw-py-5 tw-flex tw-px-4">
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-text-primary tw-flex-grow">Smart Cameras</span>
                <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-text-primary tw-underline tw-cursor-pointer">Add</span>
            </div>

            {showSmartCameraCard ? <>
                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Camera Name
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="camera-name"
                                id="camera-name"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                
                                />
                            </div>
                        </div>
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Camera Type
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="camera-type"
                                id="camera-type"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Room Name
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="room-name"
                                id="room-name"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                
                                />
                            </div>
                        </div>
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Firmware Version
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="firmware-version"
                                id="firmware-version"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                UserName
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="user-name"
                                id="user-name"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                
                                />
                            </div>
                        </div>
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Password
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="password"
                                id="password"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                MAC Address
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="mac-address"
                                id="mac-address"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                
                                />
                            </div>
                        </div>
                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Local IP
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="local-ip"
                                id="local-ip"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        <div className="tw-col-span-1">
                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                Stream Name
                            </label>
                            <div className="tw-mt-2">
                                <input
                                type="text"
                                name="stream-name"
                                id="stream-name"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                               
                                />
                            </div>
                        </div>

                        
                    </div>
                    <div className="tw-flex tw-justify-end tw-mx-4">
                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleSmartCameraCard}>
										Cancel
								</ButtonSecondary>
                                <ButtonPrimary className="tw-mt-md">
                                    Save
                                </ButtonPrimary>

                        </div>
                </div>
            </>:<>
                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                        <div className="tw-flex tw-items-center">
                                <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleSmartCameraCard}>
                                <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Smart Camera</span>
                                </div>  
                        </div>
                </div>
            </>} */}

            {/* <div className="tw-flex tw-justify-end tw-mx-4">
                                <ButtonSecondary className="tw-mt-md tw-mx-4">
										Cancel
								</ButtonSecondary>
                                <ButtonPrimary className="tw-mt-md">
                                    Save
                                </ButtonPrimary>

            </div> */}
            </>}
     </div>
    </>);
}

export default NewServiceLocationPage;