import { useEffect, useState } from "react";
import { getAPIToken, parseJwt } from "../apicalls/billingService";
import MainNav from "../components/core/MainNav";
import SideNav from "../components/panels/SideNav";
import ServicePanel from "../components/panels/Service";
import { useAppContext } from "../Context";

const OldCore = props => {
	const [showRightPanel, setShowRightPanel] = useState<boolean>(false);
	const [showBilling, setShowBilling] = useState<boolean>(false);
	const [servicePanelData, setServicePanelData] = useState<any>({'type': 1});
	const toggleShowRightPanel = () => setShowRightPanel(showRightPanel == false || true);

	const logout = props?.logout || props?.handleLogout

	const parentProps = {
		showRightPanel, setShowRightPanel, showBilling,
		servicePanelData, setServicePanelData,
		toggleShowRightPanel, logout
	}

	useEffect(() => {

		const fetchValidTaken = async () => {
			const token = await getAPIToken();
			const tokenJSON = parseJwt(token);
			console.log(token);
			console.log(tokenJSON);
			console.log(tokenJSON.unique_name);
			console.log(props);
			console.log(parentProps);
			if (tokenJSON.unique_name === "srinivas@ohioathome.com" || tokenJSON.unique_name === "ali.rahimi@ohioathome.com"){
				setShowBilling(true);
			}
		}

		fetchValidTaken()
    		.catch(console.error);
	}, [])

	return <>
		<div className="o-global-container t-dashboard tw-h-screen tw-overflow-hidden || tw-bg-light-accent">
			<div className="c-page tw-h-screen tw-overflow-hidden tw-flex">
				<div className="c-page__menu tw-h-screen tw-overflow-hidden tw-flex tw-flex-col || tw-flex-shrink-0 || tw-w-320 tw-bg-white">
					<SideNav { ...parentProps }/>
				</div>
				<div className="c-page__main tw-h-screen tw-overflow-x-hidden tw-overflow-y-auto || tw-flex || tw-flex-grow tw-flex-shrink-0">
					<div className="c-page__main-inner tw-h-screen tw-flex-grow tw-flex-shrink-0 || tw-w-full tw-max-w-content tw-mx-auto">
						{ props.children }
					</div>
					<div className="tw-flex-grow tw-min-w-0 tw-max-w-320"></div>
					{ props?.showRightPanel && <>
						<div className="tw-h-screen tw-w-full || tw-absolute tw-top-0 tw-left-0 tw-z-50 tw-flex tw-justify-end ||
						after:tw-content-[''] after:tw-block after:tw-w-screen after:tw-h-screen
						after:tw-absolute after:tw-top-0 after:tw-left-0 after:tw-bg-black/20 after:tw-z-10
						">
							<ServicePanel { ...props } />
						</div>
					</> }
				</div>
			</div>
		</div>
	</>
}

const Core = props => {
	const navContext = useAppContext();

	const logout = props?.logout || props?.handleLogout

	const parentProps = {
		logout, navContext
	}

	return <>
		<div className="o-global-container t-dashboard tw-h-screen tw-overflow-hidden || tw-bg-light-accent">
			<div className="c-page tw-h-screen tw-overflow-hidden tw-flex">
				{ !props.hideNav && <>
					<div className="c-page__menu tw-h-screen tw-overflow-hidden tw-flex tw-flex-col || tw-flex-shrink-0 || tw-w-320px tw-bg-white">
						<MainNav { ...parentProps } />
					</div>
				</> }
				<div className="c-page__main tw-h-screen tw-overflow-x-hidden tw-overflow-y-auto || tw-flex || tw-flex-grow tw-flex-shrink-0">
					<div className="c-page__main-inner tw-h-screen tw-flex-grow tw-flex-shrink-0 || tw-w-full tw-max-w-content tw-mx-auto">
						{ props.children }
					</div>
					<div className="tw-flex-grow tw-min-w-0 tw-max-w-320px"></div>
					{ navContext.isSideNavOpen && <>
						<div className="tw-h-screen tw-w-full || tw-absolute tw-top-0 tw-left-0 tw-z-50 tw-flex tw-justify-end ||
						after:tw-content-[''] after:tw-block after:tw-w-screen after:tw-h-screen
						after:tw-absolute after:tw-top-0 after:tw-left-0 after:tw-bg-black/20 after:tw-z-10
						">
							{ props.SideNav ? <>
								{ props.noWrapper ? <>
									{ props.SideNav }
								</> : <>
									<SideNav sideNavTheme={ props.sideNavTheme || navContext.sideNavTheme || null } { ...parentProps }>
										{ props.SideNav }
									</SideNav>
								</> }
							</> : <SideNav { ...parentProps } /> }
						</div>
					</> }
				</div>
			</div>
		</div>
	</>
}

export default Core;
