import { useLayoutEffect, useState } from "react";
import { CircleLabel } from "../calendars/CalendarUtils";
import { ButtonPrimary } from "../buttons/Buttons";
import { ButtonSecondary } from "@medforall/medforall-component-library";
import { useAppContext } from "../../Context";
import SessionClaimPanel from "./SessionClaimPanel";
import moment from "moment";
import { ClaimRemoteSupportSessionBottomPanel } from "./PanelCTAs";
import uuid from "react-uuid";
import RemoteSupportSession from "../../types/remoteSupportSession";
import { claimRemoteSupportSession, getStationsForColumbus } from "../../apicalls/sessionService";
import RemoteSupportStation from "../../types/remoteSupportStation";
import SessionActionSuccessPanel from "./SessionActionSuccessPanel";

const SessionClaimInfoPanel = props => {
    const [openSession, setOpenSession] = useState(null);

    const appContext = useAppContext();

    useLayoutEffect(() => {
        setOpenSession(props.openSession);


    },[props.openSession])

    const [supportStations, setSupportStations] = useState<RemoteSupportStation[]>([]);

    useLayoutEffect(() => {
		const fetchLocationStations = async () => {
			const stations = await getStationsForColumbus(appContext.handleAPICallError);
			console.log(stations.sort((a, b) => {
				const aName = a.stationName.split(' ')[1];
				const bName = b.stationName.split(' ')[1];
				return parseInt(aName) - parseInt(bName);
			  }));
			setSupportStations(stations.sort((a, b) => {
				const aName = a.stationName.split(' ')[1];
				const bName = b.stationName.split(' ')[1];
				return parseInt(aName) - parseInt(bName);
			  }));
		}

		fetchLocationStations();
	}, []);

    const handleCancel = () => {
        appContext.closeSideNav();
    }

    const saveClaimedSession = async (sessionTimes) => {
        console.log("Claiming Session");

        var startTime = moment(openSession.displayStartDate + " " + sessionTimes.start_time)
        var endTime = moment(openSession.displayStartDate + " " + sessionTimes.end_time)

        var endDate = openSession.displayStartDate;

        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'days');
            endDate = openSession.displayEndDate;
        }

        

		var selectedStation = supportStations[0];

		let newSession : RemoteSupportSession = {
			remoteSupportSessionID: uuid(),
			supervisorID: "",
			providerID: "",
			startDate: moment(openSession.displayStartDate).format(),
			displayStartDate: openSession.displayStartDate,
			endDate: moment(endDate).format(),
			displayEndDate: endDate,
			providerName: "",
			startTime: startTime.format(),
			displayStartTime: moment(startTime).format("h:mm A"),
			endTime: endTime.format(),
			displayEndTime: moment(endTime).format("h:mm A"),
			status: "Accepted",
			multiDay: moment(sessionTimes.end_date).format("MM-DD-YYYY") !== moment(sessionTimes.start_date).format("MM-DD-YYYY"),
			startIndex: 0,
			endIndex: 0,
			multiDayStartIndex: 0,
			multiDayEndIndex: 0,
			recurrence: "Never",
			assignedShifts: [],
			colStart: 0,
			colEnd: 0,
			dedicatedSession: false,
			repeatsSunday: false,
			repeatsMonday: false,
			repeatsTuesday: false,
			repeatsWednesday: false,
			repeatsThursday: false,
			repeatsFriday: false,
			repeatsSaturday: false,
			recurrenceEndDate: moment(openSession.displayStartDate).format(),
			multiDayColStart: 0,
			multiDayColEnd: 0,
			officeID: "",
			officeName: "",
			stationID: selectedStation.remoteSupportStationID,
			stationName: selectedStation.stationName,
			training: false,
		}

        console.log(newSession);

        var title = "Session Claimed";
        var message = "Thank you!, you have successfully claimed the session.";

        const result = await claimRemoteSupportSession(newSession, appContext.handleAPICallError);

        appContext.setSideNavTitle("Success");
        appContext.setSideNav( <SessionActionSuccessPanel selectedSession={newSession} title={title} message={message} updateSession={props?.updateSession} removeSession={props?.removeSession} hideProvider={true}/> );
        appContext.setSideNavActions(null);
        appContext.setSideNavBottom(null);

        //appContext.closeSideNav();
    }

    const handleClaimSession = () => {
        console.log("Claim Session");

        const initialValues = {
			"start_time": moment.utc(props?.openSession.startTime).format("h:mm A"),
			"end_time": moment.utc(props?.openSession.endTime).format("h:mm A"),
        };

        const initialErrors = {
            start_time: false,
            end_time: false,
        };
    
        const initialErrorMessages = {
            start_time: '',
            end_time: '',
        };
        appContext.setValues(initialValues);
        appContext.setErrors(initialErrors);
        appContext.setErrorMessages(initialErrorMessages);

        appContext.setSideNav(<SessionClaimPanel openSession={openSession} />);
		appContext.setSideNavTheme(3);
		appContext.setSideNavTitle("Claim Remote Support Session");
        appContext.setPanelSize('tw-max-w-400px');
		appContext.setSideNavActions(null);
        appContext.setSideNavBottom(<ClaimRemoteSupportSessionBottomPanel openSession={openSession} onCancel={handleCancel} onSave={saveClaimedSession}/>);
		appContext.openSideNav();
    }
    

    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
            <div className="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
                    <div className="tw-flex-shrink-0 tw-w-full">
                                <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px" onClick={ e => {
                                    // let temp = toggleSessionInformation;
                                    // setToggleSessionInformation( temp = !temp )
                                }}>
                                    <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                        <span className="tw-flex-grow">
                                        <CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
                                            <span className="tw-text-dark tw-ml-5px">Available Session Times</span>
                                        </CircleLabel>
                                        </span>
                                        {/* <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                            <img className="tw-w-full" src={`/images/icon-arrow-${ toggleSessionInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
                                        </span> */}
                                    </span>
                                </h3>

                                <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                                    <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-150px tw-flex tw-items-center">
                                            <span className="tw-opacity-70">
                                                Date
                                            </span>
                                        </span>
                                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                            {openSession?.displayStartDate}
                                        </span>
                                    </li>

                                    <li className={`tw-flex tw-space-x-5px tw-items-center tw-pb-15px`}>
                                        <span className="tw-w-150px tw-flex tw-items-center">
                                            <span className="tw-opacity-70">
                                                Time Period
                                            </span>
                                        </span>
                                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                            {openSession?.displayStartTime} - {openSession?.displayEndTime}
                                        </span>
                                    </li>
                                </ul>

                                <div className="tw-flex tw-justify-end tw-pr-20px tw-pb-10px tw-pt-15px">
                                    <ButtonSecondary className="tw-min-w-80px tw-mr-4" onClick={ e => {
                                        e.preventDefault();
                                        appContext.closeSideNav();
                                        }}>
                                        Close
                                    </ButtonSecondary>

                                    <ButtonPrimary className="tw-min-w-80px" onClick={ e => {
                                        e.preventDefault();
                                        handleClaimSession();
                                        }}>
                                        Claim
                                    </ButtonPrimary>
                                </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default SessionClaimInfoPanel;