import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getActiveClients, getClientByID, populateLegacyScheduleForPatient } from "../apicalls/schedulingService"
import Button, { ButtonAnchor, ButtonPrimary } from "../components/buttons/Buttons"
import Calendar from "../components/containers/Calendars"
import { ClientDetailsCard, ClientInfoCard, ContactCard } from "../components/containers/Cards"
import { PageHeader, PageSubHeader, Paragraph3 } from "../components/typography/Headers"
import { Ellipsis } from "../components/utilities/Helpers"
import Core from "../templates/Core"
import Patient from "../types/patient"
import { rem } from "../utilities/helpers"
import { articleHeader } from "../utilities/typography"
import Alert from '@mui/material/Alert';
import ServicePanel from "../components/panels/Service"
import { useAppContext } from "../Context"

const LegacySchedulePage = props => {
    const { patientID } = useParams();

    const navContext = useAppContext();

    const [clientDetails, setClientDetails] = useState(null);
    const [clientsLoading, setClientsLoading] = useState(true);

    let navigate = useNavigate();

    const [showRightPanel, setShowRightPanel] = useState<boolean>(false);
	const [servicePanelData, setServicePanelData] = useState<any>({'type': 2});
	//const toggleShowRightPanel = () => setShowRightPanel(showRightPanel == false || true);

    const [showPopulateSuccess, setShowPopulateSuccess] = useState(false);
    const [populating, setPopulating] = useState(false);

    // const [selectedShift, setSelectedShift] = useState<any>();

    

    const showShift = true;

    const logout = props?.handleLogout

    const toggleShowRightPanel = (shiftByID) => {
        console.log("Nav Context Side Nav");

        const selectedShift = shiftByID;

        const panelProps = {
            servicePanelData, setServicePanelData,
            selectedShift,
            showShift,
        }

        console.log(panelProps);
        navContext.setSideNav( <ServicePanel { ...panelProps } />);
		navContext.setSideNavTheme(2);
		navContext.setSideNavTitle("Shift Panel");
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavBottom(null);
        navContext.setSideNavActions(null);
		navContext.openSideNav();

        console.log(navContext);
    }

    const parentProps = {
		showRightPanel, setShowRightPanel,
		servicePanelData, setServicePanelData,
		toggleShowRightPanel,logout, showShift,
	}

    useEffect(() => {
	
		const fetchClientDetails = async () => {
			const clientDetails = await getClientByID(patientID, navContext.handleAPICallError);

			console.log(clientDetails);

			setClientDetails(clientDetails);
            setClientsLoading(false);
		}

		fetchClientDetails()
			.catch(console.error);
	}, []);

    const goToClientCoverageForm = () => {
		navigate("/schedule/client-schedule/"+ clientDetails?.patientID +"/new");
	};

    const populateSchedule = async () => {
        const result = await populateLegacyScheduleForPatient(clientDetails?.patientID, navContext.handleAPICallError);

        setPopulating(false);

        setShowPopulateSuccess(true);
    }

    const closeAlert = async () => {
        setShowPopulateSuccess(false);

        navigate("/SessionSchedule");
    }

    const backToClientList = () => {
        navigate("/schedule/client-schedule");
    } 

    return (<>
        {/* <Core { ...parentProps }> */}
        <div className="tw-px-xl tw-py-xl">
                {showPopulateSuccess ? <>
                    <div className="tw-pb-sm tw-px-xl">
                        <Alert  onClose={closeAlert}>Client Shifts Assigned Successfully</Alert>
                    </div>
                </>:<></> }
                
                
				<div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
					{/* <div className="tw-flex-grow tw-flex-shrink-0">
						<PageHeader>
							<Ellipsis>
								Client Calendar
							</Ellipsis>
						</PageHeader>
					</div> */}
                    <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Legacy Client Calendar</h2>
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-end tw-flex-grow tw-flex-shrink-0">
						<ul className="tw-list-none tw-flex tw-space-x-md">
							<li>
								<ButtonPrimary iconNotRounded onClick={goToClientCoverageForm} svg={`<svg width="${rem('24')}" height="${rem('24')}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM12 7C13.66 7 15 8.34 15 10C15 11.66 13.66 13 12 13C10.34 13 9 11.66 9 10C9 8.34 10.34 7 12 7ZM18 19H6V17.6C6 15.6 10 14.5 12 14.5C14 14.5 18 15.6 18 17.6V19Z" fill="currentColor"/></svg>`}>
									Add Shift
								</ButtonPrimary>
							</li>
                            {/* <li>
                                    {populating ? 
									<>
									<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
									</>: <>
									<ButtonPrimary small onClick={ e => {
                                            setPopulating(true);
											populateSchedule();
											e.preventDefault();
										}} iconNotRounded svg={`<svg width="${rem('24')}" height="${rem('24')}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM12 7C13.66 7 15 8.34 15 10C15 11.66 13.66 13 12 13C10.34 13 9 11.66 9 10C9 8.34 10.34 7 12 7ZM18 19H6V17.6C6 15.6 10 14.5 12 14.5C14 14.5 18 15.6 18 17.6V19Z" fill="currentColor"/></svg>`}>
                                       Auto-Assign
                                    </ButtonPrimary>
									</>}
                                    
                            </li> */}
						</ul>
					</div>
                    
                  
				</div>
                <div className="tw-px-xl">
                        <ButtonAnchor iconNotRounded onClick={backToClientList} svg={`<svg width="${rem('12')}" height="${rem('18')}" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.115 15.885L4.245 9L11.115 2.115L9 0L0 9L9 18L11.115 15.885Z" fill="currentColor"/></svg>`}>
                            Back to Client List
                        </ButtonAnchor>
                </div>
                
		</div>
        <div className="tw-px-xl tw-pb-xl tw-text-dark">
                <div className="tw-px-xl">
                    <div>
                        
                        {clientsLoading ? <></>:<>
                        <ClientInfoCard firstName={clientDetails?.firstName} lastName={clientDetails?.lastName} email={clientDetails?.email} />

                        <Calendar patient={clientDetails} { ...parentProps }/>
                        </>}
                        
                    </div>
                </div>
            
        </div>
        {/* </Core> */}
    </>)
}

export default LegacySchedulePage