import React from "react";
import { useEffect, useState } from "react";
import { Job } from "../../types/backgroundJob";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { useAppContext } from "../../Context";
import { getBackgroundJobs } from "../../apicalls/schedulingService";
import useWebSocket, { ReadyState } from 'react-use-websocket';

const JobList: React.FC = () => {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [expandedJobId, setExpandedJobId] = useState<string | null>(null);
    const [loadingJobs, setLoadingJobs] = useState<boolean>(false);

    const appContext = useAppContext();

    //const { lastMessage, readyState } = useWebSocket('wss://' + process.env.REACT_APP_SOCKETURL +  'ws');

    // const {
    //     sendMessage,
    //     lastMessage,
    //     readyState,
    //     getWebSocket
    //   } = useWebSocket('ws://10.9.150.21:8080/ws', {
    //     onOpen: () => console.log('WebSocket connection established.'),
    //     onClose: () => console.log('WebSocket connection closed.'),
    //     onError: (event) => console.error('WebSocket error:', event),
    //     shouldReconnect: (closeEvent) => true, // Attempt to reconnect on all close events
    //     reconnectInterval: 3000, // Try to reconnect after 3 seconds
    //     reconnectAttempts: 10
    //   });

    // useEffect(() => {
    //     if (lastMessage !== null) {
    //       const updatedJob: Job = JSON.parse(lastMessage.data);
    //       setJobs(prevJobs => 
    //         prevJobs.map(job => 
    //           job.job_id === updatedJob.job_id ? updatedJob : job
    //         )
    //       );
    //     }
    //   }, [lastMessage]);
  
    useEffect(() => {
      const fetchJobs = async () => {
        try {
        //   const response = await axios.get<Job[]>('/api/jobs/last-day');
        //   setJobs(response.data);
            setLoadingJobs(true);

            const result = await getBackgroundJobs(appContext.handleAPICallError);

            setJobs(result);

            setLoadingJobs(false);
        } catch (error) {
          console.error('Failed to fetch jobs:', error);
          // Here you might want to set an error state and display it to the user
        }
      };
  
      fetchJobs();
    }, []);

    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: 'Connecting',
    //     [ReadyState.OPEN]: 'Open',
    //     [ReadyState.CLOSING]: 'Closing',
    //     [ReadyState.CLOSED]: 'Closed',
    //     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    //   }[readyState];
  
    const toggleJobExpansion = (jobId: string) => {
      setExpandedJobId(expandedJobId === jobId ? null : jobId);
    };
  
    return (<>
        {loadingJobs ? <>
            <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>
        </> : <>
        <div className="tw-container tw-mx-auto tw-p-4">
        <h1 className="tw-text-2xl tw-font-bold tw-mb-4">Jobs from the Last 24 Hours</h1>
        {/* <p className="tw-mb-4">Live Job Status: {connectionStatus}</p> */}
        <table className="tw-min-w-full tw-bg-white tw-border tw-border-gray-300">
          <thead>
            <tr className="tw-bg-gray-100">
              <th className="tw-px-4 tw-py-2 tw-text-left">Job ID</th>
              <th className="tw-px-4 tw-py-2 tw-text-left">Name</th>
              <th className="tw-px-4 tw-py-2 tw-text-left">Status</th>
              <th className="tw-px-4 tw-py-2 tw-text-left">Created At</th>
              <th className="tw-px-4 tw-py-2 tw-text-left">Created By</th>
              <th className="tw-px-4 tw-py-2 tw-text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobs && jobs?.map((job) => (
              <React.Fragment key={job.job_id}>
                <tr className="tw-border-b tw-border-gray-300">
                  <td className="tw-px-4 tw-py-2">{job.job_id}</td>
                  <td className="tw-px-4 tw-py-2">{job.name}</td>
                  <td className="tw-px-4 tw-py-2">
                    <span className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-semibold
                      ${job.status === 'completed' ? 'tw-bg-green-200 tw-text-green-800' :
                        job.status === 'failed' ? 'tw-bg-red-200 tw-text-red-800' :
                        job.status === 'running' ? 'tw-bg-blue-200 tw-text-blue-800' :
                        'tw-bg-yellow-200 tw-text-yellow-800'}`}>
                      {job.status}
                    </span>
                  </td>
                  <td className="tw-px-4 tw-py-2">{new Date(job.created_at).toLocaleString()}</td>
                  <td className="tw-px-4 tw-py-2">{job.created_by}</td>
                  <td className="tw-px-4 tw-py-2">
                    <button 
                      onClick={() => toggleJobExpansion(job.job_id)}
                      className="tw-bg-blue-500 tw-text-white tw-px-2 tw-py-1 tw-rounded hover:tw-bg-blue-600"
                    >
                      {expandedJobId === job.job_id ? 'Hide Details' : 'Show Details'}
                    </button>
                  </td>
                </tr>
                {expandedJobId === job.job_id && (
                  <tr>
                    <td colSpan={6} className="tw-px-4 tw-py-2 tw-bg-gray-50">
                      <div className="tw-text-sm">
                        <p><strong>Description:</strong> {job.description}</p>
                        {job.started_at && <p><strong>Started At:</strong> {new Date(job.started_at).toLocaleString()}</p>}
                        {job.completed_at && job.status === 'completed' && <p><strong>Completed At:</strong> {new Date(job.completed_at).toLocaleString()}</p>}
                        {job.error && <p><strong>Error:</strong> {job.error}</p>}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}

            {!jobs && (
              <tr>
                <td colSpan={6} className="tw-px-4 tw-py-2 tw-text-center">
                  No jobs found
                </td>
                </tr>
            )}
          </tbody>
        </table>
      </div>
        </>

        }
      
      </>);
  };

const BackgroundJobsPage = props => {

    return (<>
        <div className="tw-px-xl tw-py-sm">
                    <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900 tw-mt-sm">Background Jobs</h2>
                    </div>

                    <JobList />
        </div>
    </>);
}

export default BackgroundJobsPage;