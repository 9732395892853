import React, { useState, useEffect } from 'react';
import { Calendar, Filter, X } from 'lucide-react';
import Button from '../../components/buttons/Buttons';
import { ButtonPrimary, ButtonSecondary } from '@medforall/medforall-component-library';
import CalendarSelector from '../../components/calendars/CalendarSelector';
import SessionDetailsSideNav from './SessionDetailsSideNav';
import moment from 'moment';
import RemoteSupportSession from '../../types/remoteSupportSession';
import { set } from 'lodash';
import { useAppContext } from '../../Context';
import { getSessionDocumentationForSpan } from '../../apicalls/sessionService';
import { LoadingSpinner } from '../../components/portalComponents/loadingSpinner';

// type RemoteSupportSession = {
//   remoteSupportSessionID: string;
//   startDate: Date;
//   endDate: Date;
//   status: string;
//   providerID: string;
//   providerName: string;
//   documentationStatus: 'Missing' | 'Past Due' | 'Pending Approval' | 'Approved' | 'Rejected';
// };

type StatFilter = 'Total' | 'Approved' | 'Pending Approval' | 'Missing' | 'Past Due' | 'Rejected' | null;

const COLORS = {
  Total: '#6B7280', // Gray
  Approved: '#10B981', // Green
  'Pending Approval': '#3B82F6', // Blue
  Missing: '#F59E0B', // Yellow
  'Past Due': '#EF4444', // Red
  Rejected: '#8B5CF6', // Purple
};

const RemoteSupportSessionsComponent: React.FC = () => {
  const [sessions, setSessions] = useState<RemoteSupportSession[]>([]);
  const [filteredSessions, setFilteredSessions] = useState<RemoteSupportSession[]>([]);
  const [startDate, setStartDate] = useState<string>(moment().startOf('week').add(-7, 'days').format('M-D-YYYY'));
  const [endDate, setEndDate] = useState<string>(moment().endOf('week').add(-7, 'days').format('M-D-YYYY'));
  const [statFilter, setStatFilter] = useState<StatFilter>(null);

  const [loadingSessions, setLoadingSessions] = useState<boolean>(true);

  const [selectedSession, setSelectedSession] = useState<RemoteSupportSession | null>(null);

  const appContext = useAppContext();

  useEffect(() => {
    // Mock data with 5 sample sessions
    // const mockSessions: RemoteSupportSession[] = [
    //   {
    //     remoteSupportSessionID: '1',
    //     startDate: new Date('2024-08-01T09:00:00'),
    //     endDate: new Date('2024-08-01T10:00:00'),
    //     status: 'Completed',
    //     providerID: 'P1',
    //     providerName: 'John Doe',
    //     documentationStatus: 'Approved',
    //   },
    //   {
    //     remoteSupportSessionID: '2',
    //     startDate: new Date('2024-08-02T14:00:00'),
    //     endDate: new Date('2024-08-02T15:30:00'),
    //     status: 'Completed',
    //     providerID: 'P2',
    //     providerName: 'Jane Smith',
    //     documentationStatus: 'Pending Approval',
    //   },
    //   {
    //     remoteSupportSessionID: '3',
    //     startDate: new Date('2024-08-03T11:00:00'),
    //     endDate: new Date('2024-08-03T12:00:00'),
    //     status: 'Completed',
    //     providerID: 'P3',
    //     providerName: 'Bob Johnson',
    //     documentationStatus: 'Missing',
    //   },
    //   {
    //     remoteSupportSessionID: '4',
    //     startDate: new Date('2024-08-04T16:00:00'),
    //     endDate: new Date('2024-08-04T17:00:00'),
    //     status: 'Completed',
    //     providerID: 'P1',
    //     providerName: 'John Doe',
    //     documentationStatus: 'Rejected',
    //   },
    //   {
    //     remoteSupportSessionID: '5',
    //     startDate: new Date('2024-08-05T10:00:00'),
    //     endDate: new Date('2024-08-05T11:30:00'),
    //     status: 'Completed',
    //     providerID: 'P2',
    //     providerName: 'Jane Smith',
    //     documentationStatus: 'Past Due',
    //   },
    //   {
    //     remoteSupportSessionID: '6',
    //     startDate: new Date('2024-08-01T09:00:00'),
    //     endDate: new Date('2024-08-01T10:00:00'),
    //     status: 'Completed',
    //     providerID: 'P1',
    //     providerName: 'John Doe',
    //     documentationStatus: 'Approved',
    //   },
    //   {
    //     remoteSupportSessionID: '7',
    //     startDate: new Date('2024-08-02T14:00:00'),
    //     endDate: new Date('2024-08-02T15:30:00'),
    //     status: 'Completed',
    //     providerID: 'P2',
    //     providerName: 'Jane Smith',
    //     documentationStatus: 'Pending Approval',
    //   },
    //   {
    //     remoteSupportSessionID: '8',
    //     startDate: new Date('2024-08-03T11:00:00'),
    //     endDate: new Date('2024-08-03T12:00:00'),
    //     status: 'Completed',
    //     providerID: 'P3',
    //     providerName: 'Bob Johnson',
    //     documentationStatus: 'Missing',
    //   },
    //   {
    //     remoteSupportSessionID: '9',
    //     startDate: new Date('2024-08-04T16:00:00'),
    //     endDate: new Date('2024-08-04T17:00:00'),
    //     status: 'Completed',
    //     providerID: 'P1',
    //     providerName: 'John Doe',
    //     documentationStatus: 'Rejected',
    //   },
    //   {
    //     remoteSupportSessionID: '10',
    //     startDate: new Date('2024-08-05T10:00:00'),
    //     endDate: new Date('2024-08-05T11:30:00'),
    //     status: 'Completed',
    //     providerID: 'P2',
    //     providerName: 'Jane Smith',
    //     documentationStatus: 'Past Due',
    //   },
    //   {
    //     remoteSupportSessionID: '11',
    //     startDate: new Date('2024-08-01T09:00:00'),
    //     endDate: new Date('2024-08-01T10:00:00'),
    //     status: 'Completed',
    //     providerID: 'P1',
    //     providerName: 'John Doe',
    //     documentationStatus: 'Approved',
    //   },
    //   {
    //     remoteSupportSessionID: '12',
    //     startDate: new Date('2024-08-02T14:00:00'),
    //     endDate: new Date('2024-08-02T15:30:00'),
    //     status: 'Completed',
    //     providerID: 'P2',
    //     providerName: 'Jane Smith',
    //     documentationStatus: 'Pending Approval',
    //   },
    //   {
    //     remoteSupportSessionID: '13',
    //     startDate: new Date('2024-08-03T11:00:00'),
    //     endDate: new Date('2024-08-03T12:00:00'),
    //     status: 'Completed',
    //     providerID: 'P3',
    //     providerName: 'Bob Johnson',
    //     documentationStatus: 'Missing',
    //   },
    //   {
    //     remoteSupportSessionID: '14',
    //     startDate: new Date('2024-08-04T16:00:00'),
    //     endDate: new Date('2024-08-04T17:00:00'),
    //     status: 'Completed',
    //     providerID: 'P1',
    //     providerName: 'John Doe',
    //     documentationStatus: 'Rejected',
    //   },
    //   {
    //     remoteSupportSessionID: '15',
    //     startDate: new Date('2024-08-05T10:00:00'),
    //     endDate: new Date('2024-08-05T11:30:00'),
    //     status: 'Completed',
    //     providerID: 'P2',
    //     providerName: 'Jane Smith',
    //     documentationStatus: 'Past Due',
    //   },
    // ];
    // setSessions(mockSessions);
    fetchSessions();
  }, []);

  useEffect(() => {
    filterSessions();
  }, [sessions, startDate, endDate, statFilter]);

  const fetchSessions = async () => {
    if(!startDate || !endDate) return;

    setLoadingSessions(true);

    const fetchedSessions = await getSessionDocumentationForSpan(startDate, endDate, appContext.handleAPICallError);

    const sessionsPending = fetchedSessions.filter(session => session.status !== 'Assigned' && session.status !== 'Accepted');

    const acceptedSessions = fetchedSessions.filter(session => session.status === 'Accepted');

    acceptedSessions.forEach(session => {
      if(session.documentationStatus === "Approved" || session.documentationStatus === "Rejected") {
        sessionsPending.push(session);
      }
    });

    console.log('Fetched sessions:', fetchedSessions);
    console.log('Sessions pending:', sessionsPending);

    setSessions(sessionsPending);

    setLoadingSessions(false);
  };

  const filterSessions = () => {
    let filtered = sessions;
    if (startDate && endDate) {
      

      filtered = filtered.filter(
        (session) =>
          (new Date(session.displayStartDate) >= new Date(startDate) || new Date(session.startDate) >= new Date(startDate)) &&
          (new Date(session.displayEndDate) <= new Date(endDate) || new Date(session.endDate) <= new Date(endDate))
      );
    }
    if (statFilter && statFilter !== 'Total') {
      //console.log('Filtering by stat:', statFilter);
      if (statFilter === 'Missing') {
        filtered = filtered.filter((session) => session.documentationStatus === 'Not Submitted');
      } else {
        filtered = filtered.filter((session) => session.documentationStatus === statFilter);
      }
    }

    console.log('Filtered sessions:', filtered);
    setFilteredSessions(filtered);
  };

  const getStats = () => {
    const total = sessions.length;
    const approved = sessions.filter(session => session.documentationStatus === 'Approved').length;
    const pending = sessions.filter(session => session.documentationStatus === 'Pending Approval').length;
    const missing = sessions.filter(session => session.documentationStatus === 'Missing' || session.documentationStatus === 'Not Submitted').length;
    const pastDue = sessions.filter(session => session.documentationStatus === 'Past Due').length;
    const rejected = sessions.filter(session => session.documentationStatus === 'Rejected').length;

    return [
      { name: 'Total Sessions', value: total, filter: 'Total' },
      { name: 'Approved', value: approved, filter: 'Approved' },
      { name: 'Pending Approval', value: pending, filter: 'Pending Approval' },
      { name: 'Missing', value: missing, filter: 'Missing' },
      { name: 'Past Due', value: pastDue, filter: 'Past Due' },
      { name: 'Rejected', value: rejected, filter: 'Rejected' },
    ];
  };

  const handleStatClick = (filter: StatFilter) => {
    setStatFilter(filter === statFilter ? null : filter);
  };

  const handleFilter = () => {
    fetchSessions();
  };

  const handleClear = () => {
    setStartDate('');
    setEndDate('');
    setStatFilter(null);
  };

  const handleRowClick = (session: RemoteSupportSession) => {
    setSelectedSession(session);
  };

  const handleCloseSideNav = () => {
    setSelectedSession(null);
  };

  return (
    <div className="tw-px-4 tw-sm:px-6 tw-lg:px-8">
      <div className="tw-sm:flex tw-sm:items-center">
        {/* <div className="tw-sm:flex-auto">
          <h1 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Remote Support Sessions</h1>
          <p className="tw-mt-2 tw-text-sm tw-text-gray-700">
            A list of all remote support sessions including provider name, start time, end time, status, and documentation status.
          </p>
        </div> */}
        <div className="tw-mt-4 tw-sm:ml-16 tw-sm:mt-0 tw-sm:flex-none">
          <div className="tw-flex tw-space-x-4">
            <CalendarSelector id="startDate" value={startDate}  name="Start Date:" onChange={ e => {
												//console.log("Success! - CalendarSelector output:", e.target.value)
												console.log(e.target.value);
												setStartDate(e.target.value);
											}}/>
            <CalendarSelector id="endDate" value={endDate} name="End Date:" onChange={ e => {
												//console.log("Success! - CalendarSelector output:", e.target.value)
												console.log(e.target.value);
												setEndDate(e.target.value);
											}}/>
            {/* <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="tw-border tw-rounded tw-p-2"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="tw-border tw-rounded tw-p-2"
            /> */}
            <ButtonPrimary onClick={handleFilter} className="tw-flex tw-items-center tw-my-auto">
              <Filter className="tw-mr-2 tw-h-4 tw-w-4" /> Filter
            </ButtonPrimary>
            <ButtonSecondary onClick={handleClear} variant="outline" className="tw-flex tw-items-center tw-my-auto">
              <X className="tw-mr-2 tw-h-4 tw-w-4" /> Clear
            </ButtonSecondary>
          </div>
        </div>
      </div>

      {loadingSessions ? <>
            <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                    <LoadingSpinner /> 
					<span className="tw-text-primary tw-text-5 tw-text-center">Loading Session Documentations</span>
                </div>
        </>:<>

        {!selectedSession  && <div className="tw-mt-8 tw-sticky tw-top-0 tw-z-10 tw-bg-white tw-shadow-md tw-p-4">
        <div className="tw-mx-auto tw-max-w-7xl">
          <div className="tw-grid tw-grid-cols-6 tw-gap-4">
            {getStats().map((stat) => (
              <div 
                key={stat.name} 
                className={`tw-bg-white tw-rounded-lg tw-shadow tw-px-4 tw-py-5 tw-sm:p-6 tw-cursor-pointer 
                            ${statFilter === stat.filter ? 'tw-ring-2 tw-ring-indigo-500' : ''}`}
                onClick={() => handleStatClick(stat.filter as StatFilter)}
                style={{ borderLeft: `4px solid ${COLORS[stat.filter as keyof typeof COLORS]}` }}
              >
                <dt className="tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate">{stat.name}</dt>
                <dd className="tw-mt-1 tw-text-3xl tw-font-semibold tw-text-gray-900">{stat.value}</dd>
              </div>
            ))}
          </div>
        </div>
      </div> }

      {!selectedSession ? <> 

      <div className="tw-mt-8 tw-flow-root">
        <div className="tw--mx-4 tw--my-2 tw-overflow-x-auto tw-sm:tw--mx-6 tw-lg:tw--mx-8">
          <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle tw-sm:px-6 tw-lg:px-8">
            <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sm:pl-0">
                    Provider Name
                  </th>
                  <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                    Start Time
                  </th>
                  <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                    End Time
                  </th>
                  <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                    Documentation Status
                  </th>
                </tr>
              </thead>
              <tbody className="tw-divide-y tw-divide-gray-200 tw-bg-white">
                {filteredSessions?.map((session) => (
                  <tr key={session.remoteSupportSessionID}
                  onClick={() => handleRowClick(session)}
                  className="tw-cursor-pointer hover:tw-bg-gray-50">
                    <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 tw-sm:pl-0">
                      {session.providerName}
                    </td>
                    <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                      {session.displayStartDate}, {session.displayStartTime}
                    </td>
                    <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                      {session.displayEndDate}, {session.displayEndTime}
                    </td>
                    <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                      {session.status}
                    </td>
                    <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                      {session.documentationStatus}
                    </td>
                  </tr>
                ))}
              </tbody>

              {filteredSessions.length === 0 && (
                <tbody>
                    <tr>
                        <td colSpan={5} className="tw-text-center tw-py-4 tw-text-gray-500 tw-font-medium">
                            No sessions found
                        </td>
                    </tr>
                </tbody>
                )}
            </table>
          </div>
        </div>
      </div>

      </> : <> 
      {selectedSession && (
        <SessionDetailsSideNav
          session={selectedSession}
          onClose={handleCloseSideNav}
        />
      )}
      </>}

      </>}

      
    </div>
  );
};

export default RemoteSupportSessionsComponent;