import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { getRemoteSupportAssociates, getRemoteSupportSupervisors, getStationsForColumbus, getTimeOffForNewSession, saveRecurringRemoteSupportSession, saveRemoteSupportSession } from "../apicalls/sessionService";
import { ButtonPrimary, ButtonSecondary } from "../components/buttons/Buttons";
import { Input, Label, Select } from "../components/forms/formFields";
import { PageHeader, PageSubHeader } from "../components/typography/Headers";
import { Ellipsis } from "../components/utilities/Helpers";
import IntrinsicRatio from "../components/utilities/IntrinsicRatio";
import Core from "../templates/Core";
import Employee from "../types/employee";
import RemoteSupportSession from "../types/remoteSupportSession";
import { pageSubHeader } from "../utilities/typography";
import { Dialog as TWDialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useAppContext } from "../Context";
import RemoteSupportStation from "../types/remoteSupportStation";

const SessionForm = props => {
	const appContext = useAppContext();

    const [values, setValues] = useState<any>({
		"start_date": "",
        "end_date": "",
		"start_time": "",
		"end_time": "",
		"provider": "",
        "supervisor": "",
		"dedicated": false,
		"session_recurrence": "Never",
		"repeat_end_date": "",
		"repeats_monday": false,
		"repeats_tuesday": false,
		"repeats_wednesday": false,
		"repeats_thursday": false,
		"repeats_friday": false,
		"repeats_saturday": false,
		"repeats_sunday": false,
		"station": "",
		"training": false,
	});

	const officeID = "10fe04ac-7bcd-48f3-bde5-927bfe1830a8";
	const officeName = "Columbus";

	const [supportStations, setSupportStations] = useState<RemoteSupportStation[]>([]);
	
	useLayoutEffect(() => {
		const fetchLocationStations = async () => {
			const stations = await getStationsForColumbus(appContext.handleAPICallError);
			console.log(stations.sort((a, b) => {
				const aName = a.stationName.split(' ')[1];
				const bName = b.stationName.split(' ')[1];
				return parseInt(aName) - parseInt(bName);
			  }));
			setSupportStations(stations.sort((a, b) => {
				const aName = a.stationName.split(' ')[1];
				const bName = b.stationName.split(' ')[1];
				return parseInt(aName) - parseInt(bName);
			  }));
		}

		fetchLocationStations();
	}, [])

	const [showSessionLoading, setShowSessionLoading] = useState(false);

	const [saveSessionOpen, setSaveSessionOpen] = useState(false);

	const [invalidTimesOpen, setInvalidTimesOpen] = useState(false);

	const [timeOffCautionOpen, settimeOffCautionOpen] = useState(false);

	const [timeOffCautionMessage, setTimeOffCautionMessage] = useState("");

	const [formValidationMessage, setFormValidationMessage] = useState("");

    const start_date = useRef();
    const end_date = useRef();
	const start_time = useRef();
	const end_time = useRef();
	const provider = useRef();
    const supervisor = useRef();
	const session_recurrence = useRef();
	const session_station = useRef();
	const repeat_end_date = useRef();

	const handleInvalidTimesClose = () => {
		setInvalidTimesOpen(false);
	}

	const handleTimeOffClose = () => {
		settimeOffCautionOpen(false);
	}


	let navigate = useNavigate();

	

	const [activeRSAs, setActiveRSAs] = useState<Employee[]>(null);
	const [activeSupervisors, setActiveSupervisors] = useState<Employee[]>(null);
	const [minEndDate, setMinEndDate] = useState("2000-01-01");
	const [maxEndDate, setMaxEndDate] = useState("2024-12-31");

	const toggleRepeatsMonday = () => {
		setValues({...values, "repeats_monday": !values.repeats_monday })
		console.log(values.repeats_monday);
	}

	const toggleRepeatsTuesday = () => {
		setValues({...values, "repeats_tuesday": !values.repeats_tuesday })
	}

	const toggleRepeatsWednesday = () => {
		setValues({...values, "repeats_wednesday": !values.repeats_wednesday })
	}

	const toggleRepeatsThursday = () => {
		setValues({...values, "repeats_thursday": !values.repeats_thursday })
	}

	const toggleRepeatsFriday = () => {
		setValues({...values, "repeats_friday": !values.repeats_friday })
	}

	const toggleRepeatsSaturday = () => {
		setValues({...values, "repeats_saturday": !values.repeats_saturday })
	}

	const toggleRepeatsSunday = () => {
		setValues({...values, "repeats_sunday": !values.repeats_sunday })
	}

	useEffect(() => {
		const fetchRSAs = async () => {
			const data = await getRemoteSupportAssociates(appContext.handleAPICallError);
			console.log(data);
			setActiveRSAs(data);
		}

		const fetchSupervisors = async () => {
			const data = await getRemoteSupportSupervisors(appContext.handleAPICallError);
			console.log(data);
			setActiveSupervisors(data);
			const date = moment().format("YYYY-MM-DD");

			setValues({...values, "supervisor": data[0].employeeID, "start_date": date, "end_date": date });
			setMinEndDate(moment(date).format("YYYY-MM-DD"));
			setMaxEndDate(moment(date).add(1, 'd').format("YYYY-MM-DD"));
		}

		fetchRSAs()
    		.catch(console.error);

		fetchSupervisors()
			.catch(console.error);

			// const current = new Date();
			// const date = moment().format("YYYY-MM-DD");

			// console.log(date);
	
			// setValues({...values, "start_date": date, "end_date": date })
			// setMinEndDate(moment(date).format("YYYY-MM-DD"));
			// setMaxEndDate(moment(date).add(1, 'd').format("YYYY-MM-DD"));
	}, []);

	const handleSessionSuccessClose = () => {
		setSaveSessionOpen(false);

		navigate(-1);
	}

	const saveTimeOffSession = async () => {
		console.log(values);

		const startTime = moment(values.start_date + " " + values.start_time)
        const endTime = moment(values.end_date + " " + values.end_time)

		var selectedStation = supportStations.find(station => station.remoteSupportStationID === values.station);

		let newSession : RemoteSupportSession = {
			remoteSupportSessionID: uuid(),
			supervisorID: values.supervisor,
			providerID: values.provider,
			startDate: moment(values.start_date).format(),
			displayStartDate: "",
			endDate: moment(values.end_date).format(),
			displayEndDate: "",
			providerName: "",
			startTime: startTime.format(),
			displayStartTime: "",
			endTime: endTime.format(),
			displayEndTime: "",
			status: "Assigned",
			multiDay: moment(values.end_date).format("MM-DD-YYYY") !== moment(values.start_date).format("MM-DD-YYYY"),
			startIndex: 0,
			endIndex: 0,
			multiDayStartIndex: 0,
			multiDayEndIndex: 0,
			recurrence: values.session_recurrence,
			assignedShifts: [],
			colStart: 0,
			colEnd: 0,
			dedicatedSession: values.dedicated,
			repeatsSunday: values.repeats_sunday,
			repeatsMonday: values.repeats_monday,
			repeatsTuesday: values.repeats_tuesday,
			repeatsWednesday: values.repeats_wednesday,
			repeatsThursday: values.repeats_thursday,
			repeatsFriday: values.repeats_friday,
			repeatsSaturday: values.repeats_saturday,
			recurrenceEndDate: moment(values.repeat_end_date).format(),
			multiDayColStart: 0,
			multiDayColEnd: 0,
			officeID: "",
			officeName: "",
			stationID: selectedStation.remoteSupportStationID,
			stationName: selectedStation.stationName,
			training: values.training,
		}

		console.log(newSession);

		setShowSessionLoading(true);

		if(newSession.recurrence === "Never"){
			newSession.recurrenceEndDate = newSession.startDate;

			const result = await saveRemoteSupportSession(newSession, appContext.handleAPICallError);

			setShowSessionLoading(false);

			setSaveSessionOpen(true);

			
		} else {
			const timeOff = await getTimeOffForNewSession(newSession, appContext.handleAPICallError);

			const result = await saveRecurringRemoteSupportSession(newSession, appContext.handleAPICallError);

			setShowSessionLoading(false);
	
			setSaveSessionOpen(true);

			
		}
	}

	const saveNewSession = async () => {
		console.log(values);

		const startTime = moment(values.start_date + " " + values.start_time)
        const endTime = moment(values.end_date + " " + values.end_time)

		if(values.start_date === "" || values.end_date === "" || values.start_time === "" || values.end_time === "" || values.provider === "" || values.station === ""){
			setInvalidTimesOpen(true);

			setFormValidationMessage("Please make sure all inputs are filled");
		}
		else if (endTime < startTime) {
			setInvalidTimesOpen(true);

			setFormValidationMessage("Session End Time cannot be earlier than Start Time");
		} else {
			setInvalidTimesOpen(false);

			var selectedStation = supportStations.find(station => station.remoteSupportStationID === values.station);

			let newSession : RemoteSupportSession = {
				remoteSupportSessionID: uuid(),
				supervisorID: values.supervisor,
				providerID: values.provider,
				startDate: moment(values.start_date).format(),
				displayStartDate: "",
				endDate: moment(values.end_date).format(),
				displayEndDate: "",
				providerName: "",
				startTime: startTime.format(),
				displayStartTime: "",
				endTime: endTime.format(),
				displayEndTime: "",
				status: "Assigned",
				multiDay: moment(values.end_date).format("MM-DD-YYYY") !== moment(values.start_date).format("MM-DD-YYYY"),
				startIndex: 0,
				endIndex: 0,
				multiDayStartIndex: 0,
				multiDayEndIndex: 0,
				recurrence: values.session_recurrence,
				assignedShifts: [],
				colStart: 0,
				colEnd: 0,
				dedicatedSession: values.dedicated,
				repeatsSunday: values.repeats_sunday,
				repeatsMonday: values.repeats_monday,
				repeatsTuesday: values.repeats_tuesday,
				repeatsWednesday: values.repeats_wednesday,
				repeatsThursday: values.repeats_thursday,
				repeatsFriday: values.repeats_friday,
				repeatsSaturday: values.repeats_saturday,
				recurrenceEndDate: moment(values.repeat_end_date).format(),
				multiDayColStart: 0,
				multiDayColEnd: 0,
				officeID: officeID,
				officeName: officeName,
				stationID: selectedStation.remoteSupportStationID,
				stationName: selectedStation.stationName,
				training: values.training,
			}
	
			console.log(newSession);
	
			setShowSessionLoading(true);
	
			if(newSession.recurrence === "Never"){
				newSession.recurrenceEndDate = newSession.startDate;

				const timeOff = await getTimeOffForNewSession(newSession, appContext.handleAPICallError);

				if(timeOff.status === "Not Available"){
					const result = await saveRemoteSupportSession(newSession, appContext.handleAPICallError);
					setShowSessionLoading(false);
	
					setSaveSessionOpen(true);
				} else {
					setShowSessionLoading(false);

					setTimeOffCautionMessage("Provider has an " + timeOff.status + " PTO from " + moment.utc(timeOff.startDate).format("MM-DD-YYYY") + " to " + moment.utc(timeOff.endDate).format("MM-DD-YYYY") + ". Do you still want to add this session?")

					settimeOffCautionOpen(true);
				}
	
				
			} else {
				const timeOff = await getTimeOffForNewSession(newSession, appContext.handleAPICallError);

				if(timeOff.status === "Not Available"){
					const result = await saveRecurringRemoteSupportSession(newSession, appContext.handleAPICallError);
	
					setShowSessionLoading(false);
		
					setSaveSessionOpen(true);
				} else {
					setShowSessionLoading(false);

					setTimeOffCautionMessage("Provider has an " + timeOff.status + " PTO from " + moment.utc(timeOff.startDate).format("MM-DD-YYYY") + " to " + moment.utc(timeOff.endDate).format("MM-DD-YYYY") + ". Do you still want to add this session?")

					settimeOffCautionOpen(true);
				}

				
			}
		}

		

		
	}

	const updateStartDate = event => {
		console.log(event.target.value);
		setValues({...values, "start_date": event.target.value, "end_date": event.target.value })
		console.log(moment(event.target.value).format("YYYY-MM-DD"));
		console.log(moment(event.target.value).add(1, 'd').format("YYYY-MM-DD"));
		setMinEndDate(moment(event.target.value).format("YYYY-MM-DD"));
		setMaxEndDate(moment(event.target.value).add(1, 'd').format("YYYY-MM-DD"));
	}

	const discardSession = async () => {
		navigate(-1);
	}
    
    return (<>
        {/* <Core> */}

		<Dialog
			open={timeOffCautionOpen}
			onClose={handleTimeOffClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
			{"Overlapping PTO"}
			</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">
				{timeOffCautionMessage}
			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleTimeOffClose}>No</Button>
			<Button onClick={saveTimeOffSession}>Yes</Button>
			</DialogActions>
		</Dialog>

		<Dialog
			open={invalidTimesOpen}
			onClose={handleInvalidTimesClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
			{"Invalid Input"}
			</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">
				{formValidationMessage}
			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleInvalidTimesClose}>Ok</Button>
			</DialogActions>
		</Dialog>

		{/* <Dialog
			open={saveSessionOpen}
			onClose={handleSessionSuccessClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
			{"Success!"}
			</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">
				RSA Session saved successfully
			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleSessionSuccessClose}>Ok</Button>
			</DialogActions>
		</Dialog> */}
		<Transition.Root show={saveSessionOpen} as={Fragment}>
			<TWDialog as="div" className="tw-relative tw-z-10" onClose={handleSessionSuccessClose}>
				<Transition.Child
				as={Fragment}
				enter="tw-ease-out tw-duration-300"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-ease-in tw-duration-200"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
				>
				<div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
				</Transition.Child>

				<div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
					<div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
						<Transition.Child
						as={Fragment}
						enter="tw-ease-out tw-duration-300"
						enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
						enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leave="tw-ease-in tw-duration-200"
						leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
						>
						<TWDialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6">
							<div>
							<div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100">
								<CheckIcon className="tw-h-6 tw-w-6 tw-text-green-600" aria-hidden="true" />
							</div>
							<div className="tw-mt-3 tw-text-center sm:tw-mt-5">
								<TWDialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
								Saved Successfully
								</TWDialog.Title>
								<div className="tw-mt-2">
								<p className="tw-text-sm tw-text-gray-500">
									Remote Support Session Saved Successfully
								</p>
								</div>
							</div>
							</div>
							<div className="tw-mt-5 sm:tw-mt-6">
							<button
								type="button"
								className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-primary tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-indigo-600"
								onClick={handleSessionSuccessClose}
							>
								Go back to Calendar
							</button>
							</div>
						</TWDialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</TWDialog>
		</Transition.Root>
        <div className="tw-px-xl tw-py-xl">
				<div className="tw-border-b tw-border-gray-800 tw-pb-5">
					<h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Session Calendar</h2>
				</div>
                <div className="tw-px-xl tw-pb-xl tw-text-dark">
                    <div>
                    	<div>
                        <form className="tw-p-0 tw-m-0 tw-border-0">
							<div className="tw-py-4">
								<h3 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">New Session</h3>
							</div>
							<div className="tw-space-y">

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Start Date
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
                                        <Input
										 min="2000-01-01" max="2024-12-31"
										type="date" ref={ start_date }
										value={ values.start_date } onChange={updateStartDate}  alt className="tw-flex-grow tw-max-w-300"/>
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Start Time
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Input id="start_time" type="time" onChange={ e => setValues({...values, "start_time": e.target.value }) } ref={ start_time } value={ values.start_time } alt className="tw-flex-grow tw-max-w-300" />
										</div>
									</div>
								</div>

                                <div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
												End Date
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
                                        <Input
										 min={minEndDate} max={maxEndDate}
										type="date" ref={ end_date }
										value={ values.end_date } onChange={ e => setValues({...values, "end_date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
										</div>
									</div>
								</div>

								

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
												End Time
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Input id="end_time" type="time" onChange={ e => setValues({...values, "end_time": e.target.value }) } ref={ end_time } value={ values.end_time } alt className="tw-flex-grow tw-max-w-300" />
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Provider
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-300px">
											
											<Select id="shift_type" onChange={ e => setValues({...values, "provider": e.target.value }) } ref={ provider } value={ values.provider } alt className="tw-flex-grow tw-max-w-500">
												{/* <option value="">--Select--</option>
												<option value="AMR">Ali Rahimi</option>
                                                <option value="AMS">Sam Jankowski</option>
                                                <option value="AMS">Corissa Thompson</option>
                                                <option value="AMS">Annie Watson</option> */}
												{ activeRSAs ? <>
													<option value="">--Select Provider--</option>
													{
														activeRSAs.map(provider =>
															<option value={provider.employeeID}>{provider.firstName} {provider.lastName}</option>)
													}
												</>:<>
													<option value="">--Loading--</option>
												</>}
											</Select>
										</div>
									</div>
								</div>

                                <div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Supervisor
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-300px">
											<Select id="shift_type" onChange={ e => setValues({...values, "supervisor": e.target.value }) } ref={ supervisor } value={ values.supervisor } alt className="tw-flex-grow tw-max-w-500">
												{/* <option value="">--Select--</option>
												<option value="AMR">Ali Rahimi</option> */}
												{ activeSupervisors ? <>
													<option value="">--Select Supervisor--</option>
													{
														activeSupervisors.map(provider =>
															<option value={provider.employeeID}>{provider.firstName} {provider.lastName}</option>)
													}
												</>:<>
													<option value="">--Loading--</option>
												</>}
											</Select>
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Training
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Checkbox
													checked={values.training}
													onChange={ e => setValues({...values, "training": !values.training }) }
													inputProps={{ 'aria-label': 'controlled' }}
											/>
										</div>
									</div>
									<div className="tw-flex tw-px">
									{ values.training && <div className="tw-text-shift-reassign tw-text-md tw-mt-1">Note: Shifts will not be assigned automatically to training sessions</div> }
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="session_station" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Office
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-300px">
											<Label for="session_station" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Columbus
											</Label>
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="session_station" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Station
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-300px">
											<Select id="session_station" onChange={ e => setValues({...values, "station": e.target.value }) } ref={ session_station } value={ values.station } alt className="tw-flex-grow tw-max-w-500">
												{/* <option value="">--Select--</option>
												<option value="AMR">Ali Rahimi</option> */}
												{ supportStations ? <>
													<option value="">--Select Station--</option>
													{
														supportStations.map(station =>
															<option value={station.remoteSupportStationID}>{station.stationName}</option>)
													}
												</>:<>
													<option value="">--Loading--</option>
												</>}
											</Select>
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="session_recurrence" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Recurrence
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Select id="session_recurrence" onChange={ e => setValues({...values, "session_recurrence": e.target.value }) } ref={ session_recurrence } value={ values.session_recurrence } alt className="tw-flex-grow tw-max-w-500">
												<option value="">--Select--</option>
												<option value="Never">Never</option>
												<option value="Daily">Daily</option>
												<option value="Weekly">Weekly</option>
												<option value="DaysOfWeek">Days Of Week</option>
											</Select>
										</div>
									</div>
								</div>

								{values["session_recurrence"] !== "" && values["session_recurrence"] !== "Never" ? <>
									<div className="-tw-m">
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
													Repeat End Date
												</Label>
											</div>
											<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Input
											min="2000-01-01" max="2024-12-31"
											type="date" ref={ repeat_end_date }
											value={ values.repeat_end_date } onChange={ e => setValues({...values, "repeat_end_date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
											</div>
										</div>
									</div>
								</>:<></>}

								{values["session_recurrence"] === "DaysOfWeek" ? <>
								<div className="-tw-m">
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
													Repeats
												</Label>
											</div>
											<div className="tw-flex">
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsMonday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_monday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													M
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsTuesday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_tuesday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													Tu
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsWednesday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_wednesday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													W
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsThursday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_thursday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													Th
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsFriday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_friday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													F
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsSaturday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_saturday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													Sa
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsSunday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_sunday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													Su
												</IntrinsicRatio>
											</li>
										</div>
										</div>
										
									</div>
								</>:<></>}

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Dedicated Session
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Checkbox
													checked={values.dedicated}
													onChange={ e => setValues({...values, "dedicated": !values.dedicated }) }
													inputProps={{ 'aria-label': 'controlled' }}
											/>
										</div>
									</div>
								</div>

								

							</div>
							<div className="tw-pt-xxl tw-pb-xl">
								<ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
									{showSessionLoading ? 
									<>
									<li>
										<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
									</li>
									
									</>: <>

									<li>
										<ButtonSecondary large className="tw-px tw-min-w-200" onClick={ e => {
											discardSession();
											e.preventDefault();
										}}>
											Discard
										</ButtonSecondary>
									</li>
									<li>
										<ButtonPrimary large className="tw-px tw-min-w-200" onClick={ e => {
											saveNewSession();
											e.preventDefault();
										}}>
											Save
										</ButtonPrimary>
									</li>
									</>}
									
								</ul>
							</div>
						</form>
                        </div>
                    </div>
                </div>
		</div>
        {/* </Core> */}
    </>);
}

export default SessionForm