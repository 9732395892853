import { ButtonSecondary, DefaultButton } from "@medforall/medforall-component-library";
import { useAppContext } from "../../Context";
import AvatarNameInline from "../avatars/AvatarNameInline";
import { Search } from "../forms/formFields";
import { Span } from "../utilities/Span";
import { useLayoutEffect, useState } from "react";
import AssignedEvent from "../../types/assignedEvent";
import CoverageEvent from "../../types/coverageEvent";
import { LabelOutputs } from "../utilities/Helpers";
import { Tooltip } from "@mui/material";
import { ButtonPrimary } from "../buttons/Buttons";
import RemoteSupportSession from "../../types/remoteSupportSession";
import moment from "moment";
import { getCoverageEventsBySessionID, getOverlappingSessions } from "../../apicalls/sessionService";
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import MoveShiftToSessionPanel from "./MoveShiftToSessionPanel";
import DivideShiftWithSessionPanel from "./DivideShiftWithSessionPanel";

const Added = props => {
	return <LabelOutputs className="tw-w-200px tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
		<span className="tw-text-5">
			Added Successfully
		</span>
	</LabelOutputs>
}

const ManageSessionShiftsPanel = props => {
    const navContext = useAppContext();

    const [toggleAssigned, setToggleAssigned] = useState<boolean>(true);
    const [toggleMovable, setToggleMovable] = useState<boolean>(true);

    const [selectedSession, setSelectedSession] = useState(null);
    const [sessionShifts, setSessionShifts] = useState<AssignedEvent[]>(null);
    const [movableShifts, setMovableShifts] = useState<CoverageEvent[]>(null);

	const [filteredSessionShifts, setFilteredSessionShifts] = useState<AssignedEvent[]>(null);
	const [filteredMovableShifts, setFilteredMovableShifts] = useState<CoverageEvent[]>(null);

	const [hasMovableShifts, setHasMovableShifts] = useState<boolean>(false);

    const [concurrentSessions, setConcurrentSessions] = useState<RemoteSupportSession[]>(null);

    const [added, setAdded] = useState<boolean>(false);

    const [shiftsLoading, setShiftsLoading] = useState<boolean>(false);

	const isShiftInsideSession = (shift: AssignedEvent, session: RemoteSupportSession) : boolean => {
		// if either is undefined or null
		if(!shift || !session){
			return false;
		}

		// use moment to compare
		const shiftStart = moment(`${shift.displayCoverageDate} ${shift.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const shiftEnd = moment(`${shift.displayCoverageDate} ${shift.displayEndTime}`, 'MM-DD-YYYY h:mm A');
		const sessionStart = moment(`${session.displayStartDate} ${session.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const sessionEnd = moment(`${session.displayEndDate} ${session.displayEndTime}`, 'MM-DD-YYYY h:mm A');

		
		if(shiftEnd.isBefore(shiftStart)){
            shiftEnd.add(1, 'days');
        }

		if(sessionEnd.isBefore(sessionStart)){
			sessionEnd.add(1, 'days');
		}

		if(shiftStart.isSameOrAfter(sessionStart) && shiftEnd.isSameOrBefore(sessionEnd)){
		  return true;
		} else {
		//   console.log("Shift not inside session");
		//   console.log(shift);
		//   console.log(shiftStart);
		//   console.log(shiftEnd);
		//   console.log(sessionStart);
		//   console.log(sessionEnd);
		  return false;
		}
	}

    const doesShiftOverlap = (shift: AssignedEvent, session: RemoteSupportSession) : boolean => {
		// if either is undefined or null
		if(!shift || !session){
			return false;
		}

		// use moment to compare
		const shiftStart = moment(`${shift.displayCoverageDate} ${shift.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const shiftEnd = moment(`${shift.displayCoverageDate} ${shift.displayEndTime}`, 'MM-DD-YYYY h:mm A');
		const sessionStart = moment(`${session.displayStartDate} ${session.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const sessionEnd = moment(`${session.displayEndDate} ${session.displayEndTime}`, 'MM-DD-YYYY h:mm A');
	  

		if(shiftEnd.isBefore(shiftStart)){
            shiftEnd.add(1, 'days');
        }

		if(sessionEnd.isBefore(sessionStart)){
			sessionEnd.add(1, 'days');
		}

		if(shiftStart.isBefore(sessionEnd) && shiftEnd.isAfter(sessionStart)){
		  return true;
		} else if (isShiftInsideSession(shift, session)){
			return true;
		} else {
		//   console.log("Shift not inside session");
		//   console.log(shift);
		//   console.log(shiftStart);
		//   console.log(shiftEnd);
		//   console.log(sessionStart);
		//   console.log(sessionEnd);
		  return false;
		}
	}

    const canShiftBeDivided = (shift: AssignedEvent) : boolean => {
        if(!shift){
            return false;
        }

        if(!concurrentSessions){
            return false;
        }

        let result = false;

        concurrentSessions.forEach( session => {
            if(doesShiftOverlap(shift, session)){
                result = true;
            }
        });

        return result;
    }

    const confirmMoveShiftToSession = (shift: CoverageEvent) => {
        navContext.setSideNav( <MoveShiftToSessionPanel shiftToMove={shift} selectedSession={selectedSession} sessionShifts={sessionShifts} movableShifts={movableShifts} concurrentSessions={concurrentSessions} updateSession={props?.updateSession} removeSession={props?.removeSession} updateShiftCount={props?.updateShiftCount}/>);
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavTitle('Reassign Shift to Session');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(null);
    }

    const confirmDivideShift = (shift: AssignedEvent) => {
        navContext.setSideNav( <DivideShiftWithSessionPanel shiftToDivide={shift} selectedSession={selectedSession} sessionShifts={sessionShifts} movableShifts={movableShifts} concurrentSessions={concurrentSessions} updateSession={props?.updateSession} removeSession={props?.removeSession} updateShiftCount={props?.updateShiftCount}/>);
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavTitle('Delegate Shift in Session');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(null);
    }

    useLayoutEffect(() => {
        console.log("Selected Session: ", props.selectedSession);
		setHasMovableShifts(false);

        if(props?.selectedSession) {
            setSelectedSession(props?.selectedSession);
            setSessionShifts(props?.sessionShifts);
			setFilteredSessionShifts(props?.sessionShifts);

            const fetchMovableShifts = async () => {
                setShiftsLoading(true);

                const assignedShifts = await getCoverageEventsBySessionID(props?.selectedSession.remoteSupportSessionID, navContext.handleAPICallError);

                if (assignedShifts != null){
                    console.log(assignedShifts);

                    console.log(assignedShifts.filter(f => f.providerName !== props?.selectedSession?.providerName));

					var movableShifts = assignedShifts.filter(f => f.providerName !== props?.selectedSession?.providerName);

                    setMovableShifts(movableShifts);
					setFilteredMovableShifts(movableShifts);

                    const overlappingSessions = await getOverlappingSessions(props?.selectedSession.remoteSupportSessionID, navContext.handleAPICallError);

					console.log("Overlapping sessions: ", overlappingSessions);

                    if (overlappingSessions != null){
                        setConcurrentSessions(overlappingSessions);
                    }

					if(movableShifts && movableShifts.length !== 0){
						setHasMovableShifts(true);
						console.log("Has movable shifts");
						console.log(movableShifts);
					}

					
                } else {
					setHasMovableShifts(false);
				}

                setShiftsLoading(false);

               
            }

			console.log("Movable shifts: ", props?.movableShifts);
			console.log("Concurrent sessions: ", props?.concurrentSessions);

			if(props?.movableShifts && props?.concurrentSessions){
				console.log("Setting movable shifts and concurrent sessions");
				setMovableShifts(props?.movableShifts);
				setFilteredMovableShifts(props?.movableShifts);
				if(props?.movableShifts.length !== 0){
					setHasMovableShifts(true);
				}

				setConcurrentSessions(props?.concurrentSessions);
			} else {
				fetchMovableShifts();
			}

            //fetchMovableShifts();
        }
    }, [props.selectedSession, props.sessionShifts]);

    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full tw-relative">
           
            
            
            <div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
                <div className="tw-flex-shrink-0 tw-w-full">
                <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-10px">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                    <span className="tw-flex-grow">
                                        <span className="tw-text-dark">
                                            Session Information
                                        </span>
                                    </span>
                                </span>
                            </h3>
                            <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-5px tw-leading-tight">
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Provider
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.providerName}
                                    </span>
                                </li>
								<li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Start Date
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.displayStartDate}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Time Period
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.displayStartTime} - {selectedSession?.displayEndTime}
                                    </span>
									{!moment(selectedSession?.startDate).isSame(selectedSession?.endDate) && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
                                </span>}
                                </li>
                            </ul>
                </div>
                {shiftsLoading ? 
                <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                    <LoadingSpinner /> 
					<span className="tw-text-primary tw-text-5 tw-text-center">Loading Shifts</span>
                </div>
                : 
                <>
                <div className="tw-flex-shrink-0 tw-w-full">
						<div className="tw-flex tw-w-full tw-items-center">
							<div className="tw-flex-grow tw-flex tw-items-center">
								<Search className="tw-flex tw-w-full tw-flex-grow" onChange={ e => {

										//console.log(e?.target.value || "nope");

										if(e?.target.value){
											const searchText = e?.target.value.toString().toLowerCase();
											const filtered = sessionShifts.filter(element => {
												
												// 👇️ using AND (&&) operator
												return element.patientName.toLowerCase().includes(searchText);
											});

											//console.log(filtered)

											setFilteredSessionShifts(filtered);

											const movableFiltered = movableShifts.filter(element => {
												
												// 👇️ using AND (&&) operator
												return element.patientName.toLowerCase().includes(searchText);
											});

											//console.log(movableFiltered)

											setFilteredMovableShifts(movableFiltered);
										} else {
											setFilteredSessionShifts(sessionShifts);

											setFilteredMovableShifts(movableShifts);
										}
										}}/>
							</div>
						</div>
				</div>
                <div className="tw-flex-grow tw-w-full tw-flex tw-flex-col tw-overflow-hidden">
                        <div className={`${toggleAssigned ? 'tw-h-full tw-max-h-full' : 'tw-min-h-40px' } tw-flex-shrink-0 tw-overflow-y-auto`}>
							<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-shadow-lg tw-py-3 tw-px-1" onClick={ e => {
								let temp = toggleAssigned;
								setToggleAssigned( temp = !temp )
							}}>
								<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full">
									<span className="tw-flex-grow">
										<span className="tw-text-black">
											Assigned Shifts : ({sessionShifts?.length || 0})
										</span>
									</span>
									<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
										<img className="tw-w-full" src={`/images/icon-arrow-${ toggleAssigned ? 'down-2' : 'up'}.svg`} alt="Icon" />
									</span>
								</span>
							</h3>
							{ toggleAssigned && <>
								<div className="-tw-mx-20px !tw-mt-5px tw-h-full tw-flex-grow tw-overflow-hidden tw-flex tw-flex-col tw-divide-y-1px tw-divide-black/20 tw-border-b-1px tw-border-b-black/20">

									{((filteredMovableShifts?.length === 0 && filteredSessionShifts?.length === 0) || (!filteredMovableShifts && !filteredSessionShifts)) && <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-py-sm tw-mt-4">
											<span className="tw-text-primary tw-text-5 tw-text-center">No Shifts Available!</span>
									</div>}
									<div className="tw-flex tw-flex-col tw-px-20px tw-overflow-y-auto tw-divide-y-1px tw-divide-black/20 tw-snap-y">
										{ filteredSessionShifts?.map( ( shift, index ) => {
											return <>
												<div className={`tw-snap-center tw-flex-shrink-0 tw-py-10px tw-px-5px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5`}>
													<div className="tw-flex tw-items-center tw-w-200px tw-flex-grow">
														<AvatarNameInline size="30">
															<span className="tw-w-full tw-flex tw-flex-col tw-text-5">
																<span className="tw-w-full">
																	{shift.patientName}
																</span>
																<span className="tw-w-full tw-text-[.75em] tw-text-dark-light">
																	{shift.displayStartTime} - {shift.displayEndTime}, {shift.displayCoverageDate}
																</span>
															</span>
														</AvatarNameInline>
													</div>
													<div className="tw-flex tw-items-center tw-flex-shrink-0 tw-justify-start tw-space-x-5px">
														{/* <DefaultButton className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px" onClick={ e => {
															e.preventDefault();
															//setAdded(true);
														}}>
															<img src="/images/icon-action-assigned.svg" />
														</DefaultButton> */}
														{/* <DefaultButton className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px">
															<img src="/images/icon-action-more.svg" />
														</DefaultButton> */}
                                                        {canShiftBeDivided(shift) && <ButtonSecondary small className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px" onClick={ e => {
                                                            e.preventDefault();
                                                            confirmDivideShift(shift);
                                                            //setAdded(true);
                                                        } }>
                                                            Delegate
                                                        </ButtonSecondary>}
													</div>
												</div>
											</>
										}) }

										{ filteredMovableShifts?.map( ( shift, index ) => {
														return <>
															<div className="tw-snap-center tw-px-5px tw-flex-shrink-0 tw-py-10px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5">
																<div className="tw-flex tw-items-center tw-w-200px tw-flex-grow">
																	<AvatarNameInline size="30">
																		<span className="tw-w-full tw-flex tw-flex-col tw-text-5">
																			<span className="tw-w-full">
																				{shift.patientName}
																			</span>
																			<span className="tw-w-full tw-text-[.75em] tw-text-dark-light">
																				{shift.displayStartTime} - {shift.displayEndTime}, {shift.displayCoverageDate}
																			</span>
																			<span className="tw-w-full tw-text-[.75em] tw-text-shift-reassign">
																				Assigned To: {shift.providerName}
																			</span>
																		</span>
																	</AvatarNameInline>
																</div>
																<div className="tw-flex tw-items-center tw-flex-shrink-0 tw-justify-start tw-space-x-5px">
																	{/* <Tooltip title="Move to session" placement="top">
																		<DefaultButton className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px"  onClick={ e => {
																			e.preventDefault();
																			setAdded(true);
																		}}>
																			<img src="/images/icon-action-assigned.svg" />
																		</DefaultButton>
																	</Tooltip> */}
																	<ButtonSecondary small className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px" onClick={ e => {
																		e.preventDefault();
																		confirmMoveShiftToSession(shift);
																		//setAdded(true);
																	} }>
																		Reassign
																	</ButtonSecondary>
																	
																	{/* <DefaultButton className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px">
																		<img src="/images/icon-action-more.svg" />
																	</DefaultButton> */}
																</div>
															</div>
														</>
													}) }
									</div>
									{/* <div className="tw-w-full tw-flex tw-flex-shrink-0">
										<DefaultButton className="tw-w-full tw-text-6 tw-min-h-30px tw-mt-2px tw-text-dark hover:tw-text-primary">
											View More
										</DefaultButton>
									</div> */}
								</div>
							</>}
						</div>

                        {/* {hasMovableShifts && <div className={`${toggleMovable ? 'tw-basis-1/2 tw-h-1/2 tw-max-h-1/2' : 'tw-min-h-40px' } tw-flex-shrink-0 tw-overflow-y-auto tw-mt-5`}>
							<h3 role="button" className="tw-cursor-pointer tw-bg-gray-400 tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-shadow-lg tw-py-3 tw-px-1" onClick={ e => {
								let temp = toggleMovable;
								setToggleMovable( temp = !temp )
							}}>
								<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full">
									<span className="tw-flex-grow">
										<span className="tw-text-black">
											Reassignable : ({movableShifts?.length || 0})
										</span>
									</span>
									<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
										<img className="tw-w-full" src={`/images/icon-arrow-${ toggleMovable ? 'down-2' : 'up'}.svg`} alt="Icon" />
									</span>
								</span>
							</h3>
							{ toggleMovable && <>
								<div className="-tw-mx-20px !tw-mt-5px tw-h-full tw-flex-grow tw-overflow-hidden tw-flex tw-flex-col tw-divide-y-1px tw-divide-black/20 tw-border-b-1px tw-border-b-black/20">
									<div className="tw-flex tw-flex-col tw-px-20px tw-overflow-y-auto tw-divide-y-1px tw-divide-black/20 tw-snap-y">
									
									</div>
								</div>
							</>}
						</div>} */}
                </div>
                </>}

                        
            </div>
        </div>
    </>);
}

export default ManageSessionShiftsPanel;