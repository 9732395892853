import { Block } from "@medforall/medforall-component-library";
import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import AvatarNameInline from "../avatars/AvatarNameInline";
import { CircleLabel } from "../calendars/CalendarUtils";
import { LabelOutputs } from "../utilities/Helpers";
import ClientHome from "../../types/clientHome";


const ClientInfoPanel = props => {
	const navContext = useAppContext();
	const [name, setName] = useState<any>(props.name);
	const [client, setClient] = useState<any>(props.client);
	const [plan, setPlan] = useState<any>(props.plan);
	const [homes, setHomes] = useState<ClientHome[]>(props.homes);
	const [requirement, setRequirement] = useState<any>(props.requirement);

	const handleOnClick = e => {
		e.preventDefault();
		if (props.onClick) props.onClick(e);
	}

	useLayoutEffect(() => {
		//TODO: Identify "id" to pass to client  <ClientInfoPanel />
		if (props.name) setName(props.name)
		if (props.client) setClient(props.client)
	}, [props.name, props.client])

	useLayoutEffect(() => {
		// navContext.setSideNavTitle("")
	}, [])

	return <>
		<div className="tw-p-20px tw-space-y-15px tw-w-full">
			<div>
				<AvatarNameInline className="tw-text-3 tw-font-primary-bold tw-text-dark tw-flex tw-leading-none" space="tw-space-x-15px"  size="40" name={` ${name.firstName} ${name.lastName} `} />
			</div>
			<h3 className="tw-font-primary-bold tw-font-5 tw-pt-30px">
				<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
					<span className="tw-text-dark tw-ml-5px">Contact Information</span>
				</CircleLabel>
			</h3>
			<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
				<li className="tw-flex tw-flex-col tw-space-y-5px">
					<LabelOutputs space="tw-space-x-15px" icon="/images/icon-email.svg" className="tw-min-w-150p tw-opacity-70">
						Email
					</LabelOutputs>
					<Block className="tw-pl-20px tw-ml-15px">
						<a href="mailto:${client?.email}" className="tw-text-black hover:tw-text-primary tw-block">
							{client?.email}
						</a>
					</Block>
				</li>
				{/* <li className="tw-flex tw-flex-col tw-space-y-5px">
					<LabelOutputs space="tw-space-x-15px" icon="/images/icon-phone.svg" className="tw-min-w-150p tw-opacity-70">
						Phone
					</LabelOutputs>
					<Block className="tw-pl-20px tw-ml-15px">
						<a href="tel:+16145658967" className="tw-text-black hover:tw-text-primary tw-block">
							614-565-8967
						</a>
					</Block>
				</li> */}
				{homes && homes.length > 0 &&  <li className="tw-flex tw-flex-col tw-space-y-5px">
					<LabelOutputs space="tw-space-x-15px" icon="/images/icon-location.svg" className="tw-min-w-150p tw-opacity-70">
						Address
					</LabelOutputs>
					<Block className="tw-pl-20px tw-ml-15px">
						<a href="tel:+16145658967" className="tw-text-black hover:tw-text-primary tw-block">
							<Block>
								{homes[0].street1} {homes[0].street2 ? `, ${homes[0].street2}` : ``}
							</Block>
							<Block>
								<span className="tw-inline-block">{homes[0].city},</span>{` `}<span className="tw-inline-block">{homes[0].state} {homes[0].postalCode}</span>
							</Block>
						</a>
					</Block>
				</li>}
			</ul>
			<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-pt-30px tw-pb-10px">
				{/* <li className="tw-flex tw-space-x-5px tw-items-center">
					<span className="tw-w-150px tw-flex tw-items-center">
						<LabelOutputs space="tw-space-x-15px" icon="/images/icon-timeglass.svg" className="tw-opacity-70">
							Fiscal Year
						</LabelOutputs>
					</span>
					<span className="tw-flex-grow tw-flex tw-items-center tw-text-black">
						{plan.fiscalYear}
					</span>
				</li> */}
				<li className="tw-flex tw-space-x-5px tw-items-center">
					<span className="tw-w-150px tw-flex tw-items-center">
						<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
							Span Starts
						</LabelOutputs>
					</span>
					<span className="tw-flex-grow tw-flex tw-items-center tw-text-black">
						{plan?.displayStartDate}
					</span>
				</li>
				<li className="tw-flex tw-space-x-5px tw-items-center">
					<span className="tw-w-150px tw-flex tw-items-center">
						<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
							Span Ends
						</LabelOutputs>
					</span>
					<span className="tw-flex-grow tw-flex tw-items-center tw-text-black">
						{plan?.displayEndDate}
					</span>
				</li>
				{/* <li className="tw-flex tw-space-x-5px tw-items-center">
					<span className="tw-w-150px tw-flex tw-items-center">
						<LabelOutputs space="tw-space-x-15px" icon="/images/icon-edit.svg" className="tw-opacity-70">
							Created On
						</LabelOutputs>
					</span>
					<span className="tw-flex-grow tw-flex tw-items-center tw-text-black">
						03/17/2021
					</span>
				</li> */}
			</ul>
		</div>
	</>
}

export default ClientInfoPanel;
