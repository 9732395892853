import axios, { AxiosRequestConfig } from "axios";
import { Training } from "../types/training";
import { getAPIToken } from "./billingService";

export const saveNewContinuousEducation = async (training: Training, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'continuous-education/trainings';

    return axios.post<any>(url, training, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getActiveContinuousEducationTrainingsByPageNumber = async (pageNumber: number, handleAPICallError: (error: any) => void) : Promise<Training[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'continuous-education/trainings/' + pageNumber;

    return axios.get<Training[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getDraftContinuousEducationTrainingsByPageNumber = async (pageNumber: number, handleAPICallError: (error: any) => void) : Promise<Training[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'continuous-education/draft-trainings/' + pageNumber;

    return axios.get<Training[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getArchiveContinuousEducationTrainingsByPageNumber = async (pageNumber: number, handleAPICallError: (error: any) => void) : Promise<Training[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'continuous-education/archived-trainings/' + pageNumber;

    return axios.get<Training[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getContinuousEducationTrainingById = async (trainingId: string, handleAPICallError: (error: any) => void) : Promise<Training> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'continuous-education/' + trainingId;

    return axios.get<Training>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const updateContinuousEducationTraining = async (training: Training, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'continuous-education/' + training.trainingID;

    return axios.put<any>(url, training, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getLatestContinuousEducationTrainings = async (handleAPICallError: (error: any) => void) : Promise<Training[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'continuous-education/employee/latest-trainings';

    return axios.get<Training[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getContinuousEducationTrainingByIDForProvider = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<Training> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'continuous-education/trainings/provider/' + trainingID;

    return axios.get<Training>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}