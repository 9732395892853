import { Alert, CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAPIToken, parseJwt } from "../apicalls/billingService";
import { getClientByID, populateScheduleForPatient } from "../apicalls/schedulingService";
import { ButtonAnchor, ButtonPrimary, ButtonSecondary } from "../components/buttons/Buttons";
import { RequirementCalendar } from "../components/containers/Calendars";
import { ClientInfoCard } from "../components/containers/Cards";
import ServicePanel from "../components/panels/Service";
import { PageHeader } from "../components/typography/Headers";
import { Ellipsis } from "../components/utilities/Helpers";
import { useAppContext } from "../Context";
import Core from "../templates/Core";
import { rem } from "../utilities/helpers";

const RequirementCalendarPage = props => { 
    const { patientID } = useParams();

    const navContext = useAppContext();

    const [clientDetails, setClientDetails] = useState(null);

    let navigate = useNavigate();

    //const [selectedRequirement, setSelectedRequirement] = useState<any>();
    const [showRightPanel, setShowRightPanel] = useState<boolean>(false);
    const [servicePanelData, setServicePanelData] = useState<any>({'type': 2});
    //const toggleShowRightPanel = () => setShowRightPanel(showRightPanel == false || true);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { weekStart, weekEnd } = useParams();
    const [weekLoaded, setWeekLoaded] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);


    useLayoutEffect(() => {
        console.log("Week Start");
        console.log(weekStart);
        console.log("Week end");
        console.log(weekEnd);

        const fetchValidTaken = async () => {
			const token = await getAPIToken();
			const tokenJSON = parseJwt(token);
			console.log(tokenJSON.roles);
            if (tokenJSON.roles.includes('CaseCoordinator')){
				setIsAdmin(false);
			} else if (tokenJSON.roles.includes('GlobalAdministrator') || tokenJSON.roles.includes('ScheduleCoordinator')){
                setIsAdmin(true);
            }

		}

		fetchValidTaken()
    		.catch(console.error);

        if(weekStart === undefined || weekEnd === undefined){
            setStartDate(moment().startOf('week'));
            setEndDate(moment().startOf('week').add(6, 'd'));
        } else {
            setStartDate(moment(weekStart));
            setEndDate(moment(weekEnd));
        }

        setWeekLoaded(true);
        
    },[])

    const showRequirement = true;

    const logout = props?.handleLogout

    const toggleShowRightPanel = (reqByID) => {
        console.log("NAv Context Side Nav");

        const selectedRequirement = reqByID;

        const panelProps = {
            servicePanelData, setServicePanelData,
            selectedRequirement,
            showRequirement,
            startDate, endDate,
        }

        console.log(panelProps);
        navContext.setSideNav( <ServicePanel { ...panelProps } />);
		navContext.setSideNavTheme(2);
		navContext.setSideNavTitle("Requirement Panel");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.openSideNav();

        console.log(navContext);
    }

    const parentProps = {
		showRightPanel, setShowRightPanel,
		servicePanelData, setServicePanelData,
        toggleShowRightPanel,
        logout, showRequirement,
        startDate, endDate,
	}

    const addNewRequirement = () => {
        navigate("/schedule/client-requirement/"+ clientDetails.patientID +"/new");
    }

    const editSpanRequirement = () => {
        navigate("/schedule/client-requirement/"+ clientDetails.patientID +"/span-requirement");
        //navigate("/schedule/client-requirement/"+ clientDetails.patientID +"/requirement-form");
    }

    const populateSchedule = async () => {
        setPopulating(true);

        const result = await populateScheduleForPatient(clientDetails.patientID, navContext.handleAPICallError);

        setPopulating(false);
        setShowPopulateSuccess(true);

        //navigate("/SessionSchedule");
    }

    const backToClientList = () => {
        navigate("/schedule/client-requirement");
    } 

    const [showPopulateSuccess, setShowPopulateSuccess] = useState(false);
    const [populating, setPopulating] = useState(false);

    const closeAlert = async () => {
        setShowPopulateSuccess(false);

        navigate("/schedule/session-schedule/new");
    }

    useEffect(() => {

		const fetchClientDetails = async () => {
			const clientDetails = await getClientByID(patientID, navContext.handleAPICallError);

			console.log(clientDetails);

			setClientDetails(clientDetails);
		}

		fetchClientDetails()
			.catch(console.error);
	}, [patientID])


    return (<>
        {/* <Core { ...parentProps }> */}
            <div className="tw-px-xl tw-py-xl">
            {showPopulateSuccess ? <>
                        <div className="tw-pb-sm tw-px-xl">
                            <Alert  onClose={closeAlert}>Client Requirements Assigned Successfully</Alert>
                        </div>
                    </>:<></> }
                <div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
                   
                        {/* <div className="tw-flex-grow tw-flex-shrink-0">
                            <PageHeader>
                                <Ellipsis>
                                    Client Requirement Calendar
                                </Ellipsis>
                            </PageHeader>
                        </div> */}
                        <div className="tw-pb-5">
                            <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Client Calendar Requirement</h2>
                        </div>
                        {navContext?.showScheduling && <div className="tw-flex tw-items-center tw-justify-end tw-flex-grow tw-flex-shrink-0">
                            <ul className="tw-list-none tw-flex tw-space-x-md">
                                <li>
                                    <ButtonPrimary small onClick={ e => {
											addNewRequirement();
											e.preventDefault();
										}} iconNotRounded svg={`<svg width="${rem('24')}" height="${rem('24')}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" fill="currentColor"/></svg>`}>
                                        On-Demand
                                    </ButtonPrimary>
                                </li>
                                {isAdmin ? <>
                                    <li>
                                        <ButtonPrimary small onClick={ e => {
                                                editSpanRequirement();
                                                e.preventDefault();
                                            }} iconNotRounded svg={`<svg width="${rem('24')}" height="${rem('24')}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 19h1.4l8.625-8.625-1.4-1.4L5 17.6ZM19.3 8.925l-4.25-4.2 1.4-1.4q.575-.575 1.413-.575.837 0 1.412.575l1.4 1.4q.575.575.6 1.388.025.812-.55 1.387ZM17.85 10.4 7.25 21H3v-4.25l10.6-10.6Zm-3.525-.725-.7-.7 1.4 1.4Z" fill="currentColor"/></svg>`}>
                                        Span Coverage
                                        </ButtonPrimary>
                                    </li>
                                </>:<></>}
                                

                                {/* <li>
                                {populating ? 
									<>
									<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
									</>: <>
                                    <ButtonPrimary small onClick={ e => {
											populateSchedule();
											e.preventDefault();
										}} iconNotRounded svg={`<svg width="${rem('24')}" height="${rem('24')}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM12 7C13.66 7 15 8.34 15 10C15 11.66 13.66 13 12 13C10.34 13 9 11.66 9 10C9 8.34 10.34 7 12 7ZM18 19H6V17.6C6 15.6 10 14.5 12 14.5C14 14.5 18 15.6 18 17.6V19Z" fill="currentColor"/></svg>`}>
                                       Auto-Assign
                                    </ButtonPrimary>
                                    </> }
                                    
                                </li> */}
                            </ul>
                        </div>}
                    
                </div>

                <div className="tw-px-xl">
                        <ButtonAnchor iconNotRounded onClick={backToClientList} svg={`<svg width="${rem('12')}" height="${rem('18')}" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.115 15.885L4.245 9L11.115 2.115L9 0L0 9L9 18L11.115 15.885Z" fill="currentColor"/></svg>`}>
                            Back to Client List
                        </ButtonAnchor>
                </div>
            </div>
            <div className="tw-px-xl tw-pb-xl tw-text-dark">
                <div className="tw-px-xl">
                    <div>
                        <ClientInfoCard firstName={clientDetails?.firstName} lastName={clientDetails?.lastName} email={clientDetails?.email} />

                        {clientDetails ? <>
                            <RequirementCalendar patient={clientDetails} { ...parentProps }/>
                        </>:<></>}
                        
                    </div>
                </div>
            </div>
        {/* </Core> */}
    </>);
}

export default RequirementCalendarPage