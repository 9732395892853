import axios, { AxiosRequestConfig } from "axios";
import { getAPIToken } from "./billingService";
import { HumanMessage, NewsfeedSummary } from "../types/copilotDataTypes";
import { ChatHistory, ChatMessage } from "../types/chatHistory";

export const testCoPilotService = async (handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_COPILOTURL
    const url = baseURL + 'hello';
    return axios.get<any>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getNewsFeedSummary = async (message: HumanMessage, handleAPICallError: (error: any) => void) : Promise<NewsfeedSummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_COPILOTURL
    const url = baseURL + 'newsFeedChat';
    return axios.put<NewsfeedSummary>(url, message, requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const createNewChatHistory = async (history: ChatHistory, handleAPICallError: (error: any) => void) : Promise<ChatHistory> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_COPILOTURL
    const url = baseURL + 'chat';
    return axios.post<ChatHistory>(url, history, requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getChatResponse = async (historyID: string, chatMessage: ChatMessage, handleAPICallError: (error: any) => void) : Promise<ChatMessage> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_COPILOTURL
    const url = baseURL + 'chat/' + historyID + '/messageQuery';

    return axios.put<ChatMessage>(url, chatMessage, requestHeader)
        .then(response => {
            if(response.status < 300) {
                console.log(response.data);
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            console.log("returning null");
            return null;
          });
}

export const getChatHistory = async (historyID: string, handleAPICallError: (error: any) => void) : Promise<ChatHistory> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_COPILOTURL
    const url = baseURL + 'chat/' + historyID;

    return axios.get<ChatHistory>(url, requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getMyChatHistories = async (handleAPICallError: (error: any) => void) : Promise<ChatHistory[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_COPILOTURL
    const url = baseURL + 'myChats';

    return axios.get<ChatHistory[]>(url, requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}