import { Block, DefaultButton } from "@medforall/medforall-component-library";
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { startWithZero } from "../../hooks/useFormats";
import { Label } from "../forms/formFields"
import { Div } from "../utilities/Helpers";
import { IconWrapper } from "./CalendarUtils";
export let date = new Date();
export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const daysAbbrev = ["S", "M", "T", "W", "R", "F", "S"];
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const currentMonth = new Date().getMonth() + 1;

export const currentDay = Number(date.getDate());
export const getMonthName = (monthNumber = date.getMonth(), offset = 0) => months[Number(monthNumber) + Number(offset)];
export const currentYear = Number(date.getFullYear());
export const getDaysInMonth = (month, year) => new Date(Number(year), Number(month), 0).getDate();
export const getNumOfDayOfWeek = (month, day, year) => {
	const temp = new Date(`${month} ${day}, ${year}`);
	return temp.getDay();
}
export const getDayName = (day, abbrev = false) => (abbrev) ? daysAbbrev[Number(day)] : days[Number(day)];


const SelectorButton = props => {
	const attributes = {
		...props,
		className: null,
		children: null
	}

	return <>
		<button { ...attributes } className={`tw-border-none tw-outline-none tw-flex-grow tw-basis-1/2 tw-flex tw-items-center tw-justify-center`}>
			{ props.children }
		</button>
	</>
}

const ArrowButton = props => {
	const attributes = {
		...props,
		className: null,
		children: null
	}

	return <>
		<button { ...attributes } className={`tw-border-none tw-outline-none tw-w-30p tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center ${ props.className }`}>
			{ props.children }
		</button>
	</>
}

const CalenderExpand = props => {
	const [month, setMonth] = useState(props.monthValue);
	const [year, setYear] = useState(props.yearValue);
	const [currentDay, setCurrentDay] = useState(props.dayValue);
	const [currentMonth, setCurrentMonth] = useState(props.monthValue);
	const [currentYear, setCurrentYear] = useState(props.yearValue);
	const [daysOfMonth, setDaysOfMonth] = useState<any>(props.daysInMonth || '');
	const [firstDayOfMonth, setFirstDayOfMonth] = useState<any>('');

	const attributes = {
		...props,
		className: null
	}

	const isToday = (dateNumber: number) => {
		if (month === currentMonth && year === currentYear && dateNumber === Number(currentDay)) {
			return true;
		}
	}

	useLayoutEffect(() => {
		if (month && year) {
			setFirstDayOfMonth( getNumOfDayOfWeek(getMonthName(Number(month - 1)), "1", year)  );
			setDaysOfMonth( getDaysInMonth(month.toString(), year.toString()) )
		}
	}, [month, year])

	useLayoutEffect(() => {
		setMonth(props.monthValue);
		setYear(props.yearValue);
	}, [props.monthValue, props.yearValue])

	return daysOfMonth ? <>
		<div className="tw-p-10p">
			<div className="tw-grid tw-grid-cols-7">
				<span className="tw-flex tw-items-center tw-justify-center" title="Sunday">
					S
				</span>
				<span className="tw-flex tw-items-center tw-justify-center" title="Monday">
					M
				</span>
				<span className="tw-flex tw-items-center tw-justify-center" title="Tuesday">
					T
				</span>
				<span className="tw-flex tw-items-center tw-justify-center" title="Wednesday">
					W
				</span>
				<span className="tw-flex tw-items-center tw-justify-center" title="Thursday">
					R
				</span>
				<span className="tw-flex tw-items-center tw-justify-center" title="Friday">
					F
				</span>
				<span className="tw-flex tw-items-center tw-justify-center" title="Saturday">
					S
				</span>
			</div>
			<Div className="tw-grid tw-grid-cols-7 tw-min-h-px-rem" style={{'--px': ( 30 * 6) }}>
				{ [...Array(firstDayOfMonth)].map((item, index) => <div key={ 'grid_day-block-' + index } className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-w-30px tw-min-h-30px" /> ) }
				{ [...Array(daysOfMonth)].map((item, index) => {
					const dateTemp = index + 1;
					return <div onClick={ e => {
						e.preventDefault();
						// console.log("Date Selected: ", dateTemp);
						// console.log("Current Day: ", props.dayValue);
						// if (props.dayValue === startWithZero(dateTemp)) {
						// 	console.log("Same Day");
						// 	props.setDayValue(null);
						// }
						props.setDayValue(startWithZero(dateTemp));
						props.setCalendar(false);
						props.setMonth(false);
						props.setYear(false);
						props.setmodal(null);
					}} key={ 'grid_day-' + index } role="button" title={`${ getDayName(getNumOfDayOfWeek(getMonthName(Number(month - 1)), Number(dateTemp), year)) }, ${ getMonthName(Number(month - 1)) } ${ Number(dateTemp) }, ${ year }`} className="tw-group tw-text-4 tw-relative tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-w-30px tw-min-h-30px">
						{/*
							<Block className="tw-absolute tw-left-1px tw-top-1px tw-text-[.5em] tw-text-light-dark">
								{ getDayName(getNumOfDayOfWeek(getMonthName(Number(month - 1)), Number(dateTemp), year), true) }
							</Block>
						*/}
						<Block className={`tw-text-[.75em] ${isToday(dateTemp) ? "tw-bg-primary tw-text-white": "tw-bg-transparent tw-text-dark"} group-hover:tw-bg-primary group-hover:tw-text-white tw-leading-none tw-aspect-square  tw-rounded-full tw-w-25p tw-items-center tw-justify-center tw-flex`}>
							{ dateTemp }
						</Block>
					</div>
				}) }
			</Div>
		</div>
	</> : <>
		loading...
	</>
}

const ButtonOption = props => {
	const attributes = {
		...props,
		className: null
	}

	return <>
		<DefaultButton { ...attributes } className={`tw-w-full tw-bg-white hover:tw-bg-primary hover:tw-text-white`}>
			{ props.children }
		</DefaultButton>
	</>
}

const MonthExpand = props => {
	return <>
		<Div className="tw-flex tw-flex-col tw-max-h-px-rem tw-overflow-auto tw-snap-y u-scrollbar" style={{ '--px': '162' }}>
			{ months.map((month, index) => {
				// const month = months[date.getMonth()];
				return <ButtonOption className="tw-snap-center" key={`months_${month}_${index}`} onClick={ e => {
					e.preventDefault();

					props.setMonthValue(startWithZero(index + 1));

					// show calendar toggle:
					props.setMonth(false);
					props.setCalendar(true);
					props.setModal('calendar');
				}}>
					{ month }
				</ButtonOption>
			}) }
		</Div>
	</>
}


const YearExpand = props => {
	const [amountOfYearsToShow, setAmountOfYearsToShow] = useState(12);
	const [yearRange, setYearRange] = useState(currentYear);

	useLayoutEffect(() => {
		if (props.amountOfYearsToShow) setAmountOfYearsToShow(props.amountOfYearsToShow)
	}, [props.amountOfYearsToShow])

	useLayoutEffect(() => {
		if (props.yearValue) setYearRange(props.yearValue);
	}, [props.yearValue])

	useEffect(() => {
		if (props.yearValue) setYearRange(props.yearValue);
	}, [props.yearValue])


	return <>
		<Div className="tw-flex tw-flex-col tw-max-h-px-rem tw-overflow-auto tw-snap-y u-scrollbar" style={{ '--px': '162' }}>

			<ButtonOption className="tw-snap-center" onClick={ e => {
				setAmountOfYearsToShow(10);
				console.log();
				const tempYear = Number(yearRange) - 10;
				setYearRange(tempYear);
				e.preventDefault();
			}}>
				<span className="tw-text-[.6em]">Show Past 10</span>
			</ButtonOption>

			{ [...Array(amountOfYearsToShow)].map((item, index) => {
				const year = yearRange + index;
				return <>
					<ButtonOption className="tw-snap-center" key={`year_${year}_${index}`} onClick={ e => {
						e.preventDefault();

						props.setYearValue(year);

						// show calendar toggle:
						props.setYear(false);
						props.setCalendar(true);
						props.setModal('calendar');
					}}>
						{ Number(year) }
					</ButtonOption>
				</>
			}) }

			<ButtonOption className="tw-snap-center" onClick={ e => {
				setAmountOfYearsToShow(10);
				console.log();
				const tempYear = Number(yearRange) + 10;
				setYearRange(tempYear);
				e.preventDefault();
			}}>
				<span className="tw-text-[.6em]">Show Next 10</span>
			</ButtonOption>
		</Div>
	</>
}



const CalendarSelector = forwardRef((props: any, ref: any): any => {
	const inputValueRef = useRef<HTMLInputElement>();
	const outputValueRef = useRef<HTMLInputElement>();
	const [value, setValue] = useState<any>('');
	const [inputValue, setInputValue] = useState<any>('');
	const [modal, setmodal] = useState<any>(null);
	const [calender, setCalendar] = useState<boolean>(false);
	const [month, setMonth] = useState<boolean>(false);
	const [year, setYear] = useState<boolean>(false);
	const [validity, setValidity] = useState<boolean>(true);

	const [monthValue, setMonthValue] = useState<any>(startWithZero(currentMonth));
	//const [monthValue, setMonthValue] = useState<any>(null);
	const [monthName, setMonthName] = useState(getMonthName());
	//const [monthName, setMonthName] = useState(null);
	const [dayNameValue, setdayNameValue] = useState<any>('Select a date');
	const [daysInMonth, setDaysInMonth] = useState<any>(startWithZero(currentDay));
	const [dayValue, setDayValue] = useState<any>(startWithZero(currentDay));
	//const [dayValue, setDayValue] = useState<any>(null);
	const [yearValue, setYearValue] = useState<any>(currentYear);
	//const [yearValue, setYearValue] = useState<any>(null);

	// State to track if the input has been touched
	const [isTouched, setIsTouched] = useState(false);

	const handleFocus = () => {
        if (!isTouched) {
            console.log('Input was touched/focused for the first time.');
            setIsTouched(true); // Update state to indicate the input has been touched
        }
    };

	const baseClasses = `tw-text-4-alt tw-border  tw-border-primary tw-rounded-3p tw-overflow-clip tw-bg-white ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const [fieldClasses, setFieldClasses] = useState(baseClasses);

	useEffect(() => {
		setFieldClasses(`tw-text-4-alt tw-border  tw-border-primary tw-rounded-3p tw-overflow-clip tw-bg-white ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`);
	}, [props.className]);

	const attributes = {
		...props,
		className: null,
		placeholder: null,
		required: null,
		id: null,
		name: null,
		type: null
	}

	useEffect(() => {
		console.log("Day Value: ", dayValue);
	}, [dayValue])

	const toggleOpen = (e, type) => {

		if (type === 'calendar' ) {
			let temp = calender;
			setCalendar(temp = !temp);
			setMonth(false);
			setYear(false);
			setmodal('calendar');
		}

		if (type === 'month' ) {
			let temp = month;
			setCalendar(false);
			setMonth(temp = !temp);
			setYear(false);
			setmodal('month');
		}

		if (type === 'year' ) {
			let temp = year;
			setCalendar(false);
			setMonth(false);
			setYear(temp = !temp);
			setmodal('year');
		}

		if (!type) {
			setCalendar(false);
			setMonth(false);
			setYear(false);
			setmodal(null);
		}

		e.preventDefault();
	}

	const parentProps = {
		...toggleOpen,
		value, setValue,
		modal, setmodal,
		calender, setCalendar,
		dayValue, setDayValue,
		month, setMonth,
		monthName, setMonthName,
		monthValue, setMonthValue,
		daysInMonth, setDaysInMonth,
		year, setYear,
		yearValue, setYearValue
	}

	useEffect(() => {
		// if (value && value !== inputValue && isTouched) {
		if (value && isTouched) {
			setInputValue(`${yearValue}-${startWithZero(monthValue)}-${startWithZero(dayValue)}`);
			setdayNameValue(getDayName(getNumOfDayOfWeek(getMonthName(Number(monthValue) - 1), Number(dayValue), yearValue)) + ", " + getMonthName(Number(monthValue - 1)) + " " + dayValue + ", " + yearValue);
			setValidity(true);
		}
	}, [value])

	useLayoutEffect(() => {
		console.log("Month, Day or Year Value: ", monthValue, dayValue, yearValue);
		if(monthValue && dayValue && yearValue){
			setValue(`${monthValue}-${dayValue}-${yearValue}`);
			// setdayNameValue(getDayName(getNumOfDayOfWeek(getMonthName(Number(monthValue - 1)), Number(dayValue), yearValue)));
		}
		
	}, [monthValue, dayValue, yearValue])

	useEffect(() => {
		console.log("Month, Day or Year Value: ", monthValue, dayValue, yearValue);
		if(monthValue && dayValue && yearValue){
			setValue(`${monthValue}-${dayValue}-${yearValue}`);
			//setdayNameValue(getDayName(getNumOfDayOfWeek(getMonthName(Number(monthValue - 1)), Number(dayValue), yearValue)));
			if (dayValue.length > 31) {
				setValidity(false);
			}
			if (yearValue.length > 4) {
				setValidity(false);
			}
		}
	}, [monthValue, dayValue, yearValue])


	useLayoutEffect(() => {
		if (props.value && props.value.includes('/')) {
			setIsTouched(true);

			const temp = props.value.split('/');
			setValue(props.value.replace('/','-'));

			setMonthValue(temp[0]);
			setDayValue(temp[1]);
			setYearValue(temp[2]);
		}

		if (props.value && props.value.includes('-')) {
			setIsTouched(true);

			const temp = props.value.split('-');
			setValue(props.value);

			setMonthValue(temp[0]);
			setDayValue(temp[1]);
			setYearValue(temp[2]);
		}
	}, [props.value])

	useEffect(() => {
		if (inputValue) {
			const temp = inputValue;
			console.log("New Date Selector Value: ", temp)
			let inputEvent = new Event('change', { bubbles: true });
			outputValueRef.current.dispatchEvent(inputEvent);
			if (props.onChange) props.onChange(inputEvent)
		}
	}, [inputValue, value])

	useEffect(() => {
		if (inputValue) {
			const value = inputValue.toString();
			if (value.length === 10) {
				let split = value.split('-');
				let monthTemp = Number(split[1]);
				let dayTemp = Number(split[2]);
				let yearTemp = Number(split[0]);

				if (Number(yearTemp) >= 1900) {
					setMonthValue(monthTemp);
					setDayValue(dayTemp);
					setYearValue(yearTemp);
				} else {
					setValidity(false)
				}
			}

			if (inputValue != value) {
				setValidity(true);
			}
		}
	}, [inputValue])

	// useEffect(() => {
	// 	const temp = inputValue;
	// 	let inputEvent = new Event('change', { bubbles: true });
	// 	outputValueRef.current.dispatchEvent(inputEvent);
	// 	if (props.onChange) props.onChange(inputEvent)
    // }, [inputValue]);

	return <>
		<div className={` ${ props.className }`}>
			<Label htmlFor={ props.id || null } icon="/images/icon-date.svg">
				{ props.name || "Date"}
			</Label>
			<div className={fieldClasses}>
				<div className="tw-flex tw-w-full tw-items-center tw-px-10p tw-min-h-[2.6875rem]">
					<span className="tw-flex tw-flex-grow tw-pr-5p tw-items-center tw-cursor-pointer">
						{/* { value } */}
						<span className="tw-flex tw-flex-grow tw-items-baseline">
							<input ref={ inputValueRef } type="date" value={inputValue} onFocus={handleFocus} className="tw-text-4 inline-date _reset tw-text-transparent tw-w-full Xtw-appearance-none tw-outline-none tw-border-none tw-m-0 tw-p-0 tw-leading-none" onChange={ e => {
								setInputValue(e.target.value);
								setValidity(e.target.checkValidity())
							} }/>
							{/* { monthValue } / { dayValue } / { yearValue } */}
							{/* <span className="tw-text-[.5em] tw-ml-10p">({dayNameValue})</span> */}
						</span>
					</span>
					<label className="tw-cursor-pointer tw-block tw-flex tw-items-center tw-justify-end" htmlFor={ props.id || null } onClick={ e => {
						handleFocus();
						if (modal) {
							toggleOpen(e, false);
						} else {
							toggleOpen(e, 'calendar');
						}
					 }} >
						<IconWrapper className="tw-text-black/20 tw-flex tw-items-center tw-justify-center tw-my-auto" size={ 30 }>
							<img className="tw-w-8/12 tw-aspect-square" src="/images/icon-date.svg" />
						</IconWrapper>
					</label>
				</div>
				{ modal && <>
					<div className="tw-border-x-primary tw-border-b-primary tw-rounded-b-3p tw-overflow-clip tw-bg-white">
						<div className="tw-flex">
							<div className="tw-flex tw-flex-grow tw-w-1/2">
								<ArrowButton onClick={ e => {
									e.preventDefault();
									if (Number(monthValue) > 1) {
										setMonthValue( startWithZero(Number(monthValue) - 1))
									} else if (Number(monthValue) === 1) {
										setMonthValue( startWithZero(12) )
										setYearValue( Number(yearValue) - 1 )
									}
								}} >
									&lt;
								</ArrowButton>
								<SelectorButton onClick={ e => {
									e.preventDefault();
									if (modal === 'month') {
										toggleOpen(e, 'calendar')
									} else {
										toggleOpen(e, 'month')
									}
								 }} >
									{ getMonthName(monthValue - 1) }
								</SelectorButton>

								<ArrowButton  onClick={ e => {
									e.preventDefault();
									if (Number(monthValue) < 12) {
										setMonthValue( startWithZero(Number(monthValue) + 1))
									} else if (Number(monthValue) === 12) {
										setMonthValue( startWithZero(1))
										setYearValue( Number(yearValue) + 1 )
									}
								}} >
									&gt;
								</ArrowButton>
							</div>
							<div className="tw-flex tw-flex-grow tw-w-1/2">
								<ArrowButton onClick={ e => {
									e.preventDefault();
									setYearValue( Number(yearValue) - 1)
								}} >
									&lt;
								</ArrowButton>
								<SelectorButton onClick={ e => {
									e.preventDefault();
									if (modal === 'year') {
										toggleOpen(e, 'calendar')
									} else {
										toggleOpen(e, 'year')
									}
								 }} >
								 	{ yearValue }
								</SelectorButton>
								<ArrowButton onClick={ e => {
									e.preventDefault();
									setYearValue(Number(yearValue) + 1)
								}} >
									&gt;
								</ArrowButton>
							</div>
						</div>
						{ calender && <CalenderExpand { ...parentProps } /> }
						{ month && <MonthExpand { ...parentProps } />  }
						{ year && <YearExpand { ...parentProps } /> }
					</div>
				</> }
				<input type="hidden" value={ value } ref={ outputValueRef } id={ props.id || null } name={ props.name || null } required={ props.required || null } placeholder={ props.placeholder || null }  min={ props.min || "0" } max={ props.max || "60" } />
			</div>
			<Label htmlFor={ props.id || null }>
				<Block className="tw-text-4 tw-px-10p tw-pt-4p">
					<Block className="tw-text-[.75em] tw-text-dark/70">
						{ validity ? <>
						    {dayNameValue}
							{/* { getDayName(getNumOfDayOfWeek(getMonthName(Number(monthValue) - 1), Number(dayValue), yearValue)) }, {` `}
							{ getMonthName(Number(monthValue - 1)) }{` `}
							{ dayValue },{` `}
							{ yearValue } */}
						</> : <>
							<span className="tw-text-error">Please select a valid date</span>
						</>}
					</Block>
				</Block>
			</Label>
		</div>
	</>
});

export default CalendarSelector;


// https://www.cssscript.com/es6-calendar-rolyart/