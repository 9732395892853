import { useEffect, useState } from "react";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { ActionStep, DesiredOutcome, ExpectedOutcome } from "../../types/servicePlan";
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from "formik";
import { ActionStepSchema, DesiredOutcomeSchema, ExpectedOutcomeSchema } from "../forms/newDesiredOutcomeSection";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useEditPlanContext } from "../../pages/EditServicePlanPage";
import { addDesiredOutcome, deleteDesiredOutcome, getDesiredOutcomeByID, updateDesiredOutcome } from "../../apicalls/servicePlanService";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useAppContext } from "../../Context";
import { LoadingSpinner } from "./loadingSpinner";
import { InfoAlert } from "./infoAlert";
import { arraysAreEqual } from "./editSupportAreaCard";

export const ViewDesiredOutcomeSection = props => {
    return (<>
        <div className="tw-pb-2 tw-flex">
            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">{props?.outcome.outcomeTitle}</span>
            {props?.showEdit && <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={() => props?.onEdit(props?.outcome.desiredOutcomeID)}>Edit</span>}
        </div>
        <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900">Details: {props?.outcome.outcomeDetails}</span>
        <div></div>
        <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900">Progress: {props?.outcome.outcomeProgress}</span>
        <div></div>
        <span className="tw-text-xs tw-underline tw-font-semibold tw-leading-6 tw-text-gray-900">Action Steps:</span>
        <div></div>
        {props?.outcome.actionSteps.length > 0 && props?.outcome.actionSteps.map((step, index) => (
                    <div>
                    <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900">{step.actionStepTitle}</span>
                    <div></div>
                    <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900">Details: {step.actionStepDetails}</span>
                    <div></div>

                    
                    <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                                    <div className="tw-col-span-1 tw-flex tw-items-center">
                                        <div className="tw-flex-shrink-0 tw-mx-4">
                                            <img src="/images/hour-glass.svg" className="tw-fill-blue-500"></img>
                                        </div>
                                        <div className="tw-flex-grow">
                                            <span className="tw-text-xs tw-font-light tw-leading-1 tw-text-grey-dark">Frequency</span>
                                            <div></div>
                                            <span className="tw-text-xs tw-font-semibold tw-leading-1 tw-text-gray-900">{step.frequency}</span>
                                        </div>
                                    </div>

                                    <div className="tw-col-span-1 tw-flex tw-items-center">
                                        <div className="tw-flex-shrink-0 tw-mx-4">
                                            <img src="/images/umbrella.svg" className="tw-fill-blue-500"></img>
                                        </div>
                                        <div className="tw-flex-grow">
                                            <span className="tw-text-xs tw-font-light tw-leading-1 tw-text-grey-dark">Responsibility</span>
                                            <div></div>
                                            <span className="tw-text-xs tw-font-semibold tw-leading-1 tw-text-gray-900">{step.responsibleParty}</span>
                                        </div>
                                    </div>
                    </div>
                    </div>
                ))}

        <span className="tw-text-xs tw-underline tw-font-semibold tw-leading-6 tw-text-gray-900">Expected Outcomes:</span>
        <div></div>

        {props?.outcome.expectedOutcomes.length > 0 && props?.outcome.expectedOutcomes.map((expectedOutcome, index) => (
            <>
                <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900">{expectedOutcome.expectedOutcomeDescription}</span>
                <div></div>
            </>
            ))}
    </>);
}

const EditDesiredOutcomeSection = props => {
    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const [loading, setLoading] = useState(false);
    const [showActionStepInfoMessage, setShowActionStepInfoMessage] = useState(false);
    const [stepInfoMessage, setStepInfoMessage] = useState("");

    const [showOutcomeInfoMessage, setShowOutcomeInfoMessage] = useState(false);
    const [outcomeInfoMessage, setOutcomeInfoMessage] = useState("");

    const [outcomeDirty, setOutcomeDirty] = useState(false);
    const [originalOutcome, setOriginalOutcome] = useState<DesiredOutcome>(null);

    const [showActionStepCard, setShowActionStepCard] = useState(false);
    const [showExpectedOutcomeCard, setShowExpectedOutcomeCard] = useState(false);
    const [outcomeSteps, setOutcomeSteps] = useState<ActionStep[]>(props?.outcome.actionSteps);

    const initialFormValues = {
        outcomeTitle: props?.outcome.outcomeTitle,
        outcomeDetails: props?.outcome.outcomeDetails,
        outcomeProgress: props?.outcome.outcomeProgress,
        actionSteps: props?.outcome.actionSteps.map((step) => {
            return {
                stepTitle: step.actionStepTitle,
                stepDetails: step.actionStepDetails,
                frequency: step.frequency,
                responsibleParty: step.responsibleParty,
            };
        }),
        expectedOutcomes: props?.outcome.expectedOutcomes,
    }

    useEffect(() => {
        console.log(props);

        const fetchOutcome = async () => {
            const outcome = await getDesiredOutcomeByID(props?.outcome.desiredOutcomeID, appContext.handleAPICallError);
            console.log(outcome);
            setOriginalOutcome(outcome);
        }

        if(props?.editPlan){
            fetchOutcome();
        } else {
            setOriginalOutcome(JSON.parse(JSON.stringify(props?.outcome)));
        }

        

    },[]);

    useEffect(() => {
        console.log("Outcome steps changed");
        console.log(outcomeSteps);
    }, [outcomeSteps]);

    const desiredOutcomeFormik = useFormik({
        initialValues: initialFormValues,
        validationSchema: DesiredOutcomeSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted")
            console.log(values);

            var newSteps: ActionStep [] = [];
            // outcomeSteps.forEach((step) => {
            //     newSteps.push({
            //         actionStepTitle: step.actionStepTitle,
            //         actionStepDetails: step.actionStepDetails,
            //         frequency: step.frequency,
            //         responsibleParty: step.responsibleParty,
            //         actionStepID: uuidv4(),
            //         desiredOutcomeID: ""
            //     });
            // });
            values.actionSteps.forEach((step) => {
                newSteps.push({
                    actionStepTitle: step.actionStepTitle,
                    actionStepDetails: step.actionStepDetails,
                    frequency: step.frequency,
                    responsibleParty: step.responsibleParty,
                    actionStepID: uuidv4(),
                    desiredOutcomeID: ""
                });
            });

            var newOutcomes: any [] = [];
            values.expectedOutcomes.forEach((outcome) => {
                newOutcomes.push({
                    expectedOutcomeDescription: outcome.expectedOutcomeDescription,
                    expectedOutcomeID: uuidv4(),
                    desiredOutcomeID: ""
                });
            });

            var newDesiredOutcome: DesiredOutcome = {
                outcomeTitle: values.outcomeTitle,
                outcomeDetails: values.outcomeDetails,
                outcomeProgress: values.outcomeProgress,
                desiredOutcomeID: props?.outcome.desiredOutcomeID,
                actionSteps: newSteps,
                expectedOutcomes: newOutcomes,
                servicePlanID: ""
            }

            console.log(newDesiredOutcome);

            props?.onSave(props?.outcome.desiredOutcomeID, newDesiredOutcome);
        },
    });

    const actionStepFormik = useFormik({
        initialValues: {
            stepTitle: '',
            stepDetails: '',
            frequency: '',
            responsibleParty: '',
        },
        validationSchema: ActionStepSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            const newStep: ActionStep = {
                actionStepTitle: values.stepTitle,
                actionStepDetails: values.stepDetails,
                frequency: values.frequency,
                responsibleParty: values.responsibleParty,
                actionStepID: uuidv4(),
                desiredOutcomeID: ""
            }
            desiredOutcomeFormik.values.actionSteps.push(values);
            props?.outcome.actionSteps.push(newStep);
            desiredOutcomeFormik.validateField('actionSteps');

            if(!arraysAreEqual(originalOutcome.actionSteps, desiredOutcomeFormik.values.actionSteps)){
                setOutcomeDirty(true);
            }
            setShowActionStepInfoMessage(true);
            setStepInfoMessage("New action step added to the list. Press save to keep your changes.");
            setTimeout(() => {
                setShowActionStepInfoMessage(false);
            }, 5000);
            
            toggleActionStepCard();
        },
    });

    const expectedOutcomeFormik = useFormik({
        initialValues: {
            expectedOutcomeDescription: '',
        },
        validationSchema: ExpectedOutcomeSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);

            const newOutcome: ExpectedOutcome = {
                expectedOutcomeDescription: values.expectedOutcomeDescription,
                expectedOutcomeID: uuidv4(),
                desiredOutcomeID: ""
            }
            
            desiredOutcomeFormik.values.expectedOutcomes.push(newOutcome);
            desiredOutcomeFormik.validateField('expectedOutcomes');

            if(!arraysAreEqual(originalOutcome.expectedOutcomes, desiredOutcomeFormik.values.expectedOutcomes)){
                setOutcomeDirty(true);
            }
            if(!props?.editPlan){
                setOutcomeDirty(true);
            }
            setShowOutcomeInfoMessage(true);
            setOutcomeInfoMessage("New expected outcome added to the list. Press save to keep your changes.");
            setTimeout(() => {
                setShowOutcomeInfoMessage(false);
            }, 5000);
            

            expectedOutcomeFormik.resetForm();
            toggleExpectedOutcomeCard();
        },
    });


    const toggleActionStepCard = () => {
        actionStepFormik.resetForm();
        setShowActionStepCard(!showActionStepCard);
    }

    const handleUpdateStepsList = (steps) => {
        //console.log(steps);

        var mappedSteps = steps.map((step) => {
            return {
                stepTitle: step.actionStepTitle,
                stepDetails: step.actionStepDetails,
                frequency: step.frequency,
                responsibleParty: step.responsibleParty,
            };
        });
        //desiredOutcomeFormik.values.actionSteps = mappedSteps;
        //desiredOutcomeFormik.validateField('expectedOutcomes');
        desiredOutcomeFormik.setFieldValue('actionSteps', mappedSteps, true);

        //setOutcomeSteps(steps);
        // desiredOutcomeFormik.validateField('expectedOutcomes');
    }

    const toggleExpectedOutcomeCard = () => {
        expectedOutcomeFormik.resetForm();
        setShowExpectedOutcomeCard(!showExpectedOutcomeCard);
    }

    const handleExpectedOutcomesList = (outcomes) => {
        desiredOutcomeFormik.setFieldValue('expectedOutcomes', outcomes, true);
        // desiredOutcomeFormik.values.expectedOutcomes = outcomes;
        // desiredOutcomeFormik.validateField('expectedOutcomes');
    }

    const handleRemoveDesiredOutcome = () => {
        if(props?.editPlan){
            setShowConfirmDelete(true);
        } else {
            const index = planContext.planDesiredOutcomes.indexOf(props?.outcome);
            const newItems = [...planContext.planDesiredOutcomes.slice(0, index), ...planContext.planDesiredOutcomes.slice(index + 1)];
            planContext.setPlanDesiredOutcomes(newItems);
        }
    }

    const deletePlanDesiredOutcome = async () => {
        const index = editPlanContext.planDesiredOutcomes.indexOf(props?.outcome);
        const newItems = [...editPlanContext.planDesiredOutcomes.slice(0, index), ...editPlanContext.planDesiredOutcomes.slice(index + 1)];
        editPlanContext.setPlanDesiredOutcomes(newItems);
        var result = await deleteDesiredOutcome(editPlanContext.servicePlan.servicePlanID, props?.outcome.desiredOutcomeID, appContext.handleAPICallError);
        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Desired Outcome deleted successfully.");
            appContext.setShowSuccessModal(true);
        }
        
        setShowConfirmDelete(false);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const discardChanges = () => {
        if(props?.editPlan){
            desiredOutcomeFormik.resetForm();
            editPlanContext.setPlanDesiredOutcomes(editPlanContext.planDesiredOutcomes.map(
                el => el.desiredOutcomeID ===  originalOutcome.desiredOutcomeID? { ...el, ...originalOutcome } : el
            ));
        }

        props?.onCancel();
    }

    return (<>
        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Edit Desired Outcome</span>
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-title"
                                                id="outcome-title"
                                                className={`tw-block tw-w-full tw-rounded-md ${desiredOutcomeFormik.touched.outcomeTitle && desiredOutcomeFormik.errors.outcomeTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...desiredOutcomeFormik.getFieldProps('outcomeTitle')}
                                                />
                                                {desiredOutcomeFormik.touched.outcomeTitle && desiredOutcomeFormik.errors.outcomeTitle && <p className="tw-text-red-500"><>{desiredOutcomeFormik.errors.outcomeTitle}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Details
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-details"
                                                id="outcome-details"
                                                className={`tw-block tw-w-full tw-rounded-md ${desiredOutcomeFormik.touched.outcomeDetails && desiredOutcomeFormik.errors.outcomeDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...desiredOutcomeFormik.getFieldProps('outcomeDetails')}
                                                />
                                                {desiredOutcomeFormik.touched.outcomeDetails && desiredOutcomeFormik.errors.outcomeDetails && <p className="tw-text-red-500"><>{desiredOutcomeFormik.errors.outcomeDetails}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Progress
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-progress"
                                                id="outcome-progress"
                                                className={`tw-block tw-w-full tw-rounded-md ${desiredOutcomeFormik.touched.outcomeProgress && desiredOutcomeFormik.errors.outcomeProgress ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...desiredOutcomeFormik.getFieldProps('outcomeProgress')}
                                                />
                                                {desiredOutcomeFormik.touched.outcomeProgress && desiredOutcomeFormik.errors.outcomeProgress && <p className="tw-text-red-500"><>{desiredOutcomeFormik.errors.outcomeProgress}</></p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Action Steps</span>
                                        <PlusCircleIcon className="tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={toggleActionStepCard} />
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={toggleActionStepCard}>Add</span> */}
                                    </div>

                                    {showActionStepInfoMessage && <InfoAlert message={stepInfoMessage} />}

                                    {showActionStepCard && <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-title"
                                                    id="step-title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.stepTitle && actionStepFormik.errors.stepTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('stepTitle')}
                                                    />
                                                    {actionStepFormik.touched.stepTitle && actionStepFormik.errors.stepTitle && <p className="tw-text-red-500">{actionStepFormik.errors.stepTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-details"
                                                    id="step-details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.stepDetails && actionStepFormik.errors.stepDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('stepDetails')}
                                                    />
                                                    {actionStepFormik.touched.stepDetails && actionStepFormik.errors.stepDetails && <p className="tw-text-red-500">{actionStepFormik.errors.stepDetails}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.frequency && actionStepFormik.errors.frequency ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('frequency')}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {actionStepFormik.touched.frequency && actionStepFormik.errors.frequency && <p className="tw-text-red-500">{actionStepFormik.errors.frequency}</p>}
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.responsibleParty && actionStepFormik.errors.responsibleParty ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('responsibleParty')}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {actionStepFormik.touched.responsibleParty && actionStepFormik.errors.responsibleParty && <p className="tw-text-red-500">{actionStepFormik.errors.responsibleParty}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleActionStepCard}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={actionStepFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>}

                                    <EditActionStepList steps={props?.outcome.actionSteps} onUpdate={handleUpdateStepsList}  setInfoMessage={setStepInfoMessage} setShowInfoMessage={setShowActionStepInfoMessage}></EditActionStepList>

                                    <div>
                                        {desiredOutcomeFormik.touched.actionSteps && desiredOutcomeFormik.errors.actionSteps && <p className="tw-text-red-500"><>{desiredOutcomeFormik.errors.actionSteps}</></p>}
                                    </div>

                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Expected Outcomes</span>
                                        <PlusCircleIcon className="tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={toggleExpectedOutcomeCard} />
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={toggleExpectedOutcomeCard}>Add</span> */}
                                    </div>

                                    {showOutcomeInfoMessage && <InfoAlert message={outcomeInfoMessage} />}

                                    {showExpectedOutcomeCard && <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md ${expectedOutcomeFormik.touched.expectedOutcomeDescription && expectedOutcomeFormik.errors.expectedOutcomeDescription ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...expectedOutcomeFormik.getFieldProps('expectedOutcomeDescription')}
                                                    />
                                                    {expectedOutcomeFormik.touched.expectedOutcomeDescription && expectedOutcomeFormik.errors.expectedOutcomeDescription && <p className="tw-text-red-500">{expectedOutcomeFormik.errors.expectedOutcomeDescription}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleExpectedOutcomeCard}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={expectedOutcomeFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>}

                                    <EditExpectedOutcomeList outcomes={props?.outcome.expectedOutcomes} onUpdate={handleExpectedOutcomesList} setInfoMessage={setOutcomeInfoMessage} setShowInfoMessage={setShowOutcomeInfoMessage}></EditExpectedOutcomeList>

                                    <div>
                                        {desiredOutcomeFormik.touched.expectedOutcomes && desiredOutcomeFormik.errors.expectedOutcomes && <p className="tw-text-red-500"><>{desiredOutcomeFormik.errors.expectedOutcomes}</></p>}
                                    </div>

                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                                    {showConfirmDelete ? <>
                                    <p className="tw-text-primary">Are you sure you want to delete this desired outcome?</p>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deletePlanDesiredOutcome}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={handleRemoveDesiredOutcome}>
                                                {props?.editPlan ? 'Delete' : 'Remove'}
                                            </ButtonSecondary>
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={discardChanges}>
                                                {(desiredOutcomeFormik.dirty || outcomeDirty) ? "Discard" : "Cancel"}
                                            </ButtonSecondary>
                                            {(desiredOutcomeFormik.dirty || outcomeDirty) && <ButtonPrimary className="tw-mt-md" onClick={desiredOutcomeFormik.handleSubmit}>
                                                Update
                                            </ButtonPrimary>}

                                    </div>
                                    </>}


    </>);
}

const EditActionStepSection = props => {
    const actionStepFormik = useFormik({
        initialValues: {
            stepTitle: props?.step.actionStepTitle,
            stepDetails: props?.step.actionStepDetails,
            frequency: props?.step.frequency,
            responsibleParty: props?.step.responsibleParty,
        },
        validationSchema: ActionStepSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            const newStep: ActionStep = {
                actionStepTitle: values.stepTitle,
                actionStepDetails: values.stepDetails,
                frequency: values.frequency,
                responsibleParty: values.responsibleParty,
                actionStepID: props?.step.actionStepID,
                desiredOutcomeID: ""
            }

            props?.onSave(props?.step.actionStepID, newStep);

        },
    });
    
    return (<>
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-title"
                                                    id="step-title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.stepTitle && actionStepFormik.errors.stepTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('stepTitle')}
                                                    disabled={!props?.isEditing}
                                                    />
                                                    {actionStepFormik.touched.stepTitle && actionStepFormik.errors.stepTitle && <p className="tw-text-red-500"><>{actionStepFormik.errors.stepTitle}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-details"
                                                    id="step-details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.stepDetails && actionStepFormik.errors.stepDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('stepDetails')}
                                                    disabled={!props?.isEditing}
                                                    />
                                                    {actionStepFormik.touched.stepDetails && actionStepFormik.errors.stepDetails && <p className="tw-text-red-500"><>{actionStepFormik.errors.stepDetails}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.frequency && actionStepFormik.errors.frequency ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('frequency')}
                                                    disabled={!props?.isEditing}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {actionStepFormik.touched.frequency && actionStepFormik.errors.frequency && <p className="tw-text-red-500"><>{actionStepFormik.errors.frequency}</></p>}
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.responsibleParty && actionStepFormik.errors.responsibleParty ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('responsibleParty')}
                                                    disabled={!props?.isEditing}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {actionStepFormik.touched.responsibleParty && actionStepFormik.errors.responsibleParty && <p className="tw-text-red-500"><>{actionStepFormik.errors.responsibleParty}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                        {props?.isEditing ? <>
                                                <ButtonSecondary className="tw-mt-md" onClick={() => props?.onRemove(props?.step.actionStepID)}>
                                                            Remove
                                                    </ButtonSecondary>
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={() => props?.onCancel()}>
                                                        {actionStepFormik.dirty ? "Discard" : "Cancel"}
                                                    </ButtonSecondary>
                                                    {actionStepFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={actionStepFormik.handleSubmit}>
                                                        Update
                                                    </ButtonPrimary>}
                                            </>: <>
                                                    <ButtonPrimary className="tw-mt-md" onClick={() => props?.onEdit(props?.step.actionStepID)}>
                                                        Edit
                                                    </ButtonPrimary>
                                                
                                            </>}

                                        </div>
    </>);
}

const EditExpectedOutcomeSection = props => {
    const expectedOutcomeFormik = useFormik({
        initialValues: {
            expectedOutcomeDescription: props?.outcome.expectedOutcomeDescription,
        },
        validationSchema: ExpectedOutcomeSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);

            const newOutcome: ExpectedOutcome = {
                expectedOutcomeDescription: values.expectedOutcomeDescription,
                expectedOutcomeID: props?.outcome.expectedOutcomeID,
                desiredOutcomeID: ""
            }

            props?.onSave(props?.outcome.expectedOutcomeID, newOutcome);
        },
    });
    
    return (<>
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md ${expectedOutcomeFormik.touched.expectedOutcomeDescription && expectedOutcomeFormik.errors.expectedOutcomeDescription ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...expectedOutcomeFormik.getFieldProps('expectedOutcomeDescription')}
                                                    disabled={!props?.isEditing}
                                                    />
                                                    {expectedOutcomeFormik.touched.expectedOutcomeDescription && expectedOutcomeFormik.errors.expectedOutcomeDescription && <p className="tw-text-red-500"><>{expectedOutcomeFormik.errors.expectedOutcomeDescription}</></p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                        {props?.isEditing ? <>
                                                <ButtonSecondary className="tw-mt-md" onClick={() => props?.onRemove(props?.outcome.expectedOutcomeID)}>
                                                            Remove
                                                    </ButtonSecondary>
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={() => props?.onCancel()}>
                                                        {expectedOutcomeFormik.dirty ? "Discard" : "Cancel"}
                                                    </ButtonSecondary>
                                                    {expectedOutcomeFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={expectedOutcomeFormik.handleSubmit}>
                                                        Update
                                                    </ButtonPrimary>}
                                            </>: <>
                                                    <ButtonPrimary className="tw-mt-md" onClick={() => props?.onEdit(props?.outcome.expectedOutcomeID)}>
                                                        Edit
                                                    </ButtonPrimary>
                                                
                                            </>}

                                        </div>
    </>);
}

const EditActionStepList = props => {
    const [editStepId, setEditStepId] = useState<string | null>(null);
    const [steps, setSteps] = useState<ActionStep[]>(props?.steps);

    useEffect(() => {
        setSteps(props?.steps);
        console.log("Steps updated in EditActionStepList");
    }, [props?.steps]);

    function handleStepEdit(id: string) {
        setEditStepId(id);
    }

    function handleStepSave(id: string, updatedStep: ActionStep) {
        console.log(updatedStep);

        const updatedSteps = steps.map(
            el => el.actionStepID ===  updatedStep.actionStepID? { ...el, ...updatedStep } : el
        );

        setSteps(updatedSteps);

        //console.log(updatedSteps);

        props?.onUpdate(updatedSteps);

        props?.setInfoMessage("Action Step Updated. Save desired outcome to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);

        setEditStepId(null);
    }

    function handleStepCancel() {
        // Reset the edit ID
        setEditStepId(null);
    }

    function handleRemoveStep(id: string) {
        const updatedSteps = steps.filter((step) => step.actionStepID !== id);
        setSteps(updatedSteps);
        props?.onUpdate(updatedSteps);

        props?.setInfoMessage("Action Step Removed. Save desired outcome to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);
        // supportAreaFormik.values.services = services.filter((service) => service.personalServiceID !== id);
        // supportAreaFormik.validateField('services');
    }

    return (<>
         {steps.length > 0 && steps.map((step) => (
                                        <div className="tw-space-y-6 tw-bg-card-green tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4" key={step.actionStepID}>
                                            <EditActionStepSection step={step} onEdit={handleStepEdit} onSave={handleStepSave} onCancel={handleStepCancel} onRemove={handleRemoveStep} isEditing={step.actionStepID === editStepId}></EditActionStepSection>
                                        </div>
        ))}
    </>);
}

const EditExpectedOutcomeList = props => {
    const [editOutcomeId, setEditOutcomeId] = useState<string | null>(null);
    const [outcomes, setOutcomes] = useState<ExpectedOutcome[]>(props?.outcomes);

    function handleOutcomeEdit(id: string) {
        setEditOutcomeId(id);
    }

    function handleOutcomeSave(id: string, updatedOutcome: ExpectedOutcome) {
        console.log(updatedOutcome);

        const updatedOutcomes = outcomes.map(
            el => el.expectedOutcomeID ===  updatedOutcome.expectedOutcomeID? { ...el, ...updatedOutcome } : el
        );

        setOutcomes(updatedOutcomes);

        props?.onUpdate(updatedOutcomes);

        props?.setInfoMessage("Expected Outcome Updated. Save desired outcome to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);

        setEditOutcomeId(null);
    }

    function handleOutcomeCancel() {
        // Reset the edit ID
        setEditOutcomeId(null);
    }

    function handleRemoveOutcome(id: string) {
        const updatedOutcomes = outcomes.filter((outcome) => outcome.expectedOutcomeID !== id);
        setOutcomes(updatedOutcomes);
        props?.onUpdate(updatedOutcomes);
        // supportAreaFormik.values.outcomes = outcomes.filter((outcome) => outcome.personalServiceID !== id);
        // supportAreaFormik.validateField('outcomes');

        props?.setInfoMessage("Expected Outcome Removed. Save desired outcome to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);
    }

    return (<>
         {outcomes.length > 0 && outcomes.map((outcome, index) => (
                                        <div className="tw-space-y-6 tw-bg-card-green tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4" key={outcome.expectedOutcomeID}>
                                            <EditExpectedOutcomeSection outcome={outcome} onEdit={handleOutcomeEdit} onSave={handleOutcomeSave} onCancel={handleOutcomeCancel} onRemove={handleRemoveOutcome} isEditing={outcome.expectedOutcomeID === editOutcomeId}></EditExpectedOutcomeSection>
                                        </div>
        ))}
    </>);
}


const EditDesiredOutcomeCard = props => {
    // const [editMode, setEditMode] = useState(false);

    return (<>
        {props?.isEditing ? <EditDesiredOutcomeSection outcome={props.outcome} onEdit={props?.onEdit} onSave={props?.onSave} onCancel={props?.onCancel} editPlan={props?.editPlan}/> : <ViewDesiredOutcomeSection outcome={props.outcome} onEdit={props?.onEdit} showEdit={true}/>}
    </>);
}

export const EditDesiredOutcomeList = props => {
    const [editId, setEditId] = useState<string | null>(null);

    useEffect(() => {
        console.log(props);
    }, []);

    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, updatedOutcome: DesiredOutcome) {
        if(props?.editPlan) {
            var result = await updateDesiredOutcome(updatedOutcome, editPlanContext.servicePlan.servicePlanID, updatedOutcome.desiredOutcomeID, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Desired Outcome updated successfully.");
                appContext.setShowSuccessModal(true);
                editPlanContext.setPlanDesiredOutcomes(editPlanContext.planDesiredOutcomes.map(
                    el => el.desiredOutcomeID ===  updatedOutcome.desiredOutcomeID? { ...el, ...updatedOutcome } : el
                ));
            }
            
        } else {
            planContext.setPlanDesiredOutcomes(planContext.planDesiredOutcomes.map(
                el => el.desiredOutcomeID ===  updatedOutcome.desiredOutcomeID? { ...el, ...updatedOutcome } : el
            ));
        }
        

        setEditId(null);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    const [showGoalCard, setShowGoalCard] = useState(false);

    const toggleGoalCard = () => {
        setShowGoalCard(!showGoalCard);
        desiredOutcomeFormik.resetForm();
        actionStepFormik.resetForm();
        expectedOutcomeFormik.resetForm();
        setLoading(false);
        setEditId(null);
    }

    const desiredOutcomeFormik = useFormik({
        initialValues: {
            outcomeTitle: '',
            outcomeDetails: '',
            outcomeProgress: '',
            actionSteps: [],
            expectedOutcomes: [],
        },
        validationSchema: DesiredOutcomeSchema,
        onSubmit: async (values) => {
            // handle form submission
            console.log("Form submitted")
            console.log(values);

            var newSteps: ActionStep [] = [];
            values.actionSteps.forEach((step) => {
                newSteps.push({
                    actionStepTitle: step.stepTitle,
                    actionStepDetails: step.stepDetails,
                    frequency: step.frequency,
                    responsibleParty: step.responsibleParty,
                    actionStepID: uuidv4(),
                    desiredOutcomeID: ""
                });
            });

            var newOutcomes: any [] = [];
            values.expectedOutcomes.forEach((outcome) => {
                newOutcomes.push({
                    expectedOutcomeDescription: outcome.expectedOutcomeDescription,
                    expectedOutcomeID: uuidv4(),
                    desiredOutcomeID: ""
                });
            });

            var newDesiredOutcome: DesiredOutcome = {
                outcomeTitle: values.outcomeTitle,
                outcomeDetails: values.outcomeDetails,
                outcomeProgress: values.outcomeProgress,
                desiredOutcomeID: uuidv4(),
                actionSteps: newSteps,
                expectedOutcomes: newOutcomes,
                servicePlanID: ""
            }

            setLoading(true);

            var result = await addDesiredOutcome(newDesiredOutcome, editPlanContext.servicePlan.servicePlanID, appContext.handleAPICallError);
            await editPlanContext.updateLocalDesiredOutcomes();
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Desired Outcome added successfully.");
                appContext.setShowSuccessModal(true);
            }
            

            desiredOutcomeFormik.resetForm();
            actionStepFormik.resetForm();
            expectedOutcomeFormik.resetForm();
            toggleGoalCard();

            desiredOutcomeFormik.setFieldValue('actionSteps', []);
            desiredOutcomeFormik.setFieldValue('expectedOutcomes', []);
        },
    });

    const actionStepFormik = useFormik({
        initialValues: {
            stepTitle: '',
            stepDetails: '',
            frequency: '',
            responsibleParty: '',
        },
        validationSchema: ActionStepSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            desiredOutcomeFormik.values.actionSteps.push(values);
            desiredOutcomeFormik.validateField('actionSteps');
            actionStepFormik.resetForm();
        },
    });

    const expectedOutcomeFormik = useFormik({
        initialValues: {
            expectedOutcomeDescription: '',
        },
        validationSchema: ExpectedOutcomeSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            desiredOutcomeFormik.values.expectedOutcomes.push(values);
            desiredOutcomeFormik.validateField('expectedOutcomes');
            expectedOutcomeFormik.resetForm();
        },
    });

    const handleRemoveExpectedOutcome = (outcome) => {
        const index = desiredOutcomeFormik.values.expectedOutcomes.indexOf(outcome);
        desiredOutcomeFormik.values.expectedOutcomes.splice(index, 1);
        desiredOutcomeFormik.setFieldValue('expectedOutcomes', desiredOutcomeFormik.values.expectedOutcomes);
    }

    const handleRemoveActionStep = (step) => {
        const index = desiredOutcomeFormik.values.actionSteps.indexOf(step);
        desiredOutcomeFormik.values.actionSteps.splice(index, 1);
        desiredOutcomeFormik.setFieldValue('actionSteps', desiredOutcomeFormik.values.actionSteps);
    }



    return (<>
        {props?.editPlan && <>
            {showGoalCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">New Desired Outcome</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-title"
                                                id="outcome-title"
                                                className={`tw-block tw-w-full tw-rounded-md ${desiredOutcomeFormik.touched.outcomeTitle && desiredOutcomeFormik.errors.outcomeTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...desiredOutcomeFormik.getFieldProps('outcomeTitle')}
                                                />
                                                {desiredOutcomeFormik.touched.outcomeTitle && desiredOutcomeFormik.errors.outcomeTitle && <p className="tw-text-red-500">{desiredOutcomeFormik.errors.outcomeTitle}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Details
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-details"
                                                id="outcome-details"
                                                className={`tw-block tw-w-full tw-rounded-md ${desiredOutcomeFormik.touched.outcomeDetails && desiredOutcomeFormik.errors.outcomeDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...desiredOutcomeFormik.getFieldProps('outcomeDetails')}
                                                />
                                                {desiredOutcomeFormik.touched.outcomeDetails && desiredOutcomeFormik.errors.outcomeDetails && <p className="tw-text-red-500">{desiredOutcomeFormik.errors.outcomeDetails}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Outcome Progress
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="outcome-progress"
                                                id="outcome-progress"
                                                className={`tw-block tw-w-full tw-rounded-md ${desiredOutcomeFormik.touched.outcomeProgress && desiredOutcomeFormik.errors.outcomeProgress ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...desiredOutcomeFormik.getFieldProps('outcomeProgress')}
                                                />
                                                {desiredOutcomeFormik.touched.outcomeProgress && desiredOutcomeFormik.errors.outcomeProgress && <p className="tw-text-red-500">{desiredOutcomeFormik.errors.outcomeProgress}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Action Steps</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-title"
                                                    id="step-title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.stepTitle && actionStepFormik.errors.stepTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('stepTitle')}
                                                    />
                                                    {actionStepFormik.touched.stepTitle && actionStepFormik.errors.stepTitle && <p className="tw-text-red-500">{actionStepFormik.errors.stepTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-details"
                                                    id="step-details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.stepDetails && actionStepFormik.errors.stepDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('stepDetails')}
                                                    />
                                                    {actionStepFormik.touched.stepDetails && actionStepFormik.errors.stepDetails && <p className="tw-text-red-500">{actionStepFormik.errors.stepDetails}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.frequency && actionStepFormik.errors.frequency ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('frequency')}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {actionStepFormik.touched.frequency && actionStepFormik.errors.frequency && <p className="tw-text-red-500">{actionStepFormik.errors.frequency}</p>}
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${actionStepFormik.touched.responsibleParty && actionStepFormik.errors.responsibleParty ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionStepFormik.getFieldProps('responsibleParty')}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {actionStepFormik.touched.responsibleParty && actionStepFormik.errors.responsibleParty && <p className="tw-text-red-500">{actionStepFormik.errors.responsibleParty}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={actionStepFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={actionStepFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>

                                    <div>
                                        {desiredOutcomeFormik.touched.actionSteps && desiredOutcomeFormik.errors.actionSteps && <p className="tw-text-red-500"><>{desiredOutcomeFormik.errors.actionSteps}</></p>}
                                    </div>

                                    {desiredOutcomeFormik.values.actionSteps.length > 0 && desiredOutcomeFormik.values.actionSteps?.map((actionStep, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 tw-mt-4 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-title"
                                                    id="step-title"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.stepTitle}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Step Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="step-details"
                                                    id="step-details"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.stepDetails}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.frequency}
                                                    disabled={true}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={actionStep.responsibleParty}
                                                    disabled={true}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveActionStep(actionStep);
                                                        }}>
                                                            Remove
                                                    </ButtonSecondary>

                                        </div>
                                    </div>
                                    ))}

                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Expected Outcomes</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md ${expectedOutcomeFormik.touched.expectedOutcomeDescription && expectedOutcomeFormik.errors.expectedOutcomeDescription ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...expectedOutcomeFormik.getFieldProps('expectedOutcomeDescription')}
                                                    />
                                                    {expectedOutcomeFormik.touched.expectedOutcomeDescription && expectedOutcomeFormik.errors.expectedOutcomeDescription && <p className="tw-text-red-500">{expectedOutcomeFormik.errors.expectedOutcomeDescription}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={expectedOutcomeFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={expectedOutcomeFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>

                                    <div>
                                        {desiredOutcomeFormik.touched.expectedOutcomes && desiredOutcomeFormik.errors.expectedOutcomes && <p className="tw-text-red-500"><>{desiredOutcomeFormik.errors.expectedOutcomes}</></p>}
                                    </div>

                                    {desiredOutcomeFormik.values.expectedOutcomes.length > 0 && desiredOutcomeFormik.values.expectedOutcomes?.map((expectedOutcome, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6 tw-mt-4">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={expectedOutcome.expectedOutcomeDescription}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveExpectedOutcome(expectedOutcome);
                                                        }}>
                                                            Remove
                                                    </ButtonSecondary>

                                        </div>
                                    </div>
                                    ))} 

                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleGoalCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={desiredOutcomeFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                    </>}
                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2 tw-mb-4">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleGoalCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Desired Outcome</span>
                                            </div>  
                                    </div>
                            </div>
            </>}
        </>}
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
            {props?.outcomes.length > 0 && props?.outcomes.map((outcome, index) => (
                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow" key={outcome.desiredOutcomeID}>
                    <EditDesiredOutcomeCard outcome={outcome} onEdit={handleEdit} onSave={handleSave} onCancel={handleCancel} isEditing={outcome.desiredOutcomeID === editId} editPlan={props?.editPlan}></EditDesiredOutcomeCard>
                </div>
            ))}
        </div>
    </>);
}

export default EditDesiredOutcomeCard;