import supportAreaIcon from '../../../public/images/Support-Area.png'

export const SupportAreaIcon = () => {
    return (
      <div>
        <img src="/images/Support-Area.png" className="tw-w-6 tw-h-6" alt="Support Area Icon" />
      </div>
    );
};

export const TaskAreaIcon = () => {
  return (
    <div>
      <img src="/images/Task-Icon.png" className="tw-w-6 tw-h-6" alt="Support Area Icon" />
    </div>
  );
};

export const RoutineIcon = () => {
  return (
    <div>
      <img src="/images/Routine-Icon.png" className="tw-w-6 tw-h-6" alt="Support Area Icon" />
    </div>
  );
};

export const GoalIcon = () => {
  return (
    <div>
      <img src="/images/Goal-Icon.png" className="tw-w-6 tw-h-6" alt="Support Area Icon" />
    </div>
  );
};

export const SuggestedActionIcon = () => {
  return (
    <div>
      <img src="/images/Suggested-Action-Icon.png" className="tw-w-6 tw-h-6" alt="Support Area Icon" />
    </div>
  );
};

export const UnusualEventIcon = () => {
  return (
    <div>
      <img src="/images/Unusual-Event-Icon.png" className="tw-w-6 tw-h-6" alt="Support Area Icon" />
    </div>
  );
};
  