import axios, { AxiosRequestConfig } from "axios";
import { ProviderAttempt, Training, TrainingCertification, TrainingStatus, TrainingUploadFile } from "../types/training";
import { getAPIToken } from "./billingService";

import { BlobServiceClient,BlockBlobParallelUploadOptions } from "@azure/storage-blob";
import Employee from "../types/employee";

export async function uploadFileToAzureBlobStorage(fileName: string, fileContentBase64: string): Promise<string> {
    // Decode base64 string to get file content
    // Extract base64 content from the string
    const parts: string[] = fileContentBase64.split(";base64,");
    //console.log(parts);
    //console.log(parts.length);
    if (parts.length !== 2) {
        throw new Error("Invalid base64 data format: Missing ';base64,' separator");
    }
    const base64String: string = parts[1];

    const fileContent: Uint8Array = base64ToUint8Array(base64String);

     // Get Azure Blob Storage connection string
    const connectionString = "DefaultEndpointsProtocol=https;AccountName=oahportalstorageaccount;AccountKey=YLjNlK55Tjnc+BQJ692XVMW85SeRG0S7vmzDsFyuzHSGzR0m7fcqve/kbdnKAktWQSwosFaOa7ZNpLE6DLxjAQ==;EndpointSuffix=core.windows.net"; // Your method to get the connection string

     // Create a BlobServiceClient object using the connection string
    const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);

    const container = "training-demo";

    // Get a container client
    const containerClient = blobServiceClient.getContainerClient(container);

    // Create a blockBlobClient for the file
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    var contentType = "application/pdf";

    if (fileName.endsWith(".mp4")) {
        contentType = "video/mp4";
    }

    // Set HTTP headers
    const options: BlockBlobParallelUploadOptions = {
        blobHTTPHeaders: {
            "blobContentType": contentType,
        }
    };

    // Upload file content to the block blob with HTTP headers
    await blockBlobClient.uploadData(fileContent, options);

    // Return the URL of the uploaded blob
    return blockBlobClient.url;
}

export const uploadTrainingFilesToBlob = async (uploadFile: any, handleAPICallError: (error: any) => void) => {
    const route = 'https://oah-website-form.azurewebsites.net/api/Training';

    return await axios.post(route, uploadFile)
        .then(response => response)
        .catch(async error => {
            console.error('There was an error!', error);
            handleAPICallError(error);
            return null;
        });
}

function base64ToUint8Array(base64String: string): Uint8Array {
    const binaryString = atob(base64String);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

export const saveNewTraining = async (training: Training, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings';

    return axios.post<any>(url, training, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const uploadTrainingFileToBlob = async (file: TrainingUploadFile, handleAPICallError: (error: any) => void) : Promise<string> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/file';

    return axios.post<any>(url, file, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getActiveClientTrainingsByPageNumber = async (pageNumber: number, handleAPICallError: (error: any) => void) : Promise<Training[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/active/' + pageNumber;

    return axios.get<Training[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getDraftClientTrainingsByPageNumber = async (pageNumber: number, handleAPICallError: (error: any) => void) : Promise<Training[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/draft/' + pageNumber;

    return axios.get<Training[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getArchiveClientTrainingsByPageNumber = async (pageNumber: number, handleAPICallError: (error: any) => void) : Promise<Training[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/archived/' + pageNumber;

    return axios.get<Training[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getClientTrainingByID = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<Training> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/' + trainingID;

    return axios.get<Training>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const updateTraining = async (training: Training, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/' + training.trainingID;

    return axios.put<any>(url, training, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const publishTraining = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/publish/' + trainingID;

    return axios.put<any>(url, null, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const archiveTraining = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/archive/' + trainingID;

    return axios.put<any>(url, null, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getLatestActiveTrainings = async (handleAPICallError: (error: any) => void) : Promise<Training[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'provider/trainings/latest';

    return axios.get<Training[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const addProviderAttempt = async (trainingID: string, attempt: ProviderAttempt, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/attempt/' + trainingID;

    return axios.post<any>(url, attempt, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const addTrainingCertification = async (trainingID: string, certification: TrainingCertification, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/certification/' + trainingID;

    return axios.post<any>(url, certification, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getCertificationForTraining = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<TrainingCertification> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/mycertification/' + trainingID;

    return axios.get<TrainingCertification>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
            return null;
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getAttemptsForTraining = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<ProviderAttempt[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/attempts/' + trainingID;

    return axios.get<ProviderAttempt[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
            return [];
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getMyAttemptsForTraining = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<ProviderAttempt[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/myattempts/' + trainingID;

    return axios.get<ProviderAttempt[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
            return [];
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getTrainingByIDForProvider = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<Training> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/provider/' + trainingID;

    return axios.get<Training>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
            return null;
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getEmployeesForTraining = async (handleAPICallError: (error: any) => void) : Promise<Employee[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/employees/active';

    return axios.get<any>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
            return null;
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getAssignmentStatusForTraining = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<TrainingStatus[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/' + trainingID + '/assignmentStatus';

    return axios.get<TrainingStatus[]>(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
            return [];
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const sendNotificationForTrainingForAll = async (trainingID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/notification/' + trainingID;

    return axios.put<any>(url, null, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
            return null;
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const sendNotificationForTrainingForEmployee = async (trainingID: string, employeeID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'trainings/' + trainingID + '/notify/' + employeeID;

    return axios.put<any>(url, null, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
            return null;
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}