import React from 'react';

interface RightMessageBubbleProps {
    message: string;
}

const RightMessageBubble: React.FC<RightMessageBubbleProps> = ({ message }) => {
    return (
        <div className="tw-bg-green-200 tw-w-4/5 tw-py-3 tw-px-5 tw-rounded-l-lg tw-rounded-br-lg tw-mb-2 tw-text-left tw-clear-both tw-ml-auto">
            {message}
        </div>
    );
};

export default RightMessageBubble;
