import { useEffect, useLayoutEffect, useState } from "react";
import Button from "../buttons/Buttons";
import { Comment, CommentBlock } from "../containers/Cards";
import { PageSubHeader, SectionHeader } from "../typography/Headers";
import { LabelOutputs, Status } from "../utilities/Helpers";
import { paragraph1  } from "../../utilities/typography";
import AddNewNote from "./AddNewNote";
import { Delegating } from "./Actions";
import { useAppContext } from "../../Context";
import { Block, ButtonPrimary, ButtonSecondary, Span } from "@medforall/medforall-component-library";
import CoverDetailsPanel, { EditCoverDetailsPanel } from "./CoverDetailsPanel";

const SideNav = props => {
	const navContext = useAppContext();
	const [theme, setTheme] = useState<any>(3);
	const [submitted, setSubmitted] = useState<string>(null);
	const [values, setValues] = useState<any>(null);
	const [panelTitle, setPanelTitle] = useState<any>();
	const [panelBackBtn, setPanelBackBtn] = useState<any>();
	const [navActions, setNavActions] = useState<any>(null);
	const [editingActions, setEditingActions] = useState<any>(null);
	const [editing, setEditing] = useState<boolean>(false);
	const [panelSize, setPanelSize] = useState('tw-max-w-400');

	useEffect(() => {
		if (navActions) console.log(navActions, navActions.ctas);
	}, navActions)

	useEffect(() => {
		if (navContext.sideNavTitle) setPanelTitle(navContext.sideNavTitle);
		if (navContext.sideNavTheme) setTheme(navContext.sideNavTheme);
		if (navContext.panelBackBtn) setPanelBackBtn(navContext.panelBackBtn);

		//if (navContext.values) console.log(navContext.values)


	}, [navContext.sideNavTitle, navContext.values, navContext.sideNavTheme, navContext.panelBackBtn]);

	useLayoutEffect(() => {
		if (navContext.sideNavActions) {
			if ( navContext?.sideNavActions === "editing" ) {
				setEditing(true);
			} else if (navContext.sideNavActions?.editing) {
				setEditing(true);
				setEditingActions(navContext.sideNavActions?.editing);
			} else {
				setNavActions(navContext.sideNavActions);
			}
		}
	}, [navContext.sideNavActions, navContext.sideNavBottom])

	useEffect(() => {
		console.log(navContext.sideNavBottom);
	}, [navContext.sideNavBottom])

	useEffect(() => {
		if (navContext.values) setValues(navContext.values);
		//console.log(navContext.values)
	}, [navContext.values])

	useEffect(() => {
		if (values) console.log(values)
	}, [values])

	useEffect(() => {
		if (submitted === 'success') {
			//console.log(navContext.values)
			setPanelTitle("Changes Saved");
			navContext.setSideNavActions(false);
			navContext.setSideNavTitle("Changes Saved");
		}

		if (submitted === 'error') {
			//console.log(navContext.values)
			setPanelTitle("Oops");
			navContext.setSideNavActions(false);
			navContext.setSideNavTitle("Oops");
		}
	}, [submitted])


	return <>
		<div className={`tw-relative tw-z-20 || ${navContext.panelSize} tw-h-screen tw-flex-grow tw-w-full || tw-flex tw-flex-col || tw-bg-white`}>
			<div className={`tw-w-full tw-overflow-hidden tw-flex-shrink-0 || tw-flex ||
				${ theme === 3 ? ` tw-bg-white tw-text-dark tw-font-primary-bold` : (theme === 2 ? ` tw-bg-accent-dark tw-text-white ` : ` tw-bg-primary tw-text-white `) }
			`}>
				{/* { panelBackBtn &&
					<Button onClick={ (e) => {
						e.preventDefault();
						panelBackBtn();
					} } className="tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-w-40px tw-border-l tw-border-[rgba(0,0,0,.05)] hover:tw-border-white/10 hover:tw-bg-dark/10" childClasses="tw-flex tw-items-center">
						<span className="tw-speak-none tw-w-[0.875em] tw-h-[0.875em] || tw-inline-flex tw-items-center tw-justify-center">

							<img className="tw-inline-flex tw-w-full tw-h-full tw-object-contain tw-border-0" src={`/images/icon-arrow-left.svg`} />
						</span>
						<span className="tw-hidden">Back</span>
					</Button>
				} */}

				<span className="tw-block tw-whitespace-nowrap tw-max-w-[calc(100%-3.375em-1px)]">
					<span className="tw-block tw-px-6 tw-py-3 tw-leading-[1.125em]  tw-w-full tw-text-ellipsis tw-overflow-hidden">
						{ panelTitle }
					</span>
				</span>
				<Button onClick={ (e) => {
					e.preventDefault();
					if (navContext.sideNavActions === true) {
						let text = `Are you sure you want to Discard? \n Your changes will be lost.`;
						if (window.confirm(text) == true) navContext.closeSideNav();
					} else {
						navContext.closeSideNav();
					}
				} } className="tw-ml-auto tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-w-[3.375em] tw-border-l tw-border-[rgba(0,0,0,.05)] hover:tw-border-white/10 hover:tw-bg-dark/10" childClasses="tw-flex tw-items-center">
					<span className="tw-speak-none tw-w-[0.875em] tw-h-[0.875em] || tw-inline-flex tw-items-center tw-justify-center">

						<img className="tw-inline-flex tw-w-full tw-h-full tw-object-contain tw-border-0" src={`/images/icon-close${ theme === 3 ? '-black' : ''}.svg`} />
					</span>
					<span className="tw-hidden">Close</span>
				</Button>
			</div>
			<div className="tw-flex-grow tw-flex tw-overflow-y-auto tw-text-dark">
				{ submitted ? <>
					<div className="tw-p-30px tw-space-y-20px tw-flex tw-flex-col">
						{ submitted === 'success' ? <>
							<h3 className="tw-font-primary-bold tw-font-5">
								Saved coverage information:
							</h3>

							<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
								{ values.supportType && <li className="tw-flex tw-justify-between tw-items-start tw-space-x-20px tw-w-full">
									<LabelOutputs icon="/images/icon-hash.svg" className="tw-min-w-150p tw-opacity-70">
										Support Type:
									</LabelOutputs>
									<span className="tw-flex-grow">
										{ values.supportType }
									</span>
								</li> }
								{ values.frequency && ( values.frequency?.Sunday || values.frequency?.Monday || values.frequency?.Tuesday || values.frequency?.Wednesday || values.frequency?.Thursday || values.frequency?.Friday || values.frequency?.Saturday ) && <>
									<li className="tw-flex tw-justify-between tw-items-start tw-space-x-20px tw-w-full">
										<LabelOutputs icon="/images/icon-date.svg" className="tw-min-w-150p tw-opacity-70">
											Day(s) of Week:
										</LabelOutputs>
										<ul className="tw-flex-grow tw-flex tw-flex-col tw-space-y-5px tw-leading-none">
											{  values.frequency?.Sunday && <><li>Sunday</li></> }
											{  values.frequency?.Monday && <><li>Monday</li></> }
											{  values.frequency?.Tuesday && <><li>Tuesday</li></> }
											{  values.frequency?.Wednesday && <><li>Wednesday</li></> }
											{  values.frequency?.Thursday && <><li>Thursday</li></> }
											{  values.frequency?.Friday && <><li>Friday</li></> }
											{  values.frequency?.Saturday && <><li>Saturday</li></> }
										</ul>
									</li>
								</>}
								{ values.startTime && <li className="tw-flex tw-justify-between tw-items-start tw-space-x-20px tw-w-full">
									<LabelOutputs icon="/images/icon-time.svg" className="tw-min-w-150p tw-opacity-70">
										Start Time:
									</LabelOutputs>
									<span className="tw-flex-grow">
										{ values.startTime }
									</span>
								</li> }
								{ values.endTime && <li className="tw-flex tw-justify-between tw-items-start tw-space-x-20px tw-w-full">
									<LabelOutputs icon="/images/icon-time.svg" className="tw-min-w-150p tw-opacity-70">
										End Time:
									</LabelOutputs>
									<span className="tw-flex-grow">
										{ values.endTime }
									</span>
								</li> }
								{ values.frequency && <li className="tw-flex tw-justify-between tw-items-start tw-space-x-20px tw-w-full">
									<LabelOutputs icon="/images/icon-repeat.svg" className="tw-min-w-150p tw-opacity-70">
										Repeat:
									</LabelOutputs>
									<span className="tw-flex-grow">
										Every {values.frequency.nth || ''}{` `}{values.frequency.frequency || ''}{ values.frequency.nth && values.frequency.nth > 1 && 's' }{` `}on{` `}

										{ values.frequency && ( values.frequency?.Sunday || values.frequency?.Monday || values.frequency?.Tuesday || values.frequency?.Wednesday || values.frequency?.Thursday || values.frequency?.Friday || values.frequency?.Saturday ) && <>
											<ul className="tw-inline u-comma-space">
												{  values.frequency?.Sunday && <><li className="tw-inline">Sun</li></> }
												{  values.frequency?.Monday && <><li className="tw-inline">Mon</li></> }
												{  values.frequency?.Tuesday && <><li className="tw-inline">Tues</li></> }
												{  values.frequency?.Wednesday && <><li className="tw-inline">Wed</li></> }
												{  values.frequency?.Thursday && <><li className="tw-inline">Thurs</li></> }
												{  values.frequency?.Friday && <><li className="tw-inline">Fri</li></> }
												{  values.frequency?.Saturday && <><li className="tw-inline">Sat</li></> }
											</ul>
										</>}
									</span>
								</li> }

								{ (values.reason || values.reasonDetail )&& <li className="tw-flex tw-flex-col tw-space-y-5px tw-space-x-20px tw-w-full">
									<LabelOutputs icon="/images/icon-message.svg" className="tw-min-w-150p tw-opacity-70">
										Reason for changes
									</LabelOutputs>
									<span className="tw-flex-grow tw-w-full tw-pr-40px tw-space-y-5px">
										<Block>
											{ values.reason }{ values.reasonDetail && ':' }
										</Block>
										{ values.reasonDetail && <>
											<Block>
												<i className="tw-text-[.9em]">
													{ values.reasonDetail }
												</i>
											</Block>
										</>}
									</span>
								</li> }
							</ul>

							<div className="!tw-mt-auto tw-pt-20px">
								<div className="tw-space-y-15px tw-text-center">
									<LabelOutputs className="tw-w-200px tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
										<span className="tw-text-5">
											Saved Successfully
										</span>
									</LabelOutputs>
									<ButtonSecondary className="tw-w-200px" onClick={ e => {
										e.preventDefault();
										navContext.closeSideNav();
									}}>
										Go Back to schedule
									</ButtonSecondary>
								</div>
							</div>
						</> : <>
							<div className="tw-text-center tw-space-y-20px">
								<h3 className="tw-font-primary-bold tw-font-5">
									Your Changes are not saved
								</h3>

								<p className="tw-text-5 tw-leading-normal">
									There are something wrong with the connection, please try again
								</p>
							</div>

							<div className="!tw-mt-auto tw-pt-20px">
								<div className="tw-space-y-15px tw-text-center">
									<ButtonSecondary className="tw-w-200px" onClick={ e => {
										e.preventDefault();
										setSubmitted(null);
									}}>
										Try Again
									</ButtonSecondary>
								</div>
							</div>
						</> }
					</div>
				</> : <>
					<div className="tw-w-full">
						{ props.children || navContext.sideNav }
					</div>
				</>  }
			</div>

			{ navContext.sideNavBottom && <>
				<div className={`tw-flex-shrink-0 tw-pt-10px tw-space-x-5px tw-px-20px tw-pb-40px tw-overflow-y-auto tw-text-dark tw-flex tw-justify-between`}>
					{ navContext.sideNavBottom && navContext.sideNavBottom }
				</div>
			</> }
			{ !navContext.sideNavBottom && navContext.sideNavActions && <>
				<div className={`tw-flex-shrink-0 tw-pt-10px tw-space-x-5px tw-px-20px tw-pb-40px tw-overflow-y-auto tw-text-dark tw-flex ${ (navActions && navActions.ctas && (navActions && navActions.ctas.length < 2)) ? 'tw-justify-center': 'tw-justify-between' }`}>
					{ navActions && navActions.ctas ? <>
						{ navActions.ctas.map( (cta, index) => {
							return <div key={ `sideActionCTA_${index}` }  >
								{ cta.primary ? <>
									<ButtonPrimary href={ cta.href || null } title={ cta.title || null } className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
										e.preventDefault();
										if (cta.onClick) cta.onClick(e);
									}}>
										{ cta?.name || "Click me" }
									</ButtonPrimary>
								</> : <>
									<ButtonSecondary href={ cta.href || null } title={ cta.title || null } className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
										e.preventDefault();
										if (cta.onClick) cta.onClick(e);
									}}>
										{ cta?.name || "Click me" }
									</ButtonSecondary>
								</>}
							</div>
						}) }
					</> : <>
						{ editing && <>
							<div className="tw-flex">
							<ButtonSecondary title={ editingActions && editingActions[0]?.title || null } className={`tw-min-w-120px ${editingActions && editingActions[0]?.classes || null}`} onClick={ e => {
								e.preventDefault();

								let text = `Are you sure you want to Discard? \n Your changes will be lost.`;
								if (window.confirm(text) == true) {
									if (editingActions && editingActions[0]?.onClick) {
										editingActions[0]?.onClick();
									} else {
										navContext.setSideNav(<CoverDetailsPanel />); // pass props in this
										navContext.setSideNavActions(null);
									}
								}
							}}>
								{ editingActions && editingActions[0]?.name || 'Cancel Edit' }
							</ButtonSecondary>
						</div>
						</> }
						<div className="tw-flex">
							<ButtonSecondary title={ editingActions && editingActions[1]?.title || null } className={`tw-min-w-120px ${editingActions && editingActions[1]?.classes || null}`} onClick={ e => {
								e.preventDefault();
								let text = `Are you sure you want to Discard? \n Your changes will be lost.`;
								if (window.confirm(text) == true) {
									if (editingActions && editingActions[1]?.onClick) {
										editingActions[1]?.onClick();
									} else {
										navContext.closeSideNav();
										navContext.setSideNavActions(null);
									}
								}
							}}>
								{ editingActions && editingActions[1]?.name || 'Discard' }
							</ButtonSecondary>
						</div>
						<div className="tw-flex">
							<ButtonPrimary title={ editingActions && editingActions[2]?.title || null } className={`tw-min-w-120px ${editingActions && editingActions[2]?.classes || null}`} onClick={ e => {
								e.preventDefault();

								if (editingActions && editingActions[2]?.onClick) {
									editingActions[2]?.onClick();
								} else {
									if (navContext.values && navContext.values.startTime && navContext.values.endTime) {
										setSubmitted('success');
									} else {
										setSubmitted('error');
									}
								}

								//end temp code
								console.log("clicked submit", navContext.values)
							}}>
								{ editingActions && editingActions[2]?.name || 'Save' }
							</ButtonPrimary>
						</div>
					</> }
				</div>
			</> }
		</div>
	</>;
}

export default SideNav;