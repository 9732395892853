import { Link } from 'react-router-dom';
import { IconMediaObject } from '../containers/ImageContainer';

export const ButtonInner = props => {
	const tag = props.tag || 'span';
	const childTag = props.childTag || 'span';

	const attributes = {
		...props,
		childTag: childTag,
		tag: tag,
		anchor: null,
	}

	return <IconMediaObject button={ props?.anchor ? false : true } { ...attributes } />
};

const Button = props => {
	const classes = `c-button ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const attributes = {
		...props,
		className: classes,
		childClasses: null,
		childTag: null,
		coreClasses: null,
		tag: null,
		anchor: null,
		after: null,
		alignment: null
	}

	const addTouchstatus = (e) => {
		const el = e.target;

		if (!el.classList.contains('_hover')) {
			el.classList.add('_hover');
		} else {
			el.classList.remove('_hover');
		}

		if (!el.classList.contains('_active')) {
			setTimeout(() => {
				el.classList.remove('_hover');
				el.classList.add('_active');
			}, 150);
		} else {
			el.classList.remove('_active');
		}
	}

	const removeTouchstatus = (e) => {
		const el = e.target;

		setTimeout(() => {
			if (el.classList.contains('_hover')) {
				el.classList.remove('_hover');
			}

			if (el.classList.contains('_active')) {
				el.classList.remove('_active');
			}
		}, 250);
	}

    if (props.to) {
        return <Link { ...attributes }>
			<ButtonInner alignment={ props.alignment || null } after={ props.after || null } anchor={ props.anchor || null } iconNotRounded={ props.iconNotRounded || null }  iconLarge={ props.iconLarge || null } icon={ props.icon || null } svg={ props.svg || null } tag={ props.childTag || null } className={ props.childClasses || null } overrideChild={ props.overrideChild ? true : false }>
				{ props.children }
			</ButtonInner>
        </Link>;
    } else if(props.href) {
       return  <a { ...attributes }>
			<ButtonInner  alignment={ props.alignment || null } after={ props.after || null } anchor={ props.anchor || null } iconNotRounded={ props.iconNotRounded || null }  iconLarge={ props.iconLarge || null } icon={ props.icon || null } svg={ props.svg || null } tag={ props.childTag || null } className={ props.childClasses || null } overrideChild={ props.overrideChild ? true : false }>
				{ props.children }
			</ButtonInner>
        </a>;
    } else if(props.tag) {
		const ComponentTag = props.tag;
		return <ComponentTag { ...attributes }>
			<ButtonInner  alignment={ props.alignment || null } after={ props.after || null } anchor={ props.anchor || null } iconNotRounded={ props.iconNotRounded || null }  iconLarge={ props.iconLarge || null } icon={ props.icon || null } svg={ props.svg || null } tag={ props.childTag || null } className={ props.childClasses || null } overrideChild={ props.overrideChild ? true : false }>
				{ props.children }
			</ButtonInner>
		</ComponentTag>
	} else {
       return <>
			<button { ...attributes }>
				<ButtonInner  alignment={ props.alignment || null } after={ props.after || null } anchor={ props.anchor || null } iconNotRounded={ props.iconNotRounded || null }  iconLarge={ props.iconLarge || null } icon={ props.icon || null } svg={ props.svg || null } tag={ props.childTag || null } className={ props.childClasses || null } overrideChild={ props.overrideChild ? true : false }>
					{ props.children }
				</ButtonInner>
			</button>
	   </>
    }
}

const BtnStyleLarge = 'tw-text-1 tw-leading-6';
const BtnStyleMedium = 'tw-text-3 tw-leading-6';
const BtnStyleSmall = 'tw-text-5 tw-leading-[1.125rem]';
const BtnStyleDisabled = 'tw-grayscale tw-cursor-default !tw-bg-transparent tw-pointer-events-none !tw-text-light !tw-border-light hover:!tw-bg-light hover:!tw-bg-transparent hover:!tw-text-light';

export const ButtonAnchor = props => {
	return <Button { ...props } anchor={ true } alignment={ props.alignment || null } coreClasses={`tw-bg-transparent tw-border-0 tw-outline-none ${ props?.dark ? 'tw-text-dark' : ' tw-text-primary ' } hover:tw-text-black tw-rounded-none || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonPrimary = props => {
	return <Button { ...props } coreClasses={`tw-bg-primary tw-text-white tw-border-primary hover:tw-bg-white hover:tw-text-primary || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonSecondary = props => {
	return <Button { ...props } coreClasses={`tw-bg-white tw-text-primary tw-border-primary hover:tw-bg-primary hover:tw-text-white || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonTertiary = props => {
	return <Button { ...props } coreClasses={`tw-bg-white tw-text-primary tw-border-primary hover:tw-bg-dark hover:tw-text-white hover:tw-border-dark || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonSuccess = props => {
	return <Button { ...props } coreClasses={`tw-bg-success tw-text-white tw-border-success hover:tw-bg-white hover:tw-text-success || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonPublish = props => {
	return <Button { ...props } coreClasses={`tw-bg-orange-500 tw-text-white tw-border-orange-500 hover:tw-bg-white hover:tw-text-orange-500 || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonWarning = props => {
	return <Button { ...props } coreClasses={`tw-bg-warning tw-text-white tw-border-warning hover:tw-bg-white hover:tw-text-warning || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonWarningSecondary = props => {
	return <Button { ...props } coreClasses={`tw-bg-white tw-text-warning tw-border-warning hover:tw-bg-warning hover:tw-text-white || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonError = props => {
	return <Button { ...props } coreClasses={`tw-bg-error tw-text-white tw-border-error hover:tw-bg-white hover:tw-text-error || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonErrorSecondary = props => {
	return <Button { ...props } coreClasses={`tw-bg-white tw-text-error tw-border-error hover:tw-bg-error hover:tw-text-white || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export const ButtonHelp = props => {
	return <Button { ...props } coreClasses={`tw-bg-white tw-text-orange-300 tw-border-orange-300 hover:tw-bg-orange-300 hover:tw-text-white || tw-border-[0.125rem] tw-rounded-md || ${ props.large ? ' || ' + BtnStyleLarge : ''}${ props.medium ? ' || ' + BtnStyleMedium : ''}${ props.small ? ' || ' + BtnStyleSmall : ''}${ props.disabled ? ' || ' + BtnStyleDisabled : '' }`}>
		{ props.children }
	 </Button>
}

export default Button;
