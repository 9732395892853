import { useEffect, useState } from "react";
import { CourseMaterial, Quiz, QuizQuestion, Training } from "../../types/training";
import { ButtonPrimary } from "@medforall/medforall-component-library";
import { set } from "lodash";

const QuizResults = ({ score, sampleQuiz}) => {
    const isPassing = score >= sampleQuiz.passingScore;

    return (
        <div className="tw-space-y-4 tw-text-center">
            <h2 className="tw-text-2xl tw-font-semibold tw-leading-6 tw-text-gray-900">
                Quiz Results
            </h2>
            <div className="tw-text-xl tw-font-normal tw-text-gray-800">
                Your Score: {score}
            </div>
            {isPassing ? (
                <div className="tw-space-y-4">
                    <h3 className="tw-text-xl tw-font-semibold tw-leading-6 tw-text-green-600">
                        Congratulations! You have successfully completed the training.
                    </h3>
                    <div className="tw-p-4 tw-border tw-border-gray-300 tw-rounded">
                        <h4 className="tw-text-lg tw-font-semibold tw-text-gray-800">
                            Certificate of Completion
                        </h4>
                        <p className="tw-text-gray-600">
                            This is to certify that you have successfully completed the training.
                        </p>
                        <p className="tw-text-gray-800 tw-font-medium">
                            Training ID: {sampleQuiz.trainingID}
                        </p>
                        <p className="tw-text-gray-800 tw-font-medium">
                            Quiz ID: {sampleQuiz.quizID}
                        </p>
                    </div>
                </div>
            ) : (
                <div className="tw-space-y-4">
                    <h3 className="tw-text-xl tw-font-semibold tw-leading-6 tw-text-red-600">
                        Unfortunately, you did not pass the quiz.
                    </h3>
                    <p className="tw-text-gray-600">
                        You need to wait 24 hours before trying again. Please review the training materials and try again later.
                    </p>
                </div>
            )}
        </div>
    );
};


const TrainingPreviewPage = props => {
    const [step, setStep] = useState<number>(1);

    const [header, setHeader] = useState<string>("Overview");

    useEffect(() => {
        switch (step) {
            case 1:
                setHeader("Overview");
                break;
            case 2:
                setHeader("Course Material");
                break;
            case 3:
                setHeader("Quiz");
                break;
            case 4:
                setHeader("Results");
                break;
            default:
                setHeader("Overview");
                break;
        }
    }, [step])

    const quizQuestions: QuizQuestion[] = [
        {
            questionID: "1",
            question: "What is the capital of France?",
            questionType: "Single-Choice",
            choice1: "Paris",
            choice2: "London",
            choice3: "Berlin",
            choice4: "Madrid",
            correctChoice: "1",
            quizID: "876",
            questionSequence: 1
        },
        {
            questionID: "2",
            question: "What is the capital of Germany?",
            questionType: "Single-Choice",
            choice1: "Paris",
            choice2: "London",
            choice3: "Berlin",
            choice4: "Madrid",
            correctChoice: "3",
            quizID: "876",
            questionSequence: 2
        },
        {
            questionID: "3",
            question: "What is the capital of Spain?",
            questionType: "Single-Choice",
            choice1: "Paris",
            choice2: "London",
            choice3: "Berlin",
            choice4: "Madrid",
            correctChoice: "4",
            quizID: "876",
            questionSequence: 3
        },
        {
            questionID: "4",
            question: "What is the capital of England?",
            questionType: "Single-Choice",
            choice1: "Paris",
            choice2: "London",
            choice3: "Berlin",
            choice4: "Madrid",
            correctChoice: "2",
            quizID: "876",
            questionSequence: 4
        },
    ];

    const [quizResponses, setQuizResponses] = useState([]);

    const sampleQuiz: Quiz = {
        quizID: "876",
        trainingID: "123",
        passingScore: 80,
        failingScore: 0,
        questions: quizQuestions,
        maxAttempts: 3,
    }

    const courseMaterials: CourseMaterial[] = [
        {
            courseMaterialID: "1",
            materialName: "Client OISP",
            materialSequence: 1,
            materialType: "Document",
            materialLink: "https://oahportalstorageaccount.blob.core.windows.net/training-demo/AngelIOWaiver.pdf",
            trainingID: "123"
        },
        {
            courseMaterialID: "2",
            materialName: "Client Care Protocol",
            materialSequence: 2,
            materialType: "Document",
            materialLink: "https://oahportalstorageaccount.blob.core.windows.net/training-demo/JakeIOWaiverSharedLiving.pdf",
            trainingID: "123"
        },
        {
            courseMaterialID: "3",
            materialName: "Client Care Youtube Video",
            materialSequence: 3,
            materialType: "Video",
            materialLink: "https://www.youtube.com/embed/JQ5jG43_XdY?si=dXD5VBL718Zh9puC",
            trainingID: "123"
        },
        {
            courseMaterialID: "4",
            materialName: "Client Care Custom Video",
            materialSequence: 4,
            materialType: "Video",
            materialLink: "https://animationassets.blob.core.windows.net/oah-public/Flagship_animation.mp4",
            trainingID: "123"
        }
    ]

    const [currentMaterialIndex, setCurrentMaterialIndex] = useState(0);

    const nextMaterial = () => {
        if (currentMaterialIndex < courseMaterials.length - 1) {
            setCurrentMaterialIndex(currentMaterialIndex + 1);
        }
    };

    const prevMaterial = () => {
        if (currentMaterialIndex > 0) {
            setCurrentMaterialIndex(currentMaterialIndex - 1);
        }
    };

    const sampleTraining: Training = {
        trainingID: "123",
        trainingName: "Sample Training",
        trainingDescription: "This is a longer sample training description. It provides detailed information about the training content and objectives.",
        trainingCategory: "Client Training",
        clientID: "456",
        clientName: "Sample Client",
        dateCreated: new Date(),
        createdBy: "John Doe",
        quiz: sampleQuiz,
        courseMaterials: courseMaterials,
        trainingAssignments: [],
        providerAttempts: [],
        trainingCertifications: [],
        validTill: new Date(),
        status: "Active",
        voidedReason: ""
    }

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedChoice, setSelectedChoice] = useState(null);

    const currentQuestion = sampleQuiz.questions[currentQuestionIndex];

    const handleNext = () => {
        if (currentQuestionIndex < sampleQuiz.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);

            var currentResponse = quizResponses.find((r) => r.questionID === sampleQuiz.questions[currentQuestionIndex + 1].questionID);

            setSelectedChoice(currentResponse ? currentResponse.choice : null);
        }
    };

    const handlePrev = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            
            var currentResponse = quizResponses.find((r) => r.questionID === sampleQuiz.questions[currentQuestionIndex - 1].questionID);

            setSelectedChoice(currentResponse ? currentResponse.choice : null);
        }
    };

    const handleChoiceSelection = (choice) => {
        setSelectedChoice(choice);

        const response = {
            questionID: sampleQuiz.questions[currentQuestionIndex].questionID,
            choice,
        };

        const updatedResponses = [...quizResponses];

        const existingResponseIndex = updatedResponses.findIndex((r) => r.questionID === response.questionID);

        if (existingResponseIndex > -1) {
            updatedResponses[existingResponseIndex] = response;
        } else {
            updatedResponses.push(response);
        }

        setQuizResponses(updatedResponses);
    };

    const calculateScore = () => {
        const correctResponses = quizQuestions.filter((q) => {
            const response = quizResponses.find((r) => r.questionID === q.questionID);
            return response.choice === q.correctChoice;
        });

        const score = (correctResponses.length / quizQuestions.length) * 100;

        console.log("Score: ", score);

        return score;
    }

    const submitQuiz = () => {
        const score = calculateScore();
        console.log("Score: ", score);

        setStep(4);
    }

    const renderTrainingPreview = () => {
        switch (step) {
            case 1:
                return (
                    <div className="tw-space-y-4 tw-text-center">
                        <h2 className="tw-text-2xl tw-font-semibold tw-leading-6 tw-text-gray-900">{sampleTraining.trainingName}</h2>
                        <div className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center">
                            <div className="tw-text-xl tw-font-normal tw-text-gray-800">
                                {sampleTraining.trainingCategory}
                            </div>
                            {/* <div className="tw-text-xl tw-font-normal tw-text-gray-800">
                                {sampleTraining.trainingCategory}
                            </div> */}
                            <div className="tw-text-xl tw-font-normal tw-text-gray-800">
                                {sampleTraining.trainingDescription}
                            </div>
                            {/* <div className="tw-text-xl tw-font-normal tw-text-gray-800">
                                {sampleTraining.status}
                            </div> */}
                        </div>
                    </div>
                )

            case 2: 
                return (
                <div className="tw-space-y-4 tw-text-center">
                    <h2 className="tw-text-2xl tw-font-semibold tw-leading-6 tw-text-gray-900">
                        {courseMaterials[currentMaterialIndex].materialName}
                    </h2>
                    <div className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center">
                        <iframe 
                            src={courseMaterials[currentMaterialIndex].materialLink} 
                            width="800" 
                            height="600" 
                            title="PDF Viewer"
                            className="tw-border tw-border-gray-300"
                        ></iframe>
                        <div className="tw-flex tw-justify-between tw-w-full tw-max-w-sm">
                            <button 
                                onClick={prevMaterial} 
                                disabled={currentMaterialIndex === 0}
                                className="tw-px-4 tw-py-2 tw-bg-gray-300 tw-text-gray-800 tw-rounded disabled:tw-opacity-50"
                            >
                                Previous
                            </button>
                            <button 
                                onClick={nextMaterial} 
                                disabled={currentMaterialIndex === courseMaterials.length - 1}
                                className="tw-px-4 tw-py-2 tw-bg-gray-300 tw-text-gray-800 tw-rounded disabled:tw-opacity-50"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
                )

            case 3: 
                return (
                    <div className="tw-space-y-4 tw-text-center">
                    <h2 className="tw-text-2xl tw-font-semibold tw-leading-6 tw-text-gray-900">
                        Question {currentQuestionIndex + 1} of {sampleQuiz.questions.length}
                    </h2>
                    <div className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center">
                        <div className="tw-text-xl tw-font-normal tw-text-gray-800">
                            {currentQuestion.question}
                        </div>
                        <div className="tw-flex tw-flex-col tw-space-y-2">
                            {[1, 2, 3, 4].map((choiceNumber) => (
                                <button
                                    key={choiceNumber}
                                    onClick={() => handleChoiceSelection(choiceNumber)}
                                    className={`tw-px-4 tw-py-2 tw-text-lg tw-rounded ${
                                        selectedChoice === choiceNumber
                                            ? 'tw-bg-primary tw-text-white'
                                            : 'tw-bg-gray-200 tw-text-gray-800'
                                    }`}
                                >
                                    {currentQuestion[`choice${choiceNumber}`]}
                                </button>
                            ))}
                        </div>
                        <div className="tw-flex tw-justify-between tw-w-full tw-max-w-sm">
                            <button
                                onClick={handlePrev}
                                disabled={currentQuestionIndex === 0}
                                className="tw-px-4 tw-py-2 tw-bg-gray-300 tw-text-gray-800 tw-rounded disabled:tw-opacity-50"
                            >
                                Previous Question
                            </button>
                            <button
                                onClick={handleNext}
                                disabled={currentQuestionIndex === sampleQuiz.questions.length - 1}
                                className="tw-px-4 tw-py-2 tw-bg-gray-300 tw-text-gray-800 tw-rounded disabled:tw-opacity-50"
                            >
                                Next Question
                            </button>
                        </div>
                    </div>
                </div>
                )

            case 4: 
                return (
                    <QuizResults score={calculateScore()} sampleQuiz={sampleQuiz} />
                )

            default:
                return null;
        }
    }

    const renderFooter = () => {
        switch (step) {
            case 1:
                return (
                    <div className="tw-flex tw-justify-center tw-items-center tw-mt-4">
                        <button 
                            onClick={() => setStep(2)} 
                            className="tw-px-4 tw-py-2 tw-bg-primary tw-text-white tw-rounded"
                        >
                            Start Training
                        </button>
                    </div>
                )

            case 2:
                return (
                    <div className="tw-flex tw-justify-between tw-mt-4">
                        <button 
                            onClick={() => setStep(1)} 
                            className="tw-px-4 tw-py-2 tw-bg-gray-300 tw-text-gray-800 tw-rounded"
                        >
                            Back to Overview
                        </button>
                        <button 
                            onClick={() => setStep(3)} 
                            className="tw-px-4 tw-py-2 tw-bg-primary tw-text-white tw-rounded"
                        >
                            Start Quiz
                        </button>
                    </div>
                )

            case 3:
                return (
                    <div className="tw-flex tw-justify-between tw-mt-4">
                        <button 
                            onClick={() => setStep(2)} 
                            className="tw-px-4 tw-py-2 tw-bg-gray-300 tw-text-gray-800 tw-rounded"
                        >
                            Back to Course Material
                        </button>
                        <button 
                            onClick={submitQuiz}
                            className="tw-px-4 tw-py-2 tw-bg-primary tw-text-white tw-rounded"
                        >
                            Submit Quiz
                        </button>
                    </div>
                )

            default:
                return null;
        }
    }

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };
     

    return (<>
        <div className="tw-px-xl tw-py-sm">
            <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Training Preview - {header}</h2>
            </div>

            <div className="tw-py-5">
                        {renderTrainingPreview()}
                        {renderFooter()}
                        {/* <div className="tw-flex tw-justify-between tw-mt-5">
                            {step === 1 && <ButtonPrimary onClick={handleNextStep}>Start Preview</ButtonPrimary>}

                            {step > 1 && <ButtonPrimary onClick={handlePreviousStep}>Previous</ButtonPrimary>}
                            {step === 2 && <ButtonPrimary onClick={handleNextStep}>Next</ButtonPrimary>}
                        </div> */}
            </div>
        </div>
        
    </>);
}

export default TrainingPreviewPage;