import { Block, ButtonPrimary, ButtonSecondary, DefaultButton, Span } from "@medforall/medforall-component-library";
import { useEffect, useLayoutEffect, useState } from "react";
import { LabelOutputs } from "../../components/utilities/Helpers";
import ClientInfoPanel from "../../components/panels/ClientInfoPanel";
import CoverageDetailsPanel from "../../components/panels/CoverageDetailsPanel";
import AvatarImage from "../../components/avatars/AvatarImage";
import { getDayName, getMonthName, getNumOfDayOfWeek } from "../../components/calendars/CalendarSelector";
import { useAppContext } from "../../Context";
import SchedulePanel, { ScheduleDayPanel, SpanRequirmentSuccessPanel } from "../../components/panels/SchedulePanel";
import ClientHome from "../../types/clientHome";
import { useEditPlanContext } from "../EditServicePlanPage";
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from "formik";
import SubmitDefault, { InfoDefault, SubmitNewCoverageRequirement, SubmitNewSpanRequirement } from "../../components/panels/PanelCTAs";
import { useSpanRequirementContext } from "./ClientRequirement";
import { SpanCoverageEvent } from "../../types/spanRequirement";
import uuid from "react-uuid";
import { IsDayDirectSupport, IsOvernightSupport, IsRemoteSupportWithBackup, IsRemoteSupportWithoutBackup } from "../../utils/Helpers";

export const DirectSupportBlock = props => {
	const [coverageAmount, setcoverageAmount] = useState<string>(null);
	const [providerName, setProviderName] = useState<string>(null);
	const navContext = useAppContext();
	const [icon, setIcon] = useState<string>('/images/icon-pie-_Fully-Open.svg');

	const Tag = props.tag || 'span';

	const attributes = {
		...props,
		className: null
	}

	useLayoutEffect(() => {
		if (props.coverageAmount) {
			setcoverageAmount(props.coverageAmount)
			// coverageAmount="Shift Canceled"
			// coverageAmount="Partially Open"
			// coverageAmount="Fully Open"
			// coverageAmount="Fully Covered
			if(props.coverageAmount === "Open"){
				setIcon('/images/icon-pie-_Fully-Open.svg');
			} else if(props.coverageAmount === "Assigned"){
				setIcon('/images/icon-pie-_Partially-Covered.svg');
			} else {
				setIcon('/images/icon-pie-_Fully-Covered.svg');
			}
		}
		if (props.providerName) {
			setProviderName(props.providerName)
		}
	}, [props.coverageAmount, props.providerName])
	
	return <>
		<div { ...attributes } className={`tw-text-6 tw-inline-flex tw-w-full tw-flex-col tw-text-white ${ props.className }`}>
					<div className="tw-leading-none tw-py-5px tw-px-10px tw-font-primary-bold tw-flex-shrink-0 tw-text-6 tw-bg-accent-dark">
						<span className="tw-text-[.9em]">
							{ props.type }
						</span>
					</div>
					<ul className="tw-w-full tw-bg-white tw-text-dark tw-flex tw-flex-col tw-space-y-5p tw-py-10p tw-divide-y tw-divide-dark/20 tw-text-5-alt tw-px-10p">
						<li>
							<span className="tw-flex tw-flex-col tw-w-full tw-items-center tw-text-sm">
								<Span className="tw-w-70p">{ props.time.split(' - ')[0]}</Span>
								<Span className="tw-px-5p tw-text-center"> - </Span>
								<Span className="tw-w-70p">{ props.time.split(' - ')[1]}</Span>
							</span>
						</li>
						<li className="tw-pt-10p tw-flex tw-items-center tw-w-full">
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-w-full">
								<Span className="tw-flex-shrink-0">
									{ coverageAmount }
								</Span>
								<Span className="tw-ml-auto || tw-w-16p tw-h-16p tw-aspect-square tw-relative">
									<img className="tw-block tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-object-cover tw-object-center" src={`${icon}`} alt={ coverageAmount } />
								</Span>
							</span>
						</li>
					</ul>
				</div>
	</>
}

export const NoticeBlock = props => {
	const navContext = useAppContext();
	const [coverageAmount, setcoverageAmount] = useState<string>(null);
	const [icon, setIcon] = useState<string>('/images/icon-pie-_Fully-Covered.svg');

	useLayoutEffect(() => {
		if (props.coverageAmount) {
			setcoverageAmount(props.coverageAmount)
			// coverageAmount="Shift Canceled"
			// coverageAmount="Partially Open"
			// coverageAmount="Fully Open"
			// coverageAmount="Fully Covered
			if(props.coverageAmount === "Active"){
				setIcon('/images/icon-pie-_Fully-Covered.svg');
			} else {
				setIcon('/images/icon-pie-_Cancelled.svg');
			}
		}
	}, [props.coverageAmount])

	const Tag = props.tag || 'span';

	const attributes = {
		...props,
		className: null
	}

	return <>
		<div { ...attributes } className={`tw-text-6 tw-inline-flex tw-w-full tw-cursor-pointer tw-flex-col tw-text-white ${ props.className }`}>
					<div className="tw-leading-none tw-py-5px tw-px-10px tw-font-primary-bold tw-flex-shrink-0 tw-text-6 tw-bg-orange-400">
						<span className="tw-text-[.9em]">
							{ props.noticeType }
						</span>
					</div>
					<ul className="tw-w-full tw-bg-white tw-text-dark tw-flex tw-flex-col tw-space-y-5p tw-py-10p tw-divide-y tw-divide-dark/20 tw-text-5-alt tw-px-10p">
						<li>
							<span className="tw-flex tw-flex-col tw-w-full tw-items-center tw-text-sm">
								<Span className="tw-w-70p">{ props.startDate}</Span>
								<Span className="tw-px-5p tw-text-center"> - </Span>
								<Span className="tw-w-70p">{ props.endDate}</Span>
							</span>
						</li>
						<li className="tw-pt-10p tw-flex tw-items-center tw-w-full">
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-between tw-text-black tw-w-full">
								<Span className="tw-flex-shrink-0">
									Duration: { props.duration } days
								</Span>
								<Span className="tw-ml-auto || tw-w-16p tw-h-16p tw-aspect-square tw-relative">
									<img className="tw-block tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-object-cover tw-object-center" src={`${icon}`} alt={ coverageAmount } />
								</Span>
								{/* <Span className="tw-ml-auto || tw-w-16p tw-h-16p tw-aspect-square tw-relative">
									<img className="tw-block tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-object-cover tw-object-center" src={`${icon}`} alt={ coverageAmount } />
								</Span> */}
							</span>
						</li>
					</ul>
				</div>
	</>
}

export const DataBlock = props => {
	const [coverageAmount, setcoverageAmount] = useState<string>(null);
	const navContext = useAppContext();
	const reqContext = useSpanRequirementContext();
	const [requirement, setRequirement] = useState<any>(props.requirement || 'span');
	const [billingCode, setBillingCode] = useState<string>(props.billingCode || undefined);
	const Tag = props.tag || 'span';
	const [showIcon, setShowIcon] = useState<boolean>(true);

	const [bannerClass, setBannerClass] = useState<string>('tw-bg-primary');

	const attributes = {
		...props,
		className: null
	}

	useEffect(() => {
		if (props.coverageAmount) {
			setcoverageAmount(props.coverageAmount)
			// coverageAmount="Shift Canceled"
			// coverageAmount="Partially Open"
			// coverageAmount="Fully Open"
			// coverageAmount="Fully Covered
		}
		if (props.requirement) {
			setRequirement(props.requirement);

		}

		if (props.billingCode) {
			setBillingCode(props.billingCode);
			console.log(props.billingCode);
			if(IsRemoteSupportWithBackup(props.billingCode)){
				console.log("Remote Support with Backup");
				setBannerClass('tw-bg-primary');
			}
			if(IsRemoteSupportWithoutBackup(props.billingCode)){
				console.log("Remote Support without Backup");
				setBannerClass('tw-bg-remote-support-without-backup');
			}
			if(IsDayDirectSupport(props.billingCode)){
				console.log("Day Direct Support");
				setBannerClass('tw-bg-accent-dark');
			}
			if(IsOvernightSupport(props.billingCode)){
				console.log("Overnight Support");
				setBannerClass('tw-bg-overnight-support');
			}
		
		}
	}, [props.coverageAmount, props.requirement, props.showIcon])

	const initialValues = {
		start_time: '',
		end_time: '',
		shift_type: '',
		allDay: false,
		repeats_monday: false,
		repeats_tuesday: false,
		repeats_wednesday: false,
		repeats_thursday: false,
		repeats_friday: false,
		repeats_saturday: false,
		repeats_sunday: false,
	};

	const initialErrors = {
		start_time: false,
		end_time: false,
		shift_type: false,
		frequency: false,
	};

	const initialErrorMessages = {
		start_time: '',
		end_time: '',
		shift_type: '',
		frequency: '',
	};

	const submitForm = (values) => {
		// validate form
		console.log(values);

		var startTime = moment(reqContext.planStartDate + " " + values.start_time);
        var endTime = moment(reqContext.planEndDate + " " + values.end_time);

		const found = reqContext.clientServiceTypes.find(element => element.shiftType === values.shift_type);

		let newEvent : SpanCoverageEvent = {
			spanCoverageEventID: uuid(),
			spanRequirementID: "",
			dayOfWeek: 0,
			startTime: startTime.format(),
			displayStartTime: startTime.format("h:mm A"),
			endTime: endTime.format(),
			displayEndTime: endTime.format("h:mm A"),
			staffSize: found.staffSize,
			groupSize: found.groupSize,
			billingCode: found.billingCode,
			shiftType: found.shiftType,
			allDay: false,
		};

		if(values.repeats_sunday){
			var sundayEvent = JSON.parse(JSON.stringify(newEvent));
			sundayEvent.dayOfWeek = 0;
			reqContext.setSundayRequirements([...reqContext.sundayRequirements, sundayEvent]);
		}
		if(values.repeats_monday){
			var mondayEvent = JSON.parse(JSON.stringify(newEvent));
			mondayEvent.dayOfWeek = 1;
			reqContext.setMondayRequirements([...reqContext.mondayRequirements, mondayEvent]);
		}
		if(values.repeats_tuesday){
			var tuesdayEvent = JSON.parse(JSON.stringify(newEvent));
			tuesdayEvent.dayOfWeek = 2;
			reqContext.setTuesdayRequirements([...reqContext.tuesdayRequirements, tuesdayEvent]);
		}
		if(values.repeats_wednesday){
			var wednesdayEvent = JSON.parse(JSON.stringify(newEvent));
			wednesdayEvent.dayOfWeek = 3;
			reqContext.setWednesdayRequirements([...reqContext.wednesdayRequirements, wednesdayEvent]);
		}
		if(values.repeats_thursday){
			var thursdayEvent = JSON.parse(JSON.stringify(newEvent));
			thursdayEvent.dayOfWeek = 4;
			reqContext.setThursdayRequirements([...reqContext.thursdayRequirements, thursdayEvent]);
		}
		if(values.repeats_friday){
			var fridayEvent = JSON.parse(JSON.stringify(newEvent));
			fridayEvent.dayOfWeek = 5;
			reqContext.setFridayRequirements([...reqContext.fridayRequirements, fridayEvent]);
		}
		if(values.repeats_saturday){
			var saturdayEvent = JSON.parse(JSON.stringify(newEvent));
			saturdayEvent.dayOfWeek = 6;
			reqContext.setSaturdayRequirements([...reqContext.saturdayRequirements, saturdayEvent]);
		}

		reqContext.setRequirementUpdated(true);
		navContext.closeSideNav();
	};

	const handleOpenRequirementForm = e => {
		e.preventDefault();
		if(requirement === 'span'){
			navContext.setSideNavTheme(3);
			navContext.setValues(initialValues);
			navContext.setErrors(initialErrors);
			navContext.setErrorMessages(initialErrorMessages);
			navContext.setSideNav( <ScheduleDayPanel changeRequirements={ props.changeRequirements ? true : false } day={ props?.day || null } serviceTypes={ props?.serviceTypes || null} /> );
			navContext.setSideNavActions(null);
			navContext.setSideNavBottom(<SubmitNewSpanRequirement onSubmit={submitForm} />)
			navContext.openSideNav();
		} else if (requirement === 'on-demand') {
			navContext.setSideNavTheme(3);
			navContext.setPanelSize('tw-max-w-400px');
			navContext.setSideNavTitle('Add On-Demand Requirement');
			navContext.setSideNav( <SchedulePanel date={ moment().format("MM-DD-YYYY") } serviceTypes={ props?.serviceTypes || null}/> );
			navContext.setSideNavActions(null);
			navContext.setSideNavBottom(<SubmitNewCoverageRequirement />)
			navContext.openSideNav();
		}
	}

	return  props.none ? <>
		<div role="button" { ...attributes } className={`tw-text-6 tw-inline-flex tw-space-y-5px tw-py-10px tw-px-5px tw-w-full tw-flex-col tw-items-center tw-justify-center tw-cursor-pointer tw-text-dark tw-bg-[#E5E5E5]  tw-text-dark ${ props.className }`} onClick={ e => {
				e.preventDefault();
				handleOpenRequirementForm(e);
				// navContext.setSideNav( <ScheduleDayPanel day={ props.day || null } date={ props.date || null} /> );
				// navContext.openSideNav();
			}}>
			<span className="tw-w-1/4 tw-mx-auto tw-aspect-square">
				<img className="tw-w-full" src="/images/icon-date.svg" />
			</span>
			<b className="tw-leading-none tw-block tw-text-center">
				No requirements
			</b>
			<span className="tw-text-[.9em] tw-w-full tw-text-center tw-leading-tight">
				Go add new requirements
			</span>
		</div>
	</> : <>
		{ props.change ? <>
			<div { ...attributes } className={`tw-text-6 tw-inline-flex tw-w-full tw-flex-col tw-cursor-pointer tw-text-white tw-relative tw-border-l-3px ${ props.change ? 'even:XXXtw-bg-secondary-accent/50 odd:XXXtw-bg-red-300' : ''} ${ props.type === "Remote Support" ? 'tw-border-l-primary' : 'tw-border-l-accent-dark' } ${ props.className }`}>
				<div className="tw-text-dark tw-flex-grow tw-pt-5px tw-px-10px tw-pb-10px tw-relative tw-text-[.75em]">
					<Span>
						{ props.time.split(' - ')[0]}
					</Span>
					<Span>
						|
					</Span>
					<Span>
						{ props.time.split(' - ')[1]}
					</Span>
					{ props.alert ? <>
						<Tag className="tw-absolute tw-right-5px tw-top-5px tw-rem tw-w-px-rem tw-h-px-rem tw-rounded-full tw-block tw-aspect-square tw-bg-[#EF5700]" style={{'--px': 8}}></Tag>
					</> : <></> }
				</div>
			</div>
		</> : <>
			{ coverageAmount ? <>
				<div { ...attributes } className={`tw-text-6 tw-inline-flex tw-w-full tw-flex-col tw-cursor-pointer tw-text-white ${ bannerClass } ${ props.className }`}>
					<div className="tw-leading-none tw-py-5px tw-px-10px tw-font-primary-bold tw-flex-shrink-0 tw-text-6">
						<span className="tw-text-[.9em]">
							{ props.type.slice(0, 17) }
						</span>
					</div>
					<ul className="tw-w-full tw-bg-white tw-text-dark tw-flex tw-flex-col tw-space-y-5p tw-py-10p tw-divide-y tw-divide-dark/20 tw-text-5-alt tw-px-10p">
						<li>
							<span className="tw-flex tw-flex-col tw-w-full tw-items-center tw-text-sm">
								<Span className="tw-w-70p" role="button">{ props.time.split(' - ')[0]}</Span>
								<Span className="tw-px-5p tw-text-center"> - </Span>
								<Span className="tw-w-70p" role="button">{ props.time.split(' - ')[1]}</Span>
							</span>
						</li>
						<li className="tw-pt-10p tw-flex tw-items-center tw-w-full">
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-w-full">
								<Span className="tw-flex-shrink-0">
									{ coverageAmount }
								</Span>
								{showIcon && <Span className="tw-ml-auto || tw-w-16p tw-h-16p tw-aspect-square tw-relative">
									<img className="tw-block tw-absolute tw-w-full tw-h-full tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-object-cover tw-object-center" src={`/images/icon-pie-_${ coverageAmount.replaceAll(' ','-') }.svg`} alt={ coverageAmount } />
								</Span>}
							</span>
						</li>
					</ul>
				</div>

			</> : <>
				<div { ...attributes } className={`tw-text-6 tw-inline-flex tw-w-full tw-flex-col tw-cursor-pointer tw-text-white ${ bannerClass } ${ props.className }`}>
					<div className="tw-leading-none tw-py-5px tw-px-10px tw-font-primary-bold tw-flex-shrink-0 tw-text-6">
						<span className="tw-text-[.9em]">
							{ props.type }
						</span>
					</div>
					<div className="tw-bg-white tw-text-dark tw-flex-grow tw-pt-5px tw-px-10px tw-pb-10px">
						<LabelOutputs icon={ props.alert ? '/images/icon-clock.svg' : null } reverse>
							{ props.time || "8 AM - 2 PM"}
						</LabelOutputs>
					</div>
				</div>
			</> }
		</> }
	</>
}

export const DayBlockHeader = props => {
	const navContext = useAppContext();
	const reqContext = useSpanRequirementContext();
	//const navContext = props.navContext;
	const [dayOfWeek, setDayOfWeek] = useState<any>(null);
	const [dateOfWeek, setDateOfWeek] = useState<number>(null);
	const [month, setMonth] = useState<number>(null);
	const [monthName, setMonthName] = useState<string>(null);
	const [year, setYear] = useState<number>(null);
	const [date, setDate] = useState<number>(null);
	const [highlightDate, setHighlightDate] = useState<any>(false);
	const [fullDate, setFullDate] = useState<string>(null);
	const [rsHours, setRsHours] = useState<number>(0.0);
	const [dsHours, setDsHours] = useState<number>(0.0);

	const attributes = {
		...props,
		className: null,
		day: null,
		date: null,
		children: null,
		changeView: null
	}

	// useEffect(() => {
	// 	console.log('DayBlockHeader');
	// 	console.log(navContext);
	// }, [navContext])

	useEffect(() => {
		console.log('DayBlockHeader');
		console.log(props);
	}, [])

	useLayoutEffect(() => {
		if (props.day) {
			setDayOfWeek(props.day);
		}
	}, [props.day])

	useLayoutEffect(() => {
		if (props.date) {
			setDate(props.date);
			//check if date is today
			if (props.date === moment().format("MM/DD/YYYY")){
				setHighlightDate(true);
				console.log("highlight date");
				console.log(props.date);
			}
		}
	}, [props.date])

	useEffect(() => {
		if (props.rsHours){
			setRsHours(props.rsHours);
		}
	}, [props.rsHours])

	useEffect(() => {
		if (props.dsHours){
			setDsHours(props.dsHours);
		}
	}, [props.dsHours])

	useLayoutEffect(() => {
		if (date) {
			const temp = date.toString().split('/');
			setMonth(Number(temp[0]));
			setDateOfWeek(Number(temp[1]));
			setYear(Number(temp[2]));
			setMonthName(getMonthName(Number(month - 1)));
			console.log( getMonthName(Number(temp[0])) );
			setDayOfWeek(getDayName(getNumOfDayOfWeek(getMonthName(Number(temp[0]) - 1), Number(temp[1]), Number(temp[2]))));
		}
	}, [date]);

	useLayoutEffect(() => {
		if (dayOfWeek && monthName && dateOfWeek && year) {
			setFullDate(`${dayOfWeek}, ${monthName} ${Number(dateOfWeek)}, ${year}`);
		}
	}, [dayOfWeek, monthName, dateOfWeek, year]);

	const initialValues = {
		start_time: '',
		end_time: '',
		shift_type: '',
		allDay: false,
		repeats_monday: false,
		repeats_tuesday: false,
		repeats_wednesday: false,
		repeats_thursday: false,
		repeats_friday: false,
		repeats_saturday: false,
		repeats_sunday: false,
	};

	const initialErrors = {
		start_time: false,
		end_time: false,
		shift_type: false,
		frequency: false,
	};

	const initialErrorMessages = {
		start_time: '',
		end_time: '',
		shift_type: '',
		frequency: '',
	};

	const initialFormStates = {
		start_time: false,
		end_time: false,
		shift_type: false,
		frequency: false,
	};

	const submitForm = (values) => {
		// validate form
		console.log(values);

		var startTime = moment(reqContext.planStartDate + " " + values.start_time);
        var endTime = moment(reqContext.planEndDate + " " + values.end_time);

		const found = reqContext.clientServiceTypes.find(element => element.shiftType === values.shift_type);

		let newEvent : SpanCoverageEvent = {
			spanCoverageEventID: uuid(),
			spanRequirementID: "",
			dayOfWeek: 0,
			startTime: startTime.format(),
			displayStartTime: startTime.format("h:mm A"),
			endTime: endTime.format(),
			displayEndTime: endTime.format("h:mm A"),
			staffSize: found.staffSize,
			groupSize: found.groupSize,
			billingCode: found.billingCode,
			shiftType: found.shiftType,
			allDay: false,
		};

		if(values.repeats_sunday){
			var sundayEvent = JSON.parse(JSON.stringify(newEvent));
			sundayEvent.dayOfWeek = 0;
			reqContext.setSundayRequirements([...reqContext.sundayRequirements, sundayEvent]);
		}
		if(values.repeats_monday){
			var mondayEvent = JSON.parse(JSON.stringify(newEvent));
			mondayEvent.dayOfWeek = 1;
			reqContext.setMondayRequirements([...reqContext.mondayRequirements, mondayEvent]);
		}
		if(values.repeats_tuesday){
			var tuesdayEvent = JSON.parse(JSON.stringify(newEvent));
			tuesdayEvent.dayOfWeek = 2;
			reqContext.setTuesdayRequirements([...reqContext.tuesdayRequirements, tuesdayEvent]);
		}
		if(values.repeats_wednesday){
			var wednesdayEvent = JSON.parse(JSON.stringify(newEvent));
			wednesdayEvent.dayOfWeek = 3;
			reqContext.setWednesdayRequirements([...reqContext.wednesdayRequirements, wednesdayEvent]);
		}
		if(values.repeats_thursday){
			var thursdayEvent = JSON.parse(JSON.stringify(newEvent));
			thursdayEvent.dayOfWeek = 4;
			reqContext.setThursdayRequirements([...reqContext.thursdayRequirements, thursdayEvent]);
		}
		if(values.repeats_friday){
			var fridayEvent = JSON.parse(JSON.stringify(newEvent));
			fridayEvent.dayOfWeek = 5;
			reqContext.setFridayRequirements([...reqContext.fridayRequirements, fridayEvent]);
		}
		if(values.repeats_saturday){
			var saturdayEvent = JSON.parse(JSON.stringify(newEvent));
			saturdayEvent.dayOfWeek = 6;
			reqContext.setSaturdayRequirements([...reqContext.saturdayRequirements, saturdayEvent]);
		}

		

		if (!navContext.showErrorModal) {
			navContext.setSideNavTitle("Success");
			navContext.setSideNav( <SpanRequirmentSuccessPanel saving shift={newEvent} frequency={values}/> );
			navContext.setSideNavActions(null);
			navContext.setSideNavBottom(null);

		}

		reqContext.setRequirementUpdated(true);


	};


	return <>
		<DefaultButton { ...attributes } disabled={props?.hours >= 24} className={`${ props.className } tw-border-b-2px tw-border-b-dark tw-w-full tw-group tw-px-0`} title={ fullDate || props.title || null } childClasses="tw-px-0" onClick={ e => {
			e.preventDefault();
			if (props.changeRequirements) {
				// are we doing anything here?
			} else {
				if(navContext.scheduleAdmin && props?.hours < 24){
				navContext.setSideNavTheme(3);
				navContext.setValues(initialValues);
				navContext.setErrors(initialErrors);
				navContext.setErrorMessages(initialErrorMessages);
				navContext.setFieldStates(initialFormStates);
				navContext.setSideNav( <ScheduleDayPanel changeRequirements={ props.changeRequirements ? true : false } day={ props.day || null } serviceTypes={ props?.serviceTypes || null} patientID={props?.patientID || null} /> );
				// navContext.setSideNavFormik(formik);
				
				// navContext.setSideNavActions({
                //     "ctas": [{
                //         "primary": false,
                //         "name": 'Discard',
                //         onClick: () => {
				// 			//console.log(navContext);
                //             navContext.closeSideNav();
                //         }
                //     }, {
                //         "primary": true,
                //         "name": 'Save',
                //         onClick: () => {
				// 			alert("Success!");
				// 			// const temp = navContext;
				// 			// console.log(temp);

				// 			console.log(navContext);
							
							
				// 			//console.log(navContext?.values);
				// 			//validateForm();
				// 			//navContext?.sideNavFormik.submitForm();
                //             // alert("Success!")
				// 			// console.log(navContext.values);
                //             // navContext.closeSideNav();
                //         }
                //     }]
                // });
				navContext.setSideNavActions(null);
				navContext.setSideNavBottom(<SubmitNewSpanRequirement onSubmit={submitForm} />)
				navContext.openSideNav();
				}
				
			}
		}}>
			{ (dayOfWeek && dateOfWeek) ? <>
				<span className="tw-w-full tw-flex tw-flex-col tw-items-start tw-space-y-5px">
					<span className={`tw-uppercase tw-text-6 tw-tracking-tight tw-leading-none ${highlightDate ? "tw-text-primary-dark": "tw-text-dark-accent"}`}>
						{ dayOfWeek }
					</span>
					<span className={`${highlightDate ? "tw-text-primary-dark": "tw-text-dark"} tw-flex tw-space-x-5px tw-items-end`}>
						<span className="tw-uppercase tw-text-1 tw-font-primary tw-font-primary-bold tw-tracking-tight tw-leading-[.8] tw-flex tw-items-end">
							<span className="tw-text-[1.2em]">
								{ dateOfWeek }
							</span>
						</span>
						<span className="tw-flex tw-flex-col tw-text-sm tw-flex tw-justify-end tw-font-primary-bold tw-items-start">
							<span className="tw-flex tw-flex-col tw-text-[.9em] tw-flex tw-justify-between tw-items-start tw-leading-none">
								<span className="tw-text-primary">
									{rsHours}
								</span>
								<span className="tw-text-accent-dark">
									{dsHours}
								</span>
							</span>
						</span>
					</span>
				</span>
			</> : <>
				{ dayOfWeek ? <>
					<span className={`${ props.changeView ? '' : 'tw-flex-grow tw-flex tw-justify-between' }`}>
						<span className="tw-flex-shrink-0 tw-font-primary-light">
							{ dayOfWeek.substring(0, 3) } - <span className="tw-text-sm tw-text-primary tw-font-primary-bold">{props?.hours || "0"} hrs</span>
						</span>
						{ props.changeView ? <></> : <span className={`${props?.hours < 24 ? "group-hover:tw-bg-accent-dark": ""} tw-inline-flex tw-items-center tw-justify-center tw-aspect-square ${props?.hours < 24 ? "tw-bg-primary": "tw-bg-grey-dark"} tw-text-white tw-rounded-full tw-overflow-hidden tw-text-[1.2em] tw-w-[1em] tw-h-[1em]`}>
							<span className="tw-text-[1.2em] tw-leading-none tw-text-center tw-w-full">
								+
							</span>
						</span> }
					</span>
				</> : <></> }
			</> }
		</DefaultButton>
	</>
}

export const DayBlock = props => {
	const navContext = useAppContext();

	const attributes = {
		...props,
		className: null,
		day: null,
		changeRequirements: null
	}

	const childProps = {
		...props,
		className: null
	}

	// useEffect(() => {
	// 	console.log("DayBlock props", props);
	// }, []);
	
	return <>
		<div { ...attributes } className={`tw-flex tw-flex-col tw-flex-cols tw-cursor-default tw-bg-transparent hover:tw-bg-white/30 ${ props.className }`} title={ props.day }>
			<div className="tw-flex-shrink-0">
				<DayBlockHeader { ...childProps } />
			</div>
			<div className={`${ props.changeView ? '' : 'tw-flex-grow tw-flex tw-flex-col tw-h-px-to-rem-dynamic'}`}>
				{/* <div className={`tw-flex-grow  tw-px-2px ${ props.changeView ? 'tw-grid tw-grid-cols-2 tw-grid-row-[24] tw-items-start tw tw-justify-start tw-place-items-start tw-space-y-5px tw-py-10px' : 'tw-py-20px tw-space-y-15px tw-overflow-x-hidden tw-overflow-y-auto'}`}> */}
				<div className={`tw-flex-grow  tw-px-2px ${ props.changeView ? 'tw-flex tw-flex-col tw-items-start tw tw-justify-start tw-place-items-start tw-py-10px' : 'tw-py-20px tw-space-y-15px tw-overflow-x-hidden tw-overflow-y-auto'}`}>
					{ props.changeView ? <>
						{ props.children && <div className="tw-flex tw-w-full tw-justify-end">
							<div className=" tw-w-1/2 tw-text-6 tw-text-secondary-accent tw-leading-none tw-pb-5px">
								After
							</div>
						</div> }
						<div className="tw-flex tw-w-full">
							{ props.children }
						</div>
					</> : <>
						{ props.children }
					</>}
				</div>
			</div>
		</div>
	</>
}

export const GenericBreadCrumbs = props => {
	const Tag = props.tag || "h2";

	return <>
		<Tag className="tw-text-3 tw-text-dark tw-space-x-5px tw-flex-shrink-0">
			<a href={props.backURL} className="tw-text-dark/70 hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px">
				<LabelOutputs className="tw-text-in" icon="/images/icon-arrow-right.svg" reverse>
					{ props.mainHeading }
				</LabelOutputs>
			</a>
			<a href={ props.url } className="tw-text-dark hover:tw-text-primary tw-inline-flex tw-font-primary-bold tw-items-center tw-min-h-30px">
					<span>
						{` ${props.subHeading}`}
					</span>
				</a>
		</Tag>

	</>

}

export const BreadCumbs = props => {
	const Tag = props.tag || "h2";

	const handleOnClick = e => {
		// e.preventDefault();
		if (props.onClick) props.onClick(e);
	}

	return <>
		<Tag className="tw-text-3 tw-text-dark tw-space-x-5px tw-flex-shrink-0">
			<a href={props.scheduleURL} className="tw-text-dark/70 hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px">
				<LabelOutputs className="tw-text-in" icon="/images/icon-arrow-right.svg" reverse>
					{ props.scheduleName }
				</LabelOutputs>
			</a>
			{ props.changes ?  <>
				<a href={ props.url } onClick={ handleOnClick } className="tw-text-dark/70 hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px">
					<LabelOutputs className="tw-text-in" icon="/images/icon-arrow-right.svg" reverse>
					{` ${props.name.firstName} ${props.name.lastName}`}
					</LabelOutputs>
				</a>
				<b>
					Coverage Changes
				</b>
			</> : <>
				<a href={ props.url } onClick={ handleOnClick } className="tw-text-dark hover:tw-text-primary tw-inline-flex tw-font-primary-bold tw-items-center tw-min-h-30px">
					<span>
						{` ${props.name.firstName} ${props.name.lastName}`}
					</span>
				</a>
			</> }
		</Tag>
	</>
}

export const UserBlock = props => {
	const navContext = useAppContext();
	const [name, setName] = useState<any>(props.name);
	const [started, setStarted] = useState<any>(props.started);
	const [ended, setEnded] = useState<any>(props.ended);
	const [client, setClient] = useState<any>(props.client);
	const [plan, setPlan] = useState<any>(props.plan);
	const [homes, setHomes] = useState<ClientHome[]>(props.homes);
	const [requirement, setRequirement] = useState<any>(props.requirement);
	const [bottomLabel, setBottomLabel] = useState<any>("Manage Client Requirements");

	const handleViewMoreClick = () => {
		console.log("handleViewMoreClick");
		console.log(navContext);
	}

	const handleOnClick = e => {
		e.preventDefault();

		if(!props?.simple) {
			navContext.setPanelSize('tw-max-w-400px');

			navContext.setSideNavTheme(3);

			navContext.setSideNavTitle( "Client Information" )

			navContext.setSideNavBottom(<InfoDefault button={"View More"} buttonEvent={handleViewMoreClick} />)

			navContext.setSideNav( <ClientInfoPanel name={ name } client={client} plan={plan} homes={homes} requirement={requirement}/> )
			//TODO: Identify "id" to pass to client  <ClientInfoPanel />

			navContext.openSideNav();
		}

		

		if (props.onClick) props.onClick(e);
	}

	useLayoutEffect(() => {
		if (props.name) setName(props.name)
	}, [props.name])

	useLayoutEffect(() => {
		if (props.bottomLabel) setBottomLabel(props.bottomLabel)
	}, [props.bottomLabel])

	// useLayoutEffect(() => {
	// 	navContext.setSideNavTitle("")
	// }, [])

	return <>
		<button onClick={ handleOnClick } className="tw-shadow-card tw-appearance-none tw-outline-none tw-border-none  tw-p-0 || tw-w-full || tw-bg-light tw-shadow-card tw-rounded-10p tw-relative tw-flex tw-flex-col tw-group">
			{ props.simple ? <>

				<Block className="tw-py-10p tw-text-dark tw-font-bold tw-w-full">
					<span className="tw-flex tw-items-center tw-justify-start tw-py-5p tw-px-10p tw-space-x-10p">
						<Span className="tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center  tw-flex-shrink-0">
							<AvatarImage src={ props.image || null } className="tw-w-full" size="60" />
						</Span>
						<Span className="tw-flex-grow tw-flex-shrink-0 tw-flex tw-flex-col tw-items-start tw-text-4 ">
							<span className="tw-w-full tw-mb-auto tw-flex tw-justify-start">
								{` ${name.firstName} ${name.lastName}`}
							</span>
							<span className="tw-mt-auto  tw-flex tw-justify-start group-hover:tw-text-primary">
								<LabelOutputs className="tw-text-in" icon="/images/icon-arrow-right.svg" reverse size="15">
									<span className="tw-text-6">
										<span className="tw-font-primary-light">
											{bottomLabel}
										</span>
									</span>
								</LabelOutputs>
							</span>
						</Span>
					</span>
				</Block>
			</> : <>
				<Block className="tw-px-10p tw-pt-5p tw-text-dark tw-font-bold tw-w-full">
					<span className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-5p tw-px-10p tw-space-y-5px">
						<Span className="tw-w-full tw-flex tw-items-center tw-justify-center  tw-flex-shrink-0">
							<AvatarImage src={ props.image || null } className="tw-w-full" size="54" />
						</Span>
						<Span className="tw-flex tw-items-center tw-text-5 group-hover:tw-text-primary">
							{` ${name.firstName} ${name.lastName}`}
						</Span>
					</span>
				</Block>

				<Span className="tw-mt-auto tw-pb-20px tw-w-full tw-space-x-15px tw-flex tw-justify-between tw-px-10px">
					<Span className="tw-text-6">
						<Span className="tw-text-[.75em] tw-font-primary-bold tw-text-dark-light">
							Service Started
						</Span>
						<Span className="tw-text-dark tw-font-primary-bold tw-text-[.9em] tw-flex tw-justify-between tw-space-x-5p tw-items-baseline tw-leading-none">
							<Span>
								{started}
							</Span>
						</Span>
					</Span>
					<Span className="tw-text-6">
						<Span className="tw-text-[.75em] tw-font-primary-bold tw-text-dark-light">
							Service End
						</Span>
						<Span className="tw-text-dark tw-font-primary-bold tw-text-[.9em] tw-flex tw-justify-between tw-space-x-5p tw-items-baseline tw-leading-none">
							<Span>
								{ended}
							</Span>
						</Span>
					</Span>
				</Span>
				<Span className="tw-aspect-square tw-w-20px tw-absolute tw-top-10px tw-right-5px">
					<img className="tw-w-full tw-block" src="/images/icon-arrow-right.svg" />
				</Span>
			</>}

		</button>
	</>
}

export const SupportCoverageBlock = props => {
	const Tag = props.tag || 'div';
	const [theme, setTheme] = useState<any>({ 'color': 'tw-text-accent-dark', 'pieFill': '#DAE1F3', 'pieFill2': '#1241B9'})
	const [total, setTotal] = useState<any>(null);
	const [projected, setProjected] = useState<any>(null);
	const [remaining, setRemaining] = useState<any>(null);
	const [utilized, setUtilized] = useState<any>(null);
	const [percent, setPercent] = useState<number>(null);


	const attributes = {
		...props,
		className: null
	}

	const getPercent = num => Math.round((100 * Number(num)) / 100);

	function limitToTwoDecimals(num: number): number {
		return Math.round(num * 100) / 100;
	}

	useLayoutEffect(() => {

		if (props.type && props.type === "Remote Support") setTheme(({ 'color': 'tw-text-primary', 'pieFill': '#D1EFF0', 'pieFill2': '#12B4B9'}))

		if (props.total) setTotal(props.total);
		if (props.projected) setProjected(props.projected);
		if (props.total && props.utilized) setRemaining(Number(props.total) - Number(props.utilized));
		if (props.projected && props.total) setPercent(((Number(props.projected ) / Number(props.total)) * 100 ));

		if (props.utilized) setUtilized(props.utilized);

	}, [props.type, props.total, props.projected, props.utilized])

	return <>
		<div { ...attributes } className={`tw-bg-light tw-shadow-card tw-rounded-10p ${ props.className }`}>
			<h4 className={`tw-px-10px tw-pt-5px ${ theme.color } tw-font-bold`}>
				<span className="tw-flex tw-items-center tw-py-5p tw-px-10p tw-space-x-10p">
					<Span className="tw-w-20p tw-flex tw-items-center tw-flex-shrink-0">
						<img className="tw-w-full tw-block" src={`/images/icon-${ props.type === 'Remote Support' ? 'support' : 'direct-support' }.svg`} alt="Icon" />
					</Span>
					<Span className="tw-flex tw-items-center">
						{ props.type || 'Direct Support' }{` `} Coverage
					</Span>
				</span>
			</h4>
			<div className={`tw-px-20px tw-pb-10p ${ props.type === 'Remote Support' ? '' : 'tw-mt-10px'} tw-flex tw-justify-between tw-items-center tw-space-x-20px tw-flex-grow`}>
				{ props.simple ? <>
					<span className="tw-flex tw-w-full tw-mt-auto tw-justify-between">
						<span className="tw-text-5 tw-font-primary-bold tw-flex tw-flex-col tw-justify-end tw-leading-none tw-space-y-5p">
							<span className="tw-w-full">
								{props?.weekHours ? limitToTwoDecimals(props?.weekHours*4.3) : 0}h<span className="tw-text-[.75em] tw-text-dark/90 tw-font-primary-light">/Month</span>
							</span>
							<span className="tw-text-[.75em] tw-font-primary-light tw-w-full tw-text-dark/70">
								{props?.month}
							</span>
						</span>
						<span className="tw-text-5 tw-font-primary-bold tw-flex tw-flex-col tw-justify-end tw-leading-none tw-space-y-5p">
							<span className="tw-w-full">
								{props?.weekHours}h<span className="tw-text-[.75em] tw-text-dark/90 tw-font-primary-light">/Week</span>
							</span>
							<span className="tw-text-[.75em] tw-font-primary-light tw-w-full tw-text-dark/70">
								{props?.week}
							</span>
						</span>
					</span>
				</> : <>
					<Span className="tw-flex-shrink-0 tw-basis-1/5">
						<Block className="pie tw-rounded-full tw-w-full tw-min-w-80px tw-min-h-80px tw-aspect-square tw-flex tw-items-center tw-justify-center tw-flex-grow tw-rotate-90" style={{ '--percent': percent, '--fill': theme.pieFill, '--fill-bg': theme.pieFill2 }}>
							<Block className={`tw-text-6 tw-m-5px tw-bg-white tw-w-full tw-aspect-square tw-rounded-full ${ theme.color } tw-flex tw-flex-col tw-items-center tw-justify-center -tw-rotate-90`}>
								<Block className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-[.9em] tw-font-primary-bold">
									<Span>
										{ getPercent(percent) }%
									</Span>
									<Span>
										projected
									</Span>
								</Block>
							</Block>
						</Block>
					</Span>
					<Span className="tw-basis-4/5 tw-flex-grow tw-text-6 tw-py-15px">
						<Span className="tw-grid tw-grid-rows-2 tw-grid-cols-2 tw-gap-y-15px tw-gap-x-20px">
							<Span className="tw-text-6">
								<Span className="tw-text-[.75em] tw-font-primary-bold tw-text-dark-light">
									Projected
								</Span>
								<Span className="tw-text-dark tw-font-primary-bold tw-text-[.9em] tw-flex tw-justify-between tw-space-x-5p tw-items-baseline tw-leading-none">
									<Span>
										{projected}h
									</Span>
									<Span className={`${ theme.color } tw-font-primary-normal`}>
										{ getPercent(percent) }%
									</Span>
								</Span>
							</Span>
							<Span className="tw-text-6">
								<Span className="tw-text-[.75em] tw-font-primary-bold tw-text-dark-light">
									Remaining
								</Span>
								<Span className="tw-text-dark tw-font-primary-bold tw-text-[.9em] tw-flex tw-justify-between tw-space-x-5p tw-items-baseline tw-leading-none">
									<Span>
										{remaining}h
									</Span>
									<Span className={`${ theme.color } tw-font-primary-normal`}>
										{ getPercent( Number(remaining) / Number(total) * 100 ) }%
									</Span>
								</Span>
							</Span>
							<Span className="tw-text-6">
								<Span className="tw-text-[.75em] tw-font-primary-bold tw-text-dark-light">
									Utilized
								</Span>
								<Span className="tw-text-dark tw-font-primary-bold tw-text-[.9em] tw-flex tw-justify-between tw-space-x-5p tw-items-baseline tw-leading-none">
									<Span>
										{utilized}h
									</Span>
								</Span>
							</Span>
							<Span className="tw-text-6">
								<Span className="tw-text-[.75em] tw-font-primary-bold tw-text-dark-light">
									Total Budget
								</Span>
								<Span className="tw-text-dark tw-font-primary-bold tw-text-[.9em] tw-flex tw-justify-between tw-space-x-5p tw-items-baseline tw-leading-none">
									<Span>
										{total}h
									</Span>
								</Span>
							</Span>
						</Span>
					</Span>
				</>}
			</div>
		</div>
	</>
}

export const OriginalTimes = props => {
	const attributes = {
		className: null,
		children: null
	}
	return <>
		<div { ...attributes } className={`${props.className} tw-flex tw-flex-col originalTimes tw-w-full tw-space-y-5px tw-w-1/2 tw-basis-1/2 tw-flex-grow tw-shrink-0`}>
			{ props.children }
		</div>
	</>
}

export const UpdatedTimes = props => <OriginalTimes className="tw-ml-auto updatedTimes"> { props.children } </OriginalTimes>;


export const CoverageChangesItem = props => {
	const navContext = useAppContext();
	const [isActive, setIsActive] = useState<boolean>(false);

	const attributes = {
		...props,
		className: null
	}

	const handleToggle = e => {
		let temp = isActive;
		setIsActive(temp = !temp);
	}

	return <>
		<div { ...attributes } className={`tw-rounded-3p tw-overflow-hidden tw-bg-light tw-text-dark ${ isActive ? '' : 'tw-cursor-pointer' } ${ props.className }`} onClick={ (e) => {
				if (!isActive) handleToggle(e);
			}}>
			<div className="tw-w-full tw-flex tw-space-10px tw-py-20px">
				<div className="tw-flex tw-items-center tw-text-5 tw-w-300px tw-pl-30px">
					<span className="tw-w-[3.75rem]">
						Before
					</span>
					<span className="tw-text-3 tw-text-dark-light tw-font-primary tw-font-primary-bold">
						40 hours
					</span>
				</div>
				<div className="tw-flex tw-items-center tw-text-5 tw-w-200px">
					<span className="tw-w-[3.75rem]">
						Before
					</span>
					<span className="tw-text-3 tw-text-secondary-accent tw-font-primary tw-font-primary-bold">
						40 hours
					</span>
				</div>
				<div className="tw-flex tw-items-center tw-text-5 tw-w-200px">
					Liam, Brooks
				</div>
				<div className="tw-ml-auto tw-pr-30px tw-flex tw-items-center tw-space-x-10px">
					<div className="tw-flex tw-items-center tw-text-5 tw-flex-grow">
						02/21/2022 10:55AM
					</div>
					<div className="tw-flex tw-justify-end tw-items-center tw-flex-shrink-0">
						<DefaultButton className="tw-w-40px tw-h-40px" onClick={ e => {
							handleToggle(e);
							e.preventDefault();
						}}>
							<img className="tw-w-15px" src={`/images/icon-arrow-${ isActive ? 'up' : 'down-2' }.svg`} />
						</DefaultButton>
					</div>
				</div>
			</div>
			{ isActive && <>
				<div className="tw-px-30px">
					<div className="tw-grid tw-grid-cols-7 tw-space-x-20px tw-w-full">
						<DayBlock changeView className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Sunday">
							<OriginalTimes>
								<DataBlock change type="Remote Support" time="8am - 10am" />
								<DataBlock change type="Direct Support" time="10am - 12pm" />
								<DataBlock change type="Remote Support" time="12pm - 2pm" />
							</OriginalTimes>
							<UpdatedTimes>
								<DataBlock change type="Remote Support" time="7am - 9am" alert />
								<DataBlock change type="Remote Support" time="9am - 2pm" alert />
							</UpdatedTimes>
						</DayBlock>

						<DayBlock changeView className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Monday">
							<OriginalTimes>
								<DataBlock change type="Direct Support" time="8am - 10am" />
								<DataBlock change type="Direct Support" time="10am - 2pm" />
							</OriginalTimes>
							<UpdatedTimes>
								<DataBlock change type="Direct Support" time="8am - 2pm" />
							</UpdatedTimes>
						</DayBlock>

						<DayBlock changeView className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Tuesday">
						</DayBlock>

						<DayBlock changeView className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Wednesday">
							<OriginalTimes>
								<DataBlock change type="Remote Support" time="8am - 2pm" />
								<DataBlock change type="Direct Support" time="8am - 2pm" />
							</OriginalTimes>
							<UpdatedTimes>
								<DataBlock change type="Remote Support" time="2pm - 4pm" alert />
								<DataBlock change type="Direct Support" time="2pm - 4pm" alert />
								<DataBlock change type="Remote Support" time="2pm - 4pm" />
								<DataBlock change type="Direct Support" time="2pm - 4pm" alert />
							</UpdatedTimes>
						</DayBlock>

						<DayBlock changeView className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Thursday">

						</DayBlock>

						<DayBlock changeView className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Friday">
							<OriginalTimes>
								<DataBlock change type="Remote Support" time="8am - 2pm" />
							</OriginalTimes>
							<UpdatedTimes>
								<DataBlock change type="Remote Support" time="2pm - 4pm" alert />
							</UpdatedTimes>
						</DayBlock>

						<DayBlock changeView className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Saturday">
						</DayBlock>
					</div>
				</div>
				<div className="tw-px-30px tw-space-y-5px tw-py-30px">
					<h4 className="tw-text-4 tw-text-dark">
						<LabelOutputs icon="/images/icon-message.svg" className="tw-opacity-70">
							Reason for changes
						</LabelOutputs>
					</h4>
					<div className="tw-bg-light-accent tw-py-8px tw-px-10px tw-rounded-3p tw-text-dark">
						Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus, atque.
					</div>
					<div className="tw-flex tw-justify-between tw-pt-25px">
						<ButtonSecondary onClick={ (e) => {
							setIsActive(false)
						}}>
							Close
						</ButtonSecondary>

						<ButtonPrimary>
							Revert
						</ButtonPrimary>
					</div>
				</div>
			</>}
		</div>
	</>
}

export const CoverageChangesBlock = props => {
	return <>
		<div className="tw-space-y-15px">
			{ [...Array(3)].map( (item, index) => <CoverageChangesItem key={`CoverageChangesItem_${index}`} /> ) }
		</div>
	</>
}