import moment from "moment";
import { useLayoutEffect, useState } from "react";

const ConfirmSessionChangesPanel = props => {
    const [selectedSession, setSelectedSession] = useState(null);
    
    const [updatedSession, setUpdatedSession] = useState(null);

    useLayoutEffect(() => {
        if (props.selectedSession) {
            setSelectedSession(props?.selectedSession);
        }
        if (props.updatedSession){
            setUpdatedSession(props?.updatedSession);
        }
	}, [props.selectedSession, props.updatedSession]);

    
    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
            <div className="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
                    <div className="tw-flex-shrink-0 tw-w-full">
                        <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className="tw-text-dark">
                                        Previous Session Details
                                    </span>
                                </span>
                            </span>
                        </h3>
                        <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Provider
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.providerName}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Status
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.status}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Start Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayStartDate}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        End Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayEndDate}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Time Period
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayStartTime} - {selectedSession?.displayEndTime}
                                </span>
                                {!moment(selectedSession?.startDate).isSame(selectedSession?.endDate) && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
                                </span>}
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Training
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.training ? 'Yes' : 'No'}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className="tw-flex-shrink-0 tw-w-full">
                        <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className="tw-text-dark">
                                        New Session Details
                                    </span>
                                </span>
                            </span>
                        </h3>
                        <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Provider
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {updatedSession?.providerName}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Status
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {updatedSession?.status}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Start Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {updatedSession?.displayStartDate}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        End Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {updatedSession?.displayEndDate}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Time Period
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {updatedSession?.displayStartTime} - {updatedSession?.displayEndTime}
                                </span>
                                {!moment(updatedSession?.startDate).isSame(updatedSession?.endDate) && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
                                </span>}
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Training
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {updatedSession?.training ? 'Yes' : 'No'}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default ConfirmSessionChangesPanel;