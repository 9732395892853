import { InformationCircleIcon } from "@heroicons/react/24/solid"

export const InfoAlert = props => {
    return(<>
        <div className="tw-rounded-md tw-bg-blue-50 tw-p-4">
        <div className="tw-flex">
            <div className="tw-flex-shrink-0">
            <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-blue-400" aria-hidden="true" />
            </div>
            <div className="tw-ml-3 tw-flex-1 md:tw-flex md:tw-justify-between">
            <p className="tw-text-sm tw-text-blue-700">{props.message}</p>
            {/* <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <a href="#" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                Details
                <span aria-hidden="true"> &rarr;</span>
                </a>
            </p> */}
            </div>
        </div>
        </div>
    </>)
}