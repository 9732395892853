import { ChevronRightIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon, ChevronUpIcon, UserIcon, ClipboardDocumentListIcon, UserGroupIcon, DocumentIcon, MapPinIcon, PhoneIcon, IdentificationIcon, BuildingOfficeIcon, CalendarDaysIcon, DocumentTextIcon, ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import { useLayoutEffect, useRef, useState } from "react";
import { getClient } from "../apicalls/clientMgmtService";
import { useNavigate, useParams } from "react-router-dom";
import Patient from "../types/patient";
import { CircularProgress } from "@mui/material";
import { ButtonPrimary, ButtonSecondary, Label, Select } from "@medforall/medforall-component-library";
import ServicePlan from "../types/servicePlan";
import { getActiveServicePlan, getServicePlansForPatient } from "../apicalls/servicePlanService";
import moment from "moment";
import { useAppContext } from "../Context";
import { EditClientPersonalInfo } from "../components/portalComponents/editClientPersonalInfo";
import { EditClientContactsCard } from "../components/portalComponents/editClientContactsCard";
import { EditClientInsuranceCard } from "../components/portalComponents/editClientInsuranceCard";
import { EditClientEVVServicesCard } from "../components/portalComponents/editClientEVVServicesCard";
import { getServiceLocationsByPatientID } from "../apicalls/serviceLocationService";
import ServiceLocation from "../types/serviceLocation";
import { EditLocationAddressCard } from "../components/portalComponents/editLocationAddressCard";
import { EditLocationRoomsCard } from "../components/portalComponents/editLocationRoomsCard";
import { EditLocationSmartHubsCard } from "../components/portalComponents/editLocationSmartHubCard";
import { EditLocationSmartSensorsCard } from "../components/portalComponents/editLocationSmartSensorCard";
import { EditLocationSmartCamerasCard } from "../components/portalComponents/editLocationSmartCameraCard";
import { EditLocationCredentialsCard } from "../components/portalComponents/editLocationCredentialsCard";
import { set } from "lodash";

const pages = [
    { name: 'Clients', href: '#', current: false },
    { name: 'Chelsea, B', href: '#', current: true },
  ]

const EditClientPage = props => {
    const [patient, setPatient] = useState<Patient>();
    const [activePlan, setActivePlan] = useState<ServicePlan>(null);
    const [draftPlan, setDraftPlan] = useState<ServicePlan>(null);
    const [archivedPlans, setArchivedPlans] = useState<ServicePlan[]>([]);
    const [serviceLocations, setServiceLocations] = useState<ServiceLocation[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<ServiceLocation>(null);
    const locationRef = useRef() || { current: { value: ''}};
    const { clientID } = useParams();
    const [clientLoading, setClientLoading] = useState(true);
    let navigate = useNavigate();
    const [clientDetailsCards, setClientDetailsCards] = useState({
        expandInfoCard: true,
        expandServicePlanCard: true,
        expandTeamCard: true,
        expandServiceLocationCard: true,
        expandDocumentCard: true,
    });
    const [highlightCards, setHighlightCards] = useState({
        highlightInfoCard: true,
        highlightServicePlanCard: false,
        highlightTeamCard: false,
        highlightServiceLocationCard: false,
        highlightDocumentCard: false,
    });

    const appContext = useAppContext();

    const [showArchivedPlans, setShowArchivedPlans] = useState(false);

    const showArchivedServicePlans = () => {
        setShowArchivedPlans(true);
    }

    const hideArchivedServicePlans = () => {
        setShowArchivedPlans(false);
    }

    const showServicePlanDetails = () => {
        setClientDetailsCards({
            expandInfoCard: false,
            expandServicePlanCard: false,
            expandTeamCard: false,
            expandServiceLocationCard: false,
            expandDocumentCard: false,
        });

        setHighlightCards({
            highlightInfoCard: false,
            highlightServicePlanCard: true,
            highlightTeamCard: false,
            highlightServiceLocationCard: false,
            highlightDocumentCard: false,
        });
    }

    const showClientInfoDetails = () => {
        setClientDetailsCards({
            expandInfoCard: true,
            expandServicePlanCard: true,
            expandTeamCard: true,
            expandServiceLocationCard: true,
            expandDocumentCard: true,
        });

        setHighlightCards({
            highlightInfoCard: true,
            highlightServicePlanCard: false,
            highlightTeamCard: false,
            highlightServiceLocationCard: false,
            highlightDocumentCard: false,
        });
    }

    const showServiceLocationDetails = () => {
        setClientDetailsCards({
            expandInfoCard: false,
            expandServicePlanCard: false,
            expandTeamCard: false,
            expandServiceLocationCard: true,
            expandDocumentCard: false,
        });

        setHighlightCards({
            highlightInfoCard: false,
            highlightServicePlanCard: false,
            highlightTeamCard: false,
            highlightServiceLocationCard: true,
            highlightDocumentCard: false,
        });
    }

    useLayoutEffect(() => {
        console.log("Client ID");
        console.log(clientID);

        const fetchPatientDetails = async () => {
            var client = await getClient(clientID, appContext.handleAPICallError);
            console.log(client);
            setPatient(client);

            await fetchServicePlans();

            await fetchServiceLocations();

            setClientLoading(false);
        }

        // const fetchActivePlan = async () => {
        //     var activePlan = await getActiveServicePlan(clientID);
        //     console.log(activePlan);
        //     activePlan.displayStartDate = moment.utc(activePlan.startDate).format("MM/DD/YYYY");
        //     activePlan.displayEndDate = moment.utc(activePlan.endDate).format("MM/DD/YYYY");
        //     setActivePlan(activePlan);
        // }

        const fetchServicePlans = async () => {
            var servicePlans = await getServicePlansForPatient(clientID, appContext.handleAPICallError);
            console.log(servicePlans);
            if(servicePlans) {
                var archivedPlans = servicePlans.filter(plan => plan.status === "Archived");
                setArchivedPlans(archivedPlans);
                var draftPlan = servicePlans.filter(plan => plan.status === "Draft");
                if(draftPlan.length !== 0){
                    setDraftPlan(draftPlan[0]);
                }
                var activePlan = servicePlans.filter(plan => plan.status === "Active");
                if(activePlan.length !== 0){
                    setActivePlan(activePlan[0]);
                }
            }

            //setClientLoading(false);
        }

        const fetchServiceLocations = async () => {
            var serviceLocations = await getServiceLocationsByPatientID(clientID, appContext.handleAPICallError);
            console.log(serviceLocations);
            setServiceLocations(serviceLocations);
        }



        fetchPatientDetails()
            .catch(console.error);

        // fetchActivePlan()
        //     .catch(console.error);

        // fetchServicePlans()
        //     .catch(console.error);
        
    },[])

    const updateSelectedLocation = (locationID: string) => {
        if(locationID === "new"){
            setSelectedLocation(null);
            goToNewServiceLocation();
        } else {
            var location = serviceLocations.filter(location => location.serviceLocationID === locationID);
            setSelectedLocation(location[0]);
            showServiceLocationDetails();
        }
    }


    const refreshClientInfo = async () => {
        setClientLoading(true);
        var client = await getClient(clientID, appContext.handleAPICallError);
        console.log(client);
        setPatient(client);
        setClientLoading(false);
    }

    const refreshClientLocations = async (locationID: string) => {
        //setClientLoading(true);
        var serviceLocations = await getServiceLocationsByPatientID(clientID, appContext.handleAPICallError);
        console.log(serviceLocations);
        var updatedLocation = serviceLocations.filter(location => location.serviceLocationID === locationID)[0];
        console.log("Updated Location");
        console.log(updatedLocation);
        setSelectedLocation(updatedLocation);
        // setServiceLocations(serviceLocations.map(
        //     el => el.serviceLocationID ===  updatedLocation.serviceLocationID? { ...el, ...updatedLocation } : el
        // ));
        // //locationRef?.current.value = updatedLocation.serviceLocationID;
        // updateSelectedLocation(updatedLocation.serviceLocationID);
        // setClientLoading(false);
    }

    const goToClientManagement = () => {
        navigate(`/client/client-management`);
    }

    const goToNewServiceLocation = () => {
        navigate(`/client/${clientID}/serviceLocation/new`);
    }

    const goToEditServicePlan = (planID: string) => {
        console.log("Go To Edit Service Plan");
        navigate(`/client/${clientID}/servicePlan/${planID}/edit`);
    }

    const goToNewServicePlan = () => {
        navigate(`/client/${clientID}/servicePlan/new`);
    }

   

    return (<>
        <div className="tw-px-xl tw-py-xl">
            { clientLoading ? <>
                <div className="tw-flex tw-justify-center tw-m-md">
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                </div>
            </> :
            <>
            <div className="tw-pb-5">
                <nav className="tw-flex" aria-label="Breadcrumb">
                    <ol role="list" className="tw-flex tw-items-center tw-space-x-4">
                            <li key='Clients'>
                                <div className="tw-flex tw-items-center">
                                <a href="/client/client-management"
                                    className="tw-ml-4 tw-text-lg tw-font-medium tw-text-gray-500 hover:tw-text-gray-700"
                                >
                                    Clients
                                </a>
                                </div>
                            </li>
                            <li key='ClientID'>
                                <div className="tw-flex tw-items-center">
                                <ChevronRightIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-900" aria-hidden="true" />
                                <a
                                    className="tw-ml-4 tw-text-lg tw-font-semibold tw-text-gray-900 hover:tw-text-gray-700"
                                >
                                    {patient?.firstName} {patient?.lastName.charAt(0)}.
                                </a>
                                </div>
                            </li>
                    </ol>
                </nav>
            </div>

            <div className="tw-sticky tw-top-0 tw-px-2 tw-pt-2 tw-bg-grey-light">
                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${highlightCards.highlightInfoCard ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`} onClick={showClientInfoDetails}>
                        <div className="tw-flex">
                            <UserIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Basic Information</span>
                            {clientDetailsCards.expandInfoCard ? 
                            <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />:
                            <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                        </div>
                        {clientDetailsCards.expandInfoCard && 
                            <div className="tw-flex tw-py-5">
                                <div className="tw-w-1/5">
                                            <span className="tw-inline-flex tw-h-20 tw-w-20 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-500 tw-cursor-pointer">
                                                <span className="tw-font-medium tw-leading-none tw-text-white">{patient?.firstName.charAt(0).toUpperCase()}{patient?.lastName.charAt(0).toUpperCase()}</span>
                                            </span>
                                        {/* <img
                                        className="tw-inline-block tw-h-20 w-20 tw-rounded-full"
                                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        alt=""
                                    /> */}
                                </div>
                                <div className="tw-w-4/5">
                                    <div className="tw-flex">
                                        <IdentificationIcon className="tw-h-4 tw-w-4 tw-shrink-0 tw-mx-2 tw-my-1" aria-hidden="true" />
                                        <span className="tw-text-base tw-leading-6 tw-text-gray-500 tw-w-2/5">Name</span>
                                        <span className="tw-text-base tw-leading-6 tw-text-gray-900 tw-flex-grow">{patient?.firstName}, {patient?.lastName}</span>
                                    </div>
                                    <div className="tw-flex">
                                        <PhoneIcon className="tw-h-4 tw-w-4 tw-shrink-0 tw-mx-2 tw-my-1" aria-hidden="true" />
                                        <span className="tw-text-base tw-leading-6 tw-text-gray-500 tw-w-2/5">Phone</span>
                                        <span className="tw-text-base tw-leading-6 tw-text-gray-900 tw-flex-grow">{patient?.phone}</span>
                                    </div>
                                    <div className="tw-flex">
                                        <BuildingOfficeIcon className="tw-h-4 tw-w-4 tw-shrink-0 tw-mx-2 tw-my-1" aria-hidden="true" />
                                        <span className="tw-text-base tw-leading-6 tw-text-gray-500 tw-w-2/5">County</span>
                                        <span className="tw-text-base tw-leading-6 tw-text-gray-900 tw-flex-grow">{patient?.county}</span>
                                    </div>
                                </div>
                            
                            </div>
                        }   
                    </div>

                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${highlightCards.highlightServicePlanCard ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`} onClick={showServicePlanDetails}>
                        <div className="tw-flex">
                            <ClipboardDocumentListIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Service Plan</span>
                            {clientDetailsCards.expandServicePlanCard ? 
                            <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />:
                            <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                        </div>
                        {clientDetailsCards.expandServicePlanCard &&
                            <>
                            {(!activePlan && !draftPlan) ? <>
                                <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg tw-m-2">
                                    <div className="tw-flex tw-items-center tw-cursor-pointer" onClick={goToNewServicePlan}>
                                        <PlusCircleIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-primary tw-flex-grow">Add Info</span>
                                    </div>
                                </div>

                                </>:<>
                                <div className="tw-flex tw-py-5">
                                    <div className="tw-w-1/5">
                                        <div className="tw-flex">
                                            <span className="tw-text-base tw-leading-6 tw-text-gray-500">Status</span>
                                        </div>
                                        <div className="tw-flex tw-mt-2">
                                        {activePlan && <>
                                        <span className="tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-md tw-bg-green-100 tw-px-2 tw-py-1 tw-text-base tw-font-medium tw-text-green-700">
                                            <svg className="tw-h-3 tw-w-3 tw-fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                                            <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            Active
                                        </span>
                                        </>}

                                        {draftPlan && !activePlan &&  <>
                                        <span className="tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-md tw-bg-gray-100 tw-px-2 tw-py-1 tw-text-base tw-font-medium tw-text-gray-700">
                                            <svg className="tw-h-3 tw-w-3 tw-fill-gray-500" viewBox="0 0 6 6" aria-hidden="true">
                                            <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            Draft
                                        </span>
                                        </>} 
                                        
                                        </div>
                                    </div>
                                    <div className="tw-w-4/5">
                                            {activePlan && <>
                                                <div className="tw-flex">
                                                    <IdentificationIcon className="tw-h-4 tw-w-4 tw-shrink-0 tw-mx-2 tw-my-1" aria-hidden="true" />
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-500 tw-w-2/5">Fiscal Year</span>
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-900 tw-flex-grow">{activePlan?.fiscalYear}</span>
                                                </div>
                                                <div className="tw-flex">
                                                    <CalendarDaysIcon className="tw-h-4 tw-w-4 tw-shrink-0 tw-mx-2 tw-my-1" aria-hidden="true" />
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-500 tw-w-2/5">Span Starts</span>
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-900 tw-flex-grow">{activePlan?.displayStartDate}</span>
                                                </div>
                                                <div className="tw-flex">
                                                    <CalendarDaysIcon className="tw-h-4 tw-w-4 tw-shrink-0 tw-mx-2 tw-my-1" aria-hidden="true" />
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-500 tw-w-2/5">Span Ends</span>
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-900 tw-flex-grow">{activePlan?.displayEndDate}</span>
                                                </div>
                                            </>}

                                            {(draftPlan && !activePlan) && <>
                                                <div className="tw-flex">
                                                    <IdentificationIcon className="tw-h-4 tw-w-4 tw-shrink-0 tw-mx-2 tw-my-1" aria-hidden="true" />
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-500 tw-w-2/5">Fiscal Year</span>
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-900 tw-flex-grow">{draftPlan?.fiscalYear}</span>
                                                </div>
                                                <div className="tw-flex">
                                                    <CalendarDaysIcon className="tw-h-4 tw-w-4 tw-shrink-0 tw-mx-2 tw-my-1" aria-hidden="true" />
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-500 tw-w-2/5">Span Starts</span>
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-900 tw-flex-grow">{draftPlan?.displayStartDate}</span>
                                                </div>
                                                <div className="tw-flex">
                                                    <CalendarDaysIcon className="tw-h-4 tw-w-4 tw-shrink-0 tw-mx-2 tw-my-1" aria-hidden="true" />
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-500 tw-w-2/5">Span Ends</span>
                                                    <span className="tw-text-base tw-leading-6 tw-text-gray-900 tw-flex-grow">{draftPlan?.displayEndDate}</span>
                                                </div>
                                            </>}
                                            
                                    </div>
                                </div>
                                </>}
                            </>
                            
                        }
                        
                        
                    </div>
                </div>

                <div className="tw-grid tw-grid-cols-3 tw-gap-6 tw-py-5">
                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${highlightCards.highlightTeamCard ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`}>
                        <div className="tw-flex">
                            <UserGroupIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Team</span>
                            {clientDetailsCards.expandTeamCard ? 
                            <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />:
                            <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                        </div>
                        {clientDetailsCards.expandTeamCard &&
                        <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg tw-m-2">
                            <div className="tw-flex tw-items-center">
                                <PlusCircleIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-primary tw-flex-grow">Add Info</span>
                            </div>
                        </div>
                        }

                    </div>

                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${highlightCards.highlightServiceLocationCard ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`}>
                        <div className="tw-flex">
                            <MapPinIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Service Location</span>
                            {clientDetailsCards.expandServiceLocationCard ? 
                            <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />:
                            <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                        </div>
                        {clientDetailsCards.expandServiceLocationCard &&
                        <>
                            {serviceLocations && serviceLocations.length > 0 ? <div className="">
                            <div className="tw-max-w-300px tw-mt-2">
                                <div className="tw-relative">
                                    <Label for="locations" icon="/images/icon-location.svg" className="tw-z-0 tw-absolute tw-left-15px tw-top-12px  tw-items-center">
                                        <span className="tw-hidden">Locations</span>
                                    </Label>
                                    <Select id="locations" className="tw-w-full !tw-bg-transparent tw-relative tw-z-2 !tw-border-dark" childClasses="!tw-pl-40px !tw-bg-transparent !tw-border-dark tw-whitespace-pre-wrap"
                                        ref={ locationRef } onChange={ e => {
                                            console.log(e, e.target, e.target.value, selectedLocation)
                                            updateSelectedLocation(locationRef?.current?.value);
                                            e.preventDefault();
                                        }}>
                                        <option value="">Select Location</option>
                                        {serviceLocations.map((location, index) => {
                                            return <option key={index} value={location.serviceLocationID}>{location.physicalAddress.street1}, {location.physicalAddress.street2.length > 0 ? location.physicalAddress.street2 + ", ": ""} {location.physicalAddress.city}, {location.physicalAddress.state} - {location.physicalAddress.zip} </option>
                                        })}
                                        {/* <option>875 North High Street, Suite 300, Columbus, OH - 43215</option> */}
                                        <option value={"new"}>Add Location</option>
                                        {/* <option><span><br /> </span></option> */}
                                    </Select>
                                </div>
                            </div>
                        </div> : <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg tw-m-2">
                            <div className="tw-flex tw-items-center tw-cursor-pointer" onClick={goToNewServiceLocation}>
                                <PlusCircleIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-primary tw-flex-grow">Add Info</span>
                            </div>
                        </div> }
                        </>
                        
                        
                        
                        }
                    </div>

                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${highlightCards.highlightDocumentCard ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`}>
                        <div className="tw-flex">
                            <DocumentIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Documents</span>
                            {clientDetailsCards.expandDocumentCard ? 
                            <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />:
                            <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                        </div>
                        {clientDetailsCards.expandDocumentCard &&
                        <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg tw-m-2">
                            <div className="tw-flex tw-items-center">
                                <PlusCircleIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-primary tw-flex-grow">Add Info</span>
                            </div>
                        </div>
                        }
                    </div>
                </div>

                <div className="tw-w-full tw-border-t tw-border-gray-300 tw-mt-2" />

            </div>

            {highlightCards.highlightInfoCard && <>
                <div className="tw-space-y-6 tw-mt-4">
                   <EditClientPersonalInfo patient={patient} refreshClient={refreshClientInfo} />

                   <EditClientContactsCard contacts={patient?.contacts} patientID={patient?.patientID} refreshClient={refreshClientInfo}/>

                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6">

                        <EditClientInsuranceCard insurance={patient?.insurance} refreshClient={refreshClientInfo}/>

                        <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                        <EditClientEVVServicesCard services={patient?.evvServices} patientID={patient?.patientID} refreshClient={refreshClientInfo}/>
                        
                    </div>
                </div>
            </>}

            {highlightCards.highlightServicePlanCard && <>
            
                <div className="tw-space-y-6 tw-mt-4">
                
                {activePlan &&
                <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow sm:tw-rounded-lg sm:tw-p-6">
                    <div className="tw-flex">
                        {/* <ClipboardDocumentListIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" /> */}
                        <div className="tw-py-0 tw-px-2">
                        <svg className="tw-h-3 tw-w-3 tw-fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                                            <circle cx={3} cy={3} r={3} />
                                            </svg>
                            </div>
                        
                        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Active ISP</span>
                        <ButtonSecondary className="tw-mx-4" onClick={() => goToEditServicePlan(activePlan?.servicePlanID)}>
                                            Edit
                                    </ButtonSecondary>
                    </div>
                    <div className="tw-bg-white tw-border-2 tw-border-primary tw-shadow tw-rounded-lg tw-p-6 tw-mt-4">
                        <div className="tw-flex tw-px-4 tw-py-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Fiscal Year</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{activePlan?.fiscalYear}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Waiver Type</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{activePlan?.waiverType}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Starts</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{activePlan?.displayStartDate}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Ends</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{activePlan?.displayEndDate}</span>
                        </div>
                        {/* <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>History</span>
                        </div>
                        <div className="tw-flex tw-px-6 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>02/01/2023</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet nulla malesuada pellentesque elit eget gravida.</span>
                        </div>
                        <div className="tw-flex tw-px-6 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>02/11/2023</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet nulla malesuada pellentesque elit eget gravida.</span>
                        </div>
                        <div className="tw-flex tw-px-6 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>03/01/2023</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Imperdiet nulla malesuada pellentesque elit eget gravida.</span>
                        </div> */}
                    </div>

                    
                </div>}
                </div>

                <div className="tw-space-y-6 tw-mt-4">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow sm:tw-rounded-lg sm:tw-p-6">
                        <div className="tw-flex">
                            {/* <DocumentTextIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" /> */}
                            <div className="tw-py-0 tw-px-2">
                                <svg className="tw-h-3 tw-w-3 tw-fill-gray-500" viewBox="0 0 6 6" aria-hidden="true">
                                            <circle cx={3} cy={3} r={3} />
                                            </svg>
                            </div>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Draft ISP</span>
                            {!draftPlan && <ButtonPrimary className="tw-mx-4" onClick={goToNewServicePlan}>
                                                    Add New Draft Plan
                                        </ButtonPrimary>}
                        </div>

                        {draftPlan ? <>
                <div className="tw-bg-white hover:tw-border-2 hover:tw-border-primary tw-px-4 tw-py-5 tw-shadow sm:tw-rounded-lg sm:tw-p-6 tw-cursor-pointer">
                            <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mx-4" onClick={() => goToEditServicePlan(draftPlan?.servicePlanID)}>
                                                    Edit
                                        </ButtonSecondary>
                            </div>
                        <div className="tw-flex tw-px-4 tw-py-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Fiscal Year</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{draftPlan?.fiscalYear}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Waiver Type</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{draftPlan?.waiverType}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Starts</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{draftPlan?.displayStartDate}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Ends</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{draftPlan?.displayEndDate}</span>
                        </div>
                    

                    
                </div>
                </>:<>
                <div className="tw-flex tw-justify-end tw-mx-4">
                                        
                </div>
                </>}
                    </div>
                </div>

                <div className="tw-space-y-6 tw-mt-4">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow sm:tw-rounded-lg sm:tw-p-6">
                        <div className="tw-flex">
                            {/* <ClipboardDocumentCheckIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" /> */}
                            <div className="tw-py-0 tw-px-2">
                                <svg className="tw-h-3 tw-w-3 tw-fill-black" viewBox="0 0 6 6" aria-hidden="true">
                                                    <circle cx={3} cy={3} r={3} />
                                                    </svg>
                            </div>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Archived ISPs ({archivedPlans?.length})</span>
                            {showArchivedPlans ? <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={hideArchivedServicePlans}/>: <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={showArchivedServicePlans} />}
                            
                            
                        </div>

                        {showArchivedPlans && archivedPlans && archivedPlans.map((plan, index) => {
                            return (<>
                                <div className="tw-bg-card-green hover:tw-border-2 hover:tw-border-primary tw-shadow tw-rounded-lg tw-p-6 tw-mt-4 tw-cursor-pointer">
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mx-4" onClick={() => goToEditServicePlan(plan.servicePlanID)}>
                                                                View
                                                    </ButtonSecondary>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-py-2">
                                            <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Fiscal Year</span>
                                            <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{plan.fiscalYear}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Waiver Type</span>
                                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{plan.waiverType}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Starts</span>
                                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{plan.displayStartDate}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Ends</span>
                                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{plan.displayEndDate}</span>
                                        </div>
                                    </div>
                            </>);
                        })}


                        

                        {/* <div className="tw-bg-card-green hover:tw-border-2 hover:tw-border-primary tw-shadow tw-rounded-lg tw-p-6 tw-mt-4 tw-cursor-pointer">
                            <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mx-4">
                                                    View
                                        </ButtonSecondary>
                            </div>
                            <div className="tw-flex tw-px-4 tw-py-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Fiscal Year</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>2021</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Waiver Type</span>
                                    <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>Individualized Option</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Starts</span>
                                    <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>01/01/2021</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Ends</span>
                                    <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>12/31/2021</span>
                            </div>
                        </div>

                        <div className="tw-bg-card-green hover:tw-border-2 hover:tw-border-primary tw-shadow tw-rounded-lg tw-p-6 tw-mt-4 tw-cursor-pointer">
                            <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mx-4">
                                                    View
                                        </ButtonSecondary>
                            </div>
                            <div className="tw-flex tw-px-4 tw-py-2">
                                <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Fiscal Year</span>
                                <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>2020</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Waiver Type</span>
                                    <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>Individualized Option</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Starts</span>
                                    <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>01/01/2020</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                    <span className='tw-text-sm tw-w-1/5 tw-text-grey-dark tw-font-light tw-leading-4'>Span Ends</span>
                                    <span className='tw-text-sm tw-w-4/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>12/31/2020</span>
                            </div>
                        </div> */}

                    </div>
                </div>
            </>}

            {highlightCards.highlightServiceLocationCard && <>
                <div className="tw-space-y-6 tw-mt-4">
                    {selectedLocation && <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6">

                        <EditLocationAddressCard location={selectedLocation} refreshLocation={refreshClientLocations}/>   

                        <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                        <EditLocationRoomsCard rooms={selectedLocation?.rooms} location={selectedLocation} refreshLocation={refreshClientLocations}/>  

                        <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                        <EditLocationCredentialsCard credentials={selectedLocation?.credentials} location={selectedLocation} refreshLocation={refreshClientLocations}/>

                        <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                        {/* { selectedLocation?.credentials && selectedLocation?.credentials.length > 0 && 
                            <div className="tw-mt-4">
                            <div className="tw-pb-5 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Credentials</span>
                            </div>
                            {selectedLocation?.credentials.map((credential, index) => {
                                if(index !== selectedLocation?.credentials.length - 1) {
                                    return <>
                                        <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.credentialName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.credentialType}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>UserName</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.userName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Password</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.password}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Account ID</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.accountID}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>2FA Contact</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.twoFactorAuth}</span>
                                            </div>
                                        </div>
                                        <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    </>
                                } else {
                                    return <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.credentialName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Credential Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.credentialType}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>UserName</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.userName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Password</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.password}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Account ID</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.accountID}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>2FA Contact</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{credential.twoFactorAuth}</span>
                                            </div>
                                    </div>
                                }
                                })}
                        </div>
                        } */}

                        <EditLocationSmartHubsCard hubs={selectedLocation?.smartHubs} location={selectedLocation} refreshLocation={refreshClientLocations}></EditLocationSmartHubsCard>

                        {selectedLocation?.rooms && selectedLocation?.rooms.length > 0 && <>
                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                            <EditLocationSmartSensorsCard rooms={selectedLocation?.rooms} sensors={selectedLocation?.smartSensors} location={selectedLocation} refreshLocation={refreshClientLocations}></EditLocationSmartSensorsCard>
                        </>}

                        {selectedLocation?.rooms && selectedLocation?.rooms.length > 0 && <>
                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                            <EditLocationSmartCamerasCard rooms={selectedLocation?.rooms} cameras={selectedLocation?.smartCameras} location={selectedLocation} refreshLocation={refreshClientLocations}></EditLocationSmartCamerasCard>
                        </> }

                    </div>}
                </div>
            </>}

            
            </>
            }

            
            

            
        </div>
        
    </>);
}

export default EditClientPage