import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import quill styles

interface RichTextFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  showValidation?: boolean;
  validationMessage?: string;
}

const RichTextField: React.FC<RichTextFieldProps> = ({ label, value, onChange, showValidation, validationMessage }) => {
  return (
    <>
    <label className="tw-text-gray-700">{label}</label>
    {/* <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
        {label}
    </label> */}
    <div className={`text-editor-container tw-bg-white tw-h-320px ${showValidation ? "tw-border-red-500 tw-border" : "tw-border tw-border-primary"} `}>
      <ReactQuill className="tw-h-275px" theme="snow" value={value} onChange={onChange} />
    </div>
    {showValidation && <p className="tw-text-red-500">{validationMessage || "This field is required"}</p>}
    </>
  );
};

export default RichTextField;
