
import { Component, ErrorInfo } from 'react';
import ErrorPage from './pages/ErrorPage';

interface ErrorBoundaryProps {
  navigate: (to: string) => void; 
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {

  state: ErrorBoundaryState = {
    hasError: false  
  };

  static getDerivedStateFromError(error: Error) : ErrorBoundaryState  {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('Unexpected error occurred', error, info);
  }

  redirectToError = () => {
    this.props.navigate('/error');
  }

  render() {
    if (this.state.hasError) {
      //this.redirectToError();
      console.log("Error caught in boundary");
      return <ErrorPage />;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;