import React from 'react';

interface LeftMessageBubbleProps {
    message: string;
}

const LeftMessageBubble: React.FC<LeftMessageBubbleProps> = ({ message }) => {
    return (
        <div className="tw-bg-blue-200 tw-w-4/5 tw-py-3 tw-px-5 tw-rounded-r-lg tw-rounded-bl-lg tw-mb-2 tw-text-left tw-clear-both">
            {message}
        </div>
    );
};

export default LeftMessageBubble;
