import { PlusIcon } from "@heroicons/react/24/solid";
import { CircularProgress } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllClients } from "../apicalls/clientMgmtService";
import { Search } from "../components/forms/formFields";
import Patient from "../types/patient";
import { useAppContext } from "../Context";

const people = [
    {
      name: 'Lindsay Walton',
      service: 'Remote Support',
      span: '01/01/2021 - 01/31/2021',
      email: 'lindsay.walton@example.com',
      phone: '(555) 765-4321',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Courtney Henry',
        service: 'Remote Support',
        span: '01/01/2021 - 01/31/2021',
        email: 'courtney.henry@example.com',
        phone: '(555) 765-4321',
        image: 'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Devon Webb',
        service: 'Hybrid Support',
        span: '01/01/2021 - 01/31/2021',
        email: 'devon.webb@example.com',
        phone: '(555) 765-4321',
        image:
          'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80',
    },
    {
        name: 'Hector Adams',
        service: 'Direct Support',
        span: '01/01/2021 - 01/31/2021',
        email: 'hector.adams@example.com',
        phone: '(555) 765-4321',
        image: 'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
    }
    // More people...
]

const ClientManagementPage = props => {
    const [clientsLoading, setClientsLoading] = useState(true);
    const [allPatients, setAllPatients] = useState([]);
    const [filteredPatients, setFilteredPatients] = useState([]);
    let navigate = useNavigate();
    const appContext = useAppContext();


    useEffect(() => {
		const fetchClients = async () => {
			const data = await getAllClients(appContext.handleAPICallError);
			console.log(data);
            setAllPatients(data);
            setFilteredPatients(data);
			setClientsLoading(false);
		}

		fetchClients()
    		.catch(console.error);
	}, []);

    const goToClientView = (patient: Patient) => {
        navigate(`/client/${patient.patientID}`);
    }

    const goToClientAdd = () => {
        navigate(`/client/add`);
    }

    return (<>
        <div className="tw-px-xl tw-py-xl">
            <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Client Management</h2>
            </div>
            <div className="tw-py-2">
                                <Search className="-tw-mt-sm tw-w-full" id="demsdlkfjhsdkf" onChange={ e => {

                                        console.log(e?.target.value || "nope");

                                        if(e?.target.value){
                                            const searchText = e?.target.value.toString().toLowerCase();
                                            const filtered = allPatients.filter(element => {
                                                
                                                // 👇️ using AND (&&) operator
                                                return element.firstName.toLowerCase().includes(searchText) || element.lastName.toLowerCase().includes(searchText);
                                              });

                                              console.log(filtered)

                                            setFilteredPatients(filtered);
                                        } else {
                                            console.log("setting all patients");
                                            setFilteredPatients(allPatients);
                                        }
                                    }}/>
                                </div>
            <div className="tw-mt-8 tw-flow-root">
                <div className="-tw-my-2 -tw-mx-4 tw-overflow-x-auto sm:-tw-mx-6 lg:-tw-mx-8">
                { clientsLoading ? 
                            <div className="tw-flex tw-justify-center tw-m-md">
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </div>
                            : 
                            <>
                <div className="inline-block tw-min-w-full tw-py-2 align-middle sm:tw-px-6 lg:tw-px-8">
                    <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                    <thead>
                        <tr>
                        <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">
                            Name
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Service
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Phone
                        </th>
                        <th scope="col" className="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-0">
                            <span className="tw-sr-only">Edit</span>
                        </th>
                        </tr>
                    </thead>
                    <tbody className="tw-divide-y tw-divide-gray-200 tw-bg-white">
                        {filteredPatients?.map((patient) => (
                        <tr key={patient.patientID}>
                            <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm">
                            <div className="tw-flex tw-items-center">
                                <div className="tw-h-10 tw-w-10 tw-flex-shrink-0">
                                <span className="tw-relative tw-inline-block">
                                    <span className="tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-500 tw-cursor-pointer">
                                        <span className="tw-font-medium tw-leading-none tw-text-white">{patient.firstName.charAt(0).toUpperCase()}{patient.lastName.charAt(0).toUpperCase()}</span>
                                    </span>
                                    <span className="tw-absolute tw-top-0 tw-right-0 tw-block tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-green-400 tw-ring-2 tw-ring-white" />
                                </span>
                                {/* <img className="tw-h-10 tw-w-10 tw-rounded-full" src={person.image} alt="" /> */}
                                </div>
                                <div className="tw-ml-4">
                                <div className="tw-font-medium tw-text-gray-900">{patient.firstName} {patient.lastName}</div>
                                <div className="tw-text-gray-500">{patient.email}</div>
                                </div>
                            </div>
                            </td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">
                            <div className="tw-text-gray-900">{patient.serviceType}</div>
                            <div className="tw-text-gray-500">N/A</div>
                            </td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{patient.phone}</td>
                            <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium">
                            <p onClick={e => goToClientView(patient)} className="tw-text-indigo-600 hover:tw-text-indigo-900 tw-cursor-pointer">
                                Edit<span className="tw-sr-only">, {patient.firstName} {patient.lastName}</span>
                            </p>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>

                    
                </div>
                
                </>
                }       
                </div>
            </div>
            <div className="tw-fixed tw-bottom-8 tw-right-8">
                <button
                    type="button"
                    className="tw-outline-none tw-border-0 tw-appearance-none tw-overflow-hidden tw-bg-primary tw-p-10px tw-flex tw-items-center tw-justify-center tw-aspect-square tw-rounded-full tw-text-white hover:tw-bg-indigo-500 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                    onClick={goToClientAdd}
                >
                    <PlusIcon className="tw-h-10 tw-w-10" aria-hidden="true" />
                </button>
            </div>
        </div>
    </>);
}

export default ClientManagementPage;