import { Block, Span } from "../utilities/Helpers";
import { placeholder, rem, uuid } from "../../utilities/helpers";
import IntrinsicRatio from "../utilities/IntrinsicRatio";
import React, { forwardRef, HtmlHTMLAttributes, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { paragraph3 } from "../../utilities/typography";
import { digitsOnly, startWithZero } from "../../hooks/useFormats";
import { ButtonPrimary, DefaultButton } from "@medforall/medforall-component-library";

export const FormFieldDefaultStylesBaseResets = `tw-block tw-drop-shadow-none tw-shadow-none tw-outline-transparent tw-outline-none tw-outline-0 tw-text-3-alt`;
export const FormFieldDefaultStylesBaseResetsAll = FormFieldDefaultStylesBaseResets + ` tw-rounded-3p`;
export const FormFieldDefaultStylesBaseResetsAlt = FormFieldDefaultStylesBaseResets + ` tw-rounded-md`;
export const FormFieldDefaultStylesBasePadding = `tw-pt-sm`;
export const FormFieldDefaultStylesPlaceholder = `placeholder:tw-text-dark-light placeholder:tw-text-6-alt placeholder:tw-font-normal`;
export const FormFieldDefaultStylesBaseResetsExtended = `tw-appearance-none tw-leading-none ${FormFieldDefaultStylesBasePadding}`;
export const FormFieldDefaultStylesBase = `${ FormFieldDefaultStylesBaseResetsAll } ${ FormFieldDefaultStylesPlaceholder }`;
export const FormFieldDefaultStylesBaseAlt = `${ FormFieldDefaultStylesBaseResetsAlt } ${ FormFieldDefaultStylesPlaceholder }`;
export const FormFieldDefaultStylesDefault = `tw-border tw-border-primary tw-bg-white`;
export const FormFieldDefaultStylesFocus = `focus:tw-text-black`;
export const FormFieldDefaultStylesCompleted = `tw-text-dark`;
export const FormFieldDefaultStylesAll = `${ FormFieldDefaultStylesBase } || ${ FormFieldDefaultStylesBaseResetsExtended } || ${ FormFieldDefaultStylesDefault } || ${ FormFieldDefaultStylesFocus } || ${ FormFieldDefaultStylesCompleted }`;
export const FormFieldDefaultStylesAlt = `${ FormFieldDefaultStylesBaseAlt } || ${ FormFieldDefaultStylesBaseResetsExtended } || ${ FormFieldDefaultStylesDefault } || ${ FormFieldDefaultStylesFocus } || ${ FormFieldDefaultStylesCompleted }`;


export const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return {
        value,
        onChange: handleChange
    };
};

export const handleValidate = (parentSelector:string = '._validate-children', individualSelector:any = '._validate-me') => {
	const required = document.querySelectorAll(`${parentSelector} [required]._empty, ${parentSelector}  [required]._invalid`);
	const individualValids = document.querySelectorAll(`${individualSelector}._empty, ${individualSelector}._invalid`);

	return {
		requiredInvalids: required?.length || 0,
		individualValids: individualValids?.length || 0,
		totalInvalids: (required?.length + individualValids?.length) || 0,
		valid: (required?.length + individualValids?.length) === 0 ? true : false,
		required: required,
		individuals: individualValids
	}
}

export const Input = forwardRef((props: any, ref: any): any => {
	const [eventType, setEventType] = useState('_valid-focus');
	const [isValid, setValid] = useState<boolean>(true);
	const [isEmpty, setEmpty] = useState<boolean>(true);
	const [invalidClasses, setInvalidClasses] = useState('_invalid _invalid-keyup _invalid-blur');
	const [validClasses, setValidClasses] = useState('_valid _valid-keyup _valid-blur');
	const [baseClasses, setBaseClasses] = useState(`c-input || tw-indent-sm tw-pb-sm || ${ props.disableInvalidStates ? '' : '_invalid-states' } || ${props.alt ? FormFieldDefaultStylesAlt : FormFieldDefaultStylesAll} ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props?.className : '' }`);
	const [fieldClasses, setFieldClasses] = useState(baseClasses);
	const multipleFile = props?.multipleFiles || null;

	const handleFieldCases = () => 	setFieldClasses(`${ baseClasses } || _field-valiations ${ isValid ? validClasses : invalidClasses} ${ isEmpty ? '_empty' : '_not-empty'}`);

	useEffect(handleFieldCases, [invalidClasses, validClasses, isEmpty, isValid])

	useEffect(() => {
		if (props.className) {
			setBaseClasses(`c-input || tw-indent-sm tw-pb-sm || ${ props.disableInvalidStates ? '' : '_invalid-states' } || ${props.alt ? FormFieldDefaultStylesAlt : FormFieldDefaultStylesAll} ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props?.className : '' }`);
			setFieldClasses(`c-input || tw-indent-sm tw-pb-sm || ${ props.disableInvalidStates ? '' : '_invalid-states' } || ${props.alt ? FormFieldDefaultStylesAlt : FormFieldDefaultStylesAll} ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props?.className : '' }`);
		}
	}, [props.className, props.disableInvalidStates, props.alt, props.coreClasses])

	useEffect(() => {
		const tempValidClasses = validClasses;
		const tempInvalidClasses = invalidClasses;

		if (isEmpty) {
			setValidClasses(tempValidClasses.toLowerCase());
			setInvalidClasses(tempInvalidClasses.toLowerCase());
		} else {

			if (isValid) {
				setValidClasses(tempValidClasses.replaceAll('_valid-' + eventType,( '_valid-' + eventType.toUpperCase())));
				setInvalidClasses(tempInvalidClasses.toLowerCase());
			} else {
				setValidClasses(tempValidClasses.toLowerCase());
				setInvalidClasses(tempInvalidClasses.replaceAll('_invalid-' + eventType, ('_invalid-' + eventType.toUpperCase())));
			}
		}
	}, [isValid, isEmpty, eventType])

	const handleValidation = useCallback((e) => {
		const field = e.target;
		setEventType(e.type);
		setValid(field.checkValidity());
		setEmpty(field.value.replaceAll(" ", '') === "" ? true : false)
	}, []);

	useLayoutEffect(() => {
		if (props.value) setEmpty(false);
	}, [props.value]);


	useEffect(() => {
		if (props.isValid) {
			const field = document.querySelector(`#${attrId}`) as HTMLInputElement;
			console.log(field);
			setEventType('KEYUP');
			setValid(field.checkValidity());
			setEmpty(field.value.replaceAll(" ", '') === "" ? true : false)
			handleFieldCases();
		}
	}, [props.isValid]);


	const attrOnBlur = props?.onBlur || false;
	const attrOnKeyUp = props?.onKeyUp || false;
	const attrOnChange = props?.onChange || false;

	const Tag = props?.isOutput ? 'div' : 'input';
	const attrId = props?.id || uuid();
	const attrName = props?.name || attrId;
	const attributes = {
		...props,
		className: fieldClasses,
		coreClasses: null,
		id: attrId,
		name: attrName,
		isOutput: null,
		default: null,
		reset: null,
		alt: null,
		multiple: multipleFile,
		onChange: (e) => {
			if ( attrOnChange ) attrOnChange(e);
			handleValidation(e);
		},
		onKeyUp: (e) => {
			if ( attrOnKeyUp ) attrOnKeyUp();
			handleValidation(e);
		},
		onBlur: (e) => {
			if ( attrOnBlur ) attrOnBlur();
			handleValidation(e);
		}
	}

	return <>
		<Tag ref={ ref || null } { ...attributes } children={ props?.isOutput && <Block children={ props?.value  } />} />
	</>
});

const ToggleButton = props => {
	const [isActive, setIsActive] = useState<boolean>(false);

	useEffect(() => {
		if (props.isActive) props.isActive(isActive);
	}, [isActive])

	useLayoutEffect(() => {
		if (props.setIsActive) setIsActive(props.setIsActive);
	}, [props.setIsActive])

	return <>
		<span className="tw-hidden tw-w-0 tw-h-0 tw-overflow-hidden">
			<input className="tw-border-none tw-appearance-none tw-outline-none" type="checkbox" checked={ isActive } name={`${ props.name }_toggle`} id={ props.id || null } value={ props.value || null } />
		</span>
		<ButtonPrimary className={`tw-min-w-40p tw-h-40p ${ isActive ?  '' : '!tw-bg-transparent !tw-text-dark' }`} onClick={ (e) => {
			const temp = isActive;
			setIsActive(isActive === true ? false : true);
			if (props.onClick) props.onClick();
			e.preventDefault();
		}}>
			<Span className="tw-flex tw-flex-col">
				{ props.children }
			</Span>
		</ButtonPrimary>
	</>
}
export const FrequencyFieldDays = props => {
	const ref = useRef<HTMLInputElement>();
	const [value, setValue] = useState<any>({
		"frequency": null,
		"nth": null,
		"Sunday": false,
		"Monday": false,
		"Tuesday": false,
		"Wednesday": false,
		"Thursday": false,
		"Friday": false,
		"Saturday": false,
	});

	const [sunday, setSunday] = useState<boolean>(false);
	const [monday, setMonday] = useState<boolean>(false);
	const [tuesday, setTuesday] = useState<boolean>(false);
	const [wednesday, setWednesday] = useState<boolean>(false);
	const [thursday, setThursday] = useState<boolean>(false);
	const [friday, setFriday] = useState<boolean>(false);
	const [saturday, setSaturday] = useState<boolean>(false);

	const attributes = {
		...props,
		placeholder: null,
		required: null,
		id: null,
		name: null
	}

	useEffect(() => {
		const temp = value;
		let inputEvent = new Event('change', { bubbles: true });
		ref.current.dispatchEvent(inputEvent);
		if (props.onChange) props.onChange(inputEvent)
    }, [value]);

	useLayoutEffect(() => {
		if (props.value) setValue(props.value)
		if (props?.monday || props?.tuesday || props?.wednesday || props?.thursday || props?.friday || props?.saturday || props?.sunday) {
			setValue({
				"frequency": null,
				"nth": null,
				"Sunday": props?.sunday || false,
				"Monday": props?.monday || false,
				"Tuesday": props?.tuesday || false,
				"Wednesday": props?.wednesday || false,
				"Thursday": props?.thursday || false,
				"Friday": props?.friday || false,
				"Saturday": props?.saturday || false,
			})

			if (props.sunday) setSunday(props?.sunday || false)
			if (props.monday) setMonday(props?.monday || false)
			if (props.tuesday) setTuesday(props?.tuesday || false)
			if (props.wednesday) setWednesday(props?.wednesday || false)
			if (props.thursday) setThursday(props?.thursday || false)
			if (props.friday) setFriday(props?.friday || false)
			if (props.saturday) setSaturday(props?.saturday || false)
		}
	}, [props?.value, props?.monday, props?.tuesday, props?.wednesday, props?.thursday, props?.friday, props?.saturday, props?.sunday])


	useEffect(() => {
		setValue({
			"Sunday": sunday,
			"Monday": monday,
			"Tuesday": tuesday,
			"Wednesday": wednesday,
			"Thursday": thursday,
			"Friday": friday,
			"Saturday": saturday,
		})

	}, [sunday, monday, tuesday, wednesday, thursday, friday, saturday])



	return <>
		<fieldset {...attributes}>
			<Span className="tw-flex tw-flex-col">
				<Label htmlFor="Sunday tw-w-full" icon="/images/icon-repeat.svg">
					Frequency
				</Label>
			</Span>

			<Span className={`tw-space-y-5p tw-flex tw-flex-col tw-items-start tw-w-full -tw-ml-5p`}>
				<Span className={`tw-flex tw-space-x-5p`}>
					<ToggleButton id="Sunday" value="Sunday" name={ props.id || "toggles" } setIsActive={ props.day === "Sunday" || false } isActive={ val => setSunday(val)}>
						<Span>
							S<Span className="tw-hidden">unday</Span>
						</Span>
					</ToggleButton>
					<ToggleButton id="Monday" value="Monday" name={ props.id || "toggles" }  setIsActive={ props.day === "Monday" || false } isActive={ val => setMonday(val)}>
						<Span>
							M<Span className="tw-hidden">onday</Span>
						</Span>
					</ToggleButton>
					<ToggleButton id="Tuesday" value="Tuesday" name={ props.id || "toggles" }  setIsActive={ props.day === "Tuesday" || false } isActive={ val => setTuesday(val)}>
						<Span>
							T<Span className="tw-hidden">uesday</Span>
						</Span>
					</ToggleButton>
					<ToggleButton id="Wednesday" value="Wednesday" name={ props.id || "toggles" }  setIsActive={ props.day === "Wednesday" || false } isActive={ val => setWednesday(val)}>
						<Span>
							W<Span className="tw-hidden">ednesday</Span>
						</Span>
					</ToggleButton>
					<ToggleButton id="Thursday" value="Thursday" name={ props.id || "toggles" }  setIsActive={  props.day === "Thursday" || false } isActive={ val => setThursday(val)}>
						<Span>
							R<Span className="tw-hidden"> Thursday</Span>
						</Span>
					</ToggleButton>
					<ToggleButton id="Friday" value="Friday" name={ props.id || "toggles" }  setIsActive={ props.day === "Friday" || false } isActive={ val => setFriday(val)}>
						<Span>
							F<Span className="tw-hidden">riday</Span>
						</Span>
					</ToggleButton>
					<ToggleButton id="Saturday" value="Saturday" name={ props.id || "toggles" }  setIsActive={  props.day === "Saturday" || false } isActive={ val => setSaturday(val)}>
						<Span>
							S<Span className="tw-hidden">aturday</Span>
						</Span>
					</ToggleButton>
				</Span>
			</Span>
		</fieldset>
		<input required={ props.required || null } type="hidden" ref={ ref } value={ value ? JSON.stringify(value) : [] } name={ props.name || props.id || null } id={ props.id || null } />
	</>
}

export const FrequencyField = props => {
	const ref = useRef<HTMLInputElement>();
	const frequencyField = useRef<HTMLInputElement>();
	const [frequency, setFrequency] = useState<String>('');
	const [frequencyValue, setFrequencyValue] = useState<any>('');
	const [value, setValue] = useState<any>({
		"frequency": null,
		//"nth": null,
		"Sunday": false,
		"Monday": false,
		"Tuesday": false,
		"Wednesday": false,
		"Thursday": false,
		"Friday": false,
		"Saturday": false,
	});

	const [sunday, setSunday] = useState<boolean>(false);
	const [monday, setMonday] = useState<boolean>(false);
	const [tuesday, setTuesday] = useState<boolean>(false);
	const [wednesday, setWednesday] = useState<boolean>(false);
	const [thursday, setThursday] = useState<boolean>(false);
	const [friday, setFriday] = useState<boolean>(false);
	const [saturday, setSaturday] = useState<boolean>(false);

	const attributes = {
		...props,
		placeholder: null,
		required: null,
		id: null,
		name: null
	}

	useEffect(() => {
		const temp = value;
		let inputEvent = new Event('change', { bubbles: true });
		ref.current.dispatchEvent(inputEvent);
		if (props.onChange) props.onChange(inputEvent)
    }, [value]);

	useEffect(() => {
		if ( props.id && (frequency !== '' && (frequency === 'Weekly' || frequency === 'Daily' || frequency === 'Monthly')) ) {
			//frequencyField?.current.focus();
		} else if (props.id && (frequency !== '' || frequency !== ' ') ) {
			setFrequencyValue('')
		}
	}, [frequency])

	useLayoutEffect(() => {
		if (props.value) setFrequencyValue(digitsOnly(props.value))
	}, [props?.value])


	// useLayoutEffect(() => {
	// 	const temp = {
	// 		"frequency": null,
	// 		"nth": null,
	// 		"Sunday": false,
	// 		"Monday": false,
	// 		"Tuesday": false,
	// 		"Wednesday": false,
	// 		"Thursday": false,
	// 		"Friday": false,
	// 		"Saturday": false,
	// 	}
	// 	setValue(temp);
	// }, [])


	useEffect(() => {
		setValue({
			"frequency": frequency,
			//"nth": Number(frequencyValue) || null,
			"Sunday": sunday,
			"Monday": monday,
			"Tuesday": tuesday,
			"Wednesday": wednesday,
			"Thursday": thursday,
			"Friday": friday,
			"Saturday": saturday,
		})

	}, [frequency, frequencyValue, sunday, monday, tuesday, wednesday, thursday, friday, saturday])



	return <>
		<fieldset {...attributes}>
			<Span className="tw-flex tw-flex-col">
				<Label htmlFor="frequency-demo tw-w-full">
					Repeat
				</Label>
				<Select id="frequency-demo tw-w-full" onChange={ e => {
					setFrequency(e.target.value);
				}}>
					<option value="">
						Never
					</option>
					<option value="Daily">
						Daily
					</option>
					<option value="Weekly">
						Weekly
					</option>
					{/* <option value="month">
						Monthly
					</option> */}
				</Select>
			</Span>
			{ (frequency !== '' && (frequency === 'Weekly' || frequency === 'Daily' || frequency === 'Monthly')) && <>
				<Span className={`tw-space-y-5p tw-flex tw-flex-col tw-items-start tw-w-full ${frequency === '' ? 'tw-hidden tw-pointer-events-none' : '' }`}>
					{/* <Span className="tw-flex tw-items-baseline">
						<Span>Every</Span>
						<Input ref={ frequencyField } onChange={ e => {
							const temp =  e.target.value;
							setFrequencyValue(digitsOnly(temp))
						}} value={ frequencyValue } name={ props.name || null } id={ props.id || null } placeholder={ props.placeholder || null } required={ props.required || null } pattern={ props.pattern || null } maxLength="2" type="tel" className="!tw-border-t-none !tw-border-x-none !tw-bg-transparent !tw-rounded-none !tw-border-b-2px !tw-border-b-dark tw-w-40px tw-text-center tw-flex tw-items-baseline !tw-pb-2p !tw-px-0 tw-mx-4p tw-h-30p !tw-indent-0" />
						<Span>{ frequency === 'week' ? `${frequency}(s) on` : ' day'}</Span>
					</Span> */}
					{ (frequency !== 'Daily' && frequency !== 'Monthly') && <>
						<Span className={`tw-flex tw-space-x-5p tw-space-y-10p`}>
							<ToggleButton id="Sunday" value="Sunday" name={ props.id || "toggles" } isActive={ val => setSunday(val)}>
								<Span>
									S<Span className="tw-hidden">unday</Span>
								</Span>
							</ToggleButton>
							<ToggleButton id="Monday" value="Monday" name={ props.id || "toggles" } isActive={ val => setMonday(val)}>
								<Span>
									M<Span className="tw-hidden">onday</Span>
								</Span>
							</ToggleButton>
							<ToggleButton id="Tuesday" value="Tuesday" name={ props.id || "toggles" } isActive={ val => setTuesday(val)}>
								<Span>
									T<Span className="tw-hidden">uesday</Span>
								</Span>
							</ToggleButton>
							<ToggleButton id="Wednesday" value="Wednesday" name={ props.id || "toggles" } isActive={ val => setWednesday(val)}>
								<Span>
									W<Span className="tw-hidden">ednesday</Span>
								</Span>
							</ToggleButton>
							<ToggleButton id="Thursday" value="Thursday" name={ props.id || "toggles" } isActive={ val => setThursday(val)}>
								<Span>
									R<Span className="tw-hidden"> Thursday</Span>
								</Span>
							</ToggleButton>
							<ToggleButton id="Friday" value="Friday" name={ props.id || "toggles" } isActive={ val => setFriday(val)}>
								<Span>
									F<Span className="tw-hidden">riday</Span>
								</Span>
							</ToggleButton>
							<ToggleButton id="Saturday" value="Saturday" name={ props.id || "toggles" } isActive={ val => setSaturday(val)}>
								<Span>
									S<Span className="tw-hidden">aturday</Span>
								</Span>
							</ToggleButton>
						</Span>
					</> }
				</Span>
			</> }
		</fieldset>
		<input required={ props.required || null } type="hidden" ref={ ref } value={ value ? JSON.stringify(value) : [] } name={ props.name || props.id || null } id={ props.id || null } />
	</>
}

export const InputTimes = props => {
	const ref = useRef<HTMLInputElement>(null);
	const startRef = useRef<HTMLInputElement>();
	const endRef = useRef<HTMLInputElement>();
	const [start, setStart] = useState('');
	const [end, setEnd] = useState('');
	const [amPm, setAmPm] = useState('');
	const [output, setOutput] = useState( props.value || '');

	const attributes = {
		...props,
		className: null,
		id: null,
		name: null,
		pattern: null,
		required: null,
		onChange: null,
		value: null,
		placeholder: null,
		min: null,
		max: null,
		increments: null,
		placeholder2: null,
		min2: null,
		max2: null,
		increments2: null,
		ref: null
	}

	useEffect(() => {
		if (output) {
			ref.current.value = output;
			let inputEvent = new Event('change', { bubbles: true });
			ref.current.dispatchEvent(inputEvent);
			if (props.onChange) props.onChange(inputEvent)
		}
	}, [output])

	useEffect(() => {
		if (start && end && amPm) setOutput(`${startWithZero(start.replaceAll(" ", ""))}:${startWithZero(end.replaceAll(" ", ""))} ${amPm.replaceAll(" ", "")}`);
	}, [start, end, amPm])

	useLayoutEffect(() => {
		if (props.value && props.value.includes(':')) {
			//console.log(props.value.toString().toLowerCase());
			let temp = props.value.toString().toLowerCase().split(':');
			//console.log(temp);
			let start = temp[0];
			let end = temp[1].replace('am','').replace('pm','');
			let amPm = temp[1].includes('am') ? 'AM' : 'PM';
			//console.log(start, end, amPm);
			let tempOutput = `${start}:${end} ${amPm}`;
			if(tempOutput !== output) {
				setStart(start);
				setEnd(end);
				setAmPm(amPm);
			}
		}
	}, [props.value])

	return <>
		<div { ...attributes } className={`tw-flex tw-w-full tw-flex-col ${ props.className }`}>
			<Label htmlFor="inputNumbersDemo1" className="tw-w-full" icon="/images/icon-time.svg">
				{ props.label || 'Start Time' }
			</Label>
			<span className="tw-w-full tw-flex">
				<InputNumbers id="inputNumbersDemo1" value={ startWithZero(start) } placeholder={ props.placeholder || "00" } min={ props.min || 0 } max={ props.max || 12 } ref={ startRef } increments={ props.increments || 1 } className="tw-w-100px" onChange={ (e) => {
					console.log("START", e.target.value);
					setStart(e.target.value);
				}} />
				<span className="tw-font-primary-bold tw-px-8px tw-flex tw-items-center">
					:
				</span>
				<InputNumbers value={ startWithZero(end) } placeholder={ props.placeholder || "00" } min={ props.min2 || 0 } max={ props.max2 || 60 } increments={ props.increments2 || 15 } ref={ endRef } className="tw-w-100px" onChange={ (e) => {
					console.log("END", e.target.value);
					if(e.target.value > 59) e.target.value = 0;
					if (e.target.value === '0') e.target.value = '00';
					setEnd(e.target.value);
				}} />
				<ToggleAmPm className="tw-ml-16px tw-items-center" value={ amPm } onChange={ e => {
					console.log("AMPM", e.target.value);
					if (e.target?.value) setAmPm(e.target.value)
				}} />
			</span>
			<input type="hidden" pattern={ props.pattern || null } required={ props.required || null } value={ output } id={ props.id || null } name={ props.name || props.id || null } ref={ ref } />
		</div>
	</>
}

export const Search = forwardRef((props: any, ref: any): any => {
	const [eventType, setEventType] = useState('_valid-focus');
	const [isValid, setValid] = useState(true);
	const [isEmpty, setEmpty] = useState(true);
	const [fieldValue, setFieldValue] = useState('');
	const [invalidClasses, setInvalidClasses] = useState('_invalid _invalid-keyup _invalid-blur');
	const [validClasses, setValidClasses] = useState('_valid _valid-keyup _valid-blur');
	const baseClasses = `c-input || tw-indent-sm tw-pb-sm || ${ props.disableInvalidStates ? '' : '_invalid-states' } || ${props.alt ? FormFieldDefaultStylesAlt : FormFieldDefaultStylesAll} ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props?.className : '' }`;
	const [fieldClasses, setFieldClasses] = useState(baseClasses);

	useEffect(() => {
		setFieldClasses(`${ baseClasses } || _field-valiations ${ isValid ? validClasses : invalidClasses} ${ isEmpty ? '_empty' : '_not-empty'}`)
	}, [invalidClasses, validClasses, isEmpty, isValid])

	useEffect(() => {
		const tempValidClasses = validClasses;
		const tempInvalidClasses = invalidClasses;

		if (isEmpty) {
			setValidClasses(tempValidClasses.toLowerCase());
			setInvalidClasses(tempInvalidClasses.toLowerCase());
		} else {

			if (isValid) {
				setValidClasses(tempValidClasses.replaceAll('_valid-' + eventType,( '_valid-' + eventType.toUpperCase())));
				setInvalidClasses(tempInvalidClasses.toLowerCase());
			} else {
				setValidClasses(tempValidClasses.toLowerCase());
				setInvalidClasses(tempInvalidClasses.replaceAll('_invalid-' + eventType, ('_invalid-' + eventType.toUpperCase())));
			}
		}
	}, [isValid, isEmpty, eventType])

	const handleValidation = useCallback((e) => {
		const field = e.target;
		setEventType(e.type);
		setValid(field.checkValidity());
		setEmpty(field.value.replaceAll(" ", '') === "" ? true : false)
	}, []);

	useLayoutEffect(() => {
		if (props.value) {
			setEmpty(false);
			setFieldValue(props.value)
		}
	}, [props.value]);

	const attrOnBlur = props?.onBlur || false;
	const attrOnKeyUp = props?.onKeyUp || false;

	const Tag = props?.tag || 'div';
	const classes = `tw-relative ${ props?.className ? ' || ' + props?.className : '' }`;
	const attrId = props?.id || uuid();
	const attrName = props?.name || attrId;

	const attributes = {
		...props,
		className: classes,
		childClasses: null,
		id: null,
		name: null,
		type: null,
		alt: null,
		placeholder: null,
		onChange: null,
		value: null
	}

	const fieldAttributes = {
		onKeyUp: (e) => {
			if ( attrOnKeyUp ) attrOnKeyUp();
			if ( e?.target ) handleValidation(e);
		},
		onBlur: (e) => {
			if ( attrOnBlur ) attrOnBlur();
			if ( e?.target ) handleValidation(e);
		}
	}

	const handleOnChange = e => {
		if ( props.onChange ) props.onChange(e);
		setFieldValue( (e.target?.value && e.target?.value.replaceAll(' ','') !== '') ? e.target.value : '');
	}

	return <Tag { ...attributes }>
		<label className="tw-absolute tw-z-10 tw-left-0 tw-left-md tw-top-1/2 -tw-translate-y-1/2 tw-flex tw-align-center tw-justify-center tw-w-6 tw-cursor-pointer" htmlFor={ attrId }>
			<IntrinsicRatio className="tw-flex tw-align-center tw-justify-center tw-w-full" childClasses="tw-flex tw-align-center tw-justify-center" tag="span" childTag="img" src={`/images/icon-search.svg`} />
			<span className="tw-hidden">
				Search
			</span>
		</label>
		<Input value={ fieldValue } onChange={ handleOnChange } {...fieldAttributes } placeholder={ props.placeholder || 'Search by client name' } id={ attrId } name={ attrName } type={ props.type || 'search' }  className={`!tw-rounded-full tw-w-full tw-flex-grow tw-indent-0 tw-pl-[3.375rem] tw-relative tw-z-0 ${ props?.childClasses ?  ' || ' + props?.childClasses : '' }`} />
	</Tag>
});


export const Textarea = forwardRef((props: any, ref: any): any => {
	const [eventType, setEventType] = useState('_valid-focus');
	const [isValid, setValid] = useState(true);
	const [isEmpty, setEmpty] = useState(true);
	const [invalidClasses, setInvalidClasses] = useState('_invalid _invalid-keyup _invalid-blur');
	const [validClasses, setValidClasses] = useState('_valid _valid-keyup _valid-blur');
	const [baseClasses, setBaseClasses] = useState(`c-textarea || tw-px-sm ${ props.reset ? ' ' : ' tw-pb-sm tw-resize-none tw-overflow-hidden'} ${FormFieldDefaultStylesAll}${ props?.coreClasses || '' } ${ props?.className ? ' || ' + props.className : '' }`);
	const [fieldClasses, setFieldClasses] = useState(baseClasses);
	useEffect(() => {
		setFieldClasses(`${ baseClasses } || _field-valiations ${ isValid ? validClasses : invalidClasses} ${ isEmpty ? '_empty' : '_not-empty'}`)
	}, [invalidClasses, validClasses, isEmpty, isValid])


	useEffect(() => {
		setFieldClasses(`c-textarea || tw-px-sm ${ props.reset ? ' ' : ' tw-pb-sm tw-resize-none tw-overflow-hidden'} ${FormFieldDefaultStylesAll}${ props?.coreClasses || '' } ${ props?.className ? ' || ' + props.className : '' }`);
	}, [props.className]);


	useEffect(() => {
		const tempValidClasses = validClasses;
		const tempInvalidClasses = invalidClasses;

		if (isEmpty) {
			setValidClasses(tempValidClasses.toLowerCase());
			setInvalidClasses(tempInvalidClasses.toLowerCase());
		} else {

			if (isValid) {
				setValidClasses(tempValidClasses.replaceAll('_valid-' + eventType,( '_valid-' + eventType.toUpperCase())));
				setInvalidClasses(tempInvalidClasses.toLowerCase());
			} else {
				setValidClasses(tempValidClasses.toLowerCase());
				setInvalidClasses(tempInvalidClasses.replaceAll('_invalid-' + eventType, ('_invalid-' + eventType.toUpperCase())));
			}
		}
	}, [isValid, isEmpty, eventType])

	const handleValidation = useCallback((e) => {
		const field = e.target;
		setEventType(e.type);
		setValid(field.checkValidity());
		setEmpty(field.value.replaceAll(" ", '') === "" ? true : false)
	}, []);

	useLayoutEffect(() => {
		if (props.value) setEmpty(false);
	}, [props.value]);

	const attrOnBlur = props?.onBlur || false;
	const attrOnKeyUp = props?.onKeyUp || false;

	const Tag = props.isOutput ? 'div' : 'textarea';
	// const classes = `c-textarea || tw-px-sm${ props.reset ? ' ' : ' tw-pb-sm tw-resize-none tw-overflow-hidden'}${FormFieldDefaultStylesAll}${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const attrId = props.id || uuid();
	const attrName = props.name || attrId;

	const attributes = {
		...props,
		className: fieldClasses,
		coreClasses: null,
		id: attrId,
		name: attrName,
		isOutput: null,
		default: null,
		reset: null,
		alt: null,
		onKeyUp: (e) => {
			if ( attrOnKeyUp ) attrOnKeyUp();
			handleValidation(e);
		},
		onBlur: (e) => {
			if ( attrOnBlur ) attrOnBlur();
			handleValidation(e);
		}
	}

	const onChange = (e) => {
		if (!props.reset || props.reset === null) {
			const el = e.target;
			el.style.minHeight = "auto";
			el.style.minHeight = (el.scrollHeight) + "px";
		}

		if (props.onChange) props.onChange(e);
	};

	return <>
		<Tag
			ref={ ref }
			{ ...attributes }
			onChange={ e => onChange(e) }
			children={ props.isOutput && <Block children={ props.value  } />}
		 />
	</>
});

export const InputNumbers = props => {
	const [increments, setIncrements] = useState<any>( props.increments || 1);
	const [value, setValue] = useState<any>( startWithZero(props.value) || '');
	const attributes = {
		...props,
		className: null,
		placeholder: null,
		required: null,
		id: null,
		name: null
	}

	const inputRef = useRef<HTMLInputElement>();
	const upEl = useRef<HTMLInputElement>();
	const downEl = useRef<HTMLInputElement>();

	useEffect(() => {
		const temp = value;
		let inputEvent = new Event('change', { bubbles: true });
		inputRef.current.dispatchEvent(inputEvent);
		if (props.onChange) props.onChange(inputEvent)

		if (Number(temp) >= Number(inputRef.current.max)) {
			setValue( startWithZero(inputRef.current.max) )
			// upEl.current.classList.add("tw-grayscale");
		} else if (Number(temp) <= Number(inputRef.current.min)) {
			setValue(startWithZero(inputRef.current.min))
			// downEl.current.classList.add("tw-grayscale");
		} else {
			inputRef.current.value = temp.toString();
			setValue(temp.toString())
			upEl.current.classList.remove("tw-grayscale");
			// downEl.current.classList.remove("tw-grayscale");
		}

		if (props.onChange) props.onChange(inputEvent);

    }, [value]);

	useLayoutEffect(() => {
		if (props.value) setValue( startWithZero(props.value) )
	}, [props.value])

	return <>
		<span className={`tw-flex ${ props.className }`}>
			<Input id={ props.id || null } name={ props.name || null } required={ props.required || null } placeholder={ props.placeholder || null } type="number" min={ props.min || "00" } max={ props.max || "60" } value={ value ? startWithZero(value) : '' } className="!tw-py-0 tw-text-center tw-appearance-none tw-flex-grow tw-rounded-r-none" ref={ inputRef } onChange={ e => {
				setValue(e.target.value)
			}}/>
			<span className="tw-flex tw-flex-col tw-relative tw-z-2 touch:tw-min-w-30px tw-min-w-15px touch:tw-w-30p tw-w-15p tw-text-in tw-flex-shrink-0 tw-bg-white tw-border-y tw-border-r tw-border-primary tw-rounded-r-3px tw-overflow-hidden">
				<span ref={ upEl } className="tw-h-1/2 tw-w-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-bg-primary/30" onClick={ () => {
					const value =  Number(inputRef.current.value);
					const temp = value < Number(inputRef.current.max) ? (value + Number(increments)) : (inputRef.current.min) ? Number(inputRef.current.min) : Number(inputRef.current.max);
					setValue(startWithZero(temp));
				}}>
					<img className="tw-w-7/12 tw-max-w-12p tw-mx-auto tw-block" src="/images/icon-input-arrow-up.svg" alt="Increase Number" />
				</span>
				<span ref={ downEl } className="tw-flex-grow tw-h-1/2 tw-w-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-bg-primary/30" onClick={ () => {
					const value =  Number(inputRef.current.value);
					const temp = value > Number(inputRef.current.min) ? (value - Number(increments)) : Number(inputRef.current.min);
					setValue(startWithZero(startWithZero((Number(temp) < Number(inputRef.current.min) ? Number(inputRef.current.min) : temp))));
				}}>
					<img className="tw-w-7/12 tw-max-w-12p tw-mx-auto tw-block" src="/images/icon-input-arrow-down.svg" alt="Decrease Number" />
				</span>
			</span>
		</span>
	</>
};


export const ToggleAmPm = (props) => {
	const ref = useRef<HTMLInputElement>(null);
	const [value, setValue] = useState<any>('')

	const attributes = {
		...props,
		id: null,
		name: null,
		value: null,
		className: null,
		required: null,
		onChange: null,
		ref: null
	}

	useEffect(() => {
		const temp = value;
		let inputEvent = new Event('change', { bubbles: true });
		ref.current.dispatchEvent(inputEvent);
		if (props.onChange) props.onChange(inputEvent)
    }, [value]);

	useLayoutEffect(() => {
		if (props.value) {
			setValue(props.value);
		}
	}, [props.value])


	return <>
		<div { ...attributes } className={`tw-flex tw-min-h-30p ${ props.className }`}>
			<div className={`tw-flex tw-space-x-8p tw-min-h-30px tw-text-center`}>
				<DefaultButton childClasses="tw-flex tw-justify-center" className={`tw-text-in tw-flex tw-items-center tw-justify-center tw-bg-transparent tw-border-transparent ${ value === 'AM' ? 'tw-pointer-events-none' : 'tw-opacity-50' }`} onClick={ e => {
					e.preventDefault();
					setValue('AM');
				}}>
					<span className="tw-font-primary-bold tw-pt-5px">AM</span>
				</DefaultButton>
				<DefaultButton childClasses="tw-flex tw-justify-center" className={`tw-text-in tw-flex tw-items-center tw-justify-center tw-bg-transparent tw-border-transparent ${ value === 'PM' ? 'tw-pointer-events-none' : ' tw-opacity-50' }`} onClick={ e => {
					e.preventDefault();
					setValue('PM');
				}}>
					<span className="tw-font-primary-bold tw-pt-5px">PM</span>
				</DefaultButton>
			</div>
			<input required={ props.required || null } type="hidden" ref={ ref } value={ value } name={ props.name || null } id={ props.id || null } />
		</div>
	</>

};


export const SupportType = props => {
	const ref = useRef<HTMLInputElement>(null);
	const [value, setValue] = useState( props.value || '');

	const attributes = {
		...props,
		placeholder: null,
		required: null,
		id: null,
		name: null,
		title: null
	}

	useEffect(() => {
		const temp = value;
		let inputEvent = new Event('change', { bubbles: true });
		ref.current.dispatchEvent(inputEvent);
		if (props.onChange) props.onChange(inputEvent)
    }, [value]);


	return <>
		<div { ...attributes }>
			<Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
				{ props.title || 'Support'}
			</Label>
			<fieldset className="tw-flex tw-flex-col tw-space-y-5px">
				<div className="tw-w-full">
					<label htmlFor={ props.id ? `${props.id}_1` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input  onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_1` :  null} value={ props.value || 'Remote Support' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group-hover:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span className="tw-flex-grow tw-text-dark group-hover:tw-text-primary-dark">
							{ props.value || 'Remote Support' }
						</span>
					</label>
				</div>
				{/* <div>
					<label htmlFor={ props.id ? `${props.id}_2` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_2` :  null} value={ props.value2 || 'Direct Support' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value2 || 'Direct Support' }
						</span>
					</label>
				</div> */}
				<input required={ props.required || null } type="hidden" ref={ ref } value={ value } name={ props.name || props.id || null } id={ props.id || null } />
			</fieldset>
		</div>
	</>
}


export const NoYes = props => {
	const ref = useRef<HTMLInputElement>(null);
	const [value, setValue] = useState( props.value || '');
	const [output, setOutput] = useState( props.value || '');

	const attributes = {
		...props,
		placeholder: null,
		required: null,
		id: null,
		name: null,
		title: null
	}

	useEffect(() => {
		if (output && (ref.current.value !== output)) {
			const temp = value;
			let inputEvent = new Event('change', { bubbles: true });
			ref.current.dispatchEvent(inputEvent);
			if (props.onChange) props.onChange(inputEvent)
		}
    }, [value]);

	useLayoutEffect(() => {
		if (props.value) {
			setOutput(props.value);
			setValue(props.value);
		}
    }, [props.value]);


	return <>
		<div { ...attributes }>
			<Label htmlFor={ props.id ? `${props.id}_1` : null } icon={ props.icon ? props.icon : `/images/icon-hash.svg` }>
				{ props.title || 'Support'}
			</Label>
			<fieldset className="tw-flex tw-flex-row tw-space-x-40px tw-items-center">
				<div className="tw-max-w-1/2">
					<label htmlFor={ props.id ? `${props.id}_1` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input checked={ output === 'No' ? true : null } onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_1` :  null} value={ 'No' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group-hover:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span className="tw-flex-grow tw-text-dark group-hover:tw-text-primary-dark">
							No
						</span>
					</label>
				</div>
				<div className="tw-max-w-1/2">
					<label htmlFor={ props.id ? `${props.id}_2` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input checked={ output === 'Yes' ? true : null } onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_2` :  null} value={ 'Yes' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							Yes
						</span>
					</label>
				</div>
				<input required={ props.required || null } type="hidden" ref={ ref } value={ value } name={ props.name || props.id || null } id={ props.id || null } />
			</fieldset>
		</div>
	</>
}


export const CancelType = props => {
	const ref = useRef<HTMLInputElement>(null);
	const [value, setValue] = useState( props.value || '');

	const attributes = {
		...props,
		placeholder: null,
		required: null,
		id: null,
		name: null
	}

	useEffect(() => {
		const temp = value;
		let inputEvent = new Event('change', { bubbles: true });
		ref.current.dispatchEvent(inputEvent);
		if (props.onChange) props.onChange(inputEvent)
    }, [value]);


	return <>
		<div { ...attributes }>
			<Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-message.svg">
				Reason for cancellation
			</Label>
			<fieldset className="tw-flex tw-flex-col tw-space-y-5px">
				<div className="tw-w-full">
					<label htmlFor={ props.id ? `${props.id}_1` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input  onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_1` :  null} value={ props.value || 'Requested by client' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group-hover:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span className="tw-flex-grow tw-text-dark group-hover:tw-text-primary-dark">
							{ props.value || 'Requested by client' }
						</span>
					</label>
				</div>
				<div>
					<label htmlFor={ props.id ? `${props.id}_2` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_2` :  null} value={ props.value2 || 'Requested by parent/guardian' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value2 || 'Requested by parent/guardian' }
						</span>
					</label>
				</div>
				<div>
					<label htmlFor={ props.id ? `${props.id}_3` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_3` :  null} value={ props.value2 || 'Vacation/out of town' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value3 || 'Vacation/out of town' }
						</span>
					</label>
				</div>
				<div>
					<label htmlFor={ props.id ? `${props.id}_4` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_4` :  null} value={ props.value2 || 'Other' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value4 || 'Other' }
						</span>
					</label>
				</div>
				<input required={ props.required || null } type="hidden" ref={ ref } value={ value } name={ props.name || props.id || null } id={ props.id || null } />
			</fieldset>
		</div>
	</>
}


export const ChangeType = props => {
	const ref = useRef<HTMLInputElement>(null);
	const [value, setValue] = useState( props.value || '');

	const attributes = {
		...props,
		placeholder: null,
		required: null,
		id: null,
		name: null
	}

	useEffect(() => {
		const temp = value;
		let inputEvent = new Event('change', { bubbles: true });
		ref.current.dispatchEvent(inputEvent);
		if (props.onChange) props.onChange(inputEvent)
    }, [value]);


	return <>
		<div { ...attributes }>
			<Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-message.svg">
				Reason for changes
			</Label>
			<fieldset className="tw-flex tw-flex-col tw-space-y-5px">
				<div className="tw-w-full">
					<label htmlFor={ props.id ? `${props.id}_1` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input  onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_1` :  null} value={ props.value || 'Changing coverage requirements' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group-hover:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span className="tw-flex-grow tw-text-dark group-hover:tw-text-primary-dark">
							{ props.value || 'Changing coverage requirements' }
						</span>
					</label>
				</div>
				<div>
					<label htmlFor={ props.id ? `${props.id}_2` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_2` :  null} value={ props.value2 || 'Requested by patient' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value2 || 'Requested by patient' }
						</span>
					</label>
				</div>
				<div>
					<label htmlFor={ props.id ? `${props.id}_3` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_3` :  null} value={ props.value2 || 'Requested by parents/guardians' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value3 || 'Requested by parents/guardians' }
						</span>
					</label>
				</div>
				<div>
					<label htmlFor={ props.id ? `${props.id}_4` :  null } className="tw-cursor-pointer tw-flex tw-w-full tw-min-h-30px tw-items-center tw-space-x-5px tw-group">
						<input onChange={ e => {
							if (e.target.checked) setValue(e.target.value)
						}} name={ (props.name || props.id) ? `${props.name || props.id}[]` : null } id={ props.id ? `${props.id}_4` :  null} value={ props.value2 || 'Requested by provider agency' }  type="radio" className="tw-flex-shrink-0 tw-cursor-pointer tw-h-16px tw-w-16px tw-aspect-auto tw-appearance-none tw-inline-flex tw-rounded-full tw-border tw-text-transparent tw-border-dark group:tw-text-primary hover:tw-text-primary focus:tw-text-primary checked:tw-text-primary checked:tw-border-primary c-radio-styles" />
						<span>
							{ props.value4 || 'Requested by provider agency' }
						</span>
					</label>
				</div>
				<input required={ props.required || null } type="hidden" ref={ ref } value={ value } name={ props.name || props.id || null } id={ props.id || null } />
			</fieldset>
		</div>
	</>
}


export const Select = forwardRef((props: any, ref: any): any => {
	const [eventType, setEventType] = useState('_valid-focus');
	const [isValid, setValid] = useState(true);
	const [isEmpty, setEmpty] = useState(true);
	const [invalidClasses, setInvalidClasses] = useState('_invalid _invalid-keyup _invalid-blur');
	const [validClasses, setValidClasses] = useState('_valid _valid-keyup _valid-blur');
	const baseClasses = `c-select !tw-bg-none || tw-relative tw-text-primary hover:tw-text-block tw-bg-white  ${ (props.alt ? FormFieldDefaultStylesBaseResetsAlt : FormFieldDefaultStylesBaseResetsAll) + ' ' + FormFieldDefaultStylesPlaceholder + ' ' + FormFieldDefaultStylesFocus + ' ' + FormFieldDefaultStylesCompleted } tw-border tw-border-primary ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const [fieldClasses, setFieldClasses] = useState(baseClasses);
	useEffect(() => {
		setFieldClasses(`${ baseClasses } || _field-valiations ${ isValid ? validClasses : invalidClasses} ${ isEmpty ? '_empty' : '_not-empty'}`)
	}, [invalidClasses, validClasses, isEmpty, isValid])

	useEffect(() => {
		setFieldClasses(`c-select !tw-bg-none || tw-relative tw-text-primary hover:tw-text-block tw-bg-white  ${ (props.alt ? FormFieldDefaultStylesBaseResetsAlt : FormFieldDefaultStylesBaseResetsAll) + ' ' + FormFieldDefaultStylesPlaceholder + ' ' + FormFieldDefaultStylesFocus + ' ' + FormFieldDefaultStylesCompleted } tw-border tw-border-primary ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`);
	}, [props.className]);

	useEffect(() => {
		const tempValidClasses = validClasses;
		const tempInvalidClasses = invalidClasses;

		if (isEmpty) {
			setValidClasses(tempValidClasses.toLowerCase());
			setInvalidClasses(tempInvalidClasses.toLowerCase());
		} else {

			if (isValid) {
				setValidClasses(tempValidClasses.replaceAll('_valid-' + eventType,( '_valid-' + eventType.toUpperCase())));
				setInvalidClasses(tempInvalidClasses.toLowerCase());
			} else {
				setValidClasses(tempValidClasses.toLowerCase());
				setInvalidClasses(tempInvalidClasses.replaceAll('_invalid-' + eventType, ('_invalid-' + eventType.toUpperCase())));
			}
		}
	}, [isValid, isEmpty, eventType, props.className])

	const handleValidation = useCallback((e) => {
		const field = e.target;
		setEventType(e.type);
		setValid(field.checkValidity());
		setEmpty(field.value.replaceAll(" ", '') === "" ? true : false)
	}, []);

	useLayoutEffect(() => {
		if (props.value) setEmpty(false);
	}, [props.value]);

	useEffect(() => {
		if (props.value) setCurrentValue(props.value);
	}, [props.value]);


	const Tag = props?.tag || 'div';
	const ChildTag = props?.isOutput ? (props?.tag || 'div') : 'select';
	// const classes = `c-select || tw-relative tw-text-primary hover:tw-text-block tw-bg-white  ${ (props.alt ? FormFieldDefaultStylesBaseResetsAlt : FormFieldDefaultStylesBaseResetsAll) + ' ' + FormFieldDefaultStylesPlaceholder + ' ' + FormFieldDefaultStylesFocus + ' ' + FormFieldDefaultStylesCompleted } tw-border tw-border-primary ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const childClasses = `c-select__input !tw-bg-none || ${FormFieldDefaultStylesFocus} ${FormFieldDefaultStylesCompleted} tw-bg-transparent tw-relative tw-w-full  tw-z-10 tw-leading-[1.125] tw-pr-[1.375rem] tw-pl-sm tw-py-sm tw-appearance-none tw-border-0 tw-bg-transparent tw-outline-none tw-text-[1em] tw-outline-none ${ props?.childClasses ? ' || ' + props.childClasses : '' }`;
	const attrId = props.id || uuid();
	const attrName = props.name || attrId;
	const [currentValue, setCurrentValue] = useState<any>('');
	const refId = ref || { current: { value: currentValue} };

	const attributes = {
		...props,
		className: fieldClasses,
		coreClasses: null,
		id: null,
		name: null,
		isOutput: null,
		default: null,
		childClasses: null,
		value: null,
		alt: null
	}

	const setSelected = useCallback(() => {
		if (currentValue && ref) {
			const selected = refId?.current.querySelector(`[value='${currentValue}']`);
			selected?.setAttribute("selected", "selected")
		}
	}, [currentValue]);

	const onChange = (e) => {
		setCurrentValue( refId?.current.value || e?.target?.value || '');
		handleValidation(e);
		if (props.onChange) props.onChange(e);
	};

	useEffect(() => {
		if (ref && props?.value) {
			setCurrentValue(props.value);
			setSelected();
		}
	}, [ref, props?.value])

	useLayoutEffect(() => {
		if (ref && props?.value) setSelected();
	}, [ref, props?.value])

	useLayoutEffect(() => {
		if (props?.value && ref) setCurrentValue(props.value)
	}, [props?.value])

	return <>
		<Tag { ...attributes }>
			<ChildTag
				value={ currentValue}
				className={ childClasses }
				id={ attrId }
				name={ attrName }
				ref={ refId }
				onBlur={ e => {
					if (props.onBlur) props.onBlur();
					handleValidation(e);
				}}
				onChange={ e => onChange(e) }
				children={ props?.isOutput ? <Block children={ props?.value  } /> : props?.children }
			/>

			<label htmlFor={ attrId } className="tw-flex tw-absolute tw-top-0 tw-z-[0] tw-right-0 tw-bottom-0 tw-justify-end tw-items-center tw-border-0 tw-w-[1.375rem] tw-pr-sm">
				<svg className="tw-block" width={rem('12')} height={rem('8')} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z" fill="currentColor"/>
				</svg>
			</label>
		</Tag>
	</>
});

export const Checkbox = forwardRef((props: any, ref: any): any => {
	const Tag = props?.tag || 'div';
	const ChildTag = props?.isOutput ? (props?.tag || 'div') : 'select';
	const classes = `c-select || tw-relative tw-text-primary hover:tw-text-block tw-bg-white  ${ (props.alt ? FormFieldDefaultStylesBaseResetsAlt : FormFieldDefaultStylesBaseResetsAll) + ' ' + FormFieldDefaultStylesPlaceholder + ' ' + FormFieldDefaultStylesFocus + ' ' + FormFieldDefaultStylesCompleted } tw-border tw-border-primary ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const childClasses = `c-select__input || ${FormFieldDefaultStylesFocus} ${FormFieldDefaultStylesCompleted} tw-bg-transparent tw-relative tw-w-full  tw-z-10 tw-leading-[1.125] tw-pr-[1.375rem] tw-pl-sm tw-py-sm tw-appearance-none tw-border-0 tw-bg-transparent tw-outline-none tw-text-[1em] tw-outline-none ${ props?.childClasses ? ' || ' + props.childClasses : '' }`;
	const attrId = props.id || uuid();
	const attrName = props.name || attrId;
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [currentValue, setCurrentValue] = useState<any>('');
	const refId = ref || { current: { value: currentValue} };

	const attributes = {
		...props,
		className: classes,
		coreClasses: null,
		id: null,
		name: null,
		isOutput: null,
		default: null,
		disabled: null,
		childClasses: null,
		value: null,
		alt: null
	}

	const setSelected = useCallback(() => {
		if (currentValue && ref) {
			const selected = refId?.current.querySelector(`[value='${currentValue}']`);
			selected?.setAttribute("selected", "selected")
		}
	}, [currentValue]);

	const onChange = (e) => {
		const isChecked = e?.target?.checked
		setIsChecked(isChecked);
		setCurrentValue( refId?.current.value || e?.target?.value || '');
		if (props.onChange) {
			if (isChecked) {
				props.onChange(e);
			} else {
				e.target.value = props.uncheckedValue || '';
				props.onChange(e);
			}
		}
	};

	useEffect(() => {
		if (isChecked === false) {
			setCurrentValue('');
		}
	}, [isChecked])

	useLayoutEffect(() => {
		if (ref && props?.value) setSelected();
	}, [ref, props?.value])

	useLayoutEffect(() => {
		if (props?.value && ref) setCurrentValue(props.value)
	}, [props?.value])


	useLayoutEffect(() => {
		if (props?.checked) setIsChecked(props?.checked)
	}, [props?.checked])

	return <>
		<span className={`c-checkbox tw-flex tw-items-center  ${ props.alt ? ' _alt ' : '' } ${ props.disabled ? ' tw-pointer-events-none tw-cursor-not-allowed' : '' }`}>
			{ props?.children &&  <label htmlFor={ attrId } className={`c-checkbox__text tw-order-2 tw-cursor-pointer tw-ml-sm tw-px-xs tw-w-full ${ props.active ? ' !tw-bg-primary !tw-text-white tw-border !tw-border-primary !hover:tw-bg-primary !hover:tw-text-white' : ' '  }  ${ props.alt ? '  tw-text-primary tw-border tw-border-primary hover:tw-bg-primary hover:tw-text-white' : ' tw-bg-light-accent' } ${ props.textStyles ? props.textStyles : `${ paragraph3 }` }`}>
				{ props?.children }
			</label> }
			<input value={ props.value || true } disabled={ props.disabled === true ? true : null } checked={ isChecked === true ? true : false } className="c-checkbox__input tw-hidden tw-order-0" type="checkbox" id={ attrId } name={ attrName } onChange={ e => onChange(e) } />
			<label htmlFor={ attrId } className={`tw-order-1 tw-shrink-0 tw-cursor-pointer c-checkbox__label tw-outline-none tw-border  tw-inline-flex tw-items-center tw-justify-center tw-w-18px tw-h-18px tw-overflow-hidden tw-border-light-light tw-text-transparent`}>
				<i className="fa-regular fa-check" />
			</label>
		</span>
	</>
});

export const Label = props => {
	const Tag = props.tag || 'label';
	const ChildTag = props.childTag || 'span';
	const attrFor = props.forHtml || props.for || null;

	const attributes = {
		...props,
		className: null,
		coreClasses: null,
		childClasses: null,
		isOutput: null,
		tag: null,
		childTag: null,
		for: attrFor
	}

	return <>
		<Tag { ...attributes } className={`c-label || tw-flex tw-items-center tw-w-full tw-cursor-pointer tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-[0.125em] ${ props?.className ? ' || ' + props.className : '' }`}>
			{ props.icon ? <>
				<ChildTag className={`tw-flex tw-space-x-5p tw-pb-8p tw-items-center ${props.childClasses}`}>
					<span className="tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
						<img className="tw-w-full" src={`${ props.icon }`} alt="Icon" />
					</span>
					<span className="tw-flex-grow">
						{ props.children }
					</span>
				</ChildTag>
			</> : <>
				<ChildTag className={`tw-flex tw-space-x-5p tw-pb-8p tw-items-center ${props.childClasses}`}>{ props.children }</ChildTag>
			</>}
		</Tag>
	</>
}


export const Field = props => {
	const Tag = props.tag || 'div';
	const ChildTag = props.childTag || 'div';
	const classes = `c-field || tw-flex tw-flex-col tw-justify-stretch tw-align-end${ props?.className ? ' || ' + props.className : '' }`;

	const attributes = {
		...props,
		className: classes,
		coreClasses: null,
		childClasses: null,
		tag: null,
		childTag: null
	}

	return <>
		<Tag { ...attributes }>
			<ChildTag className={`c-field__inner || tw-flex tw-flex-col tw-justify-stretch tw-align-end${ props.childClasses ? ' || ' + props.childClasses : '' }`}>{ props.children }</ChildTag>
		</Tag>
	</>
}
