import Check from "@mui/icons-material/Check";
import { CircleLabel } from "../../components/calendars/CalendarUtils";
import { LabelOutputs } from "../../components/utilities/Helpers";
import CheckboxComponent from "../../components/availabilityComponents/checkBoxComponent";
import DailyAvailabilityComponent from "../../components/availabilityComponents/dailyAvailabilityComponent";
import { CircularProgress } from "@mui/material";
import { ButtonPrimary, ButtonSecondary } from "../../components/buttons/Buttons";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import CalendarSelector from "../../components/calendars/CalendarSelector";
//import moment from "moment";
import { TrashIcon } from "@heroicons/react/24/solid";
import TransitionAvailabilityComponent from "../../components/availabilityComponents/transitionAvailabilityComponent";
import { useNavigate, useParams } from "react-router-dom";
import { getAvailabilityPeriodByID, getProviderAvailability, submitProviderAvailability, updateProviderAvailability } from "../../apicalls/availabilityService";
import { useAppContext } from "../../Context";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { AvailabilityPeriod, Period } from "../../types/availabilityPeriod";
import { DailyAvailability, HolidayAvailability, ProviderAvailability, SchoolBreakAvailability, SchoolBreakDayAvailability, WeeklyHours } from "../../types/providerAvailability";
import { set } from "lodash";
import { Dialog as TWDialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import moment from 'moment-timezone';
import SchoolBreakComponent from "../../components/availabilityComponents/schoolBreakComponent";

const AvailabilityForm = props => {
    let navigate = useNavigate();
    const [savingAvailability, setSavingAvailability] = useState(false);
    const [fetchingAvailabilityPeriod, setFetchingAvailabilityPeriod] = useState(false);
    const [availabilityPeriod, setAvailabilityPeriod] = useState<AvailabilityPeriod>(); // {name: "Winter Transition Period 2023", startDate: "2023-12-11", endDate: "2024-01-07"}
    
    const [previousSubmissionAvailable, setPreviousSubmissionAvailable] = useState(false);
    const [previousSubmission, setPreviousSubmission] = useState<ProviderAvailability>();
    const [available, setAvailable] = useState(true);
    const [unavailabilityComment, setUnavailabilityComment] = useState("");
    const [feedbackComment, setFeedbackComment] = useState("");
    const [unavailabilityCommentError, setUnavailabilityCommentError] = useState(false);
    const [editMode, setEditMode] = useState(true);
    const [showBackConfirmation, setShowBackConfirmation] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [sandboxBreaks, setSandboxBreaks] = useState<Period[]>([
        {
            name: "Winter Break",
            startDate: new Date("2023-12-24"),
            endDate: new Date("2023-12-31"),
            periodID: "1"
        }
    ]);

    const confirmGoBack = () => {
        setShowBackConfirmation(true);
    }

    const handleBackConfirmationClose = () => {
        setShowBackConfirmation(false);
    }

    const handleBackConfirmation = () => {
        setShowBackConfirmation(false);
        navigate(-1);
    }

    const [saveProviderAvailabilityOpen, setSaveProviderAvailabilityOpen] = useState(false);

    const handleSaveProviderAvailabilityClose = () => {
        setSaveProviderAvailabilityOpen(false);
        navigate(-1);
    }
    
    const [weeklyMinimumHoursError, setWeeklyMinimumHoursError] = useState(false);
    const [weeklyMaximumHoursError, setWeeklyMaximumHoursError] = useState(false);
    const [weeklyDesiredHoursError, setWeeklyDesiredHoursError] = useState(false);
    const [weeklyHoursError, setWeeklyHoursError] = useState(false);
    const [supportOptionsError, setSupportOptionsError] = useState(false);
    const [dailyAvailabilitiesError, setDailyAvailabilitiesError] = useState(false);
    const [holidayAvailabilitiesError, setHolidayAvailabilitiesError] = useState(false);
    const [breakAvailabilitiesError, setBreakAvailabilitiesError] = useState(false);

    const [weeklyMinimumHoursErrorMessage, setWeeklyMinimumHoursErrorMessage] = useState("");
    const [weeklyMaximumHoursErrorMessage, setWeeklyMaximumHoursErrorMessage] = useState("");
    const [weeklyDesiredHoursErrorMessage, setWeeklyDesiredHoursErrorMessage] = useState("");
    const [supportOptionsErrorMessage, setSupportOptionsErrorMessage] = useState("");
    const [dailyAvailabilitiesErrorMessage, setDailyAvailabilitiesErrorMessage] = useState("");
    const [holidayAvailabilitiesErrorMessage, setHolidayAvailabilitiesErrorMessage] = useState("");
    const [breakAvailabilitiesErrorMessage, setBreakAvailabilitiesErrorMessage] = useState("");

    const [validationMessage, setValidationMessage] = useState("");
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    const { periodID } = useParams();
    const navContext = useAppContext();

    const [weeklyMinimumHours, setWeeklyMinimumHours] = useState(null);
    const [weeklyMaximumHours, setWeeklyMaximumHours] = useState(null);
    const [weeklyDesiredHours, setWeeklyDesiredHours] = useState(null);

    const handleMimimumHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWeeklyMinimumHours(Number(event.target.value));
    }

    const handleMaximumHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWeeklyMaximumHours(Number(event.target.value));
    }

    const handleDesiredHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWeeklyDesiredHours(Number(event.target.value));
    }

    const validateWeeklyHours = () => {
        if(!weeklyMinimumHours || weeklyMinimumHours < 1) {
            setWeeklyMinimumHoursError(true);
            setWeeklyMinimumHoursErrorMessage("Minimum hours must be greater than zero");
        }
        if(!weeklyMaximumHours || weeklyMaximumHours < 1) {
            setWeeklyMaximumHoursError(true);
            setWeeklyMaximumHoursErrorMessage("Maximum hours must be greater than zero");
        }
        if(!weeklyDesiredHours || weeklyDesiredHours < 1) {
            setWeeklyDesiredHoursError(true);
            setWeeklyDesiredHoursErrorMessage("Desired hours must be greater than zero");
        }

        if(!weeklyMinimumHours || !weeklyMaximumHours || !weeklyDesiredHours) {
            setWeeklyHoursError(true);
            return false;
        }
        if(weeklyMinimumHours < 1 || weeklyMaximumHours < 1 || weeklyMaximumHours < 1) {
            setWeeklyHoursError(true);
            return false;
        } else {
            setWeeklyMinimumHoursError(false);
            setWeeklyMaximumHoursError(false);
            setWeeklyDesiredHoursError(false);
            setWeeklyHoursError(false);
            return true;
        }

    }

    const [directSupportChecked, setDirectSupportChecked] = useState(false);
    const [remoteSupportChecked, setRemoteSupportChecked] = useState(false);

    const handleDirectSupportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDirectSupportChecked(event.target.checked);
    }

    const handleRemoteSupportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRemoteSupportChecked(event.target.checked);
    }

    const validateSupportOptions = () => {
        if(!directSupportChecked && !remoteSupportChecked) {
            setSupportOptionsError(true);
            setSupportOptionsErrorMessage("Please select at least one support option");
            return false;
        } else {
            setSupportOptionsError(false);
            return true;
        }
    }

    const [mondayAvailability, setMondayAvailability] = useState(undefined);
    const [tuesdayAvailability, setTuesdayAvailability] = useState(undefined);
    const [wednesdayAvailability, setWednesdayAvailability] = useState(undefined);
    const [thursdayAvailability, setThursdayAvailability] = useState(undefined);
    const [fridayAvailability, setFridayAvailability] = useState(undefined);
    const [saturdayAvailability, setSaturdayAvailability] = useState(undefined);
    const [sundayAvailability, setSundayAvailability] = useState(undefined);

    const [holidayAvailability, setHolidayAvailability] = useState<HolidayAvailability[]>([]);

    const [schoolBreaksAvailability, setSchoolBreaksAvailability] = useState<SchoolBreakAvailability[]>([]);

    useLayoutEffect(() => {
        const fetchAvailabilityPeriod = async () => {
            setFetchingAvailabilityPeriod(true);

            const period = await getAvailabilityPeriodByID(periodID, navContext.handleAPICallError);

            console.log(period);

            // sort holidays by date
            period.holidays.sort((a, b) => {
                return moment.utc(a.date).diff(moment.utc(b.date));
            });

            // iterate through holidays and add to holiday availability
            const holidayAvailabilities: HolidayAvailability[] = [];
            period.holidays.forEach((holiday) => {
                return holidayAvailabilities.push({
                    holidayName: holiday.holidayName,
                    date: holiday.date,
                    isAvailable: undefined,
                    holidayAvailabilityID: "",
                    providerAvailabilityID: ""
                });
            });

            setHolidayAvailability(holidayAvailabilities);

            // period.hasBreaks = true;
            // period.schoolBreaks = sandboxBreaks;

            const schoolBreaksAvailabilities: SchoolBreakAvailability[] = [];

            period.schoolBreaks.forEach((schoolBreak) => {
                // iterate over the date range and create empty day availabilities
                var schoolBreakDays: SchoolBreakDayAvailability[] = [];

                var currentDate = moment.utc(schoolBreak.startDate);
                const endDate = moment.utc(schoolBreak.endDate);

                while(currentDate <= endDate) {
                    schoolBreakDays.push({
                        schoolBreakDate: currentDate.toDate(),
                        isAvailable: undefined,
                        schoolBreakDayAvailabilityID: "",
                        schoolBreakAvailabilityID: ""
                    });
                    currentDate.add(1, 'days');
                }

                return schoolBreaksAvailabilities.push({
                    schoolBreakName: schoolBreak.name,
                    startDate: schoolBreak.startDate,
                    endDate: schoolBreak.endDate,
                    isAvailable: undefined,
                    schoolBreakAvailabilityID: "",
                    providerAvailabilityID: "",
                    schoolBreakDays: schoolBreakDays
                });
            });

            setSchoolBreaksAvailability(schoolBreaksAvailabilities);


            setAvailabilityPeriod(period);

            await fetchPreviousSubmission();

            setFetchingAvailabilityPeriod(false);
        }

        fetchAvailabilityPeriod();

        const fetchPreviousSubmission = async () => {
            const previousProviderSubmission = await getProviderAvailability(periodID, navContext.handleAPICallError);

            console.log("Previous submission: ");
            console.log(previousProviderSubmission);

            if(previousProviderSubmission.providerName != "Not Available"){
                setPreviousSubmissionAvailable(true);
                setAvailable(previousProviderSubmission.available);
                setUnavailabilityComment(previousProviderSubmission.unavailabilityComment);
                setFeedbackComment(previousProviderSubmission.feedbackComment);
                setPreviousSubmission(previousProviderSubmission);
                setEditMode(false);

                setWeeklyMinimumHours(previousProviderSubmission.weeklyHours.minimum);
                setWeeklyMaximumHours(previousProviderSubmission.weeklyHours.maximum);
                setWeeklyDesiredHours(previousProviderSubmission.weeklyHours.desired);

                setDirectSupportChecked(previousProviderSubmission.directSupportShifts);
                setRemoteSupportChecked(previousProviderSubmission.remoteSupportShifts);

                if(previousProviderSubmission.available){
                    if(previousProviderSubmission.weeklyAvailability.Monday.isAvailable) {
                        previousProviderSubmission.weeklyAvailability.Monday.specificTimeBlocks.forEach((block) => {
                            const date = moment(previousProviderSubmission.submissionDate).format('YYYY-MM-DD');
                            const startTimeString = block.start;
                            const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                            const endTimeString = block.end;
                            const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                            block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                            block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                        });
                    }
                    if(previousProviderSubmission.weeklyAvailability.Tuesday.isAvailable) {
                        previousProviderSubmission.weeklyAvailability.Tuesday.specificTimeBlocks.forEach((block) => {
                            const date = moment(previousProviderSubmission.submissionDate).format('YYYY-MM-DD');
                            const startTimeString = block.start;
                            const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                            const endTimeString = block.end;
                            const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                            // console.log("Start time: " + startTime);
                            // console.log("End time: " + endTime);
                            // console.log("Date: " + date);
                            // console.log(`${date}T${startTime}Z`);
                            block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                            block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                        });
                    }
                    if(previousProviderSubmission.weeklyAvailability.Wednesday.isAvailable) {
                        previousProviderSubmission.weeklyAvailability.Wednesday.specificTimeBlocks.forEach((block) => {
                            const date = moment(previousProviderSubmission.submissionDate).format('YYYY-MM-DD');
                            const startTimeString = block.start;
                            const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                            const endTimeString = block.end;
                            const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                            block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                            block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                        });
                    }
                    if(previousProviderSubmission.weeklyAvailability.Thursday.isAvailable) {
                        previousProviderSubmission.weeklyAvailability.Thursday.specificTimeBlocks.forEach((block) => {
                            const date = moment(previousProviderSubmission.submissionDate).format('YYYY-MM-DD');
                            const startTimeString = block.start;
                            const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                            const endTimeString = block.end;
                            const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                            block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                            block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                        });
                    }
                    if(previousProviderSubmission.weeklyAvailability.Friday.isAvailable) {
                        previousProviderSubmission.weeklyAvailability.Friday.specificTimeBlocks.forEach((block) => {
                            const date = moment(previousProviderSubmission.submissionDate).format('YYYY-MM-DD');
                            const startTimeString = block.start;
                            const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                            const endTimeString = block.end;
                            const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                            block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                            block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                        });
                    }
                    if(previousProviderSubmission.weeklyAvailability.Saturday.isAvailable) {
                        previousProviderSubmission.weeklyAvailability.Saturday.specificTimeBlocks.forEach((block) => {
                            const date = moment(previousProviderSubmission.submissionDate).format('YYYY-MM-DD');
                            const startTimeString = block.start;
                            const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                            const endTimeString = block.end;
                            const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                            block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                            block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                        });
                    }
                    if(previousProviderSubmission.weeklyAvailability.Sunday.isAvailable) {
                        previousProviderSubmission.weeklyAvailability.Sunday.specificTimeBlocks.forEach((block) => {
                            const date = moment(previousProviderSubmission.submissionDate).format('YYYY-MM-DD');
                            const startTimeString = block.start;
                            const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                            const endTimeString = block.end;
                            const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                            block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                            block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                        });
                    }
    
                    setMondayAvailability(previousProviderSubmission.weeklyAvailability.Monday);
                    setTuesdayAvailability(previousProviderSubmission.weeklyAvailability.Tuesday);
                    setWednesdayAvailability(previousProviderSubmission.weeklyAvailability.Wednesday);
                    setThursdayAvailability(previousProviderSubmission.weeklyAvailability.Thursday);
                    setFridayAvailability(previousProviderSubmission.weeklyAvailability.Friday);
                    setSaturdayAvailability(previousProviderSubmission.weeklyAvailability.Saturday);
                    setSundayAvailability(previousProviderSubmission.weeklyAvailability.Sunday);
    
                    if(previousProviderSubmission.hasHolidays && previousProviderSubmission.holidaysAvailability != null){
                        previousProviderSubmission.holidaysAvailability.sort((a, b) => {
                            return moment.utc(a.date).diff(moment.utc(b.date));
                        });
                        setHolidayAvailability(previousProviderSubmission.holidaysAvailability);
                    }

                    if(previousProviderSubmission.hasBreaks && previousProviderSubmission.schoolBreaksAvailability != null){
                        previousProviderSubmission.schoolBreaksAvailability.sort((a, b) => {
                            return moment.utc(a.startDate).diff(moment.utc(b.startDate));
                        });

                        previousProviderSubmission.schoolBreaksAvailability.forEach((schoolBreak) => {
                            schoolBreak.schoolBreakDays?.forEach((day) => {
                                if(day.isAvailable) {
                                    day.specificTimeBlocks?.forEach((block) => {
                                        const date = moment(day.schoolBreakDate).format('YYYY-MM-DD');
                                        const startTimeString = block.start;
                                        const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                                        const endTimeString = block.end;
                                        const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                                        block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                                        block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                                    });
                                }
                            });
                        });

                        // previousSubmission.schoolBreaksAvailability.forEach((schoolBreak) => {
                        //     schoolBreak.schoolBreakDays?.sort((a, b) => {
                        //         return moment.utc(a.schoolBreakDate).diff(moment.utc(b.schoolBreakDate));
                        //     });
                        // });


                        setSchoolBreaksAvailability(previousProviderSubmission.schoolBreaksAvailability);
                    }
                }

                
            }

        }

        //fetchPreviousSubmission();
    }, [])

    useEffect(() => {
        console.log("Holiday Availability: ");
        console.log(holidayAvailability);
    }, [holidayAvailability])

    const validateDailyAvailabilities = () => {
        var valid = true;
        setDailyAvailabilitiesError(false);

        var message = "Please ensure the following days are filled out correctly: ";

        if(mondayAvailability === undefined) {
            valid = false;
            message += "Monday, ";
        } else {
            if(mondayAvailability.isAvailable) {
                if(mondayAvailability.specificTimeBlocks.length === 0) {
                    valid = false;
                    message += "Monday, ";
                }
            }
        }
        if(tuesdayAvailability === undefined) {
            valid = false;
            message += "Tuesday, ";
        } else {
            if(tuesdayAvailability.isAvailable) {
                if(tuesdayAvailability.specificTimeBlocks.length === 0) {
                    valid = false;
                    message += "Tuesday, ";
                }
            }
        }
        if(wednesdayAvailability === undefined) {
            valid = false;
            message += "Wednesday, ";
        } else {
            if(wednesdayAvailability.isAvailable) {
                if(wednesdayAvailability.specificTimeBlocks.length === 0) {
                    valid = false;
                    message += "Wednesday, ";
                }
            }
        }
        if(thursdayAvailability === undefined) {
            valid = false;
            message += "Thursday, ";
        } else {
            if(thursdayAvailability.isAvailable) {
                if(thursdayAvailability.specificTimeBlocks.length === 0) {
                    valid = false;
                    message += "Thursday, ";
                }
            }
        }
        if(fridayAvailability === undefined) {
            valid = false;
            message += "Friday, ";
        } else {
            if(fridayAvailability.isAvailable) {
                if(fridayAvailability.specificTimeBlocks.length === 0) {
                    valid = false;
                    message += "Friday, ";
                }
            }
        }
        if(saturdayAvailability === undefined) {
            valid = false;
            message += "Saturday, ";
        } else {
            if(saturdayAvailability.isAvailable) {
                if(saturdayAvailability.specificTimeBlocks.length === 0) {
                    valid = false;
                    message += "Saturday, ";
                }
            }
        }
        if(sundayAvailability === undefined) {
            valid = false;
            message += "Sunday, ";
        } else {
            if(sundayAvailability.isAvailable) {
                if(sundayAvailability.specificTimeBlocks.length === 0) {
                    valid = false;
                    message += "Sunday, ";
                }
            }
        }

        if (valid) {
            const hoursThreshold = weeklyMinimumHours;
            // Count if total hours is greater than 10
            var totalHours = 0;
            if(mondayAvailability.isAvailable) {
                console.log("Monday availability is available");
                mondayAvailability.specificTimeBlocks.forEach((block) => {
                    var hourDiff = moment.utc(block.end).diff(moment.utc(block.start), 'hours');
                    if(hourDiff < 0) {
                        hourDiff = 24 + hourDiff;
                    }
                    totalHours += hourDiff;
                });
            }
            if(tuesdayAvailability.isAvailable) {
                console.log("Tuesday availability is available");
                tuesdayAvailability.specificTimeBlocks.forEach((block) => {
                    var hourDiff = moment.utc(block.end).diff(moment.utc(block.start), 'hours');
                    if(hourDiff < 0) {
                        hourDiff = 24 + hourDiff;
                    }
                    totalHours += hourDiff;
                });
            }
            if(wednesdayAvailability.isAvailable) {
                console.log("Wednesday availability is available");
                wednesdayAvailability.specificTimeBlocks.forEach((block) => {
                    var hourDiff = moment.utc(block.end).diff(moment.utc(block.start), 'hours');
                    if(hourDiff < 0) {
                        hourDiff = 24 + hourDiff;
                    }
                    totalHours += hourDiff;
                });
            }
            if(thursdayAvailability.isAvailable) {
                console.log("Thursday availability is available");
                thursdayAvailability.specificTimeBlocks.forEach((block) => {
                    var hourDiff = moment.utc(block.end).diff(moment.utc(block.start), 'hours');
                    if(hourDiff < 0) {
                        hourDiff = 24 + hourDiff;
                    }
                    totalHours += hourDiff;
                });
            }
            if(fridayAvailability.isAvailable) {
                console.log("Friday availability is available");
                fridayAvailability.specificTimeBlocks.forEach((block) => {
                    var hourDiff = moment.utc(block.end).diff(moment.utc(block.start), 'hours');
                    if(hourDiff < 0) {
                        hourDiff = 24 + hourDiff;
                    }
                    totalHours += hourDiff;
                });
            }
            if(saturdayAvailability.isAvailable) {
                console.log("Saturday availability is available");
                saturdayAvailability.specificTimeBlocks.forEach((block) => {
                    var hourDiff = moment.utc(block.end).diff(moment.utc(block.start), 'hours');
                    if(hourDiff < 0) {
                        hourDiff = 24 + hourDiff;
                    }
                    totalHours += hourDiff;
                });
            }
            if(sundayAvailability.isAvailable) {
                console.log("Sunday availability is available");
                sundayAvailability.specificTimeBlocks.forEach((block) => {
                    var hourDiff = moment.utc(block.end).diff(moment.utc(block.start), 'hours');
                    if(hourDiff < 0) {
                        hourDiff = 24 + hourDiff;
                    }
                    totalHours += hourDiff;
                });
            }

            if(totalHours < hoursThreshold) {
                console.log("Total hours: " + totalHours);
                console.log("Hours threshold: " + hoursThreshold);
                valid = false;
                message = "Total hours must be at least " + hoursThreshold + " hours.";
            }
        }

        setDailyAvailabilitiesError(!valid);
        setDailyAvailabilitiesErrorMessage(message);

        return valid;
    }

    const handleHolidayAvailabilityChange = (holidayName: string, isAvailable: boolean) => {
        console.log("Holiday name: " + holidayName);
        console.log("Is available: " + isAvailable);

        const updatedHolidayAvailability = holidayAvailability.map((holiday) => {
            if(holiday.holidayName === holidayName) {
                return {
                    ...holiday,
                    isAvailable: isAvailable
                }
            } else {
                return holiday;
            }
        });

        console.log("Updated holiday availability: ");
        console.log(updatedHolidayAvailability);

        setHolidayAvailability(updatedHolidayAvailability);
    }

    const handleSchoolBreakAvailabilityChange = (schoolBreakName: string, availability: SchoolBreakAvailability, isAvailable: boolean) => {
        console.log("School break name: " + schoolBreakName);
        console.log("Is available: " + isAvailable);

        const updatedSchoolBreaksAvailability = schoolBreaksAvailability.map((schoolBreak) => {
            if(schoolBreak.schoolBreakName === schoolBreakName) {
                return {
                    ...schoolBreak,
                    isAvailable: isAvailable,
                    schoolBreakDays: isAvailable ? availability.schoolBreakDays : []
                }
            } else {
                return schoolBreak;
            }
        });

        console.log("Updated school break availability: ");
        console.log(updatedSchoolBreaksAvailability);

        setSchoolBreaksAvailability(updatedSchoolBreaksAvailability);
    }

    const validateHolidayAvailabilities = () => {
        var valid = true;
        setHolidayAvailabilitiesError(false);

        var message = "Please ensure the following holidays are filled out correctly: ";

        holidayAvailability.forEach((holiday) => {
            if(holiday.isAvailable === undefined) {
                valid = false;
                message += holiday.holidayName + ", ";
            }
        });

        setHolidayAvailabilitiesError(!valid);
        setHolidayAvailabilitiesErrorMessage(message);

        return valid;
    }

    const validateSchoolBreakAvailabilities = () => {
        var valid = true;
        setHolidayAvailabilitiesError(false);

        var message = "Please ensure the following school breaks are filled out correctly: ";

        

        schoolBreaksAvailability.forEach((schoolBreak) => {
            if (schoolBreak.isAvailable) {
                var isAtLeastOneDayAvailable = false;
                schoolBreak.schoolBreakDays?.forEach((day) => {
                    if (day.isAvailable) {
                        isAtLeastOneDayAvailable = true;
                    }
                });

                if(!isAtLeastOneDayAvailable) {
                    valid = false;
                    message += "At least one day must be marked as available for " + schoolBreak?.schoolBreakName + ", ";
                }
            }
        });

        

        setBreakAvailabilitiesError(!valid);
        setBreakAvailabilitiesErrorMessage(message);

        return valid;
    }

    const validateUnavailabilityComment = () => {
        if(!available && unavailabilityComment === "") {
            setUnavailabilityCommentError(true);
            return false;
        } else {
            setUnavailabilityCommentError(false);
            return true;
        }
    }


    // useEffect(() => {
    //     validateHolidayAvailabilities();
    // }, [holidayAvailability])

    // useEffect(() => {
    //     validateDailyAvailabilities();
    // }, [mondayAvailability, tuesdayAvailability, wednesdayAvailability, thursdayAvailability, fridayAvailability, saturdayAvailability, sundayAvailability])

    // useEffect(() => {
    //     validateWeeklyHours();
    // }, [weeklyMinimumHours, weeklyMaximumHours, weeklyDesiredHours])

    // useEffect(() => {
    //     validateSupportOptions();
    // }, [directSupportChecked, remoteSupportChecked])

    const dummyInfo = "During the Transition Period from December 7th through January 7th, we're collecting your availability to ensure continuity of care for our clients. Please indicate the days you are available to work and the maximum number of hours you can commit to weekly. Please be advised that you are still expected to work your shifts until December 31st as previously assigned.";

    const validateProviderAvailability = () => {
        var valid = true;
        setShowValidationMessage(false);

        var message = "Please ensure the following sections are filled out correctly: ";

        if(!available){
            if(!validateUnavailabilityComment()) {
                valid = false;
                message += "Reason for unavailability, ";
            }
        } else {
            if(!validateWeeklyHours()) {
                valid = false;
                message += "Weekly hours, ";
            }

            if(!validateSupportOptions()) {
                valid = false;
                message += "Support options, ";
            }

            if(!validateDailyAvailabilities()) {
                valid = false;
                message += "Daily availabilities, ";
            }

            if(!validateHolidayAvailabilities()) {
                valid = false;
                message += "Holiday availability, ";
            }

            if(!validateSchoolBreakAvailabilities()) {
                valid = false;
                message += "School break availability, ";
            }
        }

        setValidationMessage(message);

        return valid;
    }
    
    const saveProviderAvailability = async () => {
        console.log("Saving provider availability");
        console.log("Weekly minimum hours: " + weeklyMinimumHours);
        console.log("Weekly maximum hours: " + weeklyMaximumHours);
        console.log("Weekly desired hours: " + weeklyDesiredHours);
        console.log("Direct support checked: " + directSupportChecked);
        console.log("Remote support checked: " + remoteSupportChecked);
        console.log("Monday availability: ");
        console.log(mondayAvailability);
        console.log("Tuesday availability: ");
        console.log(tuesdayAvailability);
        console.log("Wednesday availability: ");
        console.log(wednesdayAvailability);
        console.log("Thursday availability: ");
        console.log(thursdayAvailability);
        console.log("Friday availability: ");
        console.log(fridayAvailability);
        console.log("Saturday availability: ");
        console.log(saturdayAvailability);
        console.log("Sunday availability: ");
        console.log(sundayAvailability);
        console.log("Holiday availability: ");
        console.log(holidayAvailability);

        var weeklyHours : WeeklyHours = {
            minimum: available ? weeklyMinimumHours : 0,
            maximum: available ? weeklyMaximumHours : 0,
            desired: available ? weeklyDesiredHours : 0
        }

        const availability: { [dayOfWeek: string]: DailyAvailability } = {
            Monday: available ? mondayAvailability : undefined,
            Tuesday: available ? tuesdayAvailability : undefined,
            Wednesday: available ? wednesdayAvailability : undefined,
            Thursday: available ? thursdayAvailability : undefined,
            Friday: available ?  fridayAvailability : undefined,
            Saturday: available ? saturdayAvailability : undefined,
            Sunday: available ? sundayAvailability : undefined
        }

        var period: Period = {
            name: availabilityPeriod?.name,
            startDate: availabilityPeriod?.startDate,
            endDate: availabilityPeriod?.endDate,
            periodID: availabilityPeriod?.periodID
        }

        var newAvailability : ProviderAvailability = {
            providerAvailabilityID: "",
            providerName: "",
            providerID: "",
            availabilityPeriod: period,
            hasHolidays: availabilityPeriod?.hasHolidays,
            hasBreaks: availabilityPeriod?.hasBreaks,
            status: "",
            submissionDate: undefined,
            weeklyHours: weeklyHours,
            directSupportShifts: directSupportChecked,
            remoteSupportShifts: remoteSupportChecked,
            weeklyAvailability: availability,
            holidaysAvailability: available ? holidayAvailability : [],
            schoolBreaksAvailability: schoolBreaksAvailability,
            available: available,
            unavailabilityComment: available ? "Not Applicable" : unavailabilityComment,
            feedbackComment: feedbackComment
        }

        console.log(newAvailability);

        const validForm = validateProviderAvailability();

        if(!validForm) {
            setShowValidationMessage(true);
            return;
        }

        setSavingAvailability(true);

        if(previousSubmissionAvailable){
            newAvailability.providerAvailabilityID = previousSubmission?.providerAvailabilityID;
            newAvailability.status = previousSubmission?.status;
            newAvailability.submissionDate = previousSubmission?.submissionDate;
            newAvailability.providerName = previousSubmission?.providerName;
            newAvailability.providerID = previousSubmission?.providerID;
            setSuccessMessage("You successfully updated your availability.");
            const result = await updateProviderAvailability(availabilityPeriod?.periodID, previousSubmission?.providerAvailabilityID, newAvailability, navContext.handleAPICallError);
        } else {
            setSuccessMessage("You successfully confirmed your availability.");
            const result = await submitProviderAvailability(availabilityPeriod?.periodID, newAvailability, navContext.handleAPICallError);
        }

       

        setSavingAvailability(false);
        setSaveProviderAvailabilityOpen(true);
    }

    const cancelEdit = () => {
        setEditMode(false);
        setDailyAvailabilitiesError(false);
        setWeeklyHoursError(false);
        setSupportOptionsError(false);
        setHolidayAvailabilitiesError(false);
        setValidationMessage("");
        setShowValidationMessage(false);
    }

    const clearAllErrors = () => {
        setDailyAvailabilitiesError(false);
        setWeeklyHoursError(false);
        setWeeklyMaximumHoursError(false);
        setWeeklyMinimumHoursError(false);
        setWeeklyDesiredHoursError(false);
        setSupportOptionsError(false);
        setHolidayAvailabilitiesError(false);
        setValidationMessage("");
        setShowValidationMessage(false);
        setUnavailabilityCommentError(false);
    }

    return (<>
        <Transition.Root show={saveProviderAvailabilityOpen} as={Fragment}>
			<TWDialog as="div" className="tw-relative tw-z-10" onClose={handleSaveProviderAvailabilityClose}>
				<Transition.Child
				as={Fragment}
				enter="tw-ease-out tw-duration-300"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-ease-in tw-duration-200"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
				>
				<div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
				</Transition.Child>

				<div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
					<div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
						<Transition.Child
						as={Fragment}
						enter="tw-ease-out tw-duration-300"
						enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
						enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leave="tw-ease-in tw-duration-200"
						leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
						>
						<TWDialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6">
							<div>
							<div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100">
								<CheckIcon className="tw-h-6 tw-w-6 tw-text-green-600" aria-hidden="true" />
							</div>
							<div className="tw-mt-3 tw-text-center sm:tw-mt-5">
								<TWDialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
								Thank You!
								</TWDialog.Title>
								<div className="tw-mt-2">
								<p className="tw-text-sm tw-text-gray-500">
									{successMessage}
								</p>
								</div>
							</div>
							</div>
							<div className="tw-mt-5 sm:tw-mt-6">
							<button
								type="button"
								className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-primary tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-indigo-600"
								onClick={handleSaveProviderAvailabilityClose}
							>
								OK
							</button>
							</div>
						</TWDialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</TWDialog>
		</Transition.Root>
        <div className="tw-px-xl tw-py-sm">
                    <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900 tw-mt-sm">{availabilityPeriod?.name} Availability Form</h2>
                    </div>

                {fetchingAvailabilityPeriod ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>:
				<div className="tw-flex tw-flex tw-flex-col tw-space-y-20px tw-text-dark tw-pb-sm">
                        <div className="tw-text-8 tw-text-dark tw-w-full tw-mt-20px">
                                <span className="tw-text-[.9em]">
                                    {availabilityPeriod?.description}
                                </span>
                        </div>
                        <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
							<span className="tw-flex-grow">
                                <span className="tw-text-dark tw-font-semibold">Availability Period</span>
							</span>
                            
							{/* <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
								<img className="tw-w-full" src={`/images/icon-arrow-${ toggleRequiredInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
							</span> */}
						</span>
                        <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-pb-3">
                            <li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Period Name
									</LabelOutputs>
								</span>
								<span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{availabilityPeriod?.name}
								</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Start Date
										</LabelOutputs>
									</span>
									<span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                        {moment.utc(availabilityPeriod?.startDate).format('dddd, MMM Do YYYY')}
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										End Date
										</LabelOutputs>
									</span>
									<span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                        {moment.utc(availabilityPeriod?.endDate).format('dddd, MMM Do YYYY')}
									</span>
							</li>
                        </ul>
                        <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                        <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className={`tw-text-dark tw-font-semibold`}>Available to Work During the Specified Availability Period</span>
                                </span>
                        </span>
                        <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                Please indicate whether you are available to work during the availability period outlined in this form. 
                                </span>
                        </div>
                        <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - If unavailable, please give a brief explanation to help us understand your situation and plan effectively.  
                                </span>
                        </div>
                        <div className="tw-flex tw-space-x-4 tw-items-center tw-mt-2">
                                    {/* <span className="tw-w-180px tw-flex tw-items-center">
                                        <LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
                                            Available to Work
                                        </LabelOutputs>
                                    </span> */}
                                    <input
                                    type="checkbox"
                                    checked={available}
                                    onChange={() => {
                                        if (!available) {
                                            setAvailable(true);
                                            clearAllErrors();
                                        }
                                    }}
                                    disabled={!editMode}
                                    className={`tw-form-checkbox tw-h-5 tw-w-5 ${editMode ? "tw-text-primary":"tw-text-gray-500"} tw-rounded`}
                                    />
                                    <span className="tw-text-gray-700">Yes</span>
                                    <input
                                    type="checkbox"
                                    checked={!available}
                                    onChange={() => {
                                        if (available) {
                                            setAvailable(false);
                                            clearAllErrors();
                                        }
                                    }}
                                    disabled={!editMode}
                                    className={`tw-form-checkbox tw-h-5 tw-w-5 ${editMode ? "tw-text-primary":"tw-text-gray-500"} tw-rounded`}
                                    />
                                    <span className="tw-ml-5 tw-text-gray-700">No</span>
                                    
                        </div>
                        {!available && <>
                        <div className="tw-mt-2.5">
                            <textarea
                                name="message"
                                id="message"
                                rows={4}
                                className={`tw-resize-none tw-block tw-w-full tw-rounded-md ${unavailabilityCommentError ? "tw-border-red-500":"tw-border-0"}   tw-px-3.5 tw-py-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                value={unavailabilityComment}
                                onChange={(e) => {
                                    setUnavailabilityComment(e.target.value);
                                    //clearAllErrors();
                                }}
                                disabled={!editMode}
                                placeholder="Enter the reason for your unavailability ."
                            />
                        </div>
                        {unavailabilityCommentError && <div className="tw-text-8 tw-text-red-500 tw-w-full tw-mt-md">
                                <span className="tw-text-[.9em]">
                                Reason required. Please specify why you are unavailable during this period.
                                </span>
                        </div>}
                        </>}
                        </div>
                        {available && <>
                        <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className={`${weeklyHoursError ? "tw-text-red-500": "tw-text-dark"} tw-font-semibold`}>Weekly Hours</span>
                                </span>
                            </span>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                Please provide us with the number of hours you are able to work on a weekly basis. Note that the figures should reflect your total weekly commitment, inclusive of any current schedules, with a preference to maintain a minimum of 10 hours of work per week. 
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - Minimum Hours: The least number of hours you are able to commit to per week.   
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - Maximum Hours: The highest number of hours you are available to work per week.   
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - Desired Hours: Your preferred number of working hours per week.   
                                </span>
                            </div>
                            <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <div className="tw-flex tw-flex-col tw-space-y-1">
                                        <label className="tw-text-gray-700">Minimum Hours</label>
                                        <input
                                            type="number"
                                            min={0}
                                            value={weeklyMinimumHours}
                                            onChange={handleMimimumHoursChange}
                                            disabled={!editMode}
                                            className={`tw-px-3 tw-py-2 tw-border ${editMode ? "tw-bg-white" : "tw-bg-gray-200"} ${weeklyMinimumHoursError ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-border-blue-300`}
                                        />
                                        {weeklyMinimumHoursError && <p className="tw-text-red-500">{weeklyMinimumHoursErrorMessage}</p>}
                                    </div>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <div className="tw-flex tw-flex-col tw-space-y-1">
                                        <label className="tw-text-gray-700">Maximum Hours</label>
                                        <input
                                            type="number"
                                            min={0}
                                            value={weeklyMaximumHours}
                                            onChange={handleMaximumHoursChange}
                                            disabled={!editMode}
                                            className={`tw-px-3 tw-py-2 tw-border ${editMode ? "tw-bg-white" : "tw-bg-gray-200"} ${weeklyMaximumHoursError ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-border-blue-300`}
                                        />
                                        {weeklyMaximumHoursError && <p className="tw-text-red-500">{weeklyMaximumHoursErrorMessage}</p>}
                                    </div>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <div className="tw-flex tw-flex-col tw-space-y-1">
                                        <label className="tw-text-gray-700">Desired Hours</label>
                                        <input
                                            type="number"
                                            min={0}
                                            value={weeklyDesiredHours}
                                            onChange={handleDesiredHoursChange}
                                            disabled={!editMode}
                                            className={`tw-px-3 tw-py-2 tw-border ${editMode ? "tw-bg-white" : "tw-bg-gray-200"} ${weeklyDesiredHoursError ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-border-blue-300`}
                                        />
                                        {weeklyDesiredHoursError && <p className="tw-text-red-500">{weeklyDesiredHoursErrorMessage}</p>}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className={`${supportOptionsError ? "tw-text-red-500": "tw-text-dark"} tw-font-semibold`}>Support Options</span>
                                </span>
                            </span>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                Choose your preferred support role(s): 
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                Choose the support options that best match your skills and experience. Your selection will guide us in aligning your strengths with our clients’ requirements.
                                </span>
                            </div>
                            <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-320px tw-flex tw-items-center">
                                            <LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
                                            Direct Support
                                            </LabelOutputs>
                                        </span>
                                        <input
                                        type="checkbox"
                                        checked={directSupportChecked}
                                        onChange={handleDirectSupportChange}
                                        disabled={!editMode}
                                        className={`tw-form-checkbox tw-h-5 tw-w-5 ${editMode ? "tw-text-primary":"tw-text-gray-500"} tw-rounded`}
                                    />
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-320px tw-flex tw-items-center">
                                            <LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
                                            Remote Support
                                            </LabelOutputs>
                                        </span>
                                        <input
                                        type="checkbox"
                                        checked={remoteSupportChecked}
                                        onChange={handleRemoteSupportChange}
                                        disabled={!editMode}
                                        className={`tw-form-checkbox tw-h-5 tw-w-5 ${editMode ? "tw-text-primary":"tw-text-gray-500"} tw-rounded`}
                                    />
                                </li>
                            </ul>
                            {supportOptionsError && <div className="tw-text-8 tw-text-red-500 tw-w-full tw-mt-md">
                                <span className="tw-text-[.9em]">
                                {supportOptionsErrorMessage}
                                </span>
                            </div>}
                        </div>
                        <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className={`${dailyAvailabilitiesError ? "tw-text-red-500": "tw-text-dark"} tw-font-semibold`}>Daily Availabilities</span>
                                </span>
                            </span>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                For each day of the week, please indicate your availability: 
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - If available, select the day, then specify your start and end times. 
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - If you have more than one availability block per day, you can add additional start and end times. 
                                </span>
                            </div>
                            {/* <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                This detailed information will help us create a comprehensive schedule that respects your time and meets our clients' needs. 
                                </span>
                            </div> */}
                            <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                                <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                    <DailyAvailabilityComponent dayOfWeek="Monday" setDailyAvailablity={setMondayAvailability} editMode={editMode} dailyAvailability={mondayAvailability}/>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                    <DailyAvailabilityComponent dayOfWeek="Tuesday" setDailyAvailablity={setTuesdayAvailability} editMode={editMode} dailyAvailability={tuesdayAvailability}/>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                    <DailyAvailabilityComponent dayOfWeek="Wednesday" setDailyAvailablity={setWednesdayAvailability} editMode={editMode} dailyAvailability={wednesdayAvailability}/>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                    <DailyAvailabilityComponent dayOfWeek="Thursday" setDailyAvailablity={setThursdayAvailability} editMode={editMode} dailyAvailability={thursdayAvailability}/>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                    <DailyAvailabilityComponent dayOfWeek="Friday" setDailyAvailablity={setFridayAvailability} editMode={editMode} dailyAvailability={fridayAvailability}/>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                    <DailyAvailabilityComponent dayOfWeek="Saturday" setDailyAvailablity={setSaturdayAvailability} editMode={editMode} dailyAvailability={saturdayAvailability}/>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                    <DailyAvailabilityComponent dayOfWeek="Sunday" setDailyAvailablity={setSundayAvailability} editMode={editMode} dailyAvailability={sundayAvailability}/>
                                </li>
                                
                            </ul>
                            {dailyAvailabilitiesError && <div className="tw-text-8 tw-text-red-500 tw-w-full tw-mt-md">
                                <span className="tw-text-[.9em]">
                                {dailyAvailabilitiesErrorMessage}
                                </span>
                            </div>
                            }
                            
                        </div>
                        {availabilityPeriod?.hasHolidays && <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className={`${holidayAvailabilitiesError ? "tw-text-red-500": "tw-text-dark"} tw-font-semibold`}>Holiday Availability</span>
                                </span>
                            </span>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - Receive 1.5x hourly rate for working on observed holidays. Scheduled holiday shifts automatically qualify for premium pay. 
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - Consider marking available for observed holidays to earn extra. Your support during these times is crucial for our team and clients.
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - If you’re already scheduled to work on any of the observed holidays and are unable to cover it for any reason, you need to request time off using this link: [<a href="https://provider.ohioathome.com/#/manage/time-off-requests" target="_blank" className="tw-text-blue-500 tw-underline">Request Time-Off</a>]
                                </span>
                            </div>
                            <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                                {availabilityPeriod?.holidays?.map((holiday, index) => {
                                    return (
                                        <li className="tw-flex tw-space-x-5px tw-items-center">
                                            <CheckboxComponent
                                                holiday={holiday}
                                                editMode={editMode}
                                                isAvailable={holidayAvailability[index].isAvailable}
                                                isUnAvailable={holidayAvailability[index].isAvailable === false ? true : false}
                                                onCheckboxChange={handleHolidayAvailabilityChange}
                                            />
                                        </li>);
                                })}
                                
                                {/* <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <CheckboxComponent
                                        name={"New Year's Day - 01/01/2024"}
                                        isChecked={false}
                                    />
                                </li> */}
                            </ul>
                            {holidayAvailabilitiesError && <div className="tw-text-8 tw-text-red-500 tw-w-full tw-mt-md">
                                <span className="tw-text-[.9em]">
                                {holidayAvailabilitiesErrorMessage}
                                </span>
                            </div>
                            }
                            <div className="tw-text-8 tw-text-dark tw-w-full tw-mt-2">
                                <span className="tw-text-[.9em]">
                                - Please Note: For any of these holidays, marking yourself unavailable doesn’t relieve you of previous commitments. Please remember to submit time-off requests.
                                </span>
                            </div>
                            
                        </div>}
                        {availabilityPeriod?.hasBreaks && <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className={`${breakAvailabilitiesError ? "tw-text-red-500": "tw-text-dark"} tw-font-semibold`}>School Break Availability</span>
                                </span>
                            </span>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - Earn 1.5x hourly rate during the following school breaks. All scheduled shifts during these breaks are eligible for premium pay.
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - We encourage you to mark availability during school breaks to benefit from premium pay. Your support is vital and highly valued during these times.
                                </span>
                            </div>
                            <div className="tw-text-8 tw-text-dark tw-w-full">
                                <span className="tw-text-[.9em]">
                                - Please specify your availability for each day of the school break.
                                </span>
                            </div>
                            <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                            {schoolBreaksAvailability?.map((schoolBreak, index) => {
                                return (<>
                                    <SchoolBreakComponent 
                                        breakAvailability={schoolBreak}
                                        editMode={editMode}
                                        setBreakAvailablity={handleSchoolBreakAvailabilityChange}
                                        />
                                </>)
                            })}
                            </ul>
                            {breakAvailabilitiesError && <div className="tw-text-8 tw-text-red-500 tw-w-full tw-mt-md">
                                <span className="tw-text-[.9em]">
                                {breakAvailabilitiesErrorMessage}
                                </span>
                            </div>
                            }
                        </div>}

                        <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className={`tw-text-dark tw-font-semibold`}>Comments</span>
                                </span>
                            </span>

                            <div className="tw-mt-2.5">
                            <textarea
                                name="message"
                                id="message"
                                rows={4}
                                className={`tw-resize-none tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-3.5 tw-py-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                value={feedbackComment}
                                onChange={(e) => {
                                    setFeedbackComment(e.target.value);
                                    //clearAllErrors();
                                }}
                                disabled={!editMode}
                                placeholder="Additional comments or feedback"
                            />
                        </div>
                        </div>

                        
                        </>}

                        {showValidationMessage && <div className="tw-flex tw-justify-end tw-mx-4">
                            <p className="tw-text-red-500">{validationMessage}</p>
                            </div>}
                        <div className="tw-flex tw-justify-end tw-mx-4">
                            {savingAvailability ? <>
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                               
                            </>:<>
                                    {showBackConfirmation ? <>
                                    <div className="tw-flex tw-flex-col">
                                        <p className="tw-text-dark">Are you sure you want to go back? All changes will be lost</p>
                                        <div className="tw-flex tw-flex-row tw-justify-end tw-mx-4">
                                            <ButtonSecondary className={`tw-mt-md tw-mx-4`} onClick={handleBackConfirmationClose}>
                                                No
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={handleBackConfirmation}>
                                                Yes
                                            </ButtonPrimary>
                                        </div>
                                        
                                    </div>
                                    
                                    </>:<>
                                    {editMode ? <>
                                        <ButtonSecondary className={`tw-mt-md ${previousSubmission ? "tw-ml-4" : "tw-mx-4"}`} onClick={confirmGoBack}>
                                           Back
                                        </ButtonSecondary>
                                        {previousSubmissionAvailable && <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelEdit}>
                                           Cancel
                                        </ButtonSecondary>}
                                        <ButtonPrimary className="tw-mt-md" onClick={saveProviderAvailability}>
                                            Save
                                        </ButtonPrimary>
                                    </>:<>
                                        <ButtonSecondary className={`tw-mt-md tw-mx-4`} onClick={handleBackConfirmation}>
                                           Back
                                        </ButtonSecondary>
                                        <ButtonPrimary className="tw-mt-md" onClick={() => setEditMode(true)}>
                                            Edit
                                        </ButtonPrimary>
                                    </>}
                                    </>}
                                    
                            </>}
                                    

                    </div>
                </div>
                }
        </div>
        </>
    );

};

export default AvailabilityForm;