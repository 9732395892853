import { useEffect, useState } from "react";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { UnusualEvent, UnusualEventAction } from "../../types/servicePlan";
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from "formik";
import { ActionSchema, UnusualEventSchema } from "../forms/newUnusualEventSection";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useEditPlanContext } from "../../pages/EditServicePlanPage";
import { addUnusualEvent, deleteUnusualEvent, getUnusualEventByID, updateUnusualEvent } from "../../apicalls/servicePlanService";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useAppContext } from "../../Context";
import { LoadingSpinner } from "./loadingSpinner";
import { InfoAlert } from "./infoAlert";
import { arraysAreEqual } from "./editSupportAreaCard";
import { set } from "lodash";

export const ViewUnusualEventSection = props => {
    return (<>
        <div className="tw-pb-2 tw-flex">
            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">{props?.event.eventTitle}</span>
            {props?.showEdit && <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={e => props?.onEdit(props?.event.unusualEventID)}>Edit</span>}
        </div>
        <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-mt-1">{props?.event.eventDetails}</span>
        <div></div>
        <span className="tw-text-xs tw-underline tw-font-semibold tw-leading-6 tw-text-gray-900">Corresponding Actions:</span>
        <div></div>
        {props?.event.eventActions.length > 0 && props?.event.eventActions.map((action, index) => (
            <>
                <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-mt-1">{action.actionDetails}</span>
                <div></div>
            </>
        ))}
    </>);
}

const EditUnusualEventSection = props => {
    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");

    const [actionDirty, setActionDirty] = useState(false);
    const [originalEvent, setOriginalEvent] = useState<UnusualEvent>(null);
    
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(props);

        const fetchUnusualEvent = async () => {
            const unusualEvent = await getUnusualEventByID(props?.event.unusualEventID, appContext.handleAPICallError);
            console.log(unusualEvent);
            setOriginalEvent(unusualEvent);
        }

        if(props?.editPlan){
            fetchUnusualEvent();
        } else {
            setOriginalEvent(props?.event);
        }

        

    },[]);

    const [showUnusualEventActionCard, setShowUnusualEventActionCard] = useState(false);

    const initialFormValues = {
        eventTitle: props?.event.eventTitle,
        eventDetails: props?.event.eventDetails,
        eventActions: props?.event.eventActions,
    }

    const initialActionValues = {
        actionDetails: '',
    }

    const unusualEventFormik = useFormik({
        initialValues: initialFormValues,
        validationSchema: UnusualEventSchema,
        onSubmit: (values) => {
            console.log(values);

            var eventActions: UnusualEventAction[] = [];

            values.eventActions.forEach((action) => {
                var newAction: UnusualEventAction = {
                    unusualEventActionID: uuidv4(),
                    unusualEventID: "",
                    actionDetails: action.actionDetails,
                }
                
                eventActions.push(newAction);
            })

            var newEvent: UnusualEvent = {
                unusualEventID: props?.event.unusualEventID,
                servicePlanID: "",
                eventTitle: values.eventTitle,
                eventDetails: values.eventDetails,
                eventActions: eventActions, 
            }

            console.log(newEvent);

            props?.onSave(props?.event.unusualEventID, newEvent);
            
        }
    });

    const handleRemoveUnusualEvent = () => {
        if(props?.editPlan){
            setShowConfirmDelete(true);
        } else {
        const index = planContext.planUnusualEvents.indexOf(props?.event);
        const newItems = [...planContext.planUnusualEvents.slice(0, index), ...planContext.planUnusualEvents.slice(index + 1)];
        planContext.setPlanUnusualEvents(newItems);
        }
    }

    const deleteLocalEvent = async () => {
        const index = editPlanContext.planUnusualEvents.indexOf(props?.event);
        const newItems = [...editPlanContext.planUnusualEvents.slice(0, index), ...editPlanContext.planUnusualEvents.slice(index + 1)];
        editPlanContext.setPlanUnusualEvents(newItems);
        var result = await deleteUnusualEvent(editPlanContext.servicePlan.servicePlanID, props?.event.unusualEventID, appContext.handleAPICallError);
        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Unusual Event Deleted Successfully");
            appContext.setShowSuccessModal(true);
        }
        
        setShowConfirmDelete(false);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const actionFormik = useFormik({
        initialValues: initialActionValues,
        validationSchema: ActionSchema,
        onSubmit: (values) => {
            console.log(values);
            var newAction: UnusualEventAction = {
                unusualEventActionID: uuidv4(),
                unusualEventID: "",
                actionDetails: values.actionDetails,
            }
            unusualEventFormik.values.eventActions.push(newAction);
            unusualEventFormik.validateField('eventActions');
            if(!arraysAreEqual(originalEvent.eventActions, unusualEventFormik.values.eventActions)){
                setActionDirty(true);
            }
            if(!props?.editPlan){
                setActionDirty(true);
            }
            setShowInfoMessage(true);
            setInfoMessage("New corresponding action added to the list. Press save to keep your changes.");
            setTimeout(() => {
                setShowInfoMessage(false);
            }, 5000);

            toggleUnusualEventCard();
        }
    });

    const toggleUnusualEventCard = () => {
        actionFormik.resetForm();
        setShowUnusualEventActionCard(!showUnusualEventActionCard);
    }

    const handleUpdateActionsList = (actions) => {
        unusualEventFormik.setFieldValue('eventActions', actions, true);
        if(!arraysAreEqual(originalEvent.eventActions, unusualEventFormik.values.eventActions)){
            setActionDirty(true);
        }
        // unusualEventFormik.values.eventActions = actions;
        // unusualEventFormik.validateField('eventActions');
    }

    const discardChanges = () => {
        if(props?.editPlan){
            unusualEventFormik.resetForm();
            console.log(props?.event.unusualEventID);
            console.log(originalEvent);
            editPlanContext.setPlanUnusualEvents(editPlanContext.planUnusualEvents.map(
                el => el.unusualEventID ===  originalEvent.unusualEventID? { ...el, ...originalEvent } : el
            ));
        }
        
        //supportAreaFormik.setFieldValue('services', originalArea.personalServices, true);
        props?.onCancel();
    }
    
    return (<>
        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Edit Unusual Event</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Event Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${unusualEventFormik.touched.eventTitle && unusualEventFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...unusualEventFormik.getFieldProps('eventTitle')}
                                                    />
                                                    {unusualEventFormik.touched.eventTitle && unusualEventFormik.errors.eventTitle && <p className="tw-text-red-500"><>{unusualEventFormik.errors.eventTitle}</></p>}
                                                </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Event Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="details"
                                                    id="details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${unusualEventFormik.touched.eventDetails && unusualEventFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...unusualEventFormik.getFieldProps('eventDetails')}
                                                    />
                                                    {unusualEventFormik.touched.eventDetails && unusualEventFormik.errors.eventDetails && <p className="tw-text-red-500"><>{unusualEventFormik.errors.eventDetails}</></p>}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Corresponding Actions</span>
                                        <PlusCircleIcon className="tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={toggleUnusualEventCard} />
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={toggleUnusualEventCard}>Add</span> */}
                                    </div>

                                    {showInfoMessage && <InfoAlert message={infoMessage} />}

                                    {showUnusualEventActionCard && 
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Action Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="actionDetails"
                                                    id="actionDetails"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionFormik.touched.actionDetails && actionFormik.errors.actionDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionFormik.getFieldProps('actionDetails')}
                                                    />
                                                    {actionFormik.touched.actionDetails && actionFormik.errors.actionDetails && <p className="tw-text-red-500">{actionFormik.errors.actionDetails}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleUnusualEventCard}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={actionFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>
                                    }

                                    <EditEventActionList actions={props?.event.eventActions} onUpdate={handleUpdateActionsList} setInfoMessage={setInfoMessage} setShowInfoMessage={setShowInfoMessage}></EditEventActionList>

                                    <div>
                                        {unusualEventFormik.touched.eventActions && unusualEventFormik.errors.eventActions && <p className="tw-text-red-500"><>{unusualEventFormik.errors.eventActions}</></p>}
                                    </div>  
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                                    {showConfirmDelete ? <>
                                    <p className="tw-text-primary">Are you sure you want to delete this unusual event?</p>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deleteLocalEvent}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={handleRemoveUnusualEvent}>
                                                {props?.editPlan ? 'Delete' : 'Remove'}
                                            </ButtonSecondary>
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={discardChanges}>
                                                {(unusualEventFormik.dirty || actionDirty) ? 'Discard' : 'Cancel'}
                                            </ButtonSecondary>
                                            {(unusualEventFormik.dirty || actionDirty) && <ButtonPrimary className="tw-mt-md" onClick={unusualEventFormik.handleSubmit}>
                                                Update
                                            </ButtonPrimary>}

                                    </div>   
                                    </>}
    </>);
}

const EditEventActionList = props => {

    const [editActionId, setEditActionId] = useState<string | null>(null);
    const [actions, setActions] = useState<UnusualEventAction[]>(props?.actions);

    function handleActionEdit(id: string) {
        setEditActionId(id);
    }

    function handleActionSave(id: string, updatedAction: UnusualEventAction) {
        console.log(updatedAction);

        const updatedActions = actions.map(
            el => el.unusualEventActionID ===  updatedAction.unusualEventActionID? { ...el, ...updatedAction } : el
        );

        setActions(updatedActions);

        props?.onUpdate(updatedActions);

        props?.setInfoMessage("Corresponding Action Updated. Save unusual event to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);

        setEditActionId(null);
    }

    function handleActionCancel() {
        // Reset the edit ID
        setEditActionId(null);
    }

    function handleRemoveAction(id: string) {
        const updatedActions = actions.filter((action) => action.unusualEventActionID !== id);
        setActions(updatedActions);
        props?.onUpdate(updatedActions);

        props?.setInfoMessage("Corresponding Action Removed. Save unusual event to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);
    }

    return (<>
         {actions.length > 0 && actions.map((action, index) => (
                                        <div className="tw-space-y-6 tw-bg-card-green tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4" key={action.unusualEventActionID}>
                                            <EditEventActionSection action={action} onEdit={handleActionEdit} onSave={handleActionSave} onCancel={handleActionCancel} onRemove={handleRemoveAction} isEditing={action.unusualEventActionID === editActionId}></EditEventActionSection>
                                        </div>
        ))}
    </>);

}

const EditEventActionSection = props => {
    const initialActionValues = {
        actionDetails: props?.action.actionDetails,
    }

    const actionFormik = useFormik({
        initialValues: initialActionValues,
        validationSchema: ActionSchema,
        onSubmit: (values) => {
            console.log(values);
            var newAction: UnusualEventAction = {
                unusualEventActionID: props?.action.unusualEventActionID,
                unusualEventID: "",
                actionDetails: values.actionDetails,
            }
            props?.onSave(props?.action.unusualEventActionID, newAction);
        }
    });


    return (<>
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Action Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="actionDetails"
                                                    id="actionDetails"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionFormik.touched.actionDetails && actionFormik.errors.actionDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionFormik.getFieldProps('actionDetails')}
                                                    disabled={!props?.isEditing}
                                                    />
                                                    {actionFormik.touched.actionDetails && actionFormik.errors.actionDetails && <p className="tw-text-red-500"><>{actionFormik.errors.actionDetails}</></p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            {props?.isEditing ? <>
                                                <ButtonSecondary className="tw-mt-md" onClick={() => props?.onRemove(props?.action.unusualEventActionID)}>
                                                            Remove
                                                    </ButtonSecondary>
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={() => props?.onCancel()}>
                                                        {actionFormik.dirty ? 'Discard' : 'Cancel'}
                                                    </ButtonSecondary>
                                                    {actionFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={actionFormik.handleSubmit}>
                                                        Update
                                                    </ButtonPrimary>}
                                            </>: <>
                                                    <ButtonPrimary className="tw-mt-md" onClick={() => props?.onEdit(props?.action.unusualEventActionID)}>
                                                        Edit
                                                    </ButtonPrimary>
                                                
                                            </>}

                                                    

                                        </div>
    </>);
}

const EditUnusualEventCard = props => {

    return (<>
        {props?.isEditing ? <EditUnusualEventSection event={props.event} onEdit={props?.onEdit} onSave={props?.onSave} onCancel={props?.onCancel} editPlan={props?.editPlan}  /> : <ViewUnusualEventSection event={props.event} onEdit={props?.onEdit} showEdit={true}/>}
    </>);
}

export const EditUnusualEventList = props => {
    const [editId, setEditId] = useState<string | null>(null);

    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();

    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, newEvent: UnusualEvent) {
        if(props?.editPlan){
            var result = await updateUnusualEvent(newEvent, editPlanContext.servicePlan.servicePlanID, newEvent.unusualEventID, appContext.handleAPICallError)
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Unusual Event Updated Successfully");
                appContext.setShowSuccessModal(true);
                editPlanContext.setPlanUnusualEvents(editPlanContext.planUnusualEvents.map(
                    el => el.unusualEventID ===  newEvent.unusualEventID? { ...el, ...newEvent } : el
                ));
            }
            
        } else {
            planContext.setPlanUnusualEvents(planContext.planUnusualEvents.map(
                el => el.unusualEventID ===  newEvent.unusualEventID? { ...el, ...newEvent } : el
            ));
        }
        

        setEditId(null);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    const [showUnusualEventCard, setShowUnusualEventCard] = useState(false);

    const initialEventValues = {
        eventTitle: '',
        eventDetails: '',
        eventActions: [],
    }

    const initialActionValues = {
        actionDetails: '',
    }

    const unusualEventFormik = useFormik({
        initialValues: initialEventValues,
        validationSchema: UnusualEventSchema,
        onSubmit: async (values) => {
            console.log(values);

            var eventActions: UnusualEventAction[] = [];

            values.eventActions.forEach((action) => {
                var newAction: UnusualEventAction = {
                    unusualEventActionID: "",
                    unusualEventID: "",
                    actionDetails: action.actionDetails,
                }
                
                eventActions.push(newAction);
            })

            var newEvent: UnusualEvent = {
                unusualEventID: "",
                servicePlanID: "",
                eventTitle: values.eventTitle,
                eventDetails: values.eventDetails,
                eventActions: eventActions, 
            }

            setLoading(true);

            var result = await addUnusualEvent(newEvent, editPlanContext.servicePlan.servicePlanID, appContext.handleAPICallError);
            await editPlanContext.updateLocalUnusualEvents();
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Unusual Event Added Successfully");
                appContext.setShowSuccessModal(true);
            }
            

            //editPlanContext.setPlanUnusualEvents([...editPlanContext.planUnusualEvents, newEvent]);
            toggleUnusualEventCard();
            
        }
    });

    const actionFormik = useFormik({
        initialValues: initialActionValues,
        validationSchema: ActionSchema,
        onSubmit: (values) => {
            console.log(values);
            unusualEventFormik.values.eventActions.push(values);
            unusualEventFormik.validateField('eventActions');
            actionFormik.resetForm();
        }
    });

    const toggleUnusualEventCard = () => {
        setShowUnusualEventCard(!showUnusualEventCard);
        setEditId(null);
        setLoading(false);
        unusualEventFormik.resetForm();
        actionFormik.resetForm();
        unusualEventFormik.setFieldValue('eventActions', []);
    }

    const handleRemoveAction = (action) => {
        const index = unusualEventFormik.values.eventActions.indexOf(action);
        unusualEventFormik.values.eventActions.splice(index, 1);
        unusualEventFormik.setFieldValue('eventActions', unusualEventFormik.values.eventActions);
    }

    return (<>
        {props?.editPlan && <>
            {showUnusualEventCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">New Unusual Event</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Event Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${unusualEventFormik.touched.eventTitle && unusualEventFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...unusualEventFormik.getFieldProps('eventTitle')}
                                                    />
                                                    {unusualEventFormik.touched.eventTitle && unusualEventFormik.errors.eventTitle && <p className="tw-text-red-500">{unusualEventFormik.errors.eventTitle}</p>}
                                                </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Event Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="details"
                                                    id="details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${unusualEventFormik.touched.eventDetails && unusualEventFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...unusualEventFormik.getFieldProps('eventDetails')}
                                                    />
                                                    {unusualEventFormik.touched.eventDetails && unusualEventFormik.errors.eventDetails && <p className="tw-text-red-500">{unusualEventFormik.errors.eventDetails}</p>}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Corresponding Actions</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Action Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="actionDetails"
                                                    id="actionDetails"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionFormik.touched.actionDetails && actionFormik.errors.actionDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionFormik.getFieldProps('actionDetails')}
                                                    />
                                                    {actionFormik.touched.actionDetails && actionFormik.errors.actionDetails && <p className="tw-text-red-500">{actionFormik.errors.actionDetails}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={actionFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={actionFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>

                                    {unusualEventFormik.values.eventActions.length > 0 && unusualEventFormik.values.eventActions?.map((action, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-2">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Action Details
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="actionDetails"
                                                        id="actionDetails"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={action.actionDetails}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                    e.preventDefault();
                                                    handleRemoveAction(action);
                                                    }}>
                                                        Remove
                                                </ButtonSecondary>

                                            </div>
                                        </div>))} 
                                    
                                    <div>
                                        {unusualEventFormik.touched.eventActions && unusualEventFormik.errors.eventActions && <p className="tw-text-red-500"><>{unusualEventFormik.errors.eventActions}</></p>}
                                    </div>

                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleUnusualEventCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={unusualEventFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                    </>}
                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2 tw-mb-4">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleUnusualEventCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Unusual Event</span>
                                            </div>  
                                    </div>
                            </div>
                            </>}
            </>}
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
            {props?.events.length > 0 && props?.events.map((event, index) => (
                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow" key={event.unusualEventID}>
                    <EditUnusualEventCard event={event} onEdit={handleEdit} onSave={handleSave} onCancel={handleCancel} isEditing={event.unusualEventID === editId} editPlan={props?.editPlan}></EditUnusualEventCard>
                </div>
            ))}
        </div>
    </>);
}

export default EditUnusualEventCard;
