import axios, { AxiosRequestConfig } from "axios";
import Employee from "../types/employee";
import { getAPIToken } from "./billingService";
import CoverageEvent from "../types/coverageEvent";

export const getClientDirectSupportProviders = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<Employee[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clients/'+patientID+'/directSupportTeam';
    return axios.get<Employee[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const addAssignedEventToDSRequirement = async (requirementID: string, event: CoverageEvent, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'directSupportRequirements/'+requirementID+'/events';
    return axios.post(url,event,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

