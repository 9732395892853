import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import { PencilIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { addressSchema } from "../../pages/NewServiceLocationForm";
import { getAddressLatLng, updateServiceLocation } from "../../apicalls/serviceLocationService";
import ServiceLocation, { Address } from "../../types/serviceLocation";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { LoadingSpinner } from "./loadingSpinner";
import { set } from "react-hook-form";

export const EditLocationAddressCard = props => {
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const appContext = useAppContext();

    useLayoutEffect(() => {
        console.log(props.location);
    }, [props.location])

    const toggleEditMode = () => {
        setEditMode(!editMode);
    }

    const addressFormik = useFormik({
        initialValues: {
            AddressType: props?.location.physicalAddress.addressType,
            Street1: props?.location.physicalAddress.street1,
            Street2: props?.location.physicalAddress.street2,
            City: props?.location.physicalAddress.city,
            State: props?.location.physicalAddress.state,
            ZipCode: props?.location.physicalAddress.zip,
        },
        validationSchema: addressSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Form submitted")
            console.log(values)
            setLoading(true);
            var addressString = values.Street1 + " " + values.Street2 + " " + values.City + " " + values.State + " " + values.ZipCode;

            const geoCodes = await getAddressLatLng(addressString, appContext.handleAPICallError);
            console.log(geoCodes.lat);
            console.log(geoCodes.lng);

            const newAddress: Address = {
                addressType: values.AddressType,
                street1: values.Street1,
                street2: values.Street2,
                city: values.City,
                state: values.State,
                zip: values.ZipCode,
                latitude: geoCodes.lat,
                longitude: geoCodes.lng,
            }

            const newServiceLocation: ServiceLocation = {
                physicalAddress: newAddress,
                locationCategory: "",
                rooms: [],
                credentials: [],
                serviceLocationID: props?.location?.serviceLocationID,
                smartHubs: [],
                smartSensors: [],
                smartCameras: []
            }

            console.log(newServiceLocation);
            var result = await updateServiceLocation(props?.location?.serviceLocationID, newServiceLocation, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Physical address updated successfully.");
                appContext.setShowSuccessModal(true);
            }
            props?.refreshLocation(props?.location?.serviceLocationID);
            setLoading(false);
            toggleEditMode();

            
        },
    });

    return (<>
        {editMode ? <>
            <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow sm:tw-rounded-lg sm:tw-p-6">
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Physical Address</span>
                        
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    {/* <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Address Tag
                                        </label>
                                        <select
                                            id="address-tag"
                                            name="address-tag"
                                            className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                            defaultValue="Primary"
                                        >
                                            <option>Primary</option>
                                            <option>Secondary</option>
                                        </select>
                                    </div>
                                    <div className="tw-col-span-1">

                                    </div> */}

                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Address Type
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="address-type"
                                            id="address-type"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.AddressType && addressFormik.errors.AddressType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('AddressType')}
                                            />
                                            {addressFormik.touched.AddressType && addressFormik.errors.AddressType && <p className="tw-text-red-500"><>{addressFormik.errors.AddressType}</></p>}
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Street 1
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="street-1"
                                            id="street-1"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.Street1 && addressFormik.errors.Street1 ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('Street1')}
                                            />
                                            {addressFormik.touched.Street1 && addressFormik.errors.Street1 && <p className="tw-text-red-500"><>{addressFormik.errors.Street1}</></p>}
                                        </div>
                                    </div>

                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Street 2
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="street-2"
                                            id="street-2"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.Street2 && addressFormik.errors.Street2 ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('Street2')}
                                            />
                                            {addressFormik.touched.Street2 && addressFormik.errors.Street2 && <p className="tw-text-red-500"><>{addressFormik.errors.Street2}</></p>}
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            City
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="city"
                                            id="city"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.City && addressFormik.errors.City ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('City')}
                                            />
                                            {addressFormik.touched.City && addressFormik.errors.City && <p className="tw-text-red-500"><>{addressFormik.errors.City}</></p>}
                                        </div>
                                    </div>

                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            State
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="state"
                                            id="state"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.State && addressFormik.errors.State ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('State')}
                                            />
                                            {addressFormik.touched.State && addressFormik.errors.State && <p className="tw-text-red-500"><>{addressFormik.errors.State}</></p>}
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Zip Code
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="zip-code"
                                            id="zip-code"
                                            className={`tw-block tw-w-full tw-rounded-md ${addressFormik.touched.ZipCode && addressFormik.errors.ZipCode ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            {...addressFormik.getFieldProps('ZipCode')}
                                            />
                                            {addressFormik.touched.ZipCode && addressFormik.errors.ZipCode && <p className="tw-text-red-500"><>{addressFormik.errors.ZipCode}</></p>}
                                        </div>
                                    </div>

                            </div>
                        
                        {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                        <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleEditMode}>
                                            {addressFormik.dirty ? "Discard" : "Cancel"}
                                    </ButtonSecondary>
                                    {addressFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={addressFormik.handleSubmit}>
                                            Save
                                    </ButtonPrimary>}

                        </div>
                        </>}
                    </div>
        </> : <>
            <div className="tw-pb-5 tw-flex tw-px-4">
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Physical Address</span>

                <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={toggleEditMode} />
            </div>

            <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Address Type</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.location?.physicalAddress.addressType}</span>
            </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Street 1</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.location?.physicalAddress.street1}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Street 2</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.location?.physicalAddress.street2}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>City</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.location?.physicalAddress.city}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>State</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.location?.physicalAddress.state}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Zip Code</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.location?.physicalAddress.zip}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Latitude</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.location?.physicalAddress.latitude}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Longitude</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.location?.physicalAddress.longitude}</span>
                        </div>
        </>}
    </>);
}