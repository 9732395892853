const ChildTag = props => {
	const Tag = props?.tag || 'span';
	return <Tag className={ props.className || null }>{ props.children }</Tag>
}

const Header = props => {
	const Tag = props?.tag || 'h2';
	const classes = `c-${ props?.name || 'header' } ${ props?.coreClasses || '' } ${ props?.className ? ' || ' + props.className : '' }`;
	const attributes = {
		...props,
		className: classes,
		childClasses: null,
		childTag: null,
		default: null,
		coreClasses: null,
		tag: null,
		name: null
	}

	return <Tag { ...attributes }>
		{ (props?.default === false) ? props?.children : <ChildTag tag={ props?.childTag || null } className={ props?.childClasses || null }> { props?.children } </ChildTag> }
	</Tag>
}

export const ScreenHeader = props => {
	return <Header { ...props } tag={ props?.tag || 'h1' } name="screen-header" coreClasses={ props?.override || 'tw-text-0 tw-leading-[3rem] tw-font-medium'}>{ props?.children }</Header>
}

export const ScreenSubHeader = props => {
	return <Header { ...props } tag={ props?.tag || 'h2' } name="screen-subheader" coreClasses={ props?.override || 'tw-text-1 tw-leading-7 tw-font-medium'}>{ props?.children }</Header>
}

export const PageHeader = props => {
	return <ScreenHeader className="tw-leading-1" { ...props }>{ props?.children }</ScreenHeader>
}

export const PageSubHeader = props => {
	return <ScreenSubHeader { ...props }>{ props?.children }</ScreenSubHeader>
}

export const SectionHeader = props => {
	return <Header { ...props } tag={ props?.tag || 'h3' } name="section-header" coreClasses={ props?.override || 'tw-text-2 tw-leading-6 tw-font-semibold'}>{ props?.children }</Header>
}

export const SectionSubheader = props => {
	return <Header { ...props } tag={ props?.tag || 'h4' } name="section-subheader" coreClasses={ props?.override || 'tw-text-3 tw-leading-[1.375rem] tw-font-medium'}>{ props?.children }</Header>
}

export const ArticleHeader = props => {
	return <Header { ...props } tag={ props?.tag || 'h5' } name="article-header" coreClasses={ props?.override || 'tw-text-4 tw-leading-5 tw-font-medium'}>{ props?.children }</Header>
}

export const ArticleSubheader = props => {
	return <Header { ...props } tag={ props?.tag || 'h6' } name="article-subheader" coreClasses={ props?.override || 'tw-text-4 tw-leading-5 tw-font-medium'}>{ props?.children }</Header>
}

export const ParagraphHeader = props => {
	return <Header { ...props } tag={ props?.tag || 'p' } name="paragraph-header" coreClasses={ props?.override || 'tw-text-3 tw-leading-7 tw-font-medium'}>{ props?.children }</Header>
}

export const ParagraphSubheader = props => {
	return <Header { ...props } tag={ props?.tag || 'span' } name="paragraph-subheader" coreClasses={ props?.override || 'tw-text-3 tw-leading-6 tw-font-medium'}>{ props?.children }</Header>
}

export const Paragraph1 = props => {
	return <ParagraphHeader { ...props }>{ props?.children }</ParagraphHeader>
}

export const Paragraph2 = props => {
	return <ParagraphSubheader { ...props }>{ props?.children }</ParagraphSubheader>
}

export const Paragraph3 = props => {
	return <Header { ...props } tag={ props?.tag || 'span' } name="paragraph-subheader" coreClasses={ props?.override || 'tw-text-4 tw-leading-5 tw-font-medium'}>{ props?.children }</Header>
}

export const DynamicText = props => {
	return <Header { ...props } tag={ props?.tag || 'span' } name="dynamic-text" coreClasses={'tw-text-dynamic '}>{ props?.children }</Header>
}

export default Header;
