import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getActiveClients, populateLegacyScheduleForPatient } from "../apicalls/schedulingService"
import Button, { ButtonAnchor, ButtonPrimary } from "../components/buttons/Buttons"
import Calendar from "../components/containers/Calendars"
import { ClientDetailsCard, ClientInfoCard, ContactCard } from "../components/containers/Cards"
import { Search } from "../components/forms/formFields"
import { PageHeader, PageSubHeader, Paragraph3 } from "../components/typography/Headers"
import { Ellipsis } from "../components/utilities/Helpers"
import Core from "../templates/Core"
import Patient from "../types/patient"
import { rem } from "../utilities/helpers"
import { articleHeader } from "../utilities/typography"
import { useAppContext } from "../Context"

const ClientSchedulePage = props => {
    const [showClientList, setShowClientList] = useState(true);
    const [selectedClient, setSelectedClient] = useState<Patient>(null);
    const [activePatients, setActivePatients] = useState<Patient[]>([]);
    const [allPatients, setAllPatients] = useState<Patient[]>([]);
    const [clientsLoading, setClientsLoading] = useState(true);

    let navigate = useNavigate();

    const appContext = useAppContext();

    useEffect(() => {
		const fetchClients = async () => {
			const data = await getActiveClients(appContext.handleAPICallError);
			console.log(data);
            const activePatients = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName));
			setActivePatients(activePatients);
            setAllPatients(activePatients);
			setClientsLoading(false);
		}

		fetchClients()
    		.catch(console.error);
	}, []);

    const goToClientCoverageForm = () => {
		navigate("/ClientSchedule/"+ selectedClient.patientID +"/New");
	};

    const showClientSchedule = (patient) => {
        navigate("/schedule/client-schedule/"+ patient.patientID +"/legacy");
    }

    const backToClientList = () => {
        console.log("Back To Client List");

        setShowClientList(true);

        setSelectedClient(null);
    } 

    return (<>
        {/* <Core { ...props }> */}
        <div className="tw-px-xl tw-py-sm">
				<div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
					{/* <div className="tw-flex-grow tw-flex-shrink-0">
						<PageHeader>
							<Ellipsis>
								Legacy Client Schedule
							</Ellipsis>
						</PageHeader>
					</div> */}
                     <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Legacy Client Schedule</h2>
                    </div>
                  
				</div>
                
		</div>
        <div className="tw-px-xl tw-pb-sm tw-text-dark">
        <div className="tw-px-xl">
                    <div className="tw-space-y-sm">
                        <div className="">
                            <PageSubHeader tag="h2" className="tw-sticky tw-top-0 tw-z-10 tw-bg-light-accent tw-py-sm ">
								Active Clients
							</PageSubHeader>
                        </div>

                        { clientsLoading ? 
                            <div className="tw-flex tw-justify-center tw-m-md">
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </div>
                            :
                            <>
                            <div className={`tw-sticky tw-top-[0.00rem] tw-z-10 tw-bg-light-accent tw-p-md tw-border-b tw-border-b-black/10 tw-flex tw-flex-col tw-divide-y tw-divide-light-light tw-shadow-xs tw-shadow-light-accent ${ articleHeader }`}>
								<div className="tw-p-md">
                                <Search className="-tw-mt-sm" id="demsdlkfjhsdkf" onChange={ e => {

                                        console.log(e?.target.value || "nope");

                                        if(e?.target.value){
                                            const searchText = e?.target.value.toString().toLowerCase();
                                            const filtered = allPatients.filter(element => {
                                                
                                                // 👇️ using AND (&&) operator
                                                return element.firstName.toLowerCase().includes(searchText) || element.lastName.toLowerCase().includes(searchText);
                                              });

                                              console.log(filtered)

                                            setActivePatients(filtered);
                                        } else {
                                            setActivePatients(allPatients);
                                        }
                                    }}/>
                                </div>
                                
                                <div className="tw-flex tw-pb-xs tw-px-lg  tw-space-x-md  tw-text-dark-light">
									<Paragraph3 tag="span" childTag="span" className="tw-w-250px">
										Name
									</Paragraph3>
                                    <Paragraph3 tag="span" childTag="span" className="tw-w-250px">
										Email
									</Paragraph3>
									<Paragraph3 tag="span" childTag="span" className="tw-flex-grow">
										County
									</Paragraph3>
								</div>
						    </div>
                            <div className={`tw-flex tw-flex-col tw-divide-y tw-divide-light-light ${ articleHeader }`}>
								{ activePatients.map((patient) => {
									return	<Button className="tw-p-0 tw-flex tw-w-full tw-bg-white hover:tw-text-black" onClick={ e => {
                                        e.preventDefault();
                                        showClientSchedule(patient);
                                        //props?.toggleShowRightPanel();
                                    }} childClasses="tw-p-0 tw-flex tw-w-full">
										<ClientDetailsCard
											className="tw-w-full"
                                            clientFirstName={patient.firstName}
                                            clientLastName={patient.lastName}
                                            clientEmail={patient.email}
                                            clientCounty={patient.patientDetail.county}
										/>
									</Button>
								}) }
							</div>
                            </>
                        }

                        
                    </div>
                </div>
            
        </div>
        {/* </Core> */}
    </>)
}

export default ClientSchedulePage