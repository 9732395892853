import { useEffect, useLayoutEffect, useState } from "react";
import AssignedEvent from "../../types/assignedEvent";
import CoverageEvent from "../../types/coverageEvent";
import { useAppContext } from "../../Context";
import RemoteSupportSession from "../../types/remoteSupportSession";
import moment from "moment";
import { set } from "lodash";
import { ButtonErrorSecondary, ButtonPrimary, ButtonSecondary } from "../buttons/Buttons";
import SessionInfoPanel from "./SessionInfoPanel";
import ManageSessionShiftsPanel from "./ManageSessionShiftsPanel";
import { ManageSessionShiftsBottomPanel } from "./PanelCTAs";
import { Span } from "../utilities/Span";
import { v4 as uuidv4 } from 'uuid';
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import { LabelOutputs } from "../utilities/Helpers";
import { divideShiftWihSession, getRemoteSupportSessionByID, reassignShiftToDedicatedSession } from "../../apicalls/sessionService";

const DivideShiftWithSessionPanel = props => {
    const navContext = useAppContext();

    const [selectedSession, setSelectedSession] = useState(null);
    const [sessionShifts, setSessionShifts] = useState<AssignedEvent[]>(null);
    const [movableShifts, setMovableShifts] = useState<CoverageEvent[]>(null);

    const [concurrentSessions, setConcurrentSessions] = useState<RemoteSupportSession[]>(null);

    const [shiftToDivide, setShiftToDivide] = useState<AssignedEvent>(null);

    const [overlappingSessions, setOverlappingSessions] = useState<RemoteSupportSession[]>([]);

    const [selectedOverlappingSession, setSelectedOverlappingSession] = useState<RemoteSupportSession>(null);

    const [dividedShifts, setDividedShifts] = useState<CoverageEvent[]>([]);

    const [showDivideConfirm, setShowDivideConfirm] = useState(false);

    const [delegatingShift, setDelegatingShift] = useState(false);
    
    const [showDelegationSuccess, setShowDelegationSuccess] = useState(false);

    const [isDelegation, setIsDelegation] = useState(true);

    const doesShiftOverlap = (shift: AssignedEvent, session: RemoteSupportSession) : boolean => {
		// if either is undefined or null
		if(!shift || !session){
			return false;
		}

		// use moment to compare
		const shiftStart = moment(`${shift.displayCoverageDate} ${shift.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const shiftEnd = moment(`${shift.displayCoverageDate} ${shift.displayEndTime}`, 'MM-DD-YYYY h:mm A');
		const sessionStart = moment(`${session.displayStartDate} ${session.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const sessionEnd = moment(`${session.displayEndDate} ${session.displayEndTime}`, 'MM-DD-YYYY h:mm A');

        if(shiftEnd.isBefore(shiftStart)){
            shiftEnd.add(1, 'days');
        }

        // console.log("Shift Time Period: " + shift.displayStartTime + " - " + shift.displayEndTime);
        // console.log("Session Time Period: " + session.displayStartTime + " - " + session.displayEndTime);
	  
		if(shiftStart.isBefore(sessionEnd) && shiftEnd.isAfter(sessionStart)){
		  return true;
		} 
        else {
		//   console.log("Shift not inside session");
		//   console.log(shift);
		//   console.log(shiftStart);
		//   console.log(shiftEnd);
		//   console.log(sessionStart);
		//   console.log(sessionEnd);
		  return false;
		}
	}

    const discardSessionChanges = () => {

        navContext.setSideNav( <SessionInfoPanel selectedSession={selectedSession} updateSession={props?.updateSession} removeSession={props?.removeSession} updateShiftCount={props?.updateShiftCount}/>);
		navContext.setSideNavTheme(3);
		navContext.setSideNavTitle("Session Details");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
    }

    const cancelMoveShiftToSession = () => {
        navContext.setSideNav(<ManageSessionShiftsPanel selectedSession={selectedSession} sessionShifts={sessionShifts} movableShifts={movableShifts} concurrentSessions={concurrentSessions} updateSession={props?.updateSession} removeSession={props?.removeSession} updateShiftCount={props?.updateShiftCount}/>);
        navContext.setSideNavTheme(3);
        navContext.setSideNavTitle("Manage Session Shifts");
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(<ManageSessionShiftsBottomPanel onCancel={discardSessionChanges} />);
    }

    const isShiftInsideSession = (shift: AssignedEvent, session: RemoteSupportSession) : boolean => {
		// if either is undefined or null
		if(!shift || !session){
			return false;
		}

		// use moment to compare
		const shiftStart = moment(`${shift.displayCoverageDate} ${shift.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const shiftEnd = moment(`${shift.displayCoverageDate} ${shift.displayEndTime}`, 'MM-DD-YYYY h:mm A');
		const sessionStart = moment(`${session.displayStartDate} ${session.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const sessionEnd = moment(`${session.displayEndDate} ${session.displayEndTime}`, 'MM-DD-YYYY h:mm A');

        if(shiftEnd.isBefore(shiftStart)){
            shiftEnd.add(1, 'days');
        }

        // if(sessionEnd.isBefore(sessionStart)){
        //     sessionEnd.add(1, 'days');
        // }

        console.log("Shift Time Period: " + shiftStart.format("MM-DD-YYYY h:mm A") + " - " + shiftEnd.format("MM-DD-YYYY h:mm A"));
        console.log("Session Time Period: " + sessionStart.format("MM-DD-YYYY h:mm A") + " - " + sessionEnd.format("MM-DD-YYYY h:mm A"));
	  
		if(shiftStart.isSameOrAfter(sessionStart) && shiftEnd.isSameOrBefore(sessionEnd)){
		  return true;
		} else {
		//   console.log("Shift not inside session");
		//   console.log(shift);
		//   console.log(shiftStart);
		//   console.log(shiftEnd);
		//   console.log(sessionStart);
		//   console.log(sessionEnd);
		  return false;
		}
	}

    const splitShiftsForSession = (session: RemoteSupportSession, coverageEvent: AssignedEvent): CoverageEvent[] => {
		let shifts: CoverageEvent[] = [];

		var coverageEventStart = moment(coverageEvent.displayCoverageDate + " " + coverageEvent.displayStartTime, 'MM-DD-YYYY h:mm A');
		var coverageEventEnd = moment(coverageEvent.displayCoverageDate + " " + coverageEvent.displayEndTime, 'MM-DD-YYYY h:mm A');
		var sessionStart = moment(session.displayStartDate + " " + session.displayStartTime, 'MM-DD-YYYY h:mm A');
		var sessionEnd = moment(session.displayEndDate + " " + session.displayEndTime, 'MM-DD-YYYY h:mm A');

        if (coverageEventEnd.isBefore(coverageEventStart)) {
            coverageEventEnd.add(1, 'days');
        }
	  
		if (coverageEventStart.isSameOrAfter(sessionStart) && coverageEventEnd.isAfter(sessionEnd)) {
		  let shift1: CoverageEvent = {
              coverageEventID: uuidv4(),
              startTime: coverageEventStart.toDate(),
              endTime: sessionEnd.toDate(),
              displayStartTime: coverageEventStart.format("h:mm A"),
              displayEndTime: sessionEnd.format("h:mm A"),
              overlappingSession: session,
              patientID: coverageEvent.patientID,
              patientName: coverageEvent.patientName,
              providerID: "",
              staffSize: 0,
              groupSize: 0,
              billingCode: "",
              coverageDate: coverageEvent.coverageDate,
              providerName: "",
              shiftType: "",
              shiftTheme: 0,
              displayCoverageDate: coverageEvent.displayCoverageDate,
              status: ""
          };
	  
		  shifts.push(shift1);
	  
		  let shift2: CoverageEvent = {
              coverageEventID: uuidv4(),
              startTime: sessionEnd.toDate(),
              endTime: coverageEventEnd.toDate(),
              displayStartTime: sessionEnd.format("h:mm A"),
              displayEndTime: coverageEventEnd.format("h:mm A"),
              overlappingSession: selectedSession,
              patientID: coverageEvent.patientID,
              patientName: coverageEvent.patientName,
              providerID: "",
              staffSize: 0,
              groupSize: 0,
              billingCode: "",
              coverageDate: coverageEvent.coverageDate,
              providerName: "",
              shiftType: "",
              shiftTheme: 0,
              displayCoverageDate: coverageEvent.displayCoverageDate,
              status: ""
          };

		  shifts.push(shift2);

			return shifts;
			}

		if (coverageEventEnd.isSameOrBefore(sessionEnd) && coverageEventStart.isBefore(sessionStart)) {
			let shift1: CoverageEvent = {
                coverageEventID: uuidv4(),
                startTime: coverageEventStart.toDate(),
                endTime: sessionStart.toDate(),
                displayStartTime: coverageEventStart.format("h:mm A"),
                displayEndTime: sessionStart.format("h:mm A"),
                overlappingSession: selectedSession,
                patientID: coverageEvent.patientID,
                patientName: coverageEvent.patientName,
                providerID: "",
                staffSize: 0,
                groupSize: 0,
                billingCode: "",
                coverageDate: coverageEvent.displayCoverageDate,
                providerName: "",
                shiftType: "",
                shiftTheme: 0,
                displayCoverageDate: coverageEvent.displayCoverageDate,
                status: ""
            };

			shifts.push(shift1);

			let shift2: CoverageEvent = {
                coverageEventID: uuidv4(),
                startTime: sessionStart.toDate(),
                endTime: coverageEventEnd.toDate(),
                displayStartTime: sessionStart.format("h:mm A"),
                displayEndTime: coverageEventEnd.format("h:mm A"),
                overlappingSession: session,
                patientID: coverageEvent.patientID,
                patientName: coverageEvent.patientName,
                providerID: "",
                staffSize: 0,
                groupSize: 0,
                billingCode: "",
                coverageDate: coverageEvent.displayCoverageDate,
                providerName: "",
                shiftType: "",
                shiftTheme: 0,
                displayCoverageDate: coverageEvent.displayCoverageDate,
                status: ""
            };

			shifts.push(shift2);

			return shifts;
		}

        if (sessionStart.isAfter(coverageEventStart) && sessionEnd.isBefore(coverageEventEnd)) {
            let shift1: CoverageEvent = {
                coverageEventID: uuidv4(),
                startTime: coverageEventStart.toDate(),
                endTime: sessionStart.toDate(),
                displayStartTime: coverageEventStart.format("h:mm A"),
                displayEndTime: sessionStart.format("h:mm A"),
                overlappingSession: selectedSession,
                patientID: coverageEvent.patientID,
                patientName: coverageEvent.patientName,
                providerID: "",
                staffSize: 0,
                groupSize: 0,
                billingCode: "",
                coverageDate: coverageEvent.displayCoverageDate,
                providerName: "",
                shiftType: "",
                shiftTheme: 0,
                displayCoverageDate: coverageEvent.displayCoverageDate,
                status: ""
            };

            shifts.push(shift1);

            let shift2: CoverageEvent = {
                coverageEventID: uuidv4(),
                startTime: sessionStart.toDate(),
                endTime: sessionEnd.toDate(),
                displayStartTime: sessionStart.format("h:mm A"),
                displayEndTime: sessionEnd.format("h:mm A"),
                overlappingSession: session,
                patientID: coverageEvent.patientID,
                patientName: coverageEvent.patientName,
                providerID: "",
                staffSize: 0,
                groupSize: 0,
                billingCode: "",
                coverageDate: coverageEvent.displayCoverageDate,
                providerName: "",
                shiftType: "",
                shiftTheme: 0,
                displayCoverageDate: coverageEvent.displayCoverageDate,
                status: ""
            };

            shifts.push(shift2);

            let shift3: CoverageEvent = {
                coverageEventID: uuidv4(),
                startTime: sessionEnd.toDate(),
                endTime: coverageEventEnd.toDate(),
                displayStartTime: sessionEnd.format("h:mm A"),
                displayEndTime: coverageEventEnd.format("h:mm A"),
                overlappingSession: selectedSession,
                patientID: coverageEvent.patientID,
                patientName: coverageEvent.patientName,
                providerID: "",
                staffSize: 0,
                groupSize: 0,
                billingCode: "",
                coverageDate: coverageEvent.displayCoverageDate,
                providerName: "",
                shiftType: "",
                shiftTheme: 0,
                displayCoverageDate: coverageEvent.displayCoverageDate,
                status: ""
            };

            shifts.push(shift3);

            return shifts;
        }

		return shifts;
	}

    useLayoutEffect(() => {
        console.log("Selected Session: ", props.selectedSession);

        if(props?.selectedSession) {
            setSelectedSession(props?.selectedSession);
            setSessionShifts(props?.sessionShifts);
        }

        if(props?.movableShifts){
            setMovableShifts(props?.movableShifts);
        }

        if(props?.shiftToDivide){
            setShiftToDivide(props?.shiftToDivide);
        }

        if(props?.concurrentSessions){
            setConcurrentSessions(props?.concurrentSessions);

            var filteredSessions = props?.concurrentSessions.filter(session => {
                console.log("Session: ", session);
                var doesOverlap = doesShiftOverlap(props?.shiftToDivide, session);
                console.log("Does Shift Overlap: ", doesOverlap);
                var isInside = isShiftInsideSession(props?.shiftToDivide, session);
                console.log("Is Shift Inside Session: ", isInside);
                return doesOverlap || isInside;
            });

            console.log("Filtered Sessions: ", filteredSessions);

            // remove duplicates in the array
            filteredSessions = filteredSessions.filter((session, index, self) =>
                index === self.findIndex((t) => (
                    t.remoteSupportSessionID === session.remoteSupportSessionID
                ))
            );

            setOverlappingSessions(filteredSessions);


            // setOverlappingSessions(props?.concurrentSessions.filter(session => {
            //     return doesShiftOverlap(shiftToDivide, session);
            // }));
            
        }
    }, [props.selectedSession, props.sessionShifts, props.movableShifts, props.shiftToDivide]);

    useEffect(() => {
        console.log("Selected Overlapping Session: ", selectedOverlappingSession);
        console.log("Shift To Divide: ", shiftToDivide);

        if(selectedOverlappingSession && shiftToDivide){
            if(isShiftInsideSession(shiftToDivide, selectedOverlappingSession)){
                setIsDelegation(false);

                var newShift: CoverageEvent = {
                    ...shiftToDivide,
                    overlappingSession: selectedOverlappingSession,
                    providerID: "",
                    staffSize: 0,
                    groupSize: 0,
                    billingCode: "",
                    providerName: "",
                    shiftType: "",
                    shiftTheme: 0
                };

                setDividedShifts([newShift]);

                setShowDivideConfirm(true);
            } else {
                setIsDelegation(true);

                var shiftSplits = splitShiftsForSession(selectedOverlappingSession, shiftToDivide);

                console.log("Shift Splits: ", shiftSplits);
        
                setDividedShifts(shiftSplits);
        
                setShowDivideConfirm(true);
            }

            
        }

    }, [selectedOverlappingSession]);

    const handleBackToManageShifts = () => {
        navContext.setSideNav(<ManageSessionShiftsPanel selectedSession={selectedSession} sessionShifts={sessionShifts} updateSession={props?.updateSession} removeSession={props?.removeSession} updateShiftCount={props?.updateShiftCount}/>);
        navContext.setSideNavTheme(3);
        navContext.setSideNavTitle("Manage Session Shifts");
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(<ManageSessionShiftsBottomPanel onCancel={discardSessionChanges} />);
    }

    const handleShiftDelegate = async () => {
        setDelegatingShift(true);

        if (isDelegation) {
            const result = await divideShiftWihSession(selectedOverlappingSession.remoteSupportSessionID, shiftToDivide, selectedSession, navContext.handleAPICallError);
        } else {
            var shiftToMove: CoverageEvent = {
                coverageEventID: shiftToDivide.coverageEventID,
                patientID: shiftToDivide.patientID,
                patientName: shiftToDivide.patientName,
                providerID: "",
                staffSize: 1,
                groupSize: 1,
                billingCode: "",
                coverageDate: shiftToDivide.coverageDate,
                providerName: "",
                shiftType: "",
                shiftTheme: 0,
                startTime: shiftToDivide.startTime,
                displayStartTime: "",
                endTime: shiftToDivide.endTime,
                displayEndTime: "",
                displayCoverageDate: "",
                status: ""
            };

            const result = await reassignShiftToDedicatedSession(selectedOverlappingSession.remoteSupportSessionID, shiftToMove, navContext.handleAPICallError);
        }

        //const result = await divideShiftWihSession(selectedOverlappingSession.remoteSupportSessionID, shiftToDivide, selectedSession, navContext.handleAPICallError);

		const sessionByID = await getRemoteSupportSessionByID(selectedSession?.remoteSupportSessionID, navContext.handleAPICallError);

        setSessionShifts(sessionByID?.assignedShifts);

        if(props?.updateShiftCount){
            props.updateShiftCount(sessionByID, sessionByID?.assignedShifts?.length);
        }

        setShowDelegationSuccess(true);
        setDelegatingShift(false);
    } 

    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full tw-relative">
            <div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
                <div className="tw-flex-shrink-0 tw-w-full">
                    <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-10px">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                    <span className="tw-flex-grow">
                                        <span className="tw-text-dark">
                                            Session Information
                                        </span>
                                    </span>
                                </span>
                            </h3>
                            <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-5px tw-leading-tight">
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Provider
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.providerName}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Start Date
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.displayStartDate}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Time Period
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.displayStartTime} - {selectedSession?.displayEndTime}
                                    </span>
                                    {!moment(selectedSession?.startDate).isSame(selectedSession?.endDate) && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
                                </span>}
                                </li>
                            </ul>
                </div>

                <div className="tw-flex-shrink-0 tw-w-full tw-border-t tw-border-t-neutral-400 tw-pt-20px">
                    <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-10px">
                        <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                            <span className="tw-flex-grow">
                                        <span className="tw-text-dark">
                                            Shift To Be Delegated
                                        </span>
                            </span>
                        </span>
                    </h3>
                    <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-5px tw-leading-tight">
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Client
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {shiftToDivide?.patientName}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Coverage Date
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {shiftToDivide?.displayCoverageDate}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Time Period
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {shiftToDivide?.displayStartTime} - {shiftToDivide?.displayEndTime}
                                    </span>
                                </li>
                    </ul>
                </div>

                    <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-5px">
                        <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                            <span className="tw-flex-grow">
                                        <span className="tw-text-dark">
                                            Overlapping Session(s)
                                        </span>
                            </span>
                        </span>
                    </h3>
                    <fieldset>
                    <legend className="tw-sr-only">Overlapping Sessions</legend>
                    <div className="tw-space-y-5">
                        {overlappingSessions?.map((session) => (
                        <div key={session.remoteSupportSessionID} className="tw-relative tw-flex tw-items-start">
                            <div className="tw-flex tw-h-6 tw-items-center">
                            <input
                                id={session.remoteSupportSessionID}
                                aria-describedby={`${session.remoteSupportSessionID}-description`}
                                name="session"
                                type="radio"
                                onChange={ e => {
                                    console.log("Selected Session from radio button: ", session);
                                    setSelectedOverlappingSession(session);
                                }
                                }
                                className="tw-h-4 tw-w-4 tw-border-gray-300 tw-text-primary focus:tw-ring-primary"
                            />
                            </div>
                            <div className="tw-ml-3 tw-text-sm tw-leading-6">
                            <label htmlFor={session.remoteSupportSessionID} className="tw-font-medium tw-text-gray-900">
                                {session.providerName}
                            </label>
                            <div className="tw-flex">
                                        <p id={`${session.remoteSupportSessionID}-description`} className="tw-text-gray-500">
                                            {session.displayStartDate} {session.displayStartTime} - {session.displayEndTime}
                                        </p>
                                    {!moment(session.startDate).isSame(session.endDate) && <span className="tw-ml-4 tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
										<img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
									</span>}
                            </div>
                           

                            </div>
                        </div>
                        ))}
                    </div>
                </fieldset>

                <div>
                    {showDivideConfirm ?
                    <div>
                        <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-py-5px">
                                    <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                        <span className="tw-flex-grow">
                                            <span className="tw-text-primary">
                                                Delegation Preview
                                            </span>
                                        </span>
                                    </span>
                        </h3>
                        {!isDelegation && <span className="tw-font-medium tw-text-dark tw-pb-20px tw-italic">
                            The entire shift will be delegated to {selectedOverlappingSession.providerName}.
                        </span>}
                        {dividedShifts?.map((shift, index) => {
                            return (
                                <div className="tw-flex-shrink-0 tw-w-full">
                                <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-py-5px">
                                    <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                        <span className="tw-flex-grow">
                                            <span className="tw-text-dark">
                                                Shift {dividedShifts.length > 1 ? index + 1 : ""}
                                            </span>
                                        </span>
                                    </span>
                                </h3>
                                <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-5px tw-leading-tight">
                                    <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-150px tw-flex tw-items-center">
                                            <span className="tw-opacity-70">
                                                New Provider
                                            </span>
                                        </span>
                                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                            {shift.overlappingSession?.providerName}
                                        </span>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-150px tw-flex tw-items-center">
                                            <span className="tw-opacity-70">
                                                Coverage Date
                                            </span>
                                        </span>
                                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                            {shift?.displayCoverageDate}
                                        </span>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-150px tw-flex tw-items-center">
                                            <span className="tw-opacity-70">
                                                Time Period
                                            </span>
                                        </span>
                                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                            {shift?.displayStartTime} - {shift?.displayEndTime}
                                        </span>
                                    </li>
                                </ul>
                             </div>
                            );
                        
                        })}


                    {showDelegationSuccess ? <>
                            <div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-my-auto tw-py-20px">
                                <div className="tw-space-y-15px tw-text-center">
                                    <LabelOutputs className="tw-w-full tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
                                        <span className="tw-text-5">
                                            Success! Shift successfully delegated!
                                        </span>
                                    </LabelOutputs>
                                    <ButtonSecondary className="tw-w-200px" onClick={ e => {
                                        e.preventDefault();
                                        handleBackToManageShifts();
                                        //navContext.closeSideNav();
                                    }}>
                                        Back to Manage Shifts
                                    </ButtonSecondary>
                                </div>
                            </div>
                        </>:<>
                        {delegatingShift ? <>
                            <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                                <LoadingSpinner /> 
                                <span className="tw-text-primary tw-text-5 tw-text-center">Delegating Shift</span>
                            </div>
                        </> : <>
                            <div>
                                <h3 className="tw-font-primary-bold tw-font-5 tw-text-dark tw-py-20px">
                                    Are you sure you want to delegate <Span>this shift?</Span>
                                </h3>
                                <div className="tw-flex tw-justify-between tw-space-x-60px">
                                    <ButtonErrorSecondary className="tw-basis-1/2" onClick={ e => {
                                        e.preventDefault();
                                        cancelMoveShiftToSession();
                                        // navContext.setPanelSize('tw-max-w-400px');
                                        // navContext.setSideNav( <CoverageDetailsPanel date={moment(shift.displayCoverageDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={ props?.serviceTypes || null}/> );
                                        // navContext.setSideNavActions(null);
                                        // navContext.setSideNavBottom(null);
                                    }}>
                                        No, Cancel
                                    </ButtonErrorSecondary>
                                    <ButtonPrimary className="tw-basis-1/2" onClick={ e => {
                                        e.preventDefault();
                                        handleShiftDelegate();

                                        //handleSessionDelete();
                                    }}>
                                        Yes, Delegate
                                    </ButtonPrimary>
                                </div>
                            </div>
                        </>}
                        </>}
						
					</div>
                    :
                    <div className="tw-flex tw-justify-between tw-space-x-60px">
                        <ButtonSecondary className="tw-basis-1/3" onClick={ e => {
								e.preventDefault();
                                cancelMoveShiftToSession();
								// navContext.setPanelSize('tw-max-w-400px');
								// navContext.setSideNav( <CoverageDetailsPanel date={moment(shift.displayCoverageDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={ props?.serviceTypes || null}/> );
								// navContext.setSideNavActions(null);
								// navContext.setSideNavBottom(null);
							}}>
								Go Back
							</ButtonSecondary>
                    </div>}
                </div>
            </div>
        </div>
    </>);

}

export default DivideShiftWithSessionPanel;
