import { useEffect, useRef, useState } from "react";
import { ButtonPrimary, ButtonSecondary } from "../components/buttons/Buttons";
import { Input, Label, Select } from "../components/forms/formFields";
import { PageHeader, PageSubHeader } from "../components/typography/Headers";
import { Ellipsis } from "../components/utilities/Helpers";
import { useNavigate, useParams } from 'react-router-dom';
import Core from "../templates/Core";
import { rem } from "../utilities/helpers";
import { pageSubHeader } from "../utilities/typography";
import { getClientByID, getClientHomes, getClientServiceType, saveCoverageRequirement, saveRecurringCoverageRequirement } from "../apicalls/schedulingService";
import { SelectChangeEvent } from "@mui/material/Select";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, ListItemText, MenuItem, OutlinedInput } from "@mui/material";
import moment from "moment";
import CoverageEvent from "../types/coverageEvent";
import uuid from "react-uuid";
import CoverageRequirement from "../types/coverageRequirement";
import IntrinsicRatio from "../components/utilities/IntrinsicRatio";
import { useAppContext } from "../Context";
//import { Select as MSelect } from "@mui/material/Select";


const NewCoverageRequirementForm = props => {
	const { patientID } = useParams()

	const [clientServiceTypes, setClientServiceTypes] = useState([]);

	const [clientDetails, setClientDetails] = useState(null);

	const [showReqLoading, setShowReqLoading] = useState(false);

	const [invalidTimesOpen, setInvalidTimesOpen] = useState(false);

	const [saveReqOpen, setSaveReqOpen] = useState(false);

	const [formValidationMessage, setFormValidationMessage] = useState("");

    let navigate = useNavigate();

	const appContext = useAppContext();

    const discardNewRequirement = () => {
        navigate(-1);
    }

	const handleInvalidTimesClose = () => {
		setInvalidTimesOpen(false);
	}

	const handleReqSuccessClose = () => {
		setSaveReqOpen(false);

		navigate(-1);
	}

    const saveNewRequirement = async () => {
        console.log(values)

        const found = clientServiceTypes.find(element => element.shiftType === values.shift_type);

        const startTime = moment(values.date + " " + values.start_time)
        const endTime = moment(values.date + " " + values.end_time)


		if (values.date === "" || values.start_time === "" || values.end_time === "" || values.req_reason === "" || (values.req_reason === "Custom" && values.custom_reason === "")){
			setFormValidationMessage("Please make sure all inputs are filled");

			setInvalidTimesOpen(true);

			console.log("Empty Date");
		} else if (endTime < startTime && values.end_time != "00:00") {
			setFormValidationMessage("Requirement End Time cannot be earlier than Start Time")

			setInvalidTimesOpen(true);

			console.log("Invalid Times");
		} else {
			setInvalidTimesOpen(false);

			let newRequirement : CoverageRequirement = {
				coverageRequirementID: uuid(),
				patientID: clientDetails.patientID,
				recurrenceID: "",
				staffSize: found.staffSize,
				groupSize: found.groupSize,
				billingCode: found.billingCode,
				coverageDate: moment(values.date).format(),
				providerName: "",
				shiftType: found.shiftType,
				shiftTheme: 0,
				startTime: startTime.format(),
				displayStartTime: values.start_time,
				endTime: endTime.format(),
				displayEndTime: values.end_time,
				displayCoverageDate: "",
				recurrence: values.shift_recurrence,
				status: "",
				repeatsSunday: values.repeats_sunday,
				repeatsMonday: values.repeats_monday,
				repeatsTuesday: values.repeats_tuesday,
				repeatsWednesday: values.repeats_wednesday,
				repeatsThursday: values.repeats_thursday,
				repeatsFriday: values.repeats_friday,
				repeatsSaturday: values.repeats_saturday,
				recurrenceEndDate: moment(values.repeat_end_date).format(),
				reason: values.req_reason === "Custom" ? values.custom_reason : values.req_reason,
			}
	
			console.log(newRequirement);
	
			setShowReqLoading(true);
	
			if (newRequirement.recurrence === "Never") {
				newRequirement.recurrenceEndDate = newRequirement.coverageDate;
				
				const result = await saveCoverageRequirement(newRequirement, appContext.handleAPICallError);
	
				setShowReqLoading(false);

				setSaveReqOpen(true);
	
				
			} else {
				 const result = await saveRecurringCoverageRequirement(newRequirement, appContext.handleAPICallError);
	
				 setShowReqLoading(false);
	
				
				setSaveReqOpen(true);
			}
		}

        

       
    }

	const [values, setValues] = useState<any>({
		"date": "",
		"start_time": "",
		"end_time": "",
		"shift_type": "",
		"shift_recurrence": "Never",
		"repeat_end_date": "",
		"repeats_monday": false,
		"repeats_tuesday": false,
		"repeats_wednesday": false,
		"repeats_thursday": false,
		"repeats_friday": false,
		"repeats_saturday": false,
		"repeats_sunday": false,
		"req_reason": "",
		"custom_reason": "",
	});

	useEffect(() => {
		const fetchServiceTypes = async () => {
			const serviceTypes = await getClientServiceType(patientID, appContext.handleAPICallError);

			console.log(serviceTypes);

			setClientServiceTypes(serviceTypes);

			//setValues({...values, "shift_recurrence": "Never" })

			const date = moment().format("YYYY-MM-DD");

			setValues({...values, "shift_type": serviceTypes[0].shiftType, "date": date })
		}

		const fetchClientDetails = async () => {
			const clientDetails = await getClientByID(patientID, appContext.handleAPICallError);

			console.log(clientDetails);

			setClientDetails(clientDetails);
		}

		fetchServiceTypes()
			.catch(console.error);
		fetchClientDetails()
			.catch(console.error);
	}, [patientID])

    

    const date = useRef();
	const start_time = useRef();
	const end_time = useRef();
	const shift_type = useRef();
	const shift_location = useRef();
	const shift_recurrence = useRef();
	const repeat_end_date = useRef();
	const req_reason = useRef();
	const custom_reason = useRef();

	const toggleRepeatsMonday = () => {
		setValues({...values, "repeats_monday": !values.repeats_monday })
		console.log(values.repeats_monday);
	}

	const toggleRepeatsTuesday = () => {
		setValues({...values, "repeats_tuesday": !values.repeats_tuesday })
	}

	const toggleRepeatsWednesday = () => {
		setValues({...values, "repeats_wednesday": !values.repeats_wednesday })
	}

	const toggleRepeatsThursday = () => {
		setValues({...values, "repeats_thursday": !values.repeats_thursday })
	}

	const toggleRepeatsFriday = () => {
		setValues({...values, "repeats_friday": !values.repeats_friday })
	}

	const toggleRepeatsSaturday = () => {
		setValues({...values, "repeats_saturday": !values.repeats_saturday })
	}

	const toggleRepeatsSunday = () => {
		setValues({...values, "repeats_sunday": !values.repeats_sunday })
	}

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const menuprops = {
	PaperProps: {
		style: {
		maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		width: 250,
		},
	},
	};

    useEffect(() => {
		console.log(values);
	}, [values])

    return (<>
        {/* <Core> */}
		<Dialog
			open={invalidTimesOpen}
			onClose={handleInvalidTimesClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
			{"Invalid Input"}
			</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">
				{formValidationMessage}
			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleInvalidTimesClose}>Ok</Button>
			</DialogActions>
		</Dialog>

		<Dialog
			open={saveReqOpen}
			onClose={handleReqSuccessClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
			{"Success!"}
			</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">
				Coverage Requirement saved successfully
			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleReqSuccessClose}>Ok</Button>
			</DialogActions>
		</Dialog>
        <div className="tw-px-xl tw-py-xl">
				{/* <div className="tw-flex tw-items-center tw-flex tw-border-b tw-border-light tw-pb">
					<div className="tw-flex-grow tw-flex-shrink-0">
						<PageHeader>
							<Ellipsis>
								Client Requirement Calendar
							</Ellipsis>
						</PageHeader>
					</div>
				</div> */}
				<div className="tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">New Client Requirement</h2>
                </div>
                <div className="tw-px-xl tw-pb-xl tw-text-dark">
                    <div>
                        <div>
                        <form className="tw-p-0 tw-m-0 tw-border-0">
							<PageSubHeader className="tw-py-xl" tag="legend">
								New Requirement - {clientDetails?.firstName} {clientDetails?.lastName}
							</PageSubHeader>
							<div className="tw-space-y">

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Date
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
                                        <Input
										 min="2000-01-01" max="2024-12-31"
										type="date" ref={ date }
										value={ values.date } onChange={ e => setValues({...values, "date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Start Time
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Input id="start_time" type="time" onChange={ e => setValues({...values, "start_time": e.target.value }) } ref={ start_time } value={ values.start_time } alt className={`tw-flex-grow tw-max-w-300`} />
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
												End Time
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Input id="end_time" type="time" required onChange={ e => setValues({...values, "end_time": e.target.value }) } ref={ end_time } value={ values.end_time } alt className={`tw-flex-grow tw-max-w-300`} />
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Shift Type
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
											<Select id="shift_type" onChange={ e => setValues({...values, "shift_type": e.target.value }) } ref={ shift_type } value={ values.shift_type } alt className="tw-flex-grow tw-max-w-500">
												<option value="">--Select--</option>
												{
													clientServiceTypes.map(service =>
														<option value={service.shiftType}>{service.shiftType}</option>)
												}
											</Select>
										</div>
									</div>
								</div>

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="shift_recurrence" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Recurrence
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
											<Select id="shift_recurrence" onChange={ e => setValues({...values, "shift_recurrence": e.target.value }) } ref={ shift_recurrence } value={ values.shift_recurrence } alt className="tw-flex-grow tw-max-w-500">
												<option value="">--Select--</option>
												<option value="Never">Never</option>
												<option value="Daily">Daily</option>
												<option value="Weekly">Weekly</option>
												<option value="DaysOfWeek">Days Of Week</option>
											</Select>
										</div>
									</div>
								</div>

								{values["shift_recurrence"] !== "" && values["shift_recurrence"] !== "Never" ? <>
									<div className="-tw-m">
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
													Repeat End Date
												</Label>
											</div>
											<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
											<Input
											min="2000-01-01" max="2024-12-31"
											type="date" ref={ repeat_end_date }
											value={ values.repeat_end_date } onChange={ e => setValues({...values, "repeat_end_date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
											</div>
										</div>
									</div>
								</>:<></>}

								{values["shift_recurrence"] === "DaysOfWeek" ? <>
								<div className="-tw-m">
										<div className="tw-flex">
											<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
												<Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
													Repeats
												</Label>
											</div>
											<div className="tw-flex">
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsMonday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_monday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													M
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsTuesday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_tuesday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													Tu
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsWednesday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_wednesday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													W
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsThursday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_thursday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													Th
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsFriday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_friday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													F
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsSaturday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_saturday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													Sa
												</IntrinsicRatio>
											</li>
											<li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsSunday}>
												<IntrinsicRatio className={`tw-text-primary ${values.repeats_sunday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
													Su
												</IntrinsicRatio>
											</li>
										</div>
										</div>
										
									</div>
								</>:<></>}

								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="req_reason" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Reason
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
											<Select id="req_reason" onChange={ e => setValues({...values, "req_reason": e.target.value }) } ref={ req_reason } value={ values.req_reason } alt className="tw-flex-grow tw-max-w-500">
												<option value="">--Select--</option>
												<option value="Requested By Client">Requested By Client</option>
												<option value="Delegated From Direct Support">Delegated From Direct Support</option>
												<option value="Custom">Custom</option>
											</Select>
										</div>
									</div>
								</div>


								<div className="-tw-m">
									<div className="tw-flex">
										<div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
											<Label for="custom_reason" className="tw-flex-grow" childClasses={ pageSubHeader }>
												Comment
											</Label>
										</div>
										<div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
											<Input id="custom_reason" onChange={ e => setValues({...values, "custom_reason": e.target.value }) } ref={ custom_reason } value={ values.custom_reason } alt className="tw-flex-grow tw-max-w-500"/>
										</div>
									</div>	
								</div>

							</div>
							<div className="tw-pt-xxl tw-pb-xl">
								<ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
									{showReqLoading ? 
									<>
									<li>
										<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
									</li>
									
									</>: <> 
									<li>
										<ButtonSecondary large className="tw-px tw-min-w-200" onClick={ e => {
                                            discardNewRequirement();
											e.preventDefault();
										}}>
											Discard
										</ButtonSecondary>
									</li>
									<li>
										<ButtonPrimary large className="tw-px tw-min-w-200" onClick={ e => {
											saveNewRequirement();
											e.preventDefault();
										}}>
											Save
										</ButtonPrimary>
									</li>
									</>}
									
								</ul>
							</div>
						</form>
                        </div>
                    </div>
                </div>
		</div>
        {/* </Core> */}
    </>);
}

export default NewCoverageRequirementForm