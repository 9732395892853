import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import AvatarNameInline from '../avatars/AvatarNameInline'
import { Block } from '@medforall/medforall-component-library'
import { Div } from '../utilities/Helpers';
import moment from 'moment';


export const toNumberFromTime = val => Number(val.toString().toLowerCase().replace('am', '').replace('pm', ''));
export const toHoursNumberFromTime = (val: string) => {
	const period = val.slice(-2).toLowerCase();
	let [hours, minutes] = val.slice(0, -2).split(':').map(Number);

	// console.log('period', period);
	// console.log('hours', hours);
	// console.log('minutes', minutes);
  
	if (period === 'pm' && hours < 12) hours += 12;
	if (period === 'am' && hours === 12) hours = 0;
  
	return hours + minutes / 60;
  };
export const isPm = val => val.includes('pm');
export const isStarting = val => val === 12;
export const getHoursOrMinutes = val => val.split(':');
export const isHalfHour = val => val === 0 ? 0 : ((val === 15) ? .25 : (val === 45) ? .75 : .5);

export const Timeblock = props => {
	const [offsetHours, setOffsetHours] = useState<Number>(0);
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(1);
	const TimeBlockRef = useRef(null);
	const Tag = props.tag || "div";

	const handleOnclick = (e) => {
		e.preventDefault();
		if (props.onClick) props.onClick();
	}

	useLayoutEffect(() => {
		if (props.start) {
			//console.log('props.start', props.start);
			const startHours = Number(getHoursOrMinutes(props.start)[0]);
			const startMinutes = isHalfHour(Number(toNumberFromTime(getHoursOrMinutes(props.start)[1])));
			const startTemp = toNumberFromTime(startHours + startMinutes);
			const startIndex =  Number( (isPm(props.start) && !(isStarting(startHours))) ? (startTemp + 12) : (isStarting(startHours) && !isPm(props.start) ? 0 : startTemp) ) ;
			// console.log('startHours', startHours);
			// console.log('startMinutes', startMinutes);
			// console.log('startTemp', startTemp);
			// console.log('startIndex', startIndex);
			setStart(startIndex);
		}

		if (props.end) {
			//console.log('props.end', props.end);
			const endHours = Number(getHoursOrMinutes(props.end)[0]);
			const endMinutes = isHalfHour(Number(toNumberFromTime(getHoursOrMinutes(props.end)[1])));
			const endTemp = toNumberFromTime(endHours + endMinutes);
			const endIndex = Number( (isPm(props.end) && !(isStarting(endHours))) ? (endTemp + 12) : (isStarting(endHours) && !isPm(props.end) ? 0 : endTemp) );
			// console.log('endHours', endHours);
			// console.log('endMinutes', endMinutes);
			// console.log('endTemp', endTemp);
			// console.log('endIndex', endIndex);

			if (endIndex <= 0) {

				setEnd(96);
			} else {
				setEnd(endIndex);
			}
			
		}

		//if (props.offsetHours) setOffsetHours(toNumberFromTime(props.offsetHours));

		if (props.offsetHours) setOffsetHours(toHoursNumberFromTime(props.offsetHours));

	}, [props.start, props.ends, props.offsetHours]);

	return <Tag onClick={ handleOnclick } ref={ TimeBlockRef } title={`${ props.children || props.name }: ${ props.start }-${ props.end }`} className={`grid-row-vars XXXtw-min-h-15px tw-text-white tw-flex tw-relative ${ props.className } ${ props.onClick && ' tw-cursor-pointer' }`} style={{'--start': (((start - Number(offsetHours)) * 4) + 1), '--end': (((end - Number(offsetHours)) * 4) + 1)}}>
			<Block className={`before:tw-absolute tw-flex tw-items-start tw-w-full before:tw-block before:tw-top-[1px] before:tw-right-0 before:tw-left-0 before:tw-bottom-0 ${ props.uncovered ? 'before:tw-bg-black/10' : 'before:tw-bg-primary'}`}>
				{ !props.uncovered && <AvatarNameInline className="tw-py-2px tw-px-10px tw-relative tw-z-1 tw-w-full" top={ false } size={ 15 }>  {/* NOTE: @size will need to be updated for quarter hours and halfs + the text below  OR make varaitions of <Timeblock /> for 1/4, 1/2, and regular... */}
					<span className="tw-text-[.75em] tw-text-white tw-font-primary-bold tw-flex tw-flex-col tw-w-full">
						<span>
							{ props.children || props.name || "First, L" }
						</span>
						<span className={`${ props.timestamp === false && 'tw-hidden' } tw-text-[0.625em] tw-font-primary-light tw-mt-[-0.5em]`}>{ props.start } – { props.end }</span>
					</span>
				</AvatarNameInline> }
			</Block>
		</Tag>;
}

export const OpenBlock = props => <Timeblock onClick={ props.onClick || null } uncovered={ true } offsetHours={ props.offsetHours || 0 } start={ props.start || 0 } end={ props.end || 1 } />;

const CalendarKey = props => {
	const CalendarKeyRef = useRef(null);
	const [offsetHours, setOffsetHours] = useState<Number>(0);
	const [endTime, setEndTime] = useState<string>("");

	useLayoutEffect(() => {
		if (props.offsetHours) setOffsetHours(props.offsetHours);
		if (props.endTime) setEndTime(props.endTime);
	}, [props.offsetHours])

	return <>
		<div ref={ CalendarKeyRef } className="tw-text-[0.75em] tw-text-dark-light tw-font-primary-light tw-divide-y tw-divide-light || tw-w-full">
			{ [...Array( Number(props.hours) * 4 + 4)].map( (item, index) => {
				const temp = index / 4 + Number(offsetHours);
				const hours = Math.floor(temp);
				const minutes = Math.round((temp - hours) * 60);

				// console.log('index', index);
				// console.log('temp', temp);
				// console.log('hours', hours);
				// console.log('minutes', minutes);

				const timeString = (hours === 0 ? 12 : (hours <= 12 ? hours : (hours - 12))) + ':' + (minutes < 10 ? `0${minutes}` : minutes) + (hours < 12 ? ' AM' : (hours === 24 ? ' AM' : ' PM'));

				// const isBefore = moment(timeString, 'h:mm A').isBefore(moment(endTime, 'h:mmA'));
				// const isEqual = moment(timeString, 'h:mm A').isSame(moment(endTime, 'h:mmA'));

				// console.log('timeString', timeString);
				// console.log('endTime', endTime);
				// console.log('isBeforeOrEqual', isBefore);
				// console.log('isEqual', isEqual);

				var blockEndTime = moment(endTime, 'h:mmA');
				if(endTime === "12:00am") {
					blockEndTime = moment(endTime, 'h:mmA').add(1, 'day');
				}
				

				// const validateEndTime = endTime === "12:00am" ? true : moment(timeString, 'h:mm A').isBefore(moment(endTime, 'h:mmA'));

				const validateEndTime = moment(timeString, 'h:mm A').isBefore(blockEndTime);

				if(index % 4 === 0 && validateEndTime) {
					// return <div key={ 'calendar_key-' + index } className={`tw-flex tw-justify-end tw-pr-4px tw-items-start tw-h-30px tw-text-[7px]`}>
					// 	{ temp === 0 ? 12 : (temp <= 12 ? temp : (temp - 12) ) } { temp < 12 ? ' AM' : (temp === 24 ? ' AM' : ' PM') }
					// </div>;
					return (
					<div key={ 'calendar_key-' + index } className={`tw-flex tw-justify-end tw-pr-4px tw-items-start tw-h-7.5px tw-text-[7px]`}>
						{timeString}
					</div>
					);
				} else if (moment(timeString, 'h:mm A').isSame(moment(endTime, 'h:mmA'))) {
					return <div key={ 'calendar_key-' + index } className={`tw-flex tw-justify-end tw-pr-4px tw-items-start tw-h-7.5px tw-text-[7px]`}>{timeString}</div>

				} else {
					return <div key={ 'calendar_key-' + index } className="tw-h-7.5px">{` `}</div>
				}
				
			}) }
		</div>
	</>
}

const DailyCalendar = props => {
	const DailyCalendarRef = useRef(null);
	const [hours, setHours] = useState<Number>(24);
	const [starting, setStarting] = useState<Number>(0);
	const [ending, setEnding] = useState<Number>(25);

	useLayoutEffect(() => {
		if (props.starting && props.ending) {
			console.log('props.starting', props.starting);
			console.log('props.ending', props.ending);
			const tempStarting = toHoursNumberFromTime(props.starting);
			const tempEnding = toHoursNumberFromTime(props.ending);
			// const tempStarting = isPm(props.starting) ? (toNumberFromTime(props.starting) + 12) : toNumberFromTime(props.starting);
			// const tempEnding = isPm(props.ending) ? (toNumberFromTime(props.ending) + 12) : toNumberFromTime(props.ending);
			console.log('tempStarting', tempStarting);
			console.log('tempEnding', tempEnding);
			console.log('tempEnding - tempStarting', tempEnding - tempStarting);
			setStarting(tempStarting);
			setEnding(tempEnding);
			tempEnding - tempStarting < 0 ? setHours(24 - (tempStarting - tempEnding)) : setHours(tempEnding - tempStarting);
			
		}
	}, [props.starting, props.ending])

	return <>
		<div ref={ DailyCalendarRef } className={`tw-flex ${ props.className }`}>
			<div className="tw-shrink-0">
				<CalendarKey hours={ hours } offsetHours={ Number(starting) } endTime={props.ending ? props.ending : "12:00am"} />
			</div>
			<div className={`tw-flex-grow`}>
				<div className="o-layered tw-w-full">
					<div className="o-layered__layer tw-h-full tw-overflow-y-visible">
						<Div children={ props.children } className="tw-h-full tw-w-full tw-grid tw-grid-cols-1 tw-relative tw-z-2" style={{ gridTemplateRows: `repeat(${Number(hours) * 4}, minmax(0px, 1fr))` }} />
					</div>
					<div className="o-layered__layer || tw-divide-y tw-divide-light || tw-w-full">
						{ [...Array(Number(hours) * 4)].map((item, index) => {
							return <div key={ 'grid_bg-' + index } className="tw-h-7.5px">{` `}</div>
						}) }
					</div>
				</div>

			</div>
		</div>
	</>
}

export default DailyCalendar;
