import { useLayoutEffect, useState } from "react";
import { Span } from "../utilities/Span";
import { ButtonPrimary, ButtonSecondary } from "../buttons/Buttons";
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import { useAppContext } from "../../Context";
import SessionInfoPanel from "./SessionInfoPanel";
import SessionActionSuccessPanel from "./SessionActionSuccessPanel";
import RemoteSupportSession from "../../types/remoteSupportSession";
import { deleteRecurringRemoteSupportSession, deleteRemoteSupportSession } from "../../apicalls/sessionService";
import moment from "moment";

const ConfirmSessionDeletePanel = props => {
    const [selectedSession, setSelectedSession] = useState<RemoteSupportSession>(null);

    const [loading, setLoading] = useState(false);

    const navContext = useAppContext();

    useLayoutEffect(() => {
        if (props.selectedSession) {
            setSelectedSession(props?.selectedSession);
        }
	}, [props.selectedSession]);

    const cancelSessionDelete = () => {

        navContext.setSideNav( <SessionInfoPanel selectedSession={selectedSession} updateSession={props?.updateSession} removeSession={props?.removeSession}/>);
		navContext.setSideNavTheme(3);
		navContext.setSideNavTitle("Session Details");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
    }

    const handleSessionDelete = async () => {
        setLoading(true);

        var title = "Session Deleted";
        var message = "The session has been successfully deleted.";

        const result = await deleteRemoteSupportSession(selectedSession, navContext.handleAPICallError);

        navContext.setSideNavTitle("Success");
		navContext.setSideNav( <SessionActionSuccessPanel selectedSession={selectedSession} title={title} message={message} updateSession={props?.updateSession} removeSession={props?.removeSession}/> );
		navContext.setSideNavActions(null);
	    navContext.setSideNavBottom(null);

        if(props?.removeSession){
            props.removeSession(selectedSession.remoteSupportSessionID);
        }

        setLoading(false);
    }

    const handleSeriesDelete = async () => {
        setLoading(true);

        var title = "Session Series Deleted";
        var message = "The session series has been successfully deleted.";

        const result = await deleteRecurringRemoteSupportSession(selectedSession, navContext.handleAPICallError);

        navContext.setSideNavTitle("Success");
        navContext.setSideNav( <SessionActionSuccessPanel selectedSession={selectedSession} title={title} message={message}updateSession={props?.updateSession} removeSession={props?.removeSession}/> );
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(null);

        if(props?.removeSession){
            props.removeSession(selectedSession.remoteSupportSessionID);
        }

        setLoading(false);
    }
    
    return (<>
         <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
            <div className="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
                    <div className="tw-flex-shrink-0 tw-w-full">
                        <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className="tw-text-dark">
                                        Session Details
                                    </span>
                                </span>
                            </span>
                        </h3>
                        <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Provider
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.providerName}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Status
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.status}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Start Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayStartDate}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        End Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayEndDate}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Time Period
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayStartTime} - {selectedSession?.displayEndTime}
                                </span>
                                {!moment(selectedSession?.startDate).isSame(selectedSession?.endDate) && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
                                </span>}
                            </li>
                        </ul>
                    </div>

                    {loading ? <>
						<div className="tw-flex tw-flex-col tw-justify-center tw-mx-4">
							<LoadingSpinner />
                            <span className="tw-text-primary tw-text-5 tw-text-center">Deleting</span>
						</div>
					</>
					:
					<div>
						<h3 className="tw-font-primary-bold tw-font-5 tw-text-dark tw-py-20px">
							Are you sure you want to delete <Span>this session?</Span>
						</h3>
						<div className="tw-flex tw-justify-between tw-space-x-60px">
							<ButtonSecondary className="tw-basis-1/3" onClick={ e => {
								e.preventDefault();
                                cancelSessionDelete();
								// navContext.setPanelSize('tw-max-w-400px');
								// navContext.setSideNav( <CoverageDetailsPanel date={moment(shift.displayCoverageDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={ props?.serviceTypes || null}/> );
								// navContext.setSideNavActions(null);
								// navContext.setSideNavBottom(null);
							}}>
								No
							</ButtonSecondary>
                            {selectedSession?.recurrence !== "Never" && <ButtonPrimary className="tw-basis-1/3" onClick={ e => {
                                e.preventDefault();
                                handleSeriesDelete();
                            }}>
								Delete Series
							</ButtonPrimary>}
							<ButtonPrimary className="tw-basis-1/3" onClick={ e => {
                                e.preventDefault();
                                handleSessionDelete();
                            }}>
								Delete
							</ButtonPrimary>
						</div>
					</div>}
                </div>
            </div>
        </div>
    </>);
}

export default ConfirmSessionDeletePanel;