import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ButtonSecondary } from "@medforall/medforall-component-library";
import { createContext, useContext, useLayoutEffect, useState, useMemo, Dispatch, useEffect } from "react";
import ServicePlan, { DesiredOutcome, PersonalService, SupportArea, RemoteSupportEvent, SuggestedAction, RemoteSupportRoutine, UnusualEvent, AuthorizedService, ActionStep, ExpectedOutcome, RoutineEvent, UnusualEventAction } from "../types/servicePlan";
import Patient from "../types/patient";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { GoalIcon, RoutineIcon, SuggestedActionIcon, SupportAreaIcon, TaskAreaIcon, UnusualEventIcon } from "../components/utilities/Icons";
import { EditAuthorizedServiceList, ViewAuthorizedServiceSection } from "../components/portalComponents/editAuthorizedServiceCard";
import { EditSupportAreaList, ViewSupportAreaSection } from "../components/portalComponents/editSupportAreaCard";
import { EditDesiredOutcomeList, ViewDesiredOutcomeSection } from "../components/portalComponents/editDesiredOutcomeCard";
import { EditRemoteSupportTaskList, ViewRemoteSupportTaskSection } from "../components/portalComponents/editRemoteSupportTaskCard";
import { EditRoutineList, ViewRemoteSupportRoutine } from "../components/portalComponents/editRemoteSupportRoutine";
import { EditSuggestedActionList, ViewSuggestedActionSection } from "../components/portalComponents/editSuggestedActionCard";
import { EditUnusualEventList, ViewUnusualEventSection } from "../components/portalComponents/editUnusualEventCard";
import { getClient } from "../apicalls/clientMgmtService";
import { getServicePlan } from "../apicalls/servicePlanService";
import { CircularProgress } from "@mui/material";
import { EditPlanCard } from "../components/portalComponents/editPlanCard";
import { useAppContext } from "../Context";

const tabs = [
    { name: 'Services', href: '#', current: true },
    { name: 'Budget', href: '#', current: false },
  ]

const EditPlanContext = createContext({
    servicePlan: {} as ServicePlan,
    setServicePlan: (() => undefined) as Dispatch<any>,
    planSupportAreas: [] as SupportArea[],
    setPlanSupportAreas: (() => undefined) as Dispatch<any>,
    planDesiredOutcomes: [] as DesiredOutcome[],
    setPlanDesiredOutcomes: (() => undefined) as Dispatch<any>,
    planTasks: [] as RemoteSupportEvent[],
    setPlanTasks: (() => undefined) as Dispatch<any>,
    planSuggestedActions: [] as SuggestedAction[],
    setPlanSuggestedActions: (() => undefined) as Dispatch<any>,
    planRoutines: [] as RemoteSupportRoutine[],
    setPlanRoutines: (() => undefined) as Dispatch<any>,
    planUnusualEvents: [] as UnusualEvent[],
    setPlanUnusualEvents: (() => undefined) as Dispatch<any>,
    planAuthorizedServices: [] as AuthorizedService[],
    setPlanAuthorizedServices: (() => undefined) as Dispatch<any>,
    clientID: '',
    patient: {} as Patient,
    updateLocalAreas: async () => {},
    updateLocalDesiredOutcomes: async () => {},
    updateLocalTasks: async () => {},
    updateLocalSuggestedActions: async () => {},
    updateLocalRoutines: async () => {},
    updateLocalUnusualEvents: async () => {},
    updateLocalAuthorizedServices: async () => {},
    updateLocalPlan: async () => {},
    origSupportAreas: [] as SupportArea[],
});

export const useEditPlanContext = () => useContext(EditPlanContext);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const mapAuthorizedService = (service: AuthorizedService) : AuthorizedService => {
    service.displayStartDate = moment.utc(service.budgetStartDate).format("MM/DD/YYYY");
    service.displayEndDate = moment.utc(service.budgetEndDate).format("MM/DD/YYYY");
    service.budgetStartDate = moment.utc(service.budgetStartDate).toDate().toISOString().substring(0,10);
    service.budgetEndDate = moment.utc(service.budgetEndDate).toDate().toISOString().substring(0,10);

    return service;

}

const EditServicePlanPage = props => {
    const [activeTabs, setActiveTabs] = useState(tabs);
    const appContext = useAppContext();

    const [planSupportAreas, setPlanSupportAreas] = useState<SupportArea[]>([]);
    const [origSupportAreas, setOrigSupportAreas] = useState<SupportArea[]>([]);
    const [planDesiredOutcomes, setPlanDesiredOutcomes] = useState<DesiredOutcome[]>([]);
    const [planTasks, setPlanTasks] = useState<RemoteSupportEvent[]>([]);
    const [planSuggestedActions, setPlanSuggestedActions] = useState<SuggestedAction[]>([]);
    const [planRoutines, setPlanRoutines] = useState<RemoteSupportRoutine[]>([]);
    const [planUnusualEvents, setPlanUnusualEvents] = useState<UnusualEvent[]>([]);
    const [planAuthorizedServices, setPlanAuthorizedServices] = useState<AuthorizedService[]>([]);
    const [servicePlan, setServicePlan] = useState<ServicePlan>();
    const { clientID, planID } = useParams();
    const [patient, setPatient] = useState<Patient>();
    let navigate = useNavigate();
    const [clientLoading, setClientLoading] = useState(true);

    useLayoutEffect(() => {
        console.log("Client ID");
        console.log(clientID);

        const fetchPatientInfo = async () => {
            var client = await getClient(clientID, appContext.handleAPICallError);
            console.log(client);
            setPatient(client);
            var plan = await getServicePlan(planID, appContext.handleAPICallError);
            console.log(plan);
            plan.displayStartDate = moment.utc(plan.startDate).format("MM/DD/YYYY");
            plan.displayEndDate = moment.utc(plan.endDate).format("MM/DD/YYYY");
            setServicePlan(plan);
            setPlanSupportAreas(plan.supportAreas);
            setOrigSupportAreas(plan.supportAreas);
            setPlanUnusualEvents(plan.unusualEvents);
            setPlanSuggestedActions(plan.suggestedActions);
            setPlanTasks(plan.remoteSupportEvents);
            setPlanRoutines(plan.remoteSupportRoutines);
            setPlanDesiredOutcomes(plan.desiredOutcomes);
            setPlanAuthorizedServices(plan.authorizedServices.map(mapAuthorizedService));
            //intializeTestPlan();
            setClientLoading(false);
            //setActivePlan(activePlan);
        }
            

        fetchPatientInfo()
            .catch(console.error);
    },[])

    const updateLocalPlan = async () => {
        var plan = await getServicePlan(planID, appContext.handleAPICallError);
        plan.displayStartDate = moment.utc(plan.startDate).format("MM/DD/YYYY");
        plan.displayEndDate = moment.utc(plan.endDate).format("MM/DD/YYYY");
        console.log(plan);
        setServicePlan(plan);
    }

    const updateLocalAreas = async () => {
        var plan = await getServicePlan(planID, appContext.handleAPICallError);
        console.log(plan);
        setPlanSupportAreas(plan.supportAreas);
    }
    
    const updateLocalDesiredOutcomes = async () => {
        var plan = await getServicePlan(planID, appContext.handleAPICallError);
        console.log(plan);
        setPlanDesiredOutcomes(plan.desiredOutcomes);
    }

    const updateLocalTasks = async () => {
        var plan = await getServicePlan(planID, appContext.handleAPICallError);
        console.log(plan);
        setPlanTasks(plan.remoteSupportEvents);
    }

    const updateLocalSuggestedActions = async () => {
        var plan = await getServicePlan(planID, appContext.handleAPICallError);
        console.log(plan);
        setPlanSuggestedActions(plan.suggestedActions);
    }

    const updateLocalRoutines = async () => {
        var plan = await getServicePlan(planID, appContext.handleAPICallError);
        console.log(plan);
        setPlanRoutines(plan.remoteSupportRoutines);
    }

    const updateLocalUnusualEvents = async () => {
        var plan = await getServicePlan(planID, appContext.handleAPICallError);
        console.log(plan);
        setPlanUnusualEvents(plan.unusualEvents);
    }

    const updateLocalAuthorizedServices = async () => {
        var plan = await getServicePlan(planID, appContext.handleAPICallError);
        console.log(plan);
        setPlanAuthorizedServices(plan.authorizedServices.map(mapAuthorizedService));
    }
    
    const [serviceDetailsSections, setServiceDetailsSections] = useState({
        showGoalSection: false,
        showTaskSection: false,
        showRoutineSection: false,
        showSuggestedActionSection: false,
        showUnusualEventSection: false,
        showSupportAreaSection: false,
    });

    const goToSupportAreaSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: true,
            showGoalSection: false,
            showTaskSection: false,
            showRoutineSection: false,
            showSuggestedActionSection: false,
            showUnusualEventSection: false,
        });
    }

    const goToGoalSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: true,
            showTaskSection: false,
            showRoutineSection: false,
            showSuggestedActionSection: false,
            showUnusualEventSection: false,
        });
    }

    const goToTaskSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: false,
            showTaskSection: true,
            showRoutineSection: false,
            showSuggestedActionSection: false,
            showUnusualEventSection: false,
        });
    }

    const goToRoutineSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: false,
            showTaskSection: false,
            showRoutineSection: true,
            showSuggestedActionSection: false,
            showUnusualEventSection: false,
        });
    }

    const goToSuggestedActionSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: false,
            showTaskSection: false,
            showRoutineSection: false,
            showSuggestedActionSection: true,
            showUnusualEventSection: false,
        });
    }

    const goToUnusualEventSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: false,
            showTaskSection: false,
            showRoutineSection: false,
            showSuggestedActionSection: false,
            showUnusualEventSection: true,
        });
    }

    const goToTab = (tabName: string) => {
        if(tabName === 'Services') {
            setActiveTabs([
                { name: 'Services', href: '#', current: true },
                { name: 'Budget', href: '#', current: false },
              ])
        }
        else if(tabName === 'Budget') {
            setActiveTabs([
                { name: 'Services', href: '#', current: false },
                { name: 'Budget', href: '#', current: true },
              ])
        }
    }

    const value = useMemo(() => ({
		patient,
        servicePlan, setServicePlan,
        planSupportAreas, setPlanSupportAreas,
        planDesiredOutcomes, setPlanDesiredOutcomes,
        planTasks, setPlanTasks,
        planSuggestedActions, setPlanSuggestedActions,
        planRoutines, setPlanRoutines,
        planUnusualEvents, setPlanUnusualEvents,
        planAuthorizedServices, setPlanAuthorizedServices,
        clientID, updateLocalAreas,
        updateLocalDesiredOutcomes, updateLocalTasks,
        updateLocalSuggestedActions, updateLocalRoutines,
        updateLocalUnusualEvents, updateLocalAuthorizedServices,
        updateLocalPlan, origSupportAreas
	}), [patient, servicePlan, clientID, planSupportAreas, planDesiredOutcomes, planTasks, planSuggestedActions, planRoutines, planUnusualEvents, planAuthorizedServices, origSupportAreas]);
    
    useLayoutEffect(() => {
        // console.log("Client ID");
        // console.log(clientID);

        // const fetchPatient = async () => {
        //     var client = await getClient(clientID);
        //     console.log(client);
        //     setPatient(client);
        //     setClientLoading(false);
        // }

        // fetchPatient()
        //     .catch(console.error);

        //intializeTestPlan();
        
    },[])

    const intializeTestPlan = () => {
        var newTestPlan: ServicePlan = {
            servicePlanID: uuidv4(),
            planID: "",
            patientID: "",
            waiverType: "SELF",
            fiscalYear: "2023",
            status: "",
            startDate: new Date("04-01-2023"),
            displayStartDate: "04-01-2023",
            endDate: new Date("05-06-2023"),
            displayEndDate: "05-06-2023",
            aboutClient: "Client Info",
            authorizedServices: [],
            supportAreas: [],
            desiredOutcomes: [],
            remoteSupportEvents: [],
            suggestedActions: [],
            remoteSupportRoutines: [],
            unusualEvents: [],
        }

        //setServicePlan(newTestPlan);


        var area1Services: PersonalService[] = [];

        var newService: PersonalService = {
            serviceTitle: "Drive To Doctor",
            //serviceDetails: service.serviceDetails,
            frequency: "Daily",
            responsibleParty: "Provider",
            personalServiceID: uuidv4(),
            supportAreaID: "",
            serviceDetails: "",
            lastCompletedOn: "",
            lastCompletedBy: ""
        }
        area1Services.push(newService);

        var newArea1: SupportArea = {
                supportTitle: "Transportation",
                //supportType: values.supportType,
                supportDetails: "Help Client get to doctor appointments",
                personalServices: area1Services,
                supportAreaID: uuidv4(),
                servicePlanID: "",
                supportType: ""
        }

        var area2Services: PersonalService[] = [];

        var newService2: PersonalService = {
            serviceTitle: "Service 1",
            //serviceDetails: service.serviceDetails,
            frequency: "Daily",
            responsibleParty: "Provider",
            personalServiceID: uuidv4(),
            supportAreaID: "",
            serviceDetails: "",
            lastCompletedOn: "",
            lastCompletedBy: ""
        }
        area2Services.push(newService2);

        var newArea2: SupportArea = {
            supportTitle: "Area 2",
            //supportType: values.supportType,
            supportDetails: "Test Area 2",
            personalServices: area2Services,
            supportAreaID: uuidv4(),
            servicePlanID: "",
            supportType: ""
        }

        var supportAreas = [newArea1, newArea2];
        //setPlanSupportAreas(supportAreas);

        var newAction1: SuggestedAction = {
            actionTitle: "Action 1",
            actionDetails: "Action 1 Details",
            suggestedActionID: uuidv4(),
            servicePlanID: ""
        }

        var newAction2: SuggestedAction = {
            actionTitle: "Action 2",
            actionDetails: "Action 2 Details",
            suggestedActionID: uuidv4(),
            servicePlanID: ""
        }

        var suggestedActions = [newAction1, newAction2];
        //setPlanSuggestedActions(suggestedActions);

        var outcome1Steps: ActionStep[] = [];
        var outcome1ExpectedOutcomes: ExpectedOutcome[] = [];

        var newStep1: ActionStep = {
            actionStepID: uuidv4(),
            desiredOutcomeID: "",
            actionStepTitle: "Step 1",
            actionStepDetails: "Step 1 Details",
            responsibleParty: "Provider",
            frequency: "Daily"
        }
        var newExpectedOutcome1: ExpectedOutcome = {
            expectedOutcomeID: uuidv4(),
            desiredOutcomeID: "",
            expectedOutcomeDescription: "Sample Expected Outcome 1"
        }

        outcome1Steps.push(newStep1);
        outcome1ExpectedOutcomes.push(newExpectedOutcome1);

        var newOutcome1: DesiredOutcome = {
            desiredOutcomeID: uuidv4(),
            servicePlanID: "",
            outcomeTitle: "Outcome 1",
            outcomeDetails: "Outcome 1 Details",
            outcomeProgress: "Outcome 1 Progress",
            actionSteps: outcome1Steps,
            expectedOutcomes: outcome1ExpectedOutcomes
        }

        var outcome2Steps: ActionStep[] = [];
        var outcome2ExpectedOutcomes: ExpectedOutcome[] = [];

        var newStep2: ActionStep = {
            actionStepID: uuidv4(),
            desiredOutcomeID: "",
            actionStepTitle: "Step 2",
            actionStepDetails: "Step 2 Details",
            responsibleParty: "Provider",
            frequency: "Daily"
        }
        var newExpectedOutcome2: ExpectedOutcome = {
            expectedOutcomeID: uuidv4(),
            desiredOutcomeID: "",
            expectedOutcomeDescription: "Sample Expected Outcome 2"
        }

        outcome2Steps.push(newStep2);
        outcome2ExpectedOutcomes.push(newExpectedOutcome2);

        var newOutcome2: DesiredOutcome = {
            desiredOutcomeID: uuidv4(),
            servicePlanID: "",
            outcomeTitle: "Outcome 2",
            outcomeDetails: "Outcome 2 Details",
            outcomeProgress: "Outcome 2 Progress",
            actionSteps: outcome2Steps,
            expectedOutcomes: outcome2ExpectedOutcomes
        }

        var desiredOutcomes = [newOutcome1, newOutcome2];
        setPlanDesiredOutcomes(desiredOutcomes);

        var newTask1: RemoteSupportEvent = {
            eventTitle: "Task 1",
            eventDetails: "Task 1 Details",
            timeOfDay: "5:30 AM",
            repeatsMonday: true,
            repeatsTuesday: false,
            repeatsWednesday: true,
            repeatsThursday: false,
            repeatsFriday: true,
            repeatsSaturday: false,
            repeatsSunday: true,
            preEventReminder: 5,
            postEventReminder: 5,
            remoteSupportEventID: uuidv4(),
            servicePlanID: "",
            responsibleParty: ""
        };

        var newTask2: RemoteSupportEvent = {
            eventTitle: "Task 2",
            eventDetails: "Task 2 Details",
            timeOfDay: "7:30 AM",
            repeatsMonday: false,
            repeatsTuesday: true,
            repeatsWednesday: true,
            repeatsThursday: false,
            repeatsFriday: false,
            repeatsSaturday: true,
            repeatsSunday: false,
            preEventReminder: 5,
            postEventReminder: 5,
            remoteSupportEventID: uuidv4(),
            servicePlanID: "",
            responsibleParty: ""
        };

        var tasks = [newTask1, newTask2];
        //setPlanTasks(tasks);

        var routineEvents: RoutineEvent[] = [];

        var newRoutineEvent1: RoutineEvent = {
            eventTitle: "Routine Event 1",
            eventDetails: "Routine Event 1 Details",
            responsibleParty: "",
            timeOfDay: "5:30 AM",
            preEventReminder: 5,
            postEventReminder: 5,
            routineEventID: uuidv4(),
            remoteSupportRoutineID: ""
        }
        routineEvents.push(newRoutineEvent1);
        var newRoutineEvent2: RoutineEvent = {
            eventTitle: "Routine Event 2",
            eventDetails: "Routine Event 2 Details",
            responsibleParty: "",
            timeOfDay: "6:30 AM",
            preEventReminder: 5,
            postEventReminder: 5,
            routineEventID: uuidv4(),
            remoteSupportRoutineID: ""
        }
        routineEvents.push(newRoutineEvent2);

        var newRoutine1: RemoteSupportRoutine = {
                remoteSupportRoutineID: uuidv4(),
                servicePlanID: "",
                routineTitle: "Routine 1",
                routineDetails: "Routine 1 Details",
                routineStart: "5:00 AM",
                routineEnd: "7:00 AM",
                repeatsMonday: true,
                repeatsTuesday: false,
                repeatsWednesday: true,
                repeatsThursday:  false,
                repeatsFriday:  true,
                repeatsSaturday: false,
                repeatsSunday: true,
                events: routineEvents
        }

        var routineEvents2: RoutineEvent[] = [];

        var newRoutineEvent1: RoutineEvent = {
            eventTitle: "Routine Event 1",
            eventDetails: "Routine Event 1 Details",
            responsibleParty: "",
            timeOfDay: "6:30 AM",
            preEventReminder: 5,
            postEventReminder: 5,
            routineEventID: uuidv4(),
            remoteSupportRoutineID: ""
        }
        routineEvents2.push(newRoutineEvent1);
        var newRoutineEvent1: RoutineEvent = {
            eventTitle: "Routine Event 2",
            eventDetails: "Routine Event 2 Details",
            responsibleParty: "",
            timeOfDay: "7:30 AM",
            preEventReminder: 5,
            postEventReminder: 5,
            routineEventID: uuidv4(),
            remoteSupportRoutineID: ""
        }
        routineEvents2.push(newRoutineEvent2);

        var newRoutine2: RemoteSupportRoutine = {
            remoteSupportRoutineID: uuidv4(),
            servicePlanID: "",
            routineTitle: "Routine 2",
            routineDetails: "Routine 2 Details",
            routineStart: "6:00 AM",
            routineEnd: "8:00 AM",
            repeatsMonday: false,
            repeatsTuesday: true,
            repeatsWednesday: true,
            repeatsThursday:  false,
            repeatsFriday:  false,
            repeatsSaturday: true,
            repeatsSunday: false,
            events: routineEvents2
        }

        var routines = [newRoutine1, newRoutine2];
        setPlanRoutines(routines);

        var event1Actions: UnusualEventAction[] = [];
        var newEventAction1: UnusualEventAction = {
            unusualEventActionID: uuidv4(),
            unusualEventID: "",
            actionDetails: "Action 1 Details",
        }
        event1Actions.push(newEventAction1);

        var newEvent1: UnusualEvent = {
                unusualEventID: uuidv4(),
                servicePlanID: "",
                eventTitle: "Event 1",
                eventDetails: "Event 1 Details",
                eventActions: event1Actions, 
        }

        var event2Actions: UnusualEventAction[] = [];
        var newEventAction2: UnusualEventAction = {
            unusualEventActionID: uuidv4(),
            unusualEventID: "",
            actionDetails: "Action 2 Details",
        }
        event2Actions.push(newEventAction2);

        var newEvent2: UnusualEvent = {
                unusualEventID: uuidv4(),
                servicePlanID: "",
                eventTitle: "Event 2",
                eventDetails: "Event 2 Details",
                eventActions: event2Actions, 
        }

        var events = [newEvent1, newEvent2];
        //setPlanUnusualEvents(events);

        var newAuthorizedService1: AuthorizedService = {
            authorizedServiceID: uuidv4(),
            servicePlanID: "",
            serviceTitle: "Home personal care",
            serviceCode: "APC",
            staffSize: 1,
            groupSize: 1,
            authorizedBudget: 12434.12,
            utilizedBudget: 0,
            authorizedUnits: 100,
            utilizedUnits: 0,
            budgetStartDate: moment("2023-04-01").toDate().toISOString().substring(0,10),
            budgetEndDate: moment("2023-05-06").toDate().toISOString().substring(0,10),
            evvServiceID: "",
            evvServiceName: "",
            payer: "",
            payerProgram: "",
            procedureCode: "",
            displayStartDate: moment("2023-04-01").format("MM/DD/YYYY"),
            displayEndDate: moment("2023-05-06").format("MM/DD/YYYY"),
        }

        var newAuthorizedService2: AuthorizedService = {
            authorizedServiceID: uuidv4(),
            servicePlanID: "",
            serviceTitle: "Remote Support",
            serviceCode: "AMR",
            staffSize: 1,
            groupSize: 1,
            authorizedBudget: 12434.12,
            utilizedBudget: 0,
            authorizedUnits: 150,
            utilizedUnits: 0,
            budgetStartDate: moment("2023-04-01").toDate().toISOString().substring(0,10),
            budgetEndDate: moment("2023-05-06").toDate().toISOString().substring(0,10),
            evvServiceID: "",
            evvServiceName: "",
            payer: "",
            payerProgram: "",
            procedureCode: "",
            displayStartDate: moment("2023-04-01").format("MM/DD/YYYY"),
            displayEndDate: moment("2023-05-06").format("MM/DD/YYYY"),
        }

        var authorizedServices = [newAuthorizedService1, newAuthorizedService2];
        setPlanAuthorizedServices(authorizedServices);

    }

    return (<EditPlanContext.Provider value={value}>
        <div className="tw-px-xl tw-py-xl">
                { clientLoading ? <>
                        <div className="tw-flex tw-justify-center tw-m-md">
                                        <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                        </div>
                    </> :
                    <>
                <div className="tw-pb-5">
                    <nav className="tw-flex" aria-label="Breadcrumb">
                        <ol role="list" className="tw-flex tw-items-center tw-space-x-4">
                                <li key='Clients'>
                                    <div className="tw-flex tw-items-center">
                                    <a href="/client/client-management"
                                        className="tw-ml-4 tw-text-lg tw-font-medium tw-text-gray-500 hover:tw-text-gray-700"
                                    >
                                        Clients
                                    </a>
                                    </div>
                                </li>
                                <li key='ClientID'>
                                    <div className="tw-flex tw-items-center">
                                    <ChevronRightIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-500" aria-hidden="true" />
                                    <a href={`/client/${clientID}`}
                                        className="tw-ml-4 tw-text-lg tw-font-medium tw-text-gray-500 hover:tw-text-gray-700"
                                    >
                                        {patient.firstName} {patient.lastName.charAt(0)}.
                                    </a>
                                    </div>
                                </li>
                                <li key='ClientID'>
                                    <div className="tw-flex tw-items-center">
                                    <ChevronRightIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-900" aria-hidden="true" />
                                    <a
                                        className="tw-ml-4 tw-text-lg tw-font-semibold tw-text-gray-900 hover:tw-text-gray-700"
                                    >
                                        Service Plan
                                    </a>
                                    </div>
                                </li>

                        </ol>
                    </nav>
                </div>

                <EditPlanCard></EditPlanCard>

                <div className="tw-hidden sm:tw-block">
                <div className="tw-border-b tw-border-gray-200">
                <nav className="-tw-mb-px tw-flex tw-space-x-8" aria-label="Tabs">
                    {activeTabs.map((tab) => (
                    <div
                        key={tab.name}
                        onClick={() => goToTab(tab.name)}
                        className={classNames(
                        tab.current
                            ? 'tw-border-primary tw-text-primary'
                            : 'tw-border-transparent tw-text-gray-500 tw-cursor-pointer hover:tw-border-gray-300 hover:tw-text-gray-700',
                        'tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                    >
                        {tab.name}
                    </div>
                    ))}
                </nav>
                </div>
                </div>

                {activeTabs[0].current && <>
                    <div className="tw-grid tw-grid-cols-3 tw-gap-6 tw-py-5">
                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${serviceDetailsSections.showSupportAreaSection ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`} onClick={goToSupportAreaSection}>
                        <div className="tw-flex">
                            <SupportAreaIcon></SupportAreaIcon>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-gray-900 tw-flex-grow">Support Areas</span>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-primary">{planSupportAreas ? planSupportAreas.length : 0 }</span>
                        </div>
                    </div>

                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${serviceDetailsSections.showGoalSection ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`} onClick={goToGoalSection}>
                        <div className="tw-flex">
                            <GoalIcon></GoalIcon>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-gray-900 tw-flex-grow">Desired Outcomes</span>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-primary">{planDesiredOutcomes ? planDesiredOutcomes.length : 0}</span>
                        </div>
                    </div>

                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${serviceDetailsSections.showTaskSection ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`} onClick={goToTaskSection}>
                        <div className="tw-flex">
                            <TaskAreaIcon></TaskAreaIcon>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-gray-900 tw-flex-grow">Tasks</span>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-primary">{planTasks ? planTasks.length : 0}</span>
                        </div>
                    </div>

                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${serviceDetailsSections.showRoutineSection ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`} onClick={goToRoutineSection}>
                        <div className="tw-flex">
                            <RoutineIcon></RoutineIcon>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-gray-900 tw-flex-grow">Routines</span>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-primary">{planRoutines ? planRoutines.length : 0}</span>
                        </div>
                    </div>

                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${serviceDetailsSections.showSuggestedActionSection ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`} onClick={goToSuggestedActionSection}>
                        <div className="tw-flex">
                            <SuggestedActionIcon></SuggestedActionIcon>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-gray-900 tw-flex-grow">Suggested Actions</span>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-primary">{planSuggestedActions ? planSuggestedActions.length : 0}</span>
                        </div>
                    </div>

                    <div className={`tw-col-span-1 tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg ${serviceDetailsSections.showUnusualEventSection ? "tw-border-2 tw-border-primary": "tw-cursor-pointer"}`} onClick={goToUnusualEventSection}>
                        <div className="tw-flex">
                            <UnusualEventIcon></UnusualEventIcon>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-gray-900 tw-flex-grow">Unusual Events</span>
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-px-2 tw-text-primary">{planUnusualEvents ? planUnusualEvents.length : 0}</span>
                        </div>
                    </div>
                    
                    </div>

                    
                    {serviceDetailsSections.showSupportAreaSection && <>
                        {servicePlan.status === "Archived" ? <>
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                {planSupportAreas.length > 0 && planSupportAreas.map((supportArea, index) => (
                                    <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                        <ViewSupportAreaSection area={supportArea}></ViewSupportAreaSection>
                                    </div>

                                ))}
                            </div>
                        </>:<>
                            <div className="tw-mt-4">
                                    {planSupportAreas && <EditSupportAreaList areas={planSupportAreas} editPlan={true}></EditSupportAreaList>}
                            </div>
                        </>}
                    </>
                    }

                    {serviceDetailsSections.showGoalSection && <>
                        {servicePlan.status === "Archived" ? <>
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                {planDesiredOutcomes.length > 0 && planDesiredOutcomes.map((outcome, index) => (
                                    <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                        <ViewDesiredOutcomeSection outcome={outcome}></ViewDesiredOutcomeSection>
                                    </div>
                                ))}
                            </div>
                        </>:<>
                            <div className="tw-mt-4">
                                {planDesiredOutcomes && <EditDesiredOutcomeList outcomes={planDesiredOutcomes} editPlan={true}></EditDesiredOutcomeList>}
                            </div>   
                        </>}
                    </>   
                    }

                    {serviceDetailsSections.showTaskSection && <>
                        {servicePlan.status === "Archived" ? <>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planTasks.length > 0 && planTasks.map((task, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewRemoteSupportTaskSection task={task}></ViewRemoteSupportTaskSection>
                                </div>
                            ))}
                        </div>
                        </>:<>
                        <div className="tw-mt-4">
                        
                            {planTasks && <EditRemoteSupportTaskList tasks={planTasks} editPlan={true}></EditRemoteSupportTaskList>}
                        </div>
                        </>}
                    
                    </> 
                    }

                    {serviceDetailsSections.showRoutineSection && <>
                        {servicePlan.status === "Archived" ? <>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planRoutines.length > 0 && planRoutines.map((routine, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewRemoteSupportRoutine routine={routine}></ViewRemoteSupportRoutine>
                                </div>
                            ))}
                        </div>
                        </>:<>
                        <div className="tw-mt-4">
                        {planRoutines && <EditRoutineList routines={planRoutines} editPlan={true}></EditRoutineList>}
                        </div>
                        </>}
                    </> 
                    }

                    {serviceDetailsSections.showSuggestedActionSection && <>
                        {servicePlan.status === "Archived" ? <>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planSuggestedActions.length > 0 && planSuggestedActions.map((action, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow" key={action.suggestedActionID}>
                                    <ViewSuggestedActionSection action={action}></ViewSuggestedActionSection>
                                </div>
                            ))}
                        </div>
                        </>:<>
                        <div className="tw-mt-4">
                        {planSuggestedActions && <EditSuggestedActionList actions={planSuggestedActions} editPlan={true}></EditSuggestedActionList>}
                        </div>
                        </>}
                    </> 
                    }

                    {serviceDetailsSections.showUnusualEventSection && <>
                        {servicePlan.status === "Archived" ? <>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planUnusualEvents.length > 0 && planUnusualEvents.map((event, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewUnusualEventSection event={event}></ViewUnusualEventSection>
                                </div>
                            ))}
                        </div>
                        </>:<>
                        <div className="tw-mt-4">
                        {planUnusualEvents && <EditUnusualEventList events={planUnusualEvents} editPlan={true}></EditUnusualEventList>}
                        </div>
                        </>}
                    </> 
                    }
                </>}
                
                {activeTabs[1].current && <div className="tw-mt-4">
                    {servicePlan.status === "Archived" ? <>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planAuthorizedServices.length > 0 && planAuthorizedServices.map((service, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewAuthorizedServiceSection service={service}></ViewAuthorizedServiceSection>
                                </div>
                            ))}
                        </div>
                    </>:<>
                        {planAuthorizedServices && <EditAuthorizedServiceList services={planAuthorizedServices} editPlan={true}></EditAuthorizedServiceList>}
                    </>}
                            

                            
                </div>}
                
                
                </>
            }
                
        </div>
    </EditPlanContext.Provider>);
}

export default EditServicePlanPage;