import { useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../../components/buttons/Buttons";
import { useLayoutEffect, useState } from "react";
import { getCurrentAvailabilityPeriods } from "../../apicalls/availabilityService";
import { useAppContext } from "../../Context";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import moment from "moment";
import { AvailabilityPeriod } from "../../types/availabilityPeriod";

const AvailabilityPeriodsPage = props => {
    let navigate = useNavigate();
    const navContext = useAppContext();
    const [loadingPeriods, setLoadingPeriods] = useState(true);
    const [periods, setPeriods] = useState([]); // [{name: "Winter Transition Period 2023", startDate: "2023-12-11", endDate: "2024-01-07"}

    const goToForm = (period: AvailabilityPeriod) => {
        navigate(`/availability/providerAvailability/${period.periodID}/form`);
    }

    useLayoutEffect(() => {
        const fetchPeriods = async () => {
            setLoadingPeriods(true);

            const allowedPeriodID = "70F75077-C7E2-4602-AF66-1F766009F975";

            const periods = await getCurrentAvailabilityPeriods(navContext.handleAPICallError);

            // console.log(periods.filter(period => period.periodID === allowedPeriodID));

            // setPeriods(periods.filter(period => period.periodID === allowedPeriodID));

            setPeriods(periods);

            setLoadingPeriods(false);
        }

        fetchPeriods();
    }, [])
    
    return (<>
        <div className="tw-px-xl tw-py-sm">
                <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Availability Periods</h2>
                </div>

                {loadingPeriods ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>:

                <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                    {/* <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                        <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                <span className="tw-w-1/3">
                                    <span className="tw-text-dark tw-font-semibold">Winter Transition Period 2023</span>
                                </span>
                                <span className="tw-w-1/2">
                                    <span className="tw-text-dark">Monday, Dec 11th 2023 to Sunday, Jan 7th 2024</span>
                                </span>
                                <ButtonPrimary className="tw-mb-md" onClick={() => goToForm("WinterTransition")}>
                                    Go To Form
                                </ButtonPrimary>
                        </div>
                    </li> */}
                    {periods.map((period, index) => {
                        return (
                            <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                                <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                        <span className="tw-w-1/3">
                                            <span className="tw-text-dark tw-font-semibold">{period.name}</span>
                                        </span>
                                        <span className="tw-w-1/2">
                                            <span className="tw-text-dark">{moment.utc(period.startDate).format('dddd, MMM Do YYYY')} to {moment.utc(period.endDate).format('dddd, MMM Do YYYY')}</span>
                                        </span>
                                        <ButtonPrimary className="tw-mb-md" onClick={() => goToForm(period)}>
                                            Go To Form
                                        </ButtonPrimary>
                                </div>
                            </li>
                        )
                    })}
                    {periods.length === 0 && <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-md">
                        <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-1/2">
                                            <span className="tw-text-dark tw-font-semibold">No Current Availability Periods</span>
                                        </span>
                        </div>
                    </li>
                        }
                    {/* <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                        <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                <span className="tw-w-1/3">
                                    <span className="tw-text-dark tw-font-semibold">Spring Semester 2024</span>
                                </span>
                                <span className="tw-w-1/2">
                                    <span className="tw-text-dark">Monday, Jan 8th 2024 to Sunday, April 21st 2024</span>
                                </span>
                                <ButtonPrimary className="tw-mb-md" onClick={() => goToForm("SpringSemester")}>
                                    Go To Form
                                </ButtonPrimary>
                        </div>
                    </li> */}
                </ul>}
        </div>
    </>);

}

export default AvailabilityPeriodsPage;