import { Block, ButtonPrimary } from "@medforall/medforall-component-library";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppContext } from "../../Context";
import AvatarNameInline, { AvatarNameInlineList } from "../avatars/AvatarNameInline";
import { ButtonTertiary } from "../buttons/Buttons";
import { CircleLabel } from "../calendars/CalendarUtils";
import CommentBlock from "../containers/CommentBlock";
import { Field, Label, Textarea } from "../forms/formFields";
import { LabelOutputs } from "../utilities/Helpers";
import { ScheduleDayPanel, SpanRequirementCancelPanel, SpanRequirmentSuccessPanel } from "./SchedulePanel";
import { useSpanRequirementContext } from "../../pages/Scheduling/ClientRequirement";
import { EditSpanRequirementBottomPanel } from "./PanelCTAs";
import moment from "moment";
import { SpanCoverageEvent } from "../../types/spanRequirement";
import uuid from "react-uuid";

export const EditCoverDetailsPanel = props => {
	const navContext = useAppContext();
	const reqContext = useSpanRequirementContext();
	const [requirement, setRequirement] = useState<any>(props.requirement);
	const [serviceTypes, setServiceTypes] = useState<any>(props.serviceTypes);

	useEffect(() => {
		// console.log("EditCoverDetailsPanel");
		// console.log(reqContext);
		if (props.requirement) setRequirement(props.requirement);
		if (props.serviceTypes) setServiceTypes(props.serviceTypes);
	}, [props.requirement, props.serviceTypes])

	


	useLayoutEffect(() => {
		
		navContext.setSideNavTitle("Edit Requirement");
		navContext.setSideNavActions(null);

	}, [])

    return <>
        <ScheduleDayPanel edit={ true } title={"Edit Coverage Requirement"} requirement={props.requirement || null} editSpanReq = { true } changeRequirements={ props.changeRequirements ? true : false } day={ props.day || null } serviceTypes={ serviceTypes}  data={props?.data || null}/>
    </>
}

const MakeComment = props => {
	const navContext = useAppContext();
	const [value, setValue] = useState<any>('');
	const ref = useRef(null);

	useEffect(() => {
		// navContext.setValues(value); // not needed ??....
	}, [value])

	return <>
		<Field>
			<Label for="demo-textarea-1">
				Notes
			</Label>
			<Textarea ref={ ref } placeholder="Type your notes here" className="tw-text-5 tw-leading-normal" onChange={ e => {
				setValue(e.target.value)
			}}>{ value }</Textarea>
			<div className="tw-flex tw-flex-items-stretch tw-justify-items-stretch -tw-mt-[1px]">
				<ButtonTertiary disabled={ (value.replaceAll(' ', '') === '') ? 'disabled' : null } className={`${ (value.replaceAll(' ', '') === '') && '' } tw-flex tw-items-center tw-justify-center tw-flex-1 !tw-rounded-none !tw-border !tw-border-r-0`} childClasses="tw-border-10 tw-border-accent !tw-text-5-alt !tw-leading-[1.125em] !tw-px-tiny !tw-pt-[0.5em] !tw-pb-[0.469em]" onClick={ e => {
					if (value.replaceAll(' ', '') !== '') {
						let text = `Are you sure you want to Discard? \n Your changes will be lost.`;
						if (window.confirm(text) == true) {
							setValue('');
							navContext.setSideNavActions(null);
							ref.current.value = '';
						}
					}
					e.preventDefault();
				}}>
					Discard
				</ButtonTertiary>
				<ButtonPrimary className="tw-flex tw-items-center tw-justify-center tw-flex-1 !tw-rounded-none !tw-border" childClasses="tw-border-10 tw-border-accent !tw-text-5-alt !tw-leading-[1.125em] !tw-px-tiny  !tw-pt-[0.5em] !tw-pb-[0.469em]" onClick={ e => {
					alert('add to notes service:\n' + value);
					navContext.setSideNavActions(null);
					e.preventDefault();
				}}>
					Post
				</ButtonPrimary>
			</div>
		</Field>
	</>
}

const CoverDetailsPanel = props => {
	const navContext = useAppContext();
	const [name, setName] = useState<any>(props.name);
	const [requirement, setRequirement] = useState<any>(props.requirement);
	const [serviceTypes, setServiceTypes] = useState<any>(props.serviceTypes);
	const [reqData, setReqData] = useState<any>(props.data);

	const handleOnClick = e => {
		e.preventDefault();
		if (props.onClick) props.onClick(e);
	}

	const handleDiscard = () => {
		console.log("handleDiscard");
		navContext.setSideNavTheme(3);
		navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNav( <CoverDetailsPanel requirement={requirement} serviceTypes={serviceTypes} data={reqData}/> );
		//navContext.openSideNav();
		navContext.setSideNavBottom(null);
	}

	const handleCancel = () => {
		console.log("handleCancel");
		console.log(requirement);
		console.log(reqData);
		console.log(props);


		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
		navContext.setSideNavTitle("Cancel Span Requirement");
		navContext.setSideNav( <SpanRequirementCancelPanel shift={requirement} serviceTypes={serviceTypes || null}  data={reqData}/> );


		// switch(requirement.dayOfWeek) {
		// 	case 0:
		// 		let sundayRequirements = [...reqData.sundayRequirements];
		// 		var RequirementIndex = sundayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
		// 		// remove requirement from array
		// 		sundayRequirements.splice(RequirementIndex, 1);
		// 		console.log(sundayRequirements);
		// 		reqData.setSundayRequirements(sundayRequirements);
		// 		break;
		// 	case 1:
		// 		let mondayRequirements = [...reqData.mondayRequirements];
		// 		var RequirementIndex = mondayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
		// 		// remove requirement from array
		// 		mondayRequirements.splice(RequirementIndex, 1);
		// 		console.log(mondayRequirements);
		// 		reqData.setMondayRequirements(mondayRequirements);
		// 		break;
		// 	case 2:
		// 		let tuesdayRequirements = [...reqData.tuesdayRequirements];
		// 		var RequirementIndex = tuesdayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
		// 		// remove requirement from array
		// 		tuesdayRequirements.splice(RequirementIndex, 1);
		// 		console.log(tuesdayRequirements);
		// 		reqData.setTuesdayRequirements(tuesdayRequirements);
		// 		break;
		// 	case 3:
		// 		let wednesdayRequirements = [...reqData.wednesdayRequirements];
		// 		var RequirementIndex = wednesdayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
		// 		// remove requirement from array
		// 		wednesdayRequirements.splice(RequirementIndex, 1);
		// 		console.log(wednesdayRequirements);
		// 		reqData.setWednesdayRequirements(wednesdayRequirements);
		// 		break;
		// 	case 4:
		// 		let thursdayRequirements = [...reqData.thursdayRequirements];
		// 		var RequirementIndex = thursdayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
		// 		// remove requirement from array
		// 		thursdayRequirements.splice(RequirementIndex, 1);
		// 		console.log(thursdayRequirements);
		// 		reqData.setThursdayRequirements(thursdayRequirements);
		// 		break;
		// 	case 5:
		// 		let fridayRequirements = [...reqData.fridayRequirements];
		// 		var RequirementIndex = fridayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
		// 		// remove requirement from array
		// 		fridayRequirements.splice(RequirementIndex, 1);
		// 		console.log(fridayRequirements);
		// 		reqData.setFridayRequirements(fridayRequirements);
		// 		break;
		// 	case 6:
		// 		let saturdayRequirements = [...reqData.saturdayRequirements];
		// 		var RequirementIndex = saturdayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
		// 		// remove requirement from array
		// 		saturdayRequirements.splice(RequirementIndex, 1);
		// 		console.log(saturdayRequirements);
		// 		reqData.setSaturdayRequirements(saturdayRequirements);
		// 		break;
		// }

		// reqData.setRequirementUpdated(true);

		// if (!navContext.showErrorModal) {
		// 	navContext.setSideNavTitle("Success");
		// 	navContext.setSideNav( <SpanRequirmentSuccessPanel canceling shift={requirement}/> );
		// 	navContext.setSideNavActions(null);
		// 	navContext.setSideNavBottom(null);

		// }
	}

	const handleSave = (values) => {
		console.log("handleSave");
		console.log(requirement);
		console.log(reqData);
		console.log(props);
		var startTime = moment(reqData.planStartDate.replace(/-/g, "/") + " " + values.start_time);
        var endTime = moment(reqData.planEndDate.replace(/-/g, "/") + " " + values.end_time);

		const found = reqData.clientServiceTypes.find(element => element.shiftType === values.shift_type);

		let newRequirement : SpanCoverageEvent = {
			spanCoverageEventID: uuid(),
			spanRequirementID: "",
			dayOfWeek: requirement.dayOfWeek,
			startTime: startTime.format(),
			displayStartTime: startTime.format("h:mm A"),
			endTime: endTime.format(),
			displayEndTime: endTime.format("h:mm A"),
			staffSize: found.staffSize,
			groupSize: found.groupSize,
			billingCode: found.billingCode,
			shiftType: found.shiftType,
			allDay: false,
		};

		if(newRequirement.shiftType !== requirement.shiftType || newRequirement.displayStartTime !== requirement.displayStartTime || newRequirement.displayEndTime !== requirement.displayEndTime) {

			switch(requirement.dayOfWeek) {
				case 0:
					let sundayRequirements = [...reqData.sundayRequirements];
					var RequirementIndex = sundayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
					// remove requirement from array
					sundayRequirements.splice(RequirementIndex, 1);
					sundayRequirements.push(newRequirement);
					console.log(sundayRequirements);
					reqData.setSundayRequirements(sundayRequirements);
					break;
				case 1:
					let mondayRequirements = [...reqData.mondayRequirements];
					var RequirementIndex = mondayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
					// remove requirement from array
					mondayRequirements.splice(RequirementIndex, 1);
					mondayRequirements.push(newRequirement);
					console.log(mondayRequirements);
					reqData.setMondayRequirements(mondayRequirements);
					break;
				case 2:
					let tuesdayRequirements = [...reqData.tuesdayRequirements];
					var RequirementIndex = tuesdayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
					// remove requirement from array
					tuesdayRequirements.splice(RequirementIndex, 1);
					tuesdayRequirements.push(newRequirement);
					console.log(tuesdayRequirements);
					reqData.setTuesdayRequirements(tuesdayRequirements);
					break;
				case 3:
					let wednesdayRequirements = [...reqData.wednesdayRequirements];
					var RequirementIndex = wednesdayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
					// remove requirement from array
					wednesdayRequirements.splice(RequirementIndex, 1);
					wednesdayRequirements.push(newRequirement);
					console.log(wednesdayRequirements);
					reqData.setWednesdayRequirements(wednesdayRequirements);
					break;
				case 4:
					let thursdayRequirements = [...reqData.thursdayRequirements];
					var RequirementIndex = thursdayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
					// remove requirement from array
					thursdayRequirements.splice(RequirementIndex, 1);
					thursdayRequirements.push(newRequirement);
					console.log(thursdayRequirements);
					reqData.setThursdayRequirements(thursdayRequirements);
					break;
				case 5:
					let fridayRequirements = [...reqData.fridayRequirements];
					var RequirementIndex = fridayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
					// remove requirement from array
					fridayRequirements.splice(RequirementIndex, 1);
					fridayRequirements.push(newRequirement);
					console.log(fridayRequirements);
					reqData.setFridayRequirements(fridayRequirements);
					break;
				case 6:
					let saturdayRequirements = [...reqData.saturdayRequirements];
					var RequirementIndex = saturdayRequirements.findIndex(req => req.spanCoverageEventID === requirement.spanCoverageEventID);
					// remove requirement from array
					saturdayRequirements.splice(RequirementIndex, 1);
					saturdayRequirements.push(newRequirement);
					console.log(saturdayRequirements);
					reqData.setSaturdayRequirements(saturdayRequirements);
					break;
			}

			reqData.setRequirementUpdated(true);
		}
		navContext.setSideNavTitle("Success");
		navContext.setSideNav( <SpanRequirmentSuccessPanel updating shift={requirement}/> );
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
	}

	useLayoutEffect(() => {
		//TODO: Identify "id" to pass to client  <ClientInfoPanel />
		if (props.name) setName(props.name)
		if (props.requirement) setRequirement(props.requirement)
		if (props.serviceTypes) setServiceTypes(props.serviceTypes)
		console.log(props);
	}, [props.name, props.requirement, props.serviceTypes])

	useLayoutEffect(() => {
		navContext.setSideNavTitle("Coverage Details")
	}, [])

	const mapDay = (day: number) => {
		const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	  
		if(day < 0 || day > 6) {
		  throw new Error('Invalid day number');
		}
	  
		return days[day];
	}

	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex-shrink-0 tw-w-full">
				<div className="tw-p-20px tw-space-y-30px tw-w-full">
					<h3 className="tw-font-primary-bold tw-font-5">
						<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
							<span className="tw-text-dark tw-ml-5px">Information</span>
						</CircleLabel>
					</h3>
					<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-pb-10px">
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
									Support Type
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{requirement?.shiftType}
							</span>
						</li>
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
									Time Period
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{requirement?.displayStartTime} - {requirement?.displayEndTime}
							</span>
						</li>
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-repeat.svg" className="tw-opacity-70">
									Day of Week
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{mapDay(requirement?.dayOfWeek)}
							</span>
						</li>
						{/* <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-repeat.svg" className="tw-opacity-70">
									Repeat
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								Daily
							</span>
						</li>
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-edit.svg" className="tw-opacity-70">
									Created On
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								03/17/2021
							</span>
						</li> */}
					</ul>
				</div>
			</div>
			<div className="tw-flex tw-justify-end tw-px-40px tw-pb-40px">
				{navContext.scheduleAdmin && <ButtonPrimary className="tw-min-w-80px" onClick={ e => {
					e.preventDefault();
					const initialValues = {
						start_time: props.requirement.displayStartTime,
						end_time: props.requirement.displayEndTime,
						shift_type: props.requirement.shiftType,
					};
			
					const initialErrors = {
						start_time: false,
						end_time: false,
						shift_type: false,
					};
				
					const initialErrorMessages = {
						start_time: '',
						end_time: '',
						shift_type: '',
					};
			
					navContext.setSideNavTheme(3);
					navContext.setValues(initialValues);
					navContext.setErrors(initialErrors);
					navContext.setErrorMessages(initialErrorMessages);
					navContext.setSideNavTitle('Edit Requirement');
					navContext.setSideNav(<EditCoverDetailsPanel requirement={requirement} serviceTypes={serviceTypes} data={props?.data || null}/>); // pass props in this
					navContext.setSideNavBottom(<EditSpanRequirementBottomPanel onDiscard={handleDiscard} onCancel={handleCancel} onSave={handleSave}/>);
					navContext.setSideNavActions(null);
				}}>
					Edit
				</ButtonPrimary>}
			</div>
			{/* <div className="tw-border-t tw-border-t-light-accent tw-pt-20px tw-flex-grow tw-w-full  tw-h-full tw-overflow-hidden">
				<div className="tw-flex tw-flex-col tw-w-full tw-h-full tw-overflow-hidden">
					<div className="tw-px-40px tw-pb-10px tw-border-b tw-border-b-light-accent tw-flex-shrink-0 tw-w-full tw-space-y-10px">
						<div>
							<MakeComment />
						</div>
						<div>
							<AvatarNameInlineList>
								<span className="tw-text-6 tw-text-dark-light">16 Notes</span>
							</AvatarNameInlineList>
						</div>
					</div>
					<div className="tw-flex-grow tw-space-y-15px tw-overflow-x-hidden tw-overflow-y-auto">
						<div className="tw-pt-20px tw-px-40px tw-pb-40px">
						{ [...Array(16)].map( (comment, index) => {
							return  <CommentBlock key={`comment_${index}`} data={{
									user: {
										name: "Kevin Mack",
										image: null
									},
									comment: {
										createdOn: "March 16, 2022 | 8:00AM"
									}
								}}>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis, eum!
							</CommentBlock>
						})}
						</div>
					</div>
				</div>
			</div> */}
		</div>
	</>
}

export default CoverDetailsPanel;
