import { DefaultButton, SectionHeader } from "@medforall/medforall-component-library";
import { ButtonPrimary, ButtonSecondary } from "../buttons/Buttons";
import { CircleLabel } from "../calendars/CalendarUtils";
import { ChangeType, FrequencyField, FrequencyFieldDays, InputTimes, Label, NoYes, Search, Select, SupportType, Textarea } from "../forms/formFields";
import { LabelOutputs, Span, Status } from "../utilities/Helpers";
import { useEffect, useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import CalendarSelector from "../calendars/CalendarSelector";
import AvatarNameInline from "../avatars/AvatarNameInline";
import SessionListPanel from "./SessionList";
import AssignedEvent from "../../types/assignedEvent";
import RemoteSupportSession from "../../types/remoteSupportSession";
import EditSessionPanel from "./EditSessionPanel";
import { ConfirmSessionChangesBottomPanel, EditSessionBottomPanel, ManageSessionShiftsBottomPanel, ViewSessionLogsBottomPanel } from "./PanelCTAs";
import ManageSessionShiftsPanel from "./ManageSessionShiftsPanel";
import moment from "moment";
import { getCoverageEventsBySessionID, getLogsForRemoteSupportSession, getOverlappingSessions, getRemoteSupportAssociates, getRemoteSupportSessionByID, updateRecurringRemoteSupportSession, updateRemoteSupportSession } from "../../apicalls/sessionService";
import Employee from "../../types/employee";
import ConfirmSessionChangesPanel from "./ConfirmSessionChangesPanel";
import ConfirmSessionDeletePanel from "./ConfirmSessionDeletePanel";
import SessionActionSuccessPanel from "./SessionActionSuccessPanel";
import CoverageEvent from "../../types/coverageEvent";
import { set } from "lodash";
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import { handleIncomingSpanSessions } from "../../apicalls/schedulingService";
import { AcademicCapIcon } from "@heroicons/react/24/solid";
import SessionLogsPanel from "./SessionLogsPanel";
import { SessionLog } from "../../types/sessionLog";

const SessionInfoPanel = props => {
	const navContext = useAppContext();
	const [toggleFilters, setToggleFilters] = useState<boolean>(false);
	const [type, setType] = useState<string>(null);
	const [editing, setEditing] = useState<boolean>(false);
	const [values, setValues] = useState({
		'dedicated': 'No',
		'overnight': 'No'
	});
	const [repeatFrequency, setRepeatFrequency] = useState<boolean>(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [sessionLogs, setSessionLogs] = useState<SessionLog[]>([]);
    const [sessionShifts, setSessionShifts] = useState<AssignedEvent[]>(null);
    const [filteredShifts, setFilteredShifts] = useState<AssignedEvent[]>(null);
    const [movableShifts, setMovableShifts] = useState<CoverageEvent[]>(null);
    const [totalClientHours, setTotalClientHours] = useState(0);
    const [activeRSAs, setActiveRSAs] = useState<Employee[]>(null);

    const [toggleSessionInformation, setToggleSessionInformation] = useState<boolean>(true);

    const [sessionLoading, setSessionLoading] = useState<boolean>(false);

    const [concurrentSessions, setConcurrentSessions] = useState<RemoteSupportSession[]>(null);

    function getDurationInHours(startDate: Date, endDate: Date): number {
		let diffInMs = endDate.getTime() - startDate.getTime();
		let diffInHours = diffInMs / (1000 * 60 * 60); // convert milliseconds to hours
		return diffInHours;
	}

    const getTotalClientHours = (session: RemoteSupportSession) : number => {
		let totalHours = 0;

		session.assignedShifts.forEach(clientSession => {
			totalHours += getDurationInHours(new Date(clientSession.startTime), new Date(clientSession.endTime));
		});

		return totalHours;
	}

	useLayoutEffect(() => {
		if (props.editing) setEditing(true)
		if (props.type) setType(props.type)
        if (props.selectedSession) {
            setSelectedSession(props?.selectedSession);

            setSessionShifts(props?.selectedSession?.assignedShifts);
            setFilteredShifts(props?.selectedSession?.assignedShifts);

            const fetchRSAs = async () => {
                setSessionLoading(true);

				const data = await getRemoteSupportAssociates(navContext.handleAPICallError);
				console.log(data);
				setActiveRSAs(data);

                //await fetchMovableShifts();

                setSessionLoading(false);
			}

            const fetchSessionLogs = async () => {
                const sessionLogs = await getLogsForRemoteSupportSession(props?.selectedSession.remoteSupportSessionID, navContext.handleAPICallError);

                if (sessionLogs != null){
                    console.log(sessionLogs);
                    setSessionLogs(sessionLogs);
                }
            }

            const fetchMovableShifts = async () => {
                const assignedShifts = await getCoverageEventsBySessionID(props?.selectedSession.remoteSupportSessionID, navContext.handleAPICallError);

                if (assignedShifts != null){
                    console.log(assignedShifts);

                    console.log(assignedShifts.filter(f => f.providerName !== props?.selectedSession?.providerName));

                    setMovableShifts(assignedShifts.filter(f => f.providerName !== props?.selectedSession?.providerName));

                    const overlappingSessions = await getOverlappingSessions(props?.selectedSession.remoteSupportSessionID, navContext.handleAPICallError);

                    if (overlappingSessions != null){
                        setConcurrentSessions(overlappingSessions);
                    }
                }

               
            }

            fetchRSAs()
				.catch(console.error);

            fetchSessionLogs()
                .catch(console.error);

            if(props?.selectedSession?.assignedShifts !== null){
				setTotalClientHours(getTotalClientHours(props?.selectedSession));
			}
        }
	}, [props.editing, props.type, props.selectedSession]);

    const discardSessionChanges = () => {
        const panelProps = {
            props
        }

        navContext.setSideNav(<SessionInfoPanel selectedSession={selectedSession} updateSession={props?.updateSession} removeSession={props?.removeSession}/>);
		navContext.setSideNavTheme(3);
		navContext.setSideNavTitle("Session Details");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
    }

    const backToSessionInfo = async () => {
        const panelProps = {
            props
        }

        const sessionByID = await getRemoteSupportSessionByID(selectedSession?.remoteSupportSessionID, navContext.handleAPICallError);

        setSessionShifts(sessionByID?.assignedShifts);

        navContext.setSideNav( <SessionInfoPanel selectedSession={selectedSession} updateSession={props?.updateSession} removeSession={props?.removeSession}/>);
		navContext.setSideNavTheme(3);
		navContext.setSideNavTitle("Session Details");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
    }

    const confirmDeleteSession = () => {
        navContext.setSideNav( <ConfirmSessionDeletePanel selectedSession={selectedSession} updateSession={props?.updateSession} removeSession={props?.removeSession}/>);
        navContext.setSideNavTheme(3);
        navContext.setSideNavTitle("Confirm Session Delete");
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(null);
    }

    const confirmSaveSessionChanges = (values) => {
        console.log(values);

		const startTime = moment(values.start_date.replace(/-/g, "/") + " " + values.start_time)
        const endTime = moment(values.end_date.replace(/-/g, "/") + " " + values.end_time)

		var provider = activeRSAs.find(element => element.employeeID === values.provider)

		let updatedSession : RemoteSupportSession = {
			remoteSupportSessionID: selectedSession?.remoteSupportSessionID,
			supervisorID: selectedSession?.supervisorID,
			providerID: values.provider,
			startDate: moment(values.start_date.replace(/-/g, "/")).format(),
			displayStartDate: moment(values.start_date.replace(/-/g, "/")).format("MM-DD-YYYY"), // "MM-DD-YYYY"
			endDate: moment(values.end_date.replace(/-/g, "/")).format(),
			displayEndDate: moment(values.end_date.replace(/-/g, "/")).format("MM-DD-YYYY"), // "MM-DD-YYYY"
			providerName: provider.firstName + " " + provider.lastName,
			startTime: startTime.format(),
			displayStartTime: startTime.format("h:mm A"),
			endTime: endTime.format(),
			displayEndTime: endTime.format("h:mm A"),
			status: selectedSession?.status,
			multiDay: moment(values.end_date.replace(/-/g, "/")).format("MM-DD-YYYY") !== moment(values.start_date.replace(/-/g, "/")).format("MM-DD-YYYY"),
			startIndex: 0,
			endIndex: 0,
			multiDayStartIndex: 0,
			multiDayEndIndex: 0,
			recurrence: props?.selectedSession.recurrenceEndDate,
			assignedShifts: [],
			colStart: 0,
			colEnd: 0,
			dedicatedSession: false,
			repeatsSunday: false,
			repeatsMonday: false,
			repeatsTuesday: false,
			repeatsWednesday: false,
			repeatsThursday: false,
			repeatsFriday: false,
			repeatsSaturday: false,
			recurrenceEndDate: moment(props?.selectedSession.recurrenceEndDate).format(),
			multiDayColStart: 0,
			multiDayColEnd: 0,
			officeID: "",
			officeName: "",
			stationID: "",
			stationName: "",
            training: values.training,
		}

		console.log(updatedSession);

        navContext.setSideNav( <ConfirmSessionChangesPanel selectedSession={selectedSession} updatedSession={updatedSession} updateSession={props?.updateSession} removeSession={props?.removeSession}/>);
		navContext.setSideNavTheme(3);
		navContext.setSideNavTitle("Confirm Session Changes");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(<ConfirmSessionChangesBottomPanel onCancel={editSessionChanges} onSave={saveSessionChanges} onSaveRecurring={saveRecurringSessionChanges} recurring={selectedSession?.recurrence !== "Never"} updatedSession={updatedSession}/>);
    }

    const saveSessionChanges = async (session) => {
        var title = "Session Changes Saved";
        var message = "The session changes have been saved successfully.";

        const result = await updateRemoteSupportSession(session, navContext.handleAPICallError);

        navContext.setSideNavTitle("Success");
		navContext.setSideNav( <SessionActionSuccessPanel selectedSession={session} title={title} message={message} updateSession={props?.updateSession} removeSession={props?.removeSession}/> );
		navContext.setSideNavActions(null);
	    navContext.setSideNavBottom(null);

        if(props?.updateSession){
            var sessionCopy : RemoteSupportSession = Object.assign({}, session);
            var mappedSession = handleIncomingSpanSessions(sessionCopy);
            console.log(mappedSession);
            mappedSession.assignedShiftCount = selectedSession.assignedShiftCount;
            props?.updateSession(mappedSession);
        }
    }

    const saveRecurringSessionChanges = async (session) => {
        var title = "Session Series Changes Saved";
        var message = "The session series changes have been saved successfully.";

        const result = await updateRecurringRemoteSupportSession(session, navContext.handleAPICallError);

        navContext.setSideNavTitle("Success");
        navContext.setSideNav( <SessionActionSuccessPanel selectedSession={session} title={title} message={message} updateSession={props?.updateSession} removeSession={props?.removeSession}/> );
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(null);

        if(props?.updateSession){
            var sessionCopy : RemoteSupportSession = Object.assign({}, session);
            var mappedSession = handleIncomingSpanSessions(sessionCopy);
            console.log(mappedSession);
            mappedSession.assignedShiftCount = selectedSession.assignedShiftCount;
            props?.updateSession(mappedSession);
        }
    }

    const showSessionLogs = () => {
        navContext.setSideNav( <SessionLogsPanel selectedSession={selectedSession} sessionLogs={sessionLogs} /> );
        navContext.setSideNavTheme(3);
        navContext.setSideNavTitle("Session Logs");
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(<ViewSessionLogsBottomPanel onCancel={backToSessionInfo}/>);
    }


    const editSessionChanges = () => {
        const panelProps = {
            props
        }

        const initialValues = {
            "start_date": moment.utc(props?.selectedSession.displayStartDate.replace(/-/g, "/")).format("M-D-YYYY"),
			"end_date": moment.utc(props?.selectedSession.displayEndDate.replace(/-/g, "/")).format("M-D-YYYY"),
			"start_time": moment.utc(props?.selectedSession.startTime).format("h:mm A"),
			"end_time": moment.utc(props?.selectedSession.endTime).format("h:mm A"),
			"provider": props?.selectedSession.providerID,
            "training": props?.selectedSession.training,
        };
    
        const initialErrors = {
            start_date: false,
            end_date: false,
            start_time: false,
            end_time: false,
            provider: false,
        };
    
        const initialErrorMessages = {
            start_date: '',
            end_date: '',
            start_time: '',
            end_time: '',
            provider: '',
        };
    
        const initialFormStates = {
            start_date: false,
            end_date: false,
            start_time: false,
            end_time: false,
            provider: false,
        };

        navContext.setValues(initialValues);
        navContext.setErrors(initialErrors);
        navContext.setErrorMessages(initialErrorMessages);
        navContext.setFieldStates(initialFormStates);
        navContext.setSideNav( <EditSessionPanel selectedSession={selectedSession} updateSession={props?.updateSession} removeSession={props?.removeSession}/>);
        navContext.setSideNavTheme(3);
        navContext.setSideNavTitle("Edit Session");
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(<EditSessionBottomPanel onDiscard={discardSessionChanges} onSave={confirmSaveSessionChanges} onDelete={confirmDeleteSession}/>);
    }

    const manageAssignedShifts = () => {

        navContext.setSideNav(<ManageSessionShiftsPanel selectedSession={selectedSession} sessionShifts={sessionShifts} updateSession={props?.updateSession} removeSession={props?.removeSession} updateShiftCount={props?.updateShiftCount}/>);
        navContext.setSideNavTheme(3);
        navContext.setSideNavTitle("Manage Session Shifts");
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(<ManageSessionShiftsBottomPanel onCancel={backToSessionInfo} />);
    }

	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
            {sessionLoading ? 
            <div className="tw-flex tw-justify-center tw-m-md">
                <LoadingSpinner /> 
            </div>
            
            : 

            <div className="tw-w-full tw-flex tw-flex-col tw-h-full">

                <div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
                        <div className="tw-flex-shrink-0 tw-w-full">
                        <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px" onClick={ e => {
                            let temp = toggleSessionInformation;
                            setToggleSessionInformation( temp = !temp )
                        }}>
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                <span className="tw-flex-grow">
                                <CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
                                    <span className="tw-text-dark tw-ml-5px">Session Information</span>
                                </CircleLabel>
                                </span>
                                <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/icon-arrow-${ toggleSessionInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
                                </span>
                            </span>
                        </h3>
                        {/* <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className="tw-text-dark">
                                        Session Information
                                    </span>
                                </span>
                            </span>
                        </h3> */}

                        
                        <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Provider
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.providerName}
                                    {selectedSession?.training && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                        <AcademicCapIcon className="tw-w-full tw-text-lime-500" />
                                    </span>}
                                </span>
                                
                            </li>
                            { toggleSessionInformation && <li className={`tw-flex tw-space-x-5px tw-items-center`}>
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Status
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.status}
                                </span>
                            </li>}
                             
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Start Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayStartDate}
                                </span>
                            </li>
                            { toggleSessionInformation &&  <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        End Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayEndDate}
                                </span>
                            </li>}
                            <li className={`tw-flex tw-space-x-5px tw-items-center ${toggleSessionInformation ? "" : "tw-pb-15px"}`}>
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Time Period
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayStartTime} - {selectedSession?.displayEndTime}
                                </span>
                                {!moment(selectedSession?.startDate).isSame(selectedSession?.endDate) && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
                                </span>}
                            </li>
                            { toggleSessionInformation && selectedSession?.createdBy.length > 0 && <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Created By
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.createdBy}
                                </span>
                            </li>}

                            { toggleSessionInformation && selectedSession?.createdBy.length > 0 && <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Created Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                     {moment.utc(selectedSession?.createdDate).tz('America/New_York').format('MMM Do YYYY h:mm A')}
                                </span>
                            </li>}
                            
                            {/* <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Overnight
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    No
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Dedicated
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    No
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Address
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    875 N High St Suite 300, Columbus
                                </span>
                            </li> */}
                        </ul>
                        {toggleSessionInformation &&  <div className="tw-flex tw-justify-end tw-pr-20px tw-pb-10px tw-pt-15px">
                            { navContext?.sessionScheduleAdmin && <ButtonSecondary className="tw-min-w-80px tw-mr-4" onClick={ e => {
                                e.preventDefault();
                                showSessionLogs();
                            }}>
                                View Logs
                            </ButtonSecondary>}

                            { navContext?.sessionScheduleAdmin && selectedSession?.status !== "Submitted" && <ButtonPrimary className="tw-min-w-80px" onClick={ e => {
                                e.preventDefault();
                                editSessionChanges();

                                // setEditing(true);
                                // navContext.setSideNavTitle("Edit Session");
                                // navContext.setSideNavActions({
                                //     "ctas": [{
                                //         "name": "Discard",
                                //         "onClick": (e) => {
                                //             setEditing(false)
                                //             navContext.setSideNavActions(null);
                                //         },
                                //         "title": `Go Back`
                                //     }, {
                                //         "name": "Save",
                                //         "onClick": (e) => {
                                //             // navContext.setSideNavTitle("Changes Saved");
                                //             // navContext.setSideNav( <CoverageDetailsPanelSuccess canceling  /> );
                                //             alert("Saved!");
                                //             navContext.setSideNavActions(null);
                                //             navContext.closeSideNav();
                                //         },
                                //         "title": `Save changes`
                                //     }]
                                // });
                            }}>
                                Edit Session Info
                            </ButtonPrimary>}
                        </div>}
                       
                        <h3 role="button" className="tw-cursor-pointer tw-border-t tw-border-t-light-accent tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px tw-pt-20px">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className="tw-text-dark">
                                        Assigned Shifts : {sessionShifts ? sessionShifts?.length : 0}, Total Hours : {totalClientHours} 
                                    </span>
                                </span>
                            </span>
                        </h3>
                        {/* <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-15px">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className="tw-text-dark">
                                        Total Client Hours : <Span className="tw-text-black">{totalClientHours}</Span>
                                    </span>
                                </span>
                            </span>
                        </h3> */}
                        <div className="tw-flex tw-w-full tw-items-center">
                            <div className="tw-flex-grow tw-flex tw-items-center tw-pr-20px">
                                <Search className="tw-flex tw-w-full tw-flex-grow" onChange={ e => {

                                    console.log(e?.target.value || "nope");

                                    if(e?.target.value){
                                        const searchText = e?.target.value.toString().toLowerCase();
                                        const filtered = sessionShifts.filter(element => {
                                            
                                            // 👇️ using AND (&&) operator
                                            return element.patientName.toLowerCase().includes(searchText);
                                        });

                                        console.log(filtered)

                                        setFilteredShifts(filtered);
                                    } else {
                                        setFilteredShifts(sessionShifts);
                                    }
                                    }}/>
                            </div>
                            {/* <div className="tw-max-w-40px tw-flex tw-justify-center tw-items-center" role="button" onClick={ () => {
                                    let temp = toggleFilters;
                                    setToggleFilters( temp = !temp )
                            } }>
                                <span className="tw-relative tw-aspect-square tw-w-30px">
                                    { toggleFilters && <span className="tw-w-8px tw-h-8px tw-z-2 -tw-mt-1px tw-bg-primary tw-absolute tw-top-0 tw-right-0 tw-block tw-rounded-full"></span> }
                                    <img className="tw-w-full tw-h-full tw-absolute tw-object-cover tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-max-w-40px " src="/images/icon-filter.svg" />
                                </span>
                            </div> */}

                            {/* TODO: https://www.freecodecamp.org/news/build-a-search-filter-using-react-and-react-hooks/ */}

                        </div>
                        {toggleFilters && <>
                            <div className="-tw-mx-20px tw-pt-15px">
                                <div className="tw-flex tw-w-full tw-overflow-x-auto tw-snap-x tw-space-x-5px tw-px-20px slider">
                                    { [...Array(10)].map( ( day, index ) => {
                                        return <>
                                            <ButtonSecondary className={`${ index === 0 ? '!tw-bg-primary tw-text-white hover:!tw-bg-white hover:!tw-text-primary': '' } tw-px-8px tw-snap-center tw-text-6 tw-py-0 tw-min-h-20px tw-rounded-20px tw-flex-shrink-0`} childClasses="tw-py-5px">08:00-10:00</ButtonSecondary>
                                        </>
                                    }) }
                                </div>
                            </div>
                        </>}
                    </div>
                    <div className="-tw-mx-20px !tw-mt-5px tw-h-full tw-flex-grow tw-overflow-hidden tw-flex tw-flex-col tw-divide-y-1px tw-divide-black/20 tw-border-b-1px tw-border-b-black/20">
                        <div className="tw-py-10px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5 tw-flex-shrink-0 tw-px-20px">
                            <div className="tw-flex tw-items-center tw-w-200px">
                                <span className="tw-text-dark/70 tw-text-[.75em]">
                                    Client
                                </span>
                            </div>
                            <div className="tw-flex tw-items-center tw-flex-grow tw-ml-auto tw-justify-start tw-space-x-10px">
                                <span className="tw-text-dark/70 tw-text-[.7em]">
                                    Coverage Period
                                </span>
                            </div>
                        </div>
                        {(!sessionShifts || sessionShifts?.length === 0) && <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-py-sm">
                            <span className="tw-text-primary tw-text-5 tw-text-center">Nothing Assigned Yet!</span>
                        </div>}
                        <div className="tw-flex tw-flex-col tw-px-20px tw-overflow-y-auto tw-divide-y-1px tw-divide-black/20 tw-snap-y">
                            { filteredShifts?.map( ( shift, index ) => {
                                return <>
                                    <div className="tw-snap-center tw-flex-shrink-0 tw-py-10px tw-flex tw-w-full tw-items-center tw-space-x-10px tw-text-5">
                                        <div className="tw-flex tw-items-center tw-w-200px">
                                            <CircleLabel className="tw-text-black/20 tw-space-x-5px tw-w-full" size={14} space="tw-space-x-5px">
                                                <span className="tw-text-dark">
                                                    {shift.patientName}
                                                </span>
                                            </CircleLabel>
                                        </div>
                                        <div className="tw-flex tw-items-center tw-flex-grow tw-ml-auto tw-justify-start tw-space-x-10px">
                                            <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-flex-grow">
                                                <b>
                                                    {shift.displayStartTime} - {shift.displayEndTime}
                                                </b>
                                            </div>
                                            {/* <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-ml-auto tw-pr-5px">
                                                { index == 2 ?
                                                    <img src="/images/icon-status-checked.svg" className="tw-w-20px" />
                                                    :  (index == 3 ) ? <img src="/images/icon-status-repeat.svg" className="tw-w-20px" /> : <img src="/images/icon-status-stop.svg" className="tw-w-20px" />
                                                }
                                            </div> */}
                                        </div>
                                    </div>
                                </>
                            }) }
                        </div>
                        {/* <div className="tw-w-full tw-flex tw-flex-shrink-0">
                            <DefaultButton className="tw-w-full tw-text-6 tw-min-h-30px tw-mt-2px tw-text-dark hover:tw-text-primary">
                                View More
                            </DefaultButton>
                        </div> */}
                    </div>
                </div>
                {navContext?.sessionScheduleAdmin && selectedSession?.status !== "Submitted" && <div className="tw-flex-shrink-0 tw-p-20px tw-flex tw-justify-center">
                    <ButtonSecondary href="/schedule/rsa/daily/324324234" onClick={ e => {
                        e.preventDefault();
                        manageAssignedShifts();
                        //navContext.setSideNav( <SessionListPanel  /> );
                        // setType("weekly");
                        // setEditing(false)

                        // console.log(type)
                        // navContext.openSideNav();
                        // navContext.setSideNavActions(null);
                    }}>
                        {(!sessionShifts || sessionShifts?.length === 0) ? "Assign Shifts" : "Manage Assigned Shifts"}
                    </ButtonSecondary>
                </div>}
            </div>}

		</div>
	</>
}

export default SessionInfoPanel;
