export const digitsOnly = value => value.replace(/[^\d]/g, "");

export const startWithZero = value => {
	if (!value || value === 0 || value === "0" || value === "00") return "00";

	const digits = Number(digitsOnly(value.toString()));

	return digits <= 9 ? ("0" + digits.toString()).replace("00","0") : digits.toString().replace("00","0");

}

export const formatDate = (value, seperator = '-') => {
	if (!value) return value;

	const digits = digitsOnly(value);

	const digitsLength = digits.length;

	if (digitsLength < 2) return {
		"mmddyyyy": digits,
		"yyyyddmm": digits,
		"digits": digits || '0',
		"value": value || ''
	};

	if (digitsLength < 5) {
		return {
			"mmddyyyy": `${digits.slice(0, 2)}${seperator}${digits.slice(2, 4)}`,
			"yyyyddmm": `${digits.slice(2, 4)}${seperator}${digits.slice(0, 2)}`,
			"digits": digits || '0',
			"value": value || ''
		}
	}

	return {
		"mmddyyyy": `${digits.slice(0, 2)}${seperator}${digits.slice(2, 4)}${seperator}${digits.slice(4, 8)}`,
		"yyyyddmm": `${digits.slice(4, 8)}${seperator}${digits.slice(2, 4)}${seperator}${digits.slice(0, 2)}`,
		"digits": digits || '0',
		"value": value || ''
	};
}

export const resetDate = (data, string, twoDigitYear) => {
	const seperator = string || "/";
	return (data.substr(5, 2) + seperator + data.substr(8, 2) + seperator + (twoDigitYear === true ? data.substr(2, 2) : data.substr(0, 4)))
}

export const formatSSN = value => {
	if (!value) return value;

	const digits = digitsOnly(value);

	const digitsLength = digits.length;

	if (digitsLength < 3) return digits;

	if (digitsLength < 6) return `${digits.slice(0, 3)}-${digits.slice(3, 5)}`

	return `${digits.slice(0, 3)}-${digits.slice(3, 5)}-${digits.slice(5)}`
}

export const formatDollars = (value, includeCents = false, removeEmptyCents = false) => {
	if (!value) return value;

	const digits = digitsOnly(value.toString());

	const digitsLength = digits.length;


	const money = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(digits);
	let cents = money.slice(-3);

	if (includeCents && removeEmptyCents) {
		return ((cents === '.00' && removeEmptyCents) ? money.replace(cents, '') : money);
	}

	if (includeCents && !removeEmptyCents) {
		return money
	} else {
		return money.replace(cents, '');
	}

}

export const formatPhoneNumber = value => {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value;

	// clean the input for any non-digit values.
	const phoneNumber = digitsOnly(value);

	// phoneNumberLength is used to know when to apply our formatting for the phone number
	const phoneNumberLength = phoneNumber.length;

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early
	if (phoneNumberLength < 4) return phoneNumber;

	// if phoneNumberLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	// finally, if the phoneNumberLength is greater then seven, we add the last
	// bit of formatting and return it.
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
		3,
		6
	)}-${phoneNumber.slice(6, 10)}`;
}


export const moneyToNumber = (money) => {
	const moneyString = money.toString();

	const output = (number) => {
		const tempNumberPre = Number((Math.abs(number) * 100).toPrecision(15));
		const tempNumber = Math.round(tempNumberPre) / 100 * Math.sign(number);

		return tempNumber;
	}

	return output(moneyString.includes("$") ? moneyString.replace('$', '') : moneyString);
}


export const formatNumber = (value, shorten = false) => {
	const n = digitsOnly(value);

	if (n) {
		if (shorten) {
			// let formatter = Intl.NumberFormat('en', { notation: 'compact' });
			// return formatter.format((number * 10) / 10);
			if (n < 1e3) return n;
			if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
			if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
			if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
			if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
		} else {
			const temp = Number(n);
			return temp.toLocaleString("en-US");
		}
	} else {
		return n || '';
	}
}



export const formatContactNumber = (value) => {
	if (!value) return value;

	const contactNumber = digitsOnly(value);
	const contactNumberLength = contactNumber.length;

	if (contactNumberLength <= 3) return contactNumber;

	return `${contactNumber.slice(0, 3)}-${contactNumber.slice(
		3,
		8
	)}`;
}
