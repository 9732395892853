import { CheckIcon, ChevronRightIcon, PlusCircleIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { ButtonPrimary, ButtonSecondary, Input, Label } from "@medforall/medforall-component-library";
import { useFormik } from "formik";
import { createContext, useContext, useLayoutEffect, useState, useMemo, Dispatch, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import Patient from "../types/patient";
import { getClient } from "../apicalls/clientMgmtService";
import { CircularProgress } from "@mui/material";
import ServicePlan, { DesiredOutcome, PersonalService, SupportArea, RemoteSupportEvent, SuggestedAction, RemoteSupportRoutine, UnusualEvent, AuthorizedService, ActionStep, ExpectedOutcome, RoutineEvent, UnusualEventAction } from "../types/servicePlan";
import NewPlanSection, { NewPlanBanner } from "../components/forms/newPlanSection";
import NewSupportAreaSection from "../components/forms/newSupportAreaSection";
import NewDesiredOutcomeSection from "../components/forms/newDesiredOutcomeSection";
import NewTaskSection from "../components/forms/newTaskSection";
import NewRoutineSection from "../components/forms/newRoutineSection";
import NewSuggestedActionSection from "../components/forms/newSuggestedActionSection";
import NewUnusualEventSection from "../components/forms/newUnusualEventSection";
import NewAuthorizedSection from "../components/forms/newAuthorizedServiceSection";
import EditSupportAreaCard, { EditSupportAreaList, ViewSupportAreaSection } from "../components/portalComponents/editSupportAreaCard";
import EditDesiredOutcomeCard, { EditDesiredOutcomeList, ViewDesiredOutcomeSection } from "../components/portalComponents/editDesiredOutcomeCard";
import EditRemoteSupportTaskCard, { EditRemoteSupportTaskList, ViewRemoteSupportTaskSection } from "../components/portalComponents/editRemoteSupportTaskCard";
import EditRemoteSupportRoutine, { EditRoutineList, ViewRemoteSupportRoutine } from "../components/portalComponents/editRemoteSupportRoutine";
import EditSuggestedActionCard, { EditSuggestedActionList, ViewSuggestedActionSection } from "../components/portalComponents/editSuggestedActionCard";
import EditUnusualEventCard, { EditUnusualEventList, ViewUnusualEventSection } from "../components/portalComponents/editUnusualEventCard";
import EditAuthorizedServiceCard, { EditAuthorizedServiceList, ViewAuthorizedServiceSection } from "../components/portalComponents/editAuthorizedServiceCard";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { PencilIcon } from "@heroicons/react/24/outline";
import { addServicePlan } from "../apicalls/servicePlanService";
import { useAppContext } from "../Context";

const steps = [
    { id: '1', name: 'New Plan', href: '#', status: 'current' },
    { id: '2', name: 'Service Details', href: '#', status: 'upcoming' },
    { id: '3', name: 'Authorization', href: '#', status: 'upcoming' },
    { id: '4', name: 'Review', href: '#', status: 'upcoming' },
]

const NewPlanContext = createContext({
    setActiveSteps: (() => undefined) as Dispatch<any>,
    activeSteps: steps,
    newPlan: {} as ServicePlan,
    setNewPlan: (() => undefined) as Dispatch<any>,
    planSupportAreas: [] as SupportArea[],
    setPlanSupportAreas: (() => undefined) as Dispatch<any>,
    planDesiredOutcomes: [] as DesiredOutcome[],
    setPlanDesiredOutcomes: (() => undefined) as Dispatch<any>,
    planTasks: [] as RemoteSupportEvent[],
    setPlanTasks: (() => undefined) as Dispatch<any>,
    planSuggestedActions: [] as SuggestedAction[],
    setPlanSuggestedActions: (() => undefined) as Dispatch<any>,
    planRoutines: [] as RemoteSupportRoutine[],
    setPlanRoutines: (() => undefined) as Dispatch<any>,
    planUnusualEvents: [] as UnusualEvent[],
    setPlanUnusualEvents: (() => undefined) as Dispatch<any>,
    planAuthorizedServices: [] as AuthorizedService[],
    setPlanAuthorizedServices: (() => undefined) as Dispatch<any>,
    clientID: '',
    patient: {} as Patient,
});

export const useNewPlanContext = () => useContext(NewPlanContext);

const NewServicePlanPage = props => {
    const [activeSteps, setActiveSteps] = useState(steps);
    const [planSupportAreas, setPlanSupportAreas] = useState<SupportArea[]>([]);
    const [planDesiredOutcomes, setPlanDesiredOutcomes] = useState<DesiredOutcome[]>([]);
    const [planTasks, setPlanTasks] = useState<RemoteSupportEvent[]>([]);
    const [planSuggestedActions, setPlanSuggestedActions] = useState<SuggestedAction[]>([]);
    const [planRoutines, setPlanRoutines] = useState<RemoteSupportRoutine[]>([]);
    const [planUnusualEvents, setPlanUnusualEvents] = useState<UnusualEvent[]>([]);
    const [planAuthorizedServices, setPlanAuthorizedServices] = useState<AuthorizedService[]>([]);
    const [newPlan, setNewPlan] = useState<ServicePlan>();
    const { clientID } = useParams();
    const [clientLoading, setClientLoading] = useState(true);
    const [serviceDetailsSections, setServiceDetailsSections] = useState({
        showGoalSection: false,
        showTaskSection: false,
        showRoutineSection: false,
        showSuggestedActionSection: false,
        showUnusualEventSection: false,
        showSupportAreaSection: false,
    });

    const appContext = useAppContext();

    const goToSupportAreaSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: true,
            showGoalSection: false,
            showTaskSection: false,
            showRoutineSection: false,
            showSuggestedActionSection: false,
            showUnusualEventSection: false,
        });

        goToServiceDetails();
    }

    const toggleSupportAreaSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: !serviceDetailsSections.showSupportAreaSection
        })
    }

    const goToGoalSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: true,
            showTaskSection: false,
            showRoutineSection: false,
            showSuggestedActionSection: false,
            showUnusualEventSection: false,
        });

        goToServiceDetails();
    }

    const toggleGoalSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showGoalSection: !serviceDetailsSections.showGoalSection
        })
    }

    const goToTaskSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: false,
            showTaskSection: true,
            showRoutineSection: false,
            showSuggestedActionSection: false,
            showUnusualEventSection: false,
        });

        goToServiceDetails();
    }

    const toggleTaskSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showTaskSection: !serviceDetailsSections.showTaskSection
        })
    }

    const goToRoutineSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: false,
            showTaskSection: false,
            showRoutineSection: true,
            showSuggestedActionSection: false,
            showUnusualEventSection: false,
        });

        goToServiceDetails();
    }

    const toggleRoutineSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showRoutineSection: !serviceDetailsSections.showRoutineSection
        })
    }

    const goToSuggestedActionSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: false,
            showTaskSection: false,
            showRoutineSection: false,
            showSuggestedActionSection: true,
            showUnusualEventSection: false,
        });

        goToServiceDetails();
    }

    const toggleSuggestedActionSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSuggestedActionSection: !serviceDetailsSections.showSuggestedActionSection
        })
    }

    const goToUnusualEventSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showSupportAreaSection: false,
            showGoalSection: false,
            showTaskSection: false,
            showRoutineSection: false,
            showSuggestedActionSection: false,
            showUnusualEventSection: true,
        });

        goToServiceDetails();
    }

    const toggleUnusualEventSection = () => {
        setServiceDetailsSections({
            ...serviceDetailsSections,
            showUnusualEventSection: !serviceDetailsSections.showUnusualEventSection
        })
    }

    const saveServicePlan = async () => {
        var mappedServices = planAuthorizedServices.map(service => {
            return {
                authorizedServiceID: "",
                servicePlanID: "",
                serviceTitle: service.serviceTitle,
                serviceCode: service.serviceCode,
                staffSize: service.staffSize,
                groupSize: service.groupSize,
                authorizedBudget: service.authorizedBudget,
                utilizedBudget: 0,
                authorizedUnits: service.authorizedUnits,
                utilizedUnits: 0,
                budgetStartDate: moment(service.budgetStartDate).format(),
                budgetEndDate: moment(service.budgetEndDate).format(),
                evvServiceID: "",
                evvServiceName: "",
                payer: "",
                payerProgram: "",
                procedureCode: "",
                displayStartDate: moment(service.budgetStartDate).format("MM/DD/YYYY"),
                displayEndDate: moment(service.budgetEndDate).format("MM/DD/YYYY"),
            }});

        const servicePlan: ServicePlan = {
            planID: "",
            servicePlanID: "",
            patientID: clientID,
            waiverType: newPlan.waiverType,
            fiscalYear: newPlan.fiscalYear,
            status: "Draft",
            startDate: moment(newPlan.startDate).format(),
            displayStartDate: newPlan.displayStartDate,
            endDate: moment(newPlan.endDate).format(),
            displayEndDate: newPlan.displayEndDate,
            aboutClient: newPlan.aboutClient,
            authorizedServices: mappedServices,
            supportAreas: planSupportAreas,
            desiredOutcomes: planDesiredOutcomes,
            remoteSupportEvents: planTasks,
            suggestedActions: planSuggestedActions,
            remoteSupportRoutines: planRoutines,
            unusualEvents: planUnusualEvents,
            // authorizedServices: [],
            // supportAreas: [],
            // desiredOutcomes: [],
            // remoteSupportEvents: [],
            // suggestedActions: [],
            // remoteSupportRoutines: [],
            // unusualEvents: [],
        };

        console.log("Service Plan");
        console.log(servicePlan);

        const response = await addServicePlan(servicePlan, clientID, appContext.handleAPICallError);
        console.log(response);

        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Service Plan successfully created.");
            appContext.setShowSuccessModal(true);
            appContext.setNavigateToPreviousPage(true);
        }
        

        
    }

    const [patient, setPatient] = useState<Patient>();
    let navigate = useNavigate();



    const value = useMemo(() => ({
		patient, activeSteps,
        setActiveSteps, 
        newPlan, setNewPlan,
        planSupportAreas, setPlanSupportAreas,
        planDesiredOutcomes, setPlanDesiredOutcomes,
        planTasks, setPlanTasks,
        planSuggestedActions, setPlanSuggestedActions,
        planRoutines, setPlanRoutines,
        planUnusualEvents, setPlanUnusualEvents,
        planAuthorizedServices, setPlanAuthorizedServices,
        clientID,
	}), [patient, activeSteps, newPlan, clientID, planSupportAreas, planDesiredOutcomes, planTasks, planSuggestedActions, planRoutines, planUnusualEvents, planAuthorizedServices]);

    useLayoutEffect(() => {
        console.log("Client ID");
        console.log(clientID);

        const fetchPatient = async () => {
            var client = await getClient(clientID, appContext.handleAPICallError);
            console.log(client);
            setPatient(client);
            setClientLoading(false);
        }

        fetchPatient()
            .catch(console.error);

        //intializeTestPlan();
        
    },[])

    const intializeTestPlan = () => {
        var newTestPlan: ServicePlan = {
            servicePlanID: uuidv4(),
            planID: "",
            patientID: "",
            waiverType: "SELF",
            fiscalYear: "2023",
            status: "",
            startDate: new Date("04-01-2023"),
            displayStartDate: "",
            endDate: new Date("05-06-2023"),
            displayEndDate: "",
            aboutClient: "Client Info",
            authorizedServices: [],
            supportAreas: [],
            desiredOutcomes: [],
            remoteSupportEvents: [],
            suggestedActions: [],
            remoteSupportRoutines: [],
            unusualEvents: [],
        }

        setNewPlan(newTestPlan);


        var area1Services: PersonalService[] = [];

        var newService: PersonalService = {
            serviceTitle: "Drive To Doctor",
            //serviceDetails: service.serviceDetails,
            frequency: "Daily",
            responsibleParty: "Provider",
            personalServiceID: uuidv4(),
            supportAreaID: "",
            serviceDetails: "",
            lastCompletedOn: "",
            lastCompletedBy: ""
        }
        area1Services.push(newService);

        var newArea1: SupportArea = {
                supportTitle: "Transportation",
                //supportType: values.supportType,
                supportDetails: "Help Client get to doctor appointments",
                personalServices: area1Services,
                supportAreaID: uuidv4(),
                servicePlanID: "",
                supportType: ""
        }

        var area2Services: PersonalService[] = [];

        var newService2: PersonalService = {
            serviceTitle: "Service 1",
            //serviceDetails: service.serviceDetails,
            frequency: "Daily",
            responsibleParty: "Provider",
            personalServiceID: uuidv4(),
            supportAreaID: "",
            serviceDetails: "",
            lastCompletedOn: "",
            lastCompletedBy: ""
        }
        area2Services.push(newService2);

        var newArea2: SupportArea = {
            supportTitle: "Area 2",
            //supportType: values.supportType,
            supportDetails: "Test Area 2",
            personalServices: area2Services,
            supportAreaID: uuidv4(),
            servicePlanID: "",
            supportType: ""
        }

        var supportAreas = [newArea1, newArea2];
        setPlanSupportAreas(supportAreas);

        var newAction1: SuggestedAction = {
            actionTitle: "Action 1",
            actionDetails: "Action 1 Details",
            suggestedActionID: uuidv4(),
            servicePlanID: ""
        }

        var newAction2: SuggestedAction = {
            actionTitle: "Action 2",
            actionDetails: "Action 2 Details",
            suggestedActionID: uuidv4(),
            servicePlanID: ""
        }

        var suggestedActions = [newAction1, newAction2];
        setPlanSuggestedActions(suggestedActions);

        var outcome1Steps: ActionStep[] = [];
        var outcome1ExpectedOutcomes: ExpectedOutcome[] = [];

        var newStep1: ActionStep = {
            actionStepID: uuidv4(),
            desiredOutcomeID: "",
            actionStepTitle: "Step 1",
            actionStepDetails: "Step 1 Details",
            responsibleParty: "Provider",
            frequency: "Daily"
        }
        var newExpectedOutcome1: ExpectedOutcome = {
            expectedOutcomeID: uuidv4(),
            desiredOutcomeID: "",
            expectedOutcomeDescription: "Sample Expected Outcome 1"
        }

        outcome1Steps.push(newStep1);
        outcome1ExpectedOutcomes.push(newExpectedOutcome1);

        var newOutcome1: DesiredOutcome = {
            desiredOutcomeID: uuidv4(),
            servicePlanID: "",
            outcomeTitle: "Outcome 1",
            outcomeDetails: "Outcome 1 Details",
            outcomeProgress: "Outcome 1 Progress",
            actionSteps: outcome1Steps,
            expectedOutcomes: outcome1ExpectedOutcomes
        }

        var outcome2Steps: ActionStep[] = [];
        var outcome2ExpectedOutcomes: ExpectedOutcome[] = [];

        var newStep2: ActionStep = {
            actionStepID: uuidv4(),
            desiredOutcomeID: "",
            actionStepTitle: "Step 2",
            actionStepDetails: "Step 2 Details",
            responsibleParty: "Provider",
            frequency: "Daily"
        }
        var newExpectedOutcome2: ExpectedOutcome = {
            expectedOutcomeID: uuidv4(),
            desiredOutcomeID: "",
            expectedOutcomeDescription: "Sample Expected Outcome 2"
        }

        outcome2Steps.push(newStep2);
        outcome2ExpectedOutcomes.push(newExpectedOutcome2);

        var newOutcome2: DesiredOutcome = {
            desiredOutcomeID: uuidv4(),
            servicePlanID: "",
            outcomeTitle: "Outcome 2",
            outcomeDetails: "Outcome 2 Details",
            outcomeProgress: "Outcome 2 Progress",
            actionSteps: outcome2Steps,
            expectedOutcomes: outcome2ExpectedOutcomes
        }

        var desiredOutcomes = [newOutcome1, newOutcome2];
        setPlanDesiredOutcomes(desiredOutcomes);

        var newTask1: RemoteSupportEvent = {
            eventTitle: "Task 1",
            eventDetails: "Task 1 Details",
            timeOfDay: "5:30 AM",
            repeatsMonday: true,
            repeatsTuesday: false,
            repeatsWednesday: true,
            repeatsThursday: false,
            repeatsFriday: true,
            repeatsSaturday: false,
            repeatsSunday: true,
            preEventReminder: 5,
            postEventReminder: 5,
            remoteSupportEventID: uuidv4(),
            servicePlanID: "",
            responsibleParty: ""
        };

        var newTask2: RemoteSupportEvent = {
            eventTitle: "Task 2",
            eventDetails: "Task 2 Details",
            timeOfDay: "7:30 AM",
            repeatsMonday: false,
            repeatsTuesday: true,
            repeatsWednesday: true,
            repeatsThursday: false,
            repeatsFriday: false,
            repeatsSaturday: true,
            repeatsSunday: false,
            preEventReminder: 5,
            postEventReminder: 5,
            remoteSupportEventID: uuidv4(),
            servicePlanID: "",
            responsibleParty: ""
        };

        var tasks = [newTask1, newTask2];
        setPlanTasks(tasks);

        var routineEvents: RoutineEvent[] = [];

        var newRoutineEvent1: RoutineEvent = {
            eventTitle: "Routine Event 1",
            eventDetails: "Routine Event 1 Details",
            responsibleParty: "",
            timeOfDay: "5:30 AM",
            preEventReminder: 5,
            postEventReminder: 5,
            routineEventID: uuidv4(),
            remoteSupportRoutineID: ""
        }
        routineEvents.push(newRoutineEvent1);
        var newRoutineEvent2: RoutineEvent = {
            eventTitle: "Routine Event 2",
            eventDetails: "Routine Event 2 Details",
            responsibleParty: "",
            timeOfDay: "6:30 AM",
            preEventReminder: 5,
            postEventReminder: 5,
            routineEventID: uuidv4(),
            remoteSupportRoutineID: ""
        }
        routineEvents.push(newRoutineEvent2);

        var newRoutine1: RemoteSupportRoutine = {
                remoteSupportRoutineID: uuidv4(),
                servicePlanID: "",
                routineTitle: "Routine 1",
                routineDetails: "Routine 1 Details",
                routineStart: "5:00 AM",
                routineEnd: "7:00 AM",
                repeatsMonday: true,
                repeatsTuesday: false,
                repeatsWednesday: true,
                repeatsThursday:  false,
                repeatsFriday:  true,
                repeatsSaturday: false,
                repeatsSunday: true,
                events: routineEvents
        }

        var routineEvents2: RoutineEvent[] = [];

        var newRoutineEvent1: RoutineEvent = {
            eventTitle: "Routine Event 1",
            eventDetails: "Routine Event 1 Details",
            responsibleParty: "",
            timeOfDay: "6:30 AM",
            preEventReminder: 5,
            postEventReminder: 5,
            routineEventID: uuidv4(),
            remoteSupportRoutineID: ""
        }
        routineEvents2.push(newRoutineEvent1);
        var newRoutineEvent1: RoutineEvent = {
            eventTitle: "Routine Event 2",
            eventDetails: "Routine Event 2 Details",
            responsibleParty: "",
            timeOfDay: "7:30 AM",
            preEventReminder: 5,
            postEventReminder: 5,
            routineEventID: uuidv4(),
            remoteSupportRoutineID: ""
        }
        routineEvents2.push(newRoutineEvent2);

        var newRoutine2: RemoteSupportRoutine = {
            remoteSupportRoutineID: uuidv4(),
            servicePlanID: "",
            routineTitle: "Routine 2",
            routineDetails: "Routine 2 Details",
            routineStart: "6:00 AM",
            routineEnd: "8:00 AM",
            repeatsMonday: false,
            repeatsTuesday: true,
            repeatsWednesday: true,
            repeatsThursday:  false,
            repeatsFriday:  false,
            repeatsSaturday: true,
            repeatsSunday: false,
            events: routineEvents2
        }

        var routines = [newRoutine1, newRoutine2];
        setPlanRoutines(routines);

        var event1Actions: UnusualEventAction[] = [];
        var newEventAction1: UnusualEventAction = {
            unusualEventActionID: uuidv4(),
            unusualEventID: "",
            actionDetails: "Action 1 Details",
        }
        event1Actions.push(newEventAction1);

        var newEvent1: UnusualEvent = {
                unusualEventID: uuidv4(),
                servicePlanID: "",
                eventTitle: "Event 1",
                eventDetails: "Event 1 Details",
                eventActions: event1Actions, 
        }

        var event2Actions: UnusualEventAction[] = [];
        var newEventAction2: UnusualEventAction = {
            unusualEventActionID: uuidv4(),
            unusualEventID: "",
            actionDetails: "Action 2 Details",
        }
        event2Actions.push(newEventAction2);

        var newEvent2: UnusualEvent = {
                unusualEventID: uuidv4(),
                servicePlanID: "",
                eventTitle: "Event 2",
                eventDetails: "Event 2 Details",
                eventActions: event2Actions, 
        }

        var events = [newEvent1, newEvent2];
        setPlanUnusualEvents(events);

        var newAuthorizedService1: AuthorizedService = {
            authorizedServiceID: uuidv4(),
            servicePlanID: "",
            serviceTitle: "Home personal care",
            serviceCode: "APC",
            staffSize: 1,
            groupSize: 1,
            authorizedBudget: 12434.12,
            utilizedBudget: 0,
            authorizedUnits: 100,
            utilizedUnits: 0,
            budgetStartDate: moment("2023-04-01").toDate().toISOString().substring(0,10),
            budgetEndDate: moment("2023-05-06").toDate().toISOString().substring(0,10),
            evvServiceID: "",
            evvServiceName: "",
            payer: "",
            payerProgram: "",
            procedureCode: "",
            displayStartDate: moment("2023-04-01").format("MM/DD/YYYY"),
            displayEndDate: moment("2023-05-06").format("MM/DD/YYYY"),
        }

        var newAuthorizedService2: AuthorizedService = {
            authorizedServiceID: uuidv4(),
            servicePlanID: "",
            serviceTitle: "Remote Support",
            serviceCode: "AMR",
            staffSize: 1,
            groupSize: 1,
            authorizedBudget: 12434.12,
            utilizedBudget: 0,
            authorizedUnits: 150,
            utilizedUnits: 0,
            budgetStartDate: moment("2023-04-01").toDate().toISOString().substring(0,10),
            budgetEndDate: moment("2023-05-06").toDate().toISOString().substring(0,10),
            evvServiceID: "",
            evvServiceName: "",
            payer: "",
            payerProgram: "",
            procedureCode: "",
            displayStartDate: moment("2023-04-01").format("MM/DD/YYYY"),
            displayEndDate: moment("2023-05-06").format("MM/DD/YYYY"),
        }

        var authorizedServices = [newAuthorizedService1, newAuthorizedService2];
        setPlanAuthorizedServices(authorizedServices);

    }

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
    
        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
        };
      }, []);

      function handleBeforeUnload(event: BeforeUnloadEvent) {
        // Perform any necessary cleanup or show a confirmation dialog
        // The message you return here will be shown in the confirmation dialog
        event.preventDefault();
        event.returnValue = "Any changes you made will not be saved. Are you sure you want to leave?";
      }

    // const toggleAuthorizedSupportCard = () => {
    //     setShowAuthorizedSupportCard(!showAuthorizedSupportCard);
    // }

    const goToNewPlan = () => {
        setActiveSteps([
            { id: '1', name: 'New Plan', href: '#', status: 'current' },
            { id: '2', name: 'Service Details', href: '#', status: 'upcoming' },
            { id: '3', name: 'Authorization', href: '#', status: 'upcoming' },
            { id: '4', name: 'Review', href: '#', status: 'upcoming' },
          ]);
    }

    const goToServiceDetails = () => {
        setActiveSteps([
            { id: '1', name: 'New Plan', href: '#', status: 'complete' },
            { id: '2', name: 'Service Details', href: '#', status: 'current' },
            { id: '3', name: 'Authorization', href: '#', status: 'upcoming' },
            { id: '4', name: 'Review', href: '#', status: 'upcoming' },
            ]);
    }

    const goToAuthorization = () => {
        setActiveSteps([
            { id: '1', name: 'New Plan', href: '#', status: 'complete' },
            { id: '2', name: 'Service Details', href: '#', status: 'complete' },
            { id: '3', name: 'Authorization', href: '#', status: 'current' },
            { id: '4', name: 'Review', href: '#', status: 'upcoming' },
            ]);
    }

    const goToReview = () => {
        setActiveSteps([
            { id: '1', name: 'New Plan', href: '#', status: 'complete' },
            { id: '2', name: 'Service Details', href: '#', status: 'complete' },
            { id: '3', name: 'Authorization', href: '#', status: 'complete' },
            { id: '4', name: 'Review', href: '#', status: 'current' },
            ]);
    }

    return (<>
        <NewPlanContext.Provider value={value}>
            <div className="tw-px-xl tw-py-xl">
                { clientLoading ? <>
                    <div className="tw-flex tw-justify-center tw-m-md">
                                    <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                    </div>
                </> :
                <>
                <div className="tw-pb-5">
                    <nav className="tw-flex" aria-label="Breadcrumb">
                        <ol role="list" className="tw-flex tw-items-center tw-space-x-4">
                                <li key='Clients'>
                                    <div className="tw-flex tw-items-center">
                                    <a href="/client/client-management"
                                        className="tw-ml-4 tw-text-lg tw-font-medium tw-text-gray-500 hover:tw-text-gray-700"
                                    >
                                        Clients
                                    </a>
                                    </div>
                                </li>
                                <li key='ClientID'>
                                    <div className="tw-flex tw-items-center">
                                    <ChevronRightIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-500" aria-hidden="true" />
                                    <a href={`/client/${clientID}`}
                                        className="tw-ml-4 tw-text-lg tw-font-medium tw-text-gray-500 hover:tw-text-gray-700"
                                    >
                                        {patient?.firstName} {patient?.lastName.charAt(0)}.
                                    </a>
                                    </div>
                                </li>
                                <li key='ClientID'>
                                    <div className="tw-flex tw-items-center">
                                    <ChevronRightIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-900" aria-hidden="true" />
                                    <a
                                        className="tw-ml-4 tw-text-lg tw-font-semibold tw-text-gray-900 hover:tw-text-gray-700"
                                    >
                                        New Service Plan
                                    </a>
                                    </div>
                                </li>

                        </ol>
                    </nav>
                </div>

                <nav aria-label="Progress">
                    <ol role="list" className="tw-divide-y tw-divide-gray-300 tw-rounded-md tw-border tw-border-gray-300 md:tw-flex md:tw-divide-y-0">
                        {activeSteps.map((step, stepIdx) => (
                        <li key={step.name} className="tw-relative md:tw-flex md:tw-flex-1">
                            {step.status === 'complete' ? (
                            <div className="tw-group tw-flex tw-w-full tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary group-hover:tw-bg-indigo-800">
                                    <CheckIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">{step.name}</span>
                                </span>
                            </div>
                            ) : step.status === 'current' ? (
                            <div className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium" aria-current="step">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-primary">
                                <span className="tw-text-primary">{step.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-primary">{step.name}</span>
                            </div>
                            ) : (
                            <div className="tw-group tw-flex tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 group-hover:tw-border-gray-400">
                                    <span className="tw-text-gray-500 group-hover:tw-text-gray-900">{step.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-500 group-hover:tw-text-gray-900">{step.name}</span>
                                </span>
                            </div>
                            )}

                            {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div className="tw-absolute tw-top-0 tw-right-0 tw-hidden tw-h-full tw-w-5 md:tw-block" aria-hidden="true">
                                <svg
                                    className="tw-h-full tw-w-full tw-text-gray-300"
                                    viewBox="0 0 22 80"
                                    fill="none"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                    d="M0 -2L20 40L0 82"
                                    vectorEffect="non-scaling-stroke"
                                    stroke="currentcolor"
                                    strokeLinejoin="round"
                                    />
                                </svg>
                                </div>
                            </>
                            ) : null}
                        </li>
                        ))}
                    </ol>
                </nav>

                {activeSteps[0].status === 'current' && 
                    <div className="tw-space-y-6 tw-mt-4">
                        <NewPlanSection></NewPlanSection>
                    </div>
                }

                {activeSteps[1].status === 'current' &&
                <div className="tw-space-y-6 tw-mt-4">

                    <NewPlanBanner></NewPlanBanner>
                    

                    <div className="tw-p-6">
                            {/* <div className="tw-pb-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-primary tw-flex-grow">Direct Support</span>
                            </div> */}

                            <div className="tw-py-2 tw-flex tw-px-4 tw-cursor-pointer" onClick={toggleSupportAreaSection}>
                                <span className={`tw-text-base tw-font-semibold tw-leading-6 ${serviceDetailsSections.showSupportAreaSection ? 'tw-text-primary' : 'tw-text-gray-900'} tw-flex-grow`}>Support Areas - {planSupportAreas.length}</span>
                                {serviceDetailsSections.showSupportAreaSection ?
                                <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" /> :
                                <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                            </div>

                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                            {serviceDetailsSections.showSupportAreaSection && <NewSupportAreaSection></NewSupportAreaSection>}
                            
                            <div className="tw-py-2 tw-flex tw-px-4 tw-cursor-pointer" onClick={toggleGoalSection}>
                                <span className={`tw-text-base tw-font-semibold tw-leading-6 ${serviceDetailsSections.showGoalSection ? 'tw-text-primary' : 'tw-text-gray-900'} tw-flex-grow`}>Desired Outcomes - {planDesiredOutcomes.length}</span>
                                {serviceDetailsSections.showGoalSection ?
                                <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" /> :
                                <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                            </div>

                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                            {serviceDetailsSections.showGoalSection && <NewDesiredOutcomeSection></NewDesiredOutcomeSection>}
                        

                            {/* <div className="tw-py-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-primary tw-flex-grow">Remote Support</span>
                            </div> */}

                            <div className="tw-py-2 tw-flex tw-px-4 tw-cursor-pointer" onClick={toggleTaskSection}>
                                <span className={`tw-text-base tw-font-semibold tw-leading-6 ${serviceDetailsSections.showTaskSection ? 'tw-text-primary' : 'tw-text-gray-900'} tw-flex-grow`}>Tasks - {planTasks.length}</span>
                                {serviceDetailsSections.showTaskSection ?
                                <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" /> :
                                <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                            </div>

                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                            {serviceDetailsSections.showTaskSection && <NewTaskSection></NewTaskSection>}

                            <div className="tw-py-2 tw-flex tw-px-4 tw-cursor-pointer" onClick={toggleRoutineSection}>
                                <span className={`tw-text-base tw-font-semibold tw-leading-6 ${serviceDetailsSections.showRoutineSection ? 'tw-text-primary' : 'tw-text-gray-900'} tw-flex-grow`}>Routines - {planRoutines.length}</span>
                                {serviceDetailsSections.showRoutineSection ?
                                <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" /> :
                                <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                            </div>

                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                            {serviceDetailsSections.showRoutineSection && <NewRoutineSection></NewRoutineSection>}

                            <div className="tw-py-2 tw-flex tw-px-4 tw-cursor-pointer" onClick={toggleSuggestedActionSection}>
                                <span className={`tw-text-base tw-font-semibold tw-leading-6 ${serviceDetailsSections.showSuggestedActionSection ? 'tw-text-primary' : 'tw-text-gray-900'} tw-flex-grow`}>Suggested Actions - {planSuggestedActions.length}</span>
                                {serviceDetailsSections.showSuggestedActionSection ?
                                <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" /> :
                                <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                            </div>

                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                            {serviceDetailsSections.showSuggestedActionSection && <NewSuggestedActionSection></NewSuggestedActionSection>}

                            <div className="tw-py-2 tw-flex tw-px-4 tw-cursor-pointer" onClick={toggleUnusualEventSection}>
                                <span className={`tw-text-base tw-font-semibold tw-leading-6 ${serviceDetailsSections.showUnusualEventSection ? 'tw-text-primary' : 'tw-text-gray-900'} tw-flex-grow`}>Unusual Events - {planUnusualEvents.length}</span>
                                {serviceDetailsSections.showUnusualEventSection ?
                                <ChevronUpIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" /> :
                                <ChevronDownIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary" aria-hidden="true" />}
                            </div>

                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                            {serviceDetailsSections.showUnusualEventSection && <NewUnusualEventSection></NewUnusualEventSection>}

                            <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={goToNewPlan}>
                                            Back
                                    </ButtonSecondary>
                                    <ButtonPrimary className="tw-mt-md" onClick={goToAuthorization}>
                                        Next
                                    </ButtonPrimary>

                        </div>
                    </div>
                </div>
                }

                {activeSteps[2].status === 'current' &&
                <div className="tw-space-y-6 tw-mt-4">
                    <NewPlanBanner></NewPlanBanner>

                    <div className="tw-p-6">

                            <div className="tw-pb-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-primary tw-flex-grow">Authorized Services - {planAuthorizedServices.length}</span>
                            </div>

                            <NewAuthorizedSection></NewAuthorizedSection>
                            

                            <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={goToServiceDetails}>
                                            Back
                                    </ButtonSecondary>
                                    <ButtonPrimary className="tw-mt-md" onClick={goToReview}>
                                        Next
                                    </ButtonPrimary>

                        </div>
                    </div>
                </div>
                }

                {activeSteps[3].status === 'current' &&
                <div className="tw-space-y-6 tw-mt-4">
                    <NewPlanBanner></NewPlanBanner>

                    <div className="tw-p-6">
                        {/* <div className="tw-pb-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-primary tw-flex-grow">Direct Support</span>
                        </div> */}

                        <div className="tw-pb-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Support Areas - {planSupportAreas.length}</span>

                                <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={goToSupportAreaSection} />
                        </div>

                        {/* {planSupportAreas.length > 0 && <EditSupportAreaList areas={planSupportAreas}></EditSupportAreaList>} */}
                        

                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planSupportAreas.length > 0 && planSupportAreas.map((supportArea, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewSupportAreaSection area={supportArea}></ViewSupportAreaSection>
                                </div>

                            ))}
                        </div>

                        <div className="tw-py-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Desired Outcomes - {planDesiredOutcomes.length}</span>
                                <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={goToGoalSection} />
                        </div>

                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planDesiredOutcomes.length > 0 && planDesiredOutcomes.map((outcome, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewDesiredOutcomeSection outcome={outcome}></ViewDesiredOutcomeSection>
                                </div>
                            ))}
                        </div>

                        {/* <div className="tw-py-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-primary tw-flex-grow">Remote Support</span>
                        </div> */}

                        <div className="tw-py-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Tasks - {planTasks.length}</span>
                                <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={goToTaskSection} />
                        </div>

                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planTasks.length > 0 && planTasks.map((task, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewRemoteSupportTaskSection task={task}></ViewRemoteSupportTaskSection>
                                </div>
                            ))}
                        </div>

                        <div className="tw-py-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Routines - {planRoutines.length}</span>
                                <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={goToRoutineSection} />
                        </div>

                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planRoutines.length > 0 && planRoutines.map((routine, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewRemoteSupportRoutine routine={routine}></ViewRemoteSupportRoutine>
                                </div>
                            ))}
                        </div>

                        <div className="tw-py-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Suggested Actions - {planSuggestedActions.length}</span>
                                <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={goToSuggestedActionSection} />
                        </div>

                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planSuggestedActions.length > 0 && planSuggestedActions.map((action, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow" key={action.suggestedActionID}>
                                    <ViewSuggestedActionSection action={action}></ViewSuggestedActionSection>
                                </div>
                            ))}
                        </div>

                        <div className="tw-py-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Unusual Events - {planUnusualEvents.length}</span>
                                <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={goToUnusualEventSection} />
                        </div>

                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planUnusualEvents.length > 0 && planUnusualEvents.map((event, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewUnusualEventSection event={event}></ViewUnusualEventSection>
                                </div>
                            ))}
                        </div>

                        <div className="tw-py-3 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Authorized Services - {planAuthorizedServices.length}</span>
                                <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={goToAuthorization} />
                        </div>

                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                            {planAuthorizedServices.length > 0 && planAuthorizedServices.map((service, index) => (
                                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow">
                                    <ViewAuthorizedServiceSection service={service}></ViewAuthorizedServiceSection>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={goToAuthorization}>
                                            Back
                                    </ButtonSecondary>
                                    <ButtonPrimary className="tw-mt-md" onClick={saveServicePlan}>
                                        Save
                                    </ButtonPrimary>

                        </div>
                </div>
                }
                </>
                }
                

                
            </div>
        </NewPlanContext.Provider>
    </>);

}

export default NewServicePlanPage;