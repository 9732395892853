import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import moment from 'moment'
import { Listbox } from '@headlessui/react'
import React from 'react'
import { useAppContext } from '../../Context'
import { getActiveClients, getActiveDSClients, getAllOpenDSEventsForSpan, getClientDirectSupportTeam } from '../../apicalls/schedulingService'
import CoverageEvent from '../../types/coverageEvent'
import { LoadingSpinner } from '../../components/portalComponents/loadingSpinner'
import Patient from '../../types/patient'
import { Block, ButtonSecondary } from '@medforall/medforall-component-library'
import { Input, Select } from "../../components/forms/formFields";
import Button, { ButtonPrimary } from "../../components/buttons/Buttons";
import ClientDSTeamPanel from '../../components/panels/ClientDSTeamPanel'

const options = [
    { value: "fox", label: "🦊 Fox" },
    { value: "Butterfly", label: "🦋 Butterfly" },
    { value: "Honeybee", label: "🐝 Honeybee" }
];

const people = [
    { id: 1, name: 'Durward Reynolds' },
    { id: 2, name: 'Kenton Towne' },
    { id: 3, name: 'Therese Wunsch' },
    { id: 4, name: 'Benedict Kessler' },
    { id: 5, name: 'Katelyn Rohan' },
  ]
  

const meetings = [
  {
    id: 1,
    date: 'July 6th, 2023',
    datetime: '2023-07-06',
    startTime: '5:00 PM',
    endTime: '7:00 PM',
    name: 'Leslie Alexander',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    location: '875 North High Street, Suite 300, Columbus, OH 43215',
  },
  {
    id: 2,
    date: 'July 6th, 2023',
    datetime: '2023-07-06',
    startTime: '5:00 PM',
    endTime: '7:00 PM',
    name: 'Michael Foster',
    imageUrl:
        'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    location: '875 North High Street, Suite 300, Columbus, OH 43215',
  },
  // More meetings...
]
// const days = [
//   { date: '2021-12-27' },
//   { date: '2021-12-28' },
//   { date: '2021-12-29' },
//   { date: '2021-12-30' },
//   { date: '2021-12-31' },
//   { date: '2022-01-01', isCurrentMonth: true },
//   { date: '2022-01-02', isCurrentMonth: true },
//   { date: '2022-01-03', isCurrentMonth: true },
//   { date: '2022-01-04', isCurrentMonth: true },
//   { date: '2022-01-05', isCurrentMonth: true },
//   { date: '2022-01-06', isCurrentMonth: true },
//   { date: '2022-01-07', isCurrentMonth: true },
//   { date: '2022-01-08', isCurrentMonth: true },
//   { date: '2022-01-09', isCurrentMonth: true },
//   { date: '2022-01-10', isCurrentMonth: true },
//   { date: '2022-01-11', isCurrentMonth: true },
//   { date: '2022-01-12', isCurrentMonth: true, isToday: true },
//   { date: '2022-01-13', isCurrentMonth: true },
//   { date: '2022-01-14', isCurrentMonth: true },
//   { date: '2022-01-15', isCurrentMonth: true },
//   { date: '2022-01-16', isCurrentMonth: true },
//   { date: '2022-01-17', isCurrentMonth: true },
//   { date: '2022-01-18', isCurrentMonth: true },
//   { date: '2022-01-19', isCurrentMonth: true },
//   { date: '2022-01-20', isCurrentMonth: true },
//   { date: '2022-01-21', isCurrentMonth: true },
//   { date: '2022-01-22', isCurrentMonth: true, isSelected: true },
//   { date: '2022-01-23', isCurrentMonth: true },
//   { date: '2022-01-24', isCurrentMonth: true },
//   { date: '2022-01-25', isCurrentMonth: true },
//   { date: '2022-01-26', isCurrentMonth: true },
//   { date: '2022-01-27', isCurrentMonth: true },
//   { date: '2022-01-28', isCurrentMonth: true },
//   { date: '2022-01-29', isCurrentMonth: true },
//   { date: '2022-01-30', isCurrentMonth: true },
//   { date: '2022-01-31', isCurrentMonth: true },
//   { date: '2022-02-01' },
//   { date: '2022-02-02' },
//   { date: '2022-02-03' },
//   { date: '2022-02-04' },
//   { date: '2022-02-05' },
//   { date: '2022-02-06' },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function getCalendarDates(year: number, month: number):string[] {
    // Get the first day of the month
    let date = moment({ year: year, month: month, day: 1 });

    console.log(date);
    
    // Get the day of the week for the first day (0 is Sunday, 6 is Saturday)
    let day = date.day();

    console.log(day);
    
    // Subtract the day number from the date to get the Sunday before the first day
    date.subtract(day, 'days');
    
    let dates: string[] = [];
    
    // Loop for 6 weeks which will cover all possible scenarios (4 weeks for current month, 1 for previous and 1 for next month)
    for (let i = 0; i < 42; i++) {
        // Push a clone of the moment object so we're not modifying the same date
        dates.push(date.format('YYYY-MM-DD'));
        
        // Move to the next day
        date.add(1, 'days');
    }
    
    return dates;
}

function getMonthNameFromNumber(monthNumber: number): string {
    // Subtract 1 because months are 0-based in JavaScript
    let monthName = moment([2023, monthNumber - 1]).format('MMMM');
    return monthName;
}

function StaffCalendar() {
    const [selectedPeople, setSelectedPeople] = useState([])
    const [days, setDays] = useState<any>([]);
    const patientRef = useRef() || { current: { value: ''}};
    const [selectedPatient, setSelectedPatient] = useState("");
    const [filterDays, setFilteredDays] = useState<any>([]);
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [selectedDateIndex, setSelectedDateIndex] = useState<any>(null);
    const [selectedMonth, setSelectedMonth] = useState<any>(null);
    const [selectedYear, setSelectedYear] = useState<any>(null);
    const [currentStartDate, setCurrentStartDate] = useState<any>(null);
    const [currentEndDate, setCurrentEndDate] = useState<any>(null);
    const [dataLoading, setDataLoading] = useState<any>(false);
    const [selectedDateShifts, setSelectedDateShifts] = useState<CoverageEvent[]>([]);
    const [activePatients, setActivePatients] = useState<Patient[]>([]);
    const [availablePatients, setAvailablePatients] = useState<Patient[]>([]);
    const [filterPatients, setFilterPatients] = useState<Patient[]>([]);

    const appContext = useAppContext();

    useLayoutEffect(() => {
        setDataLoading(true);

        let currentMonth = moment().month(); // This will return a zero-based month (0 for January, 11 for December)
        let currentYear = moment().year();

        console.log(`Current Month: ${currentMonth + 1}`); // To get a one-based month, add 1 to the result
        console.log(`Current Year: ${currentYear}`);

        setSelectedMonth(currentMonth + 1);
        setSelectedYear(currentYear);

        let currentDates = getCalendarDates(currentYear, currentMonth);

        console.log(currentDates);

        const fetchClients = async () => {
          const data = await getActiveDSClients(appContext.handleAPICallError);
          console.log(data);
          const activePatients = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName));
          setActivePatients(activePatients);
          setAvailablePatients(activePatients);
        }

       

        setCurrentStartDate(currentDates[0]);
        setCurrentEndDate(currentDates[currentDates.length - 1]);

        // fetch open shifts
        const fetchOpenShifts = async () => {
          console.log("Fetching Open Shifts...");
          console.log(`Start Date: ${currentDates[0]}`);
          console.log(`End Date: ${currentDates[currentDates.length - 1]}`);

          await fetchClients();

          var agencyOpenShifts = await getAllOpenDSEventsForSpan(currentDates[0], currentDates[currentDates.length - 1], appContext.handleAPICallError);
          
          console.log("Agency Open Shifts:");

          console.log(agencyOpenShifts);

          let formattedDates = currentDates.map((date: string) => {
              var openShiftsForDate = agencyOpenShifts.filter((shift: CoverageEvent) => {
                return moment(shift.displayCoverageDate).isSame(moment(date), 'day');
              });

              return {
                  date: date,
                  isCurrentMonth: moment(date).month() === currentMonth,
                  isToday: moment(date).isSame(moment(), 'day'),
                  displayDate: moment(date).format('MMMM Do YYYY'),
                  isSelected: moment(date).isSame(moment(), 'day') ? true : false,
                  hasOpenShifts: moment(date).isBefore(moment(), 'day') ? false : openShiftsForDate.length > 0,
                  openShifts: moment(date).isBefore(moment(), 'day') ? [] : openShiftsForDate,
                  openShiftsCount: moment(date).isBefore(moment(), 'day') ? 0 : openShiftsForDate.length
              }
              
            });

            var selectedDay = formattedDates.find((date: any) => date.isSelected);
            if(selectedDay) {
              setSelectedDateShifts(selectedDay.openShifts);
              setSelectedDateIndex(formattedDates.findIndex((date: any) => date.isSelected));
              setSelectedDate(selectedDay.date);
            } else {
              formattedDates[0].isSelected = true;
              setSelectedDateShifts([]);
              setSelectedDateIndex(0);
              setSelectedDate(formattedDates[0].date);
            }

            console.log(formattedDates);

            setDays(JSON.parse(JSON.stringify(formattedDates)));
            setFilteredDays(JSON.parse(JSON.stringify(formattedDates)));

            setDataLoading(false);

        };

        fetchOpenShifts().catch(console.error);

    }, []);

    const handlePreviousMonth = async () => {  
      setDataLoading(true);
      
        let previousMonth = moment([selectedYear, selectedMonth - 2]); // Subtract 2 because months are 0-based in JavaScript

        if(selectedMonth === 1) {
            previousMonth = moment([selectedYear - 1, 11]); // Subtract 1 from the year and set the month to December
        }

        console.log(`Previous Month: ${previousMonth.month() + 1}`); // To get a one-based month, add 1 to the result
        console.log(`Previous Year: ${previousMonth.year()}`);

        setSelectedMonth(previousMonth.month() + 1);
        setSelectedYear(previousMonth.year());

        let previousDates = getCalendarDates(previousMonth.year(), previousMonth.month());

        console.log(previousDates);

        console.log("Fetching Open Shifts...");
          console.log(`Start Date: ${previousDates[0]}`);
          console.log(`End Date: ${previousDates[previousDates.length - 1]}`);

          var agencyOpenShifts = await getAllOpenDSEventsForSpan(previousDates[0], previousDates[previousDates.length - 1], appContext.handleAPICallError);
          
          console.log("Agency Open Shifts:");

          console.log(agencyOpenShifts);

        setSelectedYear(previousMonth.year());

        let formattedDates = previousDates.map((date: string) => {
          var openShiftsForDate = agencyOpenShifts.filter((shift: CoverageEvent) => {
            return moment(shift.displayCoverageDate).isSame(moment(date), 'day');
          });

          return {
              date: date,
              isCurrentMonth: moment(date).month() === previousMonth.month(),
              isToday: moment(date).isSame(moment(), 'day'),
              displayDate: moment(date).format('MMMM Do YYYY'),
              isSelected: moment(date).isSame(moment(), 'day') ? true : false,
              hasOpenShifts: moment(date).isBefore(moment(), 'day') ? false : openShiftsForDate.length > 0,
              openShifts: moment(date).isBefore(moment(), 'day') ? [] : openShiftsForDate,
              openShiftsCount: moment(date).isBefore(moment(), 'day') ? 0 : openShiftsForDate.length
          }
          
        });

        var selectedDay = formattedDates.find((date: any) => date.isSelected);
        if(selectedDay) {
          setSelectedDateShifts(selectedDay.openShifts);
          setSelectedDateIndex(formattedDates.findIndex((date: any) => date.isSelected));
          setSelectedDate(selectedDay.date);
        } else {
          formattedDates[0].isSelected = true;
          setSelectedDateShifts([]);
          setSelectedDateIndex(0);
          setSelectedDate(formattedDates[0].date);
        }

        console.log(formattedDates);

        setDays(JSON.parse(JSON.stringify(formattedDates)));
        setFilteredDays(JSON.parse(JSON.stringify(formattedDates)));

        setDataLoading(false);
    }

    const handleNextMonth = async () => {
        setDataLoading(true);

        let nextMonth = moment([selectedYear, selectedMonth]); // Don't subtract 1 here because we want the first day of the next month

        if(selectedMonth === 12) {
            nextMonth = moment([selectedYear + 1, 0]); // Add 1 to the year and set the month to January
        }

        console.log(`Next Month: ${nextMonth.month() + 1}`); // To get a one-based month, add 1 to the result

        setSelectedMonth(nextMonth.month() + 1);

        let nextDates = getCalendarDates(nextMonth.year(), nextMonth.month());

        console.log(nextDates);

          console.log("Fetching Open Shifts...");
          console.log(`Start Date: ${nextDates[0]}`);
          console.log(`End Date: ${nextDates[nextDates.length - 1]}`);

          var agencyOpenShifts = await getAllOpenDSEventsForSpan(nextDates[0], nextDates[nextDates.length - 1], appContext.handleAPICallError);
          
          console.log("Agency Open Shifts:");

          console.log(agencyOpenShifts);

        setSelectedYear(nextMonth.year());

        let formattedDates = nextDates.map((date: string) => {
          var openShiftsForDate = agencyOpenShifts.filter((shift: CoverageEvent) => {
            return moment(shift.displayCoverageDate).isSame(moment(date), 'day');
          });

          return {
              date: date,
              isCurrentMonth: moment(date).month() === nextMonth.month(),
              isToday: moment(date).isSame(moment(), 'day'),
              displayDate: moment(date).format('MMMM Do YYYY'),
              isSelected: moment(date).isSame(moment(), 'day') ? true : false,
              hasOpenShifts: moment(date).isBefore(moment(), 'day') ? false : openShiftsForDate.length > 0,
              openShifts: moment(date).isBefore(moment(), 'day') ? [] : openShiftsForDate,
              openShiftsCount: moment(date).isBefore(moment(), 'day') ? 0 : openShiftsForDate.length
          }
          
        });

        var selectedDay = formattedDates.find((date: any) => date.isSelected);
        if(selectedDay) {
          setSelectedDateShifts(selectedDay.openShifts);
          setSelectedDateIndex(formattedDates.findIndex((date: any) => date.isSelected));
          setSelectedDate(selectedDay.date);
        } else {
          formattedDates[0].isSelected = true;
          setSelectedDateShifts([]);
          setSelectedDateIndex(0);
          setSelectedDate(formattedDates[0].date);
        }

        console.log(formattedDates);

        setDays(JSON.parse(JSON.stringify(formattedDates)));
        setFilteredDays(JSON.parse(JSON.stringify(formattedDates)));

        setDataLoading(false);
    }

    const extractInitials = (fullName) => {
      const nameParts = fullName.split(' ');
      const firstNameInitial = nameParts[0][0];
      const lastNameInitial = nameParts[1][0];
      
      return `${firstNameInitial}${lastNameInitial}`;
    }

    const addClientToFilter = () => {
      const client = activePatients.find((patient: Patient) => patient.patientID === selectedPatient);

      console.log(`Adding Client to Filter: ${client.firstName} ${client.lastName}`);
      console.log(client);

      let newFilteredClients = [...filterPatients];
      newFilteredClients.push(client);

      setFilterPatients(newFilteredClients);

      let newAvailablePatients = [...availablePatients];
      newAvailablePatients = newAvailablePatients.filter((availablePatient: Patient) => availablePatient.patientID !== client.patientID);

      setAvailablePatients(newAvailablePatients);

      patientRef.current.value = "";
		  setSelectedPatient(patientRef.current.value);
    }

    const removeClientFromFilter = (client: Patient) => {
      console.log(`Removing Client from Filter: ${client.firstName} ${client.lastName}`);
      console.log(client);

      let newFilteredClients = [...filterPatients];
      newFilteredClients = newFilteredClients.filter((filteredClient: Patient) => filteredClient.patientID !== client.patientID);

      setFilterPatients(newFilteredClients);

      let newAvailablePatients = [...availablePatients];
      newAvailablePatients.push(client);

      setAvailablePatients(newAvailablePatients);
    }

    const clearClientFilter = () => {
      console.log("Clearing Client Filter...");

      setFilterPatients([]);
      setAvailablePatients(activePatients);

      patientRef.current.value = "";
      setSelectedPatient(patientRef.current.value);

      setFilteredDays(days);
    }

    useEffect(() => {
      console.log("Filter Patients Changed...");
      console.log(filterPatients);
      console.log(filterPatients.length);
      if(days.length > 0) {

        if(filterPatients.length === 0) {
          console.log("No Filter Patients. Setting Filtered Days to Days...");
          let filteredDays = JSON.parse(JSON.stringify(days));

          console.log(filteredDays);

          setFilteredDays(filteredDays);
          setSelectedDateShifts(filteredDays[selectedDateIndex].openShifts);
        } else {
          let filteredDays = JSON.parse(JSON.stringify(days));

          filteredDays.forEach((day: any) => {
            if(day.openShifts && day.openShifts.length > 0){
              let filteredOpenShifts = day.openShifts.filter((shift: CoverageEvent) => {
                return filterPatients.some((patient: Patient) => patient.patientID === shift.patientID);
              });
    
              day.openShifts = filteredOpenShifts;
              day.openShiftsCount = filteredOpenShifts.length;
              day.hasOpenShifts = filteredOpenShifts.length > 0;
            }
            
          });

          console.log(filteredDays);


          setFilteredDays(filteredDays);
          setSelectedDateShifts(filteredDays[selectedDateIndex].openShifts);

        }
      }

    }, [filterPatients, days]);



  const showClientTeam = async (shift: CoverageEvent) => {
      const result = await getClientDirectSupportTeam(shift.patientID, appContext.handleAPICallError);
      
      console.log("Client Direct Support Team:");
      console.log(result);

      appContext.setSideNavTheme(3);
      appContext.setPanelSize('tw-max-w-400px');
		  appContext.setSideNavTitle( "Client Direct Support Team" );
      appContext.setSideNavActions(null);
      appContext.setSideNavBottom(null);

      appContext.setSideNav( <ClientDSTeamPanel name={shift.patientName} team={result}/> )
      //TODO: Identify "id" to pass to client  <ClientInfoPanel />

      appContext.openSideNav();
  }

  return (
    <div>
      
      {dataLoading ? <>
        <LoadingSpinner />
      </>:<>
      <div className="lg:tw-grid lg:tw-grid-cols-12 lg:tw-gap-x-16">
        <div className="tw-mt-10 tw-text-center lg:tw-col-start-8 lg:tw-col-end-13 lg:tw-row-start-1 lg:tw-mt-9 xl:tw-col-start-9">
          <div className="tw-flex tw-items-center tw-text-gray-900">
            <button
              type="button"
              className="-tw-m-1.5 tw-flex tw-flex-none tw-items-center tw-justify-center tw-p-1.5 tw-text-gray-400 hover:tw-text-gray-500"
            >
              <span className="tw-sr-only">Previous month</span>
              <ChevronLeftIcon className="tw-h-5 tw-w-5" aria-hidden="true" onClick={handlePreviousMonth} />
            </button>
            <div className="tw-flex-auto tw-text-sm tw-font-semibold">{getMonthNameFromNumber(selectedMonth)} {selectedYear}</div>
            <button
              type="button"
              className="-tw-m-1.5 tw-flex tw-flex-none tw-items-center tw-justify-center tw-p-1.5 tw-text-gray-400 hover:tw-text-gray-500"
            >
              <span className="tw-sr-only">Next month</span>
              <ChevronRightIcon className="tw-h-5 tw-w-5" aria-hidden="true" onClick={handleNextMonth} />
            </button>
          </div>
          <div className="tw-mt-6 tw-grid tw-grid-cols-7 tw-text-xs tw-leading-6 tw-text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>
          <div className="tw-isolate tw-mt-2 tw-grid tw-grid-cols-7 tw-gap-px tw-rounded-lg tw-bg-gray-200 tw-text-sm tw-shadow tw-ring-1 tw-ring-gray-200">
             {filterDays.map((day, dayIdx) => (
              <button
                key={day.date}
                type="button"
                className={classNames(
                  'tw-py-1.5 hover:tw-bg-gray-100 focus:tw-z-10',
                  (day.isCurrentMonth && !day.hasOpenShifts) ? 'tw-bg-white' : 'tw-bg-gray-50',
                  (day.hasOpenShifts && day.openShiftsCount < 3) && 'tw-bg-yellow-200',
                  (day.hasOpenShifts && day.openShiftsCount >= 3) && 'tw-bg-red-300',
                  (day.isSelected || day.isToday) && 'tw-font-semibold',
                  day.isSelected && 'tw-text-white',
                  !day.isSelected && day.isCurrentMonth && !day.isToday && 'tw-text-gray-900',
                  !day.isSelected && !day.isCurrentMonth && !day.isToday && 'tw-text-gray-400',
                  day.isToday && !day.isSelected && 'tw-text-indigo-600',
                  dayIdx === 0 && 'tw-rounded-tl-lg',
                  dayIdx === 6 && 'tw-rounded-tr-lg',
                  dayIdx === days.length - 7 && 'tw-rounded-bl-lg',
                  dayIdx === days.length - 1 && 'tw-rounded-br-lg'
                )}

                onClick={() => {
                    let newDays = [...days];
                    let newDaysFiltered = [...filterDays];
                    newDays[dayIdx].isSelected = !newDays[dayIdx].isSelected;
                    newDaysFiltered[dayIdx].isSelected = !newDaysFiltered[dayIdx].isSelected;
                    if(selectedDateIndex !== null) {
                        newDays[selectedDateIndex].isSelected = false;
                        newDaysFiltered[selectedDateIndex].isSelected = false;
                    }
                    console.log(moment(day.date).format("YYYY-MM-DD"));
                    setSelectedDate(day.date);
                    setSelectedDateIndex(dayIdx);
                    setSelectedDateShifts(day.openShifts);
                    setDays(newDays);
                    setFilteredDays(newDaysFiltered);
                    }
                }
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    'tw-mx-auto tw-flex tw-h-7 tw-w-7 tw-items-center tw-justify-center tw-rounded-full',
                    day.isSelected && day.isToday && 'tw-bg-indigo-600',
                    day.isSelected && !day.isToday && 'tw-bg-gray-900'
                  )}
                >
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
              </button>
            ))}
          </div>
        </div>
        <ol className="tw-mt-4 tw-divide-y tw-divide-gray-100 tw-text-sm tw-leading-6 lg:tw-col-span-7 xl:tw-col-span-8">
          {selectedDate ? <>
            <h2 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Open Shifts - {selectedDateShifts?.length}</h2>
          
          <ul className="tw-space-x-4 tw-flex tw-flex-wrap">
            <li>
              <Block>Filter By Clients: </Block>
              <Select value="Selected Patient" ref={ patientRef }  onChange={ e => {
                      console.log(e, e.target, e.target.value, selectedPatient)
                      console.log(patientRef?.current?.value);
                      setSelectedPatient(patientRef?.current?.value);
                      // if(patientRef?.current?.value === "All") {
                      // 	setSelectedClaimType("New");
                      // }
                      e.preventDefault();
                    }}>
                  {/* <option value="">--Select Client--</option>
                  <option value="All">All</option> */}
                  <option value="">--Select Client--</option>
                  {
                    availablePatients.map(client =>
                      <option value={client.patientID}>{client.firstName} {client.lastName}</option>)
                  }
              </Select>
            </li>
            <li className="tw-mt-1">
              <ButtonPrimary className="tw-mt-md" onClick={addClientToFilter}>
                Add To Filter
              </ButtonPrimary>
            </li>
            {filterPatients?.length > 0 && <li className="tw-mt-1">
              <ButtonSecondary className="tw-mt-md" onClick={clearClientFilter}>
               Clear Filter
              </ButtonSecondary>
            </li>}
          </ul>
          <div className="tw-flex tw-flex-wrap tw-py-2">
            {filterPatients.map((patient, patientIdx) => (
              <>
              <div className="tw-flex tw-justify-between tw-items-center tw-bg-blue-200 tw-p-2 tw-rounded tw-shadow-md tw-m-1">
                <span className="tw-text-blue-800">
                  {patient.firstName} {patient.lastName}
                </span>
                <Button onClick={() => removeClientFromFilter(patient)} className="tw-ml-auto tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-border-l tw-border-[rgba(0,0,0,.05)] hover:tw-border-white/10 hover:tw-bg-dark/10" childClasses="tw-flex tw-items-center">
                  <span className="tw-speak-none tw-w-[0.75em] tw-h-[0.75em] || tw-inline-flex tw-items-center tw-justify-center">

                    <img className="tw-inline-flex tw-w-full tw-h-full tw-object-contain tw-border-0" src={`/images/icon-close-black.svg`} />
                  </span>
                  <span className="tw-hidden">Close</span>
                </Button>
              </div>
              </>
            ))}
          </div>

          {selectedDateShifts.length === 0 ? <>
            <h3 className="tw-text-base tw-leading-6 tw-text-gray-400">No Open Shifts for selected day</h3>
          </>:<>
          {selectedDateShifts?.map((shift) => (
            <li key={shift.coverageEventID} className="tw-relative tw-flex tw-space-x-6 tw-py-6 xl:tw-static">
              <span className="tw-relative tw-inline-block">
                                    <span className="tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-500 tw-cursor-pointer">
                                        <span className="tw-font-medium tw-leading-none tw-text-white">{extractInitials(shift.patientName)}</span>
                                    </span>
                </span>
              {/* <img src={meeting.imageUrl} alt="" className="tw-h-14 tw-w-14 tw-flex-none tw-rounded-full" /> */}
              <div className="tw-flex-auto">
                <h3 className="tw-pr-10 tw-font-semibold tw-text-gray-900 xl:tw-pr-0">{shift.patientName}</h3>
                <dl className="tw-mt-2 tw-flex tw-flex-col tw-text-gray-500 xl:tw-flex-row">
                  <div className="tw-flex tw-items-start tw-space-x-3">
                    <dt className="tw-mt-0.5">
                      <span className="tw-sr-only">Date</span>
                      <CalendarIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>
                        {days[selectedDateIndex].displayDate} from {shift.displayStartTime} - {shift.displayEndTime}
                      </dd>
                  </div>
                  
                  {/* <div className="tw-mt-2 tw-flex tw-items-start tw-space-x-3 xl:tw-ml-3.5 xl:tw-mt-0 xl:tw-border-l xl:tw-border-gray-400 xl:tw-border-opacity-50 xl:tw-pl-3.5">
                    <dt className="tw-mt-0.5">
                      <span className="tw-sr-only">Location</span>
                      <MapPinIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>{meeting.location}</dd>
                  </div> */}
                </dl>
              </div>
              <div className="tw-absolute tw-right-0 tw-top-6 xl:tw-relative xl:tw-right-auto xl:tw-top-auto xl:tw-self-center">
                <ChevronRightIcon className="tw-h-5 tw-w-5 tw-text-gray-500 hover:tw-text-gray-600 tw-cursor-pointer" aria-hidden="true" onClick={() => showClientTeam(shift)} />
              </div>
              {/* <Menu as="div" className="tw-absolute tw-right-0 tw-top-6 xl:tw-relative xl:tw-right-auto xl:tw-top-auto xl:tw-self-center">
                <div>
                  <Menu.Button className="-tw-m-2 tw-flex tw-items-center tw-rounded-full tw-p-2 tw-text-gray-500 hover:tw-text-gray-600">
                    <span className="tw-sr-only">Open options</span>
                    <EllipsisHorizontalIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="tw-transition tw-ease-out tw-duration-100"
                  enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                  enterTo="tw-transform tw-opacity-100 tw-scale-100"
                  leave="tw-transition tw-ease-in tw-duration-75"
                  leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                  leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                >
                  <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-36 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                    <div className="tw-py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            className={classNames(
                              active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                              'tw-block tw-px-4 tw-py-2 tw-text-sm tw-cursor-pointer'
                            )}
                            onClick={() => showClientTeam(shift)}

                          >
                            View Team
                          </p>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                              'tw-block tw-px-4 tw-py-2 tw-text-sm'
                            )}
                          >
                            Cancel
                          </a>
                        )}
                      </Menu.Item> */}
                    {/* </div>
                  </Menu.Items>
                </Transition>
              </Menu>  */}
            </li>
          ))}
          </>}
            
          </>:<>
            <h2 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-400">Select a date to view open shifts</h2>
          </>}

          

         
         
        
        </ol>
      </div>
      </>}

      
    </div>
  )
}


const StaffShortageCalendar = props => {
    useLayoutEffect(() => {
        let currentMonth = moment().month(); // This will return a zero-based month (0 for January, 11 for December)
        let currentYear = moment().year();

        console.log(`Current Month: ${currentMonth + 1}`); // To get a one-based month, add 1 to the result
        console.log(`Current Year: ${currentYear}`);

        let currentDates = getCalendarDates(currentYear, currentMonth);

        console.log(currentDates);
    }, []);
    
    return (<>
    <div className="tw-px-xl tw-pt-xl">
            <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Coverage Planner</h2>
            </div>

            <StaffCalendar />
    </div>
    
    </>);

}

export default StaffShortageCalendar;