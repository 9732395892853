import { useLayoutEffect, useRef, useState } from "react";
import Button, { ButtonPrimary, ButtonSecondary } from "../buttons/Buttons";
import { Comment, CommentBlock } from "../containers/Cards";
import { PageSubHeader, Paragraph1, SectionHeader } from "../typography/Headers";
import { Status } from "../utilities/Helpers";
import { paragraph1  } from "../../utilities/typography";
import AddNewNote from "./AddNewNote";
import { Delegating } from "./Actions";
import { Input, Search, Select } from "../forms/formFields";
import moment from "moment";
import RemoteSupportSession from "../../types/remoteSupportSession";
import { autoAssignRemoteSupportSessionByID, deleteRecurringRemoteSupportSession, deleteRemoteSupportSession, divideShiftWihSession, getCoverageEventsBySessionID, getOverlappingSessions, getRemoteSupportAssociates, getRemoteSupportSessionByID, reassignShiftToDedicatedSession, updateRecurringRemoteSupportSession, updateRemoteSupportSession } from "../../apicalls/sessionService";
import { useNavigate } from "react-router-dom";
import Employee from "../../types/employee";
import CoverageEvent from "../../types/coverageEvent";
import AssignedEvent from "../../types/assignedEvent";
import { Alert, CircularProgress } from "@mui/material";
import { deleteLegacyEvent, handleIncomingCoverageRequirements, handleIncomingSessions, handleIncomingSpanSessions, markCoverageRequirementCancelled, updateCoverageRequirement, updateLegacyEvent } from "../../apicalls/schedulingService";
import CoverageRequirement from "../../types/coverageRequirement";
import { useAppContext } from "../../Context";
import { set, split } from "lodash";
import { v4 as uuidv4 } from 'uuid';


const ServicePanel = props => {
	const [serviceType, setServiceType] = useState(1);
	const [serviceTypeTheme, setServiceTypeTheme] = useState('primary');
	const [editMode, setEditMode] = useState(false);
	const [shiftEditMode, setShiftEditMode] = useState(false);
	const [reqEditMode, setReqEditMode] = useState(false);
	const [showDeleteConfirm, setDeleteConfirm] = useState(false);
	const [showUpdateConfirm, setUpdateConfirm] = useState(false);
	const [showReqCancelConfirm, setReqCancelConfirm] = useState(false);
	const [showShiftDeleteConfirm, setShiftDeleteConfirm] = useState(false);
	const [panelSize, setPanelSize] = useState('tw-max-w-400');

	const [showOpenShifts, setShowOpenShifts] = useState(false);

	const appContext = useAppContext();

	const [updatedRSSession, setUpdatedRSSession] = useState<RemoteSupportSession>(null);
	const [activeRSAs, setActiveRSAs] = useState<Employee[]>(null);
	const [availAssignedShifts, setAvailAssignedShifts] = useState<CoverageEvent[]>(null);
	const [availSelfAssignedShifts, setAvailSelfAssignedShifts] = useState<CoverageEvent[]>(null);
	const [availOtherAssignedShifts, setAvailOtherAssignedShifts] = useState<CoverageEvent[]>(null);
	const [allAssignedShifts, setAllAssignedShifts] = useState<CoverageEvent[]>(null);
	const [availOpenShifts, setAvailOpenShifts] = useState<CoverageEvent[]>(null);
	const [sessionShifts, setSessionShifts] = useState<AssignedEvent[]>(null);
	const [filteredShifts, setFilteredShifts] = useState<AssignedEvent[]>(null);
	const [shiftLoading, setShiftLoading] = useState(false);
	const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
	const [showUpdateFailure, setShowUpdateFailure] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
	const [showDeleteFailure, setShowDeleteFailure] = useState(false);
	const [showSessionLoading, setShowSessionLoading] = useState(false);
	const [showShiftUpdateSuccess, setShowShiftUpdateSuccess] = useState(false);
    const [showShiftDeleteSuccess, setShowShiftDeleteSuccess] = useState(false);
	const [showShiftLoading, setShowShiftLoading] = useState(false);
	const [showReqLoading, setShowReqLoading] = useState(false);
	const [showReqCancelSuccess, setShowReqCancelSuccess] = useState(false);
	const [showReqUpdateSuccess, setShowReqUpdateSuccess] = useState(false);
	const [showReqCancelFailure, setShowReqCancelFailure] = useState(false);
	const [showReqUpdateFailure, setShowReqUpdateFailure] = useState(false);
	const [showReqControls, setShowReqControls] = useState(false);
	const [showReassignSuccess, setShowReassignSuccess] = useState(false);
	const [deleteRecurring, setDeleteRecurring] = useState(false);
	const [selectedSession, setSelectedSession] = useState(null);
	const [selectedRequirement, setSelectedRequirement] = useState(null);
	const [totalClientHours, setTotalClientHours] = useState(0);
	const [showAutoAssignButton, setShowAutoAssignButton] = useState(false);
	const [showAutoAssignConfirm, setShowAutoAssignConfirm] = useState(false);

	const [concurrentSessions, setConcurrentSessions] = useState<RemoteSupportSession[]>(null);

	const closeUpdateAlert =  async () => { 
        setShowUpdateSuccess(false);

        //window.location.reload();
    }

    const closeDeleteAlert =  async () => { 
        setShowDeleteSuccess(false);

        //window.location.reload();
    }

	const closeReassignAlert = async () => {
		setShowReassignSuccess(false);
	}

	const closeUpdateFailureAlert =  async () => {
		setShowUpdateFailure(false);

		//window.location.reload();

	}

	const closeDeleteFailureAlert =  async () => {
		setShowDeleteFailure(false);

		//window.location.reload();
	
	}

	const closeShiftUpdateAlert =  async () => { 
        setShowShiftUpdateSuccess(false);

        window.location.reload();
    }

	const closeReqUpdateAlert =  async () => { 
        setShowReqUpdateSuccess(false);

        //window.location.reload();
    }

    const closeShiftDeleteAlert =  async () => { 
        setShowShiftDeleteSuccess(false);

        window.location.reload();
    }

	const closeReqCancelAlert =  async () => { 
        setShowReqCancelSuccess(false);

        //window.location.reload();
    }

	const closeReqCancelFailureAlert =  async () => {
		setShowReqCancelFailure(false);

		//window.location.reload();
	}

	const closeReqUpdateFailureAlert =  async () => {
		setShowReqUpdateFailure(false);

		//window.location.reload();
	}

	const provider = useRef();

	const [values, setValues] = useState<any>();
	const [originalValues, setOriginalValues] = useState<any>();

	const [shiftValues, setShiftValues] = useState<any>();

	const [reqValues, setReqValues] = useState<any>();

	function getDurationInHours(startDate: Date, endDate: Date): number {
		let diffInMs = endDate.getTime() - startDate.getTime();
		let diffInHours = diffInMs / (1000 * 60 * 60); // convert milliseconds to hours
		return diffInHours;
	}

	const getTotalClientHours = (session: RemoteSupportSession) : number => {
		let totalHours = 0;

		session.assignedShifts.forEach(clientSession => {
			totalHours += getDurationInHours(new Date(clientSession.startTime), new Date(clientSession.endTime));
		});

		return totalHours;
	}

	const isShiftInsideSession = (shift: AssignedEvent, session: RemoteSupportSession) : boolean => {
		// if either is undefined or null
		if(!shift || !session){
			return false;
		}

		// use moment to compare
		const shiftStart = moment(`${shift.displayCoverageDate} ${shift.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const shiftEnd = moment(`${shift.displayCoverageDate} ${shift.displayEndTime}`, 'MM-DD-YYYY h:mm A');
		const sessionStart = moment(`${session.displayStartDate} ${session.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const sessionEnd = moment(`${session.displayEndDate} ${session.displayEndTime}`, 'MM-DD-YYYY h:mm A');
	  
		if(shiftStart.isSameOrAfter(sessionStart) && shiftEnd.isSameOrBefore(sessionEnd)){
		  return true;
		} else {
		  console.log("Shift not inside session");
		  console.log(shift);
		  console.log(shiftStart);
		  console.log(shiftEnd);
		  console.log(sessionStart);
		  console.log(sessionEnd);
		  return false;
		}
	}

	const splitShiftsForSession = (session: RemoteSupportSession, coverageEvent: CoverageEvent): CoverageEvent[] => {
		let shifts: CoverageEvent[] = [];

		var coverageEventStart = moment(coverageEvent.displayCoverageDate + " " + coverageEvent.displayStartTime, 'MM-DD-YYYY h:mm A');
		var coverageEventEnd = moment(coverageEvent.displayCoverageDate + " " + coverageEvent.displayEndTime, 'MM-DD-YYYY h:mm A');
		var sessionStart = moment(session.displayStartDate + " " + session.displayStartTime, 'MM-DD-YYYY h:mm A');
		var sessionEnd = moment(session.displayEndDate + " " + session.displayEndTime, 'MM-DD-YYYY h:mm A');
	  
		if (coverageEventStart.isSameOrAfter(sessionStart) && coverageEventEnd.isSameOrBefore(sessionEnd)) {
		  let shift1: CoverageEvent = {
			...coverageEvent,
			coverageEventID: uuidv4(),
			startTime: coverageEventStart.toDate(),
			endTime: sessionEnd.toDate(),
			displayStartTime: coverageEventStart.format("h:mm A"),
			displayEndTime: sessionEnd.format("h:mm A"),
		  };
	  
		  shifts.push(shift1);
	  
		  let shift2: CoverageEvent = {
			...coverageEvent,
			coverageEventID: uuidv4(),
			startTime: sessionEnd.toDate(),
			endTime: coverageEventEnd.toDate(),
			displayStartTime: sessionEnd.format("h:mm A"),
			displayEndTime: coverageEventEnd.format("h:mm A"),
		  };

		  shifts.push(shift2);

			return shifts;
			}

		if (coverageEventEnd.isSameOrBefore(sessionEnd) && coverageEventStart.isBefore(sessionStart)) {
			let shift1: CoverageEvent = {
			...coverageEvent,
			coverageEventID: uuidv4(),
			startTime: coverageEventStart.toDate(),
			endTime: sessionStart.toDate(),
			displayStartTime: coverageEventStart.format("h:mm A"),
			displayEndTime: sessionStart.format("h:mm A"),
			};

			shifts.push(shift1);

			let shift2: CoverageEvent = {
			...coverageEvent,
			coverageEventID: uuidv4(),
			startTime: sessionStart.toDate(),
			endTime: coverageEventEnd.toDate(),
			displayStartTime: sessionStart.format("h:mm A"),
			displayEndTime: coverageEventEnd.format("h:mm A"),
			};

			shifts.push(shift2);

			return shifts;
		}

		return shifts;
	}

	const doesShiftOverlap = (shift: AssignedEvent, session: RemoteSupportSession) : boolean => {
		// if either is undefined or null
		if(!shift || !session){
			return false;
		}

		// use moment to compare
		const shiftStart = moment(`${shift.displayCoverageDate} ${shift.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const shiftEnd = moment(`${shift.displayCoverageDate} ${shift.displayEndTime}`, 'MM-DD-YYYY h:mm A');
		const sessionStart = moment(`${session.displayStartDate} ${session.displayStartTime}`, 'MM-DD-YYYY h:mm A');
		const sessionEnd = moment(`${session.displayEndDate} ${session.displayEndTime}`, 'MM-DD-YYYY h:mm A');
	  
		if(shiftStart.isBefore(sessionEnd) && shiftEnd.isAfter(sessionStart)){
		  return true;
		} else {
		  console.log("Shift not inside session");
		  console.log(shift);
		  console.log(shiftStart);
		  console.log(shiftEnd);
		  console.log(sessionStart);
		  console.log(sessionEnd);
		  return false;
		}
	}

	useLayoutEffect(() => {
		console.log("Show Session: " + props?.showSession);
		console.log("Show Shift: " + props?.showShift);
		console.log("Show Requirement: " + props?.showRequirement);

		// if (props?.servicePanelData.type === 2) {
		// 	setServiceType(2);
		// 	setServiceTypeTheme('accent-dark');
		// }

		if(props?.showSession) {
			if (props?.selectedSession?.dedicatedSession || props?.showSession){
				setPanelSize('tw-max-w-800');
				setShiftLoading(true);
			}
	
			console.log(props);
	
			setSessionShifts(props?.selectedSession?.assignedShifts);
			setFilteredShifts(props?.selectedSession?.assignedShifts);

			if(props?.selectedSession?.assignedShifts !== null){
				setTotalClientHours(getTotalClientHours(props?.selectedSession));
			}

			const fetchRSAs = async () => {
				const data = await getRemoteSupportAssociates(appContext.handleAPICallError);
				console.log(data);
				setActiveRSAs(data);

				await fetchSessionShifts();
			}
	
			const fetchSessionShifts = async () => {
				const data = await getCoverageEventsBySessionID(props?.selectedSession.remoteSupportSessionID, appContext.handleAPICallError);
				console.log("Session Shifts");
				console.log(data);

				const overlappingSessions = await getOverlappingSessions(props?.selectedSession.remoteSupportSessionID, appContext.handleAPICallError);

				if (overlappingSessions != null){
					setConcurrentSessions(overlappingSessions);
				}
	
				if (data != null){
					setAvailOpenShifts(data.filter(f => f.providerName === "Open Shift"));
					var assignedShifts = data.filter(f => f.providerName !== "Open Shift");
					let seen = new Set();

					assignedShifts = assignedShifts.filter(el => {
						let k = el.coverageEventID;
						return seen.has(k) ? false : seen.add(k);
					});

					assignedShifts.forEach(shift => {
						shift.showDivideConfirm = false;
						shift.showDivideConfirmMessage = "";
						if(overlappingSessions != null){
							overlappingSessions.forEach(session => {
								if(doesShiftOverlap(shift, session)){
									shift.overlappingSession = session;
								}
							});
						}
					});

					assignedShifts.sort((a, b) => {
						if (a.providerName === selectedSession?.providerName && b.providerName !== selectedSession?.providerName) {
							return 1;
						} else if (b.providerName === selectedSession?.providerName && a.providerName !== selectedSession?.providerName) {
							return -1;
						} else {
							return 0;
						}
					});
					setAvailAssignedShifts(assignedShifts);
					setAllAssignedShifts(assignedShifts);
					console.log("Assigned Shifts");
					console.log(selectedSession?.providerName);
					console.log(assignedShifts);
					setAvailOtherAssignedShifts(assignedShifts.filter(f => f.providerName !== props?.selectedSession?.providerName));
					setAvailSelfAssignedShifts(assignedShifts.filter(f => f.providerName === props?.selectedSession?.providerName));


					
				} else {
					setAvailOpenShifts([]);
					setAvailAssignedShifts([]);
				}
	
				setShiftLoading(false);
			}
	
			fetchRSAs()
				.catch(console.error);
	
			// fetchSessionShifts()
			// 	.catch(console.error);

			setSelectedSession(props?.selectedSession);

			// check if any assigned shifts do not fit inside the session
			setShowAutoAssignButton(false);
			props?.selectedSession?.assignedShifts?.forEach(shift => {
				if(!isShiftInsideSession(shift, props?.selectedSession)){
					setShowAutoAssignButton(true);
				}
			});


			setValues({
				"start_date": moment.utc(props?.selectedSession.displayStartDate).format("YYYY-MM-DD"),
				"end_date": moment.utc(props?.selectedSession.displayEndDate).format("YYYY-MM-DD"),
				"start_time": moment.utc(props?.selectedSession.startTime).format("HH:mm"),
				"end_time": moment.utc(props?.selectedSession.endTime).format("HH:mm"),
				"provider": props?.selectedSession.providerID,
				"supervisor": "",
			});

			setOriginalValues({
				"start_date": moment.utc(props?.selectedSession.displayStartDate).format("YYYY-MM-DD"),
				"end_date": moment.utc(props?.selectedSession.displayEndDate).format("YYYY-MM-DD"),
				"start_time": moment.utc(props?.selectedSession.startTime).format("HH:mm"),
				"end_time": moment.utc(props?.selectedSession.endTime).format("HH:mm"),
				"provider": props?.selectedSession.providerID,
				"supervisor": "",
			});
		}

		if(props?.showShift){
			setShiftValues({
				"shift_date": moment.utc(props?.selectedShift.displayCoverageDate).format("YYYY-MM-DD"),
				"shift_start": moment.utc(props?.selectedShift.startTime).format("HH:mm"),
				"shift_end": moment.utc(props?.selectedShift.endTime).format("HH:mm"),
				"provider": props?.selectedShift.providerID,
			});
		}
		
		if(props?.showRequirement) {
			setReqValues({
				"shift_date": moment.utc(props?.selectedRequirement.displayCoverageDate).format("YYYY-MM-DD"),
				"shift_start": moment.utc(props?.selectedRequirement.startTime).format("HH:mm"),
				"shift_end": moment.utc(props?.selectedRequirement.endTime).format("HH:mm"),
			});
			if(props?.selectedRequirement.status === "Cancelled"){
				setShowReqControls(false);
			} else {
				setShowReqControls(true);
			}

			setSelectedRequirement(props?.selectedRequirement);
		}

	}, [props?.type])

	const start_date = useRef();
    const end_date = useRef();
	const start_time = useRef();
	const end_time = useRef();

	const shift_date = useRef();
	const shift_start = useRef();
	const shift_end = useRef();

	const req_date = useRef();
	const req_start = useRef();
	const req_end = useRef();

	let navigate = useNavigate();

	const confirmUpdateSession = async () => {
		console.log(values);
		console.log(originalValues);

		const startTime = moment(values.start_date + " " + values.start_time)
        const endTime = moment(values.end_date + " " + values.end_time)

		var provider = activeRSAs.find(element => element.employeeID === values.provider)

		let updatedSession : RemoteSupportSession = {
			remoteSupportSessionID: selectedSession?.remoteSupportSessionID,
			supervisorID: selectedSession?.supervisorID,
			providerID: values.provider,
			startDate: moment(values.start_date).format(),
			displayStartDate: moment(values.start_date).format("MM-DD-YYYY"),
			endDate: moment(values.end_date).format(),
			displayEndDate: moment(values.start_date).format("MM-DD-YYYY"),
			providerName: provider.firstName + " " + provider.lastName,
			startTime: startTime.format(),
			displayStartTime: startTime.format("h:mm A"),
			endTime: endTime.format(),
			displayEndTime: endTime.format("h:mm A"),
			status: selectedSession?.status,
			multiDay: moment(values.end_date).format("MM-DD-YYYY") !== moment(values.start_date).format("MM-DD-YYYY"),
			startIndex: 0,
			endIndex: 0,
			multiDayStartIndex: 0,
			multiDayEndIndex: 0,
			recurrence: "Never",
			assignedShifts: [],
			colStart: 0,
			colEnd: 0,
			dedicatedSession: false,
			repeatsSunday: false,
			repeatsMonday: false,
			repeatsTuesday: false,
			repeatsWednesday: false,
			repeatsThursday: false,
			repeatsFriday: false,
			repeatsSaturday: false,
			recurrenceEndDate: moment(props?.selectedSession.recurrenceEndDate).format(),
			multiDayColStart: 0,
			multiDayColEnd: 0,
			officeID: "",
			officeName: "",
			stationID: "",
			stationName: ""
		}

		setUpdatedRSSession(updatedSession);

		setEditMode(false);
		setUpdateConfirm(true);
	}

	const updateSession = async () => {
		console.log(values);

		const startTime = moment(values.start_date + " " + values.start_time)
        const endTime = moment(values.end_date + " " + values.end_time)

		var provider = activeRSAs.find(element => element.employeeID === values.provider)

		let updatedSession : RemoteSupportSession = {
			remoteSupportSessionID: selectedSession?.remoteSupportSessionID,
			supervisorID: selectedSession?.supervisorID,
			providerID: values.provider,
			startDate: moment(values.start_date).format(),
			displayStartDate: "",
			endDate: moment(values.end_date).format(),
			displayEndDate: "",
			providerName: provider.firstName + " " + provider.lastName,
			startTime: startTime.format(),
			displayStartTime: "",
			endTime: endTime.format(),
			displayEndTime: "",
			status: selectedSession?.status,
			multiDay: moment(values.end_date).format("MM-DD-YYYY") !== moment(values.start_date).format("MM-DD-YYYY"),
			startIndex: 0,
			endIndex: 0,
			multiDayStartIndex: 0,
			multiDayEndIndex: 0,
			recurrence: "Never",
			assignedShifts: [],
			colStart: 0,
			colEnd: 0,
			dedicatedSession: false,
			repeatsSunday: false,
			repeatsMonday: false,
			repeatsTuesday: false,
			repeatsWednesday: false,
			repeatsThursday: false,
			repeatsFriday: false,
			repeatsSaturday: false,
			recurrenceEndDate: moment(props?.selectedSession.recurrenceEndDate).format(),
			multiDayColStart: 0,
			multiDayColEnd: 0,
			officeID: "",
			officeName: "",
			stationID: "",
			stationName: ""
		}

		console.log(updatedSession);

		setEditMode(false);
		setUpdateConfirm(false);
		setShowSessionLoading(true);

		//const copy = JSON.parse(JSON.stringify(updateSession));

		var sessionCopy : RemoteSupportSession = Object.assign({}, updatedSession);
		var mappedSession = handleIncomingSpanSessions(sessionCopy);
		console.log(mappedSession);
		setSelectedSession(mappedSession);

		const result = await updateRemoteSupportSession(updatedSession, appContext.handleAPICallError);

		props?.UpdateSession(mappedSession);

		setShowSessionLoading(false);

		if(result.error){
			setShowUpdateFailure(true);
		} else {
			setShowUpdateSuccess(true);
		}

		//window.location.reload();
	}

	const updateRecurringSession = async () => {
		console.log(values);

		const startTime = moment(values.start_date + " " + values.start_time)
        const endTime = moment(values.end_date + " " + values.end_time)

		var provider = activeRSAs.find(element => element.employeeID === values.provider)

		let updatedSession : RemoteSupportSession = {
			remoteSupportSessionID: selectedSession?.remoteSupportSessionID,
			supervisorID: selectedSession?.supervisorID,
			providerID: values.provider,
			startDate: moment(values.start_date).format(),
			displayStartDate: "",
			endDate: moment(values.end_date).format(),
			displayEndDate: "",
			providerName: provider.firstName + " " + provider.lastName,
			startTime: startTime.format(),
			displayStartTime: "",
			endTime: endTime.format(),
			displayEndTime: "",
			status: selectedSession?.status,
			multiDay: moment(values.end_date).format("MM-DD-YYYY") !== moment(values.start_date).format("MM-DD-YYYY"),
			startIndex: 0,
			endIndex: 0,
			multiDayStartIndex: 0,
			multiDayEndIndex: 0,
			recurrence: "Never",
			assignedShifts: [],
			colStart: 0,
			colEnd: 0,
			dedicatedSession: false,
			repeatsSunday: false,
			repeatsMonday: false,
			repeatsTuesday: false,
			repeatsWednesday: false,
			repeatsThursday: false,
			repeatsFriday: false,
			repeatsSaturday: false,
			recurrenceEndDate: moment(props?.selectedSession.recurrenceEndDate).format(),
			multiDayColStart: 0,
			multiDayColEnd: 0,
			officeID: "",
			officeName: "",
			stationID: "",
			stationName: ""
		}

		console.log(updatedSession);

		setEditMode(false);
		setUpdateConfirm(false);
		setShowSessionLoading(true);

		//const copy = JSON.parse(JSON.stringify(updateSession));

		var sessionCopy : RemoteSupportSession = Object.assign({}, updatedSession);
		var mappedSession = handleIncomingSpanSessions(sessionCopy);
		console.log(mappedSession);
		setSelectedSession(mappedSession);

		const result = await updateRecurringRemoteSupportSession(updatedSession, appContext.handleAPICallError);

		props?.UpdateSession(mappedSession);

		setShowSessionLoading(false);
		setShowUpdateSuccess(true);

		//window.location.reload();
	}

	const discardSession = async () => {
		setValues({
			"start_date": moment.utc(selectedSession?.displayStartDate).format("YYYY-MM-DD"),
			"end_date": moment.utc(selectedSession?.displayEndDate).format("YYYY-MM-DD"),
			"start_time": moment.utc(selectedSession?.startTime).format("HH:mm"),
			"end_time": moment.utc(selectedSession?.endTime).format("HH:mm"),
			"provider": props?.selectedSession.providerID,
			"supervisor": "",
		});

		setEditMode(false);
		setUpdateConfirm(false);
	}

	const confirmDeleteRecurringSession = async () => {
		setEditMode(false);
		setDeleteRecurring(true);
		setDeleteConfirm(true);
	}

	const confirmDeleteSession = async () => {
		setEditMode(false);
		setDeleteConfirm(true);
	}

	const cancelDeleteSession = async () => {
		setDeleteConfirm(false);
		setEditMode(true);
	}

	const cancelDeleteRecurringSession = async () => {
		setDeleteRecurring(false);
		setDeleteConfirm(false);
		setEditMode(true);
	}

	const deleteSession = async () => {
		setDeleteConfirm(false);
		setEditMode(false);
		setShowSessionLoading(true);

		const result = await deleteRemoteSupportSession(props?.selectedSession, appContext.handleAPICallError);


		setShowSessionLoading(false);
		if(result.error){
			setShowDeleteFailure(true);
		} else {
			setShowDeleteSuccess(true);
			props?.RemoveSession(props?.selectedSession.remoteSupportSessionID);
		}
		
		//window.location.reload();
	}

	const deleteRecurringSession = async () => {
		setDeleteConfirm(false);
		setDeleteRecurring(false);
		setEditMode(false);
		setShowSessionLoading(true);

		const result = await deleteRecurringRemoteSupportSession(props?.selectedSession, appContext.handleAPICallError);


		setShowSessionLoading(false);
		if(result.error){
			setShowDeleteFailure(true);
		} else {
			setShowDeleteSuccess(true);
			props?.RemoveSession(props?.selectedSession.remoteSupportSessionID);
		}
		//window.location.reload();
	}

	const reassignShift = async (shift: CoverageEvent) => {
		setShiftLoading(true);

		const result = await reassignShiftToDedicatedSession(selectedSession?.remoteSupportSessionID, shift, appContext.handleAPICallError);

		const sessionByID = await getRemoteSupportSessionByID(selectedSession?.remoteSupportSessionID, appContext.handleAPICallError);

		setSessionShifts(sessionByID.assignedShifts);

		const data = await getCoverageEventsBySessionID(selectedSession?.remoteSupportSessionID, appContext.handleAPICallError);
		console.log("Session Shifts");
		console.log(data);

		if (data != null){
			setAvailOpenShifts(data.filter(f => f.providerName === "Open Shift"));
			var assignedShifts = data.filter(f => f.providerName !== "Open Shift");
					let seen = new Set();

					assignedShifts = assignedShifts.filter(el => {
						let k = el.coverageEventID;
						return seen.has(k) ? false : seen.add(k);
					});

					assignedShifts.forEach(shift => {
						shift.showDivideConfirm = false;
						shift.showDivideConfirmMessage = "";
						if(concurrentSessions != null){
							concurrentSessions.forEach(session => {
								if(doesShiftOverlap(shift, session)){
									shift.overlappingSession = session;
								}
							});
						}
					});

					assignedShifts.sort((a, b) => {
						if (a.providerName === selectedSession?.providerName && b.providerName !== selectedSession?.providerName) {
							return 1;
						} else if (b.providerName === selectedSession?.providerName && a.providerName !== selectedSession?.providerName) {
							return -1;
						} else {
							return 0;
						}
					});
					setAvailAssignedShifts(assignedShifts);
					setAllAssignedShifts(assignedShifts);

			//setAvailAssignedShifts(data.filter(f => f.providerName !== "Open Shift"));
		} else {
			setAvailOpenShifts([]);
			setAvailAssignedShifts([]);
		}

		

		setShiftLoading(false);
		setShowReassignSuccess(true);
	}

	const divideShiftBetweenSessions = (shift: CoverageEvent) : string => {
		var divideMessage = "";
		
		const currentSession = selectedSession;

		const newSession = shift.overlappingSession;

		console.log("Current Session");
		console.log(currentSession);
		console.log("New Session");
		console.log(newSession);

		if(isShiftInsideSession(shift, newSession)){
			divideMessage = "This shift will be moved to " + newSession.providerName + "'s session.";
		} else {
			var splitShifts = splitShiftsForSession(newSession, shift);

			divideMessage = "This shift will be split between ";

			if(splitShifts.length > 0){
				splitShifts.forEach(splitShift => {
					console.log("Split Shift");
					console.log(splitShift);
					if(isShiftInsideSession(splitShift, newSession)){
						console.log("Shift inside new session");
						divideMessage += shift.overlappingSession.providerName + "'s session: " + moment(splitShift.startTime).tz('America/New_York').format("h:mm A") + " - " + moment(splitShift.endTime).tz('America/New_York').format("h:mm A") + ", ";
					} else if(isShiftInsideSession(splitShift, currentSession)){
						console.log("Shift inside current session");
						divideMessage += currentSession.providerName + "'s session: " + moment(splitShift.startTime).tz('America/New_York').format("h:mm A") + " - " + moment(splitShift.endTime).tz('America/New_York').format("h:mm A") + ", ";
					}
				});

			} 
		}

		console.log(divideMessage);

		return divideMessage;
	}

	const confirmDivideShift = async (shift: CoverageEvent) => {
		var divideMessage = divideShiftBetweenSessions(shift);

		setAvailAssignedShifts(availAssignedShifts.map(s => {
			if(s.coverageEventID === shift.coverageEventID){
				s.showDivideConfirmMessage = divideMessage;
				s.showDivideConfirm = true;
			}
			return s;
		}));

	}

	function insertLineBreaks(str, numChars) {
        var result = '';
        for (var i = 0; i < str.length; i += numChars) {
            result += str.substring(i, i + numChars) +  '<br />';
        }
        return result;
    }

	const cancelDivideShift = async (shift: CoverageEvent) => {
		setAvailAssignedShifts(availAssignedShifts.map(s => {
			if(s.coverageEventID === shift.coverageEventID){
				s.showDivideConfirmMessage = "";
				s.showDivideConfirm = false;
			}
			return s;
		}));
	}

	const divideShift = async (shift: CoverageEvent) => {
		setShiftLoading(true);

		const result = await divideShiftWihSession(shift.overlappingSession.remoteSupportSessionID, shift, selectedSession, appContext.handleAPICallError);

		const sessionByID = await getRemoteSupportSessionByID(selectedSession?.remoteSupportSessionID, appContext.handleAPICallError);

		setSessionShifts(sessionByID.assignedShifts);

		const data = await getCoverageEventsBySessionID(selectedSession?.remoteSupportSessionID, appContext.handleAPICallError);
		console.log("Session Shifts");
		console.log(data);

		if (data != null){
			setAvailOpenShifts(data.filter(f => f.providerName === "Open Shift"));
			var assignedShifts = data.filter(f => f.providerName !== "Open Shift");
					let seen = new Set();

					assignedShifts = assignedShifts.filter(el => {
						let k = el.coverageEventID;
						return seen.has(k) ? false : seen.add(k);
					});

					assignedShifts.forEach(shift => {
						if(concurrentSessions != null){
							shift.showDivideConfirm = false;
							shift.showDivideConfirmMessage = "";
							concurrentSessions.forEach(session => {
								if(doesShiftOverlap(shift, session)){
									shift.overlappingSession = session;
								}
							});
						}
					});

					assignedShifts.sort((a, b) => {
						if (a.providerName === selectedSession?.providerName && b.providerName !== selectedSession?.providerName) {
							return 1;
						} else if (b.providerName === selectedSession?.providerName && a.providerName !== selectedSession?.providerName) {
							return -1;
						} else {
							return 0;
						}
					});
					setAvailAssignedShifts(assignedShifts);
					setAllAssignedShifts(assignedShifts);

			//setAvailAssignedShifts(data.filter(f => f.providerName !== "Open Shift"));
		} else {
			setAvailOpenShifts([]);
			setAvailAssignedShifts([]);
		}

		

		setShiftLoading(false);
		setShowReassignSuccess(true);
	}

	const updateShift = async () => {
		console.log(shiftValues);

		const startTime = moment(shiftValues.shift_date + " " + shiftValues.shift_start)
        const endTime = moment(shiftValues.shift_date + " " + shiftValues.shift_end)

		let updatedCoverage: CoverageEvent = {
			coverageEventID: props?.selectedShift?.coverageEventID,
			patientID: props?.selectedShift?.patientID,
			patientName: "",
			providerID: "",
			staffSize: props?.selectedShift?.staffSize,
			groupSize: props?.selectedShift?.groupSize,
			billingCode: props?.selectedShift?.billingCode,
			coverageDate: moment(shiftValues.shift_date).format(),
			providerName: "",
			shiftType: props?.selectedShift?.shiftType,
			shiftTheme: 0,
			startTime: startTime.format(),
			displayStartTime: shiftValues.start_time,
			endTime: endTime.format(),
			displayEndTime: shiftValues.end_time,
			displayCoverageDate: "",
			status: props?.selectedShift?.status
		}

		console.log(updatedCoverage);

		setShiftEditMode(false);
		setShowShiftLoading(true);

		const result = await updateLegacyEvent(updatedCoverage, appContext.handleAPICallError);

		setShowShiftLoading(false);
		setShowShiftUpdateSuccess(true);

		//window.location.reload();
	}

	const discardShift = async () => {
		setShiftValues({
			"shift_date": moment.utc(props?.selectedShift.displayCoverageDate).format("YYYY-MM-DD"),
			"shift_start": moment.utc(props?.selectedShift.startTime).format("HH:mm"),
			"shift_end": moment.utc(props?.selectedShift.endTime).format("HH:mm"),
			"provider": props?.selectedShift.providerID,
		});

		setShiftEditMode(false);
	}

	const confirmAutoAssign = async () => {
		setShowAutoAssignConfirm(true);
	}

	const cancelAutoAssign = async () => {
		setShowAutoAssignConfirm(false);
	}

	const autoAssignSession = async () => {
		setShowSessionLoading(true);
		setShowAutoAssignConfirm(false);

		const result = await autoAssignRemoteSupportSessionByID(props?.selectedSession.remoteSupportSessionID, appContext.handleAPICallError);

		setShowAutoAssignButton(false);

		await reloadSession();
	}

	const reloadSession = async () => {
		const updatedSession = await getRemoteSupportSessionByID(props?.selectedSession.remoteSupportSessionID, appContext.handleAPICallError);

		setSelectedSession(updatedSession);

		setSessionShifts(updatedSession.assignedShifts);

		setShowSessionLoading(false);

		appContext.setModalMessageHeader("Auto Assign Complete");
		appContext.setModalMessageBody("All shifts have been auto-assigned to the session.");
		appContext.setShowSuccessModal(true);

		appContext.closeSideNav();
	}

	const confirmDeleteShift = async () => {
		setShiftEditMode(false);
		setShiftDeleteConfirm(true);
	}

	const deleteShift = async () => {
		setShiftDeleteConfirm(false);
		setShiftEditMode(false);
		setShowShiftLoading(true);
		const result = await deleteLegacyEvent(props?.selectedShift, appContext.handleAPICallError);

		setShowShiftLoading(false);
		setShowShiftDeleteSuccess(true);

		//window.location.reload();
	}

	const cancelDeleteShift = async () => {
		setShiftDeleteConfirm(false);
		setShiftEditMode(true);
	}

	const confirmCancelReq = async () => {
		setReqEditMode(false);
		setReqCancelConfirm(true);
	}

	const discardReq = async () => {
		setReqEditMode(false);
		setReqCancelConfirm(false);
	}

	const markReqCanceled = async () => {
		setReqEditMode(false);
		setReqCancelConfirm(false);
		setShowReqLoading(true);

		const result = await markCoverageRequirementCancelled(selectedRequirement?.coverageRequirementID, appContext.handleAPICallError);

		//const result = await deleteRemoteSupportSession(props?.selectedSession);

		setShowReqLoading(false);
		if(result.error){
			setShowReqCancelFailure(true);
		} else {
			setShowReqCancelSuccess(true);
		}

		selectedRequirement.status = "Cancelled";

		props?.UpdateRequirement(selectedRequirement);

		setShowReqControls(false);
		
		//window.location.reload();
	}

	const closePanel = async () => {

		props.setShowRightPanel(false);

		window.location.reload();
	}

	const updateReq = async () => {
		console.log(reqValues);

		const startTime = moment(reqValues.shift_date + " " + reqValues.shift_start)
        const endTime = moment(reqValues.shift_date + " " + reqValues.shift_end)

		let updatedRequirement : CoverageRequirement = {
			coverageRequirementID: selectedRequirement?.coverageRequirementID,
			patientID: selectedRequirement?.patientID,
			recurrenceID: "",
			staffSize: selectedRequirement?.staffSize,
			groupSize: selectedRequirement?.groupSize,
			billingCode: selectedRequirement?.billingCode,
			coverageDate: moment(reqValues.shift_date).format(),
			providerName: "",
			shiftType: selectedRequirement?.shiftType,
			shiftTheme: 0,
			startTime: startTime.format(),
			displayStartTime: reqValues.shift_start,
			endTime: endTime.format(),
			displayEndTime: reqValues.shift_end,
			displayCoverageDate: "",
			recurrence: selectedRequirement?.recurrence,
			status: "",
			repeatsSunday: selectedRequirement?.repeatsSunday,
			repeatsMonday: selectedRequirement?.repeatsMonday,
			repeatsTuesday: selectedRequirement?.repeatsTuesday,
			repeatsWednesday: selectedRequirement?.repeatsWednesday,
			repeatsThursday: selectedRequirement?.repeatsThursday,
			repeatsFriday: selectedRequirement?.repeatsFriday,
			repeatsSaturday: selectedRequirement?.repeatsSaturday,
			recurrenceEndDate: moment(selectedRequirement?.recurrenceEndDate).format(),
			reason: ""
		}

		// let updatedCoverage: CoverageEvent = {
		// 	coverageEventID: props?.selectedShift?.coverageEventID,
		// 	patientID: props?.selectedShift?.patientID,
		// 	patientName: "",
		// 	providerID: "",
		// 	staffSize: props?.selectedShift?.staffSize,
		// 	groupSize: props?.selectedShift?.groupSize,
		// 	billingCode: props?.selectedShift?.billingCode,
		// 	coverageDate: moment(shiftValues.shift_date).format(),
		// 	providerName: "",
		// 	shiftType: props?.selectedShift?.shiftType,
		// 	shiftTheme: 0,
		// 	startTime: startTime.format(),
		// 	displayStartTime: shiftValues.start_time,
		// 	endTime: endTime.format(),
		// 	displayEndTime: shiftValues.end_time,
		// 	displayCoverageDate: "",
		// 	status: props?.selectedShift?.status
		// }


		console.log(updatedRequirement);

		var reqCopy : CoverageRequirement = Object.assign({}, updatedRequirement);
		var mappedReq = handleIncomingCoverageRequirements(reqCopy);
		console.log(mappedReq);
		setSelectedRequirement(mappedReq);

		setReqEditMode(false);
		setShowReqLoading(true);

		const result = await updateCoverageRequirement(updatedRequirement, appContext.handleAPICallError);

		props?.UpdateRequirement(mappedReq);

		setShowReqLoading(false);
		if(result.error){
			setShowReqUpdateFailure(true);
		} else {
			setShowReqUpdateSuccess(true);
		}

		//window.location.reload();
	}




	return <>
		<div className={`tw-relative tw-z-20 || ${panelSize} tw-h-screen tw-flex-grow tw-w-full || tw-flex tw-flex-col || tw-bg-white`}>
			
			{ props?.showSession ? <>
				{/* <div className={`tw-w-full tw-overflow-hidden tw-flex-shrink-0 || tw-flex || tw-bg-${serviceTypeTheme} tw-text-white`}>
				<span className="tw-block tw-whitespace-nowrap tw-max-w-[calc(100%-3.375em-1px)]">
					<span className="tw-block tw-px-6 tw-py-3 tw-leading-[1.125em]  tw-w-full tw-text-ellipsis tw-overflow-hidden">
					 {props?.servicePanelData.type === 2 ? "Session":"Service 1"}
					</span>
				</span>
				<Button onClick={ () => {
					closePanel();
				} } className="tw-ml-auto tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-w-[3.375em] tw-border-l tw-border-[rgba(0,0,0,.05)] hover:tw-border-white/10 hover:tw-bg-dark/10" childClasses="tw-flex tw-items-center">
					<span className="tw-speak-none tw-w-[0.875em] tw-h-[0.875em] || tw-inline-flex tw-items-center tw-justify-center">
						<img className="tw-inline-flex tw-w-full tw-h-full tw-object-contain tw-border-0" src="/images/icon-close.svg" />
					</span>
					<span className="tw-hidden">Close</span>
				</Button>
				</div> */}
				<div className="tw-flex tw-flex-row">
						{selectedSession?.dedicatedSession || props?.showSession ? <>
							<div className="tw-flex-grow tw-overflow-y-auto tw-h-screen tw-text-dark tw-border-r-2 border-black">

							<div className="tw-space-y-xl tw-py-md">
								<div className="tw-px-md">
									<PageSubHeader tag="h3">
										Shifts
										
									</PageSubHeader>
								</div>

								<div className="tw-space-y-md">
									{/* <div className="tw-px-md tw-flex tw-justify-start tw-items-center">
										<SectionHeader tag="h4" childClasses="tw-flex tw-flex-grow tw-items-center">
											<Status className={`tw-text-${serviceTypeTheme}`}>Active</Status>
											<span className={!showOpenShifts ? "tw-cursor-pointer":"tw-cursor-pointer tw-text-slate-400"} onClick={ e => {
															
															e.preventDefault();
															setShowOpenShifts(false);
														}}>
												Assigned 
												
											</span>
										</SectionHeader>
										<SectionHeader tag="h4" childClasses="tw-flex tw-flex-grow tw-items-center tw-ml-4">
											<Status className={`tw-text-${serviceTypeTheme}`}>Active</Status>
											<span className={showOpenShifts ? "tw-cursor-pointer":"tw-cursor-pointer tw-text-slate-400"} onClick={ e => {
															
															e.preventDefault();
															setShowOpenShifts(true);
														}}>
												Open 
												
											</span>
										</SectionHeader>
									</div> */}
									<div className="tw-px-md">

									<div className="tw-flex tw-justify-center">
										<Search className="-tw-mt-sm tw-py-md" onChange={ e => {

													console.log(e?.target.value || "nope");

													if(e?.target.value){
														const searchText = e?.target.value.toString().toLowerCase();
														const filtered = allAssignedShifts.filter(element => {
															
															// 👇️ using AND (&&) operator
															return element.patientName.toLowerCase().includes(searchText);
														});

														console.log(filtered)

														setAvailAssignedShifts(filtered);
														setAvailOtherAssignedShifts(filtered.filter(f => f.providerName !== selectedSession?.providerName));
														setAvailSelfAssignedShifts(filtered.filter(f => f.providerName === selectedSession?.providerName));
													} else {
														setAvailAssignedShifts(allAssignedShifts);
														setAvailOtherAssignedShifts(allAssignedShifts.filter(f => f.providerName !== selectedSession?.providerName));
														setAvailSelfAssignedShifts(allAssignedShifts.filter(f => f.providerName === selectedSession?.providerName));
													}
													}}/>
									</div>

									{ shiftLoading ? <>
										<div className="tw-flex tw-justify-center tw-m-md">
											<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
										</div>
									</>:<></>}

									
									
									{ showOpenShifts ? <>
										<ul className="tw-space-y-md tw-px-md">
										{ availOpenShifts?.map((shift) => {
												return	<>
												<li>
													<div className="tw-px-md tw-pb-md tw-pt-lg tw-bg-gray-200">
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																{shift.patientName}
															</span>
														</Paragraph1>
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																{shift.displayStartTime} -- {shift.displayEndTime}
															</span>
														</Paragraph1>
													<div className="tw-pt-lg tw-flex tw-justify-between">
														{/* <div className="tw-mr-xs tw-flex-grow tw-flex-shrink-0">
															<ButtonSecondary small className="tw-w-full" onClick={ e => {
																
																e.preventDefault();
																cancelDeleteSession();
															}}>
																Cancel
															</ButtonSecondary>
														</div> */}
														<div className="tw-ml-xs tw-flex-grow tw-flex-shrink-0">
															<ButtonPrimary small className="tw-w-full" onClick={ e => {
																
																e.preventDefault();
																reassignShift(shift);
																//deleteSession();
															}}>
																Assign
															</ButtonPrimary>
														</div>
													</div>
													</div>
													</li>
												</>
											}) }
										</ul>
									</>:<>
										<ul className="tw-space-y-md tw-px-md">
										{ availOtherAssignedShifts?.map((shift) => {
												return	<>
												<li>
													<div className="tw-px-md tw-pb-md tw-pt-lg tw-bg-gray-200">
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																{shift.patientName}
															</span>
														</Paragraph1>
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																{shift.displayStartTime} -- {shift.displayEndTime}
															</span>
														</Paragraph1>
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																Assigned: {shift.providerName}
															</span>
														</Paragraph1>
													{ shift.providerName !== selectedSession?.providerName ? <div className="tw-pt-lg tw-flex tw-justify-between">
														{/* <div className="tw-mr-xs tw-flex-grow tw-flex-shrink-0">
															<ButtonSecondary small className="tw-w-full" onClick={ e => {
																
																e.preventDefault();
																cancelDeleteSession();
															}}>
																Cancel
															</ButtonSecondary>
														</div> */}
														<div className="tw-ml-xs tw-flex-grow tw-flex-shrink-0">
															{appContext?.sessionScheduleAdmin && <ButtonPrimary small className="tw-w-full" onClick={ e => {
																
																e.preventDefault();
																reassignShift(shift);
																//deleteSession();
															}}>
																Move to current session
															</ButtonPrimary>}
														</div>
													</div>: <></>}
													</div>
													</li>
												</>
											}) }
										{ availSelfAssignedShifts?.map((shift) => {
												return	<>
												<li>
													{!shift.showDivideConfirm ? <div className="tw-px-md tw-pb-md tw-pt-lg tw-bg-gray-200">
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																{shift.patientName}
															</span>
														</Paragraph1>
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																{shift.displayStartTime} -- {shift.displayEndTime}
															</span>
														</Paragraph1>
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																Assigned: {shift.providerName}
															</span>
														</Paragraph1>
														{shift.overlappingSession &&  <>
															<Paragraph1>
															<span className="tw-text-[1.111em] tw-text-primary">
																Overlapping Session: {shift.overlappingSession?.providerName}
															</span>
														</Paragraph1>
														<Paragraph1>
															<span className="tw-text-[1.111em] tw-text-primary">
																{shift.overlappingSession?.displayStartTime} -- {shift.overlappingSession?.displayEndTime}
															</span>
														</Paragraph1>
														</>}
														{shift.overlappingSession && <div className="tw-pt-lg tw-flex tw-justify-between">
														<div className="tw-ml-xs tw-flex-grow tw-flex-shrink-0">
															{appContext?.sessionScheduleAdmin && <ButtonPrimary small className="tw-w-full" onClick={ e => {
																
																e.preventDefault();

																confirmDivideShift(shift);
																//reassignShift(shift);
																//deleteSession();
															}}>
																Divide Shift
															</ButtonPrimary>}
														</div>
													</div>}
													</div> : <div className="tw-px-md tw-pb-md tw-pt-lg tw-bg-gray-200 tw-w-3/4">
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																{shift.showDivideConfirmMessage}
															</span>
														</Paragraph1>
														<div className="tw-pt-lg tw-flex tw-justify-between">
														<div className="tw-ml-xs tw-flex-grow tw-flex-shrink-0">
															<ButtonSecondary small className="tw-w-1/3 tw-mx-4" onClick={ e => {
																
																e.preventDefault();

																cancelDivideShift(shift);
																//reassignShift(shift);
																//deleteSession();
															}}>
																Cancel
															</ButtonSecondary>
															<ButtonPrimary small className="tw-w-1/3"  onClick={ e => {
																
																e.preventDefault();

																divideShift(shift);
																//reassignShift(shift);
																//deleteSession();
															}}>
																Confirm
															</ButtonPrimary>
														</div>
													</div>
													</div>}
												</li>
												</>
											}) }
										{/* { [...Array(4)].map(() => {
												return	<>
												<li>
													<div className="tw-px-md tw-pb-md tw-pt-lg tw-bg-gray-200">
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																Jon Snow 
															</span>
														</Paragraph1>
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																4:00 AM -- 8:00 AM
															</span>
														</Paragraph1>
														<Paragraph1>
															<span className="tw-text-[1.111em]">
																Assigned: Chris Green
															</span>
														</Paragraph1>
													<div className="tw-pt-lg tw-flex tw-justify-between">
														{/* <div className="tw-mr-xs tw-flex-grow tw-flex-shrink-0">
															<ButtonSecondary small className="tw-w-full" onClick={ e => {
																
																e.preventDefault();
																cancelDeleteSession();
															}}>
																Cancel
															</ButtonSecondary>
														</div> */}
														{/* <div className="tw-ml-xs tw-flex-grow tw-flex-shrink-0">
															<ButtonPrimary small className="tw-w-full" onClick={ e => {
																
																e.preventDefault();
																//deleteSession();
															}}>
																ReAssign
															</ButtonPrimary>
														</div>
													</div>
													</div>
													</li>
												</>
											}) } */}
										</ul>
									</> }
										
									
										

										
									</div>
								</div>


							</div>

							</div>
						
						</>:<></>}

						



						<div className="tw-flex-grow tw-overflow-y-auto tw-text-dark">

						<div className="tw-space-y-xl tw-py-md">
							<div className="tw-px-md">
								<PageSubHeader tag="h3">
									Session Details
									
								</PageSubHeader>
							</div>

							{showUpdateFailure ? <>
								<div className="tw-pb-sm tw-px-xl">
									<Alert severity="error" onClose={closeUpdateFailureAlert}>Error Updating Session</Alert>
								</div>
							</>:<></> }


							{showUpdateSuccess ? <>
								<div className="tw-pb-sm tw-px-xl">
									<Alert  onClose={closeUpdateAlert}>Session Updated Successfully</Alert>
								</div>
							</>:<></> }

							{showDeleteFailure ? <>
								<div className="tw-pb-sm tw-px-xl">
									<Alert severity="error" onClose={closeDeleteFailureAlert}>Error Deleting Session</Alert>
								</div>
							</>:<></> }

							{showDeleteSuccess ? <>
									<div className="tw-pb-sm tw-px-xl">
										<Alert  onClose={closeDeleteAlert}>Session Deleted Successfully</Alert>
									</div>
							</>:<></> }

							{showReassignSuccess ? <>
								<div className="tw-pb-sm tw-px-xl">
									<Alert  onClose={closeReassignAlert}>Shift Reassigned Successfully</Alert>
								</div>
							</>:<></> }

							<div className="tw-space-y-md">
								<div className="tw-px-md tw-flex tw-justify-start tw-items-center">
									<SectionHeader tag="h4" childClasses="tw-flex tw-items-center">
										<Status className={`tw-text-${serviceTypeTheme}`}>Active</Status>
										<span>
											Session Information
											
										</span>
									</SectionHeader>
								</div>
								<div className="tw-px-md">
									{ showUpdateConfirm ? <>
										<div className={`tw-table tw-w-full${ paragraph1 }`}>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12 tw-font-bold">
												Current Values
											</div>
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12">
												Start Date
											</div>
											<div className="tw-table-cell">
												{selectedSession?.displayStartDate}
											</div>
										</div>
										
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Start time
											</div>
											<div className="tw-table-cell">
												{selectedSession?.displayStartTime}
											</div>
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12">
												End Date
											</div>
											<div className="tw-table-cell">
												{selectedSession?.displayEndDate}
											</div>
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												End time
											</div>
											<div className="tw-table-cell">
													{selectedSession?.displayEndTime}
											</div>
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
														Provider
											</div>
											<div className="tw-table-cell">
														{selectedSession?.providerName}
											</div>
										</div>

										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12 tw-font-bold">
												New Values
											</div>
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12">
												Start Date
											</div>
											<div className="tw-table-cell">
												{updatedRSSession?.displayStartDate}
											</div>
										</div>
										
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Start time
											</div>
											<div className="tw-table-cell">
												{updatedRSSession?.displayStartTime}
											</div>
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12">
												End Date
											</div>
											<div className="tw-table-cell">
												{updatedRSSession?.displayEndDate}
											</div>
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												End time
											</div>
											<div className="tw-table-cell">
													{updatedRSSession?.displayEndTime}
											</div>
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
														Provider
											</div>
											<div className="tw-table-cell">
														{updatedRSSession?.providerName}
											</div>
										</div>

										<div className="tw-pt-xl tw-flex tw-justify-between">
											<div className="tw-mr-sm tw-flex-shrink-0">
													<ButtonSecondary small className="tw-w-full" onClick={ e => {
														e.preventDefault();
														discardSession();
													}}>
														Discard
													</ButtonSecondary>
												</div>
												<div className="tw-ml-sm tw-flex-shrink-0">
													<ButtonPrimary small className="tw-w-full" onClick={ e => {
														e.preventDefault();
														updateSession();
													}}>
														Update
													</ButtonPrimary>
												</div>
												{selectedSession?.recurrence !== "Never" && 
												<div className="tw-ml-sm tw-flex-shrink-0">
													<ButtonPrimary small className="tw-w-full" onClick={ e => {
														e.preventDefault();
														updateRecurringSession();
														//updateSession();
													}}>
														Update Series
													</ButtonPrimary>
												</div>
												}
												
										</div>

										</div>

									</>:<>
									<div className={`tw-table tw-w-full${ paragraph1 }`}>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12">
												Start Date
											</div>
											{ editMode ? <>
												<div className="tw-table-cell tw-py-xs">
													<Input
													min="2000-01-01" max="2024-12-31"
													type="date" ref={ start_date }
													value={ values.start_date } onChange={ e => setValues({...values, "start_date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
												</div>
											</>:<>
												<div className="tw-table-cell">
													{selectedSession?.displayStartDate}
												</div>
											</>}
											
											
										</div>
										
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Start time
											</div>
											{ editMode ? <>
												<div className="tw-table-cell tw-py-xs">
													<Input id="start_time" type="time" onChange={ e => setValues({...values, "start_time": e.target.value }) } ref={ start_time } value={ values.start_time } alt className="tw-w-full" />
												</div>
											</>:<>
												<div className="tw-table-cell">
													{selectedSession?.displayStartTime}
												</div>
											</> }
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12">
												End Date
											</div>
											{ editMode ? <>
												<div className="tw-table-cell tw-py-xs">
													<Input
													min="2000-01-01" max="2024-12-31"
													type="date" ref={ end_date }
													value={ values.end_date } onChange={ e => setValues({...values, "end_date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
												</div>
											</>:<>
												<div className="tw-table-cell">
													{selectedSession?.displayEndDate}
												</div>
											</>}
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												End time
											</div>
											{ editMode ? <>
												<div className="tw-table-cell tw-py-xs">
													<Input id="end_time" type="time" onChange={ e => setValues({...values, "end_time": e.target.value }) } ref={ end_time } value={ values.end_time } alt className="tw-w-full" />
												</div>
											</>:<>
												<div className="tw-table-cell">
													{selectedSession?.displayEndTime}
												</div>
											</> }
											
											
										</div>
										
										{
											editMode ? <>
												<div className="tw-table-row">
													<div className="tw-table-cell tw-pr-sm tw-py-xs">
														Provider
													</div>
													<div className="tw-table-cell tw-py-xs">
													<Select id="shift_type" onChange={ e => setValues({...values, "provider": e.target.value }) } ref={ provider } value={ values.provider } alt className="tw-flex-grow tw-max-w-500">
															{/* <option value="">--Select--</option>
															<option value="AMR">Ali Rahimi</option>
															<option value="AMS">Sam Jankowski</option>
															<option value="AMS">Corissa Thompson</option>
															<option value="AMS">Annie Watson</option> */}
															{ activeRSAs ? <>
																<option value="">--Select Provider--</option>
																{
																	activeRSAs.map(provider =>
																		<option value={provider.employeeID}>{provider.firstName} {provider.lastName}</option>)
																}
															</>:<>
																<option value="">--Loading--</option>
															</>}
														</Select>
													</div>
												</div>
											</>:<>
												{props?.selectedSession.status !== "Open" ? <>
												<div className="tw-table-row">
													<div className="tw-table-cell tw-pr-sm tw-py-xs">
														Provider
													</div>
													<div className="tw-table-cell">
														{selectedSession?.providerName}
													</div>
												</div>
												</>:<></>}
											</>
										}
										
										
										
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Status
											</div>
											<div className="tw-table-cell">
												{selectedSession?.status}
											</div>
										</div>

										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Dedicated
											</div>
											<div className="tw-table-cell">
												{selectedSession?.dedicatedSession ? "Yes": "No"}
											</div>
										</div>
									</div>

									{ selectedSession?.status != "Submitted" ? <>
										{ showDeleteConfirm ? <>
										<div className="tw-px-md tw-pb-md tw-pt-lg">
											<Paragraph1>
												<span className="tw-text-[1.111em]">
													{deleteRecurring ? "Are you sure you want to delete this recurring session series?" : "Are you sure you want to delete this session?"}
													
												</span>
											</Paragraph1>
										<div className="tw-pt-xl tw-flex tw-justify-between">
											<div className="tw-mr-xs tw-flex-grow tw-flex-shrink-0">
												<ButtonSecondary small className="tw-w-full" onClick={ e => {
													
													e.preventDefault();
													if(deleteRecurring) {
														cancelDeleteRecurringSession();
													} else {
														cancelDeleteSession();
													}
													
												}}>
													Cancel
												</ButtonSecondary>
											</div>
											<div className="tw-ml-xs tw-flex-grow tw-flex-shrink-0">
												<ButtonPrimary small className="tw-w-full" onClick={ e => {
													
													e.preventDefault();
													if(deleteRecurring) {
														deleteRecurringSession();
													} else {
														deleteSession();
													}
													
												}}>
													Yes
												</ButtonPrimary>
											</div>
										</div>
										</div>
										
										</>:<>
										<div className="tw-pt-xl tw-flex tw-justify-end">
											{ editMode ? <>
												{
													selectedSession?.recurrence != "Never" ? <>
														<div className="tw-mr-sm tw-flex-shrink-0">
															<ButtonSecondary small className="tw-w-full" onClick={ e => {
																e.preventDefault();
																confirmDeleteRecurringSession();
															}}>
																Delete Series
															</ButtonSecondary>
														</div>
													</> :<></>
												}
												
												<div className="tw-mr-sm tw-flex-shrink-0">
													<ButtonSecondary small className="tw-w-full" onClick={ e => {
														e.preventDefault();
														confirmDeleteSession();
													}}>
														Delete
													</ButtonSecondary>
												</div>
												<div className="tw-mr-sm tw-flex-shrink-0">
													<ButtonSecondary small className="tw-w-full" onClick={ e => {
														e.preventDefault();
														discardSession();
													}}>
														Discard
													</ButtonSecondary>
												</div>
												<div className="tw-ml-sm tw-flex-shrink-0">
													<ButtonPrimary small className="tw-w-full" onClick={ e => {
														e.preventDefault();
														confirmUpdateSession();
													}}>
														Save
													</ButtonPrimary>
												</div>
											</>:<>
												{showSessionLoading ? 
														<>
														<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
														</>: <>
														{showAutoAssignConfirm ? <>
															<div className="tw-px-md tw-pb-md tw-pt-lg">
																<Paragraph1>
																	<span className="tw-text-[1.111em] tw-w-32 tw-break-all">
																		Auto-assign will resize any shifts that do not fully fit in the current session. Do you wish to proceed?
																	</span>
																	 
																</Paragraph1>
															<div className="tw-pt-xl tw-flex tw-justify-between">
																<div className="tw-mr-xs tw-flex-grow tw-flex-shrink-0">
																	<ButtonSecondary small className="tw-w-full" onClick={ e => {
																		
																		e.preventDefault();
																		cancelAutoAssign();
																	}}>
																		Cancel
																	</ButtonSecondary>
																</div>
																<div className="tw-ml-xs tw-flex-grow tw-flex-shrink-0">
																	<ButtonPrimary small className="tw-w-full" onClick={ e => {
							
																		e.preventDefault();
																		autoAssignSession();
																	}}>
																		Yes
																	</ButtonPrimary>
																</div>
															</div>
															</div>
														</>:<>
														{appContext?.sessionScheduleAdmin && showAutoAssignButton && <div className="tw-ml-xs tw-flex-shrink-0">
															<ButtonPrimary small className="tw-w-full" onClick={ e => {
																e.preventDefault();
																confirmAutoAssign();
																// setEditMode(true);
															}}>
																Auto-Assign
															</ButtonPrimary>
														</div>}
														{appContext?.sessionScheduleAdmin && <div className="tw-ml-xs tw-flex-shrink-0">
															<ButtonPrimary small className="tw-w-full" onClick={ e => {
																e.preventDefault();
																setEditMode(true);
															}}>
																Edit
															</ButtonPrimary>
														</div>}
														</>}
															
														</>}
												
											</>}
										
										
										</div>

										</> }
									</>:<></> }
									</> }
									

									

									
								</div>
							</div>

						</div>

						{ editMode ? <></>:<>
						<div className="tw-space-y-md">
								<div className="tw-px-md tw-flex tw-justify-between tw-items-center">
									<SectionHeader tag="h4" childClasses="tw-flex tw-items-center">
										<Status className={`tw-text-${serviceTypeTheme}`}>Active</Status>
										<span>
											Assigned Shifts - {sessionShifts?.length}
										</span>
									</SectionHeader>
								</div>

								<ul className="tw-space-y-md tw-px-md">
										<li>
												<Comment title=''>
													Total Client Hours: {totalClientHours}
												</Comment>
											</li>
								<li>
									<Search className="-tw-mt-sm" id="demsdlkfjhsdkf" onChange={ e => {

											console.log(e?.target.value || "nope");

											if(e?.target.value){
											    const searchText = e?.target.value.toString().toLowerCase();
											    const filtered = sessionShifts.filter(element => {
													
											        // 👇️ using AND (&&) operator
											        return element.patientName.toLowerCase().includes(searchText);
											      });

											      console.log(filtered)

											    setFilteredShifts(filtered);
											} else {
											    setFilteredShifts(sessionShifts);
											}
										}}/>
									</li>
									{ filteredShifts?.map((shift) => {
										return	<>
											<li>
												<Comment title='' className={`${isShiftInsideSession(shift, selectedSession) ? "tw-bg-light-accent": "tw-bg-secondary-light"}`}>
													{shift.patientName}: {shift.displayStartTime} --- {shift.displayEndTime}
												</Comment>
											</li>
										</>
									}) }
								</ul>
							</div>
						</>}



						</div>
				</div>
			</>:<></> }

			{ props?.showShift ? <>
				{/* <div className={`tw-w-full tw-overflow-hidden tw-flex-shrink-0 || tw-flex || tw-bg-${serviceTypeTheme} tw-text-white`}>
				<span className="tw-block tw-whitespace-nowrap tw-max-w-[calc(100%-3.375em-1px)]">
					<span className="tw-block tw-px-6 tw-py-3 tw-leading-[1.125em]  tw-w-full tw-text-ellipsis tw-overflow-hidden">
					 	Coverage Event
					</span>
				</span>
				<Button onClick={ () => {
					closePanel();
				} } className="tw-ml-auto tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-w-[3.375em] tw-border-l tw-border-[rgba(0,0,0,.05)] hover:tw-border-white/10 hover:tw-bg-dark/10" childClasses="tw-flex tw-items-center">
					<span className="tw-speak-none tw-w-[0.875em] tw-h-[0.875em] || tw-inline-flex tw-items-center tw-justify-center">
						<img className="tw-inline-flex tw-w-full tw-h-full tw-object-contain tw-border-0" src="/images/icon-close.svg" />
					</span>
					<span className="tw-hidden">Close</span>
				</Button>
				</div> */}
				<div className="tw-flex tw-flex-row">


						<div className="tw-flex-grow tw-overflow-y-auto tw-text-dark">

						<div className="tw-space-y-xl tw-py-md">

							<div className="tw-space-y-md">
								<div className="tw-px-md tw-flex tw-justify-start tw-items-center">
									<SectionHeader tag="h4" childClasses="tw-flex tw-items-center">
										<Status className={`tw-text-${serviceTypeTheme}`}>Active</Status>
										<span>
											Coverage Information	
										</span>
									</SectionHeader>
								</div>
								<div className="tw-px-md">
								{showShiftUpdateSuccess ? <>
										<div className="tw-pb-sm tw-px-xl">
											<Alert  onClose={closeShiftUpdateAlert}>Shift Updated Successfully</Alert>
										</div>
									</>:<></> }

									{showShiftDeleteSuccess ? <>
											<div className="tw-pb-sm tw-px-xl">
												<Alert  onClose={closeShiftDeleteAlert}>Shift Deleted Successfully</Alert>
											</div>
									</>:<></> }
									<div className={`tw-table tw-w-full${ paragraph1 }`}>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12">
												Coverage Date
											</div>
											{ shiftEditMode ? <>
												<div className="tw-table-cell tw-py-xs">
													<Input
													min="2000-01-01" max="2024-12-31"
													type="date" ref={ shift_date }
													value={ shiftValues.shift_date } onChange={ e => setShiftValues({...shiftValues, "shift_date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
												</div>
											</>:<>
												<div className="tw-table-cell">
													{props?.selectedShift.displayCoverageDate}
												</div>
											</>}
											
											
										</div>
										
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Start time
											</div>
											{ shiftEditMode ? <>
												<div className="tw-table-cell tw-py-xs">
													<Input id="start_time" type="time" onChange={ e => setShiftValues({...shiftValues, "shift_start": e.target.value }) } ref={ shift_start } value={ shiftValues.shift_start } alt className="tw-w-full" />
												</div>
											</>:<>
												<div className="tw-table-cell">
													{props?.selectedShift.displayStartTime}
												</div>
											</> }
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												End time
											</div>
											{ shiftEditMode ? <>
												<div className="tw-table-cell tw-py-xs">
													<Input id="end_time" type="time" onChange={ e => setShiftValues({...shiftValues, "shift_end": e.target.value }) } ref={ shift_end } value={ shiftValues.shift_end } alt className="tw-w-full" />
												</div>
											</>:<>
												<div className="tw-table-cell">
													{props?.selectedShift.displayEndTime}
												</div>
											</> }
											
											
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Shift Type
											</div>
											<div className="tw-table-cell">
													{props?.selectedShift.shiftType}
											</div>
										</div>
										
												{props?.selectedShift.status !== "Open" ? <>
												<div className="tw-table-row">
													<div className="tw-table-cell tw-pr-sm tw-py-xs">
														Provider
													</div>
													<div className="tw-table-cell">
														{props?.selectedShift.providerName}
													</div>
												</div>
												</>:<></>}
										
										
										
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Status
											</div>
											<div className="tw-table-cell">
												{props?.selectedShift.status}
											</div>
										</div>
									</div>

									{ showShiftDeleteConfirm ? <>
									<div className="tw-px-md tw-pb-md tw-pt-lg">
										<Paragraph1>
											<span className="tw-text-[1.111em]">
												Are you sure you want to delete this shift?
											</span>
										</Paragraph1>
									<div className="tw-pt-xl tw-flex tw-justify-between">
										<div className="tw-mr-xs tw-flex-grow tw-flex-shrink-0">
											<ButtonSecondary small className="tw-w-full" onClick={ e => {
												
												e.preventDefault();
												cancelDeleteShift();
											}}>
												Cancel
											</ButtonSecondary>
										</div>
										<div className="tw-ml-xs tw-flex-grow tw-flex-shrink-0">
											<ButtonPrimary small className="tw-w-full" onClick={ e => {
												
												e.preventDefault();
												deleteShift();
											}}>
												Yes
											</ButtonPrimary>
										</div>
									</div>
									</div>
									
									</>:<>
									<div className="tw-pt-xl tw-flex tw-justify-end">
										{ shiftEditMode ? <>
											<div className="tw-mr-sm tw-flex-shrink-0">
												<ButtonSecondary small className="tw-w-full" onClick={ e => {
													e.preventDefault();
													confirmDeleteShift();
												}}>
													Delete
												</ButtonSecondary>
											</div>
											<div className="tw-mr-sm tw-flex-shrink-0">
												<ButtonSecondary small className="tw-w-full" onClick={ e => {
													e.preventDefault();
													discardShift();
												}}>
													Discard
												</ButtonSecondary>
											</div>
											<div className="tw-ml-sm tw-flex-shrink-0">
												<ButtonPrimary small className="tw-w-full" onClick={ e => {
													e.preventDefault();
													updateShift();
												}}>
													Save
												</ButtonPrimary>
											</div>
										</>:<>
										{showShiftLoading ? 
													<>
													<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
													</>: <>
													<div className="tw-ml-xs tw-flex-shrink-0">
														<ButtonPrimary small className="tw-w-full" onClick={ e => {
															e.preventDefault();
															setShiftEditMode(true);
														}}>
															Edit
														</ButtonPrimary>
													</div>
											</>}
											
										</>}
									
									
									</div>
									</> }

									
								</div>
							</div>


						</div>


					</div>
				</div>
			</>:<></> }

			{ props?.showRequirement ? <>
				{/* <div className={`tw-w-full tw-overflow-hidden tw-flex-shrink-0 || tw-flex || tw-bg-${serviceTypeTheme} tw-text-white`}>
				<span className="tw-block tw-whitespace-nowrap tw-max-w-[calc(100%-3.375em-1px)]">
					<span className="tw-block tw-px-6 tw-py-3 tw-leading-[1.125em]  tw-w-full tw-text-ellipsis tw-overflow-hidden">
					 	Coverage Requirement
					</span>
				</span>
				<Button onClick={ () => {
					closePanel();
				} } className="tw-ml-auto tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-w-[3.375em] tw-border-l tw-border-[rgba(0,0,0,.05)] hover:tw-border-white/10 hover:tw-bg-dark/10" childClasses="tw-flex tw-items-center">
					<span className="tw-speak-none tw-w-[0.875em] tw-h-[0.875em] || tw-inline-flex tw-items-center tw-justify-center">
						<img className="tw-inline-flex tw-w-full tw-h-full tw-object-contain tw-border-0" src="/images/icon-close.svg" />
					</span>
					<span className="tw-hidden">Close</span>
				</Button>
				</div> */}
				<div className="tw-flex tw-flex-row">


						<div className="tw-flex-grow tw-overflow-y-auto tw-text-dark">

						<div className="tw-space-y-xl tw-py-md">

							<div className="tw-space-y-md">
								<div className="tw-px-md tw-flex tw-justify-start tw-items-center">
									<SectionHeader tag="h4" childClasses="tw-flex tw-items-center">
										<Status className={`tw-text-${serviceTypeTheme}`}>Active</Status>
										<span>
											Coverage Information	
										</span>
									</SectionHeader>
								</div>
								<div className="tw-px-md">
								{showReqUpdateFailure ? <>
										<div className="tw-pb-sm tw-px-xl">
											<Alert severity="error" onClose={closeReqUpdateFailureAlert}>Error Updating Requirement</Alert>
										</div>
									</>:<></> }

								{showReqCancelFailure ? <>
										<div className="tw-pb-sm tw-px-xl">
											<Alert severity="error" onClose={closeReqCancelFailureAlert}>Error Cancelling Requirement</Alert>
										</div>
									</>:<></> }

								{showReqUpdateSuccess ? <>
										<div className="tw-pb-sm tw-px-xl">
											<Alert  onClose={closeReqUpdateAlert}>Requirement Updated Successfully</Alert>
										</div>
									</>:<></> }
								{showReqCancelSuccess ? <>
										<div className="tw-pb-sm tw-px-xl">
											<Alert  onClose={closeReqCancelAlert}>Requirement Marked Canceled Successfully</Alert>
										</div>
									</>:<></> }
									<div className={`tw-table tw-w-full${ paragraph1 }`}>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs tw-w-7/12">
												Coverage Date
											</div>
											<div className="tw-table-cell">
													{selectedRequirement?.displayCoverageDate}
											</div>
											
											
										</div>
										
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Start time
											</div>
											{ reqEditMode ? <>
												<div className="tw-table-cell tw-py-xs">
													<Input id="start_time" type="time" onChange={ e => setReqValues({...reqValues, "shift_start": e.target.value }) } ref={ req_start } value={ reqValues.shift_start } alt className="tw-w-full" />
												</div>
											</>:<>
												<div className="tw-table-cell">
													{selectedRequirement?.displayStartTime}
												</div>
											</> }
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												End time
											</div>
											{ reqEditMode ? <>
												<div className="tw-table-cell tw-py-xs">
													<Input id="end_time" type="time" onChange={ e => setReqValues({...reqValues, "shift_end": e.target.value }) } ref={ req_end } value={ reqValues.shift_end } alt className="tw-w-full" />
												</div>
											</>:<>
												<div className="tw-table-cell">
													{selectedRequirement?.displayEndTime}
												</div>
											</> }
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Shift Type
											</div>
											<div className="tw-table-cell">
													{selectedRequirement?.shiftType}
											</div>
										</div>
										<div className="tw-table-row">
											<div className="tw-table-cell tw-pr-sm tw-py-xs">
												Status
											</div>
											<div className="tw-table-cell">
												{selectedRequirement?.status}
											</div>
										</div>
									</div>

									{ showReqControls ? <>
										{ showReqCancelConfirm ? <>
									<div className="tw-px-md tw-pb-md tw-pt-lg">
										<Paragraph1>
											<span className="tw-text-[1.111em]">
												Are you sure you want to cancel this requirement?
											</span>
										</Paragraph1>
									<div className="tw-pt-xl tw-flex tw-justify-between">
										<div className="tw-mr-xs tw-flex-grow tw-flex-shrink-0">
											<ButtonSecondary small className="tw-w-full" onClick={ e => {
												
												e.preventDefault();
												discardReq();
											}}>
												Cancel
											</ButtonSecondary>
										</div>
										<div className="tw-ml-xs tw-flex-grow tw-flex-shrink-0">
											<ButtonPrimary small className="tw-w-full" onClick={ e => {
												
												e.preventDefault();
												markReqCanceled();
											}}>
												Yes
											</ButtonPrimary>
										</div>
									</div>
									</div>
									
										</>:<>
										<div className="tw-pt-xl tw-flex tw-justify-end">
											{ reqEditMode ? <>
												<div className="tw-mr-sm tw-flex-shrink-0">
													<ButtonSecondary small className="tw-w-full" onClick={ e => {
														e.preventDefault();
														confirmCancelReq();
													}}>
														Mark Canceled
													</ButtonSecondary>
												</div>
												<div className="tw-mr-sm tw-flex-shrink-0">
													<ButtonSecondary small className="tw-w-full" onClick={ e => {
														e.preventDefault();
														discardReq();
													}}>
														Discard
													</ButtonSecondary>
												</div>
												<div className="tw-ml-sm tw-flex-shrink-0">
													<ButtonPrimary small className="tw-w-full" onClick={ e => {
														e.preventDefault();
														updateReq();
													}}>
														Save
													</ButtonPrimary>
												</div>
											</>:<>
											{showReqLoading ? 
														<>
														<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
														</>: <>
														{appContext?.showScheduling && <div className="tw-ml-xs tw-flex-shrink-0">
															<ButtonPrimary small className="tw-w-full" onClick={ e => {
																e.preventDefault();
																setReqEditMode(true);
															}}>
																Edit
															</ButtonPrimary>
														</div>}
												</>}
												
											</>}
										
										
										</div>
										</> }
									</>:<></>}

									

									
								</div>
							</div>


						</div>


					</div>
				</div>
			</>:<></> }

		</div>
	</>;
}

export default ServicePanel;


