import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ButtonErrorSecondary, ButtonPrimary, ButtonSecondary, ButtonWarningSecondary } from "../../components/buttons/Buttons";
import { Training, Quiz, CourseMaterial, QuizQuestion, TrainingUploadFile, TrainingAssignment } from "../../types/training"; // Import your types here
import { useNavigate } from "react-router-dom";
import { ArrowDownIcon, ArrowUpIcon, CheckIcon, TrashIcon } from "@heroicons/react/24/outline";
import Patient from "../../types/patient";
import { useAppContext } from "../../Context";
import { getActiveClients } from "../../apicalls/schedulingService";
import { getEmployeesForTraining, saveNewTraining, uploadFileToAzureBlobStorage, uploadTrainingFileToBlob, uploadTrainingFilesToBlob } from "../../apicalls/trainingService";
import { read } from "fs";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import SuccessDialog from "../../components/dialogs/successDialog";
import moment, { duration } from "moment";
import { escapeSingleQuotes } from "../../apicalls/announcementService";
import { forEach, set } from "lodash";
import { GenericBreadCrumbs } from "../Scheduling/SchedulingUtils";
import CalendarSelector from "../../components/calendars/CalendarSelector";
import Employee from "../../types/employee";
import { CustomDropdown, EmployeeAssignmentList } from "./CreateTrainingPage";
import { saveNewContinuousEducation } from "../../apicalls/continuousEducationService";
import { extractFileNameWithoutExtension } from "./ManageContinuousEducationPage";


export const CustomAudienceDropdown = ({ options, selectedOptions, onChange, editMode }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionChange = (event) => {
        const value = event.target.value;
        let newSelectedOptions;
        if (selectedOptions.includes(value)) {
            newSelectedOptions = selectedOptions.filter(option => option !== value);
        } else {
            newSelectedOptions = [...selectedOptions, value];
        }
        onChange(newSelectedOptions);
    };

    const handleCloseClick = () => {
        setIsOpen(false);
    };

    const handleSelectAllClick = () => {
        if (selectedOptions.length === options.length) {
            onChange([]);
        } else {
            onChange(options.map(option => option.value));
        }
    };


    return (
        <div className="relative tw-w-full tw-mb-4">
            <div 
                className={`tw-w-full tw-p-2 tw-border ${editMode ? "tw-bg-white": "tw-bg-gray-200"} ${isOpen ? 'tw-border-blue-500' : 'tw-border-gray-300'} tw-rounded tw-cursor-pointer`}
                onClick={() => {
                    if(editMode) {
                        setIsOpen(!isOpen);
                    }
                }}
            >
                {selectedOptions.length > 0 ? `Selected Audience: ${selectedOptions.join(', ')}` : 'Select audience'}
            </div>
            {isOpen && (
                <div className="absolute tw-w-full tw-border tw-border-gray-300 tw-rounded tw-mt-2 tw-bg-white tw-shadow-lg">
                    {options.map(option => (
                        <label key={option.value} className="tw-block tw-px-4 tw-py-2 tw-cursor-pointer tw-flex tw-items-center">
                            <input
                                type="checkbox"
                                value={option.value}
                                checked={selectedOptions.includes(option.value)}
                                onChange={handleOptionChange}
                                className="tw-mr-2"
                            />
                            {option.label}
                        </label>
                    ))}
                    <button 
                        onClick={handleSelectAllClick} 
                        className="tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-cursor-pointer tw-bg-gray-200 tw-text-gray-800"
                    >
                        Select All
                    </button>
                    <button 
                        onClick={handleCloseClick} 
                        className="tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-cursor-pointer tw-bg-gray-200 tw-text-gray-800"
                    >
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

const CreateContinuousTrainingPage = props => {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
          const message = 'Are you sure you want to leave? All saved changes will be lost!';
          event.returnValue = message; // Standard way to set a custom message
          return message; // For some browsers
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

    const [step, setStep] = useState<number>(1);

    const [clients, setClients] = useState<Patient[]>([]);

    const appContext = useAppContext();

    const [showCourseMaterialError, setShowCourseMaterialError] = useState<boolean>(false);
    const [showQuizError, setShowQuizError] = useState<boolean>(false);
    const [showAudienceError, setShowAudienceError] = useState<boolean>(false);

    const [previewMode, setPreviewMode] = useState<boolean>(false);

    const [showNewQuizQuestion, setShowNewQuizQuestion] = useState<boolean>(false);

    const [savingTraining, setSavingTraining] = useState<boolean>(false);

    const [uploadingFile, setUploadingFile] = useState<boolean>(false);

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const [activeEmployees, setActiveEmployees] = useState<Employee[]>([]);

    const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);

    const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
    const [selectedEmployeeID, setSelectedEmployeeID] = useState<string>("");

    const removeSelectedEmployee = (employeeID: string) => {
        setSelectedEmployees(selectedEmployees.filter((employee) => employee.employeeID !== employeeID));

        var newFilteredEmployees = [
            ...filteredEmployees,
            activeEmployees.find((employee) => employee.employeeID === employeeID),
        ]

        setFilteredEmployees(newFilteredEmployees.sort((a, b) => a.firstName.localeCompare(b.firstName)));

    }

    const dismissSuccessDialog = () => {
        setShowSuccessDialog(false);
        navigate('/training/continuous-education-management/draft');
    };

    useLayoutEffect(() => {
        const fetchClients = async () => {
            // Fetch the clients from the API
            const clients = await getActiveClients(appContext.handleAPICallError);
            setClients(clients.sort((a, b) => a.firstName.localeCompare(b.firstName)));

            await fetchEmployees();
        };

        const fetchEmployees = async () => {
            // Fetch the employees from the API
            const employees = await getEmployeesForTraining(appContext.handleAPICallError);

            console.log(employees)

            setActiveEmployees(employees.sort((a, b) => a.firstName.localeCompare(b.firstName)));
            setFilteredEmployees(employees.sort((a, b) => a.firstName.localeCompare(b.firstName)));
        }

        fetchClients();
    }, []);

    const [overviewErrors, setOverviewErrors] = useState({
        trainingName: false,
        trainingDescription: false,
        trainingCategory: false,
        supplementalCategory: false,
        clientID: false,
        validTill: false,
        duration: false,
    });
    
    const [overviewErrorMessages, setOverviewErrorMessages] = useState({
        trainingName: "Training Name is required and must be less than 60 characters",
        trainingDescription: "Training Description is required and must be less than 500 characters",
        trainingCategory: "Training Category is required",
        supplementalCategory: "Supplemental Category is required",
        clientID: "Client Name is required",
        validTill: "Valid Till Date is required",
        duration: "Duration is required",
    });

    const [audienceErrors, setAudienceErrors] = useState({
        targetAudience: false,
    });

    const [audienceErrorMessages, setAudienceErrorMessages] = useState({
        targetAudience: "Audience is required",
    });

    const [courseMaterialErrors, setCourseMaterialErrors] = useState({
        materialType: false,
        materialFile: false,
    });

    const [courseMaterialErrorMessages, setCourseMaterialErrorMessages] = useState({
        materialType: "Course Material Type is required",
        materialFile: "Course Material File is required",
    });

    const [quizQuestionErrors, setQuizQuestionErrors] = useState({
        question: false,
        questionType: false,
        choice1: false,
        choice2: false,
        choice3: false,
        choice4: false,
        correctChoice: false,
        questionImage: false,
    });

    const [quizQuestionErrorMessages, setQuizQuestionErrorMessages] = useState({
        question: "Question is required",
        questionType: "Question Type is required",
        choice1: "Choice 1 is required",
        choice2: "Choice 2 is required",
        choice3: "Choice 3 is required",
        choice4: "Choice 4 is required",
        correctChoice: "Correct Choice is required",
        questionImage: "Question Image is required",
    });

    const [quizErrors, setQuizErrors] = useState({
        passingScore: false,
        maxAttempts: false,
    });

    const [quizErrorMessages, setQuizErrorMessages] = useState({
        passingScore: "Passing Score is required",
        maxAttempts: "Max Attempts is required",
    });

    const validateQuiz = () => {
        let isValid = true;
        var currentQuizErrors = {
            passingScore: false,
            maxAttempts: false,
        };

        if (!training.quiz.passingScore || training.quiz.passingScore < 1 || training.quiz.passingScore > 100) {
            currentQuizErrors.passingScore = true;
            isValid = false;
        }
        if (!training.quiz.maxAttempts || training.quiz.maxAttempts < 1) {
            currentQuizErrors.maxAttempts = true;
            isValid = false;
        }

        setQuizErrors(currentQuizErrors);

        return isValid;
    }

    const formSteps = [
        { id: 1, name: 'Overview', href: '#', status: 'current' },
        { id: 2, name: 'Audience', href: '#', status: 'upcoming'},
        { id: 3, name: 'Content', href: '#', status: 'upcoming' },
        { id: 4, name: 'Quiz', href: '#', status: 'upcoming' },
    ];

    const [steps, setSteps] = useState(formSteps);

    const previewSteps = [
        { id: 1, name: 'Overview', href: '#', status: 'current' },
        { id: 2, name: 'Content', href: '#', status: 'upcoming' },
        { id: 3, name: 'Quiz', href: '#', status: 'upcoming' },
    ]

    let navigate = useNavigate();

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [training, setTraining] = useState<Partial<Training>>({
        trainingName: "",
        trainingDescription: "",
        trainingCategory: "",
        supplementalCategory: "",
        targetAudience: "",
        clientID: "",
        clientName: "",
        dateCreated: new Date(),
        validTill: moment().add(1, 'year').toDate(),
        createdBy: "",
        status: "Draft",
        duration: 30,
        quiz: {
            quizID: "",
            trainingID: "",
            passingScore: 0,
            failingScore: 0,
            questions: [],
            maxAttempts: 3,
        } as Quiz,
        courseMaterials: [] as CourseMaterial[],
    });

    const validateTargetAudience = () => {
        let isValid = true;

        var currentAudienceErrors = {
            targetAudience: false,
        };

        if (selectedAudienceOptions.length === 0) {
            currentAudienceErrors.targetAudience = true;
            isValid = false;
        }

        // if (!training.targetAudience) {
        //     currentAudienceErrors.targetAudience = true;
        //     isValid = false;
        // }

        // if (training.targetAudience === "Select Employees" && selectedEmployees?.length === 0) {
        //     isValid = false;
        //     setShowAudienceError(true);
        // }

        setAudienceErrors(currentAudienceErrors);

        return isValid;
    }

    const handleNextStep = () => {
        switch (step) {
            case 1:
                if (!validateOverview()) {
                    return;
                }
                break;
            case 2:
                if (!validateTargetAudience()) {
                    return;
                }
                break;
            case 3:
                if (training.courseMaterials.length === 0) {
                    setShowCourseMaterialError(true);
                    return;
                } else {
                    setShowCourseMaterialError(false);
                }
                break;
            case 4:
                if (training.quiz.questions.length < 4) {
                    setShowQuizError(true);
                    return;
                } else {
                    setShowQuizError(false);
                }

                break;
            default:
                break;
        }


        setShowAudienceError(false);
        setShowCourseMaterialError(false);
        setShowQuizError(false);
        setStep(step + 1);
    };

    const validateOverview = () => {
        let isValid = true;
        var currentOverviewErrors = {
            trainingName: false,
            trainingDescription: false,
            trainingCategory: false,
            supplementalCategory: false,
            clientID: false,
            validTill: false,
            duration: false,
        };

        if (!training.trainingName) {
            currentOverviewErrors.trainingName = true;
            isValid = false;
        } else if (training.trainingName.length > 60) {
            currentOverviewErrors.trainingName = true;
            isValid = false;
        }


        if (!training.trainingDescription) {
            currentOverviewErrors.trainingDescription = true;
            isValid = false;
        } else if (training.trainingDescription.length > 500) {
            currentOverviewErrors.trainingDescription = true;
            isValid = false;
        }
        if (!training.trainingCategory) {
            currentOverviewErrors.trainingCategory = true;
            isValid = false;
        }

        if (!training.validTill) {
            currentOverviewErrors.validTill = true;
            isValid = false;
        }

        if (!training.duration || training.duration < 1) {
            currentOverviewErrors.duration = true;
            isValid = false;
        }

        setOverviewErrors(currentOverviewErrors);

        return isValid;
    }

    const validateCourseMaterial = () => {
        let isValid = true;
        var currentCourseMaterialErrors = {
            materialType: false,
            materialFile: false,
        };

        if (!newCourseMaterial.materialType) {
            currentCourseMaterialErrors.materialType = true;
            isValid = false;
        }
        if (!newCourseMaterial.materialLink) {
            currentCourseMaterialErrors.materialFile = true;
            isValid = false;
        }

        setCourseMaterialErrors(currentCourseMaterialErrors);

        return isValid;
    }

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        console.log(name, value);

        if(name === "duration") {
            setTraining({
                ...training,
                [name]: Number(value),
            });
        } else {
            setTraining({
                ...training,
                [name]: value,
            });
        }

        if (overviewErrors[name]) {
            setOverviewErrors({
                ...overviewErrors,
                [name]: false,
            });
        }
    };

    const [newQuizQuestion, setNewQuizQuestion] = useState<Partial<QuizQuestion>>({
        question: "",
        questionType: "",
        choice1: "",
        choice2: "",
        choice3: "",
        choice4: "",
        correctChoice: "1",
        questionImage: "",
        questionSequence: 1,
    });

    const handleQuizInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTraining({
            ...training,
            quiz: {
                ...training.quiz,
                [name]: Number(value),
            },
        });

        if (quizErrors[name]) {
            setQuizErrors({
                ...quizErrors,
                [name]: false,
            });
        }
    };

    const handleQuestionTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

        console.log(name, value);

        if (quizQuestionErrors[name]) {
            setQuizQuestionErrors({
                ...quizQuestionErrors,
                [name]: false,
            });
        }

        // check if the question type is multiple choice
        if (name === "questionType" && value === "Single-Choice") {
            console.log("Single Choice");
            setQuizQuestionErrors({
                question: false,
                questionType: false,
                choice1: false,
                choice2: false,
                choice3: false,
                choice4: false,
                correctChoice: false,
                questionImage: false,
            });

            setNewQuizQuestion({
                ...newQuizQuestion,
                choice1: "",
                choice2: "",
                choice3: "",
                choice4: "",
                correctChoice: "1",
                [name]: value,
            });
        } else if (name === "questionType" && value === "True-False") {
            console.log("True/False");
            setQuizQuestionErrors({
                question: false,
                questionType: false,
                choice1: false,
                choice2: false,
                choice3: false,
                choice4: false,
                correctChoice: false,
                questionImage: false,
            });

            setNewQuizQuestion({
                ...newQuizQuestion,
                choice1: "True",
                choice2: "False",
                correctChoice: "1",
                [name]: value,
            });
        } else if (name === "questionType" && value === "Multiple-Choice") {
            console.log("Multiple Choice");

            setQuizQuestionErrors({
                question: false,
                questionType: false,
                choice1: false,
                choice2: false,
                choice3: false,
                choice4: false,
                correctChoice: false,
                questionImage: false,
            });

            setNewQuizQuestion({
                ...newQuizQuestion,
                choice1: "",
                choice2: "",
                choice3: "",
                choice4: "",
                correctChoice: "1",
                [name]: value,
            });

            setMultipleChoiceOptions([1]);
        } else if (name === "questionType" && value === "Image-Based") {
            console.log("Image Based");

            setQuizQuestionErrors({
                question: false,
                questionType: false,
                choice1: false,
                choice2: false,
                choice3: false,
                choice4: false,
                correctChoice: false,
                questionImage: false,
            });

            setNewQuizQuestion({
                ...newQuizQuestion,
                choice1: "a",
                choice2: "b",
                choice3: "c",
                choice4: "d",
                correctChoice: "1",
                [name]: value,
            });
        }
        
        else {
            setNewQuizQuestion({
                ...newQuizQuestion,
                [name]: value,
            });
        }
    }

    const handleQuizChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        if (quizQuestionErrors[name]) {
            setQuizQuestionErrors({
                ...quizQuestionErrors,
                [name]: false,
            });
        }

        setNewQuizQuestion({
            ...newQuizQuestion,
            [name]: value,
        });

        
    };

    const validateQuizQuestion = () => {
        let isValid = true;
        var currentQuizQuestionErrors = {
            question: false,
            questionType: false,
            choice1: false,
            choice2: false,
            choice3: false,
            choice4: false,
            correctChoice: false,
            questionImage: false,
        };

        if (!newQuizQuestion.question) {
            currentQuizQuestionErrors.question = true;
            isValid = false;
        }
        if (!newQuizQuestion.questionType) {
            currentQuizQuestionErrors.questionType = true;
            isValid = false;
        } else if (newQuizQuestion.questionType === "Multiple-Choice") {
            if (multipleChoiceOptions.length === 0) {
                currentQuizQuestionErrors.correctChoice = true;
                isValid = false;
            } else {
                if (multipleChoiceOptions.length > 1) {
                    newQuizQuestion.correctChoice = multipleChoiceOptions.join("|");
                } else {
                    newQuizQuestion.correctChoice = multipleChoiceOptions[0].toString();
                }
            }
        }

        if (!newQuizQuestion.choice1) {
            currentQuizQuestionErrors.choice1 = true;
            isValid = false;
        }
        if (!newQuizQuestion.choice2) {
            currentQuizQuestionErrors.choice2 = true;
            isValid = false;
        }

        if (newQuizQuestion?.questionType !== "True-False") {

            if (!newQuizQuestion.choice3) {
                currentQuizQuestionErrors.choice3 = true;
                isValid = false;
            }
            if (!newQuizQuestion.choice4) {
                currentQuizQuestionErrors.choice4 = true;
                isValid = false;
            }
        }
        if (!newQuizQuestion.correctChoice) {
            currentQuizQuestionErrors.correctChoice = true;
            isValid = false;
        }

        if (newQuizQuestion?.questionType === "Image-Based" && !newQuizQuestion.questionImage) {
            currentQuizQuestionErrors.questionImage = true;
            isValid = false;
        }

        setQuizQuestionErrors(currentQuizQuestionErrors);

        return isValid;
    }

    const [uploadingImageFile, setUploadingImageFile] = useState<boolean>(false);

    const handleAddQuizQuestion = () => {
        if (!validateQuizQuestion()) {
            return;
        }

        setTraining({
            ...training,
            quiz: {
                ...training.quiz,
                questions: [
                    ...training.quiz.questions,
                    {
                        ...newQuizQuestion,
                        questionID: Math.random().toString(36).substr(2, 9), // Generate a random ID
                        quizID: training.quiz.quizID,
                        questionSequence: training.quiz.questions.length + 1,
                    } as QuizQuestion,
                ],
            },
        });
        setNewQuizQuestion({
            question: "",
            choice1: "",
            choice2: "",
            choice3: "",
            choice4: "",
            correctChoice: "1",
            questionImage: "",
        });

        setShowQuizError(false);
        setShowNewQuizQuestion(false);
    };

    const handleRemoveQuizQuestion = (questionID: string) => {
        const currentQuestions = [...training.quiz.questions];

        const index = currentQuestions.findIndex((question) => question.questionID === questionID);

        if (index > -1) {
            currentQuestions.splice(index, 1);
        }

        forEach(currentQuestions, (question, index) => {
            question.questionSequence = index + 1;
        });

        setTraining({
            ...training,
            quiz: {
                ...training.quiz,
                questions: currentQuestions,
            },
        });
    }

    const [newCourseMaterial, setNewCourseMaterial] = useState<Partial<CourseMaterial>>({
        materialType: "",
        materialLink: "",
    });

    const [multipleChoiceOptions, setMultipleChoiceOptions] = useState<number[]>([1]);

    const [selectedAudienceOptions, setSelectedAudienceOptions] = useState<string[]>([]);


    const handleCourseMaterialChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewCourseMaterial({
            ...newCourseMaterial,
            [name]: value,
        });

        if (courseMaterialErrors[name]) {
            setCourseMaterialErrors({
                ...courseMaterialErrors,
                [name]: false,
            });
        }
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onloadend = async () => {
                const uploadFile: TrainingUploadFile = {
                    fileName: file.name,
                    base64String: reader.result as string,
                };

                //console.log(reader.result as string);

                // Call the uploadFileToBlob function here
                // var url = await uploadTrainingFileToBlob(uploadFile, appContext.handleAPICallError);

                //var url = await uploadFileToAzureBlobStorage(file.name, reader.result as string); 

                const fileUploadDTO = {
                    "FileName": file.name,
                    "FileType": file.type,
                    "Base64FileContent": reader.result
                };

                setUploadingFile(true);

                var blobURL =  await uploadTrainingFilesToBlob(fileUploadDTO, appContext.handleAPICallError);

                console.log(blobURL.data);

                setNewCourseMaterial({
                    ...newCourseMaterial,
                    materialLink: blobURL.data,
                    materialName: file.name,
                });

                setUploadingFile(false);
            };
            reader.readAsDataURL(file);

            if (courseMaterialErrors.materialFile) {
                setCourseMaterialErrors({
                    ...courseMaterialErrors,
                    materialFile: false,
                });
            }
        }
    };

    const handleQuizFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onloadend = async () => {
                const uploadFile: TrainingUploadFile = {
                    fileName: file.name,
                    base64String: reader.result as string,
                };

                const fileUploadDTO = {
                    "FileName": file.name,
                    "FileType": file.type,
                    "Base64FileContent": reader.result
                };

                setUploadingImageFile(true);

                var blobURL =  await uploadTrainingFilesToBlob(fileUploadDTO, appContext.handleAPICallError);

                console.log(blobURL.data);

                setNewQuizQuestion({
                    ...newQuizQuestion,
                    questionImage: blobURL.data,
                });

                setUploadingImageFile(false);
            };
            reader.readAsDataURL(file);

            if (quizQuestionErrors.questionImage) {
                setQuizQuestionErrors({
                    ...quizQuestionErrors,
                    questionImage: false,
                });
            }
        }
    };

    const handleAddCourseMaterial = () => {
        if (!validateCourseMaterial()) {
            return;
        }

        setTraining({
            ...training,
            courseMaterials: [
                ...training.courseMaterials,
                {
                    ...newCourseMaterial,
                    courseMaterialID: Math.random().toString(36).substr(2, 9), // Generate a random ID
                    trainingID: training.trainingID,
                    materialSequence: training.courseMaterials.length + 1,
                } as CourseMaterial,
            ],
        });
        setNewCourseMaterial({ materialType: "", materialLink: "" });

        // Clear the file input
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

        setShowCourseMaterialError(false);
    };

    const handleRemoveCourseMaterial = (courseMaterialID: string) => {
        var newCourseMaterials = [...training.courseMaterials];

        // make sure the material sequence are updated as one is removed

        // Find the material:
        const currentMaterial = newCourseMaterials.find(
            material => material.courseMaterialID === courseMaterialID
        );

        if (!currentMaterial) {
            return;
        }

        // Remove the material
        newCourseMaterials = newCourseMaterials.filter(material => material.courseMaterialID !== courseMaterialID);

        // Update the sequence of the remaining materials
        newCourseMaterials.forEach((material, index) => {
            material.materialSequence = index + 1;
        });

        setTraining({
            ...training,
            courseMaterials: newCourseMaterials,
        });
    };

    

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setTraining({
            ...training,
            [name]: value,
        });

        if (overviewErrors[name]) {
            setOverviewErrors({
                ...overviewErrors,
                [name]: false,
            });
        }

        if (audienceErrors[name]) {
            setAudienceErrors({
                ...audienceErrors,
                [name]: false,
            });

            
        }
    };

    const handleSubmit = async () => {
        setSavingTraining(true);

        training.trainingName = escapeSingleQuotes(training.trainingName);
        training.trainingDescription = escapeSingleQuotes(training.trainingDescription);

        training.quiz.questions.forEach((question) => {
            question.question = escapeSingleQuotes(question.question);
            question.choice1 = escapeSingleQuotes(question.choice1);
            question.choice2 = escapeSingleQuotes(question.choice2);
            question.choice3 = escapeSingleQuotes(question.choice3);
            question.choice4 = escapeSingleQuotes(question.choice4);
        });

        // Save the training
        console.log(training);

        // setTimeout(() => {
        //     setSavingTraining(false);
        //     setIsSubmitted(true);
        //     setShowSuccessDialog(true);
        // }, 2000);

        //const result = await saveNewTraining(training as Training, appContext.handleAPICallError);

        const result = await saveNewContinuousEducation(training as Training, appContext.handleAPICallError);

        setSavingTraining(false);
        setIsSubmitted(true);
        setShowSuccessDialog(true);
    };

    const goToPreview = () => {
        // Navigate to the preview page
        //navigate('/training/training-management/preview');
        if (!validateQuiz()) {
            return;
        }

        if (training.quiz.questions.length < 1) {
            setShowQuizError(true);
            return;
        } else {
            setShowQuizError(false);
        }

        training.targetAudience = selectedAudienceOptions.join(" | ");

        // var client = clients.find((client) => client.patientID === training.clientID);

        // training.clientName = client ? `${client.firstName} ${client.lastName}` : "";

        // training.validTill = moment().add(1, 'year').toDate();

        setCurrentStep(0);

        setCourseMaterials(training.courseMaterials);
        setQuiz(training.quiz);

        training.trainingAssignments = [];

        console.log(training);

        setSteps(previewSteps);

        setPreviewMode(true);
    }

    const options = [
        { value: 1, label: 'Choice 1' },
        { value: 2, label: 'Choice 2' },
        { value: 3, label: 'Choice 3' },
        { value: 4, label: 'Choice 4' },
    ];

    const audienceOptions = [
        { value: "Remote Support Associates", label: 'Remote Support Associates' },
        { value: "Direct Support Providers", label: 'Direct Support Providers' },
        { value: "Client Services Specialists", label: 'Client Services Specialists' },
        { value: "Quality Assurance Specialists", label: 'Quality Assurance Specialists' },
        { value: "Quality Assurance Specialist Interns", label: 'Quality Assurance Specialist Interns' },
        { value: "Client Services Specialist Interns", label: 'Client Services Specialist Interns' },
        { value: "Basic HR", label: 'Basic HR' },
        { value: "Full HR", label: 'Full HR' },
        { value: "Director Of Operations", label: 'Director Of Operations' },
    ];

    const handleReorderCourseMaterial = (
        currentSequence: number,
        direction: 'up' | 'down'
      ): void => {
        const newCourseMaterials = [...training.courseMaterials];
        
        // Sort the materials by sequence to ensure correct order
        newCourseMaterials.sort((a, b) => a.materialSequence - b.materialSequence);
        
        // Find the material: 
        const currentMaterial = newCourseMaterials.find(
            material => material.materialSequence === currentSequence
            );
        
        if (!currentMaterial) {
            return;
        }
      
        if (direction === 'up') {
            if (currentSequence === 1) {
              return;
            }

            // Swap the current item with the one above it
            const materialAbove = newCourseMaterials.find(
                material => material.materialSequence === currentSequence - 1
            );

            if (!materialAbove) {
                return;
            }

            console.log(materialAbove);

            const tempSequence = currentMaterial.materialSequence;
            currentMaterial.materialSequence = materialAbove.materialSequence;
            materialAbove.materialSequence = tempSequence;

        }
        else if (direction === 'down') {
          // Swap the current item with the one below it

            if (currentSequence === newCourseMaterials.length) {
                return;
            }

            const materialBelow = newCourseMaterials.find(
                material => material.materialSequence === currentSequence + 1
            );

            if (!materialBelow) {
                return;
            }

            console.log(materialBelow);

            const tempSequence = currentMaterial.materialSequence;
            currentMaterial.materialSequence = materialBelow.materialSequence;
            materialBelow.materialSequence = tempSequence;

         
        }
      
        // Re-sort the array based on materialSequence
        //newCourseMaterials.sort((a, b) => a.materialSequence - b.materialSequence);

        // Update State

        setTraining({
            ...training,
            courseMaterials: newCourseMaterials,
        });
      
       
      };

    const handleReorderQuizQuestion = (
        currentSequence: number,
        direction: 'up' | 'down'
      ): void => {
        const newQuestions = [...training.quiz.questions];
        
        // Sort the questions by sequence to ensure correct order
        newQuestions.sort((a, b) => a.questionSequence - b.questionSequence);
        
        // Find the question: 
        const currentQuestion = newQuestions.find(
            question => question.questionSequence === currentSequence
            );
        
        if (!currentQuestion) {
            return;
        }
      
        if (direction === 'up') {
            if (currentSequence === 1) {
              return;
            }

            // Swap the current item with the one above it
            const questionAbove = newQuestions.find(
                question => question.questionSequence === currentSequence - 1
            );

            if (!questionAbove) {
                return;
            }

            console.log(questionAbove);

            const tempSequence = currentQuestion.questionSequence;
            currentQuestion.questionSequence = questionAbove.questionSequence;
            questionAbove.questionSequence = tempSequence;

        }
        else if (direction === 'down') {
          // Swap the current item with the one below it

            if (currentSequence === newQuestions.length) {
                return;
            }

            const questionBelow = newQuestions.find(
                question => question.questionSequence === currentSequence + 1
            );

            if (!questionBelow) {
                return;
            }

            console.log(questionBelow);

            const tempSequence = currentQuestion.questionSequence;
            currentQuestion.questionSequence = questionBelow.questionSequence;
            questionBelow.questionSequence = tempSequence;

         
        }
      
        // Re-sort the array based on questionSequence
        //newQuestions.sort((a, b) => a.questionSequence - b.questionSequence);

        // Update State

        setTraining({
            ...training,
            quiz: {
                ...training.quiz,
                questions: newQuestions,
            },
        });
      
       
    }

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div className="tw-space-y-4">
                        {/* <h2 className="tw-text-xl tw-font-semibold">Basic Information</h2> */}
                        <label htmlFor="trainingName">Name</label>
                        <input
                            type="text"
                            id="trainingName"
                            name="trainingName"
                            placeholder="Training Name"
                            value={training.trainingName}
                            onChange={handleInputChange}
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.trainingName ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded`}
                        />
                        <p className="tw-text-gray-400 tw-text-sm">Character Count: {training.trainingName.length} / Max: 60</p>
                        {overviewErrors.trainingName && <p className="tw-text-red-500">{overviewErrorMessages.trainingName}</p>}
                        
                        <label htmlFor="trainingCategory">Category</label>
                        <select
                            id="trainingCategory"
                            name="trainingCategory"
                            value={training.trainingCategory}
                            onChange={handleSelectChange}
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.trainingCategory ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded`}
                        >
                            <option value="" disabled>Select Training Category</option>
                            <option value="Technical">Technical</option>
                            <option value="Role Specific">Role Specific</option>
                            <option value="Code Of Conduct">Code Of Conduct</option>
                            <option value="Administration">Administration</option>
                            <option value="Cyber Security">Cyber Security</option>
                            <option value="Ethical AI">Ethical AI</option>
                            {/* <option value="Agency Training">Agency Training</option>
                            <option value="State Mandated Training">State Mandated Training</option> */}
                        </select>
                        {overviewErrors.trainingCategory && <p className="tw-text-red-500">{overviewErrorMessages.trainingCategory}</p>}
                        
                        <label htmlFor="trainingDescription">Description</label>
                        <textarea
                            id="trainingDescription"
                            name="trainingDescription"
                            placeholder="Training Description"
                            value={training.trainingDescription}
                            onChange={handleInputChange}
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.trainingDescription ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded`}
                        />
                        <p className="tw-text-gray-400 tw-text-sm">Character Count: {training.trainingName.length} / Max: 500</p>
                        {overviewErrors.trainingDescription && <p className="tw-text-red-500">{overviewErrorMessages.trainingDescription}</p>}

                        <label htmlFor="duration">Duration (in minutes)</label>
                        <input
                            type="number"
                            id="duration"
                            name="duration"
                            placeholder="Training Duration (in minutes)"
                            value={training.duration}
                            onChange={handleInputChange}
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.duration ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded`}
                        />
                        {overviewErrors.duration && <p className="tw-text-red-500">{overviewErrorMessages.duration}</p>}

                            <CalendarSelector id="validTill" className={overviewErrors.validTill ? '_form-error' : ''} 
                             name="Valid Until:" value={ moment(training.validTill).format("MM-DD-YYYY") || null } 
                             onChange={ e => {
                                e.preventDefault();
                                console.log(e.target.value);
                                setTraining({...training, "validTill": moment(e.target.value).toDate()});
                            }}/>
                            {overviewErrors.validTill && <p className="tw-text-red-500">{overviewErrorMessages.validTill}</p>}
                       
                      

                        {/* <label htmlFor="validTill">Valid Till</label>
                        <input
                            type="date"
                            id="validTill"
                            name="validTill"
                            value={moment(training.validTill).format("YYYY-MM-DD")}
                            onChange={(e) => {
                                e.preventDefault();

                                handleInputChange(e);

                            }}
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.validTill ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded`}
                        />
                        {overviewErrors.validTill && <p className="tw-text-red-500">{overviewErrorMessages.validTill}</p>} */}

                    </div>
                );
            case 2:
                return (
                    <div className="tw-space-y-4">
                        {/* <h2 className="tw-text-xl tw-font-semibold">Target Audience</h2> */}
                        <label htmlFor="targetAudience">Audience</label>
                        <CustomAudienceDropdown
                                    options={audienceOptions}
                                    selectedOptions={selectedAudienceOptions}
                                    onChange={setSelectedAudienceOptions}
                                    editMode={true}
                                />
                        {audienceErrors.targetAudience && <p className="tw-text-red-500">{audienceErrorMessages.targetAudience}</p>}
                        
                        

                        {<p className="tw-text-amber-400">Note: The assigned employees (audience) are not notified via email until the training has been published!</p>}

                        {/* {training.targetAudience === "Select Employees" && (
                            <>
                                <div className="tw-mt-4">Add Employees</div>

                                <div className="tw-flex tw-items-center tw-space-x-4">
                                <select 
                                    id="employees"
                                    name="employees"
                                    value={selectedEmployeeID}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        const { value } = e.target;
                                        setSelectedEmployeeID(value);
                                    }}
                                    className="tw-w-1/2 tw-p-2 tw-border tw-border-gray-300 tw-rounded"
                                    >
                                    <option value="" disabled>Select Employee</option>
                                    {filteredEmployees.map((employee, index) => (
                                        <option key={index} value={employee.employeeID}>{employee.firstName} {employee.lastName}</option>
                                    ))}
                                </select>

                                <ButtonPrimary onClick={() => {
                                    const employee = activeEmployees.find((employee) => employee.employeeID === selectedEmployeeID);

                                    if (employee) {
                                        if (selectedEmployees.find((emp) => emp.employeeID === employee.employeeID)) {
                                            return;
                                        }
                                        setSelectedEmployees([...selectedEmployees, employee]);

                                        // Remove the employee from the active employees list
                                        setFilteredEmployees(filteredEmployees.filter((emp) => emp.employeeID !== employee.employeeID));
                                    }

                                    setSelectedEmployeeID("");
                                    setShowAudienceError(false);
                                }}>Add Employee</ButtonPrimary>

                                </div>


                                
                                <EmployeeAssignmentList employees={selectedEmployees} removeEmployee={removeSelectedEmployee} editMode={true}/>
                            </>

                        )} */}
                    </div>
                );
            case 3:
                return (
                    <div className="tw-space-y-4">
                        {/* <h2 className="tw-text-xl tw-font-semibold">Course Materials</h2> */}
                        <div className="tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-mb-4">
                            <label htmlFor="materialType">Material Type</label>
                            <select
                                name="materialType"
                                value={newCourseMaterial.materialType}
                                onChange={handleCourseMaterialChange}
                                className={`tw-w-full tw-p-2 tw-border ${courseMaterialErrors.materialType ?  "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            >
                                <option value="" disabled>Select Material Type</option>
                                <option value="Document">Document</option>
                                {/* <option value="Slide">Slide</option> */}
                                <option value="Video">Video</option>
                            </select>
                            {courseMaterialErrors.materialType && <p className="tw-text-red-500">{courseMaterialErrorMessages.materialType}</p>}
                            <input
                                type="file"
                                name="materialLink"
                                onChange={handleFileUpload}
                                className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-4"
                                ref={fileInputRef}
                                accept=".pdf, .mp4"
                            />
                            {courseMaterialErrors.materialFile && <p className="tw-text-red-500">{courseMaterialErrorMessages.materialFile}</p>}
                            {uploadingFile ? <LoadingSpinner /> : <ButtonPrimary
                                onClick={handleAddCourseMaterial}
                            >
                                Add Course Material
                            </ButtonPrimary>}
                        </div>
                        {training?.courseMaterials &&
                        [...training.courseMaterials]
                        .sort((a, b) => a.materialSequence - b.materialSequence)
                        .map((material, index) => (
                            <div key={index} className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-mb-4">
                                <div className="tw-flex tw-justify-between tw-items-center">
                                    <h3 className="tw-text-lg tw-font-semibold">
                                    {material.materialType} - {material.materialName}
                                    </h3>
                                    
                                    <div className="tw-flex tw-space-x-2">
                                        <button
                                        disabled={index === 0}
                                        onClick={() => handleReorderCourseMaterial(material.materialSequence, 'up')}
                                        className="tw-p-1 tw-rounded tw-bg-gray-200 hover:tw-bg-gray-300 disabled:tw-opacity-50"
                                        >
                                        <ArrowUpIcon className="tw-h-5 tw-w-5" />
                                        </button>
                                        <button
                                        onClick={() => handleReorderCourseMaterial(material.materialSequence, 'down')}
                                        disabled={index === training.courseMaterials.length - 1}
                                        className="tw-p-1 tw-rounded tw-bg-gray-200 hover:tw-bg-gray-300 disabled:tw-opacity-50"
                                        >
                                        <ArrowDownIcon className="tw-h-5 tw-w-5" />
                                        </button>
                                    </div>
                                    
                                </div>
                                {material.materialType === "Document" && (
                                    <a href={material.materialLink} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500">
                                        View Document
                                    </a>
                                )}
                                {material.materialType === "Slide" && (
                                    <a href={material.materialLink} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500">
                                        View Slide
                                    </a>
                                )}
                                {material.materialType === "Video" && (
                                    <a href={material.materialLink} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500">
                                        View Video
                                    </a>
                                )}
                                <ButtonErrorSecondary className="tw-mt-4 tw-max-w-250px" onClick={() => handleRemoveCourseMaterial(material.courseMaterialID)}>Remove Course Material</ButtonErrorSecondary>
                            </div>
                        ))}
                    </div>
                );
            case 4:
                return (
                    <div className="tw-space-y-4">
                        {/* <h2 className="tw-text-xl tw-font-semibold">Quiz</h2> */}
                        <div className="tw-space-y-2">
                            <label htmlFor="passingScore">Passing Score (%)</label>
                            <input
                                type="number"
                                name="passingScore"
                                id="passingScore"
                                placeholder="Passing Score (%)"
                                value={training.quiz.passingScore}
                                onChange={handleQuizInputChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizErrors.passingScore ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded`}
                            />
                            {quizErrors.passingScore && <p className="tw-text-red-500">{quizErrorMessages.passingScore}</p>}
                            <label htmlFor="maxAttempts">Max Attempts (default 3)</label>
                            <input
                                type="number"
                                name="maxAttempts"
                                id="maxAttempts"
                                value={training.quiz.maxAttempts}
                                readOnly
                                className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-4"
                                title="Max Attempts (default 3)"
                            />
                            {quizErrors.maxAttempts && <p className="tw-text-red-500">{quizErrorMessages.maxAttempts}</p>}
                        </div>
                        {showNewQuizQuestion ? <>
                        <div className="tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-mb-4">
                            <label htmlFor="questionType">Question Type</label>
                            <select
                                name="questionType"
                                id="questionType"
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.questionType ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                onChange={handleQuestionTypeChange}
                            >
                                <option value="">Select Question Type</option>
                                <option value="Single-Choice">Single Correct Choice</option>
                                <option value="Multiple-Choice">Multiple Correct Choice</option>
                                <option value="True-False">True/False</option>
                                <option value="Image-Based">Image Based</option>
                            </select>
                            {quizQuestionErrors.questionType && <p className="tw-text-red-500">{quizQuestionErrorMessages.questionType}</p>}
                            <label htmlFor="question">Question {training.quiz?.questions ? training.quiz?.questions.length + 1 : 1 }</label>
                            {/* <input
                                type="text"
                                name="question"
                                id="question"
                                placeholder="Question"
                                value={newQuizQuestion.question}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.question ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            /> */}
                            <textarea
                                name="question"
                                id="question"
                                placeholder="Question"
                                value={newQuizQuestion.question}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.question ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                rows={3} // You can adjust this value to set the initial number of visible text lines
                            />
                            {quizQuestionErrors.question && <p className="tw-text-red-500">{quizQuestionErrorMessages.question}</p>}
                            
                            {newQuizQuestion?.questionType === "Image-Based" && <>
                                <label htmlFor="questionImage">Question Image</label>
                                <input
                                    type="file"
                                    name="questionImage"
                                    onChange={handleQuizFileUpload}
                                    className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-4"
                                    ref={fileInputRef}
                                    accept=".png, .jpg, .jpeg"
                                />
                                {quizQuestionErrors.questionImage && <p className="tw-text-red-500">{quizQuestionErrorMessages.questionImage}</p>}
                                {uploadingImageFile && <LoadingSpinner /> }

                                {newQuizQuestion.questionImage && (
                                    <div className="tw-flex tw-justify-between">
                                        <img src={newQuizQuestion.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />   
                                    </div>
                                     
                                )}
                            </>}
                            
                            {newQuizQuestion?.questionType && <>
                            <label htmlFor="choice1">Choice 1</label>
                            <input
                                type="text"
                                name="choice1"
                                id="choice1"
                                placeholder="Choice 1"
                                value={newQuizQuestion.choice1}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.choice1 ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            />
                            {quizQuestionErrors.choice1 && <p className="tw-text-red-500">{quizQuestionErrorMessages.choice1}</p>}
                            <label htmlFor="choice2">Choice 2</label>
                            <input
                                type="text"
                                name="choice2"
                                id="choice2"
                                placeholder="Choice 2"
                                value={newQuizQuestion.choice2}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.choice2 ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            />
                            {quizQuestionErrors.choice2 && <p className="tw-text-red-500">{quizQuestionErrorMessages.choice2}</p>}
                            {newQuizQuestion?.questionType !== "True-False" && <>
                            <label htmlFor="choice3">Choice 3</label>
                            <input
                                type="text"
                                name="choice3"
                                id="choice3"
                                placeholder="Choice 3"
                                value={newQuizQuestion.choice3}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.choice3 ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            />
                            {quizQuestionErrors.choice3 && <p className="tw-text-red-500">{quizQuestionErrorMessages.choice3}</p>}
                            <label htmlFor="choice4">Choice 4</label>
                            <input
                                type="text"
                                name="choice4"
                                id="choice4"
                                placeholder="Choice 4"
                                value={newQuizQuestion.choice4}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.choice4 ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            />
                            {quizQuestionErrors.choice4 && <p className="tw-text-red-500">{quizQuestionErrorMessages.choice4}</p>}
                            </>}
                            {newQuizQuestion?.questionType === "Single-Choice" && <>
                                <label htmlFor="correctChoice">Correct Choice</label>
                                <select
                                    name="correctChoice"
                                    id="correctChoice"
                                    value={newQuizQuestion.correctChoice}
                                    onChange={handleQuizChange}
                                    className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.correctChoice ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                >
                                    <option value={1}>Choice 1</option>
                                    <option value={2}>Choice 2</option>
                                    <option value={3}>Choice 3</option>
                                    <option value={4}>Choice 4</option>
                                </select>
                                {quizQuestionErrors.correctChoice && <p className="tw-text-red-500">{quizQuestionErrorMessages.correctChoice}</p>}
                            </>}

                            {newQuizQuestion?.questionType === "Multiple-Choice" && <>
                            <label htmlFor="correctChoice">Correct Choices</label>
                                <CustomDropdown
                                    options={options}
                                    selectedOptions={multipleChoiceOptions}
                                    onChange={setMultipleChoiceOptions}
                                />
                                {quizQuestionErrors.correctChoice && (
                                    <p className="tw-text-red-500">{quizQuestionErrorMessages.correctChoice}</p>
                                )}
                            </>}

                            {newQuizQuestion?.questionType === "True-False" && <>
                            <label htmlFor="correctChoice">Correct Choice</label>
                                <select
                                    name="correctChoice"
                                    id="correctChoice"
                                    value={newQuizQuestion.correctChoice}
                                    onChange={handleQuizChange}
                                    className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.correctChoice ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                >
                                    <option value={1}>True</option>
                                    <option value={2}>False</option>
                                </select>
                                {quizQuestionErrors.correctChoice && <p className="tw-text-red-500">{quizQuestionErrorMessages.correctChoice}</p>}
                            </>}

                            {newQuizQuestion?.questionType === "Image-Based" && <>
                                <label htmlFor="correctChoice">Correct Choice</label>
                                <select
                                    name="correctChoice"
                                    id="correctChoice"
                                    value={newQuizQuestion.correctChoice}
                                    onChange={handleQuizChange}
                                    className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.correctChoice ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                >
                                    <option value={1}>Choice 1</option>
                                    <option value={2}>Choice 2</option>
                                    <option value={3}>Choice 3</option>
                                    <option value={4}>Choice 4</option>
                                </select>
                                {quizQuestionErrors.correctChoice && <p className="tw-text-red-500">{quizQuestionErrorMessages.correctChoice}</p>}
                            </>}

                            </>}

                            <div className="tw-flex tw-justify-between tw-mt-5">
                                <ButtonErrorSecondary onClick={() => setShowNewQuizQuestion(false)}>Cancel</ButtonErrorSecondary>

                                <ButtonPrimary
                                    onClick={handleAddQuizQuestion}
                                >
                                    Add Quiz Question
                                </ButtonPrimary>
                            </div>
                        </div>
                        </>:<>
                        <ButtonPrimary
                            onClick={() => {
                                setQuizQuestionErrors({
                                    question: false,
                                    questionType: false,
                                    choice1: false,
                                    choice2: false,
                                    choice3: false,
                                    choice4: false,
                                    correctChoice: false,
                                    questionImage: false,
                                });

                                setNewQuizQuestion({
                                    question: "",
                                    questionType: "",
                                    choice1: "",
                                    choice2: "",
                                    choice3: "",
                                    choice4: "",
                                    correctChoice: "1",
                                    questionImage: "",
                                });


                                setShowNewQuizQuestion(true);
                            


                            }}
                        >Add New Quiz Question</ButtonPrimary>
                        </>}
                        {training?.quiz &&
                        [...training.quiz.questions]
                        .sort((a, b) => a.questionSequence - b.questionSequence)
                        .map((question, index) => (
                            <div key={index} className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-mb-4">
                                <div className="tw-flex tw-justify-between tw-items-center">
                                <h3 className="tw-text-lg tw-font-semibold">Question {index + 1}</h3>
                                
                                    <div className="tw-flex tw-space-x-2">
                                        <button
                                        disabled={index === 0}
                                        onClick={() => handleReorderQuizQuestion(question.questionSequence, 'up')}
                                        className="tw-p-1 tw-rounded tw-bg-gray-200 hover:tw-bg-gray-300 disabled:tw-opacity-50"
                                        >
                                        <ArrowUpIcon className="tw-h-5 tw-w-5" />
                                        </button>
                                        <button
                                        onClick={() => handleReorderQuizQuestion(question.questionSequence, 'down')}
                                        disabled={index === training.courseMaterials.length - 1}
                                        className="tw-p-1 tw-rounded tw-bg-gray-200 hover:tw-bg-gray-300 disabled:tw-opacity-50"
                                        >
                                        <ArrowDownIcon className="tw-h-5 tw-w-5" />
                                        </button>
                                    </div>
                                    
                                    </div>
                                <p>{question.questionType}</p>
                                <p>{question.question}</p>
                                {question.questionImage && (
                                    <div className="tw-flex tw-justify-between">
                                        <img src={question.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />   
                                    </div>
                                )}
                                <ul className="tw-list-decimal tw-pl-5">
                                    <li>{question.choice1}</li>
                                    <li>{question.choice2}</li>
                                    {question.questionType !== "True-False" && <li>{question.choice3}</li>}
                                    {question.questionType !== "True-False" && <li>{question.choice4}</li>}
                                </ul>
                                <p>Correct Choice: {question.correctChoice}</p>
                                <ButtonErrorSecondary className="tw-mt-4 tw-max-w-250px" onClick={() => handleRemoveQuizQuestion(question.questionID)}>Remove Quiz Question</ButtonErrorSecondary>
                            </div>
                        ))}
                    </div>
                );
            default:
                return null;
        }
    };

    const renderProgressBar = () => {

        if(!previewMode) {
            return <nav aria-label="Progress">
                    <ol role="list" className="tw-divide-y tw-divide-gray-300 tw-rounded-md tw-border tw-border-gray-300 md:tw-flex md:tw-divide-y-0 tw-mb-md">
                        {steps.map((currentStep, stepIdx) => (
                        <li key={currentStep.name} className="tw-relative md:tw-flex md:tw-flex-1">
                            {step > currentStep.id ? (
                            <div className="tw-group tw-flex tw-w-full tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary group-hover:tw-bg-indigo-800">
                                    <CheckIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">{currentStep.name}</span>
                                </span>
                            </div>
                            ) : currentStep.id === step ? (
                            <div className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium" aria-current="step">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-primary">
                                <span className="tw-text-primary">{currentStep.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-primary">{currentStep.name}</span>
                            </div>
                            ) : (
                            <div className="tw-group tw-flex tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 group-hover:tw-border-gray-400">
                                    <span className="tw-text-gray-500 group-hover:tw-text-gray-900">{currentStep.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-500 group-hover:tw-text-gray-900">{currentStep.name}</span>
                                </span>
                            </div>
                            )}

                            {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div className="tw-absolute tw-top-0 tw-right-0 tw-hidden tw-h-full tw-w-5 md:tw-block" aria-hidden="true">
                                <svg
                                    className="tw-h-full tw-w-full tw-text-gray-300"
                                    viewBox="0 0 22 80"
                                    fill="none"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                    d="M0 -2L20 40L0 82"
                                    vectorEffect="non-scaling-stroke"
                                    stroke="currentcolor"
                                    strokeLinejoin="round"
                                    />
                                </svg>
                                </div>
                            </>
                            ) : null}
                        </li>
                        ))}
                    </ol>
                </nav>;
        } else {
            // have a simple button saying back to training form
            return <ButtonPrimary className="tw-mb-4" onClick={() => {
                setSteps(formSteps);
                setStep(1);
                setPreviewMode(false);
            }}>Back to New Training Form</ButtonPrimary>;
        }

        
    }

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [answers, setAnswers] = useState<{ [key: string]: number }>({});

    const [courseMaterials, setCourseMaterials] = useState<CourseMaterial[]>([]);
    const [quiz, setQuiz] = useState<Quiz | null>(null);

    const [currentMaterialIndex, setCurrentMaterialIndex] = useState<number>(0);

    const handleAnswerChange = (questionID: string, answer: string) => {
        var choice = 0;

        switch(answer) {
            case "Paris":
                choice = 1;
                break;
            case "London":
                choice = 2;
                break;
            case "Berlin":
                choice = 3;
                break;
            case "Madrid":
                choice = 4;
                break;
            case "3":
                choice = 1;
                break;
            case "4":
                choice = 2;
                break;
            case "5":
                choice = 3;
                break;
            case "6":
                choice = 4;
                break;
        }

        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionID]: choice,
        }));
    };

    const renderPreviewProgressBar = () => {

        return <nav aria-label="Progress">
                    <ol role="list" className="tw-divide-y tw-divide-gray-300 tw-rounded-md tw-border tw-border-gray-300 md:tw-flex md:tw-divide-y-0 tw-mb-md">
                        {steps.map((step, stepIdx) => (
                        <li key={step.name} className="tw-relative md:tw-flex md:tw-flex-1">
                            {(currentStep + 1) > step.id ? (
                            <div className="tw-group tw-flex tw-w-full tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary group-hover:tw-bg-indigo-800">
                                    <CheckIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">{step.name}</span>
                                </span>
                            </div>
                            ) : step.id === (currentStep + 1) ? (
                            <div className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium" aria-current="step">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-primary">
                                <span className="tw-text-primary">{step.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-primary">{step.name}</span>
                            </div>
                            ) : (
                            <div className="tw-group tw-flex tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 group-hover:tw-border-gray-400">
                                    <span className="tw-text-gray-500 group-hover:tw-text-gray-900">{step.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-500 group-hover:tw-text-gray-900">{step.name}</span>
                                </span>
                            </div>
                            )}

                            {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div className="tw-absolute tw-top-0 tw-right-0 tw-hidden tw-h-full tw-w-5 md:tw-block" aria-hidden="true">
                                <svg
                                    className="tw-h-full tw-w-full tw-text-gray-300"
                                    viewBox="0 0 22 80"
                                    fill="none"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                    d="M0 -2L20 40L0 82"
                                    vectorEffect="non-scaling-stroke"
                                    stroke="currentcolor"
                                    strokeLinejoin="round"
                                    />
                                </svg>
                                </div>
                            </>
                            ) : null}
                        </li>
                        ))}
                    </ol>
                </nav>;
    }

    const renderCourseMaterial = () => {
        if (currentMaterialIndex >= courseMaterials.length) {
            setCurrentStep(2); // Move to quiz
            return null;
        }

        const material = courseMaterials[currentMaterialIndex];

        return (
            <div className="tw-mb-4 tw-flex tw-flex-col">
                <h3 className="tw-text-lg tw-font-semibold">{extractFileNameWithoutExtension(material.materialLink)}</h3>
                <iframe 
                            src={material.materialLink} 
                            width="850" 
                            height="600" 
                            title="PDF Viewer"
                            className="tw-border tw-border-gray-300"
                        ></iframe>
                {/* {material.materialType === "video" && (
                    <video controls className="tw-w-full">
                        <source src={material.materialLink} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
                {material.materialType === "document" && (
                    <a href={material.materialLink} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500">
                        View Document
                    </a>
                )} */}
                <div className="tw-flex tw-justify-between tw-mt-4">
                            {currentMaterialIndex > 0 ? <>
                            <ButtonErrorSecondary onClick={() => setCurrentMaterialIndex(currentMaterialIndex - 1)}>
                                Previous
                            </ButtonErrorSecondary>
                            </>: <>
                            <ButtonWarningSecondary onClick={() => {
                                setCurrentStep(0);
                                setCurrentMaterialIndex(0);
                                }}>
                                Back to Overview
                            </ButtonWarningSecondary>
                            </>}

                            {currentMaterialIndex === courseMaterials.length - 1 ? 
                            <ButtonPrimary onClick={() => setCurrentStep(2)} className="tw-bg-primary tw-text-white">
                                Go To Quiz
                            </ButtonPrimary>
                            :
                            <ButtonPrimary onClick={() => setCurrentMaterialIndex(currentMaterialIndex + 1)} className="tw-bg-primary tw-text-white">
                                Next
                            </ButtonPrimary>
                            }
                </div>
            </div>
        );
    };

    const renderQuiz = () => {
        if (!quiz) return null;

        return quiz.questions && [...quiz.questions]
            .sort((a, b) => a.questionSequence - b.questionSequence).map((question, index) => (
            <div key={question.questionID} className="tw-mb-4">
                <h4 className="tw-text-md tw-font-semibold">Question {index + 1}: {question.question} {question.questionType === "Multiple-Choice" ? "(Please select all that apply)":""}</h4>
                {question.questionType === "Image-Based" && (
                    <div className="tw-flex tw-justify-between">
                        <img src={question.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />
                    </div>
                    
                )}
                <div key={question.choice1}>
                    <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice1}
                                disabled
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice1}
                                disabled
                                checked={answers[question.questionID] === 1}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        )}
                        <span className="tw-px-2">{question.choice1}</span>
                    </label>
                </div>
                <div key={question.choice2}>
                    <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice2}
                                disabled
                                
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice2}
                                disabled
                                checked={answers[question.questionID] === 2}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        )}
                        <span className="tw-px-2">{question.choice2}</span>
                    </label>
                </div>
                {question.questionType !== "True-False" && (
                    <div key={question.choice3}>
                        <label>
                            {question.questionType === "Multiple-Choice" ? (
                                <input
                                    type="checkbox"
                                    name={question.questionID}
                                    value={question.choice3}
                                    disabled
                                    onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                />
                            ) : (
                                <input
                                    type="radio"
                                    name={question.questionID}
                                    value={question.choice3}
                                    disabled
                                    checked={answers[question.questionID] === 1}
                                    onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                />
                            )}
                            <span className="tw-px-2">{question.choice3}</span>
                        </label>
                    </div>
                )}
                {question.questionType !== "True-False" && (
                    <div key={question.choice4}>
                        <label>
                            {question.questionType === "Multiple-Choice" ? (
                                <input
                                    type="checkbox"
                                    name={question.questionID}
                                    value={question.choice4}
                                    disabled
                                    onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                />
                            ) : (
                                <input
                                    type="radio"
                                    name={question.questionID}
                                    value={question.choice4}
                                    disabled
                                    checked={answers[question.questionID] === 1}
                                    onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                />
                            )}
                            <span className="tw-px-2">{question.choice4}</span>
                        </label>
                    </div>
                )}
            </div>
        ));
    };

    const renderPreviewContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <h2 className="tw-text-xl tw-font-semibold">Training Overview</h2>
                        {training && (
                            <div className="tw-mt-4">
                                <p><strong>Name:</strong> {training.trainingName}</p>
                                <p><strong>Description:</strong> {training.trainingDescription}</p>
                                <p><strong>Category:</strong> {training.trainingCategory}</p>
                                <p><strong>Duration:</strong> {training.duration} minutes</p>
                                {/* <p><strong>Client Name:</strong> {training.clientName}</p> */}
                                {/* <p><strong>Created By:</strong> {training.createdBy}</p> */}
                                <p><strong>Date Created:</strong> {new Date(training.dateCreated).toLocaleString()}</p>
                                <p><strong>Expires On:</strong> {new Date(training.validTill).toDateString()}</p>
                            </div>
                        )}
                        <ButtonPrimary onClick={() => setCurrentStep(1)} className="tw-mt-4">
                            Start Training
                        </ButtonPrimary>
                    </>
                );
            case 1:
                return (
                    <>
                        <h2 className="tw-text-xl tw-font-semibold">Course Materials</h2>
                        {renderCourseMaterial()}
                    </>
                );
            case 2:
                return (
                    <>
                        <h2 className="tw-text-xl tw-font-semibold">Quiz</h2>
                        {renderQuiz()}
                        <div className="tw-flex tw-justify-between tw-mt-4">
                            <ButtonWarningSecondary onClick={() => {
                                setCurrentStep(1);
                                setCurrentMaterialIndex(0);
                                }}>
                                Back to Course Materials
                            </ButtonWarningSecondary>
                            {savingTraining ? <>
                                <LoadingSpinner />
                            </>:
                            <ButtonPrimary onClick={handleSubmit} className="tw-bg-primary tw-text-white">
                                Save Training
                            </ButtonPrimary>}
                        </div>
                    </>
                );
            case 3:
                return null;
            default:
                return null;
        }
    };


    return (
        <>
        <SuccessDialog  title="New Training Added" message="The continuous education training has been added successfully!" onClose={dismissSuccessDialog} closeButtonText="Back to manage trainings" open={showSuccessDialog}/>
       
        <div className="tw-px-xl tw-py-md">
            <div className="tw-flex tw-flex tw-pb-4 tw-flex-col tw-space-y-20px tw-text-dark">
                    <div className="tw-space-y-20px tw-pb-2 tw-border-b tw-border-gray-800">
					<GenericBreadCrumbs 
                    mainHeading={"Continuous Education"}
                    subHeading={previewMode ? "Preview Training" : "Create New Training"}
                    backURL={"/training/continuous-education-management"}
					/>
			        </div>
            </div>

            {/* <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">{previewMode ? "Preview Training" : "Create New Training"}</h2>
            </div> */}

            

            <div className="tw-py-5">
            {isSubmitted ? (
                    <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
                        <div className="tw-text-center">
                            <h2 className="tw-text-2xl tw-font-semibold tw-text-primary">Success!</h2>
                            <p className="tw-mt-4">The training has been successfully created.</p>
                        </div>
                    </div>
                ) : (
                    <>
                        {renderProgressBar()}

                        {previewMode && renderPreviewProgressBar()}

                        {previewMode && renderPreviewContent()}


                        {!previewMode && renderStepContent()}

                        {showCourseMaterialError && <p className="tw-text-red-500">Please add at least one course material</p>}

                        {showQuizError && <p className="tw-text-red-500">Please add at least 4 quiz questions</p>}

                        {showAudienceError && <p className="tw-text-red-500">Please select at least one employee</p>}
                        {!previewMode && <div className={`tw-flex ${step === 1 ? "tw-justify-end": "tw-justify-between"} tw-mt-5`}>
                            {step > 1 && <ButtonWarningSecondary onClick={handlePreviousStep}>Previous</ButtonWarningSecondary>}
                            {step < 4 && <ButtonPrimary onClick={handleNextStep}>Next</ButtonPrimary>}
                            {step === 4 && <ButtonPrimary onClick={goToPreview}>Preview</ButtonPrimary>}
                            {/* {step === 3 && <ButtonPrimary onClick={handleSubmit}>Submit</ButtonPrimary>} */}
                        </div>}

                        
                    </>
                )}
            </div>
        </div>
        </>
    );
};

export default CreateContinuousTrainingPage;
