import { useEffect, useLayoutEffect, useState } from "react";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { Training } from "../../types/training";
import moment from "moment";
import { ButtonPrimary } from "../../components/buttons/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../Context";
import { getActiveClientTrainingsByPageNumber, getArchiveClientTrainingsByPageNumber, getDraftClientTrainingsByPageNumber } from "../../apicalls/trainingService";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { truncateStringToLength } from "./ContinuousEducationLandingPage";
import { Search } from "../../components/forms/formFields";
import { get } from "lodash";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const TrainingLandingPage = props => {
    let navigate = useNavigate();

    const {status} = useParams();

    const [loadingTraining, setLoadingTraining] = useState<boolean>(true);

    const [currentTabs, setCurrentTabs] = useState([]);

    const [filteredTraining, setFilteredTraining] = useState<Training[]>([]);

    const [activeTraining, setActiveTraining] = useState<Training[]>([]);
    const [draftTraining, setDraftTraining] = useState<Training[]>([]);
    const [archivedTraining, setArchivedTraining] = useState<Training[]>([]);

    const [activePageNumber, setActivePageNumber] = useState<number>(1);
    const [draftPageNumber, setDraftPageNumber] = useState<number>(1);
    const [archivedPageNumber, setArchivedPageNumber] = useState<number>(1);

    const appContext = useAppContext();

    const [currentActiveTab, setCurrentActiveTab] = useState<string>("Active");

    const [searchText, setSearchText] = useState<string>("");
    
    useEffect(() => {
        if(status) {
            switch(status) {
                case "active":
                    setCurrentActiveTab("Active");
                    break;
                case "draft":
                    setCurrentActiveTab("Draft");
                    break;
                case "archived":
                    setCurrentActiveTab("Archived");
                    break;
                default:
                    break;
            }
        }
    }, [status])

    const [showActiveLoadMore, setShowActiveLoadMore] = useState<boolean>(false);
    const [showDraftLoadMore, setShowDraftLoadMore] = useState<boolean>(false);
    const [showArchivedLoadMore, setShowArchivedLoadMore] = useState<boolean>(false);

    const [loadingMoreActive, setLoadingMoreActive] = useState<boolean>(false);
    const [loadingMoreDraft, setLoadingMoreDraft] = useState<boolean>(false);
    const [loadingMoreArchived, setLoadingMoreArchived] = useState<boolean>(false);

    const LoadMoreActiveTrainings = async () => {
        setLoadingMoreActive(true);

        var newActivePageNumber = activePageNumber + 1;
        setActivePageNumber(newActivePageNumber);

        const active = await getActiveClientTrainingsByPageNumber(newActivePageNumber, appContext.handleAPICallError);

        if(active && active.length !== 0) {
            setActiveTraining([...activeTraining, ...active]);
        }

        if(active && active.length === 20) {
            setShowActiveLoadMore(true);
        } else {
            setShowActiveLoadMore(false);
        }

        if(currentActiveTab === "Active") {
            setFilteredTraining([...filteredTraining, ...active]);
        }

        setLoadingMoreActive(false);
    }

    const LoadMoreDraftTrainings = async () => {
        setLoadingMoreDraft(true);

        var newDraftPageNumber = draftPageNumber + 1;
        setDraftPageNumber(newDraftPageNumber);

        const draft = await getDraftClientTrainingsByPageNumber(newDraftPageNumber, appContext.handleAPICallError);

        if (draft && draft.length !== 0){
            setDraftTraining([...draftTraining, ...draft]);
        }

        if(draft && draft.length === 20) {
            setShowDraftLoadMore(true);
        } else {
            setShowDraftLoadMore(false);
        }

        if(currentActiveTab === "Draft") {
            setFilteredTraining([...filteredTraining, ...draft]);
        }

        setLoadingMoreDraft(false);
    }

    const LoadMoreArchivedTrainings = async () => {
        setLoadingMoreArchived(true);

        var newArchivedPageNumber = archivedPageNumber + 1;
        setArchivedPageNumber(newArchivedPageNumber);

        const archived = await getArchiveClientTrainingsByPageNumber(newArchivedPageNumber, appContext.handleAPICallError);

        if (archived && archived.length !== 0){
            setArchivedTraining([...archivedTraining, ...archived]);
        }

        if(archived && archived.length === 20) {
            setShowArchivedLoadMore(true);
        } else {
            setShowArchivedLoadMore(false);
        }

        if(currentActiveTab === "Archived") {
            setFilteredTraining([...filteredTraining, ...archived]);
        }

        setLoadingMoreArchived(false);
    }

    useLayoutEffect(() => {

        setCurrentTabs([
            { name: 'Active', href: '#', current: true },
            { name: 'Draft', href: '#', current: false },
            { name: 'Archived', href: '#', current: false }
        ]);

        const fetchTrainings = async () => {
            setLoadingTraining(true);

            const active = await getActiveClientTrainingsByPageNumber(activePageNumber, appContext.handleAPICallError);
            const draft = await getDraftClientTrainingsByPageNumber(draftPageNumber, appContext.handleAPICallError);
            const archived = await getArchiveClientTrainingsByPageNumber(archivedPageNumber, appContext.handleAPICallError);

            setActiveTraining(active);
            setDraftTraining(draft);
            setArchivedTraining(archived);

            if(active && active.length === 20) {
                setShowActiveLoadMore(true);
            } else {
                setShowActiveLoadMore(false);
            }

            if(draft && draft.length === 20) {
                setShowDraftLoadMore(true);
            } else {
                setShowDraftLoadMore(false);
            }

            if(archived && archived.length === 20) {
                setShowArchivedLoadMore(true);
            } else {
                setShowArchivedLoadMore(false);
            }

            setFilteredTraining(active);

            var activeTab = currentActiveTab;

            if(status) {
                switch(status) {
                    case "active":
                        activeTab = "Active";
                        break;
                    case "draft":
                        activeTab = "Draft";
                        break;
                    case "archived":
                        activeTab = "Archived";
                        break;
                    default:
                        break;
                }
            }

            switch(activeTab) {
                case "Active":
                    setFilteredTraining(active);
                    setCurrentTabs([
                        { name: 'Active', href: '#', current: true },
                        { name: 'Draft', href: '#', current: false },
                        { name: 'Archived', href: '#', current: false }
                    ]);
                    break;
                case "Draft":
                    setFilteredTraining(draft);
                    setCurrentTabs([
                        { name: 'Active', href: '#', current: false },
                        { name: 'Draft', href: '#', current: true },
                        { name: 'Archived', href: '#', current: false }
                    ]);
                    break;
                case "Archived":
                    setFilteredTraining(archived);
                    setCurrentTabs([
                        { name: 'Active', href: '#', current: false },
                        { name: 'Draft', href: '#', current: false },
                        { name: 'Archived', href: '#', current: true }
                    ]);
                    break;
                default:
                    setFilteredTraining(active);
                    setCurrentTabs([
                        { name: 'Active', href: '#', current: true },
                        { name: 'Draft', href: '#', current: false },
                        { name: 'Archived', href: '#', current: false }
                    ]);
                    break;
            }


            setLoadingTraining(false);
        }

        fetchTrainings();

       
    
    }, [])

    const getFilteredTrainings = (text: string, currentTab: string) => {
        
        if(text) {
            const searchText = text.toLowerCase();

            const currentTraining = currentTab === "Active" ? activeTraining : currentTab === "Draft" ? draftTraining : archivedTraining;

            const filteredByName = currentTraining.filter(element => {
                
                // 👇️ using AND (&&) operator
                return element.trainingName.toLowerCase().includes(searchText.toLowerCase());
            });

            const filteredByCategory = currentTraining.filter(element => {
                
                // 👇️ using AND (&&) operator
                return element.trainingCategory.toLowerCase().includes(searchText.toLowerCase());
            });

            const filteredByDescription = currentTraining.filter(element => {
                
                // 👇️ using AND (&&) operator
                return element.trainingDescription.toLowerCase().includes(searchText.toLowerCase());
            });

            const filteredByClient = currentTraining.filter(element => {
                    
                    // 👇️ using AND (&&) operator
                    return element.clientName.toLowerCase().includes(searchText.toLowerCase());
                });

            // Combine the results and ensure uniqueness
            const combinedResults = [
                ...filteredByName,
                ...filteredByCategory,
                ...filteredByDescription,
                ...filteredByClient
            ];

            // Use a Set to store unique entries
            const uniqueResults = Array.from(new Set(combinedResults.map(item => item.trainingID)))
                .map(id => combinedResults.find(item => item.trainingID === id));

            setFilteredTraining(uniqueResults);
        } else {
            if (currentTab === "Active") {
                setFilteredTraining(activeTraining);
            }
            if (currentTab === "Draft") {
                setFilteredTraining(draftTraining);
            }
            if (currentTab === "Archived") {
                setFilteredTraining(archivedTraining);
            }

        }
    }

    const NavigateToTab = (tab) => {
        setCurrentTabs(currentTabs.map((item) => {
            if (item.name === tab.name) {
                return {
                    ...item,
                    current: true
                }
            } else {
                return {
                    ...item,
                    current: false
                }
            }
        }));

        switch(tab.name) {
            case "Active":
                getFilteredTrainings(searchText, "Active");
                setCurrentActiveTab("Active");
                setSortedByClient("");
                setSortedByDate("");
                setSortedByDuration("");
                setSortedByTitle("");
                break;
            case "Draft":
                getFilteredTrainings(searchText, "Draft");
                setCurrentActiveTab("Draft");
                setSortedByClient("");
                setSortedByDate("");
                setSortedByDuration("");
                setSortedByTitle("");
                break;
            case "Archived":
                getFilteredTrainings(searchText, "Archived");
                setCurrentActiveTab("Archived");
                setSortedByClient("");
                setSortedByDate("");
                setSortedByDuration("");
                setSortedByTitle("");
                break;
            default:
                break;
        }
    }

    const goToCreateTraining = () => {
        navigate('/training/training-management/create');
    }

    const manageTraining = (trainingID: string) => {
        navigate(`/training/client-training-management/${trainingID}/manage`);
    }

    const [sortedByTitle, setSortedByTitle] = useState<string>("");

    const toggleSortByTitle = () => {
        if(sortedByTitle === "ascending") {
            setSortedByTitle("descending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.trainingName.localeCompare(b.trainingName)).reverse());
        } else {
            setSortedByTitle("ascending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.trainingName.localeCompare(b.trainingName)));
        }

        setSortedByClient("");
        setSortedByDuration("");
        setSortedByDate("");
    }

    const [sortedByClient, setSortedByClient] = useState<string>("");

    const toggleSortByClient = () => {
        if(sortedByClient === "ascending") {
            setSortedByClient("descending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.clientName.localeCompare(b.clientName)).reverse());
        } else {
            setSortedByClient("ascending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.clientName.localeCompare(b.clientName)));
        }

        setSortedByTitle("");
        setSortedByDuration("");
        setSortedByDate("");
    }

    const [sortedByDuration, setSortedByDuration] = useState<string>("");

    const toggleSortByDuration = () => {
        if(sortedByDuration === "ascending") {
            setSortedByDuration("descending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.duration - b.duration).reverse());
        } else {
            setSortedByDuration("ascending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.duration - b.duration));
        }

        setSortedByTitle("");
        setSortedByClient("");
        setSortedByDate("");
    }

    const [sortedByDate, setSortedByDate] = useState<string>("");

    const toggleSortByDate = () => {
        if(sortedByDate === "ascending") {
            setSortedByDate("descending");
            setFilteredTraining(filteredTraining.sort((a, b) => moment.utc(b.dateCreated).diff(moment.utc(a.dateCreated))));
        } else {
            setSortedByDate("ascending");
            setFilteredTraining(filteredTraining.sort((a, b) => moment.utc(a.dateCreated).diff(moment.utc(b.dateCreated))));
        }

        setSortedByTitle("");
        setSortedByClient("");
        setSortedByDuration("");
    }

    return (<>
        <div className="tw-px-xl tw-py-md">
                <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Client Training Management</h2>
                </div>

                {loadingTraining ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>:<>

                <div className="tw-pb-5">
                <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-md">
                    <ButtonPrimary onClick={goToCreateTraining}>
                        Create New Training
                    </ButtonPrimary>
                </div>

                <div className="tw-flex-grow tw-flex tw-items-center tw-mt-md tw-pr-20px">
                                <Search className="tw-flex tw-w-full tw-flex-grow" placeholder="Search trainings" onChange={ e => {

                                    console.log(e?.target.value || "nope");

                                    setSearchText(e?.target.value || "");

                                    getFilteredTrainings(e?.target.value || "", currentActiveTab);

                                    // if(e?.target.value){
                                    //     const searchText = e?.target.value.toString().toLowerCase();
                                    //     const filtered = sessionShifts.filter(element => {
                                            
                                    //         // 👇️ using AND (&&) operator
                                    //         return element.patientName.toLowerCase().includes(searchText);
                                    //     });

                                    //     console.log(filtered)

                                    //     setFilteredShifts(filtered);
                                    // } else {
                                    //     setFilteredShifts(sessionShifts);
                                    // }
                                    }}/>
                            </div>


                <div className="hidden sm:block">
                    <div className="tw-border-b tw-border-gray-200">
                    <nav className="-tw-mb-px tw-flex tw-space-x-8" aria-label="Tabs">
                        {currentTabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={classNames(
                            tab.current
                                ? 'tw-border-primary tw-text-primary'
                                : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-200 hover:tw-text-gray-700 tw-cursor-pointer',
                            'tw-flex tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => NavigateToTab(tab)}
                        >
                            {tab.name}
                            {/* <span
                                className={classNames(
                                tab.current ? 'tw-bg-primary-bg tw-text-primary' : 'tw-bg-gray-100 tw-text-gray-900',
                                'tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block'
                                )}
                            >
                                {tab.count}
                            </span> */}
                        </span>
                        ))}
                    </nav>
                    </div>
                </div>

                <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-10px tw-leading-tight tw-mt-15px">
                <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-140px tw-flex tw-justify-center tw-cursor-pointer" onClick={toggleSortByTitle}>
                                        <span className="tw-text-dark tw-font-semibold">Title</span>
                                        {sortedByTitle === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByTitle === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-justify-center tw-cursor-pointer" onClick={toggleSortByClient}>
                                        <span className="tw-text-dark tw-font-semibold">Client</span>
                                        {sortedByClient === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByClient === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-250px tw-flex tw-justify-center">
                                        <span className="tw-text-dark tw-font-semibold">Description</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-justify-center tw-cursor-pointer" onClick={toggleSortByDuration}>
                                        <span className="tw-text-dark tw-font-semibold">Duration</span>
                                        {sortedByDuration === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByDuration === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-justify-center tw-cursor-pointer" onClick={toggleSortByDate}>
                                        <span className="tw-text-dark tw-font-semibold">Published (Date/By)</span>
                                        {sortedByDate === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByDate === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                            </div>
                        </li>

                {(!filteredTraining || filteredTraining.length === 0) && <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                    <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                            
                            <span className="tw-w-full tw-flex tw-justify-center tw-text-dark tw-pb-sm">
                                No Client Trainings Found
                            </span>
                    </div>
                </li>}
                
                {filteredTraining?.map((training, index) => {
                    return (
                        <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                            <span className="tw-w-140px tw-flex tw-justify-start tw-pl-2">
                                        <span className="tw-text-dark">{training.trainingName}</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-justify-center">
                                        <span className="tw-text-dark">{training.clientName}</span>
                                    </span>
                                    <span className="tw-w-250px tw-flex tw-justify-start tw-pl-2">
                                        <span className="tw-text-dark tw-pb-sm">{truncateStringToLength(training.trainingDescription, 80)}</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-justify-center">
                                        <span className="tw-text-dark">{training.duration ? training.duration : 30} minutes</span>
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-flex-col tw-items-center tw-justify-center">
                                        <span className="tw-text-dark">{moment.utc(training.dateCreated).tz('America/New_York').format('MMM Do YYYY')}</span>
                                        <span className="tw-text-dark tw-pt-md">{training?.createdBy.split('@')[0]}</span>
                                    </span>
                                    <ButtonPrimary onClick={(e) => {
                                        e.preventDefault();
                                        manageTraining(training.trainingID);
                                    
                                    }} className="tw-mb-sm tw-mr-md tw-bg-gray-400 tw-border-gray-400 hover:tw-text-gray-400 tw-max-h-40px">
                                        View Details
                                    </ButtonPrimary>
                            </div>
                        </li>
                    )
                })}
                </ul>
                {currentActiveTab === "Active" && showActiveLoadMore && <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                    <ButtonPrimary onClick={LoadMoreActiveTrainings}>
                        {loadingMoreActive ? <LoadingSpinner /> : "Load More"}
                    </ButtonPrimary>
                </div>}

                {currentActiveTab === "Draft" && showDraftLoadMore && <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                    <ButtonPrimary onClick={LoadMoreDraftTrainings}>
                        {loadingMoreDraft ? <LoadingSpinner /> : "Load More"}
                    </ButtonPrimary>
                </div>}

                {currentActiveTab === "Archived" && showArchivedLoadMore && <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                    <ButtonPrimary onClick={LoadMoreArchivedTrainings}>
                        {loadingMoreArchived ? <LoadingSpinner /> : "Load More"}
                    </ButtonPrimary>
                </div>}
                </div>
                
                </>}
        </div>
    </>);
}

export default TrainingLandingPage;