import debounce from "./debouncer";

export const setVH = () => {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
};

export const resizeVH = () => {
	setVH();
	if(window) window.addEventListener("resize", debounce(setVH, 30));
}

export const vh = () => resizeVH();

export default vh;
