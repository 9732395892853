import { useLayoutEffect, useState } from "react";
import RichTextField from "../../components/forms/richTextField";
import ReadOnlyText from "../../components/forms/readOnlyRichTextField";
import { ButtonPrimary } from "@medforall/medforall-component-library";
import { AgencyAnnouncement } from "../../types/agencyAnnouncement";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getActiveAnnouncements, getArchivedAnnouncements } from "../../apicalls/announcementService";
import { useAppContext } from "../../Context";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { convertUTCDateToLocalDate } from "../../utilities/dateUtils";

// const sampleAnnouncements : AgencyAnnouncement[] = [
//     {
//         agencyAnnouncementID: "123",
//         announcementTitle: "New Policy",
//         announcementDescription: "New policy has been implemented",
//         announcementType: "Policy",
//         dateCreated: moment().add(-5, 'days').toDate(),
//         createdBy: "john.doe@ohioathome.com",
//         status: "Active"
//     },
//     {
//         agencyAnnouncementID: "456",
//         announcementTitle: "New Procedure",
//         announcementDescription: "New procedure has been implemented",
//         announcementType: "Procedure",
//         dateCreated: moment().add(-2, 'days').toDate(),
//         createdBy: "jane.doe@ohioathome.com",
//         status: "Active"
//     },
//     {
//         agencyAnnouncementID: "789",
//         announcementTitle: "New Client",
//         announcementDescription: "New client has been added",
//         announcementType: "Client Onboarding",
//         dateCreated: moment().add(-1, 'days').toDate(),
//         createdBy: "mark.spencer@ohioathome.com",
//         status: "Active"
//     }
// ]

const tabs = [
    { name: 'Active/Draft', href: '#', current: true },
    { name: 'Archived', href: '#', current: false },
  ]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const AgencyAnnouncementsPage = props => {

    const navContext = useAppContext();

    const [loadingAnnouncements, setLoadingAnnouncements] = useState(false);

    const [activeAnnouncements, setActiveAnnouncements] = useState<AgencyAnnouncement[]>([]);

    const [archivedAnnouncements, setArchivedAnnouncements] = useState<AgencyAnnouncement[]>([]);

    const [currentTabs, setCurrentTabs] = useState(tabs);


    const NavigateToTab = (tab) => {
        setCurrentTabs(currentTabs.map((item) => {
            if (item.name === tab.name) {
                return {
                    ...item,
                    current: true
                }
            } else {
                return {
                    ...item,
                    current: false
                }
            }
        }));
    }

    useLayoutEffect(() => {
        setLoadingAnnouncements(true);

        const fetchAnnouncements = async () => {
            const result = await getActiveAnnouncements(navContext.handleAPICallError);

            setActiveAnnouncements(result);

            const archived = await getArchivedAnnouncements(navContext.handleAPICallError);

            setArchivedAnnouncements(archived);

            setLoadingAnnouncements(false);
        };

        fetchAnnouncements();
    }, []);

    let navigate = useNavigate();

    const handleAddAnnouncement = () => {
        console.log("Add Announcement");

        navigate(`/announcement/agency/add`);
    }

    const handleEditAnnouncement = (announcement: AgencyAnnouncement) => {
        console.log("Edit Announcement", announcement.agencyAnnouncementID);

        navigate(`/announcement/agency/${announcement.agencyAnnouncementID}/edit`);
    }

    return (<>
        <div className="tw-px-xl tw-pt-xl">
            <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Agency Announcements</h2>
            </div>

            <div className="tw-flex tw-mx-auto tw-pb-5">
                <label className="tw-block tw-flex-grow tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                    List of active agency announcements for remote support associates
                </label>
                <ButtonPrimary onClick={handleAddAnnouncement}>
                    Add Announcement
                </ButtonPrimary>
            </div>

            {loadingAnnouncements ? <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                    <LoadingSpinner /> 
					<span className="tw-text-primary tw-text-5 tw-text-center">Loading Agency Announcements</span>
                </div> :
            <div className="-tw-mx-4 -tw-my-2 tw-overflow-x-auto sm:-tw-mx-6 lg:-tw-mx-8">
                <div className="hidden sm:block sm:tw-px-6 lg:tw-px-8">
                    <div className="tw-border-b tw-border-gray-200">
                    <nav className="-tw-mb-px tw-flex tw-space-x-8" aria-label="Tabs">
                        {currentTabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={classNames(
                            tab.current
                                ? 'tw-border-primary tw-text-primary'
                                : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-200 hover:tw-text-gray-700 tw-cursor-pointer',
                            'tw-flex tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => NavigateToTab(tab)}
                        >
                            {tab.name}
                            
                        </span>
                        ))}
                    </nav>
                    </div>
                </div>
                <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle sm:tw-px-6 lg:tw-px-8">
                    <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                    <thead>
                        <tr>
                        <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-break-words tw-max-w-300px tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">
                            Title
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Announcement Type
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Status
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Created By
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Created Date
                        </th>
                        <th scope="col" className="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4 sm:tw-pr-0">
                            <span className="tw-sr-only">Edit</span>
                        </th>
                        </tr>
                    </thead>
                    {currentTabs[0].current && <tbody className="tw-divide-y tw-divide-gray-200">
                        {activeAnnouncements?.map((announcement) => (
                        <tr key={announcement.agencyAnnouncementID} className="tw-cursor-pointer" onClick={ e => {
                            e.preventDefault();
                            handleEditAnnouncement(announcement);
                            //props?.toggleShowRightPanel();
                        }}>
                            <td className="tw-break-words tw-max-w-300px tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">
                            {announcement.announcementTitle}
                            </td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.announcementType}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.status}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.createdBy}</td>
                            
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{moment(announcement.dateCreated).format("MM-DD-YY h:mm A")}</td>
                            <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-0">
                            <span className="tw-text-primary tw-cursor-pointer hover:tw-text-primary-dark" onClick={ e => {
                                                        e.preventDefault();
                                                        handleEditAnnouncement(announcement);
                                                        //props?.toggleShowRightPanel();
                                                    }}>
                                                        Edit<span className="tw-sr-only">, {announcement.agencyAnnouncementID}</span>
                            </span>
                            </td>
                        </tr>
                        ))}
                    </tbody>}
                    {currentTabs[1].current && <tbody className="tw-divide-y tw-divide-gray-200">
                        {archivedAnnouncements?.map((announcement) => (
                        <tr key={announcement.agencyAnnouncementID} className="tw-cursor-pointer" onClick={ e => {
                            e.preventDefault();
                            handleEditAnnouncement(announcement);
                            //props?.toggleShowRightPanel();
                        }}>
                            <td className="tw-break-words tw-max-w-300px tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">
                            {announcement.announcementTitle}
                            </td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.announcementType}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.status}</td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{announcement.createdBy}</td>
                            
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{moment(announcement.dateCreated).format("MM-DD-YY h:mm A")}</td>
                            <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-0">
                            <span className="tw-text-primary tw-cursor-pointer hover:tw-text-primary-dark" onClick={ e => {
                                                        e.preventDefault();
                                                        handleEditAnnouncement(announcement);
                                                        //props?.toggleShowRightPanel();
                                                    }}>
                                                        View<span className="tw-sr-only">, {announcement.agencyAnnouncementID}</span>
                            </span>
                            </td>
                        </tr>
                        ))}
                    </tbody>}
                    </table>
                </div>
            </div>}

            

            {/* <p className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900 tw-py-5">Read Only Version</p>

            <ReadOnlyText content={content} />


            <div 
                className="rich-text-content" 
                dangerouslySetInnerHTML={{ __html: content }}
                /> */}
        </div>    
    </>);
}

export default AgencyAnnouncementsPage;