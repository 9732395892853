import { useEffect, useLayoutEffect, useState } from "react";
import { Training } from "../../types/training";
import { ButtonPrimary } from "@medforall/medforall-component-library";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context";
import { getLatestActiveTrainings } from "../../apicalls/trainingService";
import { act } from "react-dom/test-utils";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ProviderTrainingLandingPage = props => {
    const [pendingTrainings, setPendingTrainings] = useState<Training[]>([]);
    const [completedTrainings, setCompletedTrainings] = useState<Training[]>([]);
    const [expiredTrainings, setExpiredTrainings] = useState<Training[]>([]);

    const [activeTrainings, setActiveTrainings] = useState<Training[]>([]);

    const [filteredTraining, setFilteredTraining] = useState<Training[]>([]);

    let navigate = useNavigate();

    const appContext = useAppContext();

    const goToCompleteTraining = (trainingID) => {
        navigate("/provider/training/" + trainingID + "/complete");
    }

    const [loadingTrainings, setLoadingTrainings] = useState<boolean>(false);

    useLayoutEffect(() => {
        const fetchTrainings = async () => {
            setLoadingTrainings(true);
            // Call API to fetch trainings
            var result = await getLatestActiveTrainings(appContext.handleAPICallError);

            if (result) {
                setActiveTrainings(result);

                setPendingTrainings(result.filter(training => training.status === "Not Started" || training.status === "Failed"));

                setCompletedTrainings(result.filter(training => training.status === "Certified"));

                setFilteredTraining(result.filter(training => training.status === "Not Started" || training.status === "Failed"));
            }

            setLoadingTrainings(false);
        }

        fetchTrainings();
    }, [])

    const [currentTabs, setCurrentTabs] = useState([
        { name: 'Pending', href: '#', current: true },
        { name: 'Completed', href: '#', current: false }
    ]);

    const [currentActiveTab, setCurrentActiveTab] = useState<string>("Pending");

    const [sortedByTitle, setSortedByTitle] = useState<string>("");

    const toggleSortByTitle = () => {
        if(sortedByTitle === "ascending") {
            setSortedByTitle("descending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.trainingName.localeCompare(b.trainingName)).reverse());
        } else {
            setSortedByTitle("ascending");
            setFilteredTraining(filteredTraining.sort((a, b) => a.trainingName.localeCompare(b.trainingName)));
        }
    }

    // useEffect(() => {
    //     // Use sample data for trainings
    //     fetchSampleTrainings();
    // }, []);

    const fetchSampleTrainings = () => {
        const sampleTrainings: Training[] = [
            {
                trainingID: "1", trainingName: "Safety Procedures", clientName: "Client A",
                trainingDescription: "Training on safety procedures.", createdBy: "Admin",
                dateCreated: new Date(),
                status: "Active",
                trainingCategory: "",
                clientID: "",
                quiz: undefined,
                courseMaterials: [],
                trainingAssignments: [],
                providerAttempts: [],
                trainingCertifications: [],
                validTill: undefined,
                voidedReason: ""
            },
            {
                trainingID: "2", trainingName: "Health Regulations", clientName: "Client B",
                trainingDescription: "Training on health regulations.", createdBy: "Admin",
                dateCreated: new Date(), status: "Draft",
                trainingCategory: "",
                clientID: "",
                quiz: undefined,
                courseMaterials: [],
                trainingAssignments: [],
                providerAttempts: [],
                trainingCertifications: [],
                validTill: undefined,
                voidedReason: ""
            },
            {
                trainingID: "3", trainingName: "Emergency Response", clientName: "Client C",
                trainingDescription: "Training on emergency response.", createdBy: "Admin",
                dateCreated: new Date(), status: "Archived",
                trainingCategory: "",
                clientID: "",
                quiz: undefined,
                courseMaterials: [],
                trainingAssignments: [],
                providerAttempts: [],
                trainingCertifications: [],
                validTill: undefined,
                voidedReason: ""
            }
        ];

        const pending = sampleTrainings.filter(training => training.status === "Active");
        const completed = sampleTrainings.filter(training => training.status === "Draft");
        const expired = sampleTrainings.filter(training => training.status === "Archived");

        setPendingTrainings(pending);
        setCompletedTrainings(completed);
        setExpiredTrainings(sampleTrainings);
    };

    const NavigateToTab = (tab) => {
        setCurrentTabs(currentTabs.map((item) => {
            if (item.name === tab.name) {
                return {
                    ...item,
                    current: true
                }
            } else {
                return {
                    ...item,
                    current: false
                }
            }
        }));

        switch(tab.name) {
            case "Pending":
                setFilteredTraining(pendingTrainings);
                setCurrentActiveTab("Pending");
                setSortedByTitle("");
                break;
            case "Completed":
                setFilteredTraining(completedTrainings);
                setCurrentActiveTab("Completed");
                setSortedByTitle("");
                break;
            default:
                break;
        }
    }

    const renderTrainings = (trainings: Training[]) => {
        console.log("Rendering trainings: ", trainings);

        return trainings.map((training) => (
            <li key={training.trainingID} className="tw-flex tw-border-b tw-py-4">
                <span className="tw-w-250px tw-flex tw-justify-start tw-pl-2">
                    <span className="tw-text-dark tw-text-sm">{training.trainingName}</span>
                </span>
                <span className="tw-w-140px tw-flex tw-justify-center">
                    <span className="tw-text-dark tw-text-sm">{training.clientName}</span>
                </span>
                <span className="tw-w-100px tw-flex tw-justify-center">
                    <span className="tw-text-dark tw-text-sm">{training.providerAttempts ? training.providerAttempts.length : 0}</span>
                </span>
                <span className="tw-w-120px tw-flex tw-justify-center">
                    <span className="tw-text-dark tw-text-sm">{training.duration ? training.duration : 30} minutes</span>
                </span>
                <span className="tw-w-180px tw-flex tw-justify-center">
                    <span className="tw-text-dark tw-text-sm">{moment.utc(training.dateCreated).tz('America/New_York').format('MMM Do YYYY')}</span>
                </span>
                {(training.status === "Not Started" || training.status === "Failed") ? <>
                    <ButtonPrimary className="tw-mb-sm tw-mr-md tw-bg-gray-400 tw-border-gray-400 hover:tw-text-gray-400 hover:tw-border-osu-scarlet" onClick={(e) => {
                        e.preventDefault();
                        goToCompleteTraining(training.trainingID);
                    }}>
                        Start Training
                    </ButtonPrimary>
                </> : <>
                    <ButtonPrimary className="tw-mb-sm tw-mr-md tw-bg-gray-400 tw-border-gray-400 hover:tw-text-gray-400 hover:tw-border-osu-scarlet" onClick={(e) => {
                        e.preventDefault();
                        goToCompleteTraining(training.trainingID);
                    }}>
                        View Certificate
                    </ButtonPrimary>
                </>}
                
            </li>
        ));
    };

    return (
        <div className="tw-px-xl tw-py-sm">
            <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">My Trainings</h2>
            </div>

            {loadingTrainings ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>:<>

                <div className="hidden sm:block">
                    <div className="tw-border-b tw-border-gray-200">
                    <nav className="-tw-mb-px tw-flex tw-space-x-8" aria-label="Tabs">
                        {currentTabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={classNames(
                            tab.current
                                ? 'tw-border-primary tw-text-primary'
                                : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-200 hover:tw-text-gray-700 tw-cursor-pointer',
                            'tw-flex tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => NavigateToTab(tab)}
                        >
                            {tab.name}
                            {/* <span
                                className={classNames(
                                tab.current ? 'tw-bg-primary-bg tw-text-primary' : 'tw-bg-gray-100 tw-text-gray-900',
                                'tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block'
                                )}
                            >
                                {tab.count}
                            </span> */}
                        </span>
                        ))}
                    </nav>
                    </div>
                </div>

            <div className="tw-py-2">
                <ul>
                    <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row">
                                    <span className="tw-w-250px tw-flex tw-justify-center tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Title</span>
                                        {sortedByTitle === "ascending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronDownIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                        {sortedByTitle === "descending" && <span className="tw-ml-2 tw-flex-none tw-rounded tw-bg-gray-100 tw-text-gray-900 group-hover:tw-bg-gray-200">
                                            <ChevronUpIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                        </span>}
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-justify-center tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Client Name</span>
                                    </span>
                                    <span className="tw-w-100px tw-flex tw-justify-center">
                                        <span className="tw-text-dark tw-font-semibold">#Attempts</span>
                                    </span>
                                    <span className="tw-w-120px tw-flex tw-justify-center">
                                        <span className="tw-text-dark tw-font-semibold">Duration</span>
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-justify-center">
                                        <span className="tw-text-dark tw-font-semibold">Published On</span>
                                    </span>
                            </div>
                        </li>
                        {(!filteredTraining || filteredTraining.length === 0) && <li className="tw-flex tw-w-full tw-space-x-5px tw-mt-sm tw-items-center tw-border-b">
                    <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                            
                            <span className="tw-w-full tw-flex tw-justify-center tw-text-dark tw-pb-sm">
                                No Trainings Found
                            </span>
                    </div>
                </li>}
                    {renderTrainings(filteredTraining)}
                </ul>
                {/* <h3 className="tw-text-md tw-font-semibold tw-pb-md">Pending Trainings</h3>
                
                <ul>
                    <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Training Name</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Client</span>
                                    </span>
                                    <span className="tw-w-250px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Description</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Created By</span>
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Created Date</span>
                                    </span>
                            </div>
                        </li>
                    {renderTrainings(pendingTrainings)}
                </ul>

                <h3 className="tw-text-md tw-font-semibold tw-mt-8 tw-pb-md">Completed Trainings</h3>
                <ul>
                    <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Training Name</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Client</span>
                                    </span>
                                    <span className="tw-w-250px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Description</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Created By</span>
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Created Date</span>
                                    </span>
                            </div>
                        </li>
                    {renderTrainings(completedTrainings)}
                </ul>

                <h3 className="tw-text-md tw-font-semibold tw-mt-8 tw-pb-md">Expired Trainings</h3>
                <ul>
                    <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Training Name</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Client</span>
                                    </span>
                                    <span className="tw-w-250px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Description</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Created By</span>
                                    </span>
                                    <span className="tw-w-180px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Created Date</span>
                                    </span>
                            </div>
                        </li>
                    {renderTrainings(expiredTrainings)}
                </ul> */}
            </div>

            </>}
        </div>
    );
};

export default ProviderTrainingLandingPage;
