import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { SuggestedAction } from "../../types/servicePlan";
import { v4 as uuidv4 } from 'uuid';
import { EditSuggestedActionList } from "../portalComponents/editSuggestedActionCard";

const NewSuggestedActionSection = () => {
    const [showSuggestedActionCard, setShowSuggestedActionCard] = useState(false);

    const planContext = useNewPlanContext();

    const SuggestedActionSchema = Yup.object().shape({
        actionTitle: Yup.string()
          .max(50, 'Action title must be at most 50 characters')
          .required('Action title is required'),
        actionDetails: Yup.string().required('Action details are required'),
      });
      
    const initialValues = {
        actionTitle: '',
        actionDetails: '',
    };

    const suggestedActionFormik = useFormik({
        initialValues,
        validationSchema: SuggestedActionSchema,
        onSubmit: (values) => {
            console.log(values);

            var newAction: SuggestedAction = {
                actionTitle: values.actionTitle,
                actionDetails: values.actionDetails,
                suggestedActionID: uuidv4(),
                servicePlanID: ""
            }

            planContext.setPlanSuggestedActions([...planContext.planSuggestedActions, newAction]);
            toggleSuggestedActionCard();
        },
    });

    const toggleSuggestedActionCard = () => {
        setShowSuggestedActionCard(!showSuggestedActionCard);
        suggestedActionFormik.resetForm();
    }

    const handleRemoveAction = (action) => {
        const index = planContext.planSuggestedActions.indexOf(action);
        const newItems = [...planContext.planSuggestedActions.slice(0, index), ...planContext.planSuggestedActions.slice(index + 1)];
        planContext.setPlanSuggestedActions(newItems);
    }

    return (<>
        {showSuggestedActionCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${suggestedActionFormik.touched.actionTitle && suggestedActionFormik.errors.actionTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...suggestedActionFormik.getFieldProps('actionTitle')}
                                                    />
                                                    {suggestedActionFormik.touched.actionTitle && suggestedActionFormik.errors.actionTitle && <p className="tw-text-red-500">{suggestedActionFormik.errors.actionTitle}</p>}
                                                </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="details"
                                                    id="details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${suggestedActionFormik.touched.actionDetails && suggestedActionFormik.errors.actionDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...suggestedActionFormik.getFieldProps('actionDetails')}
                                                    />
                                                    {suggestedActionFormik.touched.actionDetails && suggestedActionFormik.errors.actionDetails && <p className="tw-text-red-500">{suggestedActionFormik.errors.actionDetails}</p>}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleSuggestedActionCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={suggestedActionFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleSuggestedActionCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Suggested Action</span>
                                            </div>  
                                    </div>
                            </div>
                            </>}

                            {/* {planContext.planSuggestedActions.length > 0 && planContext.planSuggestedActions?.map((action, index) => (
                                <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mt-4">
                                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="title"
                                                id="title"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={action.actionTitle}
                                                disabled={true}
                                                />
                                            </div>
                                    </div>
                                    <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Details
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="details"
                                                id="details"
                                                className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                value={action.actionDetails}
                                                disabled={true}
                                                />
                                            </div>
                                    </div>
                                </div>
                                <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mt-md" onClick={e => handleRemoveAction(action)}>
                                                Remove
                                        </ButtonSecondary>

                                </div>
                            </div>
                            ))} */}

                            <div className="tw-mt-4">
                            {planContext.planSuggestedActions.length > 0 && <EditSuggestedActionList actions={planContext.planSuggestedActions} newPlan={true}></EditSuggestedActionList>}
                            </div>

                            
    </>);
}

export default NewSuggestedActionSection;