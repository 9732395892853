import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import { ClientScheduleNotice, ClientScheduleNoticeLog } from "../../types/clientScheduleNotice";
import { getScheduleNoticeLogs, markScheduleNoticeCancelledWithReason } from "../../apicalls/schedulingService";
import { CircleLabel } from "../calendars/CalendarUtils";
import { LabelOutputs, Span } from "../utilities/Helpers";
import { ButtonError, ButtonPrimary, ButtonSecondary, ButtonWarning } from "../buttons/Buttons";
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import { AvatarNameInlineList } from "../avatars/AvatarNameInline";
import CommentBlock from "../containers/CommentBlock";
import { CancelType, Textarea } from "../forms/formFields";

export const NoticeDetailsCancelPanel = props => {
	const navContext = useAppContext();
	const [toggleRequiredInformation, setToggleRequiredInformation] = useState<boolean>(true);
	const [notice, setNotice] = useState<ClientScheduleNotice>(props.notice || null);
	const [loading, setLoading] = useState<boolean>(false);
	const [reason, setReason] = useState<string>('');
	const [reasonDetail, setReasonDetail] = useState<string>('');
	const [reasonError, setReasonError] = useState<any>(false);
	const [reasonDetailError, setReasonDetailError] = useState<any>(false);

	useLayoutEffect(() => {
		if (props.notice) setNotice(props.notice)
	}, [props.notice])

	const validateReason = () => {
		console.log(reason);

		if (reason === '') {
			setReasonError(true);
			return false;
		} else if (reason === 'Other' && reasonDetail === '') {
			setReasonDetailError(true);
			return false;
		} else {
			setReasonError(false);
			setReasonDetailError(false);
			return true;
		}

	}

	const handleCancel = async (e) => {
		e.preventDefault();

		const valid = validateReason();

		if(valid){
			setLoading(true);

			notice.reasonForCancellation = reason;
			if(reason === 'Other'){
				notice.reasonForCancellation = reasonDetail;
			}

			const result = await markScheduleNoticeCancelledWithReason(notice?.clientScheduleDayNoticeID, notice, navContext.handleAPICallError);

			setLoading(false);

			if(!navContext.showErrorModal){
				navContext.setSideNavTitle("Success");
                notice.status = "Cancelled";
				navContext.setSideNav( <NoticeDetailsPanelSuccess notice={notice}/> );
				navContext.setSideNavActions(null);
				navContext.setSideNavBottom(null);

				if(props.refreshCalendar){
					console.log("Refreshing calendar after cancel");
					await props.refreshCalendar();
				}
			}
		}

		
	}

	return (<>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex-shrink-0 tw-w-full tw-overflow-x-hidden tw-overflow-y-auto">
				<div className="tw-p-20px tw-space-y-20px tw-w-full">

					<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]" onClick={ e => {
						let temp = toggleRequiredInformation;
						setToggleRequiredInformation( temp = !temp )
					}}>
						<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
							<span className="tw-flex-grow">
							<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
								<span className="tw-text-dark tw-ml-5px">Required Information</span>
							</CircleLabel>
							</span>
							<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
								<img className="tw-w-full" src={`/images/icon-arrow-${ toggleRequiredInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
							</span>
						</span>
					</h3>
					{ toggleRequiredInformation ? <>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">

                        <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Start Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayStartDate }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Start Time
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayStartTime }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										End Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayEndDate }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										End Time
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayEndTime }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-status.svg" className="tw-opacity-70">
										Status
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.status }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Notice Type
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.noticeType }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Details
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.details }
									</span>
							</li>
						</ul>
					</> : <></> }
				</div>
			</div>
				<div className="tw-pl-20px tw-pr-40px tw-space-y-20px">
					<div>
						<CancelType id="reason" onChange={ e => {
							setReason(e.target.value);
							// setValues({
							// 	...values,
							// 	"reason": e.target.value
							// })
						}} />
						{reasonError && <p className="tw-text-red-500">Select a reason for cancellation</p>}
						<fieldset className="tw-w-full tw-pt-10px">
							<Textarea id="reasonDetail" className={`${reasonDetailError ? '_form-error !tw-w-[calc(100%-1rem)]': '!tw-w-[calc(100%-1rem)]'}`} placeholder="Type your reason for changing here" onChange={ e => {
								// setValues({
								// 	...values,
								// 	"reasonDetail": e.target.value
								// })
								setReasonDetail(e.target.value);

							}}>
							</Textarea>
							{reasonDetailError && <p className="tw-text-red-500">Details needed</p>}
						</fieldset>
					</div>
					{loading ? <>
						<div className="tw-flex tw-justify-center tw-mx-4">
							<LoadingSpinner />
						</div>
					</>
					:
					<div>
						<h3 className="tw-font-primary-bold tw-font-5 tw-text-dark tw-py-20px">
							Are you sure about cancelling <Span>this coverage?</Span>
						</h3>
						<div className="tw-flex tw-justify-between tw-space-x-60px">
							<ButtonSecondary className="tw-basis-1/2" onClick={ e => {
								e.preventDefault();
								navContext.setPanelSize('tw-max-w-400px');
								navContext.setSideNav( <NoticeDetailsPanel notice={notice} serviceTypes={ props?.serviceTypes || null}/> );
								navContext.setSideNavActions(null);
								navContext.setSideNavBottom(null);
							}}>
								No
							</ButtonSecondary>
							<ButtonPrimary className="tw-basis-1/2" onClick={handleCancel}>
								Yes, Cancel
							</ButtonPrimary>
						</div>
					</div>}
				</div>
			
		</div>
	</>);
}

export const NoticeDetailsPanelSuccess = props => {
	const navContext = useAppContext();
	const [title, setTitle] = useState<string>('Saved notice information');

	const [notice, setNotice] = useState<ClientScheduleNotice>(props.notice || null);

	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex tw-flex-col tw-flex-grow tw-w-full tw-overflow-x-hidden tw-overflow-y-aut">
				<div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow ">
					<div className="tw-w-full tw-space-y-15px">
						<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]">
							<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
								<span className="tw-text-dark tw-ml-5px">
									{title}
								</span>
							</span>
						</h3>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                        <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Start Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayStartDate }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Start Time
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayStartTime }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										End Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayEndDate }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										End Time
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayEndTime }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-status.svg" className="tw-opacity-70">
										Status
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.status }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Notice Type
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.noticeType }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Details
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.details }
									</span>
							</li>
						</ul>
					</div>
					<div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-my-auto tw-py-20px">
						<div className="tw-space-y-15px tw-text-center">
							<LabelOutputs className="tw-w-200px tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
								<span className="tw-text-5">
									Saved Successfully
								</span>
							</LabelOutputs>
							<ButtonSecondary className="tw-w-200px" onClick={ e => {
								e.preventDefault();
								navContext.closeSideNav();
							}}>
								Go Back to schedule
							</ButtonSecondary>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

const NoticeDetailsPanel = props => {
	const navContext = useAppContext();
	const [name, setName] = useState<any>(props.name);
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const [isCanceling, setIsCanceling] = useState<boolean>(false);
	const [toggleRequiredInformation, setToggleRequiredInformation] = useState<boolean>(true);
	const [toggleInformation, setToggleInformation] = useState<boolean>(true);
    const [notice, setNotice] = useState<ClientScheduleNotice>(props.notice || null);
    const [noticeLogs, setNoticeLogs] = useState<ClientScheduleNoticeLog[]>(null);
	const [logsLoading, setLogsLoading] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);

	const handleOnClick = e => {
		e.preventDefault();
		if (props.onClick) props.onClick(e);
	}

    const toggleEditMode = () => {
        setEditMode(!editMode);
    }

    const handleCancel = () => {
        navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
		navContext.setSideNavTitle("Cancel Schedule Notice");
		navContext.setSideNav( <NoticeDetailsCancelPanel notice={notice} refreshCalendar={props?.refreshCalendar || null}/> );
    }

	useLayoutEffect(() => {
		//TODO: Identify "id" to pass to client  <ClientInfoPanel />
		if (props.name) setName(props.name)
		if (props.notice) setNotice(props.notice)
	}, [props.notice])

	useLayoutEffect(() => {
		navContext.setSideNavTitle("Notice Details")

		const fetchRequirementLogs = async () => {
			setLogsLoading(true);

			var noticeID = props.notice?.clientScheduleDayNoticeID;

			if (noticeID) {
				const response = await getScheduleNoticeLogs(noticeID, navContext.handleAPICallError);

				console.log(response);

				setNoticeLogs(response);

				setLogsLoading(false);
			}
		};

		fetchRequirementLogs();
	}, [])

	useLayoutEffect(() => {
		if (props.canceling) setIsCanceling(true);
	}, [props.canceling])

	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex-shrink-0 tw-w-full tw-overflow-x-hidden tw-overflow-y-auto">
				<div className="tw-p-20px tw-space-y-20px tw-w-full">

					<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]" onClick={ e => {
						let temp = toggleRequiredInformation;
						setToggleRequiredInformation( temp = !temp )
					}}>
						<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
							<span className="tw-flex-grow">
							<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
								<span className="tw-text-dark tw-ml-5px">Required Information</span>
							</CircleLabel>
							</span>
							<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
								<img className="tw-w-full" src={`/images/icon-arrow-${ toggleRequiredInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
							</span>
						</span>
					</h3>
					{ toggleRequiredInformation ? <>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">

						
							<li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Start Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayStartDate }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Start Time
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayStartTime }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										End Date
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayEndDate }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										End Time
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.displayEndTime }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-status.svg" className="tw-opacity-70">
										Status
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.status }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Notice Type
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.noticeType }
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Details
										</LabelOutputs>
									</span>
									<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
										{ notice?.details }
									</span>
							</li>
						</ul>

					{(notice.status !== "Cancelled") && (navContext.scheduleAdmin || navContext.clientScheduleAdmin) && <div className="tw-flex tw-justify-end tw-pr-20px tw-pb-10px">
						{ editMode ? <>
                            <ButtonError className="tw-min-w-80px" onClick={ e => {
                                e.preventDefault();
                                handleCancel();
                            }}>
                                Void
                            </ButtonError>
                            <ButtonWarning className="tw-min-w-80px tw-ml-10px" onClick={ e => {
                                e.preventDefault();
                                toggleEditMode();
                            }}>
                                Cancel
                            </ButtonWarning>
                             </>:<ButtonPrimary className="tw-min-w-80px" onClick={ e => {
							e.preventDefault();
							toggleEditMode();
						}}>
							Edit
						</ButtonPrimary>}
					</div> }
					</> : <></> }
				</div>
			</div>
			<div className="tw-border-t tw-border-t-light-accent tw-pt-20px tw-flex-grow tw-w-full  tw-h-full tw-overflow-hidden">
						<div className="tw-flex tw-flex-col tw-w-full tw-h-full tw-overflow-hidden">
							<div className="tw-px-40px tw-pb-10px tw-flex-shrink-0 tw-w-full tw-space-y-10px">
								<div>
										<div className="tw-w-full tw-flex tw-justify-between">
											<h3 className=" tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]">
												Logs
											</h3>
										</div>
								</div>
								<div>
									{ logsLoading ? <>
										<div className="tw-flex tw-justify-center tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
									</>:<>
										<AvatarNameInlineList>
											<span className="tw-text-6 tw-text-dark-light">{noticeLogs? noticeLogs.length : "0"} Logs</span>
										</AvatarNameInlineList>
									</>}
									
								</div>
							</div>
							<div className="tw-flex-grow tw-space-y-15px tw-overflow-x-hidden tw-overflow-y-auto">
								<div className="tw-pt-20px tw-px-40px tw-pb-40px">
									{ noticeLogs && noticeLogs?.map( (log, index) => {
										return <CommentBlock key={`comment_${index}`} data={{
											user: {
												name: log?.userName,
												image: null
											},
											comment: {
												createdOn: log?.displayLoggedTime
											}
										}}>
										{log?.logMessage}
									</CommentBlock>
									})}
								</div>
							</div>
						</div>
					</div>
		</div>
	</>
}

export default NoticeDetailsPanel;