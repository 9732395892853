import { useEffect, useLayoutEffect, useState } from "react";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { SuggestedAction } from "../../types/servicePlan";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useEditPlanContext } from "../../pages/EditServicePlanPage";
import { addSuggestedAction, deleteSuggestedAction, updateSuggestedAction } from "../../apicalls/servicePlanService";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { v4 as uuidv4 } from 'uuid';
import { useAppContext } from "../../Context";
import { set } from "lodash";
import { LoadingSpinner } from "./loadingSpinner";

export const ViewSuggestedActionSection = props => {
    return (<>
        <div className="tw-pb-2 tw-flex">
            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">{props?.action.actionTitle}</span>
            {props?.showEdit && <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={e => props?.onEdit(props?.action.suggestedActionID)}>Edit</span>}
        </div>
        <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-mt-1">{props?.action.actionDetails}</span>
        <div></div>
    </>);
}

const EditSuggestedActionSection = props => {
    const planContext = useNewPlanContext();
    const [refresh, setRefresh] = useState(false);
    const editPlanContext = useEditPlanContext();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const SuggestedActionSchema = Yup.object().shape({
        actionTitle: Yup.string()
          .max(50, 'Action title must be at most 50 characters')
          .required('Action title is required'),
        actionDetails: Yup.string().required('Action details are required'),
      });
      
    const initialValues = {
        actionTitle: props?.action.actionTitle,
        actionDetails: props?.action.actionDetails,
    };

    const suggestedActionFormik = useFormik({
        initialValues,
        validationSchema: SuggestedActionSchema,
        onSubmit: (values) => {
            console.log(values);

            var newAction: SuggestedAction = {
                actionTitle: values.actionTitle,
                actionDetails: values.actionDetails,
                suggestedActionID: props?.action.suggestedActionID,
                servicePlanID: ""
            }

            if(props?.editPlan){
                setLoading(true);
            }

            props?.onSave(props?.action.suggestedActionID, newAction);

            // planContext.setPlanSuggestedActions(planContext.planSuggestedActions.map(
            //     el => el.suggestedActionID ===  newAction.suggestedActionID? { ...el, ...newAction } : el
            // ));

            // props?.toggleEditMode();
        },
    });

    const handleRemoveAction = () => {
        if(props?.editPlan){
            setShowConfirmDelete(true);
        } else {
            const index = planContext.planSuggestedActions.indexOf(props?.action);
            const newItems = [...planContext.planSuggestedActions.slice(0, index), ...planContext.planSuggestedActions.slice(index + 1)];
            planContext.setPlanSuggestedActions(newItems);

            props?.handleCancel();
        }
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const deletePlanSuggestedAction = async () => {   
        setLoading(true);
        var result = await deleteSuggestedAction(editPlanContext.servicePlan.servicePlanID, props?.action.suggestedActionID, appContext.handleAPICallError);
        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Suggested action deleted successfully");
            appContext.setShowSuccessModal(true);
            const index = editPlanContext.planSuggestedActions.indexOf(props?.action);
            const newItems = [...editPlanContext.planSuggestedActions.slice(0, index), ...editPlanContext.planSuggestedActions.slice(index + 1)];
            editPlanContext.setPlanSuggestedActions(newItems);  
        }
       
        setShowConfirmDelete(false);
    }
    
    return(<>
        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Edit Suggested Action</span>
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${suggestedActionFormik.touched.actionTitle && suggestedActionFormik.errors.actionTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...suggestedActionFormik.getFieldProps('actionTitle')}
                                                    />
                                                    {suggestedActionFormik.touched.actionTitle && suggestedActionFormik.errors.actionTitle && <p className="tw-text-red-500"><>{suggestedActionFormik.errors.actionTitle}</></p>}
                                                </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="details"
                                                    id="details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${suggestedActionFormik.touched.actionDetails && suggestedActionFormik.errors.actionDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...suggestedActionFormik.getFieldProps('actionDetails')}
                                                    />
                                                    {suggestedActionFormik.touched.actionDetails && suggestedActionFormik.errors.actionDetails && <p className="tw-text-red-500"><>{suggestedActionFormik.errors.actionDetails}</></p>}
                                                </div>
                                        </div>
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </> : <>
                                    
                                    {showConfirmDelete ? <>
                                    <p className="tw-text-primary">Are you sure you want to delete this suggested action?</p>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deletePlanSuggestedAction}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={handleRemoveAction}>
                                                {props?.editPlan ? 'Delete' : 'Remove'}
                                            </ButtonSecondary>
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.onCancel}>
                                                {suggestedActionFormik.dirty ? 'Discard' : 'Cancel'}
                                            </ButtonSecondary>
                                            {suggestedActionFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={suggestedActionFormik.handleSubmit}>
                                                Update
                                            </ButtonPrimary>}

                                    </div>
                                    </>}
                                    </>}
    </>);
}

const EditSuggestedActionCard = props => {
    // const [editMode, setEditMode] = useState(false);

    // const toggleEditMode = () => {
    //     setEditMode(!editMode);
    // }

    // useEffect(() => {
    //     setEditMode(false);
    //     console.log("Suggestion card updated");
    // }, [props.action]);

    return (<>
        {props?.isEditing ? <EditSuggestedActionSection action={props.action} onEdit={props?.onEdit} onSave={props?.onSave} onCancel={props?.onCancel} editPlan={props?.editPlan}/> : <ViewSuggestedActionSection action={props.action}  onEdit={props?.onEdit} onSave={props?.onSave} onCancel={props?.onCancel} showEdit={true}/>}
    </>);
}

export const EditSuggestedActionList = props => {
    const [editId, setEditId] = useState<string | null>(null);

    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, newAction: SuggestedAction) {
        if(props?.editPlan){
            var result = await updateSuggestedAction(newAction, editPlanContext.servicePlan.servicePlanID, newAction.suggestedActionID, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Suggested action updated successfully");
                appContext.setShowSuccessModal(true);
                editPlanContext.setPlanSuggestedActions(editPlanContext.planSuggestedActions.map(
                    el => el.suggestedActionID ===  newAction.suggestedActionID? { ...el, ...newAction } : el
                ));
            }
            
        } else {
            planContext.setPlanSuggestedActions(planContext.planSuggestedActions.map(
                el => el.suggestedActionID ===  newAction.suggestedActionID? { ...el, ...newAction } : el
            ));
        }
        

        setEditId(null);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    const [showSuggestedActionCard, setShowSuggestedActionCard] = useState(false);

    const SuggestedActionSchema = Yup.object().shape({
        actionTitle: Yup.string()
          .max(50, 'Action title must be at most 50 characters')
          .required('Action title is required'),
        actionDetails: Yup.string().required('Action details are required'),
      });
      
    const initialValues = {
        actionTitle: '',
        actionDetails: '',
    };

    const suggestedActionFormik = useFormik({
        initialValues,
        validationSchema: SuggestedActionSchema,
        onSubmit: async (values) => {
            console.log(values);

            var newAction: SuggestedAction = {
                actionTitle: values.actionTitle,
                actionDetails: values.actionDetails,
                suggestedActionID: uuidv4(),
                servicePlanID: ""
            }

            setLoading(true);

            var result = await addSuggestedAction(newAction, editPlanContext.servicePlan.servicePlanID, appContext.handleAPICallError);
            await editPlanContext.updateLocalSuggestedActions();
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Suggested action added successfully");
                appContext.setShowSuccessModal(true);
            }
            

            //planContext.setPlanSuggestedActions([...planContext.planSuggestedActions, newAction]);
            toggleSuggestedActionCard();
        },
    });

    const toggleSuggestedActionCard = () => {
        setShowSuggestedActionCard(!showSuggestedActionCard);
        setEditId(null);
        setLoading(false);
        suggestedActionFormik.resetForm();
    }

    return (<>
        {props?.editPlan && <>
            {showSuggestedActionCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                                    <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">New Suggested Action</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${suggestedActionFormik.touched.actionTitle && suggestedActionFormik.errors.actionTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...suggestedActionFormik.getFieldProps('actionTitle')}
                                                    />
                                                    {suggestedActionFormik.touched.actionTitle && suggestedActionFormik.errors.actionTitle && <p className="tw-text-red-500">{suggestedActionFormik.errors.actionTitle}</p>}
                                                </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="details"
                                                    id="details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${suggestedActionFormik.touched.actionDetails && suggestedActionFormik.errors.actionDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...suggestedActionFormik.getFieldProps('actionDetails')}
                                                    />
                                                    {suggestedActionFormik.touched.actionDetails && suggestedActionFormik.errors.actionDetails && <p className="tw-text-red-500">{suggestedActionFormik.errors.actionDetails}</p>}
                                                </div>
                                        </div>
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleSuggestedActionCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={suggestedActionFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                    </>}
                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2 tw-mb-4">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleSuggestedActionCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Suggested Action</span>
                                            </div>  
                                    </div>
                            </div>
                            </>}
        </>}

        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
            {props?.actions.length > 0 && props?.actions.map((action, index) => (
                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow" key={action.suggestedActionID}>
                    <EditSuggestedActionCard action={action} onEdit={handleEdit} onSave={handleSave} onCancel={handleCancel} isEditing={action.suggestedActionID === editId} editPlan={props?.editPlan}></EditSuggestedActionCard>
                </div>
            ))}
        </div>
    </>);
}

export default EditSuggestedActionCard;
