import { Dialog as TWDialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/solid'
import { ButtonPrimary, ButtonSecondary, Input } from '@medforall/medforall-component-library';
import { CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { addClient, findDuplicateClient, findDuplicateInsurance } from '../apicalls/clientMgmtService';
import EVVService from '../types/evvService';
import Patient, { PatientContact } from '../types/patient';
import PatientInsurance from '../types/patientInsurance';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAppContext } from '../Context';

const steps = [
  { id: '1', name: 'Personal Info', href: '#', status: 'current' },
  { id: '2', name: 'Insurance', href: '#', status: 'upcoming' },
  { id: '3', name: 'Preview', href: '#', status: 'upcoming' },
]

export const patientSchema = Yup.object().shape({
    FirstName: Yup.string().required('First name is required'),
    LastName: Yup.string().required('Last name is required'),
    DateOfBirth: Yup.date()
      .max(new Date(), 'Date of birth must be in the past')
      .required('Date of birth is required'),
    Phone: Yup.string()
      .required('Phone number is required')
      .matches(/^(\([0-9]{3}\)|[0-9]{3})[ -]?[0-9]{3}[ -]?[0-9]{4}$/, 'Phone number must be in the format (123) 456-7890 or 123-456-7890'),
    State: Yup.string().required('State is required'),
    County: Yup.string().required('County is required'),
    InternalEmail: Yup.string().email('Invalid email address').required('Internal Email is required'),
    PersonalEmail: Yup.string().email('Invalid email address').required('External Email is required'),
    ServiceType: Yup.string().required('Service type is required'),
    SocialSecurityNumber: Yup.string()
      .required('Social Security Number is required')
      .matches(/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/, 'Social Security Number must be in the format 123-45-6789'),
  });

export const patientContactSchema = Yup.object().shape({
    FirstName: Yup.string().required('First name is required'),
    LastName: Yup.string().required('Last name is required'),
    PhoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^(\([0-9]{3}\)|[0-9]{3})[ -]?[0-9]{3}[ -]?[0-9]{4}$/, 'Phone number must be in the format (123) 456-7890 or 123-456-7890'),
    Email: Yup.string().email('Invalid email address').required('Email is required'),
    Relationship: Yup.string().required('Relationship is required'),
  });

export const insuranceSchema = Yup.object().shape({
    insuranceType: Yup.string().required("Insurance Type is Required"),
    medicaidNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Medicaid Number must be numbers only')
    .length(12, 'Medicaid Number must be 12 digits long')
    .required("Medicaid Number is Required"),
    medicaidEffectiveDate: Yup.date()
    .max(new Date(), 'Medicaid Effective Date must be in the past')
    .required('Medicaid Effective Date is required'),
    doddNumber: Yup.string()
    .matches(/^[0-9]+$/, 'DODD Number must be numbers only')
    .length(7, 'DODD Number must be 7 digits long')
    .required("DODD Number is Required"),
  });

export const evvServiceSchema = Yup.object().shape({
    serviceName: Yup.string().required("Service name is required"),
    payer: Yup.string().required("Payer is required"),
    payerProgram: Yup.string().required("Payer program is required"),
    procedureCode: Yup.string().required("Procedure code is required"),
});

const NewClientPage = props => {
    const [activeSteps, setActiveSteps] = useState(steps);
    const [patientContacts, setPatientContacts] = useState([]);
    const [showBackupContactForm, setShowBackupContactForm] = useState(true);
    const [backupContacts, setBackupContacts] = useState([]);
    const [patientServices, setPatientServices] = useState([]);
    const [showPatientServiceForm, setShowPatientServiceForm] = useState(true);
    const [savingClient, setSavingClient] = useState(false);
    const [saveClientOpen, setSaveClientOpen] = useState(false);
    const [duplicateClientOpen, setDuplicateClientOpen] = useState(false);
    const [duplicateInsuranceOpen, setDuplicateInsuranceOpen] = useState(false);
    let navigate = useNavigate();
    const appContext = useAppContext();

    const handleClientSuccessClose = () => {
		setSaveClientOpen(false);

		navigate(-1);
	}

    const goToClientManagement = () => {
        navigate(`/client/client-management`);
    }

    // useEffect(() => {
    //     console.log("Patient Contacts Updated")
    //     //console.log(patientContacts);
    //     console.log(backupContacts);

    // }, [backupContacts]);

    const insuranceInfoFormik = useFormik({
        initialValues: {
            insuranceType: "",
            medicaidNumber: "",
            medicaidEffectiveDate: "",
            doddNumber: "",
        },
        validationSchema: insuranceSchema,
        onSubmit: async (values) => {
            // handle form submission
            console.log("Insurance Form submitted")
            console.log(values);

            const newInsurance: PatientInsurance = {
                insuranceType: values.insuranceType,
                medicaidID: values.medicaidNumber,
                medicaidEffectiveDate: new Date(values.medicaidEffectiveDate),
                doddNumber: values.doddNumber,
                patientID: '',
                patientInsuranceID: ''
            }

            const duplicates = await findDuplicateInsurance(newInsurance, appContext.handleAPICallError);
            console.log(duplicates);

            if (duplicates && duplicates.length > 0) {
                setDuplicateInsuranceOpen(true);
            } else {
                setDuplicateInsuranceOpen(false);
                goToPreview();
            }
            

            
        },
    });

    const evvInfoFormik = useFormik({
        initialValues: {
            serviceName: "",
            payer: "",
            payerProgram: "",
            procedureCode: "",
        },
        validationSchema: evvServiceSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("EVV Form submitted")
            console.log(values);

            addToPatientServices(values);

            setShowPatientServiceForm(false);

        },
    });

    const newPhoneFormik = useFormik({
        initialValues: {
          FirstName: '',
          LastName: '',
          PhoneNumber: '',
          Email: '',
          Relationship: '',
        },
        validationSchema: patientContactSchema,
        onSubmit: (values) => {
          // handle form submission
          console.log("Form submitted")
          addToPatientContacts(values);

          //newPhoneFormik.resetForm();

          setShowBackupContactForm(false);
        },
    });

    const addToPatientContacts = (values) => {
        console.log("Adding to patient contacts");
        console.log(values);
        var newContact: PatientContact = {
            contactFirstName: values.FirstName,
            contactLastName: values.LastName,
            contactPhoneNumber: values.PhoneNumber,
            contactEmail: values.Email,
            contactRelationship: values.Relationship,
            patientContactID: '',
            patientID: ''
        };
        console.log(newContact);
        //setPatientContacts([...patientContacts, newContact]);
        setBackupContacts([...backupContacts, newContact]);
        // const arrayvalue = [ ...patientContacts ];
        // arrayvalue.push(newContact);
        // console.log([...patientContacts, newContact]);
        // console.log(arrayvalue);
        // const newContacts = patientContacts.push(newContact);
        // console.log(newContacts);
        //setPatientContacts(arrayvalue);

    }

    const addToPatientServices = (values) => {
        console.log("Adding to patient services");
        console.log(values);
        var newService: EVVService = {
            serviceName: values.serviceName,
            payer: values.payer,
            payerProgram: values.payerProgram,
            procedureCode: values.procedureCode,
            serviceID: '',
            patientID: ''
        };

        setPatientServices([...patientServices, newService]);
    }


    const patientInfoFormik = useFormik({
        initialValues: {
          FirstName: '',
          LastName: '',
          DateOfBirth: '',
          Phone: '',
          State: '',
          County: '',
          InternalEmail: '',
          PersonalEmail: '',
          ServiceType: '',
          SocialSecurityNumber: '',
        },
        validationSchema: patientSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Form submitted")
            console.log(values);
            const newPatient: Patient = {
                firstName: values.FirstName,
                lastName: values.LastName,
                dateOfBirth: new Date(values.DateOfBirth),
                phone: values.Phone,
                state: values.State,
                county: values.County,
                internalEmail: values.InternalEmail,
                personalEmail: values.PersonalEmail,
                serviceType: values.ServiceType,
                socialSecurityNumber: values.SocialSecurityNumber,
                patientID: '',
                contacts: [],
                evvServices: [],
                email: values.InternalEmail,
                patientDetailID: '',
                active: false,
                patientDetail: undefined,
                testPatient: false,
                insurance: undefined
            };

            const duplicates = await findDuplicateClient(newPatient, appContext.handleAPICallError);
            console.log(duplicates);

            if (duplicates && duplicates.length > 0) {
                setDuplicateClientOpen(true);
            } else {
                setDuplicateClientOpen(false);
                goToInsurance();
            }


            
        },
      });

    const goToInsurance = () => {
        console.log(patientInfoFormik.values.ServiceType)

        setActiveSteps([
            { id: '1', name: 'Personal Info', href: '#', status: 'complete' },
            { id: '2', name: 'Insurance', href: '#', status: 'current' },
            { id: '3', name: 'Preview', href: '#', status: 'upcoming' },
        ])
    }

    const goToPreview = () => {
        setActiveSteps([
            { id: '1', name: 'Personal Info', href: '#', status: 'complete' },
            { id: '2', name: 'Insurance', href: '#', status: 'complete' },
            { id: '3', name: 'Preview', href: '#', status: 'current' },
        ])
    }

    const openBackupContactForm = () => {
        newPhoneFormik.resetForm();
        setShowBackupContactForm(true);
    }

    const closeBackupContactForm = () => {
        setShowBackupContactForm(false);
        newPhoneFormik.resetForm();
    }

    const goToPersonalInfo = () => {
        setActiveSteps([
            { id: '1', name: 'Personal Info', href: '#', status: 'current' },
            { id: '2', name: 'Insurance', href: '#', status: 'upcoming' },
            { id: '3', name: 'Preview', href: '#', status: 'upcoming' },
        ])
    }

    const handleRemoveContact = (contact) => {
        const index = backupContacts.indexOf(contact);
        const newItems = [...backupContacts.slice(0, index), ...backupContacts.slice(index + 1)];
        setBackupContacts(newItems);
    };

    const closePatientServiceForm = () => {
        setShowPatientServiceForm(false);
        evvInfoFormik.resetForm();
    }

    const openPatientServiceForm = () => {
        evvInfoFormik.resetForm();
        setShowPatientServiceForm(true);
    }

    const handleRemovePatientService = (service) => {
        const index = patientServices.indexOf(service);
        const newItems = [...patientServices.slice(0, index), ...patientServices.slice(index + 1)];
        setPatientServices(newItems);
    };

    const handleSaveClient = async () => {
        var patientInsurance: PatientInsurance = {
            patientID: '',
            patientInsuranceID: '',
            insuranceType: insuranceInfoFormik.values.insuranceType,
            medicaidID: insuranceInfoFormik.values.medicaidNumber,
            medicaidEffectiveDate: new Date(insuranceInfoFormik.values.medicaidEffectiveDate),
            doddNumber: insuranceInfoFormik.values.doddNumber,
        }


        var newClient: Patient = {
            firstName: patientInfoFormik.values.FirstName,
            lastName: patientInfoFormik.values.LastName,
            dateOfBirth: new Date(patientInfoFormik.values.DateOfBirth),
            phone: patientInfoFormik.values.Phone,
            state: patientInfoFormik.values.State,
            county: patientInfoFormik.values.County,
            internalEmail: patientInfoFormik.values.InternalEmail,
            personalEmail: patientInfoFormik.values.PersonalEmail,
            serviceType: patientInfoFormik.values.ServiceType,
            socialSecurityNumber: patientInfoFormik.values.SocialSecurityNumber,
            contacts: backupContacts,
            evvServices: patientServices,
            insurance: patientInsurance,
            patientID: '',
            email: patientInfoFormik.values.InternalEmail,
            patientDetailID: '',
            active: true,
            patientDetail: undefined,
            testPatient: false
        };

        console.log(newClient);

        setSavingClient(true);

        const result = await addClient(newClient, appContext.handleAPICallError);

        setSaveClientOpen(true);
        setSavingClient(false);
        
    }


    return (<>
        <div className="tw-px-xl tw-py-xl">
            <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Add New Client</h2>
            </div>

            <Transition.Root show={saveClientOpen} as={Fragment}>
                <TWDialog as="div" className="tw-relative tw-z-10" onClose={handleClientSuccessClose}>
                    <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                    >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                    </Transition.Child>

                    <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                        <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="tw-ease-out tw-duration-300"
                            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leave="tw-ease-in tw-duration-200"
                            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            >
                            <TWDialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-sm sm:tw-p-6">
                                <div>
                                <div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-green-100">
                                    <CheckIcon className="tw-h-6 tw-w-6 tw-text-green-600" aria-hidden="true" />
                                </div>
                                <div className="tw-mt-3 tw-text-center sm:tw-mt-5">
                                    <TWDialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                                    Added Successfully
                                    </TWDialog.Title>
                                    <div className="tw-mt-2">
                                    <p className="tw-text-sm tw-text-gray-500">
                                        New Client Saved Successfully
                                    </p>
                                    </div>
                                </div>
                                </div>
                                <div className="tw-mt-5 sm:tw-mt-6">
                                <button
                                    type="button"
                                    className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-primary tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-indigo-600"
                                    onClick={handleClientSuccessClose}
                                >
                                    Go back to Client List
                                </button>
                                </div>
                            </TWDialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </TWDialog>
            </Transition.Root>

            <Transition.Root show={duplicateClientOpen} as={Fragment}>
                <TWDialog as="div" className="tw-relative tw-z-10" onClose={setDuplicateClientOpen}>
                    <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                    >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                    </Transition.Child>

                    <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                    <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                        <Transition.Child
                        as={Fragment}
                        enter="tw-ease-out tw-duration-300"
                        enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                        leave="tw-ease-in tw-duration-200"
                        leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                        leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        >
                        <TWDialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-lg sm:tw-p-6">
                            
                            <div className="sm:tw-flex sm:tw-items-start">
                            <div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                                <ExclamationTriangleIcon className="tw-h-6 tw-w-6 tw-text-red-600" aria-hidden="true" />
                            </div>
                            <div className="tw-mt-3 tw-text-center sm:tw-ml-4 sm:tw-mt-0 sm:tw-text-left">
                                <TWDialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                                Duplicate Client
                                </TWDialog.Title>
                                <div className="tw-mt-2">
                                <p className="tw-text-sm tw-text-gray-500">
                                    The entered client is duplicate of existing clients. Please make sure email, phone number and social security number are unique.
                                </p>
                                </div>
                            </div>
                            </div>
                            <div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
                            <button
                                type="button"
                                className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-red-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-red-500 sm:tw-ml-3 sm:tw-w-auto"
                                onClick={() => setDuplicateClientOpen(false)}
                            >
                                OK
                            </button>
                            </div>
                        </TWDialog.Panel>
                        </Transition.Child>
                    </div>
                    </div>
                </TWDialog>
            </Transition.Root>

            <Transition.Root show={duplicateInsuranceOpen} as={Fragment}>
                <TWDialog as="div" className="tw-relative tw-z-10" onClose={setDuplicateInsuranceOpen}>
                    <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                    >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                    </Transition.Child>

                    <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                    <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                        <Transition.Child
                        as={Fragment}
                        enter="tw-ease-out tw-duration-300"
                        enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                        leave="tw-ease-in tw-duration-200"
                        leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                        leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        >
                        <TWDialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-lg sm:tw-p-6">
                            
                            <div className="sm:tw-flex sm:tw-items-start">
                            <div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                                <ExclamationTriangleIcon className="tw-h-6 tw-w-6 tw-text-red-600" aria-hidden="true" />
                            </div>
                            <div className="tw-mt-3 tw-text-center sm:tw-ml-4 sm:tw-mt-0 sm:tw-text-left">
                                <TWDialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                                Duplicate Insurance
                                </TWDialog.Title>
                                <div className="tw-mt-2">
                                <p className="tw-text-sm tw-text-gray-500">
                                    The entered insurance is duplicate of existing insurance records. Please make sure Medicaid ID and DODD Number are unique.
                                </p>
                                </div>
                            </div>
                            </div>
                            <div className="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
                            <button
                                type="button"
                                className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-red-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-red-500 sm:tw-ml-3 sm:tw-w-auto"
                                onClick={() => setDuplicateInsuranceOpen(false)}
                            >
                                OK
                            </button>
                            </div>
                        </TWDialog.Panel>
                        </Transition.Child>
                    </div>
                    </div>
                </TWDialog>
            </Transition.Root>

            <nav aria-label="Progress">
                <ol role="list" className="tw-divide-y tw-divide-gray-300 tw-rounded-md tw-border tw-border-gray-300 md:tw-flex md:tw-divide-y-0">
                    {activeSteps.map((step, stepIdx) => (
                    <li key={step.name} className="tw-relative md:tw-flex md:tw-flex-1">
                        {step.status === 'complete' ? (
                        <div className="tw-group tw-flex tw-w-full tw-items-center">
                            <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                            <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary group-hover:tw-bg-indigo-800">
                                <CheckIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                            </span>
                            <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">{step.name}</span>
                            </span>
                        </div>
                        ) : step.status === 'current' ? (
                        <div className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium" aria-current="step">
                            <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-primary">
                            <span className="tw-text-primary">{step.id}</span>
                            </span>
                            <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-primary">{step.name}</span>
                        </div>
                        ) : (
                        <div className="tw-group tw-flex tw-items-center">
                            <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                            <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 group-hover:tw-border-gray-400">
                                <span className="tw-text-gray-500 group-hover:tw-text-gray-900">{step.id}</span>
                            </span>
                            <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-500 group-hover:tw-text-gray-900">{step.name}</span>
                            </span>
                        </div>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                        <>
                            {/* Arrow separator for lg screens and up */}
                            <div className="tw-absolute tw-top-0 tw-right-0 tw-hidden tw-h-full tw-w-5 md:tw-block" aria-hidden="true">
                            <svg
                                className="tw-h-full tw-w-full tw-text-gray-300"
                                viewBox="0 0 22 80"
                                fill="none"
                                preserveAspectRatio="none"
                            >
                                <path
                                d="M0 -2L20 40L0 82"
                                vectorEffect="non-scaling-stroke"
                                stroke="currentcolor"
                                strokeLinejoin="round"
                                />
                            </svg>
                            </div>
                        </>
                        ) : null}
                    </li>
                    ))}
                </ol>
            </nav>

            {activeSteps[0].status === 'current' && 
                <div className="tw-space-y-6 tw-mt-4">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow sm:tw-rounded-lg sm:tw-p-6">
                        <div className="tw-space-y-6 tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        First Name
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.FirstName && patientInfoFormik.errors.FirstName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('FirstName')}
                                        />
                                        {patientInfoFormik.touched.FirstName && patientInfoFormik.errors.FirstName && <p className="tw-text-red-500">{patientInfoFormik.errors.FirstName}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Last Name
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.LastName && patientInfoFormik.errors.LastName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('LastName')}
                                        />
                                        {patientInfoFormik.touched.LastName && patientInfoFormik.errors.LastName && <p className="tw-text-red-500">{patientInfoFormik.errors.LastName}</p>}
                                    </div>
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Date of Birth
                                    </label>
                                    <div className="tw-mt-2">
                                        <Input
                                        min="2000-01-01" max="2024-12-31"
                                        type="date"
                                        name="dateOfBirth"
                                        id="dateOfBirth"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.DateOfBirth && patientInfoFormik.errors.DateOfBirth ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('DateOfBirth')}
                                        />
                                        {patientInfoFormik.touched.DateOfBirth && patientInfoFormik.errors.DateOfBirth && <p className="tw-text-red-500">{patientInfoFormik.errors.DateOfBirth}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Phone
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.Phone && patientInfoFormik.errors.Phone ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('Phone')}
                                        />
                                        {patientInfoFormik.touched.Phone && patientInfoFormik.errors.Phone && <p className="tw-text-red-500">{patientInfoFormik.errors.Phone}</p>}
                                    </div>
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        State
                                    </label>
                                    <select
                                        id="state"
                                        name="state"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.State && patientInfoFormik.errors.State ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('State')}
                                    >
                                        <option value="">Select State</option>
                                        <option value="Ohio">Ohio</option>
                                    </select>
                                    {patientInfoFormik.touched.State && patientInfoFormik.errors.State && <p className="tw-text-red-500">{patientInfoFormik.errors.State}</p>}

                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        County
                                    </label>
                                    <select
                                        id="county"
                                        name="county"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.County && patientInfoFormik.errors.County ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('County')}
                                    >
                                        <option value="">Select County</option>
                                        <option value="Franklin">Franklin</option>
                                        <option value="Cuyahoga">Cuyahoga</option>
                                        <option value="Licking">Licking</option>
                                    </select>
                                    {patientInfoFormik.touched.County && patientInfoFormik.errors.County && <p className="tw-text-red-500">{patientInfoFormik.errors.County}</p>}
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Internal Email
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="email"
                                        name="internalMail"
                                        id="internalMail"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.InternalEmail && patientInfoFormik.errors.InternalEmail ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('InternalEmail')}
                                        />
                                        {patientInfoFormik.touched.InternalEmail && patientInfoFormik.errors.InternalEmail && <p className="tw-text-red-500">{patientInfoFormik.errors.InternalEmail}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Personal Email
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="email"
                                        name="personalMail"
                                        id="personalMail"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.PersonalEmail && patientInfoFormik.errors.PersonalEmail ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('PersonalEmail')}
                                        />
                                        {patientInfoFormik.touched.PersonalEmail && patientInfoFormik.errors.PersonalEmail && <p className="tw-text-red-500">{patientInfoFormik.errors.PersonalEmail}</p>}
                                    </div>
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Service Type
                                    </label>
                                    <select
                                        id="serviceType"
                                        name="serviceType"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.ServiceType && patientInfoFormik.errors.ServiceType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('ServiceType')}
                                    >
                                        <option value="">Select Service Type</option>
                                        <option value="Remote Support">Remote Support</option>
                                        <option value="Direct Support">Direct Support</option>
                                        <option value="Hybrid">Hybrid Support</option>
                                    </select>
                                    {patientInfoFormik.touched.ServiceType && patientInfoFormik.errors.ServiceType && <p className="tw-text-red-500">{patientInfoFormik.errors.ServiceType}</p>}
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Social Security Number
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="ssn"
                                        id="ssn"
                                        className={`tw-block tw-w-full tw-rounded-md ${patientInfoFormik.touched.SocialSecurityNumber && patientInfoFormik.errors.SocialSecurityNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...patientInfoFormik.getFieldProps('SocialSecurityNumber')}
                                        />
                                        {patientInfoFormik.touched.SocialSecurityNumber && patientInfoFormik.errors.SocialSecurityNumber && <p className="tw-text-red-500">{patientInfoFormik.errors.SocialSecurityNumber}</p>}
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-text-primary tw-flex-grow">Contacts</span>
                            <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-text-primary tw-underline tw-cursor-pointer" onClick={openBackupContactForm}>Add</span>
                        </div>
                        {showBackupContactForm &&
                        <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        First Name
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        className={`tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.FirstName && newPhoneFormik.errors.FirstName ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...newPhoneFormik.getFieldProps('FirstName')}
                                        />
                                        {newPhoneFormik.touched.FirstName && newPhoneFormik.errors.FirstName && <p className="tw-text-red-500">{newPhoneFormik.errors.FirstName}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                    Last Name
                                </label>
                                <div className="tw-mt-2">
                                    <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className={`tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.LastName && newPhoneFormik.errors.LastName ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                    {...newPhoneFormik.getFieldProps('LastName')} 
                                    />
                                    {newPhoneFormik.touched.LastName && newPhoneFormik.errors.LastName && <p className="tw-text-red-500">{newPhoneFormik.errors.LastName}</p>}
                                </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Email
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="email"
                                        name="contactMail"
                                        id="contactMail"
                                        className={`tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.Email && newPhoneFormik.errors.Email ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...newPhoneFormik.getFieldProps('Email')}
                                        />
                                        {newPhoneFormik.touched.Email && newPhoneFormik.errors.Email && <p className="tw-text-red-500">{newPhoneFormik.errors.Email}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Phone
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        className={`tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.PhoneNumber && newPhoneFormik.errors.PhoneNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...newPhoneFormik.getFieldProps('PhoneNumber')}
                                        />
                                        {newPhoneFormik.touched.PhoneNumber && newPhoneFormik.errors.PhoneNumber && (
                                            <p className="tw-text-red-500">{newPhoneFormik.errors.PhoneNumber}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Relationship
                                    </label>
                                    <select
                                        id="relationship"
                                        name="relationship"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.Relationship && newPhoneFormik.errors.Relationship ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...newPhoneFormik.getFieldProps('Relationship')}
                                    >
                                        <option value="">Select Relationship</option>
                                        <option value="Self">Self</option>
                                        <option value="Parent">Parent</option>
                                        <option value="Guardian">Guardian</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {newPhoneFormik.touched.Relationship && newPhoneFormik.errors.Relationship && (
                                            <p className="tw-text-red-500">{newPhoneFormik.errors.Relationship}</p>
                                        )}
                                </div>
                            </div>
                            <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={closeBackupContactForm}>
                                                Cancel
                                        </ButtonSecondary>
                                        <ButtonPrimary className="tw-mt-md" type="submit" onClick={newPhoneFormik.handleSubmit}>
                                            Save
                                        </ButtonPrimary>

                            </div>
                        </div>
                        }

                        {backupContacts.length > 0 && backupContacts?.map((contact, index) => (
                            <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            First Name
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {contact.contactFirstName}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Last Name
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        value = {contact.contactLastName}
                                        disabled={true}
                                        />
                                    </div>
                                    </div>
                                    
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Email
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="email"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {contact.contactEmail}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Phone
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {contact.contactPhoneNumber}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Relationship
                                        </label>
                                        <select
                                            className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            value = {contact.contactRelationship}
                                            disabled={true}
                                        >
                                            <option value="">Select Relationship</option>
                                            <option value="Self">Self</option>
                                            <option value="Parent">Parent</option>
                                            <option value="Guardian">Guardian</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                e.preventDefault();
                                                handleRemoveContact(contact);
                                                }}>
                                                    Remove
                                            </ButtonSecondary>
                                            {/* <ButtonPrimary className="tw-mt-md">
                                                Edit
                                            </ButtonPrimary> */}

                                </div>
                        </div>))}

                        <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={goToClientManagement}>
                                            Cancel
                                    </ButtonSecondary>
                                    <ButtonPrimary className="tw-mt-md" onClick={patientInfoFormik.handleSubmit}>
                                        Next
                                    </ButtonPrimary>

                        </div>
                    </div>
                </div>
            }

            {activeSteps[1].status === 'current' &&
                <div className="tw-space-y-6 tw-mt-4">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow sm:tw-rounded-lg sm:tw-p-6">
                        <div className="tw-space-y-6 tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Insurance Type
                                    </label>
                                    <select
                                        id="insuranceType"
                                        name="insuranceType"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${insuranceInfoFormik.touched.insuranceType && insuranceInfoFormik.errors.insuranceType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...insuranceInfoFormik.getFieldProps('insuranceType')}
                                    >
                                        <option value="">Select Insurance Type</option>
                                        <option value="Medicaid">Medicaid</option>
                                        <option value="County Funded">County Funded</option>
                                        <option value="Private">Private</option>
                                    </select>
                                    {insuranceInfoFormik.touched.insuranceType && insuranceInfoFormik.errors.insuranceType && <div className="tw-text-red-500 tw-text-sm tw-mt-1">{insuranceInfoFormik.errors.insuranceType}</div>}

                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Medicaid Number
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="medicaidNumber"
                                        id="medicalNumber"
                                        className={`tw-block tw-w-full tw-rounded-md ${insuranceInfoFormik.touched.medicaidNumber && insuranceInfoFormik.errors.medicaidNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...insuranceInfoFormik.getFieldProps('medicaidNumber')}
                                        />
                                        {insuranceInfoFormik.touched.medicaidNumber && insuranceInfoFormik.errors.medicaidNumber && <div className="tw-text-red-500 tw-text-sm tw-mt-1">{insuranceInfoFormik.errors.medicaidNumber}</div>}
                                    </div>
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Medicaid Effective Date
                                    </label>
                                    <div className="tw-mt-2">
                                        <Input
                                        min="2000-01-01" max="2024-12-31"
                                        type="date"
                                        name="medicaidEffectiveDate"
                                        id="medicalEffectiveDate"
                                        className={`tw-block tw-w-full tw-rounded-md ${insuranceInfoFormik.touched.medicaidEffectiveDate && insuranceInfoFormik.errors.medicaidEffectiveDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...insuranceInfoFormik.getFieldProps('medicaidEffectiveDate')}
                                        />
                                        {insuranceInfoFormik.touched.medicaidEffectiveDate && insuranceInfoFormik.errors.medicaidEffectiveDate && <div className="tw-text-red-500 tw-text-sm tw-mt-1">{insuranceInfoFormik.errors.medicaidEffectiveDate}</div>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        DODD Number
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="doddNumber"
                                        id="doddNumber"
                                        className={`tw-block tw-w-full tw-rounded-md ${insuranceInfoFormik.touched.doddNumber && insuranceInfoFormik.errors.doddNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...insuranceInfoFormik.getFieldProps('doddNumber')}
                                        />
                                        {insuranceInfoFormik.touched.doddNumber && insuranceInfoFormik.errors.doddNumber && <div className="tw-text-red-500 tw-text-sm tw-mt-1">{insuranceInfoFormik.errors.doddNumber}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        { (patientInfoFormik.values.ServiceType === "Hybrid" || patientInfoFormik.values.ServiceType === "Direct Support") &&
                        <>
                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-text-primary tw-flex-grow">EVV</span>
                            <span className="tw-text-xs tw-leading-6 tw-text-gray-900 tw-text-primary tw-underline tw-cursor-pointer" onClick={openPatientServiceForm}>Add</span>
                        </div>

                        { showPatientServiceForm && 
                            <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">

                                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Service Name
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="serviceName"
                                            id="serviceName"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.serviceName && evvInfoFormik.errors.serviceName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('serviceName') }
                                            />
                                            { evvInfoFormik.touched.serviceName && evvInfoFormik.errors.serviceName && <div className="tw-text-red-500 tw-text-xs tw-mt-1">{ evvInfoFormik.errors.serviceName }</div> }
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Payer
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="payer"
                                            id="payer"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.payer && evvInfoFormik.errors.payer ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('payer') }
                                            />
                                            { evvInfoFormik.touched.payer && evvInfoFormik.errors.payer && <div className="tw-text-red-500 tw-text-xs tw-mt-1">{ evvInfoFormik.errors.payer }</div> }
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Payer Program
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="payerProgram"
                                            id="payerProgram"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.payerProgram && evvInfoFormik.errors.payerProgram ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('payerProgram') }
                                            />
                                            { evvInfoFormik.touched.payerProgram && evvInfoFormik.errors.payerProgram && <div className="tw-text-red-500 tw-text-xs tw-mt-1">{ evvInfoFormik.errors.payerProgram }</div> }
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Procedure Code
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="procedureCode"
                                            id="procedureCode"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.procedureCode && evvInfoFormik.errors.procedureCode ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('procedureCode') }
                                            />
                                            { evvInfoFormik.touched.procedureCode && evvInfoFormik.errors.procedureCode && <div className="tw-text-red-500 tw-text-xs tw-mt-1">{ evvInfoFormik.errors.procedureCode }</div> }
                                        </div>
                                    </div>
                                
                                </div>

                                <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={closePatientServiceForm}>
                                                        Cancel
                                                </ButtonSecondary>
                                                <ButtonPrimary className="tw-mt-md" onClick={evvInfoFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>

                                </div>

                            </div>
                        }

                        { patientServices.length > 0 && patientServices.map((service, index) => (
                            <div className="tw-space-y-6 tw-bg-grey-light tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6 tw-mt-4">

                                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Service Name
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                            value={service.serviceName}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Payer
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                            value={service.payer}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Payer Program
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                            value={service.payerProgram}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Procedure Code
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                            value={service.procedureCode}
                                            disabled={true}
                                            />
                                        </div>
                                    </div>
                                
                                </div>

                                <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                e.preventDefault();
                                                handleRemovePatientService(service);
                                                }}>
                                                        Remove
                                                </ButtonSecondary>
                                </div>

                        </div>
                        ))}

                        
                        </>

                        }

                        <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={goToPersonalInfo}>
                                           Back
                                    </ButtonSecondary>
                                    <ButtonPrimary className="tw-mt-md" onClick={insuranceInfoFormik.handleSubmit}>
                                        Next
                                    </ButtonPrimary>

                        </div>
                    </div>

                </div>
            }

            {activeSteps[2].status === 'current' && 
                <div className="tw-space-y-6 tw-mt-4">
                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6">

                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Personal Info</span>
                        </div>

                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>First Name</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{patientInfoFormik.values.FirstName}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Last Name</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{patientInfoFormik.values.LastName}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Date Of Birth</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{moment(patientInfoFormik.values.DateOfBirth).format("MM-DD-YYYY")}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Phone</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{patientInfoFormik.values.Phone}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>State</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{patientInfoFormik.values.State}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>County</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{patientInfoFormik.values.County}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Internal Email</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{patientInfoFormik.values.InternalEmail}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Personal Email</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{patientInfoFormik.values.PersonalEmail}</span>
                        </div>
                        <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Service Type</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{patientInfoFormik.values.ServiceType}</span>
                        </div>
                       
                    </div>

                    { backupContacts.length > 0 && 
                        <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6">
                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Contacts</span>
                        </div>
                        {backupContacts.map((contact, index) => {
                            if(index !== backupContacts.length - 1) {
                                return <>
                                    <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>First Name</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactFirstName}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Last Name</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactLastName}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Phone</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactPhoneNumber}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Email</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactEmail}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Relationship</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactRelationship}</span>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                </>
                            } else {
                                return <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                    <div className="tw-flex tw-px-4 tw-pb-2">
                                        <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>First Name</span>
                                        <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactFirstName}</span>
                                    </div>
                                    <div className="tw-flex tw-px-4 tw-pb-2">
                                        <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Last Name</span>
                                        <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactLastName}</span>
                                    </div>
                                    <div className="tw-flex tw-px-4 tw-pb-2">
                                        <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Phone</span>
                                        <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactPhoneNumber}</span>
                                    </div>
                                    <div className="tw-flex tw-px-4 tw-pb-2">
                                        <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Email</span>
                                        <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactEmail}</span>
                                    </div>
                                    <div className="tw-flex tw-px-4 tw-pb-2">
                                        <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Relationship</span>
                                        <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{contact.contactRelationship}</span>
                                    </div>
                                </div>
                            }
                            })}
                    </div>
                    }

                    

                    <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6">
                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Insurance</span>
                        </div>

                        <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Insurance Type</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{insuranceInfoFormik.values.insuranceType}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Medicaid Number</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{insuranceInfoFormik.values.medicaidNumber}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Medicaid Effective Date</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{moment(insuranceInfoFormik.values.medicaidEffectiveDate).format("MM-DD-YYYY")}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>DODD Number</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{insuranceInfoFormik.values.doddNumber}</span>
                            </div>
                        </div>

                        { patientServices.length > 0 && 
                            <>
                                <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                                <div className="tw-pb-3 tw-flex tw-px-4">
                                    <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">EVV</span>
                                </div>

                                {patientServices.map((service, index) => {
                                    if(index !== patientServices.length - 1) {
                                        return <>
                                            <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                                <div className="tw-flex tw-px-4 tw-pb-2">
                                                    <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Service Name</span>
                                                    <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{service.serviceName}</span>
                                                </div>
                                                <div className="tw-flex tw-px-4 tw-pb-2">
                                                    <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Payer</span>
                                                    <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{service.payer}</span>
                                                </div>
                                                <div className="tw-flex tw-px-4 tw-pb-2">
                                                    <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Payer Program</span>
                                                    <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{service.payerProgram}</span>
                                                </div>
                                                <div className="tw-flex tw-px-4 tw-pb-2">
                                                    <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Procedure Code</span>
                                                    <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{service.procedureCode}</span>
                                                </div>
                                            </div>
                                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                        </>
                                    } else {
                                        return <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Service Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{service.serviceName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Payer</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{service.payer}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Payer Program</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{service.payerProgram}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Procedure Code</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{service.procedureCode}</span>
                                            </div>
                                        </div>
                                    }
                                })}

                                
                            </>
                        }

                        
                    </div>

                    <div className="tw-flex tw-justify-end tw-mx-4">
                            {savingClient ? <>
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </>:<>
                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={goToInsurance}>
                                           Back
                                    </ButtonSecondary>
                                    <ButtonPrimary className="tw-mt-md" onClick={handleSaveClient}>
                                        Save
                                    </ButtonPrimary>
                            </>}
                                    

                    </div>
                </div>
            }
            
        </div>
    </>);
}

export default NewClientPage;