import { ButtonPrimary } from "@medforall/medforall-component-library";
import { useAppContext } from "../Context";

// testing:
import ServicePanel from "../components/panels/Service";

const LoadingItem = props => {
	return <>
		<div className={`${ props.className } tw-text-1 tw-rounded-5p tw-bg-white tw-inline-flex tw-flex-col tw-justify-start tw-min-w-80p tw-overflow-hidden`}>
			<div className="tw-bg-dark-light tw-w-full tw-h-15p tw-flex tw-rounded-b-5px">
				<span className="tw-hidden tw-speak-none">Placeholder Header Loading</span>
			</div>
			<div className="tw-px-10p tw-py-10p tw-flex tw-w-full">
				<ul className="tw-flex tw-flex-col tw-space-y-5p tw-w-full">
					<li className="tw-flex tw-w-20p tw-rounded-5p tw-bg-dark-light tw-h-10p">
						<span className="tw-hidden tw-speak-none">Placeholder Item Loading</span>
					</li>
					<li className="tw-flex tw-w-40p tw-rounded-5p tw-bg-dark-light tw-h-10p">
						<span className="tw-hidden tw-speak-none">Placeholder Item Loading</span>
					</li>
				</ul>
			</div>
		</div>
	</>
}

export const LoadingItems = props => {
	return <>
		<div className="tw-flex tw-flex-col tw-w-full tw-min-h-vh tw-py-40p tw-animate-pulse">
			{/* <div>
				<div className="tw-space-y-15p tw-mb-40p tw-w-full">
					<div className="tw-bg-dark-light tw-w-400p tw-h-30p tw-flex tw-rounded-5pxp">
						<span className="tw-hidden tw-speak-none">Placeholder Header Loading</span>
					</div>
					<div>
						<div className="tw-bg-dark-light tw-w-120p tw-h-30p tw-flex tw-rounded-5pxp">
							<span className="tw-hidden tw-speak-none">Placeholder Header Loading</span>
						</div>
					</div>
				</div>
			</div> */}
			<div className="tw-w-full">
				{/* <div className="tw-flex tw-bg-white tw-px-10p  tw-mb-30p tw-items-center">
					<div className="tw-flex tw-space-x-10p tw-p-20p tw-flex-shrink-0  tw-items-center">
						<div className="tw-bg-dark-light tw-w-30p tw-h-30p tw-flex tw-rounded-5px">
							<span className="tw-hidden tw-speak-none">Placeholder Header Loading</span>
						</div>
						<div className="tw-bg-dark-light tw-w-150p tw-h-30p tw-flex tw-rounded-5pxp">
							<span className="tw-hidden tw-speak-none">Placeholder Header Loading</span>
						</div>
					</div>
					<div className="tw-flex tw-flex-grow tw-justify-evenly tw-items-center">
						<div className="tw-bg-dark-light tw-w-120p tw-h-30p tw-flex tw-rounded-5pxp">
							<span className="tw-hidden tw-speak-none">Placeholder Header Loading</span>
						</div>
						<div className="tw-bg-dark-light tw-w-120p tw-h-30p tw-flex tw-rounded-5pxp">
							<span className="tw-hidden tw-speak-none">Placeholder Header Loading</span>
						</div>
					</div>
				</div> */}
				<div className="tw-bg-dark-light tw-w-250p tw-h-20p tw-flex tw-rounded-5px tw-mb-20p">
					<span className="tw-hidden tw-speak-none">Placeholder Header Loading</span>
				</div>
				<div className="-tw-m-10px tw-flex tw-pb-10p">
					{ [...Array(7)].map( item => {
						return <div className={`tw-m-10px tw-text-1 tw-inline-flex tw-flex-col tw-justify-start tw-flex-grow tw-overflow-hidden`}>
							<div className="tw-bg-dark-light tw-w-60p tw-h-15p tw-flex tw-rounded-5px">
								<span className="tw-hidden tw-speak-none">Placeholder Header Loading</span>
							</div>
						</div>
						})
					}
				</div>
				<div className="-tw-m-10px tw-flex tw-flex-wrap">
					{ [...Array(21)].map( item => <LoadingItem className="tw-m-10px tw-flex-grow" /> ) }
				</div>
			</div>
		</div>
	</>
}

const SideNavContent2 = props => {
    return <b>
        another one
    </b>
}

const TestPage = props => {
	const navContext = useAppContext();

	return <div className="tw-px-40px">
		<LoadingItems />

		<ButtonPrimary onClick={ e => {
            navContext.setSideNav( <SideNavContent2 /> );
            navContext.setSideNavTheme(2);
            navContext.setSideNavTitle("Another demo!");
            navContext.openSideNav();
			navContext.setName({ firstName: "Kevin", lastName: "Mack" });
			e.preventDefault();
		}}>
			Open panel
		</ButtonPrimary>
	</div>
}

export default TestPage;
