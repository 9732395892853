import { ArrowDownIcon, ArrowUpIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import moment from 'moment'
import { useEffect, useLayoutEffect, useState } from 'react'
import { getAllClientsScheduleForDate, getClientServiceType } from '../../apicalls/schedulingService'
import { useAppContext } from '../../Context'
import { ClientDaySchedule } from '../../types/coverageRequirement'
import { DataBlock, DirectSupportBlock } from './SchedulingUtils'
import { CircularProgress, fabClasses } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CoverageDetailsPanel from '../../components/panels/CoverageDetailsPanel'
import { LabelOutputs } from '../../components/utilities/Helpers'
import { removeWhitespace } from '../../components/panels/SchedulePanel'
import DirectSupportShiftPanel from '../../components/panels/DirectSupportShiftDetailsPanel'

const stats = [
    { name: 'Total Subscribers', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
    { name: 'Avg. Open Rate', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
    { name: 'Avg. Click Rate', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
]



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const ScheduleDashboard = props => {
    const navContext = useAppContext();
    let navigate = useNavigate();

    const [scheduleLoading, setScheduleLoading] = useState(false);
    const [clientSchedule, setClientSchedule] = useState<ClientDaySchedule[]>([]);
    const [categorySchedule, setCategorySchedule] = useState<ClientDaySchedule[]>([]);
    const [filteredSchedule, setFilteredSchedule] = useState<ClientDaySchedule[]>([]);

    const [selectedFilter, setSelectedFilter] = useState<string>('All');
    const [searchText, setSearchText] = useState<string>('');

    const [currentDate, setCurrentDate] = useState(moment().format('MM-DD-YYYY'));

    const [scheduleStats, setScheduleStats] = useState<any>([
        { name: 'All', total: '50', percentage: '100%'},
        { name: 'Fully Covered', total: '35', percentage: '70%' },
        { name: 'Partially Covered', total: '8', percentage: '16%' },
        { name: 'Open', total: '7', percentage: '14%' },
        { name: 'Cancelled', total: '0', percentage: '0%' },
    ]);

    useEffect(() => {
        console.log("Category Filtered Schedule Updated: ");
        console.log(categorySchedule);
    }, [categorySchedule])
    
    useLayoutEffect(() => {
        const today = moment().format('MM-DD-YYYY');

        const fetchTodaySchedule = async () => {
            setScheduleLoading(true);
            const schedules = await getAllClientsScheduleForDate(today, navContext.handleAPICallError);

            // sort by clientName
           
            setClientSchedule(schedules.sort((a, b) => {
                if (a.clientName < b.clientName) {
                    return -1;
                }
                if (a.clientName > b.clientName) {
                    return 1;
                }
                return 0;
            }));

            setCategorySchedule(schedules.sort((a, b) => {
                if (a.clientName < b.clientName) {
                    return -1;
                }
                if (a.clientName > b.clientName) {
                    return 1;
                }
                return 0;
            }));

            setFilteredSchedule(schedules.sort((a, b) => {
                if (a.clientName < b.clientName) {
                    return -1;
                }
                if (a.clientName > b.clientName) {
                    return 1;
                }
                return 0;
            }));

            // count the stats
            let fullyCovered = 0;
            let partiallyCovered = 0;
            let open = 0;
            let cancelled = 0;
            let total = 0;

            schedules.forEach(schedule => {
                schedule.requirements?.forEach(requirement => {
                    if (requirement.status === 'Fully Covered') {
                        fullyCovered++;
                    } else if (requirement.status === 'Partially Covered') {
                        partiallyCovered++;
                    } else if (requirement.status === 'Fully Open') {
                        open++;
                    } else if (requirement.status === 'Cancelled') {
                        cancelled++;
                    }
                    total++;
                })
            });

            setScheduleStats([
                { name: 'All', total: total, percentage: '100%'},
                { name: 'Fully Covered', total: fullyCovered, percentage: `${Math.round((fullyCovered / total) * 100)}%` },
                { name: 'Partially Covered', total: partiallyCovered, percentage: `${Math.round((partiallyCovered / total) * 100)}%` },
                { name: 'Open', total: open, percentage: `${Math.round((open / total) * 100)}%` },
                { name: 'Cancelled', total: cancelled, percentage: `${Math.round((cancelled / total) * 100)}%` },
            ]);

            console.log(schedules);
            setScheduleLoading(false);
        }

        fetchTodaySchedule();
    }, [])

    const refreshSchedule = async () => {
        setScheduleLoading(true);
            const schedules = await getAllClientsScheduleForDate(currentDate, navContext.handleAPICallError);

            // sort by clientName
           
            setClientSchedule(schedules.sort((a, b) => {
                if (a.clientName < b.clientName) {
                    return -1;
                }
                if (a.clientName > b.clientName) {
                    return 1;
                }
                return 0;
            }));

            setFilteredSchedule(schedules.sort((a, b) => {
                if (a.clientName < b.clientName) {
                    return -1;
                }
                if (a.clientName > b.clientName) {
                    return 1;
                }
                return 0;
            }));

            setCategorySchedule(schedules.sort((a, b) => {
                if (a.clientName < b.clientName) {
                    return -1;
                }
                if (a.clientName > b.clientName) {
                    return 1;
                }
                return 0;
            }));

            // count the stats
            let fullyCovered = 0;
            let partiallyCovered = 0;
            let open = 0;
            let cancelled = 0;
            let total = 0;

            schedules.forEach(schedule => {
                schedule.requirements?.forEach(requirement => {
                    if (requirement.status === 'Fully Covered') {
                        fullyCovered++;
                    } else if (requirement.status === 'Partially Covered') {
                        partiallyCovered++;
                    } else if (requirement.status === 'Fully Open') {
                        open++;
                    } else if (requirement.status === 'Cancelled') {
                        cancelled++;
                    }
                    total++;
                })
            });

            setScheduleStats([
                { name: 'Fully Covered', total: fullyCovered, percentage: `${Math.round((fullyCovered / total) * 100)}%` },
                { name: 'Partially Covered', total: partiallyCovered, percentage: `${Math.round((partiallyCovered / total) * 100)}%` },
                { name: 'Open', total: open, percentage: `${Math.round((open / total) * 100)}%` },
                { name: 'Cancelled', total: cancelled, percentage: `${Math.round((cancelled / total) * 100)}%` },
            ]);

            console.log(schedules);
            setScheduleLoading(false);
    }

    const restoreSchedule = () => {
        setFilteredSchedule(clientSchedule);
        setCategorySchedule(clientSchedule);

            // update Stats

            let fullyCovered = 0;
            let partiallyCovered = 0;
            let open = 0;
            let cancelled = 0;
            let total = 0;

            clientSchedule.forEach(schedule => {
                schedule.requirements?.forEach(requirement => {
                    if (requirement.status === 'Fully Covered') {
                        fullyCovered++;
                    } else if (requirement.status === 'Partially Covered') {
                        partiallyCovered++;
                    } else if (requirement.status === 'Fully Open') {
                        open++;
                    } else if (requirement.status === 'Cancelled') {
                        cancelled++;
                    }
                    total++;
                })
            }
            );

            setScheduleStats([
                { name: 'All', total: total, percentage: '100%'},
                { name: 'Fully Covered', total: fullyCovered, percentage: `${Math.round((fullyCovered / total) * 100)}%` },
                { name: 'Partially Covered', total: partiallyCovered, percentage: `${Math.round((partiallyCovered / total) * 100)}%` },
                { name: 'Open', total: open, percentage: `${Math.round((open / total) * 100)}%` },
                { name: 'Cancelled', total: cancelled, percentage: `${Math.round((cancelled / total) * 100)}%` },
            ]);

            return;
    }

    const filterSchedule = (filter: string) => {
        console.log("Filter String");
        console.log(filter);
        setSearchText(filter);
        const updatedSchedule = clientSchedule.filter(element => {
                                                            
            // 👇️ using AND (&&) operator
            return element.clientName.toLowerCase().includes(filter.toLowerCase());
        });

        setFilteredSchedule(updatedSchedule);

        console.log(updatedSchedule);

        // update Stats

        let fullyCovered = 0;
        let partiallyCovered = 0;
        let open = 0;
        let cancelled = 0;
        let total = 0;

        updatedSchedule.forEach(schedule => {
            schedule.requirements?.forEach(requirement => {
                if (requirement.status === 'Fully Covered') {
                    fullyCovered++;
                } else if (requirement.status === 'Partially Covered') {
                    partiallyCovered++;
                } else if (requirement.status === 'Fully Open') {
                    open++;
                } else if (requirement.status === 'Cancelled') {
                    cancelled++;
                }
                total++;
            })
        }
        );

        if(selectedFilter === 'All'){
            setCategorySchedule(updatedSchedule);
            //setFilteredSchedule(clientSchedule);
        } else if(selectedFilter === 'Fully Covered'){
            const updatedCatSchedule = updatedSchedule.filter(element => {
                                                            
                // 👇️ using AND (&&) operator
                return element.requirements?.some(requirement => requirement.status === 'Fully Covered');
            });

            setCategorySchedule(updatedCatSchedule);
            //setFilteredSchedule(updatedSchedule);
        } else if(selectedFilter === 'Partially Covered'){
            const updatedCatSchedule = updatedSchedule.filter(element => {
                                                            
                // 👇️ using AND (&&) operator
                return element.requirements?.some(requirement => requirement.status === 'Partially Covered');
            });

            setCategorySchedule(updatedCatSchedule);
            //setFilteredSchedule(updatedSchedule);
        } else if(selectedFilter === 'Open'){
            const updatedCatSchedule = updatedSchedule.filter(element => {
                                                            
                // 👇️ using AND (&&) operator
                return element.requirements?.some(requirement => requirement.status === 'Fully Open');
            });

            setCategorySchedule(updatedCatSchedule);
            //setFilteredSchedule(updatedSchedule);
        } else if(selectedFilter === 'Cancelled'){
            const updatedCatSchedule = updatedSchedule.filter(element => {
                                                            
                // 👇️ using AND (&&) operator
                return element.requirements?.some(requirement => requirement.status === 'Cancelled');
            });

            setCategorySchedule(updatedCatSchedule);
            //setFilteredSchedule(updatedSchedule);
        }

        setScheduleStats([
            { name: 'All', total: total, percentage: '100%'},
            { name: 'Fully Covered', total: fullyCovered, percentage: `${Math.round((fullyCovered / total) * 100)}%` },
            { name: 'Partially Covered', total: partiallyCovered, percentage: `${Math.round((partiallyCovered / total) * 100)}%` },
            { name: 'Open', total: open, percentage: `${Math.round((open / total) * 100)}%` },
            { name: 'Cancelled', total: cancelled, percentage: `${Math.round((cancelled / total) * 100)}%` },
        ]);

    }

    const updateSelectedFilter = (filter: string) => {
        console.log("Update Selected Filter");
        console.log(filter);

        setSelectedFilter(filter);

        if(removeWhitespace(searchText).length > 0){
            filterSchedule(searchText);
        }

        if(filter === 'All'){
            setCategorySchedule(filteredSchedule);
            //setFilteredSchedule(clientSchedule);
        } else if(filter === 'Fully Covered'){
            const updatedSchedule = filteredSchedule.filter(element => {
                                                            
                // 👇️ using AND (&&) operator
                return element.requirements?.some(requirement => requirement.status === 'Fully Covered');
            });

            setCategorySchedule(updatedSchedule);
            //setFilteredSchedule(updatedSchedule);
        } else if(filter === 'Partially Covered'){
            const updatedSchedule = filteredSchedule.filter(element => {
                                                            
                // 👇️ using AND (&&) operator
                return element.requirements?.some(requirement => requirement.status === 'Partially Covered');
            });

            setCategorySchedule(updatedSchedule);
            //setFilteredSchedule(updatedSchedule);
        } else if(filter === 'Open'){
            const updatedSchedule = filteredSchedule.filter(element => {
                                                            
                // 👇️ using AND (&&) operator
                return element.requirements?.some(requirement => requirement.status === 'Fully Open');
            });

            setCategorySchedule(updatedSchedule);
            //setFilteredSchedule(updatedSchedule);
        } else if(filter === 'Cancelled'){
            const updatedSchedule = filteredSchedule.filter(element => {
                                                            
                // 👇️ using AND (&&) operator
                return element.requirements?.some(requirement => requirement.status === 'Cancelled');
            });

            setCategorySchedule(updatedSchedule);
            //setFilteredSchedule(updatedSchedule);
        }

        


    }

    const nextDay = async () => {
        var tomorrow = moment(currentDate, 'MM-DD-YYYY').add(1, 'days').format('MM-DD-YYYY');

        setCurrentDate(tomorrow);

        setScheduleLoading(true);

        const schedules = await getAllClientsScheduleForDate(tomorrow, navContext.handleAPICallError);

        // sort by clientName
           
        setClientSchedule(schedules.sort((a, b) => {
            if (a.clientName < b.clientName) {
                return -1;
            }
            if (a.clientName > b.clientName) {
                return 1;
            }
            return 0;
        }));

        setFilteredSchedule(schedules.sort((a, b) => {
            if (a.clientName < b.clientName) {
                return -1;
            }
            if (a.clientName > b.clientName) {
                return 1;
            }
            return 0;
        }));

        setCategorySchedule(schedules.sort((a, b) => {
            if (a.clientName < b.clientName) {
                return -1;
            }
            if (a.clientName > b.clientName) {
                return 1;
            }
            return 0;
        }));

        // count the stats
        let fullyCovered = 0;
        let partiallyCovered = 0;
        let open = 0;
        let cancelled = 0;
        let total = 0;

        schedules.forEach(schedule => {
            schedule.requirements?.forEach(requirement => {
                if (requirement.status === 'Fully Covered') {
                    fullyCovered++;
                } else if (requirement.status === 'Partially Covered') {
                    partiallyCovered++;
                } else if (requirement.status === 'Fully Open') {
                    open++;
                } else if (requirement.status === 'Cancelled') {
                    cancelled++;
                }
                total++;
            })
        });

        setScheduleStats([
            { name: 'All', total: total, percentage: '100%'},
            { name: 'Fully Covered', total: fullyCovered, percentage: `${Math.round((fullyCovered / total) * 100)}%` },
            { name: 'Partially Covered', total: partiallyCovered, percentage: `${Math.round((partiallyCovered / total) * 100)}%` },
            { name: 'Open', total: open, percentage: `${Math.round((open / total) * 100)}%` },
            { name: 'Cancelled', total: cancelled, percentage: `${Math.round((cancelled / total) * 100)}%` },
        ]);

        console.log(schedules);
        setScheduleLoading(false);
    }

    const prevDay = async () => {
        var yesterday = moment(currentDate, 'MM-DD-YYYY').add(-1, 'days').format('MM-DD-YYYY');

        setCurrentDate(yesterday);

        setScheduleLoading(true);

        const schedules = await getAllClientsScheduleForDate(yesterday, navContext.handleAPICallError);

        // sort by clientName
           
        setClientSchedule(schedules.sort((a, b) => {
            if (a.clientName < b.clientName) {
                return -1;
            }
            if (a.clientName > b.clientName) {
                return 1;
            }
            return 0;
        }));

        setFilteredSchedule(schedules.sort((a, b) => {
            if (a.clientName < b.clientName) {
                return -1;
            }
            if (a.clientName > b.clientName) {
                return 1;
            }
            return 0;
        }));

        setCategorySchedule(schedules.sort((a, b) => {
            if (a.clientName < b.clientName) {
                return -1;
            }
            if (a.clientName > b.clientName) {
                return 1;
            }
            return 0;
        }));

        // count the stats
        let fullyCovered = 0;
        let partiallyCovered = 0;
        let open = 0;
        let cancelled = 0;
        let total = 0;

        schedules.forEach(schedule => {
            schedule.requirements?.forEach(requirement => {
                if (requirement.status === 'Fully Covered') {
                    fullyCovered++;
                } else if (requirement.status === 'Partially Covered') {
                    partiallyCovered++;
                } else if (requirement.status === 'Fully Open') {
                    open++;
                } else if (requirement.status === 'Cancelled') {
                    cancelled++;
                }
                total++;
            })
        });

        setScheduleStats([
            { name: 'All', total: total, percentage: '100%'},
            { name: 'Fully Covered', total: fullyCovered, percentage: `${Math.round((fullyCovered / total) * 100)}%` },
            { name: 'Partially Covered', total: partiallyCovered, percentage: `${Math.round((partiallyCovered / total) * 100)}%` },
            { name: 'Open', total: open, percentage: `${Math.round((open / total) * 100)}%` },
            { name: 'Cancelled', total: cancelled, percentage: `${Math.round((cancelled / total) * 100)}%` },
        ]);

        console.log(schedules);
        setScheduleLoading(false);
    }

    const showClientRequirement = async (patientID) => {
        console.log(patientID);
        navigate("/schedule/schedule-mgmt/" + patientID + "/weekly-schedule");
    }

    const viewRequirement = async (patientID, date, requirement) => {
        console.log("View Requirement Method");
        
        const serviceTypes = await getClientServiceType(patientID, navContext.handleAPICallError);

        navContext.setSideNavTheme(3);
        navContext.setPanelSize('tw-max-w-400px');
        navContext.setSideNav( <CoverageDetailsPanel date={moment(date.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={requirement} serviceTypes={ serviceTypes || null} refreshCalendar={refreshSchedule}/> );
        navContext.setSideNavActions(null);
        navContext.setSideNavBottom(null);
        navContext.openSideNav();
    }

    return(<>
         <div className="tw-px-xl">
            <div className="tw-sticky tw-top-0 tw-z-3 tw-bg-grey-light tw-py-sm">
                <div className="tw-flex tw-items-center tw-flex tw-text-dark tw-pb-sm">
                    <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Schedule Dashboard</h2>
                    </div>
                </div>
                <div className="tw-flex tw-items-center tw-py-10px">
                                <span className="tw-mr-auto tw-text-dark hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px tw-cursor-pointer" onClick={prevDay}>
                                    <LabelOutputs className="tw-text-in" icon="/images/icon-nav-arrow-left.svg" size="10">
                                        <span className="tw-text-5">
                                            Previous Day
                                        </span>
                                    </LabelOutputs>
                                </span>

                                <span className="tw-mx-auto tw-font-primary tw-font-primary-bold tw-inline-flex tw-items-center">
                                    {moment(currentDate.replace(/-/g, "/")).format('dddd, MMMM Do YYYY')}
                                </span>

                                <span className="tw-ml-auto tw-text-dark hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px tw-cursor-pointer" onClick={nextDay}>
                                    <LabelOutputs className="tw-text-in" icon="/images/icon-nav-arrow-right.svg" reverse size="10">
                                        <span className="tw-text-5">
                                            Next Day
                                        </span>
                                    </LabelOutputs>
                                </span>
                </div>

                {scheduleLoading ? 
                    <div className="tw-flex tw-justify-center tw-m-md">
                        <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                    </div> : <div>
                        <div className="tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm tw-mx-6">
                        <input
                                                    type="text"
                                                    name="search-client"
                                                    id="search-client"
                                                    className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-4 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 placeholder:tw-pl-3 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                                    placeholder="Search by Client Name..." onChange={ e => {

                                                        //console.log(e?.target.value || "nope");
                                                        if(e?.target.value){
                                                            filterSchedule(e?.target.value);
                                                        } else {
                                                            restoreSchedule();
                                                        }
                
                                                        // if(e?.target.value){
                                                        //     const searchText = e?.target.value.toString().toLowerCase();
                                                        //     const filtered = allPatients.filter(element => {
                                                                
                                                        //         // 👇️ using AND (&&) operator
                                                        //         return element.firstName.toLowerCase().includes(searchText) || element.lastName.toLowerCase().includes(searchText);
                                                        //     });
                
                                                        //     console.log(filtered)
                
                                                        //     setActivePatients(filtered);
                                                        // } else {
                                                        //     setActivePatients(allPatients);
                                                        // }
                                                    }}
                                                    />
                                                    <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                                                        <MagnifyingGlassIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                                                    </div>
                        </div>

                        <dl className="tw-mt-5 tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow md:tw-grid-cols-5 md:tw-divide-x md:tw-divide-y-0">
                            {scheduleStats.map((item) => (
                            <div key={item.name} className={`tw-px-4 tw-py-5 sm:tw-p-6 tw-cursor-pointer ${item.name === selectedFilter ? "tw-bg-primary": ""}`} onClick={() => updateSelectedFilter(item.name)}>
                                <dt className={`tw-text-sm tw-font-normal ${item.name === selectedFilter ? "tw-text-white": "tw-text-gray-900"}`}>{item.name}</dt>
                                <dd className="tw-mt-1 tw-flex tw-items-baseline tw-justify-between md:tw-block lg:tw-flex">
                                <div className={`tw-flex tw-items-baseline tw-text-base tw-font-semibold ${item.name === selectedFilter ? "tw-text-white": "tw-text-gray-900"}`}>
                                    {item.total}
                                </div>

                                <div
                                    className={classNames(
                                    'tw-bg-slate-100 tw-text-slate-800',
                                    'tw-inline-flex tw-items-baseline tw-rounded-full tw-px-2.5 tw-py-0.5 tw-text-sm tw-font-medium md:tw-mt-2 lg:tw-mt-0'
                                    )}
                                >
                                    {item.percentage}
                                </div>
                                </dd>
                            </div>
                            ))}
                        </dl>
                </div>}
            </div>
           
            {/* <h3 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">{moment(currentDate).format('dddd, MMMM Do YYYY')}</h3> */}
            
            <div>
                

                {!scheduleLoading && categorySchedule?.map((schedule, index) => (
                    <>
                    <div className={`tw-flex tw-justify-between tw-mt-4 ${schedule.rsHours === 0 ? "tw-bg-red-400": ""} tw-border-b tw-border-gray-800 tw-pb-2`}>
                        <h3 className={`tw-text-sm tw-mx-4 tw-font-semibold tw-leading-6 ${schedule.rsHours === 0 ? "tw-text-white": "tw-text-gray-900"} hover:tw-text-primary tw-cursor-pointer`} onClick={ e => {
                                                    e.preventDefault();
                                                    showClientRequirement(schedule.clientID);
                                                    //props?.toggleShowRightPanel();
                                                }}>{schedule.clientName}</h3>
                        <div className="tw-float-right">
                            <span className={`tw-text-sm tw-font-semibold tw-leading-6 ${schedule.rsHours === 0 ? "tw-text-white": "tw-text-primary"} tw-px-2`}>RS hours: {schedule.rsHours}</span>
                            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">|</span>
                            <span className={`tw-text-sm tw-font-semibold tw-leading-6 ${schedule.rsHours === 0 ? "tw-text-white": "tw-text-warning"} tw-px-2`}>DS hours: {schedule.dsHours}</span>
                        </div>
                        
                    </div>
                    
                    <div className="tw-grid tw-gap-4 tw-grid-flow-col tw-grid-cols-7 tw-w-full tw-overflow-hidden tw-pt-2">
                        {schedule.requirements?.map( ( requirement, index ) => {
                                                return requirement.shiftTheme !== 5 ? <DataBlock key={index} coverageAmount={requirement.status} type={requirement.shiftType} date={moment(schedule.displayDate).format("MM/DD/YYYY")} time={`${requirement.displayStartTime} - ${requirement.displayEndTime}`} onClick={e => {
                                                    e.preventDefault();
                                                    console.log("View Requirement");
                                                    viewRequirement(schedule.clientID, schedule.displayDate, requirement);
                                                }}></DataBlock> : <DirectSupportBlock key={index} coverageAmount={requirement.status} type={requirement.shiftType} date={moment(schedule.displayDate).format("MM/DD/YYYY")} time={`${requirement.displayStartTime} - ${requirement.displayEndTime}`} providerName={requirement.providerName || "Open"} onClick={e => {
                                                    e.preventDefault();
                                                    navContext.setSideNavTheme(3);
                                                    navContext.setSideNavTitle("Direct Support Shift Details");
													navContext.setPanelSize('tw-max-w-400px');
													navContext.setSideNav( <DirectSupportShiftPanel date={moment(schedule.displayDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={requirement} serviceTypes={null}/> );
													navContext.setSideNavActions(null);
													navContext.setSideNavBottom(null);
													navContext.openSideNav();
                                                }}></DirectSupportBlock>;
                                            }
                        )}
                        {(!schedule.requirements || schedule.requirements?.length === 0) && <DataBlock none date={moment(schedule.displayDate.replace(/-/g, "/")).format("MM/DD/YYYY")} requirement="on-demand" onClick={ e => {}}/>}
                        {/* {schedule.requirements?.length === 0 && <DataBlock none date={moment(schedule.displayDate.replace(/-/g, "/")).format("MM/DD/YYYY")} requirement="on-demand" onClick={ e => {}}/>} */}
                    </div>
                    
                    </>))}

            </div>

         </div>
    </>);
}

export default ScheduleDashboard;