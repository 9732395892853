import moment from "moment";
import RemoteSupportSession from "../../types/remoteSupportSession";
import { approximateToTwoDecimals } from "../Analytics/OperationAnalytics";

export function getCoverageStatusForDay(shifts: RemoteSupportSession[], date: string): string {
	moment.tz.setDefault("America/New_York");

	const dayStart = moment(date).clone().startOf('day');
	const dayEnd = moment(date).clone().endOf('day');

	shifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));  

	if (shifts.length === 0) {
		return 'open';
	}
	
	let prevEndTime = dayStart;
  
	for (let shift of shifts) {
	  if (moment(shift.displayStartDate + " " + shift.displayStartTime).isAfter(prevEndTime)) {
		// console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	  	// console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
		return 'partial';
	  }
  
	  prevEndTime = moment.max(prevEndTime, moment(shift.displayEndDate + " " + shift.displayEndTime));
	}
	
	if (prevEndTime < dayEnd) {
	//   console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	//   console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
	  return 'partial'; 
	}
  
	return 'full';
  
}

export function getDayCoverage(shifts: RemoteSupportSession[], date: string) {
	moment.tz.setDefault("America/New_York");

	const dayStart = moment(date).clone().startOf('day');
	const dayEnd = moment(date).clone().startOf('day').add(1, 'day');
  
	let durationCovered = moment.duration();

	shifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));  
  
	let previousStart = dayStart;

	for (let shift of shifts) {
	  const overlapStart = moment.max(moment(shift.displayStartDate + " " + shift.displayStartTime), previousStart); 
	  const overlapEnd = moment.min(moment(shift.displayEndDate + " " + shift.displayEndTime), dayEnd);

	  if (overlapStart.isAfter(overlapEnd)) {
		continue;
	  }

	  previousStart = overlapEnd;

	  durationCovered = durationCovered.add(overlapEnd.diff(overlapStart));

	//   console.log("Overlap Start: " + overlapStart.format('MM-DD-YYYY hh:mm A'));
	//   console.log("Overlap End: " + overlapEnd.format('MM-DD-YYYY hh:mm A'));
	//   console.log("Duration Covered: " + durationCovered.asHours());
	}
  
	const dayDuration = moment.duration(dayEnd.diff(dayStart));
  
	const coveragePct = durationCovered.asSeconds() / dayDuration.asSeconds() * 100;
  
	return {
	  status: getCoverageStatusForDay(shifts, date),
	  coveragePct: approximateToTwoDecimals(coveragePct).toString()
	};
  
}

export function getCoverageStatusForFirstQuadrant(shifts: RemoteSupportSession[], date: string): string {
	moment.tz.setDefault("America/New_York");

	const dayStart = moment(date).clone().startOf('day');
	const dayEnd = moment(date).clone().add(6, 'hours');

	shifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));  

	if (shifts.length === 0) {
		return 'No Coverage';
	}
	
	let prevEndTime = dayStart;
  
	for (let shift of shifts) {
	  if (moment(shift.displayStartDate + " " + shift.displayStartTime).isAfter(prevEndTime)) {
		// console.log(shift);
		// console.log("Shift Start: " + moment(shift.displayStartDate + " " + shift.displayStartTime).format('MM-DD-YYYY hh:mm A'));
		// console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	  	// console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
		return 'Partial';
	  }
  
	  prevEndTime = moment.max(prevEndTime, moment(shift.displayEndDate + " " + shift.displayEndTime));
	}

	if(prevEndTime <= dayStart) {
		return 'Open';
	}
	
	if (prevEndTime < dayEnd) {
	//   console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	//   console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
	  return 'Partial'; 
	}
  
	return 'Full';
}

export function getFirstQuadrantCoverage(shifts: RemoteSupportSession[], date: string) {
	moment.tz.setDefault("America/New_York");

	const quadrantStart = moment(date).clone().startOf('day');
	const quadrantEnd = moment(date).clone().add(6, 'hours');

	let filteredShifts = shifts.filter(shift => moment(shift.displayStartDate + " " + shift.displayStartTime).isBefore(quadrantEnd) && moment(shift.displayEndDate + " " + shift.displayEndTime).isAfter(quadrantStart));

	filteredShifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));

	let durationCovered = moment.duration();

	let previousStart = quadrantStart;

	for (let shift of filteredShifts) {
		const overlapStart = moment.max(moment(shift.displayStartDate + " " + shift.displayStartTime), previousStart); 
		const overlapEnd = moment.min(moment(shift.displayEndDate + " " + shift.displayEndTime), quadrantEnd);

		if (overlapStart.isAfter(overlapEnd)) {
			continue;
		}

		previousStart = overlapEnd;

		durationCovered = durationCovered.add(overlapEnd.diff(overlapStart));
	}

	const quadrantDuration = moment.duration(quadrantEnd.diff(quadrantStart));

	const coveragePct = durationCovered.asSeconds() / quadrantDuration.asSeconds() * 100;

	return {
		status: getCoverageStatusForFirstQuadrant(filteredShifts, date),
		coveragePct: approximateToTwoDecimals(coveragePct).toString(),
		shifts: filteredShifts
	};

}

export function getCoverageStatusForSecondQuadrant(shifts: RemoteSupportSession[], date: string): string {
	moment.tz.setDefault("America/New_York");

	const dayStart = moment(date).clone().add(6, 'hours');
	const dayEnd = moment(date).clone().add(12, 'hours');

	shifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));  

	if (shifts.length === 0) {
		return 'No Coverage';
	}
	
	let prevEndTime = dayStart;
  
	for (let shift of shifts) {
	  if (moment(shift.displayStartDate + " " + shift.displayStartTime).isAfter(prevEndTime)) {
		// console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	  	// console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
		return 'Partial';
	  }
  
	  prevEndTime = moment.max(prevEndTime, moment(shift.displayEndDate + " " + shift.displayEndTime));
	}

	if(prevEndTime <= dayStart) {
		return 'Open';
	}
	
	if (prevEndTime < dayEnd) {
	//   console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	//   console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
	  return 'Partial'; 
	}
  
	return 'Full';
}

export function getSecondQuadrantCoverage(shifts: RemoteSupportSession[], date: string) {
	moment.tz.setDefault("America/New_York");

	const quadrantStart = moment(date).clone().add(6, 'hours');
	const quadrantEnd = moment(date).clone().add(12, 'hours');

	let filteredShifts = shifts.filter(shift => moment(shift.displayStartDate + " " + shift.displayStartTime).isBefore(quadrantEnd) && moment(shift.displayEndDate + " " + shift.displayEndTime).isAfter(quadrantStart));

	filteredShifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));

	let durationCovered = moment.duration();

	let previousStart = quadrantStart;

	for (let shift of filteredShifts) {
		const overlapStart = moment.max(moment(shift.displayStartDate + " " + shift.displayStartTime), previousStart); 
		const overlapEnd = moment.min(moment(shift.displayEndDate + " " + shift.displayEndTime), quadrantEnd);

		if (overlapStart.isAfter(overlapEnd)) {
			continue;
		}

		previousStart = overlapEnd;

		durationCovered = durationCovered.add(overlapEnd.diff(overlapStart));
	}

	const quadrantDuration = moment.duration(quadrantEnd.diff(quadrantStart));

	const coveragePct = durationCovered.asSeconds() / quadrantDuration.asSeconds() * 100;

	return {
		status: getCoverageStatusForSecondQuadrant(filteredShifts, date),
		coveragePct: approximateToTwoDecimals(coveragePct).toString(),
		shifts: filteredShifts
	};

}

export function getCoverageStatusForThirdQuadrant(shifts: RemoteSupportSession[], date: string): string {
	moment.tz.setDefault("America/New_York");

	const dayStart = moment(date).clone().add(12, 'hours');
	const dayEnd = moment(date).clone().add(18, 'hours');

	shifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));  

	if (shifts.length === 0) {
		return 'No Coverage';
	}
	
	let prevEndTime = dayStart;
  
	for (let shift of shifts) {
	  if (moment(shift.displayStartDate + " " + shift.displayStartTime).isAfter(prevEndTime)) {
		// console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	  	// console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
		return 'Partial';
	  }
  
	  prevEndTime = moment.max(prevEndTime, moment(shift.displayEndDate + " " + shift.displayEndTime));
	}

	if(prevEndTime <= dayStart) {
		return 'Open';
	}
	
	if (prevEndTime < dayEnd) {
	//   console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	//   console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
	  return 'Partial'; 
	}
  
	return 'Full';
}

export function getThirdQuadrantCoverage(shifts: RemoteSupportSession[], date: string) {
	moment.tz.setDefault("America/New_York");

	const quadrantStart = moment(date).clone().add(12, 'hours');
	const quadrantEnd = moment(date).clone().add(18, 'hours');

	let filteredShifts = shifts.filter(shift => moment(shift.displayStartDate + " " + shift.displayStartTime).isBefore(quadrantEnd) && moment(shift.displayEndDate + " " + shift.displayEndTime).isAfter(quadrantStart));

	filteredShifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));

	let durationCovered = moment.duration();

	let previousStart = quadrantStart;

	for (let shift of filteredShifts) {
		const overlapStart = moment.max(moment(shift.displayStartDate + " " + shift.displayStartTime), previousStart); 
		const overlapEnd = moment.min(moment(shift.displayEndDate + " " + shift.displayEndTime), quadrantEnd);

		if (overlapStart.isAfter(overlapEnd)) {
			continue;
		}

		previousStart = overlapEnd;

		durationCovered = durationCovered.add(overlapEnd.diff(overlapStart));
	}

	const quadrantDuration = moment.duration(quadrantEnd.diff(quadrantStart));

	const coveragePct = durationCovered.asSeconds() / quadrantDuration.asSeconds() * 100;

	return {
		status: getCoverageStatusForThirdQuadrant(filteredShifts, date),
		coveragePct: approximateToTwoDecimals(coveragePct).toString(),
		shifts: filteredShifts
	};

}

export function getCoverageStatusForFourthQuadrant(shifts: RemoteSupportSession[], date: string): string {
	moment.tz.setDefault("America/New_York");

	const dayStart = moment(date).clone().add(18, 'hours');
	const dayEnd = moment(date).clone().add(24, 'hours');

	shifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));  

	if (shifts.length === 0) {
		return 'No Coverage';
	}
	
	let prevEndTime = dayStart;
  
	for (let shift of shifts) {
	  if (moment(shift.displayStartDate + " " + shift.displayStartTime).isAfter(prevEndTime)) {
		// console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	  	// console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
		return 'Partial';
	  }
  
	  prevEndTime = moment.max(prevEndTime, moment(shift.displayEndDate + " " + shift.displayEndTime));
	}

	if(prevEndTime <= dayStart) {
		return 'Open';
	}
	
	if (prevEndTime < dayEnd) {
	//   console.log("Previous End: " + prevEndTime.format('MM-DD-YYYY hh:mm A'));
	//   console.log("Day End: " + dayEnd.format('MM-DD-YYYY hh:mm A'));
	  return 'Partial'; 
	}
  
	return 'Full';
}

export function getFourthQuadrantCoverage(shifts: RemoteSupportSession[], date: string) {
	moment.tz.setDefault("America/New_York");

	const quadrantStart = moment(date).clone().add(18, 'hours');
	const quadrantEnd = moment(date).clone().add(24, 'hours');

	let filteredShifts = shifts.filter(shift => moment(shift.displayStartDate + " " + shift.displayStartTime).isBefore(quadrantEnd) && moment(shift.displayEndDate + " " + shift.displayEndTime).isAfter(quadrantStart));

	filteredShifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));

	let durationCovered = moment.duration();

	let previousStart = quadrantStart;

	for (let shift of filteredShifts) {
		const overlapStart = moment.max(moment(shift.displayStartDate + " " + shift.displayStartTime), previousStart); 
		const overlapEnd = moment.min(moment(shift.displayEndDate + " " + shift.displayEndTime), quadrantEnd);

		if (overlapStart.isAfter(overlapEnd)) {
			continue;
		}

		previousStart = overlapEnd;

		durationCovered = durationCovered.add(overlapEnd.diff(overlapStart));
	}

	const quadrantDuration = moment.duration(quadrantEnd.diff(quadrantStart));

	const coveragePct = durationCovered.asSeconds() / quadrantDuration.asSeconds() * 100;

	return {
		status: getCoverageStatusForFourthQuadrant(filteredShifts, date),
		coveragePct: approximateToTwoDecimals(coveragePct).toString(),
		shifts: filteredShifts
	};

}

export function getDayCoveragForMonth(shifts: RemoteSupportSession[], date: string) {
	moment.tz.setDefault("America/New_York");

	const dayStart = moment(date).clone().startOf('day');
	const dayEnd = moment(date).clone().startOf('day').add(1, 'day');
  
	let durationCovered = moment.duration();

	shifts.sort((a, b) => moment(a.displayStartDate + " " + a.displayStartTime).diff(moment(b.displayStartDate + " " + b.displayStartTime)));  
  
	let previousStart = dayStart;

	for (let shift of shifts) {
	  const overlapStart = moment.max(moment(shift.displayStartDate + " " + shift.displayStartTime), previousStart); 
	  const overlapEnd = moment.min(moment(shift.displayEndDate + " " + shift.displayEndTime), dayEnd);

	  if (overlapStart.isAfter(overlapEnd)) {
		continue;
	  }

	  previousStart = overlapEnd;

	  durationCovered = durationCovered.add(overlapEnd.diff(overlapStart));

	//   console.log("Overlap Start: " + overlapStart.format('MM-DD-YYYY hh:mm A'));
	//   console.log("Overlap End: " + overlapEnd.format('MM-DD-YYYY hh:mm A'));
	//   console.log("Duration Covered: " + durationCovered.asHours());
	}
  
	const dayDuration = moment.duration(dayEnd.diff(dayStart));
  
	const coveragePct = durationCovered.asSeconds() / dayDuration.asSeconds() * 100;

	const missingCoverage = approximateToTwoDecimals(dayDuration.asHours() - durationCovered.asHours());
  
	return {
	  status: getCoverageStatusForDay(shifts, date),
	  coveragePct: approximateToTwoDecimals(coveragePct).toString(),
	  openDuration: missingCoverage > 0 ? missingCoverage.toString() + ' hrs open' : ''
	};
  
}