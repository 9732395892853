import { useLayoutEffect, useMemo, useState } from "react";
import CoverageEvent from "../../types/coverageEvent";
import EVVService from "../../types/evvService";
import Patient, { PatientContact, PatientDetail } from "../../types/patient";
import PatientInsurance from "../../types/patientInsurance";
import RemoteSupportSession from "../../types/remoteSupportSession";
import TimeOffRequest from "../../types/timeOffRequest";
import RemoteSupportSchedule from "./RemoteSupportSchedule";
import DirectSupportSchedule from "./DirectSupportSchedule";
import TimeOffRequestsComponent from "./TimeOffRequestsCompoent";
import { add, startOfWeek, endOfWeek, format, isWithinInterval, parseISO, isToday, isTomorrow , addDays } from 'date-fns';
import { ChevronDownIcon, ChevronUpIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const ControlCenterPage = props => {
    const [remoteSessions, setRemoteSessions] = useState<RemoteSupportSession[]>([]);
    const [patients, setPatients] = useState<Patient[]>([]);
    const [coverageEvents, setCoverageEvents] = useState<CoverageEvent[]>([]);
    const [timeOffRequests, setTimeOffRequests] = useState<TimeOffRequest[]>([]);
    const [assignedShifts, setAssignedShifts] = useState<{ [employeeID: string]: number }>({});

    const [weekStart, setWeekStart] = useState<Date>(() => startOfWeek(new Date(), { weekStartsOn: 0 }));

    const [expandedPatients, setExpandedPatients] = useState<Set<string>>(new Set());
    const [expandedDates, setExpandedDates] = useState<Set<string>>(new Set());

    useLayoutEffect(() => {
        // In a real application, you'd fetch this data from an API
        // For now, we'll use our dummy data generators
        setRemoteSessions(generateDummyRemoteSessions(weekStart));
        setPatients(generateDummyPatients());
        setCoverageEvents(generateDummyCoverageEvents(weekStart));
        setTimeOffRequests(generateDummyTimeOffRequests(weekStart));
        setAssignedShifts(generateDummyAssignedShifts());
    }, [weekStart]);

    const handleDateChange = (days: number) => {
        const newWeekStart = startOfWeek(add(weekStart, { days }));
        setWeekStart(newWeekStart);
    };

    const groupedRemoteSessions = useMemo(() => {
        const grouped = remoteSessions.reduce((acc, session) => {
          if (!acc[session.displayStartDate]) {
            acc[session.displayStartDate] = [];
          }
          acc[session.displayStartDate].push(session);
          return acc;
        }, {} as Record<string, RemoteSupportSession[]>);
    
        // Sort sessions for each date
        Object.keys(grouped).forEach(date => {
          grouped[date].sort((a, b) => 
            a.status === 'open' ? -1 : b.status === 'open' ? 1 : 0
          );
        });
    
        // Sort dates
        return Object.entries(grouped).sort(([dateA], [dateB]) => 
          parseISO(dateA).getTime() - parseISO(dateB).getTime()
        );
      }, [remoteSessions]);
    
    const groupedCoverageEvents = useMemo(() => {
        const grouped = coverageEvents.reduce((acc, event) => {
          if (!acc[event.patientName]) {
            acc[event.patientName] = [];
          }
          acc[event.patientName].push(event);
          return acc;
        }, {} as Record<string, CoverageEvent[]>);
    
        // Sort events for each patient
        Object.keys(grouped).forEach(patientName => {
          grouped[patientName].sort((a, b) => 
            a.status === 'open' ? -1 : b.status === 'open' ? 1 : 0
          );
        });
    
        return grouped;
      }, [coverageEvents]);
    
    const sortedTimeOffRequests = useMemo(() => {
        return [...timeOffRequests].sort((a, b) => {
          const aScore = a.status === 'approved' && assignedShifts[a.timeOffRequestID] > 0 ? 0
                       : a.status === 'pending' ? 1
                       : a.status === 'approved' ? 2
                       : 3;
          const bScore = b.status === 'approved' && assignedShifts[b.timeOffRequestID] > 0 ? 0
                       : b.status === 'pending' ? 1
                       : b.status === 'approved' ? 2
                       : 3;
          return aScore - bScore;
        });
      }, [timeOffRequests, assignedShifts]);


      const urgentItems = useMemo(() => {
        const today = new Date();
        const tomorrow = addDays(today, 1);
    
        const urgentSessions = remoteSessions.filter(session => 
          (isToday(parseISO(session.displayStartDate)) || isTomorrow(parseISO(session.displayStartDate))) && 
          session.status === 'open'
        );
    
        const urgentShifts = coverageEvents.filter(event => 
          (isToday(parseISO(event.displayCoverageDate)) || isTomorrow(parseISO(event.displayCoverageDate))) && 
          event.status === 'open'
        );
    
        const urgentTimeOffs = timeOffRequests.filter(request => {
          const startDate = parseISO(request.displayStartDate);
          return (isToday(startDate) || isTomorrow(startDate)) && 
                 request.status === 'approved' && 
                 assignedShifts[request.employeeID] > 0;
        });
    
        return { urgentSessions, urgentShifts, urgentTimeOffs };
      }, [remoteSessions, coverageEvents, timeOffRequests, assignedShifts]);

      const toggleExpandPatient = (patientName: string) => {
        setExpandedPatients(prev => {
          const newSet = new Set(prev);
          if (newSet.has(patientName)) {
            newSet.delete(patientName);
          } else {
            newSet.add(patientName);
          }
          return newSet;
        });
      };

      const toggleExpandDate = (date: string) => {
        setExpandedDates(prev => {
          const newSet = new Set(prev);
          if (newSet.has(date)) {
            newSet.delete(date);
          } else {
            newSet.add(date);
          }
          return newSet;
        });
      };

    return (
        <div className="tw-container tw-mx-auto tw-p-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <button 
          onClick={() => handleDateChange(-7)}
          className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
        >
          Previous Week
        </button>
        <h2 className="tw-text-xl tw-font-bold">
          Week of {format(weekStart, 'MMMM d, yyyy')}
        </h2>
        <button 
          onClick={() => handleDateChange(7)}
          className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
        >
          Next Week
        </button>
      </div>

      <div className="tw-bg-red-50 tw-border-2 tw-border-red-500 tw-rounded-lg tw-p-4 tw-mb-6">
        <h2 className="tw-text-xl tw-font-bold tw-mb-4 tw-flex tw-items-center">
          <ExclamationTriangleIcon className="tw-h-6 tw-w-6 tw-text-red-500 tw-mr-2" />
          Urgent Attention Required
        </h2>
        <div className="tw-grid tw-grid-cols-3 tw-gap-4">
          <div>
            <h3 className="tw-font-semibold tw-mb-2">Open Remote Sessions</h3>
            {urgentItems.urgentSessions.map(session => (
              <div key={session.remoteSupportSessionID} className="tw-bg-white tw-rounded tw-p-2 tw-mb-2">
                <div>{session.displayStartDate} {session.displayStartTime} - {session.displayEndTime}</div>
                <div>{session.officeName} - {session.stationName}</div>
              </div>
            ))}
          </div>
          <div>
            <h3 className="tw-font-semibold tw-mb-2">Open Direct Support Shifts</h3>
            {urgentItems.urgentShifts.map(shift => (
              <div key={shift.coverageEventID} className="tw-bg-white tw-rounded tw-p-2 tw-mb-2">
                <div>{shift.patientName}</div>
                <div>{shift.displayCoverageDate} {shift.displayStartTime} - {shift.displayEndTime}</div>
              </div>
            ))}
          </div>
          <div>
            <h3 className="tw-font-semibold tw-mb-2">Time-Off Conflicts</h3>
            {urgentItems.urgentTimeOffs.map(request => (
              <div key={request.timeOffRequestID} className="tw-bg-white tw-rounded tw-p-2 tw-mb-2">
                <div>{request.employeeName}</div>
                <div>{request.displayStartDate} - {request.displayEndDate}</div>
                <div className="tw-text-red-600">Assigned Shifts: {assignedShifts[request.employeeID]}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="tw-flex tw-space-x-4">
        <div className="tw-flex-1 tw-bg-blue-50 tw-p-4 tw-rounded">
          <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Remote Support Sessions</h3>
          <div className="tw-h-[calc(100vh-200px)] tw-overflow-y-auto tw-pr-2">
            {groupedRemoteSessions.map(([date, sessions]) => {
              const openSessions = sessions.filter(s => s.status === 'open').length;
              return (
                <div key={date} className="tw-mb-6 tw-border tw-border-gray-300 tw-rounded tw-p-4">
                  <h4 className="tw-font-bold tw-mb-2 tw-flex tw-justify-between tw-items-center">
                    <span>{format(parseISO(date), 'EEEE, MMMM d')}</span>
                    <button
                      onClick={() => toggleExpandDate(date)}
                      className="tw-text-sm tw-bg-gray-200 tw-px-2 tw-py-1 tw-rounded tw-flex tw-items-center"
                    >
                      {expandedDates.has(date) ? (
                        <ChevronUpIcon className="tw-h-5 tw-w-5" />
                      ) : (
                        <ChevronDownIcon className="tw-h-5 tw-w-5" />
                      )}
                    </button>
                  </h4>
                  {!expandedDates.has(date) && (
                    <div className="tw-text-sm">
                      <span className="tw-text-green-600">{sessions.length - openSessions} covered</span> | 
                      <span className="tw-text-red-600"> {openSessions} open</span>
                    </div>
                  )}
                  {expandedDates.has(date) && sessions.map(session => (
                    <div key={session.remoteSupportSessionID} className="tw-bg-white tw-shadow tw-rounded tw-p-4 tw-mb-4">
                      {session.status !== 'open' && <div className="tw-font-bold">{session.providerName}</div>}
                      <div>{session.displayStartTime} - {session.displayEndTime}</div>
                      <div className={`tw-mt-1 ${session.status === 'open' ? 'tw-text-red-600' : 'tw-text-green-600'}`}>
                        {session.status}
                      </div>
                      <div>{session.officeName} - {session.stationName}</div>
                      {session.training && <div className="tw-text-blue-600">Training</div>}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="tw-flex-1 tw-bg-green-50 tw-p-4 tw-rounded">
          <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Direct Support Shifts</h3>
          <div className="tw-h-[calc(100vh-200px)] tw-overflow-y-auto tw-pr-2">
            {Object.entries(groupedCoverageEvents).map(([patientName, events]) => {
              const openShifts = events.filter(e => e.status === 'open').length;
              const coveredShifts = events.length - openShifts;
              return (
                <div key={patientName} className="tw-mb-6 tw-border tw-border-gray-300 tw-rounded tw-p-4">
                  <h4 className="tw-font-bold tw-mb-2 tw-flex tw-justify-between tw-items-center">
                    <span>{patientName}</span>
                    <button
                      onClick={() => toggleExpandPatient(patientName)}
                      className="tw-text-sm tw-bg-gray-200 tw-px-2 tw-py-1 tw-rounded tw-flex tw-items-center"
                    >
                      {expandedPatients.has(patientName) ? (
                        <ChevronUpIcon className="tw-h-5 tw-w-5" />
                      ) : (
                        <ChevronDownIcon className="tw-h-5 tw-w-5" />
                      )}
                    </button>
                  </h4>
                  {!expandedPatients.has(patientName) && (
                    <div className="tw-text-sm">
                      <span className="tw-text-green-600">{coveredShifts} covered</span> | 
                      <span className="tw-text-red-600"> {openShifts} open</span>
                    </div>
                  )}
                  {expandedPatients.has(patientName) && events.map(event => (
                    <div key={event.coverageEventID} className="tw-bg-white tw-shadow tw-rounded tw-p-4 tw-mb-4">
                      <div>{event.displayCoverageDate}</div>
                      <div>{event.displayStartTime} - {event.displayEndTime}</div>
                      <div className={`tw-mt-1 ${event.status === 'open' ? 'tw-text-red-600' : 'tw-text-green-600'}`}>
                        {event.status}
                      </div>
                      {event.status !== 'open' && event.providerName && <div>Provider: {event.providerName}</div>}
                      <div>Shift Type: {event.shiftType}</div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="tw-flex-1 tw-bg-yellow-50 tw-p-4 tw-rounded">
          <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Time-Off Requests</h3>
          <div className="tw-h-[calc(100vh-200px)] tw-overflow-y-auto tw-pr-2">
            {sortedTimeOffRequests.map(request => (
              <div key={request.timeOffRequestID} className="tw-bg-white tw-shadow tw-rounded tw-p-4 tw-mb-4">
                <div className="tw-font-bold">{request.employeeName}</div>
                <div>{request.displayStartDate} - {request.displayEndDate}</div>
                <div className={`tw-mt-1 ${
                  request.status === 'pending' ? 'tw-text-yellow-600' : 
                  request.status === 'approved' ? 'tw-text-green-600' : 'tw-text-red-600'
                }`}>
                  {request.status}
                </div>
                <div>Reason: {request.reason}</div>
                {assignedShifts[request.timeOffRequestID] > 0 && request.status === 'approved' && (
                  <div className="tw-text-red-600 tw-font-bold tw-mt-1">
                    Warning: {assignedShifts[request.timeOffRequestID]} assigned shifts
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    );
}

// Dummy data generation functions
// Helper function to round time to nearest 15 minutes
const roundTo15Minutes = (date: Date): Date => {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.round(minutes / 15) * 15;
    return new Date(date.setMinutes(roundedMinutes, 0, 0));
  };
  
  // Helper function to generate a random time within a range, rounded to the hour
  const randomHourTime = (start: number, end: number): Date => {
    const date = new Date();
    date.setHours(Math.floor(Math.random() * (end - start) + start), 0, 0, 0);
    return date;
  };
  
  // Helper function to generate a random time within a range, rounded to 15 minutes
  const random15MinTime = (start: number, end: number): Date => {
    const date = new Date();
    const hours = Math.floor(Math.random() * (end - start) + start);
    const minutes = Math.floor(Math.random() * 4) * 15;
    date.setHours(hours, minutes, 0, 0);
    return date;
  };

export const generateDummyRemoteSessions = (weekStart: Date): RemoteSupportSession[] => {
    const sessions: RemoteSupportSession[] = [];
    const weekEnd = endOfWeek(weekStart);
  
    for (let i = 0; i < 50; i++) {
      const sessionStart = randomHourTime(8, 20); // Sessions start between 8 AM and 8 PM
      const sessionDuration = Math.floor(Math.random() * 4) + 1; // 1 to 4 hours
      const sessionEnd = add(sessionStart, { hours: sessionDuration });
  
      // Adjust the date to fall within the week
      const daysToAdd = Math.floor(Math.random() * 7);
      const adjustedStart = add(weekStart, { days: daysToAdd, hours: sessionStart.getHours() });
      const adjustedEnd = add(adjustedStart, { hours: sessionDuration });
  
      if (isWithinInterval(adjustedStart, { start: weekStart, end: weekEnd })) {
        sessions.push({
            remoteSupportSessionID: `session-${i}`,
            providerName: `Provider ${i % 10 + 1}`,
            displayStartDate: format(adjustedStart, 'yyyy-MM-dd'),
            displayStartTime: format(adjustedStart, 'HH:mm'),
            displayEndTime: format(adjustedEnd, 'HH:mm'),
            status: Math.random() > 0.2 ? 'scheduled' : 'open',
            officeName: `Office ${i % 3 + 1}`,
            stationName: `Station ${i % 5 + 1}`,
            training: Math.random() > 0.8,
            supervisorID: "",
            providerID: "",
            startDate: "",
            endDate: "",
            displayEndDate: "",
            startTime: "",
            endTime: "",
            multiDay: false,
            startIndex: 0,
            endIndex: 0,
            multiDayStartIndex: 0,
            multiDayEndIndex: 0,
            recurrence: "",
            recurrenceEndDate: "",
            assignedShifts: [],
            colStart: 0,
            colEnd: 0,
            multiDayColStart: 0,
            multiDayColEnd: 0,
            dedicatedSession: false,
            repeatsSunday: false,
            repeatsMonday: false,
            repeatsTuesday: false,
            repeatsWednesday: false,
            repeatsThursday: false,
            repeatsFriday: false,
            repeatsSaturday: false,
            officeID: "",
            stationID: ""
        });
      }
    }
  
    return sessions;
  };
  
const generateDummyPatients = (): Patient[] => {
    return Array.from({ length: 10 }, (_, i) => ({
      patientID: `patient-${i}`,
      firstName: `FirstName${i}`,
      lastName: `LastName${i}`,
      email: `patient${i}@example.com`,
      patientDetailID: `detail-${i}`,
      active: Math.random() > 0.1,
      patientDetail: {} as PatientDetail, // Placeholder
      dateOfBirth: new Date(1950 + Math.floor(Math.random() * 50), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
      personalEmail: `personal${i}@example.com`,
      phone: `(555) ${100 + i}-${1000 + i}`,
      testPatient: i === 0, // First patient is a test patient
      serviceType: "Direct Support",
      socialSecurityNumber: `${100 + i}-${20 + i}-${3000 + i}`,
      contacts: [] as PatientContact[], // Placeholder
      insurance: {} as PatientInsurance, // Placeholder
      evvServices: [] as EVVService[], // Placeholder
      state: "OH",
      county: `County${i}`,
      internalEmail: `internal${i}@example.com`,
    }));
  };
  
  export const generateDummyCoverageEvents = (weekStart: Date): CoverageEvent[] => {
    const events: CoverageEvent[] = [];
    const weekEnd = endOfWeek(weekStart);
  
    for (let i = 0; i < 100; i++) {
      const eventStart = random15MinTime(6, 22); // Events start between 6 AM and 10 PM
      const eventDuration = (Math.floor(Math.random() * 12) + 1) * 15; // 15 minutes to 3 hours, in 15-minute increments
      const eventEnd = add(eventStart, { minutes: eventDuration });
  
      // Adjust the date to fall within the week
      const daysToAdd = Math.floor(Math.random() * 7);
      const adjustedStart = add(weekStart, { days: daysToAdd, hours: eventStart.getHours(), minutes: eventStart.getMinutes() });
      const adjustedEnd = add(adjustedStart, { minutes: eventDuration });
  
      if (isWithinInterval(adjustedStart, { start: weekStart, end: weekEnd })) {
        events.push({
            coverageEventID: `event-${i}`,
            patientName: `Patient ${i % 20 + 1}`,
            providerName: Math.random() > 0.2 ? `Provider ${i % 15 + 1}` : '',
            displayCoverageDate: format(adjustedStart, 'yyyy-MM-dd'),
            displayStartTime: format(adjustedStart, 'HH:mm'),
            displayEndTime: format(adjustedEnd, 'HH:mm'),
            status: Math.random() > 0.2 ? 'covered' : 'open',
            shiftType: ['Direct Support', 'Overnight Support'][Math.floor(Math.random() * 2)],
            patientID: "",
            providerID: "",
            staffSize: 0,
            groupSize: 0,
            billingCode: "",
            coverageDate: "",
            shiftTheme: 0,
            startTime: "",
            endTime: ""
        });
      }
    }
  
    return events;
  };
  
  export const generateDummyTimeOffRequests = (weekStart: Date): TimeOffRequest[] => {
    const requests: TimeOffRequest[] = [];
    const weekEnd = endOfWeek(weekStart);
  
    for (let i = 0; i < 20; i++) {
      const requestStart = add(weekStart, { days: Math.floor(Math.random() * 7) });
      const requestDuration = Math.floor(Math.random() * 5) + 1; // 1 to 5 days
      const requestEnd = add(requestStart, { days: requestDuration });
  
      if (isWithinInterval(requestStart, { start: weekStart, end: weekEnd }) ||
          isWithinInterval(requestEnd, { start: weekStart, end: weekEnd })) {
        requests.push({
            timeOffRequestID: `request-${i}`,
            employeeName: `Employee ${i % 10 + 1}`,
            displayStartDate: format(requestStart, 'yyyy-MM-dd'),
            displayEndDate: format(requestEnd, 'yyyy-MM-dd'),
            status: ['pending', 'approved', 'denied'][Math.floor(Math.random() * 3)],
            reason: ['Vacation', 'Sick Leave', 'Personal Day', 'Family Emergency'][Math.floor(Math.random() * 4)],
            employeeID: "",
            startDate: "",
            endDate: "",
            reasonDetail: "",
            submittedDate: "",
            displaySubmittedDate: "",
            directSupportProvider: false,
            remoteSupportAssociate: false,
            role: ""
        });
      }
    }
  
    return requests;
  };
  
  export const generateDummyAssignedShifts = (): { [employeeID: string]: number } => {
    const assignedShifts: { [employeeID: string]: number } = {};
    for (let i = 0; i < 20; i++) {
      assignedShifts[`request-${i}`] = Math.floor(Math.random() * 6); // 0 to 5 assigned shifts
    }
    return assignedShifts;
  };

export default ControlCenterPage;