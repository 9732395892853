import { useEffect, useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import { useNavigate, useParams } from "react-router-dom";
import LoadingItems from "../../components/utilities/Loaders";
import { BreadCumbs, DataBlock, DayBlock, NoticeBlock, SupportCoverageBlock, UserBlock } from "./SchedulingUtils";
import { Block, LabelOutputs } from "@medforall/medforall-component-library";
import SchedulePanel, { ScheduleDayPanel } from "../../components/panels/SchedulePanel";
import CoverageDetailsPanel, { CoverageDetailsPanelSuccess } from "../../components/panels/CoverageDetailsPanel";
import { addNewScheduleNotice, getClientActiveISPByID, getClientByID, getClientHomes, getClientServiceType, getCoverageRequirementForSpan, getScheduleNoticesForSpan, saveCoverageRequirement, saveRecurringCoverageRequirement } from "../../apicalls/schedulingService";
import ClientHome from "../../types/clientHome";
import moment from "moment";
import CoverageEvent from "../../types/coverageEvent";
import CoverageRequirement from "../../types/coverageRequirement";
import { PlusIcon } from "@heroicons/react/24/solid";
import { SubmitNewClientScheduleNotice, SubmitNewCoverageRequirement } from "../../components/panels/PanelCTAs";
import uuid from "react-uuid";
import { Tooltip } from "@mui/material";
import ClientScheduleNoticePanel, { ClientScheduleNoticePanelSuccess } from "../../components/panels/ClientScheduleNoticePanel";
import { ClientScheduleNotice } from "../../types/clientScheduleNotice";
import NoticeDetailsPanel from "../../components/panels/ScheduleNoticeDetailsPanel";

const ClientScheduleManagement = props => {
    const navContext = useAppContext();
	const [loading, setLoading] = useState<boolean>(true);
	const { patientID } = useParams();
    const { weekStart, weekEnd } = useParams();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    let navigate = useNavigate();

    const [weekLoaded, setWeekLoaded] = useState(false);

	const [isChangeView, setIsChangeView] = useState<boolean>(false);

    const [clientServiceTypes, setClientServiceTypes] = useState([]);

    const [clientDetails, setClientDetails] = useState(null);

    const [clientServicePlan, setClientServicePlan] = useState(null);

    const [clientHomes, setClientHomes] = useState<ClientHome[]>([]);

    const [servicePlanAvailable, setServicePlanAvailable] = useState(false);

    const [serviceTypeAvailable, setServiceTypeAvailable] = useState(false);

	// start demo code
	const [noEntries, setNoEntries] = useState<boolean>(false);

    const [coverageEvents, setCoverageEvents] = useState([]);
	const [requirementLoading, setRequirementLoading] = useState(false);
	const [RSWithBackupHours, setRSWithBackupHours] = useState(0.0);
	const [RSWithoutBackupHours, setRSWithoutBackupHours] = useState(0.0);
	const [weekDSHours, setWeekDSHours] = useState(0.0);
	const [weekRSHours, setWeekRSHours] = useState(0.0);
    const [currentWeek, setCurrentWeek] = useState([]);
	// end demo code

	useLayoutEffect(() => {
		// start temp code for demoing none
		const queryParams = new URLSearchParams(window.location.search)
		const noEntriesParam = queryParams.get("noEntries") || null;

		if (noEntriesParam) setNoEntries((noEntriesParam === "true"));
		//   end temp code for demoing none

		// navContext.setName({firstName: 'Kevin', lastName: 'Mack'})

		// setTimeout(() => {
		// 	setLoading(false);
		// }, 2000);

	}, [])

    const diff_hours = (dt2, dt1) => 
	{
		var duration = moment.duration(moment(dt2).diff(moment(dt1)));
            var hours = duration.asHours();
            // console.log(requirement);
            // console.log(hours);
            // console.log(hours < 0);
            if (hours < 0) {
                return hours + 24;
            } else {
                return hours;
            }
	
	}

    const nextWeek = () => {
		console.log("Next Week");

		let newStartDate = moment(startDate).add(7, 'd');
		let newEndDate = moment(endDate).add(7, 'd');

		navigate("/schedule/schedule-mgmt/" + patientID + "/weekly-schedule/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));

		// let newStartDate = moment(startDate).add(7, 'd');

		// setStartDate(newStartDate);
	}

	const prevWeek = () => {
		console.log("Prev Week");

		let newStartDate = moment(startDate).add(-7, 'd');
		let newEndDate = moment(endDate).add(-7, 'd');

		navigate("/schedule/schedule-mgmt/" + patientID + "/weekly-schedule/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));

		// let newStartDate = moment(startDate).add(-7, 'd');

		// setStartDate(newStartDate);
	}

    const RSWithBackupCodes = ['AMR', 'FMR', 'SMR', 'SVM'];
	const RSWithoutBackupCodes = ['AMS', 'FMS', 'SMS', 'RMS'];
    const [scheduleURL, setScheduleURL] = useState<string>("");

    const editSpanRequirement = (e) => {
        navigate("/schedule/client-requirement/"+ clientDetails.patientID +"/span-requirement");
        //navigate("/schedule/client-requirement/"+ clientDetails.patientID +"/requirement-form");
    }

	const initialValues = {
		// date: moment().format("M-D-YYYY"),
		date: "",
		start_time: "",
		end_time: "",
		shift_type: "",
		shift_recurrence: "Never",
		repeat_end_date: "",
		repeats_monday: false,
		repeats_tuesday: false,
		repeats_wednesday: false,
		repeats_thursday: false,
		repeats_friday: false,
		repeats_saturday: false,
		repeats_sunday: false,
		req_reason: "",
		custom_reason: "",
		service_location: "",
	};

	const initialErrors = {
		date: false,
		start_time: false,
		end_time: false,
		shift_type: false,
		frequency: false,
		repeat_end_date: false,
		req_reason: false,
		custom_reason: false,
		service_location: false,
	};

	const initialErrorMessages = {
		date: '',
		start_time: '',
		end_time: '',
		shift_type: '',
		frequency: '',
		repeat_end_date: '',
		req_reason: '',
		custom_reason: '',
		service_location: '',
	};

	const initialFormStates = {
		date: false,
		start_time: false,
		end_time: false,
		shift_type: false,
		frequency: false,
		repeat_end_date: false,
		req_reason: false,
		custom_reason: false,
		service_location: false,
	};

	const initialNoticeValues = {
		start_date: moment().format("M-D-YYYY"),
		start_time: "",
		end_date: moment().format("M-D-YYYY"),
		end_time: "",
		notice_type: "",
		comment: "",
	};

	const initialNoticeErrors = {
		start_date: false,
		start_time: false,
		end_date: false,
		end_time: false,
		notice_type: false,
		comment: false,
	};

	const initialNoticeErrorMessages = {
		start_date: '',
		start_time: '',
		end_date: '',
		end_time: '',
		notice_type: '',
		comment: '',
	};

	const initialNoticeFormStates = {
		start_date: false,
		start_time: false,
		end_date: false,	
		end_time: false,
		notice_type: false,
		comment: false,
	};


    useEffect(() => {
        console.log("Week Start");
        console.log(weekStart);
        console.log("Week end");
        console.log(weekEnd);

        const today = moment();

        setScheduleURL(`/schedule/client-requirement`);

        const fetchServiceTypes = async () => {
			const serviceTypes = await getClientServiceType(patientID, navContext.handleAPICallError);

            console.log("Service Types:");
			console.log(serviceTypes);

			setClientServiceTypes(serviceTypes);

            if(serviceTypes === null){
                setServiceTypeAvailable(false);
            } else {
                setServiceTypeAvailable(true);
            }
		}

		const fetchClientHomes = async () => {
			const clientHomes = await getClientHomes(patientID, navContext.handleAPICallError);

			console.log(clientHomes);

			setClientHomes(clientHomes);
		}

		const fetchClientDetails = async () => {
			const clientDetails = await getClientByID(patientID, navContext.handleAPICallError);

			console.log(clientDetails);

			setClientDetails(clientDetails);
		}

        const fetchClientServicePlan = async () => {
			const servicePlan = await getClientActiveISPByID(patientID, navContext.handleAPICallError)

            console.log("Service Plan:");
			console.log(servicePlan);

			setClientServicePlan(servicePlan);

            if(servicePlan.status === "Unavailable"){
                setServicePlanAvailable(false);
            } else {
                setServicePlanAvailable(true);
                // setValues({...values, "start_date": moment.utc(servicePlan.startDate).format("YYYY-MM-DD"), "end_date": moment.utc(servicePlan.endDate).format("YYYY-MM-DD") });
				// setPlanStartDate(moment.utc(servicePlan.startDate).format("YYYY-MM-DD"));
				// setPlanEndDate(moment.utc(servicePlan.endDate).format("YYYY-MM-DD"));
            }

            
		}

        const fetchCoverage = async (patientID, startTime, endTime) => {

            await fetchClientDetails();
            await fetchClientServicePlan();
            await fetchServiceTypes();
            await fetchClientHomes();

			console.log(startTime);
			console.log(endTime);

			const clientCoverage = await getCoverageRequirementForSpan(patientID, startTime, endTime, navContext.handleAPICallError);

			console.log(clientCoverage);

			const clientNotices = await getScheduleNoticesForSpan(patientID, startTime, endTime, navContext.handleAPICallError);

			console.log("Client Notices:");
			console.log(clientNotices);

			var backupHours = 0.0;
			var withoutBackupHours = 0.0;

			moment.suppressDeprecationWarnings = true;

			if(clientCoverage != null || clientNotices != null){
                setNoEntries(false);
				const sortedCoverage = clientCoverage ? clientCoverage.sort(
					(objA, objB) => {
						var timeA = new Date(objA.startTime);
						var timeB = new Date(objB.endTime);
						return timeA.getTime() - timeB.getTime()
	
					},
				  ): [];
				setCoverageEvents(sortedCoverage);

				const sortedNotices = clientNotices ? clientNotices.sort(
					(objA, objB) => {
						var timeA = new Date(objA.noticeStartTime);
						var timeB = new Date(objB.noticeEndTime);
						return timeA.getTime() - timeB.getTime()
	
					},
				  ): [];

				sortedCoverage.map((coverage, index) => { 
					if(coverage.status !== "Cancelled"){
						var coverageHours = diff_hours(coverage.endTime, coverage.startTime);
	
						if(RSWithBackupCodes.includes(coverage.billingCode)){
							backupHours += parseFloat(coverageHours.toFixed(2));
						} 
						if(RSWithoutBackupCodes.includes(coverage.billingCode)){
							withoutBackupHours += parseFloat(coverageHours.toFixed(2));
						} 
	
						console.log(coverage);
						console.log(coverageHours);
					}
	
				});

                let weekDate = moment(startTime.replace(/-/g, "/"));
				// console.log(weekDate.format("MM-DD-YYYY"));
				// console.log(weekDate);
				// console.log(weekDate <= moment(endTime.replace(/-/g, "/")));
				// console.log(moment(endTime.replace(/-/g, "/")));
                let weekDates = [];
				var weekRemoteSupportHours = 0.0;

                while(weekDate <= moment(endTime.replace(/-/g, "/"))) {
                    console.log(weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"));
        
                    const arr: CoverageRequirement[] = sortedCoverage.filter((coverage) => {
                        return coverage.displayCoverageDate === weekDate.format("MM-DD-YYYY");
                    });

					const notices: ClientScheduleNotice[] = sortedNotices.filter((notice) => {
						// check if current date is within notice start and end date
						return moment(weekDate.format("MM-DD-YYYY")).isBetween(moment(notice.displayStartDate, "MM-DD-YYYY"), moment(notice.displayEndDate, "MM-DD-YYYY"), undefined, '[]');
					});
						

                    var rsHours = 0.0;

                    arr.map((coverage, index) => { 
                        if(coverage.status !== "Cancelled"){
                            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                            rsHours += parseFloat(coverageHours.toFixed(2));
							weekRemoteSupportHours += parseFloat(coverageHours.toFixed(2));
                        }
        
                    });
        
                    let dateValue = {
                        "dayOfWeek": weekDate.format("dddd").substring(0,3),
                        "date": weekDate.format("DD"),
                        "highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
                        "fullDate": weekDate.format("MM-DD-YYYY"),
                        "shifts": arr.sort(
                            (objA, objB) => {
                                var timeA = new Date(objA.startTime);
                                var timeB = new Date(objB.endTime);
                                return timeA.getTime() - timeB.getTime()
            
                            },
                          ),
						"notices": notices,
                        "rsHours": rsHours,
                        "dsHours": 0.0,
                    }
        
                    weekDates.push(dateValue);
        
                    weekDate = weekDate.add(1, 'd');
                }


				setWeekRSHours(weekRemoteSupportHours);

                console.log(weekDates);

                setCurrentWeek(weekDates);

                setRSWithBackupHours(backupHours);
                setRSWithoutBackupHours(withoutBackupHours);

                setRequirementLoading(false);
			} else {
                setNoEntries(true);

				let weekDate = moment(startTime.replace(/-/g, "/"));
                let weekDates = [];
				var weekRemoteSupportHours = 0.0;

				while (weekDate <= moment(endTime.replace(/-/g, "/"))) {
					var rsHours = 0.0;

					let dateValue = {
                        "dayOfWeek": weekDate.format("dddd").substring(0,3),
                        "date": weekDate.format("DD"),
                        "highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
                        "fullDate": weekDate.format("MM-DD-YYYY"),
                        "shifts": [],
						"notices": [],
                        "rsHours": rsHours,
                        "dsHours": 0.0,
                    }
        
                    weekDates.push(dateValue)
        
                    weekDate = weekDate.add(1, 'd');
				}

				setWeekRSHours(weekRemoteSupportHours);

                console.log(weekDates);

                setCurrentWeek(weekDates);

            }

			
            setLoading(false);

			
			
			
			//console.log(weekDates);
		}

        if(weekStart === undefined || weekEnd === undefined){
            setStartDate(moment().startOf('week'));
            setEndDate(moment().startOf('week').add(6, 'd'));

            fetchCoverage(patientID, moment().startOf('week').format("MM-DD-YYYY"), moment().startOf('week').add(6, 'd').format("MM-DD-YYYY"))
			// make sure to catch any error
			.catch(console.error);
        } else {
            setStartDate(moment(weekStart.replace(/-/g, "/")));
            setEndDate(moment(weekEnd.replace(/-/g, "/")));

            fetchCoverage(patientID, moment(weekStart.replace(/-/g, "/")).format("MM-DD-YYYY"), moment(weekEnd.replace(/-/g, "/")).format("MM-DD-YYYY"))
			// make sure to catch any error
			.catch(console.error);
        }

        setWeekLoaded(true);
    }, [patientID])

	const submitForm = async (values) => {
		// validate form
		console.log(values);

		const found = clientServiceTypes.find(element => element.shiftType === values.shift_type);

		const startTime = moment(values.date.replace(/-/g, "/") + " " + values.start_time)
        const endTime = moment(values.date.replace(/-/g, "/") + " " + values.end_time)

		let newRequirement : CoverageRequirement = {
			coverageRequirementID: uuid(),
			patientID: clientDetails.patientID,
			recurrenceID: "",
			staffSize: found.staffSize,
			groupSize: found.groupSize,
			billingCode: found.billingCode,
			coverageDate: moment(values.date.replace(/-/g, "/")).format(),
			providerName: "",
			shiftType: found.shiftType,
			shiftTheme: 0,
			startTime: startTime.format(),
			displayStartTime: values.start_time,
			endTime: endTime.format(),
			displayEndTime: values.end_time,
			displayCoverageDate: values.date,
			recurrence: values.frequency,
			status: "",
			repeatsSunday: values.repeats_sunday,
			repeatsMonday: values.repeats_monday,
			repeatsTuesday: values.repeats_tuesday,
			repeatsWednesday: values.repeats_wednesday,
			repeatsThursday: values.repeats_thursday,
			repeatsFriday: values.repeats_friday,
			repeatsSaturday: values.repeats_saturday,
			recurrenceEndDate: moment(values.repeat_end_date.replace(/-/g, "/")).format(),
			reason: values.req_reason === "Custom" ? values.custom_reason : values.req_reason,
		}

		console.log(values.frequency);
		console.log(newRequirement);

		if (newRequirement.recurrence === "Never") {
			newRequirement.recurrenceEndDate = newRequirement.coverageDate;
			
			const result = await saveCoverageRequirement(newRequirement, navContext.handleAPICallError);

			if (!navContext.showErrorModal) {
				navContext.setSideNavTitle("Success");
				navContext.setSideNav( <CoverageDetailsPanelSuccess newRequirement shift={newRequirement}/> );
				navContext.setSideNavActions(null);
				navContext.setSideNavBottom(null);

				// refresh calendar
				await refreshCalendar();
			}

			
		} else {
			const result = await saveRecurringCoverageRequirement(newRequirement, navContext.handleAPICallError);

			if (!navContext.showErrorModal) {
				navContext.setSideNavTitle("Success");
				navContext.setSideNav( <CoverageDetailsPanelSuccess newRecurringRequirement shift={newRequirement}/> );
				navContext.setSideNavActions(null);
				navContext.setSideNavBottom(null);

				// refresh calendar
				await refreshCalendar();
			}

		}
	};

	const submitNoticeForm = async (values) => {
		// validate form
		console.log(values);

		const startTime = moment(values.start_date.replace(/-/g, "/") + " " + values.start_time)
        const endTime = moment(values.end_date.replace(/-/g, "/") + " " + values.end_time)

		let newNotice: ClientScheduleNotice = {
			clientScheduleDayNoticeID: uuid(),
			patientID: clientDetails.patientID,
			noticeType: values.notice_type,
			noticeStartTime: startTime.format(),
			noticeEndTime: endTime.format(),
			noticeStartDate: moment(values.start_date.replace(/-/g, "/")).format(),
			noticeEndDate: moment(values.end_date.replace(/-/g, "/")).format(),
			details: values.comment,
			displayStartDate: values.start_date,
			displayEndDate: values.end_date,
			displayStartTime: values.start_time,
			displayEndTime: values.end_time,
			duration: moment.duration(moment(values.end_date.replace(/-/g, "/")).diff(moment(values.start_date.replace(/-/g, "/")))).asHours(),
			status: "",
			reasonForCancellation: ""
		}
		
		console.log(newNotice);

		const result = await addNewScheduleNotice(newNotice, navContext.handleAPICallError);

			if (!navContext.showErrorModal) {
				navContext.setSideNavTheme(3);
				navContext.setSideNavTitle("Success");
				navContext.setSideNav( <ClientScheduleNoticePanelSuccess notice={newNotice}/> );
				navContext.setSideNavActions(null);
				navContext.setSideNavBottom(null);

				// refresh calendar
				await refreshCalendar();
			}
	};

	const refreshCalendar = async () => {
		const clientCoverage = await getCoverageRequirementForSpan(patientID, startDate.format("MM-DD-YYYY"), endDate.format("MM-DD-YYYY"), navContext.handleAPICallError);

			console.log(clientCoverage);

			const today = moment();

			var backupHours = 0.0;
			var withoutBackupHours = 0.0;
		
			const clientNotices = await getScheduleNoticesForSpan(patientID, startDate.format("MM-DD-YYYY"), endDate.format("MM-DD-YYYY"), navContext.handleAPICallError);

			console.log("Client Notices:");
			console.log(clientNotices);

			if(clientCoverage != null || clientNotices != null){
                setNoEntries(false);
				const sortedCoverage = clientCoverage ? clientCoverage.sort(
					(objA, objB) => {
						var timeA = new Date(objA.startTime);
						var timeB = new Date(objB.endTime);
						return timeA.getTime() - timeB.getTime()
	
					},
				  ): [];
				setCoverageEvents(sortedCoverage);

				const sortedNotices = clientNotices ? clientNotices.sort(
					(objA, objB) => {
						var timeA = new Date(objA.noticeStartTime);
						var timeB = new Date(objB.noticeEndTime);
						return timeA.getTime() - timeB.getTime()
	
					},
				  ): [];

				sortedCoverage.map((coverage, index) => { 
					if(coverage.status !== "Cancelled"){
						var coverageHours = diff_hours(coverage.endTime, coverage.startTime);
	
						if(RSWithBackupCodes.includes(coverage.billingCode)){
							backupHours += parseFloat(coverageHours.toFixed(2));
						} 
						if(RSWithoutBackupCodes.includes(coverage.billingCode)){
							withoutBackupHours += parseFloat(coverageHours.toFixed(2));
						} 
	
						console.log(coverage);
						console.log(coverageHours);
					}
	
				});

                let weekDate = moment(startDate);
                let weekDates = [];
				var weekRemoteSupportHours = 0.0;

                while (weekDate <= moment(endDate)) {
                    console.log(weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"));
        
                    const arr: CoverageRequirement[] = sortedCoverage.filter((coverage) => {
                        return coverage.displayCoverageDate === weekDate.format("MM-DD-YYYY");
                    });

					const notices: ClientScheduleNotice[] = sortedNotices.filter((notice) => {
						// check if current date is within notice start and end date
						return moment(weekDate.format("MM-DD-YYYY")).isBetween(moment(notice.displayStartDate, "MM-DD-YYYY"), moment(notice.displayEndDate, "MM-DD-YYYY"), undefined, '[]');
					});

                    var rsHours = 0.0;

                    arr.map((coverage, index) => { 
                        if(coverage.status !== "Cancelled"){
                            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                            rsHours += parseFloat(coverageHours.toFixed(2));
							weekRemoteSupportHours += parseFloat(coverageHours.toFixed(2));
                        }
        
                    });
        
                    let dateValue = {
                        "dayOfWeek": weekDate.format("dddd").substring(0,3),
                        "date": weekDate.format("DD"),
                        "highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
                        "fullDate": weekDate.format("MM-DD-YYYY"),
                        "shifts": arr.sort(
                            (objA, objB) => {
                                var timeA = new Date(objA.startTime);
                                var timeB = new Date(objB.endTime);
                                return timeA.getTime() - timeB.getTime()
            
                            },
                          ),
						"notices": notices,
                        "rsHours": rsHours,
                        "dsHours": 0.0,
                    }
        
                    weekDates.push(dateValue)
        
                    weekDate = weekDate.add(1, 'd');
                }

				setWeekRSHours(weekRemoteSupportHours);

                console.log(weekDates);

                setCurrentWeek(weekDates);

                setRSWithBackupHours(backupHours);
                setRSWithoutBackupHours(withoutBackupHours);

                setRequirementLoading(false);
			} else {
                setNoEntries(true);

				let weekDate = moment(startDate);
                let weekDates = [];
				var weekRemoteSupportHours = 0.0;

				while (weekDate <= moment(endDate)) {
					var rsHours = 0.0;

					let dateValue = {
                        "dayOfWeek": weekDate.format("dddd").substring(0,3),
                        "date": weekDate.format("DD"),
                        "highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
                        "fullDate": weekDate.format("MM-DD-YYYY"),
                        "shifts": [],
						"notices": [],
                        "rsHours": rsHours,
                        "dsHours": 0.0,
                    }
        
                    weekDates.push(dateValue)
        
                    weekDate = weekDate.add(1, 'd');
				}

				setWeekRSHours(weekRemoteSupportHours);

                console.log(weekDates);

                setCurrentWeek(weekDates);

            }

			
            setLoading(false);
	}

	// useLayoutEffect(() => {
	// 	console.log('changes', changes);
	// 	if (changes) setIsChangeView(true)
	// }, [changes])

    return (<>
    {loading ? <>
		<LoadingItems />
	</> : <>
		<div className="tw-w-full tw-px-40px">
			<div className="tw-h-vh tw-flex tw-flex-col tw-flex-grow tw-w-full">
				<div className="tw-space-y-20px tw-py-40px ">
					<BreadCumbs name={{
						firstName: clientDetails?.firstName,
						lastName: clientDetails?.lastName
					}}
					started={clientServicePlan?.displayStartDate}
					ended={clientServicePlan?.displayEndDate}
					url={`#`}
					changes={ isChangeView === true ? true : null }
                    scheduleName={"Client Schedule"}
                    scheduleURL={scheduleURL}
					/>
				</div>
					<div className="tw-flex tw-flex-col tw-flex-grow tw-w-full">
						<div className="tw-flex tw-space-x-40px tw-pb-20px">
							<div className="tw-flex-grow tw-basis-1/3">
								<UserBlock name={{
									firstName: clientDetails?.firstName,
									lastName: clientDetails?.lastName
								}}
								simple
								started={clientServicePlan?.displayStartDate}
								ended={clientServicePlan?.displayEndDate} 
                                onClick={editSpanRequirement}
                                />
							</div>
							<div className="tw-flex tw-flex-grow tw-basis-1/3">
								<SupportCoverageBlock simple className="tw-w-full tw-flex tw-flex-col" type="Remote Support" weekHours={weekRSHours} month={moment(startDate).format("MMMM YYYY")} week={moment(startDate).format("MM/DD") + " - " + moment(endDate).format("MM/DD YYYY")}/>
							</div>
							<div className="tw-flex tw-flex-grow tw-basis-1/3">
								<SupportCoverageBlock simple className="tw-w-full tw-flex-col" type="Direct Support" weekHours={weekDSHours} month={moment(startDate).format("MMMM YYYY")} week={moment(startDate).format("MM/DD") + " - " + moment(endDate).format("MM/DD YYYY")}/>
							</div>
						</div>
						<div className="tw-flex tw-items-center tw-py-10px">
							<span className="tw-mr-auto tw-text-dark hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px tw-cursor-pointer" onClick={prevWeek}>
								<LabelOutputs className="tw-text-in" icon="/images/icon-nav-arrow-left.svg" size="10">
									<span className="tw-text-5">
										Previous Week
									</span>
								</LabelOutputs>
							</span>

							<span className="tw-mx-auto tw-font-primary tw-font-primary-bold tw-inline-flex tw-items-center">
								{moment(startDate).format("MMMM YYYY")}
							</span>

							<span className="tw-ml-auto tw-text-dark hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px tw-cursor-pointer" onClick={nextWeek}>
								<LabelOutputs className="tw-text-in" icon="/images/icon-nav-arrow-right.svg" reverse size="10">
									<span className="tw-text-5">
										Next Week
									</span>
								</LabelOutputs>
							</span>
						</div>
						{ noEntries ? <>
							<div className="tw-w-full tw-flex-grow tw-flex tw-flex-col">
								<div className="tw-flex-shrhink-0 tw-grid tw-grid-cols-7 tw-space-x-20px tw-w-full tw-overflow-hidden">
								{ currentWeek.map((item, index) => {
					            return <>
                                    {index === 0 ? <DayBlock changeRequirements className="tw-w-full" date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} rsHours={item.rsHours} dsHours={item.dsHours}>

                                    </DayBlock>:
                                    <DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} rsHours={item.rsHours} dsHours={item.dsHours}>
                                       
                                    </DayBlock>}
                                    
                                </>})}
									
									
									{/* <DayBlock className="tw-w-full" date="Sunday">
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="Monday">
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="Tuesday">
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="Wednesday">
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="Thursday">
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="Friday">
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="Saturday">
									</DayBlock> */}
								</div>

								<div className="tw-flex-grow tw-w-full tw-flex tw-justify-center tw-items-center tw-py-20px">
									<div role="button" className={`tw-text-4 tw-rounded-full tw-w-300px tw-h-300px tw-aspect-square tw-inline-flex tw-space-y-5px tw-py-10px tw-px-5px tw-flex-col tw-justify-center tw-items-center tw-cursor-pointer  tw-bg-[#E5E5E5] tw-text-dark`} onClick={ e => {
											e.preventDefault();
											navContext.setSideNavTheme(3);
											navContext.setValues(initialValues);
											navContext.setErrors(initialErrors);
											navContext.setErrorMessages(initialErrorMessages);
											navContext.setFieldStates(initialFormStates);
											navContext.setPanelSize('tw-max-w-400px');
											navContext.setSideNavTitle('Add On-Demand Requirement');
											navContext.setSideNav( <SchedulePanel date={ moment().format("MM-DD-YYYY") } serviceTypes={ clientServiceTypes || null}/> );
											navContext.setSideNavActions(null);
											navContext.setSideNavBottom(<SubmitNewCoverageRequirement onSubmit={submitForm}/>)
											navContext.openSideNav();
										}}>
										<span className="tw-w-1/4 tw-mx-auto tw-aspect-square">
											<img className="tw-w-full" src="/images/icon-date.svg" />
										</span>
										<b className="tw-leading-none tw-block tw-text-center">
											No requirements
										</b>
										<span className="tw-text-6 tw-w-full tw-text-center tw-leading-tight">
											Use the &quot;Add&quot; button <Block>to add new requirements</Block>
										</span>
									</div>
								</div>
							</div>

						</> : <>
							<div className="tw-flex-grow tw-grid tw-grid-cols-7 tw-space-x-20px tw-w-full tw-overflow-hidden">
							
                            { currentWeek && currentWeek.map((item, index) => {
					            return <>
                                    {index === 0 ? <DayBlock changeRequirements className="tw-w-full" date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} rsHours={item.rsHours} dsHours={item.dsHours}>
										{item.notices && item.notices.length !== 0 ? <>	
											{item.notices.map((notice, index) => {
												return <NoticeBlock key={index} noticeType={notice.noticeType} startDate={notice.displayStartDate} endDate={notice.displayEndDate} duration={notice.duration} coverageAmount={notice.status} onClick={ e => {
													navContext.setSideNavTheme(3);
													navContext.setPanelSize('tw-max-w-400px');
													navContext.setSideNav( <NoticeDetailsPanel notice={notice} refreshCalendar={refreshCalendar}/> );
													navContext.setSideNavActions(null);
													navContext.setSideNavBottom(null);
													navContext.openSideNav();
												}}></NoticeBlock>
											})}
										</>:<></>}
										{item.shifts && item.shifts.length !== 0 ? <>
                                            {item.shifts.map((shift, index) => {
                                                return <DataBlock key={index} billingCode={shift.billingCode} coverageAmount={shift.status} type={shift.shiftType} date={moment(item.fullDate).format("MM/DD/YYYY")} time={`${shift.displayStartTime} - ${shift.displayEndTime}`} onClick={ e => {
													navContext.setSideNavTheme(3);
													navContext.setPanelSize('tw-max-w-400px');
													navContext.setSideNav( <CoverageDetailsPanel date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={ clientServiceTypes || null} refreshCalendar={refreshCalendar}/> );
													navContext.setSideNavActions(null);
													navContext.setSideNavBottom(null);
													navContext.openSideNav();
												}}></DataBlock>
                                            })}
                                            </>:<>
                                            
                                        </>}
										{((item.shifts.length === 0) && (item.notices.length === 0)) ? <>
											<DataBlock none date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} serviceTypes={ clientServiceTypes || null} requirement="on-demand"/>
										</>:<></>}
                                    </DayBlock>:
                                    <DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} rsHours={item.rsHours} dsHours={item.dsHours}>
                                        {item.notices && item.notices.length !== 0 ? <>	
											{item.notices.map((notice, index) => {
												return <NoticeBlock key={index} noticeType={notice.noticeType} startDate={notice.displayStartDate} endDate={notice.displayEndDate} duration={notice.duration} coverageAmount={notice.status} onClick={ e => {
													navContext.setSideNavTheme(3);
													navContext.setPanelSize('tw-max-w-400px');
													navContext.setSideNav( <NoticeDetailsPanel notice={notice} refreshCalendar={refreshCalendar}/> );
													navContext.setSideNavActions(null);
													navContext.setSideNavBottom(null);
													navContext.openSideNav();
												}}></NoticeBlock>
											})}
										</>:<></>}
										
										{item.shifts && item.shifts.length !== 0 ? <>
                                            {item.shifts.map((shift, index) => {
                                                return <DataBlock key={index} coverageAmount={shift.status} billingCode={shift.billingCode} type={shift.shiftType} date={moment(item.fullDate).format("MM/DD/YYYY")} time={`${shift.displayStartTime} - ${shift.displayEndTime}`} onClick={ e => {
													navContext.setSideNavTheme(3);
													navContext.setPanelSize('tw-max-w-400px');
													navContext.setSideNav( <CoverageDetailsPanel date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={ clientServiceTypes || null} refreshCalendar={refreshCalendar}/> );
													navContext.setSideNavActions(null);
													navContext.setSideNavBottom(null);
													navContext.openSideNav();
												}}></DataBlock>
                                            })}
                                            </>:<>
                                        </>}

										{((item.shifts.length === 0) && (item.notices.length === 0)) ? <>
											<DataBlock none date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} serviceTypes={ clientServiceTypes || null} requirement="on-demand"/>
										</>:<></>}
                                    </DayBlock>}
                                    
                                </>})}
								{/* <DayBlock changeRequirements className="tw-w-full" date="04/02/2023">
									<DataBlock coverageAmount="Fully Covered" type="Remote Support" date="04/02/2023" time="8am - 2pm" alert  onClick={ e => {
										navContext.setSideNav( <CoverageDetailsPanel date="04/02/2023" /> );
										navContext.openSideNav();
									}} />
									<DataBlock coverageAmount="Fully Open" type="Direct Support" date="04/02/2023" time="2pm - 4pm" />
									<DataBlock coverageAmount="Partially Open" type="Remote Support" date="04/02/2023" time="4pm - 5pm" />
									<DataBlock coverageAmount="Shift Canceled" type="Remote Support" date="04/02/2023"time="4pm - 5pm" />
								</DayBlock>
								<DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="04/03/2023">
									<DataBlock none date="04/03/2023"  />
								</DayBlock>

								<DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="04/04/2023">
									<DataBlock coverageAmount="Fully Covered" type="Remote Support" date="04/02/2023" time="8am - 2pm" alert  onClick={ e => {
										navContext.setSideNav( <CoverageDetailsPanel date="04/04/2023"/> );
										navContext.openSideNav();
									}}/>
									<DataBlock coverageAmount="Fully Covered" type="Direct Support" time="2pm - 4pm" />
								</DayBlock>

								<DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="04/05/2023">
									<DataBlock none date="04/05/2023" />
								</DayBlock>

								<DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="04/06/2023">
									<DataBlock none date="04/63/2023" />
								</DayBlock>

								<DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="04/07/2023">
									<DataBlock none date="04/07/2023"/>
								</DayBlock>

								<DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date="04/08/2023">
									<DataBlock none date="04/08/2023" />
								</DayBlock> */}
							</div>
						</> }
					</div>
			</div>
            {(navContext.scheduleAdmin || navContext.clientScheduleAdmin) &&<div className="tw-fixed tw-bottom-8 tw-right-8 tw-flex">
				<Tooltip title="Add On-Demand Requirement" placement="top">
				 <button
                    type="button"
                    className="tw-outline-none tw-border-0 tw-appearance-none tw-overflow-hidden tw-bg-primary tw-p-10px tw-flex tw-items-center tw-justify-center tw-aspect-square tw-rounded-full tw-text-white hover:tw-bg-indigo-500 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                    onClick={(e) => {
						e.preventDefault();
						navContext.setSideNavTheme(3);
						navContext.setValues(initialValues);
						navContext.setErrors(initialErrors);
						navContext.setErrorMessages(initialErrorMessages);
						navContext.setFieldStates(initialFormStates);
						navContext.setPanelSize('tw-max-w-400px');
						navContext.setSideNavTitle('Add On-Demand Requirement');
						navContext.setSideNav( <SchedulePanel date={ moment().format("MM-DD-YYYY") } serviceTypes={ clientServiceTypes || null}/> );
						navContext.setSideNavActions(null);
						navContext.setSideNavBottom(<SubmitNewCoverageRequirement onSubmit={submitForm}/>)
						navContext.openSideNav();
					}}
                >
                    <PlusIcon className="tw-h-10 tw-w-10" aria-hidden="true" />
                </button>
				</Tooltip>
				{navContext.showTesting && <Tooltip title="Add Client Schedule Notice" placement="top">
				<button
                    type="button"
                    className="tw-outline-none tw-border-0 tw-appearance-none tw-overflow-hidden tw-bg-secondary-dark tw-mx-4 tw-p-10px tw-flex tw-items-center tw-justify-center tw-aspect-square tw-rounded-full tw-text-white hover:tw-bg-indigo-500 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
					onClick={(e) => {
						e.preventDefault();
						navContext.setSideNavTheme(3);
						navContext.setValues(initialNoticeValues);
						navContext.setErrors(initialNoticeErrors);
						navContext.setErrorMessages(initialNoticeErrorMessages);
						navContext.setFieldStates(initialNoticeFormStates);
						navContext.setPanelSize('tw-max-w-400px');
						navContext.setSideNavTitle('Add Client Schedule Notice');
						navContext.setSideNav( <ClientScheduleNoticePanel /> );
						navContext.setSideNavActions(null);
						navContext.setSideNavBottom(<SubmitNewClientScheduleNotice onSubmit={submitNoticeForm}/>);
						navContext.openSideNav();
					}}
					>
						<PlusIcon className="tw-h-10 tw-w-10" aria-hidden="true" />
					</button>
					</Tooltip>}
            </div>}
		</div>
	</>}
    </>);
}

export default ClientScheduleManagement;