import { useRef, useState } from "react";
import RichTextField from "../../components/forms/richTextField";
import { ButtonPrimary, ButtonSecondary } from "../../components/buttons/Buttons";
import { Select } from "../../components/forms/formFields";
import ConfirmDialog from "../../components/dialogs/confirmDialog";
import { useNavigate } from "react-router-dom";
import SuccessDialog from "../../components/dialogs/successDialog";
import { CircularProgress } from "@mui/material";
import { AgencyAnnouncement } from "../../types/agencyAnnouncement";
import { addNewAgencyAnnouncement, escapeSingleQuotes } from "../../apicalls/announcementService";
import { useAppContext } from "../../Context";
import { GenericBreadCrumbs } from "../Scheduling/SchedulingUtils";
import moment from "moment";
import { set } from "lodash";
import CalendarSelector from "../../components/calendars/CalendarSelector";
import ReadOnlyText from "../../components/forms/readOnlyRichTextField";

const AddAgencyAnnouncementPage = props => {
    const [content, setContent] = useState('');
    let navigate = useNavigate();

    const navContext = useAppContext();

    const [showPreview, setShowPreview] = useState(false);

    const [savingAnnouncement, setSavingAnnouncement] = useState(false);

    const announcementTypeRef = useRef();

    const [showCancelDialog, setShowCancelDialog] = useState(false);

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const dismissSuccessDialog = () => {
        setShowSuccessDialog(false);
        navigate(-1);
    };

    const showAnnouncementPreview = async () => {
        const valid = validateForm();

        if(valid) {
            setShowPreview(true);
        }
    }

    const backToEdit = () => {
        setShowPreview(false);
    }

    const saveAgencyAnnouncement = async () => {
        console.log("Save Agency Announcement");

        setSavingAnnouncement(true);

            var newAnnouncement: AgencyAnnouncement = {
                agencyAnnouncementID: "",
                announcementTitle: escapeSingleQuotes(formValues.announcementTitle),
                announcementDescription: escapeSingleQuotes(formValues.announcementDescription),
                announcementType: formValues.announcementType,
                dateCreated: undefined,
                createdBy: "",
                status: "",
                startDate: moment(formValues.startDate, "M-D-YYYY").toDate(),
                dateOfExpiry: moment(formValues.dateOfExpiry, "M-D-YYYY").toDate(),
                publishedDate: undefined,
                publishedBy: "",
                archiveDate: undefined,
                archivedBy: ""
            }

            console.log(newAnnouncement);

            const result = await addNewAgencyAnnouncement(newAnnouncement, navContext.handleAPICallError);

            setSavingAnnouncement(false);
            setShowSuccessDialog(true);

    }

    const confirmCancelAddAnnouncement = () => {
        setShowCancelDialog(true);
    }

    const cancelAddAnnouncement = () => {
        setShowCancelDialog(false);
        navigate(-1);
    }

    const dismissCancelDialog = () => {
        setShowCancelDialog(false);
    }

    const handleRichTextFieldChange = (value: string) => {
        console.log(value);

        setFormValues({...formValues, announcementDescription: value});

        validateAnnouncementDescription(value);
    }

    const [formValues, setFormValues] = useState({
        announcementTitle: '',
        announcementDescription: '',
        announcementType: '',
        startDate: moment().format('M-D-YYYY'),
        dateOfExpiry: moment().add(14, 'days').format('M-D-YYYY'),
    });

    const [formErrors, setFormErrors] = useState({
        announcementTitleError: false,
        announcementTitleErrorMessage: '',
        announcementDescriptionError: false,
        announcementDescriptionErrorMessage: '',
        announcementTypeError: false,
        announcementTypeErrorMessage: '',
        startDateError: false,
        startDateErrorMessage: '',
        dateOfExpiryError: false,
        dateOfExpiryErrorMessage: ''
    });

    const validateStartDate = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, startDateError: true, startDateErrorMessage: 'Start Date is required'});
        } else if (moment(value, "M-D-YYYY").isBefore(moment().startOf('day'))) {
            setFormErrors({...formErrors, startDateError: true, startDateErrorMessage: 'Start Date cannot be in the past'});
        } else {
            setFormErrors({...formErrors, startDateError: false, startDateErrorMessage: ''});

            setFormValues({...formValues, dateOfExpiry: moment(value, "M-D-YYYY").add(14, 'days').format('M-D-YYYY')});
        }
    }

    const validateDateOfExpiry = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, dateOfExpiryError: true, dateOfExpiryErrorMessage: 'Date of Expiry is required'});
        } else if (moment(value, "M-D-YYYY").isSameOrBefore(moment(formValues.startDate, "M-D-YYYY"))) {
            setFormErrors({...formErrors, dateOfExpiryError: true, dateOfExpiryErrorMessage: 'Date of Expiry cannot be before Start Date'});
        } else {
            setFormErrors({...formErrors, dateOfExpiryError: false, dateOfExpiryErrorMessage: ''});
        }
    }

    const validateAnnouncementTitle = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, announcementTitleError: true, announcementTitleErrorMessage: 'Announcement title is required'});
        } else {
            setFormErrors({...formErrors, announcementTitleError: false, announcementTitleErrorMessage: ''});
        }
    }

    const validateAnnouncementDescription = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, announcementDescriptionError: true, announcementDescriptionErrorMessage: 'Announcement description is required'});
        } else {
            setFormErrors({...formErrors, announcementDescriptionError: false, announcementDescriptionErrorMessage: ''});
        }
    }

    const validateAnnouncementType = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, announcementTypeError: true, announcementTypeErrorMessage: 'Announcement type is required'});
        } else {
            setFormErrors({...formErrors, announcementTypeError: false, announcementTypeErrorMessage: ''});
        }
    }

    const validateForm = () => {
        let valid = true;

        var currentErrors = {...formErrors};

        if(formValues.announcementTitle === '') {
            currentErrors.announcementTitleError = true;
            currentErrors.announcementTitleErrorMessage = 'Announcement title is required';
            valid = false;
        } else {
            currentErrors.announcementTitleError = false;
            currentErrors.announcementTitleErrorMessage = '';
        }

        if(formValues.announcementDescription === '') {
            currentErrors.announcementDescriptionError = true;
            currentErrors.announcementDescriptionErrorMessage = 'Announcement description is required';
            valid = false;
        } else {
            currentErrors.announcementDescriptionError = false;
            currentErrors.announcementDescriptionErrorMessage = '';
        }

        if(formValues.announcementType === '') {
            currentErrors.announcementTypeError = true;
            currentErrors.announcementTypeErrorMessage = 'Announcement type is required';
            valid = false;
        } else {
            currentErrors.announcementTypeError = false;
            currentErrors.announcementTypeErrorMessage = '';
        }

        if(formValues.startDate === '') {
            currentErrors.startDateError = true;
            currentErrors.startDateErrorMessage = 'Start Date is required';
            valid = false;
        } else if (moment(formValues.startDate, "M-D-YYYY").isBefore(moment().startOf('day'))) {
            currentErrors.startDateError = true;
            currentErrors.startDateErrorMessage = 'Start Date cannot be in the past';
            valid = false;
        } else {
            currentErrors.startDateError = false;
            currentErrors.startDateErrorMessage = '';
        }

        if(formValues.dateOfExpiry === '') {
            currentErrors.dateOfExpiryError = true;
            currentErrors.dateOfExpiryErrorMessage = 'Date of Expiry is required';
            valid = false;
        } else if (moment(formValues.dateOfExpiry, "M-D-YYYY").isSameOrBefore(moment(formValues.startDate, "M-D-YYYY"))) {
            currentErrors.dateOfExpiryError = true;
            currentErrors.dateOfExpiryErrorMessage = 'Date of Expiry cannot be before Start Date';
            valid = false;
        } else {
            currentErrors.dateOfExpiryError = false;
            currentErrors.dateOfExpiryErrorMessage = '';
        }

        setFormErrors(currentErrors);

        return valid;
    }
    
    return (<>
        <SuccessDialog  title="Announcement Added" message="The agency announcement has been added successfully." onClose={dismissSuccessDialog} closeButtonText="Back to announcements" open={showSuccessDialog}/>
        <ConfirmDialog title="Cancel Add Announcement" message="Are you sure you want to cancel adding the announcement?" onConfirm={cancelAddAnnouncement} confirmButtonText={"Yes"} cancelButtonText="No" onCancel={dismissCancelDialog} showDialog={showCancelDialog}/>
        <div className="tw-px-xl tw-pt-xl">
            {/* <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Add Agency Announcement</h2>
            </div> */}
            <div className="tw-flex tw-flex tw-flex-col tw-space-y-20px tw-text-dark">
                    <div className="tw-space-y-20px tw-pb-2 tw-border-b tw-border-gray-800">
					<GenericBreadCrumbs 
                    mainHeading={"Agency Announcements"}
                    subHeading={"Add"}
                    backURL={"/announcement/agency"}
					/>
			        </div>
            </div>

            {showPreview ? <>
                <div className="tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                    <div className="tw-flex tw-flex-col tw-space-y-1">
                        {/* <label className="tw-text-gray-700">Announcement Title</label> */}
                        <h4 className="tw-text-gray-900">Title: {formValues.announcementTitle}</h4>
                    </div>
                    <div className="tw-flex tw-flex-col tw-space-y-1">
                        {/* <label className="tw-text-gray-700">Announcement Title</label> */}
                        <ReadOnlyText content={formValues.announcementDescription} />
                    </div>

                    <div className="tw-flex tw-justify-end tw-mx-4">
                        {savingAnnouncement ? <>
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </>:<>
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={backToEdit}>
                                            Back
                                        </ButtonSecondary>
                                        <ButtonPrimary className="tw-mt-md" type="submit" onClick={saveAgencyAnnouncement}>
                                            Save
                                        </ButtonPrimary>
                        </>}

                    </div>
                </div>
            </>: <>
            <div className="tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">   
                    <div className="tw-flex tw-flex-col tw-space-y-1">
                        <label className="tw-text-gray-700">Announcement Title</label>
                        <input
                            type="text"
                            min={0}
                            value={formValues.announcementTitle}
                            onChange={e => {
                                setFormValues({...formValues, announcementTitle: e.target.value});
                                validateAnnouncementTitle(e.target.value);
                            }}
                            className={`tw-px-3 tw-py-2 tw-border tw-bg-white ${formErrors.announcementTitleError ? "tw-border-red-500": "tw-border-primary"} tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-border-blue-300`}
                            />
                        {formErrors.announcementTitleError && <p className="tw-text-red-500">{formErrors.announcementTitleErrorMessage}</p>}
                    </div>

                    <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/2">
                        <label className="tw-text-gray-700">Announcement Type</label>
                        <Select id="announcement-type" className={formErrors.announcementTypeError ? '_form-error': ''} 
                            onChange={ e => {
                                console.log(e.target.value);
                                setFormValues({...formValues, announcementType: e.target.value});
                                validateAnnouncementType(e.target.value);
                            }} ref={ announcementTypeRef } value={ formValues.announcementType }>
                            <option value="">Select</option>
                            <option value="Policy">Policy</option>
                            <option value="Procedure">Procedure</option>
                            <option value="Client Onboarding">Client Onboarding</option>
                            <option value="Client Offboarding">Client Offboarding</option>
                            <option value="Software Update">Software Update</option>
                            <option value="Other">Other</option>
                        </Select>
                        {formErrors.announcementTypeError && <p className="tw-text-red-500">{formErrors.announcementTypeErrorMessage}</p>}
                    </div>

                    <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/4 sm:tw-w-1/2">
                        <CalendarSelector id="startDate" className={formErrors.startDateError ? '_form-error' : ''} 
                             name="Start Date:" value={ formValues.startDate || null } 
                             onChange={ e => {
                                console.log(e.target.value);
                                setFormValues({...formValues, startDate: e.target.value});
                                validateStartDate(e.target.value);
                            }}/>
                            {formErrors.startDateError && <p className="tw-text-red-500">{formErrors.startDateErrorMessage}</p>}
                    </div>

                    <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/4 sm:tw-w-1/2">
                        <CalendarSelector id="dateOfExpiry" className={formErrors.dateOfExpiryError ? '_form-error' : ''} 
                             name="Date Of Expiry:" value={ formValues.dateOfExpiry || null } 
                             onChange={ e => {
                                console.log(e.target.value);
                                setFormValues({...formValues, dateOfExpiry: e.target.value});
                                validateDateOfExpiry(e.target.value);
                            }}/>
                            {formErrors.dateOfExpiryError && <p className="tw-text-red-500">{formErrors.dateOfExpiryErrorMessage}</p>}
                    </div>



                    <RichTextField label="Announcement Description" value={formValues.announcementDescription} onChange={handleRichTextFieldChange} showValidation={formErrors.announcementDescriptionError} validationMessage={formErrors.announcementDescriptionErrorMessage}/>

                    <div className="tw-flex tw-justify-end tw-mx-4">
                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={confirmCancelAddAnnouncement}>
                                                Cancel
                                        </ButtonSecondary>
                                        <ButtonPrimary className="tw-mt-md" type="submit" onClick={showAnnouncementPreview}>
                                            Save
                                        </ButtonPrimary>

                    </div>
            </div>
            </>}

            

            
        </div>
    </>);
}

export default AddAgencyAnnouncementPage;