import axios, { AxiosRequestConfig } from "axios";
import CoverageEvent from "../types/coverageEvent";
import Employee from "../types/employee";
import RemoteSupportSession from "../types/remoteSupportSession";
import TimeOffRequest from "../types/timeOffRequest";
import { getAPIToken } from "./billingService";
import { MapOverlappingSessions, handleIncomingSessions, handleIncomingShifts, handleIncomingSpanSessions } from "./schedulingService";
import RemoteSupportStation from "../types/remoteSupportStation";
import AssignedEvent from "../types/assignedEvent";
import moment from "moment";
import { RemoteSupportTicket, SupportTicketComment } from "../types/remoteSupportTicket";
import { SessionLog } from "../types/sessionLog";

export const getRemoteSupportAssociates = async (handleAPICallError: (error: any) => void) : Promise<Employee[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'rsa/active';
    return axios.get<Employee[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getRemoteSupportSupervisors = async (handleAPICallError: (error: any) => void) : Promise<Employee[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'supervisor/active';
    return axios.get<Employee[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const saveRemoteSupportSession = async (session: RemoteSupportSession, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session';

    return axios.post<any>(url, session, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const updateRemoteSupportSession = async (session: RemoteSupportSession, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session';

    return axios.put<any>(url, session, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const updateRecurringRemoteSupportSession = async (session: RemoteSupportSession, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/recurring/' + session.remoteSupportSessionID;

    return axios.put<any>(url, session, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const deleteRemoteSupportSession = async (session: RemoteSupportSession, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/' + session.remoteSupportSessionID;

    return axios.delete<any>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const deleteRecurringRemoteSupportSession = async (session: RemoteSupportSession, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/recurring/' + session.remoteSupportSessionID;

    return axios.delete<any>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getRemoteSupportSessionByID = async (sessionID: string, handleAPICallError: (error: any) => void) : Promise<RemoteSupportSession> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/' + sessionID;

    return axios.get<RemoteSupportSession>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return handleIncomingSessions(response.data);
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const autoAssignRemoteSupportSessionByID = async (sessionID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/' + sessionID + '/autoAssign';

    return axios.get<any>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getStationsForColumbus = async(handleAPICallError: (error: any) => void) : Promise<RemoteSupportStation[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const officeID = "10fe04ac-7bcd-48f3-bde5-927bfe1830a8";

    const url = baseURL + officeID + "/stations";

    return axios.get<RemoteSupportStation[]>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
        });

}

export const getCoverageEventsBySessionID = async (sessionID: string, handleAPICallError: (error: any) => void) : Promise<CoverageEvent[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/' + sessionID + '/shifts';

    return axios.get<CoverageEvent[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            return response.data ? response.data.map(handleIncomingShifts): null;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });


}

export const reassignShiftToDedicatedSession = async (sessionID: string, shift: CoverageEvent, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/' + sessionID + '/shiftReassign';

    return axios.put<any>(url,shift,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });


}

export const divideShiftWihSession = async (sessionID: string, shift: AssignedEvent, concurrentSession: RemoteSupportSession, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/' + sessionID + '/shiftDivide/' + shift.coverageEventID;

    return axios.put<any>(url, concurrentSession, requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const saveRecurringRemoteSupportSession = async (session: RemoteSupportSession, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/recurring';

    return axios.post<any>(url, session, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getTimeOffForNewSession = async (session: RemoteSupportSession, handleAPICallError: (error: any) => void) : Promise<TimeOffRequest> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/new/timeOff';

    return axios.post<TimeOffRequest>(url, session, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const getOverlappingSessions = async (sessionID: String, handleAPICallError: (error: any) => void) : Promise<RemoteSupportSession[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"session/"+sessionID+"/overlapping";
    return axios.get<RemoteSupportSession[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            if(response.data !== null){
                const allSessions = response.data.map(handleIncomingSpanSessions);
    
                return allSessions;
            } else {
                return [];
            }
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getProviderSessionsForSpan = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<RemoteSupportSession[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"sessions/provider/"+startDate+"/"+endDate;
    return axios.get<RemoteSupportSession[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            if(response.data !== null){
                const allSessions = response.data.map(handleIncomingSpanSessions);
    
                const sortedSessions = allSessions.sort(
                    (objA, objB) => moment(objA.startTime).diff(moment(objB.startTime)),
                );
    
                allSessions.forEach(function(session, index, arr){
                    var mappedSession = MapOverlappingSessions(allSessions, session);
                    //console.log("mapped session");
                    //console.log(mappedSession);
                    arr[index] = mappedSession;
                });
    
                //console.log("Mapped Sessions:");
                //console.log(allSessions);
    
                return allSessions;
            } else {
                return [];
            }
        } else {
            handleAPICallError(response);
        }
        
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const claimRemoteSupportSession = async (session: RemoteSupportSession, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/claimed';

    return axios.post<any>(url, session, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const saveRemoteSupportTicket = async (ticker: RemoteSupportTicket, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'remote-support/ticket';

    return axios.post<any>(url, ticker, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });

}

export const loadPastTicketsByPageNumber = async (pageNumber: number, handleAPICallError: (error: any) => void) : Promise<RemoteSupportTicket[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'remote-support/tickets/' + pageNumber;

    return axios.get<RemoteSupportTicket[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });

} 

export const loadPastPendingTicketsByPageNumber = async (pageNumber: number, handleAPICallError: (error: any) => void) : Promise<RemoteSupportTicket[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'remote-support/tickets/pending/' + pageNumber;

    return axios.get<RemoteSupportTicket[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });

} 

export const loadPastResolvedTicketsByPageNumber = async (pageNumber: number, handleAPICallError: (error: any) => void) : Promise<RemoteSupportTicket[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'remote-support/tickets/resolved/' + pageNumber;

    return axios.get<RemoteSupportTicket[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });

} 

export const getAllTicketsForDateRange = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<RemoteSupportTicket[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'support/tickets/' + startDate + '/' + endDate;

    return axios.get<RemoteSupportTicket[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });

}

export const addCommentToSupportTicket = async (comment: SupportTicketComment, ticketID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'support-ticket/' + ticketID + '/comment';

    return axios.post<any>(url, comment, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });

}

export const markTicketAsResolved = async (ticketID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'support-ticket/' + ticketID + '/resolve';

    return axios.put<any>(url, null, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });

}

export const getSupportTicketByID = async (ticketID: string, handleAPICallError: (error: any) => void) : Promise<RemoteSupportTicket> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'support-ticket/' + ticketID;

    return axios.get<RemoteSupportTicket>(url,requestHeader).then(response => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });

}

export const getLogsForRemoteSupportSession = async (sessionID: string, handleAPICallError: (error: any) => void) : Promise<SessionLog[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"session/"+sessionID+"/logs";
    return axios.get<SessionLog[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            if (!response.data) {
                return null;
            } else {
                return response.data.map(handleIncomingSessionLogs);
            }
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const handleIncomingSessionLogs =  (log: SessionLog): SessionLog => {
    // Parse string as UTC time
    const utcMoment = moment.utc(log.loggedTime); 

    // Convert to Eastern time 
    const easternMoment = utcMoment.tz('America/New_York');

    // Format as string
    const easternTimeString = easternMoment.format('MMMM DD, YYYY | h:mmA'); 
   
    log.displayLoggedTime = easternTimeString;

    return log;
}

export const getSessionDocumentationForSpan = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<RemoteSupportSession[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL+"sessions/documentation/"+startDate+"/"+endDate;
    return axios.get<RemoteSupportSession[]>(url,requestHeader).then(response => {
        if(response.status < 300) {
            if(response.data !== null){
                const allSessions = response.data.map(handleIncomingSpanSessions);
    
                const sortedSessions = allSessions.sort(
                    (objA, objB) => moment(objA.startTime).diff(moment(objB.startTime)),
                );
    
                allSessions.forEach(function(session, index, arr){
                    var mappedSession = MapOverlappingSessions(allSessions, session);
                    //console.log("mapped session");
                    //console.log(mappedSession);
                    arr[index] = mappedSession;
                });
    
                //console.log("Mapped Sessions:");
                //console.log(allSessions);
    
                return allSessions;
            } else {
                return [];
            }
        } else {
            handleAPICallError(response);
        }
        
    }).catch(error => {
        handleAPICallError(error);
        return [];
      });
}

export const getEmployeeByID = async (employeeID: string, handleAPICallError: (error: any) => void) : Promise<Employee> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'employees/' + employeeID + '/info';

    return axios.get<Employee>(url,requestHeader).then(response => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });

}

export const getRemoteSupportSessionDocumentationByID = async (sessionID: string, handleAPICallError: (error: any) => void) : Promise<RemoteSupportSession> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/documentation/' + sessionID;

    return axios.get<RemoteSupportSession>(url,requestHeader).then((response) => {
        if(response.status < 300) {
            return handleIncomingSessions(response.data);
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });


}

export const approveRemoteSupportSession = async (sessionID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/' + sessionID + '/approve';

    return axios.get(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });

}

export const rejectRemoteSupportSession = async (sessionID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/' + sessionID + '/reject';

    return axios.get(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });

}

export const unlockRemoteSupportSession = async (sessionID: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + 'session/' + sessionID + '/unlock';

    return axios.get(url, requestHeader).then((response) => {
        if(response.status < 300) {
            return response.data;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });

}