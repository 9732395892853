export const Base = props => {
	const Tag = props.tag || 'span';
	const classes = `${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;

	const attributes = {
		...props,
		className: classes,
		tag: null,
		coreClasses: null
	}

	return <Tag { ...attributes } />
}

export const Block = props => <Base coreClasses="tw-block" tag={ props.tag || 'span' } children={ props.children } />;

export const Span = props => <Base coreClasses="tw-block" tag={ props.tag || 'h3' }  children={ props.children } />;
