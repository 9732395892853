import { useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { RemoteSupportEvent } from "../../types/servicePlan";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useEditPlanContext } from "../../pages/EditServicePlanPage";
import { addRemoteSupportEvent, deleteRemoteSupportEvent, updateRemoteSupportEvent } from "../../apicalls/servicePlanService";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { v4 as uuidv4 } from 'uuid';
import { useAppContext } from "../../Context";
import { LoadingSpinner } from "./loadingSpinner";

export const ViewRemoteSupportTaskSection = props => {
    return (<>
        <div className="tw-pb-2 tw-flex">
            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">{props?.task.eventTitle}</span>
            {props?.showEdit && <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={() => props?.onEdit(props?.task.remoteSupportEventID)}>Edit</span>}
        </div>
            <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900 tw-mt-1">{props?.task.eventDetails}</span>
                                <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Time</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.task.timeOfDay}</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Pre Reminder</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.task.preEventReminder} min</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Overdue Reminder</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.task.postEventReminder} min</span>
                                    </div>
                                </div>
                                <div className="tw-flex tw-p-1">
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.task.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.task.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.task.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.task.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.task.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.task.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.task.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.task.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.task.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.task.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.task.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.task.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.task.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.task.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                    </span>
                                </div>
    </>);
}

const EditRemoteSupportTaskSection = props => {
    const planContext = useNewPlanContext();

    const RemoteSupportEventSchema = Yup.object().shape({
        eventTitle: Yup.string()
          .max(50, 'Event title must be at most 50 characters')
          .required('Event title is required'),
        eventDetails: Yup.string().required('Event details are required'),
        timeOfDay: Yup.string().required('Time of day is required')
            .matches(
            /^(0?[1-9]|1[0-2]):[0-5][0-9] ?(am|pm)$/i,
            'Time of Day must be in 12-hour format (hh:mm am/pm)'
            ),
        preEventReminder: Yup.number()
          .positive('Pre-event reminder must be at least 1 minute or greater')
          .required('Pre-event reminder is required'),
        postEventReminder: Yup.number()
          .positive('Post-event reminder must be at least 1 minute or greater')
          .required('Post-event reminder is required'),
      });

    const editPlanContext = useEditPlanContext();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const initialValues = {
        eventTitle: props?.task.eventTitle,
        eventDetails: props?.task.eventDetails,
        timeOfDay: props?.task.timeOfDay,
        repeatsMonday: props?.task.repeatsMonday,
        repeatsTuesday: props?.task.repeatsTuesday,
        repeatsWednesday: props?.task.repeatsWednesday,
        repeatsThursday: props?.task.repeatsThursday,
        repeatsFriday: props?.task.repeatsFriday,
        repeatsSaturday: props?.task.repeatsSaturday,
        repeatsSunday: props?.task.repeatsSunday,
        preEventReminder: props?.task.preEventReminder,
        postEventReminder: props?.task.postEventReminder,
      };

    const taskFormik = useFormik({
        initialValues,
        validationSchema: RemoteSupportEventSchema,
        onSubmit: (values) => {
            console.log(values);
            var newTask: RemoteSupportEvent = {
                eventTitle: values.eventTitle,
                eventDetails: values.eventDetails,
                timeOfDay: values.timeOfDay,
                repeatsMonday: values.repeatsMonday,
                repeatsTuesday: values.repeatsTuesday,
                repeatsWednesday: values.repeatsWednesday,
                repeatsThursday: values.repeatsThursday,
                repeatsFriday: values.repeatsFriday,
                repeatsSaturday: values.repeatsSaturday,
                repeatsSunday: values.repeatsSunday,
                preEventReminder: values.preEventReminder,
                postEventReminder: values.postEventReminder,
                remoteSupportEventID: props?.task.remoteSupportEventID,
                servicePlanID: "",
                responsibleParty: ""
            };

            if(props?.editPlan){
                setLoading(true);
            }

            props?.onSave(props?.task.remoteSupportEventID, newTask);

            // planContext.setPlanTasks(planContext.planTasks.map(
            //     el => el.remoteSupportEventID ===  newTask.remoteSupportEventID? { ...el, ...newTask } : el
            // ));

            // props?.toggleEditMode();

            //planContext.setPlanTasks([...planContext.planTasks, newTask]);
            
        },
    });

    const handleRemoveEvent = () => {
        if(props?.editPlan){
            setShowConfirmDelete(true);
        } else {
            const index = planContext.planTasks.indexOf(props?.task);
            const newItems = [...planContext.planTasks.slice(0, index), ...planContext.planTasks.slice(index + 1)];
            planContext.setPlanTasks(newItems);

            props?.handleCancel();
        }

        //props?.toggleEditMode();
    }

    const deletePlanTask = async () => {
        setLoading(true);
        var result = await deleteRemoteSupportEvent(editPlanContext.servicePlan.servicePlanID, props?.task.remoteSupportEventID, appContext.handleAPICallError);
        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Remote Support Event successfully deleted.");
            appContext.setShowSuccessModal(true);
            const index = editPlanContext.planTasks.indexOf(props?.task);
            const newItems = [...editPlanContext.planTasks.slice(0, index), ...editPlanContext.planTasks.slice(index + 1)];
            editPlanContext.setPlanTasks(newItems);
        }
        
        setShowConfirmDelete(false);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    return (<>
        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Edit Task</span>
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.eventTitle && taskFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('eventTitle')}
                                                    />
                                                    {taskFormik.touched.eventTitle && taskFormik.errors.eventTitle && <p className="tw-text-red-500"><>{taskFormik.errors.eventTitle}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.eventDetails && taskFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('eventDetails')}
                                                    />
                                                    {taskFormik.touched.eventDetails && taskFormik.errors.eventDetails && <p className="tw-text-red-500"><>{taskFormik.errors.eventDetails}</></p>}
                                                </div>
                                            </div>
        </div>
                                    <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Time Of Day
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="time-of-day"
                                                    id="time-of-day"    
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.timeOfDay && taskFormik.errors.timeOfDay ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('timeOfDay')}
                                                    />
                                                    {taskFormik.touched.timeOfDay && taskFormik.errors.timeOfDay && <p className="tw-text-red-500"><>{taskFormik.errors.timeOfDay}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Pre Reminder(in mins)
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="number"
                                                    name="pre-reminder"
                                                    id="pre-reminder"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.preEventReminder && taskFormik.errors.preEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('preEventReminder')}
                                                    />
                                                    {taskFormik.touched.preEventReminder && taskFormik.errors.preEventReminder && <p className="tw-text-red-500"><>{taskFormik.errors.preEventReminder}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Overdue Reminder(in mins)
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="number"
                                                    name="overdue-reminder"
                                                    id="overdue-reminder"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.postEventReminder && taskFormik.errors.postEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('postEventReminder')}
                                                    />
                                                    {taskFormik.touched.postEventReminder && taskFormik.errors.postEventReminder && <p className="tw-text-red-500"><>{taskFormik.errors.postEventReminder}</></p>}
                                                </div>
                                            </div>
                                    </div>
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900 tw-pt-4">
                                        Repeat
                                    </label>
                                    <div className="tw-flex tw-p-1">
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsMonday', !taskFormik.values.repeatsMonday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsTuesday', !taskFormik.values.repeatsTuesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsWednesday', !taskFormik.values.repeatsWednesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsThursday', !taskFormik.values.repeatsThursday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsFriday', !taskFormik.values.repeatsFriday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsSaturday', !taskFormik.values.repeatsSaturday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsSunday', !taskFormik.values.repeatsSunday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                        </span>
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </> :<>
                                    
                                    
                                    {showConfirmDelete ? <>
                                    <p className="tw-text-primary">Are you sure you want to delete this task?</p>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deletePlanTask}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={handleRemoveEvent}>
                                                {props?.editPlan ? 'Delete' : 'Remove'}
                                            </ButtonSecondary>
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.onCancel}>
                                                {taskFormik.dirty ? 'Discard' : 'Cancel'}
                                            </ButtonSecondary>
                                            {taskFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={taskFormik.handleSubmit}>
                                                Update
                                            </ButtonPrimary>}

                                    </div>
                                    </>}
                                    </>}
    </>);
}

const EditRemoteSupportTaskCard = props => {

    return (<>
        {props?.isEditing ? <EditRemoteSupportTaskSection task={props.task} action={props.action} onEdit={props?.onEdit} onSave={props?.onSave} onCancel={props?.onCancel} editPlan={props?.editPlan} /> : <ViewRemoteSupportTaskSection task={props.task} onEdit={props?.onEdit} showEdit={true}/>}
    </>);
}

export const EditRemoteSupportTaskList = props => {
    const [editId, setEditId] = useState<string | null>(null);

    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const [showTaskCard, setShowTaskCard] = useState(false);
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, newTask: RemoteSupportEvent) {
        if(props?.editPlan) {
            var result = await updateRemoteSupportEvent(newTask, editPlanContext.servicePlan.servicePlanID, newTask.remoteSupportEventID, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Successfully updated remote support event.");
                appContext.setShowSuccessModal(true);
                editPlanContext.setPlanTasks(editPlanContext.planTasks.map(
                    el => el.remoteSupportEventID ===  newTask.remoteSupportEventID? { ...el, ...newTask } : el
                ));
            }
            
        } else {
            planContext.setPlanTasks(planContext.planTasks.map(
                el => el.remoteSupportEventID ===  newTask.remoteSupportEventID? { ...el, ...newTask } : el
            ));
        }

        

        setEditId(null);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    const RemoteSupportEventSchema = Yup.object().shape({
        eventTitle: Yup.string()
          .max(50, 'Event title must be at most 50 characters')
          .required('Event title is required'),
        eventDetails: Yup.string().required('Event details are required'),
        timeOfDay: Yup.string().required('Time of day is required')
            .matches(
            /^(0?[1-9]|1[0-2]):[0-5][0-9] ?(am|pm)$/i,
            'Time of Day must be in 12-hour format (hh:mm am/pm)'
            ),
        preEventReminder: Yup.number()
          .positive('Pre-event reminder must be at least 1 minute or greater')
          .required('Pre-event reminder is required'),
        postEventReminder: Yup.number()
          .positive('Post-event reminder must be at least 1 minute or greater')
          .required('Post-event reminder is required'),
      });

    const initialValues = {
        eventTitle: '',
        eventDetails: '',
        timeOfDay: '',
        repeatsMonday: false,
        repeatsTuesday: false,
        repeatsWednesday: false,
        repeatsThursday: false,
        repeatsFriday: false,
        repeatsSaturday: false,
        repeatsSunday: false,
        preEventReminder: 0,
        postEventReminder: 0,
      };

    const taskFormik = useFormik({
        initialValues,
        validationSchema: RemoteSupportEventSchema,
        onSubmit: async (values) => {
            console.log(values);
            var newTask: RemoteSupportEvent = {
                eventTitle: values.eventTitle,
                eventDetails: values.eventDetails,
                timeOfDay: values.timeOfDay,
                repeatsMonday: values.repeatsMonday,
                repeatsTuesday: values.repeatsTuesday,
                repeatsWednesday: values.repeatsWednesday,
                repeatsThursday: values.repeatsThursday,
                repeatsFriday: values.repeatsFriday,
                repeatsSaturday: values.repeatsSaturday,
                repeatsSunday: values.repeatsSunday,
                preEventReminder: values.preEventReminder,
                postEventReminder: values.postEventReminder,
                remoteSupportEventID: uuidv4(),
                servicePlanID: "",
                responsibleParty: ""
            };

            setLoading(true);

            var result = await addRemoteSupportEvent(newTask, editPlanContext.servicePlan.servicePlanID, appContext.handleAPICallError);
            await editPlanContext.updateLocalTasks();
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Successfully added remote support event.");
                appContext.setShowSuccessModal(true);
            }
            

            //planContext.setPlanTasks([...planContext.planTasks, newTask]);

            toggleTaskCard();


            
        },
    });

    const toggleTaskCard = () => {
        setShowTaskCard(!showTaskCard);
        setEditId(null);
        setLoading(false);
        taskFormik.resetForm();
    }

    return (<>
        {props?.editPlan && <>
            {showTaskCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                                    <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">New Task</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.eventTitle && taskFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('eventTitle')}
                                                    />
                                                    {taskFormik.touched.eventTitle && taskFormik.errors.eventTitle && <p className="tw-text-red-500">{taskFormik.errors.eventTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Description
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="description"
                                                    id="description"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.eventDetails && taskFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('eventDetails')}
                                                    />
                                                    {taskFormik.touched.eventDetails && taskFormik.errors.eventDetails && <p className="tw-text-red-500">{taskFormik.errors.eventDetails}</p>}
                                                </div>
                                            </div>
                                    </div>
                                    <div className="tw-grid tw-grid-cols-3 tw-gap-6 tw-mt-1">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Time Of Day
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="time-of-day"
                                                    id="time-of-day"    
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.timeOfDay && taskFormik.errors.timeOfDay ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('timeOfDay')}
                                                    />
                                                    {taskFormik.touched.timeOfDay && taskFormik.errors.timeOfDay && <p className="tw-text-red-500">{taskFormik.errors.timeOfDay}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Pre Reminder (in mins)
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="number"
                                                    name="pre-reminder"
                                                    id="pre-reminder"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.preEventReminder && taskFormik.errors.preEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('preEventReminder')}
                                                    />
                                                    {taskFormik.touched.preEventReminder && taskFormik.errors.preEventReminder && <p className="tw-text-red-500">{taskFormik.errors.preEventReminder}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Overdue Reminder (in mins)
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="number"
                                                    name="overdue-reminder"
                                                    id="overdue-reminder"
                                                    className={`tw-block tw-w-full tw-rounded-md ${taskFormik.touched.postEventReminder && taskFormik.errors.postEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...taskFormik.getFieldProps('postEventReminder')}
                                                    />
                                                    {taskFormik.touched.postEventReminder && taskFormik.errors.postEventReminder && <p className="tw-text-red-500">{taskFormik.errors.postEventReminder}</p>}
                                                </div>
                                            </div>
                                    </div>
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900 tw-pt-4">
                                        Repeat
                                    </label>
                                    <div className="tw-flex tw-p-1">
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsMonday', !taskFormik.values.repeatsMonday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsTuesday', !taskFormik.values.repeatsTuesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsWednesday', !taskFormik.values.repeatsWednesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsThursday', !taskFormik.values.repeatsThursday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsFriday', !taskFormik.values.repeatsFriday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsSaturday', !taskFormik.values.repeatsSaturday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${taskFormik.values.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => taskFormik.setFieldValue('repeatsSunday', !taskFormik.values.repeatsSunday)}>
                                            <span className={`tw-font-medium tw-leading-none ${taskFormik.values.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                        </span>
                                    </div>

                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleTaskCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={taskFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                    </>}
                                </div>
                            </>:<>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2 tw-mb-4">
                                        <div className="tw-flex tw-items-center">
                                                <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleTaskCard}>
                                                <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                                <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Task</span>
                                                </div>  
                                        </div>
                                </div>
                            </>}
        </>}
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
            {props?.tasks.length > 0 && props?.tasks.map((task, index) => (
                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow" key={task.remoteSupportEventID}>
                    <EditRemoteSupportTaskCard task={task} onEdit={handleEdit} onSave={handleSave} onCancel={handleCancel} isEditing={task.remoteSupportEventID === editId} editPlan={props?.editPlan}></EditRemoteSupportTaskCard>
                </div>
            ))}
        </div>
    </>);
}

export default EditRemoteSupportTaskCard;