import { Span } from "@medforall/medforall-component-library"
import AvatarImage from "./AvatarImage"

export const AvatarNameInlineList = props => {
	const Tag = props.tag || 'li';
	const attributes = {
		...props,
		className: null,
		name: null,
		size: null,
		space: null,
		top: null
	}

	return <>
		<ul className={`tw-inline-flex tw-items-center ${ props.reverse ? 'tw-flex-row-reverse' : '' }`}>
			{ [...Array(4)].map( ( user, index ) => {
				return <Tag className={`tw-inline-flex tw-items-center tw-z-dynamic ${index > 0 ? ` -tw-ml-6px` : null }`} key={`user-item_${index}`} style={{'--index': (index + 1) }}>
					<AvatarNameInline space="0" size={ props.size || 16 }>
						<span className="tw-hidden">Name</span>
					</AvatarNameInline>
				</Tag>
			})}
			{ props.children && <>
				<li className="tw-pl-5px tw-flex tw-items-center">
					{ props.children }
				</li>
			</> }
		</ul>
	</>
}

const AvatarNameInline = props => {
	const attributes = {
		...props,
		className: null,
		name: null,
		size: null,
		space: null,
		top: null
	}

	return <>
		<Span { ...attributes } className={`${ props.className } ${ props.space ? props.space : 'tw-space-x-8px' } tw-flex ${ props.top ? 'tw-items-start' : 'tw-items-center'}`}>
			<AvatarImage size={ props.size || null } image={ props.image || null } name={ props.name || "First, L" } />
			{ props.children || props.name && <Span className="tw-text-in">
				{ props.children || props.name }
			</Span> }
		</Span>
	</>
}

export default AvatarNameInline;
