import React, { useEffect, useLayoutEffect, useState } from 'react';
import { X, ChevronDown, ChevronUp } from 'lucide-react';
import { RemoteSupportReport } from '../../types/remoteSupportReport';
import { ButtonPrimary, ButtonSecondary } from '@medforall/medforall-component-library';
import { ButtonError } from '../../components/buttons/Buttons';
import { Textarea } from '../../components/forms/formFields';
import RemoteSupportSession from '../../types/remoteSupportSession';
import { useAppContext } from '../../Context';
import { approveRemoteSupportSession, getEmployeeByID, getRemoteSupportSessionDocumentationByID, rejectRemoteSupportSession, unlockRemoteSupportSession } from '../../apicalls/sessionService';
import { set } from 'lodash';
import { LoadingSpinner } from '../../components/portalComponents/loadingSpinner';
import { createChat, getGraphProfile, sendMessageInChat } from '../../apicalls/graphService';

import { ThumbsUp, ThumbsDown } from 'lucide-react';
import moment from 'moment';


interface SessionDetailsSideNavProps {
  session: RemoteSupportSession;
  onClose: () => void;
}

type FeedbackType = 'up' | 'down' | null;

interface SectionFeedback {
  notes: FeedbackType;
  events: FeedbackType;
  outcomeSteps: FeedbackType;
  services: FeedbackType;
}



const SessionDetailsSideNav: React.FC<SessionDetailsSideNavProps> = ({ session, onClose }) => {
  const [expandedReportId, setExpandedReportId] = useState<string | null>(null);

  const appContext = useAppContext();

  const [loadingReports, setLoadingReports] = useState(true);

  const [savingComment, setSavingComment] = useState(false);

  const [savingStatus, setSavingStatus] = useState(false);

  const [sessionReports, setSessionReports] = useState<RemoteSupportReport[]>([]);

  const [sessionDocumentationStatus, setSessionDocumentationStatus] = useState<string>(session.documentationStatus);

  const mapReports = (reports: RemoteSupportReport[]) => {
    if (!reports)
      return [];

    return reports.map((report) => {
      return {
        remoteSupportReportID: report.remoteSupportReportID,
        reportDate: new Date(report.reportDate),
        startTime: new Date(report.startTime),
        endTime: new Date(report.endTime),
        reportDescription: report.reportDescription,
        status: report.status,
        approvedByID: report.approvedByID,
        coverageEventID: report.coverageEventID,
        providerID: report.providerID,
        patientID: report.patientID,
        patientName: report.patientName,
        createdOn: new Date(report.createdOn),
        notes: report.notes?.map((note) => ({
          providerNotesID: note.providerNotesID,
          remoteSupportReportID: note.remoteSupportReportID,
          providerMail: note.providerMail,
          createdTime: new Date(note.createdTime),
          notes: note.notes,
          createdBy: note.createdBy,
          createdByDisplayName: note.createdByDisplayName,
        })),
        outcomeSteps: report.outcomeSteps,
        events: report.events?.map((event) => ({
          basicRSEventID: event.basicRSEventID,
          remoteSupportReportID: event.remoteSupportReportID,
          originalRSEventID: event.originalRSEventID,
          eventOccurred: event.eventOccurred,
          timeOfDay: event.timeOfDay,
          timeCompleted: new Date(event.timeCompleted),
          eventDetail: event.eventDetail,
          responsible: event.responsible,
          notes: event.notes,
          suggestedAction: event.suggestedAction,
        })),
        personalServices: report.personalServices,
      };
    });
  }

  useEffect(() => {
    console.log("Session Documentation Status: ", sessionDocumentationStatus);
  }, [sessionDocumentationStatus])

  const sendTeamsMessage = async () => {
    setSavingComment(true);

     // Get Current User Profile
     const currentUser = await getGraphProfile();

     const provider = await getEmployeeByID(session.providerID, appContext.handleAPICallError);

     // Get Chat
     const chat = {
       chatType: 'oneOnOne',
       members: [
         {
           '@odata.type': '#microsoft.graph.aadUserConversationMember',
           roles: ['owner'],
           'user@odata.bind': 'https://graph.microsoft.com/beta/users(\'' + currentUser.mail + '\')'
         },
        //  {
        //   '@odata.type': '#microsoft.graph.aadUserConversationMember',
        //   roles: ['owner'],
        //   'user@odata.bind': 'https://graph.microsoft.com/beta/users(\'chris.green@ohioathome.com\')'
        // }
         {
           '@odata.type': '#microsoft.graph.aadUserConversationMember',
           roles: ['owner'],
           'user@odata.bind': 'https://graph.microsoft.com/beta/users(\'' + provider.email + '\')'
         }
       ]
     };

     console.log(chat);

     const createdChat = await createChat(chat);

      // JavaScript object with template literals
      // const notification = {
      //   title: `${currentUser.displayName} has commented on your remote support documentation`,
      //   subtitle: `<h3>Session Details: ${session.displayStartDate}: ${session.displayStartTime} - ${session.displayEndTime}</h3>`,
      //   text: `Comment: ${comment}`
      // };

      const notification = {
        title: `Action needed: Remote Support Documentation`,
        subtitle: `<div style="display: flex; flex-direction: column;"><h3>Session Details: ${moment(session.displayStartDate).format('dddd, MMMM Do, YYYY')}</h3><h3>${session.displayStartTime} - ${session.displayEndTime}</h3></div>`,
        // text: ``,
        text: `<h2>Comment: ${comment}</h2>`
      };

      const jsonString = JSON.stringify(notification, null, 2);

        const chatMessage = {  
          "subject": null,  
          "body": {  
              "contentType": "html",  
              "content": "<attachment id=\"uniqueAttachmentId\"></attachment>"  
          },  
          "attachments": [  
              {  
                  "id": "uniqueAttachmentId",  
                  "contentType": "application/vnd.microsoft.card.thumbnail",  
                  "content": jsonString,  
                  "name": null,  
                  "thumbnailUrl": null  
              }  
          ]  
        }

        console.log(chatMessage);

        const sentMessage = await sendMessageInChat(createdChat.id, chatMessage);
  
        console.log(sentMessage);

        setSavingComment(false);

        toggleCommentField();


  }

  const [acknowledgedReports, setAcknowledgedReports] = useState<string[]>([]);

  useLayoutEffect(() => {
    const fetchSessionDocumentation = async () => {
      setLoadingReports(true);
      const result = await getRemoteSupportSessionDocumentationByID(session.remoteSupportSessionID, appContext.handleAPICallError);
      console.log(result);
      if (result) {
        setSessionReports(mapReports(result.reports));
        console.log(session.documentationStatus);
        if(session.documentationStatus === "Approved"){
          var reportIDs = result.reports.map((report) => report.remoteSupportReportID);
          setAcknowledgedReports(reportIDs);
          
        }
      }
      setLoadingReports(false);
    };
    fetchSessionDocumentation();
  }, [session]);

 
  const approveRemoteSupportSessionDocumentation = async () => {
    setSavingStatus(true);
    const result = await approveRemoteSupportSession(session.remoteSupportSessionID, appContext.handleAPICallError);
    
    const updatedSession = await getRemoteSupportSessionDocumentationByID(session.remoteSupportSessionID, appContext.handleAPICallError);
      console.log(updatedSession);
      if (updatedSession) {
        setSessionReports(mapReports(updatedSession.reports));
      }

      session.documentationStatus = updatedSession.documentationStatus;
      session.status = updatedSession.status;

      setSessionDocumentationStatus(updatedSession.documentationStatus);
    setSavingStatus(false);
  }

  const rejectRemoteSupportSessionDocumentation = async () => {
    setSavingStatus(true);
    const result = await rejectRemoteSupportSession(session.remoteSupportSessionID, appContext.handleAPICallError);
    
    const updatedSession = await getRemoteSupportSessionDocumentationByID(session.remoteSupportSessionID, appContext.handleAPICallError);
      console.log(updatedSession);
      if (updatedSession) {
        setSessionReports(mapReports(updatedSession.reports));
      }

      session.documentationStatus = updatedSession.documentationStatus;
      session.status = updatedSession.status;

      setSessionDocumentationStatus(updatedSession.documentationStatus);
    setSavingStatus(false);
  }

  const unlockRemoteSupportSessionDocumentation = async () => {
    setSavingStatus(true);
    const result = await unlockRemoteSupportSession(session.remoteSupportSessionID, appContext.handleAPICallError);
    console.log("Unlock Result: ", result);

    const updatedSession = await getRemoteSupportSessionDocumentationByID(session.remoteSupportSessionID, appContext.handleAPICallError);
      console.log(updatedSession);
      if (updatedSession) {
        setSessionReports(mapReports(updatedSession.reports));
      }

      session.documentationStatus = updatedSession.documentationStatus;
      session.status = updatedSession.status;

      setSessionDocumentationStatus(updatedSession.documentationStatus);
    setSavingStatus(false);
  }

  const toggleReportExpansion = (reportId: string) => {
    setExpandedReportId(expandedReportId === reportId ? null : reportId);
    acknowledgeReport(reportId);
  };

  const [isReportsSectionExpanded, setIsReportsSectionExpanded] = useState(true);

  const toggleReportsSection = () => {
    setIsReportsSectionExpanded(!isReportsSectionExpanded);
  };

  const [showCommentField, setShowCommentField] = useState(false);

  const [comment, setComment] = useState('');

  const toggleCommentField = () => {
        setComment('');
        setShowCommentField(!showCommentField);
 };

  // Sample patient names
  const patientNames = [
    "Alice Johnson", "Bob Smith", "Carol Williams", "David Brown", "Eve Davis",
    "Frank Miller", "Grace Wilson", "Henry Taylor", "Ivy Anderson", "Jack Thomas"
  ];

  const isReportAcknowledged = (reportId: string) => acknowledgedReports.includes(reportId);

  const acknowledgeReport = (reportId: string) => {
    if (!acknowledgedReports.includes(reportId)){
        setTimeout(() => {
            setAcknowledgedReports([...acknowledgedReports, reportId]);
        }, 2000);
        //setAcknowledgedReports([...acknowledgedReports, reportId]);
    }
    
  }

  //const [reportFeedback, setReportFeedback] = useState<{ [key: string]: 'up' | 'down' | null }>({});

  const [reportFeedback, setReportFeedback] = useState<{ [key: string]: SectionFeedback }>({});

  const handleFeedback = (reportId: string, section: keyof SectionFeedback, feedback: FeedbackType) => {
    setReportFeedback(prev => ({
      ...prev,
      [reportId]: {
        ...prev[reportId],
        [section]: prev[reportId]?.[section] === feedback ? null : feedback
      }
    }));
    // TODO: Implement additional feedback functionality
    console.log(`${feedback === 'up' ? 'Thumbs up' : 'Thumbs down'} for ${section} in report ${reportId}`);
  };

  const FeedbackButtons: React.FC<{ reportId: string; section: keyof SectionFeedback }> = ({ reportId, section }) => (
    <div className="tw-flex tw-space-x-2">
      <button
        onClick={() => handleFeedback(reportId, section, 'up')}
        className={`tw-focus:tw-outline-none ${
          reportFeedback[reportId]?.[section] === 'up' 
            ? 'tw-text-yellow-500' 
            : 'tw-text-gray-500 hover:tw-text-yellow-500'
        }`}
        aria-label={`Thumbs up for ${section}`}
      >
        <ThumbsUp
          className="tw-h-5 tw-w-5"
          fill={reportFeedback[reportId]?.[section] === 'up' ? 'currentColor' : 'none'}
        />
      </button>
      <button
        onClick={() => handleFeedback(reportId, section, 'down')}
        className={`tw-focus:tw-outline-none ${
          reportFeedback[reportId]?.[section] === 'down'
            ? 'tw-text-yellow-500'
            : 'tw-text-gray-500 hover:tw-text-yellow-500'
        }`}
        aria-label={`Thumbs down for ${section}`}
      >
        <ThumbsDown
          className="tw-h-5 tw-w-5"
          fill={reportFeedback[reportId]?.[section] === 'down' ? 'currentColor' : 'none'}
        />
      </button>
    </div>
  );
  

  useEffect(() => {
    console.log(showCommentField);
  }, [acknowledgedReports]);

  return (
    <div className="tw-bg-white tw-mt-4">
      <div className="tw-sticky tw-top-0 tw-px-4 tw-bg-white tw-pt-4 tw-border-b tw-border-gray-200 tw-z-10">
        <div className="tw-flex tw-justify-between tw-items-center">
          <h2 className="tw-text-lg tw-font-semibold">Session Details</h2>
          <button onClick={onClose} className="tw-text-gray-500 hover:tw-text-gray-700">
            <X className="tw-h-6 tw-w-6" />
          </button>
        </div>
        <div className="tw-mt-4">
          <p><strong>Provider:</strong> {session.providerName}</p>
          <p><strong>Start:</strong> {session.displayStartDate}, {session.displayStartTime}</p>
          <p><strong>End:</strong> {session.displayEndDate}, {session.displayEndTime}</p>
          {/* <p><strong>Status:</strong> {session.status}</p> */}
          <p><strong>Documentation Status:</strong> {sessionDocumentationStatus}</p>
        </div>

        {/* <h3 className="tw-text-md tw-font-semibold tw-my-2">Reports ({sampleReports.length})</h3> */}

        {!loadingReports && <div 
          className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-my-2"
          onClick={toggleReportsSection}
        >
          <h3 className="tw-text-md tw-font-semibold">Reports ({sessionReports?.length})</h3>
          {isReportsSectionExpanded ? <ChevronUp className="tw-h-5 tw-w-5" /> : <ChevronDown className="tw-h-5 tw-w-5" />}
        </div>}
      </div>

      {loadingReports ? <>
            <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                    <LoadingSpinner /> 
					<span className="tw-text-primary tw-text-5 tw-text-center">Loading Session Reports</span>
                </div>
        </>:<> 

      <div className="tw-p-4">
      {/* {!isReportsSectionExpanded && (
          <div className="tw-bg-white tw-p-3 tw-rounded-lg tw-shadow tw-mb-4">
            <p><strong>Total Reports:</strong> {summary.totalReports}</p>
            <p><strong>Completed:</strong> {summary.completedReports}</p>
            <p><strong>In Progress:</strong> {summary.inProgressReports}</p>
            <p><strong>Pending:</strong> {summary.pendingReports}</p>
          </div>
        )} */}

        {isReportsSectionExpanded && sessionReports.length === 0 && (
          <div className="tw-bg-white tw-p-3 tw-rounded-lg tw-shadow tw-mb-4">
            <p>No reports available for this session</p>
            </div>
            )}

        {isReportsSectionExpanded && sessionReports.map((report) => (
          <div key={report.remoteSupportReportID} className="tw-mb-4 tw-border tw-border-gray-200 tw-rounded-lg">
            <div 
              className={`tw-p-3 tw-flex tw-justify-between tw-items-center tw-cursor-pointer ${isReportAcknowledged(report.remoteSupportReportID) ? 'tw-bg-green-100' : 'tw-bg-yellow-100'}`}
              onClick={() => toggleReportExpansion(report.remoteSupportReportID)}
            >
              <span className="tw-font-medium tw-text-sm">
                {report.patientName}: {report.startTime.toLocaleTimeString()} - {report.endTime.toLocaleTimeString()}
              </span>
              {expandedReportId === report.remoteSupportReportID ? (
                <ChevronUp className="tw-h-5 tw-w-5" />
              ) : (
                <ChevronDown className="tw-h-5 tw-w-5" />
              )}
            </div>
            {expandedReportId === report.remoteSupportReportID && (
              <div className="tw-p-3">
                <p><strong>Date:</strong> {report.reportDate.toLocaleDateString()}</p>
                <p><strong>Status:</strong> {report.status}</p>
                <p><strong>Description:</strong> {report.reportDescription}</p>

                <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-4">
                <div className="tw-overflow-auto tw-max-h-48 tw-min-h-16 tw-border tw-border-gray-200 tw-p-2">
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
                <h4 className="tw-font-semibold tw-mb-2">Notes - {report.notes?.length}</h4>
                <FeedbackButtons reportId={report.remoteSupportReportID} section="notes" />
                </div>
                {report.notes.length === 0 && <p>No notes available</p>}
                {report.notes?.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()).map((note) => (
                  <div key={note.providerNotesID} className="tw-mb-2 tw-bg-gray-50 tw-p-2 tw-rounded">
                    <p className="tw-text-sm"><strong>{note.createdByDisplayName}:</strong> {note.notes}</p>
                    <p className="tw-text-xs tw-text-gray-500">{note.createdTime.toLocaleString()}</p>
                  </div>
                ))}
              </div>
              
              <div className="tw-overflow-auto tw-max-h-48 tw-min-h-16 tw-border tw-border-gray-200 tw-p-2">
              <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
                <h4 className="tw-font-semibold tw-mb-2">Events - {report.events?.length}</h4>
                <FeedbackButtons reportId={report.remoteSupportReportID} section="events" />
              </div>
                {report.events && report.events.length === 0 && <p>No events available</p>}
                {report.events?.map((event) => (
                  <div key={event.basicRSEventID} className="tw-mb-2 tw-bg-gray-50 tw-p-2 tw-rounded">
                    <p className="tw-font-medium">{event.eventDetail}</p>
                    <p className="tw-text-sm"><strong>Occurred:</strong> {event.eventOccurred ? 'Yes' : 'No'}</p>
                    {event.timeCompleted && <p className="tw-text-sm"><strong>Completed:</strong> {event.timeCompleted.toLocaleString()}</p>}
                  </div>
                ))}
              </div>
              
              <div className="tw-overflow-auto tw-max-h-48 tw-min-h-16 tw-border tw-border-gray-200 tw-p-2">
              <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
                <h4 className="tw-font-semibold tw-mb-2">Outcome Steps - {report.outcomeSteps?.length}</h4>
                <FeedbackButtons reportId={report.remoteSupportReportID} section="outcomeSteps" />
                </div>
                {report.outcomeSteps && report.outcomeSteps.length === 0 && <p>No outcome steps available</p>}
                {report.outcomeSteps?.map((step) => (
                  <div key={step.rsReportOutcomeStepID} className="tw-mb-2 tw-bg-gray-50 tw-p-2 tw-rounded">
                    <p className="tw-font-medium">{step.stepOverview}</p>
                    <p className="tw-text-sm">{step.stepDetails}</p>
                  </div>
                ))}
              </div>
              
              <div className="tw-overflow-auto tw-max-h-48 tw-min-h-16 tw-border tw-border-gray-200 tw-p-2">
              <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
                  <h4 className="tw-font-semibold">Personal Services - {report.personalServices?.length}</h4>
                  <FeedbackButtons reportId={report.remoteSupportReportID} section="services" />
                  </div>
                {report.personalServices && report.personalServices.length === 0 && <p>No personal services available</p>}
                {report.personalServices?.map((service) => (
                  <div key={service.personalServiceBasicID} className="tw-mb-2 tw-bg-gray-50 tw-p-2 tw-rounded">
                    <p className="tw-font-medium">{service.serviceTitle}</p>
                    <p className="tw-text-sm"><strong>Support Area:</strong> {service.supportAreaTitle}</p>
                    <p className="tw-text-sm"><strong>Completed By:</strong> {service.completedBy}</p>
                  </div>
                ))}
              </div>
            </div>
                
                {/* <h4 className="tw-font-semibold tw-mt-3 tw-mb-2">Notes</h4>
                {report.notes.length === 0 && <p>No notes available</p>}
               {report.notes?.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()).map((note) => (
                  <div key={note.providerNotesID} className="tw-mb-2 tw-bg-gray-50 tw-p-2 tw-rounded">
                    <p className="tw-text-sm"><strong>{note.createdByDisplayName}:</strong> {note.notes}</p>
                    <p className="tw-text-xs tw-text-gray-500">{note.createdTime.toLocaleString()}</p>
                  </div>
                ))}

                {report.outcomeSteps && report.outcomeSteps.length !== 0 && <h4 className="tw-font-semibold tw-mt-3 tw-mb-2">Outcome Steps</h4>}
                {report.outcomeSteps?.map((step) => (
                  <div key={step.rsReportOutcomeStepID} className="tw-mb-2 tw-bg-gray-50 tw-p-2 tw-rounded">
                    <p className="tw-font-medium">{step.stepOverview}</p>
                    <p className="tw-text-sm">{step.stepDetails}</p>
                  </div>
                ))}

                {report.events && report.events.length !== 0 && <h4 className="tw-font-semibold tw-mt-3 tw-mb-2">Events</h4>}
                {report.events?.map((event) => (
                  <div key={event.basicRSEventID} className="tw-mb-2 tw-bg-gray-50 tw-p-2 tw-rounded">
                    <p className="tw-font-medium">{event.eventDetail}</p>
                    <p className="tw-text-sm"><strong>Occurred:</strong> {event.eventOccurred ? 'Yes' : 'No'}</p>
                    {event.timeCompleted && <p className="tw-text-sm"><strong>Completed:</strong> {event.timeCompleted.toLocaleString()}</p>}
                  </div>
                ))}

                {report.personalServices && report.personalServices.length !== 0 && <h4 className="tw-font-semibold tw-mt-3 tw-mb-2">Personal Services</h4>}
                {report.personalServices?.map((service) => (
                  <div key={service.personalServiceBasicID} className="tw-mb-2 tw-bg-gray-50 tw-p-2 tw-rounded">
                    <p className="tw-font-medium">{service.serviceTitle}</p>
                    <p className="tw-text-sm"><strong>Support Area:</strong> {service.supportAreaTitle}</p>
                    <p className="tw-text-sm"><strong>Completed By:</strong> {service.completedBy}</p>
                  </div>
                ))} */}
              </div>
            )}
          </div>
        ))}
      </div>

      {!showCommentField && <div className="tw-p-4 tw-flex tw-justify-end">
        {savingStatus ? <>
          <LoadingSpinner />
        </>:<>
        <ButtonSecondary onClick={toggleCommentField}>Add Comment</ButtonSecondary>
        {(acknowledgedReports.length === sessionReports?.length) && sessionDocumentationStatus === "Pending Approval" && <ButtonPrimary className="tw-mx-4" onClick={approveRemoteSupportSessionDocumentation}>Approve</ButtonPrimary>}
        {(acknowledgedReports.length === sessionReports?.length) && sessionDocumentationStatus === "Pending Approval" && <ButtonError onClick={rejectRemoteSupportSessionDocumentation}>Reject</ButtonError>}
        {(sessionDocumentationStatus === "Approved" || sessionDocumentationStatus === "Rejected") && <ButtonPrimary className="tw-ml-4" onClick={unlockRemoteSupportSessionDocumentation}>Unlock</ButtonPrimary>}
        </>}
       </div>}

       {showCommentField && <div className="tw-p-4">
        <Textarea value={comment} className="tw-w-full" onChange={(e) => setComment(e.target.value)} placeholder="Enter your comment here" />
        <div className="tw-flex tw-justify-end tw-mt-4">
        {savingComment ? <>
              <LoadingSpinner />

            </>:<>
          <ButtonSecondary onClick={toggleCommentField}>Cancel</ButtonSecondary>
          <ButtonPrimary className="tw-ml-4" onClick={sendTeamsMessage}>Save Comment</ButtonPrimary>
          </>}
          </div>
          
          </div>}

          </>}
    </div>
  );
};

export default SessionDetailsSideNav;

