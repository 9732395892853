import { useState } from "react";
import { useAppContext } from "../../Context";
import { PatientContact } from "../../types/patient";
import { useFormik } from "formik";
import { evvServiceSchema } from "../../pages/NewClientForm";
import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { LoadingSpinner } from "./loadingSpinner";
import EVVService from "../../types/evvService";
import { addPatientEVVService, deletePatientEVVService, updatePatientEVVService } from "../../apicalls/clientMgmtService";

export const EditClientEVVServicesCard = props => {
    const [editId, setEditId] = useState<string | null>(null);

    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, updatedContact: PatientContact) {
        
        setEditId(null);
        props?.refreshClient();
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    function handleDelete(id: string) {
        setEditId(null);
    }

    const [showEVVServiceForm, setShowEVVServiceForm] = useState(false);

    const openEVVServiceForm = () => {
        evvInfoFormik.resetForm();
        setShowEVVServiceForm(true);
    }

    const closeEVVServiceForm = () => {
        setShowEVVServiceForm(false);
        evvInfoFormik.resetForm();
    }

    const evvInfoFormik = useFormik({
        initialValues: {
            serviceName: "",
            payer: "",
            payerProgram: "",
            procedureCode: "",
        },
        validationSchema: evvServiceSchema,
        onSubmit: async (values) => {
            // handle form submission
            console.log("EVV Form submitted")
            console.log(values);

            const newService : EVVService = {
                patientID: props.patientID,
                serviceID: "",
                serviceName: values.serviceName,
                payer: values.payer,
                payerProgram: values.payerProgram,
                procedureCode: values.procedureCode,
            }

            console.log(newService);

            setLoading(true);

            var result = await addPatientEVVService(props.patientID, newService, appContext.handleAPICallError);

            setLoading(false);

            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("New EVV Service Added Successfully");
                appContext.setShowSuccessModal(true);
            }

            props?.refreshClient();

        },
    });
    
    return (<>
        <div className="tw-pb-5 tw-flex tw-px-4">
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">EVV Services</span>
                {!showEVVServiceForm && <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={openEVVServiceForm}/> }
        </div>

        { showEVVServiceForm && 
                            <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-mb-4 tw-rounded-lg tw-mx-6 sm:tw-p-6">

                                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Service Name
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="serviceName"
                                            id="serviceName"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.serviceName && evvInfoFormik.errors.serviceName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('serviceName') }
                                            />
                                             { evvInfoFormik.touched.serviceName && evvInfoFormik.errors.serviceName && <div className="tw-text-red-500 tw-text-xs tw-mt-1">{ evvInfoFormik.errors.serviceName }</div> }
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Payer
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="payer"
                                            id="payer"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.payer && evvInfoFormik.errors.payer ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('payer') }
                                            />
                                            { evvInfoFormik.touched.payer && evvInfoFormik.errors.payer && <div className="tw-text-red-500 tw-text-xs tw-mt-1">{ evvInfoFormik.errors.payer }</div> }
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Payer Program
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="payerProgram"
                                            id="payerProgram"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.payerProgram && evvInfoFormik.errors.payerProgram ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('payerProgram') }
                                            />
                                            { evvInfoFormik.touched.payerProgram && evvInfoFormik.errors.payerProgram && <div className="tw-text-red-500 tw-text-xs tw-mt-1">{ evvInfoFormik.errors.payerProgram }</div> }
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Procedure Code
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="procedureCode"
                                            id="procedureCode"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.procedureCode && evvInfoFormik.errors.procedureCode ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('procedureCode') }
                                            />
                                            { evvInfoFormik.touched.procedureCode && evvInfoFormik.errors.procedureCode && <div className="tw-text-red-500 tw-text-xs tw-mt-1">{ evvInfoFormik.errors.procedureCode }</div> }
                                        </div>
                                    </div>
                                
                                </div>

                                {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                                <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={closeEVVServiceForm}>
                                                        Cancel
                                                </ButtonSecondary>
                                                <ButtonPrimary className="tw-mt-md" onClick={evvInfoFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>

                                </div>
                                </>}

                            </div>
                        }

        { props?.services?.length > 0 && <>
            {props?.services?.map((service, index) => {
                                    if(index !== props?.services?.length - 1) {
                                        return <>
                                            {service.serviceID === editId ? <EditEVVServiceCard service={service} handleSave={handleSave} handleCancel={handleCancel} /> : <ViewEVVServiceCard service={service} handleEdit={handleEdit} />}
                                            <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                        </>
                                    } else {
                                        return <>
                                            {service.serviceID === editId ? <EditEVVServiceCard service={service} handleSave={handleSave} handleCancel={handleCancel} /> : <ViewEVVServiceCard service={service} handleEdit={handleEdit} />}
                                        </>
                                    }
                                })}
        </>}

        

    </>);
}

const ViewEVVServiceCard = props => {
    return (<>
        <div className="tw-bg-grey-light tw-flex tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
        <div className="tw-w-4/5 tw-flex-grow">
        <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Service Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.service.serviceName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Payer</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.service.payer}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Payer Program</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.service.payerProgram}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Procedure Code</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.service.procedureCode}</span>
                                            </div>

        </div>
        <div className="tw-w-1/5 tw-flex tw-justify-end tw-items-center">
                    <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={() => props?.handleEdit(props?.service.serviceID)} />
        </div>

                                            
                                        </div>
    </>);
}

const EditEVVServiceCard = props => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const appContext = useAppContext();
    
    const initialValues = {
        serviceName: props?.service.serviceName,
        payer: props?.service.payer,
        payerProgram: props?.service.payerProgram,
        procedureCode: props?.service.procedureCode,
    }
    
    const evvInfoFormik = useFormik({
        initialValues: initialValues,
        validationSchema: evvServiceSchema,
        onSubmit: async (values) => {
            // handle form submission
            console.log("EVV Form submitted")
            console.log(values);

            const updatedService : EVVService = {
                patientID: props.service.patientID,
                serviceID: props.service.serviceID,
                serviceName: values.serviceName,
                payer: values.payer,
                payerProgram: values.payerProgram,
                procedureCode: values.procedureCode,
            }

            setLoading(true);
            const response = await updatePatientEVVService(props.service.patientID ,updatedService, appContext.handleAPICallError);
            setLoading(false);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("EVV Service Updated Successfully");
                appContext.setShowSuccessModal(true);
            }

            props.handleSave();
            

        },
        enableReinitialize: true,
    });

    const handleDeleteService = () => {
        setShowConfirmDelete(true);
    }

    const deleteService = async () => {
        setLoading(true);
        var result = await deletePatientEVVService(props.service.patientID, props.service.serviceID, appContext.handleAPICallError);
        setLoading(false);
        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("EVV Service Deleted Successfully");
            appContext.setShowSuccessModal(true);
        }
        props.handleSave();
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }
    
    return (<>
        <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 tw-mb-4 sm:tw-p-6">
                <div className="tw-pb-5 tw-flex tw-px-4">
                    <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit EVV Service</span>
                </div>

                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Service Name
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="serviceName"
                                            id="serviceName"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.serviceName && evvInfoFormik.errors.serviceName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('serviceName') }
                                            />
                                            { evvInfoFormik.touched.serviceName && evvInfoFormik.errors.serviceName && <div className="tw-text-red-500 tw-text-xs tw-mt-1"><>{ evvInfoFormik.errors.serviceName }</></div> }
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Payer
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="payer"
                                            id="payer"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.payer && evvInfoFormik.errors.payer ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('payer') }
                                            />
                                            { evvInfoFormik.touched.payer && evvInfoFormik.errors.payer && <div className="tw-text-red-500 tw-text-xs tw-mt-1"><>{ evvInfoFormik.errors.payer }</></div> }
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Payer Program
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="payerProgram"
                                            id="payerProgram"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.payerProgram && evvInfoFormik.errors.payerProgram ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('payerProgram') }
                                            />
                                            { evvInfoFormik.touched.payerProgram && evvInfoFormik.errors.payerProgram && <div className="tw-text-red-500 tw-text-xs tw-mt-1"><>{ evvInfoFormik.errors.payerProgram }</></div> }
                                        </div>
                                    </div>
                                    <div className="tw-col-span-1">
                                        <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                            Procedure Code
                                        </label>
                                        <div className="tw-mt-2">
                                            <input
                                            type="text"
                                            name="procedureCode"
                                            id="procedureCode"
                                            className={`tw-block tw-w-full tw-rounded-md ${evvInfoFormik.touched.procedureCode && evvInfoFormik.errors.procedureCode ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                            { ...evvInfoFormik.getFieldProps('procedureCode') }
                                            />
                                            { evvInfoFormik.touched.procedureCode && evvInfoFormik.errors.procedureCode && <div className="tw-text-red-500 tw-text-xs tw-mt-1"><>{ evvInfoFormik.errors.procedureCode }</></div> }
                                        </div>
                                    </div>
                                
                </div>

                {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                {showConfirmDelete ? <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                        <p className="tw-text-primary">Are you sure you want to delete this EVV service?</p>
                                    </div>
                                    
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deleteService}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={handleDeleteService}>
                                            Delete
                                        </ButtonSecondary>
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.handleCancel}>
                                            {(evvInfoFormik.dirty) ? 'Discard' : 'Cancel'}
                                        </ButtonSecondary>
                                        {evvInfoFormik.dirty && <ButtonPrimary className="tw-mt-md" type="submit" onClick={evvInfoFormik.handleSubmit}>
                                            Save
                                        </ButtonPrimary>}

                </div>
                </>}

                </>}


        </div>
    </>);
}