import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select } from "../../components/forms/formFields";
import RichTextField from "../../components/forms/richTextField";
import CalendarSelector from "../../components/calendars/CalendarSelector";
import { ButtonPrimary, ButtonSecondary } from "../../components/buttons/Buttons";
import ConfirmDialog from "../../components/dialogs/confirmDialog";
import SuccessDialog from "../../components/dialogs/successDialog";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { GenericBreadCrumbs } from "../Scheduling/SchedulingUtils";
import { useAppContext } from "../../Context";
import Patient from "../../types/patient";
import { ClientAnnouncement } from "../../types/clientAnnouncement";
import { addNewClientAnnouncement, escapeSingleQuotes } from "../../apicalls/announcementService";
import { getActiveClients } from "../../apicalls/schedulingService";
import ReadOnlyText from "../../components/forms/readOnlyRichTextField";

const AddClientAnnouncementPage = props => {
    let navigate = useNavigate();

    const navContext = useAppContext();

    const [savingAnnouncement, setSavingAnnouncement] = useState(false);

    const [showPreview, setShowPreview] = useState(false);

    const announcementImpactRef = useRef();
    const clientRef = useRef();

    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const [activePatients, setActivePatients] = useState<Patient[]>([]);
    const [clientsLoaded, setClientsLoaded] = useState(false);

    const [currentClient, setCurrentClient] = useState<String>("");

    const dismissSuccessDialog = () => {
        setShowSuccessDialog(false);
        navigate(-1);
    };

    useEffect(() => {
		const fetchClients = async () => {
			const data = await getActiveClients(navContext.handleAPICallError);
			console.log(data);
			const activePatients = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName));
			setActivePatients(activePatients);
			setClientsLoaded(true);
		}

		fetchClients()
    		.catch(console.error);
	}, []);

    const showAnnouncementPreview = async () => {
        const valid = validateForm();

        if(valid) {
            setShowPreview(true);
        }
    }

    const backToEdit = () => {
        setShowPreview(false);
    }

    const saveClientAnnouncement = async () => {
        console.log("Save Client Announcement");

        const valid = validateForm();

        if(valid) {
            setSavingAnnouncement(true);

            var selectedClient = activePatients.find(client => client.patientID === formValues.client);

            // Save the announcement
            const newAnnouncement: ClientAnnouncement = {
                clientAnnouncementID: "",
                clientID: formValues.client,
                clientName: selectedClient.firstName + " " + selectedClient.lastName,
                announcementTitle: escapeSingleQuotes(formValues.announcementTitle),
                announcementDescription: escapeSingleQuotes(formValues.announcementDescription),
                announcementImpactLevel: formValues.announcementImpactLevel,
                dateCreated: undefined,
                createdBy: "",
                dateOfExpiry: moment(formValues.dateOfExpiry, "M-D-YYYY").toDate(),
                status: "Draft",
                startDate: moment(formValues.startDate, "M-D-YYYY").toDate(),
                publishedDate: undefined,
                publishedBy: "",
                archiveDate: undefined,
                archivedBy: ""
            }

            const result = await addNewClientAnnouncement(newAnnouncement, navContext.handleAPICallError);


            setShowSuccessDialog(true);

            setSavingAnnouncement(false);
        }
    }

    const confirmCancelAddAnnouncement = () => {
        setShowCancelDialog(true);
    }

    const cancelAddAnnouncement = () => {
        setShowCancelDialog(false);
        navigate(-1);
    }

    const dismissCancelDialog = () => {
        setShowCancelDialog(false);
    }

    const handleRichTextFieldChange = (value: string) => {
        console.log(value);

        setFormValues({...formValues, announcementDescription: value});

        validateAnnouncementDescription(value);
    }

    const [formValues, setFormValues] = useState({
        client: '',
        announcementTitle: '',
        announcementDescription: '',
        announcementImpactLevel: '',
        dateOfExpiry: moment().add(14, 'days').format('M-D-YYYY'),
        startDate: moment().format('M-D-YYYY')
    });

    const [formErrors, setFormErrors] = useState({
        clientError: false,
        clientErrorMessage: '',
        announcementTitleError: false,
        announcementTitleErrorMessage: '',
        announcementDescriptionError: false,
        announcementDescriptionErrorMessage: '',
        announcementImpactError: false,
        announcementImpactErrorMessage: '',
        dateOfExpiryError: false,
        dateOfExpiryErrorMessage: '',
        startDateError: false,
        startDateErrorMessage: ''
    });

    useEffect(() => {
        if(formValues.client !== '') {
            var selectedClient = activePatients.find(client => client.patientID === formValues.client);
            setCurrentClient(selectedClient.firstName + " " + selectedClient.lastName);
        } else {
            setCurrentClient("");
        }
    }, [formValues])

    const validateClient = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, clientError: true, clientErrorMessage: 'Client is required'});
            setCurrentClient("");
        } else {
            
            setFormErrors({...formErrors, clientError: false, clientErrorMessage: ''});
        }
    }

    const validateAnnouncementTitle = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, announcementTitleError: true, announcementTitleErrorMessage: 'Announcement Title is required'});
        } else {
            setFormErrors({...formErrors, announcementTitleError: false, announcementTitleErrorMessage: ''});
        }
    }

    const validateAnnouncementDescription = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, announcementDescriptionError: true, announcementDescriptionErrorMessage: 'Announcement Description is required'});
        } else {
            setFormErrors({...formErrors, announcementDescriptionError: false, announcementDescriptionErrorMessage: ''});
        }
    }

    const validateAnnouncementImpact = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, announcementImpactError: true, announcementImpactErrorMessage: 'Announcement Impact is required'});
        } else {
            setFormErrors({...formErrors, announcementImpactError: false, announcementImpactErrorMessage: ''});
        }
    }

    const validateDateOfExpiry = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, dateOfExpiryError: true, dateOfExpiryErrorMessage: 'Date of Expiry is required'});
        } else if (moment(value, "M-D-YYYY").isSameOrBefore(moment())) {
            setFormErrors({...formErrors, dateOfExpiryError: true, dateOfExpiryErrorMessage: 'Date of Expiry must be in the future'});
        } else {
            setFormErrors({...formErrors, dateOfExpiryError: false, dateOfExpiryErrorMessage: ''});
        }
    }

    const validateStartDate = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, startDateError: true, startDateErrorMessage: 'Start Date is required'});
        } else if (moment(value, "M-D-YYYY").isBefore(moment().startOf('day'))) {
            setFormErrors({...formErrors, startDateError: true, startDateErrorMessage: 'Start Date cannot be in the past'});
        } else {
            setFormErrors({...formErrors, startDateError: false, startDateErrorMessage: ''});

            setFormValues({...formValues, dateOfExpiry: moment(value, "M-D-YYYY").add(14, 'days').format('M-D-YYYY')});
        }
    }

    const validateForm = () => {
        let valid = true;

        var currentErrors = {...formErrors};

        if(formValues.client === '') {
            currentErrors.clientError = true;
            currentErrors.clientErrorMessage = 'Client is required';
            valid = false;
        } else {
            currentErrors.clientError = false;
            currentErrors.clientErrorMessage = '';
        }

        if(formValues.announcementTitle === '') {
            currentErrors.announcementTitleError = true;
            currentErrors.announcementTitleErrorMessage = 'Announcement Title is required';
            valid = false;
        } else {
            currentErrors.announcementTitleError = false;
            currentErrors.announcementTitleErrorMessage = '';
        }

        if(formValues.announcementDescription === '') {
            currentErrors.announcementDescriptionError = true;
            currentErrors.announcementDescriptionErrorMessage = 'Announcement Description is required';
            valid = false;
        } else {
            currentErrors.announcementDescriptionError = false;
            currentErrors.announcementDescriptionErrorMessage = '';
        }

        if(formValues.announcementImpactLevel === '') {
            currentErrors.announcementImpactError = true;
            currentErrors.announcementImpactErrorMessage = 'Announcement Impact is required';
            valid = false;
        } else {
            currentErrors.announcementImpactError = false;
            currentErrors.announcementImpactErrorMessage = '';
        }

        if(formValues.startDate === '') {
            currentErrors.startDateError = true;
            currentErrors.startDateErrorMessage = 'Start Date is required';
            valid = false;
        } else if (moment(formValues.startDate, "M-D-YYYY").isBefore(moment().startOf('day'))) {
            currentErrors.startDateError = true;
            currentErrors.startDateErrorMessage = 'Start Date cannot be in the past';
            valid = false;
        } else {
            currentErrors.startDateError = false;
            currentErrors.startDateErrorMessage = '';
        }

        if(formValues.dateOfExpiry === '') {
            currentErrors.dateOfExpiryError = true;
            currentErrors.dateOfExpiryErrorMessage = 'Date of Expiry is required';
            valid = false;
        } else if (moment(formValues.dateOfExpiry, "M-D-YYYY").isSameOrBefore(moment())) {
            currentErrors.dateOfExpiryError = true;
            currentErrors.dateOfExpiryErrorMessage = 'Date of Expiry must be in the future';
            valid = false;
        } else {
            currentErrors.dateOfExpiryError = false;
            currentErrors.dateOfExpiryErrorMessage = '';
        }

        setFormErrors(currentErrors);

        return valid;
    }

    
    
    return (<>
        <SuccessDialog  title="Announcement Added" message="The client announcement has been added successfully." onClose={dismissSuccessDialog} closeButtonText="Back to announcements" open={showSuccessDialog}/>
        <ConfirmDialog title="Cancel Add Announcement" message="Are you sure you want to cancel adding the announcement?" onConfirm={cancelAddAnnouncement} confirmButtonText={"Yes"} cancelButtonText="No" onCancel={dismissCancelDialog} showDialog={showCancelDialog}/>
        <div className="tw-px-xl tw-pt-xl">
            <div className="tw-flex tw-flex tw-flex-col tw-space-y-20px tw-text-dark">
                    <div className="tw-space-y-20px tw-pb-2 tw-border-b tw-border-gray-800">
					<GenericBreadCrumbs 
                    mainHeading={"Client Announcements"}
                    subHeading={"Add"}
                    backURL={"/announcement/clients"}
					/>
			        </div>
            </div>

            {showPreview ? <>
                <div className="tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                <div className="tw-flex tw-flex-col tw-space-y-1">
                        {/* <label className="tw-text-gray-700">Announcement Title</label> */}
                        <h4 className="tw-text-gray-900">Client: {currentClient}</h4>
                    </div>
                    <div className="tw-flex tw-flex-col tw-space-y-1">
                        {/* <label className="tw-text-gray-700">Announcement Title</label> */}
                        <h4 className="tw-text-gray-900">Title: {formValues.announcementTitle}</h4>
                    </div>
                    <div className="tw-flex tw-flex-col tw-space-y-1">
                        {/* <label className="tw-text-gray-700">Announcement Title</label> */}
                        <ReadOnlyText content={formValues.announcementDescription} />
                    </div>

                    <div className="tw-flex tw-justify-end tw-mx-4">
                        {savingAnnouncement ? <>
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </>:<>
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={backToEdit}>
                                            Back
                                        </ButtonSecondary>
                                        <ButtonPrimary className="tw-mt-md" type="submit" onClick={saveClientAnnouncement}>
                                            Save
                                        </ButtonPrimary>
                        </>}

                    </div>
                </div>
            </>:

            <div className="tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">   
                    <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/2">
                        <label className="tw-text-gray-700">Client</label>
                        <Select id="announcement-client" className={formErrors.clientError ? '_form-error': ''} 
                            onChange={ e => {
                                console.log(e.target.value);
                                setFormValues({...formValues, client: e.target.value});
                                validateClient(e.target.value);
                            }} ref={ clientRef } value={ formValues.client }>
                            { clientsLoaded ? <>
								<option value="">--Select Client--</option>
								{
									activePatients.map(client =>
										<option value={client.patientID}>{client.firstName} {client.lastName}</option>)
								}
							</>:<>
								<option value="">--Loading--</option>
							</>}
                        </Select>
                        {formErrors.clientError && <p className="tw-text-red-500">{formErrors.clientErrorMessage}</p>}
                    </div>

                    <div className="tw-flex tw-flex-col tw-space-y-1">
                        <label className="tw-text-gray-700">Announcement Title</label>
                        <input
                            type="text"
                            min={0}
                            value={formValues.announcementTitle}
                            onChange={e => {
                                setFormValues({...formValues, announcementTitle: e.target.value});
                                validateAnnouncementTitle(e.target.value);
                            }}
                            className={`tw-px-3 tw-py-2 tw-border tw-bg-white ${formErrors.announcementTitleError ? "tw-border-red-500": "tw-border-primary"} tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-border-blue-300`}
                            />
                        {formErrors.announcementTitleError && <p className="tw-text-red-500">{formErrors.announcementTitleErrorMessage}</p>}
                    </div>

                    <RichTextField label="Announcement Description" value={formValues.announcementDescription} onChange={handleRichTextFieldChange} showValidation={formErrors.announcementDescriptionError} validationMessage={formErrors.announcementDescriptionErrorMessage}/>

                    <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/4 sm:tw-w-1/2">
                        <label className="tw-text-gray-700">Impact Level</label>
                        <Select id="announcement-type" className={formErrors.announcementImpactError ? '_form-error': ''} 
                            onChange={ e => {
                                console.log(e.target.value);
                                setFormValues({...formValues, announcementImpactLevel: e.target.value});
                                validateAnnouncementImpact(e.target.value);
                            }} ref={ announcementImpactRef } value={ formValues.announcementImpactLevel }>
                            <option value="">Select</option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                            <option value="Critical">Critical</option>
                        </Select>
                        {formErrors.announcementImpactError && <p className="tw-text-red-500">{formErrors.announcementImpactErrorMessage}</p>}
                    </div>

                    <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/4 sm:tw-w-1/2">
                        <CalendarSelector id="dateOfExpiry" className={formErrors.startDateError ? '_form-error' : ''} 
                             name="Start Date:" value={ formValues.startDate || null } 
                             onChange={ e => {
                                console.log(e.target.value);
                                setFormValues({...formValues, startDate: e.target.value});
                                validateStartDate(e.target.value);
                            }}/>
                            {formErrors.startDateError && <p className="tw-text-red-500">{formErrors.startDateErrorMessage}</p>}
                    </div>

                    <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/4 sm:tw-w-1/2">
                        <CalendarSelector id="dateOfExpiry" className={formErrors.dateOfExpiryError ? '_form-error' : ''} 
                             name="Date Of Expiry:" value={ formValues.dateOfExpiry || null } 
                             onChange={ e => {
                                console.log(e.target.value);
                                setFormValues({...formValues, dateOfExpiry: e.target.value});
                                validateDateOfExpiry(e.target.value);
                            }}/>
                            {formErrors.dateOfExpiryError && <p className="tw-text-red-500">{formErrors.dateOfExpiryErrorMessage}</p>}
                    </div>

                    <div className="tw-flex tw-justify-end tw-mx-4 tw-pb-4">

                        {savingAnnouncement ? <>
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </>:<>
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={confirmCancelAddAnnouncement}>
                                                Cancel
                                        </ButtonSecondary>
                                        <ButtonPrimary className="tw-mt-md" type="submit" onClick={showAnnouncementPreview}>
                                            Save
                                        </ButtonPrimary>
                            </>}

                    </div>
                            
            </div>}
        </div>
    </>);
}

export default AddClientAnnouncementPage;