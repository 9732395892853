import React, { useState, useEffect } from 'react';
import { Calendar, ChevronDown, ChevronUp } from 'lucide-react';
import { ButtonPrimary } from '@medforall/medforall-component-library';
import { RemoteSupportReport } from '../../types/remoteSupportReport';
import CalendarSelector from '../../components/calendars/CalendarSelector';
import { Select } from '../../components/forms/formFields';

const ClientReportDashboard: React.FC = () => {
  const [client, setClient] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [reports, setReports] = useState<RemoteSupportReport[]>([]);
  const [expandedReportId, setExpandedReportId] = useState<string | null>(null);

  // Mock client list
  const clients = ['Alice Johnson', 'Bob Smith', 'Carol Williams', 'David Brown'];

  const CompactStatItem: React.FC<{ label: string; value: string | number; color: string }> = ({ label, value, color }) => (
    <div className={`tw-text-center tw-p-2 tw-rounded-lg ${color}`}>
      <p className="tw-text-sm tw-font-semibold">{label}</p>
      <p className="tw-text-lg">{value}</p>
    </div>
  );

  const [showStats, setShowStats] = useState<boolean>(true);

  const toggleStats = () => {
    setShowStats(!showStats);
  };

  // Mock data for reports
  const mockReports: RemoteSupportReport[] = [
    {
      remoteSupportReportID: '1',
      reportDate: new Date('2024-08-01'),
      startTime: new Date('2024-08-01T09:00:00'),
      endTime: new Date('2024-08-01T11:00:00'),
      reportDescription: 'Morning support session',
      status: 'Completed',
      coverageEventID: 'CE001',
      providerID: 'P001',
      providerName: 'John Doe', // Added provider name
      patientID: 'PAT001',
      patientName: 'Alice Johnson',
      createdOn: new Date('2024-08-01T08:45:00'),
      notes: [
        {
          providerNotesID: 'N001',
          remoteSupportReportID: '1',
          providerMail: 'provider@example.com',
          createdTime: new Date('2024-08-01T11:15:00'),
          notes: 'Client was engaged and responsive during the session.',
          createdBy: 'P001',
          createdByDisplayName: 'John Doe',
        },
      ],
      outcomeSteps: [
        {
          rsReportOutcomeStepID: 'OS001',
          remoteSupportReportID: '1',
          stepOverview: 'Medication Management',
          stepDetails: 'Assisted client in organizing daily medication.',
          notes: 'Client showed improved understanding of medication schedule.',
          completionTime: new Date('2024-08-01T10:30:00'),
          desiredOutcomeID: 'DO001',
          originalOutcomeStepID: 'OOS001',
          completedBy: 'P001',
        },
      ],
      events: [
        {
          basicRSEventID: 'E001',
          remoteSupportReportID: '1',
          originalRSEventID: 'ORE001',
          eventOccurred: true,
          timeOfDay: 3600000, // 1 hour in milliseconds
          timeCompleted: new Date('2024-08-01T10:00:00'),
          eventDetail: 'Morning walk completed',
          responsible: 'John Doe',
          notes: 'Client enjoyed a 15-minute walk in the garden.',
          suggestedAction: 'Continue daily morning walks',
        },
      ],
      personalServices: [
        {
          personalServiceBasicID: 'PS001',
          remoteSupportReportID: '1',
          serviceTitle: 'Grooming Assistance',
          supportAreaTitle: 'Personal Care',
          notes: 'Assisted client with morning grooming routine.',
          completionTime: new Date('2024-08-01T09:30:00'),
          originalServiceID: 'OPS001',
          completedBy: 'P001',
        },
      ],
    },
    {
      remoteSupportReportID: '2',
      reportDate: new Date('2024-08-02'),
      startTime: new Date('2024-08-02T14:00:00'),
      endTime: new Date('2024-08-02T16:00:00'),
      reportDescription: 'Afternoon support session',
      status: 'Completed',
      coverageEventID: 'CE002',
      providerID: 'P002',
      providerName: 'Jane Smith', // Added provider name
      patientID: 'PAT001',
      patientName: 'Alice Johnson',
      createdOn: new Date('2024-08-02T13:45:00'),
      notes: [
        {
          providerNotesID: 'N002',
          remoteSupportReportID: '2',
          providerMail: 'provider2@example.com',
          createdTime: new Date('2024-08-02T16:15:00'),
          notes: 'Client participated in cognitive exercises with enthusiasm.',
          createdBy: 'P002',
          createdByDisplayName: 'Jane Smith',
        },
      ],
      outcomeSteps: [
        {
          rsReportOutcomeStepID: 'OS002',
          remoteSupportReportID: '2',
          stepOverview: 'Cognitive Stimulation',
          stepDetails: 'Completed puzzle-solving exercises with client.',
          notes: 'Client showed improvement in problem-solving skills.',
          completionTime: new Date('2024-08-02T15:30:00'),
          desiredOutcomeID: 'DO002',
          originalOutcomeStepID: 'OOS002',
          completedBy: 'P002',
        },
      ],
      events: [
        {
          basicRSEventID: 'E002',
          remoteSupportReportID: '2',
          originalRSEventID: 'ORE002',
          eventOccurred: true,
          timeOfDay: 7200000, // 2 hours in milliseconds
          timeCompleted: new Date('2024-08-02T15:00:00'),
          eventDetail: 'Afternoon tea and socializing',
          responsible: 'Jane Smith',
          notes: 'Client enjoyed tea and conversation with caregiver.',
          suggestedAction: 'Continue regular social interactions',
        },
      ],
      personalServices: [
        {
          personalServiceBasicID: 'PS002',
          remoteSupportReportID: '2',
          serviceTitle: 'Meal Preparation',
          supportAreaTitle: 'Nutrition',
          notes: 'Prepared a healthy afternoon snack with clients assistance.',
          completionTime: new Date('2024-08-02T14:30:00'),
          originalServiceID: 'OPS002',
          completedBy: 'P002',
        },
      ],
    },
  ];

  // Mock function to fetch reports
  const fetchReports = (clientName: string, start: string, end: string) => {
    // In a real application, this would be an API call
    // For now, we're just setting the mock data
    setReports(mockReports);
  };

  const handleFilter = () => {
    if (client && startDate && endDate) {
      fetchReports(client, startDate, endDate);
    }
  };

  const toggleReportExpansion = (reportId: string) => {
    setExpandedReportId(expandedReportId === reportId ? null : reportId);
  };

  // Calculate summary statistics
  const calculateSummary = () => {
    const totalHours = reports.reduce((sum, report) => 
      sum + (report.endTime.getTime() - report.startTime.getTime()) / (1000 * 60 * 60), 0);
    const days = new Set(reports.map(r => r.reportDate.toDateString())).size;
    const avgHoursPerDay = days > 0 ? totalHours / days : 0;
    const tasksCompleted = reports.flatMap(r => r.events).filter(e => e.eventOccurred).length;
    const servicesCompleted = reports.flatMap(r => r.personalServices).length;
    const outcomesCompleted = reports.flatMap(r => r.outcomeSteps).length;

    return {
      totalHours: totalHours.toFixed(2),
      avgHoursPerDay: avgHoursPerDay.toFixed(2),
      tasksCompleted,
      servicesCompleted,
      outcomesCompleted,
      totalReports: reports.length
    };
  };

  const summary = calculateSummary();

  return (
    <div className="tw-p-4">
      {/* <h1 className="tw-text-2xl tw-font-bold tw-mb-4">Client Report Review Dashboard</h1> */}
      
      <div className="tw-flex tw-space-x-4 tw-mb-4">
        <Select
          value={client}
          onChange={(e) => setClient(e.target.value)}
          className="tw-my-auto"
        >
          <option value="">Select Client</option>
          {clients.map(c => <option key={c} value={c}>{c}</option>)}
        </Select>
        <CalendarSelector id="startDate" name="Start Date:" onChange={(e) => setStartDate(e.target.value)} />
        <CalendarSelector id="endDate" name="End Date:" onChange={(e) => setEndDate(e.target.value)} />
        <ButtonPrimary onClick={handleFilter} className="tw-flex tw-items-center tw-my-auto">
          <Calendar className="tw-mr-2 tw-h-4 tw-w-4" /> Filter
        </ButtonPrimary>
      </div>

      {reports.length > 0 && (
        <>
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
            <h2 className="tw-text-xl tw-font-bold">Summary</h2>
            <button
              onClick={toggleStats}
              className="tw-text-indigo-600 hover:tw-text-indigo-900 tw-flex tw-items-center"
            >
              {showStats ? 'Hide Stats' : 'Show Stats'}
              {showStats ? <ChevronUp className="tw-h-5 tw-w-5 tw-ml-1" /> : <ChevronDown className="tw-h-5 tw-w-5 tw-ml-1" />}
            </button>
          </div>

          {showStats ? (
            <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-mb-6">
              <div className="tw-bg-blue-100 tw-p-4 tw-rounded-lg">
                <h3 className="tw-font-semibold">Total Hours of Service</h3>
                <p className="tw-text-2xl">{summary.totalHours}</p>
              </div>
              <div className="tw-bg-green-100 tw-p-4 tw-rounded-lg">
                <h3 className="tw-font-semibold">Average Hours per Day</h3>
                <p className="tw-text-2xl">{summary.avgHoursPerDay}</p>
              </div>
              <div className="tw-bg-yellow-100 tw-p-4 tw-rounded-lg">
                <h3 className="tw-font-semibold">Tasks Completed</h3>
                <p className="tw-text-2xl">{summary.tasksCompleted}</p>
              </div>
              <div className="tw-bg-purple-100 tw-p-4 tw-rounded-lg">
                <h3 className="tw-font-semibold">Services Provided</h3>
                <p className="tw-text-2xl">{summary.servicesCompleted}</p>
              </div>
              <div className="tw-bg-pink-100 tw-p-4 tw-rounded-lg">
                <h3 className="tw-font-semibold">Outcomes Worked On</h3>
                <p className="tw-text-2xl">{summary.outcomesCompleted}</p>
              </div>
              <div className="tw-bg-indigo-100 tw-p-4 tw-rounded-lg">
                <h3 className="tw-font-semibold">Reports Submitted</h3>
                <p className="tw-text-2xl">{summary.totalReports}</p>
              </div>
            </div>
          ) : (
            <div className="tw-grid tw-grid-cols-6 tw-gap-4 tw-mb-6">
              <CompactStatItem label="Total Hours" value={summary.totalHours} color="tw-bg-blue-100" />
              <CompactStatItem label="Avg Hours/Day" value={summary.avgHoursPerDay} color="tw-bg-green-100" />
              <CompactStatItem label="Tasks" value={summary.tasksCompleted} color="tw-bg-yellow-100" />
              <CompactStatItem label="Services" value={summary.servicesCompleted} color="tw-bg-purple-100" />
              <CompactStatItem label="Outcomes" value={summary.outcomesCompleted} color="tw-bg-pink-100" />
              <CompactStatItem label="Reports" value={summary.totalReports} color="tw-bg-indigo-100" />
            </div>
          )}

          <div className="tw-overflow-x-auto">
            <table className="tw-min-w-full tw-divide-y tw-divide-gray-200">
              <thead className="tw-bg-gray-50">
              <tr>
                  <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Date</th>
                  <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Provider Name</th>
                  <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Start Time</th>
                  <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">End Time</th>
                  <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Status</th>
                  <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="tw-bg-white tw-divide-y tw-divide-gray-200">
                {reports.map((report) => (
                  <React.Fragment key={report.remoteSupportReportID}>
                    <tr>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">{report.reportDate.toLocaleDateString()}</td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">{report.providerName}</td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">{report.startTime.toLocaleTimeString()}</td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">{report.endTime.toLocaleTimeString()}</td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">{report.status}</td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                        <button
                          onClick={() => toggleReportExpansion(report.remoteSupportReportID)}
                          className="tw-text-indigo-600 hover:tw-text-indigo-900"
                        >
                          {expandedReportId === report.remoteSupportReportID ? <ChevronUp className="tw-h-5 tw-w-5" /> : <ChevronDown className="tw-h-5 tw-w-5" />}
                        </button>
                      </td>
                    </tr>
                    {expandedReportId === report.remoteSupportReportID && (
                      <tr>
                        <td colSpan={6} className="tw-px-6 tw-py-4">
                          <div className="tw-bg-gray-50 tw-p-4 tw-rounded-lg">
                            <h4 className="tw-font-semibold tw-mb-2">Report Details</h4>
                            <p><strong>Description:</strong> {report.reportDescription}</p>
                            <h5 className="tw-font-semibold tw-mt-3 tw-mb-2">Notes</h5>
                            {report.notes.map((note) => (
                              <div key={note.providerNotesID} className="tw-mb-2">
                                <p><strong>{note.createdByDisplayName}:</strong> {note.notes}</p>
                                <p className="tw-text-sm tw-text-gray-500">{note.createdTime.toLocaleString()}</p>
                              </div>
                            ))}
                            <h5 className="tw-font-semibold tw-mt-3 tw-mb-2">Outcome Steps</h5>
                            {report.outcomeSteps.map((step) => (
                              <div key={step.rsReportOutcomeStepID} className="tw-mb-2">
                                <p><strong>{step.stepOverview}:</strong> {step.stepDetails}</p>
                              </div>
                            ))}
                            <h5 className="tw-font-semibold tw-mt-3 tw-mb-2">Events</h5>
                            {report.events.map((event) => (
                              <div key={event.basicRSEventID} className="tw-mb-2">
                                <p><strong>{event.eventDetail}</strong> - Occurred: {event.eventOccurred ? 'Yes' : 'No'}</p>
                              </div>
                            ))}
                            <h5 className="tw-font-semibold tw-mt-3 tw-mb-2">Personal Services</h5>
                            {report.personalServices.map((service) => (
                              <div key={service.personalServiceBasicID} className="tw-mb-2">
                                <p><strong>{service.serviceTitle}</strong> - {service.supportAreaTitle}</p>
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientReportDashboard;