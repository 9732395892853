import axios, { AxiosRequestConfig } from "axios";
import { msalInstance } from "..";
import { billingRequest, chatRequest, loginRequest } from "../authConfig";
import GraphProfile from "../types/graphProfile";
import {Buffer} from 'buffer';
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const getGraphToken = async () : Promise<string | void> => {
    const account = msalInstance.getActiveAccount();

    // const response = await msalInstance.acquireTokenSilent({
    //     ...loginRequest,
    //     account: account
    //   });

    // return response.accessToken;

    const token = msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
      }).then(tokenResponse => {
        // Do something with the tokenResponse
        return tokenResponse.accessToken;
    }).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(billingRequest)
        }
    
        // handle other errors
    });

    return token;
}

export const getGraphChatToken = async () : Promise<string | void> => {
    const account = msalInstance.getActiveAccount();

    // const response = await msalInstance.acquireTokenSilent({
    //     ...loginRequest,
    //     account: account
    //   });

    // return response.accessToken;

    const token = msalInstance.acquireTokenSilent({
        ...chatRequest,
        account: account
      }).then(tokenResponse => {
        // Do something with the tokenResponse
        return tokenResponse.accessToken;
    }).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(billingRequest)
        }
    
        // handle other errors
    });

    return token;
}

export const getGraphProfile = async () : Promise<GraphProfile> => {
    const token = await getGraphToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const url = "https://graph.microsoft.com/v1.0/me";
    return axios.get<GraphProfile>(url,requestHeader).then(response => response.data);
}

export const getGraphImage = async () : Promise<any> => {
    const token = await getGraphToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'arraybuffer',
        validateStatus: function (status) {
            return true;
        }
    }

    const url = "https://graph.microsoft.com/v1.0/me/photo/$value";

    const base64Image = await axios.get(url, requestHeader).then(response => 
            Buffer.from(response.data, 'binary').toString('base64')
          );
    return base64Image;
}

export const getMyChats = async () : Promise<any> => {
    const token = await getGraphChatToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const url = "https://graph.microsoft.com/beta/me/chats";
    return axios.get(url,requestHeader).then(response => response.data);
}

export const createChat = async (chat: any) : Promise<any> => {
    const token = await getGraphChatToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const url = "https://graph.microsoft.com/beta/chats";
    

    return axios.post(url,chat,requestHeader).then(response => response.data);
}

export const sendMessageInChat = async (chatId: string, message: any) : Promise<any> => {
    const token = await getGraphChatToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const url = `https://graph.microsoft.com/beta/chats/${chatId}/messages`;
    

    return axios.post(url,message,requestHeader).then(response => response.data);
}

