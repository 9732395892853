import { useEffect, useState } from "react";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { RemoteSupportRoutine, RoutineEvent } from "../../types/servicePlan";
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from "formik";
import { RemoteSupportRoutineSchema, RoutineEventSchema } from "../forms/newRoutineSection";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useEditPlanContext } from "../../pages/EditServicePlanPage";
import { addRemoteSupportRoutine, deleteRemoteSupportRoutine, getRemoteSupportRoutineByID, updateRemoteSupportRoutine } from "../../apicalls/servicePlanService";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useAppContext } from "../../Context";
import { LoadingSpinner } from "./loadingSpinner";
import { InfoAlert } from "./infoAlert";
import { arraysAreEqual } from "./editSupportAreaCard";

export const ViewRemoteSupportRoutine = props => {
    return (<>
        <div className="tw-pb-2 tw-flex">
            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">{props?.routine.routineTitle}</span>
            {props?.showEdit && <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={e => props?.onEdit(props?.routine.remoteSupportRoutineID)}>Edit</span>}
        </div>
        {/* <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900 tw-mt-1">{props?.routine.routineDetails}</span>
        <div></div> */}
        <span className="tw-text-xs tw-underline tw-font-semibold tw-leading-6 tw-text-gray-900">Routine Tasks:</span>
        <div></div>
        {props?.routine.events.length > 0 && props?.routine.events.map((event, index) => (
            <>
                <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900 tw-mt-1">{event.eventDetails}</span>
                <div></div>
                <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Time</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{event.timeOfDay}</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Pre Reminder</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{event.preEventReminder} min</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Overdue Reminder</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{event.postEventReminder} min</span>
                                    </div>
                </div>
            </>
        ))}

        <div className="tw-flex tw-p-1">
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.routine.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.routine.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.routine.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.routine.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.routine.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.routine.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.routine.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.routine.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.routine.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.routine.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.routine.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.routine.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                    </span>
                                    <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${props?.routine.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`}>
                                        <span className={`tw-font-medium tw-leading-none ${props?.routine.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                    </span>
                                </div>

    </>);
}

const EditRemoteSupportRoutineSection = props => {
    const planContext = useNewPlanContext();
    const [showRoutineTaskCard, setShowRoutineTaskCard] = useState(false);
    const editPlanContext = useEditPlanContext();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const appContext = useAppContext();

    const [loading, setLoading] = useState(false);
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");

    const [routineDirty, setRoutineDirty] = useState(false);
    const [originalRoutine, setOriginalRoutine] = useState<RemoteSupportRoutine>(null);

    useEffect(() => {
        console.log(props);

        

        const fetchRoutine = async () => {
            const routine = await getRemoteSupportRoutineByID(props?.routine.remoteSupportRoutineID, appContext.handleAPICallError);
            console.log(routine);
            setOriginalRoutine(routine);
        }

        if(props?.editPlan){
            fetchRoutine();
        } else {
            setOriginalRoutine(props?.routine);
        }

       

    },[]);

    const initialRoutineEventValues = {
        eventTitle: '',
        eventDetails: '',
        responsibleParty: '',
        timeOfDay: '',
        preEventReminder: 0,
        postEventReminder: 0,
    };

    const initialValues = {
        routineTitle: props?.routine.routineTitle,
        routineDetails: props?.routine.routineDetails,
        routineStart: props?.routine.routineStart,
        routineEnd: props?.routine.routineEnd,
        repeatsMonday: props?.routine.repeatsMonday,
        repeatsTuesday: props?.routine.repeatsTuesday,
        repeatsWednesday: props?.routine.repeatsWednesday,
        repeatsThursday: props?.routine.repeatsThursday,
        repeatsFriday: props?.routine.repeatsFriday,
        repeatsSaturday: props?.routine.repeatsSaturday,
        repeatsSunday: props?.routine.repeatsSunday,
        events: props?.routine.events,
    };

    const routineEventFormik = useFormik({
        initialValues: initialRoutineEventValues,
        validationSchema: RoutineEventSchema,
        onSubmit: (values) => {
            console.log(values);
            routineFormik.values.events.push(values);
            routineFormik.validateField('events');
            if(!arraysAreEqual(originalRoutine.events, routineFormik.values.events)){
                setRoutineDirty(true);
            }
            if(!props?.editPlan){
                setRoutineDirty(true);
            }
            setShowInfoMessage(true);
            setInfoMessage("New routine event added to the list. Press save to keep your changes.");
            setTimeout(() => {
                setShowInfoMessage(false);
            }, 5000);

            toggleRoutineTaskCard();
        },
    });

    const routineFormik = useFormik({
        initialValues: initialValues,
        validationSchema: RemoteSupportRoutineSchema,
        onSubmit: (values) => {
            console.log(values);

            var routineEvents: RoutineEvent[] = [];

            values.events.forEach((routineEvent) => {
                var newEvent: RoutineEvent = {
                    eventTitle: routineEvent.eventTitle,
                    eventDetails: routineEvent.eventDetails,
                    responsibleParty: routineEvent.responsibleParty,
                    timeOfDay: routineEvent.timeOfDay,
                    preEventReminder: routineEvent.preEventReminder,
                    postEventReminder: routineEvent.postEventReminder,
                    routineEventID: uuidv4(),
                    remoteSupportRoutineID: ""
                }
                routineEvents.push(newEvent);
            })

            var newRoutine: RemoteSupportRoutine = {
                remoteSupportRoutineID: props?.routine.remoteSupportRoutineID,
                servicePlanID: "",
                routineTitle: values.routineTitle,
                routineDetails: values.routineDetails,
                routineStart: values.routineStart,
                routineEnd: values.routineEnd,
                repeatsMonday: values.repeatsMonday,
                repeatsTuesday: values.repeatsTuesday,
                repeatsWednesday: values.repeatsWednesday,
                repeatsThursday:  values.repeatsThursday,
                repeatsFriday:  values.repeatsFriday,
                repeatsSaturday: values.repeatsSaturday,
                repeatsSunday: values.repeatsSunday,
                events: routineEvents
            }

            console.log(newRoutine);

            props?.onSave(props?.routine.remoteSupportRoutineID, newRoutine);

        },
    });

    const handleRemoveRoutine = () => {
        if(props?.editPlan){
            setShowConfirmDelete(true);
        } else {
            const index = planContext.planRoutines.indexOf(props?.routine);
            const newItems = [...planContext.planRoutines.slice(0, index), ...planContext.planRoutines.slice(index + 1)];
            planContext.setPlanRoutines(newItems);
        }
    }

    const deletePlanRoutine = async () => {
        const index = editPlanContext.planRoutines.indexOf(props?.routine);
        const newItems = [...editPlanContext.planRoutines.slice(0, index), ...editPlanContext.planRoutines.slice(index + 1)];
        editPlanContext.setPlanRoutines(newItems);
        var result = await deleteRemoteSupportRoutine(editPlanContext.servicePlan.planID, props?.routine.remoteSupportRoutineID, appContext.handleAPICallError);
        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Routine successfully deleted.");
            appContext.setShowSuccessModal(true);
        }
        
        setShowConfirmDelete(false);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const toggleRoutineTaskCard = () => {
        routineEventFormik.resetForm();
        setShowRoutineTaskCard(!showRoutineTaskCard);
    }

    const handleUpdateTasksList = (tasks) => {
        routineFormik.setFieldValue('events', tasks, true);
        // routineFormik.values.events = tasks;
        // routineFormik.validateField('events');
    }

    const discardChanges = () => {
        if(props?.editPlan){
            routineFormik.resetForm();
            editPlanContext.setPlanRoutines(editPlanContext.planRoutines.map(
                el => el.remoteSupportRoutineID ===  originalRoutine.remoteSupportRoutineID? { ...el, ...originalRoutine } : el
            ));
        }

        props?.onCancel();
    }

    return (<>
        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Edit Routine</span>
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${routineFormik.touched.routineTitle && routineFormik.errors.routineTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...routineFormik.getFieldProps('routineTitle')}
                                                    />
                                                    {routineFormik.touched.routineTitle && routineFormik.errors.routineTitle && <p className="tw-text-red-500"><>{routineFormik.errors.routineTitle}</></p>}
                                                </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Repeat
                                            </label>
                                            <div className="tw-flex tw-p-1">
                                            <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsMonday', !routineFormik.values.repeatsMonday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsTuesday', !routineFormik.values.repeatsTuesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsWednesday', !routineFormik.values.repeatsWednesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsThursday', !routineFormik.values.repeatsThursday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsFriday', !routineFormik.values.repeatsFriday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsSaturday', !routineFormik.values.repeatsSaturday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsSunday', !routineFormik.values.repeatsSunday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                        </span>
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Start Time
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${routineFormik.touched.routineStart && routineFormik.errors.routineStart ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...routineFormik.getFieldProps('routineStart')}
                                                    />
                                                    {routineFormik.touched.routineStart && routineFormik.errors.routineStart && <p className="tw-text-red-500"><>{routineFormik.errors.routineStart}</></p>}
                                                </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    End Time
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${routineFormik.touched.routineEnd && routineFormik.errors.routineEnd ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...routineFormik.getFieldProps('routineEnd')}
                                                    />
                                                    {routineFormik.touched.routineEnd && routineFormik.errors.routineEnd && <p className="tw-text-red-500"><>{routineFormik.errors.routineEnd}</></p>}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Routine Tasks</span>
                                        <PlusCircleIcon className="tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={toggleRoutineTaskCard} />
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={toggleRoutineTaskCard}>Add</span> */}
                                    </div>

                                    {showInfoMessage && <InfoAlert message={infoMessage} />}

                                    {showRoutineTaskCard &&
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Title
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.eventTitle && routineEventFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('eventTitle')}
                                                        />
                                                        {routineEventFormik.touched.eventTitle && routineEventFormik.errors.eventTitle && <p className="tw-text-red-500">{routineEventFormik.errors.eventTitle}</p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Description
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="description"
                                                        id="description"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.eventDetails && routineEventFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('eventDetails')}
                                                        />
                                                        {routineEventFormik.touched.eventDetails && routineEventFormik.errors.eventDetails && <p className="tw-text-red-500">{routineEventFormik.errors.eventDetails}</p>}
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Time Of Day
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="time-of-day"
                                                        id="time-of-day"    
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.timeOfDay && routineEventFormik.errors.timeOfDay ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('timeOfDay')}
                                                        />
                                                        {routineEventFormik.touched.timeOfDay && routineEventFormik.errors.timeOfDay && <p className="tw-text-red-500">{routineEventFormik.errors.timeOfDay}</p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-2">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Pre Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="number"
                                                        name="routine-pre-reminder"
                                                        id="routine-pre-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.preEventReminder && routineEventFormik.errors.preEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('preEventReminder')}
                                                        />
                                                        {routineEventFormik.touched.preEventReminder && routineEventFormik.errors.preEventReminder && <p className="tw-text-red-500"><>{routineEventFormik.errors.preEventReminder}</></p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-2">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Overdue Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="number"
                                                        name="routine-overdue-reminder"
                                                        id="routine-overdue-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.postEventReminder && routineEventFormik.errors.postEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('postEventReminder')}
                                                        />
                                                        {routineEventFormik.touched.postEventReminder && routineEventFormik.errors.postEventReminder && <p className="tw-text-red-500"><>{routineEventFormik.errors.postEventReminder}</></p>}
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleRoutineTaskCard}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={routineEventFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>
                                    }

                                    <EditRoutineTaskList tasks={props?.routine.events} onUpdate={handleUpdateTasksList} setInfoMessage={setInfoMessage} setShowInfoMessage={setShowInfoMessage}></EditRoutineTaskList>

                                    <div>
                                        {routineFormik.touched.events && routineFormik.errors.events && <p className="tw-text-red-500"><>{routineFormik.errors.events}</></p>}
                                    </div>

                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                                    {showConfirmDelete ? <>
                                    <p className="tw-text-primary">Are you sure you want to delete this routine?</p>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deletePlanRoutine}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={handleRemoveRoutine}>
                                                {props?.editPlan ? 'Delete' : 'Remove'}
                                            </ButtonSecondary>
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={discardChanges}>
                                            {(routineFormik.dirty || routineDirty) ? "Discard" : "Cancel"}
                                            </ButtonSecondary>
                                            {(routineFormik.dirty || routineDirty) && <ButtonPrimary className="tw-mt-md" onClick={routineFormik.handleSubmit}>
                                                Update
                                            </ButtonPrimary>}

                                    </div>
                                    </>}

    </>);
}

const EditRoutineTaskList = props => {

    const [editTaskId, setEditTaskId] = useState<string | null>(null);
    const [tasks, setTasks] = useState<RoutineEvent[]>(props?.tasks);

    function handleTaskEdit(id: string) {
        setEditTaskId(id);
    }

    function handleTaskSave(id: string, updatedTask: RoutineEvent) {
        console.log(updatedTask);

        const updatedTasks = tasks.map(
            el => el.routineEventID ===  updatedTask.routineEventID? { ...el, ...updatedTask } : el
        );

        setTasks(updatedTasks);

        props?.onUpdate(updatedTasks);

        props?.setInfoMessage("Routine Event Updated. Save remote support routine to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);

        setEditTaskId(null);
    }

    function handleTaskCancel() {
        // Reset the edit ID
        setEditTaskId(null);
    }

    function handleRemoveTask(id: string) {
        const updatedTasks = tasks.filter((task) => task.routineEventID !== id);
        setTasks(updatedTasks);
        props?.onUpdate(updatedTasks);

        props?.setInfoMessage("Routine Event Removed. Save Remote Support Routine to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);
    }

    return (<>
         {tasks.length > 0 && tasks.map((task, index) => (
                                        <div className="tw-space-y-6 tw-bg-card-green tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4" key={task.routineEventID}>
                                            <EditRoutineTaskSection task={task} onEdit={handleTaskEdit} onSave={handleTaskSave} onCancel={handleTaskCancel} onRemove={handleRemoveTask} isEditing={task.routineEventID === editTaskId}></EditRoutineTaskSection>
                                        </div>
        ))}
    </>);

}

const EditRoutineTaskSection = props => {
    const initialRoutineEventValues = {
        eventTitle: props?.task.eventTitle,
        eventDetails: props?.task.eventDetails,
        responsibleParty: props?.task.responsibleParty,
        timeOfDay: props?.task.timeOfDay,
        preEventReminder: props?.task.preEventReminder,
        postEventReminder: props?.task.postEventReminder,
    };

    const routineEventFormik = useFormik({
        initialValues: initialRoutineEventValues,
        validationSchema: RoutineEventSchema,
        onSubmit: (values) => {
            console.log(values);
            var newEvent: RoutineEvent = {
                routineEventID: props?.task.routineEventID,
                remoteSupportRoutineID: "",
                eventTitle: values.eventTitle,
                eventDetails: values.eventDetails,
                responsibleParty: values.responsibleParty,
                timeOfDay: values.timeOfDay,
                preEventReminder: values.preEventReminder,
                postEventReminder: values.postEventReminder,
            }
            props?.onSave(props?.task.routineEventID, newEvent);
        },
    });


    return (<>
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Title
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.eventTitle && routineEventFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('eventTitle')}
                                                        disabled={!props?.isEditing}
                                                        />
                                                        {routineEventFormik.touched.eventTitle && routineEventFormik.errors.eventTitle && <p className="tw-text-red-500"><>{routineEventFormik.errors.eventTitle}</></p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Description
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="description"
                                                        id="description"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.eventDetails && routineEventFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('eventDetails')}
                                                        disabled={!props?.isEditing}
                                                        />
                                                        {routineEventFormik.touched.eventDetails && routineEventFormik.errors.eventDetails && <p className="tw-text-red-500"><>{routineEventFormik.errors.eventDetails}</></p>}
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Time Of Day
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="time-of-day"
                                                        id="time-of-day"    
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.timeOfDay && routineEventFormik.errors.timeOfDay ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('timeOfDay')}
                                                        disabled={!props?.isEditing}
                                                        />
                                                        {routineEventFormik.touched.timeOfDay && routineEventFormik.errors.timeOfDay && <p className="tw-text-red-500"><>{routineEventFormik.errors.timeOfDay}</></p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-2">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Pre Reminder(in mins)
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="number"
                                                        name="routine-pre-reminder"
                                                        id="routine-pre-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.preEventReminder && routineEventFormik.errors.preEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('preEventReminder')}
                                                        disabled={!props?.isEditing}
                                                        />
                                                        {routineEventFormik.touched.preEventReminder && routineEventFormik.errors.preEventReminder && <p className="tw-text-red-500"><>{routineEventFormik.errors.preEventReminder}</></p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-2">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Overdue Reminder(in mins)
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="number"
                                                        name="routine-overdue-reminder"
                                                        id="routine-overdue-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.postEventReminder && routineEventFormik.errors.postEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('postEventReminder')}
                                                        disabled={!props?.isEditing}
                                                        />
                                                        {routineEventFormik.touched.postEventReminder && routineEventFormik.errors.postEventReminder && <p className="tw-text-red-500"><>{routineEventFormik.errors.postEventReminder}</></p>}
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            {props?.isEditing ? <>
                                                <ButtonSecondary className="tw-mt-md" onClick={() => props?.onRemove(props?.task.routineEventID)}>
                                                            Remove
                                                    </ButtonSecondary>
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={() => props?.onCancel()}>
                                                        {routineEventFormik.dirty ? "Discard" : "Cancel"}
                                                    </ButtonSecondary>
                                                    {routineEventFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={routineEventFormik.handleSubmit}>
                                                        Update
                                                    </ButtonPrimary>}
                                            </>: <>
                                                    <ButtonPrimary className="tw-mt-md" onClick={() => props?.onEdit(props?.task.routineEventID)}>
                                                        Edit
                                                    </ButtonPrimary>
                                                
                                            </>}

                                                    

                                        </div>
    </>);
}

const EditRemoteSupportRoutine = props => {

    return (<>
        {props?.isEditing ? <EditRemoteSupportRoutineSection routine={props.routine} onEdit={props?.onEdit} onSave={props?.onSave} onCancel={props?.onCancel} editPlan={props?.editPlan}/> : <ViewRemoteSupportRoutine routine={props.routine} onEdit={props?.onEdit} showEdit={true}/>}
    </>);
}

export const EditRoutineList = props => {
    const [editId, setEditId] = useState<string | null>(null);

    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, newRoutine: RemoteSupportRoutine) {
        if(props?.editPlan) {
            var result = await updateRemoteSupportRoutine(newRoutine, editPlanContext.servicePlan.servicePlanID, newRoutine.remoteSupportRoutineID, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Routine updated successfully.");
                appContext.setShowSuccessModal(true);
                editPlanContext.setPlanRoutines(editPlanContext.planRoutines.map(
                    el => el.remoteSupportRoutineID ===  newRoutine.remoteSupportRoutineID ? { ...el, ...newRoutine } : el
                ));
            }
            
        } else {
            planContext.setPlanRoutines(planContext.planRoutines.map(
                el => el.remoteSupportRoutineID ===  newRoutine.remoteSupportRoutineID ? { ...el, ...newRoutine } : el
            ));
        }
        

        setEditId(null);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    const [showRoutineCard, setShowRoutineCard] = useState(false);

    
      
    const initialRoutineEventValues = {
        eventTitle: '',
        eventDetails: '',
        responsibleParty: '',
        timeOfDay: '',
        preEventReminder: 0,
        postEventReminder: 0,
    };

      
      
    const initialValues = {
        routineTitle: '',
        routineDetails: '',
        routineStart: '',
        routineEnd: '',
        repeatsMonday: false,
        repeatsTuesday: false,
        repeatsWednesday: false,
        repeatsThursday: false,
        repeatsFriday: false,
        repeatsSaturday: false,
        repeatsSunday: false,
        events: [],
    };

    const routineEventFormik = useFormik({
        initialValues: initialRoutineEventValues,
        validationSchema: RoutineEventSchema,
        onSubmit: (values) => {
            console.log(values);
            routineFormik.values.events.push(values);
            routineFormik.validateField('events');
            routineEventFormik.resetForm();
        },
    });

    const routineFormik = useFormik({
        initialValues: initialValues,
        validationSchema: RemoteSupportRoutineSchema,
        onSubmit: async (values) => {
            console.log(values);

            var routineEvents: RoutineEvent[] = [];

            values.events.forEach((routineEvent) => {
                var newEvent: RoutineEvent = {
                    eventTitle: routineEvent.eventTitle,
                    eventDetails: routineEvent.eventDetails,
                    responsibleParty: routineEvent.responsibleParty,
                    timeOfDay: routineEvent.timeOfDay,
                    preEventReminder: routineEvent.preEventReminder,
                    postEventReminder: routineEvent.postEventReminder,
                    routineEventID: uuidv4(),
                    remoteSupportRoutineID: ""
                }
                routineEvents.push(newEvent);
            })

            var newRoutine: RemoteSupportRoutine = {
                remoteSupportRoutineID: uuidv4(),
                servicePlanID: "",
                routineTitle: values.routineTitle,
                routineDetails: values.routineDetails,
                routineStart: values.routineStart,
                routineEnd: values.routineEnd,
                repeatsMonday: values.repeatsMonday,
                repeatsTuesday: values.repeatsTuesday,
                repeatsWednesday: values.repeatsWednesday,
                repeatsThursday:  values.repeatsThursday,
                repeatsFriday:  values.repeatsFriday,
                repeatsSaturday: values.repeatsSaturday,
                repeatsSunday: values.repeatsSunday,
                events: routineEvents
            }

            setLoading(true);

            var result = await addRemoteSupportRoutine(newRoutine, editPlanContext.servicePlan.servicePlanID, appContext.handleAPICallError);
            await editPlanContext.updateLocalRoutines();
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Routine added successfully.");
                appContext.setShowSuccessModal(true);
            }
            

            setShowRoutineCard(false);
            routineEventFormik.resetForm();
            routineFormik.resetForm();
            setLoading(false);
            routineFormik.setFieldValue('events', []);

        },
    });

    const toggleRoutineCard = () => {
        routineEventFormik.resetForm();
        routineFormik.resetForm();
        setLoading(false);
        routineFormik.setFieldValue('events', []);
        setShowRoutineCard(!showRoutineCard);
        setEditId(null);
    }

    const handleRemoveRoutineEvent = (routineEvent) => {
        const index = routineFormik.values.events.indexOf(routineEvent);
        routineFormik.values.events.splice(index, 1);
        routineFormik.setFieldValue('services', routineFormik.values.events);
    }

    return (<>
        {props?.editPlan && <>
            {showRoutineCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                                    <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">New Routine</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${routineFormik.touched.routineTitle && routineFormik.errors.routineTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...routineFormik.getFieldProps('routineTitle')}
                                                    />
                                                    {routineFormik.touched.routineTitle && routineFormik.errors.routineTitle && <p className="tw-text-red-500">{routineFormik.errors.routineTitle}</p>}
                                                </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Repeat
                                            </label>
                                            <div className="tw-flex tw-p-1">
                                            <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsMonday ? 'tw-bg-monday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsMonday', !routineFormik.values.repeatsMonday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsMonday ? 'tw-text-monday-dark': 'tw-text-grey-dark'}`}>M</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsTuesday ? 'tw-bg-tuesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsTuesday', !routineFormik.values.repeatsTuesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsTuesday ? 'tw-text-tuesday-dark': 'tw-text-grey-dark'}`}>T</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsWednesday ? 'tw-bg-wednesday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsWednesday', !routineFormik.values.repeatsWednesday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsWednesday ? 'tw-text-wednesday-dark': 'tw-text-grey-dark'}`}>W</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsThursday ? 'tw-bg-thursday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsThursday', !routineFormik.values.repeatsThursday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsThursday ? 'tw-text-thursday-dark': 'tw-text-grey-dark'}`}>Th</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsFriday ? 'tw-bg-friday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsFriday', !routineFormik.values.repeatsFriday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsFriday ? 'tw-text-friday-dark': 'tw-text-grey-dark'}`}>F</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsSaturday ? 'tw-bg-saturday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsSaturday', !routineFormik.values.repeatsSaturday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsSaturday ? 'tw-text-saturday-dark': 'tw-text-grey-dark'}`}>S</span>
                                        </span>
                                        <span className={`tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full ${routineFormik.values.repeatsSunday ? 'tw-bg-sunday-light': 'tw-bg-grey-light'} tw-m-1 tw-cursor-pointer`} onClick={e => routineFormik.setFieldValue('repeatsSunday', !routineFormik.values.repeatsSunday)}>
                                            <span className={`tw-font-medium tw-leading-none ${routineFormik.values.repeatsSunday ? 'tw-text-sunday-dark': 'tw-text-grey-dark'}`}>Su</span>
                                        </span>
                                            </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Start Time
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${routineFormik.touched.routineStart && routineFormik.errors.routineStart ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...routineFormik.getFieldProps('routineStart')}
                                                    />
                                                    {routineFormik.touched.routineStart && routineFormik.errors.routineStart && <p className="tw-text-red-500">{routineFormik.errors.routineStart}</p>}
                                                </div>
                                        </div>

                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    End Time
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${routineFormik.touched.routineEnd && routineFormik.errors.routineEnd ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...routineFormik.getFieldProps('routineEnd')}
                                                    />
                                                    {routineFormik.touched.routineEnd && routineFormik.errors.routineEnd && <p className="tw-text-red-500">{routineFormik.errors.routineEnd}</p>}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Routine Tasks</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Title
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.eventTitle && routineEventFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('eventTitle')}
                                                        />
                                                        {routineEventFormik.touched.eventTitle && routineEventFormik.errors.eventTitle && <p className="tw-text-red-500">{routineEventFormik.errors.eventTitle}</p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Description
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="description"
                                                        id="description"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.eventDetails && routineEventFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('eventDetails')}
                                                        />
                                                        {routineEventFormik.touched.eventDetails && routineEventFormik.errors.eventDetails && <p className="tw-text-red-500">{routineEventFormik.errors.eventDetails}</p>}
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Time Of Day
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="time-of-day"
                                                        id="time-of-day"    
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.timeOfDay && routineEventFormik.errors.timeOfDay ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('timeOfDay')}
                                                        />
                                                        {routineEventFormik.touched.timeOfDay && routineEventFormik.errors.timeOfDay && <p className="tw-text-red-500">{routineEventFormik.errors.timeOfDay}</p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Pre Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="number"
                                                        name="routine-pre-reminder"
                                                        id="routine-pre-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.preEventReminder && routineEventFormik.errors.preEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('preEventReminder')}
                                                        />
                                                        {routineEventFormik.touched.preEventReminder && routineEventFormik.errors.preEventReminder && <p className="tw-text-red-500"><>{routineEventFormik.errors.preEventReminder}</></p>}
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Overdue Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="number"
                                                        name="routine-overdue-reminder"
                                                        id="routine-overdue-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md ${routineEventFormik.touched.postEventReminder && routineEventFormik.errors.postEventReminder ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        {...routineEventFormik.getFieldProps('postEventReminder')}
                                                        />
                                                        {routineEventFormik.touched.postEventReminder && routineEventFormik.errors.postEventReminder && <p className="tw-text-red-500"><>{routineEventFormik.errors.postEventReminder}</></p>}
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={routineEventFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={routineEventFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>

                                    {routineFormik.values.events.length > 0 && routineFormik.values.events?.map((routineEvent, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Title
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.eventTitle}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Description
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="description"
                                                        id="description"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.eventDetails}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Time Of Day
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="time-of-day"
                                                        id="time-of-day"    
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.timeOfDay}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Pre Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="pre-reminder"
                                                        id="pre-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.preEventReminder}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tw-col-span-1">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Overdue Reminder
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="overdue-reminder"
                                                        id="overdue-reminder"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={routineEvent.postEventReminder}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveRoutineEvent(routineEvent);
                                                        }}>
                                                            Remove
                                                    </ButtonSecondary>
                                                    {/* <ButtonPrimary className="tw-mt-md">
                                                        Edit
                                                    </ButtonPrimary> */}

                                        </div>
                                    </div>))}  

                                    <div>
                                        {routineFormik.touched.events && routineFormik.errors.events && <p className="tw-text-red-500"><>{routineFormik.errors.events}</></p>}
                                    </div>

                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleRoutineCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={routineFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                    </>}

                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2 tw-mb-4">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleRoutineCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Routine</span>
                                            </div>  
                                    </div>
                            </div>
                            </>}
        </>}
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
            {props?.routines.length > 0 && props?.routines.map((routine, index) => (
                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow" key={routine.remoteSupportRoutineID}>
                    <EditRemoteSupportRoutine routine={routine} onEdit={handleEdit} onSave={handleSave} onCancel={handleCancel} isEditing={routine.remoteSupportRoutineID === editId} editPlan={props?.editPlan}></EditRemoteSupportRoutine>
                </div>
            ))}
        </div>
    </>);
}

export default EditRemoteSupportRoutine;