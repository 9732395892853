import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ButtonErrorSecondary, ButtonPrimary } from "../../components/buttons/Buttons";
import { ButtonSecondary } from "@medforall/medforall-component-library";
import { Input, Label, Select, Textarea } from "../../components/forms/formFields";
import { useAppContext } from "../../Context";
import { getActiveClients, getDSClientsForProvider } from "../../apicalls/schedulingService";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { LabelOutputs } from "../../components/utilities/Helpers";
import { RemoteSupportTicket } from "../../types/remoteSupportTicket";
import { getSupportTicketByID, loadPastPendingTicketsByPageNumber, loadPastResolvedTicketsByPageNumber, loadPastTicketsByPageNumber, saveRemoteSupportTicket } from "../../apicalls/sessionService";
import { set, values } from "lodash";
import moment from "moment";
import SupportTicketPanel from "../../components/panels/SupportTicketPanel";
import { escapeSingleQuotes } from "../../apicalls/announcementService";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import { getGraphProfile } from "../../apicalls/graphService";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const RemoteSupportTicketPage = props => {
    const [showNewTicketForm, setShowNewTicketForm] = useState(false);

    const {ticketID} = useParams();

    const appContext = useAppContext();

    useLayoutEffect(() => {
        const fetchSelectedTicket = async (id) => {
            const data = await getSupportTicketByID(id, appContext.handleAPICallError);

            console.log(data);

            const graphProfile = await getGraphProfile();

            if (data.createdBy === graphProfile.mail){
                await showSupportTicket(data);
            }
        }

        if (ticketID) {
            fetchSelectedTicket(ticketID);
        }
    }, [ticketID]);

    const ticketCategory = useRef();
    const clientSelect = useRef();
    const ticketSeverityRef = useRef();
    const serviceTypeRef = useRef();

    const [savingTicket, setSavingTicket] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [showLoadMore, setShowLoadMore] = useState(false);

    const [loadingTickets, setLoadingTickets] = useState(false);

    const [tickets, setTickets] = useState<RemoteSupportTicket[]>([]);

    const [pendingTickets, setPendingTickets] = useState<RemoteSupportTicket[]>([]);
    const [resolvedTickets, setResolvedTickets] = useState<RemoteSupportTicket[]>([]);

    const [showPendingLoadMore, setShowPendingLoadMore] = useState(false);
    const [showResolvedLoadMore, setShowResolvedLoadMore] = useState(false);

    const [page, setPage] = useState(1);

    function escapeSingleQuotes(str: string): string {
        return str.replace(/'/g, "''");
    }

    useLayoutEffect(() => {
        const fetchTickets = async () => {
            setLoadingTickets(true);

            // const data = await loadPastTicketsByPageNumber(page, appContext.handleAPICallError);

            // setTickets(data);

            // if (data.length === 20) {
            //     setShowLoadMore(true);
            // }

            const pending = await loadPastPendingTicketsByPageNumber(page, appContext.handleAPICallError);

            setPendingTickets(pending);

            if (pending.length === 20) {
                setShowPendingLoadMore(true);
            } else {
                setShowPendingLoadMore(false);
            }

            const resolved = await loadPastResolvedTicketsByPageNumber(page, appContext.handleAPICallError);

            setResolvedTickets(resolved);

            if (resolved.length === 20) {
                setShowResolvedLoadMore(true);
            } else {
                setShowResolvedLoadMore(false);
            
            }

            setLoadingTickets(false);
        }

        fetchTickets()
            .catch(console.error);
    }, []);

    const loadMoreTickets = async () => {
        setLoadingTickets(true);

        const data = await loadPastTicketsByPageNumber(page + 1, appContext.handleAPICallError);

        setTickets([...tickets, ...data]);

        setPage(page + 1);

        if (data.length >= 20) {
            setShowLoadMore(true);
        }

        setLoadingTickets(false);
    }

    const loadMorePendingTickets = async () => {
        setLoadingTickets(true);

        const data = await loadPastPendingTicketsByPageNumber(page + 1, appContext.handleAPICallError);

        setPendingTickets([...pendingTickets, ...data]);

        setPage(page + 1);

        if (data.length >= 20) {
            setShowPendingLoadMore(true);
        } else {
            setShowPendingLoadMore(false);
        }

        setLoadingTickets(false);
    }

    const loadMoreResolvedTickets = async () => {
        setLoadingTickets(true);

        const data = await loadPastResolvedTicketsByPageNumber(page + 1, appContext.handleAPICallError);

        setResolvedTickets([...resolvedTickets, ...data]);

        setPage(page + 1);

        if (data.length >= 20) {
            setShowResolvedLoadMore(true);
        } else {
            setShowResolvedLoadMore(false);
        
        }

        setLoadingTickets(false);
    }

    const [currentTabs, setCurrentTabs] = useState([
        { name: 'Pending', href: '#', current: true },
        { name: 'Resolved', href: '#', current: false }
    ]);

    const [currentActiveTab, setCurrentActiveTab] = useState<string>("Pending");
    
    const NavigateToTab = (tab) => {
        setCurrentTabs(currentTabs.map((item) => {
            if (item.name === tab.name) {
                return {
                    ...item,
                    current: true
                }
            } else {
                return {
                    ...item,
                    current: false
                }
            }
        }));

        setCurrentActiveTab(tab.name);
    }

    const severityResponseTimes: Record<string, string> = {
        Low: 'within 48 hours',
        Moderate: 'within 4 hours',
        High: 'within one hour',
    };

    const isTicketBeyondResponseTime = (ticket: RemoteSupportTicket) => {
        const timeElapsed = moment().diff(moment(ticket.createdDate), 'hours');

        if (ticket.status === 'Resolved') {
            return false;
        }

        if (ticket.ticketSeverity === 'Low' && timeElapsed >= 48) {
            return true;
        } else if (ticket.ticketSeverity === 'Moderate' && timeElapsed >= 4) {
            return true;
        } else if (ticket.ticketSeverity === 'High' && timeElapsed >= 1) {
            return true;
        }

        return false;
    }

    const saveNewTicket = async () => {
        setSavingTicket(true);

        if (!isFormValid()) {
            setSavingTicket(false);
            return;
        }

        var newTicket: RemoteSupportTicket = {
            ticketID: "",
            createdBy: "",
            createdDate: new Date(),
            clientID: values.client,
            clientName: "",
            ticketCategory: values.ticket_category,
            ticketSubject: escapeSingleQuotes(values.subject),
            ticketDescription: escapeSingleQuotes(values.description),
            stepsTaken: escapeSingleQuotes(values.steps_taken),
            ticketSeverity: values.ticket_severity,
            serviceType: values.service_type,
            preferredAdmin: values.preferred_admin ? values.preferred_admin : "",
        };

        if(values.ticket_category === 'Employee Technical Support') {
            newTicket.serviceType = '';
            newTicket.clientID = '';
            newTicket.clientName = '';
        } else {
            var fullClient = activePatients.find(client => client.patientID === values.client);
            newTicket.clientName = fullClient.firstName + ' ' + fullClient.lastName;
        }

        console.log(newTicket);

        // save ticket

        const result = await saveRemoteSupportTicket(newTicket, appContext.handleAPICallError);

        setPage(1);

        const data = await loadPastTicketsByPageNumber(page, appContext.handleAPICallError);

        setTickets(data);

        setSavingTicket(false);

        // show success message
        setShowSuccessMessage(true);

    }

    const hideSuccessMessage = () => {
              setShowSuccessMessage(false);
            setShowNewTicketForm(false);

            setValues({
                ticket_category: '',
                client: '',
                subject: '',
                description: '',
                steps_taken: '',
                preferred_admin: '',
                ticket_severity: '',
                service_type: '',
            });

            setFormErrors({
                ticket_category: false,
                client: false,
                subject: false,
                description: false,
                steps_taken: false,
                preferred_admin: false,
                ticket_severity: false,
                service_type: false,
            });

            setFormErrorMessages({
                ticket_category: '',
                client: '',
                subject: '',
                description: '',
                steps_taken: '',
                preferred_admin: '',
                ticket_severity: '',
                service_type: '',
            });
    }

    const [values, setValues] = useState({
        ticket_category: '',
        client: '',
        subject: '',
        description: '',
        steps_taken: '',
        preferred_admin: '',
        ticket_severity: '',
        service_type: '',
    });

    const [formErrors, setFormErrors] = useState({
        ticket_category: false,
        client: false,
        subject: false,
        description: false,
        steps_taken: false,
        preferred_admin: false,
        ticket_severity: false,
        service_type: false,
    });

    const [formErrorMessages, setFormErrorMessages] = useState({
        ticket_category: '',
        client: '',
        subject: '',
        description: '',
        steps_taken: '',
        preferred_admin: '',
        ticket_severity: '',
        service_type: '',
    });

    const [activePatients, setActivePatients] = useState([]);
    const [activeDSPatients, setActiveDSPatients] = useState([]);
    const [activeRSPatients, setActiveRSPatients] = useState([]);
    

    const showSupportTicket = async (ticket: RemoteSupportTicket) => {

        appContext.setSideNavTheme(3);
        appContext.setPanelSize('tw-max-w-400px');
        appContext.setSideNavTitle( "Support Ticket" );
        appContext.setSideNavActions(null);
        appContext.setSideNavBottom(null);
        
  
        appContext.setSideNav( <SupportTicketPanel ticket={ticket} /> )
  
        appContext.openSideNav();
    }

    useLayoutEffect(() => {
		const fetchClients = async () => {
			const data = await getActiveClients(appContext.handleAPICallError);
			console.log(data);
			const activePatients = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName));
			setActivePatients(activePatients);
            setActiveRSPatients(activePatients);

            const dsData = await getDSClientsForProvider(appContext.handleAPICallError);

            console.log(dsData);

            const activeDSPatients = [...dsData].sort((a, b) => a.firstName.localeCompare(b.firstName));
            setActiveDSPatients(activeDSPatients);
		}

		fetchClients()
    		.catch(console.error);
	}, []);

    const validateTicketCategory = (value) => {
        if (!value) {
            setFormErrors({...formErrors, ticket_category: true});
            setFormErrorMessages({...formErrorMessages, ticket_category: 'Please select a ticket category!'});
        } else {
            setFormErrors({...formErrors, ticket_category: false});
            setFormErrorMessages({...formErrorMessages, ticket_category: ''});
        }
    }

    const validateTicketSeverity = (value) => {
        if (!value) {
            setFormErrors({...formErrors, ticket_severity: true});
            setFormErrorMessages({...formErrorMessages, ticket_severity: 'Please select a ticket severity!'});
        } else {
            setFormErrors({...formErrors, ticket_severity: false});
            setFormErrorMessages({...formErrorMessages, ticket_severity: ''});
        }
    }

    const validateClient = (value) => {
        if (!value) {
            setFormErrors({...formErrors, client: true});
            setFormErrorMessages({...formErrorMessages, client: 'Please select a client!'});
        } else {
            setFormErrors({...formErrors, client: false});
            setFormErrorMessages({...formErrorMessages, client: ''});
        }
    }

    const validateServiceType = (value) => {
        if (!value) {
            setFormErrors({...formErrors, service_type: true});
            setFormErrorMessages({...formErrorMessages, service_type: 'Please select a service type!'});
        } else {
            if (value === "Direct Support") {
                setActivePatients(activeDSPatients);
            } else {
                setActivePatients(activeRSPatients);
            }
            setFormErrors({...formErrors, service_type: false});
            setFormErrorMessages({...formErrorMessages, service_type: ''});
        }
    }

    const validateSubject = (value) => {
        if (!value) {
            setFormErrors({...formErrors, subject: true});
            setFormErrorMessages({...formErrorMessages, subject: 'Please enter a subject for this ticket!'});
        } else {
            setFormErrors({...formErrors, subject: false});
            setFormErrorMessages({...formErrorMessages, subject: ''});
        }
    }

    const validateDescription = (value) => {
        if (!value) {
            setFormErrors({...formErrors, description: true});
            setFormErrorMessages({...formErrorMessages, description: 'Please enter a description for this ticket!'});
        } else if (value.length < 200) {
            setFormErrors({...formErrors, description: true});
            setFormErrorMessages({...formErrorMessages, description: 'Description must be at least 200 characters!'});
        } else {
            setFormErrors({...formErrors, description: false});
            setFormErrorMessages({...formErrorMessages, description: ''});
        }
    }

    const validateStepsTaken = (value) => {
        if (!value) {
            setFormErrors({...formErrors, steps_taken: true});
            setFormErrorMessages({...formErrorMessages, steps_taken: 'Please enter the steps taken to resolve the issue!'});
        } else if (value.length < 200) {
            setFormErrors({...formErrors, steps_taken: true});
            setFormErrorMessages({...formErrorMessages, steps_taken: 'Steps taken must be at least 200 characters!'});
        } else {
            setFormErrors({...formErrors, steps_taken: false});
            setFormErrorMessages({...formErrorMessages, steps_taken: ''});
        }
    }

    const isFormValid = () => {
        var isValid = true;

        const errors = {...formErrors};
        const errorMessages = {...formErrorMessages};

        if (!values.ticket_category) {
            errors.ticket_category = true;
            errorMessages.ticket_category = 'Please select a ticket category!';
            isValid = false;
        } else {
            errors.ticket_category = false;
            errorMessages.ticket_category = '';
        }

        if (!values.ticket_severity) {
            errors.ticket_severity = true;
            errorMessages.ticket_severity = 'Please select a ticket severity!';
            isValid = false;
        } else {
            errors.ticket_severity = false;
            errorMessages.ticket_severity = '';
        }

        if (!values.service_type && values.ticket_category !== 'Employee Technical Support') {
            errors.service_type = true;
            errorMessages.service_type = 'Please select a service type!';
            isValid = false;
        } else {
            errors.service_type = false;
            errorMessages.service_type = '';
        }

        // if (!values.service_type) {
        //     errors.service_type = true;
        //     errorMessages.service_type = 'Please select a service type!';
        //     isValid = false;
        // } else {
        //     errors.service_type = false;
        //     errorMessages.service_type = '';
        // }


        if (!values.client && values.ticket_category !== 'Employee Technical Support') {
            errors.client = true;
            errorMessages.client = 'Please select a client!';
            isValid = false;
        } else {
            errors.client = false;
            errorMessages.client = '';
        }

        if (!values.subject) {
            errors.subject = true;
            errorMessages.subject = 'Please enter a subject for this ticket!';
            isValid = false;
        } else {
            errors.subject = false;
            errorMessages.subject = '';
        }

        if (!values.description) {
            errors.description = true;
            errorMessages.description = 'Please enter a description for this ticket!';
            isValid = false;
        } 
        // else if (values.description.length < 200) {
        //     errors.description = true;
        //     errorMessages.description = 'Description must be at least 200 characters!';
        //     isValid = false;
        // } 
        else {
            errors.description = false;
            errorMessages.description = '';
        }

        if (!values.steps_taken) {
            errors.steps_taken = true;
            errorMessages.steps_taken = 'Please enter the steps taken to resolve the issue!';
            isValid = false;
        } 
        // else if (values.steps_taken.length < 200) {
        //     errors.steps_taken = true;
        //     errorMessages.steps_taken = 'Steps taken must be at least 200 characters!';
        //     isValid = false;
        // } 
        else {
            errors.steps_taken = false;
            errorMessages.steps_taken = '';
        }

        setFormErrors(errors);
        setFormErrorMessages(errorMessages);

        return isValid;
    }

    const renderEmployeeNextSteps = () => {
        switch(values.ticket_severity){
            case 'Low':
                return <div>
                    <h2 className="tw-text-xl tw-text-gray-900 tw-font-bold">Important Next Steps</h2>
                    <p className="tw-text-base tw-text-gray-900">1. An admin will reach out to you via email or Teams within the next 48 to 72 hours.</p>
                </div>
            case 'Moderate':
                return <div>
                    <h2 className="tw-text-xl tw-text-gray-900 tw-font-bold">Important Next Steps</h2>
                    <p className="tw-text-base tw-text-gray-900">1. An admin will reach out to you via email or Teams within the next 4 to 8 hours.</p>
                </div>
            case 'High':
                return <div>
                    <h2 className="tw-text-xl tw-text-gray-900 tw-font-bold">Important Next Steps</h2>
                    <p className="tw-text-base tw-text-gray-900">1. An admin will reach out to you via email or Teams within the next 1 hour.</p>
                    <h2 className="tw-text-xl tw-text-gray-900 tw-font-bold">Emergency Protocol</h2>
                    <p className="tw-text-base tw-text-gray-900">If this issue is hindering your ability to provide services to clients during your scheduled hours, please contact administrator at 614-330-1919</p>
                </div>
        }
                
    }

    const renderClientNextSteps = () => {
        switch(values.ticket_severity){
            case 'Low':
                return <div>
                        <h2 className="tw-text-xl tw-text-gray-900 tw-font-bold">Important Next Steps</h2>
                        <p className="tw-text-base tw-text-gray-900">1. If the client was involved in the creation of this ticket, please inform them that their issue is being processed.</p>
                        <p className="tw-text-base tw-text-gray-900">2. A supervisor will contact you via Microsoft Teams or email to discuss further actions.</p>
                        <p className="tw-text-base tw-text-gray-900">3. Please note, if this ticket pertains to preventive measures or indirect issues, the client will not be directly contacted by the supervisor unless necessary for resolution.</p>
                    </div>
            case 'Moderate':
                return <div>
                        <h2 className="tw-text-xl tw-text-gray-900 tw-font-bold">Important Next Steps</h2>
                        <p className="tw-text-base tw-text-gray-900">1. If the client was aware of the creation of this ticket, please inform them that a supervisor has been notified.</p>
                        <p className="tw-text-base tw-text-gray-900">2. A supervisor will be reaching out to you via a call or Microsoft Teams to discuss the issue and determine the next steps.</p>
                        <p className="tw-text-base tw-text-gray-900">3. The supervisor will reach out to the client directly if necessary.</p>
                    </div>
            case 'High':
                return <div>
                        <h2 className="tw-text-xl tw-text-gray-900 tw-font-bold">Important Next Steps</h2>
                        <p className="tw-text-base tw-text-gray-900">1. An administrator will reach out to you via email or Microsoft Teams within the next hour to ensure rapid resolution of your issue.</p>
                        <h2 className="tw-text-xl tw-text-gray-900 tw-font-bold">Emergency Protocol</h2>
                        <p className="tw-text-base tw-text-gray-900">If this situation is an emergency, or if there are any health and safety concerns for you or the client, please follow the relevant emergency protocols immediately. If anyone's health or safety is at risk, contact 911 without delay.</p>
                    </div>
        }


       
    }

    const renderSuccessMessage = () => {
        return <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-bg-white
         tw-text-primary tw-rounded-md tw-px-4 tw-py-12 tw-my-4">
            <div className="tw-flex tw-mb-5 tw-mx-24">
                <CheckCircleIcon className="tw-text-primary tw-h-24 tw-w-24 tw-mx-10" />
                <div className="tw-flex tw-flex-col">
                    <h2 className="tw-text-xl tw-font-bold">Ticket Submitted Successfully!!</h2>
                    <p className="tw-text-base tw-mb-8">Thank you for submitting your ticket. As this ticket has been designated as {values.ticket_severity} priority , a supervisor will get back to you {severityResponseTimes[values.ticket_severity]} .</p>

                    {values.ticket_category === 'Employee Technical Support' ? renderEmployeeNextSteps() : renderClientNextSteps()}

                    <ButtonPrimary onClick={hideSuccessMessage} className="tw-mt-4 tw-mx-auto">Close</ButtonPrimary>
                </div>
            {/* <div>
                <h2 className="tw-text-lg tw-font-bold">Ticket Submitted Successfully!</h2>
                <p className="tw-text-sm">Thank you for submitting your ticket. Since you marked the ticket as {values.ticket_severity} , a supervisor will get back to you {severityResponseTimes[values.ticket_severity]} .</p>
                </div> */}
            </div>
        
       
      </div>
    }

    const [showFormDetails, setShowFormDetails] = useState(false);

    useEffect(() => {
        console.log("new values");
        console.log(values);

        console.log((values.ticket_category === 'Employee Technical Support'));

        if (values.ticket_category === 'Employee Technical Support') {
            setShowFormDetails(true);
        } else if (values.service_type !== "") {
            setShowFormDetails(true);
        } else {
            setShowFormDetails(false);
        }

        console.log((values.service_type !== ""));

        console.log((values.ticket_category === 'Employee Technicial Support') || (values.service_type !== ""));

    }, [values])

    return (<>
        <div className="tw-px-xl tw-py-sm">
                <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Support Tickets</h2>
                </div>

                {showSuccessMessage ? renderSuccessMessage():<>

                { showNewTicketForm ? <>
                    <div className="tw-flex tw-flex-col tw-space-y-15px tw-w-full tw-mt-15px tw-bg-white tw-p-4">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">New Ticket</h2>

                        <form className="tw-p-20px tw-space-y-30px tw-w-full">
                            <div className="tw-space-y-10px tw-w-1/2">
                                <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
                                        { 'Request Category'}
                                </Label>
                                <Select id="ticketCategory" className={formErrors?.ticket_category ? '_form-error': ''}  onChange={ e => {
                                    console.log(e.target.value);
                                    setValues({...values, "ticket_category": e.target.value});
                                    validateTicketCategory(e.target.value);
                                }} ref={ ticketCategory } value={ values.ticket_category }>
                                    <option value="" title="Select an option from the dropdown">Select</option>
                                    <option value="Client Consultation Request" title="This category is used when a client requests to speak with a specific provider or admin, or needs a consultation about a particular issue such as changes in their schedule, medication dispensers, or other personal matters.">Client Consultation Request</option>
                                    <option value="Client Transportation Request" title="This category is for any transportation-related assistance that a client requires, such as arranging for transportation to appointments or events.">Client Transportation Request</option>
                                    <option value="Client Technical Support" title="This category is for any technical issues related to the equipment used by clients. This includes problems with two-way communication devices, cameras, sensors, or any other monitoring equipment used to support clients.">Client Technical Support</option>
                                    <option value="Employee Technical Support" title="This category is for technical issues related to business applications, employee-specific systems, or office equipment. This includes problems with computers, software applications, network connectivity, and other technical issues encountered by employees during their work.">Employee Technical Support</option>
                                    <option value="Other Client Request" title="This category encompasses various requests made by clients that do not fall under the other categories. These can include requests for changes in services, additional support, or other non-technical assistance.">Other Client Request</option>
                                </Select>
                                {formErrors.ticket_category  && <p className="tw-text-red-500">{formErrorMessages.ticket_category}</p>}
                            </div>

                            {values.ticket_category !== "" && values.ticket_category !== 'Employee Technical Support' && <div className="tw-space-y-10px tw-w-1/2">
                                <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-tag.svg">
                                        { 'Service Type'}
                                </Label>
                                <Select id="serviceType" className={formErrors?.service_type ? '_form-error': ''}  onChange={ e => {
                                    console.log(e.target.value);
                                    setValues({...values, "service_type": e.target.value, "client": ''});
                                    validateServiceType(e.target.value);
                                }} ref={serviceTypeRef} value={ values.service_type }>
                                    <option value="">Select</option>
                                    <option value="Direct Support" title="Direct Support">Direct Support</option>
                                    <option value="Remote Support" title="Direct Support">Remote Support</option>
                                </Select>
                                {formErrors.service_type  && <p className="tw-text-red-500">{formErrorMessages.service_type}</p>}
                            </div>}


                            {showFormDetails && (<>
                            
                            {(values.ticket_category !== 'Employee Technical Support') && <div className="tw-space-y-10px tw-w-1/2">
                                <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-provider.svg">
                                        { 'Client'}
                                </Label>
                                <Select id="client" className={formErrors?.client ? '_form-error': ''}  onChange={ e => {
                                    console.log(e.target.value);
                                    setValues({...values, "client": e.target.value});
                                    validateClient(e.target.value);
                                }} ref={ clientSelect } value={ values.client }>
                                    <option value="">Select</option>
                                    {
                                        activePatients.map(client =>
                                            <option value={client.patientID}>{client.firstName} {client.lastName}</option>)
                                    }
                                </Select>
                                {formErrors.client  && <p className="tw-text-red-500">{formErrorMessages.client}</p>}
                            </div>}

                            <div className="tw-space-y-10px">
                                <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-document.svg">
                                        { 'Subject'}
                                </Label>
                                <Input id="subject" className={formErrors?.subject ? '_form-error tw-w-full': 'tw-w-full'}  onChange={ e => {
                                    console.log(e.target.value);
                                    setValues({...values, "subject": e.target.value});
                                    validateSubject(e.target.value);
                                }} value={ values.subject }/>
                                {formErrors.subject  && <p className="tw-text-red-500">{formErrorMessages.subject}</p>}
                            </div>

                            <div className="tw-space-y-10px">
                                <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-document-text.svg">
                                        { 'Description'}
                                </Label>
                                <Textarea id="description" rows="3" className={formErrors?.description ? '_form-error tw-w-full': 'tw-w-full'} placeholder="Enter details description for this ticket (200 characters at least)" onChange={ e => {
                                    setValues({
                                        ...values,
                                        description: e.target.value
                                    });
                                }}>
                                </Textarea>
                                {formErrors.description  && <p className="tw-text-red-500">{formErrorMessages.description}</p>}
                            </div>

                            <div className="tw-space-y-10px tw-w-1/2">
                                <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-shield-exclamation.svg">
                                        { 'Severity'}
                                </Label>
                                <Select id="ticketCategory" className={formErrors?.ticket_severity ? '_form-error': ''}  onChange={ e => {
                                    console.log(e.target.value);
                                    setValues({...values, "ticket_severity": e.target.value});
                                    validateTicketSeverity(e.target.value);
                                }} ref={ ticketSeverityRef } value={ values.ticket_severity }>
                                    <option value="">Select</option>
                                    <option value="Low">Low</option>
                                    <option value="Moderate">Moderate</option>
                                    <option value="High">High</option>
                                </Select>
                                {formErrors.ticket_severity  && <p className="tw-text-red-500">{formErrorMessages.ticket_severity}</p>}
                            </div>

                            <div className="tw-space-y-10px">
                                <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-list-bullet.svg">
                                        { 'Steps Taken'}
                                </Label>
                                <Textarea id="steps_taken" rows="3" className={formErrors?.steps_taken ? '_form-error tw-w-full': 'tw-w-full'} placeholder="Enter the steps you have taken to resolve the issue" onChange={ e => {
                                    setValues({
                                        ...values,
                                        steps_taken: e.target.value
                                    });
                                }}>
                                </Textarea>
                                {formErrors.steps_taken  && <p className="tw-text-red-500">{formErrorMessages.steps_taken}</p>}
                            </div>

                            {/* <div className="tw-space-y-10px">
                                <Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
                                        { 'Preferred Admin (Optional)'}
                                </Label>
                                <Input id="preferred_admin" className={formErrors?.preferred_admin ? '_form-error tw-w-full': 'tw-w-full'}  onChange={ e => {
                                    console.log(e.target.value);
                                    setValues({...values, "preferred_admin": e.target.value});
                                    //validateShiftType(e.target.value);
                                }} value={ values.preferred_admin }/>
                                {formErrors.preferred_admin  && <p className="tw-text-red-500">{formErrorMessages.preferred_admin}</p>}
                            </div> */}
                            </>)
                            
                            }
                            
                        </form>

                        <div className="tw-flex tw-justify-end tw-mt-md">
                            {/* {showSuccessMessage ? <>
                                <LabelOutputs className="tw-w-full tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
                                    <span className="tw-text-5">
                                        Ticket Saved Successfully!
                                    </span>
                                </LabelOutputs>
                            </>:<> */}
                                {savingTicket ? <>
                                    <LoadingSpinner />
                                </>:<>
                                    <ButtonErrorSecondary onClick={hideSuccessMessage} className="tw-mr-4">
                                        Cancel
                                    </ButtonErrorSecondary>
                                    <ButtonPrimary onClick={saveNewTicket} disabled={values.ticket_category === "" && values.service_type === ""}>
                                        Submit
                                    </ButtonPrimary>
                                </>}
                            {/* </>} */}
                        </div>
                    </div>
                    
                
                </>:<>
                <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-md">
                    <ButtonPrimary onClick={() => setShowNewTicketForm(true)}>
                        Create New Ticket
                    </ButtonPrimary>
                </div>

                <div className="hidden sm:block">
                    <div className="tw-border-b tw-border-gray-200">
                    <nav className="-tw-mb-px tw-flex tw-space-x-8" aria-label="Tabs">
                        {currentTabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={classNames(
                            tab.current
                                ? 'tw-border-primary tw-text-primary'
                                : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-200 hover:tw-text-gray-700 tw-cursor-pointer',
                            'tw-flex tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => NavigateToTab(tab)}
                        >
                            {tab.name}
                            {/* <span
                                className={classNames(
                                tab.current ? 'tw-bg-primary-bg tw-text-primary' : 'tw-bg-gray-100 tw-text-gray-900',
                                'tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block'
                                )}
                            >
                                {tab.count}
                            </span> */}
                        </span>
                        ))}
                    </nav>
                    </div>
                </div>

                {currentActiveTab === 'Pending' &&

                <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-10px tw-leading-tight tw-mt-15px">
                    <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-220px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Category</span>
                                    </span>
                                    <span className="tw-w-320px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Subject</span>
                                    </span>
                                    <span className="tw-w-120px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Severity</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Status</span>
                                    </span>
                                    <span className="tw-w-200px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Created On</span>
                                    </span>
                            </div>
                    </li>

                    {(!pendingTickets || pendingTickets.length === 0) && !loadingTickets && <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                        <div className="tw-flex tw-justify-center tw-mx-auto">
                            No Tickets Found
                        </div>
                    </li>}

                    {pendingTickets.map(ticket => {
                        return <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1 tw-cursor-pointer" onClick={(e) => {
                                e.preventDefault();
                                showSupportTicket(ticket);
                            }}>
                                    <span className="tw-w-220px">
                                        <span className="tw-text-dark">{ticket.ticketCategory}</span>
                                    </span>
                                    <span className="tw-w-320px">
                                        {ticket.ticketSubject}
                                    </span>
                                    <div className="tw-w-120px tw-flex tw-items-center">
                                        <span className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-medium tw-mr-2 ${
                                            isTicketBeyondResponseTime(ticket) ? 'tw-bg-red-100 tw-text-red-800' :
                                            ticket.ticketSeverity === 'High' ? 'tw-bg-red-100 tw-text-red-800' :
                                            ticket.ticketSeverity === 'Moderate' ? 'tw-bg-yellow-100 tw-text-yellow-800' :
                                            'tw-bg-green-100 tw-text-green-800'
                                            }`}>
                                            {ticket.ticketSeverity}
                                        </span>
                                    </div>
                                    
                                    <span className="tw-w-140px">
                                        {ticket.status}
                                    </span>
                                    <span className="tw-w-200px">
                                        {moment.utc(ticket.createdDate).tz('America/New_York').format('MMM Do YYYY h:mm A')}
                                    </span>
                            </div>
                        </li>
                    })}

                    {/* <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark">Aidan H</span>
                                    </span>
                                    <span className="tw-w-320px">
                                        Aidan Needs Transportation from work
                                    </span>
                                    <span className="tw-w-200px">
                                        Monday, May 13th 4:30 PM
                                    </span>
                            </div>
                    </li>

                    <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark">Marcella S</span>
                                    </span>
                                    <span className="tw-w-320px">
                                        Marci wants to talk to Ali
                                    </span>
                                    <span className="tw-w-200px">
                                        Monday, May 13th 4:15 PM
                                    </span>
                            </div>
                    </li>

                    <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-180px">
                                        <span className="tw-text-dark">Sandy H</span>
                                    </span>
                                    <span className="tw-w-320px">
                                        Sandy is requesting change in service
                                    </span>
                                    <span className="tw-w-200px">
                                        Monday, May 13th 4:04 PM
                                    </span>
                            </div>
                    </li> */}

                    {loadingTickets ? <>
                        <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-pb-2">
                            <div className="tw-flex tw-justify-center tw-mx-auto">
                                <LoadingSpinner />
                            </div>
                        </li>
                    </>: <>
                        {showPendingLoadMore && <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-pb-2">
                            <div className="tw-flex tw-justify-center tw-mx-auto">
                                <ButtonSecondary onClick={loadMoreTickets}>
                                    Load More
                                </ButtonSecondary>
                            </div>
                        </li>}
                    </>}

                    
                </ul>}

                {currentActiveTab === 'Resolved' &&

                <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-10px tw-leading-tight tw-mt-15px">
                    <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1">
                                    <span className="tw-w-220px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Category</span>
                                    </span>
                                    <span className="tw-w-320px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Subject</span>
                                    </span>
                                    <span className="tw-w-120px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Severity</span>
                                    </span>
                                    <span className="tw-w-140px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Status</span>
                                    </span>
                                    <span className="tw-w-200px tw-flex tw-cursor-pointer">
                                        <span className="tw-text-dark tw-font-semibold">Created On</span>
                                    </span>
                            </div>
                    </li>

                    {(!resolvedTickets || resolvedTickets.length === 0) && !loadingTickets && <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                        <div className="tw-flex tw-justify-center tw-mx-auto">
                            No Tickets Found
                        </div>
                    </li>}

                    {resolvedTickets.map(ticket => {
                        return <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-border-b tw-pb-2">
                            <div className="tw-flex tw-w-full tw-flex-row tw-space-x-1 tw-cursor-pointer" onClick={(e) => {
                                e.preventDefault();
                                showSupportTicket(ticket);
                            }}>
                                    <span className="tw-w-220px">
                                        <span className="tw-text-dark">{ticket.ticketCategory}</span>
                                    </span>
                                    <span className="tw-w-320px">
                                        {ticket.ticketSubject}
                                    </span>
                                    <div className="tw-w-120px tw-flex tw-items-center">
                                        <span className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-medium tw-mr-2 `}>
                                            {ticket.ticketSeverity}
                                        </span>
                                    </div>
                                    
                                    <span className="tw-w-140px">
                                        {ticket.status}
                                    </span>
                                    <span className="tw-w-200px">
                                        {moment.utc(ticket.createdDate).tz('America/New_York').format('MMM Do YYYY h:mm A')}
                                    </span>
                            </div>
                        </li>
                    })}

                    {loadingTickets ? <>
                        <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-pb-2">
                            <div className="tw-flex tw-justify-center tw-mx-auto">
                                <LoadingSpinner />
                            </div>
                        </li>
                    </>: <>
                        {showResolvedLoadMore && <li className="tw-flex tw-w-full tw-space-x-5px tw-items-center tw-pb-2">
                            <div className="tw-flex tw-justify-center tw-mx-auto">
                                <ButtonSecondary onClick={loadMoreTickets}>
                                    Load More
                                </ButtonSecondary>
                            </div>
                        </li>}
                    </>}

                    
                </ul>}
                </>}

                </>}

                
        </div>
    </>);
}

export default RemoteSupportTicketPage;