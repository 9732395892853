import { Block, LinkPrimary } from "@medforall/medforall-component-library";
import { Link } from "react-router-dom";
import Button from "../components/buttons/Buttons";
import Core from "../templates/Core";


const Rem = props => {
	return <span className="tw-text-px-rem" style={{ '--px':  props.size } as React.CSSProperties }>
		{ props.children }
	</span>
}

const NotFound = () => {
	return (
		<div className="u-min-h-full tw-flex tw-items-center tw-justify-center">
			<div className="tw-w-800px tw-mx-auto tw-font-primary-bold tw-space-y-20px">
				<h1 className="tw-font-primary-bold tw-text-primary tw-leading-none">
					<Rem size="120">
						404
					</Rem>
				</h1>
				<h2 className="tw-font-primary-bold tw-text-light tw-leading-none">
					<Rem size="60">
						We couldn't find the page <Block>you are looking for.</Block>
					</Rem>
				</h2>
				<p className="tw-font-primary-light tw-text-dark tw-pt-10px">
					No worries! You can go back to <Link to="/"><LinkPrimary>homepage</LinkPrimary></Link>, or try to <LinkPrimary onClick={ (e) => {
						e.preventDefault();
						alert("reload")
					}}>reload</LinkPrimary> the page.
				</p>
			</div>
		</div>
	);
};

export default NotFound;
