import axios, { AxiosRequestConfig } from "axios";
import { getAPIToken } from "./billingService";
import { AvailabilityPeriod } from "../types/availabilityPeriod";
import { ProviderAvailability } from "../types/providerAvailability";
import { ProviderAvailabilityLog } from "../types/providerAvailabilityLog";
import BlobFile from "../types/blobFile";
import { ProviderRecommendation } from "../types/providerRecomendation";
import moment from "moment";

export const getCurrentAvailabilityPeriods = async (handleAPICallError: (error: any) => void) : Promise<AvailabilityPeriod[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'availability/current';
    return axios.get<AvailabilityPeriod[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getAvailabilityPeriodByID = async (periodID: string, handleAPICallError: (error: any) => void) : Promise<AvailabilityPeriod | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'availabilityPeriod/' + periodID;
    return axios.get<AvailabilityPeriod>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const submitProviderAvailability = async (periodID: string, providerAvailability: ProviderAvailability, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'availabilityPeriod/' + periodID + '/availability ';
    return axios.post(url,providerAvailability,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateProviderAvailability = async (periodID: string, availabilityID: string, providerAvailability: ProviderAvailability, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'availabilityPeriod/' + periodID + '/availability/' + availabilityID;
    return axios.put(url,providerAvailability,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getProviderAvailability = async (periodID: string, handleAPICallError: (error: any) => void) : Promise<ProviderAvailability | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'availabilityPeriod/' + periodID + '/availability ';
    return axios.get<ProviderAvailability>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getAvailabilityPeriodResponseCount = async (periodID: string, handleAPICallError: (error: any) => void) : Promise<number> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'availabilityPeriod/' + periodID + '/availability/count';
    return axios.get<number>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return 0;
          });
}

export const getProviderAvailabilitiesByPeriodID = async (periodID: string, handleAPICallError: (error: any) => void) : Promise<ProviderAvailability[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'availabilityPeriod/' + periodID + '/availabilities';
    return axios.get<ProviderAvailability[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getProviderAvailabilityByID = async (availabilityID: string, handleAPICallError: (error: any) => void) : Promise<ProviderAvailability | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'availabilitySubmissions/' + availabilityID;
    return axios.get<ProviderAvailability>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getProviderAvailabilityLogs = async (availabilityID: string, handleAPICallError: (error: any) => void) : Promise<ProviderAvailabilityLog[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'availabilitySubmissions/' + availabilityID + '/logs';
    return axios.get<ProviderAvailabilityLog[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getAvalabilityFormResponsesExcelFile = async (periodID: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;

    const url = baseURL + "availabilityPeriod/" + periodID + "/availabilityFormResponses";
    

    return axios.get<BlobFile>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getProviderRecommendations = async (serviceType: string, serviceDate: string, handleAPICallError: (error: any) => void) : Promise<ProviderRecommendation[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    var parsedDate = moment(serviceDate).format('MM-DD-YYYY');
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'recommendations/' + serviceType + '/' + parsedDate;
    return axios.get<ProviderRecommendation[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}