import moment from "moment";
import { useAppContext } from "../../Context";
import { ButtonError, ButtonErrorSecondary, ButtonPrimary, ButtonSecondary, ButtonWarning, ButtonWarningSecondary } from "../buttons/Buttons";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSpanRequirementContext } from "../../pages/Scheduling/ClientRequirement";
import { set } from "lodash";
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import { isMidnight } from "./SchedulePanel";

export const SubmitDemo = props => {
	const navContext = useAppContext();

	const handleOnClick = () => {
		console.log('Demo test', navContext)
	}

	return <>
		<ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleOnClick();
		}}>
			Demo
		</ButtonSecondary>
	</>
}

export const InfoDefault = props => {
    const navContext = useAppContext();

    const handleOnClick = () => {
        console.log('Info Default Test:', navContext);
    }

    return <>
        <ButtonPrimary  className="tw-flex tw-justify-center tw-min-w-120px tw-mx-auto" onClick={ e => {
            e.preventDefault();
            handleOnClick();
            if (props.buttonEvent) props.buttonEvent();
        }}>
            { props.button || "View" }
        </ButtonPrimary>
    </>
}

export const TimeOffPanelCTA = props => {
    const navContext = useAppContext();
	const [timeOffURL, setTimeOffURL] = useState<string>(props.timeOffURL);

	useLayoutEffect(() => {
		if (props.timeOffURL) setTimeOffURL(props.timeOffURL)
	}, [props.timeOffURL])

    const handleOnClick = () => {
        console.log('TimeOffPanelCTA Test:', navContext);

		// open Time-Off URL in new tab
		window.open(timeOffURL, '_blank');
    }

    return <>
        <ButtonPrimary  className="tw-flex tw-justify-center tw-min-w-120px tw-mx-auto" onClick={ e => {
            e.preventDefault();
            handleOnClick();
        }}>
            { "Manage" }
        </ButtonPrimary>
    </>
}

export const SubmitDefault = props => {
	const navContext = useAppContext();

	const handleOnClick = () => {
		console.log('Demo test', navContext)
	}

	return <>
		<ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleOnClick();
			if (props.button1Event) props.button1Event();
		}}>
			{ props.button1 || "Discard" }
		</ButtonSecondary>
		<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleOnClick();
			if (props.button2Event) props.button2Event();
		}}>
			{ props.button2 || "Save" }
		</ButtonPrimary>
	</>
}

export const SubmitNewSpanRequirement = props => {
    const navContext = useAppContext();
	const [actionLoading, setActionLoading] = useState(false);

	const handleDiscard = () => {
		let text = `Are you sure you want to Discard? \n Your entered data will be lost.`;
		if (window.confirm(text) == true) {
			console.log('SubmitNewSpanRequirement test', navContext);
        	navContext.closeSideNav();
		}
		
	}

    const handleSave = () => {
        console.log('SubmitNewSpanRequirement test', navContext);
        if (validateForm()) {
            console.log('Form is valid');
			setActionLoading(true);
            if (props.onSubmit) props.onSubmit(navContext.values);
        } else {
            console.log('Form is invalid');
            console.log(navContext.errors);
            console.log(navContext.errorMessages);
        }
    }

    useEffect(() => {
        console.log('navContext.errors', navContext.errors);
        console.log('navContext.errorMessages', navContext.errorMessages);
    }, [navContext.errors, navContext.errorMessages]);

    const validateForm = () => {
		var isValid = true;
		console.log(navContext.values);
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if start_time is valid
		if (navContext.values.start_time.length === 0 || !moment(navContext.values.start_time, 'hh:mm A', true).isValid()) {
            contextErrors['start_time'] = true;
            contextErrorMessages['start_time'] = 'Invalid time';
			// navContext.setErrors({ ...navContext.errors, start_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, start_time: 'Invalid date format' });
			isValid = false;
		} else {
            contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';
        }
		// check if end_time is valid
		if (navContext.values.end_time.length === 0 || !moment(navContext.values.end_time, 'hh:mm A', true).isValid()) {
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'Invalid time';
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'Invalid date format' });
			isValid = false;
		} else if (!moment(navContext.values.end_time, 'hh:mm A').isAfter(moment(navContext.values.start_time, 'hh:mm A')) && !isMidnight(moment(navContext.values.end_time, 'h:mm A'))) {
            // check if end_time is greater than start_time
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'End time must be greater than start time';
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'End time must be greater than start time' });
			isValid = false;
		} else {
            contextErrors['end_time'] = false;
            contextErrorMessages['end_time'] = '';
        }
		// check if shift_type is valid
		if (!navContext.values.shift_type) {
            contextErrors['shift_type'] = true;
            contextErrorMessages['shift_type'] = 'Shift type is required';
			// navContext.setErrors({ ...navContext.errors, shift_type: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, shift_type: 'Shift type is required' });
			isValid = false;
		} else {
            contextErrors['shift_type'] = false;
            contextErrorMessages['shift_type'] = '';
        }
		// check if at least one day is selected
		if (!navContext.values.repeats_monday && !navContext.values.repeats_tuesday && !navContext.values.repeats_wednesday && !navContext.values.repeats_thursday && !navContext.values.repeats_friday && !navContext.values.repeats_saturday && !navContext.values.repeats_sunday) {
			contextErrors['frequency'] = true;
            contextErrorMessages['frequency'] = 'At least one day should be selected for repeat';
            // navContext.setErrors({ ...navContext.errors, frequency: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, frequency: 'At least one day should be selected for repeat' });
			isValid = false;
		} else {
            contextErrors['frequency'] = false;
            contextErrorMessages['frequency'] = '';
        }

		// if all are valid, return true
		// else return false
		console.log(contextErrors);
		console.log(contextErrorMessages);
        navContext.setErrors(contextErrors);
        navContext.setErrorMessages(contextErrorMessages);
		return isValid;
	}

	return <>
		{actionLoading ? <>
		<div className="tw-flex tw-justify-center tw-mx-4">
            <LoadingSpinner />
        </div>
	   </> : <>
		<ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleDiscard();
		}}>
			Discard
		</ButtonSecondary>
		<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleSave();
		}}>
			Save
		</ButtonPrimary>
		</>}
	</>
}

export const SubmitNewCoverageRequirement = props => {
    const navContext = useAppContext();
	const [actionLoading, setActionLoading] = useState(false);

	const handleDiscard = () => {
		let text = `Are you sure you want to Discard? \n Your entered data will be lost.`;
		if (window.confirm(text) == true) {
			console.log('SubmitNewCoverageRequirement test', navContext);
        	navContext.closeSideNav();
		}
	}

    const handleSave = () => {
        console.log('SubmitNewCoverageRequirement test', navContext);
        if (validateForm()) {
            console.log('Form is valid');
			setActionLoading(true);
			if (props.onSubmit) props.onSubmit(navContext.values);
            //if (props.onSubmit) props.onSubmit(navContext.values);
        } else {
            console.log('Form is invalid');
            console.log(navContext.errors);
            console.log(navContext.errorMessages);
        }
    }

    useEffect(() => {
        console.log('navContext.errors', navContext.errors);
        console.log('navContext.errorMessages', navContext.errorMessages);
    }, [navContext.errors, navContext.errorMessages]);

    const validateForm = () => {
		var isValid = true;
		console.log(navContext.values);
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if date is valid
		if (navContext.values.date.length === 0 || !moment(navContext.values.date, 'M-D-YYYY', true).isValid()) {
			contextErrors['date'] = true;
			contextErrorMessages['date'] = 'Invalid date';
			isValid = false;
		} else {
			contextErrors['date'] = false;
			contextErrorMessages['date'] = '';
		}

		// check if start_time is valid
		if (!navContext.values.start_time || navContext.values.start_time.length === 0 || !moment(navContext.values.start_time, 'h:mm A', true).isValid()) {
           
			contextErrors['start_time'] = true;
            contextErrorMessages['start_time'] = 'Invalid time';
			// navContext.setErrors({ ...navContext.errors, start_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, start_time: 'Invalid date format' });
			isValid = false;
		} else {
            contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';
        }
		// check if end_time is valid
		if (!navContext.values.end_time || navContext.values.end_time.length === 0 || !moment(navContext.values.end_time, 'h:mm A', true).isValid()) {
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'Invalid time';
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'Invalid date format' });
			isValid = false;
		} else if (!moment(navContext.values.end_time, 'h:mm A').isAfter(moment(navContext.values.start_time, 'h:mm A')) && !isMidnight(moment(navContext.values.end_time, 'h:mm A', true))) {
            // check if end_time is greater than start_time
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'End time must be greater than start time';
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'End time must be greater than start time' });
			isValid = false;
		} else {
            contextErrors['end_time'] = false;
            contextErrorMessages['end_time'] = '';
        }
		// check if shift_type is valid
		if (!navContext.values.shift_type) {
            contextErrors['shift_type'] = true;
            contextErrorMessages['shift_type'] = 'Shift type is required';
			// navContext.setErrors({ ...navContext.errors, shift_type: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, shift_type: 'Shift type is required' });
			isValid = false;
		} else {
            contextErrors['shift_type'] = false;
            contextErrorMessages['shift_type'] = '';
        }
		if(navContext.values.frequency !== 'Never') {
			// check if repeat_end_date is valid
			if (navContext.values.repeat_end_date.length === 0 || !moment(navContext.values.repeat_end_date, 'M-D-YYYY', true).isValid()) {
				contextErrors['repeat_end_date'] = true;
				contextErrorMessages['repeat_end_date'] = 'Invalid date';
				// navContext.setErrors({ ...navContext.errors, repeat_end_date: true });
				// navContext.setErrorMessages({ ...navContext.errorMessages, repeat_end_date: 'Invalid date format' });
				isValid = false;
			} else if (!moment(navContext.values.repeat_end_date, 'M-D-YYYY').isAfter(moment(navContext.values.date, 'M-D-YYYY'))){
				contextErrors['repeat_end_date'] = true;
				contextErrorMessages['repeat_end_date'] = 'Repeat end date must be greater than start date';
				// navContext.setErrors({ ...navContext.errors, repeat_end_date: true });
				// navContext.setErrorMessages({ ...navContext.errorMessages, repeat_end_date: 'Repeat end date must be greater than start date' });
				isValid = false;
			} else {
				contextErrors['repeat_end_date'] = false;
				contextErrorMessages['repeat_end_date'] = '';
			}

			if(navContext.values.frequency === 'Weekly'){
				// check if at least one day is selected
				if (!navContext.values.repeats_monday && !navContext.values.repeats_tuesday && !navContext.values.repeats_wednesday && !navContext.values.repeats_thursday && !navContext.values.repeats_friday && !navContext.values.repeats_saturday && !navContext.values.repeats_sunday) {
					contextErrors['frequency'] = true;
					contextErrorMessages['frequency'] = 'At least one day should be selected for repeat';
					// navContext.setErrors({ ...navContext.errors, frequency: true });
					// navContext.setErrorMessages({ ...navContext.errorMessages, frequency: 'At least one day should be selected for repeat' });
					isValid = false;
				} else {
					contextErrors['frequency'] = false;
					contextErrorMessages['frequency'] = '';
				}
			}
		}

		// check if reason is valid
		if (!navContext.values.req_reason || navContext.values.req_reason.length === 0) {
			contextErrors['req_reason'] = true;
			contextErrorMessages['req_reason'] = 'Reason is required';
			// navContext.setErrors({ ...navContext.errors, req_reason: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, req_reason: 'Reason is required' });
			isValid = false;
		} else {
			contextErrors['req_reason'] = false;
			contextErrorMessages['req_reason'] = '';
			if (navContext.values.req_reason && navContext.values.req_reason === "Custom") {
				if (!navContext.values.custom_reason || navContext.values.custom_reason.length === 0) {
					contextErrors['custom_reason'] = true;
					contextErrorMessages['custom_reason'] = 'Custom reason is required';
					// navContext.setErrors({ ...navContext.errors, custom_reason: true });
					// navContext.setErrorMessages({ ...navContext.errorMessages, custom_reason: 'Custom reason is required' });
					isValid = false;
				} else {
					contextErrors['custom_reason'] = false;
					contextErrorMessages['custom_reason'] = '';
				}
			} 
		}

		// if all are valid, return true
		// else return false
		console.log(contextErrors);
		console.log(contextErrorMessages);
        navContext.setErrors(contextErrors);
        navContext.setErrorMessages(contextErrorMessages);
		return isValid;
	}

	return <>
		{actionLoading ? <>
		<div className="tw-flex tw-justify-center tw-mx-4">
            <LoadingSpinner />
        </div>
	   </> : <>
		<ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleDiscard();
		}}>
			Discard
		</ButtonSecondary>
		<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleSave();
		}}>
			Save
		</ButtonPrimary>
		</>}
	</>
}

export const SubmitNewClientScheduleNotice = props => {
	const navContext = useAppContext();
	const [actionLoading, setActionLoading] = useState(false);

	const handleDiscard = () => {
		let text = `Are you sure you want to Discard? \n Your entered data will be lost.`;
		if (window.confirm(text) == true) {
			//console.log('SubmitNewCoverageRequirement test', navContext);
        	navContext.closeSideNav();
		}
	}

	const handleSave = () => {
		if (validateForm()) {
            console.log('Form is valid');
			setActionLoading(true);
			if (props.onSubmit) props.onSubmit(navContext.values);
            //if (props.onSubmit) props.onSubmit(navContext.values);
        } else {
            console.log('Form is invalid');
            console.log(navContext.errors);
            console.log(navContext.errorMessages);
        }
	}

	const validateForm = () => {
		var isValid = true;
		console.log(navContext.values);
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if start_date is valid
		if (navContext.values.start_date.length === 0 || !moment(navContext.values.start_date, 'M-D-YYYY', true).isValid()) {
			contextErrors['start_date'] = true;
			contextErrorMessages['start_date'] = 'Invalid date';
			isValid = false;
		} else {
			contextErrors['start_date'] = false;
			contextErrorMessages['start_date'] = '';
		}

		// check if end_date is valid
		if (navContext.values.end_date.length === 0 || !moment(navContext.values.end_date, 'M-D-YYYY', true).isValid()) {
			contextErrors['end_date'] = true;
			contextErrorMessages['end_date'] = 'Invalid date';
			isValid = false;
		} else if (!moment(navContext.values.end_date, 'M-D-YYYY').isAfter(moment(navContext.values.start_date, 'M-D-YYYY'))){
			contextErrors['end_date'] = true;
			contextErrorMessages['end_date'] = 'End date must be greater than start date';
			isValid = false;
		} else {
			contextErrors['end_date'] = false;
			contextErrorMessages['end_date'] = '';
		}

		// check if start_time is valid
		if (!navContext.values.start_time || navContext.values.start_time.length === 0 || !moment(navContext.values.start_time, 'h:mm A', true).isValid()) {
			contextErrors['start_time'] = true;
			contextErrorMessages['start_time'] = 'Invalid time';
			isValid = false;
		} else {
			contextErrors['start_time'] = false;
			contextErrorMessages['start_time'] = '';
		}

		// check if end_time is valid
		if (!navContext.values.end_time || navContext.values.end_time.length === 0 || !moment(navContext.values.end_time, 'h:mm A', true).isValid()) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'Invalid time';
			isValid = false;
		} else if (moment(navContext.values.end_date + " " + navContext.values.end_time, 'M-D-YYYY h:mm A').isBefore(moment(navContext.values.start_date + " " +  navContext.values.start_time, 'M-D-YYYY h:mm A'))) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'End time must be greater than start time';
			isValid = false;
		} else {
			contextErrors['end_time'] = false;
			contextErrorMessages['end_time'] = '';
		}

		// check if notice_type is valid
		if (!navContext.values.notice_type || navContext.values.notice_type.length === 0) {
			contextErrors['notice_type'] = true;
			contextErrorMessages['notice_type'] = 'Notice type is required';
			isValid = false;
		} else {
			contextErrors['notice_type'] = false;
			contextErrorMessages['notice_type'] = '';
		}

		// check if comment is valid
		if (!navContext.values.comment || navContext.values.comment.length === 0) {
			contextErrors['comment'] = true;
			contextErrorMessages['comment'] = 'Comment is required';
			isValid = false;
		} else {
			contextErrors['comment'] = false;
			contextErrorMessages['comment'] = '';
		}

		// if all are valid, return true
		// else return false
		console.log(contextErrors);
		console.log(contextErrorMessages);
		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
		return isValid;
	}

	return <>
		{actionLoading ? <>
		<div className="tw-flex tw-justify-center tw-mx-4">
            <LoadingSpinner />
        </div>
	   </> : <>
		<ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleDiscard();
		}}>
			Discard
		</ButtonSecondary>
		<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleSave();
		}}>
			Save
		</ButtonPrimary>
		</>}
	</>
}

export const EditSpanRequirementBottomPanel = props => {
    const navContext = useAppContext();
	const [actionLoading, setActionLoading] = useState(false);

	const handleCancel = () => {
		console.log('EditSpanRequirement Cancel', navContext);
		if(props.onCancel) props.onCancel();
	}

	const handleDiscard = () => {
		let text = `Are you sure you want to Discard? \n Your changes will not be saved.`;
		if (window.confirm(text) == true) {
			console.log('EditSpanRequirement Discard', navContext);
			if(props.onDiscard) props.onDiscard();
		}

        //navContext.closeSideNav();
	}

    const handleSave = () => {
        console.log('EditSpanRequirement Save', navContext);
        if (validateForm()) {
            console.log('Form is valid');
			setActionLoading(true);
            if (props.onSave) props.onSave(navContext.values);
        } else {
            console.log('Form is invalid');
            console.log(navContext.errors);
            console.log(navContext.errorMessages);
        }
    }

    useEffect(() => {
        console.log('navContext.errors', navContext.errors);
        console.log('navContext.errorMessages', navContext.errorMessages);
    }, [navContext.errors, navContext.errorMessages]);

	const validateTime = (value) =>  {
		// Try parsing as format "HH:mm" 
		const format1 = moment(value.trim().replace(/\s/g, ''), 'hh:mmA', true);
		
		if(format1.isValid()) {
		  return true; 
		}
		
		// Try parsing as format "HH:mm:ss"
		const format2 = moment(value.trim().replace(/\s/g, ''), 'h:mmA', true);
		
		if(format2.isValid()) {
		  return true;
		}
	  
		// Invalid if neither parses
		return false;
	  }

	const parseTime = (value) => {
		// Try parsing as format "HH:mm"
		const format1 = moment(value.trim().replace(/\s/g, ''), 'hh:mmA', true);

		if (format1.isValid()) {
			return format1;
		}

		// Try parsing as format "HH:mm:ss"
		const format2 = moment(value.trim().replace(/\s/g, ''), 'h:mmA', true);

		if (format2.isValid()) {
			return format2;
		}

		// Invalid if neither parses
		return null;
	}

    const validateForm = () => {
		var isValid = true;
		console.log(navContext.values);
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if start_time is valid
		if (navContext.values.start_time.length === 0 || !validateTime(navContext.values.start_time)) {
            contextErrors['start_time'] = true;
            contextErrorMessages['start_time'] = 'Invalid date';
			// navContext.setErrors({ ...navContext.errors, start_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, start_time: 'Invalid date format' });
			isValid = false;
		} else {
            contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';
        }
		// check if end_time is valid
		if (navContext.values.end_time.length === 0 || !validateTime(navContext.values.end_time)) {
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'Invalid date';
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'Invalid date format' });
			isValid = false;
		} else if (!parseTime(navContext.values.end_time).isAfter(parseTime(navContext.values.start_time)) && !isMidnight(parseTime(navContext.values.end_time))) {
            // check if end_time is greater than start_time
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'End time must be greater than start time';
			console.log("End time must be greater than start time in Bottom Panel");
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'End time must be greater than start time' });
			isValid = false;
		} else {
            contextErrors['end_time'] = false;
            contextErrorMessages['end_time'] = '';
        }
		// check if shift_type is valid
		if (!navContext.values.shift_type) {
            contextErrors['shift_type'] = true;
            contextErrorMessages['shift_type'] = 'Shift type is required';
			// navContext.setErrors({ ...navContext.errors, shift_type: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, shift_type: 'Shift type is required' });
			isValid = false;
		} else {
            contextErrors['shift_type'] = false;
            contextErrorMessages['shift_type'] = '';
        }

		// if all are valid, return true
		// else return false
		console.log(contextErrors);
		console.log(contextErrorMessages);
        navContext.setErrors(contextErrors);
        navContext.setErrorMessages(contextErrorMessages);
		return isValid;
	}

	return <>
		{actionLoading ? <>
		<div className="tw-flex tw-justify-center tw-mx-4">
            <LoadingSpinner />
        </div>
	   </> : <>
		<ButtonErrorSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleCancel();
		}}>
			Remove
		</ButtonErrorSecondary>
		<ButtonWarningSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleDiscard();
		}}>
			Discard
		</ButtonWarningSecondary>
		<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleSave();
		}}>
			Save
		</ButtonPrimary>
		</>}
	</>
}

export const EditCoverageRequirementBottomPanel = (props) => {
	const navContext = useAppContext();
	const [actionLoading, setActionLoading] = useState(false);

	const handleCancel = () => {
		console.log('EditSpanRequirement Cancel', navContext);
		if(props.onCancel) props.onCancel();
		
	}

	const handleDiscard = () => {
		let text = `Are you sure you want to Discard? \n Your changes will not be saved.`;
		if (window.confirm(text) == true) {
			console.log('EditSpanRequirement Discard', navContext);
			if(props.onDiscard) props.onDiscard();
		}

        //navContext.closeSideNav();
	}

    const handleSave = () => {
        console.log('EditSpanRequirement Save', navContext);
		
        if (validateForm()) {
            console.log('Form is valid');
			setActionLoading(true);
            if (props.onSave) props.onSave(navContext.values);
        } else {
            console.log('Form is invalid');
            console.log(navContext.errors);
            console.log(navContext.errorMessages);
        }
    }

	useEffect(() => {
        console.log('navContext.errors', navContext.errors);
        console.log('navContext.errorMessages', navContext.errorMessages);
    }, [navContext.errors, navContext.errorMessages]);

    const validateForm = () => {
		var isValid = true;
		console.log("Validating form");
		console.log(navContext.values);
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if date is valid
		if (navContext.values.date.length === 0 || !moment(navContext.values.date, 'M-D-YYYY', true).isValid()) {
			contextErrors['date'] = true;
			contextErrorMessages['date'] = 'Invalid date';
			isValid = false;
		} else {
			contextErrors['date'] = false;
			contextErrorMessages['date'] = '';
		}

		// check if start_time is valid
		if (!navContext.values.start_time || navContext.values.start_time.length === 0 || !moment(navContext.values.start_time, 'h:mm A', true).isValid()) {
			console.log(!navContext.values.start_time);
			console.log(navContext.values.start_time.length);
			console.log(moment(navContext.values.start_time, 'h:mm A', true).isValid());
			contextErrors['start_time'] = true;
            contextErrorMessages['start_time'] = 'Invalid time';
			// navContext.setErrors({ ...navContext.errors, start_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, start_time: 'Invalid date format' });
			isValid = false;
		} else {
            contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';
        }
		// check if end_time is valid
		if (!navContext.values.end_time || navContext.values.end_time.length === 0 || !moment(navContext.values.end_time, 'h:mm A', true).isValid()) {
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'Invalid time';
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'Invalid date format' });
			isValid = false;
		} else if (!moment(navContext.values.end_time, 'h:mm A').isAfter(moment(navContext.values.start_time, 'h:mm A')) && !isMidnight(moment(navContext.values.end_time, 'h:mm A'))) {
            // check if end_time is greater than start_time
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'End time must be greater than start time';
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'End time must be greater than start time' });
			isValid = false;
		} else {
            contextErrors['end_time'] = false;
            contextErrorMessages['end_time'] = '';
        }
		// check if shift_type is valid
		if (!navContext.values.shift_type) {
            contextErrors['shift_type'] = true;
            contextErrorMessages['shift_type'] = 'Shift type is required';
			// navContext.setErrors({ ...navContext.errors, shift_type: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, shift_type: 'Shift type is required' });
			isValid = false;
		} else {
            contextErrors['shift_type'] = false;
            contextErrorMessages['shift_type'] = '';
        }

		// check if reason is valid
		if (!navContext.values.req_reason || navContext.values.req_reason.length === 0) {
			contextErrors['req_reason'] = true;
			contextErrorMessages['req_reason'] = 'Reason is required';
			// navContext.setErrors({ ...navContext.errors, req_reason: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, req_reason: 'Reason is required' });
			isValid = false;
		} else {
			contextErrors['req_reason'] = false;
			contextErrorMessages['req_reason'] = '';
		}

		// if all are valid, return true
		// else return false
		console.log(contextErrors);
		console.log(contextErrorMessages);
        navContext.setErrors(contextErrors);
        navContext.setErrorMessages(contextErrorMessages);
		return isValid;
	}

	return <>
	   {actionLoading ? <>
		<div className="tw-flex tw-justify-center tw-mx-4">
            <LoadingSpinner />
        </div>
	   </> : <>
	   {/* <ButtonErrorSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleCancel();
		}}>
			Void
		</ButtonErrorSecondary> */}
		<ButtonWarningSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleDiscard();
		}}>
			Discard
		</ButtonWarningSecondary>
		<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleSave();
		}}>
			Save
		</ButtonPrimary>
	   </>}
		
	</>
}

export const AddNewAssignedEventBottomPanel = (props) => {
	const navContext = useAppContext();
	const [actionLoading, setActionLoading] = useState(false);

	const handleCancel = () => {
		console.log('AddNewAssignedEvent Cancel', navContext);
		if(props.onCancel) props.onCancel();
	}

	const handleSave = () => {
		console.log('AddNewAssignedEvent Save', navContext);
		
		if (validateForm()) {
			console.log('Form is valid');
			console.log(navContext.values);
			setActionLoading(true);
			if (props.onSave) props.onSave(navContext.values);
		} else {
			console.log('Form is invalid');
			console.log(navContext.errors);
			console.log(navContext.errorMessages);
		}
	}

	useEffect(() => {
		console.log('navContext.errors', navContext.errors);
		console.log('navContext.errorMessages', navContext.errorMessages);
	}, [navContext.errors, navContext.errorMessages]);

	const validateForm = () => {
		return true;
	}

	return <>
		{actionLoading ? <>
			<div className="tw-flex tw-justify-center tw-mx-4">
				<LoadingSpinner />
			</div>
		</> : <>
			<ButtonErrorSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
				e.preventDefault();
				handleCancel();
			}}>
				Back
			</ButtonErrorSecondary>
			<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
				e.preventDefault();
				handleSave();
			}}>
				Assign
			</ButtonPrimary>
		</>}
	</>

}

export const EditSessionBottomPanel = (props) => {
	const navContext = useAppContext();
	const [actionLoading, setActionLoading] = useState(false);

	const handleDelete = () => {
		console.log('EditSessionInfo Delete', navContext);
		if(props.onDelete) props.onDelete();
		
	}

	const handleDiscard = () => {
		let text = `Are you sure you want to Discard? \n Your changes will not be saved.`;
		if (window.confirm(text) == true) {
			console.log('EditSessionInfo Discard', navContext);
			if(props.onDiscard) props.onDiscard();
		}

        //navContext.closeSideNav();
	}

    const handleSave = () => {
        console.log('EditSessionInfo Save', navContext);
		
        if (validateForm()) {
            console.log('Form is valid');
			setActionLoading(true);
            if (props.onSave) props.onSave(navContext.values);
        } else {
            console.log('Form is invalid');
            console.log(navContext.errors);
            console.log(navContext.errorMessages);
        }
    }

	useEffect(() => {
        console.log('navContext.errors', navContext.errors);
        console.log('navContext.errorMessages', navContext.errorMessages);
    }, [navContext.errors, navContext.errorMessages]);

    const validateForm = () => {
		var isValid = true;
		console.log("Validating form");
		console.log(navContext.values);
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if start_date is valid
		if (navContext.values.start_date.length === 0 || !moment(navContext.values.start_date, 'M-D-YYYY', true).isValid()) {
			contextErrors['start_date'] = true;
			contextErrorMessages['start_date'] = 'Invalid date';
			isValid = false;
		} else {
			contextErrors['start_date'] = false;
			contextErrorMessages['start_date'] = '';
		}

		// check if start_time is valid
		if (!navContext.values.start_time || navContext.values.start_time.length === 0 || !moment(navContext.values.start_time, 'h:mm A', true).isValid()) {
			console.log(!navContext.values.start_time);
			console.log(navContext.values.start_time.length);
			console.log(moment(navContext.values.start_time, 'h:mm A', true).isValid());
			contextErrors['start_time'] = true;
            contextErrorMessages['start_time'] = 'Invalid time';
			// navContext.setErrors({ ...navContext.errors, start_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, start_time: 'Invalid date format' });
			isValid = false;
		} else {
            contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';
        }

		// check if end_date is valid
		if (navContext.values.end_date.length === 0 || !moment(navContext.values.end_date, 'M-D-YYYY', true).isValid()) {
			contextErrors['end_date'] = true;
			contextErrorMessages['end_date'] = 'Invalid date';
			isValid = false;
		} else if (!moment(navContext.values.end_date, 'M-D-YYYY').isSameOrAfter(moment(navContext.values.start_date, 'M-D-YYYY'))){
			contextErrors['end_date'] = true;
			contextErrorMessages['end_date'] = 'End date must be same or greater than start date';
			isValid = false;
		} else {
			contextErrors['end_date'] = false;
			contextErrorMessages['end_date'] = '';
		}

		// check if end_time is valid
		if (!navContext.values.end_time || navContext.values.end_time.length === 0 || !moment(navContext.values.end_time, 'h:mm A', true).isValid()) {
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'Invalid time';
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'Invalid date format' });
			isValid = false;
		} else if (!moment(navContext.values.end_time, 'h:mm A').isAfter(moment(navContext.values.start_time, 'h:mm A')) && !isMidnight(moment(navContext.values.end_time, 'h:mm A')) && !moment(navContext.values.end_date, 'M-D-YYYY').isAfter(moment(navContext.values.start_date, 'M-D-YYYY'))) {
            // check if end_time is greater than start_time
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'End time must be greater than start time';
			// navContext.setErrors({ ...navContext.errors, end_time: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, end_time: 'End time must be greater than start time' });
			isValid = false;
		} else {
            contextErrors['end_time'] = false;
            contextErrorMessages['end_time'] = '';
        }

		// check if provider is valid
		if (!navContext.values.provider) {
			contextErrors['provider'] = true;
			contextErrorMessages['provider'] = 'Select a provider';
			// navContext.setErrors({ ...navContext.errors, provider: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, provider: 'Provider is required' });
			isValid = false;
		} else {
			contextErrors['provider'] = false;
			contextErrorMessages['provider'] = '';
		}

		// if all are valid, return true
		// else return false
		console.log(contextErrors);
		console.log(contextErrorMessages);
        navContext.setErrors(contextErrors);
        navContext.setErrorMessages(contextErrorMessages);
		return isValid;
	}

	return <>
	   {actionLoading ? <>
		<div className="tw-flex tw-justify-center tw-mx-4">
            <LoadingSpinner />
        </div>
	   </> : <>
	   <ButtonErrorSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleDelete();
		}}>
			Delete
		</ButtonErrorSecondary>
		<ButtonWarningSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleDiscard();
		}}>
			Discard
		</ButtonWarningSecondary>
		<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleSave();
		}}>
			Save
		</ButtonPrimary>
	   </>}
		
	</>
}

export const ManageSessionShiftsBottomPanel = (props) => {
	const navContext = useAppContext();

	const handleCancel = () => {
		console.log('ManageSessionShifts Cancel', navContext);
		if(props.onCancel) props.onCancel();
	}

	return <>
	   {<>
		<ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleCancel();
		}}>
			Back To Session
		</ButtonSecondary>
		{/* <ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleSave();
		}}>
			Save
		</ButtonPrimary> */}
	   </>}
		
	</>;
}

export const ConfirmSessionChangesBottomPanel = (props) => {
	const navContext = useAppContext();
	const [actionLoading, setActionLoading] = useState(false);
	const [recurring, setRecurring] = useState(props.recurring || false);

	const handleCancel = () => {
		console.log('ConfirmSessionChanges Cancel', navContext);
		if(props.onCancel) props.onCancel();
	}

	const handleSave = () => {
		console.log('ConfirmSessionChanges Save', navContext);
		
		console.log(navContext.values);
		setActionLoading(true);
		if (props.onSave) props.onSave(props.updatedSession);

	}

	const handleSaveRecurring = () => {
		console.log('ConfirmSessionChanges Save Recurring', navContext);
		
		console.log(navContext.values);
		setActionLoading(true);
		if (props.onSaveRecurring) props.onSaveRecurring(props.updatedSession);

	}

	useEffect(() => {
		console.log('navContext.errors', navContext.errors);
		console.log('navContext.errorMessages', navContext.errorMessages);
	}, [navContext.errors, navContext.errorMessages]);


	return <>
		{actionLoading ? <>
			<div className="tw-flex tw-justify-center tw-mx-4">
				<LoadingSpinner />
			</div>
		</> : <>
			<ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
				e.preventDefault();
				handleCancel();
			}}>
				Cancel
			</ButtonSecondary>
			{recurring && <ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
				e.preventDefault();
				handleSaveRecurring();
			}}>
				Update Series
			</ButtonPrimary>}
			<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
				e.preventDefault();
				handleSave();
			}}>
				Update
			</ButtonPrimary>
		</>}
	</>;
}

export const ClaimRemoteSupportSessionBottomPanel = (props) => {
	const navContext = useAppContext();
	const [actionLoading, setActionLoading] = useState(false);

	const [openSession, setOpenSession] = useState(props.openSession);

	useEffect(() => {
		if (props.openSession) {
			setOpenSession(props.openSession);
		}
	}, [props.openSession]);

	const handleCancel = () => {
		console.log('ClaimRemoteSupportSession Cancel', navContext);
		if(props.onCancel) props.onCancel();
	}

	const handleSave = () => {
		console.log('ClaimRemoteSupportSession Save', navContext);
		
		if (validateForm()) {
			console.log('Form is valid');
			console.log(navContext.values);
			setActionLoading(true);
			if (props.onSave) props.onSave(navContext.values);
		} else {
			console.log('Form is invalid');
			console.log(navContext.errors);
			console.log(navContext.errorMessages);
		}
	}

	useEffect(() => {
		console.log('navContext.errors', navContext.errors);
		console.log('navContext.errorMessages', navContext.errorMessages);
	}, [navContext.errors, navContext.errorMessages]);

	const validateForm = () => {
		var isValid = true;

		console.log("Validating form");
		console.log(navContext.values);
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		var openSessionStartTime = moment(openSession?.displayStartTime, 'h:mm A', true);
		var openSessionEndTime = moment(openSession?.displayEndTime, 'h:mm A', true);

		if (openSessionEndTime.isBefore(openSessionStartTime)) {
			openSessionEndTime.add(1, 'd');
		}

		var endTime = moment(navContext.values.end_time, 'h:mm A', true);
        var startTime = moment(navContext.values.start_time, 'h:mm A', true);

		if (endTime.isBefore(startTime)) {
            endTime.add(1, 'd');
        }

		// Validate Start Time
		if (!navContext.values.start_time || navContext.values.start_time.length === 0 || !moment(navContext.values.start_time, 'h:mm A', true).isValid()) {
			contextErrors['start_time'] = true;
			contextErrorMessages['start_time'] = 'Invalid time';
			isValid = false;
		} else if (moment(navContext.values.start_time, 'h:mm A', true).isBefore(openSessionStartTime) || moment(navContext.values.start_time, 'h:mm A', true).isAfter(openSessionEndTime)) {
			contextErrors['start_time'] = true;
			contextErrorMessages['start_time'] = 'Start time must be between available time period';
			console.log("Start time must be between available time period in Schedule Day Panel");
			isValid = false;
		} else if (parseInt(moment(navContext.values.start_time, 'h:mm A', true).format('mm')) % 30 !== 0) {
			contextErrors['start_time'] = true;
			contextErrorMessages['start_time'] = 'Start time must be in 30 minute increments';
			console.log("Start time must be in 30 minute increments in Schedule Day Panel");
			isValid = false;
		} else {
			contextErrors['start_time'] = false;
			contextErrorMessages['start_time'] = '';
		}

		// Validate End Time
		if (!navContext.values.end_time || navContext.values.end_time.length === 0 || !moment(navContext.values.end_time, 'h:mm A', true).isValid()) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'Invalid time';
			isValid = false;
		} else if (navContext.values.start_time && moment(navContext.values.start_time, 'h:mm A', true).isValid() && moment(navContext.values.end_time, 'h:mm A').isBefore(moment(navContext.values.start_time, 'h:mm A')) && !isMidnight(moment(navContext.values.end_time, 'h:mm A', true))) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'End time must be after start time';
			console.log("End time must be greater than start time in Schedule Day Panel");
			isValid = false;
		} else if (endTime.isBefore(openSessionStartTime) || endTime.isAfter(openSessionEndTime)) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'End time must be between available time period';
			console.log("End time must be between available time period in Schedule Day Panel");
			isValid = false;
		} else if (parseInt(moment(navContext.values.end_time, 'h:mm A', true).format('mm')) % 30 !== 0) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'End time must be in 30 minute increments';
			console.log("End time must be in 30 minute increments in Schedule Day Panel");
			isValid = false;
		} else {
			contextErrors['end_time'] = false;
			contextErrorMessages['end_time'] = '';
		}

		// Validate Duration 
		if(contextErrors['start_time'] === false && contextErrors['end_time'] === false) {
			var start = moment(navContext.values.start_time, 'h:mm A', true);
			var end = moment(navContext.values.end_time, 'h:mm A', true);

			// duration in hours
			var duration = moment.duration(end.diff(start)).asHours();

			console.log("Duration", duration);

			if (duration < 0) {
				duration = moment.duration(end.add(1, 'd').diff(start)).asHours();
			}

			if(duration < 1) {
	
				contextErrors['end_time'] = true;
				contextErrorMessages['end_time'] = 'Duration must be at least 1 hour';
			}
			// check if duration is multiple of 30 minutes
			else if(duration % 0.5 !== 0) {
				contextErrors['end_time'] = true;
				contextErrorMessages['end_time'] = 'Duration must be in 30 minute increments';
			}

		}
	
		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);


		return isValid;
	}

	return <>
		{actionLoading ? <>
			<div className="tw-flex tw-justify-center tw-mx-4">
				<LoadingSpinner />
			</div>
		</> : <>
			<ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
				e.preventDefault();
				handleCancel();
			}}>
				Cancel
			</ButtonSecondary>
			<ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
				e.preventDefault();
				handleSave();
			}}>
				Claim
			</ButtonPrimary>
		</>}
	</>;
}

export const ViewSessionLogsBottomPanel = (props) => {
	const navContext = useAppContext();

	const handleCancel = () => {
		console.log('View Session Logs Cancel', navContext);
		if(props.onCancel) props.onCancel();
	}

	return <>
	   {<>
		<ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleCancel();
		}}>
			Back To Session
		</ButtonSecondary>
		{/* <ButtonPrimary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
			e.preventDefault();
			handleSave();
		}}>
			Save
		</ButtonPrimary> */}
	   </>}
		
	</>;
}

export default SubmitDefault;