import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ButtonPrimary, ButtonSecondary, Input } from "@medforall/medforall-component-library";
import { useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { AuthorizedService } from "../../types/servicePlan";
import moment from "moment";
import { EditAuthorizedServiceList } from "../portalComponents/editAuthorizedServiceCard";

export const ServiceDataSchema = Yup.object().shape({
    serviceTitle: Yup.string().required('Service title is required'),
    serviceCode: Yup.string().required('Service code is required'),
    staffSize: Yup.number()
      .required('Staff size is required')
      .positive('Staff size must be a positive number')
      .integer('Staff size must be a whole number'),
    groupSize: Yup.number()
      .required('Group size is required')
      .positive('Group size must be a positive number')
      .integer('Group size must be a whole number'),
    totalAuthorizedBudget: Yup.number()
      .required('Total authorized budget is required')
      .positive('Total authorized budget must be a positive number'),
    totalAuthorizedUnits: Yup.number()
      .required('Total authorized units is required')
      .positive('Total authorized units must be a positive number')
      .integer('Total authorized units must be a whole number'),
    budgetStartDate: Yup.date().required('Budget start date is required'),
    budgetEndDate: Yup.date()
    .min(Yup.ref('budgetStartDate'), 'Budget end date must be budget span start date.')
    .required('Budget end date is required'),
  });

const NewAuthorizedSection = () => {
    const planContext = useNewPlanContext();

    const [showAuthorizedServiceCard, setShowAuthorizedServiceCard] = useState(false);

    const serviceInitialValues = {
        serviceTitle: '',
        serviceCode: '',
        staffSize: 1,
        groupSize: 1,
        totalAuthorizedBudget: 0.0,
        totalAuthorizedUnits: 0,
        budgetStartDate: '',
        budgetEndDate: '',
    };

    const serviceFormik = useFormik({
        initialValues: serviceInitialValues,
        validationSchema: ServiceDataSchema,
        onSubmit: (values) => {
            console.log(values);

            var newService: AuthorizedService = {
                authorizedServiceID: "",
                servicePlanID: "",
                serviceTitle: values.serviceTitle,
                serviceCode: values.serviceCode,
                staffSize: values.staffSize,
                groupSize: values.groupSize,
                authorizedBudget: values.totalAuthorizedBudget,
                utilizedBudget: 0,
                authorizedUnits: values.totalAuthorizedUnits,
                utilizedUnits: 0,
                budgetStartDate: values.budgetStartDate,
                budgetEndDate: values.budgetEndDate,
                evvServiceID: "",
                evvServiceName: "",
                payer: "",
                payerProgram: "",
                procedureCode: "",
                displayStartDate: moment(values.budgetStartDate).format("MM/DD/YYYY"),
                displayEndDate: moment(values.budgetEndDate).format("MM/DD/YYYY"),
            }

            planContext.setPlanAuthorizedServices([...planContext.planAuthorizedServices, newService]);
            serviceFormik.resetForm();
            toggleAuthorizedServiceCard();
        },
    });

    const toggleAuthorizedServiceCard = () => {
        setShowAuthorizedServiceCard(!showAuthorizedServiceCard);
        serviceFormik.resetForm();
    }

    const handleRemoveAuthorizedService = (service) => {
        const index = planContext.planAuthorizedServices.indexOf(service);
        const newItems = [...planContext.planAuthorizedServices.slice(0, index), ...planContext.planAuthorizedServices.slice(index + 1)];
        planContext.setPlanAuthorizedServices(newItems);
    }



    return (<>
        {showAuthorizedServiceCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-title"
                                                    id="service-title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.serviceTitle && serviceFormik.errors.serviceTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('serviceTitle')}
                                                    />
                                                    {serviceFormik.touched.serviceTitle && serviceFormik.errors.serviceTitle && <p className="tw-text-red-500">{serviceFormik.errors.serviceTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Code
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-code"
                                                    id="service-code"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.serviceCode && serviceFormik.errors.serviceCode ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('serviceCode')}
                                                    />
                                                    {serviceFormik.touched.serviceCode && serviceFormik.errors.serviceCode && <p className="tw-text-red-500">{serviceFormik.errors.serviceCode}</p>}
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Staff Size
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="staff-size"
                                                    id="group-size"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.staffSize && serviceFormik.errors.staffSize ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('staffSize')}
                                                    />
                                                    {serviceFormik.touched.staffSize && serviceFormik.errors.staffSize && <p className="tw-text-red-500">{serviceFormik.errors.staffSize}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Group Size
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="group-size"
                                                    id="group-size"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.groupSize && serviceFormik.errors.groupSize ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('groupSize')}
                                                    />
                                                    {serviceFormik.touched.groupSize && serviceFormik.errors.groupSize && <p className="tw-text-red-500">{serviceFormik.errors.groupSize}</p>}
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Total Authorized Cost
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="total-cost"
                                                    id="total-cost"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.totalAuthorizedBudget && serviceFormik.errors.totalAuthorizedBudget ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('totalAuthorizedBudget')}
                                                    />
                                                    {serviceFormik.touched.totalAuthorizedBudget && serviceFormik.errors.totalAuthorizedBudget && <p className="tw-text-red-500">{serviceFormik.errors.totalAuthorizedBudget}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Total Authorized Units
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="total-units"
                                                    id="total-units"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.totalAuthorizedUnits && serviceFormik.errors.totalAuthorizedUnits ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('totalAuthorizedUnits')}
                                                    />
                                                    {serviceFormik.touched.totalAuthorizedUnits && serviceFormik.errors.totalAuthorizedUnits && <p className="tw-text-red-500">{serviceFormik.errors.totalAuthorizedUnits}</p>}
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Budget Start Date
                                                </label>
                                                <div className="tw-mt-2">
                                                <Input
                                                    min="2000-01-01" max="2024-12-31"
                                                    type="date"
                                                    name="budget-start-date"
                                                    id="budget-start-date"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.budgetStartDate && serviceFormik.errors.budgetStartDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('budgetStartDate')}
                                                    />
                                                    {serviceFormik.touched.budgetStartDate && serviceFormik.errors.budgetStartDate && <p className="tw-text-red-500">{serviceFormik.errors.budgetStartDate}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Budget End Date
                                                </label>
                                                <div className="tw-mt-2">
                                                <Input
                                                    min="2000-01-01" max="2024-12-31"
                                                    type="date"
                                                    name="budget-end-date"
                                                    id="budget-end-date"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.budgetEndDate && serviceFormik.errors.budgetEndDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('budgetEndDate')}
                                                    />
                                                    {serviceFormik.touched.budgetEndDate && serviceFormik.errors.budgetEndDate && <p className="tw-text-red-500">{serviceFormik.errors.budgetEndDate}</p>}
                                                </div>
                                            </div>
                                    </div>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleAuthorizedServiceCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={serviceFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleAuthorizedServiceCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Authorized Service</span>
                                            </div>  
                                    </div>
                            </div>
                            </>}

                            {/* {planContext.planAuthorizedServices.length > 0 && planContext.planAuthorizedServices?.map((service, index) => (
                                <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mt-4">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-title"
                                                    id="service-title"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={service.serviceTitle}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Code
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-code"
                                                    id="service-code"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={service.serviceCode}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Staff Size
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="staff-size"
                                                    id="group-size"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={service.staffSize}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Group Size
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="group-size"
                                                    id="group-size"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={service.groupSize}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Total Authorized Cost
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="total-cost"
                                                    id="total-cost"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={service.authorizedBudget}
                                                    disabled={true}
                                                    />
                                                    {serviceFormik.touched.totalAuthorizedBudget && serviceFormik.errors.totalAuthorizedBudget && <p className="tw-text-red-500">{serviceFormik.errors.totalAuthorizedBudget}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Total Authorized Units
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="total-units"
                                                    id="total-units"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={service.authorizedUnits}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Budget Start Date
                                                </label>
                                                <div className="tw-mt-2">
                                                <Input
                                                    min="2000-01-01" max="2024-12-31"
                                                    type="date"
                                                    name="budget-start-date"
                                                    id="budget-start-date"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={service.budgetStartDate}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Budget End Date
                                                </label>
                                                <div className="tw-mt-2">
                                                <Input
                                                    min="2000-01-01" max="2024-12-31"
                                                    type="date"
                                                    name="budget-end-date"
                                                    id="budget-end-date"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={service.budgetEndDate}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                    </div>

                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mt-md" onClick={e => handleRemoveAuthorizedService(service)}>
                                                Remove
                                        </ButtonSecondary>

                                    </div>
                            </div>
                            ))} */}

                            <div className="tw-mt-4">
                                {planContext.planAuthorizedServices.length > 0 && <EditAuthorizedServiceList services={planContext.planAuthorizedServices} newPlan={true}></EditAuthorizedServiceList>}
                            </div>
    </>);
}

export default NewAuthorizedSection