import { useNavigate, useParams } from "react-router-dom";
import { AvailabilityPeriod } from "../../types/availabilityPeriod";
import { useLayoutEffect, useState } from "react";
import { HolidayAvailability, ProviderAvailability, SchoolBreakAvailability } from "../../types/providerAvailability";
import { useAppContext } from "../../Context";
import { getAvailabilityPeriodByID, getProviderAvailabilityByID, getProviderAvailabilityLogs } from "../../apicalls/availabilityService";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { LabelOutputs } from "../../components/utilities/Helpers";
import moment from "moment";
import DailyAvailabilityComponent from "../../components/availabilityComponents/dailyAvailabilityComponent";
import CheckboxComponent from "../../components/availabilityComponents/checkBoxComponent";
import { ProviderAvailabilityLog } from "../../types/providerAvailabilityLog";
import CommentBlock from "../../components/containers/CommentBlock";
import { GenericBreadCrumbs } from "../Scheduling/SchedulingUtils";
import SchoolBreakComponent from "../../components/availabilityComponents/schoolBreakComponent";

const AvailabilitySubmission = props => {
    const navContext = useAppContext();
    let navigate = useNavigate();
    const { periodID, submissionID } = useParams();
    const [period, setPeriod] = useState<AvailabilityPeriod>();
    const [loadingSubmission, setLoadingSubmission] = useState(true);
    const [submission, setSubmission] = useState<ProviderAvailability>();
    const [submissionLogs, setSubmissionLogs] = useState<ProviderAvailabilityLog[]>([]);

    const [mondayAvailability, setMondayAvailability] = useState(undefined);
    const [tuesdayAvailability, setTuesdayAvailability] = useState(undefined);
    const [wednesdayAvailability, setWednesdayAvailability] = useState(undefined);
    const [thursdayAvailability, setThursdayAvailability] = useState(undefined);
    const [fridayAvailability, setFridayAvailability] = useState(undefined);
    const [saturdayAvailability, setSaturdayAvailability] = useState(undefined);
    const [sundayAvailability, setSundayAvailability] = useState(undefined);

    const [holidayAvailability, setHolidayAvailability] = useState<HolidayAvailability[]>([]);

    const [schoolBreaksAvailability, setSchoolBreaksAvailability] = useState<SchoolBreakAvailability[]>([]);

    useLayoutEffect(() => {
        const fetchPeriod = async () => {
            setLoadingSubmission(true);

            const currentPeriod = await getAvailabilityPeriodByID(periodID, navContext.handleAPICallError);

            console.log(currentPeriod);

            currentPeriod.holidays.sort((a, b) => {
                return moment.utc(a.date).diff(moment.utc(b.date));
            });

            setPeriod(currentPeriod);

            const availability = await getProviderAvailabilityByID(submissionID, navContext.handleAPICallError);

            console.log(availability);

            if(availability.available){
                if(availability.weeklyAvailability.Monday.isAvailable) {
                    availability.weeklyAvailability.Monday.specificTimeBlocks.forEach((block) => {
                        const date = moment(availability.submissionDate).format('YYYY-MM-DD');
                        const startTimeString = block.start;
                        const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                        const endTimeString = block.end;
                        const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                        block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                        block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                    });
                }
                if(availability.weeklyAvailability.Tuesday.isAvailable) {
                    availability.weeklyAvailability.Tuesday.specificTimeBlocks.forEach((block) => {
                        const date = moment(availability.submissionDate).format('YYYY-MM-DD');
                        const startTimeString = block.start;
                        const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                        const endTimeString = block.end;
                        const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                        // console.log("Start time: " + startTime);
                        // console.log("End time: " + endTime);
                        // console.log("Date: " + date);
                        // console.log(`${date}T${startTime}Z`);
                        block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                        block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                    });
                }
                if(availability.weeklyAvailability.Wednesday.isAvailable) {
                    availability.weeklyAvailability.Wednesday.specificTimeBlocks.forEach((block) => {
                        const date = moment(availability.submissionDate).format('YYYY-MM-DD');
                        const startTimeString = block.start;
                        const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                        const endTimeString = block.end;
                        const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                        block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                        block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                    });
                }
                if(availability.weeklyAvailability.Thursday.isAvailable) {
                    availability.weeklyAvailability.Thursday.specificTimeBlocks.forEach((block) => {
                        const date = moment(availability.submissionDate).format('YYYY-MM-DD');
                        const startTimeString = block.start;
                        const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                        const endTimeString = block.end;
                        const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                        block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                        block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                    });
                }
                if(availability.weeklyAvailability.Friday.isAvailable) {
                    availability.weeklyAvailability.Friday.specificTimeBlocks.forEach((block) => {
                        const date = moment(availability.submissionDate).format('YYYY-MM-DD');
                        const startTimeString = block.start;
                        const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                        const endTimeString = block.end;
                        const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                        block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                        block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                    });
                }
                if(availability.weeklyAvailability.Saturday.isAvailable) {
                    availability.weeklyAvailability.Saturday.specificTimeBlocks.forEach((block) => {
                        const date = moment(availability.submissionDate).format('YYYY-MM-DD');
                        const startTimeString = block.start;
                        const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                        const endTimeString = block.end;
                        const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                        block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                        block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                    });
                }
                if(availability.weeklyAvailability.Sunday.isAvailable) {
                    availability.weeklyAvailability.Sunday.specificTimeBlocks.forEach((block) => {
                        const date = moment(availability.submissionDate).format('YYYY-MM-DD');
                        const startTimeString = block.start;
                        const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                        const endTimeString = block.end;
                        const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                        block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                        block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                    });
                }
    
                setMondayAvailability(availability.weeklyAvailability.Monday);
                setTuesdayAvailability(availability.weeklyAvailability.Tuesday);
                setWednesdayAvailability(availability.weeklyAvailability.Wednesday);
                setThursdayAvailability(availability.weeklyAvailability.Thursday);
                setFridayAvailability(availability.weeklyAvailability.Friday);
                setSaturdayAvailability(availability.weeklyAvailability.Saturday);
                setSundayAvailability(availability.weeklyAvailability.Sunday);
    
                if(availability.hasHolidays){
                    availability.holidaysAvailability.sort((a, b) => {
                        return moment.utc(a.date).diff(moment.utc(b.date));
                    });
                    setHolidayAvailability(availability.holidaysAvailability);
                }

                if(availability.hasBreaks && availability.schoolBreaksAvailability != null){
                    availability.schoolBreaksAvailability.sort((a, b) => {
                        return moment.utc(a.startDate).diff(moment.utc(b.startDate));
                    });

                    availability.schoolBreaksAvailability.forEach((schoolBreak) => {
                        schoolBreak.schoolBreakDays?.forEach((day) => {
                            if(day.isAvailable) {
                                day.specificTimeBlocks?.forEach((block) => {
                                    const date = moment(day.schoolBreakDate).format('YYYY-MM-DD');
                                    const startTimeString = block.start;
                                    const startTime = moment.utc(startTimeString).format('HH:mm:ss');
                                    const endTimeString = block.end;
                                    const endTime = moment.utc(endTimeString).format('HH:mm:ss');
                                    block.displayStart = moment.utc(`${date}T${startTime}Z`).tz('America/New_York').format('h:mm A');
                                    block.displayEnd = moment.utc(`${date}T${endTime}Z`).tz('America/New_York').format('h:mm A');
                                });
                            }
                        });
                    });

                    // previousSubmission.schoolBreaksAvailability.forEach((schoolBreak) => {
                    //     schoolBreak.schoolBreakDays?.sort((a, b) => {
                    //         return moment.utc(a.schoolBreakDate).diff(moment.utc(b.schoolBreakDate));
                    //     });
                    // });


                    setSchoolBreaksAvailability(availability.schoolBreaksAvailability);
                }

                if(!availability.feedbackComment || availability.feedbackComment === ""){
                    availability.feedbackComment = "No comments";
                }
            }

            

            setSubmission(availability);

            const availabilityLogs = await getProviderAvailabilityLogs(submissionID, navContext.handleAPICallError);

            console.log(availabilityLogs.sort((a, b) => {
                return moment.utc(a.loggedTime).diff(moment.utc(b.loggedTime));
            }));

            setSubmissionLogs(availabilityLogs.sort((a, b) => {
                return moment.utc(a.loggedTime).diff(moment.utc(b.loggedTime));
            }));

            setLoadingSubmission(false);
        }

        fetchPeriod();
    }, []);
    
    return (<>
     <div className="tw-px-xl tw-py-sm">
                {/* <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Availability Submissions {period ? " - " + period.name: ""}{submission ? ", Provider: " + submission.providerName: ""}</h2>
                </div> */}
                {loadingSubmission ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>: 
                <div className="tw-flex tw-flex tw-flex-col tw-space-y-20px tw-text-dark">
                    <div className="tw-space-y-20px tw-pb-2 tw-border-b tw-border-gray-800">
					<GenericBreadCrumbs 
                    mainHeading={(period?.name) + " Submissions"}
                    subHeading={submission?.providerName}
                    backURL={"/availability/submissions/" + periodID + "/submissions"}
					/>
				</div>
                    <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
							<span className="tw-flex-grow">
                                <span className="tw-text-dark tw-font-semibold">Availability Period</span>
							</span>
					</span>
                    <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-border-b tw-border-gray-800 tw-pb-5">
                            <li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Period Name
									</LabelOutputs>
								</span>
								<span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{period?.name}
								</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Start Date
										</LabelOutputs>
									</span>
									<span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                        {moment.utc(period?.startDate).format('dddd, MMM Do YYYY')}
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										End Date
										</LabelOutputs>
									</span>
									<span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                        {moment.utc(period?.endDate).format('dddd, MMM Do YYYY')}
									</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Provider Name
									</LabelOutputs>
								</span>
								<span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{submission?.providerName}
								</span>
							</li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
									<span className="tw-w-180px tw-flex tw-items-center">
										<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Submission Date
										</LabelOutputs>
									</span>
									<span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                        {moment.utc(submission?.submissionDate).format('dddd, MMM Do YYYY')}
									</span>
							</li>
                        </ul>
                        <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                    <span className="tw-flex-grow">
                                        <span className={`tw-text-dark tw-font-semibold`}>Available to Work During the Specified Availability Period</span>
                                    </span>
                                </span>
                                <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                                    <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-180px tw-flex tw-items-center">
                                            <LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
                                                Available To Work
                                            </LabelOutputs>
                                        </span>
                                        <span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                            {submission?.available ? "Yes" : "No"}
                                        </span>
                                    </li>
                                    {!submission?.available && <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-180px tw-flex tw-items-center">
                                            <LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
                                                Reason for Unavailability
                                            </LabelOutputs>
                                        </span>
                                        <span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                            {submission?.unavailabilityComment}
                                        </span>
                                    </li>}
                                </ul>
                        </div>

                        {submission?.available && <>
                            <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                    <span className="tw-flex-grow">
                                        <span className={`tw-text-dark tw-font-semibold`}>Weekly Hours</span>
                                    </span>
                                </span>
                                <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                                    <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-180px tw-flex tw-items-center">
                                            <LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
                                                Minimum Hours
                                            </LabelOutputs>
                                        </span>
                                        <span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                            {submission?.weeklyHours.minimum}
                                        </span>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-180px tw-flex tw-items-center">
                                            <LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
                                                Maximum Hours
                                            </LabelOutputs>
                                        </span>
                                        <span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                            {submission?.weeklyHours.maximum}
                                        </span>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center">
                                        <span className="tw-w-180px tw-flex tw-items-center">
                                            <LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
                                                Desired Hours
                                            </LabelOutputs>
                                        </span>
                                        <span className="tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
                                            {submission?.weeklyHours.desired}
                                        </span>
                                    </li>
                                </ul>
                            </div>

                            <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                    <span className="">
                                        <span className={`tw-text-dark tw-font-semibold`}>Support Types : </span>
                                    </span>
                                    {submission?.directSupportShifts && submission?.remoteSupportShifts && <span className="tw-flex tw-items-center tw-text-black tw-text-5 tw-pl-20px tw-pr-20px tw-">
                                            Direct Support & Remote Support
                                    </span>}

                                    {submission?.directSupportShifts && !submission?.remoteSupportShifts && <span className="tw-flex tw-items-center tw-text-5 tw-text-black tw-pl-20px tw-pr-20px">
                                            Direct Support
                                    </span>}

                                    {!submission?.directSupportShifts && submission?.remoteSupportShifts && <span className="tw-flex tw-items-center tw-text-5 tw-text-black tw-pl-20px tw-pr-20px">
                                            Remote Support
                                    </span>}
                                </span>

                                


                            </div>

                            <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                    <span className="tw-flex-grow">
                                        <span className={`tw-text-dark tw-font-semibold`}>Daily Availabilities</span>
                                    </span>
                                </span>
                                <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                                    <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                        <DailyAvailabilityComponent dayOfWeek="Monday" setDailyAvailablity={setMondayAvailability} editMode={false} dailyAvailability={mondayAvailability}/>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                        <DailyAvailabilityComponent dayOfWeek="Tuesday" setDailyAvailablity={setTuesdayAvailability} editMode={false} dailyAvailability={tuesdayAvailability}/>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                        <DailyAvailabilityComponent dayOfWeek="Wednesday" setDailyAvailablity={setWednesdayAvailability} editMode={false} dailyAvailability={wednesdayAvailability}/>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                        <DailyAvailabilityComponent dayOfWeek="Thursday" setDailyAvailablity={setThursdayAvailability} editMode={false} dailyAvailability={thursdayAvailability}/>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                        <DailyAvailabilityComponent dayOfWeek="Friday" setDailyAvailablity={setFridayAvailability} editMode={false} dailyAvailability={fridayAvailability}/>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                        <DailyAvailabilityComponent dayOfWeek="Saturday" setDailyAvailablity={setSaturdayAvailability} editMode={false} dailyAvailability={saturdayAvailability}/>
                                    </li>
                                    <li className="tw-flex tw-space-x-5px tw-items-center tw-border tw-border-gray-500 tw-p-4">
                                        <DailyAvailabilityComponent dayOfWeek="Sunday" setDailyAvailablity={setSundayAvailability} editMode={false} dailyAvailability={sundayAvailability}/>
                                    </li>
                                    
                                </ul>
                                
                            </div>

                            {period?.hasHolidays && <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                    <span className="tw-flex-grow">
                                        <span className={`tw-text-dark tw-font-semibold`}>Holiday Availability</span>
                                    </span>
                                </span>
                                <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                                    {period?.holidays?.map((holiday, index) => {
                                        return (
                                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                                <CheckboxComponent
                                                    holiday={holiday}
                                                    editMode={false}
                                                    isAvailable={holidayAvailability[index].isAvailable}
                                                    isUnAvailable={holidayAvailability[index].isAvailable === false ? true : false}
                                                />
                                            </li>);
                                    })}
                                </ul>
                                
                            </div>}

                            {period?.hasBreaks && <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className={`tw-text-dark tw-font-semibold`}>School Break Availability</span>
                                </span>
                            </span>
                            <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px">
                            {schoolBreaksAvailability?.map((schoolBreak, index) => {
                                return (<>
                                    <SchoolBreakComponent 
                                        breakAvailability={schoolBreak}
                                        editMode={false}
                                        />
                                </>)
                            })}
                            </ul>
                            </div>}

                            <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                    <span className="tw-flex-grow">
                                        <span className={`tw-text-dark tw-font-semibold`}>Comments</span>
                                    </span>
                                </span>
                                <span className="tw-flex tw-items-center tw-text-black tw-pr-20px">
                                    {submission?.feedbackComment}
                                </span>

                            </div>
                        </>}

                        {submissionLogs?.length > 0 && <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                            <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                <span className="tw-flex-grow">
                                    <span className={`tw-text-dark tw-font-semibold`}>Logs</span>
                                </span>
                            </span>
                            { submissionLogs && submissionLogs?.map( (log, index) => {
										return <CommentBlock color={"tw-bg-white"} key={`comment_${index}`} data={{
											user: {
												name: log?.loggedBy,
												image: null
											},
											comment: {
												createdOn: moment.utc(log?.loggedTime).tz('America/New_York').format('dddd, MMM Do YYYY, h:mm A'),
											}
										}}>
                                        <div className="tw-flex tw-flex-col">
                                            <span>Action: {log?.changeLog}</span>
                                            <span>Weekly Hours: {log?.weeklyHoursLog}</span>
                                            <span>Support Options: {log?.supportOptionsLog}</span>
                                            <span>Daily Availability: {log?.dailyAvailabilityLog}</span>
                                            {period?.hasHolidays && <span>Holiday Availability: {log?.holidayAvailabilityLog}</span>}
                                            {period?.hasBreaks && <span>Breaks: {log?.schoolBreakAvailabilityLog}</span>}
                                        </div>
									</CommentBlock>
									})}
                        </div>}
                </div>}

                </div>
                </>);
}

export default AvailabilitySubmission;