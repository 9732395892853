import { useLayoutEffect, useState } from "react";
import { Label, Select } from "../../components/forms/formFields"
import { LabelOutputs } from "../../components/utilities/Helpers"
import { useParams } from "react-router-dom";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";

export const RSABreadCumbs = props => {
	const Tag = props.tag || "h2";

	const handleOnClick = e => {
		// e.preventDefault();
		if (props.onClick) props.onClick(e);
	}

	return <>
		<Tag className="tw-text-3 tw-text-dark tw-space-x-5px tw-flex-shrink-0">
			<a href="#" className="tw-text-dark/70 hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px">
				<LabelOutputs className="tw-text-in" icon="/images/icon-arrow-right.svg" reverse>
					On-Call Calendar
				</LabelOutputs>
			</a>
			<a href={ `/schedule/rsa` } onClick={ handleOnClick } className="tw-text-dark hover:tw-text-primary tw-inline-flex tw-font-primary-bold tw-items-center tw-min-h-30px">
				<span>
					RSA Calendar
				</span>
			</a>
		</Tag>
	</>
}

const RSALocationBlock = props => {
	const attributes = {
		className: null
	}
	return <>
		<a href="#" { ...attributes } className={`${ props.className } tw-flex tw-flex-col tw-w-full  tw-bg-white tw-rounded-10px tw-overflow-hidden tw-group tw-border-2px tw-border-transparent hover:tw-border-primary`}>
			<span className="tw-relative tw-aspect-[16/9] tw-w-full">
				<img src="/images/placeholder_16x9.png" alt=""  className="tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-object-center" />
			</span>
			<span className="tw-flex tw-flex-col tw-py-15px tw-space-y-10px tw-leading-none tw-text-dark group-hover:tw-text-primary-light tw-px-20px">
				<span className="tw-font-primary tw-font-primary-bold">
					Lorem ipsum
				</span>
				<LabelOutputs icon="/images/icon-location.svg" className="tw-leading-none tw-opacity-50 tw-text-dark group-hover:tw-opacity-100">
					12345 First Street,
					<span className="tw-block">Columbus, OH</span>
				</LabelOutputs>
			</span>
		</a>
	</>
}

const RSALocationResults = props => {
	return <>
		<div className="tw-flex tw-flex-col tw-h-vh tw-overflow-hidden">
			<div className="tw-flex-shrink-0 tw-flex tw-flex-col tw-pt-40px tw-pb-30px">
				<RSABreadCumbs />
				<div className="tw-flex tw-justify-between tw-pt-60px tw-items-center">
					<div className="tw-flex-grow">
						<h3 className="tw-font-primary-bold tw-text-3 tw-text-black">
							Choose Locations
						</h3>
						<p className="tw-text-5 tw-text-dark">
							Which locations are you looking for?
						</p>
					</div>
					<div className="tw-flex-grow tw-max-w-300px tw-flex-shrink-0">
						<div className="tw-relative">
							<Label for="locations" icon="/images/icon-location.svg" className="tw-z-0 tw-absolute tw-left-15px tw-top-12px  tw-items-center">
								<span className="tw-hidden">Locations</span>
							</Label>
							<Select id="locations" className="tw-w-full !tw-bg-transparent tw-relative tw-z-2 !tw-border-dark" childClasses="!tw-pl-40px !tw-bg-transparent !tw-border-dark">
								<option>Columbus</option>
							</Select>
						</div>
					</div>
				</div>
			</div>
			<div className="tw-flex-grow tw-overflow-hidden tw-border-t tw-border-t-dark/20">
				<div className="tw-flex tw-flex-col tw-h-full tw-overflow-y-auto slider tw-pt-30px tw-pb-60px">
					<div className="tw-grid tw-grid-cols-3 tw-gap-40px">
						{ [...Array(12)].map( ( user, index ) => {
							return <RSALocationBlock className="tw-w-full" />
						})}
					</div>
				</div>
			</div>
			<div className="tw-flex-shrink-0 tw-border-t tw-border-t-dark/20">
				<div className="tw-flex tw-justify-end tw-space-x-40px tw-py-40px">
					<ButtonSecondary className="tw-min-w-100px">
						Back
					</ButtonSecondary>
					<ButtonPrimary className="tw-min-w-100px">
						Continue
					</ButtonPrimary>
				</div>
			</div>
		</div>
	</>
}


const RSLocations = props => {
	let { locationSlug } = useParams();
	const [location, setLocation] = useState<any>(null);

	useLayoutEffect(() => {
		if (locationSlug) setLocation(locationSlug)
	}, [locationSlug])

	return locationSlug ? <>
		<RSALocationResults />
	</> : <>
		<div className="tw-flex tw-flex-col tw-h-vh">
			<div className="tw-flex-shrink-0 tw-flex tw-flex-col tw-pt-40px tw-pb-60px">
				<RSABreadCumbs />
			</div>
			<div className="tw-flex-grow">
				<div className="tw-text-center">
					<div className="tw-relative tw-aspect-square tw-w-full tw-max-w-200px tw-mx-auto">
						<img src="/images/placeholder_16x9.png" alt=""  className="tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-object-center" />
					</div>
					<div className="tw-flex tw-flex-col tw-py-40px tw-space-y-10px tw-leading-none tw-px-20px">
						<span className="tw-text-3 tw-font-primary tw-font-primary-bold tw-text-black">
							Choose Locations
						</span>
						<span className="tw-text-dark">
							Which locations are you looking at?
						</span>
					</div>
				</div>
				<div>
					<Label for="locations">
						<span className="tw-text-4">
							RSA Locations
						</span>
					</Label>
					<Select id="locations" className="tw-w-full !tw-border-2px tw-relative tw-z-2 !tw-rounded-6px !tw-bg-transparent  !tw-border-primary" childClasses="!tw-px-20px !tw-bg-transparent !tw-border-2px !tw-rounded-6px !tw-border-transparent">
						<option>Columbus</option>
					</Select>
				</div>
				<div className="tw-flex tw-justify-center tw-space-x-40px tw-py-60px">
					<ButtonSecondary className="tw-min-w-100px">
						Back
					</ButtonSecondary>
					<ButtonPrimary className="tw-min-w-100px">
						Continue
					</ButtonPrimary>
				</div>
			</div>
		</div>
	</>
}

export default RSLocations;
