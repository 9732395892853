import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { UnusualEvent, UnusualEventAction } from "../../types/servicePlan";
import { EditUnusualEventList } from "../portalComponents/editUnusualEventCard";

export const ActionSchema = Yup.object().shape({
    actionDetails: Yup.string().required('Action details is required.'),
});

export const UnusualEventSchema = Yup.object().shape({
    eventTitle: Yup.string().required('Event title is required.'),
    eventDetails: Yup.string().required('Event details are required.'),
    eventActions: Yup.array().of(ActionSchema)
                    .min(1, "Please add at least one action.")
                    .required('At least one action is required.'),
});

const NewUnusualEventSection = () => {
    const planContext = useNewPlanContext();

    const [showUnusualEventCard, setShowUnusualEventCard] = useState(false);

    const initialEventValues = {
        eventTitle: '',
        eventDetails: '',
        eventActions: [],
    }

    const initialActionValues = {
        actionDetails: '',
    }

    const unusualEventFormik = useFormik({
        initialValues: initialEventValues,
        validationSchema: UnusualEventSchema,
        onSubmit: (values) => {
            console.log(values);

            var eventActions: UnusualEventAction[] = [];

            values.eventActions.forEach((action) => {
                var newAction: UnusualEventAction = {
                    unusualEventActionID: "",
                    unusualEventID: "",
                    actionDetails: action.actionDetails,
                }
                
                eventActions.push(newAction);
            })

            var newEvent: UnusualEvent = {
                unusualEventID: "",
                servicePlanID: "",
                eventTitle: values.eventTitle,
                eventDetails: values.eventDetails,
                eventActions: eventActions, 
            }

            planContext.setPlanUnusualEvents([...planContext.planUnusualEvents, newEvent]);
            toggleUnusualEventCard();
            
        }
    });

    const actionFormik = useFormik({
        initialValues: initialActionValues,
        validationSchema: ActionSchema,
        onSubmit: (values) => {
            console.log(values);
            unusualEventFormik.values.eventActions.push(values);
            unusualEventFormik.validateField('eventActions');
            actionFormik.resetForm();
        }
    });

    const toggleUnusualEventCard = () => {
        setShowUnusualEventCard(!showUnusualEventCard);
        unusualEventFormik.resetForm();
        actionFormik.resetForm();
        unusualEventFormik.setFieldValue('eventActions', []);
    }

    const handleRemoveAction = (action) => {
        const index = unusualEventFormik.values.eventActions.indexOf(action);
        unusualEventFormik.values.eventActions.splice(index, 1);
        unusualEventFormik.setFieldValue('eventActions', unusualEventFormik.values.eventActions);
    }

    const handleRemoveUnusualEvent = (event) => {
        const index = planContext.planUnusualEvents.indexOf(event);
        const newItems = [...planContext.planUnusualEvents.slice(0, index), ...planContext.planUnusualEvents.slice(index + 1)];
        planContext.setPlanUnusualEvents(newItems);
    }

    return (<>
        {showUnusualEventCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6">
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Event Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${unusualEventFormik.touched.eventTitle && unusualEventFormik.errors.eventTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...unusualEventFormik.getFieldProps('eventTitle')}
                                                    />
                                                    {unusualEventFormik.touched.eventTitle && unusualEventFormik.errors.eventTitle && <p className="tw-text-red-500">{unusualEventFormik.errors.eventTitle}</p>}
                                                </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Event Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="details"
                                                    id="details"
                                                    className={`tw-block tw-w-full tw-rounded-md ${unusualEventFormik.touched.eventDetails && unusualEventFormik.errors.eventDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...unusualEventFormik.getFieldProps('eventDetails')}
                                                    />
                                                    {unusualEventFormik.touched.eventDetails && unusualEventFormik.errors.eventDetails && <p className="tw-text-red-500">{unusualEventFormik.errors.eventDetails}</p>}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Corresponding Actions</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Action Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="actionDetails"
                                                    id="actionDetails"
                                                    className={`tw-block tw-w-full tw-rounded-md ${actionFormik.touched.actionDetails && actionFormik.errors.actionDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...actionFormik.getFieldProps('actionDetails')}
                                                    />
                                                    {actionFormik.touched.actionDetails && actionFormik.errors.actionDetails && <p className="tw-text-red-500">{actionFormik.errors.actionDetails}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={actionFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={actionFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>

                                    {unusualEventFormik.values.eventActions.length > 0 && unusualEventFormik.values.eventActions?.map((action, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-2">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Action Details
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="actionDetails"
                                                        id="actionDetails"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={action.actionDetails}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                    e.preventDefault();
                                                    handleRemoveAction(action);
                                                    }}>
                                                        Remove
                                                </ButtonSecondary>

                                            </div>
                                        </div>))} 
                                    
                                    <div>
                                        {unusualEventFormik.touched.eventActions && unusualEventFormik.errors.eventActions && <p className="tw-text-red-500"><>{unusualEventFormik.errors.eventActions}</></p>}
                                    </div>

                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleUnusualEventCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={unusualEventFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleUnusualEventCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Unusual Event</span>
                                            </div>  
                                    </div>
                            </div>
                            </>}

                            {/* {planContext.planUnusualEvents.length > 0 && planContext.planUnusualEvents?.map((unusualEvent, index) => (
                                <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mt-4">
                                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Event Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={unusualEvent.eventTitle}
                                                    disabled={true}
                                                    />
                                                </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Event Details
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="details"
                                                    id="details"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={unusualEvent.eventDetails}
                                                    disabled={true}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Event Actions</span>
                                       
                                    </div>

                                    {unusualEvent.eventActions.length > 0 && unusualEvent.eventActions?.map((action, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                                <div className="tw-col-span-2">
                                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                        Action Details
                                                    </label>
                                                    <div className="tw-mt-2">
                                                        <input
                                                        type="text"
                                                        name="actionDetails"
                                                        id="actionDetails"
                                                        className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                        value={action.actionDetails}
                                                        disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))} 


                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemoveUnusualEvent(unusualEvent);
                                                        }}>
                                                    Remove
                                            </ButtonSecondary>
                                    </div>
                                </div>
                            ))} */}

                            <div className="tw-mt-4">
                                {planContext.planUnusualEvents.length > 0 && <EditUnusualEventList events={planContext.planUnusualEvents} newPlan={true}></EditUnusualEventList>}
                            </div>
    </>);
}

export default NewUnusualEventSection;