import moment from "moment";
import { useEffect, useState } from "react";
import { SessionLog } from "../../types/sessionLog";
import CommentBlock from "../containers/CommentBlock";

const SessionLogsPanel = props => {
    const [selectedSession, setSelectedSession] = useState(null);
    const [sessionLogs, setSessionLogs] = useState<SessionLog[]>([]);

    useEffect(() => {
        if (props.selectedSession) {
            setSelectedSession(props.selectedSession);
        }

        if (props.sessionLogs) {
            setSessionLogs(props.sessionLogs);
        }
    }, [props.selectedSession, props.sessionLogs]);
    
    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full tw-relative">
            <div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
                <div className="tw-flex-shrink-0 tw-w-full">
                            <h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-pb-10px">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                    <span className="tw-flex-grow">
                                        <span className="tw-text-dark">
                                            Session Information
                                        </span>
                                    </span>
                                </span>
                            </h3>
                            <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-5px tw-leading-tight">
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Provider
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.providerName}
                                    </span>
                                </li>
								<li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Start Date
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.displayStartDate}
                                    </span>
                                </li>
                                <li className="tw-flex tw-space-x-5px tw-items-center">
                                    <span className="tw-w-150px tw-flex tw-items-center">
                                        <span className="tw-opacity-70">
                                            Time Period
                                        </span>
                                    </span>
                                    <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                        {selectedSession?.displayStartTime} - {selectedSession?.displayEndTime}
                                    </span>
									{!moment(selectedSession?.startDate).isSame(selectedSession?.endDate) && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
                                </span>}
                                </li>
                            </ul>
                </div>

                <h3 className="tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]">
                                <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full tw-pr-20px">
                                    <span>
                                        <span className="tw-text-dark">
                                            Logs
                                        </span>
                                    </span>
                                </span>
                            </h3>

                {(sessionLogs?.length === 0 || (!sessionLogs)) && <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-py-sm tw-mt-4">
											<span className="tw-text-primary tw-text-5 tw-text-center">No Logs Available!</span>
									</div>
                }

                <div className="tw-pb-40px tw-overflow-y-scroll">
									{ sessionLogs && sessionLogs?.map( (log, index) => {
										return <CommentBlock key={`comment_${index}`} data={{
											user: {
												name: log?.userMail,
												image: null
											},
											comment: {
												createdOn: log?.displayLoggedTime,
											}
										}}>
										{log?.logMessage}
									</CommentBlock>
									})}
				</div>
            </div>

            
        </div>
    </>);
}

export default SessionLogsPanel;