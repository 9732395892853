import axios, { AxiosRequestConfig } from "axios";
import { AgencyAnnouncement, AgencyAnnouncementAcknowledgement } from "../types/agencyAnnouncement";
import { getAPIToken } from "./billingService";
import { ClientAnnouncement, ClientAnnouncementAcknowledgement } from "../types/clientAnnouncement";

export const escapeSingleQuotes = (str: string): string => {
    return str.replace(/'/g, "''");
};

export const getActiveAnnouncements = async (handleAPICallError: (error: any) => void) : Promise<AgencyAnnouncement[] | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'agencyAnnouncements';
    return axios.get<AgencyAnnouncement[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getArchivedAnnouncements = async (handleAPICallError: (error: any) => void) : Promise<AgencyAnnouncement[] | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'agencyAnnouncements/archived/get';
    return axios.get<AgencyAnnouncement[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getAgencyAnnouncementByID = async (announcementID: string, handleAPICallError: (error: any) => void) : Promise<AgencyAnnouncement | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'agencyAnnouncements/' + announcementID;
    return axios.get<AgencyAnnouncement>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const addNewAgencyAnnouncement = async (announcement: AgencyAnnouncement, handleAPICallError: (error: any) => void) : Promise<AgencyAnnouncement | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'agencyAnnouncements';
    return axios.post<AgencyAnnouncement>(url,announcement,requestHeader)
        .then(response => {
            //console.log("Add Announcement Response: ", response.status, response.data);
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateAgencyAnnouncement = async (announcement: AgencyAnnouncement, handleAPICallError: (error: any) => void) : Promise<AgencyAnnouncement | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'agencyAnnouncements/' + announcement.agencyAnnouncementID;
    return axios.put<AgencyAnnouncement>(url,announcement,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const archiveAgencyAnnouncement = async (announcementID: string, handleAPICallError: (error: any) => void) : Promise<boolean> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'agencyAnnouncements/' + announcementID + '/archive';
    return axios.put<boolean>(url,null,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return false;
          });
}

export const publishAgencyAnnouncement = async (announcementID: string, handleAPICallError: (error: any) => void) : Promise<boolean> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'agencyAnnouncements/' + announcementID + '/publish';
    return axios.put<boolean>(url,null,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return false;
          });
}

export const getActiveClientAnnouncements = async (handleAPICallError: (error: any) => void) : Promise<ClientAnnouncement[] | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clientAnnouncements';
    return axios.get<ClientAnnouncement[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getArchivedClientAnnouncements = async (handleAPICallError: (error: any) => void) : Promise<ClientAnnouncement[] | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clientAnnouncements/archived/get';
    return axios.get<ClientAnnouncement[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getClientAnnouncementByID = async (announcementID: string, handleAPICallError: (error: any) => void) : Promise<ClientAnnouncement | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clientAnnouncements/' + announcementID;
    return axios.get<ClientAnnouncement>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const addNewClientAnnouncement = async (announcement: ClientAnnouncement, handleAPICallError: (error: any) => void) : Promise<ClientAnnouncement | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clientAnnouncements/' + announcement.clientID;
    return axios.post<ClientAnnouncement>(url,announcement,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const updateClientAnnouncement = async (announcement: ClientAnnouncement, handleAPICallError: (error: any) => void) : Promise<ClientAnnouncement | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clientAnnouncements/' + announcement.clientAnnouncementID;
    return axios.put<ClientAnnouncement>(url,announcement,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const archiveClientAnnouncement = async (announcementID: string, handleAPICallError: (error: any) => void) : Promise<boolean> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clientAnnouncements/' + announcementID + '/archive';
    return axios.put<boolean>(url,null,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return false;
          });
}

export const publishClientAnnouncement = async (announcementID: string, handleAPICallError: (error: any) => void) : Promise<boolean> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }
    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clientAnnouncements/' + announcementID + '/publish';
    return axios.put<boolean>(url,null,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return false;
          });
}

export const getAcknowledgementsForAgencyAnnouncement = async (announcementID: string, handleAPICallError: (error: any) => void) : Promise<AgencyAnnouncementAcknowledgement[] | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'agencyAnnouncementAcknowledgements/' + announcementID;
    return axios.get<AgencyAnnouncementAcknowledgement[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getAcknowledgementsForClientAnnouncement = async (announcementID: string, handleAPICallError: (error: any) => void) : Promise<ClientAnnouncementAcknowledgement[] | null> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_SCHEDULINGURL;
    const url = baseURL + 'clientAnnouncements/' + announcementID + '/acknowledgements';
    return axios.get<ClientAnnouncementAcknowledgement[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}