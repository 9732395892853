import { useEffect, useRef, useState } from "react";
import { getActiveClients, getAgencyBillingFlatFile, getAgencyBillingSummary, getAgencyClaimExcelFile, getAgencyErroredBillingFlatFile, getAgencyErroredBillingSummary, getAgencyMismatchBillingFlatFile, getAgencyMismatchBillingSummary, getAgencyUbsubmiitedBillingFlatFile, getAgencyUnsubmittedBillingSummary, getBillingFlatFile, getBillingFlatFileForCode, getBillingSummary, getBillingSummaryForCode, getClientBillingCodes, getClientClaimExcelFile, getErroredBillingFlatFile, getErroredBillingSummary, getMismatchBillingFlatFile, getMismatchBillingSummary, getUnBilledBillingFlatFile, getUnbilledBillingSummary } from "../apicalls/billingService";
import Button, { ButtonPrimary, ButtonSecondary, ButtonWarning } from "../components/buttons/Buttons";
import { ClaimDetailsCard, ClientClaimDetailsCard, SessionCardHours } from "../components/containers/Cards";
import { Input, Select } from "../components/forms/formFields";
import { DynamicText, PageHeader, PageSubHeader, Paragraph3 } from "../components/typography/Headers";
import { Block, Ellipsis } from "../components/utilities/Helpers";
import IntrinsicRatio from "../components/utilities/IntrinsicRatio";
import { formatDate, formatDollars } from "../hooks/useFormats";
import Core from "../templates/Core";
import Patient from "../types/patient";
import { placeholder } from "../utilities/helpers";
import { articleHeader, paragraph3 } from "../utilities/typography";
import CircularProgress from '@mui/material/CircularProgress';
import BillingSummary from "../types/billingSummary";
import Moment from 'moment';
import 'moment-timezone';
import AgencySummary from "../types/agencySummary";
import { useAppContext } from "../Context";
import CalendarSelector from "../components/calendars/CalendarSelector";

const BillingPage = props => {
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [activePatients, setActivePatients] = useState<Patient[]>([]);
	const patientRef = useRef() || { current: { value: ''}};
	const codeRef = useRef() || { current: { value: ''}};
	const claimTypeRef = useRef() || { current: { value: ''}};
	const [selectedPatient, setSelectedPatient] = useState("");
	const [selectedClaimType, setSelectedClaimType] = useState("New");
	const [summaryLoading, setSummaryLoading] = useState(false);
	const [summaryAvailabale, setSummaryAvailable] = useState(false);
	const [agencySummary, setAgencySummary] = useState<AgencySummary>();
	const [agencySummaryAvailable, setAgencySummaryAvailable] = useState(false);
	const [noClaimsFound, setNoClaimsFound] = useState(false);
	const [billingSummary, setBillingSummary] = useState<BillingSummary>();
	const [flatFileLoading, setFlatFileLoading] = useState(false);
	const [clientsLoaded, setClientsLoaded] = useState(false);
	const [showBillingCodes, setShowBillingCodes] = useState(false);
	const [clientBillingCodes, setClientBillingCodes] = useState<string[]>([]);
	const [selectedBillingCode, setSelectedBillingCode] = useState("");
	const appContext = useAppContext();

	useEffect(() => {
		const fetchClients = async () => {
			const data = await getActiveClients(appContext.handleAPICallError);
			console.log(data);
			const activePatients = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName));
			setActivePatients(activePatients);
			setClientsLoaded(true);
		}

		fetchClients()
    		.catch(console.error);
	}, []);

	const updateEndDate = (date: string) => {
		console.log("Testing End Date");
		console.log(date);
		setEndDate(date);
		
	}

	const updateStartDate = (date: string) => {
		console.log("Testing Start Date");
		console.log(date);
		setStartDate(date);
	}

	useEffect(() => {
		setShowBillingCodes(false);
		console.log(selectedPatient);

		const fetchClientBillingCodes = async (patientID) => {
			const data = await getClientBillingCodes(patientID, appContext.handleAPICallError);
			console.log(data);
			console.log(Array.isArray(data));
			setClientBillingCodes(data);
			setSelectedBillingCode("All");
			setShowBillingCodes(true);
		}

		if(selectedPatient != "All" && selectedPatient.length > 0){
			fetchClientBillingCodes(selectedPatient)
    		.catch(console.error);
		}
	}, [selectedPatient])

	

	const formatAmount = (value) => {
		var amountFormatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: 2,
			// These options are needed to round to whole numbers if that's what you want.
			//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
			//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		});

		return amountFormatter.format(value)
	}

	const generateAgencySummary = async () => {
		console.log(startDate);
		console.log(endDate);
		setSummaryLoading(true);
		setNoClaimsFound(false);

		if(selectedClaimType === "New"){
			const agencySummary = await getAgencyBillingSummary(startDate, endDate, appContext.handleAPICallError);

			if(agencySummary !== null)
			{
				setAgencySummary(agencySummary);
				setAgencySummaryAvailable(true);
			} else {
				setNoClaimsFound(true);
			}
			

			setSummaryLoading(false);
		}

		if(selectedClaimType === "UnBilled"){
			const agencySummary = await getAgencyUnsubmittedBillingSummary(startDate, endDate, appContext.handleAPICallError);

			if(agencySummary !== null)
			{
				setAgencySummary(agencySummary);
				setAgencySummaryAvailable(true);
			} else {
				setNoClaimsFound(true);
			}

			setSummaryLoading(false);
		}

		if(selectedClaimType === "Mismatch"){
			const agencySummary = await getAgencyMismatchBillingSummary(startDate, endDate, appContext.handleAPICallError);

			if(agencySummary !== null)
			{
				setAgencySummary(agencySummary);
				setAgencySummaryAvailable(true);
			} else {
				setNoClaimsFound(true);
			}

			setSummaryLoading(false);
		}

		if(selectedClaimType === "Errored"){
			const agencySummary = await getAgencyErroredBillingSummary(startDate, endDate, appContext.handleAPICallError);

			if(agencySummary !== null)
			{
				setAgencySummary(agencySummary);
				setAgencySummaryAvailable(true);
			} else {
				setNoClaimsFound(true);
			}

			setSummaryLoading(false);
		}

	}

	const generateClaimSummary = async () => {
		console.log(startDate);
		console.log(endDate);
		console.log(selectedPatient)
		setSummaryLoading(true);
		setNoClaimsFound(false);
		if(selectedClaimType === "New"){
			if(selectedBillingCode == "All"){
				const billingSummary = await getBillingSummary(selectedPatient, startDate, endDate, appContext.handleAPICallError);
				console.log(billingSummary);
				if(billingSummary !== null)
				{
					setBillingSummary(billingSummary);
					setSummaryAvailable(true);
				} else {
					setNoClaimsFound(true);
				}
				setSummaryLoading(false);
			} else {
				const billingSummary = await getBillingSummaryForCode(selectedPatient, selectedBillingCode, startDate, endDate, appContext.handleAPICallError);
				console.log(billingSummary);
				if(billingSummary !== null)
				{
					setBillingSummary(billingSummary);
					setSummaryAvailable(true);
				} else {
					setNoClaimsFound(true);
				}
				setSummaryLoading(false);
			}
		}

		if(selectedClaimType === "UnBilled"){
			const billingSummary = await getUnbilledBillingSummary(selectedPatient, startDate, endDate, appContext.handleAPICallError);
			console.log(billingSummary);
			if(billingSummary !== null)
				{
					setBillingSummary(billingSummary);
					setSummaryAvailable(true);
				} else {
					setNoClaimsFound(true);
				}
			setSummaryLoading(false);
		}

		if(selectedClaimType === "Mismatch"){
			const billingSummary = await getMismatchBillingSummary(selectedPatient, startDate, endDate, appContext.handleAPICallError);
			console.log(billingSummary);
			if(billingSummary !== null)
				{
					setBillingSummary(billingSummary);
					setSummaryAvailable(true);
				} else {
					setNoClaimsFound(true);
				}
			setSummaryLoading(false);
		}

		if(selectedClaimType === "Errored"){
			const billingSummary = await getErroredBillingSummary(selectedPatient, startDate, endDate, appContext.handleAPICallError);
			console.log(billingSummary);
			if(billingSummary !== null)
				{
					setBillingSummary(billingSummary);
					setSummaryAvailable(true);
				} else {
					setNoClaimsFound(true);
				}
			setSummaryLoading(false);
		}
		

		
	}

	const generateClaims = async () => {
		setSummaryAvailable(false);
		setAgencySummaryAvailable(false);
		console.log(startDate);
		console.log(endDate);

		if(selectedPatient === "All"){
			await generateAgencySummary();
		} else {
			await generateClaimSummary();
		}
	}

	const getFlatFile = async () => {
		setFlatFileLoading(true);

		if(selectedClaimType === "New"){
			if(selectedBillingCode == "All"){
				const flatFile = await getBillingFlatFile(selectedPatient, startDate, endDate, appContext.handleAPICallError);
	
				//console.log(flatFile);
	
				var activePatient = activePatients.find(patient => patient.patientID === selectedPatient)
	
				var fileName = "m2558727_" + activePatient.firstName + "_" + Moment().format("MMDDYY") + ".txt";
	
				if (activePatient.firstName.length > 8) {
					fileName = "m2558727_" + activePatient.firstName.substring(0,8) + "_" + Moment().format("MMDDYY") + ".txt";
				}
	
				const url = window.URL.createObjectURL(new Blob([flatFile.content], { type: 'application/octet-stream' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName); //or any other extension
				document.body.appendChild(link);
				link.click();
	
				setFlatFileLoading(false);
			} else {
				const flatFile = await getBillingFlatFileForCode(selectedPatient, selectedBillingCode, startDate, endDate, appContext.handleAPICallError);
	
				//console.log(flatFile);
	
				var activePatient = activePatients.find(patient => patient.patientID === selectedPatient)
	
				var fileName = "m2558727_" + activePatient.firstName + "_" + Moment().format("MMDDYY") + ".txt";
	
				if (activePatient.firstName.length > 8) {
					fileName = "m2558727_" + activePatient.firstName.substring(0,8) + "_" + Moment().format("MMDDYY") + ".txt";
				}
	
				const url = window.URL.createObjectURL(new Blob([flatFile.content], { type: 'application/octet-stream' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName); //or any other extension
				document.body.appendChild(link);
				link.click();
	
				setFlatFileLoading(false);
			}
		}

		
		if(selectedClaimType === "UnBilled"){
			const flatFile = await getUnBilledBillingFlatFile(selectedPatient, startDate, endDate, appContext.handleAPICallError);
	
				//console.log(flatFile);
	
				var activePatient = activePatients.find(patient => patient.patientID === selectedPatient)
	
				var fileName = "m2558727_" + activePatient.firstName + "_" + Moment().format("MMDDYY") + ".txt";
	
				if (activePatient.firstName.length > 8) {
					fileName = "m2558727_" + activePatient.firstName.substring(0,8) + "_" + Moment().format("MMDDYY") + ".txt";
				}
	
				const url = window.URL.createObjectURL(new Blob([flatFile.content], { type: 'application/octet-stream' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName); //or any other extension
				document.body.appendChild(link);
				link.click();
	
				setFlatFileLoading(false);
		}

		if(selectedClaimType === "Mismatch"){
			const flatFile = await getMismatchBillingFlatFile(selectedPatient, startDate, endDate, appContext.handleAPICallError);
	
				//console.log(flatFile);
	
				var activePatient = activePatients.find(patient => patient.patientID === selectedPatient)
	
				var fileName = "m2558727_" + activePatient.firstName + "_" + Moment().format("MMDDYY") + ".txt";
	
				if (activePatient.firstName.length > 8) {
					fileName = "m2558727_" + activePatient.firstName.substring(0,8) + "_" + Moment().format("MMDDYY") + ".txt";
				}
	
				const url = window.URL.createObjectURL(new Blob([flatFile.content], { type: 'application/octet-stream' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName); //or any other extension
				document.body.appendChild(link);
				link.click();
	
				setFlatFileLoading(false);
		}

		if(selectedClaimType === "Errored"){
			const flatFile = await getErroredBillingFlatFile(selectedPatient, startDate, endDate, appContext.handleAPICallError);
	
				//console.log(flatFile);
	
				var activePatient = activePatients.find(patient => patient.patientID === selectedPatient)
	
				var fileName = "m2558727_" + activePatient.firstName + "_" + Moment().format("MMDDYY") + ".txt";
	
				if (activePatient.firstName.length > 8) {
					fileName = "m2558727_" + activePatient.firstName.substring(0,8) + "_" + Moment().format("MMDDYY") + ".txt";
				}
	
				const url = window.URL.createObjectURL(new Blob([flatFile.content], { type: 'application/octet-stream' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName); //or any other extension
				document.body.appendChild(link);
				link.click();
	
				setFlatFileLoading(false);
		}
		
	}

	const getAgencyFlatFile = async () => {
		setFlatFileLoading(true);

		if(selectedClaimType === "New"){
			const flatFile = await getAgencyBillingFlatFile(startDate, endDate, appContext.handleAPICallError);

			var fileName = "m2558727_OAH_" + Moment().format("MMDDYY") + ".txt";

			const url = window.URL.createObjectURL(new Blob([flatFile.content], { type: 'application/octet-stream' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName); //or any other extension
			document.body.appendChild(link);
			link.click();

			setFlatFileLoading(false);
		}

		if(selectedClaimType === "UnBilled"){
			const flatFile = await getAgencyUbsubmiitedBillingFlatFile(startDate, endDate, appContext.handleAPICallError);

			var fileName = "m2558727_OAH_" + Moment().format("MMDDYY") + ".txt";

			const url = window.URL.createObjectURL(new Blob([flatFile.content], { type: 'application/octet-stream' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName); //or any other extension
			document.body.appendChild(link);
			link.click();

			setFlatFileLoading(false);
		}

		if(selectedClaimType === "Mismatch"){
			const flatFile = await getAgencyMismatchBillingFlatFile(startDate, endDate, appContext.handleAPICallError);

			var fileName = "m2558727_OAH_" + Moment().format("MMDDYY") + ".txt";

			const url = window.URL.createObjectURL(new Blob([flatFile.content], { type: 'application/octet-stream' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName); //or any other extension
			document.body.appendChild(link);
			link.click();

			setFlatFileLoading(false);
		}

		if(selectedClaimType === "Errored"){
			const flatFile = await getAgencyErroredBillingFlatFile(startDate, endDate, appContext.handleAPICallError);

			var fileName = "m2558727_OAH_" + Moment().format("MMDDYY") + ".txt";

			const url = window.URL.createObjectURL(new Blob([flatFile.content], { type: 'application/octet-stream' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName); //or any other extension
			document.body.appendChild(link);
			link.click();

			setFlatFileLoading(false);
		}

		
	}

	const getAgencyExcel = async () => {
		setFlatFileLoading(true);

		const excelBlob = await getAgencyClaimExcelFile(startDate, endDate, appContext.handleAPICallError);

		var fileName = "OAH_ClaimSummary_" + Moment().format("MMDDYY") + ".xlsx";

		const url = excelBlob.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

		setFlatFileLoading(false);
	}

	const getClientExcel = async () => {
		setFlatFileLoading(true);

		var activePatient = activePatients.find(patient => patient.patientID === selectedPatient)

		const excelBlob = await getClientClaimExcelFile(selectedPatient, startDate, endDate, appContext.handleAPICallError);

		var fileName = "OAH_"+ activePatient.firstName + "Summary_" + Moment().format("MMDDYY") + ".xlsx";

		const url = excelBlob.blobLink;
		console.log(url);
		//window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

		setFlatFileLoading(false);
	}

	const clearClaimSummary = async () => {
		setBillingSummary(null);
		setSummaryAvailable(false);
		setAgencySummaryAvailable(false);
		setStartDate('');
		setEndDate('');
		patientRef.current.value = "";
		setSelectedPatient(patientRef.current.value);
		//setSelectedPatient('');
	}

    return(<>
        {/* <Core { ...props }> */}
        <div className="tw-px-xl tw-py-xl">
				{/* <div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
					<div className="tw-flex-grow tw-flex-shrink-0">
						<PageHeader>
							<Ellipsis>
								Generate Claims
							</Ellipsis>
						</PageHeader>
					</div>
				</div> */}
				 <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Generate Claims</h2>
                </div>
				<ul className="tw-space-x-4 tw-flex tw-flex-wrap">
					<li className="tw-flex-grow">
						<Block>Client:</Block>
						<Select value="Selected Patient" ref={ patientRef } onChange={ e => {
										console.log(e, e.target, e.target.value, selectedPatient)
										setSelectedPatient(patientRef?.current?.value);
										if(patientRef?.current?.value === "All") {
											setSelectedClaimType("New");
										}
										e.preventDefault();
									}}>
							{ clientsLoaded ? <>
								<option value="">--Select Client--</option>
								<option value="All">All</option>
								{
									activePatients.map(client =>
										<option value={client.patientID}>{client.firstName} {client.lastName}</option>)
								}
							</>:<>
								<option value="">--Loading--</option>
							</>}
							
						</Select>
					</li>

					<li className="tw-flex-grow">
						<Block>Claim Type:</Block>
						<Select value="Claim Type" ref={ claimTypeRef } onChange={ e => {
										console.log(e, e.target, e.target.value, selectedClaimType)
										//setSelectedPatient(patientRef?.current?.value);
										setSelectedClaimType(claimTypeRef?.current?.value);
										e.preventDefault();
									}}>
								<option value="New">New</option>
								{/* { selectedPatient === "All" ? <> </>: <> */}
								<option value="UnBilled">Not Submitted</option>
								<option value="Mismatch">Low Reimbursement</option>
								<option value="Errored">Errored Claims</option>
								{/* </> } */}
								
							
						</Select>
					</li>
					
					
				</ul>
				<ul className="tw-space-x-4 tw-flex tw-flex-wrap">
					{showBillingCodes && selectedClaimType === "New" ? <>
							<li className="tw-flex-grow tw-mt-2">
								<Block>Billing Code:</Block>
								<Select value="Selected Code" ref={ codeRef } onChange={ e => {
												console.log(e, e.target, e.target.value, selectedBillingCode)
												setSelectedBillingCode(codeRef?.current?.value);
												e.preventDefault();
											}}>
										<option value="">--Select Code--</option>
										<option value="All">All</option>
										{Array.isArray(clientBillingCodes)
											? clientBillingCodes.map(code =>
												<option value={code}>{code}</option>)
											: null}
									
								</Select>
							</li>
						</>:<></>}

						<li className="tw-flex-grow tw-mt-1">
									{/* <Block>Span Start Date:</Block> */}
									<CalendarSelector id="startDate" name="Start Date:" onChange={ e => {
												//console.log("Success! - CalendarSelector output:", e.target.value)
												console.log(e.target.value);
												updateStartDate(e.target.value);
											}}/>
									{/* <Input
										className="tw-w-10/12"
										 min="2000-01-01" max="2024-12-31"
										type="date"
										value={ startDate } onChange={ e => {
										const temp = e.target.value || '';
										setStartDate( temp )
									} }/> */}
									{/* <Input type="tel" value={ startDate } onChange={ e => {
										const temp = e.target.value || '';
										setStartDate( formatDate(temp,'-').mmddyyyy )
									} }/> */}
					</li>
					<li className="tw-flex-grow tw-mt-1">
									<CalendarSelector id="endDate" name="End Date:" onChange={ e => {
												//console.log("Success! - CalendarSelector output:", e.target.value)
												console.log(e.target.value);
												updateEndDate(e.target.value);
											}} />
									{/* <Block>Span End Date:</Block> 
									<Input
										className="tw-w-10/12"
										 min="2000-01-01" max="2024-12-31"
										type="date"
										value={ endDate } onChange={ e => {
										const temp = e.target.value || '';
										setEndDate( temp )
									} }/> */}
									{/* <Input type="tel" value={ endDate } onChange={ e => {
										const temp = e.target.value || '';
										setEndDate( formatDate(temp,'-').mmddyyyy )
									} }/> */}
					</li>
					<li className="tw-mt-4">
						<ButtonPrimary className="tw-mt-md" onClick={generateClaims}>
							Generate
						</ButtonPrimary>
					</li>
					<li className="tw-mt-4">
									{/* <ButtonWarning className="tw-mt-md" onClick={clearClaimSummary}>
										Clear
									</ButtonWarning> */}

									<ButtonSecondary className="tw-mt-md" onClick={clearClaimSummary}>
										Clear
									</ButtonSecondary>
					</li>
				</ul>

				{ summaryLoading ? 
				<div className="tw-flex tw-justify-center tw-m-md">
					<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
				</div>
				:
				<></>
				}

				{noClaimsFound && <div className="tw-bg-white tw-px-6 tw-py-6 sm:tw-py-6 lg:tw-px-8">
					<div className="tw-mx-auto tw-max-w-2xl tw-text-center">
						<h2 className="tw-text-xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-xl">No Claims Founds</h2>
						<p className="tw-mt-6 tw-text-base tw-leading-8 tw-text-gray-600">
						 There are no claims found for the selected criteria. Please update the search criteria and try again.
						</p>
					</div>
				</div>}

				{summaryAvailabale ? <>
					<div className="tw-my-lg tw-">
						<div className="tw-bg-white">
							<div className="tw-flex tw-py">
								<div className="tw-px tw-flex-shrink-0 tw-max-w-400px tw-flex-grow">
									<h5 className={ paragraph3 }>
										Total Claim
									</h5>
									<div className="tw-flex tw-items-center">
										<div className="tw-shrink-0 tw-block tw-w-full tw-max-w-50px tw-pr-sm">
											<IntrinsicRatio className="tw-block tw-w-full" src='/images/icon-total-amount-flowers.svg' />
										</div>
										<span className="tw tw-text tw-text ">
											<DynamicText style={{'--max-font-size': '40'}}>{formatAmount(billingSummary.totalClaim)}</DynamicText>
										</span>
									</div>
								</div>
								<div className={`tw-border-l tw-max-w-350px tw-border-l-light-light tw-pl-sm tw-mx-lg ${paragraph3} tw-flex tw-items-center`}>
									<ul className="tw-list-none tw-space-y-sm tw-text-5 tw-leading-[1.125rem]">
										<li>
											<span>Total Direct Support Units:</span> <span>{billingSummary.directSupportUnits}</span>
										</li>
										<li>
											<span>Total Remote Support Units:</span> <span>{billingSummary.remoteSupportUnits}</span>
										</li>
										<li>
											<span>Total Remote Support Without Backup Units:</span> <span>{billingSummary.remoteSupportWoBackupUnits}</span>
										</li>
										<li>
											<span>Total Transportation Units:</span> <span>{billingSummary.transportationUnits}</span>
										</li>
										{/* <li>
											<span>Current Rates:</span> <span>3.35 per hours</span>
										</li> */}
									</ul>
								</div>
								<div className="tw-flex-shrink-0 tw-ml-auto tw-pr tw-flex tw-items-end tw-justify-end">
									{flatFileLoading ? 
									<>
									<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
									</>: <>
									<ul className="tw-list-none tw-space-y-sm tw-text-5 tw-leading-[1.125rem]">
										{selectedClaimType === "New" && selectedBillingCode === "All" && <li>
											<ButtonPrimary small onClick={getClientExcel}>Export Excel</ButtonPrimary>
										</li>}
										<li>
											<ButtonPrimary small onClick={getFlatFile}>Generate Flat File</ButtonPrimary>
										</li>
									</ul>
									</>}
									
								</div>
							</div>
						</div>
					</div>

					{ billingSummary.billingEntries !== null ? <>
						<div className="tw-space-y-xl">
							<div className="">
								<PageSubHeader tag="h2" className="tw-sticky tw-top-0 tw-z-10 tw-bg-light-accent tw-py-sm ">
										Claim Details
								</PageSubHeader>
								<div className={`tw-sticky tw-top-[3.625rem] tw-z-10 tw-bg-light-accent  tw-border-b tw-border-b-black/10 tw-flex tw-flex-col tw-divide-y tw-divide-light-light tw-shadow-xs tw-shadow-light-accent ${ articleHeader }`}>
										<div className="tw-flex tw-pb-xs tw-px-lg  tw-space-x-md  tw-text-dark-light">
											<Paragraph3 tag="span" childTag="span" className="tw-w-300px">
												Service Date
											</Paragraph3>
											<Paragraph3 tag="span" childTag="span" className="tw-w-150px">
												Service Type
											</Paragraph3>
											<Paragraph3 tag="span" childTag="span" className="tw-w-100px">
												Units
											</Paragraph3>
											<Paragraph3 tag="span" childTag="span" className="tw-w-150px">
												Budget
											</Paragraph3>
											<Paragraph3 tag="span" childTag="span" className="tw-flex-grow">
												Amount
											</Paragraph3>
										</div>
								</div>
									<div className={`tw-flex tw-flex-col tw-divide-y tw-divide-light-light ${ articleHeader }`}>
											{
												billingSummary.billingEntries.map((billingEntry) => {
													return	<Button className={`tw-p-0 tw-flex tw-w-full tw-bg-white hover:tw-text-black`} childClasses="tw-p-0 tw-flex tw-w-full">
													<ClaimDetailsCard
														className="tw-w-full"
														serviceDate={Moment.utc(billingEntry.serviceDate).format('dddd, MMMM DD, YYYY')}
														serviceType={billingEntry.serviceType}
														units={billingEntry.serviceUnits}
														budgetStatus={billingEntry.budgetRecordsAvailable ? `Available` : `Not Available`}
														totalAmount={formatAmount(billingEntry.serviceUnits * billingEntry.unitRate)}
													/>
												</Button>
												})
											}
										</div>
								</div>
							</div>			
										</>: <></> }
					
				 </> : <></>}

				 {agencySummaryAvailable ? <>
					<div className="tw-my-lg tw-">
						<div className="tw-bg-white">
							<div className="tw-flex tw-py">
								<div className="tw-px tw-flex-shrink-0 tw-max-w-400px tw-flex-grow">
									<h5 className={ paragraph3 }>
										Total Claim
									</h5>
									<div className="tw-flex tw-items-center">
										<div className="tw-shrink-0 tw-block tw-w-full tw-max-w-50px tw-pr-sm">
											<IntrinsicRatio className="tw-block tw-w-full" src='/images/icon-total-amount-flowers.svg' />
										</div>
										<span className="tw tw-text tw-text ">
											<DynamicText style={{'--max-font-size': '40'}}>{formatAmount(agencySummary.totalClaim)}</DynamicText>
										</span>
									</div>
								</div>
								<div className={`tw-border-l tw-max-w-350px tw-border-l-light-light tw-pl-sm tw-mx-lg ${paragraph3} tw-flex tw-items-center`}>
									<ul className="tw-list-none tw-space-y-sm tw-text-5 tw-leading-[1.125rem]">
										<li>
											<span>Total Direct Support Units:</span> <span>{agencySummary.directSupportUnits}</span>
										</li>
										<li>
											<span>Total Remote Support Units:</span> <span>{agencySummary.remoteSupportUnits}</span>
										</li>
										<li>
											<span>Total Remote Support Without Backup Units:</span> <span>{agencySummary.remoteSupportWoBackupUnits}</span>
										</li>
										<li>
											<span>Total Transportation Units:</span> <span>{agencySummary.transportationUnits}</span>
										</li>
										{/* <li>
											<span>Current Rates:</span> <span>3.35 per hours</span>
										</li> */}
									</ul>
								</div>
								<div className="tw-flex-shrink-0 tw-ml-auto tw-pr tw-flex tw-items-end tw-justify-end">
									{flatFileLoading ? 
									<>
									<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
									</>: <>
									<ul className="tw-list-none tw-space-y-sm tw-text-5 tw-leading-[1.125rem]">
										{selectedClaimType === "New" && <li>
											<ButtonPrimary small onClick={getAgencyExcel}>Export Excel</ButtonPrimary>
										</li>}
										<li>
											<ButtonPrimary small onClick={getAgencyFlatFile}>Generate Flat File</ButtonPrimary>
										</li>
									</ul>
									
									
									</>}
									
								</div>
							</div>
						</div>
					</div>

					<div className="tw-space-y-xl">
						<div className="">
							<PageSubHeader tag="h2" className="tw-sticky tw-top-0 tw-z-10 tw-bg-light-accent tw-py-sm ">
									Agency Claim Details
							</PageSubHeader>
							<div className={`tw-sticky tw-top-[3.625rem] tw-z-10 tw-bg-light-accent  tw-border-b tw-border-b-black/10 tw-flex tw-flex-col tw-divide-y tw-divide-light-light tw-shadow-xs tw-shadow-light-accent ${ articleHeader }`}>
									<div className="tw-flex tw-pb-xs tw-px-lg  tw-space-x-md  tw-text-dark-light">
										<Paragraph3 tag="span" childTag="span" className="tw-w-250px">
											Client
										</Paragraph3>
										<Paragraph3 tag="span" childTag="span" className="tw-w-150px">
											Direct Support Units
										</Paragraph3>
										<Paragraph3 tag="span" childTag="span" className="tw-w-150px">
											Remote Support Units
										</Paragraph3>
										<Paragraph3 tag="span" childTag="span" className="tw-w-150px">
											Transportation Units
										</Paragraph3>
										<Paragraph3 tag="span" childTag="span" className="tw-flex-grow">
											Amount
										</Paragraph3>
									</div>
							</div>
							<div className={`tw-flex tw-flex-col tw-divide-y tw-divide-light-light ${ articleHeader }`}>
									{
										agencySummary.billingSummaries.map((billingSummary) => {
											return	<Button className={`tw-p-0 tw-flex tw-w-full ${billingSummary.totalClaim > 0 ? "tw-bg-white": "tw-bg-red-100"}  hover:tw-text-black`} childClasses="tw-p-0 tw-flex tw-w-full">
											{/* <SessionCardHours
												className="tw-w-full"
												image={ placeholder(`100/000000/FFFFFF?text=Hi`) }
												firstName={`Kevin`}
												lastName={`Mack`}
												hours={100}
												amount={`$1000.00`}
												status={`review`}
											/> */}
											<ClientClaimDetailsCard
												className="tw-w-full"
												clientName={billingSummary.lastName + ", " + billingSummary.firstName}
												directSupportUnits={billingSummary.directSupportUnits}
												remoteSupportUnits={billingSummary.remoteSupportUnits + billingSummary.remoteSupportWoBackupUnits}
												tranportationUnits={billingSummary.transportationUnits}
												totalAmount={formatAmount(billingSummary.totalClaim)}
											></ClientClaimDetailsCard>
										</Button>
										})
									}
								</div>
							
						</div>
					</div>
				 </> : <></>}
			</div>
        {/* </Core> */}
    </>)
};

export default BillingPage;