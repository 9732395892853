import { LinearProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCoverageForSpan, getCoverageRequirementByID, getCoverageRequirementForSpan, getLegacyEventByID, getSessionsForSpan } from "../../apicalls/schedulingService";
import { getProviderSessionsForSpan, getRemoteSupportSessionByID } from "../../apicalls/sessionService";
import { useAppContext } from "../../Context";
import { LoadingItems } from "../../pages/TestPage";
import CoverageEvent from "../../types/coverageEvent";
import { rem } from "../../utilities/helpers";
import { pageSubHeader, paragraph3 } from "../../utilities/typography";
import { ButtonAnchor } from "../buttons/Buttons";
import InlineServicePanel, { InlineServiceMark, ProviderInlineServiceMark } from "../panels/InlineServicePanel";
import ServicePanel from "../panels/Service";
import { PageSubHeader } from "../typography/Headers";
import { Custom, Span } from "../utilities/Helpers";
import { Block } from "../utilities/Span";
import RemoteSupportSession from "../../types/remoteSupportSession";
import { get } from "lodash";
import SessionDetailsPanel from "../panels/SessionDetail";
import SessionInfoPanel from "../panels/SessionInfoPanel";
import SessionClaimInfoPanel from "../panels/SessionClaimInfoPanel";

const CalendarControls = props => {
	return <>
		<div className="tw-sticky tw-top-0 tw-w-full tw-bg-light-accent tw-z-50">
			<ul className="tw-list-none tw-flex tw-justify-between tw-pb-xl tw-pt-xl">
				<li className="tw-w-1/3 tw-flex tw-justify-start tw-items-center">
					<ButtonAnchor title="View previous week" onClick={props.prevWeek} iconNotRounded svg={`<svg width="${rem('14')}" height="${rem('16')}" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M-3.93403e-07 8L13.5 0.205772L13.5 15.7942L-3.93403e-07 8Z" fill="currentColor"/></svg>`}>
						<span className="tw-text-3">Previous Week</span>
					</ButtonAnchor>
				</li>
				<li className="tw-w-1/3 tw-flex tw-justify-center tw-items-center">
					<PageSubHeader tag="h2">
						{props.month} {props.year}
					</PageSubHeader>
				</li>
				<li className="tw-w-1/3 tw-flex tw-justify-end tw-items-center">
					<ButtonAnchor after title="View next week" onClick={props.nextWeek} iconNotRounded svg={`<svg width="${rem('14')}" height="${rem('16')}" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8L0.499999 15.7942L0.5 0.205771L14 8Z" fill="currentColor"/></svg>`}>
						<span className="tw-text-3">Next Week</span>
					</ButtonAnchor>
				</li>
			</ul>
		</div>
	</>
}

const TimeMarker = props => {
	return <>
		<div className="tw-flex tw-flex-col tw-space-y-sm tw-border-b-[0.188rem] tw-opacity-0">
			<span className={`tw-leading-none tw-uppercase ${paragraph3}`}>
				Time
			</span>
			<span className={`tw-leading-none tw-uppercase tw-pb-xs ${pageSubHeader}`}>
				<span className={`tw-flex tw-items-center tw-justify-center tw-leading-none  tw-rounded-full tw-w-[2.75rem] tw-h-[2.75rem] ${  props.highlight ? ' tw-text-light-accent tw-bg-dark tw-font-bold ' : '' }`}>
					Hour
				</span>
			</span>
		</div>
	</>
}

const DayMarker = props => {
	return <>
		<div className="tw-flex tw-flex-col tw-space-y-sm tw-border-b-[0.188rem]">
			<span className={`tw-leading-none tw-uppercase ${paragraph3}`}>
				{ props.dayOfWeek || 'Mon' }
			</span>
			<span className={`tw-leading-none tw-uppercase tw-pb-xs ${pageSubHeader}`}>
				<span className={`tw-flex tw-items-center tw-justify-center tw-leading-none  tw-rounded-full tw-w-[2.75rem] tw-h-[2.75rem] ${  props.highlight ? ' tw-text-light-accent tw-bg-dark tw-font-bold ' : '' }`}>
					{ props.date || 14 }
				</span>
			</span>
		</div>
	</>
}


export const TimeCalendar = props => {
	const classes = `c-calendar tw-sticky tw-top-0 tw-w-full tw-bg-light-accent tw-z-10 ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const attributes = {
		...props,
		className: classes
	}

	let navigate = useNavigate();

	const navContext = useAppContext();

	const [startDate, setStartDate] = useState(moment().startOf('week'));
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");

	//const [endDate, setEndDate] = useState(moment().endOf('week'));

	const today = moment();

	const [currentWeek, setCurrentWeek] = useState([]);
	const [remoteSessions, setRemoteSessions] = useState([]);
	const [sessionLoading, setSessionLoading] = useState(false);


	useEffect(() => {
		console.log("Use Effect")

		//console.log(startDate.format("MM-DD-YYYY"));

		//setMonth(startDate.format('MMMM'));
		//setYear(startDate.format("YYYY"));

		//let weekDate = moment(startDate);

		//let endDate = moment(startDate).add(6, 'd');

		setMonth(moment(props?.startDate).format('MMMM'));
		setYear(moment(props?.startDate).format("YYYY"));

		let weekDate = moment(props?.startDate);

		let endDate = moment(props?.endDate);

		let weekDates = [];

		const fetchSessions = async (startTime, endTime) => {
			setSessionLoading(true);

			const sessions = await getSessionsForSpan(startTime, endTime, navContext.handleAPICallError)

			// sessions.forEach(async (session, index, arr) => {
			// 	var sessionByID = await getRemoteSupportSessionByID(session.remoteSupportSessionID, navContext.handleAPICallError);
			// 	arr[index].assignedShifts = sessionByID.assignedShifts;
			// });

			console.log(sessions);

			if(sessions !== null){
				setRemoteSessions(sessions);
			}

			setSessionLoading(false);
		}

		while (weekDate <= endDate) {
			console.log(weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"));

			let dateValue = {
				"dayOfWeek": weekDate.format("dddd").substring(0,3),
				"date": weekDate.format("DD"),
				"highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
				"fullDate": weekDate.format("MM-DD-YYYY"),
			}

			weekDates.push(dateValue)

			weekDate = weekDate.add(1, 'd');
		}

		fetchSessions(moment(props?.startDate).format("MM-DD-YYYY"), endDate.format("MM-DD-YYYY"))
			// make sure to catch any error
			.catch(console.error);

		console.log(weekDates);
		
		setCurrentWeek(weekDates);

	}, [])

	const nextWeek = () => {
		console.log("Next Week");

		let newStartDate = moment(props?.startDate).add(7, 'd');
		let newEndDate = moment(props?.endDate).add(7, 'd');

		navigate("/schedule/session-schedule/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));

		//window.location.reload();

		//setStartDate(newStartDate);
	}

	const prevWeek = () => {
		console.log("Prev Week");

		let newStartDate = moment(props?.startDate).add(-7, 'd');
		let newEndDate = moment(props?.endDate).add(-7, 'd');

		navigate("/schedule/session-schedule/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));

		//window.location.reload();

		//setStartDate(newStartDate);
	}

	const RemoveSession = (sessionID) => {
		setRemoteSessions(remoteSessions.filter(function(session) { 
			return session.remoteSupportSessionID !== sessionID
		}));

		//navContext.closeSideNav();
	}

	const UpdateSession = (session) => {
		console.log("Update Session");

		let sessions = [...remoteSessions];

		let index = sessions.findIndex(x => x.remoteSupportSessionID === session.remoteSupportSessionID);

		sessions[index] = session;

		console.log(sessions[index]);

		setRemoteSessions(sessions);
	}

	const UpdateSessionShiftCount = (session, shiftCount) => {
		console.log("Update Session");

		let sessions = [...remoteSessions];

		let index = sessions.findIndex(x => x.remoteSupportSessionID === session.remoteSupportSessionID);

		sessions[index].assignedShiftCount = shiftCount;

		console.log(sessions[index]);

		setRemoteSessions(sessions);
	}


	const ShowSessionDetails = (sessionByID) => {
        console.log("Nav Context Side Nav");

        const selectedSession = sessionByID;
		const showSession = true;
		let endDate = moment(props?.endDate);


        const panelProps = {
            selectedSession,
            showSession,
            startDate, endDate,
			RemoveSession, UpdateSession,
        }

        console.log(panelProps);
        // navContext.setSideNav( <ServicePanel { ...panelProps } />);
		// navContext.setSideNavTheme(2);
		// navContext.setSideNavTitle("Session Panel");
        // navContext.setPanelSize('tw-max-w-800px');
		navContext.setSideNav( <SessionInfoPanel selectedSession={selectedSession} updateSession={UpdateSession} removeSession={RemoveSession} updateShiftCount={UpdateSessionShiftCount}/>);
		navContext.setSideNavTheme(3);
		navContext.setSideNavTitle("Session Details");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
		navContext.openSideNav();

        console.log(navContext);
    }

	function getDurationInHours(startDate: Date, endDate: Date): number {
		let diffInMs = endDate.getTime() - startDate.getTime();
		let diffInHours = diffInMs / (1000 * 60 * 60); // convert milliseconds to hours
		return diffInHours;
	}

	const getTotalClientHours = (session: RemoteSupportSession) : number => {
		let totalHours = 0;

		session.assignedShifts.forEach(clientSession => {
			totalHours += getDurationInHours(new Date(clientSession.startTime), new Date(clientSession.endTime));
		});

		return totalHours;
	}

	return <>
		<div { ...attributes } className={ classes }>
			<CalendarControls  prevWeek={prevWeek} nextWeek={nextWeek} month={month} year={year} />
			{ sessionLoading ? <>
				<div className="tw-m-md">
					<LinearProgress style={{ color: "#12B4B9" }} />
				</div>
				{/* <LoadingItems /> */}
			</>:<>
				
			</>}
			
			<div className="tw-grid tw-grid-cols-8 xtw-gap-5">
					<div>
						<div className="c-calendar tw-sticky tw-top-[6.750rem] tw-w-full tw-bg-light-accent tw-z-20">
							<TimeMarker/>
						</div>
						<Custom className="tw-grid tw-grid-cols-1 tw-grid-rows-calendar tw-z-0 tw-bg-light-accent"  style={{'--rows': '24', '--min': '4.375rem', '--max': '1fr'}}>
							<>
								{ [...Array(24)].map((item, index) => {
									const number = index > 12 ? (index - 12): index;
									const amOrpm = index >= 12 ? 'PM' : 'AM';
									return <div className="tw-row-span-1 tw-min-h-[4.375rem]">
										{`${ number < 10 && number > 0 ? `0${number}` : ( number === 0 ? '12' : number ) }:00 ${amOrpm}`}
									</div>
								}) }
							</>
						</Custom>
					</div>

					{/* <Custom className="tw-bg-light-accent">
						<div className="tw-px-[.625rem] c-calendar tw-sticky tw-top-[9.250rem] tw-w-full tw-bg-light-accent tw-z-20">
							<DayMarker highlight={ null } />
						</div>
						<div className="-tw-mt-px tw-px-[.625rem] c-grid-outlines tw-grid tw-grid-cols-4 tw-grid-rows-calendar tw-z-0">
							<InlineServiceMark
								className="tw-row-span-calendar"
								style={{'--start': 0, '--end': 2, '--col-start': 1, '--col-expand': 2 }}
								user={{
									"avatar": null,
									"firstName": "Ali",
									"lastName": "CoolGuy",
									"email": "kmack@discreetaf.com",
									"phone": "614-565-8967",
									"address": "123 Street Name St, City, ST 12345"
								}}
							/>
							<InlineServiceMark className="tw-row-span-calendar" style={{'--start': .5, '--end': 4, '--col-start': 3, '--col-expand': 2 }} />
							
							<InlineServiceMark className="tw-row-span-calendar" style={{'--start': 3.5, '--end': 5.5 }} />
							<InlineServiceMark className="tw-row-span-calendar" style={{'--start': 3.5, '--end': 5.5 }} />
						</div>
					</Custom> */}

					{/* <Custom className="tw-bg-light-accent">
						<div className="tw-px-[.625rem] c-calendar tw-sticky tw-top-[9.250rem] tw-w-full tw-bg-light-accent tw-z-20">
							<DayMarker highlight={ null } />
						</div>
						<div className="-tw-mt-px tw-px-[.625rem] c-grid-outlines tw-grid tw-grid-cols-4 tw-grid-rows-calendar tw-z-0">
							<InlineServiceMark
								className="tw-row-span-calendar"
								style={{'--start': 1, '--end': 3 }}
								users={[{
									"avatar": null,
									"firstName": "dfsdf",
									"lastName": "Mack",
									"email": "kmack@discreetaf.com",
									"phone": "614-565-8967",
									"address": "123 Street Name St, City, ST 12345"
								}, {
									"avatar": null,
									"firstName": "Ali",
									"lastName": "CoolGuy",
									"email": "kmack@discreetaf.com",
									"phone": "614-565-8967",
									"address": "123 Street Name St, City, ST 12345"
								}]}
							/>
						</div>
					</Custom> */}

					

					{ currentWeek.map((item, index) => {
						return  <div className="tw-bg-light-accent">
							<div className="tw-px-[.625rem] c-calendar tw-sticky tw-top-[6.750rem] tw-w-full tw-bg-light-accent tw-z-20">
								<DayMarker highlight={item.highlight} dayOfWeek={item.dayOfWeek} date={item.date} />
							</div>

							

							<div className="-tw-mt-px tw-px-[.625rem] c-grid-outlines tw-grid tw-grid-cols-4 tw-grid-rows-calendar tw-z-0">
								{remoteSessions.map((session, index) =>  {
									const startIndex = session.startIndex;
									const endIndex = session.endIndex;
									const multiStartIndex = session.multiDayStartIndex;
									const multiEndIndex = session.multiDayEndIndex;
									const colStart = session.colStart;
									const colExp = session.colEnd;
									const multiDayColStart = session.multiDayColStart;
									const multiDayColEnd = session.multiDayColEnd;
									const provider = session.providerName;
									var numClients = 0;
									var totalClientHours = 0;
									if(session.assignedShifts !== null){
										let uniqueList = session.assignedShifts.filter((value, index, self) => 
																	self.findIndex(v => v.patientID === value.patientID) === index
																);
										numClients = uniqueList.length;
										totalClientHours = getTotalClientHours(session);
									}
									
									
									// const updatedSession = FindOverlap(session, item.fullDate);
									// if(updatedSession){
									// 	const colStart = updatedSession.colStart;
									// 	const colExp = updatedSession.colEnd;
									// }
									return <>
										{session.displayEndDate === item.fullDate && session.multiDay && session.multiDayEndIndex !== 0 ? <>
											<InlineServiceMark className={`tw-row-span-calendar ${session.training ? "tw-bg-lime-100": "tw-bg-white"}`} onClick={ async e => {
												e.preventDefault();
												if(!sessionLoading){
													setSessionLoading(true);
													var sessionByID = await getRemoteSupportSessionByID(session.remoteSupportSessionID, navContext.handleAPICallError);
													console.log(sessionByID);
													
													//props?.setSelectedSession(sessionByID);
													ShowSessionDetails(sessionByID);
													setSessionLoading(false);
												}
												
											}} shiftCount={session.assignedShiftCount} training={session.training ? session.training : false}  provider={provider} startTime={session.displayStartTime} width={multiDayColEnd} numClients={numClients} totalHours={totalClientHours} endTime={session.displayEndTime} status={session.status} style={{'--start': multiStartIndex, '--end': multiEndIndex, '--col-start': multiDayColStart, '--col-expand': multiDayColEnd}} />
										</>:<></> }

										{session.displayStartDate === item.fullDate ? <>
											<InlineServiceMark className={`tw-row-span-calendar ${session.training ? "tw-bg-lime-100": "tw-bg-white"}`} onClick={ async e => {
												e.preventDefault();
												if(!sessionLoading){
													setSessionLoading(true);
													var sessionByID = await getRemoteSupportSessionByID(session.remoteSupportSessionID, navContext.handleAPICallError);
													console.log(sessionByID);
													//props?.setSelectedSession(sessionByID);
													ShowSessionDetails(sessionByID);
													setSessionLoading(false);
												}
												
											}} shiftCount={session.assignedShiftCount} training={session.training ? session.training : false} provider={provider} startTime={session.displayStartTime} width={colExp} numClients={numClients} totalHours={totalClientHours} endTime={session.displayEndTime} status={session.status} style={{'--start': startIndex, '--end': endIndex, '--col-start': colStart, '--col-expand': colExp }} />
										</> : <></>}
									</>
								})}
								{/* <InlineServicePanel />
								<InlineServicePanel type={ 2 } />
								<>
									{ [...Array(index + 1)].map(() => {
										return <InlineServicePanel />
									}) }
								</> */}
							</div>
							
						</div>
					})}
			</div>
			
		</div>
	</>;
}

export const RequirementCalendar = props => {
	const classes = `c-calendar tw-sticky tw-top-0 tw-w-full tw-bg-light-accent tw-z-10 ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const attributes = {
		...props,
		className: classes
	}

	const [startDate, setStartDate] = useState(moment().startOf('week'));
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const [coverageEvents, setCoverageEvents] = useState([]);
	const [requirementLoading, setRequirementLoading] = useState(false);
	const [RSWithBackupHours, setRSWithBackupHours] = useState(0.0);
	const [RSWithoutBackupHours, setRSWithoutBackupHours] = useState(0.0);

	let navigate = useNavigate();

	const navContext = useAppContext();

	//const [endDate, setEndDate] = useState(moment().endOf('week'));

	const today = moment();

	const [currentWeek, setCurrentWeek] = useState([]);
	const RSWithBackupCodes = ['AMR', 'FMR', 'SMR', 'SVM'];
	const RSWithoutBackupCodes = ['AMS', 'FMS', 'SMS', 'RMS'];

	const diff_hours = (dt2, dt1) => 
	{
		var duration = moment.duration(moment(dt2).diff(moment(dt1)));
            var hours = duration.asHours();
            // console.log(requirement);
            // console.log(hours);
            // console.log(hours < 0);
            if (hours < 0) {
                return hours + 24;
            } else {
                return hours;
            }
	
	}

	useEffect(() => {
		console.log("Use Effect")

		// console.log(startDate.format("MM-DD-YYYY"));

		// setMonth(startDate.format('MMMM'));
		// setYear(startDate.format("YYYY"));

		

		// let weekDate = moment(startDate);

		// let endDate = moment(startDate).add(6, 'd');

		setMonth(moment(props?.startDate).format('MMMM'));
		setYear(moment(props?.startDate).format("YYYY"));

		let weekDate = moment(props?.startDate);

		let endDate = moment(props?.endDate);

		let weekDates = [];

		const fetchCoverage = async (patientID, startTime, endTime) => {
			setRequirementLoading(true);

			const clientCoverage = await getCoverageRequirementForSpan(patientID, startTime, endTime, navContext.handleAPICallError);

			console.log(clientCoverage);

			var backupHours = 0.0;
			var withoutBackupHours = 0.0;

			if(clientCoverage != null){
				const sortedCoverage = clientCoverage.sort(
					(objA, objB) => {
						var timeA = new Date(objA.startTime);
						var timeB = new Date(objB.endTime);
						return timeA.getTime() - timeB.getTime()
	
					},
				  );
				setCoverageEvents(sortedCoverage);

				clientCoverage.map((coverage, index) => { 
					if(coverage.status !== "Cancelled"){
						var coverageHours = diff_hours(coverage.endTime, coverage.startTime);
	
						if(RSWithBackupCodes.includes(coverage.billingCode)){
							backupHours += parseFloat(coverageHours.toFixed(2));
						} 
						if(RSWithoutBackupCodes.includes(coverage.billingCode)){
							withoutBackupHours += parseFloat(coverageHours.toFixed(2));
						} 
	
						console.log(coverage);
						console.log(coverageHours);
					}
	
				});
			}

			

			setRSWithBackupHours(backupHours);
			setRSWithoutBackupHours(withoutBackupHours);

			setRequirementLoading(false);
			
			console.log(weekDates);
		}

		while (weekDate <= endDate) {
			console.log(weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"));

			const arr: CoverageEvent[] = [];

			let dateValue = {
				"dayOfWeek": weekDate.format("dddd").substring(0,3),
				"date": weekDate.format("DD"),
				"highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
				"fullDate": weekDate.format("MM-DD-YYYY"),
				"shifts": arr
			}

			weekDates.push(dateValue)

			weekDate = weekDate.add(1, 'd');

			
		}

		fetchCoverage(props.patient.patientID, moment(props?.startDate).format("MM-DD-YYYY"), endDate.format("MM-DD-YYYY"))
			// make sure to catch any error
			.catch(console.error);

		console.log(weekDates);
		
		setCurrentWeek(weekDates);

	}, [startDate])

	const nextWeek = () => {
		console.log("Next Week");

		let newStartDate = moment(props?.startDate).add(7, 'd');
		let newEndDate = moment(props?.endDate).add(7, 'd');

		navigate("/schedule/client-requirement/" + props.patient.patientID + "/requirement/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));

		// let newStartDate = moment(startDate).add(7, 'd');

		// setStartDate(newStartDate);
	}

	const prevWeek = () => {
		console.log("Prev Week");

		let newStartDate = moment(props?.startDate).add(-7, 'd');
		let newEndDate = moment(props?.endDate).add(-7, 'd');

		navigate("/schedule/client-requirement/" + props.patient.patientID + "/requirement/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));

		// let newStartDate = moment(startDate).add(-7, 'd');

		// setStartDate(newStartDate);
	}

    const weekDates = [
        {
            "dayOfWeek": "Sun",
            "date": "18"
        },
        {
            "dayOfWeek": "Mon",
            "date": "19"
        },
        {
            "dayOfWeek": "Tue",
            "date": "20"
        },
        {
            "dayOfWeek": "Wed",
            "date": "21"
        },
        {
            "dayOfWeek": "Thu",
            "date": "22"
        },
        {
            "dayOfWeek": "Fri",
            "date": "23"
        },
        {
            "dayOfWeek": "Sat",
            "date": "24"
        }
    ]

	const RemoveRequirement = (reqID) => {
		setCoverageEvents(coverageEvents.filter(function(requirement) { 
			return requirement.coverageRequirementID !== reqID
		}));

		navContext.closeSideNav();
	}

	const UpdateRequirement = (requirement) => {
		console.log("Update Requirement");

		let requirements = [...coverageEvents];

		let index = requirements.findIndex(x => x.coverageRequirementID === requirement.coverageRequirementID);

		requirements[index] = requirement;

		console.log(requirements);

		setCoverageEvents(requirements);
		var backupHours = 0.0;
		var withoutBackupHours = 0.0;

		requirements.map((coverage, index) => { 
			if(coverage.status !== "Cancelled"){
				var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

				if(RSWithBackupCodes.includes(coverage.billingCode)){
					backupHours += parseFloat(coverageHours.toFixed(2));
				} 
				if(RSWithoutBackupCodes.includes(coverage.billingCode)){
					withoutBackupHours += parseFloat(coverageHours.toFixed(2));
				} 
			}

		});

		setRSWithBackupHours(backupHours);
		setRSWithoutBackupHours(withoutBackupHours);


	}

	const showRequirementPanel = (reqByID) => {
        console.log("NAv Context Side Nav");

        const selectedRequirement = reqByID;
		const showRequirement = true;
		let endDate = moment(props?.endDate);

        const panelProps = {
            selectedRequirement,
            showRequirement,
            startDate, endDate,
			RemoveRequirement, UpdateRequirement,
        }

        console.log(panelProps);
        navContext.setSideNav( <ServicePanel { ...panelProps } />);
		navContext.setSideNavTheme(2);
		navContext.setSideNavTitle("Requirement Panel");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.openSideNav();

        console.log(navContext);
    }

	

	return <>
		<div { ...attributes } className={ classes }>
			<ul className="tw-list-none tw-flex tw-justify-between tw-pb-sm tw-pt-sm">
				<li className="tw-w-1/3 tw-flex tw-justify-center tw-items-center">
					<span>
						RS With Backup: {RSWithBackupHours} hours
					</span>
				</li>
				<li className="tw-w-1/3 tw-flex tw-justify-center tw-items-center">
					<span>
						RS Without Backup: {RSWithoutBackupHours} hours
					</span>
				</li>
			</ul>
			<CalendarControls prevWeek={prevWeek} nextWeek={nextWeek} month={month} year={year} />
			{ requirementLoading ? <>
				<div className="tw-m-md">
					<LinearProgress style={{ color: "#12B4B9" }} />
				</div>
			</>:<></>}
			<div className="tw-grid tw-grid-cols-7 tw-gap-5">
				{ currentWeek.map((item, index) => {
					return  <div>
						<div className="c-calendar tw-sticky tw-top-[6.750rem] tw-w-full tw-bg-light-accent tw-z-20">
							<DayMarker highlight={item.highlight} dayOfWeek={item.dayOfWeek} date={item.date} />
						</div>
						<div className="tw-flex tw-flex-col tw-pt-5 tw-space-y-5 tw-relative tw-z-0 tw-bg-light-accent">
							{coverageEvents.map((coverage, index) => {
								
								if(RSWithBackupCodes.includes(coverage.billingCode)){
									coverage.shiftTheme = 2;
								} 
								if(RSWithoutBackupCodes.includes(coverage.billingCode)){
									coverage.shiftTheme = 3;
								} 

								return <>
									{coverage.displayCoverageDate === item.fullDate ? <>
										<InlineServicePanel serviceType={coverage.shiftType} startTime={coverage.displayStartTime} endTime={coverage.displayEndTime} status={coverage.status} shiftTheme={coverage.shiftTheme}  onClick={ async e => {
											e.preventDefault();
											if(!requirementLoading){
												setRequirementLoading(true);
												var coverageByID = await getCoverageRequirementByID(coverage.coverageRequirementID, navContext.handleAPICallError);
												console.log(coverageByID);
												//props?.setSelectedRequirement(coverageByID);
												showRequirementPanel(coverageByID);
												//props?.toggleShowRightPanel(coverageByID);
												setRequirementLoading(false);
											}
											
										}} />
									</> : <></>}
								</>
							})}
							{/* <InlineServicePanel />
							<InlineServicePanel type={ 2 } />
							<>
								{ [...Array(index + 1)].map(() => {
									return <InlineServicePanel />
								}) }
							</> */}
						</div>
					</div>
				})}
			</div>
		</div>
	</>;
}

const Calendar = props => {
	const classes = `c-calendar tw-sticky tw-top-0 tw-w-full tw-bg-light-accent tw-z-10 ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const attributes = {
		...props,
		className: classes
	}

	const RSWithBackupCodes = ['AMR', 'FMR', 'SMR', 'SVM'];
	const RSWithoutBackupCodes = ['AMS', 'FMS', 'SMS', 'RMS'];

	const [startDate, setStartDate] = useState(moment().startOf('week'));
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const [coverageEvents, setCoverageEvents] = useState([]);

	const appContext = useAppContext();
	

	//const [endDate, setEndDate] = useState(moment().endOf('week'));

	const today = moment();

	const [currentWeek, setCurrentWeek] = useState([]);

	

	useEffect(() => {
		console.log("Use Effect")

		console.log(startDate.format("MM-DD-YYYY"));

		setMonth(startDate.format('MMMM'));
		setYear(startDate.format("YYYY"));

		let weekDate = moment(startDate);

		let endDate = moment(startDate).add(6, 'd');

		let weekDates = [];

		const fetchCoverage = async (patientID, startTime, endTime) => {
			const clientCoverage = await getCoverageForSpan(patientID, startTime, endTime, appContext.handleAPICallError);

			console.log(clientCoverage);

			

			setCoverageEvents(clientCoverage);

			console.log(weekDates);
		}

		while (weekDate <= endDate) {
			console.log(weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"));

			const arr: CoverageEvent[] = [];

			let dateValue = {
				"dayOfWeek": weekDate.format("dddd").substring(0,3),
				"date": weekDate.format("DD"),
				"highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
				"fullDate": weekDate.format("MM-DD-YYYY"),
				"shifts": arr
			}

			weekDates.push(dateValue)

			weekDate = weekDate.add(1, 'd');

			
		}

		fetchCoverage(props.patient.patientID, startDate.format("MM-DD-YYYY"), endDate.format("MM-DD-YYYY"))
			// make sure to catch any error
			.catch(console.error);

		console.log(weekDates);
		
		setCurrentWeek(weekDates);

	}, [startDate])

	const nextWeek = () => {
		console.log("Next Week");

		let newStartDate = moment(startDate).add(7, 'd');

		setStartDate(newStartDate);
	}

	const prevWeek = () => {
		console.log("Prev Week");

		let newStartDate = moment(startDate).add(-7, 'd');

		setStartDate(newStartDate);
	}

    const weekDates = [
        {
            "dayOfWeek": "Sun",
            "date": "18"
        },
        {
            "dayOfWeek": "Mon",
            "date": "19"
        },
        {
            "dayOfWeek": "Tue",
            "date": "20"
        },
        {
            "dayOfWeek": "Wed",
            "date": "21"
        },
        {
            "dayOfWeek": "Thu",
            "date": "22"
        },
        {
            "dayOfWeek": "Fri",
            "date": "23"
        },
        {
            "dayOfWeek": "Sat",
            "date": "24"
        }
    ]

	

	return <>
		<div { ...attributes } className={ classes }>
			<CalendarControls prevWeek={prevWeek} nextWeek={nextWeek} month={month} year={year} />
			<div className="tw-grid tw-grid-cols-7 tw-gap-5">
				{ currentWeek.map((item, index) => {
					return  <div>
						<div className="c-calendar tw-sticky tw-top-[6.750rem] tw-w-full tw-bg-light-accent tw-z-20">
							<DayMarker highlight={item.highlight} dayOfWeek={item.dayOfWeek} date={item.date} />
						</div>
						<div className="tw-flex tw-flex-col tw-pt-5 tw-space-y-5 tw-relative tw-z-0 tw-bg-light-accent">
							{coverageEvents.map((coverage, index) => {
								if(RSWithBackupCodes.includes(coverage.billingCode)){
									coverage.shiftTheme = 2;
								} 
								if(RSWithoutBackupCodes.includes(coverage.billingCode)){
									coverage.shiftTheme = 3;
								} 
								return <>
									{coverage.displayCoverageDate === item.fullDate ? <>									
										<InlineServicePanel serviceType={coverage.shiftType} startTime={coverage.displayStartTime} endTime={coverage.displayEndTime} status={coverage.status} shiftTheme={coverage.shiftTheme} showStatus={false} onClick={ async e => {
											e.preventDefault();
											var coverageByID = await getLegacyEventByID(coverage.coverageEventID, appContext.handleAPICallError);
											console.log(coverageByID);
											//props?.setSelectedShift(coverageByID);
											props?.toggleShowRightPanel(coverageByID);
										}} />
									</> : <></>}
								</>
							})}
							{/* <InlineServicePanel />
							<InlineServicePanel type={ 2 } />
							<>
								{ [...Array(index + 1)].map(() => {
									return <InlineServicePanel />
								}) }
							</> */}
						</div>
					</div>
				})}
			</div>
		</div>
	</>;
}

export const ProviderTimeCalendar = props => {
	const classes = `c-calendar tw-sticky tw-top-0 tw-w-full tw-bg-light-accent tw-z-10 ${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;
	const attributes = {
		...props,
		className: classes
	}

	let navigate = useNavigate();

	const navContext = useAppContext();

	const [startDate, setStartDate] = useState(moment().startOf('week'));
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");

	//const [endDate, setEndDate] = useState(moment().endOf('week'));

	const today = moment();

	const [currentWeek, setCurrentWeek] = useState([]);
	const [remoteSessions, setRemoteSessions] = useState([]);
	const [sessionLoading, setSessionLoading] = useState(false);

	const [openHours, setOpenHours] = useState(0.0);


	useEffect(() => {
		console.log("Use Effect")

		//console.log(startDate.format("MM-DD-YYYY"));

		//setMonth(startDate.format('MMMM'));
		//setYear(startDate.format("YYYY"));

		//let weekDate = moment(startDate);

		//let endDate = moment(startDate).add(6, 'd');

		setMonth(moment(props?.startDate).format('MMMM'));
		setYear(moment(props?.startDate).format("YYYY"));

		let weekDate = moment(props?.startDate);

		let endDate = moment(props?.endDate);

		let weekDates = [];

		const fetchSessions = async (startTime, endTime) => {
			setSessionLoading(true);

			const sessions = await getProviderSessionsForSpan(startTime, endTime, navContext.handleAPICallError)

			// sessions.forEach(async (session, index, arr) => {
			// 	var sessionByID = await getRemoteSupportSessionByID(session.remoteSupportSessionID, navContext.handleAPICallError);
			// 	arr[index].assignedShifts = sessionByID.assignedShifts;
			// });

			console.log(sessions);

			if(sessions !== null){
				setRemoteSessions(sessions);
			}

			var openSessionHours = 0.0;

			sessions.forEach(async (session, index, arr) => {
				if(session.status === "Open"){
					var sessionDurationInhours = getDurationInHours(new Date(session.startTime), new Date(session.endTime));
					openSessionHours += sessionDurationInhours;
				}
			});

			setOpenHours(openSessionHours);


			setSessionLoading(false);
		}

		while (weekDate <= endDate) {
			console.log(weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"));

			let dateValue = {
				"dayOfWeek": weekDate.format("dddd").substring(0,3),
				"date": weekDate.format("DD"),
				"highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
				"fullDate": weekDate.format("MM-DD-YYYY"),
			}

			weekDates.push(dateValue)

			weekDate = weekDate.add(1, 'd');
		}

		fetchSessions(moment(props?.startDate).format("MM-DD-YYYY"), endDate.format("MM-DD-YYYY"))
			// make sure to catch any error
			.catch(console.error);

		console.log(weekDates);
		
		setCurrentWeek(weekDates);

	}, [])

	const nextWeek = () => {
		console.log("Next Week");

		let newStartDate = moment(props?.startDate).add(7, 'd');
		let newEndDate = moment(props?.endDate).add(7, 'd');

		navigate("/schedule/provider/schedule/session-calendar/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));
	
		window.location.reload();
	}

	const prevWeek = () => {
		console.log("Prev Week");

		let newStartDate = moment(props?.startDate).add(-7, 'd');
		let newEndDate = moment(props?.endDate).add(-7, 'd');

		navigate("/schedule/provider/schedule/session-calendar/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));
	
		window.location.reload();
	}

	const RemoveSession = (sessionID) => {
		setRemoteSessions(remoteSessions.filter(function(session) { 
			return session.remoteSupportSessionID !== sessionID
		}));

		//navContext.closeSideNav();
	}

	const UpdateSession = (session) => {
		console.log("Update Session");

		let sessions = [...remoteSessions];

		let index = sessions.findIndex(x => x.remoteSupportSessionID === session.remoteSupportSessionID);

		sessions[index] = session;

		console.log(sessions[index]);

		setRemoteSessions(sessions);
	}

	const UpdateSessionShiftCount = (session, shiftCount) => {
		console.log("Update Session");

		let sessions = [...remoteSessions];

		let index = sessions.findIndex(x => x.remoteSupportSessionID === session.remoteSupportSessionID);

		sessions[index].assignedShiftCount = shiftCount;

		console.log(sessions[index]);

		setRemoteSessions(sessions);
	}


	const ShowSessionDetails = (session) => {
        console.log("Nav Context Side Nav");

		const showSession = true;

		navContext.setSideNav( <SessionClaimInfoPanel openSession={session} />);
		navContext.setSideNavTheme(3);
		navContext.setSideNavTitle("Available Session Hours");
        navContext.setPanelSize('tw-max-w-400px');
		navContext.setSideNavActions(null);
		navContext.setSideNavBottom(null);
		navContext.openSideNav();

    }

	function getDurationInHours(startDate: Date, endDate: Date): number {
		let diffInMs = endDate.getTime() - startDate.getTime();
		let diffInHours = diffInMs / (1000 * 60 * 60); // convert milliseconds to hours
		if (diffInHours < 0) {
			diffInHours += 24;
		}

		if (diffInHours > 24) {
			diffInHours -= 24;
		}

		return diffInHours;
	}

	const getTotalClientHours = (session: RemoteSupportSession) : number => {
		let totalHours = 0;

		session.assignedShifts.forEach(clientSession => {
			totalHours += getDurationInHours(new Date(clientSession.startTime), new Date(clientSession.endTime));
		});

		return totalHours;
	}

	return <>
		<div { ...attributes } className={ classes }>
			<CalendarControls  prevWeek={prevWeek} nextWeek={nextWeek} month={month} year={year} />
			{!sessionLoading && <span className="tw-mb-4 tw-text-red-500">Available Hours: {openHours} hours</span>}
			{ sessionLoading ? <>
				<div className="tw-m-md">
					<LinearProgress style={{ color: "#12B4B9" }} />
				</div>
				{/* <LoadingItems /> */}
			</>:<>
				
			</>}
			
			<div className="tw-grid tw-grid-cols-8 xtw-gap-5">
					<div>
						<div className="c-calendar tw-sticky tw-top-[6.750rem] tw-w-full tw-bg-light-accent tw-z-20">
							<TimeMarker/>
						</div>
						<Custom className="tw-grid tw-grid-cols-1 tw-grid-rows-calendar tw-z-0 tw-bg-light-accent"  style={{'--rows': '24', '--min': '4.375rem', '--max': '1fr'}}>
							<>
								{ [...Array(24)].map((item, index) => {
									const number = index > 12 ? (index - 12): index;
									const amOrpm = index >= 12 ? 'PM' : 'AM';
									return <div className="tw-row-span-1 tw-min-h-[4.375rem]">
										{`${ number < 10 && number > 0 ? `0${number}` : ( number === 0 ? '12' : number ) }:00 ${amOrpm}`}
									</div>
								}) }
							</>
						</Custom>
					</div>		

					{ currentWeek.map((item, index) => {
						return  <div className="tw-bg-light-accent">
							<div className="tw-px-[.625rem] c-calendar tw-sticky tw-top-[6.750rem] tw-w-full tw-bg-light-accent tw-z-20">
								<DayMarker highlight={item.highlight} dayOfWeek={item.dayOfWeek} date={item.date} />
							</div>

							

							<div className="-tw-mt-px tw-px-[.625rem] c-grid-outlines tw-grid tw-grid-cols-4 tw-grid-rows-calendar tw-z-0">
								{remoteSessions.map((session, index) =>  {
									const startIndex = session.startIndex;
									const endIndex = session.endIndex;
									const multiStartIndex = session.multiDayStartIndex;
									const multiEndIndex = session.multiDayEndIndex;
									const colStart = session.colStart;
									const colExp = session.colEnd;
									const multiDayColStart = session.multiDayColStart;
									const multiDayColEnd = session.multiDayColEnd;
									const provider = session.providerName;
									var numClients = 0;
									var totalClientHours = 0;
									if(session.assignedShifts !== null){
										let uniqueList = session.assignedShifts.filter((value, index, self) => 
																	self.findIndex(v => v.patientID === value.patientID) === index
																);
										numClients = uniqueList.length;
										totalClientHours = getTotalClientHours(session);
									}
									
									
									// const updatedSession = FindOverlap(session, item.fullDate);
									// if(updatedSession){
									// 	const colStart = updatedSession.colStart;
									// 	const colExp = updatedSession.colEnd;
									// }
									return <>
										{session.displayEndDate === item.fullDate && session.multiDay && session.multiDayEndIndex !== 0 ? <>
											<ProviderInlineServiceMark className={`tw-row-span-calendar ${session.training ? "tw-bg-lime-100": "tw-bg-white"}`} onClick={ async e => {
												e.preventDefault();
												if(!sessionLoading){
													setSessionLoading(true);
													// var sessionByID = await getRemoteSupportSessionByID(session.remoteSupportSessionID, navContext.handleAPICallError);
													// console.log(sessionByID);
													
													// //props?.setSelectedSession(sessionByID);
													// ShowSessionDetails(sessionByID);
													if(session.status === "Open"){
														ShowSessionDetails(session);
													}
													setSessionLoading(false);
												}
												
											}} open={session.status === "Open"} provider={provider} startTime={session.displayStartTime} width={multiDayColEnd} numClients={numClients} totalHours={totalClientHours} endTime={session.displayEndTime} status={session.status} style={{'--start': multiStartIndex, '--end': multiEndIndex, '--col-start': multiDayColStart, '--col-expand': multiDayColEnd}} />
										</>:<></> }

										{session.displayStartDate === item.fullDate ? <>
											<ProviderInlineServiceMark className={`tw-row-span-calendar ${session.training ? "tw-bg-lime-100": "tw-bg-white"}`} onClick={ async e => {
												e.preventDefault();
												if(!sessionLoading){
													setSessionLoading(true);
													// var sessionByID = await getRemoteSupportSessionByID(session.remoteSupportSessionID, navContext.handleAPICallError);
													// console.log(sessionByID);
													// //props?.setSelectedSession(sessionByID);
													// ShowSessionDetails(sessionByID);
													if(session.status === "Open"){
														ShowSessionDetails(session);
													}
													setSessionLoading(false);
												}
												
											}} open={session.status === "Open"} provider={provider} startTime={session.displayStartTime} width={colExp} numClients={numClients} totalHours={totalClientHours} endTime={session.displayEndTime} status={session.status} style={{'--start': startIndex, '--end': endIndex, '--col-start': colStart, '--col-expand': colExp }} />
										</> : <></>}
									</>
								})}
								{/* <InlineServicePanel />
								<InlineServicePanel type={ 2 } />
								<>
									{ [...Array(index + 1)].map(() => {
										return <InlineServicePanel />
									}) }
								</> */}
							</div>
							
						</div>
					})}
			</div>
			
		</div>
	</>;
}

export default Calendar;
