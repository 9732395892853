import React from "react";

export const BaseComponent = props => {
	const Tag = props.tag || 'div';
	const classes = `${ props?.coreClasses || '' }${ props?.className ? ' || ' + props.className : '' }`;

	const attributes = {
		...props,
		className: classes,
		tag: null,
		coreClasses: null
	}

	return <Tag { ...attributes } />
}

export const Block = props => <BaseComponent coreClasses="tw-block" tag={ props.tag || 'span' } { ...props } />;

export const Div = props => <BaseComponent tag={ props.tag || 'div' } { ...props } />;

export const Span = props => <BaseComponent coreClasses="tw-inline-block" tag={ props.tag || 'span' } { ...props } />;

export const Status = props => {
	return <BaseComponent coreClasses="tw-flex-shrink-0 tw-inline-block tw-w-[.75em] tw-h-[.75em] tw-bg-current tw-rounded-full tw-leading-none tw-mr-sm" tag={ props.tag || 'span' } { ...props }>
		<span className="tw-hidden">{ props.children || 'Status' }</span>
	</BaseComponent>;
}

export const LabelOutputs = props => {
	const attributes = {
		className: null,
		icon: null,
		size: null
	}

	return <>
		<Span className={`tw-flex ${ props.space ? props.space : 'tw-space-x-5p' } tw-items-center ${props.className}`}>
			{ props.reverse ? <>
				<span className="tw-flex-grow tw-flex tw-items-center">
					{ props.children }
				</span>
				{  props.icon && <BaseComponent tag="span" className="tw-flex tw-justify-start tw-items-center tw-w-px-em" style={{ '--px-override':  props.size || 20  }}>
					<img className="tw-w-full" src={`${ props.icon }`} alt="Icon" />
				</BaseComponent> }
			</> : <>
				{  props.icon && <BaseComponent tag="span" className="tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-px-em" style={{ '--px-override':  props.size || 20  }}>
					<img className="tw-w-full" src={`${ props.icon }`} alt="Icon" />
				</BaseComponent> }
				<span className="tw-flex-grow">
					{ props.children }
				</span>
			</> }
		</Span>
	</>
}

export const Ellipsis = props =>  {
	const Tag = props.tag || 'span';
	const classes = `tw-min-w-0 tw-block tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden${ props?.className ? ' || ' + props.className : '' }`;

	const attributes = {
		...props,
		className: classes,
		tag: null
	}

	return <Tag { ...attributes } />;
}

export const Custom = props => {
	const Tag = props.tag || 'div';

	const attributes = {
		...props,
		tag: null
	}

	return <Tag { ...props }>
		{ props.children }
	</Tag>
}

export const Tooltip = ({ children, message }) => {
	const [show, setShow] = React.useState(false);
  
	return (
	  <div
		onMouseEnter={() => setShow(true)} 
		onMouseLeave={() => setShow(false)}
	  >
		{children}
		
		{show && (
		  <div 
		  className="tw-absolute tw-bottom-full tw-left-1/2 tw-transform tw-p-2 tw-text-sm tw-text-white tw-bg-gray-900 tw-rounded tw-shadow-lg">
			{message}
		  </div>  
		)}
	  </div>
	);
}
