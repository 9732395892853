import { useLayoutEffect, useState } from "react";
import { placeholder, rem } from "../../utilities/helpers";
import { Avatar } from "../containers/ImageContainer";
import { caption2 } from "../../utilities/typography";
import { Custom, Ellipsis } from "../utilities/Helpers";
import { ButtonAnchor } from "../buttons/Buttons";

export const InlineMemberInfo = props => {
	const Tag = props.tag || 'span';

	const user = props.user || {
		"avatar": null,
		"firstName": "Kevin",
		"lastName": "Mack",
		"email": "kmack@discreetaf.com",
		"phone": "614-565-8967",
		"address": "123 Street Name St, City, ST 12345"
	}

	return <>
		<Tag className={`tw-w-6 ${ props.hideAvatar ? ' || ' + 'tw-hidden' : '' }`}>
			<Avatar className="tw-flex" tag="span" src={ props?.avatar || placeholder('200/000000/FFFFFF?text=User') } />
		</Tag>
		<Tag className={`tw-py-xs ${ caption2 }`}>
			<Ellipsis>
				{ props.users ? <>
					{ props.children }
				</> : <>
					{`${ user?.firstName } ${ user?.lastName }`}
				</> }
			</Ellipsis>
		</Tag>
	</>
}

export const InlineRSAInfo = props => {
	const Tag = props.tag || 'span';

	const user = props.user || {
		"avatar": null,
		"providerName": "Kevin Mack",
		"email": "kmack@discreetaf.com",
		"phone": "614-565-8967",
		"address": "123 Street Name St, City, ST 12345"
	}

	const getInitials = (name: string): string => {
		if(!name) return 'Open';
		return name.split(' ')
			.map((word) => word[0].toUpperCase())
			.join('');
	};

	const provider = props.provider || "";
	const width = props.width || 4;

	const providerInitials = getInitials(provider);

	return <>
		<Tag className={`tw-w-6 ${ props.hideAvatar ? ' || ' + 'tw-hidden' : '' }`}>
			<Avatar className="tw-flex" tag="span" src={ props?.avatar || placeholder('200/000000/FFFFFF?text=User') } />
		</Tag>
		<Tag className={`tw-py-xs ${ caption2 }`}>
			<Ellipsis>
				{ props.users ? <>
					{ props.children }
				</> : <>
					{`${width === 4 ? provider : providerInitials}`}
				</> }
			</Ellipsis>
		</Tag>
	</>
}

export const InlineMemberDetails = props => {
	const Tag = props.tag || 'span';

	const user = props.user || {
		"avatar": null,
		"firstName": "Kevin",
		"lastName": "Mack",
		"email": "kmack@discreetaf.com",
		"phone": "614-565-8967",
		"address": "123 Street Name St, City, ST 12345"
	}

	return <>
		<span className={`${ props?.styleOverride ? props.styleOverride : 'tw-bg-white'} tw-flex tw-flex-col tw-px-[0.313rem] tw-pt-[0.813rem] tw-pb ${ caption2 }`}>
			<InlineMemberInfo user={ user } />
			<span className="tw-flex tw-flex-col tw-space-y-sm tw-pt-sm">
				{ user?.phone && <span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" href={`tel:+1${ user?.phone.replaceAll('-','').replaceAll('(', '').replaceAll(')', '') }`} title={`Call ${ user?.phone }`} className="tw-min-w-0" svg={`<svg width="${rem("10")}" height="${rem("10")}" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 12.5C15.75 12.5 14.55 12.3 13.43 11.93C13.08 11.82 12.69 11.9 12.41 12.17L10.21 14.37C7.38 12.93 5.06 10.62 3.62 7.79L5.82 5.58C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.5C18 12.95 17.55 12.5 17 12.5ZM9 0V10L12 7H18V0H9Z" fill="currentColor"/></svg>`}>
						<span className={`tw-text-dark`}>{ user?.phone }</span>
					</ButtonAnchor>
				</span> }
				<span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" to={`/`} className="tw-min-w-0" svg={`<svg width="${rem("10")}" height="${rem("10")}"viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.12 2.88C6.535 2.295 5.77 2 5 2V5L2.88 7.12C4.05 8.29 5.95 8.29 7.125 7.12C8.295 5.95 8.295 4.05 7.12 2.88ZM5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5 9C2.79 9 1 7.21 1 5C1 2.79 2.79 1 5 1C7.21 1 9 2.79 9 5C9 7.21 7.21 9 5 9Z" fill="currentColor"/></svg>`}>
						<span className={`tw-text-dark tw-flex tw-flex-col tw-space-y-xs`}>
							<span>
								02:00 AM -
							</span>
							<span>
								04:00 AM
							</span>
						</span>
					</ButtonAnchor>
				</span>
			</span>
		</span>
	</>
}

export const InlineRSADetails = props => {
	const Tag = props.tag || 'span';

	const provider = props.provider || "";
	const startTime = props.startTime || null;
	const endTime = props.endTime || null;
	const status = props.status || null;
	const numClients = props.numClients || 0;
	const totalHours = props.totalHours || 0;
	const shiftCount = props.shiftCount || 0;

	const user = props.user || {
		"avatar": null,
		"firstName": "Kevin",
		"lastName": "Mack",
		"email": "kmack@discreetaf.com",
		"phone": "614-565-8967",
		"address": "123 Street Name St, City, ST 12345"
	}

	return <>
		<span className={`${ props?.styleOverride ? props.styleOverride : 'tw-bg-white'} tw-flex tw-flex-col tw-px-[0.313rem] tw-pt-[0.813rem] tw-pb ${ caption2 }`}>
			<InlineRSAInfo provider={provider} />
			<span className="tw-flex tw-flex-col tw-space-y-sm tw-pt-sm">
				{/* { user?.phone && <span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" href={`tel:+1${ user?.phone.replaceAll('-','').replaceAll('(', '').replaceAll(')', '') }`} title={`Call ${ user?.phone }`} className="tw-min-w-0" svg={`<svg width="${rem("10")}" height="${rem("10")}" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 12.5C15.75 12.5 14.55 12.3 13.43 11.93C13.08 11.82 12.69 11.9 12.41 12.17L10.21 14.37C7.38 12.93 5.06 10.62 3.62 7.79L5.82 5.58C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.5C18 12.95 17.55 12.5 17 12.5ZM9 0V10L12 7H18V0H9Z" fill="currentColor"/></svg>`}>
						<span className={`tw-text-dark`}>{ user?.phone }</span>
					</ButtonAnchor>
				</span> } */}
				<span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" title={`Call ${ user?.phone }`} className="tw-min-w-0">
						<span className={`tw-text-dark`}>{ status }</span>
					</ButtonAnchor>
				</span>
				<span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" className="tw-min-w-0" svg={`<svg width="${rem("10")}" height="${rem("10")}"viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.12 2.88C6.535 2.295 5.77 2 5 2V5L2.88 7.12C4.05 8.29 5.95 8.29 7.125 7.12C8.295 5.95 8.295 4.05 7.12 2.88ZM5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5 9C2.79 9 1 7.21 1 5C1 2.79 2.79 1 5 1C7.21 1 9 2.79 9 5C9 7.21 7.21 9 5 9Z" fill="currentColor"/></svg>`}>
						<span className={`tw-text-dark tw-flex tw-flex-col tw-space-y-xs`}>
							<span>
								{startTime} -
							</span>
							<span>
								{endTime}
							</span>
						</span>
					</ButtonAnchor>
				</span>
				<span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" title={`Call ${ user?.phone }`} className="tw-min-w-0">
						<span className={`tw-text-dark`}>{ shiftCount } shifts</span>
					</ButtonAnchor>
				</span>
				{/* <span className="tw-flex-shrink-0 tw-flex tw-items-center">
					
						<span className={`tw-text-dark tw-flex tw-flex-col tw-space-y-xs`}>
							<span>
								Clients -
							</span>
							<span>
								{numClients}
							</span>
						</span>
				</span> */}
			</span>
		</span>
	</>
}

export const InlineServiceMark = props => {
	const Tag = props?.tag || 'span'
	const user = props.user || null;
	const provider = props.provider || "";
	const providerInfo = props.provider || "Open";
	const startTime = props.startTime || null;
	const endTime = props.endTime || null;
	const status = props.status || null;
	const width = props.width || 4;
	const numClients = props.numClients || 0;
	const totalHours = props.totalHours || 0;
	const [shiftCount, setShiftCount] = useState(0);
	const [users, setUsers] = useState<any>(null);
	const [serviceType, setServiceType] = useState(1);
	const [serviceTypeTheme, setServiceTypeTheme] = useState('primary');
	const [typeBySize, setTypeBySize] = useState('standard');

	useLayoutEffect(() => {
		if (props?.type === 2) {
			setServiceType(2);
			setServiceTypeTheme('accent-dark');
		}

		if(props?.shiftCount){
			console.log("shiftCount", props.shiftCount);
			setShiftCount(props.shiftCount);
		}
	}, [props?.type, props?.shiftCount])


	const attributes = {
		...props,
		className: null,
		user: null,
		users: null
	}

	useLayoutEffect(() => {
		if (props.style && props.style['--start'] && props.style['--end']) {
			setTypeBySize( (props.style['--end'] - props.style['--start']) < 1 ? 'small' : 'standard' );
		}
	},[ props.style]);

	useLayoutEffect(() => {
		if (props?.users && props.users.length > 1) setUsers(props?.users);
	}, [props?.users])

	return <>
		{ users ? <>
			<Tag  { ...attributes } className={`tw-w-full tw-max-w-400 tw-min-h-[calc(4.375rem/4)] tw-w-full || tw-flex || tw-leading-none tw-relative tw-overflow-visible ${ props.className ? " || " + props.className : '' }`}>
				<span className="tw-w-full tw-flex tw-flex-col c-hover-details">
					<Tag className={`tw-flex tw-flex-col tw-flex-grow tw-my-[0.063rem] tw-bg-white tw-border-l-[0.250rem] ${ shiftCount === 0 ? "tw-border-l-secondary-accent" : "tw-border-l-primary" }  tw-py-3 tw-px-xs`}>
						<InlineMemberInfo hideAvatar={ true } users={ true }>
							<span className="tw-leading-tight">{ users.length } Providers</span>
						</InlineMemberInfo>
					</Tag>
					<Custom className="c-hover-details__toggle tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-30 Xtw-min-w-[5.938rem] tw-min-w-[8.125rem]" tag="span">
						<span className="tw-bg-white tw-text-dark tw-w-full tw-flex tw-flex-col tw-ml-1 tw-shadow-sm tw-mt-[0.063rem] tw-divide-y tw-divide-light-accent">
							{/* <InlineMemberDetails /> */}
							{ users.map( user => {
								// return <span className="tw-flex tw-flex-col tw-w-full tw-bg-white tw-pt-sm tw-pl-xs"><InlineMemberInfo key={ user } user={ user } /></span>
								return <span key={ user } className="c-hover-details tw-flex tw-flex-col tw-w-full">
									<Tag className={`tw-flex tw-flex-col tw-flex-grow ${props.training ? "tw-bg-lime-100": "tw-bg-white"} hover:tw-bg-secondary-accent tw-py-sm tw-px-xs tw-bg-red`}>
										<InlineRSAInfo provider={providerInfo} styleOverride="tw-bg-inherit" />
									</Tag>
									<Custom className="c-hover-details__toggle tw-absolute tw-top-0 tw-right-0 tw-translate-x-full tw-bottom-0 tw-min-h-full tw-left-0 tw-z-30 Xtw-min-w-[5.938rem] tw-min-w-[6.875rem]" tag="span">
										<span className={`${props.training ? "tw-bg-lime-100": "tw-bg-white"} tw-text-dark tw-w-full tw-flex tw-flex-col  tw-shadow-sm  tw-border-l-[0.250rem] tw-border-l-secondary-accent tw-min-h-full tw-mt-[0.063rem]`}>
											<InlineRSADetails provider={provider} startTime={startTime} endTime={endTime} status={status} shiftCount={shiftCount} styleOverride={props.training ? "tw-bg-lime-100" : "tw-bg-white"}/>
										</span>
									</Custom>
								</span>
							})}
						</span>
					</Custom>
				</span>
			</Tag>
		</> : <>
			<Tag  { ...attributes } className={`tw-w-full tw-max-w-400 tw-min-h-[calc(4.375rem/4)] tw-w-full || tw-flex || tw-leading-none tw-relative tw-overflow-visible ${ props.className ? " || " + props.className : '' }`}>
				<span className="tw-w-full tw-flex tw-flex-col c-hover-details">
					{ typeBySize === 'small' ? <>
						<Tag className={`tw-flex tw-flex-col tw-flex-grow tw-my-[0.063rem] ${props.training ? "tw-bg-lime-100": "tw-bg-white"} tw-border-l-[0.250rem] ${ shiftCount === 0 ? "tw-border-l-secondary-accent" : "tw-border-l-primary" } tw-py-[0.125rem] tw-px-xs`}>
							<InlineRSAInfo provider={providerInfo} hideAvatar={ true } width={width} />
						</Tag>
						<Custom className="c-hover-details__toggle tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-min-h-full tw-left-0 tw-z-30 Xtw-min-w-[5.938rem] tw-min-w-[6.875rem]" tag="span">
							<span className={`${props.training ? "tw-bg-lime-100": "tw-bg-white"} tw-text-dark tw-w-full tw-flex tw-flex-col tw-ml-1 tw-shadow-sm  tw-min-h-full tw-mt-[0.063rem]`}>
							<InlineRSADetails provider={provider} startTime={startTime} endTime={endTime} status={status} numClients={numClients} totalHours={totalHours} shiftCount={shiftCount} styleOverride={props.training ? "tw-bg-lime-100" : "tw-bg-white"}/>
							</span>
						</Custom>
					</>: <>
						<Tag className={`tw-flex tw-flex-col tw-flex-grow tw-my-[0.063rem] ${props.training ? "tw-bg-lime-100": "tw-bg-white"} tw-border-l-[0.250rem] ${ shiftCount === 0 ? "tw-border-l-secondary-accent" : "tw-border-l-primary" } tw-py-3 tw-px-xs `}>
							<InlineRSAInfo provider={providerInfo} width={width} />
						</Tag>
						<Custom className="c-hover-details__toggle tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-30 Xtw-min-w-[5.938rem] tw-min-w-[8.125rem]" tag="span">
							<span className={`${props.training ? "tw-bg-lime-100": "tw-bg-white"} tw-text-dark tw-w-full tw-flex tw-flex-col tw-ml-1 tw-shadow-sm tw-mt-[0.063rem]`}>
							<InlineRSADetails provider={provider} startTime={startTime} endTime={endTime} status={status} numClients={numClients} totalHours={totalHours} shiftCount={shiftCount} styleOverride={props.training ? "tw-bg-lime-100" : "tw-bg-white"}/>
							</span>
						</Custom>
					</> }
				</span>
			</Tag>
		 </>}
	</>;
}

export const InlineProviderDetails = props => {
	const Tag = props.tag || 'span';

	const provider = props.provider || "";
	const startTime = props.startTime || null;
	const endTime = props.endTime || null;
	const status = props.status || null;
	const numClients = props.numClients || 0;
	const totalHours = props.totalHours || 0;
	const shiftCount = props.shiftCount || 0;

	const user = props.user || {
		"avatar": null,
		"firstName": "Kevin",
		"lastName": "Mack",
		"email": "kmack@discreetaf.com",
		"phone": "614-565-8967",
		"address": "123 Street Name St, City, ST 12345"
	}

	return <>
		<span className={`${ props?.styleOverride ? props.styleOverride : 'tw-bg-white'} tw-flex tw-flex-col tw-px-[0.313rem] tw-pt-[0.813rem] tw-pb ${ caption2 }`}>
			<InlineRSAInfo provider={provider} />
			<span className="tw-flex tw-flex-col tw-space-y-sm tw-pt-sm">
				{/* { user?.phone && <span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" href={`tel:+1${ user?.phone.replaceAll('-','').replaceAll('(', '').replaceAll(')', '') }`} title={`Call ${ user?.phone }`} className="tw-min-w-0" svg={`<svg width="${rem("10")}" height="${rem("10")}" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 12.5C15.75 12.5 14.55 12.3 13.43 11.93C13.08 11.82 12.69 11.9 12.41 12.17L10.21 14.37C7.38 12.93 5.06 10.62 3.62 7.79L5.82 5.58C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.5C18 12.95 17.55 12.5 17 12.5ZM9 0V10L12 7H18V0H9Z" fill="currentColor"/></svg>`}>
						<span className={`tw-text-dark`}>{ user?.phone }</span>
					</ButtonAnchor>
				</span> } */}
				<span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" title={`Call ${ user?.phone }`} className="tw-min-w-0">
						<span className={`tw-text-dark`}>{ status }</span>
					</ButtonAnchor>
				</span>
				<span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" className="tw-min-w-0" svg={`<svg width="${rem("10")}" height="${rem("10")}"viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.12 2.88C6.535 2.295 5.77 2 5 2V5L2.88 7.12C4.05 8.29 5.95 8.29 7.125 7.12C8.295 5.95 8.295 4.05 7.12 2.88ZM5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM5 9C2.79 9 1 7.21 1 5C1 2.79 2.79 1 5 1C7.21 1 9 2.79 9 5C9 7.21 7.21 9 5 9Z" fill="currentColor"/></svg>`}>
						<span className={`tw-text-dark tw-flex tw-flex-col tw-space-y-xs`}>
							<span>
								{startTime} -
							</span>
							<span>
								{endTime}
							</span>
						</span>
					</ButtonAnchor>
				</span>
				{/* <span className="tw-flex-shrink-0 tw-flex tw-items-center">
					<ButtonAnchor alignment="start" title={`Call ${ user?.phone }`} className="tw-min-w-0">
						<span className={`tw-text-dark`}>{ shiftCount } shifts</span>
					</ButtonAnchor>
				</span> */}
				{/* <span className="tw-flex-shrink-0 tw-flex tw-items-center">
					
						<span className={`tw-text-dark tw-flex tw-flex-col tw-space-y-xs`}>
							<span>
								Clients -
							</span>
							<span>
								{numClients}
							</span>
						</span>
				</span> */}
			</span>
		</span>
	</>
}

export const ProviderInlineServiceMark = props => {
	const Tag = props?.tag || 'span'
	const user = props.user || null;
	const provider = props.provider || "";
	const providerInfo = props.provider || "Open";
	const startTime = props.startTime || null;
	const endTime = props.endTime || null;
	const status = props.status || null;
	const width = props.width || 4;
	const numClients = props.numClients || 0;
	const totalHours = props.totalHours || 0;
	const openSession = props.open || false;
	const [shiftCount, setShiftCount] = useState(0);
	const [users, setUsers] = useState<any>(null);
	const [serviceType, setServiceType] = useState(1);
	const [serviceTypeTheme, setServiceTypeTheme] = useState('primary');
	const [typeBySize, setTypeBySize] = useState('standard');

	const getBackgroundColor = (): string => {
		if(status === "Open") return "tw-bg-red-300";
		if(status === "Assigned" || status === "Accepted" || status === "Ongoing") return "tw-bg-emerald-200";
		if(status === "Submitted") return "tw-bg-zinc-300";
		return "tw-bg-white";
	}

	useLayoutEffect(() => {
		if (props?.type === 2) {
			setServiceType(2);
			setServiceTypeTheme('accent-dark');
		}

		if(props?.shiftCount){
			console.log("shiftCount", props.shiftCount);
			setShiftCount(props.shiftCount);
		}
	}, [props?.type, props?.shiftCount])


	const attributes = {
		...props,
		className: null,
		user: null,
		users: null
	}

	useLayoutEffect(() => {
		if (props.style && props.style['--start'] && props.style['--end']) {
			setTypeBySize( (props.style['--end'] - props.style['--start']) < 1 ? 'small' : 'standard' );
		}
	},[ props.style]);

	useLayoutEffect(() => {
		if (props?.users && props.users.length > 1) setUsers(props?.users);
	}, [props?.users])



	return <>
		{ users ? <>
			<Tag  { ...attributes } className={`tw-w-full tw-max-w-400 tw-min-h-[calc(4.375rem/4)] tw-w-full || tw-flex || tw-leading-none tw-relative tw-overflow-visible ${ props.className ? " || " + props.className : '' }`}>
				<span className="tw-w-full tw-flex tw-flex-col c-hover-details">
					<Tag className={`tw-flex tw-flex-col tw-flex-grow tw-my-[0.063rem] tw-bg-white tw-border-l-[0.250rem] tw-py-3 tw-px-xs`}>
						<InlineMemberInfo hideAvatar={ true } users={ true }>
							<span className="tw-leading-tight">{ users.length } Providers</span>
						</InlineMemberInfo>
					</Tag>
					<Custom className="c-hover-details__toggle tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-30 Xtw-min-w-[5.938rem] tw-min-w-[8.125rem]" tag="span">
						<span className="tw-bg-white tw-text-dark tw-w-full tw-flex tw-flex-col tw-ml-1 tw-shadow-sm tw-mt-[0.063rem] tw-divide-y tw-divide-light-accent">
							{/* <InlineMemberDetails /> */}
							{ users.map( user => {
								// return <span className="tw-flex tw-flex-col tw-w-full tw-bg-white tw-pt-sm tw-pl-xs"><InlineMemberInfo key={ user } user={ user } /></span>
								return <span key={ user } className="c-hover-details tw-flex tw-flex-col tw-w-full">
									<Tag className={`tw-flex tw-flex-col tw-flex-grow ${props.training ? "tw-bg-lime-100": "tw-bg-white"} hover:tw-bg-secondary-accent tw-py-sm tw-px-xs tw-bg-red`}>
										<InlineRSAInfo provider={providerInfo} styleOverride="tw-bg-inherit" />
									</Tag>
									<Custom className="c-hover-details__toggle tw-absolute tw-top-0 tw-right-0 tw-translate-x-full tw-bottom-0 tw-min-h-full tw-left-0 tw-z-30 Xtw-min-w-[5.938rem] tw-min-w-[6.875rem]" tag="span">
										<span className={`${props.training ? "tw-bg-lime-100": "tw-bg-white"} tw-text-dark tw-w-full tw-flex tw-flex-col  tw-shadow-sm  tw-border-l-[0.250rem] tw-border-l-secondary-accent tw-min-h-full tw-mt-[0.063rem]`}>
											<InlineProviderDetails provider={provider} startTime={startTime} endTime={endTime} status={status} shiftCount={shiftCount} styleOverride={props.training ? "tw-bg-lime-100" : "tw-bg-white"}/>
										</span>
									</Custom>
								</span>
							})}
						</span>
					</Custom>
				</span>
			</Tag>
		</> : <>
			<Tag  { ...attributes } className={`tw-w-full tw-max-w-400 tw-min-h-[calc(4.375rem/4)] tw-w-full || tw-flex || tw-leading-none tw-relative tw-overflow-visible ${ props.className ? " || " + props.className : '' }`}>
				<span className="tw-w-full tw-flex tw-flex-col c-hover-details">
					{ typeBySize === 'small' ? <>
						<Tag className={`tw-flex tw-flex-col tw-flex-grow tw-my-[0.063rem] ${getBackgroundColor()} tw-border-l tw-py-[0.125rem] tw-px-xs`}>
							<InlineRSAInfo provider={providerInfo} hideAvatar={ true } width={width} />
						</Tag>
						<Custom className="c-hover-details__toggle tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-min-h-full tw-left-0 tw-z-30 Xtw-min-w-[5.938rem] tw-min-w-[6.875rem]" tag="span">
							<span className={`${props.training ? "tw-bg-lime-100": "tw-bg-white"} tw-text-dark tw-w-full tw-flex tw-flex-col tw-ml-1 tw-shadow-sm  tw-min-h-full tw-mt-[0.063rem]`}>
							<InlineProviderDetails provider={provider} startTime={startTime} endTime={endTime} status={status} numClients={numClients} totalHours={totalHours} shiftCount={shiftCount} styleOverride={props.training ? "tw-bg-lime-100" : "tw-bg-white"}/>
							</span>
						</Custom>
					</>: <>
						<Tag className={`tw-flex tw-flex-col tw-flex-grow tw-my-[0.063rem] ${getBackgroundColor()} tw-border-l tw-py-3 tw-px-xs `}>
							<InlineRSAInfo provider={providerInfo} width={width} />
						</Tag>
						<Custom className="c-hover-details__toggle tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-30 Xtw-min-w-[5.938rem] tw-min-w-[8.125rem]" tag="span">
							<span className={`${props.training ? "tw-bg-lime-100": "tw-bg-white"} tw-text-dark tw-w-full tw-flex tw-flex-col tw-ml-1 tw-shadow-sm tw-mt-[0.063rem]`}>
							<InlineProviderDetails provider={provider} startTime={startTime} endTime={endTime} status={status} numClients={numClients} totalHours={totalHours} shiftCount={shiftCount} styleOverride={props.training ? "tw-bg-lime-100" : "tw-bg-white"}/>
							</span>
						</Custom>
					</> }
				</span>
			</Tag>
		 </>}
	</>;
}

const InlineServicePanel = props => {
	const Tag = props?.tag || 'span'
	const [serviceType, setServiceType] = useState(1);
    const [service, setService] = useState("Direct Support");
	const [serviceTypeTheme, setServiceTypeTheme] = useState('tw-bg-primary');
	const [cardTheme, setCardTheme] = useState('tw-bg-white');

	useLayoutEffect(() => {
		if (props?.shiftTheme === 2) {
			setServiceType(2);
			setServiceTypeTheme('tw-bg-accent-dark');
            setService("Remote Support")
		}

		if (props?.shiftTheme === 3) {
			setServiceType(3);
			setServiceTypeTheme('tw-bg-secondary');
            setService("Remote Support")
		}

		if (props?.status === "Cancelled"){
			setCardTheme("tw-bg-gray-400");
		}

	}, [props?.type])

	const attributes = {
		...props,
		className: null
	}

	return <>
		<Tag { ...attributes } className={`tw-relative tw-z-20 || tw-max-w-400 tw-flex-grow tw-w-full || tw-flex tw-flex-col || ${cardTheme} ${ props.className ? " || " + props.className : '' }`}>
			<Tag className={`tw-w-full tw-overflow-hidden tw-flex-shrink-0 || tw-flex || ${serviceTypeTheme} tw-text-white`}>
				<Tag className="tw-block tw-whitespace-nowrap tw-max-w-full">
					<Tag className="tw-block tw-px-3 tw-py-3 tw-leading-[1.125em] tw-w-full tw-text-ellipsis tw-overflow-hidden tw-text-xs">
						{props?.serviceType}
					</Tag>
				</Tag>
			</Tag>
			<Tag className="tw-flex tw-flex-col tw-flex-grow tw-px-3 tw-pb-3 tw-overflow-y-auto tw-text-dark">
				<Tag className="tw-flex tw-flex-col tw-divide-y tw-divide-light-light tw-space-y-3">
					<Tag className="tw-flex tw-flex-col tw-pt-3">
						<span>Start</span>
						<span>{props?.startTime}</span>
					</Tag>
					<Tag className="tw-flex tw-flex-col tw-pt-3">
						<span>End</span>
						<span>{props?.endTime}</span>
					</Tag>
					{props?.showStatus ? <>
					<Tag className="tw-flex tw-flex-col tw-pt-3">
						<span>Status</span>
						<span>
						{ props?.status.slice(0,8) }
						{ props?.status.length > 8 && <span className="tw-hidden">{ props?.status.slice(8) }</span> }
						</span>
						
						{/* <span>{props?.status}</span> */}
					</Tag>
				
					</>:<>
					</>}
					
				</Tag>
			</Tag>
		</Tag>
	</>;
}

export default InlineServicePanel;
