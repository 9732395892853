const IntrinsicRatio = props => {
	const Tag = props?.tag || 'span';
	const ChildTag = props?.src ? 'img' : props?.childTag || 'span';
	const classes = `o-intrinsic-ratio${ props?.className ? ' || ' + props.className : '' }`;
	const attributes = {
		...props,
		className: classes,
		childClasses: null,
		childTag: null,
		tag: null,
		src: null,
		alt: null
	}

	return <Tag { ...attributes }>
		 <ChildTag src={ props.src || null } alt={ props.alt || null } className={ `o-intrinsic-ratio__element${props?.childClasses ? ` || ${ props.childClasses }` : ''}` }  children={ props?.children || null }/>
	</Tag>
}

export default IntrinsicRatio;
