import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import uuid from "react-uuid";
import { getClientActiveISPByID, getClientByID, getClientHomes, getClientServiceType, getSpanRequirementForPatient, saveCoverageSpanRequirement, updateCoverageSpanRequirement } from "../apicalls/schedulingService";
import { ButtonPrimary, ButtonSecondary } from "../components/buttons/Buttons";
import { Input, Label, Select } from "../components/forms/formFields";
import { PageHeader, PageSubHeader, ParagraphSubheader, SectionHeader } from "../components/typography/Headers";
import { Ellipsis } from "../components/utilities/Helpers";
import IntrinsicRatio from "../components/utilities/IntrinsicRatio";
import Core from "../templates/Core";
import SpanRequirement, { SpanCoverageEvent } from "../types/spanRequirement";
import { pageSubHeader, paragraph3 } from "../utilities/typography";
import { useAppContext } from "../Context";

const CoverageRequirementForm = props => {
    const { patientID } = useParams()

    const appContext = useAppContext();
    
    const [clientServiceTypes, setClientServiceTypes] = useState([]);

	const [clientHomes, setClientHomes] = useState([]);

	const [clientDetails, setClientDetails] = useState(null);

    const [clientServicePlan, setClientServicePlan] = useState(null);

    const [spanRequirement, setSpanRequirement] = useState(null);

    const [spanRequirementAvailable, setSpanRequirementAvailable] = useState(false);

    const [requirementLoading, setRequirementLoading] = useState(true);

    const [saveReqOpen, setSaveReqOpen] = useState(false);

    const [servicePlanAvailable, setServicePlanAvailable] = useState(false);

    const [serviceTypeAvailable, setServiceTypeAvailable] = useState(false);

    const [showInvalidTimeError, setShowInvalidTimeError] = useState(false);

    const handleReqSuccessClose = () => {
		setSaveReqOpen(false);

        navigate(-1);

		//navigate("/ClientRequirement/"+ clientDetails.patientID +"/Requirement");
	}

    let navigate = useNavigate();

    const [values, setValues] = useState<any>({
		"start_date": "",
		"end_date": "",
	});

    const diff_hours = (dt2, dt1) => 
	{
		var duration = moment.duration(moment(dt2).diff(moment(dt1)));
            var hours = duration.asHours();
            // console.log(requirement);
            // console.log(hours);
            // console.log(hours < 0);
            if (hours <= 0) {
                return hours + 24;
            } else {
                return hours;
            }
	
	}

    useEffect(() => {

		const fetchServiceTypes = async () => {
			const serviceTypes = await getClientServiceType(patientID, appContext.handleAPICallError);

            console.log("Service Types:");
			console.log(serviceTypes);

			setClientServiceTypes(serviceTypes);

            if(serviceTypes === null){
                setServiceTypeAvailable(false);
            } else {
                setServiceTypeAvailable(true);
            }
		}

		const fetchClientHomes = async () => {
			const clientHomes = await getClientHomes(patientID, appContext.handleAPICallError);

			console.log(clientHomes);

			setClientHomes(clientHomes);
		}

		const fetchClientDetails = async () => {
			const clientDetails = await getClientByID(patientID, appContext.handleAPICallError);

			console.log(clientDetails);

			setClientDetails(clientDetails);
		}

        const fetchClientServicePlan = async () => {
			const servicePlan = await getClientActiveISPByID(patientID, appContext.handleAPICallError)

            console.log("Service Plan:");
			console.log(servicePlan);

			setClientServicePlan(servicePlan);

            if(servicePlan.status === "Unavailable"){
                setServicePlanAvailable(false);
            } else {
                setServicePlanAvailable(true);
                setValues({...values, "start_date": moment.utc(servicePlan.startDate).format("YYYY-MM-DD"), "end_date": moment.utc(servicePlan.endDate).format("YYYY-MM-DD") });
            }

            
		}

        const fetchSpanRequirement = async () => {
			const requirement = await getSpanRequirementForPatient(patientID, appContext.handleAPICallError)

			console.log(requirement);

			setSpanRequirement(requirement);

            var backupHours = 0.0;
			var withoutBackupHours = 0.0;


            if (requirement.spanRequirementID.length !== 0) {
                setSpanRequirementAvailable(true);

                if (requirement.sundayRequirements !== null){
                    setSundayRequirements(requirement.sundayRequirements);
                    
                }
                if (requirement.mondayRequirements !== null){
                    setMondayRequirements(requirement.mondayRequirements);
                   
                }
                if (requirement.tuesdayRequirements !== null){
                    setTuesdayRequirements(requirement.tuesdayRequirements);
                   
                }
                if (requirement.wednesdayRequirements !== null){
                    setWednesdayRequirements(requirement.wednesdayRequirements);
                    
                }
                if(requirement.thursdayRequirements !== null){
                    setThursdayRequirements(requirement.thursdayRequirements);
                    
                }
                if(requirement.fridayRequirements !== null){
                    setFridayRequirements(requirement.fridayRequirements);
                    
                }
                if(requirement.saturdayRequirements !== null){
                    setSaturdayRequirements(requirement.saturdayRequirements);
                   
                }

                
                
                
            }

       

            setRequirementLoading(false);

		}

		fetchServiceTypes()
			.catch(console.error);

		fetchClientHomes()
			.catch(console.error);

		fetchClientDetails()
			.catch(console.error);

        fetchClientServicePlan()
            .catch(console.error);

        fetchSpanRequirement()
            .catch(console.error);
	}, [patientID])

    const updateCoverageHours = () => {
        var backupHours = 0.0;
        var withoutBackupHours = 0.0;

        sundayRequirements.map((coverage, index) => { 
            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                if(RSWithBackupCodes.includes(coverage.billingCode)){
                    backupHours += parseFloat(coverageHours.toFixed(2));
                } 
                if(RSWithoutBackupCodes.includes(coverage.billingCode)){
                    withoutBackupHours += parseFloat(coverageHours.toFixed(2));
                } 

        });

        mondayRequirements.map((coverage, index) => { 
            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                if(RSWithBackupCodes.includes(coverage.billingCode)){
                    backupHours += parseFloat(coverageHours.toFixed(2));
                } 
                if(RSWithoutBackupCodes.includes(coverage.billingCode)){
                    withoutBackupHours += parseFloat(coverageHours.toFixed(2));
                } 

        });

        tuesdayRequirements.map((coverage, index) => { 
            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                if(RSWithBackupCodes.includes(coverage.billingCode)){
                    backupHours += parseFloat(coverageHours.toFixed(2));
                } 
                if(RSWithoutBackupCodes.includes(coverage.billingCode)){
                    withoutBackupHours += parseFloat(coverageHours.toFixed(2));
                } 

        });

        wednesdayRequirements.map((coverage, index) => { 
            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                if(RSWithBackupCodes.includes(coverage.billingCode)){
                    backupHours += parseFloat(coverageHours.toFixed(2));
                } 
                if(RSWithoutBackupCodes.includes(coverage.billingCode)){
                    withoutBackupHours += parseFloat(coverageHours.toFixed(2));
                } 

        });

        thursdayRequirements.map((coverage, index) => { 
            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                if(RSWithBackupCodes.includes(coverage.billingCode)){
                    backupHours += parseFloat(coverageHours.toFixed(2));
                } 
                if(RSWithoutBackupCodes.includes(coverage.billingCode)){
                    withoutBackupHours += parseFloat(coverageHours.toFixed(2));
                } 

        });

        fridayRequirements.map((coverage, index) => { 
            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                if(RSWithBackupCodes.includes(coverage.billingCode)){
                    backupHours += parseFloat(coverageHours.toFixed(2));
                } 
                if(RSWithoutBackupCodes.includes(coverage.billingCode)){
                    withoutBackupHours += parseFloat(coverageHours.toFixed(2));
                } 

        });

        saturdayRequirements.map((coverage, index) => { 
            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                if(RSWithBackupCodes.includes(coverage.billingCode)){
                    backupHours += parseFloat(coverageHours.toFixed(2));
                } 
                if(RSWithoutBackupCodes.includes(coverage.billingCode)){
                    withoutBackupHours += parseFloat(coverageHours.toFixed(2));
                } 

        });

        setRSWithBackupHours(backupHours);
        setRSWithoutBackupHours(withoutBackupHours);
    }

    

    const [newShiftForm, setNewShiftForm] = useState<any>({
        "showSundayForm": false,
        "showMondayForm": false,
        "showTuesdayForm": false,
        "showWednesdayForm": false,
        "showThursdayForm": false,
        "showFridayForm": false,
        "showSaturdayForm": false,
        "showGeneralForm": false,
    });


    const [SundayHours, setSundayHours] = useState(0.0);
    const [MondayHours, setMondayHours] = useState(0.0);
    const [TuesdayHours, setTuesdayHours] = useState(0.0);
    const [WednesdayHours, setWednesdayHours] = useState(0.0);
    const [ThursdayHours, setThursdayHours] = useState(0.0);
    const [FridayHours, setFridayHours] = useState(0.0);
    const [SaturdayHours, setSaturdayHours] = useState(0.0);

    const [RSWithBackupHours, setRSWithBackupHours] = useState(0.0);
    const [RSWithoutBackupHours, setRSWithoutBackupHours] = useState(0.0);

    const RSWithBackupCodes = ['AMR', 'FMR', 'SMR'];
	const RSWithoutBackupCodes = ['AMS', 'FMS', 'SMS', 'RMS'];

    const startDate = useRef();
    const endDate = useRef();

    const addGeneralRequirement = () => {
        setNewShiftForm({...newShiftForm, "showGeneralForm": true })
    }

    const discardGeneralRequirement = () => {
        setNewShiftForm({...newShiftForm, "showGeneralForm": false })
        setGeneralFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
            "allDay": false,
            "repeats_monday": false,
            "repeats_tuesday": false,
            "repeats_wednesday": false,
            "repeats_thursday": false,
            "repeats_friday": false,
            "repeats_saturday": false,
            "repeats_sunday": false,
        });
    }

    const toggleRepeatsMonday = () => {
		setGeneralFormValues({...generalFormValues, "repeats_monday": !generalFormValues.repeats_monday })
		console.log(generalFormValues.repeats_monday);
	}

	const toggleRepeatsTuesday = () => {
		setGeneralFormValues({...generalFormValues, "repeats_tuesday": !generalFormValues.repeats_tuesday })
	}

	const toggleRepeatsWednesday = () => {
		setGeneralFormValues({...generalFormValues, "repeats_wednesday": !generalFormValues.repeats_wednesday })
	}

	const toggleRepeatsThursday = () => {
		setGeneralFormValues({...generalFormValues, "repeats_thursday": !generalFormValues.repeats_thursday })
	}

	const toggleRepeatsFriday = () => {
		setGeneralFormValues({...generalFormValues, "repeats_friday": !generalFormValues.repeats_friday })
	}

	const toggleRepeatsSaturday = () => {
		setGeneralFormValues({...generalFormValues, "repeats_saturday": !generalFormValues.repeats_saturday })
	}

	const toggleRepeatsSunday = () => {
		setGeneralFormValues({...generalFormValues, "repeats_sunday": !generalFormValues.repeats_sunday })
	}

    const saveGeneralRequirement = () => {
        var newShift = {
            "id": uuid(),
            "startTime": generalFormValues.start_time,
            "endTime": generalFormValues.end_time,
            "shiftType": generalFormValues.shift_type,
        }

        console.log(newShift);
        console.log(generalFormValues);

        const found = clientServiceTypes.find(element => element.shiftType === generalFormValues.shift_type);

        var displayDate = clientServicePlan.displayStartDate;
        if(spanRequirementAvailable){
            displayDate = spanRequirement.displayStartDate;
        }

        var startTime = moment(displayDate + " " + generalFormValues.start_time)
        var endTime = moment(displayDate + " " + generalFormValues.end_time)

        if (generalFormValues.allDay) {
            startTime = moment(displayDate + " 00:00")
            endTime = moment(displayDate + " 00:00")
        } 

        if (endTime < startTime && generalFormValues.end_time != "00:00") {
            setShowInvalidTimeError(true);
        } else {
            setShowInvalidTimeError(false);
        

            let newEvent : SpanCoverageEvent = {
                spanCoverageEventID: uuid(),
                spanRequirementID: "",
                dayOfWeek: 0,
                startTime: startTime.format(),
                displayStartTime: startTime.format("h:mm A"),
                endTime: endTime.format(),
                displayEndTime: endTime.format("h:mm A"),
                staffSize: found.staffSize,
                groupSize: found.groupSize,
                billingCode: found.billingCode,
                shiftType: found.shiftType,
                allDay: generalFormValues.allDay
            }

            if(generalFormValues.repeats_sunday){
                var sundayEvent = JSON.parse(JSON.stringify(newEvent));
                sundayEvent.dayOfWeek = 0;
                setSundayRequirements([...sundayRequirements, sundayEvent]);
            }
            if(generalFormValues.repeats_monday){
                var mondayEvent = JSON.parse(JSON.stringify(newEvent));
                mondayEvent.dayOfWeek = 1;
                setMondayRequirements([...mondayRequirements, mondayEvent]);
            }
            if(generalFormValues.repeats_tuesday){
                var tuesdayEvent = JSON.parse(JSON.stringify(newEvent));
                tuesdayEvent.dayOfWeek = 2;
                setTuesdayRequirements([...tuesdayRequirements, tuesdayEvent]);
            }
            if(generalFormValues.repeats_wednesday){
                var wednesdayEvent = JSON.parse(JSON.stringify(newEvent));
                wednesdayEvent.dayOfWeek = 3;
                setWednesdayRequirements([...wednesdayRequirements, wednesdayEvent]);
            }
            if(generalFormValues.repeats_thursday){
                var thursdayEvent = JSON.parse(JSON.stringify(newEvent));
                thursdayEvent.dayOfWeek = 4;
                setThursdayRequirements([...thursdayRequirements, thursdayEvent]);
            }
            if(generalFormValues.repeats_friday){
                var fridayEvent = JSON.parse(JSON.stringify(newEvent));
                fridayEvent.dayOfWeek = 5;
                setFridayRequirements([...fridayRequirements, fridayEvent]);
            }
            if(generalFormValues.repeats_saturday){
                var saturdayEvent = JSON.parse(JSON.stringify(newEvent));
                saturdayEvent.dayOfWeek = 6;
                setSaturdayRequirements([...saturdayRequirements, saturdayEvent]);
            }


        
            setNewShiftForm({...newShiftForm, "showGeneralForm": false })
            setGeneralFormValues({
                "start_time": "",
                "end_time": "",
                "shift_type": "",
                "allDay": false,
                "repeats_monday": false,
                "repeats_tuesday": false,
                "repeats_wednesday": false,
                "repeats_thursday": false,
                "repeats_friday": false,
                "repeats_saturday": false,
                "repeats_sunday": false,
            });

        }
    }

    const generalStartTime = useRef();
    const generalEndTime = useRef();
    const generalShiftType = useRef();

    const [generalFormValues, setGeneralFormValues] = useState<any>({
		"start_time": "",
		"end_time": "",
		"shift_type": "",
        "allDay": false,
        "repeats_monday": false,
		"repeats_tuesday": false,
		"repeats_wednesday": false,
		"repeats_thursday": false,
		"repeats_friday": false,
		"repeats_saturday": false,
		"repeats_sunday": false,
	});

    const sundayStartTime = useRef();
    const sundayEndTime = useRef();
    const sundayShiftType = useRef();

    const [sundayFormValues, setSundayFormValues] = useState<any>({
		"start_time": "",
		"end_time": "",
		"shift_type": "",
        "allDay": ""
	});

    const [sundayRequirements, setSundayRequirements] = useState([]);

    const addSundayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showSundayForm": true })
    }

    const discardSundayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showSundayForm": false })
        setSundayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const saveSundayRequirement = () => {
        var newShift = {
            "id": uuid(),
            "startTime": sundayFormValues.start_time,
            "endTime": sundayFormValues.end_time,
            "shiftType": sundayFormValues.shift_type,
        }

        console.log(newShift);

        const found = clientServiceTypes.find(element => element.shiftType === sundayFormValues.shift_type);

       

        var startTime = moment(clientServicePlan.displayStartDate + " " + sundayFormValues.start_time)
        var endTime = moment(clientServicePlan.displayStartDate + " " + sundayFormValues.end_time)

        if (sundayFormValues.allDay) {
            startTime = moment(clientServicePlan.displayStartDate + " 00:00")
            endTime = moment(clientServicePlan.displayStartDate + " 00:00")
        } 

        let newEvent : SpanCoverageEvent = {
            spanCoverageEventID: uuid(),
            spanRequirementID: "",
            dayOfWeek: 0,
            startTime: startTime.format(),
            displayStartTime: startTime.format("h:mm A"),
            endTime: endTime.format(),
            displayEndTime: endTime.format("h:mm A"),
            staffSize: found.staffSize,
            groupSize: found.groupSize,
            billingCode: found.billingCode,
            shiftType: found.shiftType,
            allDay: sundayFormValues.allDay
        }

        setSundayRequirements([...sundayRequirements, newEvent]);
        setNewShiftForm({...newShiftForm, "showSundayForm": false })
        setSundayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const mondayStartTime = useRef();
    const mondayEndTime = useRef();
    const mondayShiftType = useRef();

    const [mondayFormValues, setMondayFormValues] = useState<any>({
		"start_time": "",
		"end_time": "",
		"shift_type": "",
	});

    const [mondayRequirements, setMondayRequirements] = useState([]);

    const addMondayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showMondayForm": true })
    }

    const discardMondayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showMondayForm": false })
        setMondayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const saveMondayRequirement = () => {
        const found = clientServiceTypes.find(element => element.shiftType === mondayFormValues.shift_type);

        const startTime = moment(clientServicePlan.displayStartDate + " " + mondayFormValues.start_time)
        const endTime = moment(clientServicePlan.displayStartDate + " " + mondayFormValues.end_time)

        let newEvent : SpanCoverageEvent = {
            spanCoverageEventID: uuid(),
            spanRequirementID: "",
            dayOfWeek: 1,
            startTime: startTime.format(),
            displayStartTime: startTime.format("h:mm A"),
            endTime: endTime.format(),
            displayEndTime: endTime.format("h:mm A"),
            staffSize: found.staffSize,
            groupSize: found.groupSize,
            billingCode: found.billingCode,
            shiftType: found.shiftType,
            allDay: false
        }

        setMondayRequirements([...mondayRequirements, newEvent]);
        setNewShiftForm({...newShiftForm, "showMondayForm": false })
        setMondayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const tuesdayStartTime = useRef();
    const tuesdayEndTime = useRef();
    const tuesdayShiftType = useRef();

    const [tuesdayFormValues, setTuesdayFormValues] = useState<any>({
		"start_time": "",
		"end_time": "",
		"shift_type": "",
	});

    const [tuesdayRequirements, setTuesdayRequirements] = useState([]);

    const addTuesdayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showTuesdayForm": true })
    }

    const discardTuesdayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showTuesdayForm": false })
        setTuesdayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const saveTuesdayRequirement = () => {
        const found = clientServiceTypes.find(element => element.shiftType === tuesdayFormValues.shift_type);

        const startTime = moment(clientServicePlan.displayStartDate + " " + tuesdayFormValues.start_time)
        const endTime = moment(clientServicePlan.displayStartDate + " " + tuesdayFormValues.end_time)

        let newEvent : SpanCoverageEvent = {
            spanCoverageEventID: uuid(),
            spanRequirementID: "",
            dayOfWeek: 2,
            startTime: startTime.format(),
            displayStartTime: startTime.format("h:mm A"),
            endTime: endTime.format(),
            displayEndTime: endTime.format("h:mm A"),
            staffSize: found.staffSize,
            groupSize: found.groupSize,
            billingCode: found.billingCode,
            shiftType: found.shiftType,
            allDay: false
        }

        setTuesdayRequirements([...tuesdayRequirements, newEvent]);
        setNewShiftForm({...newShiftForm, "showTuesdayForm": false })
        setTuesdayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const wednesdayStartTime = useRef();
    const wednesdayEndTime = useRef();
    const wednesdayShiftType = useRef();

    const [wednesdayFormValues, setWednesdayFormValues] = useState<any>({
		"start_time": "",
		"end_time": "",
		"shift_type": "",
	});

    const [wednesdayRequirements, setWednesdayRequirements] = useState([]);

    const addWednesdayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showWednesdayForm": true })
    }

    const discardWednesdayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showWednesdayForm": false })
        setWednesdayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const saveWednesdayRequirement = () => {
        const found = clientServiceTypes.find(element => element.shiftType === wednesdayFormValues.shift_type);

        const startTime = moment(clientServicePlan.displayStartDate + " " + wednesdayFormValues.start_time)
        const endTime = moment(clientServicePlan.displayStartDate + " " + wednesdayFormValues.end_time)


        let newEvent : SpanCoverageEvent = {
            spanCoverageEventID: uuid(),
            spanRequirementID: "",
            dayOfWeek: 3,
            startTime: startTime.format(),
            displayStartTime: startTime.format("h:mm A"),
            endTime: endTime.format(),
            displayEndTime: endTime.format("h:mm A"),
            staffSize: found.staffSize,
            groupSize: found.groupSize,
            billingCode: found.billingCode,
            shiftType: found.shiftType,
            allDay: false
        }

        setWednesdayRequirements([...wednesdayRequirements, newEvent]);
        setNewShiftForm({...newShiftForm, "showWednesdayForm": false })
        setWednesdayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const thursdayStartTime = useRef();
    const thursdayEndTime = useRef();
    const thursdayShiftType = useRef();

    const [thursdayFormValues, setThursdayFormValues] = useState<any>({
		"start_time": "",
		"end_time": "",
		"shift_type": "",
	});

    const [thursdayRequirements, setThursdayRequirements] = useState([]);

    const addThursdayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showThursdayForm": true })
    }

    const discardThursdayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showThursdayForm": false })
        setThursdayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const saveThursdayRequirement = () => {
        const found = clientServiceTypes.find(element => element.shiftType === thursdayFormValues.shift_type);

        const startTime = moment(clientServicePlan.displayStartDate + " " + thursdayFormValues.start_time)
        const endTime = moment(clientServicePlan.displayStartDate + " " + thursdayFormValues.end_time)

        let newEvent : SpanCoverageEvent = {
            spanCoverageEventID: uuid(),
            spanRequirementID: "",
            dayOfWeek: 4,
            startTime: startTime.format(),
            displayStartTime: startTime.format("h:mm A"),
            endTime: endTime.format(),
            displayEndTime: endTime.format("h:mm A"),
            staffSize: found.staffSize,
            groupSize: found.groupSize,
            billingCode: found.billingCode,
            shiftType: found.shiftType,
            allDay: false
        }

        setThursdayRequirements([...thursdayRequirements, newEvent]);
        setNewShiftForm({...newShiftForm, "showThursdayForm": false })
        setThursdayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const fridayStartTime = useRef();
    const fridayEndTime = useRef();
    const fridayShiftType = useRef();

    const [fridayFormValues, setFridayFormValues] = useState<any>({
		"start_time": "",
		"end_time": "",
		"shift_type": "",
	});

    const [fridayRequirements, setFridayRequirements] = useState([]);

    const addFridayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showFridayForm": true })
    }

    const discardFridayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showFridayForm": false })
        setFridayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const saveFridayRequirement = () => {
        const found = clientServiceTypes.find(element => element.shiftType === fridayFormValues.shift_type);

        const startTime = moment(clientServicePlan.displayStartDate + " " + fridayFormValues.start_time)
        const endTime = moment(clientServicePlan.displayStartDate + " " + fridayFormValues.end_time)

        let newEvent : SpanCoverageEvent = {
            spanCoverageEventID: uuid(),
            spanRequirementID: "",
            dayOfWeek: 5,
            startTime: startTime.format(),
            displayStartTime: startTime.format("h:mm A"),
            endTime: endTime.format(),
            displayEndTime: endTime.format("h:mm A"),
            staffSize: found.staffSize,
            groupSize: found.groupSize,
            billingCode: found.billingCode,
            shiftType: found.shiftType,
            allDay: false
        }

        setFridayRequirements([...fridayRequirements, newEvent]);
        setNewShiftForm({...newShiftForm, "showFridayForm": false })
        setFridayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const saturdayStartTime = useRef();
    const saturdayEndTime = useRef();
    const saturdayShiftType = useRef();

    const [saturdayFormValues, setSaturdayFormValues] = useState<any>({
		"start_time": "",
		"end_time": "",
		"shift_type": "",
	});

    const [saturdayRequirements, setSaturdayRequirements] = useState([]);

    const addSaturdayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showSaturdayForm": true })
    }

    const discardSaturdayRequirement = () => {
        setNewShiftForm({...newShiftForm, "showSaturdayForm": false })
        setSaturdayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const saveSaturdayRequirement = () => {
        const found = clientServiceTypes.find(element => element.shiftType === saturdayFormValues.shift_type);

        const startTime = moment(clientServicePlan.displayStartDate + " " + saturdayFormValues.start_time)
        const endTime = moment(clientServicePlan.displayStartDate + " " + saturdayFormValues.end_time)


        let newEvent : SpanCoverageEvent = {
            spanCoverageEventID: uuid(),
            spanRequirementID: "",
            dayOfWeek: 6,
            startTime: startTime.format(),
            displayStartTime: startTime.format("h:mm A"),
            endTime: endTime.format(),
            displayEndTime: endTime.format("h:mm A"),
            staffSize: found.staffSize,
            groupSize: found.groupSize,
            billingCode: found.billingCode,
            shiftType: found.shiftType,
            allDay: false
        }

        setSaturdayRequirements([...saturdayRequirements, newEvent]);
        setNewShiftForm({...newShiftForm, "showSaturdayForm": false })
        setSaturdayFormValues({
            "start_time": "",
            "end_time": "",
            "shift_type": "",
        });
    }

    const saveCoverageRequirement = async () => {
        var StartDate = moment(values.start_date);
        var EndDate = moment(values.end_date);

        let sundayMap = new Map();
        let mondayMap = new Map();
        let tuesdayMap = new Map();
        let wednesdayMap = new Map();
        let thursdayMap = new Map();
        let fridayMap = new Map();
        let saturdayMap = new Map();

        clientServiceTypes.forEach( (serviceType) => {
            sundayMap.set(serviceType.billingCode, 0.0);
            mondayMap.set(serviceType.billingCode, 0.0);
            tuesdayMap.set(serviceType.billingCode, 0.0);
            wednesdayMap.set(serviceType.billingCode, 0.0);
            thursdayMap.set(serviceType.billingCode, 0.0);
            fridayMap.set(serviceType.billingCode, 0.0);
            saturdayMap.set(serviceType.billingCode, 0.0);
        });

        sundayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            sundayMap.set(requirement.billingCode, sundayMap.get(requirement.billingCode) + hours);
        });

        mondayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            mondayMap.set(requirement.billingCode, mondayMap.get(requirement.billingCode) + hours);
        });

        tuesdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            tuesdayMap.set(requirement.billingCode, tuesdayMap.get(requirement.billingCode) + hours);
        });

        wednesdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            wednesdayMap.set(requirement.billingCode, wednesdayMap.get(requirement.billingCode) + hours);
        });

        thursdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            thursdayMap.set(requirement.billingCode, thursdayMap.get(requirement.billingCode) + hours);
        });

        fridayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            fridayMap.set(requirement.billingCode, fridayMap.get(requirement.billingCode) + hours);
        });

        saturdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            saturdayMap.set(requirement.billingCode, saturdayMap.get(requirement.billingCode) + hours);
        });

        console.log(sundayMap);
        console.log(mondayMap);
        console.log(tuesdayMap);
        console.log(wednesdayMap);
        console.log(thursdayMap);
        console.log(fridayMap);
        console.log(saturdayMap);


        let coverageRequirement : SpanRequirement = {
            spanRequirementID: uuid(),
            patientID: patientID,
            planID: clientServicePlan.planID,
            startDate: StartDate.format(),
            displayStartDate: "",
            endDate: EndDate.format(),
            displayEndDate: "",
            sundayRequirements: sundayRequirements,
            mondayRequirements: mondayRequirements,
            tuesdayRequirements: tuesdayRequirements,
            wednesdayRequirements: wednesdayRequirements,
            thursdayRequirements: thursdayRequirements,
            fridayRequirements: fridayRequirements,
            saturdayRequirements: saturdayRequirements
        }

        console.log(coverageRequirement);

        setShowReqLoading(true);

        const response = await saveCoverageSpanRequirement(coverageRequirement, appContext.handleAPICallError);

        setShowReqLoading(false);

        setSaveReqOpen(true);

        //navigate("/ClientRequirement/"+ clientDetails.patientID +"/Requirement");
    }

    const discardCoverageRequirement = async () => {
        navigate(-1);
    }

    const updateCoverageRequirement = async () => {

        var StartDate = moment(spanRequirement.displayStartDate);
        var EndDate = moment(spanRequirement.displayEndDate);

        let sundayMap = new Map();
        let mondayMap = new Map();
        let tuesdayMap = new Map();
        let wednesdayMap = new Map();
        let thursdayMap = new Map();
        let fridayMap = new Map();
        let saturdayMap = new Map();

        clientServiceTypes.forEach( (serviceType) => {
            sundayMap.set(serviceType.billingCode, 0.0);
            mondayMap.set(serviceType.billingCode, 0.0);
            tuesdayMap.set(serviceType.billingCode, 0.0);
            wednesdayMap.set(serviceType.billingCode, 0.0);
            thursdayMap.set(serviceType.billingCode, 0.0);
            fridayMap.set(serviceType.billingCode, 0.0);
            saturdayMap.set(serviceType.billingCode, 0.0);
        });

        sundayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            sundayMap.set(requirement.billingCode, sundayMap.get(requirement.billingCode) + hours);
        });

        mondayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            mondayMap.set(requirement.billingCode, mondayMap.get(requirement.billingCode) + hours);
        });

        tuesdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            tuesdayMap.set(requirement.billingCode, tuesdayMap.get(requirement.billingCode) + hours);
        });

        wednesdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            wednesdayMap.set(requirement.billingCode, wednesdayMap.get(requirement.billingCode) + hours);
        });

        thursdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            thursdayMap.set(requirement.billingCode, thursdayMap.get(requirement.billingCode) + hours);
        });

        fridayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            fridayMap.set(requirement.billingCode, fridayMap.get(requirement.billingCode) + hours);
        });

        saturdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            saturdayMap.set(requirement.billingCode, saturdayMap.get(requirement.billingCode) + hours);
        });

        console.log(sundayMap);
        console.log(mondayMap);
        console.log(tuesdayMap);
        console.log(wednesdayMap);
        console.log(thursdayMap);
        console.log(fridayMap);
        console.log(saturdayMap);


        let coverageRequirement : SpanRequirement = {
            spanRequirementID: spanRequirement.spanRequirementID,
            patientID: patientID,
            planID: clientServicePlan.planID,
            startDate: StartDate.format(),
            displayStartDate: "",
            endDate: EndDate.format(),
            displayEndDate: "",
            sundayRequirements: sundayRequirements,
            mondayRequirements: mondayRequirements,
            tuesdayRequirements: tuesdayRequirements,
            wednesdayRequirements: wednesdayRequirements,
            thursdayRequirements: thursdayRequirements,
            fridayRequirements: fridayRequirements,
            saturdayRequirements: saturdayRequirements
        }

        console.log(coverageRequirement);

        setShowReqLoading(true);

        const response = await updateCoverageSpanRequirement(coverageRequirement, appContext.handleAPICallError);

        setShowReqLoading(false);

        setSaveReqOpen(true);

        //navigate("/ClientRequirement/"+ clientDetails.patientID +"/Requirement");
    }

   

    const [showReqLoading, setShowReqLoading] = useState(false);

    useEffect(() => {
        var sundayHours = 0.0;

        sundayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();
            // console.log(requirement);
            // console.log(hours);
            // console.log(hours < 0);
            if (hours <= 0) {
                sundayHours += hours + 24;
            } else {
                sundayHours += hours;
            }
            
        });

        //console.log(sundayHours);

        setSundayHours(sundayHours);

        updateCoverageHours();
        
    }, [sundayRequirements])

    useEffect(() => {
        var mondayHours = 0.0;

        mondayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();
            if (hours <= 0) {
                mondayHours += hours + 24;
            } else {
                mondayHours += hours;
            }
        });

        setMondayHours(mondayHours);
        updateCoverageHours();
        
    }, [mondayRequirements])

    useEffect(() => {
        var tuesdayHours = 0.0;

        tuesdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();
            if (hours <= 0) {
                tuesdayHours += hours + 24;
            } else {
                tuesdayHours += hours;
            }
        });

        setTuesdayHours(tuesdayHours);
        updateCoverageHours();
        
    }, [tuesdayRequirements])

    useEffect(() => {
        var wednesdayHours = 0.0;

        wednesdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();
            if (hours <= 0) {
                wednesdayHours += hours + 24;
            } else {
                wednesdayHours += hours;
            }
        });

        setWednesdayHours(wednesdayHours);
        updateCoverageHours();
        
    }, [wednesdayRequirements])

    useEffect(() => {
        var thursdayHours = 0.0;

        thursdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();
            if (hours <= 0) {
                thursdayHours += hours + 24;
            } else {
                thursdayHours += hours;
            }
        });

        setThursdayHours(thursdayHours);
        updateCoverageHours();
        
    }, [thursdayRequirements])

    useEffect(() => {
        var fridayHours = 0.0;

        fridayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();
            if (hours <= 0) {
                fridayHours += hours + 24;
            } else {
                fridayHours += hours;
            }
        });

        setFridayHours(fridayHours);
        updateCoverageHours();
        
    }, [fridayRequirements])

    useEffect(() => {
        var saturdayHours = 0.0;

        saturdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();
            if (hours <= 0) {
                saturdayHours += hours + 24;
            } else {
                saturdayHours += hours;
            }
        });

        setSaturdayHours(saturdayHours);
        updateCoverageHours();
        
    }, [saturdayRequirements])

    return (<>
    {/* <Core> */}
    <Dialog
			open={saveReqOpen}
			onClose={handleReqSuccessClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
			{"Success!"}
			</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">
				Coverage Requirements for Span saved successfully
			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleReqSuccessClose}>Ok</Button>
			</DialogActions>
		</Dialog>
        <div className="tw-px-xl tw-py-xl">
            {/* <div className="tw-flex tw-items-center tw-flex tw-border-b tw-border-light tw-pb">
					<div className="tw-flex-grow tw-flex-shrink-0">
						<PageHeader>
							<Ellipsis>
								Client Coverage Requirements
							</Ellipsis>
						</PageHeader>
					</div>
			</div> */}
            <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Client Span Requirements</h2>
            </div>
            <div className="tw-px-xl tw-pb-xl tw-text-dark">
                <div>
                { requirementLoading ? 
                    <div className="tw-flex tw-justify-center tw-m-md">
                        <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                    </div>:
                    <div>

                        { !servicePlanAvailable ? <>
                            <div className="tw-flex">
                                            <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                <Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                    Note: Client has no active service plan
                                                </Label>
                                            </div>
                            </div>
                        </>:<></>}

                        { !serviceTypeAvailable ? <>
                            <div className="tw-flex">
                                            <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                <Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                    Note: Client has no authorized service types
                                                </Label>
                                            </div>
                            </div>
                        </>:<></>}

                        { servicePlanAvailable && serviceTypeAvailable ? <>
                            <form className="tw-p-0 tw-m-0 tw-border-0">
                            <PageSubHeader className="tw-py-xl" tag="legend">
								{clientDetails?.firstName} {clientDetails?.lastName} - {spanRequirementAvailable ? spanRequirement?.displayStartDate : clientServicePlan?.displayStartDate} --- {spanRequirementAvailable ? spanRequirement?.displayEndDate : clientServicePlan?.displayEndDate}
							</PageSubHeader>
                            <div className="tw-space-y">

                            <div className="-tw-m">
                                    {spanRequirementAvailable ? <>

                                    </>:<>

                                        <div className="tw-flex">
                                            <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                <Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                    Span Start Date
                                                </Label>
                                            </div>
                                            <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                            <Input
                                            min="2000-01-01" max="2024-12-31"
                                            type="date" ref={ startDate }
                                            value={ values.start_date } onChange={ e => {
                                                console.log(e.target.value);
                                                setValues({...values, "start_date": e.target.value }); }}  alt className="tw-flex-grow tw-max-w-300"/>
                                            </div>
                                        </div>
                                        <div className="tw-flex">
                                            <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                <Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                    Span End Date
                                                </Label>
                                            </div>
                                            <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                            <Input
                                            min="2000-01-01" max="2024-12-31"
                                            type="date" ref={ endDate }
                                            value={ values.end_date } onChange={ e => setValues({...values, "end_date": e.target.value }) }  alt className="tw-flex-grow tw-max-w-300"/>
                                            </div>
                                        </div>


                                    </> }

                                    <div className="tw-flex">
                                            <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                <Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                    Total Hours
                                                </Label>
                                            </div>
                                            <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                <span className="tw-text-2 tw-text-primary">
                                                    {SundayHours + MondayHours + TuesdayHours + WednesdayHours + ThursdayHours + FridayHours + SaturdayHours} Hours
                                                </span>
                                            </div>
                                    </div>

                                    <div className="tw-flex">
                                            <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                <Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                    RS With Backup Hours
                                                </Label>
                                            </div>
                                            <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                <span className="tw-text-2 tw-text-primary">
                                                    {RSWithBackupHours} Hours
                                                </span>
                                            </div>
                                            <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                <Label for="date" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                    RS Without Backup Hours
                                                </Label>
                                            </div>
                                            <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                <span className="tw-text-2 tw-text-primary">
                                                    {RSWithoutBackupHours} Hours
                                                </span>
                                            </div>
                                    </div>

                                    <div className="tw-flex-grow tw-flex tw-items-start tw-mt-sm tw-p" >
                                            {!newShiftForm.showGeneralForm ? <>
                                                <ButtonPrimary small  onClick={ e => {
                                                            addGeneralRequirement();
                                                            e.preventDefault();
                                                        }}>
                                                    Add Requirement
                                                </ButtonPrimary>
                                            </>:<></>}
                                    </div>

                                    {newShiftForm.showGeneralForm ? <>
                                        <div className="tw-p tw-bg-lime-100">
                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
                                                        <Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Shift Type
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-400px">
                                                        <Select id="shift_type" onChange={ e => setGeneralFormValues({...generalFormValues, "shift_type": e.target.value }) } ref={ generalShiftType } value={ generalFormValues.shift_type } alt className="tw-flex-grow tw-max-w-500">
                                                            <option value="">--Select--</option>
                                                            {
                                                                clientServiceTypes.map(service =>
                                                                    <option value={service.shiftType}>{service.shiftType}</option>)
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
                                                        <Label for="all_day" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            All Day
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
                                                            <Checkbox
                                                                    checked={generalFormValues.allDay}
                                                                    onChange={ e => setGeneralFormValues({...generalFormValues, "allDay": !generalFormValues.allDay }) }
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                    </div>
                                                </div>
                                            </div> */}

                                            { generalFormValues.allDay ? <></> : <>
                                                <div className="-tw-m">
                                                    <div className="tw-flex">
                                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
                                                            <Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                                Start Time
                                                            </Label>
                                                        </div>
                                                        <div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
                                                            <Input id="start_time" type="time" onChange={ e => setGeneralFormValues({...generalFormValues, "start_time": e.target.value }) } ref={ generalStartTime } value={ generalFormValues.start_time } alt className="tw-flex-grow tw-max-w-300" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="-tw-m">
                                                    <div className="tw-flex">
                                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
                                                            <Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                                End Time
                                                            </Label>
                                                        </div>
                                                        <div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
                                                            <Input id="end_time" type="time" onChange={ e => setGeneralFormValues({...generalFormValues, "end_time": e.target.value }) } ref={ generalEndTime } value={ generalFormValues.end_time } alt className="tw-flex-grow tw-max-w-300" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}

                                            
                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200px">
                                                        <Label for="repeats" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Repeats
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p tw-max-w-250px">
                                                    <li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsMonday}>
                                                        <IntrinsicRatio className={`tw-text-primary ${generalFormValues.repeats_monday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
                                                            M
                                                        </IntrinsicRatio>
                                                    </li>
                                                    <li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsTuesday}>
                                                        <IntrinsicRatio className={`tw-text-primary ${generalFormValues.repeats_tuesday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
                                                            Tu
                                                        </IntrinsicRatio>
                                                    </li>
                                                    <li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsWednesday}>
                                                        <IntrinsicRatio className={`tw-text-primary ${generalFormValues.repeats_wednesday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
                                                            W
                                                        </IntrinsicRatio>
                                                    </li>
                                                    <li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsThursday}>
                                                        <IntrinsicRatio className={`tw-text-primary ${generalFormValues.repeats_thursday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
                                                            Th
                                                        </IntrinsicRatio>
                                                    </li>
                                                    <li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsFriday}>
                                                        <IntrinsicRatio className={`tw-text-primary ${generalFormValues.repeats_friday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
                                                            F
                                                        </IntrinsicRatio>
                                                    </li>
                                                    <li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsSaturday}>
                                                        <IntrinsicRatio className={`tw-text-primary ${generalFormValues.repeats_saturday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
                                                            Sa
                                                        </IntrinsicRatio>
                                                    </li>
                                                    <li className="tw-p-xs tw-flex tw-items-center tw-flex-shrink-0 tw-w-12 tw-cursor-pointer" onClick={toggleRepeatsSunday}>
                                                        <IntrinsicRatio className={`tw-text-primary ${generalFormValues.repeats_sunday ? 'tw-text-white tw-bg-primary' : 'tw-text-[#12B4B9]'} || tw-border tw-border-[#12B4B9] tw-rounded-lg tw-flex tw-flex-grow`} childClasses="tw-flex tw-items-center tw-justify-center">
                                                            Su
                                                        </IntrinsicRatio>
                                                    </li>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m tw-px-4">
                                                {showInvalidTimeError && <p className="tw-text-red-500">End Time Cannot be earlier than Start Time</p>}
                                            </div>
                                            

                                            <div className="tw-pt-xxl tw-pb-xl">
                                                <ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
                                                    <li>
                                                        <ButtonSecondary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                discardGeneralRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Discard
                                                        </ButtonSecondary>
                                                    </li>
                                                    <li>
                                                        <ButtonPrimary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                saveGeneralRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Save
                                                        </ButtonPrimary>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>:<></>}
									
                                    <div className="tw-flex">
                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                            <ParagraphSubheader>
                                                Sunday
                                            </ParagraphSubheader>
                                            <span className="tw-text-2 tw-text-primary tw-pl">
                                                    {SundayHours} Hours
                                            </span>
                                        </div>
                                        <div className="tw-flex-grow tw-flex tw-items-start tw-mt-sm tw-p" >
                                            {/* {!newShiftForm.showSundayForm ? <>
                                                <ButtonPrimary small  onClick={ e => {
                                                            addSundayRequirement();
                                                            e.preventDefault();
                                                        }}>
                                                    Add
                                                </ButtonPrimary>
                                            </>:<></>} */}
                                        </div>
                                    </div>
                                    {newShiftForm.showSundayForm ? <>
                                        <div className="tw-p tw-bg-lime-100">
                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Shift Type
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Select id="shift_type" onChange={ e => setSundayFormValues({...sundayFormValues, "shift_type": e.target.value }) } ref={ sundayShiftType } value={ sundayFormValues.shift_type } alt className="tw-flex-grow tw-max-w-500">
                                                            <option value="">--Select--</option>
                                                            {
                                                                clientServiceTypes.map(service =>
                                                                    <option value={service.shiftType}>{service.shiftType}</option>)
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="all_day" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            All Day
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                            <Checkbox
                                                                    checked={sundayFormValues.allDay}
                                                                    onChange={ e => setSundayFormValues({...sundayFormValues, "allDay": !sundayFormValues.allDay }) }
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                    </div>
                                                </div>
                                            </div>

                                            { sundayFormValues.allDay ? <></> : <>
                                                <div className="-tw-m">
                                                    <div className="tw-flex">
                                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                            <Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                                Start Time
                                                            </Label>
                                                        </div>
                                                        <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                            <Input id="start_time" type="time" onChange={ e => setSundayFormValues({...sundayFormValues, "start_time": e.target.value }) } ref={ sundayStartTime } value={ sundayFormValues.start_time } alt className="tw-flex-grow tw-max-w-300" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="-tw-m">
                                                    <div className="tw-flex">
                                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                            <Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                                End Time
                                                            </Label>
                                                        </div>
                                                        <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                            <Input id="end_time" type="time" onChange={ e => setSundayFormValues({...sundayFormValues, "end_time": e.target.value }) } ref={ sundayEndTime } value={ sundayFormValues.end_time } alt className="tw-flex-grow tw-max-w-300" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}

                                            

                                            

                                            <div className="tw-pt-xxl tw-pb-xl">
                                                <ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
                                                    <li>
                                                        <ButtonSecondary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                discardSundayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Discard
                                                        </ButtonSecondary>
                                                    </li>
                                                    <li>
                                                        <ButtonPrimary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                saveSundayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Save
                                                        </ButtonPrimary>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>:<></>}


                                    
                                    <div className="tw-flex tw-flex-wrap">
                                        { sundayRequirements.length === 0 ? <>
                                            <span className="tw-text-2 tw-text-primary">
                                                No Coverage Requirements
                                            </span>
                                        </>:<></> }
                                        { sundayRequirements
                                        .sort((a, b) => moment(a.startTime) > moment(b.startTime) ? 1 : -1)
                                        .map((requirement) => {
                                            return <>
                                                 <div className="tw-flex tw-flex-shrink-0 tw-w-1/2 tw-basis-1/2">
                                                    <div className="tw-m-sm tw-bg-white tw-flex tw-flex-col tw-flew-grow tw-w-full">
                                                        <div className={`tw-flex tw-p tw-items-center tw-justify-between tw-w-full tw-min-h-30 ${RSWithBackupCodes.includes(requirement.billingCode) ? "tw-bg-accent-dark":"tw-bg-secondary"}`}>
                                                            <SectionHeader tag="h3" className="tw-text-white">
                                                                {requirement.shiftType} {requirement.allDay ? "- All Day": ""}
                                                            </SectionHeader>
                                                        </div>
                                                        <div className="tw-px tw-pb">
                                                            <div className="tw-flex tw-items-end tw-pt-lg tw-pb">
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        From
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayStartTime}
                                                                    </span>
                                                                </div>
                                                                <span className="tw-px-sm tw-text-2">
                                                                    &mdash;
                                                                </span>
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        To
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayEndTime}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-min-h-30">
                                                                <div>
                                                                    <ButtonPrimary small className="tw-min-w-150" onClick={ e => {
                                                                        setSundayRequirements(
                                                                            sundayRequirements.filter(a => a.spanCoverageEventID !== requirement.spanCoverageEventID)
                                                                        );
                                                                        e.preventDefault();
                                                                    }}>
                                                                        Remove
                                                                    </ButtonPrimary>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                            </>
                                        }) }
                                    </div>
                                    


                                    <div className="tw-flex">
                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                            <ParagraphSubheader>
                                                Monday
                                            </ParagraphSubheader>
                                            <span className="tw-text-2 tw-text-primary tw-pl">
                                                    {MondayHours} Hours
                                            </span>
                                        </div>
                                        <div className="tw-flex-grow tw-flex tw-items-start tw-mt-sm tw-p">
                                            {/* {!newShiftForm.showMondayForm ? <>
                                                    <ButtonPrimary small  onClick={ e => {
                                                                addMondayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                        Add
                                                    </ButtonPrimary>
                                                </>:<></>} */}
                                        </div>
                                    </div>

                                    {newShiftForm.showMondayForm ? <>
                                        <div className="tw-p tw-bg-lime-100">
                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Shift Type
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Select id="shift_type" onChange={ e => setMondayFormValues({...mondayFormValues, "shift_type": e.target.value }) } ref={ mondayShiftType } value={ mondayFormValues.shift_type } alt className="tw-flex-grow tw-max-w-500">
                                                            <option value="">--Select--</option>
                                                            {
                                                                clientServiceTypes.map(service =>
                                                                    <option value={service.shiftType}>{service.shiftType}</option>)
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Start Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="start_time" type="time" onChange={ e => setMondayFormValues({...mondayFormValues, "start_time": e.target.value }) } ref={ mondayStartTime } value={ mondayFormValues.start_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            End Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="end_time" type="time" onChange={ e => setMondayFormValues({...mondayFormValues, "end_time": e.target.value }) } ref={ mondayEndTime } value={ mondayFormValues.end_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                           

                                            <div className="tw-pt-xxl tw-pb-xl">
                                                <ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
                                                    <li>
                                                        <ButtonSecondary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                discardMondayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Discard
                                                        </ButtonSecondary>
                                                    </li>
                                                    <li>
                                                        <ButtonPrimary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                saveMondayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Save
                                                        </ButtonPrimary>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>:<></>}

                                    <div className="tw-flex tw-flex-wrap">
                                        { mondayRequirements.length === 0 ? <>
                                            <span className="tw-text-2 tw-text-primary">
                                                No Coverage Requirements
                                            </span>
                                        </>:<></> }

                                        { mondayRequirements
                                        .sort((a, b) => moment(a.startTime) > moment(b.startTime) ? 1 : -1)
                                        .map((requirement) => {
                                            return <>
                                                   <div className="tw-flex tw-flex-shrink-0 tw-w-1/2 tw-basis-1/2">
                                                    <div className="tw-m-sm tw-bg-white tw-flex tw-flex-col tw-flew-grow tw-w-full">
                                                        <div className={`tw-flex tw-p tw-items-center tw-justify-between tw-w-full tw-min-h-30 ${RSWithBackupCodes.includes(requirement.billingCode) ? "tw-bg-accent-dark":"tw-bg-secondary"}`}>
                                                            <SectionHeader tag="h3" className="tw-text-white">
                                                                {requirement.shiftType} {requirement.allDay ? "- All Day": ""}
                                                            </SectionHeader>
                                                        </div>
                                                        <div className="tw-px tw-pb">
                                                            <div className="tw-flex tw-items-end tw-pt-lg tw-pb">
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        From
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayStartTime}
                                                                    </span>
                                                                </div>
                                                                <span className="tw-px-sm tw-text-2">
                                                                    &mdash;
                                                                </span>
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        To
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayEndTime}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-min-h-30">
                                                                <div>
                                                                    <ButtonPrimary small className="tw-min-w-150" onClick={ e => {
                                                                        setMondayRequirements(
                                                                            mondayRequirements.filter(a => a.spanCoverageEventID !== requirement.spanCoverageEventID)
                                                                        );
                                                                        e.preventDefault();
                                                                    }}>
                                                                        Remove
                                                                    </ButtonPrimary>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                            </>
                                        }) }
                                    </div>

                                    <div className="tw-flex">
                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                            <ParagraphSubheader>
                                                Tuesday
                                            </ParagraphSubheader>
                                            <span className="tw-text-2 tw-text-primary tw-pl">
                                                    {TuesdayHours} Hours
                                            </span>
                                        </div>
                                        <div className="tw-flex-grow tw-flex tw-items-start tw-mt-sm tw-p">
                                                {/* {!newShiftForm.showTuesdayForm ? <>
                                                    <ButtonPrimary small  onClick={ e => {
                                                                addTuesdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                        Add
                                                    </ButtonPrimary>
                                                </>:<></>} */}
                                        </div>
                                    </div>

                                    {newShiftForm.showTuesdayForm ? <>
                                        <div className="tw-p tw-bg-lime-100">
                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Shift Type
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Select id="shift_type" onChange={ e => setTuesdayFormValues({...tuesdayFormValues, "shift_type": e.target.value }) } ref={ tuesdayShiftType } value={ tuesdayFormValues.shift_type } alt className="tw-flex-grow tw-max-w-500">
                                                            <option value="">--Select--</option>
                                                            {
                                                                clientServiceTypes.map(service =>
                                                                    <option value={service.shiftType}>{service.shiftType}</option>)
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Start Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="start_time" type="time" onChange={ e => setTuesdayFormValues({...tuesdayFormValues, "start_time": e.target.value }) } ref={ tuesdayStartTime } value={ tuesdayFormValues.start_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            End Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="end_time" type="time" onChange={ e => setTuesdayFormValues({...tuesdayFormValues, "end_time": e.target.value }) } ref={ tuesdayEndTime } value={ tuesdayFormValues.end_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tw-pt-xxl tw-pb-xl">
                                                <ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
                                                    <li>
                                                        <ButtonSecondary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                discardTuesdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Discard
                                                        </ButtonSecondary>
                                                    </li>
                                                    <li>
                                                        <ButtonPrimary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                saveTuesdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Save
                                                        </ButtonPrimary>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>:<></>}

                                    <div className="tw-flex tw-flex-wrap">
                                        { tuesdayRequirements.length === 0 ? <>
                                            <span className="tw-text-2 tw-text-primary">
                                                No Coverage Requirements
                                            </span>
                                        </>:<></> }
                                        { tuesdayRequirements
                                        .sort((a, b) => moment(a.startTime) > moment(b.startTime) ? 1 : -1)
                                        .map((requirement) => {
                                            return <>
                                                <div className="tw-flex tw-flex-shrink-0 tw-w-1/2 tw-basis-1/2">
                                                    <div className="tw-m-sm tw-bg-white tw-flex tw-flex-col tw-flew-grow tw-w-full">
                                                        <div className={`tw-flex tw-p tw-items-center tw-justify-between tw-w-full tw-min-h-30 ${RSWithBackupCodes.includes(requirement.billingCode) ? "tw-bg-accent-dark":"tw-bg-secondary"}`}>
                                                            <SectionHeader tag="h3" className="tw-text-white">
                                                                {requirement.shiftType} {requirement.allDay ? "- All Day": ""}
                                                            </SectionHeader>
                                                        </div>
                                                        <div className="tw-px tw-pb">
                                                            <div className="tw-flex tw-items-end tw-pt-lg tw-pb">
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        From
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayStartTime}
                                                                    </span>
                                                                </div>
                                                                <span className="tw-px-sm tw-text-2">
                                                                    &mdash;
                                                                </span>
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        To
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayEndTime}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-min-h-30">
                                                                <div>
                                                                    <ButtonPrimary small className="tw-min-w-150" onClick={ e => {
                                                                        setTuesdayRequirements(
                                                                            tuesdayRequirements.filter(a => a.spanCoverageEventID !== requirement.spanCoverageEventID)
                                                                        );
                                                                        e.preventDefault();
                                                                    }}>
                                                                        Remove
                                                                    </ButtonPrimary>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                            </>
                                        }) }
                                    </div>

                                    <div className="tw-flex">
                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                            <ParagraphSubheader>
                                                Wednesday
                                            </ParagraphSubheader>
                                            <span className="tw-text-2 tw-text-primary tw-pl">
                                                    {WednesdayHours} Hours
                                            </span>
                                        </div>
                                        <div className="tw-flex-grow tw-flex tw-items-start tw-mt-sm tw-p">
                                                {/* {!newShiftForm.showWednesdayForm ? <>
                                                    <ButtonPrimary small  onClick={ e => {
                                                                addWednesdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                        Add
                                                    </ButtonPrimary>
                                                </>:<></>} */}
                                        </div>
                                    </div>
                                    
                                    {newShiftForm.showWednesdayForm ? <>
                                        <div className="tw-p tw-bg-lime-100">
                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Shift Type
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Select id="shift_type" onChange={ e => setWednesdayFormValues({...wednesdayFormValues, "shift_type": e.target.value }) } ref={ wednesdayShiftType } value={ wednesdayFormValues.shift_type } alt className="tw-flex-grow tw-max-w-500">
                                                            <option value="">--Select--</option>
                                                            {
                                                                clientServiceTypes.map(service =>
                                                                    <option value={service.shiftType}>{service.shiftType}</option>)
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Start Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="start_time" type="time" onChange={ e => setWednesdayFormValues({...wednesdayFormValues, "start_time": e.target.value }) } ref={ wednesdayStartTime } value={ wednesdayFormValues.start_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            End Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="end_time" type="time" onChange={ e => setWednesdayFormValues({...wednesdayFormValues, "end_time": e.target.value }) } ref={ wednesdayEndTime } value={ wednesdayFormValues.end_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tw-pt-xxl tw-pb-xl">
                                                <ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
                                                    <li>
                                                        <ButtonSecondary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                discardWednesdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Discard
                                                        </ButtonSecondary>
                                                    </li>
                                                    <li>
                                                        <ButtonPrimary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                saveWednesdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Save
                                                        </ButtonPrimary>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>:<></>}

                                    <div className="tw-flex tw-flex-wrap">
                                        { wednesdayRequirements.length === 0 ? <>
                                            <span className="tw-text-2 tw-text-primary">
                                                No Coverage Requirements
                                            </span>
                                        </>:<></> }
                                        { wednesdayRequirements
                                        .sort((a, b) => moment(a.startTime) > moment(b.startTime) ? 1 : -1)
                                        .map((requirement) => {
                                            return <>
                                                  <div className="tw-flex tw-flex-shrink-0 tw-w-1/2 tw-basis-1/2">
                                                    <div className="tw-m-sm tw-bg-white tw-flex tw-flex-col tw-flew-grow tw-w-full">
                                                        <div className={`tw-flex tw-p tw-items-center tw-justify-between tw-w-full tw-min-h-30 ${RSWithBackupCodes.includes(requirement.billingCode) ? "tw-bg-accent-dark":"tw-bg-secondary"}`}>
                                                            <SectionHeader tag="h3" className="tw-text-white">
                                                                {requirement.shiftType} {requirement.allDay ? "- All Day": ""}
                                                            </SectionHeader>
                                                        </div>
                                                        <div className="tw-px tw-pb">
                                                            <div className="tw-flex tw-items-end tw-pt-lg tw-pb">
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        From
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayStartTime}
                                                                    </span>
                                                                </div>
                                                                <span className="tw-px-sm tw-text-2">
                                                                    &mdash;
                                                                </span>
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        To
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayEndTime}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-min-h-30">
                                                                <div>
                                                                    <ButtonPrimary small className="tw-min-w-150" onClick={ e => {
                                                                        setWednesdayRequirements(
                                                                            wednesdayRequirements.filter(a => a.spanCoverageEventID !== requirement.spanCoverageEventID)
                                                                        );
                                                                        e.preventDefault();
                                                                    }}>
                                                                        Remove
                                                                    </ButtonPrimary>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                            </>
                                        }) }
                                    </div>

                                    <div className="tw-flex">
                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                            <ParagraphSubheader>
                                                Thursday
                                            </ParagraphSubheader>
                                            <span className="tw-text-2 tw-text-primary tw-pl">
                                                    {ThursdayHours} Hours
                                            </span>
                                        </div>
                                        <div className="tw-flex-grow tw-flex tw-items-start tw-mt-sm tw-p">
                                                {/* {!newShiftForm.showThursdayForm ? <>
                                                    <ButtonPrimary small  onClick={ e => {
                                                                addThursdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                        Add
                                                    </ButtonPrimary>
                                                </>:<></>} */}
                                        </div>
                                    </div>
                                    
                                    {newShiftForm.showThursdayForm ? <>
                                        <div className="tw-p tw-bg-lime-100">
                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Shift Type
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Select id="shift_type" onChange={ e => setThursdayFormValues({...thursdayFormValues, "shift_type": e.target.value }) } ref={ thursdayShiftType } value={ thursdayFormValues.shift_type } alt className="tw-flex-grow tw-max-w-500">
                                                            <option value="">--Select--</option>
                                                            {
                                                                clientServiceTypes.map(service =>
                                                                    <option value={service.shiftType}>{service.shiftType}</option>)
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Start Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="start_time" type="time" onChange={ e => setThursdayFormValues({...thursdayFormValues, "start_time": e.target.value }) } ref={ thursdayStartTime } value={ thursdayFormValues.start_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            End Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="end_time" type="time" onChange={ e => setThursdayFormValues({...thursdayFormValues, "end_time": e.target.value }) } ref={ thursdayEndTime } value={ thursdayFormValues.end_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tw-pt-xxl tw-pb-xl">
                                                <ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
                                                    <li>
                                                        <ButtonSecondary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                discardThursdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Discard
                                                        </ButtonSecondary>
                                                    </li>
                                                    <li>
                                                        <ButtonPrimary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                saveThursdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Save
                                                        </ButtonPrimary>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>:<></>}

                                    <div className="tw-flex tw-flex-wrap">
                                        { thursdayRequirements.length === 0 ? <>
                                            <span className="tw-text-2 tw-text-primary">
                                                No Coverage Requirements
                                            </span>
                                        </>:<></> }
                                        { thursdayRequirements
                                        .sort((a, b) => moment(a.startTime) > moment(b.startTime) ? 1 : -1)
                                        .map((requirement) => {
                                            return <>
                                                  <div className="tw-flex tw-flex-shrink-0 tw-w-1/2 tw-basis-1/2">
                                                    <div className="tw-m-sm tw-bg-white tw-flex tw-flex-col tw-flew-grow tw-w-full">
                                                        <div className={`tw-flex tw-p tw-items-center tw-justify-between tw-w-full tw-min-h-30 ${RSWithBackupCodes.includes(requirement.billingCode) ? "tw-bg-accent-dark":"tw-bg-secondary"}`}>
                                                            <SectionHeader tag="h3" className="tw-text-white">
                                                                {requirement.shiftType} {requirement.allDay ? "- All Day": ""}
                                                            </SectionHeader>
                                                        </div>
                                                        <div className="tw-px tw-pb">
                                                            <div className="tw-flex tw-items-end tw-pt-lg tw-pb">
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        From
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayStartTime}
                                                                    </span>
                                                                </div>
                                                                <span className="tw-px-sm tw-text-2">
                                                                    &mdash;
                                                                </span>
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        To
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayEndTime}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-min-h-30">
                                                                <div>
                                                                    <ButtonPrimary small className="tw-min-w-150" onClick={ e => {
                                                                        setThursdayRequirements(
                                                                            thursdayRequirements.filter(a => a.spanCoverageEventID !== requirement.spanCoverageEventID)
                                                                        );
                                                                        e.preventDefault();
                                                                    }}>
                                                                        Remove
                                                                    </ButtonPrimary>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                            </>
                                        }) }
                                    </div>

                                    <div className="tw-flex">
                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                            <ParagraphSubheader>
                                                Friday
                                            </ParagraphSubheader>
                                            <span className="tw-text-2 tw-text-primary tw-pl">
                                                    {FridayHours} Hours
                                            </span>
                                        </div>
                                        <div className="tw-flex-grow tw-flex tw-items-start tw-mt-sm tw-p">
                                                {/* {!newShiftForm.showFridayForm ? <>
                                                    <ButtonPrimary small  onClick={ e => {
                                                                addFridayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                        Add
                                                    </ButtonPrimary>
                                                </>:<></>} */}
                                        </div>
                                    </div>
                                    
                                    {newShiftForm.showFridayForm ? <>
                                        <div className="tw-p tw-bg-lime-100">
                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Shift Type
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Select id="shift_type" onChange={ e => setFridayFormValues({...fridayFormValues, "shift_type": e.target.value }) } ref={ fridayShiftType } value={ fridayFormValues.shift_type } alt className="tw-flex-grow tw-max-w-500">
                                                            <option value="">--Select--</option>
                                                            {
                                                                clientServiceTypes.map(service =>
                                                                    <option value={service.shiftType}>{service.shiftType}</option>)
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Start Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="start_time" type="time" onChange={ e => setFridayFormValues({...fridayFormValues, "start_time": e.target.value }) } ref={ fridayStartTime } value={ fridayFormValues.start_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            End Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="end_time" type="time" onChange={ e => setFridayFormValues({...fridayFormValues, "end_time": e.target.value }) } ref={ fridayEndTime } value={ fridayFormValues.end_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tw-pt-xxl tw-pb-xl">
                                                <ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
                                                    <li>
                                                        <ButtonSecondary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                discardFridayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Discard
                                                        </ButtonSecondary>
                                                    </li>
                                                    <li>
                                                        <ButtonPrimary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                saveFridayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                            Save
                                                        </ButtonPrimary>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>:<></>}

                                    <div className="tw-flex tw-flex-wrap">
                                        { fridayRequirements.length === 0 ? <>
                                            <span className="tw-text-2 tw-text-primary">
                                                No Coverage Requirements
                                            </span>
                                        </>:<></> }
                                        { fridayRequirements
                                        .sort((a, b) => moment(a.startTime) > moment(b.startTime) ? 1 : -1)
                                        .map((requirement) => {
                                            return <>
                                                   <div className="tw-flex tw-flex-shrink-0 tw-w-1/2 tw-basis-1/2">
                                                    <div className="tw-m-sm tw-bg-white tw-flex tw-flex-col tw-flew-grow tw-w-full">
                                                        <div className={`tw-flex tw-p tw-items-center tw-justify-between tw-w-full tw-min-h-30 ${RSWithBackupCodes.includes(requirement.billingCode) ? "tw-bg-accent-dark":"tw-bg-secondary"}`}>
                                                            <SectionHeader tag="h3" className="tw-text-white">
                                                                {requirement.shiftType} {requirement.allDay ? "- All Day": ""}
                                                            </SectionHeader>
                                                        </div>
                                                        <div className="tw-px tw-pb">
                                                            <div className="tw-flex tw-items-end tw-pt-lg tw-pb">
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        From
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayStartTime}
                                                                    </span>
                                                                </div>
                                                                <span className="tw-px-sm tw-text-2">
                                                                    &mdash;
                                                                </span>
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        To
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayEndTime}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-min-h-30">
                                                                <div>
                                                                    <ButtonPrimary small className="tw-min-w-150" onClick={ e => {
                                                                        setFridayRequirements(
                                                                            fridayRequirements.filter(a => a.spanCoverageEventID !== requirement.spanCoverageEventID)
                                                                        );
                                                                        e.preventDefault();
                                                                    }}>
                                                                        Remove
                                                                    </ButtonPrimary>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                            </>
                                        }) }
                                    </div>

                                    <div className="tw-flex">
                                        <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                            <ParagraphSubheader>
                                                Saturday
                                            </ParagraphSubheader>
                                            <span className="tw-text-2 tw-text-primary tw-pl">
                                                    {SaturdayHours} Hours
                                            </span>
                                        </div>
                                        <div className="tw-flex-grow tw-flex tw-items-start tw-mt-sm tw-p">
                                                {/* {!newShiftForm.showSaturdayForm ? <>
                                                    <ButtonPrimary small  onClick={ e => {
                                                                addSaturdayRequirement();
                                                                e.preventDefault();
                                                            }}>
                                                        Add
                                                    </ButtonPrimary>
                                                </>:<></>} */}
                                        </div>
                                    </div>

                                    {newShiftForm.showSaturdayForm ? <>
                                        <div className="tw-p tw-bg-lime-100">
                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="shift_type" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Shift Type
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Select id="shift_type" onChange={ e => setSaturdayFormValues({...saturdayFormValues, "shift_type": e.target.value }) } ref={ saturdayShiftType } value={ saturdayFormValues.shift_type } alt className="tw-flex-grow tw-max-w-500">
                                                            <option value="">--Select--</option>
                                                            {
                                                                clientServiceTypes.map(service =>
                                                                    <option value={service.shiftType}>{service.shiftType}</option>)
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="start_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            Start Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="start_time" type="time" onChange={ e => setSaturdayFormValues({...saturdayFormValues, "start_time": e.target.value }) } ref={ saturdayStartTime } value={ saturdayFormValues.start_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="-tw-m">
                                                <div className="tw-flex">
                                                    <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-p tw-min-w-200">
                                                        <Label for="end_time" className="tw-flex-grow" childClasses={ pageSubHeader }>
                                                            End Time
                                                        </Label>
                                                    </div>
                                                    <div className="tw-flex-grow tw-flex tw-items-start tw-p">
                                                        <Input id="end_time" type="time" onChange={ e => setSaturdayFormValues({...saturdayFormValues, "end_time": e.target.value }) } ref={ saturdayEndTime } value={ saturdayFormValues.end_time } alt className="tw-flex-grow tw-max-w-300" />
                                                    </div>
                                                </div>
                                            </div>

                                          

                                            <div className="tw-pt-xxl tw-pb-xl">
                                                <ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
                                                        <li>
                                                            <ButtonSecondary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                    discardSaturdayRequirement();
                                                                    e.preventDefault();
                                                                }}>
                                                                Discard
                                                            </ButtonSecondary>
                                                        </li>
                                                        <li>
                                                            <ButtonPrimary small className="tw-px tw-min-w-200"  onClick={ e => {
                                                                    saveSaturdayRequirement();
                                                                    e.preventDefault();
                                                                }}>
                                                                Save
                                                            </ButtonPrimary>
                                                        </li>

                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </>:<></>}

                                    <div className="tw-flex tw-flex-wrap">
                                        { saturdayRequirements.length === 0 ? <>
                                            <span className="tw-text-2 tw-text-primary">
                                                No Coverage Requirements
                                            </span>
                                        </>:<></> }
                                        { saturdayRequirements
                                        .sort((a, b) => moment(a.startTime) > moment(b.startTime) ? 1 : -1)
                                        .map((requirement) => {
                                            return <>
                                                  <div className="tw-flex tw-flex-shrink-0 tw-w-1/2 tw-basis-1/2">
                                                    <div className="tw-m-sm tw-bg-white tw-flex tw-flex-col tw-flew-grow tw-w-full">
                                                        <div className={`tw-flex tw-p tw-items-center tw-justify-between tw-w-full tw-min-h-30 ${RSWithBackupCodes.includes(requirement.billingCode) ? "tw-bg-accent-dark":"tw-bg-secondary"}`}>
                                                            <SectionHeader tag="h3" className="tw-text-white">
                                                                {requirement.shiftType} {requirement.allDay ? "- All Day": ""}
                                                            </SectionHeader>
                                                        </div>
                                                        <div className="tw-px tw-pb">
                                                            <div className="tw-flex tw-items-end tw-pt-lg tw-pb">
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        From
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayStartTime}
                                                                    </span>
                                                                </div>
                                                                <span className="tw-px-sm tw-text-2">
                                                                    &mdash;
                                                                </span>
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className={`tw-text-light ${ paragraph3 }`}>
                                                                        To
                                                                    </span>
                                                                    <span className="tw-text-2">
                                                                        {requirement.displayEndTime}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-min-h-30">
                                                                <div>
                                                                    <ButtonPrimary small className="tw-min-w-150" onClick={ e => {
                                                                        setSaturdayRequirements(
                                                                            saturdayRequirements.filter(a => a.spanCoverageEventID !== requirement.spanCoverageEventID)
                                                                        );
                                                                        e.preventDefault();
                                                                    }}>
                                                                        Remove
                                                                    </ButtonPrimary>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                </div>
                                            </>
                                        }) }
                                    </div>
								</div>
                            </div>
                            <div className="tw-pt-xxl tw-pb-xl">
								<ul className="tw-list-none tw-flex tw-justify-end tw-space-x-lg">
                                {spanRequirementAvailable ? <>
                                    {showReqLoading ? 
									<>
									<li>
										<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
									</li>
									
									</>: <> 
                                    <li>
										<ButtonSecondary large className="tw-px tw-min-w-200" onClick={ e => {
                                            discardCoverageRequirement();
											e.preventDefault();
										}}>
											Discard
										</ButtonSecondary>
									</li>
									<li>
										<ButtonPrimary large className="tw-px tw-min-w-200" onClick={ e => {
											updateCoverageRequirement();
											e.preventDefault();
										}}>
											Update
										</ButtonPrimary>
									</li>
                                    </>}
                                   
                                </>:<>
                                {showReqLoading ? 
									<>
									<li>
										<CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
									</li>
									
									</>: <> 
                                    <li>
										<ButtonSecondary large className="tw-px tw-min-w-200" onClick={ e => {
                                            discardCoverageRequirement();
											e.preventDefault();
										}}>
											Discard
										</ButtonSecondary>
									</li>
									<li>
										<ButtonPrimary large className="tw-px tw-min-w-200" onClick={ e => {
											saveCoverageRequirement();
											e.preventDefault();
										}}>
											Save
										</ButtonPrimary>
									</li>
                                    </>}
                                    
                                </>}
									
								</ul>
							</div>
                        </form>
                        </>: <>
                        </>}
                        
                    </div>
                }
                </div>
            </div>
        </div>
    {/* </Core> */}
    </>);
}

export default CoverageRequirementForm