import { useState } from "react";
import { useAppContext } from "../../Context";
import { Room } from "../../types/serviceLocation";
import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { roomSchema } from "../../pages/NewServiceLocationForm";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { LoadingSpinner } from "./loadingSpinner";
import { set } from "lodash";
import { addRoomToLocation, deleteRoomInLocation, updateRoomInLocation } from "../../apicalls/serviceLocationService";

export const EditLocationRoomsCard = props => {
    const [editId, setEditId] = useState<string | null>(null);

    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, updatedRoom: Room) {
        
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    function handleDelete(){
        // Delete the contact
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    const [showRoomForm, setShowRoomForm] = useState(false);

    const openRoomForm = () => {
        setShowRoomForm(true);
    }

    const closeRoomForm = () => {
        setShowRoomForm(false);
    }

    const roomFormik = useFormik({
        initialValues: {
            RoomName: "",
            RoomType: "",
        },
        validationSchema: roomSchema,
        onSubmit: async (values) => {
          // handle form submission
          console.log("Form submitted")
            console.log(values)
            setLoading(true);

            var newRoom: Room = {
                roomID: "",
                roomName: values.RoomName,
                roomType: values.RoomType,
                serviceLocationID: ""
            }

            var result = await addRoomToLocation(props?.location?.serviceLocationID, newRoom, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Room added successfully.");
                appContext.setShowSuccessModal(true);
            }
            setLoading(false);
            props?.refreshLocation(props?.location?.serviceLocationID);
            closeRoomForm();
            //handleAddRoom(values);
        },
    });

    return (<>
        <div className="tw-mt-4">
                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Rooms</span>
                            {!showRoomForm && <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={openRoomForm}/> }
                        </div>
                        {showRoomForm && <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">New Room</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${roomFormik.touched.RoomName && roomFormik.errors.RoomName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...roomFormik.getFieldProps('RoomName')}
                                                />
                                                {roomFormik.touched.RoomName && roomFormik.errors.RoomName && <p className="tw-text-red-500">{roomFormik.errors.RoomName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-type"
                                                id="room-type"
                                                className={`tw-block tw-w-full tw-rounded-md ${roomFormik.touched.RoomType && roomFormik.errors.RoomType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...roomFormik.getFieldProps('RoomType')}
                                                />
                                                {roomFormik.touched.RoomType && roomFormik.errors.RoomType && <p className="tw-text-red-500">{roomFormik.errors.RoomType}</p>}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={closeRoomForm}>
                                                        Cancel
                                                </ButtonSecondary>
                                                <ButtonPrimary className="tw-mt-md" onClick={roomFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>

                                        </div>
                                        </>}
                                </div>}

                        {props?.rooms?.length > 0 &&  props?.rooms?.map((room, index) => {
                            if(index !== props?.rooms?.length - 1) {
                                return <>
                                    {room.roomID === editId ? <EditRoomCard room={room} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} location={props?.location} /> : <ViewRoomCard room={room} handleEdit={handleEdit} />}
                                    
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                </>
                            } else {
                                return <>
                                    {room.roomID === editId ? <EditRoomCard room={room} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} location={props?.location}/> : <ViewRoomCard room={room} handleEdit={handleEdit} />}
                                </>
                            }
                            })}
        </div>
    </>);
}

const EditRoomCard = props => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const confirmDelete = () => {
        setShowConfirmDelete(true);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const deleteRoom = async () => {
        setLoading(true);
        var result = await deleteRoomInLocation(props?.location?.serviceLocationID, props?.room?.roomID, appContext.handleAPICallError);

        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Room deleted successfully.");
            appContext.setShowSuccessModal(true);
        }

        props?.handleDelete();

        setLoading(false);
    }
    
    const roomFormik = useFormik({
        initialValues: {
            RoomName: props?.room.roomName,
            RoomType: props?.room.roomType,
        },
        validationSchema: roomSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Form submitted");
            console.log(values);
            

            var updatedRoom : Room = {
                roomID: props?.room.roomID,
                roomName: values.RoomName,
                roomType: values.RoomType,
                serviceLocationID: props?.room.serviceLocationID,
            }

            setLoading(true);
            var result = await updateRoomInLocation(props?.location?.serviceLocationID, updatedRoom, appContext.handleAPICallError);

            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Room updated successfully.");
                appContext.setShowSuccessModal(true);
            }

            props?.handleSave(updatedRoom);

            setLoading(false);

            //handleAddRoom(values);
        },
    });
    
    return (<>
        <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Room</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${roomFormik.touched.RoomName && roomFormik.errors.RoomName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...roomFormik.getFieldProps('RoomName')}
                                                />
                                                {roomFormik.touched.RoomName && roomFormik.errors.RoomName && <p className="tw-text-red-500"><>{roomFormik.errors.RoomName}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-type"
                                                id="room-type"
                                                className={`tw-block tw-w-full tw-rounded-md ${roomFormik.touched.RoomType && roomFormik.errors.RoomType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...roomFormik.getFieldProps('RoomType')}
                                                />
                                                {roomFormik.touched.RoomType && roomFormik.errors.RoomType && <p className="tw-text-red-500"><>{roomFormik.errors.RoomType}</></p>}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </> : <>
                                    {showConfirmDelete ? <>
                                    <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                        <p className="tw-text-primary">Are you sure you want to delete this room?</p>
                                        </div>
                                    
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deleteRoom}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={confirmDelete}>
                                                    Delete
                                            </ButtonSecondary>
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.handleCancel}>
                                                        {roomFormik.dirty ? "Discard": "Cancel" }
                                                </ButtonSecondary>
                                                {roomFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={roomFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>}

                                    </div>
                                    </>}
                                    </>}
                                </div>
    </>);
}

const ViewRoomCard = props => {
    return (<>
        
        <div className="tw-bg-grey-light tw-flex tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
            <div className="tw-w-4/5 tw-flex-grow">
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Room Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.room.roomName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Room Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.room.roomType}</span>
                                            </div>
            </div>
            <div className="tw-w-1/5 tw-flex tw-justify-end tw-items-center">
                        <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={() => props?.handleEdit(props?.room.roomID)}/>
            </div>                    
        </div>
    </>);
}