export const pageHeader = `c-screen-header tw-text-0 tw-leading-[3rem] tw-font-medium`;
export const pageSubHeader = `c-screen-subheader tw-text-1 tw-leading-7 tw-font-medium`;

export const sectionHeader = `c-section-header tw-text-2 tw-leading-6 tw-font-semibold`;
export const sectionSubheader = `c-section-subheader tw-text-3 tw-leading-[1.375rem] tw-font-medium`;

export const articleHeader = `c-article-header tw-text-4 tw-leading-5 tw-font-medium`;
export const articleSubheader = `c-article-subheader tw-text-4 tw-leading-5 tw-font-medium`;

export const paragraphHeader = `c-paragraph-header tw-text-3 tw-leading-7 tw-font-medium`;
export const paragraph1 = paragraphHeader;
export const paragraphSubheader = `c-paragraph-subheader tw-text-3 tw-leading-6 tw-font-medium `;
export const paragraph2 = paragraphSubheader;
export const paragraph3 = `c-paragraph-subheader tw-text-4 tw-leading-6 tw-font-medium`;

export const caption = `c-paragraph-subheader tw-text-6 tw-leading-[0.8] tw-font-bold`;
export const caption1 = caption;
export const caption2 = `c-paragraph-subheader tw-text-6 tw-leading-[0.8] tw-font-normal`;
