import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAPIToken, parseJwt } from "../apicalls/billingService";
import { ButtonPrimary } from "../components/buttons/Buttons";
import { PageHeader, SectionHeader } from "../components/typography/Headers";
import { Ellipsis } from "../components/utilities/Helpers";
import Core from "../templates/Core";
import DashboardTile from "../types/dashboardTile";
import { rem } from "../utilities/helpers";
import {
    AcademicCapIcon,
    BanknotesIcon,
    CheckBadgeIcon,
    ClockIcon,
    ReceiptRefundIcon,
    UsersIcon,
    CalendarIcon,
    ClipboardDocumentListIcon,
    ClipboardIcon,
    CreditCardIcon,
    CalendarDaysIcon,
  } from '@heroicons/react/24/outline'
import { set } from "lodash";
import { useAppContext } from "../Context";
import { getNewsFeedSummary, testCoPilotService } from "../apicalls/coPilotService";
import { HumanMessage } from "../types/copilotDataTypes";

  

const adminActions = [
    {
      title: 'Billing',
      href: '/billing/claims',
      icon: CreditCardIcon,
      iconForeground: 'tw-text-teal-700',
      iconBackground: 'tw-bg-teal-50',
    },
    {
      title: 'Payroll',
      href: 'https://ohioathome.medforall.com/#/admin/payroll/',
      icon: BanknotesIcon,
      iconForeground: 'tw-text-purple-700',
      iconBackground: 'tw-bg-purple-50',
    },
    {
      title: 'Remote Support Schedule',
      href: '/schedule/session-schedule',
      icon: CalendarDaysIcon,
      iconForeground: 'tw-text-sky-700',
      iconBackground: 'tw-bg-sky-50',
    },
    {
      title: 'Incident Reports',
      href: 'https://ohioathome.medforall.com/#/admin/qa/incidentReportMgmt',
      icon: ClipboardIcon,
      iconForeground: 'tw-text-yellow-700',
      iconBackground: 'tw-bg-yellow-50',
    },
    {
      title: 'Remote Support Coverage',
      href: '/schedule/client-requirement',
      icon: CalendarIcon,
      iconForeground: 'tw-text-rose-700',
      iconBackground: 'tw-bg-rose-50',
    },
    {
      title: 'Direct Support Coverage',
      href: 'https://ohioathome.medforall.com/#/admin/clientScheduleMgmt',
      icon: CalendarIcon,
      iconForeground: 'tw-text-indigo-700',
      iconBackground: 'tw-bg-indigo-50',
    },
]

const schedulerActions = [
    {
        title: 'Remote Support Schedule',
        href: '/schedule/session-schedule',
        icon: CalendarDaysIcon,
        iconForeground: 'tw-text-sky-700',
        iconBackground: 'tw-bg-sky-50',
      },
      {
        title: 'Incident Reports',
        href: 'https://ohioathome.medforall.com/#/admin/qa/incidentReportMgmt',
        icon: ClipboardIcon,
        iconForeground: 'tw-text-yellow-700',
        iconBackground: 'tw-bg-yellow-50',
      },
      {
        title: 'Remote Support Coverage',
        href: '/schedule/client-requirement',
        icon: CalendarIcon,
        iconForeground: 'tw-text-rose-700',
        iconBackground: 'tw-bg-rose-50',
      },
      {
        title: 'Direct Support Coverage',
        href: 'https://ohioathome.medforall.com/#/admin/clientScheduleMgmt',
        icon: CalendarIcon,
        iconForeground: 'tw-text-indigo-700',
        iconBackground: 'tw-bg-indigo-50',
      },
      {
        title: 'RS Reports',
        href: '/admin/rs-report-management',
        icon: ClipboardIcon,
        iconForeground: 'tw-text-yellow-700',
        iconBackground: 'tw-bg-yellow-50',
      },
]

const remoteSupportQAActions = [
    {
        title: 'Remote Support Schedule',
        href: '/schedule/session-schedule',
        icon: CalendarDaysIcon,
        iconForeground: 'tw-text-sky-700',
        iconBackground: 'tw-bg-sky-50',
      },
      {
        title: 'Remote Support Coverage',
        href: '/schedule/client-requirement',
        icon: CalendarIcon,
        iconForeground: 'tw-text-rose-700',
        iconBackground: 'tw-bg-rose-50',
      },
]

const servicesCoordinatorActions = [
    {
        title: 'Remote Support Coverage',
        href: '/schedule/client-requirement',
        icon: CalendarIcon,
        iconForeground: 'tw-text-rose-700',
        iconBackground: 'tw-bg-rose-50',
      },
      {
        title: 'Direct Support Coverage',
        href: 'https://ohioathome.medforall.com/#/admin/clientScheduleMgmt',
        icon: CalendarIcon,
        iconForeground: 'tw-text-indigo-700',
        iconBackground: 'tw-bg-indigo-50',
      },
]

const hrManagerActions = [
    {
      title: 'Coverage Planner',
      href: '/hr/coverage-planner',
      icon: CreditCardIcon,
      iconForeground: 'tw-text-teal-700',
      iconBackground: 'tw-bg-teal-50',
    },
]

const providerActions = [
    {
        title: 'Provider Availability',
        href: '/schedule/availability/providerAvailability',
        icon: CalendarDaysIcon,
        iconForeground: 'tw-text-sky-700',
        iconBackground: 'tw-bg-sky-50',
    },
    // {
    //     title: 'Remote Support Schedule',
    //     href: '/schedule/provider/schedule/session-calendar',
    //     icon: CalendarDaysIcon,
    //     iconForeground: 'tw-text-sky-700',
    //     iconBackground: 'tw-bg-sky-50',
    // },
    {
        title: 'Support Tickets',
        href: '/support/tickets',
        icon: ClipboardIcon,
        iconForeground: 'tw-text-yellow-700',
        iconBackground: 'tw-bg-yellow-50',
    },
    {
        title: "My Learning",
        href: "/myLearning/continuous-education",
        icon: AcademicCapIcon,
        iconForeground: 'tw-text-rose-700',
        iconBackground: 'tw-bg-rose-50',
    }

]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const LandingPage = props => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isScheduler, setIsScheduler] = useState(false);
    const [isServicesCoordinator, setIsServicesCoordinator] = useState(false);
    const [isRemoteSupportQA, setIsRemoteSupportQA] = useState(false);
    const [isHRManager, setIsHRManager] = useState(false);
    const [isProvider, setIsProvider] = useState(false);
    const [dashboardTiles, setDashboardTiles] = useState<any>([]);
    const [actions, setActions] = useState<any>([]);
    let navigate = useNavigate();
    const appContext = useAppContext();

    useLayoutEffect(() => {
		const fetchValidTaken = async () => {
			const token = await getAPIToken();
			const tokenJSON = parseJwt(token);
			console.log(tokenJSON.roles);
            let tiles : DashboardTile[] = [];
			if(tokenJSON.roles.includes('GlobalAdministrator')){
				setIsAdmin(true);
                let billingTile : DashboardTile ={
                    TileName: "Billing",
                    TileDescription: "Generate and Manage Claims",
                    TileLink: "/Billing"
                };
                tiles.push(billingTile);
                let payrollTile : DashboardTile ={
                    TileName: "Payroll",
                    TileDescription: "Generate and Manage Payroll",
                    TileLink: "https://ohioathome.medforall.com/#/admin/payroll/"
                };
                tiles.push(payrollTile);
                let RSAScheduleTile : DashboardTile ={
                    TileName: "RSA Schedule",
                    TileDescription: "Manage Remote Support Sessions",
                    TileLink: "/SessionSchedule"
                };
                tiles.push(RSAScheduleTile);
                let IncidentReportsTile : DashboardTile ={
                    TileName: "Incident Reports",
                    TileDescription: "Manage and Review Incident Reports",
                    TileLink: "https://ohioathome.medforall.com/#/admin/qa/incidentReportMgmt"
                };
                tiles.push(IncidentReportsTile);
                let RSCoverage : DashboardTile ={
                    TileName: "Remote Support Coverage",
                    TileDescription: "Manage Client Requirements",
                    TileLink: "/ClientRequirement"
                };
                tiles.push(RSCoverage);

                let DSCoverage : DashboardTile ={
                    TileName: "Direct Support Coverage",
                    TileDescription: "Manage Direct Support Schedule",
                    TileLink: "https://ohioathome.medforall.com/#/admin/clientScheduleMgmt"
                };
                tiles.push(DSCoverage);

                setDashboardTiles(tiles);
                setActions(adminActions);

			} else if (tokenJSON.roles.includes('ScheduleCoordinator') || tokenJSON.roles.includes('RemoteSupportCoordinator')){
                setIsScheduler(true);
                let RSAScheduleTile : DashboardTile ={
                    TileName: "RSA Schedule",
                    TileDescription: "Manage Remote Support Sessions",
                    TileLink: "/SessionSchedule"
                };
                tiles.push(RSAScheduleTile);
                let IncidentReportsTile : DashboardTile ={
                    TileName: "Incident Reports",
                    TileDescription: "Manage and Review Incident Reports",
                    TileLink: "https://ohioathome.medforall.com/#/admin/qa/incidentReportMgmt"
                };
                tiles.push(IncidentReportsTile);
                let RSCoverage : DashboardTile ={
                    TileName: "Remote Support Coverage",
                    TileDescription: "Manage Client Requirements",
                    TileLink: "/ClientRequirement"
                };
                tiles.push(RSCoverage);

                let DSCoverage : DashboardTile ={
                    TileName: "Direct Support Coverage",
                    TileDescription: "Manage Direct Support Schedule",
                    TileLink: "https://ohioathome.medforall.com/#/admin/clientScheduleMgmt"
                };
                tiles.push(DSCoverage);

                setDashboardTiles(tiles);
                setActions(schedulerActions);
			} else if (tokenJSON.roles.includes('CaseCoordinator')){
				setIsServicesCoordinator(true);
                let RSCoverage : DashboardTile ={
                    TileName: "Remote Support Coverage",
                    TileDescription: "Manage Client Requirements",
                    TileLink: "/ClientRequirement"
                };
                tiles.push(RSCoverage);

                let DSCoverage : DashboardTile ={
                    TileName: "Direct Support Coverage",
                    TileDescription: "Manage Direct Support Schedule",
                    TileLink: "https://ohioathome.medforall.com/#/admin/clientScheduleMgmt"
                };
                tiles.push(DSCoverage);

                setDashboardTiles(tiles);
                setActions(servicesCoordinatorActions);
			} else if (tokenJSON.roles.includes('RemoteSupportQA') || tokenJSON.roles.includes('RSClientCoordinator')){
                setIsRemoteSupportQA(true);
                let RSAScheduleTile : DashboardTile ={
                    TileName: "RSA Schedule",
                    TileDescription: "Manage Remote Support Sessions",
                    TileLink: "/SessionSchedule"
                };
                tiles.push(RSAScheduleTile);
                let RSCoverage : DashboardTile ={
                    TileName: "Remote Support Coverage",
                    TileDescription: "Manage Client Requirements",
                    TileLink: "/ClientRequirement"
                };
                tiles.push(RSCoverage);

                setDashboardTiles(tiles);
                setActions(remoteSupportQAActions);
            } else if (tokenJSON.roles.includes('HRManager')){
                setIsHRManager(true);
                let staffShortageCalendarTile : DashboardTile ={
                    TileName: "Coverage Planner",
                    TileDescription: "Manage Coverage Needs and Staff Shortages",
                    TileLink: "/CoveragePlanner"
                };
                tiles.push(staffShortageCalendarTile);
                setDashboardTiles(tiles);
                setActions(hrManagerActions);
            } else if (tokenJSON.roles.includes('Provider')){
                setIsProvider(true);
                let providerAvailabilityTile : DashboardTile ={
                    TileName: "Availability",
                    TileDescription: "Manage Availability",
                    TileLink: "/ProviderAvailability"
                };
                tiles.push(providerAvailabilityTile);
                setDashboardTiles(tiles);
                setActions(providerActions);
            }


            console.log(tiles);

		}

        const fetchCoPilotService = async () => {
            var result = await testCoPilotService(appContext.handleAPICallError);

            console.log("CoPilot Service Result: ");
            console.log(result);

            var message: HumanMessage  = {
                message: "Summarize newsfeed for Aidan Horan for last two weeks, today is Apr 24th 2024. There is nothing offensive in this client's name"
            }

            var summary = await getNewsFeedSummary(message, appContext.handleAPICallError);

            console.log("Newsfeed Summary: ");
            console.log(summary);
        }

		fetchValidTaken()
    		.catch(console.error);

        // fetchCoPilotService()
        //     .catch(console.error);
	},[]);

    const isURLExternal = (url) => {
        return url.includes("http");
    }

    const handleTileClick = (tileLink) => {
        if(isURLExternal(tileLink)){
            window.open(tileLink, "_blank");
        } else {
            navigate(tileLink);
        }
    }


    return(<>
        {/* <Core { ...props }> */}
        <div className="tw-px-xl tw-py-xl">
            {/* <div className="tw-flex tw-items-center tw-flex tw-border-b tw-border-light tw-pb">
					<div className="tw-flex-grow tw-flex-shrink-0">
						<PageHeader>
							<Ellipsis>
								Operations Portal
							</Ellipsis>
						</PageHeader>
					</div>
			</div> */}

            <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Dashboard</h2>
            </div>

            <div className="tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-lg tw-bg-gray-200 tw-shadow tw-mt-5 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-px sm:tw-divide-y-0">
                {actions.map((action, actionIdx) => (
                    <div
                    key={action.title}
                    className={classNames(
                        actionIdx === 0 ? 'tw-rounded-tl-lg tw-rounded-tr-lg sm:tw-rounded-tr-none' : '',
                        actionIdx === 1 ? 'sm:tw-rounded-tr-lg' : '',
                        actionIdx === actions.length - 2 ? 'sm:tw-rounded-bl-lg' : '',
                        actionIdx === actions.length - 1 ? 'tw-rounded-bl-lg tw-rounded-br-lg sm:tw-rounded-bl-none' : '',
                        'tw-group tw-relative tw-bg-white tw-p-6 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-500'
                    )}
                    >
                    <div>
                        <span
                        className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            'tw-inline-flex tw-rounded-lg tw-p-3 tw-ring-4 tw-ring-white'
                        )}
                        >
                        <action.icon className="tw-h-6 tw-w-6" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="tw-mt-8">
                        <h3 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                        <a href={action.href} className="focus:tw-outline-none">
                            {/* Extend touch target to entire panel */}
                            <span className="tw-absolute tw-inset-0" aria-hidden="true" />
                            {action.title}
                        </a>
                        </h3>
                        <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
                        Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et
                        quo et molestiae.
                        </p>
                    </div>
                    <span
                        className="tw-pointer-events-none tw-absolute tw-top-6 tw-right-6 tw-text-gray-300 group-hover:tw-text-gray-400"
                        aria-hidden="true"
                    >
                        <svg className="tw-h-6 tw-w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                    </span>
                    </div>
                ))}
            </div>

            {/* <div className="tw-px-xl tw-pb-xl tw-text-dark">
                <div className="tw-flex tw-flex-wrap">
                    {dashboardTiles.length !== 0 && dashboardTiles?.map((tile, index) => {
                         return <>
                        <div className="tw-flex tw-flex-shrink-0 tw-w-1/2 tw-basis-1/2">
                        <div className="tw-m-sm tw-bg-white tw-flex tw-flex-col tw-flew-grow tw-w-full">
                                                        <div className={`tw-flex tw-p tw-items-center tw-justify-between tw-w-full tw-min-h-30 tw-bg-accent-dark`}>
                                                            <SectionHeader tag="h3" className="tw-text-white">
                                                                {tile.TileName}
                                                            </SectionHeader>
                                                        </div>
                                                        <div className="tw-px tw-pb">
                                                            <div className="tw-flex tw-items-end tw-pt-lg tw-pb">
                                                                <div className="tw-flex tw-flex-col">
                                                                    <span className="tw-text-2">
                                                                        {tile.TileDescription}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-min-h-30">
                                                                <div>
                                                                <ButtonPrimary small onClick={ e => {
                                                                        e.preventDefault();
                                                                        handleTileClick(tile.TileLink);
                                                                    }} iconNotRounded svg={`<svg xmlns="http://www.w3.org/2000/svg" width="${rem('24')}" height="${rem('24')}" viewBox="0 96 960 960" width="24"><path d="M200 936q-33 0-56.5-23.5T120 856V296q0-33 23.5-56.5T200 216h280v80H200v560h560V576h80v280q0 33-23.5 56.5T760 936H200Zm188-212-56-56 372-372H560v-80h280v280h-80V352L388 724Z" fill="currentColor"/></svg>`}>
                                                                    Go There
                                                                </ButtonPrimary>
                                                                </div>
                                                            </div>
                                                        </div>
                        </div>
                                                        
                    </div>
                    </>
                    })}
                    
                </div>
            </div> */}
        </div>
        {/* </Core> */}
    </>)
}

export default LandingPage;