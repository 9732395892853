import { useEffect, useLayoutEffect, useState } from "react";
import { CircleLabel } from "../calendars/CalendarUtils";
import { useAppContext } from "../../Context";
import { InputTimes } from "../forms/formFields";
import { isMidnight, removeWhitespace } from "./SchedulePanel";
import moment from "moment";

const SessionClaimPanel = props => {
    const [openSession, setOpenSession] = useState(null);

    const navContext = useAppContext();

    const [values, setValues] = useState<any>(navContext.values || {});

    const [claimEntireTime, setClaimEntireTime] = useState(true);
    const [claimPartialTime, setClaimPartialTime] = useState(false);

    useLayoutEffect(() => {
        setOpenSession(props.openSession);
    },[props.openSession])

    const validateStartTime = (start) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));
		console.log("Validate Start Time");

        var openSessionStartTime = moment(openSession?.displayStartTime, 'h:mm A', true);
		var openSessionEndTime = moment(openSession?.displayEndTime, 'h:mm A', true);

		if (openSessionEndTime.isBefore(openSessionStartTime)) {
			openSessionEndTime.add(1, 'd');
		}

		// check if start_time is valid
		if (start.length === 0 || !moment(start, 'hh:mm A', true).isValid()) {
			contextErrors['start_time'] = true;
            contextErrorMessages['start_time'] = 'Invalid time';
		} else {
			contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';
			
			if (values.end_time && moment(values.end_time, 'h:mm A', true).isValid() && moment(start, 'h:mm A', true).isAfter(moment(values.end_time, 'h:mm A', true)) && !isMidnight(moment(values.end_time, 'h:mm A', true))) {
				contextErrors['end_time'] = true;
				contextErrorMessages['end_time'] = 'End time must be after start time';
				console.log("End time must be greater than start time in Schedule Day Panel Start Time");
			} else {
				contextErrors['end_time'] = false;
				contextErrorMessages['end_time'] = '';
			}

            // check if start_time is between available time period
            if (moment(start, 'h:mm A', true).isBefore(openSessionStartTime) || moment(start, 'h:mm A', true).isAfter(openSessionEndTime)) {
                contextErrors['start_time'] = true;
                contextErrorMessages['start_time'] = 'Start time must be between available time period';
                console.log("Start time must be between available time period in Schedule Day Panel");
            } else {
                contextErrors['start_time'] = false;
                contextErrorMessages['start_time'] = '';
            }

            // check if start_time is in 30 minute increments
            if (parseInt(moment(start, 'h:mm A', true).format('mm')) % 30 !== 0) {
                contextErrors['start_time'] = true;
                contextErrorMessages['start_time'] = 'Start time must be in 30 minute increments';
                console.log("Start time must be in 30 minute increments in Schedule Day Panel");
            } else {
                contextErrors['start_time'] = false;
                contextErrorMessages['start_time'] = '';
            }
		}

        if(contextErrors['end_time'] === false && contextErrors['start_time'] === false) {
            validateDuration(start, values.end_time);
        }


            // console.log(contextErrors);
            // console.log(contextErrorMessages);
            // navContext.setErrors(contextErrors);
            // navContext.setErrorMessages(contextErrorMessages);
 

		

	}

	const validateEndTime = (end) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

        console.log("Validate End Time");
        console.log(end);

        var openSessionStartTime = moment(openSession?.displayStartTime, 'h:mm A', true);
		var openSessionEndTime = moment(openSession?.displayEndTime, 'h:mm A', true);

		if (openSessionEndTime.isBefore(openSessionStartTime)) {
			openSessionEndTime.add(1, 'd');
		}

        var endTime = moment(end, 'h:mm A', true);
        var startTime = moment(values.start_time, 'h:mm A', true);

        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'd');
        }

		// check if end_time is valid
		if (end.length === 0 || !moment(end, 'h:mm A', true).isValid()) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'Invalid time';
		} else if (values.start_time && moment(values.start_time, 'h:mm A', true).isValid() && moment(end, 'h:mm A').isBefore(moment(values.start_time, 'h:mm A')) && !isMidnight(moment(end, 'h:mm A', true))) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'End time must be after start time';
			console.log("End time must be greater than start time in Schedule Day Panel");
		}
        // check if end_time is between available time period
        else if (endTime.isBefore(openSessionStartTime) || endTime.isAfter(openSessionEndTime)) {
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'End time must be between available time period';
            console.log("End time must be between available time period in Schedule Day Panel");
        } 
        // check if end_time is in 30 minute increments
        else if (parseInt(moment(end, 'h:mm A', true).format('mm')) % 30 !== 0) {
            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'End time must be in 30 minute increments';
            console.log("End time must be in 30 minute increments in Schedule Day Panel");
        } else {
            contextErrors['end_time'] = false;
            contextErrorMessages['end_time'] = '';
        }

        if(contextErrors['end_time'] === false && contextErrors['start_time'] === false) {
            validateDuration(values.start_time, end);
        } else {
            console.log(contextErrors);
            console.log(contextErrorMessages);
            navContext.setErrors(contextErrors);
            navContext.setErrorMessages(contextErrorMessages);
        }

		
	}

    const validateDuration = (start_time, end_time) => {
        var start = moment(start_time, 'h:mm A', true);
        var end = moment(end_time, 'h:mm A', true);

        // duration in hours
        var duration = moment.duration(end.diff(start)).asHours();

        if (duration < 0) {
            duration = moment.duration(end.add(1, 'd').diff(start)).asHours();
        }

        console.log("Duration", duration);

        // check if duration is less than 1 hour
        if(duration < 1) {
            var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
            var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

            contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';

            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'Duration must be at least 1 hour';

            navContext.setErrors(contextErrors);
            navContext.setErrorMessages(contextErrorMessages);
        }
        // check if duration is multiple of 30 minutes
        else if(duration % 0.5 !== 0) {
            var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
            var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

            contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';

            contextErrors['end_time'] = true;
            contextErrorMessages['end_time'] = 'Duration must be in 30 minute increments';

            navContext.setErrors(contextErrors);
            navContext.setErrorMessages(contextErrorMessages);
        } else {
            var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
            var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

            contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';
            
            contextErrors['end_time'] = false;
            contextErrorMessages['end_time'] = '';

            navContext.setErrors(contextErrors);
            navContext.setErrorMessages(contextErrorMessages);
        }
    }

    useEffect(() => {
		//console.log(navContext.values);
		navContext.setValues(values);
		console.log("Session Claim Panel Values", values);
	}, [values])

    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
            <div className="tw-w-full tw-flex tw-flex-col tw-h-full">
                <div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
                    <div className="tw-flex-shrink-0 tw-w-full">
                                
                        <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mb-md">
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Session Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {openSession?.displayStartDate}
                                </span>
                            </li>

                            <li className={`tw-flex tw-space-x-5px tw-items-center tw-pb-15px`}>
                                        <span className="tw-w-150px tw-flex tw-items-center">
                                            <span className="tw-opacity-70">
                                                Available Time Period
                                            </span>
                                        </span>
                                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                            {openSession?.displayStartTime} - {openSession?.displayEndTime}
                                        </span>
                                    </li>
                        </ul>

                        <div className="tw-space-y-5">
                            <div className="tw-relative tw-flex tw-items-start">
                                <div className="tw-flex tw-h-6 tw-items-center">
                                    <input
                                        name="full"
                                        type="radio"
                                        checked={claimEntireTime}
                                        onChange={ e => {
                                            if(e.target.checked) {
                                                console.log("Claim Entire Time Period");
                                                setClaimEntireTime(true);
                                                setClaimPartialTime(false);
                                            }
                                        }
                                        }
                                        className="tw-h-4 tw-w-4 tw-border-gray-300 tw-text-primary focus:tw-ring-primary"
                                    />
                                </div>
                                <div className="tw-ml-3 tw-text-sm tw-leading-6">
                                    <label className="tw-font-medium tw-text-gray-900">
                                        Claim Entire Time Period
                                    </label>
                                </div>
                            </div>

                            <div className="tw-relative tw-flex tw-items-start">
                                <div className="tw-flex tw-h-6 tw-items-center">
                                    <input
                                        name="full"
                                        type="radio"
                                        checked={claimPartialTime}
                                        onChange={ e => {
                                            if(e.target.checked) {
                                                console.log("Claim Partial Time Period");
                                                setClaimEntireTime(false);
                                                setClaimPartialTime(true);
                                            }
                                        }
                                        }
                                        className="tw-h-4 tw-w-4 tw-border-gray-300 tw-text-primary focus:tw-ring-primary"
                                    />
                                </div>
                                <div className="tw-ml-3 tw-text-sm tw-leading-6">
                                    <label className="tw-font-medium tw-text-gray-900">
                                        Claim Partial Time Period
                                    </label>
                                </div>
                            </div>
                        
                        </div>

                        {claimPartialTime && <>
                            <h3 role="button" className="tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em] tw-py-15px" onClick={ e => {
                                    // let temp = toggleSessionInformation;
                                    // setToggleSessionInformation( temp = !temp )
                                }}>
                                    <span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
                                        <span className="tw-flex-grow">
                                        
                                            <span className="tw-text-dark tw-ml-5px">Select Time Period To Claim</span>
                                        
                                        </span>
                                        {/* <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                            <img className="tw-w-full" src={`/images/icon-arrow-${ toggleSessionInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
                                        </span> */}
                                    </span>
                                </h3>

                                <div className="tw-space-y-10px">
                            <InputTimes id="startTime" value={values.start_time} className={navContext.errors?.start_time ? '_form-error' : ''} label="Start Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="30"
                                onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "start_time": true})}
                                onChange={ e => {
                                    if(removeWhitespace(e.target.value) !== removeWhitespace(values.start_time)) {
                                        console.log("Start Time", e.target.value);
                                        setValues({
                                            ...values,
                                            "start_time": e.target.value
                                        });
                                        validateStartTime(e.target.value);
                                    }
                                }}
                            />
                            {navContext.errors?.start_time && <p className="tw-text-red-500">{navContext.errorMessages.start_time}</p>}

                            <InputTimes id="endTime" value={values.end_time} className={navContext.errors?.end_time ? '_form-error' : ''} label="End Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="30"
                                onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "end_time": true})}
                                onChange={ e => {
                                    if(removeWhitespace(e.target.value) !== removeWhitespace(values.end_time)) {
                                        console.log("End Time", e.target.value);
                                        setValues({
                                            ...values,
                                            "end_time": e.target.value
                                        });
                                        validateEndTime(e.target.value);
                                    }
                                }}
                            />
                            {navContext.errors?.end_time && <p className="tw-text-red-500">{navContext.errorMessages.end_time}</p>}
                        </div>
                        </>}

                            

                        
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default SessionClaimPanel;