import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useRef } from "react";

interface CautionDialogProps {
    title: string;
    message: string;
    confirmButtonText: string;
    onConfirm: () => void;
    cancelButtonText?: string;
    onCancel?: () => void;
    showDialog: boolean;
}

const CautionDialog: React.FC<CautionDialogProps> = ({ title, message, confirmButtonText, onConfirm, cancelButtonText, onCancel, showDialog }) => {
    const cancelButtonRef = useRef(null);

    return (<>
        <Transition.Root show={showDialog} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" initialFocus={cancelButtonRef} onClose={onCancel}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                    >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
                <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                    enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                    leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                    >
                    <Dialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-lg sm:tw-p-6">
                        <div className="sm:tw-flex sm:tw-items-start">
                        <div className="tw-mx-auto tw-flex tw-h-12 tw-w-12 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-yellow-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                            <ExclamationTriangleIcon className="tw-h-6 tw-w-6 tw-text-yellow-600" aria-hidden="true" />
                        </div>
                        <div className="tw-mt-3 tw-text-center sm:tw-ml-4 sm:tw-mt-0 sm:tw-text-left">
                            <Dialog.Title as="h3" className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                            {title}
                            </Dialog.Title>
                            <div className="tw-mt-2">
                            <p className="tw-text-sm tw-text-gray-500">
                                {message}
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="tw-mt-5 sm:tw-ml-10 sm:tw-mt-4 sm:tw-flex sm:tw-pl-4">
                        <button
                            type="button"
                            className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-yellow-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-yellow-500 sm:tw-w-auto"
                            onClick={onConfirm}
                        >
                            {confirmButtonText}
                        </button>
                        <button
                            type="button"
                            className="tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 sm:tw-ml-3 sm:tw-mt-0 sm:tw-w-auto"
                            onClick={onCancel}
                            ref={cancelButtonRef}
                        >
                            {cancelButtonText || "Cancel"}
                        </button>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>);

};

export default CautionDialog;