import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useAppContext } from "../../Context";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { LoadingSpinner } from "./loadingSpinner";
import { SmartSensor } from "../../types/serviceLocation";
import { addSmartSensorToLocation, deleteSmartSensorInLocation, updateSmartSensorInLocation } from "../../apicalls/serviceLocationService";

const sensorSchema = Yup.object().shape({
    roomID: Yup.string().required('Room ID is required'),
    sensorName: Yup.string().required('Sensor Name is required'),
    sensorType: Yup.string().required('Sensor Type is required'),
    sensorManufacturer: Yup.string().required('Sensor Manufacturer is required'),
});

export const EditLocationSmartSensorsCard = props => {
    const [showSensorForm, setShowSensorForm] = useState(false);
    const [editId, setEditId] = useState<string | null>(null);

    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const openSensorForm = () => {
        sensorFormik.resetForm();
        setShowSensorForm(true);
    }

    const closeSensorForm = () => {
        setShowSensorForm(false);
    }

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, updatedSensor: SmartSensor) {
        
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    function handleDelete(){
        // Delete the contact
        setEditId(null);

        props?.refreshLocation(props?.location?.serviceLocationID);
    }

    const initialValues = {
        roomID: '',
        sensorName: '',
        sensorType: '',
        sensorManufacturer: '',
    };

    const sensorFormik = useFormik({
        initialValues: initialValues,
        validationSchema: sensorSchema,
        onSubmit: async (values) => {
          // handle form submission
          console.log("Form submitted")
            console.log(values)
            setLoading(true);

            var room = props?.rooms.filter(r => r.roomID === values.roomID)[0];

            var newSensor : SmartSensor = {
                smartSensorID: "",
                serviceLocationID: "",
                roomID: values.roomID,
                roomName: room.roomName,
                sensorName: values.sensorName,
                sensorType: values.sensorType,
                sensorManufacturer: values.sensorManufacturer,
            }

            var result = await addSmartSensorToLocation(props?.location?.serviceLocationID, newSensor, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Smart Sensor added successfully.");
                appContext.setShowSuccessModal(true);
            }
            setLoading(false);
            props?.refreshLocation(props?.location?.serviceLocationID);
            closeSensorForm();
            //handleAddRoom(values);
        },
    });

    return (<>
        <div className="tw-mt-4">
                    <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Sensors</span>
                            {!showSensorForm && <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={openSensorForm}/> }
                    </div>

                    {showSensorForm && <div className="tw-bg-card-green tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">New Smart Sensor</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Sensor Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${sensorFormik.touched.sensorName && sensorFormik.errors.sensorName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...sensorFormik.getFieldProps('sensorName')}
                                                />
                                                {sensorFormik.touched.sensorName && sensorFormik.errors.sensorName && <p className="tw-text-red-500">{sensorFormik.errors.sensorName}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room
                                            </label>
                                            <select
                                                id="state"
                                                name="state"
                                                className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${sensorFormik.touched.roomID && sensorFormik.errors.roomID ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...sensorFormik.getFieldProps('roomID')}
                                            >
                                                <option value="">Select Room</option>
                                                {props?.rooms?.map((room, index) => {
                                                    return <option key={index} value={room.roomID}>{room.roomName}</option>
                                                })}
                                            </select>
                                            {sensorFormik.touched.roomID && sensorFormik.errors.roomID && <p className="tw-text-red-500">{sensorFormik.errors.roomID}</p>}

                                        </div>
                                        
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Sensor Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${sensorFormik.touched.sensorType && sensorFormik.errors.sensorType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...sensorFormik.getFieldProps('sensorType')}
                                                />
                                                {sensorFormik.touched.sensorType && sensorFormik.errors.sensorType && <p className="tw-text-red-500">{sensorFormik.errors.sensorType}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Sensor Manufacturer
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${sensorFormik.touched.sensorManufacturer && sensorFormik.errors.sensorManufacturer ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...sensorFormik.getFieldProps('sensorManufacturer')}
                                                />
                                                {sensorFormik.touched.sensorManufacturer && sensorFormik.errors.sensorManufacturer && <p className="tw-text-red-500">{sensorFormik.errors.sensorManufacturer}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={closeSensorForm}>
                                                        Cancel
                                                </ButtonSecondary>
                                                <ButtonPrimary className="tw-mt-md" onClick={sensorFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>

                                        </div>
                                    </>}
                                </div>}

                    {props?.sensors?.length > 0 &&  props?.sensors?.map((sensor, index) => {
                            if(index !== props?.sensors?.length - 1) {
                                return <>
                                    {sensor.smartSensorID === editId ? <EditSensorCard sensor={sensor} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} rooms={props?.rooms} location={props?.location}/> : <ViewSensorCard sensor={sensor} handleEdit={handleEdit} />}
                                    
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                </>
                            } else {
                                return <>
                                    {sensor.smartSensorID === editId ? <EditSensorCard sensor={sensor} handleSave={handleSave} handleCancel={handleCancel} handleDelete={handleDelete} rooms={props?.rooms} location={props?.location}/> : <ViewSensorCard sensor={sensor} handleEdit={handleEdit} />}
                                </>
                            }
                            })}
        </div>
    </>);
}

export const EditSensorCard = props => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    const confirmDelete = () => {
        setShowConfirmDelete(true);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const deleteCamera = async () => {
        setLoading(true);
        var result = await deleteSmartSensorInLocation(props?.location?.serviceLocationID, props?.sensor?.smartSensorID, appContext.handleAPICallError);

        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Smart sensor deleted successfully.");
            appContext.setShowSuccessModal(true);
        }

        props?.handleDelete();

        setLoading(false);
    }

    const initialValues = {
        roomID: props?.sensor?.roomID,
        sensorName: props?.sensor?.sensorName,
        sensorType: props?.sensor?.sensorType,
        sensorManufacturer: props?.sensor?.sensorManufacturer,
    };

    const sensorFormik = useFormik({
        initialValues: initialValues,
        validationSchema: sensorSchema,
        onSubmit: async (values) => {
          // handle form submission
          console.log("Form submitted")
            console.log(values)
            setLoading(true);

            var room = props?.rooms.filter(r => r.roomID === values.roomID)[0];

            var newSensor : SmartSensor = {
                smartSensorID: props?.sensor?.smartSensorID,
                serviceLocationID: props?.location?.serviceLocationID,
                roomID: values.roomID,
                roomName: room.roomName,
                sensorName: values.sensorName,
                sensorType: values.sensorType,
                sensorManufacturer: values.sensorManufacturer,
            }

            var result = await updateSmartSensorInLocation(props?.location?.serviceLocationID, newSensor, appContext.handleAPICallError);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Smart Sensor updated successfully.");
                appContext.setShowSuccessModal(true);
            }
            setLoading(false);

            props?.handleSave(newSensor);
            //handleAddRoom(values);
        },
    });

    return (<>
        <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Smart Sensor</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Sensor Name
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${sensorFormik.touched.sensorName && sensorFormik.errors.sensorName ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...sensorFormik.getFieldProps('sensorName')}
                                                />
                                                {sensorFormik.touched.sensorName && sensorFormik.errors.sensorName && <p className="tw-text-red-500"><>{sensorFormik.errors.sensorName}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Room
                                            </label>
                                            <select
                                                id="state"
                                                name="state"
                                                className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${sensorFormik.touched.roomID && sensorFormik.errors.roomID ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...sensorFormik.getFieldProps('roomID')}
                                            >
                                                <option value="">Select Room</option>
                                                {props?.rooms?.map((room, index) => {
                                                    return <option key={index} value={room.roomID}>{room.roomName}</option>
                                                })}
                                            </select>
                                            {sensorFormik.touched.roomID && sensorFormik.errors.roomID && <p className="tw-text-red-500"><>{sensorFormik.errors.roomID}</></p>}

                                        </div>
                                        
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Sensor Type
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${sensorFormik.touched.sensorType && sensorFormik.errors.sensorType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...sensorFormik.getFieldProps('sensorType')}
                                                />
                                                {sensorFormik.touched.sensorType && sensorFormik.errors.sensorType && <p className="tw-text-red-500"><>{sensorFormik.errors.sensorType}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Sensor Manufacturer
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="room-name"
                                                id="room-name"
                                                className={`tw-block tw-w-full tw-rounded-md ${sensorFormik.touched.sensorManufacturer && sensorFormik.errors.sensorManufacturer ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...sensorFormik.getFieldProps('sensorManufacturer')}
                                                />
                                                {sensorFormik.touched.sensorManufacturer && sensorFormik.errors.sensorManufacturer && <p className="tw-text-red-500"><>{sensorFormik.errors.sensorManufacturer}</></p>}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4 tw-mt-2">
                                            <LoadingSpinner />
                                        </div>
                                    </> : <>
                                    {showConfirmDelete ? <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                        <p className="tw-text-primary">Are you sure you want to delete this smart sensor?</p>
                                        </div>
                                    
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deleteCamera}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={confirmDelete}>
                                                    Delete
                                            </ButtonSecondary>
                                                <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.handleCancel}>
                                                        {sensorFormik.dirty ? "Discard": "Cancel" }
                                                </ButtonSecondary>
                                                {sensorFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={sensorFormik.handleSubmit}>
                                                    Save
                                                </ButtonPrimary>}

                                    </div>
                                    </>}
                                    </>}
                                </div>
    </>);
}

export const ViewSensorCard = props => {
    return (<>
         <div className="tw-bg-grey-light tw-flex tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
        <div className="tw-w-4/5 tw-flex-grow">
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Sensor Name</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.sensor.sensorName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Room</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.sensor.roomName}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Sensor Type</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.sensor.sensorType}</span>
                                            </div>
                                            <div className="tw-flex tw-px-4 tw-pb-2">
                                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Sensor Manufacturer</span>
                                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.sensor.sensorManufacturer}</span>
                                            </div>

        </div>
        <div className="tw-w-1/5 tw-flex tw-justify-end tw-items-center">
                        <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={() => props?.handleEdit(props?.sensor.smartSensorID)} />
            </div>  
            </div>
    </>);
}
