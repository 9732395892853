import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import moment from "moment";
import { getSessionDocumentationForSpan } from "../../apicalls/sessionService";

import { List, FileText } from 'lucide-react';

// Import the components we created earlier
import RSReportDashboard from './RemoteSupportDashboard';
import ReportDetailView from './RSDetailedDashboard';
import RemoteSupportSessionsComponent from "./RemoteSupportDocumentation";

const RSReportManagementPage = props => {
    const appContext = useAppContext();

    const [activeView, setActiveView] = useState<'dashboard' | 'detail'>('dashboard');
    
    useLayoutEffect(() => {
        const fetchRSReports = async () => {
            const startDate = moment().add(-7, "days").format("MM-DD-YYYY");
            const endDate = moment().format("MM-DD-YYYY");

            const reports = await getSessionDocumentationForSpan(startDate, endDate, appContext.handleAPICallError);

            console.log(reports);
        }

        //fetchRSReports();
    }, []);
    
    return (<>
        <div className="tw-px-xl tw-py-xl">
				 <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Remote Support Report Management</h2>
                </div>

                <RemoteSupportSessionsComponent />

                {/* <div className="tw-flex tw-space-x-4">
                    <button
                        onClick={() => setActiveView('dashboard')}
                        className={`tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-md ${
                        activeView === 'dashboard'
                            ? 'tw-bg-blue-500 tw-text-white'
                            : 'tw-bg-gray-200 tw-text-gray-700 hover:tw-bg-gray-300'
                        }`}
                    >
                        <List className="tw-mr-2" size={20} />
                        Dashboard View
                    </button>
                    <button
                        onClick={() => setActiveView('detail')}
                        className={`tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-md ${
                        activeView === 'detail'
                            ? 'tw-bg-blue-500 tw-text-white'
                            : 'tw-bg-gray-200 tw-text-gray-700 hover:tw-bg-gray-300'
                        }`}
                    >
                        <FileText className="tw-mr-2" size={20} />
                        Detail View
                    </button>
                </div>

                {activeView === 'dashboard' ? (
                    <RSReportDashboard />
                ) : (
                    <ReportDetailView />
                )} */}
        </div>
    </>);
}

export default RSReportManagementPage;