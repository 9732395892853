import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppContext } from "../../Context";
import CalendarSelector from "../calendars/CalendarSelector";
import { InputTimes, Select, SupportType, Label, FrequencyField, FrequencyFieldDays, ChangeType, Textarea } from "../forms/formFields";
import { set } from "lodash";
import { useFormik } from "formik";
import * as Yup from 'yup';
import moment from 'moment';
import { ButtonPrimary, LabelOutputs, Span } from "@medforall/medforall-component-library";
import CoverageRequirement from "../../types/coverageRequirement";
import { ButtonSecondary } from "../buttons/Buttons";
import { SpanCoverageEvent } from "../../types/spanRequirement";
import { CircleLabel } from "../calendars/CalendarUtils";
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import CoverDetailsPanel from "./CoverDetailsPanel";
import { getServiceLocationsByPatientID } from "../../apicalls/serviceLocationService";
import { IsDirectSupport } from "../../utils/Helpers";
import ServiceLocation from "../../types/serviceLocation";

export function removeWhitespace(str: string) {
	if(!str) return str;
	return str.replace(/\s/g, '');
}

export function isMidnight(time: moment.Moment) {
	return time.hour() === 0 && time.minute() === 0;
}

export const ScheduleDayPanel = props => {
	const navContext = useAppContext();
	const [repeatFrequency, setRepeatFrequency] = useState<boolean>(false);
	const [values, setValues] = useState<any>(navContext.values || {});
	const [day, setDay] = useState<any>(null);
	const [title, setTitle] = useState<any>(props.title || 'Add New Requirement');
	const [editing, setEditing] = useState<boolean>(false);
	const [editingSpanReq, setEditingSpanReq] = useState<boolean>(false);
	const [changeRequirements, setChangeRequirements] = useState<boolean>(false);
	const [clientServiceTypes, setClientServiceTypes] = useState([]);
	const [clientServiceLocations, setClientServiceLocations] = useState<ServiceLocation[]>([]);
	const [showLocations, setShowLocations] = useState<boolean>(true);
	const generalShiftType = useRef();
	const shiftLocation = useRef();

	const [generalFormValues, setGeneralFormValues] = useState<any>({
		"start_time": "",
		"end_time": "",
		"shift_type": "",
        "allDay": false,
        "repeats_monday": false,
		"repeats_tuesday": false,
		"repeats_wednesday": false,
		"repeats_thursday": false,
		"repeats_friday": false,
		"repeats_saturday": false,
		"repeats_sunday": false,
	});

	useEffect(() => {
		console.log(navContext.values);
		navContext.setValues(values);
		console.log("Schedule Day Panel Values", values);
	}, [values])

	useEffect(() => {
		console.log(navContext.fieldStates);

		if(!editingSpanReq && navContext.fieldStates){
			if (navContext.fieldStates['shift_type']) {
				validateShiftType(values.shift_type);
			}
			if (navContext.fieldStates['start_time']) {
				validateStartTime(values.start_time);
			}
			if (navContext.fieldStates['end_time']) {
				validateEndTime(values.end_time);
			}
			if (navContext.fieldStates['repeats_monday'] || navContext.fieldStates['repeats_tuesday'] || navContext.fieldStates['repeats_wednesday'] || navContext.fieldStates['repeats_thursday'] || navContext.fieldStates['repeats_friday'] || navContext.fieldStates['repeats_saturday'] || navContext.fieldStates['repeats_sunday']) {
				validateFrequency(values.repeats_monday, values.repeats_tuesday, values.repeats_wednesday, values.repeats_thursday, values.repeats_friday, values.repeats_saturday, values.repeats_sunday);
			}
		}

		
	}, [navContext.fieldStates])

	const validateShiftType = (type) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		console.log("Validate Shift Type", type);

		// check if shift_type is valid
		if ((!type || type === '')) {
            contextErrors['shift_type'] = true;
            contextErrorMessages['shift_type'] = 'Shift type is required';
		} else {
            contextErrors['shift_type'] = false;
            contextErrorMessages['shift_type'] = '';

			var serviceType = clientServiceTypes.find((serviceType) => {
				return serviceType.shiftType === type;
			});

			console.log("Service Type", serviceType);

			if (serviceType && IsDirectSupport(serviceType.billingCode)) {
				setShowLocations(true);
			} else {
				setShowLocations(false);
			}
        }

		console.log(contextErrors);
		console.log(contextErrorMessages);
        navContext.setErrors(contextErrors);
        navContext.setErrorMessages(contextErrorMessages);
	}

	const validateStartTime = (start) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));
		console.log("Validate Start Time");

		// check if start_time is valid
		if (start.length === 0 || !moment(start, 'hh:mm A', true).isValid()) {
			contextErrors['start_time'] = true;
            contextErrorMessages['start_time'] = 'Invalid time';
		} else {
			contextErrors['start_time'] = false;
            contextErrorMessages['start_time'] = '';
			
			if (values.end_time && moment(values.end_time, 'h:mm A', true).isValid() && moment(start, 'h:mm A', true).isAfter(moment(values.end_time, 'h:mm A', true)) && !isMidnight(moment(values.end_time, 'h:mm A', true))) {
				contextErrors['end_time'] = true;
				contextErrorMessages['end_time'] = 'End time must be after start time';
				console.log("End time must be greater than start time in Schedule Day Panel Start Time");
			} else {
				contextErrors['end_time'] = false;
				contextErrorMessages['end_time'] = '';
			}
		}

		console.log(contextErrors);
		console.log(contextErrorMessages);
		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);

	}

	const validateEndTime = (end) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if end_time is valid
		if (end.length === 0 || !moment(end, 'h:mm A', true).isValid()) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'Invalid time';
		} else if (values.start_time && moment(values.start_time, 'h:mm A', true).isValid() && moment(end, 'h:mm A').isBefore(moment(values.start_time, 'h:mm A')) && !isMidnight(moment(end, 'h:mm A', true))) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'End time must be after start time';
			console.log("End time must be greater than start time in Schedule Day Panel");
		} else {
			contextErrors['end_time'] = false;
			contextErrorMessages['end_time'] = '';
		}

		console.log(contextErrors);
		console.log(contextErrorMessages);
		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateFrequency = (monday, tuesday, wednesday, thursday, friday, saturday, sunday) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if frequency is valid
		if (!monday && !tuesday && !wednesday && !thursday && !friday && !saturday && !sunday) {
			contextErrors['frequency'] = true;
			contextErrorMessages['frequency'] = 'At least one day should be selected for repeat';
		} else {
			contextErrors['frequency'] = false;
			contextErrorMessages['frequency'] = '';
		}

		console.log(contextErrors);
		console.log(contextErrorMessages);
		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	useLayoutEffect(() => {
		navContext.setSideNavTitle(title);
		//navContext.setSideNavActions(true);
	}, [navContext, title])

	useLayoutEffect(() => {
		if (props.day) {
			setDay(props.day);
			console.log("Appointment Day", props.day)
		}
		if (props.title) setTitle(props.title);
		if (props.edit) setEditing(true);
		if (props.editSpanReq) setEditingSpanReq(true);
		if (props.serviceTypes) setClientServiceTypes(props.serviceTypes);
		console.log("Schedule Day Panel", props);
		console.log(navContext);
	}, [props.day, props.title, props.edit, props.serviceTypes, props.editSpanReq])

	useLayoutEffect(() => {
		if (props.changeRequirements) setChangeRequirements(true);
	}, [props.changeRequirements])

	useEffect(() => {
		const fetchLocations = async () => {
			const locations = await getServiceLocationsByPatientID(props?.patientID, navContext.handleAPICallError);

			if (locations) {
				console.log("Locations", locations);
				setClientServiceLocations(locations);
			}
		}

		if (props?.patientID) {
			fetchLocations();
		}
	}, [props?.patientID])

	const mapDay = (day: number) => {
		const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	  
		if(day < 0 || day > 6) {
		  throw new Error('Invalid day number');
		}
	  
		return days[day];
	}

	return <>
		<form className="tw-p-20px tw-space-y-30px tw-w-full">
			<div className="tw-space-y-10px">
				{/* <SupportType id="supportType" title={ changeRequirements ? 'Support Type' : null } onChange={ e => {
					setValues({
						...values,
						"supportType": e.target.value
					})
				}} /> */}
				<Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
						{ props.title || 'Support Type'}
				</Label>
				<Select id="backupType" className={navContext.errors?.shift_type ? '_form-error': ''} onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "shift_type": true})} onChange={ e => {
					console.log(e.target.value);
					setValues({...values, "shift_type": e.target.value});
					validateShiftType(e.target.value);

					// formik.setFieldValue('shift_type', e.target.value);
					// formik.setFieldTouched('shift_type', true, false);
					// formik.validateForm({ ...formik.values, ['shift_type']: e.target.value });
					// //formik.validateField('shift_type');
					
					// console.log(formik.values);
					// navContext?.setSideNavFormik(formik);
				}} ref={ generalShiftType } value={ values.shift_type }>
					<option value="">Select</option>
					{ clientServiceTypes.map((serviceType, index) => {
						return <option key={ index } value={ serviceType.shiftType }>{ serviceType.shiftType }</option>
					})
					}

					{/* <option value="With Back-up">With Back-up</option>
					<option value="Without Back-up">Without Back-up</option> */}
				</Select>
				{navContext.errors?.shift_type  && <p className="tw-text-red-500">{navContext.errorMessages.shift_type}</p>}
			</div>
			{ changeRequirements || props.date ? <>
				<div>
					<CalendarSelector value={ props.date || null } />
				</div>
			</> : <></>}
			<div className="tw-space-y-10px">
				<InputTimes id="startTime" value={values.start_time} className={navContext.errors?.start_time ? '_form-error' : ''} label="Start Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "start_time": true})}
					onChange={ e => {
						if(removeWhitespace(e.target.value) !== removeWhitespace(values.start_time)) {
							console.log("Start Time", e.target.value);
							setValues({
								...values,
								"start_time": e.target.value
							});
							validateStartTime(e.target.value);
						}
						// formik.setFieldValue('start_time', e.target.value);
						// formik.validateField('start_time');
						// setGeneralFormValues({
						// 	...generalFormValues,
						// 	"start_time": e.target.value
						// });
					}}
				/>
				{navContext.errors?.start_time && <p className="tw-text-red-500">{navContext.errorMessages.start_time}</p>}
				<InputTimes id="endTime" value={values.end_time} className={navContext.errors?.end_time ? '_form-error' : ''} label="End Time" increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "end_time": true})}
					onChange={ e => {
						if(removeWhitespace(e.target.value) !== removeWhitespace(values.end_time)) {
							console.log("End Time", e.target.value);
							setValues({
								...values,
								"end_time": e.target.value
							});
							validateEndTime(e.target.value);
						}
						
						// setValues({
						// 	...values,
						// 	[e.target.id]: e.target.value
						// });
						// formik.setFieldValue('end_time', e.target.value);
						// formik.validateField('end_time');
						// setGeneralFormValues({
						// 	...generalFormValues,
						// 	"end_time": e.target.value
						// });
					}}
				/>
				{navContext.errors?.end_time && <p className="tw-text-red-500">{navContext.errorMessages.end_time}</p>}
			</div>

			{showLocations && <div className="tw-space-y-10px">
				<Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
						{'Service Location'}
				</Label>
				<Select id="serviceLocation" className={navContext.errors?.service_location ? '_form-error': ''} onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "service_location": true})} onChange={ e => {
					console.log(e.target.value);
					setValues({...values, "serviceLocation": e.target.value});
					//validateShiftType(e.target.value);

					// formik.setFieldValue('shift_type', e.target.value);
					// formik.setFieldTouched('shift_type', true, false);
					// formik.validateForm({ ...formik.values, ['shift_type']: e.target.value });
					// //formik.validateField('shift_type');
					
					// console.log(formik.values);
					// navContext?.setSideNavFormik(formik);
				}} ref={ shiftLocation } value={ values.service_location }>
					<option value="">Select Location</option>
					{ clientServiceLocations.map((serviceLocation, index) => {
						return <option key={ index } value={ serviceLocation.serviceLocationID }>{ serviceLocation.physicalAddress.street1 }, {serviceLocation.physicalAddress.street2}, {serviceLocation.physicalAddress.city}, {serviceLocation.physicalAddress.state} - {serviceLocation.physicalAddress.zip}</option>
					})
					}

					{/* <option value="With Back-up">With Back-up</option>
					<option value="Without Back-up">Without Back-up</option> */}
				</Select>
				{navContext.errors?.service_location  && <p className="tw-text-red-500">{navContext.errorMessages.service_location}</p>}
			</div>}

			{ !changeRequirements && !props.date && !editingSpanReq ? <>
				<div>
					<FrequencyFieldDays id="frequency" className={navContext.errors?.frequency ? '_form-error':''} day={ day } 
					 onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "frequency": true})}
					onChange={ e => {
						// setValues({
						// 	...values,
						// 	[e.target.id]: JSON.parse(e.target.value)
						// });
						var days = JSON.parse(e.target.value);
						console.log("Frequency", e.target.value);
						// setGeneralFormValues({
						// 	...generalFormValues,
						// 	"repeats_monday": days.Monday,
						// 	"repeats_tuesday": days.Tuesday,
						// 	"repeats_wednesday": days.Wednesday,
						// 	"repeats_thursday": days.Thursday,
						// 	"repeats_friday": days.Friday,
						// 	"repeats_saturday": days.Saturday,
						// 	"repeats_sunday": days.Sunday,
						// });
						// formik.setFieldValue('repeats_monday', days.Monday);
						// formik.setFieldValue('repeats_tuesday', days.Tuesday);
						// formik.setFieldValue('repeats_wednesday', days.Wednesday);
						// formik.setFieldValue('repeats_thursday', days.Thursday);
						// formik.setFieldValue('repeats_friday', days.Friday);
						// formik.setFieldValue('repeats_saturday', days.Saturday);
						// formik.setFieldValue('repeats_sunday', days.Sunday);
						setValues({
							...values,
							"repeats_monday": days.Monday,
							"repeats_tuesday": days.Tuesday,
							"repeats_wednesday": days.Wednesday,
							"repeats_thursday": days.Thursday,
							"repeats_friday": days.Friday,
							"repeats_saturday": days.Saturday,
							"repeats_sunday": days.Sunday,
							});
						validateFrequency(days.Monday, days.Tuesday, days.Wednesday, days.Thursday, days.Friday, days.Saturday, days.Sunday);
					}}/>
					{navContext.errors?.frequency && <p className="tw-text-red-500">{navContext.errorMessages.frequency}</p>}
				</div>
			</> : <></>}

			{editingSpanReq && <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-repeat.svg" className="tw-opacity-70">
									Day of Week
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{mapDay(props?.requirement?.dayOfWeek)}
							</span>
						</li>}

			{ editing && !editingSpanReq && <>
				<div className="tw-w-full">
					<ChangeType id="reason" onChange={ e => {
						setValues({
							...values,
							"reason": e.target.value
						})
					}} />
					<fieldset className="tw-w-full tw-pt-10px">
						<Textarea id="reasonDetail" className="!tw-w-[calc(100%-2.5rem)]" placeholder="Type your reason for changing here (Optional)" onChange={ e => {
							setValues({
								...values,
								"reasonDetail": e.target.value
							})
						}}>
						</Textarea>
					</fieldset>
				</div>
			</>}
			{/* <div>
				<ButtonPrimary type="submit" className="tw-w-full" onClick={ e => {
					e.preventDefault();
					console.log(navContext);
				}}>Save</ButtonPrimary>
			</div> */}
		</form>

	</>
}

export const EditScheduleDayPanel = props => {
	const navContext = useAppContext();
	const [values, setValues] = useState<any>(navContext.values || {});
	const [clientServiceTypes, setClientServiceTypes] = useState([]);
	const [title, setTitle] = useState<any>(props.title || 'Edit Coverage Requirement');
	const [shift, setShift] = useState<CoverageRequirement>(props.shift || null);
	const [reason, setReason] = useState<any>(null);
	const reqShiftType = useRef();

	useEffect(() => {
		console.log(navContext.values);
		if (values?.date && values?.start_time && values?.end_time && values?.shift_type) {
			navContext.setValues(values);
		}
		console.log("Edit Schedule Day Panel Values", values);
		if(values?.end_time && values?.start_time){
			validateStartTime(values.start_time);
			validateEndTime(values.end_time);
		}
	}, [values])

	useEffect(() => {
		if(reason){
			setValues({...values, "req_reason": reason})
		}
	}, [reason])

	const validateStartTime = (startTime) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if start_time is valid
		if (!startTime || startTime.length === 0 || !moment(startTime, 'h:mm A', true).isValid()) {
			contextErrors['start_time'] = true;
			contextErrorMessages['start_time'] = 'Invalid time';
		} else {
			contextErrors['start_time'] = false;
			contextErrorMessages['start_time'] = '';
			if (values.end_time && moment(values.end_time, 'h:mm A', true).isValid() && moment(startTime, 'h:mm A', true).isAfter(moment(values.end_time, 'h:mm A', true))) {
				contextErrors['end_time'] = true;
				contextErrorMessages['end_time'] = 'End time must be after start time';
			} else {
				contextErrors['end_time'] = false;
				contextErrorMessages['end_time'] = '';
			}
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateEndTime = (endTime) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));
		console.log("validateEndTime", endTime);

		// check if end_time is valid
		if (!endTime || endTime.length === 0 || !moment(endTime, 'h:mm A', true).isValid()) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'Invalid time';
		} else if (values.start_time && moment(values.start_time, 'h:mm A', true).isValid() && moment(endTime, 'h:mm A', true).isBefore(moment(values.start_time, 'h:mm A', true)) && !isMidnight(moment(endTime, 'h:mm A', true))) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'End time must be after start time';
		} else {
			contextErrors['end_time'] = false;
			contextErrorMessages['end_time'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateShiftDate = (date) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if date is valid
		if (date.length === 0 || !moment(date, 'M-D-YYYY', true).isValid()) {
			contextErrors['date'] = true;
			contextErrorMessages['date'] = 'Invalid date';
		} else {
			contextErrors['date'] = false;
			contextErrorMessages['date'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateShiftType = (shiftType) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if shift_type is valid
		if (!shiftType || shiftType.length === 0) {
			contextErrors['shift_type'] = true;
			contextErrorMessages['shift_type'] = 'Shift type is required';
		} else {
			contextErrors['shift_type'] = false;
			contextErrorMessages['shift_type'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateReason = (reason) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if reason is valid
		if (!reason || reason.length === 0) {
			contextErrors['req_reason'] = true;
			contextErrorMessages['req_reason'] = 'Reason is required';
		} else {
			contextErrors['req_reason'] = false;
			contextErrorMessages['req_reason'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}



	useLayoutEffect(() => {
		navContext.setSideNavTitle(title);
		//navContext.setSideNavActions(true);
	}, [navContext, title])

	useLayoutEffect(() => {
		console.log(values);

		if (props.shift) {
			setShift(props.shift);
			const initialValues = {
				date: moment(props.shift?.displayCoverageDate).format("M-D-YYYY") || "",
				start_time: props.shift?.displayStartTime || "",
				end_time: props.shift?.displayEndTime || "",
				shift_type: props.shift?.shiftType || "",
				req_reason: "",
				reasonDetail: "",
			};
			setValues(initialValues);

		}
		if (props.serviceTypes) setClientServiceTypes(props.serviceTypes);

	}, [props.shift, props.serviceTypes])

	const mapDay = (day: number) => {
		const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	  
		if(day < 0 || day > 6) {
		  throw new Error('Invalid day number');
		}
	  
		return days[day];
	}

	return <>
		<form className="tw-p-20px tw-space-y-30px tw-w-full">
			<div className="tw-space-y-10px">
				<Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
						{ props.title || 'Support Type'} 
				</Label>
				
				<Select id="backupType" className={navContext.errors?.shift_type ? '_form-error': ''} onChange={ e => {
					
					console.log(e.target.value);
					setValues({...values, "shift_type": e.target.value});
					validateShiftType(e.target.value);

					// formik.setFieldValue('shift_type', e.target.value);
					// formik.setFieldTouched('shift_type', true, false);
					// formik.validateForm({ ...formik.values, ['shift_type']: e.target.value });
					// //formik.validateField('shift_type');
					
					// console.log(formik.values);
					// navContext?.setSideNavFormik(formik);
				}} ref={ reqShiftType } value={ values.shift_type }>
					<option value="">Select</option>
					{ clientServiceTypes.map((serviceType, index) => {
						return <option key={ index } value={ serviceType.shiftType }>{ serviceType.shiftType }</option>
					})
					}

					{/* <option value="With Back-up">With Back-up</option>
					<option value="Without Back-up">Without Back-up</option> */}
				</Select>
				{navContext.errors?.shift_type && <p className="tw-text-red-500">{navContext.errorMessages.shift_type}</p>}
				{/* <SupportType id="supportType" onChange={ e => {
					setValues({
						...values,
						"supportType": e.target.value
					})
				}} />
				<Select id="backupType" onChange={ e => {
					setValues({
						...values,
						[e.target.id]: e.target.value
					})
				}}>
					<option value="With Back-up">With Back-up</option>
					<option value="Without Back-up">Without Back-up</option>
				</Select> */}
			</div>
			<div className="tw-space-y-20px">
					<CalendarSelector id="coverageDate" name="Coverage Date:" value={values.date} className={navContext.errors?.date ? '_form-error' : ''} onChange={ e => {
												
												console.log(e.target.value);
												setValues({...values, "date": e.target.value});
												validateShiftDate(e.target.value);
					}}/>
					{navContext.errors?.date && <p className="tw-text-red-500">{navContext.errorMessages.date}</p>}
				<InputTimes id="startTime" value={values.start_time} label="Start Time" className={navContext.errors?.start_time ? '_form-error' : ''} increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
					onChange={ e => {
						if(removeWhitespace(e.target.value) !== removeWhitespace(values.start_time)) {
							console.log("Start Time", e.target.value);
							setValues({
								...values,
								"start_time": e.target.value
							});
							validateStartTime(e.target.value);
						}
					}}
				/>
				{navContext.errors?.start_time && <p className="tw-text-red-500">{navContext.errorMessages.start_time}</p>}
				<InputTimes id="endTime" value={values.end_time} label="End Time" className={navContext.errors?.end_time ? '_form-error' : ''} increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
					onChange={ e => {
						if(removeWhitespace(e.target.value) !== removeWhitespace(values.end_time)) {
							console.log("End Time", e.target.value);
							setValues({
								...values,
								"end_time": e.target.value
							});
							validateEndTime(e.target.value);
						}
					}}
				/>
				{navContext.errors?.end_time && <p className="tw-text-red-500">{navContext.errorMessages.end_time}</p>}
			</div>

			
			<div className="tw-w-full">
					<ChangeType id="reason" onChange={ e => {
						console.log("Reason", e.target.value);
						setReason(e.target.value);
						//validateReason(e.target.value);
						// setValues({
						// 	...values,
						// 	"req_reason": e.target.value
						// })
					}} />
					{navContext.errors?.req_reason && <p className="tw-text-red-500">{navContext.errorMessages.req_reason}</p>}
					<fieldset className="tw-w-full tw-pt-10px">
						<Textarea id="reasonDetail" className="!tw-w-[calc(100%-2.5rem)]" placeholder="Type your reason for changing here" onChange={ e => {
							console.log("Reason Detail", e.target.value);
							setValues({
								...values,
								"reasonDetail": e.target.value
							})
						}}>
						</Textarea>
					</fieldset>
			</div>
			{/* <div>
				<ButtonPrimary type="submit" className="tw-w-full" onClick={ e => {
					e.preventDefault();
					console.log(navContext);
				}}>Save</ButtonPrimary>
			</div> */}
		</form>

	</>
}

const SchedulePanel = props => {
	const navContext = useAppContext();
	const [repeatFrequency, setRepeatFrequency] = useState<string>("Never");
	const [values, setValues] = useState<any>(navContext.values || []);
	const [clientServiceTypes, setClientServiceTypes] = useState([]);
	const [date, setDate] = useState<any>(null);
	const [title, setTitle] = useState<any>(props.title || 'Add New Coverage');
	const [onDemandReasons, setOnDemandReasons] = useState<any>([
		{"value": "Requested By Client"},
		{"value": "Delegated From Direct Support"},
		{"value": "Custom"}
	]);

	const reqShiftType = useRef();
	const reqReason = useRef();
	const customRef = useRef(null);

	useEffect(() => {
		console.log(navContext.values);
		navContext.setValues(values);
		console.log("On-Demand Requirement Panel Values", values);
	}, [values])

	useLayoutEffect(() => {
		if (props.date) {
			setDate(props.date);
			console.log("Appointment Day", props.date)
		}
		if (props.title) setTitle(props.title);
		if (props.serviceTypes) setClientServiceTypes(props.serviceTypes);
		console.log("Requirement Panel", props);
		console.log(navContext);
	}, [props.day, props.title, props.serviceTypes])

	useEffect(() => {
		console.log(navContext.fieldStates);

		if (navContext.fieldStates['date']) {
			validateShiftDate(values.date);
		}
		if (navContext.fieldStates['shift_type']) {
			validateShiftType(values.shift_type);
		}
		if (navContext.fieldStates['start_time']) {
			validateStartTime(values.start_time);
		}
		if (navContext.fieldStates['end_time']) {
			validateEndTime(values.end_time);
		}
		if (navContext.fieldStates['req_reason']) {
			validateReason(values.req_reason);
		}
		if (navContext.fieldStates['custom_reason']) {
			validateCustomReason(values.custom_reason);
		}
		if (navContext.fieldStates['repeat_end_date']) {
			validateEndDate(values.repeat_end_date);
		}
		if (navContext.fieldStates['repeats_monday'] || navContext.fieldStates['repeats_tuesday'] || navContext.fieldStates['repeats_wednesday'] || navContext.fieldStates['repeats_thursday'] || navContext.fieldStates['repeats_friday'] || navContext.fieldStates['repeats_saturday'] || navContext.fieldStates['repeats_sunday']) {
			//validateFrequency(values.repeats_monday, values.repeats_tuesday, values.repeats_wednesday, values.repeats_thursday, values.repeats_friday, values.repeats_saturday, values.repeats_sunday);
		}
	}, [navContext.fieldStates])

	const validateShiftDate = (date) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if date is valid
		if (date.length === 0 || !moment(date, 'M-D-YYYY', true).isValid()) {
			contextErrors['date'] = true;
			contextErrorMessages['date'] = 'Invalid date';
		} else {
			contextErrors['date'] = false;
			contextErrorMessages['date'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateShiftType = (shiftType) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		if(shiftType === "External Engagement"){
			setOnDemandReasons([
				{"value": "Day Program"},
				{"value": "Family Time"},
				{"value": "Work"},
				{"value": "School"},
				{"value": "Other Agency"},	
				{"value": "Custom"}
			]);
		} else {
			setOnDemandReasons([
				{"value": "Requested By Client"},
				{"value": "Delegated From Direct Support"},
				{"value": "Custom"}
			]);
		}

		//setValues({...values, "req_reason": ""});

		// check if shift_type is valid
		if (!shiftType || shiftType.length === 0) {
			contextErrors['shift_type'] = true;
			contextErrorMessages['shift_type'] = 'Shift type is required';
		} else {
			contextErrors['shift_type'] = false;
			contextErrorMessages['shift_type'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateStartTime = (startTime) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if start_time is valid
		if (!startTime || startTime.length === 0 || !moment(startTime, 'h:mm A', true).isValid()) {
			contextErrors['start_time'] = true;
			contextErrorMessages['start_time'] = 'Invalid time';
		} else {
			contextErrors['start_time'] = false;
			contextErrorMessages['start_time'] = '';
			if (values.end_time && moment(values.end_time, 'h:mm A', true).isValid() && moment(startTime, 'h:mm A', true).isAfter(moment(values.end_time, 'h:mm A', true))) {
				contextErrors['end_time'] = true;
				contextErrorMessages['end_time'] = 'End time must be after start time';
			} else {
				contextErrors['end_time'] = false;
				contextErrorMessages['end_time'] = '';
			}
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateEndTime = (endTime) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if end_time is valid
		if (!endTime || endTime.length === 0 || !moment(endTime, 'h:mm A', true).isValid()) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'Invalid time';
		} else if (values.start_time && moment(values.start_time, 'h:mm A', true).isValid() && moment(endTime, 'h:mm A', true).isBefore(moment(values.start_time, 'h:mm A', true)) && !isMidnight(moment(endTime, 'h:mm A', true))) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'End time must be after start time';
		} else {
			contextErrors['end_time'] = false;
			contextErrorMessages['end_time'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateReason = (reason) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if reason is valid
		if (!reason || reason.length === 0) {
			contextErrors['req_reason'] = true;
			contextErrorMessages['req_reason'] = 'Reason is required';
		} else {
			contextErrors['req_reason'] = false;
			contextErrorMessages['req_reason'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateCustomReason = (reason) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if reason is valid
		if (!reason || reason.length === 0) {
			contextErrors['custom_reason'] = true;
			contextErrorMessages['custom_reason'] = 'Custom reason is required';
		} else {
			contextErrors['custom_reason'] = false;
			contextErrorMessages['custom_reason'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateEndDate = (endDate) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if end_date is valid
		if (endDate.length === 0 || !moment(endDate, 'M-D-YYYY', true).isValid()) {
			contextErrors['repeat_end_date'] = true;
			contextErrorMessages['repeat_end_date'] = 'Invalid date';
		} else if (values.date && moment(values.date, 'M-D-YYYY', true).isValid() && moment(endDate, 'M-D-YYYY', true).isBefore(moment(values.date, 'M-D-YYYY', true))) {
			contextErrors['repeat_end_date'] = true;
			contextErrorMessages['repeat_end_date'] = 'Repeat end date must be after start date';
		} else {
			contextErrors['repeat_end_date'] = false;
			contextErrorMessages['repeat_end_date'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateWeeklyFrequency = (monday, tuesday, wednesday, thursday, friday, saturday, sunday) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if at least one day is selected
		if (!monday && !tuesday && !wednesday && !thursday && !friday && !saturday && !sunday) {
			contextErrors['frequency'] = true;
			contextErrorMessages['frequency'] = 'At least one day should be selected for repeat';
			// navContext.setErrors({ ...navContext.errors, frequency: true });
			// navContext.setErrorMessages({ ...navContext.errorMessages, frequency: 'At least one day should be selected for repeat' });
			
		} else {
			contextErrors['frequency'] = false;
			contextErrorMessages['frequency'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	// useLayoutEffect(() => {
	// 	navContext.setSideNavTitle("Add New Coverage");
	// 	navContext.setSideNavActions(true);
	// }, [navContext])

	// useLayoutEffect(() => {
	// 	if (props.day) console.log("Appointment Day", props.day)
	// }, [props.day])

	return <>
		<form className="tw-p-20px tw-space-y-15px">
			<div className="tw-space-y-10px">
				<Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
						{ props.title || 'Support Type'}
				</Label>
				<Select id="backupType" className={navContext.errors?.shift_type ? '_form-error': ''} 
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "shift_type": true})}
					onChange={ e => {
					console.log(e.target.value);
					setValues({...values, "shift_type": e.target.value});

					validateShiftType(e.target.value);

					// formik.setFieldValue('shift_type', e.target.value);
					// formik.setFieldTouched('shift_type', true, false);
					// formik.validateForm({ ...formik.values, ['shift_type']: e.target.value });
					// //formik.validateField('shift_type');
					
					// console.log(formik.values);
					// navContext?.setSideNavFormik(formik);
				}} ref={ reqShiftType } value={ values.shift_type }>
					<option value="">Select</option>
					{ clientServiceTypes.map((serviceType, index) => {
						return <option key={ index } value={ serviceType.shiftType }>{ serviceType.shiftType }</option>
					})
					}

					{/* <option value="With Back-up">With Back-up</option>
					<option value="Without Back-up">Without Back-up</option> */}
				</Select>
				{navContext.errors?.shift_type && <p className="tw-text-red-500">{navContext.errorMessages.shift_type}</p>}
				{/* <SupportType id="supportType" onChange={ e => {
					setValues({
						...values,
						"supportType": e.target.value
					})
				}} />
				<Select id="backupType" onChange={ e => {
					setValues({
						...values,
						[e.target.id]: e.target.value
					})
				}}>
					<option value="With Back-up">With Back-up</option>
					<option value="Without Back-up">Without Back-up</option>
				</Select> */}
			</div>
			<div>
			<div className="tw-space-y-20px">

				
					<CalendarSelector id="coverageDate" name="Coverage Date:" className={navContext.errors?.date ? '_form-error' : ''} 
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "date": true})}
					onChange={ e => {
												
												console.log(e.target.value);
												setValues({...values, "date": e.target.value});
												validateShiftDate(e.target.value);
					}}/>
					{navContext.errors?.date && <p className="tw-text-red-500">{navContext.errorMessages.date}</p>}
				<InputTimes id="startTime" value={values.start_time} label="Start Time" className={navContext.errors?.start_time ? '_form-error' : ''} increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "start_time": true})}
					onChange={ e => {
						if(removeWhitespace(e.target.value) !== removeWhitespace(values.start_time)) {
							console.log("Start Time", e.target.value);
							setValues({
								...values,
								"start_time": e.target.value
							});
							validateStartTime(e.target.value);
						}
					}}
				/>
				{navContext.errors?.start_time && <p className="tw-text-red-500">{navContext.errorMessages.start_time}</p>}
				<InputTimes id="endTime" value={values.end_time} label="End Time" className={navContext.errors?.end_time ? '_form-error' : ''} increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "end_time": true})}
					onChange={ e => {
						if(removeWhitespace(e.target.value) !== removeWhitespace(values.end_time)) {
							console.log("End Time", e.target.value);
							setValues({
								...values,
								"end_time": e.target.value
							});
							validateEndTime(e.target.value);
						}
					}}
				/>
				{navContext.errors?.end_time && <p className="tw-text-red-500">{navContext.errorMessages.end_time}</p>}
			</div>
			</div>
			<div className="-tw-mx-20px tw-px-20px">
					<FrequencyField id="frequency" className={navContext.errors?.frequency ? '_form-error':''} 
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "frequency": true})}
					onChange={ e => {
						var frequency = JSON.parse(e.target.value);
						console.log("Frequency", frequency);
						setValues({
							...values,
							"frequency": frequency.frequency == "" ? "Never" : frequency.frequency
						});
						setRepeatFrequency( frequency.frequency == "" ? "Never" : frequency.frequency );
						if(frequency.frequency == "Weekly"){
							setValues({
								...values,
								"frequency": frequency.frequency,
								"repeats_monday": frequency.Monday,
								"repeats_tuesday": frequency.Tuesday,
								"repeats_wednesday": frequency.Wednesday,
								"repeats_thursday": frequency.Thursday,
								"repeats_friday": frequency.Friday,
								"repeats_saturday": frequency.Saturday,
								"repeats_sunday": frequency.Sunday
							});
							validateWeeklyFrequency(frequency.Monday, frequency.Tuesday, frequency.Wednesday, frequency.Thursday, frequency.Friday, frequency.Saturday, frequency.Sunday);
						}
						console.log("Frequency", frequency);
					}}/>
					{navContext.errors?.frequency && <p className="tw-text-red-500">{navContext.errorMessages.frequency}</p>}
			</div>
			<div>
			{ repeatFrequency !== "Never" && 
				<div>
					<CalendarSelector id="repeatDate" name="Repeat End Date:" 
					className={navContext.errors?.repeat_end_date ? '_form-error' : ''}
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "repeat_end_date": true})}
					onChange={ e => {
												console.log(e.target.value);
												setValues({...values, "repeat_end_date": e.target.value});
												validateEndDate(e.target.value);
					}}/>
					{navContext.errors?.repeat_end_date && <p className="tw-text-red-500">{navContext.errorMessages.repeat_end_date}</p>}
				</div>}

				<Label htmlFor={ props.id ? `${props.id}_1` : null }>
						Reason
				</Label>	
				<Select id="reason" className={navContext.errors?.req_reason ? '_form-error': ''} 
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "req_reason": true})}
					onChange={ e => {
					console.log(e.target.value);
					setValues({...values, "req_reason": e.target.value});
					validateReason(e.target.value);

					// formik.setFieldValue('shift_type', e.target.value);
					// formik.setFieldTouched('shift_type', true, false);
					// formik.validateForm({ ...formik.values, ['shift_type']: e.target.value });
					// //formik.validateField('shift_type');
					
					// console.log(formik.values);
					// navContext?.setSideNavFormik(formik);
				}} ref={ reqReason } value={ values.req_reason }>
					<option value="">Select Reason</option>
					{ onDemandReasons?.map((reason, index) => {
						return <option key={ index } value={ reason.value }>{ reason.value }</option>
					})
					}
					{/* <option value="Requested By Client">Requested By Client</option>
					<option value="Delegated From Direct Support">Delegated From Direct Support</option>
					<option value="Custom">Custom</option> */}

					{/* <option value="With Back-up">With Back-up</option>
					<option value="Without Back-up">Without Back-up</option> */}
				</Select>
				{navContext.errors?.req_reason && <p className="tw-text-red-500">{navContext.errorMessages.req_reason}</p>}
				{values.req_reason === "Custom" && <>
					<Label htmlFor={ props.id ? `${props.id}_1` : null } className="tw-pt-20px">
							Comment
					</Label>
					<Textarea ref={ customRef } className={`${navContext.errors?.custom_reason ? '_form-error tw-text-5 tw-leading-normal ': 'tw-text-5 tw-leading-normal '} tw-w-full`} placeholder="Type your notes here" 
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "custom_reason": true})}
					onChange={ e => {
						setValues({...values, "custom_reason": e.target.value});
						validateCustomReason(e.target.value);
					}}>{ values["custom_reason"] }</Textarea>
					{navContext.errors?.custom_reason && <p className="tw-text-red-500">{navContext.errorMessages.custom_reason}</p>}
				</>} 
			</div>
				
			{/* <div>
				<Label htmlFor="repeatFrequency" icon="/images/icon-repeat.svg">
					Repeat
				</Label>
				<Select id="repeatFrequency" onChange={ e => {
					setRepeatFrequency( e.target.value === 'customize' )
				}}>
					<option value="">
						No Repeat
					</option>
					<option value="customize">
						Customize
					</option>
				</Select>
			</div>
			{ repeatFrequency && <>
				<div className="tw-bg-light-accent -tw-mx-20px tw-p-20px">
					<FrequencyField id="frequency" onChange={ e => {
						setValues({
							...values,
							[e.target.id]: JSON.parse(e.target.value)
						})
					}}/>
				</div>
			</> } */}
		</form>
	</>
}

export const SpanRequirmentSuccessPanel = props => {
	const navContext = useAppContext();
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [isUpdating, setIsUpdating] = useState<boolean>(false);
	const [isCanceling, setIsCanceling] = useState<boolean>(false);
	const [title, setTitle] = useState<string>('Saved coverage information');

	const [shift, setShift] = useState<CoverageRequirement>(props.shift || null);

	useLayoutEffect(() => {
		if (props.saving) {
			setIsSaving(true);
			setTitle('Saved new span coverage requirement');
		}
		if (props.updating){
			setIsUpdating(true);
			setTitle('Updated span coverage requirenment');
		} 
		if (props.canceling){
			setIsCanceling(true);
			setTitle('Canceled span coverage requirement');
		}
	}, [props.newRequirement, props.newRecurringRequirement, props.updating])

	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex tw-flex-col tw-flex-grow tw-w-full tw-overflow-x-hidden tw-overflow-y-aut">
				<div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow ">
					<div className="tw-w-full tw-space-y-15px">
						<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]">
							<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
								<span className="tw-text-dark tw-ml-5px">
									{title}
								</span>
							</span>
						</h3>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Support Type
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{ shift?.shiftType }
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Start Time
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.displayStartTime}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										End Time
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.displayEndTime}
								</span>
							</li>
							{isSaving && <li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Occurs every
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{props?.frequency.repeats_sunday ? 'Sunday, ' : ''} {props?.frequency.repeats_monday ? 'Monday, ' : ''} {props?.frequency.repeats_tuesday ? 'Tuesday, ' : ''} {props?.frequency.repeats_wednesday ? 'Wednesday, ' : ''} {props?.frequency.repeats_thursday ? 'Thursday, ' : ''} {props?.frequency.repeats_friday ? 'Friday, ' : ''} {props?.frequency.repeats_saturday ? 'Saturday, ' : ''}
								</span>
							</li>}
						</ul>
					</div>
					<div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-my-auto tw-py-20px">
						<div className="tw-space-y-15px tw-text-center">
							<LabelOutputs className="tw-w-full tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
								<span className="tw-text-5">
									Saved to span requirement calendar. Remember to click save on the calendar to update weekly schedule.
								</span>
							</LabelOutputs>
							<ButtonSecondary className="tw-w-200px" onClick={ e => {
								e.preventDefault();
								navContext.closeSideNav();
							}}>
								Close
							</ButtonSecondary>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export const SpanRequirementCancelPanel = props => {
	const navContext = useAppContext();
	const [toggleRequiredInformation, setToggleRequiredInformation] = useState<boolean>(true);
	const [shift, setShift] = useState<SpanCoverageEvent>(props.shift || null);
	const [loading, setLoading] = useState<boolean>(false);
	const [reqData, setReqData] = useState<any>(props.data);

	useLayoutEffect(() => {
		if (props.shift) setShift(props.shift)
	}, [props.shift])

	const handleCancel = () => {
		console.log("handleCancel");
		console.log(shift);
		console.log(reqData);
		console.log(props);
		switch(shift.dayOfWeek) {
			case 0:
				let sundayRequirements = [...reqData.sundayRequirements];
				var RequirementIndex = sundayRequirements.findIndex(req => req.spanCoverageEventID === shift.spanCoverageEventID);
				// remove requirement from array
				sundayRequirements.splice(RequirementIndex, 1);
				console.log(sundayRequirements);
				reqData.setSundayRequirements(sundayRequirements);
				break;
			case 1:
				let mondayRequirements = [...reqData.mondayRequirements];
				var RequirementIndex = mondayRequirements.findIndex(req => req.spanCoverageEventID === shift?.spanCoverageEventID);
				// remove requirement from array
				mondayRequirements.splice(RequirementIndex, 1);
				console.log(mondayRequirements);
				reqData.setMondayRequirements(mondayRequirements);
				break;
			case 2:
				let tuesdayRequirements = [...reqData.tuesdayRequirements];
				var RequirementIndex = tuesdayRequirements.findIndex(req => req.spanCoverageEventID === shift?.spanCoverageEventID);
				// remove requirement from array
				tuesdayRequirements.splice(RequirementIndex, 1);
				console.log(tuesdayRequirements);
				reqData.setTuesdayRequirements(tuesdayRequirements);
				break;
			case 3:
				let wednesdayRequirements = [...reqData.wednesdayRequirements];
				var RequirementIndex = wednesdayRequirements.findIndex(req => req.spanCoverageEventID === shift?.spanCoverageEventID);
				// remove requirement from array
				wednesdayRequirements.splice(RequirementIndex, 1);
				console.log(wednesdayRequirements);
				reqData.setWednesdayRequirements(wednesdayRequirements);
				break;
			case 4:
				let thursdayRequirements = [...reqData.thursdayRequirements];
				var RequirementIndex = thursdayRequirements.findIndex(req => req.spanCoverageEventID === shift?.spanCoverageEventID);
				// remove requirement from array
				thursdayRequirements.splice(RequirementIndex, 1);
				console.log(thursdayRequirements);
				reqData.setThursdayRequirements(thursdayRequirements);
				break;
			case 5:
				let fridayRequirements = [...reqData.fridayRequirements];
				var RequirementIndex = fridayRequirements.findIndex(req => req.spanCoverageEventID === shift?.spanCoverageEventID);
				// remove requirement from array
				fridayRequirements.splice(RequirementIndex, 1);
				console.log(fridayRequirements);
				reqData.setFridayRequirements(fridayRequirements);
				break;
			case 6:
				let saturdayRequirements = [...reqData.saturdayRequirements];
				var RequirementIndex = saturdayRequirements.findIndex(req => req.spanCoverageEventID === shift?.spanCoverageEventID);
				// remove requirement from array
				saturdayRequirements.splice(RequirementIndex, 1);
				console.log(saturdayRequirements);
				reqData.setSaturdayRequirements(saturdayRequirements);
				break;
		}

		reqData.setRequirementUpdated(true);

		if (!navContext.showErrorModal) {
			navContext.setSideNavTitle("Success");
			navContext.setSideNav( <SpanRequirmentSuccessPanel canceling shift={shift}/> );
			navContext.setSideNavActions(null);
			navContext.setSideNavBottom(null);

		}
		
	}

	const mapDay = (day: number) => {
		const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	  
		if(day < 0 || day > 6) {
		  throw new Error('Invalid day number');
		}
	  
		return days[day];
	}

	return (<>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex-shrink-0 tw-w-full tw-overflow-x-hidden tw-overflow-y-auto">
				<div className="tw-p-20px tw-space-y-20px tw-w-full">

					<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]" onClick={ e => {
						let temp = toggleRequiredInformation;
						setToggleRequiredInformation( temp = !temp )
					}}>
						<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
							<span className="tw-flex-grow">
							<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
								<span className="tw-text-dark tw-ml-5px">Information</span>
							</CircleLabel>
							</span>
							<span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
								<img className="tw-w-full" src={`/images/icon-arrow-${ toggleRequiredInformation ? 'down-2' : 'up'}.svg`} alt="Icon" />
							</span>
						</span>
					</h3>
					{ toggleRequiredInformation ? <>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Support Type
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.shiftType}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Time Period
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{shift?.displayStartTime} - {shift?.displayEndTime}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-180px tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" icon="/images/icon-repeat.svg" className="tw-opacity-70">
									Day of Week
								</LabelOutputs>
							</span>
							<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
								{mapDay(shift?.dayOfWeek)}
							</span>
						</li>
						</ul>
					</> : <></> }
				</div>
			</div>
				<div className="tw-pl-20px tw-pr-40px tw-space-y-20px">
					{/* <div>
						<CancelType id="reason" onChange={ e => {
							setValues({
								...values,
								"reason": e.target.value
							})
						}} />
						<fieldset className="tw-w-full tw-pt-10px">
							<Textarea id="reasonDetail" className="!tw-w-[calc(100%-2.5rem)]" placeholder="Type your reason for changing here (Optional)" onChange={ e => {
								setValues({
									...values,
									"reasonDetail": e.target.value
								})
							}}>
							</Textarea>
						</fieldset>
					</div> */}
					{loading ? <>
						<div className="tw-flex tw-justify-center tw-mx-4">
							<LoadingSpinner />
						</div>
					</>
					:
					<div>
						<h3 className="tw-font-primary-bold tw-font-5 tw-text-dark tw-py-20px">
							Are you sure about cancelling <Span>this span coverage requirement?</Span>
						</h3>
						<div className="tw-flex tw-justify-between tw-space-x-60px">
							<ButtonSecondary className="tw-basis-1/2" onClick={ e => {
								e.preventDefault();
								navContext.setSideNavTheme(3);
								navContext.setPanelSize('tw-max-w-400px');
								navContext.setSideNav( <CoverDetailsPanel requirement={shift} serviceTypes={props?.serviceTypes || null} data={reqData}/> );
								navContext.openSideNav();
								navContext.setSideNavBottom(null);
							}}>
								No
							</ButtonSecondary>
							<ButtonPrimary className="tw-basis-1/2" onClick={handleCancel}>
								Yes, Cancel
							</ButtonPrimary>
						</div>
					</div>}
				</div>
			
		</div>
	</>);
}

export default SchedulePanel;
