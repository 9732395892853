
export const IsRemoteSupport = (billingCode: string): boolean => {
    // Add your logic for remote support here
    var RSBillingCodes = [
    "AMR",
    "SMR",
    "FMR",
    "AMS",
    "SMS",
    "FMS",
    "RMS",
    "SVM"
    ];

    return RSBillingCodes.includes(billingCode);
}

export const IsDirectSupport = (billingCode: string): boolean => {
    // Add your logic for direct support here

    var DSBillingCodes = [
        "APC",
		"AMW",
		"AOC",
		"FPC",
		"FOC",
		"FMW",
		"SDC"
    ];

    return DSBillingCodes.includes(billingCode);
}

export const IsRemoteSupportWithBackup = (billingCode: string): boolean => {
    // Add your logic for remote support with backup here
    var RSBillingCodes = [
    "AMR",
    "SMR",
    "FMR",
    "SVM"
    ];

    return RSBillingCodes.includes(billingCode);
}

export const IsRemoteSupportWithoutBackup = (billingCode: string): boolean => {
    // Add your logic for remote support without backup here
    var RSBillingCodes = [
    "AMS",
    "SMS",
    "FMS",
    "RMS"
    ];

    return RSBillingCodes.includes(billingCode);
}

export const IsOvernightSupport = (billingCode: string): boolean => {
    // Add your logic for overnight support here
    var RSBillingCodes = [
        "AOC",
        "FOC"
    ];

    return RSBillingCodes.includes(billingCode);
}

export const IsDayDirectSupport = (billingCode: string): boolean => {
    // Add your logic for day support here
    var RSBillingCodes = [
        "APC",
		"AMW",
		"FPC",
		"FMW",
		"SDC"
    ];

    return RSBillingCodes.includes(billingCode);
}

export const IsTraining = (billingCode: string): boolean => {
    // Add your logic for training here
    var RSBillingCodes = [
        "NBC"
    ];

    return RSBillingCodes.includes(billingCode);
}