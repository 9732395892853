import { Alert, CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { populateAgencySchedule, populateLegacyScheduleForPatient } from "../apicalls/schedulingService";
import { ButtonError, ButtonPrimary, ButtonSuccess } from "../components/buttons/Buttons";
import { TimeCalendar } from "../components/containers/Calendars";
import ServicePanel from "../components/panels/Service";
import { PageHeader } from "../components/typography/Headers";
import { Ellipsis } from "../components/utilities/Helpers";
import { useAppContext } from "../Context";
import Core from "../templates/Core";
import { rem } from "../utilities/helpers";

const SessionSchedulePage = props => {
    let navigate = useNavigate();

    const goToSessionForm = () => {
		navigate("/schedule/session-schedule/new");
	};

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { weekStart, weekEnd } = useParams();
    const [weekLoaded, setWeekLoaded] = useState(false);
    const [showAutoAssignConfirm, setShowAutoAssignConfirm] = useState(false);

    const navContext = useAppContext();
    
    useLayoutEffect(() => {
        console.log("Week Start");
        console.log(weekStart);
        console.log("Week end");
        console.log(weekEnd);

        if(weekStart === undefined || weekEnd === undefined){
            setStartDate(moment().startOf('week'));
            setEndDate(moment().startOf('week').add(6, 'd'));
        } else {
            setStartDate(moment(weekStart, "MM-DD-YYYY"));
            setEndDate(moment(weekEnd, "MM-DD-YYYY"));
        }

        setWeekLoaded(true);
        
    },[])

    const [showRightPanel, setShowRightPanel] = useState<boolean>(false);
	const [servicePanelData, setServicePanelData] = useState<any>({'type': 2});
	//const toggleShowRightPanel = () => setShowRightPanel(showRightPanel == false || true);

    const SideNavContent2 = props => {
        return <b>
            another one
        </b>
    }


    //const [selectedSession, setSelectedSession] = useState<any>();

    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

    const [showPopulateSuccess, setShowPopulateSuccess] = useState(false);
    const [populating, setPopulating] = useState(false);

    const populateSchedule = async () => {
        setPopulating(true);

        setShowAutoAssignConfirm(false);

        const result = await populateAgencySchedule(navContext.handleAPICallError);

        setPopulating(false);

        setShowPopulateSuccess(true);
    }

    const confirmAutoAssign = async () => {
        setShowAutoAssignConfirm(true);
    }

    const cancelAutoAssign = async () => {
        setShowAutoAssignConfirm(false);
    }

    const closeAlert = async () => {
        setShowPopulateSuccess(false);
        window.location.reload();
    }

    const showSession = true;

    const logout = props?.handleLogout

    const toggleShowRightPanel = (sessionByID) => {
        console.log("NAv Context Side Nav");

        const selectedSession = sessionByID;

        const panelProps = {
            servicePanelData, setServicePanelData,
            selectedSession,
            showSession,
            startDate, endDate,
        }

        console.log(panelProps);
        navContext.setSideNav( <ServicePanel { ...panelProps } />);
		navContext.setSideNavTheme(2);
		navContext.setSideNavTitle("Session Panel");
        navContext.setPanelSize('tw-max-w-800px');
		navContext.openSideNav();

        console.log(navContext);
    }

    const parentProps = {
		showRightPanel, setShowRightPanel,
		servicePanelData, setServicePanelData,
		logout, showSession,
        startDate, endDate,
	}

    return <>
        {/* <Core { ...parentProps }> */}
            <div className="tw-px-xl tw-pt-xl">
                {showPopulateSuccess ? <>
                    <div className="tw-pb-sm tw-px-xl">
                        <Alert onClose={closeAlert}>Session Populate has been scheduled to run!</Alert>
                    </div>
                </>:<></> }
                    
                    <div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
                        {/* <div className="tw-flex-grow tw-flex-shrink-0">
                            <PageHeader>
                                <Ellipsis>
                                    RSA Calendar
                                </Ellipsis>
                            </PageHeader>
                        </div> */}
                        <div className="tw-pb-5">
                            <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Session Calendar</h2>
                        </div>
                        
                        {navContext?.sessionScheduleAdmin && <div className="tw-flex tw-items-center tw-justify-end tw-flex-grow tw-flex-shrink-0">
						
                            { showAutoAssignConfirm ? <></>:<ul className="tw-list-none tw-flex tw-space-x-md">
                                <li>
                                    <ButtonPrimary iconNotRounded onClick={goToSessionForm} svg={`<svg width="${rem('24')}" height="${rem('24')}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" fill="currentColor"/></svg>`}>
                                        Session
                                    </ButtonPrimary>
                                </li>
                                {/* <li>
                                    <ButtonPrimary iconNotRounded onClick={ e => {
                                        //  navContext.setSideNav( <ServicePanel /> );
                                        //  navContext.setSideNavTheme(1);
                                        //  navContext.setSideNavTitle("Service Panel");
                                        //  navContext.openSideNav();
                                        navContext.setName({ firstName: "Kevin", lastName: "Mack" });
                                        alert("test");
                                        console.log(navContext);
                                        e.preventDefault();

                                        }} svg={`<svg width="${rem('24')}" height="${rem('24')}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" fill="currentColor"/></svg>`}>
                                        Test
                                    </ButtonPrimary>
                                </li> */}
                                <li>
                                        {populating ? 
                                        <>
                                        <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                                        </>: <>
                                        <ButtonPrimary small onClick={ e => {
                                            
                                                confirmAutoAssign();
                                                e.preventDefault();
                                            }} iconNotRounded svg={`<svg width="${rem('24')}" height="${rem('24')}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM12 7C13.66 7 15 8.34 15 10C15 11.66 13.66 13 12 13C10.34 13 9 11.66 9 10C9 8.34 10.34 7 12 7ZM18 19H6V17.6C6 15.6 10 14.5 12 14.5C14 14.5 18 15.6 18 17.6V19Z" fill="currentColor"/></svg>`}>
                                        Auto-Assign
                                        </ButtonPrimary>
                                        </>}
                                        
                                </li>
                            </ul>}
					    </div>}
                    
                    
                    </div>

                    { showAutoAssignConfirm ? <div className="tw-bg-white tw-shadow sm:tw-rounded-lg">
                            <div className="tw-px-4 tw-py-5 sm:tw-p-6">
                                <div className="sm:tw-flex sm:tw-items-start sm:tw-justify-between">
                                <div className="tw-flex-grow">
                                    <h3 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Run Auto Assign?</h3>
                                    <div className="tw-mt-2 tw-text-sm tw-text-gray-500">
                                    <p>
                                        Running auto assign will ensure all client requirements for today and tomorrow are assigned to available sessions. Additionally, any requirements that start 6 hours from now will be assigned again to reduce unwanted splits. Do you want to continue?
                                    </p>
                                    </div>
                                </div>
                                <div className="tw-mt-2 sm:tw-ml-6 tw-flex tw-flex-col sm:tw-flex-shrink-0 sm:tw-items-center">
                                    <ButtonSuccess onClick={populateSchedule}>Run</ButtonSuccess>
                                    <ButtonError className="tw-mt-2" onClick={cancelAutoAssign}>Cancel</ButtonError>
                                    {/* <button
                                    type="button"
                                    className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-indigo-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                                    >
                                    Change plan
                                    </button> */}
                                </div>
                                </div>
                            </div>
                        </div>: <></>}
            </div>
            { weekLoaded ? <>
            <div className="tw-px-xl tw-pb-xl tw-text-dark">
                <div className="tw-px-xl">
                    <div>
						<TimeCalendar { ...parentProps } />
					</div>
                </div>
            </div>
            </>:<></> }
            
        {/* </Core> */}
    </>;
}

export default SessionSchedulePage