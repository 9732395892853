import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../Context";
import { InputTimes, Label, Select, Textarea } from "../forms/formFields";
import CalendarSelector from "../calendars/CalendarSelector";
import { removeWhitespace } from "./SchedulePanel";
import moment from "moment";
import { ClientScheduleNotice } from "../../types/clientScheduleNotice";
import { LabelOutputs } from "../utilities/Helpers";
import { ButtonSecondary } from "../buttons/Buttons";

const ScheduleNoticePanel = props => {
    const navContext = useAppContext();
    const [values, setValues] = useState<any>(navContext.values);

    const noticeType = useRef();
    const commentRef = useRef(null);

    useEffect(() => {
		console.log(navContext.fieldStates);

        if (navContext.fieldStates.notice_type) {
            validateNoticeType(values.notice_type);
        }

        if (navContext.fieldStates.start_date) {
            validateStartDate(values.start_date);
        }

        if (navContext.fieldStates.start_time) {
            validateStartTime(values.start_time);
        }

        if (navContext.fieldStates.end_date) {
            validateEndDate(values.end_date);
        }

        if (navContext.fieldStates.end_time) {
            validateEndTime(values.end_time);
        }

        if (navContext.fieldStates.comment) {
            validateNoticeComment(values.comment);
        }
    }, [navContext.fieldStates]);

	useEffect(() => {
		console.log(navContext.values);
		navContext.setValues(values);
		console.log("Client Schedule Notice Panel Values", values);
	}, [values])

	useEffect(() => {
		console.log("values", values);
		console.log("errors", navContext.errors);
		console.log("errorMessages", navContext.errorMessages);
	}, [navContext.errors, navContext.errorMessages]);

    const validateStartDate = (date) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if date is valid
		if (date.length === 0 || !moment(date, 'M-D-YYYY', true).isValid()) {
			contextErrors['start_date'] = true;
			contextErrorMessages['start_date'] = 'Invalid date';
		} else {
			contextErrors['start_date'] = false;
			contextErrorMessages['start_date'] = '';
			if(values.end_date && values.end_date.length > 0) {
				if(moment(date, 'M-D-YYYY').isBefore(moment(values.end_date, 'M-D-YYYY'))) {
					contextErrors['end_date'] = false;
					contextErrorMessages['end_date'] = '';
				}
			}
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

    const validateEndDate = (date) => {
		// console.log("validateEndDate", date);
		// console.log(moment(date, 'M-D-YYYY').isBefore(moment(values.start_date, 'M-D-YYYY')));
        var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
        var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

        // check if date is valid
        if (date.length === 0 || !moment(date, 'M-D-YYYY', true).isValid()) {

            contextErrors['end_date'] = true;
            contextErrorMessages['end_date'] = 'Invalid date';
        } else if (moment(date, 'M-D-YYYY').isBefore(moment(values.start_date, 'M-D-YYYY'))) {
            contextErrors['end_date'] = true;
            contextErrorMessages['end_date'] = 'End date must be after start date';
        } else {

			//console.log(moment(date, 'M-D-YYYY').isBefore(moment(values.start_date, 'M-D-YYYY')));
            contextErrors['end_date'] = false;
            contextErrorMessages['end_date'] = '';
        }

        navContext.setErrors(contextErrors);
        navContext.setErrorMessages(contextErrorMessages);
    }

	const validateStartTime = (time) => {
		console.log("validateStartTime", time);
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if time is valid
		if (!time || time.length === 0 || !moment(time, 'h:mm A', true).isValid()) {
			console.log("Invalid time");
			contextErrors['start_time'] = true;
			contextErrorMessages['start_time'] = 'Invalid time';
		} else {
			contextErrors['start_time'] = false;
			contextErrorMessages['start_time'] = '';
		}

		console.log("validateStartTime", contextErrors, contextErrorMessages);

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateEndTime = (time) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if time is valid
		if (!time || time.length === 0 || !moment(time, 'h:mm A', true).isValid()) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'Invalid time';
		} else if (moment(values.end_date + " " + time, 'M-D-YYYY h:mm A').isBefore(moment(values.start_date + " " +  values.start_time, 'M-D-YYYY h:mm A'))) {
			contextErrors['end_time'] = true;
			contextErrorMessages['end_time'] = 'End time must be after start time';
		} else {
			contextErrors['end_time'] = false;
			contextErrorMessages['end_time'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateNoticeType = (type) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if type is valid
		if (!type || type.length === 0) {
			contextErrors['notice_type'] = true;
			contextErrorMessages['notice_type'] = 'Notice type is required';
		} else {
			contextErrors['notice_type'] = false;
			contextErrorMessages['notice_type'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}

	const validateNoticeComment = (comment) => {
		var contextErrors = JSON.parse(JSON.stringify(navContext.errors));
		var contextErrorMessages = JSON.parse(JSON.stringify(navContext.errorMessages));

		// check if comment is valid
		if (!comment || comment.length === 0) {
			contextErrors['comment'] = true;
			contextErrorMessages['comment'] = 'Comment is required';
		} else {
			contextErrors['comment'] = false;
			contextErrorMessages['comment'] = '';
		}

		navContext.setErrors(contextErrors);
		navContext.setErrorMessages(contextErrorMessages);
	}


    return (<>
        <form className="tw-p-20px tw-space-y-15px">
            <div className="tw-space-y-10px">
				<Label htmlFor={ props.id ? `${props.id}_1` : null } icon="/images/icon-hash.svg">
						{ props.title || 'Notice Type'}
				</Label>
				<Select id="backupType" className={navContext.errors?.notice_type ? '_form-error': ''} 
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "notice_type": true})}
					onChange={ e => {
					console.log(e.target.value);
					setValues({...values, "notice_type": e.target.value});

					validateNoticeType(e.target.value);
				}} ref={ noticeType } value={ values.shift_type }>
					<option value="">Select</option>
                    <option value="Vacation">Vacation</option>
                    <option value="Hospitalization">Hospitalization</option>
                    <option value="Other">Other</option>
				</Select>
				{navContext.errors?.notice_type && <p className="tw-text-red-500">{navContext.errorMessages.notice_type}</p>}

			</div>

            <div className="tw-space-y-20px">

				
				<CalendarSelector id="noticeStartDate" name="Notice Start Date:" className={navContext.errors?.start_date ? '_form-error' : ''} 
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "start_date": true})}
					onChange={ e => {
												
												console.log(e.target.value);
												setValues({...values, "start_date": e.target.value});
												validateStartDate(e.target.value);
					}}/>
					{navContext.errors?.start_date && <p className="tw-text-red-500">{navContext.errorMessages.start_date}</p>}
				<InputTimes id="startTime" value={values.start_time} label="Start Time" className={navContext.errors?.start_time ? '_form-error' : ''} increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "start_time": true})}
					onChange={ e => {
						if(removeWhitespace(e.target.value) !== removeWhitespace(values.start_time)) {
							console.log("Start Time", e.target.value);
							setValues({
								...values,
								"start_time": e.target.value
							});
							validateStartTime(e.target.value);
						}
					}}
				/>
				{navContext.errors?.start_time && <p className="tw-text-red-500">{navContext.errorMessages.start_time}</p>}
				<CalendarSelector id="noticeEndDate" name="Notice End Date:" className={navContext.errors?.end_date ? '_form-error' : ''} 
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "end_date": true})}
					onChange={ e => {
												
												console.log(e.target.value);
												setValues({...values, "end_date": e.target.value});
												validateEndDate(e.target.value);
					}}/>
					{navContext.errors?.end_date && <p className="tw-text-red-500">{navContext.errorMessages.end_date}</p>}
                <InputTimes id="endTime" value={values.end_time} label="End Time" className={navContext.errors?.end_time ? '_form-error' : ''} increments="1" min="0" max="12" placeholder="00" min2="0" max2="60" placeholder2="00" increments2="15"
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "end_time": true})}
					onChange={ e => {
						if(removeWhitespace(e.target.value) !== removeWhitespace(values.end_time)) {
							console.log("End Time", e.target.value);
							setValues({
								...values,
								"end_time": e.target.value
							});
							validateEndTime(e.target.value);
						}
					}}
				/>
				{navContext.errors?.end_time && <p className="tw-text-red-500">{navContext.errorMessages.end_time}</p>}
			</div>
            <div>
                <Label htmlFor={ props.id ? `${props.id}_1` : null } className="tw-pt-20px">
							Comment
					</Label>
					<Textarea ref={ commentRef } className={`${navContext.errors?.comment ? '_form-error tw-text-5 tw-leading-normal ': 'tw-text-5 tw-leading-normal '} tw-w-full`} placeholder="Type your notes here" 
					onBlur={() => navContext.setFieldStates({...navContext.fieldStates, "comment": true})}
					onChange={ e => {
						setValues({...values, "comment": e.target.value});
						validateNoticeComment(e.target.value);
					}}>{ values["comment"] }</Textarea>
					{navContext.errors?.comment && <p className="tw-text-red-500">{navContext.errorMessages.comment}</p>}
            </div>
        </form>
    </>);
}

export const ClientScheduleNoticePanelSuccess = props => {
	const navContext = useAppContext();
	const [title, setTitle] = useState<string>('Saved notice information');

	const [notice, setNotice] = useState<ClientScheduleNotice>(props.notice || null);

	// useLayoutEffect(() => {
	// 	if (props.newRequirement) {
	// 		setIsNewRequirement(true);
	// 		setTitle('Saved new coverage requirement');
	// 	}
	// 	if (props.newRecurringRequirement) {
	// 		setIsNewRecurringRequirement(true);
	// 		setTitle('Saved new recurring coverage requirement');
	// 	}
	// 	if (props.updating){
	// 		setIsUpdating(true);
	// 		setTitle('Updated coverage information');
	// 	} 
	// 	if (props.canceling){
	// 		setIsCanceling(true);
	// 		setTitle('Canceled coverage requirement');
	// 	}
	// }, [props.newRequirement, props.newRecurringRequirement, props.updating])

	return <>
		<div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex tw-flex-col tw-flex-grow tw-w-full tw-overflow-x-hidden tw-overflow-y-aut">
				<div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow ">
					<div className="tw-w-full tw-space-y-15px">
						<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]">
							<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
								<span className="tw-text-dark tw-ml-5px">
									{title}
								</span>
							</span>
						</h3>
						<ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Notice Type
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{ notice?.noticeType }
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										Start Date
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{ notice?.displayStartDate}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										Start Time
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{notice?.displayStartTime}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-date.svg" className="tw-opacity-70">
										End Date
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{ notice?.displayEndDate}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-time.svg" className="tw-opacity-70">
										End Time
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{notice?.displayEndTime}
								</span>
							</li>
							<li className="tw-flex tw-space-x-5px tw-items-center">
								<span className="tw-w-180px tw-flex tw-items-center">
									<LabelOutputs space="tw-space-x-15px" icon="/images/icon-hash.svg" className="tw-opacity-70">
										Details
									</LabelOutputs>
								</span>
								<span className="tw-flex-grow tw-flex tw-items-center tw-justify-end tw-text-black tw-pr-20px">
									{ notice?.details }
								</span>
							</li>
						</ul>
					</div>
					<div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-my-auto tw-py-20px">
						<div className="tw-space-y-15px tw-text-center">
							<LabelOutputs className="tw-w-200px tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
								<span className="tw-text-5">
									Saved Successfully
								</span>
							</LabelOutputs>
							<ButtonSecondary className="tw-w-200px" onClick={ e => {
								e.preventDefault();
								navContext.closeSideNav();
							}}>
								Go Back to schedule
							</ButtonSecondary>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default ScheduleNoticePanel;