import { AppProps } from "../App";
import { ButtonPrimary } from "../components/buttons/Buttons";
import { PageHeader, PageSubHeader, Paragraph2 } from "../components/typography/Headers";
import IntrinsicRatio from "../components/utilities/IntrinsicRatio";
import { Block } from "../components/utilities/Span";
import { useAppContext } from "../Context";

const Home = ({ pca }: AppProps) => {
	const navContext = useAppContext();

    const handleLogin = () => {
		console.log("Login Called");
        pca.handleRedirectPromise().then(authResult=>{
          // Check if user signed in 
          const account = pca.getActiveAccount();
		  console.log(account);
          if(!account){
            // redirect anonymous user to login page 
            pca.loginRedirect();
          }
        }).catch(err=>{
          // TODO: Handle errors
          console.log(err);
        });



        //pca.loginRedirect(loginRequest);
    }

	return (<>

		<div className="o-global-container t-dashboard tw-h-screen tw-overflow-hidden || tw-bg-light-accent">
			<div className="c-page tw-h-screen tw-overflow-hidden tw-flex">
				<div className="c-page__main tw-h-screen tw-overflow-x-hidden tw-overflow-y-auto || tw-flex || tw-flex-grow tw-flex-shrink-0">
					<div className="c-page__main-inner tw-h-screen tw-flex-grow tw-flex-shrink-0 || tw-w-full tw-max-w-1440px tw-mx-auto tw-flex">
						<div className="tw-flex tw-flex-grow">
							<div className="tw-flex tw-w-[44.44%] tw-overflow-y-auto tw-overflow-x-hidden tw-text-dark">
								<div className="tw-flex tw-flex-col tw-py-28 tw-px-24 tw-flex-grow">
									<div className="tw-w-full tw-max-w-[13.313rem]">
										<img alt="Powered by MedForAll" className="tw-w-full tw-block" src="/images/logo_powered-by.svg" />
									</div>

									<div className="tw-pt-12 tw-pb-12 tw-my-auto">
										<PageHeader tag="h1">
											<PageSubHeader tag="span" className="tw-block">Welcome to</PageSubHeader>
											<span className="tw-text-[1.333em] tw-block tw-pt-3 tw-pb-12">
												Ohio-At-Home <Block>Operations Portal</Block>
											</span>
										</PageHeader>

										<ButtonPrimary  onClick={handleLogin} className="tw-block tw-w-full tw-max-w-200px">
											Sign In
										</ButtonPrimary>
									</div>

									<Paragraph2 className="tw-text-dark-light tw-p-sm">
										&copy;2024 Medforall. All rights reserved.
									</Paragraph2>

								</div>
							</div>
							<div className="tw-bg-white tw-flex-grow tw-flex tw-items-center tw-overflow-y-auto tw-overflow-x-hidden">
								<IntrinsicRatio tag="div" className="c-entry-image tw-w-full tw-bg-red" src="/images/screen_entry_partnership-login_00_01.png" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<style  dangerouslySetInnerHTML={{__html: `
			.c-entry-image  {
				--aspect-ratio: .78125;
			}
		`}} />
	</>);
};

export default Home;
