import { PhoneIcon } from '@heroicons/react/24/outline';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import React from 'react';

interface TimeOffRequest {
  reason: string;
  date: string;
}

interface DetailedProviderCardProps {
  name: string;
  providerID: string;
  providerMail: string;
  providerPhone: string;
  serviceDate: string;
  profilePictureUrl?: string;
  availability: string;
  timeOffRequests: string;
  availableHoursServiceDate: string;
  scheduledHoursServiceDate: string;
  availableHoursPreviousDate: string;
  scheduledHoursPreviousDate: string;
  availableHoursUpcomingDate: string;
  scheduledHoursUpcomingDate: string;
  hideDetails: (providerID: string) => void;
}

const DetailedProviderCard: React.FC<DetailedProviderCardProps> = ({
  name,
  providerID,
  providerMail,
  providerPhone,
  serviceDate,
  profilePictureUrl,
  availability,
  timeOffRequests,
  availableHoursServiceDate,
  scheduledHoursServiceDate,
  availableHoursPreviousDate,
  scheduledHoursPreviousDate,
  availableHoursUpcomingDate,
  scheduledHoursUpcomingDate,
  hideDetails,
}) => {
      const handleEmailButtonClick = () => {
        // Encode the subject and body to ensure special characters are handled correctly
        const subject = encodeURIComponent("Help Needed on " + moment(serviceDate).format("MM/DD/YYYY"));

        // Construct the mailto link
        const mailtoLink = `mailto:${providerMail}?subject=${subject}`;

        // Open the default email client
        window.location.href = mailtoLink;
      };

      const handlePhoneButtonClick = () => {
        // Construct the tel link
        const telLink = `tel:${providerPhone}`;
    
        // Initiate the phone call
        window.location.href = telLink;
      };

    const getBackgroundColor = () => {
        switch (availability) {
          case 'Available':
            return 'tw-bg-green-200';
          case 'Possibly Available':
            return 'tw-bg-yellow-200';
          case 'Not Available':
            return 'tw-bg-red-200';
          default:
            return 'tw-bg-gray-200';
        }
      };

      const getTopBorderColor = () => {
        switch (availability) {
          case 'Available':
            return 'tw-border-t-4 tw-border-green-500';
          case 'Possibly Available':
            return 'tw-border-t-4 tw-border-yellow-500';
          case 'Not Available':
            return 'tw-border-t-4 tw-border-red-500';
          default:
            return 'tw-border-t-4 tw-border-gray-200';
        }
      }
    
      const getInitials = (name: string) => {
        return name.split(' ').map(n => n[0]).join('');
      };

  return (
    <div className={`${getTopBorderColor()} tw-bg-gray-100 tw-p-4 tw-m-2 tw-col-span-3`}>
       
       
       <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
        <div className="tw-flex tw-items-center">
          {profilePictureUrl ? (
            <img src={profilePictureUrl} alt="Profile" className="tw-h-16 tw-w-16 tw-rounded-full tw-mr-4" />
          ) : (
            <div className="tw-h-16 tw-w-16 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-300 tw-text-xl tw-mr-4">
              <span>{getInitials(name)}</span>
            </div>
          )}
          <h2 className="tw-text-2xl tw-font-bold">{name}</h2>

          <EnvelopeIcon className="tw-h-6 tw-w-6 tw-ml-4 tw-mr-2 tw-cursor-pointer" onClick={handleEmailButtonClick}/>

          <PhoneIcon className="tw-h-6 tw-w-6 tw-mx-2 tw-cursor-pointer" onClick={handlePhoneButtonClick}/>

          <h3 className="tw-text-lg">{providerPhone}</h3>
        </div>

        <div className="tw-cursor-pointer" onClick={() => hideDetails(providerID)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="tw-text-gray-700" viewBox="0 0 24 24">
            <path d="M18.3,5.71a1,1,0,0,0-1.41,0L12,10.59,7.11,5.7A1,1,0,0,0,5.7,7.11L10.59,12,5.7,16.89a1,1,0,0,0,1.41,1.41L12,13.41l4.89,4.89a1,1,0,0,0,1.41-1.41L13.41,12l4.89-4.89A1,1,0,0,0,18.3,5.71Z"/>
          </svg>
        </div>
      </div>

      <div className="tw-mb-4">
        <h3 className="tw-font-semibold">Time-off Requests:</h3>
        <ul>
          {timeOffRequests}
        </ul>
      </div>

      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
        <div>
          <h3 className="tw-font-semibold">Available Hours on Service Date:</h3>
          <p>{availableHoursServiceDate}</p>
        </div>
        <div>
          <h3 className="tw-font-semibold">Scheduled Hours on Service Date:</h3>
          <p>{scheduledHoursServiceDate}</p>
        </div>
        <div>
          <h3 className="tw-font-semibold">Available Hours on Previous Date:</h3>
          <p>{availableHoursPreviousDate}</p>
        </div>
        <div>
          <h3 className="tw-font-semibold">Scheduled Hours on Previous Date:</h3>
          <p>{scheduledHoursPreviousDate}</p>
        </div>
        <div>
          <h3 className="tw-font-semibold">Available Hours on Upcoming Date:</h3>
          <p>{availableHoursUpcomingDate}</p>
        </div>
        <div>
          <h3 className="tw-font-semibold">Scheduled Hours on Upcoming Date:</h3>
          <p>{scheduledHoursUpcomingDate}</p>
        </div>
      </div>
    </div>
  );
};

export default DetailedProviderCard;
