import { useEffect, useLayoutEffect, useState } from "react";
import { CourseMaterial, ProviderAttempt, Quiz, QuizQuestion, QuizQuestionResponse, Training, TrainingCertification } from "../../types/training";
import { ButtonPrimary } from "@medforall/medforall-component-library";
import { forEach, set } from "lodash";
import { ButtonSecondary } from "../../components/buttons/Buttons";
import { CheckCircleIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useAppContext } from "../../Context";
import { useNavigate, useParams } from "react-router-dom";
import { addProviderAttempt, addTrainingCertification, getCertificationForTraining, getClientTrainingByID, getMyAttemptsForTraining, getTrainingByIDForProvider } from "../../apicalls/trainingService";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { GenericBreadCrumbs } from "../Scheduling/SchedulingUtils";
import { extractFileNameWithoutExtension } from "./ManageContinuousEducationPage";

interface answerDataModel {
    choice1: boolean;
    choice2: boolean;
    choice3: boolean;
    choice4: boolean;
}

const ProviderCompleteTrainingPage = props => {
    const [training, setTraining] = useState<Training | null>(null);
    const [courseMaterials, setCourseMaterials] = useState<CourseMaterial[]>([]);
    const [quiz, setQuiz] = useState<Quiz | null>(null);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [answers, setAnswers] = useState<{ [key: string]: string }>({});
    const [savingAttempt, setSavingAttempt] = useState<boolean>(false);
    const [certification, setCertification] = useState<TrainingCertification | null>(null);

    const navigate = useNavigate();

    // const [booleanAnswers, setBooleanAnswers] = useState<>

    const [loadingTraining, setLoadingTraining] = useState<boolean>(true);

    const appContext = useAppContext();

    const { trainingID, trainingMode } = useParams();

    const [attempts, setAttempts] = useState<ProviderAttempt[]>([]);

    useLayoutEffect(() => {
        const fetchTraining = async () => {
            setLoadingTraining(true);

            const storedTraining = await getTrainingByIDForProvider(trainingID, appContext.handleAPICallError);

            console.log(storedTraining);

            setTraining(storedTraining);

            setCourseMaterials(storedTraining.courseMaterials.sort((a, b) => a.materialSequence - b.materialSequence));
            setQuiz(storedTraining.quiz);

            if (storedTraining.status === "Certified") {
                var certificate = await getCertificationForTraining(storedTraining.trainingID, appContext.handleAPICallError);

                console.log(certificate);

                setCertification(certificate);

                var attempts = await getMyAttemptsForTraining(storedTraining.trainingID, appContext.handleAPICallError);

                console.log(attempts);

                setAttempts(attempts);
            }

            setLoadingTraining(false);
        }

       

        fetchTraining();
    }, [trainingID])

    const steps = [
        { id: 1, name: 'Overview', href: '#', status: 'current' },
        { id: 2, name: 'Content', href: '#', status: 'upcoming' },
        { id: 3, name: 'Quiz', href: '#', status: 'upcoming' },
        { id: 4, name: 'Results', href: '#', status: 'upcoming' },
    ]

    const containsAnswer = (questionID: string, answer: string) => {

        if(!answers[questionID]) return false;

        if (answers[questionID].toString().split("|").includes(answer)) {
            return true;
        }

        return false;
    }

    const handleAnswerChange = (questionID: string, answer: string) => {
        var choice = "0";

        var question = quiz?.questions.find((q) => q.questionID === questionID);

        if (!question) return;

        if (question.questionType === "Multiple-Choice") {
            if (answer === question.choice1) {
                choice = "1";
            }
            if (answer === question.choice2) {
                choice = "2";
            }
            if (answer === question.choice3) {
                choice = "3";
            }
            if (answer === question.choice4) {
                choice = "4";
            }

            var existingChoices = [];

            if (answers[questionID]) {
                existingChoices = answers[questionID].toString().split("|");
            }

            if (existingChoices.includes(choice)) {
                existingChoices = existingChoices.filter((c) => c !== choice);
            } else {
                existingChoices.push(choice);
            }

            var multipleChoice = ""

            if (existingChoices.length === 1) {
                multipleChoice = existingChoices[0];
            } else {
                multipleChoice = existingChoices.join("|");
            }

            // console.log(choice);
            // console.log(existingChoices);
            // console.log(multipleChoice);

            setAnswers((prevAnswers) => ({
                ...prevAnswers,
                [questionID]: multipleChoice,
            }));

            return;
        }

        if (question.choice1 === answer) {
            choice = "1";
        }
        if (question.choice2 === answer) {
            choice = "2";
        }
        if (question.choice3 === answer && question.questionType !== "True-False") {
            choice = "3";
        }
        if (question.choice4 === answer && question.questionType !== "True-False") {
            choice = "4";
        }

        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionID]: choice,
        }));
    };

    const allQuestionsAnswered = () => {
        if (!quiz) return false;

        let answeredQuestions = 0;

        quiz.questions.forEach((question) => {
            if (answers[question.questionID]) {
                answeredQuestions += 1;
            }
        });

        return answeredQuestions === quiz.questions.length;
    }

    const isCorrectAnswer = (questionID: string, answer: string) => {
        var question = quiz?.questions.find((q) => q.questionID === questionID);

        //console.log(question);

        if (!question) return false;

        if (question.questionType === "Multiple-Choice") {
            var submittedAnswers = answer.split("|");
            var correctMCQAnswers = question.correctChoice.split("|");

            var correct = true;

            correctMCQAnswers.forEach((ca) => {
                if (!submittedAnswers.includes(ca)) {
                    correct = false;
                }
            });

            submittedAnswers.forEach((sa) => {
                if (!correctMCQAnswers.includes(sa)) {
                    correct = false;
                }
            });

            //console.log("MCQ Correct: " + correct);

            return correct;
        }

        //console.log("Single Correct: " + (answer === question.correctChoice));

        return answer === question.correctChoice;
    }

    const calculateScore = () => {
        if (!quiz) return 0;

        let correctAnswers = 0;
        quiz.questions.forEach((question) => {
            console.log(question.question);
            console.log(question.correctChoice);
            console.log(answers[question.questionID]);
            if(question.questionType === "Multiple-Choice") {
                var submittedAnswers = answers[question.questionID]?.split("|") || [];
                var correctMCQAnswers = question.correctChoice.split("|");

                var correct = true;

                correctMCQAnswers.forEach((ca) => {
                    if (!submittedAnswers.includes(ca)) {
                        correct = false;
                    }
                });

                submittedAnswers.forEach((sa) => {
                    if (!correctMCQAnswers.includes(sa)) {
                        correct = false;
                    }
                });

                if (correct) {
                    correctAnswers += 1;
                }
            } else {
                if (answers[question.questionID] === question.correctChoice) {
                    correctAnswers += 1;
                }
            }
            
        });
        return (correctAnswers / quiz.questions.length) * 100;
    };

    const renderProgressBar = () => {

        return <nav aria-label="Progress">
                    <ol role="list" className="tw-divide-y tw-divide-gray-300 tw-rounded-md tw-border tw-border-gray-300 md:tw-flex md:tw-divide-y-0 tw-mb-md">
                        {steps.map((step, stepIdx) => (
                        <li key={step.name} className="tw-relative md:tw-flex md:tw-flex-1">
                            {(currentStep + 1) > step.id ? (
                            <div className="tw-group tw-flex tw-w-full tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary group-hover:tw-bg-indigo-800">
                                    <CheckIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">{step.name}</span>
                                </span>
                            </div>
                            ) : step.id === (currentStep + 1) ? (
                            <div className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium" aria-current="step">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-primary">
                                <span className="tw-text-primary">{step.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-primary">{step.name}</span>
                            </div>
                            ) : (
                            <div className="tw-group tw-flex tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 group-hover:tw-border-gray-400">
                                    <span className="tw-text-gray-500 group-hover:tw-text-gray-900">{step.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-500 group-hover:tw-text-gray-900">{step.name}</span>
                                </span>
                            </div>
                            )}

                            {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div className="tw-absolute tw-top-0 tw-right-0 tw-hidden tw-h-full tw-w-5 md:tw-block" aria-hidden="true">
                                <svg
                                    className="tw-h-full tw-w-full tw-text-gray-300"
                                    viewBox="0 0 22 80"
                                    fill="none"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                    d="M0 -2L20 40L0 82"
                                    vectorEffect="non-scaling-stroke"
                                    stroke="currentcolor"
                                    strokeLinejoin="round"
                                    />
                                </svg>
                                </div>
                            </>
                            ) : null}
                        </li>
                        ))}
                    </ol>
                </nav>;
    }

    const handleQuizSubmit = async () => {
        
        const calculatedScore = calculateScore();
        setScore(calculatedScore);

        

        setSavingAttempt(true);

        setCurrentStep(3); // Move to results step


        if(trainingMode === "preview") {
            var submittedResponses : QuizQuestionResponse[] = [];

                forEach(answers, (value, key) => {
                    var question = quiz?.questions.find((q) => q.questionID === key);

                    if (!question) return;

                    var correct = true;

                    if(question.questionType === "Multiple-Choice") {
                        var submittedAnswers = value.split("|");
                        var correctMCQAnswers = question.correctChoice.split("|");

                        correctMCQAnswers.forEach((ca) => {
                            if (!submittedAnswers.includes(ca)) {
                                correct = false;
                            }
                        });

                        submittedAnswers.forEach((sa) => {
                            if (!correctMCQAnswers.includes(sa)) {
                                correct = false;
                            }
                        });
                    } else {
                        correct = value === question.correctChoice;
                    }

                    var response: QuizQuestionResponse = {
                        quizQuestionResponseID: "",
                        questionID: key,
                        providerAttemptID: "",
                        submittedChoice: value,
                        correctChoice: question.correctChoice,
                        correct: correct
                    };

                    submittedResponses.push(response);
                });


                var providerAttempt: ProviderAttempt = {
                    providerAttemptID: "",
                    trainingID: trainingID,
                    status: "Passed",
                    attemptStartTime: new Date(),
                    submissionTime: new Date(),
                    score: Math.floor(calculatedScore),
                    employeeID: "",
                    submittedAnswers: submittedResponses
                };
            
                console.log(providerAttempt);

            setSavingAttempt(false);
            return;
        }

        if (calculatedScore >= (quiz?.passingScore || 0)) {
            console.log("Passed");

            // Save the attempt
            // Save the certification
                var submittedResponses : QuizQuestionResponse[] = [];

                forEach(answers, (value, key) => {
                    var question = quiz?.questions.find((q) => q.questionID === key);

                    if (!question) return;

                    var correct = true;

                    if(question.questionType === "Multiple-Choice") {
                        var submittedAnswers = value.split("|");
                        var correctMCQAnswers = question.correctChoice.split("|");

                        correctMCQAnswers.forEach((ca) => {
                            if (!submittedAnswers.includes(ca)) {
                                correct = false;
                            }
                        });

                        submittedAnswers.forEach((sa) => {
                            if (!correctMCQAnswers.includes(sa)) {
                                correct = false;
                            }
                        });
                    } else {
                        correct = value === question.correctChoice;
                    }

                    var response: QuizQuestionResponse = {
                        quizQuestionResponseID: "",
                        questionID: key,
                        providerAttemptID: "",
                        submittedChoice: value,
                        correctChoice: question.correctChoice,
                        correct: correct
                    };

                    submittedResponses.push(response);
                });


                var providerAttempt: ProviderAttempt = {
                    providerAttemptID: "",
                    trainingID: trainingID,
                    status: "Passed",
                    attemptStartTime: new Date(),
                    submissionTime: new Date(),
                    score: Math.floor(calculatedScore),
                    employeeID: "",
                    submittedAnswers: submittedResponses
                };
            
                console.log(providerAttempt);

                var storedAttempt = await addProviderAttempt(trainingID ,providerAttempt, appContext.handleAPICallError);


                var certification: TrainingCertification = {
                    trainingCertificationID: "",
                    trainingID: trainingID,
                    providerID: "",
                    providerName: "",
                    certificationDate: new Date(),
                    status: "Certified"
                };

                console.log(certification);

                setCertification(certification);

                var storedCertification = await addTrainingCertification(trainingID ,certification, appContext.handleAPICallError);
        } else {
            console.log("Failed");

            var submittedResponses : QuizQuestionResponse[] = [];

                forEach(answers, (value, key) => {
                    var question = quiz?.questions.find((q) => q.questionID === key);

                    if (!question) return;

                    var correct = true;

                    if(question.questionType === "Multiple-Choice") {
                        var submittedAnswers = value.split("|");
                        var correctMCQAnswers = question.correctChoice.split("|");

                        correctMCQAnswers.forEach((ca) => {
                            if (!submittedAnswers.includes(ca)) {
                                correct = false;
                            }
                        });

                        submittedAnswers.forEach((sa) => {
                            if (!correctMCQAnswers.includes(sa)) {
                                correct = false;
                            }
                        });
                    } else {
                        correct = value === question.correctChoice;
                    }

                    var response: QuizQuestionResponse = {
                        quizQuestionResponseID: "",
                        questionID: key,
                        providerAttemptID: "",
                        submittedChoice: value,
                        correctChoice: question.correctChoice,
                        correct: correct
                    };

                    submittedResponses.push(response);
                });

            // Save the attempt
            // Save the certification
                var providerAttempt: ProviderAttempt = {
                    providerAttemptID: "",
                    trainingID: trainingID,
                    status: "Failed",
                    attemptStartTime: new Date(),
                    submissionTime: new Date(),
                    score: Math.floor(calculatedScore),
                    employeeID: "",
                    submittedAnswers: submittedResponses
                };
            
                console.log(providerAttempt);

                var storedAttempt = await addProviderAttempt(trainingID ,providerAttempt, appContext.handleAPICallError);
        }
        
        setSavingAttempt(false);

        
    };

    const renderCourseMaterial = () => {
        if (currentMaterialIndex >= courseMaterials.length) {
            console.log("Moving to quiz");
            console.log(courseMaterials);
            console.log(currentMaterialIndex);
            setCurrentStep(2); // Move to quiz
            return null;
        }

        const material = courseMaterials[currentMaterialIndex];

        return (
            <div className="tw-mb-4 tw-flex tw-flex-col">
                <h3 className="tw-text-lg tw-font-semibold">{extractFileNameWithoutExtension(material.materialLink)}</h3>
                <iframe 
                            src={material.materialLink} 
                            width="950" 
                            height="600" 
                            title="PDF Viewer"
                            className="tw-border tw-border-gray-300"
                        ></iframe>
                {/* {material.materialType === "video" && (
                    <video controls className="tw-w-full">
                        <source src={material.materialLink} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
                {material.materialType === "document" && (
                    <a href={material.materialLink} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500">
                        View Document
                    </a>
                )} */}
                <div className="tw-flex tw-justify-between tw-mt-4">
                            {currentMaterialIndex > 0 ? <>
                            <ButtonSecondary onClick={() => setCurrentMaterialIndex(currentMaterialIndex - 1)}>
                                Previous
                            </ButtonSecondary>
                            </>: <>
                            <ButtonSecondary onClick={() => {
                                setCurrentStep(0);
                                setCurrentMaterialIndex(0);
                                }}>
                                Back to Overview
                            </ButtonSecondary>
                            </>}

                            {currentMaterialIndex === courseMaterials.length - 1 ? 
                            <ButtonPrimary onClick={() => setCurrentStep(2)} className="tw-bg-primary tw-text-white">
                                Start Quiz
                            </ButtonPrimary>
                            :
                            <ButtonPrimary onClick={() => setCurrentMaterialIndex(currentMaterialIndex + 1)} className="tw-bg-primary tw-text-white">
                                Next
                            </ButtonPrimary>
                            }
                </div>
            </div>
        );
    };

    const renderQuiz = () => {
        if (!quiz) return null;

        return [...quiz.questions]
        .sort((a, b) => a.questionSequence - b.questionSequence)
            .map((question, index) => (
            <div key={question.questionID} className="tw-mb-4">
                <h4 className="tw-text-md tw-font-semibold">Question {index + 1}: {question.questionType === "True-False" ? "True or False: ": ""} {question.question} {question.questionType === "Multiple-Choice" ? " - Select all that apply":""}</h4>
                {question.questionType === "Image-Based" && (
                    <div className="tw-flex tw-justify-between">
                        <img src={question.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />
                    </div>
                    
                )}
                    
                    <div key={question.choice1}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice1}
                                checked={containsAnswer(question.questionID, "1")}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice1}
                                checked={answers[question.questionID] === "1"}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        )}
                            <span className="tw-px-2">{question.choice1}</span>
                        </label>
                    </div>
                    <div key={question.choice2}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice2}
                                checked={containsAnswer(question.questionID, "2")}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice2}
                                checked={answers[question.questionID] === "2"}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        )}
                            <span className="tw-px-2">{question.choice2}</span>
                        </label>
                    </div>
                    {question.questionType !== "True-False" && <div key={question.choice3}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice3}
                                checked={containsAnswer(question.questionID, "3")}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice3}
                                checked={answers[question.questionID] === "3"}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        )}
                            <span className="tw-px-2">{question.choice3}</span>
                        </label>
                    </div>}
                    {question.questionType !== "True-False" && <div key={question.choice4}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice4}
                                checked={containsAnswer(question.questionID, "4")}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice4}
                                checked={answers[question.questionID] === "4"}
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                            />
                        )}
                            <span className="tw-px-2">{question.choice4}</span>
                        </label>
                    </div>}
            </div>
        ));
    };

    const renderQuizResults = () => {
        if (!quiz) return null;

        return quiz && 
            [...quiz.questions]
            .sort((a, b) => a.questionSequence - b.questionSequence)
            .map((question, index) => (
            <div key={question.questionID} className="tw-mb-4">
                <div className="tw-flex tw-justify-between">
                    <h4 className="tw-text-md tw-font-semibold">Question {index + 1}: {question.questionType === "True-False" ? "True or False: ": ""} {question.question} {question.questionType === "Multiple-Choice" ? " - Select all that apply":""}</h4>
                    <div>
                        {isCorrectAnswer(question.questionID, answers[question.questionID] || "") ? (
                        <CheckCircleIcon className="tw-h-6 tw-w-6 tw-text-green-500" />
                        ) : (
                        <XCircleIcon className="tw-h-6 tw-w-6 tw-text-red-500" />
                        )}
                    </div>
                </div>
                
                {question.questionType === "Image-Based" && (
                    <div className="tw-flex tw-justify-between">
                        <img src={question.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />
                    </div>
                    
                )}
                    
                    <div key={question.choice1}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice1}
                                checked={containsAnswer(question.questionID, "1")}
                                disabled
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice1}
                                checked={answers[question.questionID] === "1"}
                                disabled
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice1}</span>
                        </label>
                    </div>
                    <div key={question.choice2}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice2}
                                checked={containsAnswer(question.questionID, "2")}
                                disabled
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice2}
                                checked={answers[question.questionID] === "2"}
                                disabled
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice2}</span>
                        </label>
                    </div>
                    {question.questionType !== "True-False" && <div key={question.choice3}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice3}
                                checked={containsAnswer(question.questionID, "3")}
                                disabled
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice3}
                                checked={answers[question.questionID] === "3"}
                                disabled
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice3}</span>
                        </label>
                    </div>}
                    {question.questionType !== "True-False" && <div key={question.choice4}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice4}
                                checked={containsAnswer(question.questionID, "4")}
                                disabled
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice4}
                                checked={answers[question.questionID] === "4"}
                                disabled
                                onChange={(e) => handleAnswerChange(question.questionID, e.target.value)}
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice4}</span>
                        </label>
                    </div>}
            </div>
        ));
    };

    const renderResults = () => {
        const isPassing = score >= (quiz?.passingScore || 0);
        return (
            <div>

                
            <div className="tw-text-center tw-mt-8">
                {savingAttempt ? <>
                    <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                    <LoadingSpinner /> 
					<span className="tw-text-primary tw-text-5 tw-text-center">Calculating Score ...</span>
                </div>
                </>:<>


                
                
                <h2 className="tw-text-2xl tw-font-semibold">Quiz Results</h2>
                <p className="tw-text-xl">Your Score: {score}</p>
                <p className="tw-text-xl">Passing Score: {training?.quiz.passingScore}</p>
                {isPassing ? (
                    <div className="tw-text-green-600">
                        {trainingMode === "complete" &&  <>
                        <h3 className="tw-text-xl tw-font-semibold">Congratulations! You have successfully completed the training.</h3>
                        <div className="tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-mt-4">
                            <h4 className="tw-text-lg tw-font-semibold tw-text-gray-800">Certificate of Completion</h4>
                            <p className="tw-text-gray-600">This is to certify that you have successfully completed the training.</p>
                            <p className="tw-text-gray-800 tw-font-medium">Training ID: {quiz?.trainingID}</p>
                            <p className="tw-text-gray-800 tw-font-medium">Quiz ID: {quiz?.quizID}</p>
                        </div>
                        </>}

                        {trainingMode === "preview" &&  <>  
                        <h3 className="tw-text-xl tw-text-primary tw-font-semibold">Thank you for participating in this training preview!</h3>
                        <h3 className="tw-text-xl tw-text-primary tw-font-semibold">Please share your feedback!</h3>
                        <p className="tw-text-lg tw-text-red-400">Note: This is a preview link for this training and this attempt cannot be used for certification!</p>
                        </>}

                        
                    </div>
                ) : (
                    <div className="tw-text-red-600">
                        {trainingMode === "complete" && <h3 className="tw-text-xl tw-font-semibold">Unfortunately, you did not pass the quiz.</h3>}


                        {trainingMode === "preview" &&  <>  
                        <h3 className="tw-text-xl tw-text-primary tw-font-semibold">Thank you for participating in this training preview!</h3>
                        <h3 className="tw-text-xl tw-text-primary tw-font-semibold">Please share your feedback!</h3>
                        <p className="tw-text-lg tw-text-red-400">Note: This is a preview link for this training and this attempt cannot be used for certification!</p>
                        </>}
                    </div>
                )}

                <ButtonSecondary onClick={(e) => {
                    e.preventDefault();
                    navigate("/myLearning/continuous-education");
                }} className="tw-mt-4">
                    Back to Continuous Education
                </ButtonSecondary>
                </>}
            </div>

            <h2 className="tw-text-lg tw-font-semibold tw-py-4">Your Responses</h2>
            {renderQuizResults()}
            </div>
        );
    };

    const renderCertificate = () => {
        if (!certification) return null;

        return (
            <div className="tw-text-center tw-mt-8">
                <h2 className="tw-text-xl tw-font-semibold">Training Certification</h2>
                <div className="tw-mt-4">
                    <p className="tw-text-gray-600">This is to certify that you have successfully completed the training.</p>
                    <p><strong>Certification Date:</strong> {new Date(certification.certificationDate).toLocaleString()}</p>
                    <p><strong>Status:</strong> {certification.status}</p>
                </div>
            </div>
        );
    }

    const [currentMaterialIndex, setCurrentMaterialIndex] = useState<number>(0);

    const renderContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <h2 className="tw-text-xl tw-font-semibold">Training Overview</h2>
                        {training && (
                            <div className="tw-mt-4">
                                <p><strong>Training Name:</strong> {training.trainingName}</p>
                                <p><strong>Description:</strong> {training.trainingDescription}</p>
                                <p><strong>Client Name:</strong> {training.clientName}</p>
                                <p><strong>Created By:</strong> {training.createdBy}</p>
                                <p><strong>Date Created:</strong> {new Date(training.dateCreated).toLocaleString()}</p>
                            </div>
                        )}
                        <ButtonPrimary onClick={() => setCurrentStep(1)} className="tw-mt-4">
                            Start Training
                        </ButtonPrimary>
                    </>
                );
            case 1:
                return (
                    <>
                        <h2 className="tw-text-xl tw-font-semibold">Content</h2>
                        {renderCourseMaterial()}
                    </>
                );
            case 2:
                return (
                    <>
                        <h2 className="tw-text-xl tw-font-semibold">Quiz</h2>
                        {renderQuiz()}
                        <div className="tw-flex tw-justify-between tw-mt-4">
                            <ButtonSecondary onClick={() => {
                                setCurrentStep(1);
                                setCurrentMaterialIndex(0);
                                }}>
                                Back to Course Materials
                            </ButtonSecondary>
                            <ButtonPrimary onClick={handleQuizSubmit} className="tw-bg-primary tw-text-white">
                                Submit Quiz
                            </ButtonPrimary>
                        </div>
                    </>
                );
            case 3:
                return renderResults();
            default:
                return null;
        }
    };

    const [showContent, setShowContent] = useState<boolean>(false);
    const [showQuizResponses, setShowQuizResponses] = useState<boolean>(false);

    const toggleContent = () => {
        setShowContent(!showContent);
    }

    const isCorrectResponse = (questionID: string, submittedAnswers: QuizQuestionResponse[]) => {
        const question = submittedAnswers.find((answer) => answer.questionID === questionID);
        return question?.correct;
    }

    const containsMCQAnswer = (questionID: string, choice: string, submittedAnswers: QuizQuestionResponse[]) => {
        const question = submittedAnswers.find((answer) => answer.questionID === questionID);
        const submittedChoices = question?.submittedChoice.split("|");
        return submittedChoices?.includes(choice);
    }

    const containsSingleAnswer = (questionID: string, choice: string, submittedAnswers: QuizQuestionResponse[]) => {
        const question = submittedAnswers.find((answer) => answer.questionID === questionID);
        return question?.submittedChoice === choice;
    }

    const renderQuizResponses = () => {
        if (!attempts) return null;

        if (!attempts[0].submittedAnswers) return null;

        return training?.quiz?.questions.map((question, index) => (
            <div key={question.questionID} className="tw-mb-4">
                <div className="tw-flex tw-justify-start">
                    <div className="tw-mr-2">
                        {isCorrectResponse(question.questionID, attempts[0].submittedAnswers) ? (
                        <CheckCircleIcon className="tw-h-6 tw-w-6 tw-text-green-500" />
                        ) : (
                        <XCircleIcon className="tw-h-6 tw-w-6 tw-text-red-500" />
                        )}
                    </div>
                    <h4 className="tw-text-md tw-font-semibold">Question {index + 1}: {question.questionType === "True-False" ? "True or False: ": ""} {question.question} {question.questionType === "Multiple-Choice" ? " - Select all that apply":""}</h4>
                    
                </div>
                
                {question.questionType === "Image-Based" && (
                    <div className="tw-flex tw-justify-between">
                        <img src={question.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />
                    </div>
                    
                )}
                    
                    <div key={question.choice1}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice1}
                                checked={containsMCQAnswer(question.questionID, "1", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice1}
                                checked={containsSingleAnswer(question.questionID, "1", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice1} <span className="tw-text-green-500">{isCorrectAnswer(question.questionID, "1") ? "- Correct": ""}</span></span>
                        </label>
                    </div>
                    <div key={question.choice2}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice2}
                                checked={containsMCQAnswer(question.questionID, "2", attempts[0].submittedAnswers)}
                                disabled
                
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice2}
                                checked={containsSingleAnswer(question.questionID, "2", attempts[0].submittedAnswers)}
                                disabled
                                
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice2} <span className="tw-text-green-500">{isCorrectAnswer(question.questionID, "2") ? "- Correct": ""}</span></span>
                        </label>
                    </div>
                    {question.questionType !== "True-False" && <div key={question.choice3}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice3}
                                checked={containsMCQAnswer(question.questionID, "3", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice3}
                                checked={containsSingleAnswer(question.questionID, "3", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice3} <span className="tw-text-green-500">{isCorrectAnswer(question.questionID, "3") ? "- Correct": ""}</span></span>
                        </label>
                    </div>}
                    {question.questionType !== "True-False" && <div key={question.choice4}>
                        <label>
                        {question.questionType === "Multiple-Choice" ? (
                            <input
                                type="checkbox"
                                name={question.questionID}
                                value={question.choice4}
                                checked={containsMCQAnswer(question.questionID, "4", attempts[0].submittedAnswers)}
                                disabled
                                className="tw-form-checkbox tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        ) : (
                            <input
                                type="radio"
                                name={question.questionID}
                                value={question.choice4}
                                checked={containsSingleAnswer(question.questionID, "4", attempts[0].submittedAnswers)}
                                disabled
                                
                                className="tw-form-radio tw-text-gray-500 tw-bg-gray-200 tw-border-gray-300 tw-disabled:opacity-50"
                            />
                        )}
                            <span className="tw-px-2">{question.choice4} <span className="tw-text-green-500">{isCorrectAnswer(question.questionID, "4") ? "- Correct": ""}</span></span>
                        </label>
                    </div>}
            </div>
        ));
    };

    const toggleQuizResponses = () => {
        setShowQuizResponses(!showQuizResponses);
    }

    return (
        <div className="tw-px-xl tw-py-sm">
            <div className="tw-flex tw-flex tw-pb-4 tw-flex-col tw-space-y-20px tw-text-dark">
                    <div className="tw-space-y-20px tw-pb-2 tw-border-b tw-border-gray-800">
                        <GenericBreadCrumbs 
                        mainHeading={"Client Trainings"}
                        subHeading={training ? `${trainingMode.charAt(0).toUpperCase() + trainingMode.slice(1).toLowerCase()} Training - ${training.trainingName}` : "Loading..."}
                        backURL={"/myLearning/training"}
                        />
			        </div>
            </div>

            {/* <div className="tw-border-b tw-border-gray-800 tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">
                    {training ? `Complete Training - ${training.trainingName}` : "Loading..."}
                </h2>
            </div> */}

            {loadingTraining ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>:<>

                {training?.status === "Certified" ? <>
                    <div className="tw-flex tw-justify-between tw-pb-2 tw-border-b tw-border-gray-300 tw-mb-2">
                        <h1 className="tw-text-xl tw-font-semibold tw-w-4/5">{training?.trainingName}</h1>
                    </div>

                    {renderCertificate()}

                    <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                        <div className="tw-flex tw-justify-between">
                            <h3 className={`tw-text-xl tw-pb-2 ${showContent ? "tw-font-semibold":"tw-font-normal"}`}>Content</h3>

                            {showContent ?
                            <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleContent()} />:
                            <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleContent()} />}
                        </div>

                        {showContent && (<>
                            {training?.courseMaterials?.map((material, index) => (
                                <>
                                <h3 className="tw-text-lg tw-font-semibold">{extractFileNameWithoutExtension(material.materialLink)}</h3>
                                <iframe 
                                            src={material.materialLink} 
                                            width="850" 
                                            height="600" 
                                            title="PDF Viewer"
                                            className="tw-border tw-border-gray-300"
                                        ></iframe>
                                </>
                            ))}
                        </>)}

                    </div>

                    <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                        <div className="tw-flex tw-justify-between">
                            <h3 className={`tw-text-xl tw-pb-2 ${showQuizResponses} ? "tw-font-semibold":"tw-font-normal"}`}>Quiz Responses</h3>

                            {showQuizResponses ?
                            <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleQuizResponses()} />:
                            <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleQuizResponses()} />}
                        </div>

                        

                        {showQuizResponses && (<>
                            {renderQuizResponses()}
                        </>)}

                    </div>
                </>:<>

                <div className="tw-flex tw-justify-between tw-pb-2 tw-border-b tw-border-gray-300 tw-mb-2">
                    <h1 className="tw-text-xl tw-font-semibold tw-w-4/5">{training?.trainingName}</h1>
                </div>

                <div className="tw-mt-5">
                    {renderProgressBar()}
                </div>

                <div className="tw-py-5">{renderContent()}</div>
                </>}
            </>}
        </div>
    );
};

export default ProviderCompleteTrainingPage;
