import { useState } from "react";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { AuthorizedService } from "../../types/servicePlan";
import { useFormik } from "formik";
import { ServiceDataSchema } from "../forms/newAuthorizedServiceSection";
import moment from "moment";
import { ButtonPrimary, ButtonSecondary, Input } from "@medforall/medforall-component-library";
import { useEditPlanContext } from "../../pages/EditServicePlanPage";
import { addAuthorizedService, deleteAuthorizedService, updateAuthorizedService } from "../../apicalls/servicePlanService";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useAppContext } from "../../Context";
import { set } from "lodash";
import { LoadingSpinner } from "./loadingSpinner";

export const ViewAuthorizedServiceSection = props => {
    return (<>
        <div className="tw-pb-2 tw-flex">
            <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">{props?.service.serviceTitle}</span>
            {props?.showEdit && <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={() => props?.onEdit(props?.service.authorizedServiceID)}>Edit</span>}
        </div>
        <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Service Code</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.service.serviceCode}</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Total Budget</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">${props?.service.authorizedBudget}</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Staff Size</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.service.staffSize}</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Group Size</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.service.groupSize}</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Authorized Cost</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">${props?.service.authorizedBudget}</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Authorized Units</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.service.authorizedUnits}</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Budget Start</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.service.displayStartDate}</span>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-flex-col">
                                        <span className="tw-text-xs tw-font-light tw-leading-6 tw-text-gray-500">Budget End</span>
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.service.displayEndDate}</span>
                                    </div>
                                </div>

    </>);
}

const EditAuthorizedServiceSection = props => {
    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [loading, setLoading] = useState(false);

    const serviceInitialValues = {
        serviceTitle: props?.service.serviceTitle,
        serviceCode: props?.service.serviceCode,
        staffSize: props?.service.staffSize,
        groupSize: props?.service.groupSize,
        totalAuthorizedBudget: props?.service.authorizedBudget,
        totalAuthorizedUnits: props?.service.authorizedUnits,
        budgetStartDate: props?.service.budgetStartDate,
        budgetEndDate: props?.service.budgetEndDate,
    };

    const serviceFormik = useFormik({
        initialValues: serviceInitialValues,
        validationSchema: ServiceDataSchema,
        onSubmit: (values) => {
            console.log(values);

            var newService: AuthorizedService = {
                authorizedServiceID: props?.service.authorizedServiceID,
                servicePlanID: "",
                serviceTitle: values.serviceTitle,
                serviceCode: values.serviceCode,
                staffSize: values.staffSize,
                groupSize: values.groupSize,
                authorizedBudget: parseFloat(values.totalAuthorizedBudget),
                utilizedBudget: 0,
                authorizedUnits: parseFloat(values.totalAuthorizedUnits),
                utilizedUnits: 0,
                budgetStartDate: values.budgetStartDate,
                budgetEndDate: values.budgetEndDate,
                evvServiceID: "",
                evvServiceName: "",
                payer: "",
                payerProgram: "",
                procedureCode: "",
                displayStartDate: moment(values.budgetStartDate).format("MM/DD/YYYY"),
                displayEndDate: moment(values.budgetEndDate).format("MM/DD/YYYY"),
            }

            console.log(newService);

            if(props?.editPlan){
                setLoading(true);
            }

            props?.onSave(props?.service.authorizedServiceID, newService);
        },
    });

    const handleRemoveService = () => {
        if(props?.editPlan){
            setShowConfirmDelete(true);
        } else {
            const index = planContext.planAuthorizedServices.indexOf(props?.service);
            const newItems = [...planContext.planAuthorizedServices.slice(0, index), ...planContext.planAuthorizedServices.slice(index + 1)];
            planContext.setPlanAuthorizedServices(newItems);

            props?.handleCancel();
        }
    }

    const deletePlanAuthorizedService = async () => {
        
        setLoading(true);
        var result = await deleteAuthorizedService(editPlanContext.servicePlan.servicePlanID, props?.service.authorizedServiceID, appContext.handleAPICallError);
        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Authorized Service Deleted Successfully");
            appContext.setShowSuccessModal(true);
            const index = editPlanContext.planAuthorizedServices.indexOf(props?.service);
            const newItems = [...editPlanContext.planAuthorizedServices.slice(0, index), ...editPlanContext.planAuthorizedServices.slice(index + 1)];
            editPlanContext.setPlanAuthorizedServices(newItems);
        }   
        
        setShowConfirmDelete(false);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    return (<>
        <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Edit Authorized Service</span>
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-title"
                                                    id="service-title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.serviceTitle && serviceFormik.errors.serviceTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('serviceTitle')}
                                                    />
                                                    {serviceFormik.touched.serviceTitle && serviceFormik.errors.serviceTitle && <p className="tw-text-red-500"><>{serviceFormik.errors.serviceTitle}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Code
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-code"
                                                    id="service-code"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.serviceCode && serviceFormik.errors.serviceCode ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('serviceCode')}
                                                    />
                                                    {serviceFormik.touched.serviceCode && serviceFormik.errors.serviceCode && <p className="tw-text-red-500"><>{serviceFormik.errors.serviceCode}</></p>}
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Staff Size
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="staff-size"
                                                    id="group-size"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.staffSize && serviceFormik.errors.staffSize ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('staffSize')}
                                                    />
                                                    {serviceFormik.touched.staffSize && serviceFormik.errors.staffSize && <p className="tw-text-red-500"><>{serviceFormik.errors.staffSize}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Group Size
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="group-size"
                                                    id="group-size"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.groupSize && serviceFormik.errors.groupSize ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('groupSize')}
                                                    />
                                                    {serviceFormik.touched.groupSize && serviceFormik.errors.groupSize && <p className="tw-text-red-500"><>{serviceFormik.errors.groupSize}</></p>}
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Total Authorized Cost
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="total-cost"
                                                    id="total-cost"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.totalAuthorizedBudget && serviceFormik.errors.totalAuthorizedBudget ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('totalAuthorizedBudget')}
                                                    />
                                                    {serviceFormik.touched.totalAuthorizedBudget && serviceFormik.errors.totalAuthorizedBudget && <p className="tw-text-red-500"><>{serviceFormik.errors.totalAuthorizedBudget}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Total Authorized Units
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="total-units"
                                                    id="total-units"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.totalAuthorizedUnits && serviceFormik.errors.totalAuthorizedUnits ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('totalAuthorizedUnits')}
                                                    />
                                                    {serviceFormik.touched.totalAuthorizedUnits && serviceFormik.errors.totalAuthorizedUnits && <p className="tw-text-red-500"><>{serviceFormik.errors.totalAuthorizedUnits}</></p>}
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Budget Start Date
                                                </label>
                                                <div className="tw-mt-2">
                                                <Input
                                                    min="2000-01-01" max="2024-12-31"
                                                    type="date"
                                                    name="budget-start-date"
                                                    id="budget-start-date"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.budgetStartDate && serviceFormik.errors.budgetStartDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('budgetStartDate')}
                                                    />
                                                    {serviceFormik.touched.budgetStartDate && serviceFormik.errors.budgetStartDate && <p className="tw-text-red-500"><>{serviceFormik.errors.budgetStartDate}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Budget End Date
                                                </label>
                                                <div className="tw-mt-2">
                                                <Input
                                                    min="2000-01-01" max="2024-12-31"
                                                    type="date"
                                                    name="budget-end-date"
                                                    id="budget-end-date"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.budgetEndDate && serviceFormik.errors.budgetEndDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('budgetEndDate')}
                                                    />
                                                    {serviceFormik.touched.budgetEndDate && serviceFormik.errors.budgetEndDate && <p className="tw-text-red-500"><>{serviceFormik.errors.budgetEndDate}</></p>}
                                                </div>
                                            </div>
                                    </div>

                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </>:<>
                                    
                                    {showConfirmDelete ? <>
                                    <p className="tw-text-primary">Are you sure you want to delete this authorized service?</p>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deletePlanAuthorizedService}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md" onClick={handleRemoveService}>
                                            {props?.editPlan ? 'Delete' : 'Remove'}
                                            </ButtonSecondary>
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.onCancel}>
                                                    {serviceFormik.dirty ? "Discard" : "Cancel"}
                                            </ButtonSecondary>
                                            {serviceFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={serviceFormik.handleSubmit}>
                                                Update
                                            </ButtonPrimary>}

                                    </div>
                                    </>}
                                    </>}
    </>);
}

const EditAuthorizedServiceCard = props => {

    return (<>
        {props?.isEditing ? <EditAuthorizedServiceSection service={props.service} onEdit={props?.onEdit} onSave={props?.onSave} onCancel={props?.onCancel} editPlan={props?.editPlan}/> : <ViewAuthorizedServiceSection service={props.service}  onEdit={props?.onEdit} showEdit={true}/>}
    </>);
}

export const EditAuthorizedServiceList = props => {
    const [editId, setEditId] = useState<string | null>(null);

    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, newService: AuthorizedService) {
        if(props?.editPlan) {
            newService.budgetStartDate = moment(newService.budgetStartDate).format()
            newService.budgetEndDate = moment(newService.budgetEndDate).format()
            var result = await updateAuthorizedService(newService, editPlanContext.servicePlan.servicePlanID, newService.authorizedServiceID, appContext.handleAPICallError)
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Authorized Service Updated Successfully");
                appContext.setShowSuccessModal(true);
            }
            editPlanContext.setPlanAuthorizedServices(editPlanContext.planAuthorizedServices.map(
                el => el.authorizedServiceID ===  newService.authorizedServiceID ? { ...el, ...newService } : el
            ));
        } else {
            planContext.setPlanAuthorizedServices(planContext.planAuthorizedServices.map(
                el => el.authorizedServiceID ===  newService.authorizedServiceID ? { ...el, ...newService } : el
            ));
        }

        setEditId(null);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    const [showAuthorizedServiceCard, setShowAuthorizedServiceCard] = useState(false);

    const serviceInitialValues = {
        serviceTitle: '',
        serviceCode: '',
        staffSize: 1,
        groupSize: 1,
        totalAuthorizedBudget: 0.0,
        totalAuthorizedUnits: 0,
        budgetStartDate: '',
        budgetEndDate: '',
    };

    const serviceFormik = useFormik({
        initialValues: serviceInitialValues,
        validationSchema: ServiceDataSchema,
        onSubmit: async (values) => {
            console.log(values);

            var newService: AuthorizedService = {
                authorizedServiceID: "",
                servicePlanID: "",
                serviceTitle: values.serviceTitle,
                serviceCode: values.serviceCode,
                staffSize: values.staffSize,
                groupSize: values.groupSize,
                authorizedBudget: parseFloat(values.totalAuthorizedBudget.toString()),
                utilizedBudget: 0,
                authorizedUnits: parseFloat(values.totalAuthorizedUnits.toString()),
                utilizedUnits: 0,
                budgetStartDate: moment(values.budgetStartDate).format(),
                budgetEndDate: moment(values.budgetEndDate).format(),
                evvServiceID: "",
                evvServiceName: "",
                payer: "",
                payerProgram: "",
                procedureCode: "",
                displayStartDate: moment(values.budgetStartDate).format("MM/DD/YYYY"),
                displayEndDate: moment(values.budgetEndDate).format("MM/DD/YYYY"),
            }

            console.log(newService);

            setLoading(true);

            var result = await addAuthorizedService(newService, editPlanContext.servicePlan.servicePlanID, appContext.handleAPICallError);
            await editPlanContext.updateLocalAuthorizedServices();
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Authorized Service Added Successfully");
                appContext.setShowSuccessModal(true);
                serviceFormik.resetForm();
                toggleAuthorizedServiceCard();
            }
            
        },
    });

    const toggleAuthorizedServiceCard = () => {
        setShowAuthorizedServiceCard(!showAuthorizedServiceCard);
        serviceFormik.resetForm();
        setLoading(false);
        setEditId(null);
    }

    return (<>
        {props?.editPlan && <>
            {showAuthorizedServiceCard ? <>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">New Authorized Service</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Title
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-title"
                                                    id="service-title"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.serviceTitle && serviceFormik.errors.serviceTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('serviceTitle')}
                                                    />
                                                    {serviceFormik.touched.serviceTitle && serviceFormik.errors.serviceTitle && <p className="tw-text-red-500">{serviceFormik.errors.serviceTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Code
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-code"
                                                    id="service-code"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.serviceCode && serviceFormik.errors.serviceCode ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('serviceCode')}
                                                    />
                                                    {serviceFormik.touched.serviceCode && serviceFormik.errors.serviceCode && <p className="tw-text-red-500">{serviceFormik.errors.serviceCode}</p>}
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Staff Size
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="staff-size"
                                                    id="group-size"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.staffSize && serviceFormik.errors.staffSize ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('staffSize')}
                                                    />
                                                    {serviceFormik.touched.staffSize && serviceFormik.errors.staffSize && <p className="tw-text-red-500">{serviceFormik.errors.staffSize}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Group Size
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="group-size"
                                                    id="group-size"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.groupSize && serviceFormik.errors.groupSize ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('groupSize')}
                                                    />
                                                    {serviceFormik.touched.groupSize && serviceFormik.errors.groupSize && <p className="tw-text-red-500">{serviceFormik.errors.groupSize}</p>}
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Total Authorized Cost
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="total-cost"
                                                    id="total-cost"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.totalAuthorizedBudget && serviceFormik.errors.totalAuthorizedBudget ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('totalAuthorizedBudget')}
                                                    />
                                                    {serviceFormik.touched.totalAuthorizedBudget && serviceFormik.errors.totalAuthorizedBudget && <p className="tw-text-red-500">{serviceFormik.errors.totalAuthorizedBudget}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Total Authorized Units
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="total-units"
                                                    id="total-units"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.totalAuthorizedUnits && serviceFormik.errors.totalAuthorizedUnits ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('totalAuthorizedUnits')}
                                                    />
                                                    {serviceFormik.touched.totalAuthorizedUnits && serviceFormik.errors.totalAuthorizedUnits && <p className="tw-text-red-500">{serviceFormik.errors.totalAuthorizedUnits}</p>}
                                                </div>
                                            </div>

                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Budget Start Date
                                                </label>
                                                <div className="tw-mt-2">
                                                <Input
                                                    min="2000-01-01" max="2024-12-31"
                                                    type="date"
                                                    name="budget-start-date"
                                                    id="budget-start-date"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.budgetStartDate && serviceFormik.errors.budgetStartDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('budgetStartDate')}
                                                    />
                                                    {serviceFormik.touched.budgetStartDate && serviceFormik.errors.budgetStartDate && <p className="tw-text-red-500">{serviceFormik.errors.budgetStartDate}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Budget End Date
                                                </label>
                                                <div className="tw-mt-2">
                                                <Input
                                                    min="2000-01-01" max="2024-12-31"
                                                    type="date"
                                                    name="budget-end-date"
                                                    id="budget-end-date"
                                                    className={`tw-block tw-w-full tw-rounded-md ${serviceFormik.touched.budgetEndDate && serviceFormik.errors.budgetEndDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...serviceFormik.getFieldProps('budgetEndDate')}
                                                    />
                                                    {serviceFormik.touched.budgetEndDate && serviceFormik.errors.budgetEndDate && <p className="tw-text-red-500">{serviceFormik.errors.budgetEndDate}</p>}
                                                </div>
                                            </div>
                                    </div>
                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner />
                                        </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleAuthorizedServiceCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={serviceFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                    </>}
                                </div>
                            </>:<>
                            <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2 tw-mb-4">
                                    <div className="tw-flex tw-items-center">
                                            <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleAuthorizedServiceCard}>
                                            <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                            <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Authorized Service</span>
                                            </div>  
                                    </div>
                            </div>
                            </>}
        </>}
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
            {props?.services.length > 0 && props?.services.map((service, index) => (
                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow" key={service.authorizedServiceID}>
                    <EditAuthorizedServiceCard service={service} onEdit={handleEdit} onSave={handleSave} onCancel={handleCancel} isEditing={service.authorizedServiceID === editId} editPlan={props?.editPlan}></EditAuthorizedServiceCard>
                </div>
            ))}
        </div>
    </>);
}

export default EditAuthorizedServiceCard;