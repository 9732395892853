import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ensure you import Quill's CSS

interface ReadOnlyTextProps {
  content: string;
}

const ReadOnlyText: React.FC<ReadOnlyTextProps> = ({ content }) => {
  return (
    <div className="tw-bg-yellow">
         <ReactQuill value={content} readOnly={true} theme={"bubble"} />
    </div>
   
  );
};

export default ReadOnlyText;
