import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClientAnnouncement, ClientAnnouncementAcknowledgement } from "../../types/clientAnnouncement";
import { GenericBreadCrumbs } from "../Scheduling/SchedulingUtils";
import { useAppContext } from "../../Context";
import moment from "moment";
import ReadOnlyText from "../../components/forms/readOnlyRichTextField";
import { ButtonErrorSecondary, ButtonPrimary, ButtonWarning, ButtonWarningSecondary } from "../../components/buttons/Buttons";
import { archiveClientAnnouncement, escapeSingleQuotes, getAcknowledgementsForClientAnnouncement, getClientAnnouncementByID, publishClientAnnouncement, updateClientAnnouncement } from "../../apicalls/announcementService";
import RichTextField from "../../components/forms/richTextField";
import { Select } from "@medforall/medforall-component-library";
import CalendarSelector from "../../components/calendars/CalendarSelector";
import { CircularProgress } from "@mui/material";
import ConfirmDialog from "../../components/dialogs/confirmDialog";
import SuccessDialog from "../../components/dialogs/successDialog";
import { start } from "repl";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";

const ManageClientAnnouncementPage = props => {
    const { announcementID } = useParams();
    let navigate = useNavigate();

    const [savingAnnouncement, setSavingAnnouncement] = useState(false);

    const [loadingAnnouncement, setLoadingAnnouncement] = useState(true);

    const navContext = useAppContext();
    const announcementImpactRef = useRef();

    const [showCancelDialog, setShowCancelDialog] = useState(false);

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const [showArchiveConfirmDialog, setShowArchiveConfirmDialog] = useState(false);
    const [showArchiveSuccessDialog, setShowArchiveSuccessDialog] = useState(false);

    const [showPublishConfirmDialog, setShowPublishConfirmDialog] = useState(false);
    const [showPublishSuccessDialog, setShowPublishSuccessDialog] = useState(false);

    const [acknowledgements, setAcknowledgements] = useState<ClientAnnouncementAcknowledgement[]>(null);

    const [editMode, setEditMode] = useState<boolean>(false);

    const [announcement, setAnnouncement] = useState<ClientAnnouncement | null>(null);

    const [formValues, setFormValues] = useState({
        client: '',
        announcementTitle: '',
        announcementDescription: '',
        announcementImpactLevel: '',
        dateOfExpiry: moment().add(1, 'days').format('M-D-YYYY'),
        startDate: moment().format('M-D-YYYY')
    });

    const [formErrors, setFormErrors] = useState({
        clientError: false,
        clientErrorMessage: '',
        announcementTitleError: false,
        announcementTitleErrorMessage: '',
        announcementDescriptionError: false,
        announcementDescriptionErrorMessage: '',
        announcementImpactError: false,
        announcementImpactErrorMessage: '',
        dateOfExpiryError: false,
        dateOfExpiryErrorMessage: '',
        startDateError: false,
        startDateErrorMessage: '',
    });

    useLayoutEffect(() => {

        const fetchAnnouncement = async () => {
            const result = await getClientAnnouncementByID(announcementID, navContext.handleAPICallError);

            setAnnouncement(result);

            setFormValues({
                client: result?.clientName || '',
                announcementTitle: result?.announcementTitle || '',
                announcementDescription: result?.announcementDescription || '',
                announcementImpactLevel: result?.announcementImpactLevel || '',
                dateOfExpiry: moment(result?.dateOfExpiry).format('M-D-YYYY'),
                startDate: moment(result?.startDate).format('M-D-YYYY')
            });

            const acknowledgements = await getAcknowledgementsForClientAnnouncement(announcementID, navContext.handleAPICallError);

            console.log(acknowledgements);

            setAcknowledgements(acknowledgements);

            setLoadingAnnouncement(false);
        }

        if (announcementID) {
            // fetch announcement by ID
            fetchAnnouncement();
        }
    
    }, [announcementID])

    const handleRichTextFieldChange = (value: string) => {
        console.log(value);

        setFormValues({...formValues, announcementDescription: value});

        validateAnnouncementDescription(value);
    }

    const validateAnnouncementTitle = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, announcementTitleError: true, announcementTitleErrorMessage: 'Announcement Title is required'});
        } else {
            setFormErrors({...formErrors, announcementTitleError: false, announcementTitleErrorMessage: ''});
        }
    }

    const validateAnnouncementDescription = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, announcementDescriptionError: true, announcementDescriptionErrorMessage: 'Announcement Description is required'});
        } else {
            setFormErrors({...formErrors, announcementDescriptionError: false, announcementDescriptionErrorMessage: ''});
        }
    }

    const validateAnnouncementImpact = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, announcementImpactError: true, announcementImpactErrorMessage: 'Announcement Impact is required'});
        } else {
            setFormErrors({...formErrors, announcementImpactError: false, announcementImpactErrorMessage: ''});
        }
    }

    const validateDateOfExpiry = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, dateOfExpiryError: true, dateOfExpiryErrorMessage: 'Date of Expiry is required'});
        } else if (moment(value, "M-D-YYYY").isSameOrBefore(moment())) {
            setFormErrors({...formErrors, dateOfExpiryError: true, dateOfExpiryErrorMessage: 'Date of Expiry must be in the future'});
        } else {
            setFormErrors({...formErrors, dateOfExpiryError: false, dateOfExpiryErrorMessage: ''});
        }
    }

    const validateStartDate = (value: string) => {
        if(value === '') {
            setFormErrors({...formErrors, startDateError: true, startDateErrorMessage: 'Start Date is required'});
        } else if (moment(value, "M-D-YYYY").isBefore(moment().startOf('day'))) {
            setFormErrors({...formErrors, startDateError: true, startDateErrorMessage: 'Start Date cannot be in the past'});
        } else {
            setFormErrors({...formErrors, startDateError: false, startDateErrorMessage: ''});

            setFormValues({...formValues, dateOfExpiry: moment(value, "M-D-YYYY").add(14, 'days').format('M-D-YYYY')});
        }
    }

    const validateForm = () => {
        let valid = true;

        var currentErrors = {...formErrors};

        if(formValues.announcementTitle === '') {
            currentErrors.announcementTitleError = true;
            currentErrors.announcementTitleErrorMessage = 'Announcement Title is required';
            valid = false;
        } else {
            currentErrors.announcementTitleError = false;
            currentErrors.announcementTitleErrorMessage = '';
        }

        if(formValues.announcementDescription === '') {
            currentErrors.announcementDescriptionError = true;
            currentErrors.announcementDescriptionErrorMessage = 'Announcement Description is required';
            valid = false;
        } else {
            currentErrors.announcementDescriptionError = false;
            currentErrors.announcementDescriptionErrorMessage = '';
        }

        if(formValues.announcementImpactLevel === '') {
            currentErrors.announcementImpactError = true;
            currentErrors.announcementImpactErrorMessage = 'Announcement Impact is required';
            valid = false;
        } else {
            currentErrors.announcementImpactError = false;
            currentErrors.announcementImpactErrorMessage = '';
        }

        if(formValues.startDate === '') {
            currentErrors.startDateError = true;
            currentErrors.startDateErrorMessage = 'Start Date is required';
            valid = false;
        } else if (moment(formValues.startDate, "M-D-YYYY").isBefore(moment().startOf('day'))) {
            currentErrors.startDateError = true;
            currentErrors.startDateErrorMessage = 'Start Date cannot be in the past';
            valid = false;
        } else {
            currentErrors.startDateError = false;
            currentErrors.startDateErrorMessage = '';
        }

        if(formValues.dateOfExpiry === '') {
            currentErrors.dateOfExpiryError = true;
            currentErrors.dateOfExpiryErrorMessage = 'Date of Expiry is required';
            valid = false;
        } else if (moment(formValues.dateOfExpiry, "M-D-YYYY").isSameOrBefore(moment())) {
            currentErrors.dateOfExpiryError = true;
            currentErrors.dateOfExpiryErrorMessage = 'Date of Expiry must be in the future';
            valid = false;
        } else {
            currentErrors.dateOfExpiryError = false;
            currentErrors.dateOfExpiryErrorMessage = '';
        }

        setFormErrors(currentErrors);

        return valid;
    }

    const confirmDiscardAnnouncementChanges = () => {
        setShowCancelDialog(true);
    }

    const discardAnnouncementChanges = () => {
        setShowCancelDialog(false);
        setEditMode(false);
        setFormValues({
            client: announcement?.clientName || '',
            announcementTitle: announcement?.announcementTitle || '',
            announcementDescription: announcement?.announcementDescription || '',
            announcementImpactLevel: announcement?.announcementImpactLevel || '',
            dateOfExpiry: moment(announcement?.dateOfExpiry).format('M-D-YYYY'),
            startDate: moment(announcement?.startDate).format('M-D-YYYY')
        });
        setFormErrors({
            clientError: false,
            clientErrorMessage: '',
            announcementTitleError: false,
            announcementTitleErrorMessage: '',
            announcementDescriptionError: false,
            announcementDescriptionErrorMessage: '',
            announcementImpactError: false,
            announcementImpactErrorMessage: '',
            dateOfExpiryError: false,
            dateOfExpiryErrorMessage: '',
            startDateError: false,
            startDateErrorMessage: '',
        });
    }

    const dismissCancelDialog = () => {
        setShowCancelDialog(false);
    }

    const dismissSuccessDialog = () => {
        setShowSuccessDialog(false);
        setEditMode(false);
    };

    const saveClientAnnouncement = async () => {
        console.log("Save Client Announcement");

        const valid = validateForm();

        if(valid) {
            setSavingAnnouncement(true);

            const newAnnouncement: ClientAnnouncement = {
                clientAnnouncementID: announcementID,
                clientID: announcement?.clientID,
                clientName: announcement?.clientName,
                announcementTitle: escapeSingleQuotes(formValues.announcementTitle),
                announcementDescription: escapeSingleQuotes(formValues.announcementDescription),
                announcementImpactLevel: formValues.announcementImpactLevel,
                dateCreated: announcement?.dateCreated,
                createdBy: announcement?.createdBy,
                dateOfExpiry: moment(formValues.dateOfExpiry, "M-D-YYYY").toDate(),
                status: announcement?.status,
                startDate: moment(formValues.startDate, "M-D-YYYY").toDate(),
                publishedDate: undefined,
                publishedBy: "",
                archiveDate: undefined,
                archivedBy: ""
            }

            console.log(newAnnouncement);

            const result = await updateClientAnnouncement(newAnnouncement, navContext.handleAPICallError);

            setAnnouncement(newAnnouncement);

            setSavingAnnouncement(false);
            setShowSuccessDialog(true);
        }
    }

    const dismissArchiveConfirmDialog = () => {
        setShowArchiveConfirmDialog(false);
    }

    const confirmArchiveAnnouncement = () => {
        setShowArchiveConfirmDialog(true);
    }

    const archiveAnnouncement = async () => {
        console.log("Archive Announcement");

        setShowArchiveConfirmDialog(false);

        setSavingAnnouncement(true);

        const result = await archiveClientAnnouncement(announcementID, navContext.handleAPICallError);

        setSavingAnnouncement(false);

        setShowArchiveSuccessDialog(true);
    }

    const dismissArchiveSuccessDialog = () => {
        setShowArchiveSuccessDialog(false);
        setSavingAnnouncement(false);
        navigate(-1);
    }

    const dismissPublishConfirmDialog = () => {
        setShowPublishConfirmDialog(false);
    }

    const confirmPublishAnnouncement = () => {
        setShowPublishConfirmDialog(true);
    }

    const publishAnnouncement = async () => {
        console.log("Publish Announcement");

        setShowPublishConfirmDialog(false);

        setSavingAnnouncement(true);

        const result = await publishClientAnnouncement(announcementID, navContext.handleAPICallError);

        setAnnouncement({...announcement, status: "Active", publishedDate: new Date()});

        setSavingAnnouncement(false);

        setShowPublishSuccessDialog(true);
    }

    const dismissPublishSuccessDialog = () => {
        setShowPublishSuccessDialog(false);
        setSavingAnnouncement(false);
        //navigate(-1);
    }

    return (<>
        <SuccessDialog title="Announcement Updated" message="The client announcement has been updated successfully." onClose={dismissSuccessDialog} closeButtonText="close" open={showSuccessDialog}/>
        <ConfirmDialog title="Discard Announcement Changes" message="Are you sure you want to discard changes to this announcement?" onConfirm={discardAnnouncementChanges} confirmButtonText={"Yes"} cancelButtonText="No" onCancel={dismissCancelDialog} showDialog={showCancelDialog}/>
        <SuccessDialog title="Announcement Archived" message="The client announcement has been archvied successfully." onClose={dismissArchiveSuccessDialog} closeButtonText="Back to announcements" open={showArchiveSuccessDialog}/>
        <ConfirmDialog title="Archive Announcement" message="Are you sure you want to archive this announcement? This is not reversible" onConfirm={archiveAnnouncement} confirmButtonText={"Yes"} cancelButtonText="No" onCancel={dismissArchiveConfirmDialog} showDialog={showArchiveConfirmDialog}/>
        <SuccessDialog title="Announcement Published" message="The agency announcement has been publish successfully." onClose={dismissPublishSuccessDialog} closeButtonText="Close" open={showPublishSuccessDialog}/>
        <ConfirmDialog title="Publish Announcement" message="Are you sure you want to publish this announcement?" onConfirm={publishAnnouncement} confirmButtonText={"Yes"} cancelButtonText="No" onCancel={dismissPublishConfirmDialog} showDialog={showPublishConfirmDialog}/>
        <div className="tw-px-xl tw-pt-xl">
            <div className="tw-flex tw-flex tw-flex-col tw-space-y-20px tw-text-dark">
                    <div className="tw-space-y-20px tw-pb-2 tw-border-b tw-border-gray-800">
					<GenericBreadCrumbs 
                    mainHeading={"Client Announcements"}
                    subHeading={editMode ? "Edit" : "View"}
                    backURL={"/announcement/clients"}
					/>
			        </div>
            </div>

            {loadingAnnouncement ? <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                    <LoadingSpinner /> 
					<span className="tw-text-primary tw-text-5 tw-text-center">Loading Client Announcement</span>
                </div> : <>   

            {editMode ? <>
                <div className="tw-flex tw-flex-row tw-w-full">
                    <div className="tw-flex tw-flex-col tw-space-y-2 tw-w-1/2">
                        <li className="tw-flex tw-flex-col tw-space-y-5px">
                            <span className="tw-w-150px tw-flex">
                                <span className="tw-opacity-70">
                                    Client
                                </span>
                            </span>
                            <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                                {announcement?.clientName}
                            </span>
                        </li>

                        <li className="tw-flex tw-flex-col tw-space-y-5px">
                                <span className="tw-w-150px tw-flex">
                                    <span className="tw-opacity-70">
                                        Created By
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                                    {announcement?.createdBy}
                                </span>
                            </li>

                            <li className="tw-flex tw-flex-col tw-space-y-5px">
                                <span className="tw-w-150px tw-flex">
                                    <span className="tw-opacity-70">
                                        Date Created
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                                    {moment(announcement?.dateCreated).format("MM/DD/YYYY h:mm A")}
                                </span>
                            </li>

                            <li className="tw-flex tw-flex-col tw-space-y-5px">
                                <span className="tw-w-150px tw-flex">
                                    <span className="tw-opacity-70">
                                        Status
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                                    {announcement?.status}
                                </span>
                            </li>

                            {announcement?.status === "Active" && <li className="tw-flex tw-flex-col tw-space-y-5px">
                                <span className="tw-w-150px tw-flex">
                                    <span className="tw-opacity-70">
                                        Published Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                                    {moment(announcement?.publishedDate).format("MM/DD/YYYY h:mm A")}
                                </span>
                            </li>}

                            {announcement?.status === "Active" && <li className="tw-flex tw-flex-col tw-space-y-5px">
                                <span className="tw-w-150px tw-flex">
                                    <span className="tw-opacity-70">
                                        Published By
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                                    {announcement?.publishedBy}
                                </span>
                            </li>}

                            {announcement?.status === "Archived" && <li className="tw-flex tw-flex-col tw-space-y-5px">
                                <span className="tw-w-150px tw-flex">
                                    <span className="tw-opacity-70">
                                        Archived Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                                    {moment(announcement?.archiveDate).format("MM/DD/YYYY h:mm A")}
                                </span>
                            </li>}

                            {announcement?.status === "Archived" && <li className="tw-flex tw-flex-col tw-space-y-5px">
                                <span className="tw-w-150px tw-flex">
                                    <span className="tw-opacity-70">
                                        Archived By
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                                    {announcement?.archivedBy}
                                </span>
                            </li>}
                    </div>

                    <div className="tw-flex tw-flex-col tw-space-y-5 tw-w-1/2">
                        <div className="tw-flex tw-flex-col tw-space-y-1">
                            <div className="tw-flex tw-flex-col tw-space-y-1">
                                <label className="tw-text-gray-700">Announcement Title</label>
                                <input
                                    type="text"
                                    min={0}
                                    value={formValues.announcementTitle}
                                    onChange={e => {
                                        setFormValues({...formValues, announcementTitle: e.target.value});
                                        validateAnnouncementTitle(e.target.value);
                                    }}
                                    className={`tw-px-3 tw-py-2 tw-border tw-bg-white ${formErrors.announcementTitleError ? "tw-border-red-500": "tw-border-primary"} tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-border-blue-300`}
                                    />
                                {formErrors.announcementTitleError && <p className="tw-text-red-500">{formErrors.announcementTitleErrorMessage}</p>}
                            </div>

                            <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/4 sm:tw-w-1/2">
                                <label className="tw-text-gray-700">Impact Level</label>
                                <Select id="announcement-type" className={formErrors.announcementImpactError ? '_form-error': ''} 
                                    onChange={ e => {
                                        console.log(e.target.value);
                                        setFormValues({...formValues, announcementImpactLevel: e.target.value});
                                        validateAnnouncementImpact(e.target.value);
                                    }} ref={ announcementImpactRef } value={ formValues.announcementImpactLevel }>
                                    <option value="">Select</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                    <option value="Critical">Critical</option>
                                </Select>
                                {formErrors.announcementImpactError && <p className="tw-text-red-500">{formErrors.announcementImpactErrorMessage}</p>}
                            </div>

                            <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/4 sm:tw-w-1/2">
                                <CalendarSelector id="startDate" className={formErrors.startDateError ? '_form-error' : ''} 
                                    name="Start Date:" value={ formValues.startDate || null } 
                                    onChange={ e => {
                                        console.log(e.target.value);
                                        setFormValues({...formValues, startDate: e.target.value});
                                        validateStartDate(e.target.value);
                                    }}/>
                                    {formErrors.startDateError && <p className="tw-text-red-500">{formErrors.startDateErrorMessage}</p>}
                            </div>

                            <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-1/4 sm:tw-w-1/2">
                                <CalendarSelector id="dateOfExpiry" className={formErrors.dateOfExpiryError ? '_form-error' : ''} 
                                    name="Date Of Expiry:" value={ formValues.dateOfExpiry || null } 
                                    onChange={ e => {
                                        console.log(e.target.value);
                                        setFormValues({...formValues, dateOfExpiry: e.target.value});
                                        validateDateOfExpiry(e.target.value);
                                    }}/>
                                    {formErrors.dateOfExpiryError && <p className="tw-text-red-500">{formErrors.dateOfExpiryErrorMessage}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-mt-15px"> 

                    

                    <RichTextField label="Announcement Description" value={formValues.announcementDescription} onChange={handleRichTextFieldChange} showValidation={formErrors.announcementDescriptionError} validationMessage={formErrors.announcementDescriptionErrorMessage}/>

                    <div className="tw-flex tw-justify-end tw-mx-4">
                        {savingAnnouncement ? <>
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </>:<>
                                        <ButtonErrorSecondary className="tw-mt-md tw-ml-4" onClick={confirmArchiveAnnouncement}>
                                            Archive
                                        </ButtonErrorSecondary>
                                        <ButtonWarningSecondary className="tw-mt-md tw-mx-4" onClick={confirmDiscardAnnouncementChanges}>
                                            Discard
                                        </ButtonWarningSecondary>
                                        <ButtonPrimary className="tw-mt-md" type="submit" onClick={saveClientAnnouncement}>
                                            Save
                                        </ButtonPrimary>
                        </>}

                    </div>
                            
            </div>

            </>:<>
            <ul className="tw-text-5 tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-pb-5 tw-mt-15px">
                    <li className="tw-flex tw-flex-col tw-space-y-5px">
                        <span className="tw-w-150px tw-flex">
                            <span className="tw-opacity-70">
                                Client
                            </span>
                        </span>
                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                            {announcement?.clientName}
                        </span>
                    </li>
                    <li className="tw-flex tw-flex-col tw-space-y-5px">
                        <span className="tw-w-150px tw-flex">
                            <span className="tw-opacity-70">
                                Title
                            </span>
                        </span>
                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                            {announcement?.announcementTitle}
                        </span>
                    </li>
                    <li className="tw-flex tw-flex-col tw-space-y-5px">
                        <span className="tw-w-150px tw-flex">
                            <span className="tw-opacity-70">
                                Created By
                            </span>
                        </span>
                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                            {announcement?.createdBy}
                        </span>
                    </li>
                    <li className="tw-flex tw-flex-col tw-space-y-5px">
                        <span className="tw-w-150px tw-flex">
                            <span className="tw-opacity-70">
                                Date Created
                            </span>
                        </span>
                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                            {moment(announcement?.dateCreated).format("MM/DD/YYYY h:mm A")}
                        </span>
                    </li>
                    <li className="tw-flex tw-flex-col tw-space-y-5px">
                                <span className="tw-w-150px tw-flex">
                                    <span className="tw-opacity-70">
                                        Status
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                                    {announcement?.status}
                                </span>
                    </li>
                    <li className="tw-flex tw-flex-col tw-space-y-5px">
                        <span className="tw-w-250px tw-flex">
                            <span className="tw-opacity-70">
                                Announcement Impact
                            </span>
                        </span>
                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                            {announcement?.announcementImpactLevel}
                        </span>
                    </li>
                    <li className="tw-flex tw-flex-col tw-space-y-5px">
                        <span className="tw-w-150px tw-flex">
                            <span className="tw-opacity-70">
                                Start Date
                            </span>
                        </span>
                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                            {moment(announcement?.startDate).format("MM/DD/YYYY")}
                        </span>
                    </li>
                    <li className="tw-flex tw-flex-col tw-space-y-5px">
                        <span className="tw-w-150px tw-flex">
                            <span className="tw-opacity-70">
                                Date Of Expiry
                            </span>
                        </span>
                        <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                            {moment(announcement?.dateOfExpiry).format("MM/DD/YYYY")}
                        </span>
                    </li>
                    <li className="tw-flex tw-flex-col tw-space-y-5px">
                        <span className="tw-w-250px tw-flex ">
                            <span className="tw-opacity-70">
                                Announcement Description
                            </span>
                        </span>
                        <div className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-justify-start tw-text-black tw-pr-20px">
                            <ReadOnlyText content={announcement?.announcementDescription} />
                        </div>
                    </li>
                    
                </ul>

                <div className="tw-flex tw-justify-end tw-mx-4">
                    {savingAnnouncement ? <>
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </>:<>
                    {announcement?.status === "Draft" && <ButtonWarning className="tw-mx-4" onClick={confirmPublishAnnouncement}>
                        Publish
                    </ButtonWarning>}
                    {announcement?.status !== "Archived" && <ButtonPrimary className="" type="submit" onClick={() => setEditMode(true)}>
                        Edit
                    </ButtonPrimary>}
                    </>}
                </div>
            </>}

            {!editMode && announcement?.status !== "Draft" && <div className="tw-border-dashed tw-border-t-2 tw-border-gray-500 tw-pt-5 tw-mt-5">
                <div className="tw-pb-2 tw-border-b tw-border-gray-800">
                    <h6 className="tw-text-md tw-font-semibold tw-leading-6 tw-text-gray-900">Acknowlegement Records - {acknowledgements ? acknowledgements.length : 0 }</h6>
                </div>

                {acknowledgements && acknowledgements.length > 0 && <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle sm:tw-px-6 lg:tw-px-8">
                    <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                    <thead>
                        <tr>
                        <th scope="col" className="tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 sm:tw-pl-0">
                            Employee Name
                        </th>
                        <th scope="col" className="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                            Acknowledgement Date
                        </th>
                        </tr>
                    </thead>
                    <tbody className="tw-divide-y tw-divide-gray-200">
                        {acknowledgements?.map((acknowledgement) => (
                        <tr key={acknowledgement.clientAnnouncementAcknowledgementID} >
                            <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-0">
                            {acknowledgement.employeeName}
                            </td>
                            <td className="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500">{moment(acknowledgement.acknowledgementDate).format("MM-DD-YY h:mm A")}</td>
                           
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>}
            </div>}
            </>}
        </div>
    </>);
}

export default ManageClientAnnouncementPage;