import { PencilIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import moment from "moment";
import { useLayoutEffect, useState } from "react";
import { insuranceSchema } from "../../pages/NewClientForm";
import PatientInsurance from "../../types/patientInsurance";
import { ButtonPrimary, ButtonSecondary, Input } from "@medforall/medforall-component-library";
import { LoadingSpinner } from "./loadingSpinner";
import { useAppContext } from "../../Context";
import { updateClientInsurance } from "../../apicalls/clientMgmtService";

export const EditClientInsuranceCard = (props: any) => {
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const appContext = useAppContext();

    useLayoutEffect(() => {
        console.log(props.insurance);
    }, [props.insurance])

    const toggleEditMode = () => {

        setEditMode(!editMode);
    }

    const initialValues = {
        insuranceType: props?.insurance?.insuranceType,
        medicaidNumber: props?.insurance?.medicaidID,
        medicaidEffectiveDate: new Date(moment(props?.insurance?.medicaidEffectiveDate).format("MM-DD-YYYY")).toISOString().substring(0,10) || '',
        doddNumber: props?.insurance?.doddNumber,
    }

    const insuranceInfoFormik = useFormik({
        initialValues: initialValues,
        validationSchema: insuranceSchema,
        onSubmit: async (values) => {
            // handle form submission
            console.log("Insurance Form submitted")
            console.log(values);

            const newInsurance: PatientInsurance = {
                insuranceType: values.insuranceType,
                medicaidID: values.medicaidNumber,
                medicaidEffectiveDate: new Date(values.medicaidEffectiveDate),
                doddNumber: values.doddNumber,
                patientID: props.insurance.patientID,
                patientInsuranceID: props.insurance.patientInsuranceID
            }

            setLoading(true);
            var result = await updateClientInsurance(props.insurance.patientID, newInsurance, appContext.handleAPICallError);
            setLoading(false);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Client Info Updated Successfully");
                appContext.setShowSuccessModal(true);
            }
            props?.refreshClient();
            

            
        },
        enableReinitialize: true
    });
    
    return (<>
        {/* <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6"> */}
            {editMode ? <>
                <div className="tw-pb-5 tw-flex tw-px-4">
                    <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Insurance</span>
                </div>

                <div className="tw-space-y-6 tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Insurance Type
                                    </label>
                                    <select
                                        id="insuranceType"
                                        name="insuranceType"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${insuranceInfoFormik.touched.insuranceType && insuranceInfoFormik.errors.insuranceType ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...insuranceInfoFormik.getFieldProps('insuranceType')}
                                    >
                                        <option value="">Select Insurance Type</option>
                                        <option value="Medicaid">Medicaid</option>
                                        <option value="County Funded">County Funded</option>
                                        <option value="Private">Private</option>
                                    </select>
                                    {insuranceInfoFormik.touched.insuranceType && insuranceInfoFormik.errors.insuranceType && <div className="tw-text-red-500 tw-text-sm tw-mt-1"><>{insuranceInfoFormik.errors.insuranceType}</></div>}

                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Medicaid Number
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="medicaidNumber"
                                        id="medicalNumber"
                                        className={`tw-block tw-w-full tw-rounded-md ${insuranceInfoFormik.touched.medicaidNumber && insuranceInfoFormik.errors.medicaidNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...insuranceInfoFormik.getFieldProps('medicaidNumber')}
                                        />
                                        {insuranceInfoFormik.touched.medicaidNumber && insuranceInfoFormik.errors.medicaidNumber && <div className="tw-text-red-500 tw-text-sm tw-mt-1"><>{insuranceInfoFormik.errors.medicaidNumber}</></div>}
                                    </div>
                                </div>

                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Medicaid Effective Date
                                    </label>
                                    <div className="tw-mt-2">
                                        <Input
                                        min="2000-01-01" max="2024-12-31"
                                        type="date"
                                        name="medicaidEffectiveDate"
                                        id="medicalEffectiveDate"
                                        className={`tw-block tw-w-full tw-rounded-md ${insuranceInfoFormik.touched.medicaidEffectiveDate && insuranceInfoFormik.errors.medicaidEffectiveDate ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...insuranceInfoFormik.getFieldProps('medicaidEffectiveDate')}
                                        />
                                        {insuranceInfoFormik.touched.medicaidEffectiveDate && insuranceInfoFormik.errors.medicaidEffectiveDate && <div className="tw-text-red-500 tw-text-sm tw-mt-1">{insuranceInfoFormik.errors.medicaidEffectiveDate}</div>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        DODD Number
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="doddNumber"
                                        id="doddNumber"
                                        className={`tw-block tw-w-full tw-rounded-md ${insuranceInfoFormik.touched.doddNumber && insuranceInfoFormik.errors.doddNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...insuranceInfoFormik.getFieldProps('doddNumber')}
                                        />
                                        {insuranceInfoFormik.touched.doddNumber && insuranceInfoFormik.errors.doddNumber && <div className="tw-text-red-500 tw-text-sm tw-mt-1"><>{insuranceInfoFormik.errors.doddNumber}</></div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleEditMode}>
                                                {(insuranceInfoFormik.dirty) ? 'Discard' : 'Cancel'}
                                            </ButtonSecondary>
                                            {(insuranceInfoFormik.dirty) && <ButtonPrimary className="tw-mt-md" onClick={insuranceInfoFormik.handleSubmit}>
                                                Update
                                            </ButtonPrimary>}

                        </div>
                        </>}


            </>:<>
                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Insurance</span>
                            <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={toggleEditMode} />
                        </div>

                        <div className="tw-bg-grey-light tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Insurance Type</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.insurance?.insuranceType}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Medicaid Number</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.insurance?.medicaidID}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Medicaid Effective Date</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{moment(props?.insurance?.medicaidEffectiveDate).format("MM-DD-YYYY")}</span>
                            </div>
                            <div className="tw-flex tw-px-4 tw-pb-2">
                                <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>DODD Number</span>
                                <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.insurance?.doddNumber}</span>
                            </div>
                        </div>
            </>}
        {/* </div> */}
    </>);
}