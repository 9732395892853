import { Block, ButtonPrimary, ButtonSecondary, DefaultButton, Span } from "@medforall/medforall-component-library";
import { useAppContext } from "../../Context";
import { createContext, useContext, useLayoutEffect, useState, useMemo, Dispatch, useEffect } from "react";
import { LabelOutputs } from "../../components/utilities/Helpers";
import AvatarImage from "../../components/avatars/AvatarImage";
//import LoadingItems from
import CoverDetailsPanel from "../../components/panels/CoverDetailsPanel";
import { useLocation, useParams } from "react-router-dom";
import { getDayName, getMonthName, getNumOfDayOfWeek } from "../../components/calendars/CalendarSelector";
import LoadingItems from "../../components/utilities/Loaders";
import { BreadCumbs, CoverageChangesBlock, DataBlock, DayBlock, SupportCoverageBlock, UserBlock } from "./SchedulingUtils";
import { ScheduleDayPanel } from "../../components/panels/SchedulePanel";
import { getClientActiveISPByID, getClientByID, getClientHomes, getClientServiceType, getSpanRequirementForPatient, updateCoverageSpanRequirement, updateSpanRequirementDataModel } from "../../apicalls/schedulingService";
import moment from "moment";
import ClientHome from "../../types/clientHome";
import { set } from "lodash";
import { time } from "console";
import SpanRequirement from "../../types/spanRequirement";

const SpanRequirementContext = createContext({
    sundayRequirements: [],
	setSundayRequirements: (sundayRequirements: any) => {},
	mondayRequirements: [],
	setMondayRequirements: (mondayRequirements: any) => {},
	tuesdayRequirements: [],
	setTuesdayRequirements: (tuesdayRequirements: any) => {},
	wednesdayRequirements: [],
	setWednesdayRequirements: (wednesdayRequirements: any) => {},
	thursdayRequirements: [],
	setThursdayRequirements: (thursdayRequirements: any) => {},
	fridayRequirements: [],
	setFridayRequirements: (fridayRequirements: any) => {},
	saturdayRequirements: [],
	setSaturdayRequirements: (saturdayRequirements: any) => {},
	planStartDate: "",
	setPlanStartDate: (planStartDate: any) => {},
	planEndDate: "",
	setPlanEndDate: (planEndDate: any) => {},
	clientServiceTypes: [],
	setClientServiceTypes: (clientServiceTypes: any) => {},
	requirementUpdated: false,
	setRequirementUpdated: (requirementUpdated: any) => {},
});

export const useSpanRequirementContext = () => useContext(SpanRequirementContext);

const ClientSpanRequirementPage = props => {
	const navContext = useAppContext();
	const { patientID } = useParams();
	const [loading, setLoading] = useState<boolean>(true);
	let { changes } = useParams();
	const [isChangeView, setIsChangeView] = useState<boolean>(false);

	// start demo code
	const [noEntries, setNoEntries] = useState<boolean>(false);
	// end demo code

	const [clientServiceTypes, setClientServiceTypes] = useState([]);

	const [clientHomes, setClientHomes] = useState<ClientHome[]>([]);

	const [clientDetails, setClientDetails] = useState(null);

    const [clientServicePlan, setClientServicePlan] = useState(null);

    const [spanRequirement, setSpanRequirement] = useState(null);

    const [spanRequirementAvailable, setSpanRequirementAvailable] = useState(false);

	const [servicePlanAvailable, setServicePlanAvailable] = useState(false);

    const [serviceTypeAvailable, setServiceTypeAvailable] = useState(false);

	const [values, setValues] = useState<any>({
		"start_date": "",
		"end_date": "",
	});

	const [sundayRequirements, setSundayRequirements] = useState([]);
	const [mondayRequirements, setMondayRequirements] = useState([]);
	const [tuesdayRequirements, setTuesdayRequirements] = useState([]);
	const [wednesdayRequirements, setWednesdayRequirements] = useState([]);
	const [thursdayRequirements, setThursdayRequirements] = useState([]);
	const [fridayRequirements, setFridayRequirements] = useState([]);
	const [saturdayRequirements, setSaturdayRequirements] = useState([]);
	const [planStartDate, setPlanStartDate] = useState("");
	const [planEndDate, setPlanEndDate] = useState("");
	const [scheduleURL, setScheduleURL] = useState<any>("");
	const [requirementUpdated, setRequirementUpdated] = useState(false);

	const { state } = useLocation();

	const value = useMemo(() => ({
		sundayRequirements,
		setSundayRequirements,
		mondayRequirements,
		setMondayRequirements,
		tuesdayRequirements,
		setTuesdayRequirements,
		wednesdayRequirements,
		setWednesdayRequirements,
		thursdayRequirements,
		setThursdayRequirements,
		fridayRequirements,
		setFridayRequirements,
		saturdayRequirements,
		setSaturdayRequirements,
		planStartDate,
		setPlanStartDate,
		planEndDate,
		setPlanEndDate,
		clientServiceTypes,
		setClientServiceTypes,
		requirementUpdated,
		setRequirementUpdated,

	}), [clientServiceTypes, requirementUpdated, planStartDate, planEndDate, sundayRequirements, mondayRequirements, tuesdayRequirements, wednesdayRequirements, thursdayRequirements, fridayRequirements, saturdayRequirements]);


	useLayoutEffect(() => {
		// start temp code for demoing none
		const queryParams = new URLSearchParams(window.location.search)
		const noEntriesParam = queryParams.get("noEntries") || null;

		if (noEntriesParam) setNoEntries((noEntriesParam === "true"));
		//   end temp code for demoing none

		// navContext.setName({firstName: 'Kevin', lastName: 'Mack'})

		// setTimeout(() => {
		// 	setLoading(false);
		// }, 2000);

	}, [])

	useLayoutEffect(() => {
		console.log('changes', changes);
		if (changes) setIsChangeView(true)
	}, [changes])

	useEffect(() => {

		setScheduleURL(`/schedule/schedule-mgmt/${patientID}/weekly-schedule`);

		const fetchServiceTypes = async () => {
			const serviceTypes = await getClientServiceType(patientID, navContext.handleAPICallError);

            console.log("Service Types:");
			console.log(serviceTypes);

			setClientServiceTypes(serviceTypes);

            if(serviceTypes === null){
                setServiceTypeAvailable(false);
            } else {
                setServiceTypeAvailable(true);
            }
		}

		const fetchClientHomes = async () => {
			const clientHomes = await getClientHomes(patientID, navContext.handleAPICallError);

			console.log(clientHomes);

			setClientHomes(clientHomes);
		}

		const fetchClientDetails = async () => {
			const clientDetails = await getClientByID(patientID, navContext.handleAPICallError);

			console.log(clientDetails);

			setClientDetails(clientDetails);
		}

        const fetchClientServicePlan = async () => {
			const servicePlan = await getClientActiveISPByID(patientID, navContext.handleAPICallError)

            console.log("Service Plan:");
			console.log(servicePlan);

			setClientServicePlan(servicePlan);

            if(servicePlan.status === "Unavailable"){
                setServicePlanAvailable(false);
            } else {
                setServicePlanAvailable(true);
                setValues({...values, "start_date": moment.utc(servicePlan.startDate).format("YYYY-MM-DD"), "end_date": moment.utc(servicePlan.endDate).format("YYYY-MM-DD") });
				setPlanStartDate(moment.utc(servicePlan.startDate).format("YYYY-MM-DD"));
				setPlanEndDate(moment.utc(servicePlan.endDate).format("YYYY-MM-DD"));
            }

            
		}

        const fetchSpanRequirement = async () => {
			await fetchClientDetails();
			await fetchClientServicePlan();
			await fetchServiceTypes();
			await fetchClientHomes();

			const requirement = await getSpanRequirementForPatient(patientID, navContext.handleAPICallError)

			console.log(requirement);

			setSpanRequirement(requirement);

            var backupHours = 0.0;
			var withoutBackupHours = 0.0;

            if (requirement.spanRequirementID.length !== 0) {
                setSpanRequirementAvailable(true);

                if (requirement.sundayRequirements !== null){
                    setSundayRequirements(JSON.parse(JSON.stringify(requirement.sundayRequirements)));
                }
                if (requirement.mondayRequirements !== null){
                    setMondayRequirements(JSON.parse(JSON.stringify(requirement.mondayRequirements)));
                }
                if (requirement.tuesdayRequirements !== null){
                    setTuesdayRequirements(JSON.parse(JSON.stringify(requirement.tuesdayRequirements)));
                }
                if (requirement.wednesdayRequirements !== null){
                    setWednesdayRequirements(JSON.parse(JSON.stringify(requirement.wednesdayRequirements)));
                }
                if(requirement.thursdayRequirements !== null){
                    setThursdayRequirements(JSON.parse(JSON.stringify(requirement.thursdayRequirements)));
                }
                if(requirement.fridayRequirements !== null){
                    setFridayRequirements(JSON.parse(JSON.stringify(requirement.fridayRequirements)));           
                }
                if(requirement.saturdayRequirements !== null){
                    setSaturdayRequirements(JSON.parse(JSON.stringify(requirement.saturdayRequirements)));       
                }

                
                
                
            }

            //setRequirementLoading(false);
			setLoading(false);

		}

		// fetchServiceTypes()
		// 	.catch(console.error);

		// fetchClientHomes()
		// 	.catch(console.error);

		// fetchClientDetails()
		// 	.catch(console.error);

        // fetchClientServicePlan()
        //     .catch(console.error);

        fetchSpanRequirement()
            .catch(console.error);
	}, [patientID])

	const discardSpanRequirementChanges = () => {
		console.log(spanRequirement);

		if (spanRequirement.sundayRequirements !== null){
			setSundayRequirements(JSON.parse(JSON.stringify(spanRequirement.sundayRequirements)));
		} else {
			setSundayRequirements([]);
		}
		if (spanRequirement.mondayRequirements !== null){
			setMondayRequirements(JSON.parse(JSON.stringify(spanRequirement.mondayRequirements)));
		} else {
			setMondayRequirements([]);
		}
		if (spanRequirement.tuesdayRequirements !== null){
			setTuesdayRequirements(JSON.parse(JSON.stringify(spanRequirement.tuesdayRequirements)));
		} else {
			setTuesdayRequirements([]);
		}
		if (spanRequirement.wednesdayRequirements !== null){
			setWednesdayRequirements(JSON.parse(JSON.stringify(spanRequirement.wednesdayRequirements)));
		} else {
			setWednesdayRequirements([]);
		}
		if(spanRequirement.thursdayRequirements !== null){
			setThursdayRequirements(JSON.parse(JSON.stringify(spanRequirement.thursdayRequirements)));
		} else {
			setThursdayRequirements([]);
		}
		if(spanRequirement.fridayRequirements !== null){
			setFridayRequirements(JSON.parse(JSON.stringify(spanRequirement.fridayRequirements)));
		} else {
			setFridayRequirements([]);
		}
		if(spanRequirement.saturdayRequirements !== null){
			setSaturdayRequirements(JSON.parse(JSON.stringify(spanRequirement.saturdayRequirements)));
		} else {
			setSaturdayRequirements([]);
		}

		setRequirementUpdated(false);
	}

	const saveSpanRequirementChanges = async () => {
		setLoading(true);

		setRequirementUpdated(false);

		await updateCoverageRequirement();

		setLoading(false);

		if(!navContext.showErrorModal){
			navContext.setModalMessageHeader("Success");
			navContext.setModalMessageBody("Client Span Requirement updated successfully.");
			navContext.setShowSuccessModal(true);
		}

	}

	const updatePatientSpanRequirementDataModel = async () => {
		setLoading(true);

		setRequirementUpdated(false);

		const response = await updateSpanRequirementDataModel(patientID, navContext.handleAPICallError);

		setLoading(false);

		if(!navContext.showErrorModal){
			navContext.setModalMessageHeader("Success");
			navContext.setModalMessageBody("Client Span Requirement updated successfully.");
			navContext.setShowSuccessModal(true);
		}

	}

	const updateCoverageRequirement = async () => {

        var StartDate = moment(spanRequirement.displayStartDate);
        var EndDate = moment(spanRequirement.displayEndDate);

        let sundayMap = new Map();
        let mondayMap = new Map();
        let tuesdayMap = new Map();
        let wednesdayMap = new Map();
        let thursdayMap = new Map();
        let fridayMap = new Map();
        let saturdayMap = new Map();

        clientServiceTypes.forEach( (serviceType) => {
            sundayMap.set(serviceType.billingCode, 0.0);
            mondayMap.set(serviceType.billingCode, 0.0);
            tuesdayMap.set(serviceType.billingCode, 0.0);
            wednesdayMap.set(serviceType.billingCode, 0.0);
            thursdayMap.set(serviceType.billingCode, 0.0);
            fridayMap.set(serviceType.billingCode, 0.0);
            saturdayMap.set(serviceType.billingCode, 0.0);
        });

        sundayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            sundayMap.set(requirement.billingCode, sundayMap.get(requirement.billingCode) + hours);
        });

        mondayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            mondayMap.set(requirement.billingCode, mondayMap.get(requirement.billingCode) + hours);
        });

        tuesdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            tuesdayMap.set(requirement.billingCode, tuesdayMap.get(requirement.billingCode) + hours);
        });

        wednesdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            wednesdayMap.set(requirement.billingCode, wednesdayMap.get(requirement.billingCode) + hours);
        });

        thursdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            thursdayMap.set(requirement.billingCode, thursdayMap.get(requirement.billingCode) + hours);
        });

        fridayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            fridayMap.set(requirement.billingCode, fridayMap.get(requirement.billingCode) + hours);
        });

        saturdayRequirements.forEach((requirement) => {
            var duration = moment.duration(moment(requirement.endTime).diff(moment(requirement.startTime)));
            var hours = duration.asHours();

            const startTime = moment(spanRequirement.displayStartDate + " " + requirement.displayStartTime)
            const endTime = moment(spanRequirement.displayStartDate + " " + requirement.displayEndTime)

            requirement.StartTime = startTime.format();
            requirement.EndTime = endTime.format();

            saturdayMap.set(requirement.billingCode, saturdayMap.get(requirement.billingCode) + hours);
        });

        console.log(sundayMap);
        console.log(mondayMap);
        console.log(tuesdayMap);
        console.log(wednesdayMap);
        console.log(thursdayMap);
        console.log(fridayMap);
        console.log(saturdayMap);


        let coverageRequirement : SpanRequirement = {
            spanRequirementID: spanRequirement.spanRequirementID,
            patientID: patientID,
            planID: clientServicePlan.planID,
            startDate: StartDate.format(),
            displayStartDate: "",
            endDate: EndDate.format(),
            displayEndDate: "",
            sundayRequirements: sundayRequirements,
            mondayRequirements: mondayRequirements,
            tuesdayRequirements: tuesdayRequirements,
            wednesdayRequirements: wednesdayRequirements,
            thursdayRequirements: thursdayRequirements,
            fridayRequirements: fridayRequirements,
            saturdayRequirements: saturdayRequirements
        }

        console.log(coverageRequirement);

        const response = await updateCoverageSpanRequirement(coverageRequirement, navContext.handleAPICallError);

        //navigate("/ClientRequirement/"+ clientDetails.patientID +"/Requirement");
    }

	const diff_hours = (dt2, dt1) => 
	{
		var duration = moment.duration(moment(dt2).diff(moment(dt1)));
            var hours = duration.asHours();
            // console.log(requirement);
            // console.log(hours);
            // console.log(hours < 0);
			if(hours > 24){
				console.log("hours > 24");
				console.log(hours);
				console.log(dt2);
				console.log(dt1);
				return hours % 24;
			}

            if (hours <= 0) {
                return hours + 24;
            } else {
                return hours;
            }
	
	}

	// useEffect(() => {
	// 	if(!fridayRequirements || fridayRequirements.length === 0) return;

	// 	const sortedFridayRequirements = [...fridayRequirements].sort((a, b) => {
	// 		const timeA = new Date(`1970-01-01T${a.displayStartTime}`);
	// 		const timeB = new Date(`1970-01-01T${b.displayStartTime}`);
	// 		return timeA.getTime() - timeB.getTime();
	// 	});

	// 	console.log(sortedFridayRequirements);

	// 	setFridayRequirements(sortedFridayRequirements);
	// }, [fridayRequirements]);


	return  <SpanRequirementContext.Provider value={value}>
	{loading ? <>
		<LoadingItems />
	</> : <>
		<div className="tw-w-full tw-px-40px">
			<div className="tw-h-vh tw-flex tw-flex-col tw-flex-grow tw-w-full">
				<div className="tw-space-y-20px tw-py-40px ">
					<BreadCumbs name={{
						firstName: clientDetails?.firstName,
						lastName: clientDetails?.lastName,
					}}
					started={clientServicePlan?.displayStartDate}
					ended={clientServicePlan?.displayEndDate}
					url={`#`}
					changes={ isChangeView === true ? true : null }
					scheduleURL={scheduleURL}
					scheduleName={"Requirement Calendar"}
					/>
				</div>
				{ isChangeView ? <>
					<div className="tw-flex tw-w-full tw-justify-between tw-space-x-20px tw-pb-10px">
						<h3 className="tw-text-3 tw-text-dark">
							Coverage Requirements Changes
						</h3>
					</div>
					<CoverageChangesBlock />
				</> : <>
					<div className="tw-flex tw-flex-col tw-flex-grow tw-w-full">
						<div className="tw-flex tw-space-x-40px tw-pb-20px">
							<div className="tw-flex-grow tw-basis-4/12 tw-max-w-250px">
								<UserBlock name={{
									firstName: clientDetails?.firstName,
									lastName: clientDetails?.lastName,
								}}
								started={clientServicePlan?.displayStartDate}
								ended={clientServicePlan?.displayEndDate}
								client={clientDetails}
								plan={clientServicePlan}
								homes={clientHomes}
								requirement={spanRequirement}
								/>
							</div>
							<div className="tw-flex-grow tw-basis-4/12">
								<SupportCoverageBlock className="tw-w-full" type="Remote Support" total="140"  utilized="80" projected="135" />
							</div>
							<div className="tw-flex-grow tw-basis-4/12">
								<SupportCoverageBlock className="tw-w-full" type="Direct Support" total="140" utilized="80" projected="120" />
							</div>
						</div>
						<div className="tw-flex tw-w-full tw-justify-between tw-space-x-20px tw-pb-10px">
							<h3 className="tw-text-3 tw-text-dark">
								Coverage Requirements
							</h3>
							{/* <a href="/schedule/schedule-coverage/04182023" className="tw-text-dark hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px">
								<LabelOutputs className="tw-text-in" icon="/images/icon-arrow-right.svg" reverse>
									View Changes
								</LabelOutputs>
							</a> */}
						</div>
						<div className="tw-flex tw-w-full tw-justify-end tw-space-x-20px tw-pb-10px">
							{requirementUpdated && <ButtonSecondary  className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
								e.preventDefault();
								discardSpanRequirementChanges();
								//handleDiscard();
							}}>
								Discard
							</ButtonSecondary>}
							<ButtonPrimary disabled={!requirementUpdated} className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
								e.preventDefault();
								saveSpanRequirementChanges();
								//handleSave();
							}}>
								Save Changes
							</ButtonPrimary>

							{/* <ButtonPrimary className="tw-flex  tw-justify-center tw-min-w-120px" onClick={ e => {
								e.preventDefault();
								updatePatientSpanRequirementDataModel();
								//handleSave();
							}}>
								Update Data Model
							</ButtonPrimary> */}

						</div>
						{ noEntries ? <>
							<div className="tw-w-full tw-flex-grow tw-flex tw-flex-col">
								<div className="tw-flex-shrhink-0 tw-grid tw-grid-cols-7 tw-space-x-20px tw-w-full tw-overflow-hidden">
									<DayBlock className="tw-w-full" day="Sunday" serviceTypes={clientServiceTypes} patientID={patientID}>
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Monday" serviceTypes={clientServiceTypes} patientID={patientID}>
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Tuesday" serviceTypes={clientServiceTypes} patientID={patientID}>
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Wednesday" serviceTypes={clientServiceTypes} patientID={patientID}>
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Thursday" serviceTypes={clientServiceTypes} patientID={patientID}>
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Friday" serviceTypes={clientServiceTypes} patientID={patientID}>
									</DayBlock>

									<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Saturday" serviceTypes={clientServiceTypes} patientID={patientID}>
									</DayBlock>
								</div>

								{navContext.scheduleAdmin && <div className="tw-flex-grow tw-w-full tw-flex tw-justify-center tw-items-center tw-py-20px">
									<div role="button" className={`tw-text-4 tw-rounded-full tw-w-300px tw-h-300px tw-aspect-square tw-inline-flex tw-space-y-5px tw-py-10px tw-px-5px tw-flex-col tw-justify-center tw-items-center tw-cursor-pointer  tw-bg-[#E5E5E5] tw-text-dark`} onClick={ e => {
											e.preventDefault();
											navContext.setSideNavTheme(3);
											navContext.setPanelSize('tw-max-w-400px');
											navContext.setSideNav( <ScheduleDayPanel day={ props.day } /> );
											navContext.openSideNav();
										}}>
										<span className="tw-w-1/4 tw-mx-auto tw-aspect-square">
											<img className="tw-w-full" src="/images/icon-date.svg" />
										</span>
										<b className="tw-leading-none tw-block tw-text-center">
											No appointments
										</b>
										<span className="tw-text-6 tw-w-full tw-text-center tw-leading-tight">
											Use the &quot;Add&quot; button <Block>to add new requirements</Block>
										</span>
									</div>
								</div>}
							</div>

						</> : <>
							<div className="tw-flex-grow tw-grid tw-grid-cols-7 tw-space-x-20px tw-w-full tw-overflow-hidden">
								<DayBlock className="tw-w-full" day="Sunday" serviceTypes={clientServiceTypes} hours={sundayRequirements.reduce((acc, curr) => acc + diff_hours(curr.endTime, curr.startTime), 0)}>
									{sundayRequirements
										.sort((a, b) => {
											const timeA = new Date('1970/01/01 ' + a.displayStartTime); 
											const timeB = new Date('1970/01/01 ' + b.displayStartTime);
											if(timeA.getHours() < 12 && timeB.getHours() >= 12) return -1; 
											if(timeA.getHours() >= 12 && timeB.getHours() < 12) return 1;
											return timeA.getTime() - timeB.getTime();
										})
										.map( ( requirement, index ) => {
										return <DataBlock key={index} billingCode={requirement.billingCode} type={requirement.shiftType} time={requirement.displayStartTime + "-" + requirement.displayEndTime} onClick={ e => {
											navContext.setSideNavTheme(3);
											navContext.setPanelSize('tw-max-w-400px');
											navContext.setSideNav( <CoverDetailsPanel requirement={requirement} serviceTypes={clientServiceTypes} data={value}/> );
											navContext.openSideNav();
											navContext.setSideNavBottom(null);
										}} />;
									}
									)}
									{sundayRequirements.length === 0 && <DataBlock none serviceTypes={ clientServiceTypes || null} day="Sunday" requirement="span" />}
									{/* <DataBlock type="Remote Support" time="8am - 2pm" alert  onClick={ e => {
										navContext.setSideNav( <CoverDetailsPanel /> );
										navContext.openSideNav();
									}} />
									<DataBlock type="Direct Support" time="2pm - 4pm" />
									<DataBlock type="Remote Support" time="4pm - 5pm" /> */}
								</DayBlock>

								<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Monday" serviceTypes={clientServiceTypes} hours={mondayRequirements.reduce((acc, curr) => acc + diff_hours(curr.endTime, curr.startTime), 0)}>
									{mondayRequirements
										.sort((a, b) => {
											const timeA = new Date('1970/01/01 ' + a.displayStartTime); 
											const timeB = new Date('1970/01/01 ' + b.displayStartTime);
											if(timeA.getHours() < 12 && timeB.getHours() >= 12) return -1; 
											if(timeA.getHours() >= 12 && timeB.getHours() < 12) return 1;
											return timeA.getTime() - timeB.getTime();
										})
										.map( ( requirement, index ) => {
										return <DataBlock key={index} billingCode={requirement.billingCode} type={requirement.shiftType} time={requirement.displayStartTime + "-" + requirement.displayEndTime} onClick={ e => {
											navContext.setSideNavTheme(3);
											navContext.setPanelSize('tw-max-w-400px');
											navContext.setSideNav( <CoverDetailsPanel requirement={requirement} serviceTypes={clientServiceTypes} data={value}/> );
											navContext.openSideNav();
											navContext.setSideNavBottom(null);
										}} />;
									}
									)}
									{mondayRequirements.length === 0 && <DataBlock none serviceTypes={ clientServiceTypes || null} day="Monday" requirement="span"/>}
									{/* <DataBlock none day="Monday" /> */}
								</DayBlock>

								<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Tuesday" serviceTypes={clientServiceTypes} hours={tuesdayRequirements.reduce((acc, curr) => acc + diff_hours(curr.endTime, curr.startTime), 0)}>
									{/* <DataBlock type="Remote Support" time="8am - 2pm" alert  onClick={ e => {
										navContext.setSideNav( <CoverDetailsPanel /> );
										navContext.openSideNav();
									}}/>
									<DataBlock type="Direct Support" time="2pm - 4pm" /> */}
									{tuesdayRequirements
										.sort((a, b) => {
											const timeA = new Date('1970/01/01 ' + a.displayStartTime); 
											const timeB = new Date('1970/01/01 ' + b.displayStartTime);
											if(timeA.getHours() < 12 && timeB.getHours() >= 12) return -1; 
											if(timeA.getHours() >= 12 && timeB.getHours() < 12) return 1;
											return timeA.getTime() - timeB.getTime();
										})
										.map( ( requirement, index ) => {
										return <DataBlock key={index} billingCode={requirement.billingCode} type={requirement.shiftType} time={requirement.displayStartTime + "-" + requirement.displayEndTime} onClick={ e => {
											navContext.setSideNavTheme(3);
											navContext.setPanelSize('tw-max-w-400px');
											navContext.setSideNav( <CoverDetailsPanel requirement={requirement} serviceTypes={clientServiceTypes} data={value}/> );
											navContext.openSideNav();
											navContext.setSideNavBottom(null);
										}} />;
									}
									)}
									{tuesdayRequirements.length === 0 && <DataBlock none serviceTypes={ clientServiceTypes || null} day="Tuesday" requirement="span"/>}
								</DayBlock>

								<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Wednesday" serviceTypes={clientServiceTypes} hours={wednesdayRequirements.reduce((acc, curr) => acc + diff_hours(curr.endTime, curr.startTime), 0)}>
									{/* <DataBlock none day="Wednesday" /> */}
									{wednesdayRequirements
										.sort((a, b) => {
											const timeA = new Date('1970/01/01 ' + a.displayStartTime); 
											const timeB = new Date('1970/01/01 ' + b.displayStartTime);
											if(timeA.getHours() < 12 && timeB.getHours() >= 12) return -1; 
											if(timeA.getHours() >= 12 && timeB.getHours() < 12) return 1;
											return timeA.getTime() - timeB.getTime();
										})
										.map( ( requirement, index ) => {
										return <DataBlock key={index} billingCode={requirement.billingCode} type={requirement.shiftType} time={requirement.displayStartTime + "-" + requirement.displayEndTime} onClick={ e => {
											navContext.setSideNavTheme(3);
											navContext.setPanelSize('tw-max-w-400px');
											navContext.setSideNav( <CoverDetailsPanel requirement={requirement} serviceTypes={clientServiceTypes} data={value}/> );
											navContext.openSideNav();
											navContext.setSideNavBottom(null);
										}} />;
									}
									)}
									{wednesdayRequirements.length === 0 && <DataBlock none serviceTypes={ clientServiceTypes || null} day="Wednesday" requirement="span"/>}
								</DayBlock>

								<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Thursday" serviceTypes={clientServiceTypes} hours={thursdayRequirements.reduce((acc, curr) => acc + diff_hours(curr.endTime, curr.startTime), 0)}>
									{/* <DataBlock none day="Thursday" /> */}
									{thursdayRequirements
										.sort((a, b) => {
											const timeA = new Date('1970/01/01 ' + a.displayStartTime); 
											const timeB = new Date('1970/01/01 ' + b.displayStartTime);
											if(timeA.getHours() < 12 && timeB.getHours() >= 12) return -1; 
											if(timeA.getHours() >= 12 && timeB.getHours() < 12) return 1;
											return timeA.getTime() - timeB.getTime();
										})
										.map( ( requirement, index ) => {
										return <DataBlock key={index} billingCode={requirement.billingCode} type={requirement.shiftType} time={requirement.displayStartTime + "-" + requirement.displayEndTime} onClick={ e => {
											navContext.setSideNavTheme(3);
											navContext.setPanelSize('tw-max-w-400px');
											navContext.setSideNav( <CoverDetailsPanel requirement={requirement} serviceTypes={clientServiceTypes} data={value}/> );
											navContext.openSideNav();
											navContext.setSideNavBottom(null);
										}} />;
									}
									)}
									{thursdayRequirements.length === 0 && <DataBlock none serviceTypes={ clientServiceTypes || null} day="Thursday" requirement="span"/>}
								</DayBlock>

								<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Friday" serviceTypes={clientServiceTypes} hours={fridayRequirements.reduce((acc, curr) => acc + diff_hours(curr.endTime, curr.startTime), 0)}>
									{/* <DataBlock none day="Friday"/> */}
									{fridayRequirements
									.sort((a, b) => {
										const timeA = new Date('1970/01/01 ' + a.displayStartTime); 
										const timeB = new Date('1970/01/01 ' + b.displayStartTime);
										if(timeA.getHours() < 12 && timeB.getHours() >= 12) return -1; 
										if(timeA.getHours() >= 12 && timeB.getHours() < 12) return 1;
										return timeA.getTime() - timeB.getTime();
									})
									.map( ( requirement, index ) => {
										return <DataBlock key={index} billingCode={requirement.billingCode} type={requirement.shiftType} time={requirement.displayStartTime + "-" + requirement.displayEndTime} onClick={ e => {
											navContext.setSideNavTheme(3);
											navContext.setPanelSize('tw-max-w-400px');
											navContext.setSideNav( <CoverDetailsPanel requirement={requirement} serviceTypes={clientServiceTypes} data={value}/> );
											navContext.openSideNav();
											navContext.setSideNavBottom(null);
										}} />;
									}
									)}
									{fridayRequirements.length === 0 && <DataBlock none serviceTypes={ clientServiceTypes || null} day="Friday" requirement="span"/>}
								</DayBlock>

								<DayBlock className="tw-basis-[calc((1/7)*100%)] tw-flex-grow tw-flex-shrink-0" day="Saturday" serviceTypes={clientServiceTypes} hours={saturdayRequirements.reduce((acc, curr) => acc + diff_hours(curr.endTime, curr.startTime), 0)}>
									{/* <DataBlock none day="Saturday" /> */}
									{saturdayRequirements
										.sort((a, b) => {
											const timeA = new Date('1970/01/01 ' + a.displayStartTime); 
											const timeB = new Date('1970/01/01 ' + b.displayStartTime);
											if(timeA.getHours() < 12 && timeB.getHours() >= 12) return -1; 
											if(timeA.getHours() >= 12 && timeB.getHours() < 12) return 1;
											return timeA.getTime() - timeB.getTime();
										})
										.map( ( requirement, index ) => {
										return <DataBlock key={index} billingCode={requirement.billingCode} type={requirement.shiftType} time={requirement.displayStartTime + "-" + requirement.displayEndTime} onClick={ e => {
											navContext.setSideNavTheme(3);
											navContext.setPanelSize('tw-max-w-400px');
											navContext.setSideNav( <CoverDetailsPanel requirement={requirement} serviceTypes={clientServiceTypes} data={value}/> );
											navContext.openSideNav();
											navContext.setSideNavBottom(null);
										}} />;
									}
									)}
									{saturdayRequirements.length === 0 && <DataBlock none serviceTypes={ clientServiceTypes || null} day="Saturday" requirement="span"/>}
								</DayBlock>
							</div>
						</> }
					</div>
				</> }
			</div>
		</div>
	</>}
	</SpanRequirementContext.Provider>
}

export default ClientSpanRequirementPage;
