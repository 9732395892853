import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getActiveClients, getSpanRequirementForPatient } from "../apicalls/schedulingService"
import Button, { ButtonAnchor, ButtonPrimary } from "../components/buttons/Buttons"
import Calendar from "../components/containers/Calendars"
import { ClientDetailsCard, ClientInfoCard, ContactCard } from "../components/containers/Cards"
import { Search } from "../components/forms/formFields"
import { PageHeader, PageSubHeader, Paragraph3 } from "../components/typography/Headers"
import { Ellipsis } from "../components/utilities/Helpers"
import Core from "../templates/Core"
import Patient from "../types/patient"
import { rem } from "../utilities/helpers"
import { articleHeader } from "../utilities/typography"
import { useAppContext } from "../Context"

const ClientRequirementPage = props => {
    const [showClientList, setShowClientList] = useState(true);
    const [selectedClient, setSelectedClient] = useState<Patient>(null);
    const [activePatients, setActivePatients] = useState<Patient[]>([]);
    const [allPatients, setAllPatients] = useState<Patient[]>([]);
    const [clientsLoading, setClientsLoading] = useState(true);

    let navigate = useNavigate();
    const appContext = useAppContext();

    useEffect(() => {
		const fetchClients = async () => {
			const data = await getActiveClients(appContext.handleAPICallError);
			console.log(data);
            const activePatients = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName));
			setActivePatients(activePatients);
            setAllPatients(activePatients);
			setClientsLoading(false);
		}

		fetchClients()
    		.catch(console.error);
	}, []);

    const goToClientCoverageForm = () => {
		navigate("/ClientSchedule/"+ selectedClient.patientID +"/New");
	};

    const showClientRequirement = async (patient) => {
        console.log(patient.lastName + ", " + patient.firstName);

        setSelectedClient(patient);

        const requirement = await getSpanRequirementForPatient(patient.patientID, appContext.handleAPICallError)

		console.log(requirement);

        if(requirement.spanRequirementID.length !== 0){
            //navigate("/schedule/client-requirement/"+ patient.patientID +"/requirement");
            navigate("/schedule/schedule-mgmt/" + patient.patientID + "/weekly-schedule");
        } else {
            navigate("/schedule/client-requirement/"+ patient.patientID +"/requirement-form");
        }

        
    }

    const showClientSchedule = (patient) => {
        console.log(patient.lastName + ", " + patient.firstName);

        setSelectedClient(patient);

        setShowClientList(false);
    }

    const backToClientList = () => {
        console.log("Back To Client List");

        setShowClientList(true);

        setSelectedClient(null);
    } 

    return (<>
        {/* <Core { ...props }> */}
        <div className="tw-px-xl tw-py-sm">
				<div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
                    <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Client Requirements</h2>
                    </div>
					{/* <div className="tw-flex-grow tw-flex-shrink-0">
						<PageHeader>
							<Ellipsis>
								Client Requirements
							</Ellipsis>
						</PageHeader>
					</div> */}
                    { showClientList ? <></>:<>
                    <div className="tw-flex tw-items-center tw-justify-end tw-flex-grow tw-flex-shrink-0">
						<ul className="tw-list-none tw-flex tw-space-x-md">
							<li>
								<ButtonPrimary iconNotRounded onClick={goToClientCoverageForm} svg={`<svg width="${rem('24')}" height="${rem('24')}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM12 7C13.66 7 15 8.34 15 10C15 11.66 13.66 13 12 13C10.34 13 9 11.66 9 10C9 8.34 10.34 7 12 7ZM18 19H6V17.6C6 15.6 10 14.5 12 14.5C14 14.5 18 15.6 18 17.6V19Z" fill="currentColor"/></svg>`}>
									Add Shift
								</ButtonPrimary>
							</li>
						</ul>
					</div>
                    </>}
                  
				</div>
                { showClientList ? <></>:<>
                    <div className="tw-px-xl">
                        <ButtonAnchor iconNotRounded onClick={backToClientList} svg={`<svg width="${rem('12')}" height="${rem('18')}" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.115 15.885L4.245 9L11.115 2.115L9 0L0 9L9 18L11.115 15.885Z" fill="currentColor"/></svg>`}>
                            Back to Client List
                        </ButtonAnchor>
                    </div>
                </>}
                
		</div>
        <div className="tw-px-xl tw-pb-sm tw-text-dark">
            { showClientList ? <>
                <div className="tw-px-xl">
                    <div className="tw-space-y-sm">
                        {/* <div className="">
                            <PageSubHeader tag="h2" className="tw-sticky tw-top-0 tw-z-10 tw-bg-light-accent tw-py-sm ">
								Active Clients
							</PageSubHeader>
                        </div> */}

                        { clientsLoading ? 
                            <div className="tw-flex tw-justify-center tw-m-md">
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </div>
                            :
                            <>
                                <div className="tw-px-4 sm:tw-px-6 lg:tw-px-8">
                                    <div className="sm:tw-flex sm:tw-items-center tw-sticky tw-top-0 tw-z-10  tw-backdrop-blur tw-backdrop-filter tw-mb-2">
                                        <div className="sm:tw-flex-auto">
                                            <h1 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Active Clients</h1>
                                            <div className="tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm tw-mx-6">
                                                <input
                                                type="text"
                                                name="search-client"
                                                id="search-client"
                                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-4 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 placeholder:tw-pl-3 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                                placeholder="Search by Client Name..." onChange={ e => {

                                                    console.log(e?.target.value || "nope");
            
                                                    if(e?.target.value){
                                                        const searchText = e?.target.value.toString().toLowerCase();
                                                        const filtered = allPatients.filter(element => {
                                                            
                                                            // 👇️ using AND (&&) operator
                                                            return element.firstName.toLowerCase().includes(searchText) || element.lastName.toLowerCase().includes(searchText);
                                                        });
            
                                                        console.log(filtered)
            
                                                        setActivePatients(filtered);
                                                    } else {
                                                        setActivePatients(allPatients);
                                                    }
                                                }}
                                                />
                                                <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                                                    <MagnifyingGlassIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tw-mt-4 tw-flow-root">
                                        <div className="-tw-my-2 -tw-mx-4 tw-overflow-x-auto sm:-tw-mx-6 lg:-tw-mx-8">
                                        <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle sm:tw-px-6 lg:tw-px-8">
                                            <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                                            <thead className="">
                                                <tr>
                                                <th scope="col" className="tw-sticky tw-top-0 tw-z-20 tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                                                    Name
                                                </th>
                                                <th scope="col" className="tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                                                    Email
                                                </th>
                                                <th scope="col" className="tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                                                    County
                                                </th>
                                                <th scope="col" className="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4">
                                                    <span className="tw-sr-only">View</span>
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody className="tw-divide-y tw-divide-gray-200 tw-overflow-y-auto">
                                                {activePatients.map((patient) => (
                                                <tr key={patient.email} className="tw-cursor-pointer" onClick={ e => {
                                                    e.preventDefault();
                                                    showClientRequirement(patient);
                                                    //props?.toggleShowRightPanel();
                                                }}>
                                                    <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900">
                                                    {patient.firstName} {patient.lastName}
                                                    </td>
                                                    <td className="tw-whitespace-nowrap tw-py-4 tw-px-3 tw-text-sm tw-text-gray-500">{patient.email}</td>
                                                    <td className="tw-whitespace-nowrap tw-py-4 tw-px-3 tw-text-sm tw-text-gray-500">{patient.patientDetail.county}</td>
                                                    <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium">
                                                    <span className="tw-text-indigo-600 tw-cursor-pointer hover:tw-text-indigo-900" onClick={ e => {
                                                        e.preventDefault();
                                                        showClientRequirement(patient);
                                                        //props?.toggleShowRightPanel();
                                                    }}>
                                                        View<span className="tw-sr-only">, {patient.firstName} {patient.lastName}</span>
                                                    </span>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        
                    </div>
                </div>
            </>:<>
            </> }
            
        </div>
        {/* </Core> */}
    </>)
}

export default ClientRequirementPage