import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Context";
import Patient from "../../types/patient";
import { useEffect, useLayoutEffect, useState } from "react";
import { getActiveDSClients } from "../../apicalls/schedulingService";
import CircularProgress from "@mui/material/CircularProgress";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const DirectSupportClientsList = props => {
    let navigate = useNavigate();
    const appContext = useAppContext();
    const [selectedClient, setSelectedClient] = useState<Patient>(null);

    const [allPatients, setAllPatients] = useState<Patient[]>([]);
    const [activePatients, setActivePatients] = useState<Patient[]>([]);
    const [clientsLoading, setClientsLoading] = useState(true);

    useLayoutEffect(() => {
		const fetchClients = async () => {
			const data = await getActiveDSClients(appContext.handleAPICallError);
			console.log(data);
            const activePatients = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName));
			setActivePatients(activePatients);
            setAllPatients(activePatients);
			setClientsLoading(false);
		}

		fetchClients()
    		.catch(console.error);
	}, []);

    const showClientSchedule = async (patient) => {
        console.log(patient.lastName + ", " + patient.firstName);

        setSelectedClient(patient);
            
        navigate("/schedule/direct-support/" + patient.patientID + "/weekly-schedule");


        
    }
    
    return (<>
        <div className="tw-px-xl tw-py-sm">
				<div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
                    <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Direct Support Clients</h2>
                    </div>
				</div>
                
		</div>
        <div className="tw-px-xl tw-pb-sm tw-text-dark">
                <div className="tw-px-xl">
                    <div className="tw-space-y-sm">
                        {/* <div className="">
                            <PageSubHeader tag="h2" className="tw-sticky tw-top-0 tw-z-10 tw-bg-light-accent tw-py-sm ">
								Active Clients
							</PageSubHeader>
                        </div> */}

                        { clientsLoading ? 
                            <div className="tw-flex tw-justify-center tw-m-md">
                                <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                            </div>
                            :
                            <>
                                <div className="tw-px-4 sm:tw-px-6 lg:tw-px-8">
                                    <div className="sm:tw-flex sm:tw-items-center tw-sticky tw-top-0 tw-z-10  tw-backdrop-blur tw-backdrop-filter tw-mb-2">
                                        <div className="sm:tw-flex-auto">
                                            <h1 className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Active Clients</h1>
                                            <div className="tw-relative tw-mt-2 tw-rounded-md tw-shadow-sm tw-mx-6">
                                                <input
                                                type="text"
                                                name="search-client"
                                                id="search-client"
                                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-4 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 placeholder:tw-pl-3 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                                                placeholder="Search by Client Name..." onChange={ e => {

                                                    console.log(e?.target.value || "nope");
            
                                                    if(e?.target.value){
                                                        const searchText = e?.target.value.toString().toLowerCase();
                                                        const filtered = allPatients.filter(element => {
                                                            
                                                            // 👇️ using AND (&&) operator
                                                            return element.firstName.toLowerCase().includes(searchText) || element.lastName.toLowerCase().includes(searchText);
                                                        });
            
                                                        console.log(filtered)
            
                                                        setActivePatients(filtered);
                                                    } else {
                                                        setActivePatients(allPatients);
                                                    }
                                                }}
                                                />
                                                <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                                                    <MagnifyingGlassIcon className="tw-h-5 tw-w-5 tw-text-gray-400" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tw-mt-4 tw-flow-root">
                                        <div className="-tw-my-2 -tw-mx-4 tw-overflow-x-auto sm:-tw-mx-6 lg:-tw-mx-8">
                                        <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle sm:tw-px-6 lg:tw-px-8">
                                            <table className="tw-min-w-full tw-divide-y tw-divide-gray-300">
                                            <thead className="">
                                                <tr>
                                                <th scope="col" className="tw-sticky tw-top-0 tw-z-20 tw-py-3.5 tw-pl-4 tw-pr-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                                                    Name
                                                </th>
                                                <th scope="col" className="tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                                                    Email
                                                </th>
                                                <th scope="col" className="tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900">
                                                    County
                                                </th>
                                                <th scope="col" className="tw-relative tw-py-3.5 tw-pl-3 tw-pr-4">
                                                    <span className="tw-sr-only">View</span>
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody className="tw-divide-y tw-divide-gray-200 tw-overflow-y-auto">
                                                {activePatients.map((patient) => (
                                                <tr key={patient.email} className="tw-cursor-pointer" onClick={ e => {
                                                    e.preventDefault();
                                                    showClientSchedule(patient);
                                                    //showClientRequirement(patient);
                                                    //props?.toggleShowRightPanel();
                                                }}>
                                                    <td className="tw-whitespace-nowrap tw-py-4 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900">
                                                    {patient.firstName} {patient.lastName}
                                                    </td>
                                                    <td className="tw-whitespace-nowrap tw-py-4 tw-px-3 tw-text-sm tw-text-gray-500">{patient.email}</td>
                                                    <td className="tw-whitespace-nowrap tw-py-4 tw-px-3 tw-text-sm tw-text-gray-500">{patient.patientDetail.county}</td>
                                                    <td className="tw-relative tw-whitespace-nowrap tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium">
                                                    <span className="tw-text-indigo-600 tw-cursor-pointer hover:tw-text-indigo-900" onClick={ e => {
                                                        e.preventDefault();
                                                        showClientSchedule(patient);
                                                        //showClientRequirement(patient);
                                                        //props?.toggleShowRightPanel();
                                                    }}>
                                                        View<span className="tw-sr-only">, {patient.firstName} {patient.lastName}</span>
                                                    </span>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        
                    </div>
                </div>
            
        </div>
    </>);
}

export default DirectSupportClientsList;