import React from 'react';

interface ProviderCardProps {
  name: string;
  providerID: string;
  availability: string;
  status?: string;
  profilePictureUrl?: string;
  scheduledHours: string;
  timeOffRequests: string;
  showDetails: (providerID: string) => void;
}

const ProviderCard: React.FC<ProviderCardProps> = ({
  name,
  providerID,
  availability,
  status,
  profilePictureUrl,
  scheduledHours,
  timeOffRequests,
  showDetails,
}) => {
  const getBackgroundColor = () => {
    // switch (availability) {
    //   case 'available':
    //     return 'tw-bg-green-200';
    //   case 'possiblyAvailable':
    //     return 'tw-bg-yellow-200';
    //   case 'notAvailable':
    //     return 'tw-bg-red-200';
    //   default:
    //     return 'tw-bg-gray-200';
    // }

    return 'tw-bg-gray-100';
  };

  const getTopBorderColor = () => {
    switch (status) {
      case 'Available':
        return 'tw-border-t-4 tw-border-green-500';
      case 'Possibly Available':
        return 'tw-border-t-4 tw-border-yellow-500';
      case 'Not Available':
        return 'tw-border-t-4 tw-border-red-500';
      default:
        return 'tw-border-t-4 tw-border-gray-200';
    }
  }

  const getInitials = (name: string) => {
    return name.split(' ').map(n => n[0]).join('');
  };

  return (
    <div className={`tw-p-4 tw-m-2 tw-bg-gray-100 ${getTopBorderColor()} tw-cursor-pointer tw-col-span-1`} onClick={() => showDetails(providerID)}>
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
            <h2 className="tw-font-bold tw-text-lg">{name}</h2>

            <div className="tw-cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="tw-text-gray-700" viewBox="0 0 24 24">
              <path d="M6,9.5L18,9.5L12,15.5L6,9.5Z" />
            </svg>
            </div>
        </div>
      
      <div className="tw-h-16 tw-w-16 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-300 tw-text-xl tw-mb-4">
        {profilePictureUrl ? (
          <img src={profilePictureUrl} alt="Profile" className="tw-rounded-full" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        ) : (
          <span>{getInitials(name)}</span>
        )}
      </div>
      <ul>
        {/* <li>Hours Available: {hoursAvailable}</li> */}
        <li>Availability: {availability}</li>
        <li>Scheduled Hours: {scheduledHours}</li>
        <li>Time-off Requests: {timeOffRequests}</li>
      </ul>
    </div>
  );
};

export default ProviderCard;
