import { Span } from "@medforall/medforall-component-library"

const AvatarImage = props => {
	const attributes = {
		...props,
		className: null,
		size: null,
		name: null,
		image: null
	}
	return <>
		<Span {...attributes} className={`tw-flex-shrink-0 tw-w-px-rem tw-h-px-rem tw-aspect-square tw-rounded-full tw-overflow-hidden tw-relative ${props.className}`} style={{ '--px-override':  props.size || 20  }}>
			<img className="tw-w-full tw-h-full tw-min-w-px-rem tw-min-h-px-rem tw-object-cover tw-object-center tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0" src={ props.image || '/images/placeholder_1x1.png' } alt={ props.name + ' Avatar' || 'Avatar'} />
		</Span>
	</>
}

export default AvatarImage;
