import { Span } from "@medforall/medforall-component-library"


export const IconWrapper = props => {
	const attributes = {
		...props,
		className: null,
		size: null
	}

	 return <Span { ...attributes } className={`${ props.className } tw-flex-shrink-0 tw-w-px-rem tw-h-px-rem tw-aspect-square tw-rounded-full tw-overflow-hidden tw-relative tw-bg-currentColor`} style={{ '--px-override':  props.size || 20  }} />
}

export const CircleLabel = props => {
	const Tag = props.tag || 'span';
	const attributes = {
		...props,
		className: null,
		size: null,
		top: null
	}
	return <>
		<Tag { ...attributes } className={`${props.className} tw-flex ${ props.space ? props.space : 'tw-space-x-5p'} tw-items-${props.top || 'center'}`}>
			<IconWrapper size={ props.size } />
			<Span className={`tw-flex-grow tw-text-dark/70 tw-flex tw-leading-none tw-items-${props.top || 'center'}`}>
				{ props.children || "Text" }
			</Span>
		</Tag>
	</>
}

const CalendarHeader = props => {
	return <>
		<div className="tw-flex tw-items-center tw-justify-end tw-space-x-20px tw-mb-10px">
			<CircleLabel className="tw-text-primary" size={10}>
				<span className="tw-text-[.75em]">{ props.filledTitle || "Assigned" }</span>
			</CircleLabel>
			<CircleLabel className="tw-text-black/10" size={10}>
				<span className="tw-text-[.75em]">{ props.emptyTitle || "Empty" }</span>
			</CircleLabel>
		</div>
	</>
}

export default CalendarHeader;
