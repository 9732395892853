import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import AvatarNameInline from "../avatars/AvatarNameInline";
import { CircleLabel } from "../calendars/CalendarUtils";
import { LabelOutputs } from "../utilities/Helpers";
import { RemoteSupportTicket, SupportTicketComment } from "../../types/remoteSupportTicket";
import moment from "moment";
import CommentBlock from "../containers/CommentBlock";
import { Textarea } from "../forms/formFields";
import { LoadingSpinner } from "../portalComponents/loadingSpinner";
import { ButtonErrorSecondary, ButtonPrimary, ButtonSecondary } from "../buttons/Buttons";
import { addCommentToSupportTicket, getSupportTicketByID } from "../../apicalls/sessionService";
import { set } from "lodash";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";


const SupportTicketPanel = props => {
	const navContext = useAppContext();
	const [ticket, setTicket] = useState<RemoteSupportTicket>(props.ticket);

	useLayoutEffect(() => {
		//TODO: Identify "id" to pass to client  <ClientInfoPanel />
        console.log(props.ticket);
		if (props.ticket) setTicket(props.ticket)
	}, [props.ticket])

	useLayoutEffect(() => {
		// navContext.setSideNavTitle("")
	}, [])

	const [newComment, setNewComment] = useState<string>("");

	const [savingComment, setSavingComment] = useState<boolean>(false);

	const cancelAction = () => {
		setNewComment("");
	}

	const addComment = async () => {
		setSavingComment(true);

		var ticketComment : SupportTicketComment = {
			supportTicketCommentID: '',
			ticketID: ticket.ticketID,
			comment: newComment,
		}
	
		const result = await addCommentToSupportTicket(ticketComment, ticket.ticketID, navContext.handleAPICallError);

		const newTicket = await getSupportTicketByID(ticket.ticketID, navContext.handleAPICallError);

		setTicket(newTicket);

		setNewComment("");

		if(props.refreshTicket) props.refreshTicket();

		setSavingComment(false);

	}

	return <>
		<div className="tw-p-20px tw-space-y-15px tw-w-full">
			<div>
				<AvatarNameInline className="tw-text-3 tw-font-primary-bold tw-text-dark tw-flex tw-leading-none" space="tw-space-x-15px"  size="40" name={` ${ticket?.clientName ? ticket.clientName : "Employee Ticket"} `} />
			</div>
			<h3 className="tw-font-primary-bold tw-font-5 tw-pt-30px">
				<CircleLabel className="tw-text-primary tw-space-x-15px" size={14} space="tw-space-x-15px">
					<span className="tw-text-dark tw-ml-5px">Ticket Details</span>
				</CircleLabel>
			</h3>
            <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight tw-pb-10px">
						<li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Category
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{ticket?.ticketCategory}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Status
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{ticket?.status}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Severity
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{ticket?.ticketSeverity}
							</span>
						</li>
                        {ticket?.serviceType && <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Service Type
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{ticket?.serviceType}
							</span>
						</li>}
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Created On
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
                                {moment.utc(ticket?.createdDate).tz('America/New_York').format('MMM Do YYYY h:mm A')}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px tw-items-center">
							<span className="tw-w-2/6 tw-flex tw-items-center">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Subject
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{ticket?.ticketSubject}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px">
							<span className="tw-w-2/6 tw-flex">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Description
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{ticket?.ticketDescription}
							</span>
						</li>
                        <li className="tw-flex tw-space-x-5px">
							<span className="tw-w-2/6 tw-flex">
								<LabelOutputs space="tw-space-x-15px" className="tw-opacity-70">
									Steps Taken
								</LabelOutputs>
							</span>
							<span className="tw-flex tw-w-4/6 tw-justify-start tw-text-black tw-pr-20px">
								{ticket?.stepsTaken}
							</span>
						</li>

            </ul>

				{ticket.status === "Pending" && <div className="tw-mt-4">
					<Textarea id={"action-comment-" + ticket.ticketID} value={newComment} className={`!tw-w-[calc(100%)] ${savingComment ? "tw-bg-gray-200" : ""}`} placeholder="Enter you comment..." disabled={savingComment} onChange={ e => {
							// setValues({
							// 	...values,
							// 	"reasonDetail": e.target.value
							// })
							console.log(e.target.value);
							setNewComment(e.target.value);
              //setActionComment(e.target.value);
						}}>
						</Textarea>

						<div className="tw-flex tw-justify-end tw-mt-2 tw-space-x-2">
						{savingComment ? <>
						<LoadingSpinner />

						</>:<>
						<ButtonErrorSecondary onClick={cancelAction}>
						  Discard
						</ButtonErrorSecondary>
						<ButtonPrimary onClick={() => addComment()} disabled={!newComment.trim()}>
						  Add Comment
						</ButtonPrimary>
						</>}
            
          			</div>
			</div>}

			<h4 className="tw-mt-4 tw-font-bold">Comments</h4>
      			<div className="">
                  { !ticket.comments || ticket.comments.length === 0 && <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-start tw-py-sm tw-mt-4">
                      <span className="tw-text-primary tw-text-5">No Comments Added!</span>
                      </div>}
									{ ticket.comments && ticket.comments?.map( (log, index) => {
										return <CommentBlock key={`comment_${index}`} data={{
											user: {
												name: log?.commenterName,
												image: null
											},
											comment: {
												createdOn: moment.utc(log?.commentDate).tz('America/New_York').format('MMMM DD, YYYY | h:mmA'),
											}
										}}>
										{log?.comment}
									</CommentBlock>
									})}
				</div>
		</div>
	</>
}

export default SupportTicketPanel;