import axios, { AxiosRequestConfig } from "axios";
import { AnyAaaaRecord } from "dns";
import { msalInstance } from "..";
import { billingRequest } from "../authConfig";
import AgencySummary from "../types/agencySummary";
import BillingSummary from "../types/billingSummary";
import BlobFile from "../types/blobFile";
import Patient from "../types/patient";
import { AppContext, useAppContext } from "../Context";
import React from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { BillingMetric } from "../types/billingMetric";



export const getAPIToken = async () : Promise<string | void> => {
    const account = msalInstance.getActiveAccount();

    // const response = await msalInstance.acquireTokenSilent({
    //     ...billingRequest,
    //     account: account
    //   });
    
    const token = msalInstance.acquireTokenSilent({
        ...billingRequest,
        account: account
      }).then(tokenResponse => {
        // Do something with the tokenResponse
        return tokenResponse.idToken;
    }).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(billingRequest)
        }
    
        // handle other errors
    });

    //console.log(response);

    return token;

    //return response.idToken;
}

export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const getActiveClients = async (handleAPICallError: (error: any) => void) : Promise<Patient[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_BASEURL;
    const url = baseURL + 'clients/active';
    return axios.get<Patient[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getActiveDSClients = async (handleAPICallError: (error: any) => void) : Promise<Patient[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_BASEURL;
    const url = baseURL + 'clients/active/directSupport';
    return axios.get<Patient[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getDirectSupportMetric = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BillingMetric> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL+"clients/"+patientID+"/directSupportBillingMetrics/"+startDate+"/"+endDate;
    return axios.get<BillingMetric>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });;
}


export const getBillingSummary = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BillingSummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL+"claims/"+patientID+"/"+startDate+"/"+endDate;
    return axios.get<BillingSummary>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });;
}

export const getBillingSummaryForCode = async (patientID: string, billingCode: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BillingSummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL+"claims/"+patientID+"/"+startDate+"/"+endDate+"/"+billingCode;
    return axios.get<BillingSummary>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getClientClaimExcelFile = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "claimExcel/" +  patientID +"/" +startDate+"/"+endDate;
    

    return axios.get<BlobFile>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getBillingFlatFile = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "/flatfile/"+patientID+"/"+startDate+"/"+endDate;
    

    return axios.get(url,requestHeader).then((response) => { 
        //console.log(response);

        if(response.status < 300) {
            const fileContents = {
                content: response.data
            }

            return fileContents;
        } else {
            handleAPICallError(response);
        }
        //console.log(fileContents);

    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getBillingFlatFileForCode = async (patientID: string, billingCode: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "/flatfile/"+patientID+"/"+startDate+"/"+endDate+"/"+billingCode;
    

    return axios.get(url,requestHeader).then((response) => { 
        if(response.status < 300) {
            const fileContents = {
                content: response.data
            }
            return fileContents;
        } else {
            handleAPICallError(response);
        }
        

    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getAgencyBillingSummary = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<AgencySummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;
    const url = baseURL + "claims/"+startDate+"/"+endDate;
    return axios.get<AgencySummary>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getAgencyBillingFlatFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "flatfile/"+startDate+"/"+endDate;
    

    return axios.get(url,requestHeader).then((response) => { 
        if(response.status < 300) {
            const fileContents = {
                content: response.data
            }
    
            return fileContents;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getAgencyClaimExcelFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BlobFile> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "claimExcel/"+startDate+"/"+endDate;
    

    return axios.get<BlobFile>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getClientBillingCodes = async (patientID: string, handleAPICallError: (error: any) => void) : Promise<string[]> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    //const url = "http://localhost:9090/clients/active";
    const baseURL = process.env.REACT_APP_BASEURL;
    const url = baseURL + 'clients/' + patientID + '/billingCodes';
    return axios.get<string[]>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return [];
          });
}

export const getUnbilledBillingSummary = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BillingSummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL+"claims/unbilled/"+patientID+"/"+startDate+"/"+endDate;
    return axios.get<BillingSummary>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getMismatchBillingSummary = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BillingSummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL+"claims/mismatch/"+patientID+"/"+startDate+"/"+endDate;
    return axios.get<BillingSummary>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getErroredBillingSummary = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<BillingSummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL+"claims/errored/"+patientID+"/"+startDate+"/"+endDate;
    return axios.get<BillingSummary>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getUnBilledBillingFlatFile = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "/flatfile/claim/unbilled/"+patientID+"/"+startDate+"/"+endDate;
    

    return axios.get(url,requestHeader).then((response) => { 
        if(response.status < 300) {
            const fileContents = {
                content: response.data
            }
    
            return fileContents;
        } else {
            handleAPICallError(response);
        }

    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getMismatchBillingFlatFile = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "/flatfile/claim/mismatch/"+patientID+"/"+startDate+"/"+endDate;
    

    return axios.get(url,requestHeader).then((response) => { 
        if(response.status < 300) {
            const fileContents = {
                content: response.data
            }
            return fileContents;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getErroredBillingFlatFile = async (patientID: string, startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "/flatfile/claim/errored/"+patientID+"/"+startDate+"/"+endDate;
    

    return axios.get(url,requestHeader).then((response) => { 
        if(response.status < 300) {
            const fileContents = {
                content: response.data
            }
            return fileContents;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getAgencyUnsubmittedBillingSummary = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<AgencySummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;
    const url = baseURL + "unsubmittedClaims/"+startDate+"/"+endDate;
    return axios.get<AgencySummary>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getAgencyUbsubmiitedBillingFlatFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "unsubmittedFlatFile/"+startDate+"/"+endDate;
    

    return axios.get(url,requestHeader).then((response) => {    
        if(response.status < 300) {
            const fileContents = {
                content: response.data
            }
    
            return fileContents;
        } else {
            handleAPICallError(response);
        }
    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getAgencyErroredBillingSummary = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<AgencySummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;
    const url = baseURL + "erroredClaims/"+startDate+"/"+endDate;
    return axios.get<AgencySummary>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getAgencyErroredBillingFlatFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "erroredClaimsFlatFile/"+startDate+"/"+endDate;
    

    return axios.get(url,requestHeader).then((response) => { 
        if(response.status < 300) {
            const fileContents = {
                content: response.data
            }
    
            return fileContents;
        } else {
            handleAPICallError(response);
        }

    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}

export const getAgencyMismatchBillingSummary = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<AgencySummary> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;
    const url = baseURL + "lowReimbursedClaims/"+startDate+"/"+endDate;
    return axios.get<AgencySummary>(url,requestHeader)
        .then(response => {
            if(response.status < 300) {
                return response.data;
            } else {
                handleAPICallError(response);
            }
        })
        .catch(error => {
            handleAPICallError(error);
            return null;
          });
}

export const getAgencyMismatchBillingFlatFile = async (startDate: string, endDate: string, handleAPICallError: (error: any) => void) : Promise<any> => {
    const token = await getAPIToken();
    const requestHeader: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        validateStatus: function (status) {
            return true;
        }
    }

    const baseURL = process.env.REACT_APP_BASEURL;

    const url = baseURL + "mismatchClaimsFlatFile/"+startDate+"/"+endDate;
    

    return axios.get(url,requestHeader).then((response) => { 
        if(response.status < 300) {
            const fileContents = {
                content: response.data
            }
    
            return fileContents;
        } else {
            handleAPICallError(response);
        }        

    }).catch(error => {
        handleAPICallError(error);
        return null;
      });
}