import { placeholder } from "../../utilities/helpers";
import IntrinsicRatio from "../utilities/IntrinsicRatio";

// tw-max-w-[4rem]
// tw-max-w-[10.6875rem]
// tw-max-w-[9.375rem]

export const ImageContainer = props => {
	const Tag = props?.tag || 'h2';
	const ChildTag = props?.childTag || 'h2';
	const classes = `${ props?.coreClasses || '' } ${ props?.className ? ' || ' + props.className : '' }`;
	const attributes = {
		...props,
		className: classes,
		childClasses: null,
		tag: null,
		childTag: null,
		coreClasses: null
	}

	return <IntrinsicRatio { ...attributes } tag={ props.tag || null }childTag={ props.childTag || null } childClasses={ props.childClasses || null } />
}

export const ImageSmall = props => {
	return <ImageContainer {...props} childTag="img" coreClasses={`tw-max-w-[4rem]`} />
}

export const ImageMedium = props => {
	return <ImageContainer {...props} childTag="img" coreClasses={`tw-max-w-[9.375rem]`} />
}

export const ImageLarge = props => {
	return <ImageContainer {...props} childTag="img" coreClasses={`tw-max-w-[10.6875rem]`} />
}

export const Avatar = props => {
	const Tag = props?.tag || 'div';
	const classes = `tw-relative tw-overflow-hidden tw-rounded-full ${ props?.className ? ' || ' + props.className : '' }`;

	const attributes = {
		...props,
		className: classes,
		childClasses: null,
		tag: null,
		src: null,
		alt: null
	}

	return <Tag { ...attributes }>
		 <ImageContainer childClasses={ props.childClasses || null } src={ props.src ||'/images/no-profile-image.svg' } alt={ props.alt || null } childTag="img" coreClasses={`tw-object-cover tw-w-full tw-h-full tw-block`} />
	</Tag>
}


export const IconMediaObject = props => {
	const Tag = props?.tag || 'div';
	const ChildTag = props?.childTag || 'div';

	return props.overrideChild ?  <>{ props.children }</> : <>
		<Tag className={`tw-flex ${ props.alignment ? `tw-items-${props.alignment}` : 'tw-items-center'} ${  props?.button ? ` tw-p-sm tw-justify-center ` : ''} || tw-leading-none || tw-appearance-none tw-outline-none tw-select-none tw-flex ${ props?.after ? ' tw-flex-row-reverse' : ''} || ${ props.className ? ' || ' + props.className : '' }${ props.childClasses ? ' || ' + props.childClasses : '' }`}>
			{ props.icon ? <>
				<ChildTag className={`${ props.iconLarge ?  'tw-w-9' : 'tw-w-6'} ${ props.iconNotRounded ?  '' : 'tw-rounded-full'}  tw-overflow-hidden`}>
					<IntrinsicRatio className="tw-w-full tw-block" childTag="img" src={ props.icon } alt={ props.children + ' Button Image'} />
				</ChildTag>
				<ChildTag className={props?.after ? ' tw-pr-sm ' : ' tw-pl-sm '}>
					{ props.children }
				</ChildTag>
			</> : props.svg ? <>
				<ChildTag className={`tw-flex-shrink-0 tw-overflow-hidden`}>
					<span className="tw-w-full" dangerouslySetInnerHTML={{__html: props.svg }} />
				</ChildTag>
				<ChildTag className={props?.after ? ' tw-pr-sm ' : ' tw-pl-sm '}>
					{ props.children }
				</ChildTag>
			</> : props.children }
		</Tag>
	</>;
}

export default ImageContainer;
