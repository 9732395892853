import { ChangeEvent, createRef, useEffect, useRef, useState } from "react";
import { getActiveClients, getBillingFlatFile, getBillingSummary } from "../apicalls/billingService";
import Button, { ButtonPrimary, ButtonSecondary } from "../components/buttons/Buttons";
import { ClaimDetailsCard, SessionCardHours } from "../components/containers/Cards";
import { Input, Select } from "../components/forms/formFields";
import { ArticleHeader, DynamicText, PageHeader, PageSubHeader, Paragraph3, SectionHeader, SectionSubheader } from "../components/typography/Headers";
import { Block, Ellipsis } from "../components/utilities/Helpers";
import IntrinsicRatio from "../components/utilities/IntrinsicRatio";
import { formatDate, formatDollars } from "../hooks/useFormats";
import Core from "../templates/Core";
import Patient from "../types/patient";
import { placeholder } from "../utilities/helpers";
import { articleHeader, paragraph3 } from "../utilities/typography";
import CircularProgress from '@mui/material/CircularProgress';
import BillingSummary from "../types/billingSummary";
import Moment from 'moment';
import 'moment-timezone';
import { useForm } from "react-hook-form";
import { uploadFilesToBlob } from "../apicalls/billingReportService";
import CheckIcon from '@mui/icons-material/Check';
import { useAppContext } from "../Context";

const UploadReportsPage = props => {
    const reportRef = useRef<HTMLInputElement>(null);
    const [uploadedFiles, setUploadedFiles] = useState<FileList>();
    const [filesSelected, setFilesSelected] = useState(false);
    const [file, setFile] = useState<File>();
    const [fileUploading, setFileUploading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileStatus, setFileStatus] = useState([]);

    const appContext = useAppContext();

    const multipleFiles = true;
    const id = "uploadreportinput";

    const fileProps = {
		multipleFiles, id
	}

    function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
        const fileObj = event.target.files && event.target.files[0];
        setFile(fileObj);
    
        if(event.target.files && event.target.files.length !== 0){
          console.log(event.target.files);
          setUploadedFiles(event.target.files);
          setFilesSelected(true);
        }
    }

    function clearFiles(){
        setFilesSelected(false);
        if(reportRef.current){
            reportRef.current.value = "";
        }
        setUploadedFiles(undefined);
        setFileUploading(false);
        setFileUploaded(false);
        setFileStatus([]);
    }

    const uploadFiles = async () => {
        setFileUploading(true);
        for (let i = 0; i < uploadedFiles.length; i++) {
            var fileResult = await handleFileUpload(uploadedFiles.item(i));

            // if(uploadedFiles.item(i)){
            //   console.log(uploadedFiles.item(i)?.name);
            //   formData.append("files", uploadedFiles.item(i) as Blob)
            // }
            //console.log(fileResult);
            // const newFileStatus = [...fileStatus, fileResult];
            // console.log(newFileStatus);
            
            setFileStatus((fileStatus) => fileStatus.concat(fileResult));
        }

        //const result = await uploadFilesToBlob(formData);

        //setFileStatus(result);

        setFileUploading(false);

        setFileUploaded(true);
    }

    useEffect(() => {
        console.log("File Status Updated");
        console.log(fileStatus);
    }, [fileStatus])

    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append("files", file as Blob)

        const result = await uploadFilesToBlob(formData, appContext.handleAPICallError);

        return result[0];
    }

    return(<>
        {/* <Core { ...props }> */}
        <div className="tw-px-xl tw-py-xl">
                {/* <div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
					<div className="tw-flex-grow tw-flex-shrink-0">
						<PageHeader>
							<Ellipsis>
								Upload Reports
							</Ellipsis>
						</PageHeader>
					</div>
				</div> */}

                <div className="tw-pb-5">
                    <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Upload Reports</h2>
                    <p className="tw-mt-1 tw-max-w-2xl tw-text-sm tw-text-gray-500">
                    Supported Files: BILL_FLAT_***.TXT, ERROR_FLAT_***.TXT, REIMB_FLATAPPV_***.TXT, REIMB_FLATDENY_***.TXT
                    </p>
                </div>

                {/* <div className="tw-flex tw-items-center tw-flex tw-text-dark tw-pb-sm">
                    <ArticleHeader>
                        Supported Files: BILL_FLAT_***.TXT, ERROR_FLAT_***.TXT, REIMB_FLATAPPV_***.TXT, REIMB_FLATDENY_***.TXT
                    </ArticleHeader>
                </div> */}
               
               


                {filesSelected ? <>
                    <div className="tw-py-8">
                        <SectionHeader tag="h2" childClasses="tw-uppercase tw-font-bold tw-text-dark tw-px-xs">
                            Selected Files
                        </SectionHeader>
                        <ul className="tw-space-y-4 tw-pt-4">

                            {Array.from(uploadedFiles).map((file) => {
                                return <li key={file.name}>
                                     <ul className="tw-space-x-4 tw-flex tw-flex-wrap">
                                     <span className="tw-text-3 tw-py-sm">{file.name}</span>
                                     {fileUploading && !fileStatus.some(i => i.fileName === file.name) ? <CircularProgress style={{ color: "#12B4B9" }} className="tw-p-sm" ></CircularProgress> : <></>}
                                     {fileStatus.some(i => i.fileName === file.name) ? <>
                                        <div className="tw-py-sm">
                                            <CheckIcon sx={{ color: "#12B4B9" }}></CheckIcon>
                                            <span className="tw-text-4 tw-px-sm" style={{ color: "#12B4B9" }}>
                                            {fileStatus.find(i => i.fileName === file.name).action}
                                            </span>
                                        </div>
                                        
                                        
                                     </> : <></>}
                                    
                                    
                                     </ul>
                                    
                                    </li>
                            })}
                        </ul>
                    </div>

                    <ul className="tw-space-x-4 tw-flex tw-flex-wrap">
                        {!fileUploading && !fileUploaded ? <>
                            <li>
                                <ButtonPrimary onClick={uploadFiles}>
                                    Upload
                                </ButtonPrimary>
                            
                        </li>
                        <li>
                            <ButtonSecondary onClick={clearFiles}>
                                Clear
                            </ButtonSecondary>
                        </li>
                        </> : <></> }

                        {fileUploaded ? <>
                            <li>
                                <ButtonPrimary onClick={clearFiles}>
                                    Upload More
                                </ButtonPrimary>
                            
                            </li>
                        
                        </>:<></>}
                        
                    </ul>
                </>:
                <>
                 <ul className="tw-space-x-4 tw-flex tw-flex-wrap">
                    <li className="tw-flex-grow">
                        <Block>Select Provider Reports:</Block>
                        <Input type="file" { ...fileProps } onChange={handleFileChange} ref={reportRef} />
                    </li>
                </ul>
                </>}

                
        </div>
        {/* </Core> */}
    </>)

}

export default UploadReportsPage;

