import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../../Context";
import { LabelOutputs } from "../utilities/Helpers";
import { ButtonSecondary } from "../buttons/Buttons";
import moment from "moment";

const SessionActionSuccessPanel = props => {
    const navContext = useAppContext();

    const [title, setTitle] = useState<string>(null);
    const [message, setMessage] = useState<string>(null);

    const [selectedSession, setSelectedSession] = useState(null);

    const [showProvider, setShowProvider] = useState(true);

    useLayoutEffect(() => {
        if (props.selectedSession) {
            console.log("Selected Session");
            console.log(props.selectedSession);
            setSelectedSession(props?.selectedSession);
        }
        if (props.title){
            setTitle(props?.title);
        }
        if (props.message){
            setMessage(props?.message);
        }

        if (props.hideProvider){
            setShowProvider(false);
        }

    }, [props.selectedSession, props.title, props.message, props.showProvider]);
    

    return (<>
        <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden tw-h-full">
			<div className="tw-flex tw-flex-col tw-flex-grow tw-w-full tw-overflow-x-hidden tw-overflow-y-aut">
				<div className="tw-p-20px tw-space-y-20px tw-w-full tw-flex tw-flex-col tw-flex-grow ">
					<div className="tw-w-full tw-space-y-15px">
						<h3 role="button" className="tw-cursor-pointer tw-flex tw-items-center tw-w-full tw-text-5 tw-font-primary-bold tw-text-dark tw-leading-[1.125em]">
							<span className="tw-flex tw-space-x-5p tw-items-center tw-w-full  tw-pr-20px">
								<span className="tw-text-dark tw-ml-5px">
									{title}
								</span>
							</span>
						</h3>

                        <ul className="tw-text-5 tw-w-full tw-flex tw-flex-col tw-space-y-15px tw-leading-tight">
                            {showProvider && <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Provider
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.providerName}
                                </span>
                            </li>}
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Status
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.status}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Start Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayStartDate}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        End Date
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayEndDate}
                                </span>
                            </li>
                            <li className="tw-flex tw-space-x-5px tw-items-center">
                                <span className="tw-w-150px tw-flex tw-items-center">
                                    <span className="tw-opacity-70">
                                        Time Period
                                    </span>
                                </span>
                                <span className="tw-flex-grow tw-w-[calc(100%-9.6875rem)] tw-flex tw-items-center tw-justify-start tw-text-black tw-pr-20px">
                                    {selectedSession?.displayStartTime} - {selectedSession?.displayEndTime}
                                </span>
                                {!moment(selectedSession?.startDate).isSame(selectedSession?.endDate) && <span className="tw-ml-auto tw-flex tw-justify-start tw-items-center tw-flex-shrink-0 tw-w-20p">
                                    <img className="tw-w-full" src={`/images/eye-4-512.png`} alt="Icon" />
                                </span>}
                            </li>
                        </ul>


                    </div>

                    <div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-my-auto tw-py-20px">
						<div className="tw-space-y-15px tw-text-center">
							<LabelOutputs className="tw-w-full tw-bg-secondary/60 tw-inline-flex tw-rounded-3p tw-overflow-hidden tw-py-5px tw-px-10px" icon="/images/icon-saved.svg">
								<span className="tw-text-5">
									{message}
								</span>
							</LabelOutputs>
							<ButtonSecondary className="tw-w-200px" onClick={ e => {
								e.preventDefault();
								navContext.closeSideNav();
							}}>
								Go Back to schedule
							</ButtonSecondary>
						</div>
					</div>
                </div>
            </div>
        </div>
    </>);
}

export default SessionActionSuccessPanel;