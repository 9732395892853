import { useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../Context";
import { archiveTraining, getAssignmentStatusForTraining, getAttemptsForTraining, getClientTrainingByID, getEmployeesForTraining, publishTraining, sendNotificationForTrainingForAll, sendNotificationForTrainingForEmployee, updateTraining, uploadTrainingFilesToBlob } from "../../apicalls/trainingService";
import { CourseMaterial, ProviderAttempt, QuizQuestion, Training, TrainingAssignment, TrainingStatus, TrainingUploadFile } from "../../types/training";
import { GenericBreadCrumbs } from "../Scheduling/SchedulingUtils";
import { LoadingSpinner } from "../../components/portalComponents/loadingSpinner";
import { ArrowDownIcon, ArrowUpIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { ButtonError, ButtonErrorSecondary, ButtonPrimary, ButtonPublish, ButtonSecondary, ButtonSuccess, ButtonWarning, ButtonWarningSecondary } from "../../components/buttons/Buttons";
import { CustomDropdown, EmployeeAssignmentList } from "./CreateTrainingPage";
import Patient from "../../types/patient";
import { getActiveClients } from "../../apicalls/schedulingService";
import MFADropDown from "../../components/portalComponents/dropdownMenu";
import ConfirmDialog from "../../components/dialogs/confirmDialog";
import { forEach, set } from "lodash";
import SuccessDialog from "../../components/dialogs/successDialog";
import { escapeSingleQuotes } from "../../apicalls/announcementService";
import CautionDialog from "../../components/dialogs/cautionDialog";
import CalendarSelector from "../../components/calendars/CalendarSelector";
import moment from "moment";
import Employee from "../../types/employee";
import SelectEmployeesDialog from "../../components/dialogs/selectEmployeeDialog";

import { ArrowUpOnSquareIcon } from "@heroicons/react/24/solid";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import ProviderAttemptPanel from "../../components/panels/ProviderAttemptPanel";
import { extractFileName, extractFileNameWithoutExtension } from "./ManageContinuousEducationPage";

export const EmployeeStatusList = ({employees, notifyEmployee, loadingIndex, showNotify}) => {
    return <ul role="list" className="tw-divide-y tw-divide-gray-900">
    {employees?.map((person, index) => (
      <li key={person.email} className="tw-flex tw-justify-between tw-gap-x-6 tw-py-5">
        <div className="tw-flex tw-min-w-0 tw-gap-x-4">
            <div className="tw-h-10 tw-w-10 tw-flex-shrink-0">
                                <span className="tw-relative tw-inline-block">
                                    <span className="tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-500 tw-cursor-pointer">
                                        <span className="tw-font-medium tw-leading-none tw-text-white">{person.employeeName.charAt(0).toUpperCase()}</span>
                                    </span>
                                    {person.status === "Certified" ? <span className="tw-absolute tw-top-0 tw-right-0 tw-block tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-green-400 tw-ring-2 tw-ring-white" />:
                                     <span className="tw-absolute tw-top-0 tw-right-0 tw-block tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-red-500 tw-ring-2 tw-ring-white" />}
                                </span>
                                {/* <img className="tw-h-10 tw-w-10 tw-rounded-full" src={person.image} alt="" /> */}
            </div>
          {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
          <div className="tw-min-w-0 tw-flex-auto">
            <p className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">{person.employeeName}</p>
            <p className="tw-mt-1 tw-truncate tw-text-xs tw-leading-5 tw-text-gray-500">{person.status}</p>
          </div>
        </div>
       {person.status !== "Certified" && showNotify && (

        <div className="tw-shrink-0 tw-mx-4">
            {loadingIndex === index ? <LoadingSpinner></LoadingSpinner>:
            <EnvelopeIcon className="tw-h-6 tw-w-6 tw-text-primary tw-cursor-pointer" onClick={(e) => {
                e.preventDefault();
                notifyEmployee(person);
            }}></EnvelopeIcon>}
        </div>)}
      </li>
    ))}
  </ul>
}

const ManageClientTrainingPage = props => {
    const { trainingID } = useParams();
    const appContext = useAppContext();

    const [clients, setClients] = useState<Patient[]>([]);

    const [menuItems, setMenuItems] = useState([
        {
            type: 'link',
            label: 'Edit',
            href: '#',
            onClick: () => handleEdit(),
        },
        {
            type: 'link',
            label: 'Publish',
            href: '#',
            onClick: () => handlePublish(),
        },
    ]);

    const handleEdit = () => {
        setEditMode(true);

        setMenuItems([
            {
                type: 'link',
                label: 'Cancel Edit',
                href: '#',
                onClick: () => handleCancelEdit(),
            },
        ]);
    }

    const handlePublish = () => {
        // Call the publish training API
        console.log("Publish Training");
    }

    const [uploadingImageFile, setUploadingImageFile] = useState<boolean>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [loadingTraining, setLoadingTraining] = useState<boolean>(true);

    const [employeeIndexLoading, setEmployeeIndexLoading] = useState<number>(-1);

    const [training, setTraining] = useState<Training | null>(null);
    const [originalTraining, setOriginalTraining] = useState<Training | null>(null);

    const [editMode, setEditMode] = useState<boolean>(false);

    const [step, setStep] = useState<number>(1);

    const [showCourseMaterialError, setShowCourseMaterialError] = useState<boolean>(false);
    const [showQuizError, setShowQuizError] = useState<boolean>(false);

    const [previewMode, setPreviewMode] = useState<boolean>(false);

    const [showNewQuizQuestion, setShowNewQuizQuestion] = useState<boolean>(false);

    const [savingTraining, setSavingTraining] = useState<boolean>(false);

    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const [showPublishMode, setShowPublishMode] = useState(false);

    const [trainingAssignments, setTrainingAssignments] = useState<TrainingStatus[]>([]);

    const [successDialogHeader, setSuccessDialogHeader] = useState("");
    const [successDialogMessage, setSuccessDialogMessage] = useState("");

    const [activeEmployees, setActiveEmployees] = useState<Employee[]>([]);

    const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);

    const [uploadingFile, setUploadingFile] = useState<boolean>(false);

    const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
    const [selectedEmployeeID, setSelectedEmployeeID] = useState<string>("");

    const [overviewErrors, setOverviewErrors] = useState({
        trainingName: false,
        trainingDescription: false,
        trainingCategory: false,
        clientName: false,
        supplementalCategory: false,
        validTill: false,
        duration: false,
    });
    
    const [overviewErrorMessages, setOverviewErrorMessages] = useState({
        trainingName: "Training Name is required and must be less than 60 characters",
        trainingDescription: "Training Description is required and must be less than 500 characters",
        trainingCategory: "Training Category is required",
        clientName: "Client Name is required",
        supplementalCategory: "Supplemental Category is required",
        validTill: "Valid Till is required",
        duration: "Duration is required",
    });

    const [audienceErrors, setAudienceErrors] = useState({
        targetAudience: false,
    });

    const [showAudienceError, setShowAudienceError] = useState(false);

    const [audienceErrorMessages, setAudienceErrorMessages] = useState({
        targetAudience: "Audience is required",
    });

    const [courseMaterialErrors, setCourseMaterialErrors] = useState({
        materialType: false,
        materialFile: false,
    });

    const [courseMaterialErrorMessages, setCourseMaterialErrorMessages] = useState({
        materialType: "Course Material Type is required",
        materialFile: "Course Material File is required",
    });

    const [quizQuestionErrors, setQuizQuestionErrors] = useState({
        question: false,
        questionType: false,
        choice1: false,
        choice2: false,
        choice3: false,
        choice4: false,
        correctChoice: false,
        questionImage: false,
    });

    const [quizQuestionErrorMessages, setQuizQuestionErrorMessages] = useState({
        question: "Question is required",
        questionType: "Question Type is required",
        choice1: "Choice 1 is required",
        choice2: "Choice 2 is required",
        choice3: "Choice 3 is required",
        choice4: "Choice 4 is required",
        correctChoice: "Correct Choice is required",
        questionImage: "Question Image is required",
    });

    const [quizErrors, setQuizErrors] = useState({
        passingScore: false,
        maxAttempts: false,
    });

    const [quizErrorMessages, setQuizErrorMessages] = useState({
        passingScore: "Passing Score is required",
        maxAttempts: "Max Attempts is required",
    });

    const [groupedAndSortedAttempts, setGroupedAndSortedAttempts] = useState<{ [key: string]: ProviderAttempt[] }>({});

    const groupAndSortAttempts = (attempts: ProviderAttempt[]): { [key: string]: ProviderAttempt[] } => {
        // Group by employeeName

        if (!attempts || attempts.length === 0) {
          return null;
        }

        const groupedByEmployee: { [key: string]: ProviderAttempt[] } = attempts.reduce((acc, attempt) => {
          const key = attempt.employeeName || "Unknown";
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(attempt);
          return acc;
        }, {} as { [key: string]: ProviderAttempt[] });
      
        // Sort each group by submissionTime in descending order
        for (const key in groupedByEmployee) {
          groupedByEmployee[key].sort((a, b) => new Date(b.submissionTime).getTime() - new Date(a.submissionTime).getTime());
        }
      
        return groupedByEmployee;
      };

    const showProviderAttempt = async (attempts: ProviderAttempt[], employeeName: string) => {

        appContext.setSideNavTheme(3);
        appContext.setPanelSize('tw-max-w-600px');
        appContext.setSideNavTitle( "Attempt Details" );
        appContext.setSideNavActions(null);
        appContext.setSideNavBottom(null);
        
  
        appContext.setSideNav( <ProviderAttemptPanel attempts={attempts} employeeName={employeeName} trainingQuiz={training.quiz} /> )
  
        appContext.openSideNav();
    }

    useLayoutEffect(() => {
        const fetchTraining = async () => {
            setLoadingTraining(true);

            const storedTraining = await getClientTrainingByID(trainingID, appContext.handleAPICallError);

            console.log(storedTraining);

            if (!storedTraining.courseMaterials) {
                storedTraining.courseMaterials = [];
            }

            setTraining(storedTraining);
            setOriginalTraining(storedTraining);

            if (storedTraining.status === "Active") {
                setShowPublishMode(true);
            } else {
                setShowPublishMode(false);
            }

            if (storedTraining.status === "Active" || storedTraining.status === "Archived") {
                setShowPublishMode(true);

                var attempts = await getAttemptsForTraining(trainingID, appContext.handleAPICallError);

                console.log(attempts);

                console.log(groupAndSortAttempts(attempts));

                setGroupedAndSortedAttempts(groupAndSortAttempts(attempts));
            } else {
                setShowPublishMode(false);
            }

            await fetchClients();

            await fetchEmployees(storedTraining);

            await fetchEmployeesForTraining();

            setLoadingTraining(false);
        }

        const fetchClients = async () => {
            // Fetch the clients from the API
            const clients = await getActiveClients(appContext.handleAPICallError);
            setClients(clients);
        };

        const fetchEmployees = async (training: Training) => {
            // Fetch the employees from the API
            const employees = await getEmployeesForTraining(appContext.handleAPICallError);

            console.log(employees);

            setActiveEmployees(employees.sort((a, b) => a.firstName.localeCompare(b.firstName)));

            if (training.targetAudience === "Select Employees") {
                var selectedEmps: Employee[] = [];

                employees?.forEach((employee) => {
                    if (training.trainingAssignments.find((assignment) => assignment.providerID === employee.employeeID)) {
                        selectedEmps.push(employee);
                    }
                });

                setSelectedEmployees(selectedEmps);

                var sortedEmployees = employees.sort((a, b) => a.firstName.localeCompare(b.firstName));

                setFilteredEmployees(sortedEmployees.filter((employee) => !selectedEmps.find((emp) => emp.employeeID === employee.employeeID)));

            } else {
                setFilteredEmployees(employees.sort((a, b) => a.firstName.localeCompare(b.firstName)));
            }


        }

        const fetchEmployeesForTraining = async () => {
            const assignments = await getAssignmentStatusForTraining(trainingID, appContext.handleAPICallError);

            console.log("Assignments");

            console.log(assignments);

            setTrainingAssignments(assignments.sort((a, b) => a.status.localeCompare(b.status)));
        }

       

        fetchTraining();
    }, [trainingID])

    const validateQuiz = () => {
        let isValid = true;
        var currentQuizErrors = {
            passingScore: false,
            maxAttempts: false,
        };

        if (!training.quiz.passingScore || training.quiz.passingScore < 1 || training.quiz.passingScore > 100) {
            currentQuizErrors.passingScore = true;
            isValid = false;
        }
        if (!training.quiz.maxAttempts || training.quiz.maxAttempts < 1) {
            currentQuizErrors.maxAttempts = true;
            isValid = false;
        }

        setQuizErrors(currentQuizErrors);

        return isValid;
    }

    const validateTargetAudience = () => {
        let isValid = true;

        var currentAudienceErrors = {
            targetAudience: false,
        };

        if (!training.targetAudience) {
            currentAudienceErrors.targetAudience = true;
            isValid = false;
        }

        if (training.targetAudience === "Select Employees" && selectedEmployees?.length === 0) {
            isValid = false;
            setShowAudienceError(true);
        }

        setAudienceErrors(currentAudienceErrors);

        return isValid;
    }


    const steps = [
        { id: 1, name: 'Overview', href: '#', status: 'current' },
        { id: 2, name: 'Audience', href: '#', status: 'upcoming'},
        { id: 3, name: 'Content', href: '#', status: 'upcoming' },
        { id: 4, name: 'Quiz', href: '#', status: 'upcoming' },
        { id: 5, name: 'Review', href: '#', status: 'upcoming' },
    ]

    const handleNextStep = () => {
        switch (step) {
            case 1:
                if (!validateOverview()) {
                    return;
                }
                break;
            case 2:
                if (!validateTargetAudience()) {
                    return;
                } else {
                    setShowAudienceError(false);
                }

                break;
            case 3:
                if (training.courseMaterials.length === 0) {
                    setShowCourseMaterialError(true);
                    return;
                } else {
                    setShowCourseMaterialError(false);
                }

                break;
            case 4:
                if (training.quiz.questions.length < 4) {
                    setShowQuizError(true);
                    return;
                } else {
                    setShowQuizError(false);
                }

                break;
            default:
                break;
        }


        setStep(step + 1);
    };

    const validateOverview = () => {
        let isValid = true;
        var currentOverviewErrors = {
            trainingName: false,
            trainingDescription: false,
            trainingCategory: false,
            clientName: false,
            supplementalCategory: false,
            targetAudience: false,
            validTill: false,
            duration: false,
        };

        if (!training.trainingName) {
            currentOverviewErrors.trainingName = true;
            isValid = false;
        } else if (training.trainingName.length > 60) {
            currentOverviewErrors.trainingName = true;
            isValid = false;
        } else if (training.trainingDescription.length > 500) {
            currentOverviewErrors.trainingDescription = true;
            isValid = false;
        }

        if (!training.trainingDescription) {
            currentOverviewErrors.trainingDescription = true;
            isValid = false;
        }
        if (!training.trainingCategory) {
            currentOverviewErrors.trainingCategory = true;
            isValid = false;
        }
        if (!training.clientID) {
            currentOverviewErrors.clientName = true;
            isValid = false;
        }

        if (training.trainingCategory === "Client Supplemental Training" && !training.supplementalCategory) {
            currentOverviewErrors.supplementalCategory = true;
            isValid = false;
        }

        if (!training.targetAudience) {
            currentOverviewErrors.targetAudience = true;
            isValid = false;
        }

        if (!training.validTill) {
            currentOverviewErrors.validTill = true;
            isValid = false;
        }

        if (!training.duration || training.duration < 1) {
            currentOverviewErrors.duration = true;
            isValid = false;
        }

        setOverviewErrors(currentOverviewErrors);

        return isValid;
    }

    const validateCourseMaterial = () => {
        let isValid = true;
        var currentCourseMaterialErrors = {
            materialType: false,
            materialFile: false,
        };

        if (!newCourseMaterial.materialType) {
            currentCourseMaterialErrors.materialType = true;
            isValid = false;
        }
        if (!newCourseMaterial.materialLink) {
            currentCourseMaterialErrors.materialFile = true;
            isValid = false;
        }

        setCourseMaterialErrors(currentCourseMaterialErrors);

        return isValid;
    }

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if(name === "duration") {
            setTraining({
                ...training,
                [name]: Number(value),
            });
        } else {
            setTraining({
                ...training,
                [name]: value,
            });
        }

        if (overviewErrors[name]) {
            setOverviewErrors({
                ...overviewErrors,
                [name]: false,
            });
        }
    };

    const [newQuizQuestion, setNewQuizQuestion] = useState<Partial<QuizQuestion>>({
        question: "",
        questionType: "",
        choice1: "",
        choice2: "",
        choice3: "",
        choice4: "",
        correctChoice: "1",
    });

    const handleQuizInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTraining({
            ...training,
            quiz: {
                ...training.quiz,
                [name]: Number(value),
            },
        });

        if (quizErrors[name]) {
            setQuizErrors({
                ...quizErrors,
                [name]: false,
            });
        }
    };

    const handleQuestionTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

        console.log(name, value);

        if (quizQuestionErrors[name]) {
            setQuizQuestionErrors({
                ...quizQuestionErrors,
                [name]: false,
            });
        }

        // check if the question type is multiple choice
        if (name === "questionType" && value === "Single-Choice") {
            console.log("Single Choice");
            setQuizQuestionErrors({
                question: false,
                questionType: false,
                choice1: false,
                choice2: false,
                choice3: false,
                choice4: false,
                correctChoice: false,
                questionImage: false,
            });

            setNewQuizQuestion({
                ...newQuizQuestion,
                choice1: "",
                choice2: "",
                choice3: "",
                choice4: "",
                correctChoice: "1",
                [name]: value,
            });
        } else if (name === "questionType" && value === "True-False") {
            console.log("True/False");
            setQuizQuestionErrors({
                question: false,
                questionType: false,
                choice1: false,
                choice2: false,
                choice3: false,
                choice4: false,
                correctChoice: false,
                questionImage: false,
            });

            setNewQuizQuestion({
                ...newQuizQuestion,
                choice1: "True",
                choice2: "False",
                correctChoice: "1",
                [name]: value,
            });
        } else if (name === "questionType" && value === "Multiple-Choice") {
            console.log("Multiple Choice");

            setQuizQuestionErrors({
                question: false,
                questionType: false,
                choice1: false,
                choice2: false,
                choice3: false,
                choice4: false,
                correctChoice: false,
                questionImage: false,
            });

            setNewQuizQuestion({
                ...newQuizQuestion,
                choice1: "",
                choice2: "",
                choice3: "",
                choice4: "",
                correctChoice: "1",
                [name]: value,
            });

            setMultipleChoiceOptions([1]);
        } else {
            setNewQuizQuestion({
                ...newQuizQuestion,
                [name]: value,
            });
        }
    }

    const handleQuizChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        if (quizQuestionErrors[name]) {
            setQuizQuestionErrors({
                ...quizQuestionErrors,
                [name]: false,
            });
        }

        setNewQuizQuestion({
            ...newQuizQuestion,
            [name]: value,
        });

        
    };

    const validateQuizQuestion = () => {
        let isValid = true;
        var currentQuizQuestionErrors = {
            question: false,
            questionType: false,
            choice1: false,
            choice2: false,
            choice3: false,
            choice4: false,
            correctChoice: false,
            questionImage: false,
        };

        if (!newQuizQuestion.question) {
            currentQuizQuestionErrors.question = true;
            isValid = false;
        }
        if (!newQuizQuestion.questionType) {
            currentQuizQuestionErrors.questionType = true;
            isValid = false;
        } else if (newQuizQuestion.questionType === "Multiple-Choice") {
            if (multipleChoiceOptions.length === 0) {
                currentQuizQuestionErrors.correctChoice = true;
                isValid = false;
            } else {
                if (multipleChoiceOptions.length > 1) {
                    newQuizQuestion.correctChoice = multipleChoiceOptions.join("|");
                } else {
                    newQuizQuestion.correctChoice = multipleChoiceOptions[0].toString();
                }
            }
        }

        if (!newQuizQuestion.choice1) {
            currentQuizQuestionErrors.choice1 = true;
            isValid = false;
        }
        if (!newQuizQuestion.choice2) {
            currentQuizQuestionErrors.choice2 = true;
            isValid = false;
        }

        if (newQuizQuestion?.questionType !== "True-False") {

            if (!newQuizQuestion.choice3) {
                currentQuizQuestionErrors.choice3 = true;
                isValid = false;
            }
            if (!newQuizQuestion.choice4) {
                currentQuizQuestionErrors.choice4 = true;
                isValid = false;
            }
        }
        if (!newQuizQuestion.correctChoice) {
            currentQuizQuestionErrors.correctChoice = true;
            isValid = false;
        }

        if (newQuizQuestion?.questionType === "Image-Based" && !newQuizQuestion.questionImage) {
            currentQuizQuestionErrors.questionImage = true;
            isValid = false;
        }

        setQuizQuestionErrors(currentQuizQuestionErrors);

        return isValid;
    }

    const handleAddQuizQuestion = () => {
        if (!validateQuizQuestion()) {
            return;
        }

        setTraining({
            ...training,
            quiz: {
                ...training.quiz,
                questions: [
                    ...training.quiz.questions,
                    {
                        ...newQuizQuestion,
                        questionID: "", // Generate a random ID
                        quizID: training.quiz.quizID,
                        questionSequence: training.quiz.questions.length + 1,
                    } as QuizQuestion,
                ],
            },
        });
        setNewQuizQuestion({
            question: "",
            choice1: "",
            choice2: "",
            choice3: "",
            choice4: "",
            correctChoice: "1",
        });

        setShowQuizError(false);
        setShowNewQuizQuestion(false);
    };

    const handleRemoveQuizQuestion = (questionID: string) => {
        const currentQuestions = [...training.quiz.questions];

        const index = currentQuestions.findIndex((question) => question.questionID === questionID);

        if (index > -1) {
            currentQuestions.splice(index, 1);
        }



        forEach(currentQuestions.sort((a, b) => a.questionSequence - b.questionSequence), (question, index) => {
            question.questionSequence = index + 1;
        });

        setTraining({
            ...training,
            quiz: {
                ...training.quiz,
                questions: currentQuestions,
            },
        });
    }

    const [newCourseMaterial, setNewCourseMaterial] = useState<Partial<CourseMaterial>>({
        materialType: "",
        materialLink: "",
    });

    const [multipleChoiceOptions, setMultipleChoiceOptions] = useState<number[]>([1]);

    const handleCourseMaterialChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewCourseMaterial({
            ...newCourseMaterial,
            [name]: value,
        });

        if (courseMaterialErrors[name]) {
            setCourseMaterialErrors({
                ...courseMaterialErrors,
                [name]: false,
            });
        }
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onloadend = async () => {
                const uploadFile: TrainingUploadFile = {
                    fileName: file.name,
                    base64String: reader.result as string,
                };

                //console.log(reader.result as string);

                // Call the uploadFileToBlob function here
                // var url = await uploadTrainingFileToBlob(uploadFile, appContext.handleAPICallError);

                //var url = await uploadFileToAzureBlobStorage(file.name, reader.result as string); 

                setUploadingFile(true);

                const fileUploadDTO = {
                    "FileName": file.name,
                    "FileType": file.type,
                    "Base64FileContent": reader.result
                };

                var blobURL =  await uploadTrainingFilesToBlob(fileUploadDTO, appContext.handleAPICallError);

                console.log(blobURL.data);

                setNewCourseMaterial({
                    ...newCourseMaterial,
                    materialLink: blobURL.data,
                    materialName: file.name,
                });

                setUploadingFile(false);
            };
            reader.readAsDataURL(file);

            if (courseMaterialErrors.materialFile) {
                setCourseMaterialErrors({
                    ...courseMaterialErrors,
                    materialFile: false,
                });
            }
        }
    };

    const handleAddCourseMaterial = () => {
        if (!validateCourseMaterial()) {
            return;
        }

        setTraining({
            ...training,
            courseMaterials: [
                ...training.courseMaterials,
                {
                    ...newCourseMaterial,
                    courseMaterialID: "", // Generate a random ID
                    trainingID: training.trainingID,
                    materialSequence: training.courseMaterials.length + 1,
                } as CourseMaterial,
            ],
        });
        setNewCourseMaterial({ materialType: "", materialLink: "" });

        // Clear the file input
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

        setShowCourseMaterialError(false);
    };

    const removeSelectedEmployee = (employeeID: string) => {
        setSelectedEmployees(selectedEmployees.filter((employee) => employee.employeeID !== employeeID));

        var employees = [...training.trainingAssignments];

        var index = employees.findIndex((employee) => employee.providerID === employeeID);

        if (index > -1) {
            employees.splice(index, 1);
        }

        setTraining({
            ...training,
            trainingAssignments: employees,
        });

        var newFilteredEmployees = [
            ...filteredEmployees,
            activeEmployees.find((employee) => employee.employeeID === employeeID),
        ]

        setFilteredEmployees(newFilteredEmployees.sort((a, b) => a.firstName.localeCompare(b.firstName)));
    }

    const handleRemoveCourseMaterial = (courseMaterialID: string) => {
        var materials = [...training.courseMaterials]

        var index = materials.findIndex((material) => material.courseMaterialID === courseMaterialID);

        if (index > -1) {
            materials.splice(index, 1);
        }

        forEach(materials.sort((a, b) => a.materialSequence - b.materialSequence), (material, index) => {
            material.materialSequence = index + 1;
        });

        setTraining({
            ...training,
            courseMaterials: materials,
        });
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setTraining({
            ...training,
            [name]: value,
        });

        if (overviewErrors[name]) {
            setOverviewErrors({
                ...overviewErrors,
                [name]: false,
            });
        }

        if (audienceErrors[name]) {
            setAudienceErrors({
                ...audienceErrors,
                [name]: false,
            });
        }
    };

    const options = [
        { value: 1, label: 'Choice 1' },
        { value: 2, label: 'Choice 2' },
        { value: 3, label: 'Choice 3' },
        { value: 4, label: 'Choice 4' },
    ];

    const employees = [
        "Alice Johnson",
        "Bob Smith",
        "Charlie Brown",
        "Diana Prince",
        "Edward Norton"
    ];

    const handleReorderCourseMaterial = (
        currentSequence: number,
        direction: 'up' | 'down'
      ): void => {
        const newCourseMaterials = [...training.courseMaterials];
        
        // Sort the materials by sequence to ensure correct order
        newCourseMaterials.sort((a, b) => a.materialSequence - b.materialSequence);
        
        // Find the material: 
        const currentMaterial = newCourseMaterials.find(
            material => material.materialSequence === currentSequence
            );
        
        if (!currentMaterial) {
            return;
        }
      
        if (direction === 'up') {
            if (currentSequence === 1) {
              return;
            }

            // Swap the current item with the one above it
            const materialAbove = newCourseMaterials.find(
                material => material.materialSequence === currentSequence - 1
            );

            if (!materialAbove) {
                return;
            }

            console.log(materialAbove);

            const tempSequence = currentMaterial.materialSequence;
            currentMaterial.materialSequence = materialAbove.materialSequence;
            materialAbove.materialSequence = tempSequence;

        }
        else if (direction === 'down') {
          // Swap the current item with the one below it

            if (currentSequence === newCourseMaterials.length) {
                return;
            }

            const materialBelow = newCourseMaterials.find(
                material => material.materialSequence === currentSequence + 1
            );

            if (!materialBelow) {
                return;
            }

            console.log(materialBelow);

            const tempSequence = currentMaterial.materialSequence;
            currentMaterial.materialSequence = materialBelow.materialSequence;
            materialBelow.materialSequence = tempSequence;

         
        }
      
        // Re-sort the array based on materialSequence
        //newCourseMaterials.sort((a, b) => a.materialSequence - b.materialSequence);

        // Update State

        setTraining({
            ...training,
            courseMaterials: newCourseMaterials,
        });
      
       
      };

    const handleReorderQuizQuestion = (
        currentSequence: number,
        direction: 'up' | 'down'
      ): void => {
        const newQuestions = [...training.quiz.questions];
        
        // Sort the questions by sequence to ensure correct order
        newQuestions.sort((a, b) => a.questionSequence - b.questionSequence);
        
        // Find the question: 
        const currentQuestion = newQuestions.find(
            question => question.questionSequence === currentSequence
            );
        
        if (!currentQuestion) {
            return;
        }
      
        if (direction === 'up') {
            if (currentSequence === 1) {
              return;
            }

            // Swap the current item with the one above it
            const questionAbove = newQuestions.find(
                question => question.questionSequence === currentSequence - 1
            );

            if (!questionAbove) {
                return;
            }

            console.log(questionAbove);

            const tempSequence = currentQuestion.questionSequence;
            currentQuestion.questionSequence = questionAbove.questionSequence;
            questionAbove.questionSequence = tempSequence;

        }
        else if (direction === 'down') {
          // Swap the current item with the one below it

            if (currentSequence === newQuestions.length) {
                return;
            }

            const questionBelow = newQuestions.find(
                question => question.questionSequence === currentSequence + 1
            );

            if (!questionBelow) {
                return;
            }

            console.log(questionBelow);

            const tempSequence = currentQuestion.questionSequence;
            currentQuestion.questionSequence = questionBelow.questionSequence;
            questionBelow.questionSequence = tempSequence;

         
        }
      
        // Re-sort the array based on questionSequence
        //newQuestions.sort((a, b) => a.questionSequence - b.questionSequence);

        // Update State

        setTraining({
            ...training,
            quiz: {
                ...training.quiz,
                questions: newQuestions,
            },
        });
      
       
    }

    const handleQuizFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onloadend = async () => {
                const uploadFile: TrainingUploadFile = {
                    fileName: file.name,
                    base64String: reader.result as string,
                };

                const fileUploadDTO = {
                    "FileName": file.name,
                    "FileType": file.type,
                    "Base64FileContent": reader.result
                };

                setUploadingImageFile(true);

                var blobURL =  await uploadTrainingFilesToBlob(fileUploadDTO, appContext.handleAPICallError);

                console.log(blobURL.data);

                setNewQuizQuestion({
                    ...newQuizQuestion,
                    questionImage: blobURL.data,
                });

                setUploadingImageFile(false);
            };
            reader.readAsDataURL(file);

            if (quizQuestionErrors.questionImage) {
                setQuizQuestionErrors({
                    ...quizQuestionErrors,
                    questionImage: false,
                });
            }
        }
    };

    const [showQuestions, setShowQuestions] = useState<boolean>(false);

    const toggleQuestions = () => {
        setShowQuestions(!showQuestions);
    }

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div className="tw-space-y-4">
                        {/* <h2 className="tw-text-xl tw-font-semibold">Basic Information</h2> */}
                        <label htmlFor="trainingName">Name</label>
                        <input
                            type="text"
                            id="trainingName"
                            name="trainingName"
                            placeholder="Training Name"
                            value={training?.trainingName}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.trainingName ? "tw-border-red-500": "tw-border-gray-300"} ${!editMode ? "tw-bg-gray-200" : ""} tw-rounded`}
                        />
                        {overviewErrors.trainingName && <p className="tw-text-red-500">{overviewErrorMessages.trainingName}</p>}
                        {editMode && <p className="tw-text-gray-400 tw-text-sm">Character Count: {training?.trainingName.length} / Max: 60</p>}
                        <label htmlFor="trainingDescription">Description</label>
                        <textarea
                            id="trainingDescription"
                            name="trainingDescription"
                            placeholder="Training Description"
                            value={training?.trainingDescription}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.trainingDescription ? "tw-border-red-500": "tw-border-gray-300"} ${!editMode ? "tw-bg-gray-200" : ""} tw-rounded`}
                        />
                        {editMode && <p className="tw-text-gray-400 tw-text-sm">Character Count: {training?.trainingDescription.length} / Max: 500</p>}
                        {overviewErrors.trainingDescription && <p className="tw-text-red-500">{overviewErrorMessages.trainingDescription}</p>}
                        <label htmlFor="trainingCategory">Category</label>
                        <select
                            id="trainingCategory"
                            name="trainingCategory"
                            value={training?.trainingCategory}
                            onChange={handleSelectChange}
                            disabled
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.trainingCategory ? "tw-border-red-500": "tw-border-gray-300"} tw-bg-gray-200 tw-rounded`}
                        >
                            <option value="" disabled>Select Training Category</option>
                            <option value="Client Service Plan Training">Service Plan Training</option>
                            <option value="Client Supplemental Training">Supplemental Training</option>
                            {/* <option value="Agency Training">Agency Training</option>
                            <option value="State Mandated Training">State Mandated Training</option> */}
                        </select>
                        {overviewErrors.trainingCategory && <p className="tw-text-red-500">{overviewErrorMessages.trainingCategory}</p>}
                        {training.trainingCategory === "Client Supplemental Training" && (<>
                        <label htmlFor="supplementalCategory">Supplemental Category</label>
                        <select
                            id="supplementalCategory"
                            name="supplementalCategory"
                            value={training?.supplementalCategory}
                            onChange={handleSelectChange}
                            disabled={!editMode}
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.supplementalCategory ? "tw-border-red-500": "tw-border-gray-300"} ${!editMode ? "tw-bg-gray-200" : ""} tw-rounded`}
                        >
                            <option value="" disabled>Select Supplemental Category</option>
                            <option value="Physical">Physical</option>
                            <option value="Emotional">Emotional</option>
                            <option value="Safety">Safety</option>
                            <option value="Favorites">Favorites</option>
                            <option value="Other">Other</option>
                        </select>
                        {overviewErrors.supplementalCategory && <p className="tw-text-red-500">{overviewErrorMessages.supplementalCategory}</p>}
                        </>)}
                        {(<>
                            <label htmlFor="clientID">Client Name</label>
                            <select
                                id="clientID"
                                name="clientID"
                                value={training?.clientID}
                                onChange={handleSelectChange}
                                disabled
                                className={`tw-w-full tw-p-2 tw-border ${overviewErrors.clientName ? "tw-border-red-500": "tw-border-gray-300"}  tw-bg-gray-200 tw-rounded`}
                            >
                                <option value="" disabled>Select Client</option>
                                {clients.map((client, index) => (
                                    <option key={index} value={client.patientID}>{client.firstName} {client.lastName}</option>
                                ))}
                            </select>
                            {overviewErrors.clientName && <p className="tw-text-red-500">{overviewErrorMessages.clientName}</p>}
                            </>
                        )}

                        <label htmlFor="duration">Duration (in minutes)</label>
                        <input
                            type="number"
                            id="duration"
                            name="duration"
                            placeholder="Training Duration (in minutes)"
                            value={training?.duration}
                            disabled={!editMode}
                            onChange={handleInputChange}
                            className={`tw-w-full tw-p-2 tw-border ${overviewErrors.duration ? "tw-border-red-500": "tw-border-gray-300"} ${!editMode ? "tw-bg-gray-200" : ""} tw-rounded`}
                        />
                        {overviewErrors.duration && <p className="tw-text-red-500">{overviewErrorMessages.duration}</p>}
                        {!editMode ? <>
                            <label htmlFor="validTill">Valid Till</label>

                            <div className="tw-p-2 tw-bg-gray-200 tw-border tw-border-gray-300 tw-rounded">
                                <p>{moment(training?.validTill).format("MM-DD-YYYY")}</p>
                            </div>
                            
                           
                        </>:<>
                            <CalendarSelector id="validTill" className={overviewErrors.validTill ? '_form-error' : ''} 
                             name="Valid Till:" value={ moment(training?.validTill).format("MM-DD-YYYY") || null } 
                             disabled={!editMode}
                             onChange={ e => {
                                e.preventDefault();
                                console.log(e.target.value);
                                setTraining({...training, "validTill": moment(e.target.value).toDate()});
                            }}/>
                            {overviewErrors.validTill && <p className="tw-text-red-500">{overviewErrorMessages.validTill}</p>}
                        </>}

                            

                           
                    </div>
                );
            case 2:
                return (
                    <div className="tw-space-y-4">
                        {/* <h2 className="tw-text-xl tw-font-semibold">Target Audience</h2> */}
                        <label htmlFor="targetAudience">Audience</label>
                        <select 
                            id="targetAudience"
                            name="targetAudience"
                            value={training.targetAudience}
                            disabled={!editMode}
                            onChange={(e) => {
                                e.preventDefault();
                                handleSelectChange(e);
                                setShowAudienceError(false);
                            }}
                            className={`tw-w-full tw-p-2 tw-border ${audienceErrors.targetAudience ? "tw-border-red-500": "tw-border-gray-300"} ${!editMode ? "tw-bg-gray-200" : ""} tw-rounded`}
                        >
                            <option value="" disabled>Select Audience</option>
                            <option value="Direct Support Care Team">Direct Support Care Team</option>
                            <option value="Remote Support Care Team">Remote Support Care Team</option>
                            <option value="Entire Care Team">Entire Care Team</option>
                            <option value="Select Employees">Select Employees</option>
                        </select>
                        {audienceErrors.targetAudience && <p className="tw-text-red-500">{audienceErrorMessages.targetAudience}</p>}

                        {<p className="tw-text-amber-400">Note: The assigned employees (audience) are not notified via email until the training has been published!</p>}

                        {training.targetAudience === "Select Employees" && (
                            <>
                                {editMode && (<>
                                <div className="tw-mt-4">Add Employees</div>

                                <div className="tw-flex tw-items-center tw-space-x-4">
                                <select 
                                    id="employees"
                                    name="employees"
                                    value={selectedEmployeeID}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        const { value } = e.target;
                                        setSelectedEmployeeID(value);
                                    }}
                                    className="tw-w-1/2 tw-p-2 tw-border tw-border-gray-300 tw-rounded"
                                    >
                                    <option value="" disabled>Select Employee</option>
                                    {filteredEmployees.map((employee, index) => (
                                        <option key={index} value={employee.employeeID}>{employee.firstName} {employee.lastName}</option>
                                    ))}
                                </select>

                                <ButtonPrimary onClick={() => {
                                    const employee = activeEmployees.find((employee) => employee.employeeID === selectedEmployeeID);

                                    if (employee) {
                                        if (selectedEmployees.find((emp) => emp.employeeID === employee.employeeID)) {
                                            return;
                                        }
                                        setSelectedEmployees([...selectedEmployees, employee]);

                                        setFilteredEmployees(filteredEmployees.filter((emp) => emp.employeeID !== employee.employeeID));
                                    }

                                    setSelectedEmployeeID("");
                                    setShowAudienceError(false);
                                }}>Add Employee</ButtonPrimary>

                                </div>
                                </>)}


                                
                                <EmployeeAssignmentList employees={selectedEmployees} removeEmployee={removeSelectedEmployee} editMode={editMode} />
                            </>

                        )}
                    </div>
                );
            
            case 3:
                return (
                    <div className="tw-space-y-4 tw-mt-4">
                        {/* <h2 className="tw-text-xl tw-font-semibold">Course Materials</h2> */}
                        {editMode && <div className="tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-mb-4">
                            <label htmlFor="materialType">Material Type</label>
                            <select
                                name="materialType"
                                value={newCourseMaterial.materialType}
                                onChange={handleCourseMaterialChange}
                                className={`tw-w-full tw-p-2 tw-border ${courseMaterialErrors.materialType ?  "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            >
                                <option value="" disabled>Select Material Type</option>
                                <option value="Document">Document</option>
                                {/* <option value="Slide">Slide</option> */}
                                <option value="Video">Video</option>
                            </select>
                            {courseMaterialErrors.materialType && <p className="tw-text-red-500">{courseMaterialErrorMessages.materialType}</p>}
                            <input
                                type="file"
                                name="materialLink"
                                onChange={handleFileUpload}
                                className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-4"
                                ref={fileInputRef}
                                accept=".pdf, .mp4"
                            />
                            {courseMaterialErrors.materialFile && <p className="tw-text-red-500">{courseMaterialErrorMessages.materialFile}</p>}
                            {uploadingFile ? <LoadingSpinner /> : <ButtonPrimary
                                onClick={handleAddCourseMaterial}>
                                Add Course Material
                            </ButtonPrimary>}
                        </div>}
                        {training?.courseMaterials &&
                        [...training.courseMaterials]
                        .sort((a, b) => a.materialSequence - b.materialSequence)
                        .map((material, index) => (
                        <div key={index} className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-mb-4">
                        <div className="tw-flex tw-justify-between tw-items-center">
                            <h3 className="tw-text-lg tw-font-semibold">
                            {material.materialType} - {extractFileName(material.materialLink)}
                            </h3>
                            {editMode && (
                            <div className="tw-flex tw-space-x-2">
                                <button
                                disabled={material.materialSequence === 1}
                                onClick={() => handleReorderCourseMaterial(material.materialSequence, 'up')}
                                className="tw-p-1 tw-rounded tw-bg-gray-200 hover:tw-bg-gray-300 disabled:tw-opacity-50"
                                >
                                <ArrowUpIcon className="tw-h-5 tw-w-5" />
                                </button>
                                <button
                                onClick={() => handleReorderCourseMaterial(material.materialSequence, 'down')}
                                disabled={material.materialSequence === training.courseMaterials.length}
                                className="tw-p-1 tw-rounded tw-bg-gray-200 hover:tw-bg-gray-300 disabled:tw-opacity-50"
                                >
                                <ArrowDownIcon className="tw-h-5 tw-w-5" />
                                </button>
                            </div>
                            )}
                        </div>
                        {material.materialType === "Document" && (
                            <a href={material.materialLink} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500">
                            View Document
                            </a>
                        )}
                        {material.materialType === "Slide" && (
                            <a href={material.materialLink} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500">
                            View Slide
                            </a>
                        )}
                        {material.materialType === "Video" && (
                            <a href={material.materialLink} target="_blank" rel="noopener noreferrer" className="tw-text-blue-500">
                            View Video
                            </a>
                        )}
                        {editMode && (
                            <ButtonErrorSecondary
                            className="tw-mt-4 tw-max-w-250px"
                            onClick={() => handleRemoveCourseMaterial(material.courseMaterialID)}
                            >
                            Remove Course Material
                            </ButtonErrorSecondary>
                        )}
                        </div>
                         ))}
                    </div>
                );
            case 4:
                return (
                    <div className="tw-space-y-4">
                        {/* <h2 className="tw-text-xl tw-font-semibold">Quiz</h2> */}
                        <div className="tw-space-y-2">
                            <label htmlFor="passingScore">Passing Score (%)</label>
                            <input
                                type="number"
                                name="passingScore"
                                id="passingScore"
                                placeholder="Passing Score (%)"
                                value={training?.quiz.passingScore}
                                disabled={!editMode}
                                onChange={handleQuizInputChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizErrors.passingScore ? "tw-border-red-500": "tw-border-gray-300"} ${!editMode ? "tw-bg-gray-200" : ""} tw-rounded`}
                            />
                            {quizErrors.passingScore && <p className="tw-text-red-500">{quizErrorMessages.passingScore}</p>}
                            <label htmlFor="maxAttempts">Max Attempts (default 3)</label>
                            <input
                                type="number"
                                name="maxAttempts"
                                id="maxAttempts"
                                value={training?.quiz.maxAttempts}
                                readOnly
                                className="tw-w-full tw-p-2 tw-border tw-bg-gray-200 tw-border-gray-300 tw-rounded tw-mb-4"
                                title="Max Attempts (default 3)"
                            />
                            {quizErrors.maxAttempts && <p className="tw-text-red-500">{quizErrorMessages.maxAttempts}</p>}
                        </div>
                        {showNewQuizQuestion ? <>
                        <div className="tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-mb-4">
                            <label htmlFor="questionType">Question Type</label>
                            <select
                                name="questionType"
                                id="questionType"
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.questionType ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                onChange={handleQuestionTypeChange}
                            >
                                <option value="">Select Question Type</option>
                                <option value="Single-Choice">Single Correct Choice</option>
                                <option value="Multiple-Choice">Multiple Correct Choice</option>
                                <option value="True-False">True/False</option>
                                <option value="Image-Based">Image Based</option>
                            </select>
                            {quizQuestionErrors.questionType && <p className="tw-text-red-500">{quizQuestionErrorMessages.questionType}</p>}
                            {newQuizQuestion?.questionType && <>
                            <label htmlFor="question">Question {training?.quiz?.questions ? training?.quiz?.questions.length + 1 : 1 }</label>
                            <textarea
                                name="question"
                                id="question"
                                placeholder="Question"
                                value={newQuizQuestion.question}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.question ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                rows={3} // You can adjust this value to set the initial number of visible text lines
                            />
                            {quizQuestionErrors.question && <p className="tw-text-red-500">{quizQuestionErrorMessages.question}</p>}
                            </>}
                            {newQuizQuestion?.questionType === "Image-Based" && <>
                                <label htmlFor="questionImage">Question Image</label>
                                <input
                                    type="file"
                                    name="questionImage"
                                    onChange={handleQuizFileUpload}
                                    className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-4"
                                    ref={fileInputRef}
                                    accept=".png, .jpg, .jpeg"
                                />
                                {quizQuestionErrors.questionImage && <p className="tw-text-red-500">{quizQuestionErrorMessages.questionImage}</p>}
                                {uploadingImageFile && <LoadingSpinner /> }

                                {newQuizQuestion.questionImage && (
                                    <div className="tw-flex tw-justify-between">
                                        <img src={newQuizQuestion.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />   
                                    </div>
                                     
                                )}
                            </>}
                            
                            {newQuizQuestion?.questionType && <>
                            <label htmlFor="choice1">Choice 1</label>
                            <input
                                type="text"
                                name="choice1"
                                id="choice1"
                                placeholder="Choice 1"
                                value={newQuizQuestion.choice1}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.choice1 ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            />
                            {quizQuestionErrors.choice1 && <p className="tw-text-red-500">{quizQuestionErrorMessages.choice1}</p>}
                            <label htmlFor="choice2">Choice 2</label>
                            <input
                                type="text"
                                name="choice2"
                                id="choice2"
                                placeholder="Choice 2"
                                value={newQuizQuestion.choice2}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.choice2 ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            />
                            {quizQuestionErrors.choice2 && <p className="tw-text-red-500">{quizQuestionErrorMessages.choice2}</p>}
                            {newQuizQuestion?.questionType !== "True-False" && <>
                            <label htmlFor="choice3">Choice 3</label>
                            <input
                                type="text"
                                name="choice3"
                                id="choice3"
                                placeholder="Choice 3"
                                value={newQuizQuestion.choice3}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.choice3 ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            />
                            {quizQuestionErrors.choice3 && <p className="tw-text-red-500">{quizQuestionErrorMessages.choice3}</p>}
                            <label htmlFor="choice4">Choice 4</label>
                            <input
                                type="text"
                                name="choice4"
                                id="choice4"
                                placeholder="Choice 4"
                                value={newQuizQuestion.choice4}
                                onChange={handleQuizChange}
                                className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.choice4 ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                            />
                            {quizQuestionErrors.choice4 && <p className="tw-text-red-500">{quizQuestionErrorMessages.choice4}</p>}
                            </>}
                            {newQuizQuestion?.questionType === "Single-Choice" && <>
                                <label htmlFor="correctChoice">Correct Choice</label>
                                <select
                                    name="correctChoice"
                                    id="correctChoice"
                                    value={newQuizQuestion.correctChoice}
                                    onChange={handleQuizChange}
                                    className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.correctChoice ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                >
                                    <option value={1}>Choice 1</option>
                                    <option value={2}>Choice 2</option>
                                    <option value={3}>Choice 3</option>
                                    <option value={4}>Choice 4</option>
                                </select>
                                {quizQuestionErrors.correctChoice && <p className="tw-text-red-500">{quizQuestionErrorMessages.correctChoice}</p>}
                            </>}

                            {newQuizQuestion?.questionType === "Multiple-Choice" && <>
                            <label htmlFor="correctChoice">Correct Choices</label>
                                <CustomDropdown
                                    options={options}
                                    selectedOptions={multipleChoiceOptions}
                                    onChange={setMultipleChoiceOptions}
                                />
                                {quizQuestionErrors.correctChoice && (
                                    <p className="tw-text-red-500">{quizQuestionErrorMessages.correctChoice}</p>
                                )}
                            </>}

                            {newQuizQuestion?.questionType === "True-False" && <>
                            <label htmlFor="correctChoice">Correct Choice</label>
                                <select
                                    name="correctChoice"
                                    id="correctChoice"
                                    value={newQuizQuestion.correctChoice}
                                    onChange={handleQuizChange}
                                    className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.correctChoice ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                >
                                    <option value={1}>True</option>
                                    <option value={2}>False</option>
                                </select>
                                {quizQuestionErrors.correctChoice && <p className="tw-text-red-500">{quizQuestionErrorMessages.correctChoice}</p>}
                            </>}

                            {newQuizQuestion?.questionType === "Image-Based" && <>
                                <label htmlFor="correctChoice">Correct Choice</label>
                                <select
                                    name="correctChoice"
                                    id="correctChoice"
                                    value={newQuizQuestion.correctChoice}
                                    onChange={handleQuizChange}
                                    className={`tw-w-full tw-p-2 tw-border ${quizQuestionErrors.correctChoice ? "tw-border-red-500": "tw-border-gray-300"} tw-rounded tw-mb-4`}
                                >
                                    <option value={1}>Choice 1</option>
                                    <option value={2}>Choice 2</option>
                                    <option value={3}>Choice 3</option>
                                    <option value={4}>Choice 4</option>
                                </select>
                                {quizQuestionErrors.correctChoice && <p className="tw-text-red-500">{quizQuestionErrorMessages.correctChoice}</p>}
                            </>}

                            </>}

                            <div className="tw-flex tw-justify-between tw-mt-5">
                                <ButtonErrorSecondary onClick={() => setShowNewQuizQuestion(false)}>Cancel</ButtonErrorSecondary>

                                <ButtonPrimary
                                    onClick={handleAddQuizQuestion}
                                >
                                    Add Quiz Question
                                </ButtonPrimary>
                            </div>
                        </div>
                        </>:<>
                        {editMode && <ButtonPrimary
                            onClick={() => {
                                setQuizQuestionErrors({
                                    question: false,
                                    questionType: false,
                                    choice1: false,
                                    choice2: false,
                                    choice3: false,
                                    choice4: false,
                                    correctChoice: false,
                                    questionImage: false,
                                });

                                setNewQuizQuestion({
                                    question: "",
                                    questionType: "",
                                    choice1: "",
                                    choice2: "",
                                    choice3: "",
                                    choice4: "",
                                    correctChoice: "1",
                                });


                                setShowNewQuizQuestion(true);
                            


                            }}
                        >Add New Quiz Question</ButtonPrimary>}
                        </>}

                        <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                            <div className="tw-flex tw-justify-between">
                                <h3 className={`tw-text-xl tw-pb-2 ${showQuiz ? "tw-font-semibold":"tw-font-normal"}`}>Questions - {training?.quiz?.questions?.length}</h3>

                                {showQuiz ? 
                                <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleQuestions()} />:
                                <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleQuestions()} />}
                            </div>

                            {showQuestions && 
                            training?.quiz &&
                            [...training.quiz.questions]
                            .sort((a, b) => a.questionSequence - b.questionSequence)
                            .map((question, index) => (
                            <div key={index} className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-mb-4">
                                <div className="tw-flex tw-justify-between tw-items-center">
                                <h3 className="tw-text-lg tw-font-semibold">Question {index + 1}</h3>
                                {editMode && (
                                    <div className="tw-flex tw-space-x-2">
                                        <button
                                        disabled={question.questionSequence === 1}
                                        onClick={() => handleReorderQuizQuestion(question.questionSequence, 'up')}
                                        className="tw-p-1 tw-rounded tw-bg-gray-200 hover:tw-bg-gray-300 disabled:tw-opacity-50"
                                        >
                                        <ArrowUpIcon className="tw-h-5 tw-w-5" />
                                        </button>
                                        <button
                                        onClick={() => handleReorderQuizQuestion(question.questionSequence, 'down')}
                                        disabled={question.questionSequence === training.quiz.questions.length}
                                        className="tw-p-1 tw-rounded tw-bg-gray-200 hover:tw-bg-gray-300 disabled:tw-opacity-50"
                                        >
                                        <ArrowDownIcon className="tw-h-5 tw-w-5" />
                                        </button>
                                    </div>
                                    )}
                                    </div>
                                <p>{question.questionType}</p>
                                <p>{question.question}</p>
                                {question.questionType === "Image-Based" && (
                                        <div className="tw-flex tw-justify-between">
                                            <img src={question.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />
                                        </div>
                                        
                                    )}
                                <ul className="tw-list-decimal tw-pl-5">
                                    <li>{question.choice1}</li>
                                    <li>{question.choice2}</li>
                                    {question.questionType !== "True-False" && <li>{question.choice3}</li>}
                                    {question.questionType !== "True-False" && <li>{question.choice4}</li>}
                                </ul>
                                <p>Correct Choice: {question.correctChoice}</p>
                                {editMode && <ButtonErrorSecondary className="tw-mt-4 tw-max-w-250px" onClick={() => handleRemoveQuizQuestion(question.questionID)}>Remove Quiz Question</ButtonErrorSecondary>}
                            </div>
                        ))}
                        </div>
                        
                    </div>
                );
            case 5:
                return renderReview();
            default:
                return null;
        }
    };

    const [showTrainingOverview, setShowTrainingOverview] = useState(true);
    const [showCourseMaterials, setShowCourseMaterials] = useState(false);
    const [showQuiz, setShowQuiz] = useState(false);
    const [showAudience, setShowAudience] = useState(false);
    const [showPublishSection, setShowPublishSection] = useState(false);

    const renderReview = () => {

        const toggleShowPublishSection = () => {

            if (!showPublishSection) {
                setShowAudience(false);
                setShowQuiz(false);
                setShowCourseMaterials(false);
                setShowTrainingOverview(false);
            }

            setShowPublishSection(!showPublishSection);
        };

        const toggleOverview = () => {
            if (!showTrainingOverview) {
                setShowPublishSection(false);
                setShowAudience(false);
                setShowQuiz(false);
                setShowCourseMaterials(false);
            }

            setShowTrainingOverview(!showTrainingOverview);
        }

        const toggleMaterials = () => {
            if (!showCourseMaterials) {
                setShowPublishSection(false);
                setShowAudience(false);
                setShowQuiz(false);
                setShowTrainingOverview(false);
            }

            setShowCourseMaterials(!showCourseMaterials);
        }

        const toggleQuiz = () => {
            if (!showQuiz) {
                setShowPublishSection(false);
                setShowAudience(false);
                setShowCourseMaterials(false);
                setShowTrainingOverview(false);
            }

            setShowQuiz(!showQuiz);
        }

        const toggleAudience = () => {
            if (!showAudience) {
                setShowPublishSection(false);
                setShowQuiz(false);
                setShowCourseMaterials(false);
                setShowTrainingOverview(false);
            }

            setShowAudience(!showAudience);
        }


        return (<div className="tw-flex tw-flex-col tw-space-y-5">

            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showTrainingOverview ? "tw-font-semibold":"tw-font-normal"}`}>Overview</h3>

                    {showTrainingOverview ? 
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleOverview()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleOverview()} />}
                </div>

                {showTrainingOverview && (<>
                
                        {training && (
                            <div className="">
                                <p className="tw-text-lg"><strong>Name:</strong> {training.trainingName}</p>
                                <p className="tw-text-lg"><strong>Description:</strong> {training.trainingDescription}</p>
                                <p className="tw-text-lg"><strong>Category:</strong> {training.trainingCategory}</p>
                                <p className="tw-text-lg"><strong>Client Name:</strong> {training.clientName}</p>
                                <p className="tw-text-lg"><strong>Status:</strong> {training.status}</p>
                                {/* <p><strong>Created By:</strong> {training.createdBy}</p> */}
                                <p className="tw-text-lg"><strong>Date Created:</strong> {new Date(training.dateCreated).toLocaleString()}</p>
                                <p className="tw-text-lg"><strong>Expires On:</strong> {new Date(training.validTill).toDateString()}</p>
                            </div>
                        )}


                        {/* <div className="tw-flex tw-justify-end">
                                <ButtonPrimary onClick={handlePublishTraining}>Next</ButtonPrimary>
                        </div> */}
                        </>

                    
                )}
            </div>

            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showCourseMaterials ? "tw-font-semibold":"tw-font-normal"}`}>Materials</h3>

                    {showCourseMaterials ? 
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleMaterials()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleMaterials()} />}
                </div>

                {showCourseMaterials && 
                <>
                {training?.courseMaterials?.map((material, index) => (
                    <>
                    <h3 className="tw-text-lg tw-font-semibold">{extractFileNameWithoutExtension(material.materialLink)}</h3>
                    <iframe 
                                src={material.materialLink} 
                                width="850" 
                                height="600" 
                                title="PDF Viewer"
                                className="tw-border tw-border-gray-300"
                            ></iframe>
                    </>
                ))}
                </>}

                
            </div>

            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showQuiz ? "tw-font-semibold":"tw-font-normal"}`}>Quiz</h3>

                    {showQuiz ? 
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleQuiz()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleQuiz()} />}
                </div>

                {showQuiz && renderPreviewQuiz()}
            </div>

            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showAudience ? "tw-font-semibold":"tw-font-normal"}`}>Audience</h3>

                    {showAudience ? 
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleAudience()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleAudience()} />}
                </div>

                {showAudience && renderPreviewAudience()}
            </div>

            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showPublishSection ? "tw-font-semibold":"tw-font-normal"}`}>Publish</h3>

                    {showPublishSection ?
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleShowPublishSection()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleShowPublishSection()} />}

                </div>

                {showPublishSection && renderReviewPublish()}
            </div>
           
        </div>);
    }

    const [notificationSelection, setNotificationSelection] = useState("all");

    const renderReviewPublish = () => {
        const notificationMethods = [
            { id: 'all', title: 'Notify All Audience' },
            // { id: 'selected', title: 'Notify Selected Audience' },
            { id: 'later', title: 'Notify Audience Later' },
          ];

        return (<>
             <fieldset>
              {/* <legend className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">Notifications</legend> */}
              <p className="tw-mt-1 tw-text-base tw-leading-6 tw-text-gray-900">How do you prefer to notify audience?</p>
              <div className="tw-mt-6 tw-space-y-6">
                {notificationMethods.map((notificationMethod) => (
                  <div key={notificationMethod.id} className="tw-flex tw-items-center">
                    <input
                      id={notificationMethod.id}
                      name="notification-method"
                      type="radio"
                      className="tw-h-4 tw-w-4 tw-border-gray-300 tw-text-primary focus:tw-ring-primary"
                      defaultChecked={notificationMethod.id === "later"}
                      onChange={(e) => {
                        console.log(`Radio button with id ${e.target.id} is selected.`);
                        setNotificationSelection(e.target.id);
                      }}
                 
                    />
                    <label htmlFor={notificationMethod.id} className="tw-ml-3 tw-block tw-text-md tw-font-medium tw-leading-6 tw-text-gray-900">
                      {notificationMethod.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>

            <div className="tw-flex tw-justify-end">
                <ButtonSecondary className="tw-mx-2" onClick={(e) => {
                            e.preventDefault();
                            handleCopyPreviewLink();
                           //setEditMode(true);
                            //setStep(1);
                        }}>Copy Preview Link</ButtonSecondary>
                <ButtonPrimary onClick={handlePublishTraining} disabled={!appContext.showBilling}>Publish Training</ButtonPrimary>
            </div>
            
            </>
           

          )
        
    }

    const renderPreviewAudience = () => {
        return (<>
            <div className="">
                <p><strong>Selected Audience:</strong> {training.targetAudience}</p>
            </div>

            {training.targetAudience === "Select Employees" && (
                <EmployeeAssignmentList employees={selectedEmployees} removeEmployee={() => {}} editMode={false} />
            )}
        </>);
    }

    const renderPreviewQuiz = () => {
        if (!training?.quiz) return null;

        return (<>
            <div className="tw-pb-2 tw-border-b tw-border-gray-300">
                                <p className="tw-text-lg"><strong>Passing Score:</strong> {training?.quiz.passingScore}</p>
                                <p className="tw-text-lg"><strong>Max Attempts:</strong> {training?.quiz.maxAttempts}</p>
                                <p className="tw-text-lg"><strong>Number of Questions:</strong> {training?.quiz.questions?.length}</p>
            </div>

            <h3 className="tw-text-lg tw-font-bold tw-py-2">Questions:</h3>

            {training?.quiz && 
            [...training.quiz.questions]
            .sort((a, b) => a.questionSequence - b.questionSequence)
            .map((question, index) => (
                <div key={question.questionID} className="tw-mb-4">
                        <h4 className="tw-text-md tw-font-semibold">Question {index + 1}: {question.question} {question.questionType === "Multiple-Choice" ? "(Please select all that apply)":""}</h4>
                        {question.questionType === "Image-Based" && (
                            <div className="tw-flex tw-justify-between">
                                <img src={question.questionImage} alt="Question" className="tw-mx-auto tw-my-4 tw-w-3/4 tw-h-auto" />
                            </div>
                            
                        )}
                        <div key={question.choice1}>
                            <label>
                                {question.questionType === "Multiple-Choice" ? (
                                    <input
                                        type="checkbox"
                                        name={question.questionID}
                                        value={question.choice1}
                                        disabled
                                    />
                                ) : (
                                    <input
                                        type="radio"
                                        name={question.questionID}
                                        value={question.choice1}
                                        disabled
                                    />
                                )}
                                <span className="tw-px-2">{question.choice1}</span>
                            </label>
                        </div>
                        <div key={question.choice2}>
                            <label>
                                {question.questionType === "Multiple-Choice" ? (
                                    <input
                                        type="checkbox"
                                        name={question.questionID}
                                        value={question.choice2}
                                        disabled
                                    />
                                ) : (
                                    <input
                                        type="radio"
                                        name={question.questionID}
                                        value={question.choice2}
                                        disabled
                                    />
                                )}
                                <span className="tw-px-2">{question.choice2}</span>
                            </label>
                        </div>
                        {question.questionType !== "True-False" && (
                            <div key={question.choice3}>
                                <label>
                                    {question.questionType === "Multiple-Choice" ? (
                                        <input
                                            type="checkbox"
                                            name={question.questionID}
                                            value={question.choice3}
                                            disabled
                                        />
                                    ) : (
                                        <input
                                            type="radio"
                                            name={question.questionID}
                                            value={question.choice3}
                                            disabled
                                        />
                                    )}
                                    <span className="tw-px-2">{question.choice3}</span>
                                </label>
                            </div>
                        )}
                        {question.questionType !== "True-False" && (
                            <div key={question.choice4}>
                                <label>
                                    {question.questionType === "Multiple-Choice" ? (
                                        <input
                                            type="checkbox"
                                            name={question.questionID}
                                            value={question.choice4}
                                            disabled
                                        />
                                    ) : (
                                        <input
                                            type="radio"
                                            name={question.questionID}
                                            value={question.choice4}
                                            disabled
                                        />
                                    )}
                                    <span className="tw-px-2">{question.choice4}</span>
                                </label>
                            </div>
                        )}
                </div>
            ))}
        </>);
        
       
    };

    const renderProgressBar = () => {

        if(!previewMode) {
            return <nav aria-label="Progress">
                    <ol role="list" className="tw-divide-y tw-divide-gray-300 tw-rounded-md tw-border tw-border-gray-300 md:tw-flex md:tw-divide-y-0 tw-mb-md">
                        {steps.map((currentStep, stepIdx) => (
                        <li key={currentStep.name} className="tw-relative md:tw-flex md:tw-flex-1">
                            {step > currentStep.id ? (
                            <div className="tw-group tw-flex tw-w-full tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary group-hover:tw-bg-indigo-800">
                                    <CheckIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">{currentStep.name}</span>
                                </span>
                            </div>
                            ) : currentStep.id === step ? (
                            <div className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium" aria-current="step">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-primary">
                                <span className="tw-text-primary">{currentStep.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-primary">{currentStep.name}</span>
                            </div>
                            ) : (
                            <div className="tw-group tw-flex tw-items-center">
                                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                                <span className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 group-hover:tw-border-gray-400">
                                    <span className="tw-text-gray-500 group-hover:tw-text-gray-900">{currentStep.id}</span>
                                </span>
                                <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-500 group-hover:tw-text-gray-900">{currentStep.name}</span>
                                </span>
                            </div>
                            )}

                            {stepIdx !== steps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div className="tw-absolute tw-top-0 tw-right-0 tw-hidden tw-h-full tw-w-5 md:tw-block" aria-hidden="true">
                                <svg
                                    className="tw-h-full tw-w-full tw-text-gray-300"
                                    viewBox="0 0 22 80"
                                    fill="none"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                    d="M0 -2L20 40L0 82"
                                    vectorEffect="non-scaling-stroke"
                                    stroke="currentcolor"
                                    strokeLinejoin="round"
                                    />
                                </svg>
                                </div>
                            </>
                            ) : null}
                        </li>
                        ))}
                    </ol>
                </nav>;
        } else {
            // have a simple button saying back to training form
            return <ButtonPrimary className="tw-mb-4" onClick={() => setPreviewMode(false)}>Back to New Training Form</ButtonPrimary>;
        }

        
    }

    const handleCancelEdit = () => {
        setEditMode(false);
        setShowCancelEditDialog(false);
        setTraining(originalTraining);
    }

    const [showCancelEditDialog, setShowCancelEditDialog] = useState(false);

    const dismissCancelEditDialog = () => {
        setShowCancelEditDialog(false);
    }

    const [showPublishTrainingDialog, setShowPublishTrainingDialog] = useState(false);

    const handlePublishTraining = () => {
        setShowPublishTrainingDialog(true);
    }

    const dismissPublishTrainingDialog = () => {
        setShowPublishTrainingDialog(false);
    }

    const [showPublishAndNotifyDialog, setShowPublishAndNotifyDialog] = useState(false);

    const handlePublishAndNotify = () => {
        setShowPublishAndNotifyDialog(true);
    }

    const dismissPublishAndNotifyDialog = () => {
        setShowPublishAndNotifyDialog(false);
    }

    const handleSaveChanges = async () => {
        // Save changes to the training
        if (!validateOverview()) {
            return;
        }

        if (training.courseMaterials.length === 0) {
            setShowCourseMaterialError(true);
            return;
        }

        if (training.quiz.questions.length < 1) {
            setShowQuizError(true);
            return;
        }

        console.log(training);

        training.trainingName = escapeSingleQuotes(training.trainingName);
        training.trainingDescription = escapeSingleQuotes(training.trainingDescription);

        forEach(training.quiz.questions, (question) => {
            question.question = escapeSingleQuotes(question.question);
            question.choice1 = escapeSingleQuotes(question.choice1);
            question.choice2 = escapeSingleQuotes(question.choice2);
            question.choice3 = escapeSingleQuotes(question.choice3);
            question.choice4 = escapeSingleQuotes(question.choice4);
        });

        training.duration = 30;

        // Save the training
        setSavingTraining(true);

        if(training.targetAudience === "Select Employees") {
            if(training.trainingAssignments && training.trainingAssignments.length > 0) {
                const trainingAssignments: TrainingAssignment[] = [...training.trainingAssignments];

                selectedEmployees.forEach((employee) => {

                if(!training.trainingAssignments.find((assignment) => assignment.providerID === employee.employeeID)) {
                    trainingAssignments.push({
                        trainingAssignmentID: "",
                        trainingID: training.trainingID,
                        providerID: employee.employeeID,
                        providerName: `${employee.firstName} ${employee.lastName}`,
                        status: "Not Notified",
                    });
                }});

                training.trainingAssignments = trainingAssignments;
            } else {
                var trainingAssignments: TrainingAssignment[] = [];

                selectedEmployees.forEach((employee) => {
                    trainingAssignments.push({
                        trainingAssignmentID: "",
                        trainingID: training.trainingID,
                        providerID: employee.employeeID,
                        providerName: `${employee.firstName} ${employee.lastName}`,
                        status: "Not Notified",
                    });
                });

                training.trainingAssignments = trainingAssignments;
            }

            

        } else {
            training.trainingAssignments = [];
        }

        const result = await updateTraining(training, appContext.handleAPICallError);

        await refreshTraining();

        setEditMode(false);
        setStep(1);

        setSavingTraining(false);

        setSuccessDialogHeader("Changes Saved Successfully");
        setSuccessDialogMessage("The client training has been updated successfully!");
        setShowSuccessDialog(true);



    }

    const refreshTraining = async () => {
        const result = await getClientTrainingByID(trainingID, appContext.handleAPICallError);

        if (result) {
            setTraining(result);
            setOriginalTraining(result);

            if (result.status === "Active") {
                setShowPublishMode(true);
            } else {
                setShowPublishMode(false);
            }

            const assignments = await getAssignmentStatusForTraining(trainingID, appContext.handleAPICallError);

            if (assignments) {
                setTrainingAssignments(assignments.sort((a, b) => a.status.localeCompare(b.status)));
            }
        }

        setLoadingTraining(false);
    }

    const [showArchiveTrainingDialog, setShowArchiveTrainingDialog] = useState(false);

    const handleArchiveTraining = () => {
        setShowArchiveTrainingDialog(true);
    }

    const dismissArchiveTrainingDialog = () => {
        setShowArchiveTrainingDialog(false);
    }

    const archiveClientTraining = async () => {
        setShowArchiveTrainingDialog(false);

        setSavingTraining(true);

        const result = await archiveTraining(trainingID, appContext.handleAPICallError);

        await refreshTraining();

        setEditMode(false);
        setStep(1);

        setSavingTraining(false);
        setSuccessDialogHeader("Training Archived Successfully");
        setSuccessDialogMessage("The client training has been archived successfully!");
        setShowSuccessDialog(true);
    }

    const publishClientTraining = async () => {
        setShowPublishTrainingDialog(false);

        setSavingTraining(true);

        const result = await publishTraining(trainingID, appContext.handleAPICallError);

        await refreshTraining();

        setEditMode(false);
        setStep(1);

        console.log(notificationSelection);

        if(notificationSelection === "all") {
            NotifyAllAssignments();
        }

        setSavingTraining(false);
        setSuccessDialogHeader("Training Published Successfully");
        setSuccessDialogMessage("The client training has been published successfully!");
        setShowSuccessDialog(true);
    }

    const publishAndNotifyClientTraining = async () => {
        setShowPublishTrainingDialog(false);

        setSavingTraining(true);

        const result = await publishTraining(trainingID, appContext.handleAPICallError);

        const notifyResult = await sendNotificationForTrainingForAll(trainingID, appContext.handleAPICallError);

        await refreshTraining();

        setEditMode(false);
        setStep(1);

        setSavingTraining(false);
        setSuccessDialogHeader("Training Published & Audience Notified Successfully");
        setSuccessDialogMessage("The client training has been published successfully!");
        setShowSuccessDialog(true);
    }

    const NotifyAllAssignments = async () => {
        setSavingTraining(true);

        setTimeout(async () => {
            //const notifyResult = await sendNotificationForTrainingForAll(trainingID, appContext.handleAPICallError);

            setSavingTraining(false);
            setSuccessDialogHeader("Audience Notified Successfully");
            setSuccessDialogMessage("The audience has been notified successfully!");
            setShowSuccessDialog(true);
        }, 2000);

        // const notifyResult = await sendNotificationForTrainingForAll(trainingID, appContext.handleAPICallError);


        
        // setSavingTraining(false);
        // setSuccessDialogHeader("Audience Notified Successfully");
        // setSuccessDialogMessage("The audience has been notified successfully!");
        // setShowSuccessDialog(true);
    }

    const NotifyEmployeeByEmail = async (employee: TrainingStatus) => {
        const index = trainingAssignments.findIndex((assignment) => assignment.employeeID === employee.employeeID);

        if (index > -1){
            console.log("Notifying employee by email");
            console.log(index);
            setEmployeeIndexLoading(index);
        }

        setTimeout(async () => {
            var result = sendNotificationForTrainingForEmployee(trainingID, employee.employeeID, appContext.handleAPICallError);

            setEmployeeIndexLoading(-1);
            

            setSuccessDialogHeader("Employee Notified Successfully");
            setSuccessDialogMessage("The employee has been notified successfully!");
            setShowSuccessDialog(true);
        }, 1000);


    }

    const handleCopyPreviewLink = () => {
        const url = `${window.location.origin}/provider/training/${trainingID}/preview`;
        navigator.clipboard.writeText(url);

        setSuccessDialogHeader("Link Copied Successfully");
        setSuccessDialogMessage("The preview link has been copied to your clipboard!");
        setShowSuccessDialog(true);
    }

    const handleCopyTrainingLink = () => {
        const url = `${window.location.origin}/provider/training/${trainingID}/complete`;
        navigator.clipboard.writeText(url);

        setSuccessDialogHeader("Link Copied Successfully");
        setSuccessDialogMessage("The training link has been copied to your clipboard!");
        setShowSuccessDialog(true);
    }

    const [showOverview, setShowOverview] = useState(true);
    const [showPublishAudience, setShowPublishAudience] = useState(true);
    const [showParticipants, setShowParticipants] = useState(true);
    const [showMaterials, setShowMaterials] = useState(false);
    const [showPublishQuiz, setShowPublishQuiz] = useState(false);

    const renderPublishMode = () => {
       

        const toggleOverview = () => {
            if (!showOverview) {
                setShowPublishAudience(false);
                setShowParticipants(false);
                setShowMaterials(false);
                setShowPublishQuiz(false);
            }

            setShowOverview(!showOverview);
        }

        const toggleAudience = () => {
            if (!showAudience) {
                setShowOverview(false);
                setShowParticipants(false);
                setShowMaterials(false);
                setShowPublishQuiz(false);
            }

            setShowPublishAudience(!showPublishAudience);
        }

        const toggleParticipants = () => {
            if (!showParticipants) {
                setShowOverview(false);
                setShowPublishAudience(false);
                setShowMaterials(false);
                setShowPublishQuiz(false);
            }

            setShowParticipants(!showParticipants);
        }

        const toggleMaterials = () => {
            if (!showMaterials) {
                setShowOverview(false);
                setShowPublishAudience(false);
                setShowParticipants(false);
                setShowPublishQuiz(false);
            }

            setShowMaterials(!showMaterials);
        }

        const toggleQuiz = () => {
            if (!showQuiz) {
                setShowOverview(false);
                setShowPublishAudience(false);
                setShowParticipants(false);
                setShowMaterials(false);
            }

            setShowPublishQuiz(!showPublishQuiz);
        }

        return (<>
            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showOverview ? "tw-font-semibold":"tw-font-normal"}`}>Overview</h3>

                    {showOverview ? 
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleOverview()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleOverview()} />}
                </div>

                {showOverview && (<>
                
                        {training && (
                            <div className="">
                                <p className="tw-text-lg"><strong>Name:</strong> {training.trainingName}</p>
                                <p className="tw-text-lg"><strong>Description:</strong> {training.trainingDescription}</p>
                                <p className="tw-text-lg"><strong>Category:</strong> {training.trainingCategory}</p>
                                <p className="tw-text-lg"><strong>Duration:</strong> {training.duration} minutes</p>
                                <p className="tw-text-lg"><strong>Status:</strong> {training.status}</p>
                                {/* <p className="tw-text-lg"><strong>Client Name:</strong> {training.clientName}</p> */}
                                {/* <p><strong>Created By:</strong> {training.createdBy}</p> */}
                                <p className="tw-text-lg"><strong>Date Created:</strong> {new Date(training.dateCreated).toLocaleString()}</p>
                                <p className="tw-text-lg"><strong>Valid Until:</strong> {new Date(training.validTill).toDateString()}</p>
                            </div>
                        )}


                        {/* <div className="tw-flex tw-justify-end">
                                <ButtonPrimary onClick={handlePublishTraining}>Next</ButtonPrimary>
                        </div> */}
                        </>

                    
                )}
            </div>

            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showPublishAudience ? "tw-font-semibold":"tw-font-normal"}`}>Audience</h3>

                    {showPublishAudience ? 
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleAudience()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleAudience()} />}
                </div>

                {showPublishAudience && (<>
                    <div className="tw-mt-4">
                        <p><strong></strong> {training.targetAudience}</p>
                    </div>
                </>)}

            </div>

            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showParticipants ? "tw-font-semibold":"tw-font-normal"}`}>Participants</h3>

                    {showParticipants ?
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleParticipants()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleParticipants()} />}
                </div>

                {showParticipants && (<>
                    { groupedAndSortedAttempts ? <>
                        <ul className="tw-list-none">
                            {Object.keys(groupedAndSortedAttempts).map((employeeName) => {
                            const lastAttempt = groupedAndSortedAttempts[employeeName][0];
                            return (
                                <li key={employeeName} className="tw-mb-4 tw-p-4 tw-border tw-border-gray-300 tw-rounded tw-shadow tw-cursor-pointer" onClick={() => {
                                    showProviderAttempt(groupedAndSortedAttempts[employeeName], employeeName);
                                }}>
                                <h2 className="tw-text-xl tw-font-semibold">{employeeName}</h2>
                                <p>Number of Attempts: {groupedAndSortedAttempts[employeeName]?.length} | Last Attempt Date: {new Date(lastAttempt.submissionTime).toLocaleString()}</p>
                                <p>Status: {lastAttempt.status}</p>
                                <p>Last Attempt Score: {lastAttempt.score} | Passing Score: {training.quiz.passingScore}</p>
                                </li>
                            );
                            })}
                           
                        </ul>
                        </> : <p>No participants yet</p>}
                </>)}

            </div>

            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showMaterials ? "tw-font-semibold":"tw-font-normal"}`}>Content</h3>

                    {showMaterials ?
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleMaterials()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleMaterials()} />}
                </div>

                {showMaterials && (<>
                    
                    
                    {training?.courseMaterials &&
                    [...training.courseMaterials]
                    .sort((a, b) => a.materialSequence - b.materialSequence)
                    .map((material, index) => (
                        <>
                        <h3 className="tw-text-lg tw-font-semibold">{extractFileNameWithoutExtension(material.materialLink)}</h3>
                        <iframe 
                                    src={material.materialLink} 
                                    width="850" 
                                    height="600" 
                                    title="PDF Viewer"
                                    className="tw-border tw-border-gray-300"
                                ></iframe>
                        </>
                    ))}
                </>)}

            </div>

            <div className="tw-pb-4 tw-mt-4 tw-border-b tw-border-gray-900">
                <div className="tw-flex tw-justify-between">
                    <h3 className={`tw-text-xl tw-pb-2 ${showPublishQuiz ? "tw-font-semibold":"tw-font-normal"}`}>Quiz</h3>

                    {showPublishQuiz ?
                    <ChevronUpIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleQuiz()} />:
                    <ChevronDownIcon className="tw-w-6 tw-h-6 tw-mt-2 tw-text-gray-900 tw-cursor-pointer" onClick={() => toggleQuiz()} />}
                </div>

                {showPublishQuiz && renderPreviewQuiz()}

            </div>

        </>);
    }

    const renderClientTrainingPublishMode = () => {
        return (<>
            <h2 className="tw-text-xl tw-font-semibold">Overview</h2>
                        {training && (
                            <div className="tw-mt-4">
                                <p><strong>Name:</strong> {training.trainingName}</p>
                                <p><strong>Description:</strong> {training.trainingDescription}</p>
                                <p><strong>Category:</strong> {training.trainingCategory}</p>
                                <p><strong>Client Name:</strong> {training.clientName}</p>
                                {/* <p><strong>Created By:</strong> {training.createdBy}</p> */}
                                <p><strong>Date Created:</strong> {new Date(training.dateCreated).toLocaleString()}</p>
                                <p><strong>Valid Until:</strong> {new Date(training.validTill).toDateString()}</p>
                            </div>
                        )}

                    <div className="tw-flex tw-justify-between tw-mt-2 tw-pt-2 tw-border-t tw-border-t-gray-900">
                        <h2 className="tw-text-xl tw-font-semibold tw-mt-2">Audience</h2>

                        {savingTraining ? <LoadingSpinner /> :
                         <>
                         {training?.status === "Active" && <ButtonSecondary className="tw-mx-2" onClick={NotifyAllAssignments}>Notify All Pending</ButtonSecondary>}
                         </>
                        }
                    </div>
                    

                    <EmployeeStatusList employees={trainingAssignments} notifyEmployee={NotifyEmployeeByEmail} loadingIndex={employeeIndexLoading} showNotify={training?.status === "Active"}/>
        </>);
    }
    
    return (<>
        <SuccessDialog  title={successDialogHeader} message={successDialogMessage} onClose={() => setShowSuccessDialog(false)} closeButtonText="Close" open={showSuccessDialog}/>
        <ConfirmDialog title="Discard changes - Training" message="Are you sure you want to stop editing this training? All pending changes will be lost!" onConfirm={handleCancelEdit} confirmButtonText={"Yes"} cancelButtonText="No" onCancel={dismissCancelEditDialog} showDialog={showCancelEditDialog}/>
        <CautionDialog title="Publish Training" message="Are you sure you want to publish this training? Once published, you won't be able to edit it!" onConfirm={publishClientTraining} confirmButtonText={"Yes"} cancelButtonText="No" onCancel={dismissPublishTrainingDialog} showDialog={showPublishTrainingDialog}/>
        {/* <CautionDialog title="Publish Training & Notify" message="Are you sure you want to publish this training and notify all assigned employees? Once published, you won't be able to edit it!" onConfirm={publishAndNotifyClientTraining} confirmButtonText={"Yes"} cancelButtonText="No" onCancel={dismissPublishAndNotifyDialog} showDialog={showPublishAndNotifyDialog}/> */}
        {/* <SelectEmployeesDialog
                title="Select Employees"
                confirmButtonText="Confirm"
                onConfirm={() => {}}
                cancelButtonText="Cancel"
                onCancel={dismissPublishTrainingDialog}
                showDialog={showPublishTrainingDialog}
                employees={employees}
            /> */}
        <ConfirmDialog title="Archive Training" message="Are you sure you want to archive this training? Once archived, you won't be able to access it!" onConfirm={archiveClientTraining} confirmButtonText={"Yes"} cancelButtonText="No" onCancel={dismissArchiveTrainingDialog} showDialog={showArchiveTrainingDialog}/>
        <div className="tw-px-xl tw-py-md">
            <div className="tw-flex tw-flex tw-pb-4 tw-flex-col tw-space-y-20px tw-text-dark">
                    <div className="tw-space-y-20px tw-pb-2 tw-border-b tw-border-gray-800">
                        <GenericBreadCrumbs 
                        mainHeading={"Client Training"}
                        subHeading={"Manage"}
                        backURL={"/training/client-training-management"}
                        />
			        </div>
            </div>

            {loadingTraining ? <div className="tw-flex tw-justify-center tw-mx-4 tw-mt-md">
                <LoadingSpinner />
                </div>:<>
                {!showPublishMode && renderProgressBar()}

                {/* <div className="tw-flex tw-justify-start tw-pb-2 tw-border-b tw-border-gray-300 tw-mb-2">
                    <h1 className="tw-text-xl tw-font-semibold tw-mt-4">{training.trainingName}</h1>
                </div> */}

                <div className="tw-flex tw-justify-between tw-pb-2 tw-border-b tw-border-gray-300 tw-mb-2">
                    <h1 className="tw-text-xl tw-font-semibold">{training.trainingName}</h1>

                    {showPublishMode ? <>
                        <ButtonSecondary className="tw-mx-2 tw-my-auto" onClick={(e) => {
                            e.preventDefault();
                            handleCopyTrainingLink();
                           //setEditMode(true);
                            //setStep(1);
                        }}>Copy Training Link</ButtonSecondary>
                        {training.status === "Active" && <ButtonError onClick={handleArchiveTraining} className="tw-mx-2" disabled={!appContext.showBilling}>Archive</ButtonError>}
                    </>:<>
                        {savingTraining ? <>
                            <LoadingSpinner />
                        </>:<>
                        <div>
                        {editMode && <ButtonWarning className="tw-mx-2" onClick={(e) => {
                            e.preventDefault();
                            //setStep(1);
                            setShowCancelEditDialog(true);
                        }}>Discard Changes</ButtonWarning>
                        }
                        {editMode && training.status === "Draft" && <ButtonPrimary className="tw-mx-2" onClick={handleSaveChanges}>Save</ButtonPrimary>}
                        {!editMode && training.status === "Draft" && step < 5 && <ButtonSecondary className="tw-mx-2" onClick={(e) => {
                            e.preventDefault();
                            setEditMode(true);
                            //setStep(1);
                        }}>Edit</ButtonSecondary>}
                        {step === 4 && !editMode && training.status === "Draft" && <ButtonPublish onClick={e => {
                            e.preventDefault();
                            setStep(step + 1);
                        }} className="tw-mx-2">Review & Publish</ButtonPublish>}
                         {!editMode && step == 1 && <ButtonSecondary className="tw-mx-2" onClick={(e) => {
                            e.preventDefault();
                            handleCopyPreviewLink();
                           //setEditMode(true);
                            //setStep(1);
                        }}>Copy Preview Link</ButtonSecondary>}
                        {/* {step === 4 && !editMode && training.status === "Draft" && <ButtonPublish onClick={handlePublishTraining} className="tw-mx-2">Publish</ButtonPublish>}
                        {step === 4 && !editMode && training.status === "Draft" && <ButtonPublish onClick={handlePublishAndNotify} className="tw-mx-2">Publish & Notify</ButtonPublish>} */}
                        </div>
                        </>}
                    </>} 
                    
                </div>

                {showPublishMode || training?.status === "Archived" ? <>
                    {renderPublishMode()}
                </>:<>
                {renderStepContent()}

                {showCourseMaterialError && <p className="tw-text-red-500">Please add at least one course material</p>}

                {showQuizError && <p className="tw-text-red-500">Please add at least 4 quiz questions</p>}

                {showAudienceError && <p className="tw-text-red-500">Please select at least one employee</p>}

                {<div className={`tw-flex ${step === 1 ? "tw-justify-end":"tw-justify-between"} tw-mt-5`}>

                            {step > 1 && <ButtonWarningSecondary onClick={handlePreviousStep}>Previous</ButtonWarningSecondary>}
                            

                            {step < 4 && <ButtonPrimary onClick={handleNextStep}>Next</ButtonPrimary>}
                            
                            {/* {step === 3 && <ButtonPrimary onClick={handleSubmit}>Submit</ButtonPrimary>} */}
                        </div>}

                </>}


            </>}

                


        </div>
    </>);
}

export default ManageClientTrainingPage;