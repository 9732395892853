import { useEffect, useState } from "react";
import { useNewPlanContext } from "../../pages/NewServicePlanForm";
import { PersonalService, SupportArea } from "../../types/servicePlan";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { ServiceSchema, SupportAreaSchema } from "../forms/newSupportAreaSection";
import { v4 as uuidv4 } from 'uuid';
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useEditPlanContext } from "../../pages/EditServicePlanPage";
import { addSupportArea, deleteSupportArea, getSupportArea, updateSupportArea } from "../../apicalls/servicePlanService";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useAppContext } from "../../Context";
import { InfoAlert } from "./infoAlert";
import cloneDeep from 'lodash.clonedeep';
import { LoadingSpinner } from "./loadingSpinner";

export const ViewSupportAreaSection = props => {
    useEffect(() => {
        console.log(props);
        console.log(props?.area.personalServices.length);
    }, []);

    return (<>
                <div className="tw-pb-2 tw-flex">
                    <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">{props?.area.supportTitle}</span>
                    {props?.showEdit && <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer" onClick={e => props?.onEdit(props?.area.supportAreaID)}>Edit</span>}
                </div>
                

                <div></div>
                <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900">{props?.area.supportDetails}</span>
                <div></div>
                <span className="tw-text-xs tw-underline tw-font-semibold tw-leading-6 tw-text-gray-900">Personal Services:</span>
                <div></div>

                {props?.area.personalServices.length > 0 && props?.area.personalServices.map((service, index) => (
                    <div>
                    <span className="tw-text-xs tw-font-semibold tw-leading-6 tw-text-gray-900">{service.serviceTitle}</span>
                        

                    
                    <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                                    <div className="tw-col-span-1 tw-flex tw-items-center">
                                        <div className="tw-flex-shrink-0 tw-mx-4">
                                            <img src="/images/hour-glass.svg" className="tw-fill-blue-500"></img>
                                        </div>
                                        <div className="tw-flex-grow">
                                            <span className="tw-text-xs tw-font-light tw-leading-1 tw-text-grey-dark">Frequency</span>
                                            <div></div>
                                            <span className="tw-text-xs tw-font-semibold tw-leading-1 tw-text-gray-900">{service.frequency}</span>
                                        </div>
                                    </div>

                                    <div className="tw-col-span-1 tw-flex tw-items-center">
                                        <div className="tw-flex-shrink-0 tw-mx-4">
                                            <img src="/images/umbrella.svg" className="tw-fill-blue-500"></img>
                                        </div>
                                        <div className="tw-flex-grow">
                                            <span className="tw-text-xs tw-font-light tw-leading-1 tw-text-grey-dark">Responsibility</span>
                                            <div></div>
                                            <span className="tw-text-xs tw-font-semibold tw-leading-1 tw-text-gray-900">{service.responsibleParty}</span>
                                        </div>
                                    </div>
                    </div>
                    </div>
                ))}

                                
                                
    </>);
}

export function arraysAreEqual<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) {
      return false;
    }
  
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
  
    return true;
}

const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);

const EditSupportAreaSection = props => {
    const planContext = useNewPlanContext();
    const appContext = useAppContext();

    const editPlanContext = useEditPlanContext();
   
    const [showPersonalServiceCard, setShowPersonalServiceCard] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [serviceDirty, setServiceDirty] = useState(false);
    const [originalArea, setOriginalArea] = useState<SupportArea>(null);
    const [loading, setLoading] = useState(false);
    //const originalServices =  JSON.parse(JSON.stringify(props?.area.personalServices));

    useEffect(() => {
        console.log(props);
        console.log(props?.area.personalServices.length);

        const fetchSupportArea = async () => {
            const supportArea = await getSupportArea(props?.area.supportAreaID, appContext.handleAPICallError);
            console.log(supportArea);
            setOriginalArea(supportArea);
        }

        // console.log("New Plan Context: :" + planContext);
        // console.log("Edit Plan Context: :" + editPlanContext);

        if(props?.editPlan){
            fetchSupportArea();
        } else {
            setOriginalArea(props?.area);
        }
        

    },[]);

    const intialFormValues = {
        supportTitle: props?.area.supportTitle,
            //supportType: '',
        supportDetails: props?.area.supportDetails,
        services: props?.area.personalServices,
    }

    const supportAreaFormik = useFormik({
        initialValues: intialFormValues,
        validationSchema: SupportAreaSchema,
        onSubmit: async (values) => {
            // handle form submission
            console.log("Form submitted")
            console.log(values);
            var areaServices: PersonalService[] = [];

            values.services.forEach((service) => {
                var newService: PersonalService = {
                    serviceTitle: service.serviceTitle,
                    //serviceDetails: service.serviceDetails,
                    frequency: service.frequency,
                    responsibleParty: service.responsibleParty,
                    personalServiceID: uuidv4(),
                    supportAreaID: "",
                    serviceDetails: "",
                    lastCompletedOn: "",
                    lastCompletedBy: ""
                }
                areaServices.push(newService);
            })

            var newArea: SupportArea = {
                supportTitle: values.supportTitle,
                //supportType: values.supportType,
                supportDetails: values.supportDetails,
                personalServices: areaServices,
                supportAreaID: props?.area.supportAreaID,
                servicePlanID: "",
                supportType: ""
            }

            console.log(newArea);

            if(props?.editPlan){
                setLoading(true);
            }

            await props?.onSave(props?.area.supportAreaID, newArea);
        },
    });

    const handleRemoveSupportArea = () => {
        if(props?.editPlan){
            setShowConfirmDelete(true);
        } else {
            const index = planContext.planSupportAreas.indexOf(props?.area);
            const newItems = [...planContext.planSupportAreas.slice(0, index), ...planContext.planSupportAreas.slice(index + 1)];
            planContext.setPlanSupportAreas(newItems);
        }
        
    }

    const deletePlanSupportArea = async () => {
        setLoading(true);
        var result = await deleteSupportArea(props?.area.supportAreaID, appContext.handleAPICallError);
        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Support Area Deleted Successfully");
            appContext.setShowSuccessModal(true);
            const index = editPlanContext.planSupportAreas.indexOf(props?.area);
            const newItems = [...editPlanContext.planSupportAreas.slice(0, index), ...editPlanContext.planSupportAreas.slice(index + 1)];
            editPlanContext.setPlanSupportAreas(newItems);
        }
        
        setShowConfirmDelete(false);
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    // const getOriginalServices = (areaID: string, defaultValue: PersonalService[] = null): PersonalService[] => {
    //     const area = editPlanContext.origSupportAreas.find(a => a.supportAreaID === areaID);
    //     if(area == null) {
    //         return defaultValue;
    //     } else {
    //         return area.personalServices;
    //     }
    // };


    const personalServiceFormik = useFormik({
        initialValues: {
            serviceTitle: '',
            //serviceDetails: '',
            frequency: '',
            responsibleParty: '',
        },
        validationSchema: ServiceSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            var newService: PersonalService = {
                serviceTitle: values.serviceTitle,
                //serviceDetails: service.serviceDetails,
                frequency: values.frequency,
                responsibleParty: values.responsibleParty,
                personalServiceID: uuidv4(),
                supportAreaID: "",
                serviceDetails: "",
                lastCompletedOn: "",
                lastCompletedBy: ""
            }
            //var updatedServices = [...supportAreaFormik.values.services, newService];

            supportAreaFormik.values.services.push(newService);
            supportAreaFormik.validateField('services');
            //setServiceDirty(true);
            console.log(arraysAreEqual(originalArea.personalServices, supportAreaFormik.values.services));
            if(!arraysAreEqual(originalArea.personalServices, supportAreaFormik.values.services)){
                setServiceDirty(true);
            }
            if(!props?.editPlan){
                setServiceDirty(true);
            }
            //console.log(supportAreaFormik.status);
            //supportAreaFormik.setFieldValue('services', [...supportAreaFormik.values.services, newService], true);
            
            //supportAreaFormik.values.services.push(newService);
            //supportAreaFormik.setFieldValue('services', supportAreaFormik.values.services, true);
            //supportAreaFormik.setTouched({services: true, supportTitle: true, supportDetails: true});
            //supportAreaFormik.setStatus({dirty: true});
            //supportAreaFormik.validateField('services');
            //supportAreaFormik.setFieldTouched('services', true, true);
            //console.log(supportAreaFormik.dirty);
            //handleUpdateServicesList(updatedServices);
            setShowInfoMessage(true);
            setInfoMessage("New service added to the list. Press save to keep your changes.");
            setTimeout(() => {
                setShowInfoMessage(false);
            }, 5000);
            togglePersonalServiceCard();
        },
    });

    const togglePersonalServiceCard = () => {
        personalServiceFormik.resetForm();
        setShowPersonalServiceCard(!showPersonalServiceCard);
    }

    const handleUpdateServicesList = (services) => {
        supportAreaFormik.setFieldValue('services', services, true);
        if(!arraysAreEqual(originalArea.personalServices, supportAreaFormik.values.services)){
            setServiceDirty(true);
        }
        if(!props?.editPlan){
            setServiceDirty(true);
        }
        

        //supportAreaFormik.validateField('services');
        //supportAreaFormik.setValues(supportAreaFormik.values);
    }

    const discardChanges = async () => {
        if(props?.editPlan){
            supportAreaFormik.resetForm();
            console.log(props?.area.supportAreaID);
            console.log(editPlanContext.origSupportAreas);
            console.log(originalArea);
            editPlanContext.setPlanSupportAreas(editPlanContext.planSupportAreas.map(
                el => el.supportAreaID ===  originalArea.supportAreaID? { ...el, ...originalArea } : el
            ));
        }
        
        //supportAreaFormik.setFieldValue('services', originalArea.personalServices, true);
        props?.onCancel();
    }

    
    return (<>
                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">Edit Support Area</span>
                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Support Area Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="support-area-title"
                                                id="support-area-title"
                                                className={`tw-block tw-w-full tw-rounded-md ${supportAreaFormik.touched.supportTitle && supportAreaFormik.errors.supportTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...supportAreaFormik.getFieldProps('supportTitle')}
                                                />
                                                {supportAreaFormik.touched.supportTitle && supportAreaFormik.errors.supportTitle && <p className="tw-text-red-500"><>{supportAreaFormik.errors.supportTitle}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Details
                                            </label>
                                            <textarea
                                                rows={2}
                                                name="supportDetails"
                                                id="supportDetails"
                                                className={`tw-block tw-w-full tw-rounded-md ${supportAreaFormik.touched.supportDetails && supportAreaFormik.errors.supportDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-text-gray-900 tw-px-2 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-py-1.5 sm:tw-text-sm sm:tw-leading-6`}
                                                {...supportAreaFormik.getFieldProps('supportDetails')}
                                                />
                                                {supportAreaFormik.touched.supportDetails && supportAreaFormik.errors.supportDetails && <p className="tw-text-red-500"><>{supportAreaFormik.errors.supportDetails}</></p>}
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Services</span>
                                        <PlusCircleIcon className="tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={togglePersonalServiceCard} />
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline">Add</span> */}
                                    </div>

                                    {showInfoMessage && <InfoAlert message={infoMessage} />}

                                    {showPersonalServiceCard &&

                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Name
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-name"
                                                    id="service-name"
                                                    className={`tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.serviceTitle && personalServiceFormik.errors.serviceTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('serviceTitle')}
                                                    />
                                                    {personalServiceFormik.touched.serviceTitle && personalServiceFormik.errors.serviceTitle && <p className="tw-text-red-500">{personalServiceFormik.errors.serviceTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.frequency && personalServiceFormik.errors.frequency ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('frequency')}
                                                >
                                                    <option value="">Select Frequency</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {personalServiceFormik.touched.frequency && personalServiceFormik.errors.frequency && <p className="tw-text-red-500">{personalServiceFormik.errors.frequency}</p>}
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.responsibleParty && personalServiceFormik.errors.responsibleParty ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('responsibleParty')}
                                                >
                                                    <option value="">Select Responsible Party</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {personalServiceFormik.touched.responsibleParty && personalServiceFormik.errors.responsibleParty && <p className="tw-text-red-500">{personalServiceFormik.errors.responsibleParty}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={togglePersonalServiceCard}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={personalServiceFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>
                                    }

                                   <EditPersonalServiceList services={props?.area.personalServices} onUpdate={handleUpdateServicesList} setInfoMessage={setInfoMessage} setShowInfoMessage={setShowInfoMessage}></EditPersonalServiceList>
                                    
                                    <div>
                                        {supportAreaFormik.touched.services && supportAreaFormik.errors.services && <p className="tw-text-red-500"><>{supportAreaFormik.errors.services}</></p>}
                                    </div>

                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />

                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                                    
                                    
                                        
                                    {showConfirmDelete ? <>
                                    <p className="tw-text-primary">Are you sure you want to delete this support area?</p>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deletePlanSupportArea}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            
                                            <ButtonSecondary className="tw-mt-md" onClick={handleRemoveSupportArea}>
                                                    {props?.editPlan ? 'Delete' : 'Remove'}
                                            </ButtonSecondary>
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={discardChanges}>
                                                {(supportAreaFormik.dirty || serviceDirty) ? 'Discard' : 'Cancel'}
                                            </ButtonSecondary>
                                            {(supportAreaFormik.dirty || serviceDirty) && <ButtonPrimary className="tw-mt-md" onClick={supportAreaFormik.handleSubmit}>
                                                Update
                                            </ButtonPrimary>}

                                    </div>
                                    </>}

                                    </>}
                                    

    </>);
}

const EditPersonalServiceList = props => {

    const [editServiceId, setEditServiceId] = useState<string | null>(null);
    const [services, setServices] = useState<PersonalService[]>(props?.services);

    function handleServiceEdit(id: string) {
        setEditServiceId(id);
    }

    function handleServiceSave(id: string, updatedService: PersonalService) {
        console.log(updatedService);

        const updatedServices = services.map(
            el => el.personalServiceID ===  updatedService.personalServiceID? { ...el, ...updatedService } : el
        );

        setServices(updatedServices);

        props?.onUpdate(updatedServices);

        props?.setInfoMessage("Personal Service Updated. Save support area to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);

        setEditServiceId(null);
    }

    function handleServiceCancel() {
        // Reset the edit ID
        setEditServiceId(null);
    }

    function handleRemoveService(id: string) {
        const updatedServices = services.filter((service) => service.personalServiceID !== id);
        setServices(updatedServices);
        props?.onUpdate(updatedServices);
        props?.setInfoMessage("Personal Service Removed. Save support area to update plan.");
        props?.setShowInfoMessage(true);
        setTimeout(() => {
            props?.setShowInfoMessage(false);
        }, 5000);
        // supportAreaFormik.values.services = services.filter((service) => service.personalServiceID !== id);
        // supportAreaFormik.validateField('services');
    }

    return (<>
         {services.length > 0 && services.map((service, index) => (
                                        <div className="tw-space-y-6 tw-bg-card-green tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4" key={service.personalServiceID}>
                                            <EditPersonalServiceSection service={service} onEdit={handleServiceEdit} onSave={handleServiceSave} onCancel={handleServiceCancel} onRemove={handleRemoveService} isEditing={service.personalServiceID === editServiceId}></EditPersonalServiceSection>
                                        </div>
        ))}
    </>);

}

const EditPersonalServiceSection = props => {
    const personalServiceFormik = useFormik({
        initialValues: {
            serviceTitle: props?.service.serviceTitle,
            //serviceDetails: '',
            frequency: props?.service.frequency,
            responsibleParty: props?.service.responsibleParty,
        },
        validationSchema: ServiceSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            var newService: PersonalService = {
                serviceTitle: values.serviceTitle,
                //serviceDetails: service.serviceDetails,
                frequency: values.frequency,
                responsibleParty: values.responsibleParty,
                personalServiceID: props?.service.personalServiceID,
                supportAreaID: "",
                serviceDetails: "",
                lastCompletedOn: "",
                lastCompletedBy: ""
            }
            
            props?.onSave(props?.service.personalServiceID, newService);
        },
    });


    return (<>
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Name
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-name"
                                                    id="service-name"
                                                    className={`tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.serviceTitle && personalServiceFormik.errors.serviceTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('serviceTitle')}
                                                    disabled={!props?.isEditing}
                                                    />
                                                    {personalServiceFormik.touched.serviceTitle && personalServiceFormik.errors.serviceTitle && <p className="tw-text-red-500"><>{personalServiceFormik.errors.serviceTitle}</></p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.frequency && personalServiceFormik.errors.frequency ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('frequency')}
                                                    disabled={!props?.isEditing}
                                                >
                                                    <option value="">Select Frequency</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {personalServiceFormik.touched.frequency && personalServiceFormik.errors.frequency && <p className="tw-text-red-500"><>{personalServiceFormik.errors.frequency}</></p>}
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.responsibleParty && personalServiceFormik.errors.responsibleParty ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('responsibleParty')}
                                                    disabled={!props?.isEditing}
                                                >
                                                    <option value="">Select Responsible Party</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {personalServiceFormik.touched.responsibleParty && personalServiceFormik.errors.responsibleParty && <p className="tw-text-red-500"><>{personalServiceFormik.errors.responsibleParty}</></p>}
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            {props?.isEditing ? <>
                                                <ButtonSecondary className="tw-mt-md" onClick={() => props?.onRemove(props?.service.personalServiceID)}>
                                                            Remove
                                                    </ButtonSecondary>
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={() => props?.onCancel()}>
                                                        {personalServiceFormik.dirty ? 'Discard' : 'Cancel'}
                                                    </ButtonSecondary>
                                                    {personalServiceFormik.dirty && <ButtonPrimary className="tw-mt-md" onClick={personalServiceFormik.handleSubmit}>
                                                        Update
                                                    </ButtonPrimary>}
                                            </>: <>
                                                    <ButtonPrimary className="tw-mt-md" onClick={() => props?.onEdit(props?.service.personalServiceID)}>
                                                        Edit
                                                    </ButtonPrimary>
                                                
                                            </>}

                                                    

                                        </div>
    </>);
}

const EditSupportAreaCard = props => {

    return (<>
        {props?.isEditing ? <EditSupportAreaSection area={props.area} onEdit={props?.onEdit} onSave={props?.onSave} onCancel={props?.onCancel} editPlan={props?.editPlan} newPlan={props?.newPlan}/> : <ViewSupportAreaSection area={props.area} onEdit={props?.onEdit} showEdit={true}/>}
    </>);
}

export const EditSupportAreaList = props => {
    const [editId, setEditId] = useState<string | null>(null);

    useEffect(() => {
        console.log(props);
    }, []);

    const planContext = useNewPlanContext();
    const editPlanContext = useEditPlanContext();
    const appContext = useAppContext();

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, updatedArea: SupportArea) {
        if(props?.editPlan){
            const result = await updateSupportArea(updatedArea, updatedArea.supportAreaID, appContext.handleAPICallError);
            console.log(result);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Support Area Updated Successfully");
                appContext.setShowSuccessModal(true);
                editPlanContext.setPlanSupportAreas(editPlanContext.planSupportAreas.map(
                    el => el.supportAreaID ===  updatedArea.supportAreaID? { ...el, ...updatedArea } : el
                ));
            }
            
            
        } else {
            planContext.setPlanSupportAreas(planContext.planSupportAreas.map(
                el => el.supportAreaID ===  updatedArea.supportAreaID? { ...el, ...updatedArea } : el
            ));
        }
        

        setEditId(null);
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    const [showSupportAreaCard, setShowSupportAreaCard] = useState(false);
    const [loading, setLoading] = useState(false);

    const supportAreaFormik = useFormik({
        initialValues: {
            supportTitle: '',
            //supportType: '',
            supportDetails: '',
            services: [],
        },
        validationSchema: SupportAreaSchema,
        onSubmit: async (values) => {
            // handle form submission
            console.log("Form submitted")
            console.log(values);
            var areaServices: PersonalService[] = [];

            values.services.forEach((service) => {
                var newService: PersonalService = {
                    serviceTitle: service.serviceTitle,
                    //serviceDetails: service.serviceDetails,
                    frequency: service.frequency,
                    responsibleParty: service.responsibleParty,
                    personalServiceID: uuidv4(),
                    supportAreaID: "",
                    serviceDetails: "",
                    lastCompletedOn: "",
                    lastCompletedBy: ""
                }
                areaServices.push(newService);
            })

            var newArea: SupportArea = {
                supportTitle: values.supportTitle,
                //supportType: values.supportType,
                supportDetails: values.supportDetails,
                personalServices: areaServices,
                supportAreaID: uuidv4(),
                servicePlanID: "",
                supportType: ""
            }

            setLoading(true);

            var result = await addSupportArea(newArea, editPlanContext.servicePlan.servicePlanID, appContext.handleAPICallError);
            await editPlanContext.updateLocalAreas();
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("Support Area Added Successfully");
                appContext.setShowSuccessModal(true);
            }
            
            //editPlanContext.setPlanSupportAreas([...editPlanContext.planSupportAreas, newArea]);

            supportAreaFormik.resetForm();
            personalServiceFormik.resetForm();
            toggleSupportAreaCard();
            supportAreaFormik.setFieldValue('services', []);

            setLoading(false);
        },
    });

    const personalServiceFormik = useFormik({
        initialValues: {
            serviceTitle: '',
            //serviceDetails: '',
            frequency: '',
            responsibleParty: '',
        },
        validationSchema: ServiceSchema,
        onSubmit: (values) => {
            // handle form submission
            console.log("Form submitted");
            console.log(values);
            supportAreaFormik.values.services.push(values);
            supportAreaFormik.validateField('services');
            personalServiceFormik.resetForm();
        },
    });

    const toggleSupportAreaCard = () => {
        supportAreaFormik.resetForm();
        personalServiceFormik.resetForm();
        supportAreaFormik.setFieldValue('services', []);
        setShowSupportAreaCard(!showSupportAreaCard);
        setLoading(false);
        setEditId(null);
    }

    const handleRemovePersonalService = (service) => {
        const index = supportAreaFormik.values.services.indexOf(service);
        supportAreaFormik.values.services.splice(index, 1);
        supportAreaFormik.setFieldValue('services', supportAreaFormik.values.services);
    }

    return (<>
        {props?.editPlan && <>
            {showSupportAreaCard ?<>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">New Support Area</span>
                                    <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                        <div className="tw-col-span-1">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Support Area Title
                                            </label>
                                            <div className="tw-mt-2">
                                                <input
                                                type="text"
                                                name="support-area-title"
                                                id="support-area-title"
                                                className={`tw-block tw-w-full tw-rounded-md ${supportAreaFormik.touched.supportTitle && supportAreaFormik.errors.supportTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-px-2 tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                {...supportAreaFormik.getFieldProps('supportTitle')}
                                                />
                                                {supportAreaFormik.touched.supportTitle && supportAreaFormik.errors.supportTitle && <p className="tw-text-red-500">{supportAreaFormik.errors.supportTitle}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-2">
                                            <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                Details
                                            </label>
                                            <textarea
                                                rows={2}
                                                name="supportDetails"
                                                id="supportDetails"
                                                className={`tw-block tw-w-full tw-rounded-md ${supportAreaFormik.touched.supportDetails && supportAreaFormik.errors.supportDetails ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-text-gray-900 tw-px-2 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-py-1.5 sm:tw-text-sm sm:tw-leading-6`}
                                                {...supportAreaFormik.getFieldProps('supportDetails')}
                                                />
                                                {supportAreaFormik.touched.supportDetails && supportAreaFormik.errors.supportDetails && <p className="tw-text-red-500">{supportAreaFormik.errors.supportDetails}</p>}
                                        </div>
                                    </div>
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                    <div className="tw-pb-5 tw-flex tw-px-4">
                                        <span className="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Services</span>
                                        {/* <span className="tw-text-xs tw-leading-6 tw-text-primary tw-underline tw-cursor-pointer">Add</span> */}
                                    </div>
                                    <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 sm:tw-p-6">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Name
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-name"
                                                    id="service-name"
                                                    className={`tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.serviceTitle && personalServiceFormik.errors.serviceTitle ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('serviceTitle')}
                                                    />
                                                    {personalServiceFormik.touched.serviceTitle && personalServiceFormik.errors.serviceTitle && <p className="tw-text-red-500">{personalServiceFormik.errors.serviceTitle}</p>}
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.frequency && personalServiceFormik.errors.frequency ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('frequency')}
                                                >
                                                    <option value="">Select Frequency</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {personalServiceFormik.touched.frequency && personalServiceFormik.errors.frequency && <p className="tw-text-red-500">{personalServiceFormik.errors.frequency}</p>}
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${personalServiceFormik.touched.responsibleParty && personalServiceFormik.errors.responsibleParty ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    {...personalServiceFormik.getFieldProps('responsibleParty')}
                                                >
                                                    <option value="">Select Responsible Party</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {personalServiceFormik.touched.responsibleParty && personalServiceFormik.errors.responsibleParty && <p className="tw-text-red-500">{personalServiceFormik.errors.responsibleParty}</p>}
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={personalServiceFormik.resetForm}>
                                                            Cancel
                                                    </ButtonSecondary>
                                                    <ButtonPrimary className="tw-mt-md" onClick={personalServiceFormik.handleSubmit}>
                                                        Add
                                                    </ButtonPrimary>

                                        </div>
                                    </div>
                                
                                    {supportAreaFormik.values.services.length > 0 && supportAreaFormik.values.services?.map((personalService, index) => (
                                        <div className="tw-space-y-6 tw-bg-grey-light tw-rounded-lg tw-mx-6 tw-p-6 tw-mt-4">
                                        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                            <div className="tw-col-span-2">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Service Name
                                                </label>
                                                <div className="tw-mt-2">
                                                    <input
                                                    type="text"
                                                    name="service-name"
                                                    id="service-name"
                                                    className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value = {personalService.serviceTitle}
                                                    disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Frequency
                                                </label>
                                                <select
                                                    id="frequency"
                                                    name="frequency"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={personalService.frequency}
                                                    disabled={true}
                                                >
                                                    <option value="">Select Frequency</option>
                                                    <option value="1X/Week">1X/Week</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="3X/Week">3X/Week</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="tw-col-span-1">
                                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                                    Responsible Party
                                                </label>
                                                <select
                                                    id="responsible-party"
                                                    name="responsible-party"
                                                    className={`tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                                    value={personalService.responsibleParty}
                                                    disabled={true}
                                                >
                                                    <option value="">Select Responsible Party</option>
                                                    <option value="Provider">Provider</option>
                                                    <option value="Guardian">Guardian</option>
                                                    <option value="Nurse">Nurse</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                                    <ButtonSecondary className="tw-mt-md" onClick={e => {
                                                        e.preventDefault();
                                                        handleRemovePersonalService(personalService);
                                                        }}>
                                                            Remove
                                                    </ButtonSecondary>
                                                    {/* <ButtonPrimary className="tw-mt-md">
                                                        Edit
                                                    </ButtonPrimary> */}

                                        </div>
                                    </div>))}                            

                                    <div>
                                        {supportAreaFormik.touched.services && supportAreaFormik.errors.services && <p className="tw-text-red-500"><>{supportAreaFormik.errors.services}</></p>}
                                    </div>

                                    {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                                    
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={toggleSupportAreaCard}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={supportAreaFormik.handleSubmit}>
                                                Add
                                            </ButtonPrimary>

                                    </div>
                                    </>}
                                </div>
                            </>:<>
                                <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-2 tw-mb-4">
                                        <div className="tw-flex tw-items-center">
                                                <div className="tw-mx-auto tw-flex tw-items-center tw-cursor-pointer" onClick={toggleSupportAreaCard}>
                                                <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2" aria-hidden="true" />
                                                <span className="tw-text-base tw-font-medium tw-leading-6 tw-text-primary">Add Support Area</span>
                                                </div>  
                                        </div>
                                </div>
                            </> }
        </>}
        <div className="tw-grid tw-grid-cols-2 tw-gap-6">
            {props?.areas.length > 0 && props?.areas.map((area, index) => (
                <div className="tw-col-span-1 tw-bg-white tw-flex-col tw-p-4 tw-rounded-lg tw-shadow" key={area.supportAreaID}>
                    <EditSupportAreaCard area={area} onEdit={handleEdit} onSave={handleSave} onCancel={handleCancel} isEditing={area.supportAreaID === editId} editPlan={props?.editPlan}></EditSupportAreaCard>
                </div>
            ))}
        </div>
    </>);
}

export default EditSupportAreaCard;

