import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid"
import { useState } from "react";
import { useAppContext } from "../../Context";
import { PatientContact } from "../../types/patient";
import { ButtonPrimary, ButtonSecondary } from "@medforall/medforall-component-library";
import { useFormik } from "formik";
import { patientContactSchema } from "../../pages/NewClientForm";
import { LoadingSpinner } from "./loadingSpinner";
import { addPatientContact, deletePatientContact, updatePatientContact } from "../../apicalls/clientMgmtService";

export const EditClientContactsCard = (props: any) => {
    const [editId, setEditId] = useState<string | null>(null);

    const appContext = useAppContext();
    const [loading, setLoading] = useState(false);

    function handleEdit(id: string) {
        setEditId(id);
    }

    async function handleSave(id: string, updatedContact: PatientContact) {
        
        setEditId(null);

        props?.refreshClient();
    }

    function handleCancel() {
        // Reset the edit ID
        setEditId(null);
    }

    function handleDelete(){
        // Delete the contact
        setEditId(null);
    }

    const [showBackupContactForm, setShowBackupContactForm] = useState(false);

    const openBackupContactForm = () => {
        newPhoneFormik.resetForm();
        setShowBackupContactForm(true);
    }

    const closeBackupContactForm = () => {
        setShowBackupContactForm(false);
        newPhoneFormik.resetForm();
    }

    const newPhoneFormik = useFormik({
        initialValues: {
          FirstName: '',
          LastName: '',
          PhoneNumber: '',
          Email: '',
          Relationship: '',
        },
        validationSchema: patientContactSchema,
        onSubmit: async (values) => {
          // handle form submission
            console.log("Form submitted");

            const newContact: PatientContact = {
                patientContactID: "",
                patientID: "",
                contactPhoneNumber: values.PhoneNumber,
                contactFirstName: values.FirstName,
                contactLastName: values.LastName,
                contactRelationship: values.Relationship,
                contactEmail: values.Email,
            }

            setLoading(true);
            var result = await addPatientContact(props.patientID, newContact, appContext.handleAPICallError);
            setLoading(false);
            if(!appContext.showErrorModal){
                appContext.setModalMessageHeader("Success");
                appContext.setModalMessageBody("New Contact Added Successfully");
                appContext.setShowSuccessModal(true);
            }

            props?.refreshClient();


          //addToPatientContacts(values);

          //newPhoneFormik.resetForm();

          //setShowBackupContactForm(false);
        },
    });
    
    return (<>
            { 
                        <div className="tw-bg-white tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6">
                        <div className="tw-pb-5 tw-flex tw-px-4">
                            <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Contacts</span>
                            {!showBackupContactForm && <PlusCircleIcon className="tw-h-8 tw-w-8 tw-flex-shrink-0 tw-text-primary tw-mx-2 tw-cursor-pointer" aria-hidden="true" onClick={openBackupContactForm}/> }
                        </div>
                        {showBackupContactForm &&
                        <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 tw-mb-4 sm:tw-p-6">
                            <div className="tw-pb-5 tw-flex tw-px-4">
                                <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">New Contact</span>
                            </div>
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        First Name
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        className={`tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.FirstName && newPhoneFormik.errors.FirstName ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...newPhoneFormik.getFieldProps('FirstName')}
                                        />
                                        {newPhoneFormik.touched.FirstName && newPhoneFormik.errors.FirstName && <p className="tw-text-red-500">{newPhoneFormik.errors.FirstName}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                    Last Name
                                </label>
                                <div className="tw-mt-2">
                                    <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className={`tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.LastName && newPhoneFormik.errors.LastName ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                    {...newPhoneFormik.getFieldProps('LastName')} 
                                    />
                                    {newPhoneFormik.touched.LastName && newPhoneFormik.errors.LastName && <p className="tw-text-red-500">{newPhoneFormik.errors.LastName}</p>}
                                </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Email
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="email"
                                        name="contactMail"
                                        id="contactMail"
                                        className={`tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.Email && newPhoneFormik.errors.Email ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...newPhoneFormik.getFieldProps('Email')}
                                        />
                                        {newPhoneFormik.touched.Email && newPhoneFormik.errors.Email && <p className="tw-text-red-500">{newPhoneFormik.errors.Email}</p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Phone
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        className={`tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.PhoneNumber && newPhoneFormik.errors.PhoneNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...newPhoneFormik.getFieldProps('PhoneNumber')}
                                        />
                                        {newPhoneFormik.touched.PhoneNumber && newPhoneFormik.errors.PhoneNumber && (
                                            <p className="tw-text-red-500">{newPhoneFormik.errors.PhoneNumber}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Relationship
                                    </label>
                                    <select
                                        id="relationship"
                                        name="relationship"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${newPhoneFormik.touched.Relationship && newPhoneFormik.errors.Relationship ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...newPhoneFormik.getFieldProps('Relationship')}
                                    >
                                        <option value="">Select Relationship</option>
                                        <option value="Self">Self</option>
                                        <option value="Parent">Parent</option>
                                        <option value="Guardian">Guardian</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {newPhoneFormik.touched.Relationship && newPhoneFormik.errors.Relationship && (
                                            <p className="tw-text-red-500">{newPhoneFormik.errors.Relationship}</p>
                                        )}
                                </div>
                            </div>
                            {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                            <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={closeBackupContactForm}>
                                                Cancel
                                        </ButtonSecondary>
                                        <ButtonPrimary className="tw-mt-md" type="submit" onClick={newPhoneFormik.handleSubmit}>
                                            Save
                                        </ButtonPrimary>

                            </div>
                            </>}
                        </div>
                        }
                        {props?.contacts?.length > 0 &&  props?.contacts?.map((contact, index) => {
                            if(index !== props?.contacts?.length - 1) {
                                return <>
                                    {contact.patientContactID === editId ? <EditContactCard contact={contact} handleSave={handleSave} handleCancel={handleCancel} /> : <ViewContactCard contact={contact} handleEdit={handleEdit} />}
                                    
                                    <div className="tw-w-full tw-border-t tw-border-gray-300 tw-my-4" />
                                </>
                            } else {
                                return <>
                                    {contact.patientContactID === editId ? <EditContactCard contact={contact} handleSave={handleSave} handleCancel={handleCancel} /> : <ViewContactCard contact={contact} handleEdit={handleEdit} />}
                                </>
                            }
                            })}

                    
                    </div>
                    }
    </>)
}

const ViewContactCard = (props: any) => {
    return (<>
        <div className="tw-bg-grey-light tw-flex tw-px-4 tw-py-5 tw-shadow tw-rounded-lg sm:tw-p-6 tw-mx-4">
                <div className="tw-w-4/5 tw-flex-grow">
                    <div className="tw-flex tw-px-4 tw-pb-2">
                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>First Name</span>
                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.contact?.contactFirstName}</span>
                    </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Last Name</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.contact?.contactLastName}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Phone</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.contact?.contactPhoneNumber}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Email</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.contact?.contactEmail}</span>
                                        </div>
                                        <div className="tw-flex tw-px-4 tw-pb-2">
                                            <span className='tw-text-sm tw-w-2/5 tw-text-grey-dark tw-font-light tw-leading-4'>Relationship</span>
                                            <span className='tw-text-sm tw-w-3/5 tw-text-gray-900 tw-font-semibold tw-leading-4'>{props?.contact?.contactRelationship}</span>
                                        </div>
                </div>
                <div className="tw-w-1/5 tw-flex tw-justify-end tw-items-center">
                    <PencilIcon className="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-primary tw-cursor-pointer" aria-hidden="true" onClick={() => props?.handleEdit(props?.contact.patientContactID)} />
                </div>
               
            </div>
    </>)
}

const EditContactCard = (props: any) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const appContext = useAppContext();
    
    const phoneFormik = useFormik({
        initialValues: {
          FirstName: props?.contact?.contactFirstName,
          LastName: props?.contact?.contactLastName,
          PhoneNumber: props?.contact?.contactPhoneNumber,
          Email: props?.contact?.contactEmail,
          Relationship: props?.contact?.contactRelationship,
        },
        validationSchema: patientContactSchema,
        onSubmit: async (values) => {
          // handle form submission
          console.log("Form submitted")

          const updatedContact: PatientContact = {
            patientContactID: props?.contact?.patientContactID,
            patientID: "",
            contactPhoneNumber: values.PhoneNumber,
            contactFirstName: values.FirstName,
            contactLastName: values.LastName,
            contactRelationship: values.Relationship,
            contactEmail: values.Email,
        }

          setLoading(true);
          var result = await updatePatientContact(props.contact.patientID, updatedContact, appContext.handleAPICallError);
          setLoading(false);
          if(!appContext.showErrorModal){
              appContext.setModalMessageHeader("Success");
              appContext.setModalMessageBody("Contact Updated Successfully");
              appContext.setShowSuccessModal(true);
          }

          props.handleSave();

          //addToPatientContacts(values);

          //newPhoneFormik.resetForm();

          //setShowBackupContactForm(false);
        },
        enableReinitialize: true
    });

    const handleDeleteContact = () => {
        setShowConfirmDelete(true);
    }

    const deleteContact = async () => {
        setLoading(true);
        var result = await deletePatientContact(props.contact.patientID, props.contact.patientContactID, appContext.handleAPICallError);
        setLoading(false);
        if(!appContext.showErrorModal){
            appContext.setModalMessageHeader("Success");
            appContext.setModalMessageBody("Contact Deleted Successfully");
            appContext.setShowSuccessModal(true);
        }
        props.handleSave();
    }

    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }


    
    return (<>
        <div className="tw-space-y-6 tw-bg-card-green tw-px-4 tw-py-5 tw-rounded-lg tw-mx-6 tw-mb-4 sm:tw-p-6">
                <div className="tw-pb-5 tw-flex tw-px-4">
                    <span className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-flex-grow">Edit Contact</span>
                </div>
                            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        First Name
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        className={`tw-block tw-w-full tw-rounded-md ${phoneFormik.touched.FirstName && phoneFormik.errors.FirstName ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...phoneFormik.getFieldProps('FirstName')}
                                        />
                                        {phoneFormik.touched.FirstName && phoneFormik.errors.FirstName && <p className="tw-text-red-500"><>{phoneFormik.errors.FirstName}</></p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                    Last Name
                                </label>
                                <div className="tw-mt-2">
                                    <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className={`tw-block tw-w-full tw-rounded-md ${phoneFormik.touched.LastName && phoneFormik.errors.LastName ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                    {...phoneFormik.getFieldProps('LastName')} 
                                    />
                                    {phoneFormik.touched.LastName && phoneFormik.errors.LastName && <p className="tw-text-red-500"><>{phoneFormik.errors.LastName}</></p>}
                                </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Email
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="email"
                                        name="contactMail"
                                        id="contactMail"
                                        className={`tw-block tw-w-full tw-rounded-md ${phoneFormik.touched.Email && phoneFormik.errors.Email ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...phoneFormik.getFieldProps('Email')}
                                        />
                                        {phoneFormik.touched.Email && phoneFormik.errors.Email && <p className="tw-text-red-500"><>{phoneFormik.errors.Email}</></p>}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Phone
                                    </label>
                                    <div className="tw-mt-2">
                                        <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        className={`tw-block tw-w-full tw-rounded-md ${phoneFormik.touched.PhoneNumber && phoneFormik.errors.PhoneNumber ? 'tw-border tw-border-red-500' : 'tw-border-0'}  tw-py-1.5 tw-px-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...phoneFormik.getFieldProps('PhoneNumber')}
                                        />
                                        {phoneFormik.touched.PhoneNumber && phoneFormik.errors.PhoneNumber && (
                                            <p className="tw-text-red-500"><>{phoneFormik.errors.PhoneNumber}</></p>
                                        )}
                                    </div>
                                </div>
                                <div className="tw-col-span-1">
                                    <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                        Relationship
                                    </label>
                                    <select
                                        id="relationship"
                                        name="relationship"
                                        className={`tw-mt-2 tw-block tw-w-full tw-rounded-md ${phoneFormik.touched.Relationship && phoneFormik.errors.Relationship ? 'tw-border tw-border-red-500' : 'tw-border-0'} tw-py-2 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6`}
                                        {...phoneFormik.getFieldProps('Relationship')}
                                    >
                                        <option value="">Select Relationship</option>
                                        <option value="Self">Self</option>
                                        <option value="Parent">Parent</option>
                                        <option value="Guardian">Guardian</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {phoneFormik.touched.Relationship && phoneFormik.errors.Relationship && (
                                            <p className="tw-text-red-500"><>{phoneFormik.errors.Relationship}</></p>
                                        )}
                                </div>
                            </div>

                            {loading ? <>
                                        <div className="tw-flex tw-justify-end tw-mx-4">
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </> : <>
                            {showConfirmDelete ? <>
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                    <p className="tw-text-primary ">Are you sure you want to delete this patient contact?</p>
                                    </div>
                                    
                                    <div className="tw-flex tw-justify-end tw-mx-4">
                                            <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={cancelDelete}>
                                                    Cancel
                                            </ButtonSecondary>
                                            <ButtonPrimary className="tw-mt-md" onClick={deleteContact}>
                                                    Delete
                                            </ButtonPrimary>

                                    </div>
                                    </>:<>
                            <div className="tw-flex tw-justify-end tw-mx-4">
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={handleDeleteContact}>
                                            Delete
                                        </ButtonSecondary>
                                        <ButtonSecondary className="tw-mt-md tw-mx-4" onClick={props?.handleCancel}>
                                            {(phoneFormik.dirty) ? 'Discard' : 'Cancel'}
                                        </ButtonSecondary>
                                        {phoneFormik.dirty && <ButtonPrimary className="tw-mt-md" type="submit" onClick={phoneFormik.handleSubmit}>
                                            Save
                                        </ButtonPrimary>}

                            </div>
                            </>}
                            </>}
                        </div>
    </>);
}